import type { ShipmentInfo } from './shipment-details-form'
import { InputType, DataPropertyType } from '@extend/zen'
import type { CompactFormFieldData } from '../../../../../../components/compact-form/types'
import { AdditionalDataPropertyTypes } from '../../../../../../components/compact-form/types'

export interface ShipmentInfoFormFields {
  PRODUCTS: CompactFormFieldData[]
  'DESTINATION ADDRESS': CompactFormFieldData[]
  'SOURCE ADDRESS': CompactFormFieldData[]
  'TRACKING INFORMATION': CompactFormFieldData[]
}

export const getShipmentInfoFormFields = (shipmentInfo: ShipmentInfo): ShipmentInfoFormFields => {
  return {
    PRODUCTS: getProductsFields(shipmentInfo),
    'DESTINATION ADDRESS': getDestinationAddressFields(shipmentInfo),
    'SOURCE ADDRESS': getSourceAddressFields(shipmentInfo),
    'TRACKING INFORMATION': getTrackingInformationFields(shipmentInfo),
  }
}

const getProductsFields = ({ productIds }: ShipmentInfo): CompactFormFieldData[] =>
  productIds.map((productId, index) => ({
    id: `${productId}-${index}`,
    key: `${productId}-${index}`,
    label: 'Product ID',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: productId,
    isEditable: false,
  }))

const getDestinationAddressFields = ({ destination }: ShipmentInfo): CompactFormFieldData[] => [
  {
    id: 'destination.address1',
    label: 'Address 1',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: destination?.address1 || '',
    isEditable: false,
  },
  {
    id: 'destination.address2',
    label: 'Address 2',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: destination?.address2 || '',
    isEditable: false,
  },
  {
    id: 'destination.city',
    label: 'City',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: destination?.city || '',
    isEditable: false,
  },
  {
    id: 'destination.countryCode',
    label: 'Country Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: destination?.countryCode || '',
    isEditable: false,
  },
  {
    id: 'destination.postalCode',
    label: 'Postal Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: destination?.postalCode || '',
    isEditable: false,
  },
  {
    id: 'destination.provinceCode',
    label: 'Province',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: destination?.provinceCode || '',
    isEditable: false,
  },
]

const getSourceAddressFields = ({ source }: ShipmentInfo): CompactFormFieldData[] => [
  {
    id: 'source.address1',
    label: 'Address 1',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: source?.address1 || '',
    isEditable: false,
  },
  {
    id: 'source.address2',
    label: 'Address 2',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: source?.address2 || '',
    isEditable: false,
  },
  {
    id: 'source.city',
    label: 'City',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: source?.city || '',
    isEditable: false,
  },
  {
    id: 'source.countryCode',
    label: 'Country Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: source?.countryCode || '',
    isEditable: false,
  },
  {
    id: 'source.postalCode',
    label: 'Postal Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: source?.postalCode || '',
    isEditable: false,
  },
  {
    id: 'source.provinceCode',
    label: 'Province',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: source?.provinceCode || '',
    isEditable: false,
  },
]

const getTrackingInformationFields = (shipment: ShipmentInfo): CompactFormFieldData[] => [
  {
    id: 'shipmentDate',
    label: 'Shipment Date',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: shipment.shipmentDate || '',
    isEditable: false,
  },
  {
    id: 'shippingProvider',
    label: 'Shipment Provider',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: shipment.shippingProvider || '',
    isEditable: false,
  },
  {
    id: 'trackingId',
    label: 'Tracking ID',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: shipment.trackingId,
    isEditable: false,
  },
  {
    id: 'trackingUrl',
    label: 'Tracking URL',
    urlText: 'Track Shipment',
    inputType: InputType.text,
    dataPropertyType: AdditionalDataPropertyTypes.url,
    value: shipment.trackingUrl || '',
    isEditable: false,
  },
]
