import styled from '@emotion/styled'
import type { OfferType, Store, StoreShippingProtection } from '@helloextend/extend-api-client'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { Button, ButtonGroup, Information, Paragraph, Radio, RadioGroup } from '@extend/zen'
import { FormikProvider, useFormik } from 'formik'
import type { FC } from 'react'
import React from 'react'
import type {
  SPCartPriceMap,
  SPOfferTypeMap,
} from '@helloextend/extend-api-client/src/models/store-shipping-protection'
import { OfferTypeByPriceBandForm } from './offer-type-by-price-band-form/offer-type-by-price-band-form'
import type { PriceBandValues, Values } from './offer-type-by-price-band-form/schema'
import {
  mapPriceBandValues,
  initialPriceBandValues,
  schema,
} from './offer-type-by-price-band-form/schema'

type ShippingProtectionOfferTypeProps = {
  store: Store
}

const ShippingProtectionOfferType: FC<ShippingProtectionOfferTypeProps> = ({ store }) => {
  const [updateStore, { isLoading }] = useUpdateStoreMutation()
  const { toastCompleted, toastError } = useStandardToast()
  const offerTypeValue = store.shippingProtection?.offerTypeMap?.enabled
    ? 'PRICE_BAND'
    : store.shippingProtection?.offerType

  const handleSave = async (): Promise<void> => {
    const isPriceBand = values.offerType === 'PRICE_BAND'

    let spData = {} as Partial<StoreShippingProtection>

    if (isPriceBand) {
      spData = {
        offerTypeMap: {
          enabled: true,
          map: mapPriceBandValues(values.priceBands as PriceBandValues[]),
        } as SPOfferTypeMap,
      }
    } else {
      spData = {
        offerType: values.offerType as OfferType,
        // ensure that the new offerTypeMap.enabled is updated but the offerTypeMap.map is preserved because otherwise the bands array will be overwritten
        offerTypeMap: {
          enabled: false,
          map: store.shippingProtection?.offerTypeMap?.map as SPCartPriceMap[],
        },
      }
    }

    try {
      await updateStore({
        storeId: store.id,
        data: {
          shippingProtection: spData as Partial<StoreShippingProtection>,
        },
        version: 'latest',
      }).unwrap()
      toastCompleted('Offer Type has been successfully updated.')
    } catch {
      toastError('Something went wrong. Please try again.')
    }
  }

  const initialPriceBands = store.shippingProtection?.offerTypeMap?.map ?? [
    initialPriceBandValues(true),
  ]

  const formik = useFormik<Values>({
    initialValues: {
      offerType: offerTypeValue,
      enabled: store.shippingProtection?.offerTypeMap?.enabled,
      priceBands: initialPriceBands,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleSave,
  })

  const { values, isValid, dirty, errors, handleChange, handleSubmit, handleReset } = formik

  return (
    <>
      <FormikProvider value={formik}>
        <RadioGroup
          data-cy="offer-type-radio-group"
          label="Select an Offer Type"
          name="offerType"
          onChange={handleChange}
          value={values.offerType || ''}
        >
          <Radio label="Opt-In" value="OPT_IN" />
          <Radio label="Opt-Out" value="OPT_OUT" />
          <RadioWithInfo>
            <Radio label="Merchant Complete" value="OPT_MERCHANT" />
            <Information>
              <Paragraph>SP premium paid by merchants.</Paragraph>
            </Information>
          </RadioWithInfo>
          <Radio label="Offer Type By Price Band" value="PRICE_BAND" />
          <Radio label="Safe Package Guarantee" value="SAFE_PACKAGE" />
        </RadioGroup>
        {values.offerType === 'PRICE_BAND' && (
          <ContainerWithMargin>
            <OfferTypeByPriceBandForm
              spMapValues={values}
              handleOnChange={handleChange}
              errors={errors}
            />
          </ContainerWithMargin>
        )}
        {dirty && (
          <ContainerWithMargin>
            <ButtonGroup>
              <Button
                text="Cancel"
                onClick={handleReset}
                emphasis="medium"
                isDisabled={isLoading}
                data-cy="offerType-cancel-button"
              />
              <Button
                text="Save"
                onClick={() => handleSubmit()}
                emphasis="high"
                isDisabled={!isValid}
                isProcessing={isLoading}
                data-cy="offerType-save-button"
              />
            </ButtonGroup>
          </ContainerWithMargin>
        )}
      </FormikProvider>
    </>
  )
}

const RadioWithInfo = styled.div({
  display: 'flex',
})

const ContainerWithMargin = styled.div({
  marginTop: '16px',
})

export { ShippingProtectionOfferType }
