import type { PlanCategory } from '@helloextend/extend-api-client'
import type { ProductCsvValidatorPipe } from './product-csv-headers'

export const validatePlanCategoryId = (
  meta: ProductCsvValidatorPipe,
  planCategories: PlanCategory[],
): ProductCsvValidatorPipe => {
  const productTitle = meta.rowData[meta.headerIndexes.title]

  if (planCategories.length === 0) {
    meta.warnings?.push(
      `Unable to validate plan category information for Product: ${productTitle}.`,
    )
    return meta
  }
  const planCategoryName = meta.rowData[meta.headerIndexes.planCategory]?.trim()
  const validPlanCategoryNames = planCategories.map((cat) => cat.name)

  if (planCategoryName && !validPlanCategoryNames.includes(planCategoryName)) {
    meta.errors.push(`Invalid plan category for Product: ${productTitle}`)
  }
  return meta
}
