import React from 'react'
import styled from '@emotion/styled'
import type { ButtonIconPosition, ButtonProps } from '@extend/zen'
import { COLOR, Icon, getColorsButton, getMeasurementsButton, getPaddingButton } from '@extend/zen'
import type { ColorArgs, MeasurementArgs } from './types'

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      ariaLabel,
      emphasis = 'high',
      size = 'regular',
      color = 'neutral',
      fillContainer = false,
      icon,
      iconPosition = 'left',
      tooltip = '',
      isDisabled = false,
      isInverted = false,
      isToggled = false,
      onClick,
      text,
      'data-cy': dataCy,
      tabIndex,
    },
    ref,
  ) => {
    const colors = getColorsButton(color, isInverted, emphasis)
    const padding = getPaddingButton(size, !!text, !!icon, iconPosition, false)
    const measurements = getMeasurementsButton(size)
    return (
      <StyledButton
        ref={ref}
        type="button"
        className={`${isToggled ? 'active' : ''}`}
        onClick={onClick}
        disabled={isDisabled}
        aria-label={ariaLabel || text}
        data-cy={dataCy}
        colors={colors}
        iconPosition={iconPosition}
        data-tooltip={tooltip}
        fillContainer={fillContainer}
        padding={padding}
        measurements={measurements}
        borderRadius="24px"
        tabIndex={tabIndex}
      >
        {!!icon && (
          <Icon
            icon={icon}
            size={measurements.iconSize}
            color={!isDisabled ? colors.contentColor : colors.disabledContentColor}
          />
        )}
        {text}
      </StyledButton>
    )
  },
)

export { Button }

const StyledButton = styled.button<{
  colors: ColorArgs
  fillContainer: boolean
  iconPosition: ButtonIconPosition
  padding: string
  measurements: MeasurementArgs
  borderRadius: string
}>(({ colors, fillContainer, iconPosition, padding, measurements, borderRadius }) => ({
  /* Base styles */
  position: 'relative',
  boxSizing: 'border-box',
  border: 'none',
  borderRadius,
  fontWeight: 800,
  fontSize: measurements.fontSize,
  lineHeight: measurements.lineHeight,
  gap: measurements.gap,
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'background-color 120ms',
  cursor: 'pointer',
  pointerEvents: 'all',

  /* Fill container styles */
  display: fillContainer ? 'flex' : 'inline-flex',
  ...(iconPosition === 'right' && {
    flexDirection: 'row-reverse',
  }),
  width: fillContainer ? '100%' : undefined,

  /* Override browser default margins */
  margin: 0,

  /* Padding adjusted by presence and/or position of icon */
  padding,

  /* Color styles */
  background: colors.backgroundColor || 'none',
  color: colors.contentColor,

  /* Before pseudo-elements used for medium emphasis border to prevent the need to change padding in base styles */
  ...(colors.borderColor && {
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      border: `1px solid ${colors.borderColor}`,
      borderRadius: 'inherit',
    },
  }),

  /* After pseudo-elements used for focus state style */
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: -2,
    boxShadow: `0 0 4px 2px ${COLOR.BLUE[500]}`,
    borderRadius: 'inherit',
    opacity: 0,
    transition: 'opacity 120ms',
    pointerEvents: 'none',
  },

  /* Disabled styles */
  '&:disabled': {
    cursor: 'default',
    pointerEvents: 'none',
    background: colors.disabledBackgroundColor,
    color: colors.disabledContentColor,
    ...(colors.borderColor && {
      '&::before': {
        borderColor: colors.disabledBorderColor,
      },
    }),
  },

  /* State styles */
  '&:hover': {
    background: colors.hoverBackgroundColor,
  },
  '&:active, &.active': {
    background: colors.activeBackgroundColor,
  },
  '&.active:hover': {
    background: colors.hoverBackgroundColor,
  },
  '&:focus': {
    outline: 'none',
  },
  '&:focus-visible': {
    outline: 'none',
    '&::after': {
      opacity: 1,
    },
  },
}))
