import styled from '@emotion/styled'
import { Breadcrumbs, DisplayLarge } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  useCreatePlanTermsUploadUrlMutation,
  useListPlanTermsQuery,
  useUploadTermsToS3UrlMutation,
} from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { TermsForm } from '../components/terms-form/terms-form'
import { fileToBuffer } from '../../../../utils/file-to-buffer'

const CreateTerms: FC = () => {
  const history = useHistory()

  const { data: planTermsList, isLoading: isListLoading } = useListPlanTermsQuery()

  const [createUrl] = useCreatePlanTermsUploadUrlMutation()
  const [uploadFile, { isLoading: isUploading }] = useUploadTermsToS3UrlMutation()

  const handleCancel = (isRefetch = false): void => {
    history.push('/admin/terms', { isRefetch })
  }

  const { toastCompleted, toastError } = useStandardToast()

  const handleUploadFile = async (
    file: File,
    description: string,
    termsId: string,
  ): Promise<void> => {
    if (!file) return

    let url

    try {
      const res = await createUrl({
        filename: file.name,
        description,
        termsId,
      }).unwrap()
      url = res.url
    } catch {
      toastError('Something went wrong. Please try again.')
      return
    }

    const fileBuffer = await fileToBuffer(file)
    if (!url || !fileBuffer) return
    try {
      await uploadFile({ url, file: fileBuffer }).unwrap()
      toastCompleted(`${file.name} has been successfully uploaded.`)
      handleCancel(true)
    } catch {
      toastError('Something went wrong. Please try again.')
    }
  }

  return (
    <>
      <Breadcrumbs
        data-cy="terms-breadcrumbs"
        crumbs={[
          {
            text: 'Terms & Conditions',
            to: '/admin/terms',
          },
          {
            text: 'Create Terms',
          },
        ]}
      />
      <DisplayLargeWithMargin>Create Terms</DisplayLargeWithMargin>
      <TermsForm
        onCancel={handleCancel}
        onUpload={handleUploadFile}
        isUploadProcessing={isListLoading || isUploading}
        planTermsList={planTermsList}
      />
    </>
  )
}

const DisplayLargeWithMargin = styled(DisplayLarge)({
  margin: '24px 0',
})

export { CreateTerms }
