import { useListAllPlanIdsQuery } from '@helloextend/extend-api-rtk-query'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { AdvancedSelect, DataProperty, DataPropertyType } from '@extend/zen'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import { listToAdvancedSelectOptions } from '../../../../../utils/form-utils'

type PlansDropdownProps = {
  onChange: (e: AdvancedSelectChangeEvent) => void
  value?: string
}

const PlansDropdown: FC<PlansDropdownProps> = ({ onChange, value = '' }) => {
  const { data, isLoading } = useListAllPlanIdsQuery()

  const options = useMemo(() => {
    if (data) {
      return listToAdvancedSelectOptions(data)
    }
    return [{ display: '', value: '' }]
  }, [data])

  if (isLoading)
    return (
      <DataProperty
        label="Plan ID"
        type={DataPropertyType.string}
        data-cy="configuration-planId-data-property"
        isLoading={isLoading}
      />
    )

  return (
    <AdvancedSelect
      id="planId"
      label="Plan ID"
      placeholder={value === '' ? 'Select' : value}
      value={value}
      onChange={onChange}
      options={options}
      data-cy="configuration-planId-selector"
      showSearch
      maxQuantityToDisplay={10}
      pageSize={25}
      multiple={false}
    />
  )
}

export { PlansDropdown }
