import type { FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import type {
  Contract20220201GetResponse,
  ContractProduct,
  ContractFormValuesUpdate,
  ShippingProtectionContract,
  ContractCoveredProduct,
  CategoryProduct,
} from '@helloextend/extend-api-client'
import { useDispatch, useSelector } from 'react-redux'
import { productsActions } from '@helloextend/core-api-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useExtendAuth } from '@extend/package-okta-login'
import type { FormikMapperConfig } from '../../../../components/form-text-group'
import {
  applyEditPermissions,
  CollapsibleFormGroupEditWrapper,
  CollapsibleFormTextGroup,
  formikMapper,
} from '../../../../components/form-text-group'
import type { V2Values } from '../schema'
import { mapContract20220201ToValues, V2Schema } from '../schema'
import {
  billingInformationFields,
  charitableDonationFields,
  contractInformationFieldsSP,
  customerFields,
  sectionTitles,
  shippingInformationFields,
  storeInformationFields,
  transactionInformationFieldsSP,
} from '../fields'
import type { RootState } from '../../../../reducers'
import * as storesActions from '../../../../actions/stores'
import * as selectors from '../../../../reducers/selectors'
import { LeavePageModal } from '../../../../components/leave-page-guard'
import { permissionsSelectorV2 } from '../edit-permissionsV2'
import { mapContractV2ToLatestUpdateRequest } from '../../../../utils/contract-property-mapper'
import { STORE_ERROR_MESSAGE } from '../constants'
import type { InputItem } from '../../../../components/form-text-group/types'
import type { ContractProductTracking } from './product-shipping-info'
import { ProductShippingInformation } from './product-shipping-info'
import { LDFlag } from '../../../../constants/ld-flags'
import type { UserRole } from '../../../../utils/user-role-mapper'

export interface ShippingProduct extends ContractProduct {
  description?: string
  lineItemId: string
  limitOfLiability?: number
}

type ShippingProtectionProps = {
  saveUpdates?: (formValues: ContractFormValuesUpdate) => void
  toggleNavBlocked?: (blocked: boolean) => void
  contract: Contract20220201GetResponse | ShippingProtectionContract
  planTermsUrl?: string
  orderUrl?: string
}

const ShippingProtection: FC<ShippingProtectionProps> = ({
  contract,
  saveUpdates,
  toggleNavBlocked,
  planTermsUrl,
  orderUrl,
}) => {
  const dispatch = useDispatch()
  const { [LDFlag.LegacyAndEnterpriseRoles]: FF_LEGACY_AND_ENTERPRISE_ROLES } = useFlags()
  const { user, token: accessToken } = useExtendAuth()

  const store = useSelector((state: RootState) => selectors.getStoreById(state, contract?.sellerId))

  const storeError = useSelector((state: RootState) => selectors.getStoreErrors(state))
  const isContractUpdating = useSelector((state: RootState) =>
    selectors.getIsContractUpdating(state),
  )

  const [initialFormValues, setInitialFormValues] = useState({} as V2Values)
  const isContractUpdateSuccessful = useSelector((state: RootState) =>
    selectors.getIsContractUpdateSuccess(state),
  )
  const [selectedSection, setSelectedSection] = useState('')
  const [isLeaveSectionModalVisible, setIsLeaveSectionModalVisible] = useState(false)
  const role = (user?.role as UserRole) ?? 'merchantagent'
  const appliedPermissionRole = permissionsSelectorV2[role]

  const { results: productAndShippingValues, trackingInfoReferenceIds: referenceIds } =
    useMemo(() => {
      const results: ContractProductTracking[] = []
      const trackingInfoReferenceIds = new Set<string>()
      const referenceIdProductMap = new Map<string, ShippingProduct[]>()
      const { productsList, trackingInfo } = contract

      if (productsList?.length) {
        ;(productsList as ShippingProduct[]).forEach((product) => {
          if (!referenceIdProductMap.has(product.referenceId)) {
            referenceIdProductMap.set(product.referenceId, [product])
          } else {
            const products = referenceIdProductMap.get(product.referenceId)
            const newProducts = products?.concat(product)
            referenceIdProductMap.set(product.referenceId, newProducts || [])
          }
        })
      }

      if (trackingInfo?.length) {
        trackingInfo.forEach((tracking) => {
          const { productsShipped } = tracking
          const ids = new Set(productsShipped)

          ids.forEach((id) => trackingInfoReferenceIds.add(id))
          const shippingProtectionProducts: ShippingProduct[] = []

          for (const referenceId of productsShipped) {
            if (ids.has(referenceId) && referenceIdProductMap.has(referenceId)) {
              const products = referenceIdProductMap.get(referenceId) || []
              shippingProtectionProducts.push(...products)
            }
          }

          results.push({ products: shippingProtectionProducts, ...tracking })
        })
      }

      return { results, trackingInfoReferenceIds }
    }, [contract])

  const productsWithoutTrackingInfo = useMemo(() => {
    const { productsList } = contract

    if (productsList) {
      // @ts-ignore - productsList is not a partial
      return productsList.filter(
        (product: ContractCoveredProduct | CategoryProduct) =>
          !referenceIds.has((product as ContractCoveredProduct).referenceId),
      ) as ShippingProduct[]
    }

    return []
  }, [contract, referenceIds])

  useEffect(() => {
    if (!store && !storeError && accessToken && contract) {
      dispatch(storesActions.fetch(contract.sellerId, accessToken))
    }

    if (contract && store) {
      dispatch(productsActions.fetchAll(store.id, accessToken || ''))
    }
  }, [contract, dispatch, store, accessToken, storeError])

  useEffect(() => {
    if (contract && store) {
      setInitialFormValues(
        mapContract20220201ToValues(
          contract as Contract20220201GetResponse,
          store,
          planTermsUrl,
          orderUrl,
        ),
      )
    }
  }, [contract, store, planTermsUrl])

  useEffect(() => {
    if (contract && storeError) {
      setInitialFormValues(
        mapContract20220201ToValues(
          contract as Contract20220201GetResponse,
          store,
          planTermsUrl,
          orderUrl,
        ),
      )
    }
  }, [contract, store, storeError, planTermsUrl])

  useEffect(() => {
    if (isContractUpdateSuccessful) {
      setSelectedSection('')
    }
  }, [isContractUpdateSuccessful])

  const { values, errors, touched, dirty, handleChange, handleBlur, resetForm, setFieldValue } =
    useFormik<V2Values>({
      enableReinitialize: true,
      validationSchema: V2Schema.get(selectedSection),
      validateOnChange: true,
      validateOnBlur: false,
      initialValues: initialFormValues,
      onSubmit: (): void => {},
    })

  useEffect(() => {
    if (toggleNavBlocked) toggleNavBlocked(dirty)
  }, [dirty, toggleNavBlocked])

  if (!contract) return null
  const hasErrors = Object.entries(errors).length >= 1

  const setFormValues = (formField: FormikMapperConfig[]): InputItem[] => {
    const updatedFormFields = FF_LEGACY_AND_ENTERPRISE_ROLES
      ? formField
      : applyEditPermissions(formField, appliedPermissionRole)
    return formikMapper<V2Values>(values, touched, errors, updatedFormFields)
  }

  const handleClickEdit = (section: string): void => {
    if (dirty) {
      setIsLeaveSectionModalVisible(true)
      return
    }
    setSelectedSection(section)
  }

  const handleCancel = (): void => {
    resetForm()
    setSelectedSection('')
  }

  const handleFormSave = (): void => {
    const { contractUpdate } = mapContractV2ToLatestUpdateRequest(
      values,
      contract as Contract20220201GetResponse,
    )
    // the PUT API for some 2.0 contracts environments will throw an error if the original status is passed back to it
    // This check will remove status if it has not been updated
    // @ts-ignore - contractUpdate is not a partial
    if (contractUpdate.values.status === initialFormValues?.contractStatus) {
      delete contractUpdate.values.status
    }
    if (saveUpdates) saveUpdates({ contractUpdate })
    resetForm({ values })
  }

  const handleLeaveSectionModalClose = (): void => {
    setIsLeaveSectionModalVisible(false)
  }

  const handleLeaveSectionModalLeave = (): void => {
    handleCancel()
    setIsLeaveSectionModalVisible(false)
  }

  return (
    <Container data-cy="contract-form-pcrs">
      <LeavePageModal
        isVisible={isLeaveSectionModalVisible}
        handleCloseModal={handleLeaveSectionModalClose}
        handleLeavePage={handleLeaveSectionModalLeave}
      />
      <LeftContainer>
        <CollapsibleFormGroupEditWrapper
          dataCy="customer-section"
          handleSubmit={handleFormSave}
          handleCancel={handleCancel}
          handleClickEdit={handleClickEdit}
          isSubmitDisabled={hasErrors || !dirty}
          isEditable
          isEditing={selectedSection === sectionTitles.customerInformation}
          isSubmitting={isContractUpdating}
          title={sectionTitles.customerInformation}
          shouldStartExpanded
        >
          <NestedFormGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={selectedSection !== sectionTitles.customerInformation || isContractUpdating}
            isStatic={selectedSection !== sectionTitles.customerInformation}
            values={setFormValues(customerFields)}
            numColumns={4}
            autoFocus
            warning={storeError ? STORE_ERROR_MESSAGE : undefined}
          />
          <NestedFormGroup
            subsectionTitle={sectionTitles.billingInformation}
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={selectedSection !== sectionTitles.customerInformation || isContractUpdating}
            isStatic={selectedSection !== sectionTitles.customerInformation}
            values={setFormValues(billingInformationFields)}
            numColumns={9}
            autoFocus
          />
          <NestedFormGroup
            subsectionTitle={sectionTitles.shippingInformation}
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={selectedSection !== sectionTitles.customerInformation}
            isStatic={selectedSection !== sectionTitles.customerInformation}
            values={setFormValues(shippingInformationFields)}
            numColumns={9}
            autoFocus
          />
        </CollapsibleFormGroupEditWrapper>
        <CollapsibleFormGroupEditWrapper
          dataCy="transaction-section"
          handleSubmit={handleFormSave}
          handleCancel={handleCancel}
          handleClickEdit={handleClickEdit}
          isSubmitDisabled={hasErrors || !dirty}
          isEditable
          isEditing={selectedSection === sectionTitles.transactionInformation}
          isSubmitting={isContractUpdating}
          title={sectionTitles.transactionInformation}
          shouldStartExpanded
        >
          <NestedFormGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={
              selectedSection !== sectionTitles.transactionInformation || isContractUpdating
            }
            isStatic={selectedSection !== sectionTitles.transactionInformation}
            numColumns={4}
            values={setFormValues(transactionInformationFieldsSP)}
            autoFocus
          />
        </CollapsibleFormGroupEditWrapper>
        <CollapsibleFormGroupEditWrapper
          dataCy="charitable-donation-section"
          handleSubmit={handleFormSave}
          handleCancel={handleCancel}
          handleClickEdit={handleClickEdit}
          isSubmitDisabled={hasErrors || !dirty}
          isEditable
          isEditing={selectedSection === sectionTitles.charitableDonations}
          isSubmitting={isContractUpdating}
          title={sectionTitles.charitableDonations}
          shouldStartExpanded
        >
          <NestedFormGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={selectedSection !== sectionTitles.charitableDonations || isContractUpdating}
            isStatic={selectedSection !== sectionTitles.charitableDonations}
            numColumns={4}
            values={setFormValues(charitableDonationFields)}
            autoFocus
          />
        </CollapsibleFormGroupEditWrapper>
        <CollapsibleFormGroupEditWrapper
          dataCy="store-section"
          handleSubmit={handleFormSave}
          handleCancel={handleCancel}
          handleClickEdit={handleClickEdit}
          isSubmitting={isContractUpdating}
          title={sectionTitles.storeInformation}
          isSubmitDisabled={hasErrors || !dirty}
          isEditing={selectedSection === sectionTitles.storeInformation}
          shouldStartExpanded
        >
          <NestedFormGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={selectedSection !== sectionTitles.storeInformation || isContractUpdating}
            isStatic={selectedSection !== sectionTitles.storeInformation}
            numColumns={3}
            sectionError={Boolean(storeError)}
            errorMessage={STORE_ERROR_MESSAGE}
            values={setFormValues(storeInformationFields)}
            autoFocus
          />
        </CollapsibleFormGroupEditWrapper>
        <CollapsibleFormGroupEditWrapper
          dataCy="contract-section"
          handleSubmit={handleFormSave}
          handleCancel={handleCancel}
          handleClickEdit={handleClickEdit}
          isSubmitDisabled={hasErrors || !dirty}
          isEditable
          isEditing={selectedSection === sectionTitles.contractInformation}
          isSubmitting={isContractUpdating}
          title={sectionTitles.contractInformation}
          shouldStartExpanded
        >
          <NestedFormGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={selectedSection !== sectionTitles.contractInformation}
            numColumns={4}
            setFieldValue={setFieldValue}
            isStatic={selectedSection !== sectionTitles.contractInformation}
            autoFocus
            values={setFormValues(contractInformationFieldsSP)}
          />
        </CollapsibleFormGroupEditWrapper>
        {contract?.productsList?.length && (
          <CollapsibleFormGroupEditWrapper
            dataCy="products-and-shiping-section"
            handleSubmit={handleFormSave}
            handleCancel={handleCancel}
            handleClickEdit={handleClickEdit}
            isSubmitting={isContractUpdating}
            title={sectionTitles.productAndShippingInformation}
            isSubmitDisabled={hasErrors || !dirty}
            isEditing={selectedSection === sectionTitles.productAndShippingInformation}
            shouldStartExpanded
          >
            <ProductShippingInformation
              values={productAndShippingValues}
              contract={contract as ShippingProtectionContract}
              productsWithoutTrackingInfo={productsWithoutTrackingInfo}
            />
          </CollapsibleFormGroupEditWrapper>
        )}
      </LeftContainer>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  gap: 40,
  alignItems: 'flex-start',
})
const LeftContainer = styled.div({
  width: '100%',
})

const NestedFormGroup = styled(CollapsibleFormTextGroup)({
  border: 'none',
  padding: 0,
  margin: 0,
})

export { ShippingProtection }
