import type { FC } from 'react'
import React from 'react'
import type { StoreIntegrationSettings } from '@helloextend/extend-api-client'
import { DataProperty, DataPropertyType } from '@extend/zen'
import { SectionContainer } from '../../../../../../../../components/section-container'

type IntegrationSettingsProps = {
  integrationConfig: StoreIntegrationSettings
}

const BasicInfo: FC<IntegrationSettingsProps> = ({ integrationConfig }) => {
  return (
    <SectionContainer title="Basic Info">
      <DataProperty
        type={DataPropertyType.string}
        label="Platform Store ID"
        value={integrationConfig.platformStoreKey}
        helperText="An unique store identifier from a platform (e.g. storeKey in Shopify, storeHash in BigCommerce)"
        data-cy="platform-store-id-data"
      />
      <DataProperty
        type={DataPropertyType.string}
        label="Platform"
        value={integrationConfig.platform}
        data-cy="platform-data"
      />
      {integrationConfig.platform === 'magento' && (
        <DataProperty
          label="Platform Note"
          value="Magento merchants control some of these settings in the Magento admin."
        />
      )}
    </SectionContainer>
  )
}

export { BasicInfo }
