import * as Yup from 'yup'
import { ContractType } from '@helloextend/extend-api-client'
import type {
  Plan,
  Product,
  EntitlementsResponse,
  CategoryContract,
  Contract20220201GetResponse,
  Store,
} from '@helloextend/extend-api-client'
import { date, validate } from '@extend/client-helpers'

const PHONE_NUMBER_MIN_LENGTH_USA = 10
const phoneNumberValidation = Yup.string().test(
  'phone-number-validation',
  'Please enter a valid phone number',
  (value): boolean => {
    return !value || validate.validateIsNumberEntered(value, PHONE_NUMBER_MIN_LENGTH_USA)
  },
)

const storeInformationSchema = Yup.object()
  .shape({
    storeName: Yup.string().required('Store name is required').default(''),
    storeId: Yup.string().required('Store ID is required').default(''),
    customerSupportLine: Yup.string(),
    customerSupportEmail: Yup.string(),
    escalationContact: Yup.string(),
    escalationNumber: Yup.string(),
    extension: Yup.string(),
    storeEmail: Yup.string(),
  })
  .defined()

const contractInformationSchema = Yup.object()
  .shape({
    contractId: Yup.string().required('Contract ID is required').default(''),
    type: Yup.string().required().default(''),
    isTest: Yup.boolean(),
    contractStatus: Yup.string().required('Contract Status is required').default(''),
    planName: Yup.string(),
    planServiceType: Yup.string(),
    planCategory: Yup.string(),
    planCoverageIncludes: Yup.string(),
    planId: Yup.string().required('Plan ID is required').default(''),
    planWarrantySku: Yup.string(),
    planVersion: Yup.number(),
    termsId: Yup.string(),
    termVersion: Yup.string(),
    planTermsUrl: Yup.string(),
    planPurchasePrice: Yup.number().required('Plan purchase price is required').nullable().default(null),
    planListPrice: Yup.number(),
    createdDate: Yup.string().required().default(''),
    planPurchaseDate: Yup.string(),
    transactionDate: Yup.number(),
    canceledDate: Yup.string(),
    refundedDate: Yup.string(),
    updatedDate: Yup.string(),
    coverageStartDate: Yup.string(),
    coverageEndDate: Yup.string(),
    limitOfLiabilityAmountType: Yup.string(),
    limitOfLiabilityAmount: Yup.number(),
    remainingCoverageAmount: Yup.number(),
    taxCostTotal: Yup.number(),
    shippingCostTotal: Yup.number(),
    productsList: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
          referenceId: Yup.string(),
          serialNumber: Yup.string(),
          purchasePrice: Yup.number().required('Purchase price is required'),
          listPrice: Yup.number(),
          imageUrl: Yup.string(),
          manufacturerWarrantyLengthLabor: Yup.number(),
          manufacturerWarrantyLengthParts: Yup.number(),
        }),
      )

      .default(undefined),
  })
  .defined()

const productInformationSchema = Yup.object()
  .shape({
    referenceId: Yup.string().required('Reference ID is').default(''),
    serialNumber: Yup.string(),
    productPurchasePrice: Yup.number().required('Purchase price is required').nullable().default(null),
    productName: Yup.string().required('Product name is required').default(''),
    productListPrice: Yup.number().required('List price is required').default(1),
    productPurchaseDate: Yup.string().required('Product purchase date is required').default(''),
    imageUrl: Yup.string(),
    brand: Yup.string(),
    sku: Yup.string(),
    productTransactionId: Yup.string().required('Product transaction ID is required').default(''),
    mfrsWarrantyLengthLabor: Yup.number().required('Mfrs warranty length (labor) is required').nullable().default(null),
    mfrsWarrantyLengthParts: Yup.number().required('Mfrs warranty length (parts) is required').nullable().default(null),
  })
  .required()

const customerInformationSchema = Yup.object()
  .shape({
    fullName: Yup.string().required('Full name is required').default(''),
    phoneNumber: phoneNumberValidation,
    customerEmail: Yup.string()
      .required('Email is required')
      .default('')
      .max(100, 'Please enter a valid email address')
      .email('Please enter a valid email address'),
    merchantCustomerId: Yup.string(),
    billingAddress: Yup.string().required('Address is required').default(''),
    billingAddressTwo: Yup.string(),
    billingCity: Yup.string().required('City is required').default(''),
    billingProvinceCode: Yup.string().required('Province code is required').default(''),
    billingPostalCode: Yup.string().required('Postal code is required').default(''),
    billingCountryCode: Yup.string().required('Country code is required').default(''),
    shippingAddress: Yup.string().required('Address is required').default(''),
    shippingAddressTwo: Yup.string(),
    shippingCity: Yup.string().required('City is required').default(''),
    shippingProvinceCode: Yup.string().required('Province code is required').default(''),
    shippingPostalCode: Yup.string().required('Postal code is required').default(''),
    shippingCountryCode: Yup.string().required('Country code is required').default(''),
  })
  .defined()

const transactionInformationSchema = Yup.object()
  .shape({
    transactionId: Yup.string().required('Transaction ID is required').default(''),
    currencyCode: Yup.string().required('Currency code is required').default(''),
    poNumber: Yup.string(),
    orderId: Yup.string(),
    orderUrl: Yup.string(),
    quoteId: Yup.string(),
  })
  .defined()

const charitableDonationSchema = Yup.object()
  .shape({
    charityId: Yup.string().required('Charity ID is required').default(''),
    charityName: Yup.string(),
    charityDonationAmount: Yup.number().required('Donation amount is required').default(1),
  })
  .defined()

export const mapContract20220201ToValues = (
  incomingContract: Contract20220201GetResponse | CategoryContract,
  store: Store | null,
  planTermsUrl?: string,
  orderUrl?: string,
  plan?: Plan | null,
  product?: Product | null,
  entitlements?: EntitlementsResponse | null,
): V2Values => {
  if (!incomingContract)
    return {
      ...customerInformationSchema.getDefault(),
      ...transactionInformationSchema.getDefault(),
      ...charitableDonationSchema.getDefault(),
      ...storeInformationSchema.getDefault(),
      ...contractInformationSchema.getDefault(),
      ...productInformationSchema.getDefault(),
    }

  const categoryContract = incomingContract as CategoryContract
  const contractType = categoryContract.type

  if (ContractType.CATEGORY === contractType) {
    return {
      // customerInformationSchema
      fullName: categoryContract.customer.name,
      phoneNumber: categoryContract.customer.phone,
      customerEmail: categoryContract.customer.email,
      merchantCustomerId: categoryContract?.merchantCustomerId,
      billingAddress: categoryContract.customer.billingAddress?.address1 ?? '',
      billingAddressTwo: categoryContract.customer.billingAddress?.address2,
      billingCity: categoryContract.customer.billingAddress?.city ?? '',
      billingProvinceCode: categoryContract.customer.billingAddress?.provinceCode ?? '',
      billingPostalCode: categoryContract.customer.billingAddress?.postalCode ?? '',
      billingCountryCode: categoryContract.customer.billingAddress?.countryCode ?? '',
      shippingAddress: categoryContract.customer.shippingAddress?.address1 ?? '',
      shippingAddressTwo: categoryContract.customer.shippingAddress?.address2,
      shippingCity: categoryContract.customer.shippingAddress?.city ?? '',
      shippingProvinceCode: categoryContract.customer.shippingAddress?.provinceCode ?? '',
      shippingPostalCode: categoryContract.customer.shippingAddress?.postalCode ?? '',
      shippingCountryCode: categoryContract.customer.shippingAddress?.countryCode ?? '',
      // transactionInformationSchema
      transactionId: categoryContract.transactionId,
      currencyCode: categoryContract.purchaseCurrency,
      orderId: categoryContract.orderId ?? '',
      orderUrl,
      quoteId: categoryContract.quoteId ?? '',
      // storeInformationSchema
      storeName: categoryContract.sellerName ?? '',
      storeId: categoryContract.sellerId,
      customerSupportLine: store?.supportContact?.phoneNumber?.number,
      customerSupportEmail: store?.supportContact?.email,
      escalationContact: store?.escalationContact?.contactName,
      escalationNumber: store?.escalationContact?.phoneNumber?.number,
      extension: store?.escalationContact?.phoneNumber?.extension,
      storeEmail: store?.escalationContact?.email,
      // contractInformationSchema
      contractId: categoryContract.id,
      type: categoryContract.type,
      isTest: categoryContract.isTest,
      contractStatus: categoryContract.status,
      planId: categoryContract.plan.id ?? '',
      planName: plan?.name ?? '',
      planServiceType: plan?.contract?.service_type ?? '',
      planCategory: plan?.contract?.category ?? '',
      planCoverageIncludes: plan?.contract?.coverage_includes ?? '',
      planWarrantySku: categoryContract.plan.skuId || categoryContract.plan.skuId || '',
      planVersion: categoryContract.plan.version,
      termsId: categoryContract.plan.termsId,
      termVersion: categoryContract.plan.termsVersion,
      planTermsUrl,
      planPurchasePrice: categoryContract.purchasePrice,
      planListPrice: categoryContract.listPrice || categoryContract.expectedPrice,
      createdDate: date.format(Number(categoryContract.createdAt), 'MMM DD YYYY'),
      planPurchaseDate: categoryContract.purchaseDate
        ? date.format(Number(categoryContract.purchaseDate), 'MMM DD YYYY')
        : '',
      refundedDate: categoryContract.refundedAt
        ? date.format(Number(categoryContract.refundedAt), 'MMM DD YYYY')
        : '',
      updatedDate: date.format(Number(categoryContract.updatedAt), 'MMM DD YYYY'),
      coverageStartDate: categoryContract.coverage
        ? date.format(Number(categoryContract.coverage?.starts), 'MMM DD YYYY')
        : '',
      coverageEndDate: categoryContract.coverage
        ? date.format(Number(categoryContract.coverage?.ends), 'MMM DD YYYY')
        : '',
      // productInformationSchema
      brand: product?.brand ?? '',
      sku: product?.identifiers?.sku ?? '',
      limitOfLiabilityAmount: categoryContract?.limitOfLiabilityAmount,
      limitOfLiabilityAmountType: categoryContract?.limitOfLiabilityAmountType,
      remainingCoverageAmount: entitlements?.coverageAmountRemaining?.amount ?? 0,
      productsList: categoryContract?.productsList,
    } as V2Values
  }

  const contract = incomingContract as Contract20220201GetResponse & {
    taxCostTotal?: number
    shippingCostTotal?: number
  }

  return {
    // customerInformationSchema
    fullName: contract.customer.name,
    phoneNumber: contract.customer.phone,
    customerEmail: contract.customer.email,
    merchantCustomerId: contract?.merchantCustomerId,
    billingAddress: contract.customer.billingAddress?.address1 ?? '',
    billingAddressTwo: contract.customer.billingAddress?.address2,
    billingCity: contract.customer.billingAddress?.city ?? '',
    billingProvinceCode: contract.customer.billingAddress?.provinceCode ?? '',
    billingPostalCode: contract.customer.billingAddress?.postalCode ?? '',
    billingCountryCode: contract.customer.billingAddress?.countryCode ?? '',
    shippingAddress: contract.customer.shippingAddress?.address1 ?? '',
    shippingAddressTwo: contract.customer.shippingAddress?.address2,
    shippingCity: contract.customer.shippingAddress?.city ?? '',
    shippingProvinceCode: contract.customer.shippingAddress?.provinceCode ?? '',
    shippingPostalCode: contract.customer.shippingAddress?.postalCode ?? '',
    shippingCountryCode: contract.customer.shippingAddress?.countryCode ?? '',
    // transactionInformationSchema
    transactionId: contract.transactionId,
    currencyCode: contract.purchaseCurrency,
    poNumber: contract.poNumber ?? '',
    orderId: contract.orderId ?? '',
    orderUrl,
    quoteId: contract.quoteId ?? '',
    // storeInformationSchema
    storeName: contract.sellerName ?? '',
    storeId: contract.sellerId,
    customerSupportLine: store?.supportContact?.phoneNumber?.number,
    customerSupportEmail: store?.supportContact?.email,
    escalationContact: store?.escalationContact?.contactName,
    escalationNumber: store?.escalationContact?.phoneNumber?.number,
    extension: store?.escalationContact?.phoneNumber?.extension,
    storeEmail: store?.escalationContact?.email,
    // contractInformationSchema
    contractId: contract.id,
    type: contract.type,
    isTest: contract.isTest,
    contractStatus: contract.status,
    planId: contract.plan.id ?? '',
    planName: plan?.name ?? '',
    planServiceType: plan?.contract?.service_type ?? '',
    planCategory: plan?.contract?.category ?? '',
    planCoverageIncludes: plan?.contract?.coverage_includes ?? '',
    planWarrantySku: contract.plan.skuId || contract.plan.skuId || '',
    planVersion: contract.plan.version,
    termsId: contract.plan.termsId,
    termVersion: contract.plan.termsVersion,
    planTermsUrl,
    planPurchasePrice: contract.purchasePrice,
    planListPrice: contract.listPrice || contract.expectedPrice,
    createdDate: date.format(Number(contract.createdAt), 'MMM DD YYYY'),
    planPurchaseDate: contract.purchaseDate
      ? date.format(Number(contract.purchaseDate), 'MMM DD YYYY')
      : '',
    canceledDate: contract.cancelledAt
      ? date.format(Number(contract.cancelledAt), 'MMM DD YYYY')
      : '',
    refundedDate: contract.refundedAt
      ? date.format(Number(contract.refundedAt), 'MMM DD YYYY')
      : '',
    updatedDate: date.format(Number(contract.updatedAt), 'MMM DD YYYY'),
    coverageStartDate: contract.coverage
      ? date.format(Number(contract.coverage?.starts), 'MMM DD YYYY')
      : '',
    coverageEndDate: contract.coverage
      ? date.format(Number(contract.coverage?.ends), 'MMM DD YYYY')
      : '',
    // productInformationSchema
    referenceId: contract.product?.referenceId,
    serialNumber: contract.product?.serialNumber,
    productPurchasePrice: contract.product?.purchasePrice,
    productName: contract.product?.name,
    productListPrice: contract.product?.listPrice,
    productPurchaseDate: contract.product?.purchaseDate
      ? date.format(Number(contract.product.purchaseDate), 'MMM DD YYYY')
      : '',
    imageUrl: contract.product?.imageUrl,
    brand: product?.brand ?? '',
    sku: product?.identifiers?.sku ?? '',
    productTransactionId: contract.product?.transactionId,
    mfrsWarrantyLengthLabor: contract.product?.manufacturerWarrantyLengthLabor,
    mfrsWarrantyLengthParts: contract.product?.manufacturerWarrantyLengthParts,
    limitOfLiabilityAmount: contract?.limitOfLiabilityAmount,
    limitOfLiabilityAmountType: contract?.limitOfLiabilityAmountType,
    remainingCoverageAmount: entitlements?.coverageAmountRemaining?.amount ?? 0,
    taxCostTotal: contract?.taxCostTotal ?? 0,
    shippingCostTotal: contract?.shippingCostTotal ?? 0,
    charityId: contract.charitableDonation?.id,
    charityName: contract.charitableDonation?.name,
    charityDonationAmount: contract.charitableDonation?.donationAmount,
  } as V2Values
}

type V2Values = Yup.InferType<typeof customerInformationSchema> &
  Yup.InferType<typeof transactionInformationSchema> &
  Yup.InferType<typeof charitableDonationSchema> &
  Yup.InferType<typeof storeInformationSchema> &
  Yup.InferType<typeof contractInformationSchema> &
  Yup.InferType<typeof productInformationSchema>

const V2Schema = new Map()
V2Schema.set('Customer Information', customerInformationSchema)
V2Schema.set('Transaction Information', transactionInformationSchema)
V2Schema.set('Charitable Donations', charitableDonationSchema)
V2Schema.set('Store Information', storeInformationSchema)
V2Schema.set('Contract Information', contractInformationSchema)
V2Schema.set('Product Information', productInformationSchema)

export type { V2Values }
export { V2Schema }
