import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import { Breadcrumbs, COLOR, Spinner, useToaster, ToastColor, ToastDuration } from '@extend/zen'
import { useGetUserDetailsQuery } from '@helloextend/extend-api-rtk-query'

import { UserDetailsForm } from './user-details-form'
import { UserDetailsToastErrorText } from './user-details-toast-error-text'
import UserDetailsHeader from './user-details-header'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'

const UserDetails: FC = () => {
  const { userId } = useParams<{ userId: string }>()

  const { toast } = useToaster()
  const { hasPermission } = usePermissions()

  const {
    data: user,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetUserDetailsQuery(userId, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (isError) {
      toast({
        message: <UserDetailsToastErrorText />,
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.long,
      })
    }
  }, [isError, toast])

  if (isLoading || isFetching || !isSuccess || !user) {
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  return (
    <>
      {/* Breadcrumbs */}
      <BreadCrumbContainer>
        <Breadcrumbs
          crumbs={[
            {
              text: 'User Management',
              to: '/admin/user-management',
            },
            {
              text: `${user.firstName} ${user.lastName}`,
            },
          ]}
          data-cy="breadcrumb"
        />
      </BreadCrumbContainer>
      {/* Breadcrumbs */}

      {/* User details header */}
      <UserDetailsHeader user={user} canEditUser={hasPermission(Permission.ManageExtendUsers)} />
      {/* User details header */}

      {/* User details form */}
      <UserDetailsForm user={user} canEditUser={hasPermission(Permission.ManageExtendUsers)} />
      {/* User details form */}
    </>
  )
}

const BreadCrumbContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexFlow: 'row nowrap',
})

const SpinnerContainer = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexWrap: 'wrap',
  padding: 24,
})

export { UserDetails }
