import type { FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import type { SelectInstance, SingleValue } from 'react-select'
import { COLOR } from '@extend/zen'
import {
  getDetails,
  getPlacePredictions,
  initServices,
  getFullAddress,
  getNewOptions,
} from '@helloextend/google-api'
import type { AutocompletePrediction, OptionType } from '@helloextend/google-api'
import { customLogger } from '@extend/client-helpers'
import styled from '@emotion/styled'
import type { Address } from '@helloextend/extend-api-client'
import { customSelectStyles } from './styles'

interface AddressSearchProps {
  label?: string
  errorMessage?: string
  onChange: (fields: Address) => void
  value?: string
}

const AddressSearch: FC<AddressSearchProps> = ({ label, errorMessage, onChange, value }) => {
  const [isMapsServicesLoaded, setIsMapsServicesLoaded] = useState<boolean>(false)
  const [addressInput, setAddressInput] = useState<string>()
  const [selectedOption, setSelectedOption] = useState<OptionType>()
  const [isFocused, setIsFocused] = useState(false)
  const [options, setOptions] = useState<OptionType[]>([])

  const reactSelectRef = useRef<SelectInstance<OptionType>>(null)

  useEffect(() => {
    if (!isMapsServicesLoaded) {
      const initGoogleApi = async (): Promise<void> => {
        await initServices(true)
        setIsMapsServicesLoaded(true)
      }
      initGoogleApi()
    }
    if (options.length && reactSelectRef?.current) {
      reactSelectRef.current.focusOption('last')
    }
  }, [isMapsServicesLoaded, options])

  const displaySuggestions = (predictions: AutocompletePrediction[] | null): void => {
    if (!predictions?.length) {
      setOptions([])
      return
    }

    setOptions(getNewOptions(predictions))
  }

  const handleInputChange = (inputValue: string): void => {
    setAddressInput(inputValue)
    if (!inputValue) {
      setOptions([])
      return
    }
    if (!isMapsServicesLoaded) return
    getPlacePredictions({ input: inputValue, types: ['address'] }, displaySuggestions)
  }

  const handleOptionChange = (option: SingleValue<OptionType>): void => {
    if (!option) return
    getDetails({ placeId: option.value, fields: ['address_component'] }, (result) => {
      if (!result?.address_components?.length) {
        customLogger.error(`Error occurred during Google Maps attempt to get address details`, {
          timestamp: Date.now(),
          errorMessage: `No address details returned for given Google Maps Prediction: ${JSON.stringify(
            { address: option.label, placeId: option.value },
          )}. Google Maps Result: ${JSON.stringify(result)}`,
        })
        return
      }
      setSelectedOption(option)
      const address = getFullAddress(result.address_components)
      onChange(address)
    })
  }

  const handleFocus = (): void => {
    setIsFocused(true)
  }

  const handleBlur = (): void => {
    setIsFocused(false)
  }

  const getValue = (): OptionType | undefined => {
    if (isFocused) return { value: '', label: '' }
    return value ? { value, label: value } : selectedOption
  }

  return (
    <FieldWrapper>
      {!!label && <FieldHeader>{Label && <Label htmlFor={label}>{label}</Label>}</FieldHeader>}
      <Select
        ref={reactSelectRef}
        styles={customSelectStyles(Boolean(errorMessage))}
        value={getValue()}
        placeholder=""
        onChange={handleOptionChange}
        onInputChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        options={options}
        menuPlacement="top"
        menuPortalTarget={document.body}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        menuIsOpen={!!addressInput}
      />
      {Boolean(errorMessage) && <ErrorMessage>{errorMessage || ''}</ErrorMessage>}
    </FieldWrapper>
  )
}

const FieldWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})

const FieldHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
})

const Label = styled.label({
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 800,
  color: COLOR.NEUTRAL[1000],
})

const ErrorMessage = styled.div({
  color: COLOR.RED[700],
  fontFamily: 'Nunito Sans',
  fontSize: 12,
  lineHeight: '16px',
  marginBottom: 16,
  marginTop: 8,
})

export { AddressSearch }
export type { AddressSearchProps }
