import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from '@helloextend/client-hooks'
import styled from '@emotion/styled'
import { useExtendAuth } from '@extend/package-okta-login'
import {
  useGetContract20220201Query,
  useListInsuranceClaimsQuery,
} from '@helloextend/extend-api-rtk-query'
import type { ContractNote } from '@helloextend/extend-api-client';
import { ClaimSelectStatus } from '@helloextend/extend-api-client'
import { COLOR, InlineAlert, InlineAlertColor, Warning } from '@extend/zen'
import { contracts as contractsActions } from '../../../actions'
import { TabBar } from '../../../components/tab-bar'
import type { RootState } from '../../../reducers'
import * as selectors from '../../../reducers/selectors'
import { DataTable } from '../../../components/data-table'
import { auditColumns } from './table-config'
import { toTableAuditItems } from './to-table-audit-item'
import { EmptyMessage } from '../../../components/data-table/empty-message'
import { ChangesRow } from './changes-row'
import type { TableContractsAuditItem } from '../../../types/contracts'
import { ContractNotes } from '../contract/components/contract-notes/contract-notes'
import { contractNotesEscalationDescription } from 'src/lib/escalation-levels'

const ContractAuditLog: FC = () => {
  const { contractId } = useParams<{ contractId: string }>()
  const { data: contract } = useGetContract20220201Query({
    contractId,
  })
  const dispatch = useDispatch()
  const { token: accessToken } = useExtendAuth()
  const isLoading = useSelector((state: RootState) => selectors.getIsContractLoading(state))
  const auditLog = useSelector((state: RootState) => selectors.getContractAuditHistory(state))
  const nextPageCursor = useSelector((state: RootState) =>
    selectors.getContractAuditHistoryCursor(state),
  )
  const hasPrevPage = useSelector((state: RootState) =>
    selectors.getHasContractAuditHistoryPrevPage(state),
  )
  const lastFetchedPage = useSelector((state: RootState) =>
    selectors.getContractAuditHistoryLastFetchedPage(state),
  )
  const page = useSelector((state: RootState) =>
    selectors.getContractAuditHistoryCurrentPage(state),
  )

  const auditTableItems = toTableAuditItems(auditLog)
  const prevLoading = usePrevious(isLoading)
  const [loaded, setLoaded] = useState(false)
  const [escalationNote, setEscalationNote] = useState<ContractNote | undefined>(undefined)

  const handleEscalationNote = useCallback((note: ContractNote) => {
    setEscalationNote(note)
  }, [])

  useEffect(() => {
    if (!isLoading && prevLoading) {
      setLoaded(true)
    }
  }, [isLoading, prevLoading])

  useEffect(() => {
    if (accessToken && !loaded) {
      dispatch(contractsActions.fetchAuditHistory(accessToken, contractId))
    }
  }, [dispatch, accessToken, contractId, loaded])

  const queryParams = useMemo(() => {
    const matchesClaimStatus = [
      ClaimSelectStatus.review,
      ClaimSelectStatus.approved,
      ClaimSelectStatus.denied,
      ClaimSelectStatus.fulfilled,
      ClaimSelectStatus.closed,
    ]

    return {
      searchVersion: '2',
      containsContractId: contractId,
      matchesClaimStatus,
    }
  }, [contractId])

  const { data: claimsData } = useListInsuranceClaimsQuery(queryParams, {
    refetchOnMountOrArgChange: true,
    skip: !contractId,
  })

  const claims = claimsData?.items?.length ? claimsData.items : []

  const getPrevPage = (): void => {
    dispatch(contractsActions.prevAuditPage())
  }

  const getNextPage = (): void => {
    if (accessToken && nextPageCursor && page === lastFetchedPage) {
      dispatch(contractsActions.nextAuditPage(accessToken, contractId, nextPageCursor))
    } else {
      dispatch(contractsActions.incrementNextAuditPage())
    }
  }
  return (
    <>
      <Header>
        <Title data-cy="contract-id-header-audit-log">Contract ID: {contractId}</Title>
        {escalationNote?.escalationLevel && (
          <AlertContainer>
            <InlineAlert color={InlineAlertColor.yellow} icon={Warning}>
              {contractNotesEscalationDescription[escalationNote.escalationLevel]}
            </InlineAlert>
          </AlertContainer>
        )}
        <StyledLink to="/admin/contracts" data-cy="back-to-search-btn">
          Back to search results
        </StyledLink>
      </Header>
      <TabBar
        tabBarLinks={[
          { to: `/admin/contracts/${contractId}`, text: 'Contract Details' },
          {
            to: `/admin/contracts/${contractId}/claims`,
            text: 'Claims',
            tabBadge:
              (claims?.length && {
                color: 'blue',
                text: String(claims?.length),
              }) ||
              undefined,
          },
          { to: `/admin/contracts/${contractId}/auditlog`, text: 'Audit Log' },
        ]}
      />
      <DataTable
        isLoading={isLoading}
        data={auditTableItems}
        columns={auditColumns}
        paginationType="events"
        currPage={page}
        onPrevPage={getPrevPage}
        onNextPage={getNextPage}
        hasCollapsibleRow
        collapsibleRowAccessor="changes"
        CollapsibleRow={({ data }) => (
          <ChangesRow data={data as TableContractsAuditItem['changes']} offsetColumn={1} />
        )}
        hasNext={Boolean(nextPageCursor) || page < lastFetchedPage}
        hasPrev={hasPrevPage}
      />
      {loaded && auditLog.length === 0 && (
        <EmptyMessage
          header="No changes have been recorded for this contract"
          message="When this contract is edited by a user or an automated process, changes will appear here"
        />
      )}
      {contract && (
        <ContractNotes contract={contract} handleEscalationNote={handleEscalationNote} />
      )}
    </>
  )
}

const Header = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 32,
})

const StyledLink = styled(Link)({
  color: COLOR.BLUE[800],
  fontSize: 16,
  lineHeight: '24px',
})

const Title = styled.h1({
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
  marginTop: 0,
  marginBottom: 4,
})

const AlertContainer = styled.div({
  width: '100%',
  marginBottom: 8,
})

export { ContractAuditLog }
