import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

type ListItemIconProps = {
  width?: number
  height?: number
  active?: boolean
}

const ListItemIcon: FC<ListItemIconProps> = ({
  children,
  width = 12,
  height = 12,
  active = false,
}) => {
  return (
    <Figure width={width} height={height} active={active}>
      {children}
    </Figure>
  )
}

const Figure = styled.figure<{ width: number; height: number; active: boolean }>(
  ({ width, height, active }) => ({
    alignItems: 'center',
    display: 'inline-flex',
    height,
    margin: 0,
    width,
    background: active ? COLOR.BLUE[800] : 'initial',
    color: active ? COLOR.WHITE : 'initial',
    padding: 6,
    borderRadius: 4,
    transition: 'background-color .2s ease-in',
    '> svg': {
      path: {
        ...(active && { fill: COLOR.WHITE }),
        transition: 'fill .2s ease-in',
      },
    },
  }),
)

export { ListItemIcon }
