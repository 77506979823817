import type {
  DefaultMessage,
  Message,
  ThreadResponse,
  DefaultReply,
  ScriptItem,
  Reply,
  Option,
  PromptOption,
  RuleCreate,
  RulesetBase,
  NonNumericConditionWithScript,
  DefaultPrompt,
} from '@helloextend/extend-api-rtk-query'
import { isUUID, identifyCondition } from '../../pages/admin/adjudication-management/utils'
import type { Conversation, MessageBlockReferenceMap } from '../../types/conversations'

export const getIsMessageTextType = (message: Message): boolean => {
  return /^text$|^textSelect$/.test((message as DefaultMessage)?.type)
}

export interface ReusableThreadsTypeCount {
  [key: string]: number
}

export interface PublishValidationDetails {
  isConversationEmpty?: boolean
  hasDuplicateThreadTypes?: boolean
  reusableThreadsTypeCounts?: ReusableThreadsTypeCount
  isAnyMessageBlockOrphaned?: boolean
  isEveryJumpToAssigned?: boolean
}

export interface RemoveMessageBlockReferenceCountChangeSet {
  updates?: Array<[string, number]>
  deletes: string[]
}

export const isConversationStructureValid = (
  conversation: Conversation,
): [boolean, PublishValidationDetails] => {
  const isConversationEmpty =
    conversation.singleUseThreads?.length === 0 && conversation.reusableThreadsTypes?.length === 0
  const reusableThreadsTypeCounts: ReusableThreadsTypeCount | undefined =
    conversation.reusableThreadsTypes?.reduce((acc: ReusableThreadsTypeCount, current) => {
      if (!acc[current]) {
        acc[current] = 0
      }
      acc[current] += 1
      return acc
    }, {})

  const hasDuplicateThreadTypes =
    reusableThreadsTypeCounts && Object.values(reusableThreadsTypeCounts).some((count) => count > 1)

  // we should not consider first message blocks in single-use threads. They are allowed to be unreferenced for the structure to be valid.
  const isAnyMessageBlockOrphaned =
    conversation.messageBlockReferenceMap &&
    Object.entries(conversation.messageBlockReferenceMap).some(([, counts]) =>
      counts.some((count, index) => index !== 0 && count === 0),
    )

  // Does any single_use thread have a message block with an empty Jump To value
  const isEveryJumpToAssigned = Boolean(
    conversation.singleUseThreads &&
      conversation.singleUseThreads.every((thread) =>
        thread.script.every((scriptItem) =>
          scriptItem.collect.options.every((option) => isJumpToAssignedToValidRoute(option)),
        ),
      ),
  )

  // Return a tuple of [boolean, PublishValidationDetails]
  return [
    !isConversationEmpty &&
      !hasDuplicateThreadTypes &&
      !isAnyMessageBlockOrphaned &&
      isEveryJumpToAssigned,
    {
      isConversationEmpty,
      hasDuplicateThreadTypes,
      reusableThreadsTypeCounts,
      isAnyMessageBlockOrphaned,
      isEveryJumpToAssigned,
    },
  ]
}

export const isThreadTextAssigned = (thread: ThreadResponse | null): boolean => {
  if (!thread) return false
  return thread.script?.every((script) => isMessageBlockTextAssigned(script))
}

export const getUnreferencedMessageBlocks = (thread: ThreadResponse | null): ScriptItem[] => {
  const difference = (setA: Set<number>, setB: Set<number>): Set<number> => {
    const diff = new Set(setA)

    for (const elem of setB) {
      diff.delete(elem)
    }

    return diff
  }

  if (!thread) {
    return []
  }

  const assignedScriptIndices = new Set(
    thread.script.flatMap((script) => {
      return script.collect.options.map((option) => option.execute?.scriptIndex ?? -1)
    }),
  )

  const allScriptIndices = new Set(thread.script.map((_script, index) => index))
  const unreferencedScriptIndices = [...difference(allScriptIndices, assignedScriptIndices)]

  return thread.script.filter((_script, index) => unreferencedScriptIndices.includes(index))
}

export const isDefaultReply = (reply: Reply | undefined): reply is DefaultReply => {
  if (reply) {
    return 'messages' in reply
  }
  return false
}

export const getDefaultReply = (scriptItem: ScriptItem): DefaultReply | undefined => {
  return isDefaultReply(scriptItem.reply) ? (scriptItem.reply as DefaultReply) : undefined
}

export const hasKaleyMessages = (defaultReply: DefaultReply): boolean => {
  return defaultReply.messages !== undefined
}

// we only consider default messages(which have content & type === text/textSelect)
export const isEachKaleyTextAssigned = (defaultReply: DefaultReply): boolean => {
  return hasKaleyMessages(defaultReply)
    ? defaultReply.messages.every((message) =>
        // Regex is testing that the message type is either 'text' or 'textSelect' to perform a content length check
        getIsMessageTextType(message) ? (message as DefaultMessage).content.length > 0 : true,
      )
    : true
}

// we perform the every check if the prompt type is 'multiselect' or 'buttons', otherwise return true
export const isEachChoiceTextAssigned = (defaultReply: DefaultReply): boolean => {
  const prompt = defaultReply.prompt as DefaultPrompt
  if (defaultReply.prompt && !['multiselect', 'buttons'].includes(prompt.type)) return true

  return (
    (defaultReply.prompt &&
      ['multiselect', 'buttons'].includes(prompt.type) &&
      prompt.options?.every((option) => option.title.length > 0)) ??
    true
  )
}

export const isReportingValueAssigned = (option: PromptOption): boolean => {
  return !isUUID(option.value) && option.value.length > 0
}
export const isReportingValueCapableScriptItem = (scriptItem: ScriptItem): boolean => {
  const defaultReply = getDefaultReply(scriptItem)
  const prompt = defaultReply?.prompt as DefaultPrompt

  // if there are no reporting value prompts, return false
  if (!defaultReply || (defaultReply && !defaultReply.prompt)) return false
  return (prompt && prompt.type === 'multiselect') || (prompt && prompt.type === 'buttons') || false
}

export const getIsEachReportingValueAssigned = (scriptItem: ScriptItem): boolean => {
  if (!isReportingValueCapableScriptItem(scriptItem)) return true

  const defaultReply = getDefaultReply(scriptItem)
  if (!defaultReply || (defaultReply && !defaultReply.prompt)) return false
  const isEach = (defaultReply?.prompt as DefaultPrompt)?.options?.every((option) =>
    isReportingValueAssigned(option),
  )
  return isEach ?? false
}

export const isJumpToAssignedToValidRoute = (jumpTo: Option): boolean => {
  return (
    (jumpTo.action === 'execute' &&
      jumpTo.execute?.scriptIndex !== undefined &&
      jumpTo.execute?.scriptIndex >= 0) ||
    jumpTo.action === 'next' ||
    jumpTo.action === 'stop'
  )
}

// Note: yes other prompt types can have routes but only these 4 types are editable in
// the application.
export const isRoutingCapableScriptItem = (scriptItem: ScriptItem): boolean => {
  const defaultReply = getDefaultReply(scriptItem)
  const prompt = defaultReply?.prompt as DefaultPrompt
  // if there are no routings, return false
  if (!defaultReply || (defaultReply && !defaultReply.prompt)) return false

  // only consider prompt types that we support edit their routings in the application
  return (
    (prompt && prompt.type === 'datepicker') ||
    (prompt && prompt.type === 'multiselect') ||
    (prompt && prompt.type === 'buttons') ||
    (prompt && prompt.type === 'imageUpload') ||
    (prompt && prompt.type === 'input') ||
    false
  )
}

export const getIsMessageBlockRoutingsAssigned = (scriptItem: ScriptItem): boolean => {
  if (!isRoutingCapableScriptItem(scriptItem)) return true
  return scriptItem.collect.options.every((option) => isJumpToAssignedToValidRoute(option))
}

export const getIsMessageBlockTextAssigned = (scriptItem: ScriptItem): boolean => {
  const defaultReply = getDefaultReply(scriptItem)
  if (!defaultReply) return true

  return isEachKaleyTextAssigned(defaultReply) && isEachChoiceTextAssigned(defaultReply)
}

export const isMessageBlockTextAssigned = (scriptItem: ScriptItem): boolean => {
  const defaultReply = getDefaultReply(scriptItem)
  if (!defaultReply) return true

  return isEachKaleyTextAssigned(defaultReply) && isEachChoiceTextAssigned(defaultReply)
}

export const getReferenceCountMap = (thread: ThreadResponse): MessageBlockReferenceMap => {
  const refCountMap: MessageBlockReferenceMap = {}
  refCountMap[thread.id] = []
  thread?.script.forEach((messageBlock, index) => {
    // initialize empty items as we go so that every index is mapped, even for zeros
    if (!refCountMap[thread.id][index]) refCountMap[thread.id][index] = 0
    const { collect } = messageBlock
    collect.options.forEach((option) => {
      const scriptIndex = option.execute?.scriptIndex
      // don't count options that point to themselves
      if (scriptIndex !== undefined && scriptIndex !== index && scriptIndex >= 0) {
        if (!refCountMap[thread.id][scriptIndex]) refCountMap[thread.id][scriptIndex] = 0
        refCountMap[thread.id][scriptIndex] += option.patterns?.length ?? 1
      }
    })
  })
  return refCountMap
}

export const hasRequiredSlots = (thread: ThreadResponse | null): boolean => {
  if (!thread) return false
  return hasFailureTypeSlot(thread)
}

export const hasFailureTypeSlot = (thread: ThreadResponse | null): boolean => {
  if (!thread) return false
  return thread.script.some(
    (scriptItem) =>
      ((scriptItem?.reply as DefaultReply)?.prompt as DefaultPrompt)?.slot === 'FailureType',
  )
}

export const isMessageBlockOrphaned = (
  threadId = '',
  index: number,
  messageBlockReferenceMap: MessageBlockReferenceMap,
): boolean => {
  return messageBlockReferenceMap[threadId]?.[index] === 0
}

export const hasMissingKeySelectorValues = (ruleset: RuleCreate[]): boolean => {
  return ruleset.some((rule) => {
    return rule.conditions.some((condition) => {
      const conditionType = identifyCondition(condition)
      if (conditionType && conditionType.includes('Script')) {
        const script = (condition as NonNumericConditionWithScript).script as number
        return script === -1
      }
      return false
    })
  })
}

export const hasValidConditions = (ruleset: RulesetBase | null): boolean => {
  if (!ruleset) return false
  // first validation check: Key selector is not empty
  const hasMissingApproveKey = hasMissingKeySelectorValues(ruleset.approveRules)
  const hasMissingDenyKey = hasMissingKeySelectorValues(ruleset.denyRules)
  const hasMissingReviewKey = hasMissingKeySelectorValues(ruleset.reviewRules)
  // TODO: Phase 2 [CCS-1317] - Add validation for rest of condition inputs/selectors

  return !hasMissingApproveKey && !hasMissingDenyKey && !hasMissingReviewKey
}
