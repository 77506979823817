import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { get } from '@helloextend/extend-api-fetch'
import { querystring } from '@extend/client-helpers'
import type { AuditHistoryResponse } from '../models'

export async function getHistory<T>(
  accessToken: string,
  type: string,
  id: string,
  cursor?: string,
  limit?: number,
): Promise<ApiResponse<AuditHistoryResponse<T>>> {
  const queryString = querystring.build({ cursor, limit })
  return get(`/audit/${type}/${id}/history?${queryString}`, { accessToken })
}
