import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Badge, Button, COLOR, Icon, Check } from '@extend/zen'
import type { ClaimNote } from '@helloextend/extend-api-client'
import type { FollowUpClaimNote } from '@helloextend/extend-api-client/src/models/claim-note'
import { date as dateHelper } from '@extend/client-helpers'
import { useUpdateClaimNoteMutation } from '@helloextend/extend-api-rtk-query'
import type { EscalationClaimNote } from '@extend-services/claims-management/dist/src/models/claim-note'
import { escalationLevels } from '../../../../../../lib/escalation-levels'
import { FollowUpBadge } from './follow-up-badge'

interface ClaimNotesEntryProps {
  claimNote: ClaimNote
  isTableData?: boolean
}

const ClaimNotesEntry: FC<ClaimNotesEntryProps> = ({ claimNote, isTableData = false }) => {
  const [showMore, setShowMore] = useState(false)
  const [updateClaimNote, { isLoading }] = useUpdateClaimNoteMutation()

  const handleMarkComplete = (): void => {
    updateClaimNote({
      claimId: claimNote.claimId,
      noteId: claimNote.id,
      body: { ...claimNote, completed: true },
    })
  }

  const handleMarkResolved = (): void => {
    updateClaimNote({
      claimId: claimNote.claimId,
      noteId: claimNote.id,
      body: { ...claimNote, isResolved: true },
    })
  }

  return (
    <ContentWrapper data-cy="claim-note-entry" isTableData={isTableData}>
      <NoteHeader isTableData={isTableData}>
        <NoteDate data-cy="claims-note-date" isTableData={isTableData}>
          {dateHelper.format(claimNote.createdAt, 'MMMM DD YYYY h:mm:ss A')}{' '}
        </NoteDate>
        <NoteAuthor>
          <Badge
            color="neutral"
            data-cy="notes-author-badge"
            emphasis="low"
            size="small"
            text={claimNote.createdBy}
          />
        </NoteAuthor>
      </NoteHeader>
      <NoteText data-cy="claim-note-entry-text" isTableData={isTableData}>
        {showMore ? claimNote.text : `${claimNote.text.substring(0, 200)}`}
        {claimNote.text.length >= 200 && (
          <ShowMoreButton className="see-more-btn" onClick={() => setShowMore(!showMore)}>
            {showMore ? '...see less' : '...see more'}
          </ShowMoreButton>
        )}
      </NoteText>
      {claimNote.type === 'follow-up' && (
        <FollowUpRow>
          <FollowUpBadge
            timestamp={(claimNote as FollowUpClaimNote).followUpDate}
            isCompleted={(claimNote as FollowUpClaimNote).completed}
          />
          {(claimNote as FollowUpClaimNote).completed ? (
            <Icon data-cy="claim-note-complete-icon" icon={Check} />
          ) : (
            !isTableData && (
              <Button
                text="Mark Completed"
                emphasis="low"
                color="neutral"
                onClick={handleMarkComplete}
                isProcessing={isLoading}
                data-cy="claim-note-mark-complete"
              />
            )
          )}
        </FollowUpRow>
      )}
      {claimNote.type === 'escalation' && (
        <FollowUpRow>
          <Badge
            data-cy="escalation-note-badge"
            color={(claimNote as EscalationClaimNote).isResolved ? 'neutral' : 'red'}
            text="Escalation"
            details={escalationLevels[(claimNote as EscalationClaimNote).escalationLevel]}
          />
          {(claimNote as EscalationClaimNote).resolvedAt ? (
            <ResolvedEscalationLabel data-cy="escalation-removed-label">
              {`Escalation Removed ${dateHelper.format(
                (claimNote as EscalationClaimNote).resolvedAt || 0,
                'MMM DD YYYY',
              )}`}
            </ResolvedEscalationLabel>
          ) : (
            !isTableData && (
              <Button
                text="Remove Escalation"
                emphasis="low"
                color="neutral"
                onClick={handleMarkResolved}
                isProcessing={isLoading}
                data-cy="escalation-mark-resolved"
              />
            )
          )}
        </FollowUpRow>
      )}
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div<{ isTableData: boolean }>(({ isTableData }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: isTableData ? undefined : `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: isTableData ? undefined : 4,
  padding: isTableData ? '0 16px' : 16,
  marginTop: isTableData ? undefined : 8,
  width: isTableData ? '494px' : 'auto',
}))

const NoteHeader = styled.div<{ isTableData: boolean }>(({ isTableData }) => ({
  display: 'flex',
  justifyContent: isTableData ? 'flex-start' : 'space-between',
  gap: isTableData ? 16 : 0,
}))

const NoteDate = styled.div<{ isTableData: boolean }>(({ isTableData }) => ({
  fontSize: isTableData ? 14 : 15,
  fontWeight: isTableData ? 400 : 'inherit',
  lineHeight: isTableData ? '24px' : 'inherit',
  color: isTableData ? COLOR.NEUTRAL[800] : 'inherit',
}))

const NoteAuthor = styled.div({
  textAlign: 'right',
})

const NoteText = styled.div<{ isTableData: boolean }>(({ isTableData }) => ({
  fontSize: isTableData ? 14 : 16,
  overflow: 'hidden',
  wordWrap: 'break-word',
  fontWeight: isTableData ? 400 : 'inherit',
  lineHeight: isTableData ? '24px' : 'inherit',
  color: isTableData ? COLOR.NEUTRAL[800] : 'inherit',
}))

const FollowUpRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const ResolvedEscalationLabel = styled.span({
  color: COLOR.NEUTRAL[800],
  textAlign: 'right',
  fontFamily: 'Nunito Sans',
})

const ShowMoreButton = styled.a({ cursor: 'pointer' })

export { ClaimNotesEntry }
