import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Input } from '@extend/zen'
import type { ChangeFunction, SetFieldFunction, StringInputItem } from './types'

type InputItemProps = {
  handleChange: ChangeFunction & SetFieldFunction
  handleBlur: (e: SyntheticEvent) => void
  isDisabled: boolean
  item: StringInputItem
  autoFocus?: boolean
}

const InputItem: FC<InputItemProps> = ({
  handleChange,
  handleBlur,
  isDisabled,
  item,
  autoFocus,
}) => {
  return (
    <TextInputWrapper>
      <Input
        id={item.name}
        label={item.label}
        value={item.value}
        onChange={handleChange}
        onBlur={handleBlur}
        isDisabled={item.isItemDisabled ?? isDisabled}
        isError={Boolean(item.error)}
        errorFeedback={item.error}
        autoFocus={autoFocus}
        data-cy={item.name}
      />
    </TextInputWrapper>
  )
}

const TextInputWrapper = styled.div({
  width: '100%',
  marginRight: 24,
})

export type { InputItemProps }
export { InputItem }
