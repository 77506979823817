import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useExtendAuth } from '@extend/package-okta-login'
import { COLOR } from '@extend/zen'
import { HeadTag } from '../components/head-tag'
import images from '../images'

const LOGOUT_TIMEOUT_MS = 3500

const Logout: FC = () => {
  const { logout, isAuthenticated } = useExtendAuth()

  useEffect(() => {
    if (isAuthenticated) {
      logout()
    }
    navigateToLogin()
  }, [isAuthenticated, logout])

  return (
    <Wrapper>
      <HeadTag />
      <main>
        <Logo src={images.extendIcon} alt="Extend" />
        <h2>See you later!</h2>
        <div>
          You have been logged out of <strong>Extend Portal</strong> and will be redirected to the
          login page in a moment.
        </div>
        <a href="/login">Send me back to login</a>
      </main>
    </Wrapper>
  )
}

/**
 * forces page refresh to reroute to login
 */
function navigateToLogin(): void {
  setTimeout(() => {
    window.location.replace('/login')
  }, LOGOUT_TIMEOUT_MS)
}

const Wrapper = styled.main({
  alignItems: 'center',
  backgroundColor: COLOR.NEUTRAL[100],
  display: 'flex',
  justifyContent: 'center',
  lineHeight: '2rem',
  minHeight: '100vh',
  textAlign: 'center',
  width: ' 100%',
  h2: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 20,
  },
  strong: {
    fontWeight: 600,
  },
  a: {
    color: COLOR.BLUESKY,
  },
})

const Logo = styled.img({
  maxHeight: 50,
})

export { Logout }
