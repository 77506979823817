import type { FC } from 'react'
import React, { useEffect } from 'react'
import type { Store } from '@helloextend/extend-api-client'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'
import { Switch } from '@extend/zen'
import { SectionContainer } from '../../../../../../../../components/section-container'
import { DefaultUrlSettings } from './default-url-settings'
import { HistoricalLeadsSettings } from './historical-leads-settings'
import { AnalyticsTabSettings } from './analytics-tab-settings'

type OfferFormProps = {
  store: Store
}

const MerchantPortalForm: FC<OfferFormProps> = ({ store }) => {
  const [updateStore, { isError, isSuccess, isLoading }] = useUpdateStoreMutation()
  const { toastCompleted, toastError } = useStandardToast()

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Merchant Portal Settings have been successfully updated.')
    }
  }, [isSuccess, toastCompleted])

  useEffect(() => {
    if (isError) {
      toastError('Merchant Portal Settings could not be updated. Please try again.')
    }
  }, [isError, toastError])

  const handleUpdateStore = (storeData: Partial<Store>): void => {
    updateStore({
      storeId: store.id,
      data: storeData,
      version: 'latest',
    })
  }

  if (!store) return null

  return (
    <SectionContainer title="Merchant Portal">
      <Container>
        <Switch
          label="Show Claims Tab"
          id="claimsManagementEnabled"
          isOn={Boolean(store.claimsManagementEnabled)}
          onChange={() =>
            handleUpdateStore({ claimsManagementEnabled: !store.claimsManagementEnabled })
          }
          data-cy="claims-tab-toggle"
        />
      </Container>
      <AnalyticsTabSettings store={store} handleUpdateStore={handleUpdateStore} />
      <Container>
        <Switch
          label="Lead Emails Configuration"
          id="enableMerchantPortalLeadEmailsConfiguration"
          isOn={Boolean(store.enableMerchantPortalLeadEmailsConfiguration)}
          onChange={() =>
            handleUpdateStore({
              enableMerchantPortalLeadEmailsConfiguration:
                !store.enableMerchantPortalLeadEmailsConfiguration,
            })
          }
          data-cy="lead-emails-configuration-toggle"
        />
      </Container>
      <DefaultUrlSettings
        store={store}
        isLoading={isLoading}
        handleUpdateStore={handleUpdateStore}
      />
      <HistoricalLeadsSettings
        store={store}
        isLoading={isLoading}
        handleUpdateStore={handleUpdateStore}
      />
    </SectionContainer>
  )
}

const Container = styled.div({
  marginBottom: 16,
})

export { MerchantPortalForm }
