import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import type { CellContext, DataTableAction, VisibilityState } from '@extend/zen'
import {
  Badge,
  COLOR,
  DataTable,
  Mail,
  Stack,
  ToastColor,
  ToastDuration,
  useToaster,
  Upload,
} from '@extend/zen'
import { useHistory } from 'react-router'
import type { CategoryMapping, PlanSet, StoreIdAndName } from '@helloextend/extend-api-client'
import { CategoryMappingStatus } from '@helloextend/extend-api-client'
import type { OnChangeFn, ColumnSort, ColumnFiltersState } from '@tanstack/react-table'
import {
  useListCategoryMappingsQuery,
  useListPlanSetsQuery,
  useCreateCategoryMappingDownloadMutation,
} from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { useExtendAuth } from '@extend/package-okta-login'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../constants/ld-flags'
import { getUserEmailFromToken } from '../../../lib/jwt'
import { statusBadgeProps, toMonthDayYearTimeString } from './utils'
import { getCategoryMapperFilterDefs } from './table/lib/get-filter-defs'

export interface PlanCategoryProps {
  display: string
  value: string
  planCategoryId?: string
}

interface CategoryMapperTableProps {
  selectedStore: StoreIdAndName
  toggleModal: () => void
  handleSelectedMappings: (selectedRows: CategoryMapping[]) => void
  categories: PlanCategoryProps[]
}

export interface CategoryMappingTableItem extends CategoryMapping {
  planSetName?: string
}

type Columns = 'id' | 'updatedAt' | 'createdAt' | 'version' | 'planCategoryId'

type ColumnEnabledState = Record<Columns, boolean>

const initialColumnVisibility: ColumnEnabledState = {
  id: false,
  updatedAt: false,
  createdAt: false,
  version: false,
  planCategoryId: false,
}

const CategoryMapperTable: FC<CategoryMapperTableProps> = ({
  selectedStore: { id: storeId, name },
  handleSelectedMappings,
  categories,
}) => {
  const flags = useFlags()
  const offersFeatures = flags[LDFlag.OffersFeatures]
  const { toast } = useToaster()
  const { data: { items: mappings = [] } = {}, isFetching: isMappingLoading } =
    useListCategoryMappingsQuery({ storeId })
  const [createCategoryMappingDownload, { isLoading }] = useCreateCategoryMappingDownloadMutation()
  const { data: planSets = [], isFetching: isPlanSetsLoading } = useListPlanSetsQuery()
  const [filteredMappings, setFilteredMappings] = useState<CategoryMapping[]>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'mappingStatus', value: [CategoryMappingStatus.UNMAPPED] },
  ])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initialColumnVisibility)
  const [sortBy, setSortBy] = useState<ColumnSort[]>([])
  const { token: accessToken } = useExtendAuth()
  const email = getUserEmailFromToken(accessToken) ?? ''
  const history = useHistory()

  const merchantCategories = Array.from(
    new Set(mappings.map((mapping) => mapping.merchantCategory)),
  )

  const onSortingChange: OnChangeFn<ColumnSort[]> = (newSortBy) => {
    const changes = typeof newSortBy === 'function' ? newSortBy(sortBy) : newSortBy
    setSortBy(changes)
  }

  const onColumnVisibilityChange: OnChangeFn<VisibilityState> = (newColumnVisibility) => {
    const changes =
      typeof newColumnVisibility === 'function'
        ? newColumnVisibility(columnVisibility)
        : newColumnVisibility

    setColumnVisibility(changes)
  }

  const handleCategoryMappingImport = (): void =>
    history.push(`/admin/products/${storeId}/category-mapping/import`)

  const handleSubmitCategoryMappingDownload = useCallback(async () => {
    try {
      const response = await createCategoryMappingDownload({ storeId, email }).unwrap()
      if ('url' in response && 'message' in response) {
        toast({
          message:
            'Your data is being prepared. You will receive an email with a download link shortly.',
          toastColor: ToastColor.blue,
        })
      }
    } catch (err) {
      toast({
        message: 'There was an error exporting category mappings, please try again later.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [createCategoryMappingDownload, storeId, email, toast])

  // Plan Set Column is only displayed when we are not in pdr post-launch phase
  if (offersFeatures.pdr.postLaunchEnabled) {
    TABLE_COLUMNS = TABLE_COLUMNS.filter((col) => col.id !== 'planSetName')
  }

  const getTableActions = (selectedRows: CategoryMapping[]): DataTableAction[] => {
    return [
      {
        'data-cy': 'import-category-mapping-button',
        text: 'Import',
        icon: Upload,
        emphasis: 'medium',
        isDisabled: Boolean(isMappingLoading || isPlanSetsLoading || isLoading),
        onClick: handleCategoryMappingImport,
      },
      {
        'data-cy': 'export-category-mapping-button',
        text: 'Export All Categories',
        icon: Mail,
        emphasis: 'medium',
        isDisabled: Boolean(isMappingLoading || isPlanSetsLoading || isLoading),
        onClick: handleSubmitCategoryMappingDownload,
      },
      {
        'data-cy': 'map-category-button',
        text: 'Map',
        emphasis: 'high',
        isDisabled: Boolean(!selectedRows.length),
        onClick: () => handleSelectedMappings(selectedRows),
      },
    ]
  }

  useEffect(() => {
    const categoryFilters = getFilters('extendCategory', columnFilters)
    const statusFilters = getFilters('mappingStatus', columnFilters)

    const newMappings = columnFilters.length
      ? filterMappings(mappings, categoryFilters, statusFilters)
      : mappings

    // prevents continually re-rendering while fetching the paginated mappings/plan sets
    if (!isPlanSetsLoading && !isMappingLoading) {
      setFilteredMappings(newMappings)
    }
  }, [columnFilters, isMappingLoading, isPlanSetsLoading, mappings])

  return (
    <>
      <Header data-cy="category-mapper-header-text">{name}</Header>
      <Stack spacing={2}>
        <SubTitle data-cy="category-mapper-sub-header-text">
          <StoreIdTitle>Store ID:</StoreIdTitle>
          {storeId}
        </SubTitle>
        <DataTable
          data-cy="category-mapper-data-table"
          isLoading={isMappingLoading || isPlanSetsLoading}
          columns={TABLE_COLUMNS}
          data={getTableItems(filteredMappings, planSets)}
          onColumnFiltersChange={setColumnFilters}
          columnFilters={columnFilters}
          hasManualFiltering={false}
          filterDefs={getCategoryMapperFilterDefs(categories, merchantCategories)}
          hasSelectableRows
          getTableActions={getTableActions}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={onColumnVisibilityChange}
          onSortingChange={onSortingChange}
          sorting={sortBy}
        />
      </Stack>
    </>
  )
}

const getTableItems = (
  mappings: CategoryMapping[],
  planSets: PlanSet[],
): CategoryMappingTableItem[] =>
  mappings.map((mapping) => ({
    ...mapping,
    planSetName: planSets.find(({ id }) => id === mapping.planSetId)?.name,
  }))

const getFilters = (
  filterId: 'extendCategory' | 'mappingStatus',
  columnFilters: ColumnFiltersState,
): string[] | undefined =>
  columnFilters.find(({ id }) => id === filterId)?.value as undefined | string[]

const filterMappings = (
  mappings: CategoryMapping[],
  categoryFilters?: string[],
  statusFilters?: string[],
): CategoryMapping[] =>
  mappings.filter(
    ({ extendCategory, mappingStatus }) =>
      (!categoryFilters || categoryFilters.includes(extendCategory)) &&
      (!statusFilters || statusFilters.includes(mappingStatus)),
  )

let TABLE_COLUMNS = [
  {
    id: 'merchantCategory',
    label: 'Merchant Category',
    renderCell: (cellData: CellContext<CategoryMapping, string>) => (
      <>{cellData.getValue()?.trim().length ? cellData.getValue() : <Dash />}</>
    ),
    isSortable: true,
  },
  {
    id: 'extendCategory',
    label: 'Extend Plan Category',
    renderCell: (cellData: CellContext<CategoryMapping, string>) => (
      <>{cellData.getValue()?.trim().length ? cellData.getValue() : <Dash />}</>
    ),
    isSortable: true,
  },
  {
    id: 'mappingStatus',
    label: 'Warranty Status',
    renderCell: (cellData: CellContext<CategoryMapping, CategoryMappingStatus>) => (
      <Badge {...statusBadgeProps[cellData.getValue()]} />
    ),
    isSortable: true,
  },
  {
    id: 'planSetName',
    label: 'Plan Set',
    renderCell: (cellData: CellContext<CategoryMapping, string>) => (
      <>{cellData.getValue()?.trim().length ? cellData.getValue() : <Dash />}</>
    ),
    isSortable: true,
  },
  {
    id: 'version',
    label: 'Version',
    renderCell: (cellData: CellContext<CategoryMapping, string>) => (
      <>{cellData.getValue()?.trim().length ? cellData.getValue() : <Dash />}</>
    ),
    isSortable: true,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    renderCell: (cellData: CellContext<CategoryMapping, string>) => (
      <>{cellData.getValue() ? toMonthDayYearTimeString(cellData.getValue()) : <Dash />}</>
    ),
    isSortable: true,
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    renderCell: (cellData: CellContext<CategoryMapping, string>) => (
      <>{cellData.getValue() ? toMonthDayYearTimeString(cellData.getValue()) : <Dash />}</>
    ),
    isSortable: true,
  },
  {
    id: 'planCategoryId',
    label: 'Plan Category ID',
    renderCell: (cellData: CellContext<CategoryMapping, string>) => (
      <>{cellData?.getValue()?.trim().length ? cellData.getValue() : <Dash />}</>
    ),
    isSortable: true,
  },
  {
    id: 'id',
    label: 'ID',
    renderCell: (cellData: CellContext<CategoryMapping, string>) => (
      <>{cellData.getValue()?.trim().length ? cellData.getValue() : <Dash />}</>
    ),
    isSortable: true,
  },
]

const StoreIdTitle = styled.span({
  color: '#6B7887',
  fontSize: 16,
  fontWeight: 800,
  marginRight: 5,
})

const Header = styled.h1({
  color: COLOR.BLACK,
  fontSize: 20,
  lineHeight: '28px',
  fontWeight: 800,
  marginTop: 30,
})

const SubTitle = styled.span({
  color: COLOR.BLACK,
  fontSize: 16,
  margin: 0,
})

const Dash = styled.hr({
  borderTop: 0,
  borderStyle: 'solid',
  borderColor: '#141617',
  width: 14,
  marginLeft: 0,
})

export { CategoryMapperTable }
