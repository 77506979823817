import type { CurrencyCode } from '@helloextend/extend-api-client'
import type { Sku } from '@helloextend/extend-api-rtk-query'
import type { SkuCreateRequest } from '@helloextend/extend-api-rtk-query'
import type { Values } from '../pages/admin/premiums/premium-details/premium-form/form-schema'

const skuPropertiesMapper = (values: Values): SkuCreateRequest => {
  return {
    id: values.id,
    name: values.name,
    activeFrom: values.activeFrom,
    activeTo: values.activeTo,
    program: values.program,
    subProgram: values.subprogram,
    coverageType: values.coverageType,
    term: Number(values.term),
    serviceType: values.serviceType,
    lineOfBusiness: values.lineOfBusiness,
    currencyCode: values.currencyCode as CurrencyCode,
    productCondition: values.productCondition,
    purchasePriceOfProduct: values.purchasePriceOfProduct,
    limitOfLiabilityBasedUpon: values.limitOfLiabilityBasedUpon,
    paymentModel: values.paymentModel,
    cancellation: values.cancellation,
    obligor: values.obligor,
    insuranceProgramId: values.insuranceProgramId,
    priceBandLow: Number(values.priceBandLow),
    priceBandHigh: Number(values.priceBandHigh),
    isDeductible: values.isDeductible === 'YES',
    deductibleAmount: Number(values.deductibleAmount),
    lossCost: Number(values.lossCost),
    adminFee: values.adminFee ? Number(values.adminFee) : 0,
    rateFee: Number(values.rateFee),
    rateFeePercentage: values.rateFeePercentage ? Number(values.rateFeePercentage) : 0,
    lossReserve: Number(values.lossReserve),
    premium: Number(values.premium),
    targetLossRatio: Number(values.targetLossRatio),
    isActive: values.isActive === 'yes',
    frequency: Number(values.frequency),
    severity: Number(values.severity),
    source: values.source,
  }
}

const skuFormInitialValues = (skuDetails: Sku): Values => ({
  id: skuDetails.id ?? '',
  name: skuDetails.name ?? '',
  activeFrom: skuDetails.activeFrom ?? 0,
  activeTo: skuDetails.activeTo ?? undefined,
  program: skuDetails.program ?? '',
  subprogram: skuDetails.subProgram ?? '',
  coverageType: skuDetails.coverageType ?? '',
  term: skuDetails.term ?? undefined,
  serviceType: skuDetails.serviceType ?? '',
  lineOfBusiness: skuDetails.lineOfBusiness ?? 'serviceContract',
  currencyCode: skuDetails.currencyCode ?? undefined,
  productCondition: skuDetails.productCondition ?? '',
  purchasePriceOfProduct: skuDetails.purchasePriceOfProduct ?? '',
  limitOfLiabilityBasedUpon: skuDetails.limitOfLiabilityBasedUpon ?? '',
  paymentModel: skuDetails.paymentModel ?? '',
  cancellation: skuDetails.cancellation ?? '',
  obligor: skuDetails.obligor ?? '',
  insuranceProgramId: skuDetails.insuranceProgramId ?? '',
  priceBandLow: skuDetails.priceBandLow ?? undefined,
  priceBandHigh: skuDetails.priceBandHigh ?? undefined,
  isDeductible: skuDetails.isDeductible ? 'yes' : 'no',
  deductibleAmount: skuDetails.deductibleAmount ?? undefined,
  lossCost: skuDetails.lossCost ?? undefined,
  adminFee: skuDetails.adminFee ?? undefined,
  rateFee: skuDetails.rateFee ?? undefined,
  rateFeePercentage: skuDetails.rateFeePercentage ?? undefined,
  lossReserve: skuDetails.lossReserve ?? undefined,
  premium: skuDetails.premium ?? undefined,
  targetLossRatio: skuDetails.targetLossRatio ?? undefined,
  isActive: skuDetails.isActive ? 'yes' : 'no',
  frequency: skuDetails.frequency ?? undefined,
  severity: skuDetails.severity ?? undefined,
  source: skuDetails.source ?? '',
})

export { skuPropertiesMapper, skuFormInitialValues }
