import type { ChangeEvent, FC, RefObject } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import {
  Button,
  ButtonGroup,
  COLOR,
  InlineAlert,
  InlineAlertColor,
  ToastColor,
  ToastDuration,
  useToaster,
  Check,
  Error,
  Info,
  Stack,
  DisplayLarge,
} from '@extend/zen'
import { useHistory } from 'react-router-dom'
import {
  useGetUploadUrlMutation,
  useImportSkusMutation,
  useLazyGetSkuQuery,
  useListPlanAttributesQuery,
} from '@helloextend/extend-api-rtk-query'
import { skuCsvHeaders } from '../../../../utils/skus-csv-upload/skus-csv-headers'
import skuSampleCsvFile from '../../../../misc/sku-sample.csv'
import { parseSkuCsv } from '../../../../utils/skus-csv-upload/skus-parser'
import { toPlanAttributesDropdownOptions } from '../../../../utils/plan-attributes-dropdown-mapping'
import { fileToBuffer } from '../../../../utils/file-to-buffer'

interface AlertItem {
  key: string
  text: string
}

const convertToAlertItem = (alerts: string[]): AlertItem[] => {
  return alerts.map((error, index) => ({
    key: `error-${index}`,
    text: error,
  }))
}

const PremiumsImport: FC = () => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [skusNumber, setSkusNumber] = useState(0)
  const [errorsBanner, setErrorsBanner] = useState<AlertItem[]>([])
  const [warningsBanner, setWarningsBanner] = useState<AlertItem[]>([])
  const [hasValidated, setHasValidated] = useState(false)

  const { goBack, push } = useHistory()
  const { toast } = useToaster()
  const { data: planAttributes, isFetching } = useListPlanAttributesQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => {
      const attributesList = toPlanAttributesDropdownOptions(data?.items || [])
      return {
        data: attributesList,
        ...rest,
      }
    },
  })
  const [getSkuById] = useLazyGetSkuQuery()
  const [getUploadUrl, { data: uploadUrl, isLoading }] = useGetUploadUrlMutation()
  const [uploadCsv, { isSuccess: isUploadSuccess, isError, isLoading: isUploading }] =
    useImportSkusMutation()

  const taxonomyAttributes = useMemo(() => {
    return {
      program: planAttributes.program,
      sub_program: planAttributes.sub_program,
    }
  }, [planAttributes])

  const skusFileInputRef: RefObject<HTMLInputElement> = React.createRef()

  const showToastMessage = useCallback(
    (isSuccess = true): void => {
      if (isSuccess) {
        toast({
          message: `${skusNumber} SKUs have been successfully imported.`,
          toastColor: ToastColor.blue,
          toastDuration: ToastDuration.short,
        })
      } else {
        toast({
          message: 'Something went wrong. Please try again.',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      }
    },
    [skusNumber, toast],
  )
  const resetUploadedFile = useCallback(() => {
    setUploadedFile(null)
    setErrorsBanner([])
    setWarningsBanner([])
    setHasValidated(false)
    const { current } = skusFileInputRef
    if (current?.value) {
      current.value = ''
    }
  }, [skusFileInputRef])

  const checkCurrentActiveTo = useCallback(
    async (skuId: string, activeFrom: string): Promise<string> => {
      try {
        // check to see if uploaded file has an existing version. If so check activeTo(existing version) vs activeFrom(uploaded)
        const response = await getSkuById({ skuId }).unwrap()
        if (response.activeTo) {
          const currentTime = new Date().getTime()
          const currentActiveTo = new Date(response.activeTo).getTime()
          const activateFrom = new Date(activeFrom).getTime()
          if (currentActiveTo < activateFrom && currentActiveTo > currentTime) {
            return `For SkuId: ${skuId} there is a gap between unsaved "Active From" date: ${new Date(
              activateFrom,
            ).toDateString()} and the active version's "Active To" date: ${new Date(
              currentActiveTo,
            ).toDateString()}.`
          }
        }
      } catch (e) {
        // Swallow any errors
      }
      return ''
    },
    [getSkuById],
  )
  useEffect(() => {
    if (!uploadedFile || !Object.keys(taxonomyAttributes).length || hasValidated) return
    ;(async function parseAndValidateUploadedFile(): Promise<void> {
      try {
        const results = await parseSkuCsv(uploadedFile, { taxonomyAttributes })
        const activeToWarnings = []
        for (const sku of results.data) {
          const skuId = sku[skuCsvHeaders.indexOf('skuId')]?.trim()
          const activeFrom = sku[skuCsvHeaders.indexOf('activateFrom')]?.trim()
          if (skuId && activeFrom) {
            activeToWarnings.push(checkCurrentActiveTo(skuId, activeFrom))
          }
        }
        const resolvedActiveToWarnings = (await Promise.all(activeToWarnings)).filter(
          (value) => value !== '',
        )

        results.warnings.push(...resolvedActiveToWarnings)
        const errors = convertToAlertItem(results?.errors || [])
        const warnings = convertToAlertItem(results?.warnings || [])
        setErrorsBanner(errors)
        setWarningsBanner(warnings)
        setSkusNumber(results?.data?.length)
        setHasValidated(true)
      } catch (err: unknown) {
        showToastMessage(false)
      }
    })()
  }, [uploadedFile, showToastMessage, taxonomyAttributes, hasValidated, checkCurrentActiveTo])

  useEffect(() => {
    if (uploadUrl || !uploadedFile?.name) return
    getUploadUrl(uploadedFile.name)
  }, [getUploadUrl, uploadUrl, uploadedFile])

  useEffect(() => {
    if (isUploadSuccess) {
      showToastMessage(true)
      push('/admin/premiums')
    } else if (isError) {
      showToastMessage(false)
    }
  }, [isError, isUploadSuccess, push, resetUploadedFile, showToastMessage])

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0] || null
    setUploadedFile(file)
    setHasValidated(false)
  }, [])

  const handleCancelClick = (): void => {
    goBack()
  }
  const handleSelectFile = useCallback(() => {
    resetUploadedFile()
    skusFileInputRef.current?.click()
  }, [resetUploadedFile, skusFileInputRef])

  const handleUploadClick = useCallback(() => {
    if (!uploadUrl?.url || !uploadedFile) return
    ;(async function uploadCsvFile(): Promise<void> {
      const bufferArray = await fileToBuffer(uploadedFile)
      uploadCsv({ url: uploadUrl.url, fileArrayBuffer: bufferArray })
    })()
  }, [uploadCsv, uploadUrl, uploadedFile])

  return (
    <>
      <Stack spacing={2}>
        <DisplayLarge>Import Premiums</DisplayLarge>
        {uploadedFile && hasValidated && errorsBanner.length === 0 && warningsBanner.length === 0 && (
          <InlineAlert
            color={InlineAlertColor.green}
            icon={Check}
            primaryButtonProps={{
              text: 'Replace File',
              onClick: handleSelectFile,
              isProcessing: isUploading,
              isDisabled: isUploading,
            }}
          >
            {uploadedFile.name} has been successfully validated! You are about to import{' '}
            {skusNumber} SKUs. Note that if the SKU already exists, the attributes provided in this
            file will update the existing SKU.
          </InlineAlert>
        )}
        {uploadedFile && hasValidated && errorsBanner.length > 0 && (
          <InlineAlert
            color={InlineAlertColor.red}
            icon={Error}
            primaryButtonProps={{ text: 'Replace File', onClick: handleSelectFile }}
          >
            {uploadedFile.name} failed to validate.
            {errorsBanner.map(({ key, text }) => (
              <li key={key}>{text}</li>
            ))}
          </InlineAlert>
        )}
        {uploadedFile && hasValidated && warningsBanner.length > 0 && (
          <InlineAlert
            color={InlineAlertColor.yellow}
            icon={Info}
            primaryButtonProps={{
              text: 'Replace File',
              onClick: handleSelectFile,
              isProcessing: isUploading,
              isDisabled: isUploading,
            }}
          >
            {uploadedFile.name} has some warnings.
            {warningsBanner.map(({ key, text }) => (
              <li key={key}>{text}</li>
            ))}
          </InlineAlert>
        )}
        <StyledDownload href={skuSampleCsvFile}>Download Premiums CSV Example</StyledDownload>
        <Stack isRow justify="start">
          {!uploadedFile && !hasValidated && (
            <Button
              text="Select CSV File"
              emphasis="medium"
              isProcessing={isFetching}
              onClick={handleSelectFile}
            />
          )}
        </Stack>
        <Stack isRow justify="end">
          <ButtonGroup>
            <Button text="Cancel" emphasis="low" onClick={handleCancelClick} />
            <Button
              text="Upload"
              emphasis="high"
              isDisabled={!uploadedFile || errorsBanner.length > 0}
              isProcessing={isFetching || isLoading || isUploading}
              onClick={handleUploadClick}
            />
          </ButtonGroup>
        </Stack>
      </Stack>
      <HiddenInput
        id="skusInputFile"
        ref={skusFileInputRef}
        accept=".csv"
        type="file"
        onChange={handleFileChange}
      />
    </>
  )
}

const StyledDownload = styled.a({
  color: COLOR.BLUE[800],
  '&:hover': { textDecoration: 'underline' },
})

const HiddenInput = styled.input({
  display: 'none',
})

export { PremiumsImport }
