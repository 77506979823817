import type { FC } from 'react'
import React from 'react'
import { DataTable } from '@extend/zen'
import { useHistory } from 'react-router-dom'
import { useGetPromotionsQuery } from '@helloextend/extend-api-rtk-query'
import { getTableColumns } from './promotions-data-table-config'

type PromotionsDataTableProps = {
  storeId: string
}

const PromotionsDataTable: FC<PromotionsDataTableProps> = ({ storeId }) => {
  const history = useHistory()
  const { data: promotionsData, isLoading, isError } = useGetPromotionsQuery({ storeId })

  const activePromotion = promotionsData?.items.find(
    (promotion) => promotion.approved && promotion.enabled,
  )

  return (
    <DataTable
      data-cy="promotions-data-table"
      isLoading={isLoading}
      isError={isError}
      data={promotionsData?.items || []}
      columns={getTableColumns(Boolean(activePromotion))}
      pageSizeOptions={[10, 25, 50, 100]}
      getRowActions={(promotion) => [
        {
          text: 'View',
          emphasis: 'low',
          onClick: () => history.push(`/admin/stores/${storeId}/promotions/${promotion.id}`),
        },
      ]}
      stateViews={{
        skeleton: { message: 'Please wait while we retrieve promotion data' },
        error: { heading: 'Unable to retrieve promotion data' },
      }}
    />
  )
}

export { PromotionsDataTable }
