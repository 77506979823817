import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.userCreate>

export default function* create(action: Action): SagaIterator {
  const { data, accessToken, accountId } = action.payload
  yield put(actions.userCreateRequest())

  try {
    const user = {
      ...data,
      accountId,
    }

    const response: ResolvedType<typeof client.users.createUser> = yield call(
      client.users.createUser,
      accessToken,
      user,
    )

    if (isErrorResponse(response)) {
      yield put(actions.userCreateFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.userCreateFailure(`An unknown error occurred`, response.status))
      return
    }

    yield put(actions.userCreateSuccess(response.data))
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.userCreateFailure(e.message))
    }
  }
}
