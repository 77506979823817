import type { FC } from 'react'
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { startCase } from 'lodash/fp'
import type {
  ContractTrackingInfo,
  ShippingProtectionContract,
} from '@helloextend/extend-api-client'

import { Subheading, DataProperty, Grid } from '@extend/zen'
import type { PrecheckResponse } from '@helloextend/extend-api-rtk-query'

import { date } from '@extend/client-helpers'
import type { ShippingProduct } from './shipping-protection'
import { ContractsProductsTable } from './contract-products-table'

interface ContractProductTracking extends ContractTrackingInfo {
  products: ShippingProduct[]

  shipmentStatusDate?: number
  estimatedDeliveryDate?: number
  actualDeliveryDate?: number
}

export interface ProductShippingInformationProps {
  values: ContractProductTracking[]

  contract: ShippingProtectionContract
  productsWithoutTrackingInfo: ShippingProduct[]

  precheckData?: PrecheckResponse
}

const ProductShippingInformation: FC<ProductShippingInformationProps> = ({
  values,
  productsWithoutTrackingInfo,
  precheckData,
  contract,
}) => {
  const plan = contract?.planDetails?.category || contract?.plan?.category
  return (
    <>
      {values.map((value, index) => {
        const shipmentStatusDate = value?.shipmentStatusDate
          ? date.format(Number(value?.shipmentStatusDate), date.standardDateFormat)
          : undefined

        const estimatedDeliveryDate = value?.estimatedDeliveryDate
          ? date.format(Number(value?.estimatedDeliveryDate), date.standardDateFormat)
          : undefined

        const deliveredDate = value?.actualDeliveryDate
          ? date.format(Number(value?.actualDeliveryDate), date.standardDateFormat)
          : undefined

        return (
          <Fragment key={value.trackingId}>
            <StyledSubheading data-cy="product-name" style={{ marginBottom: '2rem' }}>
              Shipment {index + 1}{' '}
            </StyledSubheading>
            <Grid
              columns={{ lg: 3, md: 2, sm: 1 }}
              data-cy={`product-shipping-${index}`}
              spacing={{ lg: 6, md: 4, sm: 2 }}
            >
              <DataProperty
                value={startCase(value.shippingProvider)}
                label="Shipping Provider"
                data-cy="shipping-provider"
                includeCopyAction={false}
              />

              <DataProperty
                value={value.trackingId}
                label="Tracking ID"
                data-cy="tracking-id"
                includeCopyAction={false}
              />

              <DataProperty
                value={value.shippingState}
                label="Shipping Status"
                data-cy="shipping-status"
                includeCopyAction={false}
              />

              <DataProperty
                value={value.shipmentId}
                label="Shipment ID"
                data-cy="shipment-id"
                includeCopyAction={false}
              />

              <DataProperty
                value={shipmentStatusDate}
                label="Shipment Status Date"
                data-cy="shipment-status-date"
                includeCopyAction={false}
              />

              <DataProperty
                value={estimatedDeliveryDate}
                label="Estimated Delivery Date"
                data-cy="estimated-delivery-date"
                includeCopyAction={false}
              />

              <DataProperty
                value={deliveredDate}
                label="Delivered Date"
                data-cy="delivered-date"
                includeCopyAction={false}
              />
            </Grid>
            <ContractsProductsTable
              dataCy={`product-shipping-${index}`}
              isLoading={false}
              category={plan}
              contract={contract}
              precheckData={precheckData}
              productIdsToRender={value.productsShipped}
            />
          </Fragment>
        )
      })}
      {!!productsWithoutTrackingInfo.length && (
        <ContainNoShipmentTable>
          <StyledSubheading data-cy="product-name-shipment-unassigned">
            No Shipments Assigned
          </StyledSubheading>
          <br />
          <ContractsProductsTable
            dataCy={`no-shipments-table`}
            isLoading={false}
            category={plan}
            contract={contract}
            productIdsToRender={productsWithoutTrackingInfo.map((product) => product.referenceId)}
          />
        </ContainNoShipmentTable>
      )}
    </>
  )
}

const StyledSubheading = styled(Subheading)({
  textTransform: 'uppercase',
  marginTop: '1rem',
})

const ContainNoShipmentTable = styled.div({
  marginTop: '3.5rem',
})

export type { ContractProductTracking }
export { ProductShippingInformation }
