import { date } from '@extend/client-helpers'
/**
 * @param value number
 * @returns string formatted date, i.e. May 13, 2022, 3:54PM EST
 */
const formatDateWithTimezone = (value?: number): string => {
  return (typeof value === 'number' && date.format(value, 'MMM D YYYY, h:mm A z')) || ''
}

export { formatDateWithTimezone }
