import styled from '@emotion/styled'
import { date } from '@extend/client-helpers'
import type { ColumnDef} from '@extend/zen';
import { Badge, Box, COLOR, DataTable, Stack } from '@extend/zen'
import type { RateCard, Rate } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import type { FC } from 'react';
import React from 'react'
import { formatCurrency } from '../../../claim/util'
import { expenseTypes } from '../../../claim/claim-details/components/tab-section/tabs/expenses/mappings'

interface RateCardDisplayProps {
  rateCard: RateCard
  index: number
}

const tableColumns: Array<ColumnDef<Rate>> = [
  {
    id: 'type',
    label: 'Type',
    renderCell: (data) =>
      expenseTypes.find((t) => t.value === data.getValue())?.display ?? data.getValue(),
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'rate',
    label: 'Rate',
    renderCell: (data) => (data.getValue() ? formatCurrency(data.getValue()) : '—'),
  },
  {
    id: 'qtyLow',
    label: 'Quantity Low',
  },
  {
    id: 'qtyHigh',
    label: 'Quantity High',
    renderCell: (data) => data.getValue() ?? '—',
  },
  {
    id: 'costLow',
    label: 'Cost Low',
    renderCell: (data) => formatCurrency(data.getValue()),
  },
  {
    id: 'costHigh',
    label: 'Cost High',
    renderCell: (data) => (data.getValue() ? formatCurrency(data.getValue()) : '—'),
  },
]

export const RateCardDisplay: FC<RateCardDisplayProps> = ({ rateCard, index }) => {
  const dataCyPrefix = `rate-card-${index}`
  return (
    <Box data-cy={`${dataCyPrefix}-display`}>
      <Stack spacing={2}>
        <Stack isRow spacing={1}>
          <Title>Rate Card {index + 1}</Title>
          <Badge
            data-cy={`${dataCyPrefix}-status-badge`}
            color={rateCard.isActive ? 'green' : 'neutral'}
            text={rateCard.isActive ? 'Active' : 'Inactive'}
          />
        </Stack>
        <p data-cy={`${dataCyPrefix}-update-text`}>
          Updated {date.format(rateCard.createdAt, date.standardDateFormat)} at{' '}
          {date.format(rateCard.createdAt, 'hh:MM:ss A')}
          {rateCard.lastUpdatedBy ? ` by ${rateCard.lastUpdatedBy}` : ''}
        </p>
        <Stack isRow spacing={2}>
          <Stack isRow spacing={1} data-cy={`${dataCyPrefix}-start-date-display`}>
            <DateLabel>Start Date:</DateLabel>
            {date.format(rateCard.startDate, date.standardDateFormat)}
          </Stack>
          <Stack isRow spacing={1} data-cy={`${dataCyPrefix}-end-date-display`}>
            <DateLabel>End Date:</DateLabel>
            {rateCard.endDate && <>{date.format(rateCard.endDate, date.standardDateFormat)}</>}
          </Stack>
        </Stack>
        <DataTable
          data-cy={`${dataCyPrefix}-rates-table`}
          isLoading={false}
          columns={tableColumns}
          data={rateCard.rates}
          hasConfigurableColumns={false}
          pageSizeOptions={[]}
          pagination={{ pageSize: 100, pageIndex: 0 }}
        />
      </Stack>
    </Box>
  )
}

const Title = styled.div({
  fontStyle: 'normal',
  fontSize: 20,
  fontWeight: 700,
})

const DateLabel = styled.div({
  color: COLOR.NEUTRAL[600],
})
