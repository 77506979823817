import styled from '@emotion/styled'
import {
  claimsApi,
  useCreateClaimPhotoMutation,
  useUploadClaimPhotoMutation,
} from '@helloextend/extend-api-rtk-query'
import {
  ButtonGroup,
  Input,
  Stack,
  Button,
  Upload,
  FileInput,
  useToaster,
  ToastDuration,
  ToastColor,
  InlineAlertColor,
  Info as InfoIcon,
  InlineAlert,
} from '@extend/zen'
import type { ChangeEvent, FC } from 'react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

const ALLOWED_FILE_EXTENSIONS = ['jpg', 'png']

interface AttachmentsUploadProps {
  claimId: string
  includeDisclaimer?: boolean
}

type ErrorType = { status?: string; error?: string }

const AttachmentsUpload: FC<AttachmentsUploadProps> = ({ claimId, includeDisclaimer = true }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [image, setImage] = useState<Blob>()
  const [imageName, setImageName] = useState<string>('')
  const [imageDescription, setImageDescription] = useState<string>('')
  const [createPhoto] = useCreateClaimPhotoMutation()
  const [uploadPhoto] = useUploadClaimPhotoMutation()
  const { toast } = useToaster()
  const dispatch = useDispatch()

  const toggleIsOpen = (): void => {
    clear()
    setIsOpen(!isOpen)
  }
  const handleImageNameChange = (e: ChangeEvent<HTMLInputElement>): void =>
    setImageDescription(e.target.value)

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files || !e.target.files[0]) return
    const file = e.target.files[0]
    setImageName(file.name)
    setImage(file)
  }

  const clear = (): void => {
    setIsOpen(false)
    setImage(undefined)
    setImageName('')
    setImageDescription('')
  }

  const handleUploadClick = async (): Promise<void> => {
    if (!imageName || !imageDescription || !image) return

    try {
      const presignedPost = await createPhoto({
        claimId,
        body: { source: 'ops_admin', description: imageDescription },
      }).unwrap()

      const { url, fields } = presignedPost
      const formData = new FormData()
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value)
      })
      formData.append('file', image)
      try {
        await uploadPhoto({ url, body: formData }).unwrap()
      } catch (err: unknown) {
        // There can be CORS errors despite successfully uploading
        if (
          (err as ErrorType).status !== 'FETCH_ERROR'
        ) {
          toast({
            message: 'An error occurred',
            toastDuration: ToastDuration.short,
            toastColor: ToastColor.red,
          })
          return
        }
      }
      setTimeout(() => dispatch(claimsApi.util.invalidateTags(['claim-photos'])), 7000)
      toast({
        message: 'File successfully uploaded!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
      clear()
    } catch (err: unknown) {
      toast({
        message: 'An error occurred',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return isOpen ? (
    <Stack spacing={1}>
      <Input
        label="File Description"
        data-cy="file-description-input"
        placeholder="Enter file description"
        value={imageDescription}
        onChange={handleImageNameChange}
        id="description"
      />
      <FileInputWrapper>
        <FileInput
          data-cy="attachment-upload-input"
          id="attachment-upload"
          label="File"
          fileExtensions={ALLOWED_FILE_EXTENSIONS}
          onChange={handleFileUpload}
          displayedFile={imageName}
          maxSizeMb={5}
        />
      </FileInputWrapper>
      <ButtonGroup>
        <Button emphasis="medium" text="Cancel" onClick={toggleIsOpen} data-cy="cancel-button" />
        <Button
          data-cy="upload-file-button"
          emphasis="high"
          text="Upload File"
          onClick={handleUploadClick}
          icon={Upload}
          isDisabled={!image || !imageDescription}
        />
      </ButtonGroup>
      {includeDisclaimer && (
        <InlineAlert
          data-cy="attachments-inline-alert"
          color={InlineAlertColor.yellow}
          icon={InfoIcon}
        >
          <strong>Do not upload photos with PII or photos that violate HIPPA</strong>
          <br />
          Personally identifiable information (PII) is any information connected to a specific
          individual that can be used to uncover the individual's identity. PII includes but not
          limited to social security number, full name, address or email address. Health Insurance
          Portability and Accountability act (HIPPA) Title II mandates that anyone who deals with
          individuals' medical data take active steps to keep that data private and secure. To avoid
          any liability for HIPPA violations, do not upload photos with medical data.
        </InlineAlert>
      )}
    </Stack>
  ) : (
    <Button
      emphasis="high"
      text="Upload File"
      onClick={toggleIsOpen}
      icon={Upload}
      data-cy="open-upload-button"
    />
  )
}

const FileInputWrapper = styled.div({
  maxWidth: 500,
})

export { AttachmentsUpload }
