import type { FC } from 'react'
import React from 'react'
import { Subheading } from '@extend/zen'
import type { CategoryProduct } from '@helloextend/extend-api-client'
import { currency, date } from '@extend/client-helpers'
import {
  Header,
  HeaderContainer,
  HeaderCoverageDate,
  HeaderFulfillmentDate,
  HeaderLineItem,
  HeaderListPrice,
  HeaderManufacturerWarrantyLengthLabor,
  HeaderManufacturerWarrantyLengthParts,
  HeaderProductCategory,
  HeaderPurchasePrice,
  HeaderSerialNumber,
  Item,
  NestedTableContainer,
  NestedTableRow,
  StyledCoverage,
  StyledItem,
  StyledLineItem,
  StyledLink,
  StyledLengthParts,
  StyledTitle,
} from './styles'

interface NestedProductsProps {
  bundleProducts: CategoryProduct[]
  productCategory: string
  getProductCoverage: (coverage: CategoryProduct['coverage']) => string
  openProductModal: (product: CategoryProduct) => void
  maxLength?: number
}

const NestedProducts: FC<NestedProductsProps> = ({
  bundleProducts,
  productCategory,
  openProductModal,
  getProductCoverage,
  maxLength,
}) => {
  const gap = (maxLength || 6.5) * 0.45

  return (
    <NestedTableContainer>
      {bundleProducts?.length > 0 && (
        <>
          <Subheading>Component Products</Subheading>
          <br />

          <HeaderContainer>
            <Header>Title</Header>
            <Header
              style={{
                marginLeft: `${gap + 7.25}rem`,
              }}
            >
              Product Reference ID
            </Header>
            <HeaderProductCategory>Product Category</HeaderProductCategory>
            <HeaderPurchasePrice>Purchase Price</HeaderPurchasePrice>
            <HeaderListPrice>List Price</HeaderListPrice>
            <HeaderLineItem>Line Item ID</HeaderLineItem>
            <Header>Transaction Date</Header>
            <HeaderFulfillmentDate>Fulfillment Date</HeaderFulfillmentDate>
            <HeaderSerialNumber>Serial Number</HeaderSerialNumber>
            <HeaderCoverageDate>Coverage Date</HeaderCoverageDate>
            <HeaderManufacturerWarrantyLengthLabor>
              Manufacturer Warranty Length Labor
            </HeaderManufacturerWarrantyLengthLabor>
            <HeaderManufacturerWarrantyLengthParts>
              Manufacturer Warranty Length Parts
            </HeaderManufacturerWarrantyLengthParts>
          </HeaderContainer>

          <br />
        </>
      )}

      {bundleProducts.map((product, index) => {
        const title = product?.title || 'Bundle Product'

        return (
          <NestedTableRow key={index}>
            <StyledTitle>
              <StyledLink onClick={() => openProductModal(product)}>{title}</StyledLink>
            </StyledTitle>
            <Item />
            <StyledItem
              style={{
                marginLeft: `${gap}rem`,
              }}
            >
              {product.referenceId}
            </StyledItem>
            <Item>{productCategory}</Item>
            <Item>{currency.format(product.purchasePrice)}</Item>
            <Item>{currency.format(product.listPrice)}</Item>
            <StyledLineItem>{product.lineItemId}</StyledLineItem>
            <Item />
            <Item>
              {product.transactionDate
                ? date.format(Number(product.transactionDate), 'MMM DD YYYY')
                : ''}
            </Item>
            <Item>
              {product.fulfillmentDate
                ? date.format(Number(product.fulfillmentDate), 'MMM DD YYYY')
                : ''}
            </Item>
            <Item>{product.serialNumber}</Item>
            <StyledCoverage>{getProductCoverage(product?.coverage)}</StyledCoverage>
            <Item>{product.manufacturerWarrantyLengthLabor || 0}</Item>
            <StyledLengthParts>{product.manufacturerWarrantyLengthParts || 0}</StyledLengthParts>
          </NestedTableRow>
        )
      })}
    </NestedTableContainer>
  )
}

export default NestedProducts
