import React from 'react'
import type { OktaUserStatus, UserGrant } from '@helloextend/extend-api-rtk-query'
import type { ColumnDefs, CellContext, FilterDef } from '@extend/zen'
import UserRole from '../../../../../components/user-role/user-role'
import UserStatusBadge from '../../../../../components/user-status-badge/user-status-badge'
import type { UserWithGrants } from '../../../../../types/users'
import { getUserStatus } from '../../../../../utils/user-status'

type TableAccountUser = {
  name: string
  email: string
  uuid: string
  status: OktaUserStatus
  accountId: string
  grants: UserGrant[]
}

const toTableUser = (user: UserWithGrants, accountId: string): TableAccountUser => {
  return {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    status: user.status,
    uuid: user.uuid,
    accountId,
    grants: user.grants,
  }
}

const toTableUsers = (users: UserWithGrants[], accountId: string): TableAccountUser[] => {
  return users.map((user) => toTableUser(user, accountId))
}

const usersColumns: ColumnDefs<TableAccountUser> = [
  {
    label: 'Name',
    id: 'name',
    search: 'explicit',
    renderCell: (cellData: CellContext<TableAccountUser, string>) => cellData.getValue(),
  },
  {
    id: 'email',
    label: 'Email',
    search: 'explicit',
    renderCell: (cellData: CellContext<TableAccountUser, string>) => cellData.getValue(),
  },
  {
    id: 'grants',
    label: 'Roles',
    renderCell: (cellData: CellContext<TableAccountUser, UserGrant[]>) => (
      <UserRole
        grants={cellData.getValue()}
        roleFilter="merchant"
        accountId={cellData.row.original.accountId}
      />
    ),
  },
  {
    id: 'status',
    label: 'Status',
    accessorFn: (row: TableAccountUser) => {
      const { status, grants, accountId } = row
      return getUserStatus({
        userStatus: status,
        userGrants: grants,
        roleFilter: 'merchant',
        accountId,
      })
    },
    renderCell: (cellData: CellContext<TableAccountUser, string>) => {
      return (
        <UserStatusBadge
          grants={cellData.row.original.grants}
          oktaUserStatus={cellData.row.original.status}
          roleFilter="merchant"
          accountId={cellData.row.original.accountId}
        />
      )
    },
  },
]

const FILTER_DEFS: FilterDef[] = [
  {
    type: 'group',
    filterDefs: [
      {
        id: 'status',
        type: 'select',
        label: 'Status',
        isMultiSelect: true,
        options: [
          {
            display: 'Active',
            value: 'Active',
          },
          { display: 'Pending', value: 'Pending' },
          {
            display: 'Deactivated',
            value: 'Deactivated',
          },
        ],
      },
    ],
  },
]

export type { TableAccountUser }
export { toTableUsers, usersColumns, FILTER_DEFS }
