import type { Order } from '@helloextend/extend-api-client'
import { InputType, DataPropertyType } from '@extend/zen'
import type { CustomerInformation, PaymentInformation } from './schema'
import { mapOrderToOrderAnalyticsInfoSchema } from './schema'
import type { CompactFormFieldData } from '../../../../../../components/compact-form/types'

export interface OrderAnalyticsInfoFormFields {
  CUSTOMER: CompactFormFieldData[]
  PAYMENT: CompactFormFieldData[]
}

export const getAnalyticsInfoFormFields = (order: Order): OrderAnalyticsInfoFormFields => {
  const analyticsInfo = mapOrderToOrderAnalyticsInfoSchema(order)

  return {
    CUSTOMER: getCustomerFields(analyticsInfo.customer),
    PAYMENT: getPaymentFields(analyticsInfo.payment),
  }
}

const getCustomerFields = ({
  customerStatus = '',
  customerType = '',
  loyaltyStatus = '',
  marketingOptIn = false,
}: CustomerInformation): CompactFormFieldData[] => [
  {
    id: 'customer.customerStatus',
    label: 'Customer Status',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: customerStatus,
    isEditable: true,
  },
  {
    id: 'customer.customerType',
    label: 'Customer Type',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: customerType,
    isEditable: true,
  },
  {
    id: 'customer.loyaltyStatus',
    label: 'Loyalty Status',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: loyaltyStatus,
    isEditable: true,
  },
  {
    id: 'customer.marketingOptIn',
    label: 'Marketing Opt In',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: String(marketingOptIn ? true : ''),
    isEditable: true,
  },
]

const getPaymentFields = ({
  paymentMethod = '',
  paymentInstrument = '',
  totalDiscounts,
  checkoutId = '',
  discountSource = '',
  fileName = '',
}: PaymentInformation): CompactFormFieldData[] => [
  {
    id: 'payment.paymentMethod',
    label: 'Payment Method',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: paymentMethod,
    isEditable: true,
  },
  {
    id: 'payment.paymentInstrument',
    label: 'Payment Instrument',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: paymentInstrument,
    isEditable: true,
  },
  {
    id: 'payment.totalDiscounts',
    label: 'Total Discounts',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: String(totalDiscounts || ''),
    isEditable: true,
  },
  {
    id: 'payment.checkoutId',
    label: 'Checkout ID',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: checkoutId,
    isEditable: true,
  },
  {
    id: 'payment.discountSource',
    label: 'Discount Source',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: discountSource,
    isEditable: true,
  },
  {
    id: 'payment.fileName',
    label: 'File Name',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: fileName,
    isEditable: true,
  },
]
