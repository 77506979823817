import React from 'react'
import type { Order, LineItemStatus, LineItemType } from '@helloextend/extend-api-client'
import { Badge } from '@extend/zen'
import { date, currency } from '@extend/client-helpers'
import type { ColumnDefs, BadgeProps, CellContext } from '@extend/zen'

type OrderLineItem = Order['lineItems'][number]

/**
 * Returns the nested property value from the OrderLineItem or an empty string if the propPath doesn't exist
 * @param data the cell context object
 * @param propPath optional dot separated path to field to be rendered. ie: 'product.name'
 */
const getNestedPropertyValue = (data: CellContext<OrderLineItem, any>, propPath: string): any => {
  let currentObject: any = data.row.original
  const properties = propPath.split('.')

  for (const prop of properties) {
    if (!(prop in currentObject)) return ''
    currentObject = currentObject[prop]
  }

  return currentObject
}

const renderCurrencyCell = (
  data: CellContext<OrderLineItem, Record<string, unknown>>,
  propPath?: string,
): string => {
  const moneyValue = propPath ? getNestedPropertyValue(data, propPath) : data.getValue()

  return moneyValue || moneyValue === 0 ? `${currency.formatWithoutCurrencySymbol(moneyValue)}` : ''
}

const renderDateCell = (
  data: CellContext<OrderLineItem, Record<string, unknown>>,
  propPath?: string,
): string => {
  const dateValue = propPath ? getNestedPropertyValue(data, propPath) : data.getValue()

  if (!dateValue) return ''
  return date.format(new Date(dateValue).getTime(), 'MMMM DD, YYYY')
}

const renderCell = (data: CellContext<OrderLineItem, unknown>, propPath?: string): string => {
  return propPath ? getNestedPropertyValue(data, propPath) : data.getValue() || ''
}

const renderType = (cellData: CellContext<OrderLineItem, LineItemType>): string => {
  const type: LineItemType = cellData.getValue()
  const hasContractId = 'contractId' in cellData.row.original

  if (type === 'shipments' && hasContractId) {
    return 'SP Contract'
  }

  return formatStringField(type)
}

const renderBadge = (data: CellContext<OrderLineItem, string>): JSX.Element | string => {
  const status = data.getValue()
  if (!status) return ''

  const color = STATUS_TO_COLOR_MAPPER[status as LineItemStatus]
  return <Badge text={formatStringField(status)} color={color} />
}

const formatStringField = (status: string): string => {
  return status
    .split('_')
    .map((word) => word.at(0)?.toUpperCase() + word.slice(1))
    .join(' ')
}

const STATUS_TO_COLOR_MAPPER: Record<LineItemStatus, BadgeProps['color']> = {
  fulfilled: 'green',
  refunded: 'green',
  unfulfilled: 'yellow',
  contract_pending: 'yellow',
  pending: 'yellow',
  canceled: 'red',
  contract_failed: 'red',
  cancel_failed: 'red',
  lead_failed: 'red',
  quote_failed: 'red',
}

export const lineItemsTableColumns: ColumnDefs<OrderLineItem> = [
  {
    label: 'Type',
    id: 'type',
    renderCell: renderType,
    isSortable: true,
  },
  {
    label: 'Status',
    id: 'status',
    renderCell: renderBadge,
    isSortable: true,
  },
  {
    label: 'Line Item ID',
    id: 'id',
    renderCell: (cellData) => renderCell(cellData),
  },
  {
    label: 'Line Item Transaction ID',
    id: 'lineItemTransactionId',
    renderCell: (cellData) => renderCell(cellData),
    isSortable: true,
  },
  {
    label: 'Product Name',
    id: 'productName',
    renderCell: (cellData) => renderCell(cellData, 'product.name'),
  },
  {
    label: 'Created Date',
    id: 'createdAt',
    renderCell: (cellData) => renderDateCell(cellData),
    isSortable: true,
  },
  {
    label: 'Updated Date',
    id: 'updatedAt',
    renderCell: (cellData) => renderDateCell(cellData),
    isSortable: true,
  },
  {
    label: 'Contract ID',
    id: 'contractId',
    renderCell: (cellData) => {
      const contractId = cellData.getValue()
      return contractId ? (
        <a href={`/admin/contracts/${contractId}`} target="_blank" rel="noreferrer">
          {contractId}
        </a>
      ) : (
        ''
      )
    },
  },
  {
    label: 'Lead Token',
    id: 'leadToken',
    renderCell: (cellData) => renderCell(cellData),
  },
  {
    label: 'Product Reference ID',
    id: 'productId',
    renderCell: (cellData) => renderCell(cellData, 'product.id'),
  },
  {
    label: 'Product List Price',
    id: 'productListPrice',
    renderCell: (cellData) => renderCurrencyCell(cellData, 'product.listPrice'),
  },
  {
    label: 'Product Purchase Price',
    id: 'productPurchasePrice',
    renderCell: (cellData) => renderCurrencyCell(cellData, 'product.purchasePrice'),
  },
  {
    label: 'Product Purchase Date',
    id: 'productPurchaseDate',
    renderCell: (cellData) => renderDateCell(cellData, 'product.purchaseDate'),
  },
  {
    label: 'Product Category',
    id: 'productCategory',
    renderCell: (cellData) => renderCell(cellData, 'product.category'),
  },
  {
    label: 'Quantity',
    id: 'quantity',
    renderCell: (cellData) => renderCell(cellData),
  },
  {
    label: 'Fulfilled Quantity',
    id: 'fulfilledQuantity',
    renderCell: (cellData) => renderCell(cellData),
  },
  {
    label: 'Fulfilled At',
    id: 'fulfilledAt',
    renderCell: (cellData) => renderDateCell(cellData),
  },
]
