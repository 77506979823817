import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { COLOR } from '@extend/zen'

interface ReadOnlyWrapperProps {
  label?: string
  width?: number | string
}
interface ReadOnlyItemProps {
  label?: string
  values?: string[]
  width?: number | string
}

interface ReadOnlyValueProp {
  value?: string
  contractId?: string
}

type ReadOnlyValueProps = ReadOnlyWrapperProps & ReadOnlyValueProp

const ReadOnlyItems: FC<ReadOnlyItemProps> = ({ label, values, width }) => (
  <ReadOnlyItemWrapper width={width}>
    <LabelWrapper>{label && <LabelText>{label}</LabelText>}</LabelWrapper>
    {values?.map((value) => (
      <ValueWrapper data-cy={label}>{value}</ValueWrapper>
    ))}
  </ReadOnlyItemWrapper>
)

const ReadOnlyWrapper: FC<ReadOnlyWrapperProps> = ({ label, width, children }) => (
  <ReadOnlyItemWrapper width={width}>
    <LabelWrapper>{label && <LabelText>{label}</LabelText>}</LabelWrapper>
    {children}
  </ReadOnlyItemWrapper>
)

const ReadOnlyValue: FC<ReadOnlyValueProps> = ({ label, value }) => (
  <ValueWrapper data-cy={label}>{value}</ValueWrapper>
)

const ReadOnlyValueLink: FC<ReadOnlyValueProps> = ({ label, value, contractId }) => {
  const history = useHistory()
  const handleOnClick = (id?: string): void => {
    if (id) {
      history.push(`/admin/contracts/${id}`)
    }
  }
  return (
    <ValueWrapper data-cy={label}>
      {value}
      <ValueWrapperLink onClick={() => handleOnClick(contractId)}>{contractId}</ValueWrapperLink>
    </ValueWrapper>
  )
}

export { ReadOnlyItems, ReadOnlyWrapper, ReadOnlyValue, ReadOnlyValueLink }

const ReadOnlyItemWrapper = styled.div<{ width?: number | string }>(({ width }) => ({
  width: width ?? 'inherit',
}))

const LabelWrapper = styled.label({
  fontFamily: 'Nunito Sans, sans-serif',
})

const ValueWrapper = styled.div({
  marginTop: 4,
  lineHeight: '24px',
  display: 'block',
})

const ValueWrapperLink = styled.a({
  color: COLOR.BLUE[800],
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const LabelText = styled.div({
  color: COLOR.NEUTRAL[600],
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
  lineHeight: '24px',
})
