import type { PlanTermsVersion } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'
import { DataProperty, DataPropertyType, Grid, Section } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'

type BasicInformationProps = {
  termsDetails: PlanTermsVersion
}

const BasicInformation: FC<BasicInformationProps> = ({ termsDetails }) => {
  return (
    <Section heading="Basic Information">
      <Grid columns={4} spacing={2}>
        <DataProperty
          label="Terms Id"
          type={DataPropertyType.string}
          value={termsDetails.termsId}
        />
        <DataProperty label="version" type={DataPropertyType.number} value={termsDetails.version} />
        <DataProperty label="Created" type={DataPropertyType.date} value={termsDetails.createdAt} />
        <DataProperty label="Updated" type={DataPropertyType.date} value={termsDetails.updatedAt} />
      </Grid>{' '}
    </Section>
  )
}

export { BasicInformation }
