import type { FC } from 'react'
import React, { useState, useEffect, useCallback } from 'react'
import type { PlanCategory } from '@helloextend/extend-api-client'
import {
  AdvancedSelect,
  Modal,
  InlineAlert,
  InlineAlertColor,
  Stack,
  Information,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@extend/zen'
import { useValidateProductMappingsMutation } from '@helloextend/extend-api-rtk-query'
import type {
  ProductMappedRecord,
  ProductsMutateRequest,
  InvalidProductMapped,
} from '@helloextend/extend-api-rtk-query'

interface ValidatePlanCategoryModalProps {
  closeModal: () => void
  planCategories: PlanCategory[]
  productMappings?: ProductMappedRecord[]
  alertCharLimit?: number
  'data-cy'?: string
}

const warrantyStatusOptions = [
  { display: 'Warrantable', value: 'warrantable' },
  { display: 'Non-Warrantable', value: 'non-warrantable' },
]

const truncate = (str: string, n: number): string => {
  return str.length > n ? `${str.slice(0, n - 1)} ... ` : str
}

const ValidatePlanCategoryModal: FC<ValidatePlanCategoryModalProps> = ({
  closeModal,
  planCategories,
  productMappings,
  alertCharLimit = 30,
  'data-cy': dataCy,
}) => {
  const [selectedPlanCategoryId, setSelectedPlanCategoryId] = useState<string>('')
  const [selectedWarrantyStatus, setSelectedWarrantyStatus] = useState<string>('non-warrantable')

  const { toast } = useToaster()

  const [validateMappingPOST, { isLoading: isUpdatedProductLoading }] =
    useValidateProductMappingsMutation()

  const planCategoryOptions = planCategories.map((planCategory) => ({
    display: planCategory.name,
    value: planCategory.id,
  }))

  const productIdsStrFull =
    productMappings?.map((productMapping) => productMapping.product_id).join(', ') ?? ''
  const productIdsStr = truncate(productIdsStrFull, alertCharLimit)
  const isWarrantyStatusDisabled = selectedPlanCategoryId === ''

  const handleClickSubmit = async (): Promise<void> => {
    const productsMutateRequest: ProductsMutateRequest = {
      store_id: productMappings?.[0].store_id ?? '',
      product_ids: productMappings?.map((productMapping) => productMapping.product_id) ?? [],
      warranty_status: selectedWarrantyStatus,
      plan_category_id: selectedPlanCategoryId,
    }
    try {
      const result = await validateMappingPOST(productsMutateRequest).unwrap()
      const validItems = result.valid_items
      const invalidItems = result.invalid_items
      loadPostValidationMessage(validItems, invalidItems)
      handleCloseReset()
    } catch (error) {
      toast({
        message: `Error, most likely timeout; some product validations may have succeeded. Actual error from api call:  ${JSON.stringify(
          error,
        )}`,
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.indefinite,
      })
    }
  }

  const loadPostValidationMessage = useCallback(
    (validItems: ProductMappedRecord[], invalidItems: InvalidProductMapped[]): void => {
      if (validItems.length > 0) {
        const msg = 'Successfully validated '.concat(validItems.length.toString(), ' item(s).')
        toast({
          message: msg,
          toastColor: ToastColor.green,
          toastDuration: ToastDuration.long,
        })
      }
      if (invalidItems.length > 0) {
        const errList: string[] = invalidItems.map((val) =>
          '   .('.concat(val.item.title, ' [', val.item.product_id, ']): ', val.error),
        )
        toast({
          message: invalidItems.length.toString().concat(' item(s) not validated:', ...errList),
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.indefinite,
        })
      }
    },
    [toast],
  )

  const handleCloseReset = useCallback((): void => {
    setSelectedPlanCategoryId('')
    closeModal() // closes modal in parent component
  }, [closeModal])

  // Triggers when plan category dropdown is changed
  useEffect(() => {
    if (selectedPlanCategoryId === '') {
      // warranty_status dropdown is disabled, locked to non-warrantable
      setSelectedWarrantyStatus('non-warrantable')
    } else {
      // warranty_status dropdown is enabled, default to warrantable
      setSelectedWarrantyStatus('warrantable')
    }
  }, [selectedPlanCategoryId])

  return (
    <Modal
      heading="Validate Plan Category ID"
      primaryButtonProps={{
        text: 'Submit',
        onClick: handleClickSubmit,
        isProcessing: isUpdatedProductLoading,
      }}
      secondaryButtonProps={{ text: 'Cancel', onClick: handleCloseReset }}
      onDismissRequest={handleCloseReset}
      data-cy={dataCy}
    >
      <Stack justify="space-between" spacing={3}>
        <InlineAlert color={InlineAlertColor.blue}>
          Validating {productMappings?.length} products: {productIdsStr}
          {productIdsStrFull.length > alertCharLimit && (
            <Information buttonSize="small">{productIdsStrFull}</Information>
          )}
        </InlineAlert>
        <AdvancedSelect
          id="plan-category-id-select"
          label="Plan Category ID"
          options={planCategoryOptions}
          placeholder="Select Plan Category ID"
          onChange={(e) => setSelectedPlanCategoryId(e.target.value)}
          value={selectedPlanCategoryId}
          multiple={false}
          showSearch
          data-cy="plan-category-select"
        />
        <AdvancedSelect
          id="warranty-status-id-select"
          label="Warranty Status"
          options={warrantyStatusOptions}
          isNotClearable
          onChange={(e) => setSelectedWarrantyStatus(e.target.value)}
          value={selectedWarrantyStatus}
          multiple={false}
          isDisabled={isWarrantyStatusDisabled}
          data-cy="warranty-status-select"
        />
      </Stack>
    </Modal>
  )
}

export type { ValidatePlanCategoryModalProps }
export { ValidatePlanCategoryModal }
