import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { NavLink } from 'react-router-dom'

type LinkTabProps = {
  to: string
}

const LinkTab: FC<LinkTabProps> = ({ to, children }) => (
  <StyledLink to={to} exact activeClassName="active">
    {children}
  </StyledLink>
)

const StyledLink = styled(NavLink)({
  textDecoration: 'none',
  color: COLOR.NEUTRAL[800],
  fontSize: 16,
  lineHeight: '24px',
  padding: `16px 0px`,
  zIndex: 1,
  display: 'flex',
  gap: '4px',
  '&.active': {
    color: COLOR.NEUTRAL[1000],
    borderBottom: `2px solid ${COLOR.BLUE[800]}`,
  },
})

export { LinkTab }
