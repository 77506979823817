import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import ReactDatePicker from 'react-datepicker'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import { bp } from '@extend/client-helpers'
import { Button, COLOR, Date as DateIcon } from '@extend/zen'
import { Menu } from '@helloextend/merchants-ui'

interface DatepickerMenuProps {
  id?: string
  buttonText: string
  'data-cy'?: string
  date?: number | null
  minDate?: Date
  maxDate?: Date
  onChange: (value: number | null) => void
  isDisabled?: boolean
  isCalendarDisabled?: boolean
}

const DatepickerMenu: FC<DatepickerMenuProps> = ({
  id,
  buttonText,
  'data-cy': dataCy,
  minDate,
  maxDate,
  date = null,
  isDisabled,
  isCalendarDisabled,
  onChange,
}) => {
  const [isMenuOpen, { toggle, off }] = useToggle()

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    off()
  })

  const selectedDate = useMemo(() => (date ? new Date(date) : null), [date])

  const handleDateChange = (selected: Date | null): void => {
    const newDate = selected?.getTime() || null
    onChange(date === newDate ? null : newDate)
    off()
  }

  return (
    <Wrapper ref={ref}>
      <Button
        emphasis="medium"
        isToggled={isMenuOpen}
        data-cy={dataCy ?? 'datepicker-button'}
        text={buttonText}
        onClick={toggle}
        icon={DateIcon}
        iconPosition="left"
        isDisabled={isDisabled}
      />
      <MenuWrapper>
        <Menu isOpen={isMenuOpen} width={0} maxHeight={0} position="left">
          <DateWrapper fullWidth fill={COLOR.BLUE[800]}>
            <ReactDatePicker
              data-cy={dataCy}
              disabled={isCalendarDisabled}
              id={id}
              open={isMenuOpen}
              onChange={() => {}}
              onSelect={handleDateChange}
              inline
              minDate={minDate}
              maxDate={maxDate}
              selected={selectedDate}
            />
          </DateWrapper>
        </Menu>
      </MenuWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
  width: 184,
}))

const MenuWrapper = styled.div({
  width: 368,
  position: 'absolute',
  top: 38,
})

interface DateWrapperProps {
  fill: string
  disabled?: boolean
  fullWidth?: boolean
}

const DateWrapper = styled('div')<DateWrapperProps>(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [bp.md]: {
      flexDirection: 'row',
    },
  },
  ({ disabled, fill, fullWidth }) => ({
    '.react-datepicker-wrapper': {
      width: fullWidth ? '100%' : 'auto',
      input: {
        width: fullWidth ? '100%' : 'auto',
        boxSizing: fullWidth ? 'border-box' : 'inherit',
      },
    },
    '.react-datepicker': {
      fontFamily: 'Nunito Sans, Arial, Helvetica, sans-serif',
      border: 'none',
      pointerEvents: disabled ? 'none' : 'auto',
      [bp.sm]: {
        border: `1px solid ${COLOR.NEUTRAL[300]}`,
        boxShadow: '0px 3px 5px 0px rgba(209, 205, 205, 0.5)',
        position: 'relative',
      },
    },
    '.react-datepicker__triangle': {
      display: 'none',
    },
    '.react-datepicker .react-datepicker__header': {
      background: COLOR.WHITE,
      border: 'none',
    },
    [`.react-datepicker .react-datepicker__current-month,
    .react-datepicker .react-datepicker__day, .react-datepicker .react-datepicker__day-name`]: {
      color: COLOR.NEUTRAL[1000],
    },
    [`.react-datepicker .react-datepicker__day--outside-month, .react-datepicker .react-datepicker__day--disabled`]:
      {
        color: COLOR.NEUTRAL[300],
      },
    '.react-datepicker .react-datepicker__day--today.react-datepicker__day--outside-month': {
      color: COLOR.WHITE,
    },
    '.react-datepicker .react-datepicker__day--today': {
      fontWeight: 'normal',
      background: COLOR.NEUTRAL[300],
    },
    '.react-datepicker .react-datepicker__day': {
      transition: 'all .25s ease-in-out',
      width: '32px',
      height: '32px',
      margin: '8px',
      border: `1px solid ${COLOR.WHITE}`,
      fontSize: '12px',
      borderRadius: '50%',
      padding: 0,
      lineHeight: '32px',
    },
    '.react-datepicker .react-datepicker__day:hover': {
      background: COLOR.WHITE,
      borderRadius: '50%',
      border: `1px solid ${fill}`,
    },
    '.react-datepicker .react-datepicker__day--disabled:hover': {
      borderRadius: '50%',
      border: `1px solid ${COLOR.NEUTRAL[300]}`,
    },
    [`.react-datepicker .react-datepicker__day--selected,
  .react-datepicker .react-datepicker__day--selected:hover, .react-datepicker .react-datepicker__day--keyboard-selected:not(.react-datepicker .react-datepicker__day--today)`]:
      {
        background: fill,
        color: COLOR.WHITE,
        border: 'none',
      },
    '.react-datepicker .react-datepicker__day--keyboard-selected:hover': {
      color: COLOR.NEUTRAL[1000],
    },
    '.react-datepicker .react-datepicker__day-name': {
      width: '32px',
      height: '32px',
      margin: '8px',
      fontSize: '12px',
      marginTop: '24px',
      fontWeight: 600,
    },
    '.react-datepicker .react-datepicker__navigation--previous': {
      borderRightColor: fill,
    },
    '.react-datepicker .react-datepicker__navigation--next': {
      borderLeftColor: fill,
    },
    '.react-datepicker .react-datepicker__navigation--previous:hover': {
      outline: 'none',
      '@media (hover: none)': {
        borderRightColor: fill,
      },
      '@media (hover: hover)': {
        borderRightColor: fill,
      },
    },
    '.react-datepicker .react-datepicker__navigation--next:hover': {
      outline: 'none',
      '@media (hover: none)': {
        borderLeftColor: fill,
      },
      '@media (hover: hover)': {
        borderLeftColor: fill,
      },
    },
  }),
)

export { DatepickerMenu }
