import type { Order } from '@helloextend/extend-api-client'
import * as Yup from 'yup'

const saleOriginInformationSchema = Yup.object()
  .shape({
    agentId: Yup.string().notRequired(),
    channel: Yup.string().notRequired(),
    integratorId: Yup.string().notRequired(),
    locationId: Yup.string().notRequired(),
    platform: Yup.string().notRequired(),
  })
  .defined()

const storeAddressInformationSchema = Yup.object()
  .shape({
    address1: Yup.string().notRequired(),
    address2: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    countryCode: Yup.string().notRequired(),
    postalCode: Yup.string().notRequired(),
    province: Yup.string().notRequired(),
  })
  .defined()

type SaleOriginInformation = Yup.InferType<typeof saleOriginInformationSchema>
type StoreAddressInformation = Yup.InferType<typeof storeAddressInformationSchema>

interface OrderAdditionalSalesInfoFormSchemaInterface {
  saleOrigin: SaleOriginInformation
  storeAddress: StoreAddressInformation
}

export function mapOrderToOrderAdditionalInfoSchema(
  order: Order,
): OrderAdditionalSalesInfoFormSchemaInterface {
  const saleOrigin = order?.saleOrigin
  const storeAdddress = saleOrigin?.storeAddress

  return {
    saleOrigin: {
      agentId: order?.saleOrigin?.agentId || '',
      channel: order?.saleOrigin?.channel || '',
      integratorId: order?.saleOrigin?.integratorId || '',
      locationId: order?.saleOrigin?.locationId || '',
      platform: order?.saleOrigin?.platform || '',
    },
    storeAddress: {
      address1: storeAdddress?.address1 || '',
      address2: storeAdddress?.address2 || '',
      city: storeAdddress?.city || '',
      countryCode: storeAdddress?.countryCode || '',
      postalCode: storeAdddress?.postalCode || '',
      province: storeAdddress?.province || '',
    },
  }
}

export type { SaleOriginInformation, StoreAddressInformation }
