import { date as dateHelper } from '@extend/client-helpers'

/**
 * Converts a UTC timestamp to a formatted date string in Pacific Standard Time (PST).
 *
 * @param {number} utcTimestamp - The UTC timestamp to format.
 * @returns {string} The formatted date string in PST, or an empty string if the timestamp is invalid.
 */
export const formatTimestampToPST = (utcTimestamp?: number): string => {
  const timezone = 'America/Los_Angeles'

  return utcTimestamp
    ? dateHelper.format(utcTimestamp, 'MMMM DD YYYY h:mm:ss A', timezone) + ' PST'
    : ''
}
