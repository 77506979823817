import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { useGetPlanSetCategoriesQuery, useGetStoreQuery } from '@helloextend/extend-api-rtk-query'
import { Breadcrumbs, Button, COLOR, Spinner, SettingsIcon } from '@extend/zen'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { usePermissions } from '../../../../../hooks/use-permissions'
import { Permission } from '../../../../../lib/permissions'
import { TabMenu } from '../../../../../components/tab-menu/tab-menu'
import { Settings as SettingsSection } from './settings'
import { IntegrationSettings } from './integration-settings'
import { WDConfiguration as WDConfigurationTab } from './wd-configuration'
import { PlanAndPricing as PlanAndPricingSettings } from './plan-and-pricing'
import { ProductsDataTable } from './products/products-data-table'
import { ShippingProtection as ShippingProtectionSettings } from './shipping-protection/shipping-protection'
import { PromotionSettings } from './promotion/promotion-settings'
import { AisDataTable } from './ais/ais-data-table/record-data-table'

enum StoreTabs {
  Products = 'products',
  Leads = 'leads',
  ProductProtection = 'product-protection',
  Settings = 'settings',
  Integration = 'integration',
  WDConfiguration = 'wd-configuration',
  ShippingProtection = 'shipping-protection',
  Promotion = 'promotion',
  AIS = 'ais',
}

const StoreDetails: FC = () => {
  const { hasPermission } = usePermissions()
  const { storeId } = useParams<{ storeId: string }>()
  const { push } = useHistory()
  const { url } = useRouteMatch()
  const { data: store, isLoading, error } = useGetStoreQuery({ storeId, version: 'latest' })
  const { data: planSetCategoryListResponse } = useGetPlanSetCategoriesQuery()

  const sortedCategories = useMemo(() => {
    if (!planSetCategoryListResponse) {
      return []
    }

    const categories = planSetCategoryListResponse.items.map((category) => ({
      display: category.name,
      value: category.name,
      id: category.id,
    }))

    return categories.sort((a, b) => a.display.localeCompare(b.display))
  }, [planSetCategoryListResponse])
  const menuTabs = useMemo(() => {
    const tabs = [
      { text: 'Products', key: StoreTabs.Products },
      { text: 'Product Protection', key: StoreTabs.ProductProtection },
      { text: 'Shipping Protection', key: StoreTabs.ShippingProtection },
      { text: 'Settings', key: StoreTabs.Settings },
      { text: 'Integration', key: StoreTabs.Integration },
      { text: 'Promotion', key: StoreTabs.Promotion },
      { text: 'AIS', key: StoreTabs.AIS },
    ]

    if (hasPermission(Permission.ManageWarrantyDecisioning)) {
      tabs.push({ text: 'WD Configuration', key: StoreTabs.WDConfiguration })
    }
    return tabs
  }, [hasPermission])
  const { toastError } = useStandardToast()

  const activeTab = useMemo((): StoreTabs => {
    const tabPath = url.split('/').pop()
    switch (tabPath) {
      case `settings`:
        return StoreTabs.Settings
      case `products`:
        return StoreTabs.Products
      case `leads`:
        return StoreTabs.Leads
      case `product-protection`:
        return StoreTabs.ProductProtection
      case `shipping-protection`:
        return StoreTabs.ShippingProtection
      case `integration-settings`:
        return StoreTabs.Integration
      case `wd-configuration`:
        return StoreTabs.WDConfiguration
      case 'promotion':
        return StoreTabs.Promotion
      case 'ais':
        return StoreTabs.AIS
      default:
        return StoreTabs.Products
    }
  }, [url])

  const handleTabClick = (tab: string): void => {
    let tabUrl = `/admin/stores/${storeId}`
    switch (tab) {
      case StoreTabs.Settings:
        tabUrl += `/settings`
        break
      case StoreTabs.Products:
        tabUrl += `/products`
        break
      case StoreTabs.Leads:
        tabUrl += `/leads`
        break
      case StoreTabs.ProductProtection:
        tabUrl += `/product-protection`
        break
      case StoreTabs.ShippingProtection:
        tabUrl += `/shipping-protection`
        break
      case StoreTabs.Integration:
        tabUrl += `/integration-settings`
        break
      case StoreTabs.WDConfiguration:
        tabUrl += `/wd-configuration`
        break
      case StoreTabs.Promotion:
        tabUrl += `/promotion`
        break
      case StoreTabs.AIS:
        tabUrl += `/ais`
        break
      default:
        tabUrl += '/products'
    }
    push(tabUrl)
  }

  const handleAccountManagementClick = useCallback(() => {
    if (store?.accountId) {
      push(`/admin/accounts/${store.accountId}/stores`)
    }
  }, [store?.accountId, push])

  useEffect(() => {
    if ((error as FetchBaseQueryError)?.status === 404) {
      toastError('Store not found. You were redirected to the Stores page.')
      push('/admin/stores')
    }
  }, [error, push, toastError])

  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </LoadingContainer>
    )
  }

  if (!store) {
    return null
  }

  return (
    <>
      <HeaderContainer>
        <div>
          <Breadcrumbs
            crumbs={[
              {
                text: 'Stores',
                to: '/admin/stores',
              },
              {
                text: store?.name || 'Store',
              },
            ]}
            data-cy="store-details-breadcrumbs"
          />
          <Title data-cy="store-details-name">{store.name}</Title>
          <StoreIdContainer data-cy="store-details-id">Store ID: {storeId}</StoreIdContainer>
        </div>
        <div>
          <Button
            emphasis="medium"
            text="Account Management"
            icon={SettingsIcon}
            onClick={handleAccountManagementClick}
            data-cy="store-details-account-management-button"
          />
        </div>
      </HeaderContainer>
      <TabMenu tabs={menuTabs} onClick={handleTabClick} activeTab={activeTab} />
      {activeTab === StoreTabs.Products && (
        <SectionContainer>
          <ProductsDataTable storeId={storeId} currencyCode={store?.currencyCode || 'USD'} />
        </SectionContainer>
      )}
      {activeTab === StoreTabs.ProductProtection && (
        <SectionContainer>
          <PlanAndPricingSettings
            selectCategories={sortedCategories}
            planCategories={planSetCategoryListResponse?.items || []}
            storeId={storeId}
          />
        </SectionContainer>
      )}
      {activeTab === StoreTabs.ShippingProtection && (
        <SectionContainer>
          <ShippingProtectionSettings selectCategories={sortedCategories} store={store} />
        </SectionContainer>
      )}
      {activeTab === StoreTabs.Settings && (
        <SectionContainer>
          <SettingsSection storeId={storeId} />
        </SectionContainer>
      )}
      {activeTab === StoreTabs.Integration && (
        <SectionContainer>
          <IntegrationSettings storeId={storeId} />
        </SectionContainer>
      )}
      {hasPermission(Permission.ManageWarrantyDecisioning) &&
        activeTab === StoreTabs.WDConfiguration && (
          <SectionContainer>
            <WDConfigurationTab storeId={storeId} storeName={store.name ?? ''} />
          </SectionContainer>
        )}
      {activeTab === StoreTabs.Promotion && (
        <SectionContainer>
          <PromotionSettings storeId={storeId} />
        </SectionContainer>
      )}
      {activeTab === StoreTabs.AIS && (
        <SectionContainer>
          <AisDataTable storeId={storeId} />
        </SectionContainer>
      )}
    </>
  )
}

const LoadingContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const Title = styled.h1({
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  margin: '24px 0 8px',
})

const StoreIdContainer = styled.p({
  marginTop: '0',
})

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const SectionContainer = styled.div({
  marginTop: 24,
})

export { StoreDetails }
