import type { FC, MouseEventHandler } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { ButtonGroup, Button, AccordionSection, COLOR } from '@extend/zen'

export interface CompactFormProps {
  dataCy?: string
  handleSubmit?: () => void
  handleCancel?: () => void
  handleClickEdit?: MouseEventHandler<HTMLButtonElement>
  onToggleRequest: () => void
  isEditing: boolean
  isEditable?: boolean
  isSubmitDisabled: boolean
  isSubmitting: boolean
  isExpanded?: boolean
  shouldStartExpanded?: boolean
  heading?: string
  isToggleDisabled?: boolean
}

const CompactForm: FC<CompactFormProps> = ({
  dataCy,
  handleSubmit,
  handleCancel,
  handleClickEdit,
  isEditing,
  isEditable,
  isExpanded,
  isSubmitDisabled,
  isSubmitting,
  children,
  onToggleRequest,
  heading = '',
  isToggleDisabled = false,
}) => {
  return (
    <CompactFormContainer>
      <AccordionSection
        data-cy={dataCy}
        isExpanded={isExpanded}
        onToggleRequest={onToggleRequest}
        isToggleDisabled={isToggleDisabled}
        heading={heading}
        toolbar={
          isEditable && (
            <Button
              data-cy="compact-form-edit-button"
              emphasis="low"
              onClick={handleClickEdit}
              size="small"
              text="Edit"
            />
          )
        }
      >
        <FormSectionsContainer>{children}</FormSectionsContainer>
      </AccordionSection>
      {isEditable && isEditing && (
        <EditButtons {...{ handleSubmit, handleCancel, isSubmitDisabled, isSubmitting }} />
      )}
    </CompactFormContainer>
  )
}

const EditButtons: FC<{
  handleSubmit?: () => void
  handleCancel?: () => void
  isSubmitDisabled: boolean
  isSubmitting: boolean
}> = ({ handleCancel, isSubmitDisabled, handleSubmit, isSubmitting }) => {
  return (
    <ButtonGroup>
      <Button type="button" size="regular" emphasis="medium" text="Cancel" onClick={handleCancel} />
      <Button
        type="submit"
        size="regular"
        text="Save Changes"
        isDisabled={isSubmitDisabled}
        onClick={handleSubmit}
        isProcessing={isSubmitting}
        data-cy="save-changes"
      />
    </ButtonGroup>
  )
}

const FormSectionsContainer = styled.section({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 20,
  marginBottom: 32,
})

const CompactFormContainer = styled.div({
  padding: 32,
  marginBottom: 32,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
})

export { CompactForm }
