import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { ButtonColor, ModalProps } from '@extend/zen'
import { COLOR, ModalController, Modal } from '@extend/zen'

export type ConfirmationModalProps = {
  isVisible: boolean
  onConfirm: () => void
  onCancel: () => void
  isProcessing: boolean
  heading: string
  description: string
  confirmButtonText: string
  confirmButtonColor: ButtonColor
  cancelButtonText?: string
  cancelButtonColor?: ButtonColor
  size?: ModalProps['size']
  'data-cy'?: string
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isVisible,
  onConfirm,
  onCancel,
  isProcessing,
  heading,
  description,
  confirmButtonText,
  confirmButtonColor,
  cancelButtonText = 'Cancel',
  cancelButtonColor,
  size,
  'data-cy': dataCy,
}) => (
  <ModalController isOpen={isVisible}>
    <Modal
      data-cy={dataCy}
      heading={heading}
      onDismissRequest={onCancel}
      size={size}
      primaryButtonProps={{
        text: confirmButtonText,
        onClick: onConfirm,
        isProcessing,
        color: confirmButtonColor,
        'data-cy': 'confirmation-modal-confirm-button',
      }}
      secondaryButtonProps={{
        text: cancelButtonText,
        onClick: onCancel,
        color: cancelButtonColor,
        'data-cy': 'confirmation-modal-cancel-button',
      }}
    >
      <Description>{description}</Description>
    </Modal>
  </ModalController>
)

const Description = styled.p({
  color: COLOR.NEUTRAL[800],
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  whiteSpace: 'pre-line',
})
