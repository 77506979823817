import React, { useState, useCallback } from 'react'
import type { FC } from 'react'
import {
  Breadcrumbs,
  COLOR,
  Box,
  HeadingLarge,
  Icon,
  IconSize,
  ToastDuration,
  Edit,
  Paragraph,
  Badge,
  Download,
  useToaster,
  ToastColor,
} from '@extend/zen'
import { useParams } from 'react-router-dom'
import {
  useGetStoreQuery,
  useGetPromotionQuery,
  useGetPromotionProductsQuery,
  useDownloadPromotionProductsMutation,
} from '@helloextend/extend-api-rtk-query'
import type { EmbeddedPromotion } from '@helloextend/extend-api-client'

import { useExtendAuth } from '@extend/package-okta-login'
import { formatDateTime } from './promotions-data-table/promotions-data-table-helpers'
import { getPromotionBadgeProps } from '../../../../../../utils/promotions'
import { PromotionEditModal } from './components/promotion-edit-modal'
import {
  Head,
  HeaderContainer,
  ContainTitle,
  Title,
  ContainBadge,
  PromoptionIdContainer,
  Container,
  Body,
  PromotionName,
  Key,
  Item,
  ContainProductsDownload,
  DownloadIconContainer,
  IconContainer,
  IconText,
} from './styles'
import { getUserEmailFromToken } from '../../../../../../lib/jwt'

interface PromotionStoreDetailsPathParams {
  storeId: string
  promotionId: string
}

const buidPromotionFileName = (promotionName: string | undefined): string => {
  if (!promotionName) return 'promotion-products.csv'

  const fileName = promotionName.replace(/ /g, '-').toLowerCase()
  return `${fileName}.csv`
}

export const PromotionStoreDetails: FC = () => {
  const { toast } = useToaster()
  const { token: accessToken } = useExtendAuth()
  const email = getUserEmailFromToken(accessToken) ?? ''
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { storeId, promotionId } = useParams<PromotionStoreDetailsPathParams>()
  const storeRequestProps = { storeId, version: 'latest' }
  const { data: store, isLoading } = useGetStoreQuery(storeRequestProps)

  const promotionRequestProps = { promotionId, email, ...storeRequestProps }
  const { data: promotion, isLoading: promotionIsLoading } =
    useGetPromotionQuery(promotionRequestProps)
  const { data: promotionProducts } = useGetPromotionProductsQuery(promotionRequestProps)
  const hasPromotion = !isLoading && !promotionIsLoading

  const products = promotionProducts?.items
  const hasPromotionProducts = products && products.length > 0
  const { text, color } = getPromotionBadgeProps(promotion?.status || 'inactive')
  const closeEditModal = useCallback(() => setEditModalOpen(false), [setEditModalOpen])

  const crumbs = [
    {
      text: 'Stores',
      to: '/admin/stores',
    },
    {
      text: store?.name || 'Store',
    },
    {
      text: 'Promotions',
      to: `/admin/stores/${storeId}/promotion`,
    },
    {
      text: promotion?.name || 'Promotion',
    },
  ]

  const [downloadRequest] = useDownloadPromotionProductsMutation()

  const handleDownloadProducts = useCallback(async () => {
    try {
      const res = await downloadRequest(promotionRequestProps).unwrap()
      toast({
        toastColor: ToastColor.blue,
        message: `${res?.message || `Bulk download for promotion ${promotion?.name} has started`}`,
        toastDuration: ToastDuration.long,
      })
    } catch (error) {
      toast({
        toastColor: ToastColor.red,
        message: 'Failed to download promotion products. Please try again',
        toastDuration: ToastDuration.long,
      })
    }
  }, [])

  return (
    <>
      <HeaderContainer>
        <div>
          <Breadcrumbs crumbs={crumbs} data-cy="promotion-details-breadcrumbs" />
          <ContainTitle>
            <Title data-cy="promotion-details-name">{promotion?.name}</Title>
            <ContainBadge>
              <Badge text={text} color={color} />
            </ContainBadge>
          </ContainTitle>

          <PromoptionIdContainer data-cy="promotion-details-id">
            Promotion ID: {promotionId}
          </PromoptionIdContainer>
        </div>
      </HeaderContainer>
      <Container>
        {hasPromotion && (
          <Box>
            <Head>
              <HeadingLarge>Details</HeadingLarge>
              <IconContainer onClick={() => setEditModalOpen(true)} data-cy="promotion-edit-button">
                <Icon size={IconSize.medium} icon={Edit} color={COLOR.BLUE['700']} />
                <IconText>Edit</IconText>
              </IconContainer>
            </Head>

            <Body>
              <PromotionName>
                <Key>Name</Key>
                <Paragraph>{promotion?.name}</Paragraph>
              </PromotionName>
              <Item data-cy="promotion-details-start-time">
                <Key>Start</Key>
                <Paragraph>{formatDateTime(promotion?.startTime)}</Paragraph>
              </Item>
              <Item data-cy="promotion-details-end-time">
                <Key>End</Key>
                {formatDateTime(promotion?.endTime)}
              </Item>
              <Item>
                <Key>Products for Promotion</Key>
                {!hasPromotionProducts && <Paragraph>No products</Paragraph>}
                {hasPromotionProducts && (
                  <ContainProductsDownload>
                    <Paragraph>{buidPromotionFileName(promotion?.name)}</Paragraph>
                    <DownloadIconContainer onClick={handleDownloadProducts}>
                      <Icon size={IconSize.medium} icon={Download} color={COLOR.BLUE['700']} />
                    </DownloadIconContainer>
                  </ContainProductsDownload>
                )}
              </Item>
            </Body>
          </Box>
        )}
      </Container>

      {hasPromotion && (
        <PromotionEditModal
          isOpen={editModalOpen}
          onClose={closeEditModal}
          storeId={storeId}
          promotion={promotion as EmbeddedPromotion}
        />
      )}
    </>
  )
}
