export function formatDateTime(dateInSeconds?: number): string {
  if (!dateInSeconds) return ''

  return new Date(dateInSeconds * 1000).toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  })
}

export const formatDateTimeOfDay = (dateInSeconds?: number): string => {
  if (!dateInSeconds) return ''

  return new Date(dateInSeconds * 1000).toLocaleTimeString('en-us', {
    hour: 'numeric',
    minute: 'numeric',
  })
}
