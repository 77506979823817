import styled from '@emotion/styled'
import { Modal } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'

type TermsNewVersionModalProps = {
  handleModalOff: () => void
  handleConfirm: () => Promise<void>
  termsId: string
  currentVersion: number
  isUploading: boolean
}

const TermsNewVersionModal: FC<TermsNewVersionModalProps> = ({
  termsId,
  currentVersion,
  isUploading,
  handleModalOff,
  handleConfirm,
}) => {
  return (
    <Modal
      data-cy="terms-new-version-modal"
      heading="Save New Version"
      onDismissRequest={handleModalOff}
      primaryButtonProps={{
        text: 'Save',
        'data-cy': 'create-new-version-button',
        onClick: handleConfirm,
        isProcessing: isUploading,
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        isDisabled: isUploading,
        'data-cy': 'cancel-new-version-button',
        onClick: handleModalOff,
      }}
    >
      <p data-cy="modal-text">
        You are about to save version <SpanBold>{currentVersion + 1}</SpanBold> of Terms ID:{' '}
        <SpanBold>{termsId}</SpanBold>.{' '}
      </p>
      <p>
        By saving the terms, you will affect the live warranty offers and the consumer-facing terms
        and conditions pages. Are you sure you want to update the terms?
      </p>
    </Modal>
  )
}

const SpanBold = styled.span({
  fontWeight: 700,
})

export { TermsNewVersionModal }
