import type { FC } from 'react'
import React from 'react'
import ReactDOM from 'react-dom'
import { asyncWithLDProvider, useFlags } from 'launchdarkly-react-client-sdk'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { configureDataDogRum } from '@extend/client-helpers'
import {
  LD_CLIENT_ID_PORTAL,
  OKTA_CLIENT_ID,
  PORTAL_APP_REDIRECT_URI,
} from '@helloextend/client-constants'
import { ErrorBoundary, SystemError } from '@helloextend/component-commons'
import { merchants, Tooltip } from '@extend/zen'
import { ExtendOktaLoginProvider } from '@extend/package-okta-login'
import { LDFlag, defaultLDFlagValues } from './constants/ld-flags'
import { Router } from './pages/router'
import configureStore from './store'
import { GlobalStyle } from './components/global-style'
import { enterpriseInternalRoles, legacyInternalRoles } from './utils/user-role-mapper'
import '@extend/zen/dist/style.css'
const store = configureStore()

const Main: FC = () => {
  const flags = useFlags()

  return (
    <Provider store={store}>
      <ThemeProvider theme={merchants}>
        <BrowserRouter>
          <ExtendOktaLoginProvider
            clientId={OKTA_CLIENT_ID}
            redirectUri={PORTAL_APP_REDIRECT_URI}
            roles={Object.keys({ ...enterpriseInternalRoles, ...legacyInternalRoles })}
            customLoginRoute="/login"
            tokenVersion={flags[LDFlag.useV3Token] ? 'V3' : 'V2'}
          >
            <ErrorBoundary page={SystemError}>
              <GlobalStyle />
              <Router />
              <Tooltip />
            </ErrorBoundary>
          </ExtendOktaLoginProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  )
}

;(async () => {
  configureDataDogRum(15, 100)
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LD_CLIENT_ID_PORTAL,
    flags: defaultLDFlagValues,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })

  ReactDOM.render(
    <LDProvider>
      <Main />
    </LDProvider>,
    document.querySelector('#root'),
  )
})()
