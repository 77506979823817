import { date } from '@extend/client-helpers'
import type { LineItemGetResponse } from '@helloextend/extend-api-rtk-query'
import * as Yup from 'yup'

const generalInformationSchema = Yup.object()
  .shape({
    transactionId: Yup.string().required(),
    offerId: Yup.string().notRequired(),
    offerPlanId: Yup.string().notRequired(),
    leadToken: Yup.string().notRequired(),
    quantity: Yup.number().notRequired(),
    fulfilledQuantity: Yup.number().notRequired(),
    discountAmount: Yup.number().notRequired(),
    taxCost: Yup.number().notRequired(),
    fulfilledAt: Yup.string().notRequired(),
    refundedAt: Yup.string().notRequired(),
  })
  .defined()

const productDetailsSchema = Yup.object()
  .shape({
    referenceId: Yup.string().notRequired(),
    productName: Yup.string().notRequired(),
    category: Yup.string().notRequired(),
    listPrice: Yup.number().notRequired(),
    purchasePrice: Yup.number().notRequired(),
    purchaseDate: Yup.string().notRequired(),
    fulfillmentDate: Yup.string().notRequired(),
    serialNumber: Yup.string().notRequired(),
  })
  .defined()

const planInformationSchema = Yup.object()
  .shape({
    planId: Yup.string().required(),
    purchasePrice: Yup.number().required(),
    contractFailedReasons: Yup.array().of(Yup.string()).notRequired(),
    premium: Yup.number().notRequired(),
    quoteId: Yup.string().notRequired(),
    adjustedQuoteId: Yup.string().notRequired(),
  })
  .defined()

const charityDonationSchema = Yup.object()
  .shape({
    charityId: Yup.string().required(),
    charityName: Yup.string().required(),
    charityDonationAmount: Yup.number().required(),
  })
  .defined()

const adjustmentDetailsSchema = Yup.object()
  .shape({
    previousContractIds: Yup.array().of(Yup.string()),
    productsRemoved: Yup.array().of(Yup.string()),
  })
  .defined()

const bundleInformationSchema = Yup.object()
  .shape({
    referenceId: Yup.string().notRequired(),
    productName: Yup.string().notRequired(),
    category: Yup.string().notRequired(),
    imageUrl: Yup.string().url().notRequired(),
    listPrice: Yup.number().notRequired(),
    purchasePrice: Yup.number().notRequired(),
    purchaseDate: Yup.string().notRequired(),
    fulfillmentDate: Yup.string().notRequired(),
    serialNumber: Yup.string().notRequired(),
    mfwLabor: Yup.number().notRequired(),
    mfwParts: Yup.number().notRequired(),
  })
  .defined()

type GeneralInformation = Yup.InferType<typeof generalInformationSchema>
type ProductDetails = Yup.InferType<typeof productDetailsSchema>
type PlanInformation = Yup.InferType<typeof planInformationSchema>
type CharityDonation = Yup.InferType<typeof charityDonationSchema>
type AdjustmentDetails = Yup.InferType<typeof adjustmentDetailsSchema>
type BundleInformation = Yup.InferType<typeof bundleInformationSchema>

interface LineItemDetailsSchemaInterface {
  generalInformation: GeneralInformation
  productDetails?: ProductDetails
  planInformation?: PlanInformation
  charityDonation?: CharityDonation
  adjustmentDetails?: AdjustmentDetails
  bundleInformation?: BundleInformation
}

export function mapLineItemDetailsSchema(
  lineItem: LineItemGetResponse,
): LineItemDetailsSchemaInterface {
  const quantity = ('quantity' in lineItem ? lineItem.quantity || 0 : 0) as number
  const fulfilledQuantity = (
    'fulfilledQuantity' in lineItem ? lineItem.fulfilledQuantity || 0 : 0
  ) as number

  const generalInformation: GeneralInformation = {
    transactionId: 'transactionId' in lineItem ? lineItem.transactionId || '' : '',
    offerId: 'offerId' in lineItem ? lineItem.offerId || '' : '',
    offerPlanId: 'offerPlanId' in lineItem ? lineItem.offerPlanId || '' : '',
    leadToken: 'leadToken' in lineItem ? lineItem.leadToken || '' : '',
    quantity,
    fulfilledQuantity,
    discountAmount: 'discountAmount' in lineItem ? lineItem.discountAmount || 0 : 0,
    taxCost: 'taxCost' in lineItem ? lineItem.taxCost || 0 : 0,
    fulfilledAt:
      'fulfilledAt' in lineItem
        ? lineItem.fulfilledAt
          ? date.format(lineItem.fulfilledAt, 'MMMM DD YYYY')
          : ''
        : '',
    refundedAt:
      'refundedAt' in lineItem
        ? lineItem.refundedAt
          ? date.format(lineItem.refundedAt, 'MMMM DD YYYY')
          : ''
        : '',
  }

  const lineItemDetails: LineItemDetailsSchemaInterface = { generalInformation }

  switch (lineItem.type) {
    case 'contract':
    case 'category_product':
    case 'bundle_component':
    case 'lead':
    case 'non_warrantable':
      assignProductDetails(lineItem, lineItemDetails)
      break
  }

  switch (lineItem.type) {
    case 'contract':
    case 'shipments':
    case 'category_contract':
    case 'bundle_contract':
      assignPlanInformation(lineItem, lineItemDetails)
      break
  }

  if (lineItem.type === 'shipments') {
    assignPlanInformation(lineItem, lineItemDetails)
    assignCharityDonation(lineItem, lineItemDetails)
  }

  if (lineItem.type === 'shipments' || lineItem.type === 'category_contract') {
    assignAdjustmentDetails(lineItem, lineItemDetails)
  }

  if (lineItem.type === 'bundle_component') {
    assignBundleInformation(lineItem, lineItemDetails)
  }

  return lineItemDetails
}

const assignProductDetails = (
  lineItem: LineItemGetResponse,
  lineItemDetails: LineItemDetailsSchemaInterface,
) => {
  if (!('product' in lineItem) || !lineItem.product) return
  const { product } = lineItem
  lineItemDetails.productDetails = {
    referenceId: product.id || '',
    productName: product.name || '',
    // @ts-expect-error TS models are unaware of of this property
    category: 'category' in product ? product.category || '' : '',
    listPrice: 'listPrice' in product ? product.listPrice : 0,
    purchasePrice: 'purchasePrice' in product ? product.purchasePrice : 0,
    purchaseDate:
      'purchaseDate' in product ? date.format(product.purchaseDate as number, 'MMMM DD YYYY') : '',
    fulfillmentDate:
      'fulfillmentDate' in product
        ? date.format(product.fulfillmentDate as number, 'MMMM DD YYYY')
        : '',
    serialNumber: 'serialNumber' in product ? product.serialNumber || '' : '',
  }
}

const assignPlanInformation = (
  lineItem: LineItemGetResponse,
  lineItemDetails: LineItemDetailsSchemaInterface,
) => {
  if (!('plan' in lineItem) || !lineItem.plan) return
  const { plan } = lineItem

  lineItemDetails.planInformation = {
    planId: plan.id,
    purchasePrice: plan.purchasePrice,
    contractFailedReasons:
      'contractFailedReasons' in lineItem ? lineItem.contractFailedReasons : [],
  }

  // @ts-expect-error TS models are unaware of shipment line item type so these properties are unknown
  if (lineItem.type === 'shipments') {
    // @ts-expect-error TS models are unaware of shipment line item type so these properties are unknown
    lineItemDetails.planInformation.premium = 'premium' in lineItem ? lineItem.premium || 0 : 0
    // @ts-expect-error TS models are unaware of shipment line item type so these properties are unknown
    lineItemDetails.planInformation.quoteId = 'quoteId' in lineItem ? lineItem.quoteId || '' : ''
    lineItemDetails.planInformation.adjustedQuoteId =
      // @ts-expect-error TS models are unaware of shipment line item type so these properties are unknown
      lineItem.adjustments?.adjustedQuoteId || ''
  }
}

const assignCharityDonation = (
  lineItem: LineItemGetResponse,
  lineItemDetails: LineItemDetailsSchemaInterface,
) => {
  if (!('charitableDonation' in lineItem) || !lineItem.charitableDonation) return
  const { charitableDonation } = lineItem

  lineItemDetails.charityDonation = {
    charityId: charitableDonation.id || '',
    charityName: charitableDonation.name || '',
    charityDonationAmount: charitableDonation.donationAmount || 0,
  }
}

const assignAdjustmentDetails = (
  lineItem: LineItemGetResponse,
  lineItemDetails: LineItemDetailsSchemaInterface,
) => {
  if (!('adjustments' in lineItem) || !lineItem.adjustments) return
  const { adjustments } = lineItem
  lineItemDetails.adjustmentDetails = {
    previousContractIds: 'previousContracts' in adjustments ? adjustments.previousContracts : [],
    productsRemoved:
      'productsRemoved' in adjustments
        ? adjustments.productsRemoved?.map((prod) => prod.referenceId)
        : [],
  }
}

const assignBundleInformation = (
  lineItem: LineItemGetResponse,
  lineItemDetails: LineItemDetailsSchemaInterface,
) => {
  if (!('bundleInfo' in lineItem) || !lineItem.bundleInfo) return

  const bundle = lineItem.bundleInfo
  const bundleProduct = lineItem.product
  lineItemDetails.bundleInformation = {
    referenceId: 'id' in bundle ? bundle.id || '' : '',
    productName: 'title' in bundle ? bundle.title || '' : '',
    category: 'category' in bundle ? bundle.category || '' : '',
    imageUrl: 'imageUrl' in bundle ? bundle.imageUrl || '' : '',
    listPrice: 'listPrice' in bundle ? bundle.listPrice || 0 : 0,
    purchasePrice: 'purchasePrice' in bundle ? bundle.purchasePrice || 0 : 0,
    purchaseDate:
      'purchaseDate' in bundle ? date.format(bundle.purchaseDate as number, 'MMMM DD YYYY') : '',
    fulfillmentDate:
      'fulfillmentDate' in bundle
        ? date.format(bundle.fulfillmentDate as number, 'MMMM DD YYYY')
        : '',
    serialNumber:
      bundleProduct && 'serialNumber' in bundleProduct ? bundleProduct.serialNumber : '',
    // @ts-expect-error TS models are unaware of of this property
    mfwLabor: bundleProduct ? bundleProduct?.mfrWarranty?.labor : 0,
    // @ts-expect-error TS models are unaware of of this property
    mfwParts: bundleProduct ? bundleProduct?.mfrWarranty?.parts : 0,
  }
}

export type {
  GeneralInformation,
  ProductDetails,
  PlanInformation,
  CharityDonation,
  AdjustmentDetails,
  BundleInformation,
}
