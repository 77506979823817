import type { FC, SyntheticEvent } from 'react'
import React, { useState } from 'react'
import type { ServiceOrderClosedResolution } from '@helloextend/extend-api-client/src/models/service-order'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { useServiceOrderCloseMutation } from '@helloextend/extend-api-rtk-query'
import { ButtonGroup, Button } from '@extend/zen'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { Select, SelectItem } from '../../../../../../components/select'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'

interface CloseServiceOrderDropdownProps {
  serviceOrder: ServiceOrder
  hasExpenses: boolean
}

interface CloseOption {
  label: string
  value: ServiceOrderClosedResolution
}

const serviceOrderClosingStatusCriteria = [
  'created',
  'assigned',
  'accepted',
  'repair_shipped',
  'checked_in',
]

const CloseServiceOrderDropdown: FC<CloseServiceOrderDropdownProps> = ({
  serviceOrder,
  hasExpenses,
}) => {
  const dispatch = useDispatch()
  const [resolution, setResolution] = useState<ServiceOrderClosedResolution | null>(null)
  const [closeServiceOrder] = useServiceOrderCloseMutation()

  const handleSubmit = (): void => {
    if (!resolution) return
    closeServiceOrder({
      serviceOrderId: serviceOrder.id,
      body: {
        resolution,
      },
    })
    dispatch(setClaimDetailsToast('Service order closed!'))
    dispatch(setClaimDetailsActiveView(''))
  }

  const getCloseOptions = (): CloseOption[] => {
    // "No service" should only show for accepted replacements
    const shouldDisplayNoServiceReplacement =
      serviceOrder.serviceType === 'replace' && serviceOrder.status === 'accepted'
    // "No service" should only show for valid repair statuses for service orders with no expenses
    const shouldDisplayNoServiceRepair =
      serviceOrder.serviceType !== 'replace' &&
      !hasExpenses &&
      serviceOrderClosingStatusCriteria.includes(serviceOrder.status)

    const shouldDisplayDefectiveProductNotShipped =
      serviceOrder.status === 'accepted' && serviceOrder.configurations?.productReturnRequired

    return [
      ...(serviceOrder.status !== 'closed' ||
      shouldDisplayNoServiceReplacement ||
      shouldDisplayNoServiceRepair
        ? [{ label: 'No service required', value: 'no_service' as ServiceOrderClosedResolution }]
        : []),
      { label: 'Service change', value: 'service_change' as ServiceOrderClosedResolution },
      ...(shouldDisplayDefectiveProductNotShipped
        ? [
            {
              label: 'Defective product not shipped',
              value: 'defective_product_not_shipped' as ServiceOrderClosedResolution,
            },
          ]
        : []),
    ]
  }

  return (
    <ContentWrapper>
      <DataRow>
        <ItemWrapper>
          <LabelWrapper>
            <LabelText>Reason</LabelText>
          </LabelWrapper>
          <Select
            onChange={(e: SyntheticEvent<Element>): void => {
              const { value } = e.currentTarget as HTMLInputElement
              setResolution(value as ServiceOrderClosedResolution)
            }}
            value={resolution ?? ''}
            placeholder="—SELECT—"
            data-cy="close-resolution-select"
          >
            {getCloseOptions().map(({ label, value }) => (
              <SelectItem
                value={value}
                key={value}
                label={label}
                data-cy={`select-item-${value.replaceAll('_', '-')}`}
              />
            ))}
          </Select>
        </ItemWrapper>
      </DataRow>
      <ButtonRow>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
          />
          <Button
            type="button"
            emphasis="high"
            isDisabled={resolution === null}
            text="Submit"
            data-cy="submit-button"
            onClick={() => handleSubmit()}
          />
        </ButtonGroup>
      </ButtonRow>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

const DataRow = styled.div({
  display: 'flex',
  width: '100%',
})

const ButtonRow = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
})

const ItemWrapper = styled.div({
  width: '100%',
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
})

const LabelWrapper = styled.label({
  fontFamily: 'Nunito Sans, sans-serif',
})

const LabelText = styled.div({
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
  lineHeight: '24px',
})

export { CloseServiceOrderDropdown }
