import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    depotRepairEnabled: Yup.boolean().required().default(false),
    onsiteRepairEnabled: Yup.boolean().required().default(false),
    isSelfRepairEnabled: Yup.boolean().required().default(false),
    isCleaningKitEnabled: Yup.boolean().required().default(false),
    isCarryInEnabled: Yup.boolean().required().default(false),
    preventAutoAccept: Yup.boolean().default(false),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

interface CheckboxProps {
  key: keyof Values
  label: string
  dataCy: string
}

const serviceTypeFields: CheckboxProps[] = [
  { key: 'depotRepairEnabled', label: 'Depot Repair', dataCy: 'depot-repair-checkbox' },
  { key: 'onsiteRepairEnabled', label: 'On-site Repair', dataCy: 'onsite-repair-checkbox' },
  { key: 'isSelfRepairEnabled', label: 'Self Repair', dataCy: 'self-repair-checkbox' },
  {
    key: 'isCleaningKitEnabled',
    label: 'Cleaning Kit Repair',
    dataCy: 'cleaning-kit-repair-checkbox',
  },
  { key: 'isCarryInEnabled', label: 'Carry-In Repair', dataCy: 'carry-in-repair-checkbox' },
]

const settingsFields: CheckboxProps[] = [
  {
    key: 'preventAutoAccept',
    label: 'Require the servicer to manually accept assigned repair service orders',
    dataCy: 'prevent-auto-accept-checkbox',
  },
]

export { schema, serviceTypeFields, settingsFields }
export type { Values }
