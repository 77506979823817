import type { SagaIterator } from 'redux-saga'
import { call, put, select, delay } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/extend-api-fetch'
import type { ProductReplacementServiceOrdersFulfillRequest } from '@helloextend/extend-api-client/src/models/service-order'
import { getServiceOrderError } from './get-service-order-error'
import { fetch as fetchExpenses } from '../../service-order-expenses/sagas/fetch'
import { actions } from '../actions'
import { fetchServiceOrders } from './fetch-by-claim-id'

export const MAX_FETCH_ATTEMPTS = 5

type Action = ReturnType<typeof actions.fulfill>

function* fulfill(payload: {
  serviceOrderId: string
  body: ProductReplacementServiceOrdersFulfillRequest
  accessToken: string
}): SagaIterator {
  const { serviceOrderId, body, accessToken } = payload
  yield put(actions.fulfillRequest())
  try {
    const response = yield call(client.serviceOrders.fulfill, serviceOrderId, body, accessToken)

    if (isErrorResponse(response)) {
      yield put(actions.fulfillFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.fulfillFailure(`An unknown error occurred`, response.status))
      return
    }

    yield put(actions.fulfillSuccess(response.data))
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.fulfillFailure(e.message))
    }
  }
}

export default function* fulfillServiceOrderAndRefetch(action: Action): SagaIterator {
  yield call(fulfill, action.payload)

  const error = yield select(getServiceOrderError)
  if (error) return

  // refetch service orders (if there were no errors)
  yield call(fetchServiceOrders, action.payload)

  // refetch expenses
  let attempts = 0
  let finished
  while (!finished && attempts < MAX_FETCH_ATTEMPTS) {
    yield delay(2 ** attempts * 500) // exponential backoff: 0.5s, 1s, 2s, 4s...

    attempts += 1
    yield call(fetchExpenses, action.payload)

    // check if expenses data has arrived
    const { serviceOrderId } = action.payload
    const expenses = (yield select()).serviceOrderExpenses.byServiceOrder[serviceOrderId]
    if ((expenses && expenses.length > 0) || attempts >= MAX_FETCH_ATTEMPTS) {
      finished = true
    }
  }
}
