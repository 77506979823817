import type { FC } from 'react'
import React, { memo } from 'react'
import type { LineItemGetResponse } from '@helloextend/extend-api-rtk-query'
import type { LineItem } from '@helloextend/extend-api-client'
import { LineItemBreadcrumbs } from './line-item-breadcrumbs'
import { LineItemIDAndStatus } from './line-item-id-and-status'
import { HeaderDetails } from './header-details'

const LineItemDetailsPageHeader: FC<{
  lineItem: LineItemGetResponse
  orderId: string
}> = memo(({ lineItem, orderId }) => {
  if (!lineItem) return <></>

  const { id: lineItemId, createdAt, updatedAt, status, type } = lineItem as LineItem
  const contractId = 'contractId' in lineItem ? lineItem.contractId : undefined

  return (
    <>
      <LineItemBreadcrumbs lineItemId={lineItemId} orderId={orderId} />
      <LineItemIDAndStatus lineItemId={lineItemId} lineItemType={type} status={status} />
      <HeaderDetails createdAt={createdAt} updatedAt={updatedAt} contractId={contractId} />
    </>
  )
})

export { LineItemDetailsPageHeader }
