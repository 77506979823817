import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import { Button, InputType, Add } from '@extend/zen'
import styled from '@emotion/styled'
import { useToggle } from '@helloextend/client-hooks'
import type { FormikErrors } from 'formik'
import { getIn, useFormikContext } from 'formik'
import type { Fee } from '@helloextend/extend-api-client'
import type { Values } from './schema'
import type { InputItem } from '../../../components/form-text-group'
import { FormTextGroup } from '../../../components/form-text-group'
import type { DropdownProps } from '../../../components/dropdown'
import type { DropdownItem } from '../../../components/form-text-group/form-text-group'
import { getDropdownCurrencyCode } from './util'
import { useDecimalsOnBlur } from '../../../hooks/use-decimals-on-blur'

type InsuranceProgramAddFeeFormProps = {
  obligorIndex: number
  currentFeeIndex: number
  isDisabled?: boolean
  handleAddFee: (fee: Fee) => void
  handleRemoveFee: (feeIndex: number, shouldDecreaseIndex: boolean) => void
  handleDropdownChange: DropdownProps['onChange']
}

const getFormikError = (
  errors: FormikErrors<Values>,
  currentFee: string,
  fieldName: string,
): string => {
  const error = getIn(errors, `${currentFee}.${fieldName}`)
  return error ?? ''
}

const InsuranceProgramAddFeeForm: FC<InsuranceProgramAddFeeFormProps> = ({
  obligorIndex,
  currentFeeIndex,
  isDisabled = false,
  handleAddFee,
  handleRemoveFee,
  handleDropdownChange,
}) => {
  const { handleChange, handleBlur, setFieldValue, values, errors, touched } =
    useFormikContext<Values>()

  const { handleOnBlurCustom } = useDecimalsOnBlur(setFieldValue)

  const [isFormVisible, handleIsFormVisible] = useToggle(false)

  const currentFeeFieldName = useMemo(
    () => `obligor[${obligorIndex}]fees[${currentFeeIndex}]`,
    [currentFeeIndex, obligorIndex],
  )

  const currentFeeValue = useMemo(
    () => values?.obligor?.[obligorIndex]?.fees?.[currentFeeIndex],
    [currentFeeIndex, obligorIndex, values?.obligor],
  )

  const currentFeeTouched = useMemo(
    () => touched?.obligor?.[obligorIndex]?.fees?.[currentFeeIndex],
    [currentFeeIndex, obligorIndex, touched?.obligor],
  )

  const isCurrentFeeValid = useMemo(() => {
    const fields = [
      'type',
      'otherFee',
      'amount',
      'amount',
      'feeAmountOfType',
      'basedOn',
      'routing',
      'recognition',
    ]
    // fee is valid if there are no errors for all fields
    return !fields.some((field) => getFormikError(errors, currentFeeFieldName, field))
  }, [currentFeeFieldName, errors])

  const isCurrentFeeTouched = useMemo(() => {
    if (!currentFeeTouched) {
      return false
    }
    return Object.keys(currentFeeTouched).length
  }, [currentFeeTouched])

  const handleAddFeeClick = useCallback(() => {
    handleAddFee(values.obligor[obligorIndex].fees[currentFeeIndex])
    handleIsFormVisible.off()
  }, [currentFeeIndex, handleAddFee, handleIsFormVisible, obligorIndex, values.obligor])

  const handleCancelClick = useCallback(() => {
    handleRemoveFee(currentFeeIndex, false)
    handleIsFormVisible.off()
  }, [currentFeeIndex, handleIsFormVisible, handleRemoveFee])

  return (
    <>
      {isFormVisible && (
        <FormTextGroupWrapper
          title="Add Fee"
          handleChange={handleChange}
          handleBlur={handleBlur}
          numColumns={4}
          dataCy={`add-fee-form-${obligorIndex}`}
          isDisabled={isDisabled}
          values={[
            {
              name: `${currentFeeFieldName}type`,
              label: 'Fee Type',
              value: currentFeeValue?.type,
              error: getFormikError(errors, currentFeeFieldName, 'type'),
              touched: currentFeeTouched?.type,
              fieldType: 'dropdown',
              options: [
                { value: 'Admin', label: 'Admin' },
                { value: 'CLIP', label: 'CLIP' },
                { value: 'Obligor', label: 'Obligor' },
                { value: 'Ceding', label: 'Ceding' },
                { value: 'Other', label: 'Other' },
              ],
              columnCount: currentFeeValue?.type === 'Other' ? 1 : 2,
              handleDropdownChange,
            },
            ...(currentFeeValue?.type === 'Other'
              ? [
                  {
                    name: `${currentFeeFieldName}otherFee`,
                    label: 'Other Fee',
                    value: currentFeeValue?.otherFee,
                    error: getFormikError(errors, currentFeeFieldName, 'otherFee'),
                    touched: currentFeeTouched?.otherFee,
                  } as InputItem,
                ]
              : []),
            {
              name: `${currentFeeFieldName}amount`,
              label: 'Fee Amount',
              value: currentFeeValue?.amount,
              error: getFormikError(errors, currentFeeFieldName, 'amount'),
              touched: currentFeeTouched?.amount,
              type: InputType.number,
              columnCount: 0.5,
              handleOnBlurCustom,
            } as InputItem,
            {
              name: `${currentFeeFieldName}feeAmountOfType`,
              label: 'Fee Amount Type',
              value: currentFeeValue?.feeAmountOfType,
              error: getFormikError(errors, currentFeeFieldName, 'feeAmountOfType'),
              touched: currentFeeTouched?.feeAmountOfType,
              fieldType: 'dropdown',
              options: [
                { value: 'percentage', label: '%' },
                { ...getDropdownCurrencyCode(values.permittedGeo) },
              ],
              columnCount: currentFeeValue?.feeAmountOfType === 'percentage' ? 0.5 : 1.5,
              handleDropdownChange,
            },
            ...(currentFeeValue?.feeAmountOfType === 'percentage'
              ? [
                  {
                    name: `${currentFeeFieldName}basedOn`,
                    label: 'Based On',
                    value: currentFeeValue?.basedOn,
                    error: getFormikError(errors, currentFeeFieldName, 'basedOn'),
                    touched: currentFeeTouched?.basedOn,
                    fieldType: 'dropdown',
                    options: [
                      { value: 'Premium', label: 'Premium' },
                      { value: 'Reserve', label: 'Reserve' },
                    ],
                    columnCount: 1,
                    handleDropdownChange,
                  } as DropdownItem,
                ]
              : []),
            {
              name: `${currentFeeFieldName}routing`,
              label: 'Routing',
              value: currentFeeValue?.routing,
              error: getFormikError(errors, currentFeeFieldName, 'routing'),
              touched: currentFeeTouched?.routing,
              fieldType: 'dropdown',
              options: [
                { value: 'Disburse', label: 'Disburse' },
                { value: 'Retain', label: 'Retain' },
              ],
              columnCount: 2,
              handleDropdownChange,
            },
            {
              name: `${currentFeeFieldName}recognition`,
              label: 'Recognition',
              value: currentFeeValue?.recognition,
              error: getFormikError(errors, currentFeeFieldName, 'recognition'),
              touched: currentFeeTouched?.recognition,
              fieldType: 'dropdown',
              options: [
                { value: 'Immediate', label: 'Immediate' },
                { value: 'Over Time', label: 'Over Time' },
              ],
              columnCount: 2,
              handleDropdownChange,
            },
          ]}
        >
          <ButtonGroup>
            <Button text="Cancel" emphasis="medium" onClick={handleCancelClick} />
            <Button
              text="Add Fee"
              emphasis="high"
              onClick={handleAddFeeClick}
              isDisabled={isDisabled || !isCurrentFeeValid || !isCurrentFeeTouched}
              data-cy={`add-fee-to-table-button-${obligorIndex}`}
            />
          </ButtonGroup>
        </FormTextGroupWrapper>
      )}
      {!isFormVisible && (
        <ButtonWrapper>
          <Button
            text="Add Fee"
            icon={Add}
            emphasis="low"
            onClick={() => handleIsFormVisible.on()}
            data-cy={`add-fee-button-${obligorIndex}`}
            isDisabled={isDisabled}
          />
        </ButtonWrapper>
      )}
    </>
  )
}

const ButtonWrapper = styled.div({
  marginTop: 24,
  marginBottom: 24,
})

const FormTextGroupWrapper = styled(FormTextGroup)({
  padding: 0,
  marginTop: 32,
  border: 0,
})

const ButtonGroup = styled.div({
  marginTop: 40,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 8,
})

export { InsuranceProgramAddFeeForm }
