import type { ApiResponse, ResponseObject, ErrorResponse } from './types'

export function isErrorResponse<T>(
  response: ApiResponse<T>,
): response is ResponseObject<ErrorResponse> {
  return isErrorStatus(response.status)
}

export function isExceptionResponse<T>(response: ApiResponse<T>): response is ResponseObject<null> {
  return !response.data
}

export const isErrorStatus = (status: number | string): boolean => /^(4|5)/.test(String(status))
