import type { FC } from 'react'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import type { InsuranceClaimsListQueryStringOptions as Filters } from '@helloextend/extend-api-rtk-query'
import { usePrevious } from '@helloextend/client-hooks'
import {
  useListClaimsAssignmentUsersQuery,
  useListInsuranceClaimsQuery,
  useLazyGetServiceOrderQuery,
} from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router-dom'
import type { InsuranceClaim } from '@helloextend/extend-api-client'
import type { ColumnFiltersState, SortingState } from '@extend/zen'
import { Add, DataTable, HeadingLarge } from '@extend/zen'
import { getTableColumns, getFilterDefs } from './table-config'
import type { TableClaimsSearch } from '../../../types/claims'

type ColumnFilter = {
  id: string
  value: unknown
}

const PAGE_SIZE = 50

const OldClaims: FC = () => {
  const { push } = useHistory()
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'status', value: ['approved', 'review'] },
    { id: 'assignee', value: ['unassigned'] },
  ])
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [sorting, setSorting] = useState<SortingState>([{ id: 'reportedAt', desc: true }])
  const [claims, setClaims] = useState<TableClaimsSearch[]>([])
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: PAGE_SIZE })
  const [filtersForApi, setFiltersForApi] = useState<Filters>({})
  const [nextPageCursor, setNextPageCursor] = useState('')
  const [getServiceOrder] = useLazyGetServiceOrderQuery()

  const handleFileClaim = (): void => {
    push('/admin/contracts')
  }

  const updateFiltersForApi = useCallback(
    async (filters: ColumnFiltersState): Promise<void> => {
      const assigneeFilter = filters.find((f) => f.id === 'assignee')
      const escalationFilter = filters.find((f) => f.id === 'escalation')

      if (assigneeFilter) {
        const assigneeValues = assigneeFilter.value as string[]

        if (assigneeValues.includes('unassigned') && assigneeValues.length > 1) {
          // Since the 'assignee' filter is a multiselect, the user can select 'unassigned' and other assignees
          // The assignees are in the order they were selected, so if unassigned is not last in the array and the length is > 1, we need to remove it
          // If it is the last (with length > 1), then we make that the only 'assignee' since this means they want to filter unassigned as it was the last selected
          // In the mapping of column filters to api filters, it will filter by unassigned if the array only contains 'unassigned'
          const assigneeFilterValue =
            assigneeValues[assigneeValues.length - 1] === 'unassigned'
              ? ['unassigned']
              : assigneeValues.filter((a) => a !== 'unassigned')

          setColumnFilters([
            ...filters.filter((f) => f.id !== 'assignee'),
            {
              id: 'assignee',
              value: assigneeFilterValue,
            },
          ])
          return
        }
      }

      // If the `all` escalation filter is selected and not all escalation filters are selected, select them all
      if (
        escalationFilter &&
        (escalationFilter.value as string[]).includes('all') &&
        (escalationFilter.value as string[]).length !== 5
      ) {
        setColumnFilters([
          ...filters.filter((f) => f.id !== 'escalation'),
          {
            id: 'escalation',
            value: ['all', 'resolved', 'tier1', 'tier2', 'tier3'],
          },
        ])
        return
      }

      const getFilterValue = async ({ id: fieldName, value }: ColumnFilter): Promise<Filters> => {
        switch (fieldName) {
          case 'customerEmail':
            return { containsCustomerEmail: value as string }
          case 'phoneNumber':
            return { containsCustomerPhone: value as string }
          case 'contractId':
            return { containsContractId: value as string }
          case 'claimId':
            return { containsClaimId: value as string }
          case 'assignee': {
            if (!value || (value as string[]).length === 0) return {}
            const assignees = value as string[]
            if (assignees.length === 1 && assignees[0] === 'unassigned') {
              return { filterUnassigned: true }
            }
            return { matchesAssignedUser: assignees.filter((a) => a !== 'unassigned').join(',') }
          }
          case 'escalation':
            return {
              matchesEscalationTier: (value as string[]).filter((v) => v !== 'all').join(','),
            }
          case 'reportedAtDate': {
            if (!value || (value as number[]).length !== 2) return {}
            const reportedAtDate = value as number[]
            return { reportedAtBegin: reportedAtDate[0], reportedAtEnd: reportedAtDate[1] }
          }
          case 'followUpDate': {
            if (!value || (value as number[]).length !== 2) return {}
            const followUpDate = value as number[]
            return { followUpDateBegin: followUpDate[0], followUpDateEnd: followUpDate[1] }
          }
          case 'fraudRiskScore': {
            if (!value || (value as number[]).length !== 2) return {}
            const fraudRiskScore = value as number[]
            return { fraudRiskScoreBegin: fraudRiskScore[0], fraudRiskScoreEnd: fraudRiskScore[1] }
          }
          case 'claimType':
            if (!value || (value as string[]).length === 0) return {}
            return { matchesClaimType: (value as string[]).join(',') }
          case 'serviceType':
            if (!value || (value as string[]).length === 0) return {}
            return { matchesClaimServiceType: (value as string[]).join(',') }
          case 'status':
            if (!value || (value as string[]).length === 0) return {}
            return { matchesClaimStatus: value as string[] }
        }

        if (fieldName === 'serviceOrderId') {
          try {
            const serviceOrder = await getServiceOrder({ serviceOrderId: value as string }).unwrap()
            return { containsClaimId: serviceOrder.claimId }
          } catch (e) {
            return { containsClaimId: '' }
          }
        }
        return {}
      }

      const promises = filters.map((filter) => getFilterValue(filter))
      const filterValues = await Promise.all(promises)
      const newFilters = filterValues.reduce((acc, filter) => ({ ...acc, ...filter }), {})

      setIsInitialLoad(true)
      setNextPageCursor('')
      setFiltersForApi(newFilters)
      setPagination({ pageIndex: 0, pageSize: PAGE_SIZE })
    },
    [setFiltersForApi, getServiceOrder, setColumnFilters],
  )

  const sortForApi = useMemo(() => {
    if (!sorting.length) return {}
    const sortingParams: Filters = {}
    const { id, desc } = sorting[0]
    sortingParams.sortAsc = !desc
    sortingParams.sortKey = id

    setIsInitialLoad(true)
    setNextPageCursor('')
    setPagination({ pageIndex: 0, pageSize: PAGE_SIZE })
    return sortingParams
  }, [sorting])

  const params = useMemo(
    () => ({
      minLimit: 100,
      cursor: nextPageCursor || undefined,
      ...sortForApi,
      ...filtersForApi,
    }),
    [nextPageCursor, sortForApi, filtersForApi],
  )

  useEffect(() => {
    updateFiltersForApi(columnFilters)
  }, [columnFilters, updateFiltersForApi])

  const { data, isLoading, isFetching, isSuccess } = useListInsuranceClaimsQuery(params)

  const prevData = usePrevious(data)

  const formatClaimItems = (claim: InsuranceClaim): TableClaimsSearch => ({
    ...claim,
    status: claim.status,
    customerName: claim.customer.name,
    customerEmail: claim.customer.email,
    dateReported: claim.reportedAt,
    claimId: claim.id,
    dateAssigned: claim.userAssignedAt,
    assignee: claim.assignedUser,
  })

  useEffect(() => {
    if (!isSuccess || prevData === data) return
    if (data?.items) {
      if (isInitialLoad) {
        setClaims(data.items.map(formatClaimItems))
        setIsInitialLoad(false)
      } else {
        setClaims([...claims, ...data.items.map(formatClaimItems)])
      }
    } else {
      setClaims([])
    }
  }, [data, claims, isInitialLoad, setIsInitialLoad, setClaims, isSuccess, prevData])

  const cursor = useMemo(() => data?.nextPageCursor ?? '', [data?.nextPageCursor])

  useEffect(() => {
    if (
      cursor &&
      pagination.pageIndex > 0 &&
      claims.length > pagination.pageSize &&
      (pagination.pageIndex + 1) * pagination.pageSize + 1 > claims.length &&
      !isFetching &&
      !isLoading
    ) {
      setNextPageCursor(cursor)
    }
  }, [cursor, pagination, setNextPageCursor, claims.length, isFetching, isLoading])

  const {
    data: userData,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
  } = useListClaimsAssignmentUsersQuery(undefined)
  const userList = useMemo(() => userData?.items ?? [], [userData?.items])

  const columns = useMemo(() => getTableColumns(), [])

  const handleRowClick = (row: InsuranceClaim): void => {
    window.open(`/admin/claims/${row.id}`, '_blank', 'noreferrer')
  }

  const filterDefs = useMemo(() => getFilterDefs(userList), [userList])

  return (
    <DataTable
      heading={<HeadingLarge>Claims</HeadingLarge>}
      itemName="claim"
      data-cy="claims"
      data={claims.slice(
        pagination.pageIndex * pagination.pageSize,
        (pagination.pageIndex + 1) * pagination.pageSize,
      )}
      columns={columns}
      filterDefs={filterDefs}
      rowCount={claims.length}
      isLoading={isLoading || isFetching || isUsersLoading || isUsersFetching}
      columnFilters={columnFilters}
      pagination={pagination}
      onPaginationChange={setPagination}
      onColumnFiltersChange={setColumnFilters}
      sorting={sorting}
      onSortingChange={setSorting}
      hasManualFiltering
      hasManualSorting
      hasManualPagination
      getRowId={(row) => row.id}
      getRowActions={(row) => [
        {
          onClick: () => handleRowClick(row),
          text: 'View',
          emphasis: 'low',
        },
      ]}
      getTableActions={() => [
        {
          emphasis: 'medium',
          text: 'File a new claim',
          onClick: handleFileClaim,
          icon: Add,
        },
      ]}
    />
  )
}

export { OldClaims }
