import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { ModalController, Modal } from '@extend/zen'
import { CreateAccountModalForm } from './create-account-modal-form'

type CreateAccountModalProps = {
  isVisible: boolean
  onClickClose: () => void
}

const CreateAccountModal: FC<CreateAccountModalProps> = ({ isVisible, onClickClose }) => {
  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="create-account-modal"
        heading="Create Account"
        onDismissRequest={onClickClose}
      >
        <SubText>
          By creating a merchant account, an email with an update password link will be sent to the
          account owner email address.
        </SubText>
        <CreateAccountModalForm onClickClose={onClickClose} />
      </Modal>
    </ModalController>
  )
}

const SubText = styled.p({
  marginTop: 0,
  marginBottom: 24,
})

export { CreateAccountModal }
