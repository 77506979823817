import type { OfferType } from '@helloextend/extend-api-client/src/models/store'
import type { SPCartPriceMap } from '@helloextend/extend-api-client/src/models/store-shipping-protection'
import * as Yup from 'yup'
import { hasGapsInPriceband, hasOverlappingPriceBands } from '../../../../utils'

const initialPriceBandValues = (
  isFirstRow = false,
): { low: string; high: string; offerType: string } => ({
  low: isFirstRow ? '0' : '',
  high: '',
  offerType: '',
})

const mapPriceBandValues = (values: PriceBandValues[]): SPCartPriceMap[] => {
  return values.map(({ low, high, offerType }) => ({
    low: Number(low),
    high: high ? Number(high) : undefined,
    offerType: offerType as OfferType,
  }))
}

const offerTypes = ['OPT_IN', 'OPT_OUT', 'OPT_MERCHANT', 'PRICE_BAND', 'SAFE_PACKAGE']

const priceBandSchema = Yup.object<SPCartPriceMap>()
  .shape({
    high: Yup.number(),
    low: Yup.number().required('Price band low is required'),
    offerType: Yup.string()
      .required('Offer type is required')
      .oneOf(offerTypes)
      .nullable()
      .default(undefined),
  })
  .test('checkOverlap', 'Price bands cannot overlap', function checkOverlap(value) {
    const isOverlapping = hasOverlappingPriceBands(this.parent, value as SPCartPriceMap)

    return isOverlapping
      ? this.createError({
          message: 'Price bands must not overlap',
          path: `${this.path}.high`,
        })
      : true
  })
  .test('checkGap', 'Price bands cannot have gaps', function testGapsInPricebands(value) {
    const hasGaps = hasGapsInPriceband(this.parent, value as SPCartPriceMap)
    return hasGaps
      ? this.createError({
          message: 'Price bands must not have gaps',
          path: `${this.path}.low`,
        })
      : true
  })
  .test(
    'price band high is required if not last',
    'Price band high is required',
    function checkIfRequired(value) {
      const priceBands = this.parent as SPCartPriceMap[] // Assuming SPCartPriceMap is the correct type
      const isLastRow = priceBands.length > 0 && this.path.endsWith(`[${priceBands.length - 1}]`)
      return !isLastRow && !value?.high
        ? this.createError({ message: 'Price band high is required', path: `${this.path}.high` })
        : true
    },
  )
  .defined()

const schema = Yup.object()
  .shape({
    enabled: Yup.boolean(),
    offerType: Yup.string().oneOf(offerTypes),
    priceBands: Yup.array().when('offerType', {
      is: (offerType: string) => offerType === 'PRICE_BAND',
      then: () => Yup.array().of(priceBandSchema).required('At least one price band is required'),
      otherwise: () => Yup.array().notRequired(),
    }),
  })
  .defined()

type Values = ReturnType<typeof schema.validateSync>
type PriceBandValues = ReturnType<typeof priceBandSchema.validateSync>

export type { Values, PriceBandValues }
export { schema, initialPriceBandValues, mapPriceBandValues }
