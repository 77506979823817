import type { FC } from 'react';
import React from 'react'
import type { ContractNote } from '@helloextend/extend-api-client'

import { ContractNoteResolutionForm } from './contract-note-resolution-form'
import { ContractResolutionNote } from './contract-resolution-note'
import { formatTimestampToPST } from 'src/lib/date'

interface ContractNoteResolutionProps {
  isEscalation: boolean
  displayResolutionNote: boolean
  contractNote: ContractNote
  setDisplayResolutionNote: (displayResolutionNote: boolean) => void
  setHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void
  handleEscalationNote?: (note: ContractNote) => void
}

const ContractNoteResolution: FC<ContractNoteResolutionProps> = ({
  isEscalation,
  displayResolutionNote,
  contractNote,
  setHasUnsavedChanges,
  setDisplayResolutionNote,
  handleEscalationNote,
}: ContractNoteResolutionProps) => {
  const resolvedAtDate = formatTimestampToPST(contractNote.resolvedAt)

  return (
    <>
      {displayResolutionNote && (
        <ContractNoteResolutionForm
          setDisplayResolutionNote={setDisplayResolutionNote}
          contractNote={contractNote}
          setHasUnsavedChanges={setHasUnsavedChanges}
          handleEscalationNote={handleEscalationNote}
        />
      )}
      {isEscalation && contractNote.isResolved && (
        <ContractResolutionNote
          resolvedAtDate={resolvedAtDate}
          resolvedBy={contractNote.resolvedBy || 'Unknown'}
          resolutionText={contractNote.resolutionText || ''}
        />
      )}
    </>
  )
}

export { ContractNoteResolution }
