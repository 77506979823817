import type { ChangeEvent, FC, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { InputProps } from '@extend/zen'
import { Input, TextArea, COLOR, InlineAlert, InlineAlertColor, Warning } from '@extend/zen'
import { Button, DatePicker } from '@helloextend/merchants-ui'
import type { CurrencyInputGroupProps } from '../currency-input-group'
import { CurrencyInputGroup } from '../currency-input-group'
import type { DropdownProps } from '../dropdown'
import { Dropdown } from '../dropdown'
import type { CurrencyInputAlignment } from '../currency-input/currency-input'
import { convertToAlertItem } from '../../utils/form-utils'

type InputItem =
  StringInputItem
  | CurrencyInputItem
  | NumberInputItem
  | BooleanInputItem
  | DropdownItem
  | TextAreaInputItem
  | DateItem

type FieldType = 'input' | 'textarea' | 'currency' | 'dropdown' | 'date'
interface BaseInput {
  name: string
  label: string
  error?: string
  touched?: boolean
  fieldType?: FieldType
  block?: boolean
  subtitle?: string
  isItemDisabled?: boolean
  columnCount?: number
  placeholder?: string
  helperText?: string
  dataCy?: string
  handleCustomOnChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

interface StringInputItem extends BaseInput {
  value: string
  suffix?: string
  type?: InputProps['type']
  handleOnBlurCustom?: (e: ChangeEvent<HTMLInputElement>) => void // allows to set different logic from the default formik one
}
interface TextAreaInputItem extends StringInputItem {
  isResizable?: boolean
  rows?: number
}

interface NumberInputItem extends BaseInput {
  value?: number | null
}

interface BooleanInputItem extends BaseInput {
  value?: boolean
}

interface CurrencyInputItem extends BaseInput {
  value?: number
  fieldType: 'currency'
  currencyProps: CurrencyInputGroupProps['currencyProps']
  isCurrencyCodeDisplayed?: boolean
  isGroup?: boolean
  handleCurrencyChange: (value: string | number | undefined, name: string) => void
  alignment?: CurrencyInputAlignment
  showSymbol?: boolean
  isZeroAllowed?: boolean
}

interface DropdownItem extends BaseInput, DropdownProps {
  isLoading?: boolean
  handleDropdownChange: DropdownProps['onChange']
}

interface DateItem extends BaseInput {
  value?: Date
  selected: Date | null
  handleDateChange: (name: string, date: Date | null) => void
  fullWidth?: boolean
  minDate?: Date | null
}
type ChangeFunction = (e: SyntheticEvent) => void
type SetFieldFunction = (name: string, value: any) => void

type FormTextGroupProps = {
  title: string
  warnings?: string[]
  warningHeader?: string
  handleChange: ChangeFunction & SetFieldFunction
  handleBlur: (e: SyntheticEvent) => void
  isDisabled?: boolean
  sectionError?: boolean
  errorMessage?: string
  values: InputItem[]
  numColumns?: number
  hasEditButton?: boolean
  handleClickEdit?: () => void
  className?: string
  dataCy?: string
  isZeroAllowed?: boolean
}

const calculateSpacingPerItem = (values: InputItem[], columns: number): number[] => {
  let columnsInRow = 0
  return values.map((input: InputItem) => {
    columnsInRow += input.columnCount ?? 1
    if (columnsInRow % columns === 0) {
      columnsInRow = 0
      return 0
    }
    // 16px is the base spacing between items
    return (16 * (columns - 1)) / (columns - 1)
  })
}

const FormTextGroup: FC<FormTextGroupProps> = ({
  title,
  warnings,
  warningHeader,
  sectionError,
  errorMessage,
  handleChange,
  handleBlur,
  isDisabled = false,
  values,
  numColumns = 1,
  hasEditButton = false,
  handleClickEdit = () => {},
  className,
  children,
  dataCy,
}) => {
  const margins = calculateSpacingPerItem(values, numColumns)
  return (
    <Container className={className} data-cy={dataCy}>
      <Header>
        <Title>{title}</Title>
        {hasEditButton && (isDisabled || sectionError) && (
          <EditButton
            name="edit"
            isInverted
            kind="primary"
            text="Edit"
            size="xs"
            onClick={handleClickEdit}
          />
        )}
      </Header>
      {((warnings && warnings.length > 0) || warningHeader) && (
        <WarningWrapper data-cy={`${title}-form-warning`}>
          <InlineAlert color={InlineAlertColor.yellow} icon={Warning}>
            {warningHeader}
            {warnings &&
              convertToAlertItem(warnings).map(({ key, text }) => <li key={key}>{text}</li>)}
          </InlineAlert>
        </WarningWrapper>
      )}
      {sectionError && errorMessage && (
        <WarningWrapper data-cy={`${title}-form-error`}>
          <InlineAlert data-cy="error-inline-alert" color={InlineAlertColor.red}>
            {errorMessage}
          </InlineAlert>
        </WarningWrapper>
      )}
      <InputGroup>
        {values.map((item: InputItem, i: number) => {
          const fieldType = item.fieldType ?? 'input'
          return (
            <InputWrapper
              key={item.name}
              width={item.columnCount ? (100 / numColumns) * item.columnCount : 100 / numColumns}
              block={item.block ?? false}
              margin={margins[i]}
            >
              {fieldType === 'textarea' && (
                <>
                  <Label>{item.label}</Label>
                  <Subtitle>{item.subtitle}</Subtitle>
                  <TextArea
                    id={item.name}
                    value={(item as TextAreaInputItem).value ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isDisabled={item.isItemDisabled ?? (sectionError || isDisabled)}
                    placeholder={(item as StringInputItem).placeholder || ''}
                    isResizable={(item as TextAreaInputItem).isResizable}
                    isError={Boolean(item.error)}
                    errorFeedback={item.error}
                    helperText={item.helperText}
                    rows={(item as TextAreaInputItem).rows ?? 2}
                  />
                </>
              )}
              {fieldType === 'input' && (
                <TextInputWrapper>
                  <Input
                    id={item.name}
                    label={item.label}
                    value={(item as StringInputItem).value ?? ''}
                    onChange={(item as BaseInput).handleCustomOnChange ?? handleChange}
                    onBlur={(item as StringInputItem).handleOnBlurCustom ?? handleBlur}
                    isDisabled={item.isItemDisabled ?? (sectionError || isDisabled)}
                    isError={Boolean(item.error)}
                    errorFeedback={item.error}
                    placeholder={(item as StringInputItem).placeholder || ''}
                    suffix={(item as StringInputItem).suffix}
                    helperText={item.helperText}
                    type={(item as StringInputItem).type}
                    data-cy={`${item.dataCy}`}
                  />
                </TextInputWrapper>
              )}
              {fieldType === 'currency' && (
                <CurrencyInputWrapper>
                  <CurrencyInputGroup
                    name={item.name}
                    label={item.label}
                    value={(item as CurrencyInputItem).value}
                    onChange={(item as CurrencyInputItem).handleCurrencyChange}
                    onBlur={handleBlur}
                    isDisabled={item.isItemDisabled ?? (sectionError || isDisabled)}
                    errorMessage={item.error ?? ''}
                    currencyProps={(item as CurrencyInputItem).currencyProps}
                    isCurrencyCodeDisplayed={(item as CurrencyInputItem).isCurrencyCodeDisplayed}
                    isGroup={(item as CurrencyInputItem).isGroup}
                    placeholder={item.placeholder}
                    alignment={(item as CurrencyInputItem).alignment}
                    invalid={Boolean(item.error)}
                    helperText={item.helperText}
                    showSymbol={(item as CurrencyInputItem).showSymbol}
                    isZeroAllowed={(item as CurrencyInputItem).isZeroAllowed}
                  />
                </CurrencyInputWrapper>
              )}
              {fieldType === 'dropdown' && (
                <DropdownWrapper>
                  <Dropdown
                    name={item.name}
                    label={item.label}
                    value={(item as DropdownItem).value}
                    placeholder="Select from list"
                    dataCy="dropdown-input"
                    options={(item as DropdownItem).options}
                    onChange={(item as DropdownItem).handleDropdownChange}
                    disabled={item.isItemDisabled ?? (sectionError || isDisabled)}
                    errorMessage={item.error}
                    isLoading={(item as DropdownItem).isLoading}
                    helperText={item.helperText}
                  />
                </DropdownWrapper>
              )}
              {fieldType === 'date' && (
                <>
                  <Label>{item.label}</Label>
                  <DatePicker
                    disabled={item.isItemDisabled}
                    onChange={(value) => (item as DateItem).handleDateChange(item.name, value)}
                    selected={(item as DateItem).value || null}
                    id={item.name}
                    placeHolder={(item as DateItem).placeholder}
                    data-cy="date-input"
                    errorFeedback={item.error}
                    fullWidth={(item as DateItem).fullWidth}
                    minDate={(item as DateItem).minDate}
                  />
                </>
              )}
            </InputWrapper>
          )
        })}
      </InputGroup>
      {children}
    </Container>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
})

const TextInputWrapper = styled.div({
  width: '100%',
  marginRight: 24,
})

const InputGroup = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: 16,
})

const EditButton = styled(Button)({
  padding: 0,
})

const InputWrapper = styled.div<{ width: number; block: boolean; margin: number }>(
  ({ width, block, margin }) => ({
    width: `calc(${width}% - ${margin}px)`, // subtract margin to avoid row wrapping
    marginRight: block ? `${100 - width}%` : margin,
    '& textarea': {
      border: `1px solid ${COLOR.NEUTRAL[300]}`,
    },
  }),
)

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  marginBottom: 32,
  padding: 40,
})

const Label = styled.div({
  color: COLOR.BLUE[1000],
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
})

const Subtitle = styled.div({
  color: COLOR.NEUTRAL[600],
  fontSize: 14,
  marginBottom: 4,
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '33px',
  color: COLOR.BLUE[1000],
  margin: '0 0 32px 0',
})

const WarningWrapper = styled.div({
  marginBottom: 24,
})

// to make it same height as zen fields
const DropdownWrapper = styled.div({
  label: {
    paddingBottom: 6,
    paddingTop: 2,
  },
  button: {
    height: 38,
  },
})

const CurrencyInputWrapper = styled.div({
  label: {
    paddingBottom: 6,
    paddingTop: 2,
  },
})

export type {
  FormTextGroupProps,
  InputItem,
  CurrencyInputItem,
  DropdownItem,
  DateItem,
  TextAreaInputItem,
}
export { FormTextGroup }
