import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface AccountsBreadcrumbsState {
  accountsUrl: string
}

const initialState: AccountsBreadcrumbsState = {
  accountsUrl: '',
}

const accountsBreadcrumbsSlice = createSlice({
  name: 'AccountsBreadcrumbs',
  initialState,
  reducers: {
    setAccountsUrl(state, action: PayloadAction<string>) {
      return {
        ...state,
        accountsUrl: action.payload,
      }
    },
  },
})

const accountsBreadcrumbsReducer = accountsBreadcrumbsSlice.reducer
const { setAccountsUrl } = accountsBreadcrumbsSlice.actions

export type { AccountsBreadcrumbsState }
export { accountsBreadcrumbsReducer, setAccountsUrl }
