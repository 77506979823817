import type { clientTypes } from '@extend-orders/client'

export const ordersAPIVersion: clientTypes.VersionsType = '2022-02-01'

export type OrdersSearchKeys = clientTypes.OrderSearchOptionsVersionMap[typeof ordersAPIVersion]
export interface OrdersSearchOptions {
  searchKey: OrdersSearchKeys | string
  searchValue: unknown
  limit?: number
  cursor?: string
}

export type OrdersGetResponse = clientTypes.OrdersGetResponseVersionMap[typeof ordersAPIVersion]
export type OrdersSearchResponse =
  clientTypes.OrderSearchResponseVersionMap[typeof ordersAPIVersion]
export type OrderSearchItem = NonNullable<OrdersSearchResponse['orders']>[number]
