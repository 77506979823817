import React from 'react'
import type { ColumnDefs } from '@extend/zen'
import type { StoresSearchItem } from '@helloextend/extend-api-client/src/models/store'
import { string as stringHelper } from '@extend/client-helpers'
import { Link } from 'react-router-dom'
import { Badge } from '@extend/zen'
import { ToggleStatusType } from './column-components/types'
import { StoreStatusToggle } from './column-components/store-status-toggle'

export const storesColumns: ColumnDefs<StoresSearchItem> = [
  {
    id: 'storeName',
    label: 'Name',
    isAlwaysPinned: true,
    isSortable: true,
    maxWidth: 300,
    search: 'explicit',
    renderCell: (data) => (
      <>
        <Link to={`/admin/stores/${data.row.original.storeId}`}>{data.getValue()} </Link>
        {data.row.original.isTestStore && (
          <Badge color="blue" emphasis="medium" text="Test" data-cy="test-store" />
        )}
        {data.row.original.isDeleted && (
          <Badge color="neutral" emphasis="medium" text="Deleted" data-cy="deleted-store" />
        )}
      </>
    ),
  },
  {
    id: 'accountId',
    label: 'Account ID',
    isSortable: true,
    search: 'explicit',
    renderCell: (data) => (
      <Link to={`/admin/accounts/${data.getValue()}/stores`}>{data.getValue()}</Link>
    ),
  },
  {
    id: 'storeId',
    label: 'Store ID',
    isSortable: true,
    search: 'explicit',
  },
  {
    id: 'merchantEwRevenueSharePercentage',
    label: 'PP Merch. Revenue',
    information: 'Product Protection Merchant Revenue',
    isSortable: true,
    renderCell: (data) => stringHelper.getPercentageStringFromDecimal(data.getValue()),
  },
  {
    id: 'enabled',
    label: 'PP Enabled',
    information:
      'Controls whether product protection offers are showing in the merchant store. This status can be modified by merchants on the merchant portal.',
    isHugged: true,
    align: 'center',
    renderCell: (data) => (
      <StoreStatusToggle
        isEnabled={data.getValue()}
        storeId={data.row.original.storeId}
        storeName={data.row.original.storeName}
        toggleType={ToggleStatusType.Enabled}
      />
    ),
  },
  {
    id: 'approved',
    label: 'PP Approved',
    information: 'Product Protection approval can only be controlled by Extend Admins.',
    isHugged: true,
    align: 'center',
    renderCell: (data) => (
      <StoreStatusToggle
        isEnabled={data.getValue()}
        storeId={data.row.original.storeId}
        storeName={data.row.original.storeName}
        toggleType={ToggleStatusType.Approved}
      />
    ),
  },
  {
    id: 'shippingProtectionEnabled',
    label: 'SP Enabled',
    information:
      'Controls whether Shipping Protection offers are showing in the merchant store. This status can be modified by merchants on the merchant portal.',
    isHugged: true,
    align: 'center',
    renderCell: (data) => (
      <StoreStatusToggle
        isEnabled={data.getValue()}
        storeId={data.row.original.storeId}
        storeName={data.row.original.storeName}
        toggleType={ToggleStatusType.Enabled}
        isShippingProtection
      />
    ),
  },
  {
    id: 'shippingProtectionApproved',
    label: 'SP Approved',
    information: 'Shipping Protection approval can only be controlled by Extend Admins.',
    isHugged: true,
    align: 'center',
    renderCell: (data) => (
      <StoreStatusToggle
        isEnabled={data.getValue()}
        storeId={data.row.original.storeId}
        storeName={data.row.original.storeName}
        toggleType={ToggleStatusType.Approved}
        isShippingProtection
      />
    ),
  },
  {
    id: 'isTestStore',
    renderCell: () => {
      return null
    },
  },
  {
    id: 'isDeleted',
    renderCell: () => {
      return null
    },
  },
]
