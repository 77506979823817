import type { FC, SyntheticEvent } from 'react'
import React, { useState, useEffect, useRef } from 'react'
import type { ButtonSize } from '@extend/zen'
import { Button, Info } from '@extend/zen'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import type { TooltipPosition } from '../../utils/calculate-tooltip-position'
import { calculateTooltipPosition } from '../../utils/calculate-tooltip-position'
import { InformationSize } from './types'
import { InformationBubble } from './information-bubble'

const OFFSET_PX = 4

interface InformationProps {
  children: React.ReactNode
  size?: InformationSize
  buttonSize?: Exclude<ButtonSize, 'regular' | 'large'>
  'data-cy'?: string
}

/**
 * @deprecated Use Zen Information component instead: `import { Information } from '@extend/zen'`
 */
const Information: FC<InformationProps> = ({
  children,
  size = InformationSize.regular,
  buttonSize = 'xsmall',
  'data-cy': dataCy,
}) => {
  const [isVisible, { off, toggle }] = useToggle(false)
  const [position, setPosition] = useState<TooltipPosition>({
    top: 0,
    bottom: 'auto',
    left: 0,
    right: 'auto',
  })

  const bubbleRef = useRef<HTMLDivElement>(null)
  const { ref: buttonRef } = useClickOutside<HTMLButtonElement>(off)

  const toggleVisibility = (e: SyntheticEvent): void => {
    toggle()
    e.stopPropagation()
  }

  useEffect(() => {
    if (!isVisible || !buttonRef.current || !bubbleRef.current) {
      return
    }

    const targetBounding = buttonRef.current.getBoundingClientRect()
    const refBounding = bubbleRef.current.getBoundingClientRect()
    const newPosition = calculateTooltipPosition(
      window.innerWidth,
      window.innerHeight,
      targetBounding,
      refBounding,
      OFFSET_PX,
    )

    setPosition(newPosition)
  }, [isVisible, buttonRef])

  useEffect(() => {
    // Use capture instead of bubble  to ensure any scroll event on the page will remove information bubble
    window.addEventListener('scroll', off, true)

    return () => {
      window.removeEventListener('scroll', off, true)
    }
  }, [off])

  return (
    <>
      <Button
        ref={buttonRef}
        onClick={toggleVisibility}
        size={buttonSize}
        emphasis="low"
        color="neutral"
        icon={Info}
        isToggled={isVisible}
        data-cy={dataCy}
      />
      <InformationBubble
        ref={bubbleRef}
        isVisible={isVisible}
        position={position}
        size={size}
        data-cy={`${dataCy}:bubble`}
      >
        {children}
      </InformationBubble>
    </>
  )
}

export { Information }
