import { Modal, ModalController, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useGetShippingProtectionPlanQuery } from '@helloextend/extend-api-rtk-query'
import type { SpPlanFormValues } from '../sp-plan-form/sp-plan-form-schema';
import { getInitialValues } from '../sp-plan-form/sp-plan-form-schema'
import { SpPlanForm } from '../sp-plan-form/sp-plan-form'

type SpPlanDuplicateModalProps = {
  planId: string
  isOpen: boolean
  closeModal: () => void
}

const SpPlanDuplicateModal: FC<SpPlanDuplicateModalProps> = ({ planId, isOpen, closeModal }) => {
  const { toast } = useToaster()
  const [formProps, setFormProps] = useState({ isLoading: false, canSubmit: false })
  const createFormRef = useRef<{ submit: () => void; resetForm: () => void }>(null)

  const {
    data: existingSpPlan,
    isLoading: loadingSpPlan,
    isError: isErrorGettingSpPlan,
  } = useGetShippingProtectionPlanQuery({ planId })

  useEffect(() => {
    if (isErrorGettingSpPlan) {
      toast({
        message: 'Unable to get Shipping Protection Plan details. Please try again.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
      closeModal()
    }
  }, [closeModal, isErrorGettingSpPlan, toast])

  return (
    <ModalController isOpen={isOpen}>
      <Modal
        heading="Create Shipping Protection Plan"
        primaryButtonProps={{
          text: 'Create',
          onClick: () => createFormRef.current?.submit(),
          isProcessing: formProps.isLoading,
          isDisabled: !formProps.canSubmit || formProps.isLoading,
          'data-cy': 'create-sp-plan-button',
        }}
        secondaryButtonProps={{
          text: 'Cancel',
          onClick: closeModal,
          'data-cy': 'cancel-sp-plan-button',
        }}
        onDismissRequest={closeModal}
        size="xl"
        isLoading={loadingSpPlan}
        data-cy="sp-plan-duplicate-modal"
      >
        <SpPlanForm
          modalRender
          initialValues={getInitialValues(
            (existingSpPlan ? { ...existingSpPlan, id: '' } : undefined) as SpPlanFormValues,
          )}
          ref={createFormRef}
          isEditing
          handleFormProps={setFormProps}
        />
      </Modal>
    </ModalController>
  )
}

export { SpPlanDuplicateModal }
