import React, { useMemo } from 'react'
import type { FC } from 'react'
import { useGetPlanCategoriesQuery } from '@helloextend/extend-api-rtk-query'

type PlanCategoryMapperProps = {
  planCategoryId: string
}

export const PlanCategoryMapper: FC<PlanCategoryMapperProps> = ({ planCategoryId }) => {
  const { data: planCategories } = useGetPlanCategoriesQuery()

  const value = useMemo(() => {
    return planCategories?.items.find((planCategory) => planCategory.id === planCategoryId)
  }, [planCategories])

  return <div>{value?.name}</div>
}
