import { useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { enterpriseInternalRoles, legacyInternalRoles } from '../utils/user-role-mapper'
import { LDFlag } from '../constants/ld-flags'

export function useSortedRoleOptions(): Array<{ display: string; value: string }> {
  const {
    [LDFlag.EnterpriseRoles]: FF_ENTERPRISE_ROLES,
    [LDFlag.LegacyAndEnterpriseRoles]: FF_ENTERPRISE_AND_LEGACY_ROLES,
  } = useFlags()

  return useMemo(() => {
    if (FF_ENTERPRISE_ROLES) {
      return sorted(Object.values(enterpriseInternalRoles))
    }

    if (FF_ENTERPRISE_AND_LEGACY_ROLES) {
      return sorted(Object.values({ ...enterpriseInternalRoles, ...legacyInternalRoles }))
    }

    return sorted(Object.values(legacyInternalRoles))
  }, [FF_ENTERPRISE_AND_LEGACY_ROLES, FF_ENTERPRISE_ROLES])
}

const sorted = (
  list: Array<{ value: string; display: string }>,
): Array<{ value: string; display: string }> => {
  return list.sort((a, b) => (a.display > b.display ? 1 : -1))
}
