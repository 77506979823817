import * as Yup from 'yup'
import type { CurrencyCode, ExpenseType } from '@helloextend/extend-api-client'
import type {
  ExpenseMetaData,
  ExpenseOwner,
} from '@helloextend/extend-api-client/src/models/expense'

const productReplacementMetaData = Yup.object()
  .shape({
    productName: Yup.string().default('').required(),
  })
  .defined()
const partsMetaData = Yup.object()
  .shape({
    partNumber: Yup.string().default('').required(),
  })
  .defined()

const baseSchema = Yup.object()
  .shape({
    status: Yup.string().default('approved'),
    type: Yup.string().default('').required(),
    cost: Yup.object()
      .shape({
        amount: Yup.number().positive().required(),
        currencyCode: Yup.string<CurrencyCode>().default('USD').required(),
      })
      .defined(),
    wholesaleCost: Yup.number().positive().optional(),
    description: Yup.string()
      .default('')
      .when('type', ([value]) => {
        switch (value) {
          case 'cleaning_kit':
            return Yup.string()
          default:
            return Yup.string().required()
        }
      }),
    isGoodwill: Yup.boolean().default(false),
    quantity: Yup.mixed<number>()
      .default(undefined)
      .test({
        name: 'testDecimalPlaces',
        message: 'Quantity must not have more than two decimal places',
        test: (value) => {
          if (!value) return true
          const decimals = value.toString().split('.')
          if (decimals.length !== 2) return true
          return decimals[1].length <= 2
        },
      })
      .test({
        name: 'isPositiveNumber',
        message: 'Quantity must be a positive number',
        test: (value) => {
          if (value == null) return true
          return typeof value === 'number' && value > 0
        },
      }),
    incurredBy: Yup.string<ExpenseOwner>().required(),
    metaData: Yup.object()
      .nullable()
      .default(null)
      .when('type', ([value]) => {
        switch (value) {
          case 'replacement':
          case 'refurbished_replacement':
            return productReplacementMetaData
          case 'parts':
            return partsMetaData
          default:
            return Yup.object().nullable()
        }
      }),
  })
  .defined()

type Values = Yup.InferType<typeof baseSchema>
type MetaDataValues =
  | Yup.InferType<typeof productReplacementMetaData>
  | Yup.InferType<typeof partsMetaData>

const metaDataLabels = {
  productName: 'Product',
  partNumber: 'Part Number',
  numberOfHours: 'Number of Hours',
}

const getMetaDataForType = (type: ExpenseType): ExpenseMetaData | null => {
  switch (type) {
    case 'replacement':
    case 'refurbished_replacement':
      return { productName: '' }
    case 'parts':
      return { partNumber: '' }
    default:
      return null
  }
}

const multiplyCostAndQuantity = (cost: number, quantity = 1): number => {
  // To avoid multiplying decimals, both will be multiplied by 100
  if (quantity === 1 || cost === 0) return cost
  const multipliedQuantity = quantity * 100
  const multipliedCost = cost * 100
  return Math.round((multipliedQuantity * multipliedCost) / 10000)
}

export type { Values, MetaDataValues }
export { baseSchema, metaDataLabels, getMetaDataForType, multiplyCostAndQuantity }
