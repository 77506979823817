import type { FC } from 'react'
import React, { useState, useMemo } from 'react'
import styled from '@emotion/styled'
import type {
  InsuranceClaim,
  ServiceOrder,
  ClaimPhotosGetResponse,
} from '@helloextend/extend-api-client'
import { COLOR, Button, Icon, ExpandMore } from '@extend/zen'
import { useGetContractQuery } from '@helloextend/extend-api-rtk-query'
import { generateSchema } from './schema'
import { ClaimPhotosSection } from '../../../claim-photos-section'

interface AttachmentTabProps {
  claim?: InsuranceClaim
  photoData?: ClaimPhotosGetResponse
  serviceOrder?: ServiceOrder
}

const AttachmentsTab: FC<AttachmentTabProps> = ({ serviceOrder, claim, photoData }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleCollapseToggle = (): void => {
    setIsCollapsed(!isCollapsed)
  }

  const { data: contract } = useGetContractQuery(
    { contractId: serviceOrder?.contractId ?? '' },
    {
      skip: !serviceOrder?.contractId,
    },
  )

  const schema = useMemo(
    () => generateSchema(serviceOrder, claim, contract),
    [serviceOrder, claim, contract],
  )

  const filteredSchema = schema.filter(({ key }) => {
    if (key === 'instructions') return true
    const packingSlipConfiguration = serviceOrder?.configurations?.packingSlipConfiguration
    return packingSlipConfiguration && packingSlipConfiguration[key]
  })

  const handleDownloadClick = (): void => {
    const packingSlipUrl = serviceOrder?.attachments?.packingSlip?.packingSlipUrl
    if (!packingSlipUrl) return
    window.open(packingSlipUrl, '_blank', 'noopener,noreferrer')
  }

  return (
    <>
      {claim && (
        <Container>
          <ClaimPhotosSection claim={claim} photoData={photoData} />
        </Container>
      )}
      {serviceOrder?.configurations?.packingSlipRequired && (
        <Container>
          <Header onClick={handleCollapseToggle}>
            <Title>Packing Slip (Mail-in Repair)</Title>
            <CaretWrapper isCollapsed={isCollapsed}>
              <Icon icon={ExpandMore} color={COLOR.NEUTRAL[800]} />
            </CaretWrapper>
          </Header>
          <ContentWrapper isCollapsed={isCollapsed}>
            <SlipDataContainer>
              {filteredSchema.map(({ key, label, value }) => (
                <SlipRow key={key}>
                  <RowKey>{label}:</RowKey>
                  <RowValue>{value}</RowValue>
                </SlipRow>
              ))}
            </SlipDataContainer>
            {Boolean(serviceOrder.attachments?.packingSlip?.packingSlipUrl) && (
              <ButtonWrapper>
                <Button
                  emphasis="medium"
                  text="Download Packing Slip"
                  size="regular"
                  onClick={handleDownloadClick}
                />
              </ButtonWrapper>
            )}
          </ContentWrapper>
        </Container>
      )}
    </>
  )
}

export type { AttachmentTabProps }
export { AttachmentsTab }

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  cursor: 'pointer',
})

const ButtonWrapper = styled.div({
  marginTop: 10,
})

const ContentWrapper = styled.div<{ isCollapsed?: boolean }>(({ isCollapsed }) => ({
  width: '100%',
  overflow: isCollapsed ? 'hidden' : undefined,
  maxHeight: isCollapsed ? 0 : 2000,
  transition: '250ms max-height ease-in-out',
  marginTop: 20,
}))

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '33px',
  color: COLOR.BLUE[1000],
  margin: 0,
  marginRight: 12,
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  marginBottom: 32,
  padding: 40,
})

const SlipDataContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
})

const SlipRow = styled.div({
  display: 'flex',
  marginTop: -15,
  flexDirection: 'row',
  lineHeight: '24px',
})

const RowKey = styled.p({
  minWidth: 200,
})

const RowValue = styled.p({
  overflowWrap: 'anywhere',
})

const CaretWrapper = styled.div<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
  '& > svg': {
    transform: isCollapsed
      ? 'scale(1.5) translateY(-50%)'
      : 'scale(1.5) translateY(-50%) rotate(-180deg)',
    transition: '250ms transform ease-in-out',
  },
}))
