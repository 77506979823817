import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button, Menu, MessageBlock } from '@extend/zen'
import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { batch, useSelector, useDispatch } from 'react-redux'
import type { MenuItem } from '@extend/zen'
import type { RootState } from '../../../../../reducers'
import * as selectors from '../../../../../reducers/selectors'
import {
  addMessageBlockToThread,
  setSelectedMessageBlock,
  setIsEditorPanelVisible,
  MessageBlockInsertionType,
} from '../../../../../store/slices/amp-slice'
import { getEmptyBlockByType } from '../../message-blocks/utils'
import { DynamicPickerTypes, KaleyConditionTypes, MessageBlockTypes } from '../../utils'
import { getConditionByType } from '../../kaley-conditions/utils'
import { getDynamicPickerByType } from '../../dynamic-picker/utils'

type ThreadAppendButtonsProps = {
  'data-cy': string
  shouldAddBlockToBeginning?: boolean
}

const ThreadAppendButtons: FC<ThreadAppendButtonsProps> = ({
  'data-cy': dataCy,
  shouldAddBlockToBeginning,
}) => {
  const dispatch = useDispatch()

  const selectedBlockIndex = useSelector((state: RootState) =>
    selectors.getSelectedMessageBlockIndex(state),
  )

  const selectedThread = useSelector((state: RootState) => selectors.getSelectedThread(state))

  const handleMessageBlockClickItem = async (e: SyntheticEvent): Promise<void> => {
    const ele = e?.target as HTMLElement
    const blockType = ele.textContent as MessageBlockTypes
    const block = getEmptyBlockByType(blockType)
    if (!block) return
    handleAddBlockToThread(block)
  }

  const handleKaleyConditionClickItem = async (e: SyntheticEvent): Promise<void> => {
    const ele = e?.target as HTMLElement
    const conditionType = ele.textContent as KaleyConditionTypes
    const condition = getConditionByType(conditionType)
    if (!condition) return
    handleAddBlockToThread(condition)
  }

  const handleDynamicPickerClickItem = async (e: SyntheticEvent): Promise<void> => {
    const ele = e?.target as HTMLElement
    const dynamicPickerType = ele.textContent as DynamicPickerTypes
    const dynamicPicker = getDynamicPickerByType(dynamicPickerType)
    if (!dynamicPicker) return
    handleAddBlockToThread(dynamicPicker)
  }

  const handleAddBlockToThread = (block: ScriptItem): void => {
    // any time we are adding a new block from thread start we want to make sure our pointer resets to the beginning of the thread
    if (shouldAddBlockToBeginning) dispatch(setSelectedMessageBlock(null))
    const lastBlockIndex =
      selectedThread && selectedThread.script.length !== 0 ? selectedThread.script.length : 0
    const indexToInjectBlock = selectedBlockIndex !== null ? selectedBlockIndex + 1 : lastBlockIndex

    batch(() => {
      dispatch(
        addMessageBlockToThread({
          scriptItem: block,
          ...(shouldAddBlockToBeginning && {
            messageBlockInsertionType: MessageBlockInsertionType.Start,
          }),
        }),
      )
      dispatch(setSelectedMessageBlock(shouldAddBlockToBeginning ? 0 : indexToInjectBlock))
      dispatch(setIsEditorPanelVisible(true))
    })
  }

  const menuButtons: MenuItem[] = [
    {
      type: 'menu',
      label: 'Message Block',
      items: Object.values(MessageBlockTypes).map((blockType) => ({
        type: 'button',
        label: blockType,
        onClick: handleMessageBlockClickItem,
        'data-cy': blockType,
      })),
    },
    {
      type: 'menu',
      label: 'Kaley Condition',
      items: Object.values(KaleyConditionTypes).map((conditionType) => ({
        type: 'button',
        label: conditionType,
        onClick: handleKaleyConditionClickItem,
        'data-cy': conditionType,
      })),
    },
    {
      type: 'menu',
      label: 'Dynamic Picker',
      items: Object.values(DynamicPickerTypes).map((dynamicPickerType) => ({
        type: 'button',
        label: dynamicPickerType,
        onClick: handleDynamicPickerClickItem,
        'data-cy': dynamicPickerType,
      })),
    },
  ]

  return (
    <Container>
      <Menu
        data-cy="menu-buttons"
        items={menuButtons}
        triggerRenderer={() => (
          <Button
            text="Add Block"
            size="small"
            emphasis="medium"
            icon={MessageBlock}
            data-cy={dataCy}
          />
        )}
      />
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  gap: 8,
  marginTop: 12,
  marginLeft: 44,
})

export type { ThreadAppendButtonsProps }
export { ThreadAppendButtons }
