import { useEffect, useCallback, useState } from 'react'
import {
  useLazyGetPlanMappingsQuery,
  useLazyGetPlansByIdsQuery,
} from '@helloextend/extend-api-rtk-query'
import type {
  StorePlanMappingWithFullPlan,
  StorePlanMapping,
  Plan,
} from '@helloextend/extend-api-client'

export interface GetPlanMappingAndFullPlanResult {
  isLoading: boolean
  isError: boolean
  data?: StorePlanMappingWithFullPlan[]
}

export function useGetPlanMappingsAndFullPlans(storeId: string): GetPlanMappingAndFullPlanResult {
  const [mappingsWithPlans, setMappingsWithPlans] = useState<StorePlanMappingWithFullPlan[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const [fetchPlansByIds] = useLazyGetPlansByIdsQuery()

  const [getPlanMappings] = useLazyGetPlanMappingsQuery()

  const fetchPlansData = useCallback(async () => {
    setIsLoading(true)
    let storePlanMappings: StorePlanMapping[] = []
    let plans: Plan[] = []

    try {
      storePlanMappings = await getPlanMappings({ storeId }).unwrap()

      const planIds = storePlanMappings.map((planMapping) => planMapping.planId)
      plans = await fetchPlansByIds(planIds).unwrap()
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
    }

    const hydratedMappings = storePlanMappings.map((planMapping) => {
      const plan = plans.find((p) => p.id === planMapping.planId) as Plan

      return { ...planMapping, plan }
    })
    setMappingsWithPlans(hydratedMappings)
    setIsLoading(false)
  }, [fetchPlansByIds, getPlanMappings, setMappingsWithPlans, storeId])

  useEffect(() => {
    fetchPlansData()
  }, [fetchPlansData])

  return {
    isLoading,
    isError,
    data: mappingsWithPlans,
  }
}
