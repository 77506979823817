import type { FC, SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'
import type { ClaimDenialReason } from '@helloextend/extend-api-client/src/models/claim'
import { ClaimSelectStatus } from '@helloextend/extend-api-client/src/models/claim'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { ButtonGroup, Button } from '@extend/zen'
import type { Claim } from '@helloextend/extend-api-client'
import { Select, SelectItem } from '../../../../../../components/select'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { useUpdateClaim } from '../../../../../../hooks'

interface DenyClaimDropdownProps {
  claim: Claim
}

const DenyClaimDropdown: FC<DenyClaimDropdownProps> = ({ claim }) => {
  const dispatch = useDispatch()
  const [denialReason, setDenialReason] = useState<ClaimDenialReason | null>(null)
  const { updateClaim, isSuccess } = useUpdateClaim()

  const handleSubmit = async (): Promise<void> => {
    if (!denialReason) return
    await updateClaim({
      claimId: claim.id,
      body: {
        status: ClaimSelectStatus.denied,
        customer: claim.customer,
        incident: claim.incident,
        denialReason,
      },
    })
    dispatch(setClaimDetailsActiveView(''))
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(setClaimDetailsToast('Claim denied!'))
    }
  }, [isSuccess])

  const claimDenialReasons = [
    { value: 'occurred_outside_coverage', label: 'Occurred Outside Coverage' },
    { value: 'failure_not_covered', label: 'Failure Not Covered' },
    { value: 'police_report_not_provided', label: 'Police Report Not Provided' },
    { value: 'images_not_provided', label: 'Images Not Provided' },
    { value: 'under_manufacturer_warranty', label: 'Under Manufacturer Warranty' },
  ]

  return (
    <ContentWrapper>
      <DataRow>
        <ItemWrapper>
          <LabelWrapper>
            <LabelText>Reason</LabelText>
          </LabelWrapper>
          <Select
            onChange={(e: SyntheticEvent<Element>): void => {
              const { value } = e.currentTarget as HTMLInputElement
              setDenialReason((value as ClaimDenialReason) || null)
            }}
            value={denialReason ?? ''}
            placeholder="—SELECT—"
            data-cy="deny-denialReason-select"
          >
            {claimDenialReasons.map((reason) => (
              <SelectItem {...reason} key={reason.value} data-cy={`select-item-${reason.value}`} />
            ))}

            <SelectItem value="" label="—SELECT—" />
          </Select>
        </ItemWrapper>
      </DataRow>
      <ButtonRow>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
          />
          <Button
            type="button"
            text="Submit"
            data-cy="submit-button"
            onClick={handleSubmit}
            isDisabled={!denialReason}
          />
        </ButtonGroup>
      </ButtonRow>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

const DataRow = styled.div({
  display: 'flex',
  width: '100%',
})

const ButtonRow = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
})

const ItemWrapper = styled.div({
  width: '100%',
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
})

const LabelWrapper = styled.label({
  fontFamily: 'Nunito Sans, sans-serif',
})

const LabelText = styled.div({
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
  lineHeight: '24px',
})

export { DenyClaimDropdown }
