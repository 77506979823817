import type { FC } from 'react'
import React from 'react'
import {
  useBulkStoreUpdateJobMutation,
  useGetStorePlanMappingProductCountsQuery,
} from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { COLOR, Modal, Spinner } from '@extend/zen'
import styled from '@emotion/styled'

type DeletePlanModalProps = {
  storeId: string
  planId: string
  toggleModalOff: () => void
}

const DeletePlanModal: FC<DeletePlanModalProps> = ({ storeId, planId, toggleModalOff }) => {
  const { data, isLoading } = useGetStorePlanMappingProductCountsQuery({ planId, storeId })

  const { toastCompleted, toastError } = useStandardToast()

  const [deleteSpm, { isLoading: isDeleting }] = useBulkStoreUpdateJobMutation()

  const handleDeleteConfirm = async (): Promise<void> => {
    try {
      await deleteSpm({
        storeId,
        jobType: 'delete-spm',
        planIds: [planId],
      }).unwrap()
      toastCompleted('Plan has been deleted successfully.')
      toggleModalOff()
    } catch {
      toastError('Something went wrong. Please try again.')
    }
  }

  const getCountsText = (): string => {
    if (!data) return ''
    const { totalProducts, totalProductVariants } = data

    if (totalProducts > 0 && totalProductVariants > 0) {
      return `${totalProducts} product${
        totalProducts > 1 ? 's' : ''
      } and ${totalProductVariants} variant${totalProductVariants > 1 ? 's' : ''}`
    }
    if (totalProducts > 0) {
      return `${totalProducts} product${totalProducts > 1 ? 's' : ''}`
    }
    if (totalProductVariants > 0) {
      return `${totalProductVariants} variant${totalProductVariants > 1 ? 's' : ''}`
    }
    return ''
  }

  return (
    <Modal
      data-cy="delete-plan-modal"
      heading="Remove plan from the store"
      onDismissRequest={toggleModalOff}
      primaryButtonProps={{
        onClick: handleDeleteConfirm,
        text: 'Delete',
        color: 'red',
        isDisabled: isLoading,
        isProcessing: isDeleting,
        'data-cy': 'delete-plan-modal-delete-button',
      }}
      secondaryButtonProps={{
        onClick: toggleModalOff,
        text: 'Cancel',
        color: 'blue',
        isDisabled: isDeleting,
        'data-cy': 'delete-plan-modal-cancel-button',
      }}
    >
      {isLoading ? (
        <SpinnerContainer>
          <Spinner color={COLOR.BLUE[800]} />
        </SpinnerContainer>
      ) : (
        <>
          <p data-cy="modal-text">
            This plan will no long be available for any of the products or variants in the store.{' '}
            {getCountsText().length > 0 && (
              <>
                <BoldSpan>{planId}</BoldSpan> is currently mapped to{' '}
                <BoldSpan>{getCountsText()}</BoldSpan>.
              </>
            )}
          </p>
          <p>Are you sure you want to remove the plan assignment from the store?</p>
        </>
      )}
    </Modal>
  )
}

const BoldSpan = styled.span({
  fontWeight: 700,
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { DeletePlanModal }
