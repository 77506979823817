import type { AdvancedSelectOption, FilterDef } from '@extend/zen'
import { CategoryMappingStatus } from '@helloextend/extend-api-client'
import type { PlanCategoryProps } from '../../category-mapper-table'

const { WARRANTABLE, NON_WARRANTABLE, UNMAPPED } = CategoryMappingStatus

export const getCategoryMapperFilterDefs = (
  planCategories: PlanCategoryProps[],
  merchantCategories: string[],
): FilterDef[] => {
  const categoryNames: string[] = []
  const categoryIds: string[] = []

  planCategories.forEach((category) => {
    categoryNames.push(category.display)
    categoryIds.push(category.planCategoryId || category.display)
  })

  return [
    {
      type: 'group',
      label: 'Filters',
      filterDefs: [
        {
          id: 'extendCategory',
          type: 'select',
          label: 'Extend Plan Category',
          options: getCategoryOptions(categoryNames),
          isMultiSelect: true,
        },
        {
          id: 'planCategoryId',
          type: 'select',
          label: 'Plan Category ID',
          options: getCategoryOptions(categoryIds),
          isMultiSelect: true,
        },
        {
          id: 'mappingStatus',
          type: 'select',
          label: 'Warranty Status',
          options: [
            { display: WARRANTABLE, value: WARRANTABLE },
            { display: NON_WARRANTABLE, value: NON_WARRANTABLE },
            { display: UNMAPPED, value: UNMAPPED },
          ],
          isMultiSelect: true,
        },
        {
          id: 'merchantCategory',
          type: 'select',
          label: 'Merchant Category',
          options: getCategoryOptions(merchantCategories),
          isMultiSelect: true,
        },
      ],
    },
  ]
}

export const getCategoryOptions = (categories: string[]): AdvancedSelectOption[] =>
  categories.map((category) => ({
    display: category,
    value: category,
  }))
