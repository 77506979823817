import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { ButtonGroup, Button, Select, Input } from '@extend/zen'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import type { WalletClosedReason, WalletGetResponse } from '@helloextend/extend-api-rtk-query'
import { useCloseWalletMutation } from '@helloextend/extend-api-rtk-query'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import type { Values } from './schema'
import { formSchema } from './schema'

interface CancelVirtualCardDropdownProps {
  serviceOrder: ServiceOrder
  wallet: WalletGetResponse
}

interface ReasonOption {
  label: string
  value: WalletClosedReason
}

const CancelVirtualCardDropdown: FC<CancelVirtualCardDropdownProps> = ({
  serviceOrder,
  wallet,
}) => {
  const dispatch = useDispatch()
  const [closeWallet] = useCloseWalletMutation()

  const { handleSubmit, setFieldValue, handleChange, values } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: formSchema.getDefault(),
    validationSchema: formSchema,
    onSubmit: (formValues: Values) => {
      closeWallet({
        walletId: wallet.id,
        serviceOrderId: serviceOrder.id,
        body: {
          reason: formValues.reason as WalletClosedReason,
          notes: formValues.notes,
        },
      })
      dispatch(setClaimDetailsToast('Virtual Card canceled!'))
      dispatch(setClaimDetailsActiveView(''))
    },
  })

  const reasonOptions: ReasonOption[] = [
    {
      label: 'Address verification failure on merchant site',
      value: 'address_verification_failure',
    },
    {
      label: 'Generic card decline on merchant site',
      value: 'generic_card_decline',
    },
    {
      label: 'Product unavailable / Out of stock at merchant',
      value: 'product_unavailable',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ]

  return (
    <ContentWrapper>
      <DataRow>
        <ItemWrapper>
          <LabelText>Reason</LabelText>
          <Select
            id="close-reason-select"
            onChange={(e: SyntheticEvent<Element>): void => {
              const { value } = e.currentTarget as HTMLInputElement
              setFieldValue('reason', value)
            }}
            value={values.reason}
            placeholder="—SELECT—"
            data-cy="close-reason-select"
          >
            {reasonOptions.map(({ label, value }) => (
              <option value={value} key={value} data-cy={`select-item-${value.replace('_', '-')}`}>
                {label}
              </option>
            ))}
          </Select>
        </ItemWrapper>
      </DataRow>
      <DataRow>
        <ItemWrapper>
          <Input
            label="Notes"
            id="notes"
            data-cy="notes-input"
            placeholder="Reason notes"
            value={values.notes ?? ''}
            onChange={handleChange}
          />
        </ItemWrapper>
      </DataRow>
      <ButtonRow>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
          />
          <Button
            type="button"
            emphasis="high"
            isDisabled={!values.reason}
            text="Submit"
            data-cy="submit-button"
            onClick={() => handleSubmit()}
          />
        </ButtonGroup>
      </ButtonRow>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

const DataRow = styled.div({
  display: 'flex',
  width: '100%',
})

const ButtonRow = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
})

const ItemWrapper = styled.div({
  width: '100%',
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
})

const LabelText = styled.div({
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
  lineHeight: '24px',
})

export { CancelVirtualCardDropdown }
