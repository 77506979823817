import styled from '@emotion/styled'
import { HeadingLarge, Badge } from '@extend/zen'
import type { LineItemType } from '@helloextend/extend-api-client'
import type { LineItemStatus } from '@helloextend/extend-api-client/src/models/order'
import type { FC } from 'react'
import React from 'react'
import { lineItemStatusColors, formattedLineItemTypes } from '../../lib/line-item-formatters'

export const LineItemIDAndStatus: FC<{
  lineItemId: string
  lineItemType: LineItemType
  status: LineItemStatus
}> = ({ lineItemId, lineItemType, status }) => {
  const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1)
  const badgeColor = lineItemStatusColors[status]
  return (
    <HeadingLarge data-cy="line-item-id-and-status">
      <HeadingContainer>
        <TypeAndIDContainer data-cy="line-item-type-and-id">
          {formattedLineItemTypes[lineItemType]} Line Item ID: {lineItemId}
        </TypeAndIDContainer>
        <Badge data-cy="status-badge" text={capitalizedStatus} color={badgeColor} />
      </HeadingContainer>
    </HeadingLarge>
  )
}

const HeadingContainer = styled.div({
  display: 'flex',
  alignContent: 'center',
  margin: '8px 0',
})

const TypeAndIDContainer = styled.div({
  marginRight: 8,
})
