import type { FC } from 'react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ModalController, Modal, useToaster, ToastColor, ToastDuration, COLOR } from '@extend/zen'
import type { ThreadListItem } from '@helloextend/extend-api-rtk-query'
import { usePatchThreadMutation } from '@helloextend/extend-api-rtk-query'
import type { ApiResponse, ErrorResponse } from '@helloextend/extend-api-fetch'
import styled from '@emotion/styled'
import { setIsArchiveThreadModalVisible } from '../../../../../store/slices/amp-slice'

type ArchiveThreadModalProps = {
  isVisible: boolean
  onCancel: () => void
  thread: ThreadListItem | null
}

const ArchiveThreadModal: FC<ArchiveThreadModalProps> = ({ isVisible, onCancel, thread }) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()

  const [archiveThread, { isLoading: isArchiveThreadProcessing }] = usePatchThreadMutation()

  const handleArchive = async (): Promise<void> => {
    if (!thread) return
    try {
      const archivedThread = await archiveThread({
        threadId: thread.id,
        patches: [
          {
            op: 'replace',
            path: '/status',
            value: 'archived',
          },
        ],
      }).unwrap()

      toast({
        message: `${archivedThread.title} has been successfully archived.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      dispatch(setIsArchiveThreadModalVisible(false))
    } catch (err: unknown) {
      const responsePayload = (err as ApiResponse)?.data
      const message = (responsePayload as ErrorResponse)?.message
      toast({
        message: message ?? 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="amp-archive-thread-modal"
        heading="Archive Thread"
        primaryButtonProps={{
          'data-cy': 'amp-archive-thread-modal-submit',
          onClick: handleArchive,
          text: 'Archive',
          isDisabled: isArchiveThreadProcessing,
          isProcessing: isArchiveThreadProcessing,
        }}
        secondaryButtonProps={{
          'data-cy': 'amp-archive-thread-modal-cancel',
          onClick: onCancel,
          text: 'Cancel',
        }}
        onDismissRequest={onCancel}
      >
        <Text data-cy="amp-archive-thread-warning-message">
          Archiving a thread prevents it from being assigned to conversations.
        </Text>
        <Text data-cy="amp-archive-confirm-message">
          Are you sure you want to archive {thread?.title || 'it'}?
        </Text>
      </Modal>
    </ModalController>
  )
}

const Text = styled.p({
  color: COLOR.NEUTRAL[800],
  fontSize: 16,
  lineHeight: '24px',
})

export type { ArchiveThreadModalProps }
export { ArchiveThreadModal }
