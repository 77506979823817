import type { ChangeEvent, Dispatch, FC } from 'react'
import React, { createRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Button, Check, COLOR, Error, InlineAlert, InlineAlertColor, Paragraph } from '@extend/zen'
import { useImportEmbeddedPromotionMutation } from '@helloextend/extend-api-rtk-query'
import { gzip } from 'fflate'
import promotionCsvSample from './promotion-csv-sample.csv'
import { parseEmbeddedPromotionCsv } from '../../../utils/embedded-promotion-csv-upload/parse-embedded-promotion-csv'
import { ImportState } from './constants'

type ErrorType = { status?: string; error?: string }

interface AlertItem {
  key: string
  text: string
}

const convertToAlertItem = (alerts: string[]): AlertItem[] => {
  return alerts.map((error, index) => ({
    key: `error-${index}`,
    text: error,
  }))
}

export interface PromotionCSVImportProps {
  importState: ImportState
  setImportState: Dispatch<React.SetStateAction<ImportState>>
  presignedUrl: string | null
}

const gzipPromise = (file: File): Promise<Uint8Array> =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const arrayBuffer = await file.arrayBuffer()
    const uint8Array = new Uint8Array(arrayBuffer)
    gzip(uint8Array, (err, data) => {
      if (err) {
        reject(err)
      }
      resolve(data)
    })
  })

export const PromotionCSVImport: FC<PromotionCSVImportProps> = ({
  importState,
  setImportState,
  presignedUrl,
}) => {
  const [isParsing, setIsParsing] = useState<boolean>(false)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [importItemsNumber, setImportItemsNumber] = useState(0)
  const [errorsBanner, setErrorsBanner] = useState<AlertItem[]>([])
  const [warningsBanner, setWarningsBanner] = useState<AlertItem[]>([])
  const [hasValidated, setHasValidated] = useState(false)

  const [uploadCsv] = useImportEmbeddedPromotionMutation()

  const inputFileRef = createRef<HTMLInputElement>()

  const handleSelectFile = (): void => {
    resetUploadedFile()
    inputFileRef.current?.click()
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0] || null
    setUploadedFile(file)
    setHasValidated(false)
  }

  const resetUploadedFile = (): void => {
    setUploadedFile(null)
    setErrorsBanner([])
    setWarningsBanner([])
    setHasValidated(false)
    setImportState(ImportState.READY)
    const { current } = inputFileRef
    if (current?.value) {
      current.value = ''
    }
  }

  useEffect(() => {
    if (!uploadedFile || hasValidated || importState !== ImportState.READY) {
      return
    }
    setIsParsing(true)
    const parseAndValidateUploadedFile = async (): Promise<void> => {
      try {
        const results = await parseEmbeddedPromotionCsv(uploadedFile)

        setImportItemsNumber(results?.data?.length as number)

        const errors = convertToAlertItem(results?.errors || [])
        const warnings = convertToAlertItem(results?.warnings || [])

        if (warnings.length > 0) {
          setWarningsBanner(warnings)
        }

        if (errors.length > 0) {
          setErrorsBanner(errors)
          setImportState(ImportState.FILE_PARSE_FAILED)
        } else {
          setImportState(ImportState.FILE_SELECTED)
        }

        setHasValidated(true)
      } catch {
        // do nothing apparently
      }
      setIsParsing(false)
    }

    parseAndValidateUploadedFile()
  }, [hasValidated, uploadedFile])

  useEffect(() => {
    if (presignedUrl && uploadedFile && importState === ImportState.UPLOADING) {
      const upload = async (): Promise<void> => {
        try {
          const content = await gzipPromise(uploadedFile)
          const blob = new Blob([content])
          await uploadCsv({ url: presignedUrl, blob }).unwrap()
          setImportState(ImportState.UPLOADED)
        } catch (err: unknown) {
          // There can be CORS errors despite successfully uploading
          if (
            (err as ErrorType).status !== 'FETCH_ERROR' ||
            (err as ErrorType).error !==
              'TypeError: NetworkError when attempting to fetch resource.'
          ) {
            setImportState(ImportState.UPLOAD_FAILED)
          }
        }
      }

      upload()
    }
  }, [presignedUrl])

  const alertPrimaryButtonProps = {
    text: 'Replace File',
    onClick: handleSelectFile,
    isProcessing: isParsing,
    isDisabled: isParsing || importState === ImportState.UPLOADING,
  }

  return (
    <>
      <BoldParagraph>PRODUCTS FOR PROMOTION</BoldParagraph>
      {uploadedFile && hasValidated && errorsBanner.length === 0 && (
        <InlineAlertWrapper>
          <InlineAlert
            color={InlineAlertColor.green}
            icon={Check}
            primaryButtonProps={alertPrimaryButtonProps}
            data-cy="import-success-alert"
          >
            {uploadedFile?.name} has been successfully validated! You are about to import{' '}
            {importItemsNumber} embedded promotions.
          </InlineAlert>
        </InlineAlertWrapper>
      )}
      {uploadedFile && hasValidated && errorsBanner.length > 0 && (
        <InlineAlertWrapper>
          <InlineAlert
            color={InlineAlertColor.red}
            icon={Error}
            primaryButtonProps={alertPrimaryButtonProps}
            data-cy="import-error-alert"
          >
            {uploadedFile.name} failed to validate.
            {errorsBanner.map(({ key, text }) => (
              <li key={key}>{text}</li>
            ))}
          </InlineAlert>
        </InlineAlertWrapper>
      )}
      {uploadedFile && hasValidated && warningsBanner.length > 0 && (
        <InlineAlertWrapper>
          <InlineAlert
            color={InlineAlertColor.yellow}
            icon={Error}
            primaryButtonProps={alertPrimaryButtonProps}
            data-cy="import-error-alert"
          >
            {uploadedFile.name} failed to validate.
            {warningsBanner.map(({ key, text }) => (
              <li key={key}>{text}</li>
            ))}
          </InlineAlert>
        </InlineAlertWrapper>
      )}

      <ParagraphWrapper data-cy="download-links">
        <Paragraph>
          Upload a promotion CSV file.{' '}
          <StyledDownload href={promotionCsvSample}>Download promotion CSV sample</StyledDownload>
        </Paragraph>
      </ParagraphWrapper>
      {(!uploadedFile || isParsing) && (
        <>
          <ButtonContainer>
            <Button
              data-cy="select-promotion-csv-button"
              text="Select CSV File"
              onClick={handleSelectFile}
              emphasis="medium"
              isProcessing={isParsing}
              isDisabled={isParsing}
            />
          </ButtonContainer>
        </>
      )}
      <HiddenInput
        id="select-promotion-csv-input"
        ref={inputFileRef}
        accept=".csv"
        type="file"
        onChange={handleFileChange}
      />
    </>
  )
}

const HiddenInput = styled.input({
  display: 'none',
})

const ButtonContainer = styled.div({
  margin: '32px 0 40px',
})

const InlineAlertWrapper = styled.div({
  marginTop: 24,
})

const ParagraphWrapper = styled(Paragraph)({
  paddingTop: 18,
})

const StyledDownload = styled.a({
  color: COLOR.BLUE[800],
  textDecoration: 'underline',
})

const BoldParagraph = styled(Paragraph)({
  fontWeight: 600,
})
