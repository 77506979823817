import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const conversationsSamplePhotos = createApi({
  reducerPath: 'conversationsSamplePhotos',
  baseQuery: fetchBaseQuery({}),
  endpoints: (build) => ({
    getSamplePhoto: build.query<Blob, { url: string }>({
      query: ({ url }) => ({
        url,
        method: 'GET',
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
})

export const { useLazyGetSamplePhotoQuery } = conversationsSamplePhotos
