import type { PromptType, Reply, Slot } from '@helloextend/extend-api-client'
import type { ComponentType } from 'react'
import { AnswerDatePicker } from './answer-date-picker'
import { AnswerImageUpload } from './answer-image-upload'
import { AnswerProductSelection } from './answer-product-selection'
import { AnswerSelection } from './answer-selection'
import { AnswerShipmentSelection } from './answer-shipment-selection'
import { AnswerTextArea } from './answer-text-area'

export interface UserInputComponentProps<T extends Reply> {
  onSubmit: (slot: Slot, slotValue: string | number | string[]) => void
  reply: T
  isLoading?: boolean
  shouldAutoSelectOption?: boolean
}

export const PROMPT_MAP: Partial<Record<
  PromptType,
  ComponentType<UserInputComponentProps<any>>
>> = {
  input: AnswerTextArea,
  datepicker: AnswerDatePicker,
  imageUpload: AnswerImageUpload,
  multiselect: AnswerSelection,
  buttons: AnswerSelection,
  shipmentSelect: AnswerShipmentSelection,
  productSelect: AnswerProductSelection,
}
