import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  Claim,
  ClaimNote,
  ClaimsSearchResponse,
  ClaimPhotosGetResponse,
  ClaimPhotosCreateResponse,
  ClaimPhotosCreateRequest,
  ClaimPhotoDetail,
} from '@helloextend/extend-api-client'
import type {
  GetClaimsReponse,
  InsuranceClaim,
} from '@helloextend/extend-api-client/src/models/claim'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { baseQuery, X_EXTEND_ACCESS_TOKEN } from '../base-query'
import type {
  ClaimAssignmentRequest,
  ClaimAssignmentResponse,
  ClaimsNotesCreateRequest,
  ClaimsNotesGetResponse,
  ClaimsSearchQueryStringOptions,
  ClaimSearchOptionsV1,
  ClaimsSearchResponseV2,
  ClaimsSearchResponseV1,
  InsuranceClaimsListResponse,
  InsuranceClaimsListQueryStringOptions,
  UpdateClaimNoteRequest,
  ClaimsUpdateRequest,
  InsuranceClaimsSearchResponse,
  ClaimsSearchParameters,
} from './types'
import { safeBtoa } from '../../helpers'

const buildCacheKey = (qs: ClaimsSearchQueryStringOptions): string => {
  const { cursor, minLimit, ...qsWithoutPagination } = qs
  return safeBtoa(JSON.stringify(qsWithoutPagination))
}

export const claimsApi = createApi({
  baseQuery,
  reducerPath: 'Claims',
  tagTypes: ['claims', 'claim-notes', 'claim-photos'],
  endpoints: (build) => ({
    getClaim: build.query<ClaimsSearchResponse, { claimId: string }>({
      query: ({ claimId }) => ({
        url: `contracts/claims/search`,
        params: { claimId },
      }),
      transformResponse: ({ claims }) => {
        return claims.length > 0 ? claims[0] : null
      },
      providesTags: (_, _err, { claimId }) => [{ type: 'claims', id: claimId }],
    }),
    getClaimWithFulfillments: build.query<
      GetClaimsReponse,
      { claimId: string; contractId: string; accessToken: string }
    >({
      query: ({ claimId, contractId, accessToken }) => ({
        url: `contracts/${contractId}/claims/${claimId}`,
        headers: {
          [X_EXTEND_ACCESS_TOKEN]: accessToken,
        },
      }),
      providesTags: (_, _err, { claimId }) => [{ type: 'claims', id: claimId }],
    }),
    getClaimsByContractId: build.query<{ claims: ClaimsSearchResponse[] }, { contractId: string }>({
      query: ({ contractId }) => ({
        url: `contracts/claims/search`,
        params: { contractId },
      }),
      providesTags: (_, _err, { contractId }) => [{ type: 'claims', id: contractId }],
    }),
    searchClaimsV1: build.query<ClaimsSearchResponseV1, ClaimSearchOptionsV1>({
      query: (qs: ClaimSearchOptionsV1) => ({
        url: 'contracts/claims/search',
        params: { typeFilter: ['shipping_protection', 'extended_warranty'], ...qs },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, qs) => [{ type: 'claims', id: buildCacheKey(qs) }],
    }),
    searchClaims: build.query<ClaimsSearchResponseV2, ClaimsSearchQueryStringOptions>({
      query: (qs: ClaimsSearchQueryStringOptions) => ({
        url: 'contracts/claims/search',
        params: { typeFilter: ['shipping_protection', 'extended_warranty'], ...qs },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, qs) => {
        return [{ type: 'claims', id: buildCacheKey(qs) }]
      },
      async onCacheEntryAdded(
        arg: ClaimsSearchQueryStringOptions,
        { getState, updateCachedData, cacheDataLoaded },
      ) {
        const { cursor } = arg
        const { Claims } = getState()
        if (cursor?.length) {
          const queryValues = Claims.queries
          const cacheKey = buildCacheKey(arg)
          const getCachedKeys = Claims.provided.claims[cacheKey]
          const cachedQueries = getCachedKeys.reduce(
            (newArr: ClaimsSearchResponseV2['items'], cachedDataKey) => {
              const cache = queryValues[cachedDataKey]
              const { items } = cache?.data as ClaimsSearchResponseV2
              return [...newArr, ...items]
            },
            [],
          )
          try {
            const { data } = await cacheDataLoaded

            updateCachedData((draft) => ({
              ...draft,
              items: [...cachedQueries, ...data.items],
            }))
          } catch (err) {
            console.error(err)
          }
        }
      },
    }),
    getClaimPhotos: build.query<
      ClaimPhotosGetResponse,
      { claimId: string; filterStatuses?: Array<ClaimPhotoDetail['status']> }
    >({
      query: ({ claimId }) => {
        return {
          url: `claims-management/claims/${claimId}/photos`,
        }
      },
      transformResponse: (
        response: ClaimPhotosGetResponse,
        _meta,
        { filterStatuses = ['saved'] },
      ) => ({
        photoDetails: response.photoDetails?.filter((photo) =>
          filterStatuses.includes(photo.status),
        ),
      }),
      providesTags: (_, _err, { claimId }) => [{ type: 'claim-photos', id: claimId }],
    }),
    createClaimPhoto: build.mutation<
      ClaimPhotosCreateResponse,
      { claimId: string; body: ClaimPhotosCreateRequest }
    >({
      query: ({ claimId, body }) => ({
        url: `claims-management/claims/${claimId}/photos`,
        method: 'POST',
        body,
      }),
    }),
    deleteClaimPhoto: build.mutation<
      void,
      { claimId: string; photoId: string; contractId: string }
    >({
      query: ({ claimId, photoId }) => ({
        url: `claims-management/claims/${claimId}/photos/${photoId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ claimId, photoId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          claimsApi.util.updateQueryData(
            'getClaimPhotos',
            { claimId },
            (response: ClaimPhotosGetResponse) => {
              return {
                ...response,
                photoDetails: response.photoDetails.filter((photo) => photo.name !== photoId),
              }
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (_, _err, { claimId }) => [{ type: 'claim-photos', id: claimId }],
    }),
    getClaimNotes: build.query<ClaimsNotesGetResponse, { claimId?: string; claimIds?: string[] }>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { claimId, claimIds } = args
        if (claimIds) {
          const promises = claimIds.map((id) => {
            return fetchWithBQ({
              url: `claims-management/claims/${id}/notes`,
            })
          })
          const results = await Promise.all(promises)
          const data = results.reduce((acc: ClaimNote[], result) => {
            return [...acc, ...(result.data as ClaimsNotesGetResponse).items]
          }, [])
          return { data: { items: data } }
        }

        const response: QueryReturnValue = await fetchWithBQ({
          url: `claims-management/claims/${claimId}/notes`,
        })

        if (response.error) throw response.error

        const payload = response.data as ClaimsNotesGetResponse

        return { data: payload }
      },
      providesTags: (_, _error, { claimId, claimIds }) =>
        (claimIds || (claimId ? [claimId] : [])).map((id) => ({ type: 'claim-notes', id })),
    }),
    updateClaimNote: build.mutation<ClaimNote, UpdateClaimNoteRequest>({
      query: ({ claimId, noteId, body }) => ({
        url: `claims-management/claims/${claimId}/notes/${noteId}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ claimId, noteId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          claimsApi.util.updateQueryData(
            'getClaimNotes',
            { claimId },
            (response: ClaimsNotesGetResponse) => {
              const note = response.items.find((claimNote) => claimNote.id === noteId)
              if (note) {
                Object.assign(note, body)
              }
              return response
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: ['claim-notes'],
    }),
    createClaimNotes: build.mutation<ClaimNote, ClaimsNotesCreateRequest>({
      query: ({ claimId, body }) => ({
        url: `claims-management/claims/${claimId}/notes`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        body,
      }),
      invalidatesTags: ['claim-notes'],
      async onQueryStarted({ claimId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          claimsApi.util.updateQueryData(
            'getClaimNotes',
            { claimId },
            (response: ClaimsNotesGetResponse) => {
              response?.items.push({
                ...(body as ClaimNote),
                id: `${Date.now()}`,
                createdAt: Date.now(),
              })
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    getInsuranceClaim: build.query<InsuranceClaim, { claimId: string; accessToken?: string }>({
      query: ({ claimId, accessToken }) => ({
        url: `claims-management/claims/${claimId}`,
        params: { claimId },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=2022-02-01;',
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
      }),
      providesTags: (_, _err, { claimId }) => [{ type: 'claims', id: claimId }],
    }),
    submitClaim: build.mutation<InsuranceClaim, { claimId: string }>({
      query: ({ claimId }) => ({
        url: `claims-management/claims/${claimId}/submit`,
        params: { claimId },
        method: 'POST',
      }),
      invalidatesTags: ['claims'],
    }),
    updateClaim: build.mutation<InsuranceClaim, ClaimsUpdateRequest>({
      query: ({ claimId, body }) => ({
        url: `claims-management/claims/${claimId}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ claimId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          claimsApi.util.updateQueryData('getInsuranceClaim', { claimId }, (claim: Claim) => {
            return {
              ...claim,
              ...body,
              ...(body.status === 'closed' && {
                closedMetaData: {
                  closedAt: Date.now(),
                  resolution: body.resolution ?? 'no_service',
                },
              }),
            } as InsuranceClaim
          }),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: ['claims'],
    }),
    listInsuranceClaims: build.query<
      InsuranceClaimsListResponse,
      InsuranceClaimsListQueryStringOptions
    >({
      query: (qs: InsuranceClaimsListQueryStringOptions) => ({
        url: 'claims-management/claims',
        params: qs,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=2022-02-01;',
        },
      }),
      providesTags: (_, _err, qs) => {
        return [{ type: 'claims', id: buildCacheKey(qs) }]
      },
      async onCacheEntryAdded(
        arg: InsuranceClaimsListQueryStringOptions,
        { getState, updateCachedData, cacheDataLoaded },
      ) {
        const { cursor } = arg
        const { Claims } = getState()
        if (cursor?.length) {
          const queryValues = Claims.queries
          const cacheKey = buildCacheKey(arg)
          const getCachedKeys = Claims.provided.claims[cacheKey]
          const cachedQueries = getCachedKeys.reduce(
            (newArr: InsuranceClaimsListResponse['items'], cachedDataKey) => {
              const cache = queryValues[cachedDataKey]
              const { items } = cache?.data as InsuranceClaimsListResponse
              return [...newArr, ...items]
            },
            [],
          )
          try {
            const { data } = await cacheDataLoaded

            updateCachedData((draft) => ({
              ...draft,
              items: [...cachedQueries, ...data.items],
            }))
          } catch (err) {
            console.error(err)
          }
        }
      },
    }),
    assignClaim: build.mutation<ClaimAssignmentResponse, ClaimAssignmentRequest>({
      query: ({ claimId, user: { id: userId } }) => ({
        url: `claims-management/claims/${claimId}/assign`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        body: {
          userId,
        },
      }),
      invalidatesTags: (_, _err, { claimId }) => [{ type: 'claims', id: claimId }],
      async onQueryStarted({ claimId, user }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          claimsApi.util.updateQueryData(
            'getInsuranceClaim',
            { claimId },
            (response: InsuranceClaim) => {
              response.assignedUser = user
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    claimsSearch: build.query<InsuranceClaimsSearchResponse, ClaimsSearchParameters>({
      query: (params) => ({
        url: 'claims-management/claims/search',
        params,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=2022-02-01;',
        },
      }),
      providesTags: () => ['claims'],
    }),
  }),
})

export const {
  useUpdateClaimMutation,
  useGetClaimQuery,
  useGetClaimWithFulfillmentsQuery,
  useGetClaimsByContractIdQuery,
  useSearchClaimsV1Query,
  useSearchClaimsQuery,
  useGetClaimPhotosQuery,
  useCreateClaimPhotoMutation,
  useDeleteClaimPhotoMutation,
  useGetClaimNotesQuery,
  useUpdateClaimNoteMutation,
  useCreateClaimNotesMutation,
  useAssignClaimMutation,
  useGetInsuranceClaimQuery,
  useLazyGetInsuranceClaimQuery,
  useListInsuranceClaimsQuery,
  useSubmitClaimMutation,
  useClaimsSearchQuery,
  useLazyClaimsSearchQuery,
} = claimsApi
