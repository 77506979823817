import type { FC } from 'react'
import React, { useCallback, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Add, Button, DataTable } from '@extend/zen'
import type {
  SortingState,
  PaginationState,
  ColumnFiltersState,
  DataTableAction,
} from '@extend/zen'
import type { ConversationConfigurationResponse } from '@helloextend/extend-api-rtk-query'
import { ClipboardSearchIcon } from '@helloextend/merchants-ui'
import { useHistory, useLocation } from 'react-router-dom'
import { bp } from '@extend/client-helpers'
import { Permission } from '../../../../lib/permissions'
import { AdjudicationTabs } from '../components'
import { configurationColumns } from './table-config'
import { usePermissions } from '../../../../hooks/use-permissions'
import { getColumnFiltersFromSearchParams, getSearchParams } from '../utils'
import { useListConversationConfigs } from '../../../../hooks/use-list-conversation-configs'

const ConversationConfigurationLanding: FC = () => {
  const { push } = useHistory()
  const { hasPermission } = usePermissions()
  const { search: searchParams } = useLocation()
  const columnFiltersInit = getColumnFiltersFromSearchParams(searchParams)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(columnFiltersInit)
  const { tableData, isFetching, hasConfigurations } = useListConversationConfigs()

  const [sorting, setSorting] = useState<SortingState>([{ id: 'updatedAt', desc: true }])
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })

  useEffect(() => {
    if (columnFilters.length > 0) {
      const search = getSearchParams(columnFilters)
      push({
        pathname: '/admin/adjudication-management/conversation-configuration',
        search,
      })
      return
    }
    push({
      pathname: '/admin/adjudication-management/conversation-configuration',
      search: '',
    })
  }, [push, columnFilters])

  const handleRoute = useCallback(
    (pathname: string) => {
      push({
        pathname,
        search: searchParams,
      })
    },
    [push, searchParams],
  )

  const handleCreateConversation = (): void => {
    handleRoute('/admin/adjudication-management/conversation-configuration/create')
  }

  const getRowActions = (row: ConversationConfigurationResponse): DataTableAction[] => [
    {
      onClick: () =>
        handleRoute(`/admin/adjudication-management/conversation-configuration/${row.id}`),
      emphasis: 'low',
      text: 'View',
      'data-cy': `view-conversation-configuration-${row.id}`,
    },
  ]

  return (
    <>
      <AdjudicationTabs />
      <InfoWrapper>
        <InfoText>
          The defined configuration will determine what conversation a customer will see when they
          file their claim via Kaley.
        </InfoText>
      </InfoWrapper>
      <TopRowButtonContainer>
        {hasPermission(Permission.ManageAdjudication) && (
          <ButtonWrapper>
            <Button
              data-cy="create-configuration-button"
              text="Create Configuration"
              icon={Add}
              emphasis="medium"
              onClick={handleCreateConversation}
            />
          </ButtonWrapper>
        )}
      </TopRowButtonContainer>
      {hasConfigurations && (
        <DataTable
          data-cy="conversation-configuration-list"
          isLoading={isFetching}
          data={tableData}
          columns={configurationColumns}
          hasConfigurableColumns={false}
          sorting={sorting}
          onSortingChange={setSorting}
          hasManualSorting={false}
          pagination={pagination}
          onPaginationChange={setPagination}
          hasManualPagination={false}
          columnFilters={columnFilters}
          onColumnFiltersChange={setColumnFilters}
          hasManualFiltering={false}
          getRowActions={getRowActions}
        />
      )}
      {!hasConfigurations && (
        <NoConfigurationsContainer>
          <ClipboardSearchIcon sizePx={48} />
          <NoConfigurationsHeader>No configurations yet</NoConfigurationsHeader>
          <NoConfigurationsText>
            The defined configuration will determine what conversation a customer will see when they
            file their claim via Kaley.
          </NoConfigurationsText>
        </NoConfigurationsContainer>
      )}
    </>
  )
}

const TopRowButtonContainer = styled.div({
  display: 'flex',
  gap: 40,
  marginBottom: 24,
  [bp.lg]: {
    justifyContent: 'flex-end',
    marginBottom: -40,
  },
})

const InfoWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 40,
})

const ButtonWrapper = styled.div({
  flexShrink: 0,
})

const InfoText = styled.div({
  fontSize: 16,
  lineHeight: '24px',
  marginBottom: 28,
})

const NoConfigurationsContainer = styled.div({
  maxWidth: 584,
  margin: 'auto',
  marginTop: '10%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
})

const NoConfigurationsHeader = styled.p({
  margin: '32px 0 0 0',
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '34px',
})

const NoConfigurationsText = styled.p({
  fontWeight: 400,
  fontSize: 20,
  lineHeight: '28px',
  marginTop: 24,
})

export { ConversationConfigurationLanding }
