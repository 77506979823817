import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button, Add, ModalController, Grid } from '@extend/zen'
import type { CustomTableauReport, Store } from '@helloextend/extend-api-client'
import { useAtom, useSetAtom } from 'jotai/react'
import { EditTableauReportsModal } from './edit-tableau-reports-modal'
import { selectedTableauReportAtom, openTableauReportModalAtom } from './atoms/tableauReportAtom'
import { ManageCustomTableauReportsModal } from './manage-custom-tableau-reports-modal'
import { DeleteTableauReportsModal } from './delete-tableau-reports-modal'
import { AddTableauReportsModal } from './add-tableau-reports-modal'

interface TableauReportsProps {
  store: Store
}

const TableauReports: FC<TableauReportsProps> = ({ store }) => {
  const customReports = store.customReports || []

  const [openTableauReportModal, setOpenTableauReportModal] = useAtom(openTableauReportModalAtom)
  const setSelectedReport = useSetAtom(selectedTableauReportAtom)

  const openAddCustomTableauReportsModal = (report?: CustomTableauReport): void => {
    if (report && report?.title && report.url) {
      setSelectedReport(report)
    }
    setOpenTableauReportModal('add')
  }

  const openManageCustomTableauReportsModal = (): void => {
    setOpenTableauReportModal('manage')
  }

  const hasCustomReports = customReports.length > 0

  return (
    <ReportsContainer>
      <>
        {hasCustomReports ? (
          <Grid columns={2}>
            <Button
              hasReducedPadding
              text="Manage Custom Tableau Reports"
              emphasis="medium"
              size="regular"
              onClick={() => openManageCustomTableauReportsModal()}
            />
          </Grid>
        ) : (
          <Grid columns={2}>
            <Button
              icon={Add}
              text="Add Custom Tableau Report"
              emphasis="medium"
              size="regular"
              onClick={() => openAddCustomTableauReportsModal()}
            />
          </Grid>
        )}
      </>
      <ModalController isOpen={openTableauReportModal === 'add'}>
        <AddTableauReportsModal customReports={customReports} storeId={store.id} />
      </ModalController>
      <ModalController isOpen={openTableauReportModal === 'edit'}>
        <EditTableauReportsModal customReports={customReports} storeId={store.id} />
      </ModalController>
      <ModalController isOpen={openTableauReportModal === 'manage'}>
        <ManageCustomTableauReportsModal store={store} />
      </ModalController>
      <ModalController isOpen={openTableauReportModal === 'delete'}>
        <DeleteTableauReportsModal customReports={customReports} storeId={store.id} />
      </ModalController>
    </ReportsContainer>
  )
}

const ReportsContainer = styled.div({
  marginTop: 8,
})

export { TableauReports }
