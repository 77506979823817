import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'

type ScopeDetails = {
  name: string
  shortDescription: string
  longDescription: string
  state: string
  actorType: string[]
}

type ScopeCollectionDetails = ScopeDetails & {
  scopes: string[]
}

type GetScopesAndPermissionsResponse = {
  scopes: Record<string, ScopeDetails>
  scopeCollections: Record<string, ScopeCollectionDetails>
}

type GetRolesResponse = {
  roles: Array<{
    name: string
    shortDescription: string
    longDescription: string
    state: string
    allowCrossAccountAccess: boolean
    actorType: string
    grantors: string[]
    scopes: string[]
  }>
}

export const authApi = createApi({
  baseQuery,
  reducerPath: 'authApi',
  tagTypes: ['Roles', 'Permissions'],
  endpoints: (build) => ({
    getScopesAndPermissions: build.query<GetScopesAndPermissionsResponse, void>({
      query: () => ({
        url: 'auth/scopes/actor-type',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
    getRoles: build.query<GetRolesResponse, { actorType?: string }>({
      query: ({ actorType = 'internal' }) => ({
        url: `auth/roles/actor-type/${actorType}`,
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
  }),
})

export const { useGetScopesAndPermissionsQuery, useGetRolesQuery } = authApi
