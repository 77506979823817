import * as Yup from 'yup'

const storeInformationSchema = Yup.object()
  .shape({
    storeId: Yup.string().required('Store ID is required').default(''),
  })
  .defined()

const contractInformationSchema = Yup.object()
  .shape({
    transactionId: Yup.string().required('Transaction ID is required').default(''),
    currencyCode: Yup.string().required('Currency Code is required').default(''),
    transactionDate: Yup.string().required('Transaction Date is required').default(''),
    planId: Yup.string().required('Plan ID is required').default(''),
    planPurchasePrice: Yup.number().required('Plan purchase price is required').nullable().default(null),
    quantity: Yup.number().required().nullable().default(null),
  })
  .defined()

const productInformationSchema = Yup.object()
  .shape({
    referenceId: Yup.string().required('Product ID is required').default(''),
    productName: Yup.string().required('Product name is required').default(''),
    productPurchasePrice: Yup.number().required('Purchase price is required').nullable().default(null),
    productPurchaseDate: Yup.string().required('Product purchase date is required').default(''),
  })
  .required()

const customerInformationSchema = Yup.object()
  .shape({
    fullName: Yup.string().required('Full name is required').default(''),
    phoneNumber: Yup.string().notRequired(),
    customerEmail: Yup.string().required('Email is required').default(''),
    billingAddress: Yup.string().notRequired(),
    billingAddressTwo: Yup.string().notRequired(),
    billingCity: Yup.string().notRequired(),
    billingProvinceCode: Yup.string().notRequired(),
    billingPostalCode: Yup.string().notRequired(),
    billingCountryCode: Yup.string().notRequired(),
    shippingAddress: Yup.string().notRequired(),
    shippingAddressTwo: Yup.string().notRequired(),
    shippingCity: Yup.string().notRequired(),
    shippingProvinceCode: Yup.string().notRequired(),
    shippingPostalCode: Yup.string().notRequired(),
    shippingCountryCode: Yup.string().notRequired(),
  })
  .defined()

type V1Values = Yup.InferType<typeof customerInformationSchema> &
  Yup.InferType<typeof storeInformationSchema> &
  Yup.InferType<typeof contractInformationSchema> &
  Yup.InferType<typeof productInformationSchema>

const V1Schema = customerInformationSchema
  .concat(storeInformationSchema)
  .concat(contractInformationSchema)
  .concat(productInformationSchema)

export type { V1Values }
export { V1Schema }
