import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import type {
  FulfillmentMethod,
  VirtualCardFulfillmentMetaData,
} from '@helloextend/extend-api-client/src/models/service-order'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import type { ButtonSize } from '@extend/zen'
import { Button, ArrowDropDown } from '@extend/zen'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import { ListItemButton, Menu } from '@helloextend/merchants-ui'
import type { Claim, ServiceOrder } from '@helloextend/extend-api-client'
import {
  claimsApi,
  serviceOrdersApi,
  useGetWalletQuery,
  useResendPopaEmailMutation,
  useResendRewardEmailMutation,
} from '@helloextend/extend-api-rtk-query'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { LDFlag } from '../../../../../../constants/ld-flags'
import { useServiceOrderExpenses } from '../tab-section/hooks/use-service-orders-expenses'
import type { RootState } from '../../../../../../reducers'
import * as selectors from '../../../../../../reducers/selectors'
import { isDepotRepair } from '../../../../../../utils/service-order-utils'
import { useUpdateClaim } from '../../../../../../hooks'

interface ServiceOrderActionsMenuProps {
  serviceOrder?: ServiceOrder
  claim?: Claim
  cyPrefix?: string
  buttonText?: string
  buttonSize?: ButtonSize
}

interface ActionConfig {
  label: string
  action: string
}

const serviceOrderClosingStatusCriteria = [
  'created',
  'assigned',
  'accepted',
  'repair_shipped',
  'checked_in',
]

const closeDisabledStatuses = ['paid', 'closed', 'payment_approved']

const getVirtualCardActions = (isVirtualCardCancelEnabled?: boolean): ActionConfig[] => {
  const config: ActionConfig[] = []
  config.push({
    label: 'Resend Fulfillment email',
    action: 'resendVirtualCardEmail',
  })

  if (isVirtualCardCancelEnabled) {
    config.push({
      label: 'Cancel Virtual Card',
      action: 'cancelVirtualCardModal',
    })
  }
  return config
}

const getFlexibleServicingActions = (status?: ServiceOrder['status']): ActionConfig[] => {
  const config: ActionConfig[] = [
    {
      label: 'Create service order',
      action: 'createServiceOrderModal',
    },
  ]
  const allowedStatuses: Array<ServiceOrder['status']> = [
    'created',
    'replacement_approved',
    'fulfilled',
  ]
  if (status && allowedStatuses.includes(status)) {
    config.push({
      label: 'Close service order',
      action: 'closeServiceOrderModal',
    })
  }
  return config
}

const getResendRewardEmailActions = (status?: ServiceOrder['status']): ActionConfig[] => {
  const config: ActionConfig[] = []
  const resendRewardEmailStatuses: Array<ServiceOrder['status']> = [
    'payment_approved',
    'payment_requested',
    'paid',
  ]
  if (status && resendRewardEmailStatuses.includes(status)) {
    config.push({
      label: 'Resend Fulfillment email',
      action: 'resendRewardEmail',
    })
  }
  return config
}

const getReplacementServiceOrderActions = (
  status?: ServiceOrder['status'],
  fulfillmentMethod?: FulfillmentMethod,
  isVirtualCardCancelEnabled?: boolean,
  isResendRewardEmailEnabled?: boolean,
): ActionConfig[] | null => {
  const config: ActionConfig[] = []
  if (status && closeDisabledStatuses.includes(status) && fulfillmentMethod === 'virtual_card') {
    config.push(...getVirtualCardActions(isVirtualCardCancelEnabled))
  }
  if (isResendRewardEmailEnabled && fulfillmentMethod === 'direct_payment') {
    config.push(...getResendRewardEmailActions(status))
  }

  config.push(...getFlexibleServicingActions(status))

  switch (status) {
    case 'accepted':
      config.push(
        ...[
          {
            label: 'Approve SO for replacement',
            action: 'approveReplacementModal',
          },
          {
            label: 'Close service order',
            action: 'closeServiceOrderModal',
          },
        ],
      )
      break
    default:
  }
  return config.length > 0 ? config : null
}

const getClaimActions = (claim?: Claim): ActionConfig[] => {
  if (claim?.status !== 'fulfilled') return []
  return [
    {
      label: 'Reopen claim',
      action: 'reopenClaim',
    },
  ]
}

const getRepairServiceOrderActions = (
  serviceOrder: ServiceOrder,
  hasExpenses?: boolean,
): ActionConfig[] | null => {
  if (
    (!closeDisabledStatuses.includes(serviceOrder?.status || '') ||
      serviceOrderClosingStatusCriteria.includes(serviceOrder?.status || '')) &&
    !hasExpenses
  ) {
    const actions = [
      {
        label: 'Close service order',
        action: 'closeServiceOrderModal',
      },
      {
        label: 'Create service order',
        action: 'createServiceOrderModal',
      },
    ]

    if (isDepotRepair(serviceOrder) && serviceOrder?.status === 'accepted') {
      actions.push({
        label: 'Mark as shipped',
        action: 'markAsShippedModal',
      })
    }
    return actions
  }

  const actions: ActionConfig[] = []
  if (isDepotRepair(serviceOrder) && serviceOrder?.status === 'accepted') {
    actions.push({
      label: 'Mark as shipped',
      action: 'markAsShippedModal',
    })
  }

  if (
    serviceOrder.closedMetaData?.resolution !== 'no_service' &&
    serviceOrder.closedMetaData?.resolution !== 'defective_product_not_shipped'
  )
    actions.push({
      label: 'Create service order',
      action: 'createServiceOrderModal',
    })

  return actions.length > 0 ? actions : null
}

const ServiceOrderActionsMenu: FC<ServiceOrderActionsMenuProps> = ({
  claim,
  serviceOrder,
  cyPrefix,
  buttonText,
  buttonSize,
}) => {
  const { [LDFlag.ResendRewardEmail]: FF_RESEND_REWARD_EMAIL } = useFlags()
  const dispatch = useDispatch()
  const [isMenuOpen, { toggle, off }] = useToggle()
  const { expenses } = useServiceOrderExpenses(serviceOrder?.id)
  const hasExpenses = expenses && expenses.length > 0
  const activeView = useSelector((state: RootState) => selectors.getActiveClaimDetailsView(state))
  const [resendVirtualCardEmail] = useResendPopaEmailMutation()
  const [resendRewardEmail] = useResendRewardEmailMutation()
  const { updateClaim, isSuccess: isUpdateClaimSuccess } = useUpdateClaim()

  const { data: wallet } = useGetWalletQuery(
    serviceOrder
      ? (serviceOrder.fulfillmentMetaData as VirtualCardFulfillmentMetaData)?.walletId ?? ''
      : '',
    {
      skip:
        !serviceOrder ||
        !serviceOrder.fulfillmentMetaData ||
        !(serviceOrder.fulfillmentMetaData as VirtualCardFulfillmentMetaData).walletId,
    },
  )

  useEffect(() => {
    if (activeView === 'resendVirtualCardEmail' && serviceOrder) {
      dispatch(setClaimDetailsActiveView(''))
      resendVirtualCardEmail({
        serviceOrderId: serviceOrder.id,
        body: {
          iterableEventName: 'virtual-card-service-order-fulfilled',
          customerEmail: serviceOrder.customerEmail,
        },
      })
      dispatch(setClaimDetailsToast('Virtual Card email resent!'))
      dispatch(
        serviceOrdersApi.util.invalidateTags([{ type: 'ServiceOrder', id: serviceOrder?.claimId }]),
      )
    }

    if (activeView === 'resendRewardEmail' && serviceOrder) {
      dispatch(setClaimDetailsActiveView(''))
      resendRewardEmail({
        serviceOrderId: serviceOrder.id,
      })
      dispatch(setClaimDetailsToast('Direct payment email resent!'))
      dispatch(
        serviceOrdersApi.util.invalidateTags([{ type: 'ServiceOrder', id: serviceOrder?.claimId }]),
      )
    }

    const handleUpdateClaim = async () => {
      if (activeView === 'reopenClaim' && claim) {
        dispatch(setClaimDetailsActiveView(''))
        await updateClaim({
          claimId: claim.id,
          body: { status: 'approved' },
        })
        dispatch(
          serviceOrdersApi.util.invalidateTags([{ type: 'ServiceOrder', id: serviceOrder?.claimId }]),
        )
      }
    }
    handleUpdateClaim()
  }, [
    activeView,
    dispatch,
    resendVirtualCardEmail,
    serviceOrder,
    claim,
    updateClaim,
    resendRewardEmail,
  ])

    useEffect(() => {
    if (isUpdateClaimSuccess) {
      dispatch(setClaimDetailsToast('Claim reopened!'))
    }
  }, [isUpdateClaimSuccess, dispatch])

  const actions: ActionConfig[] | null = useMemo(() => {
    const claimActions = getClaimActions(claim)
    let serviceOrderActions: ActionConfig[] | null
    switch (serviceOrder?.serviceType) {
      case 'replace':
        serviceOrderActions = getReplacementServiceOrderActions(
          serviceOrder?.status,
          serviceOrder?.fulfillmentMetaData?.method,
          wallet?.status === 'active',
          FF_RESEND_REWARD_EMAIL,
        )
        break
      case 'repair_depot':
      case 'repair':
      case 'repair_onsite':
        serviceOrderActions = getRepairServiceOrderActions(serviceOrder, hasExpenses)
        break
      default:
        serviceOrderActions =
          claim?.status === 'approved'
            ? [
                {
                  label: 'Create service order',
                  action: 'createServiceOrderModal',
                },
              ]
            : null
    }

    if (!serviceOrderActions) {
      return claimActions.length > 0 ? claimActions : null
    }

    return [...claimActions, ...serviceOrderActions]
  }, [serviceOrder, claim, hasExpenses, wallet, FF_RESEND_REWARD_EMAIL])

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    off()
  })

  const handleClick = (action: string): void => {
    dispatch(setClaimDetailsActiveView(action))
    setTimeout(() => {
      dispatch(claimsApi.util.invalidateTags([{ type: 'claims', id: serviceOrder?.claimId }]))
    }, 8000)
    off()
  }

  if (!actions) return null

  return (
    <ActionsWrapper data-cy={cyPrefix ? `${cyPrefix}-so-actions-wrapper` : 'so-actions-wrapper'}>
      <div ref={ref}>
        <Button
          emphasis="medium"
          isToggled={isMenuOpen}
          data-cy="so-actions-button"
          text={buttonText || 'More Actions'}
          size={buttonSize}
          onClick={toggle}
          icon={ArrowDropDown}
          iconPosition="right"
        />
        <MenuWrapper>
          <Menu isOpen={isMenuOpen} position="right" width={320}>
            {actions.map((action) => (
              <ItemWrapper key={action.label}>
                <MenuButton
                  data-cy={`${action.action}-btn`}
                  onClick={() => handleClick(action.action)}
                >
                  {action.label}
                </MenuButton>
              </ItemWrapper>
            ))}
          </Menu>
        </MenuWrapper>
      </div>
    </ActionsWrapper>
  )
}

const ActionsWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
}))

const MenuWrapper = styled.div({
  width: '100%',
  position: 'absolute',
  top: 38,
})
const ItemWrapper = styled.div({
  position: 'relative',
  '&:first-child > .menu-button': {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  '&:last-child > .menu-button': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
})

const MenuButton = styled(ListItemButton)({
  backgroundColor: 'transparent',
  borderRadius: 0,
  display: 'flex',
  justifyContent: 'space-between',
})

export { ServiceOrderActionsMenu }
