import type { Operation } from 'fast-json-patch'
import { applyPatch as fastJsonApplyPatch } from 'fast-json-patch'

export const applyPatch = <T>(entity: T, patchUpdates: Operation[]): T => {
  const patch: T = fastJsonApplyPatch<T>(
    entity,
    patchUpdates,
    // supplying this arg could allow us to validate each individual update but for now we'll use ajv
    false,
    // setting this to false cause the document to be deep clone before modification
    false,
  ).newDocument

  return patch
}
