import type { BadgeProps, BadgeColor } from '@extend/zen'
import type { EmbeddedPromotionStatus } from '@helloextend/extend-api-client'
import type { BadgeDescriptionItem } from 'src/components/badge-descriptions'

interface PromotionBadgeProps extends Pick<BadgeProps, 'color' | 'text'> {}

export const PROMOTION_STATUS: Record<EmbeddedPromotionStatus, string> = {
  active: 'Active',
  scheduled: 'Scheduled',
  expired: 'Expired',
  inactive: 'Inactive',
}

const PROMOTION_COLOR: Record<EmbeddedPromotionStatus, BadgeColor> = {
  active: 'green',
  scheduled: 'blue',
  expired: 'neutral',
  inactive: 'red',
}

export const getPromotionBadgeProps = (status: EmbeddedPromotionStatus): PromotionBadgeProps => {
  return {
    text: PROMOTION_STATUS[status],
    color: PROMOTION_COLOR[status],
  }
}

export const ACTIVE_STATUS_OPTIONS: BadgeDescriptionItem = {
  text: 'Active',
  description: 'Currently within the specified promotional period.',
  color: PROMOTION_COLOR.active,
}

export const SCHEDULED_STATUS_OPTIONS: BadgeDescriptionItem = {
  text: 'Scheduled',
  description: 'The specified promotional period is scheduled for the future.',
  color: PROMOTION_COLOR.scheduled,
}

export const EXPIRED_STATUS_OPTIONS: BadgeDescriptionItem = {
  text: 'Expired',
  description: 'The specified promotional period is scheduled in the past.',
  color: PROMOTION_COLOR.expired,
}

export const INACTIVE_STATUS_OPTIONS: BadgeDescriptionItem = {
  text: 'Inactive',
  description:
    'Currently within promotional period and promotion is inactive or promotion isn’t fully set up yet.',
  color: PROMOTION_COLOR.inactive,
}

export const PROMOTION_STATUS_MAP: BadgeDescriptionItem[] = [
  ACTIVE_STATUS_OPTIONS,
  SCHEDULED_STATUS_OPTIONS,
  EXPIRED_STATUS_OPTIONS,
  INACTIVE_STATUS_OPTIONS,
]
