import { startCase } from 'lodash/fp'
import { currency, date } from '@extend/client-helpers'
import type {
  Money,
  ShippingTracking,
  Order,
  LineItem,
  LineItemShipments,
  LineItemContract,
  LineItemNonWarrantable,
  LineItemLead,
  InsuranceClaim,
} from '@helloextend/extend-api-client'
import type { Claim, ClaimsManagementServiceErrorCodes } from '@extend-services/claims-management'

type OrderLineItemProduct = LineItemContract | LineItemNonWarrantable | LineItemLead

const formatStartCase = (value?: string): string => startCase(value ?? '')
const formatDate = (value?: number): string => {
  return (typeof value === 'number' && date.format(value, 'MMM DD YYYY')) || ''
}
const formatClaimType = (value?: string): string => {
  return value === 'shipping_protection' ? 'Shipping Protection' : 'Extended Warranty'
}
const formatCurrency = (value?: Money): string => {
  if (!value) return '—'
  return currency.format(value.amount, value.currencyCode)
}
const formatDateTime = (value?: number, omitTime?: boolean): string => {
  const format = omitTime ? 'MM/DD/YYYY' : 'MM/DD/YYYY [at] hh:mm:ss A'
  return typeof value === 'number' ? date.format(value, format) : ''
}
const isShippingProtectionType = (value?: string): boolean => {
  return value === 'shipping_protection'
}
const formatShippingStatus = (tracking: ShippingTracking): string => {
  const [event] = tracking.events
  const status = event.status !== 'unknown' ? event.status : tracking.status
  const occurredAt = formatDate(event.occurredAt)
  return `${status}: ${occurredAt}`
}

const getShipmentLineItem = (order: Order): LineItemShipments | null => {
  const shipmentsLineItems = order.lineItems.filter((lineItem) => lineItem.type === 'shipments')
  if (shipmentsLineItems.length) {
    const [shipmentLineItem] = shipmentsLineItems as LineItemShipments[]
    return shipmentLineItem
  }
  return null
}

const getShippingProtectionProducts = (
  lineItems: LineItem[],
  lineItemIds?: string[],
): OrderLineItemProduct[] => {
  return lineItems.filter(
    (lineItem) => lineItemIds?.includes(lineItem.id) && lineItem.type !== 'shipments',
  ) as OrderLineItemProduct[]
}

const getTotalCost = (claim: InsuranceClaim, order?: Order): number | null => {
  if (claim.products) {
    let total = claim.products.reduce((cost, product) => cost + product.limitOfLiability.amount, 0)
    total += claim.tax?.amount ?? 0
    total += claim.shippingCost?.amount ?? 0
    return total
  }
  if (!order) return null
  const shippingCostTaxTotal = (order.shippingCostTotal ?? 0) + (order.taxCostTotal ?? 0)
  const shipmentLineItem = getShipmentLineItem(order)
  const shippingProtectionProducts = getShippingProtectionProducts(
    order.lineItems,
    shipmentLineItem?.lineItemIds,
  )

  const shippingProtectionProductsCost = shippingProtectionProducts.reduce((cost, item) => {
    let currentCost = item.product?.purchasePrice ?? 0
    if ((item as LineItemContract).plan) {
      const contractProduct = item as LineItemContract
      currentCost += contractProduct.plan.purchasePrice ?? 0
    }

    return cost + currentCost
  }, 0)

  return shippingProtectionProductsCost + shippingCostTaxTotal
}

const getIsClaimShippingAddressComplete = (claim: Claim): boolean => {
  const { customer } = claim
  if (!customer.shippingAddress) return false
  return !!(
    customer.shippingAddress.address1 &&
    customer.shippingAddress.city &&
    customer.shippingAddress.countryCode &&
    customer.shippingAddress.postalCode &&
    customer.shippingAddress.provinceCode
  )
}

const ClaimsManagementServiceErrorCodeMap: Partial<Record<ClaimsManagementServiceErrorCodes, string>>= {
  claim_missing_address: 'Claim is missing a shipping address',
  claim_missing_email: 'Claim is missing an email address',
  claim_missing_phone: 'Claim is missing a phone number',
  invalid_phone_format: 'Invalid phone number format',
}

const mapClaimsManagementServiceError = (errorCode: string): string => {
  return ClaimsManagementServiceErrorCodeMap[errorCode as ClaimsManagementServiceErrorCodes] ?? errorCode
}


export {
  formatDate,
  formatStartCase,
  formatCurrency,
  formatDateTime,
  formatShippingStatus,
  getShippingProtectionProducts,
  getTotalCost,
  isShippingProtectionType,
  formatClaimType,
  getIsClaimShippingAddressComplete,
  ClaimsManagementServiceErrorCodeMap,
  mapClaimsManagementServiceError,
}
