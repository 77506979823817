import type { CellContext, ColumnDefs } from '@extend/zen'
import type { Account, AccountEnterprise } from '@helloextend/extend-api-rtk-query'
import type { TableAccounts } from '../../../../../types/accounts'

const toTableAccount = (account: Account): TableAccounts => {
  return {
    name: account.name ?? '',
    accountId: account.id,
    isLegacyAccount: Object.prototype.hasOwnProperty.call(account, 'isLegacyAccount') ?? true,
    status: (account as AccountEnterprise).status ?? undefined,
  }
}

const toTableAccounts = (accounts: Account[]): TableAccounts[] => accounts.map(toTableAccount)

const accountsColumns: ColumnDefs<TableAccounts> = [
  {
    label: 'Name',
    id: 'name',
    search: 'explicit',
    renderCell: (cellData: CellContext<TableAccounts, string>) => cellData.getValue(),
  },
  {
    label: 'Account Id',
    id: 'accountId',
    search: 'explicit',
    renderCell: (cellData: CellContext<TableAccounts, string>) => cellData.getValue(),
  },
]

const searchOptions = [
  { label: 'Name', value: 'name' },
  { label: 'Account Id', value: 'id' },
]

export { toTableAccount, toTableAccounts, accountsColumns, searchOptions }
