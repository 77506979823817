import type { FC, SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { ButtonGroup, Button, InlineAlert, InlineAlertColor, Info, Select } from '@extend/zen'
import type { Claim, ServiceOrder, ServiceType } from '@helloextend/extend-api-client'
import { ClaimType } from '@helloextend/extend-api-client'
import {
  useGetMerchantServicingSettingsQuery,
  useServiceOrderCreateMutation,
} from '@helloextend/extend-api-rtk-query'
import type { V2 } from '@extend-services/service-orders'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { LDFlag } from '../../../../../../constants/ld-flags'

interface CreateServiceOrderDropdownProps {
  serviceOrders: ServiceOrder[]
  claim: Claim
}

interface ServiceTypeOption {
  label: string
  value: ServiceType
}

interface FulfillmentTypeOption {
  label: string
  value: V2.Models.ReplacementFulfillmentMethod | V2.Models.RepairFulfillmentMethod
}
const terminalStatuses = ['closed', 'payment_approved', 'paid']

const CreateServiceOrderDropdown: FC<CreateServiceOrderDropdownProps> = ({
  serviceOrders,
  claim,
}) => {
  const dispatch = useDispatch()
  const { [LDFlag.AutomatedReplacementFulfillment]: FF_AUTOMATED_REPLACEMENT } = useFlags()
  const [serviceType, setServiceType] = useState<ServiceType | null>(null)
  const [fulfillmentType, setFulfillmentType] = useState<
    V2.Models.ReplacementFulfillmentMethod | V2.Models.RepairFulfillmentMethod | undefined
  >()
  const [isActiveSOError, setIsActiveSOError] = useState(false)
  const [createServiceOrder] = useServiceOrderCreateMutation()
  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId: claim.sellerId,
  })

  const handleSubmit = (): void => {
    if (!serviceType) return
    if (serviceOrders.some((serviceOrder) => !terminalStatuses.includes(serviceOrder.status))) {
      setIsActiveSOError(true)
      return
    }

    createServiceOrder({ claimId: claim.id, serviceType, fulfillmentType })
    dispatch(setClaimDetailsToast('SO created!'))
    dispatch(setClaimDetailsActiveView(''))
  }

  useEffect(() => {
    if (
      merchantServicingSettings?.replacementFulfillmentMethod &&
      !fulfillmentType &&
      serviceType === 'replace'
    ) {
      setFulfillmentType(merchantServicingSettings?.replacementFulfillmentMethod)
    }
  }, [
    merchantServicingSettings?.replacementFulfillmentMethod,
    fulfillmentType,
    serviceType,
    setFulfillmentType,
  ])

  const serviceTypeOptions: ServiceTypeOption[] = [
    {
      label: 'Replacement',
      value: 'replace',
    },
    {
      label: 'Depot Repair',
      value: 'repair_depot',
    },
    {
      label: 'Onsite Repair',
      value: 'repair_onsite',
    },
    {
      label: 'Repair',
      value: 'repair',
    },
  ]

  const replacementFulfillmentOptions: FulfillmentTypeOption[] = [
    {
      label: 'Manual via Portal',
      value: 'manual',
    },
    {
      label: 'Direct payment',
      value: 'direct_payment',
    },
    {
      label: 'Virtual card',
      value: 'virtual_card',
    },
  ]

  if (
    merchantServicingSettings?.supportedCustomerFulfillmentMethods?.includes(
      'automated_replacement',
    ) &&
    claim.type === ClaimType.SHIPPING_PROTECTION &&
    FF_AUTOMATED_REPLACEMENT
  ) {
    replacementFulfillmentOptions.push({
      label: 'Automated Replacement',
      value: 'automated_replacement',
    })
  }

  const fulfillmentTypeOptionMap: Record<'replace' | 'repair', FulfillmentTypeOption[]> = {
    replace: replacementFulfillmentOptions,
    repair: [
      {
        label: 'Self Repair',
        value: 'self_repair',
      },
      {
        label: 'Cleaning Kit',
        value: 'cleaning_kit',
      },
      {
        label: 'Carry In',
        value: 'carry_in',
      },
      {
        label: 'Depot Repair',
        value: 'repair_depot',
      },
      {
        label: 'Onsite Repair',
        value: 'repair_onsite',
      },
    ],
  }

  return (
    <ContentWrapper>
      <DataRow>
        <ItemWrapper>
          <LabelText>Type</LabelText>
          <Select
            id="service-type-select"
            onChange={(e: SyntheticEvent<Element>): void => {
              const { value } = e.currentTarget as HTMLInputElement
              setServiceType(value as ServiceType)
            }}
            value={serviceType ?? ''}
            placeholder="—SELECT—"
            data-cy="service-type-select"
          >
            {serviceTypeOptions.map(({ label, value }) => (
              <option value={value} key={value} data-cy={`select-item-${value.replace('_', '-')}`}>
                {label}
              </option>
            ))}
          </Select>
          {isActiveSOError && (
            <AlertWrapper>
              <InlineAlert color={InlineAlertColor.red} icon={Info} data-cy="active-so-alert">
                The active service order must be closed before creating a new one.
              </InlineAlert>
            </AlertWrapper>
          )}
        </ItemWrapper>
      </DataRow>
      {(serviceType === 'replace' || serviceType === 'repair') && (
        <DataRow>
          <ItemWrapper>
            <LabelText>Fulfillment type</LabelText>
            <Select
              id="fulfillment-type-select"
              onChange={(e: SyntheticEvent<Element>): void => {
                const { value } = e.currentTarget as HTMLInputElement
                setFulfillmentType(
                  value as
                    | V2.Models.ReplacementFulfillmentMethod
                    | V2.Models.RepairFulfillmentMethod,
                )
              }}
              value={fulfillmentType ?? ''}
              placeholder="—SELECT—"
              data-cy="fulfillment-type-select"
            >
              {fulfillmentTypeOptionMap[serviceType].map(({ label, value }) => (
                <option
                  value={value}
                  key={value}
                  data-cy={`select-item-${value.replace('_', '-')}`}
                >
                  {label}
                </option>
              ))}
            </Select>
          </ItemWrapper>
        </DataRow>
      )}
      <ButtonRow>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
          />
          <Button
            type="button"
            emphasis="high"
            isDisabled={!serviceType || isActiveSOError}
            text="Submit"
            data-cy="submit-button"
            onClick={handleSubmit}
          />
        </ButtonGroup>
      </ButtonRow>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

const DataRow = styled.div({
  display: 'flex',
  width: '100%',
})

const ButtonRow = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
})

const ItemWrapper = styled.div({
  width: '100%',
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
})

const LabelText = styled.div({
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
  lineHeight: '24px',
})

const AlertWrapper = styled.div({
  marginTop: 25,
})

export { CreateServiceOrderDropdown }
