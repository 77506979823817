import type { AuditItem, Contract } from '@helloextend/extend-api-client'
import { date } from '@extend/client-helpers'
import type { Diff } from 'deep-diff'
import type { TableContractsAuditItem } from '../../../types/contracts'

const PRODUCT_EVENT = [
  'brand',
  'condition',
  'imageUrl',
  'listPrice',
  'manufacturerWarrantyLengthLabor',
  'manufacturerWarrantyLengthParts',
  'purchasePrice',
  'referenceId',
  'reimbursementAmount',
  'serialNumber',
  'name',
  'title',
  'transactionDate',
  'transactionId',
  'purchaseDate',
  'fulfillmentDate',
  'product',
]

const CUSTOMER_EVENT = [
  'billingAddress',
  'email',
  'name',
  'phone',
  'shippingAddress',
  'type',
  'address1',
  'address2',
  'city',
  'countryCode',
  'postalCode',
  'provinceCode',
  'customer',
]

const TRANSACTION_EVENT = ['transactionId', 'transactionTotal', 'currency', 'poNumber']

const STORE_EVENT = [
  'sellerName',
  'sellerId',
  'adminLocationNumber',
  'adminDealerNumber',
  'adminManufacturer',
  'email',
  'contactName',
  'number',
  'extension',
  'store',
]

const PURCHASE_EVENT = ['purchasePrice', 'currency', 'purchaseDate']

const EVENT = {
  'Product Information': PRODUCT_EVENT,
  'Customer Information': CUSTOMER_EVENT,
  'Transaction Information': TRANSACTION_EVENT,
  'Store Information': STORE_EVENT,
  'Purchase Information': PURCHASE_EVENT,
}

const matchEvent = (path: string): string | null => {
  const entries = Object.entries(EVENT)
  for (const [key, value] of entries) {
    const isMatch = value.some((field) => field === path)
    if (isMatch) {
      return key
    }
  }
  return null
}

const getChangeEvent = (changes: Array<Diff<string | number>>): string =>
  changes.reduce((acc, change) => {
    const matched = matchEvent(change.path?.[0])
    const name = matched ?? 'Contract Information'

    if (acc && name !== acc) {
      return 'Multiple Edits'
    }

    return name
  }, '')

export const toTableAuditItem = (auditItem: AuditItem<Contract>): TableContractsAuditItem => ({
  id: `${auditItem.eventTime}`,
  timestamp: date.format(auditItem.eventTime, 'MMMM DD, YYYY HH:MM:ss'),
  event: getChangeEvent(auditItem.changes),
  changes: auditItem.changes,
  user: auditItem.actor,
  comments: auditItem.reason,
})

export const toTableAuditItems = (
  auditItems: Array<AuditItem<Contract>>,
): TableContractsAuditItem[] => auditItems.map(toTableAuditItem)
