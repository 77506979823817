import { Breadcrumbs } from '@extend/zen'
import type { FC } from 'react'
import React, { memo } from 'react'

const OrderBreadcrumbs: FC<{ id: string }> = memo(({ id }) => {
  return (
    <Breadcrumbs
      crumbs={[
        {
          text: 'Orders',
          to: '/admin/orders',
        },
        {
          text: `Order ID: ${id}`,
        },
      ]}
      data-cy="order-details-breadcrumbs"
      max-width="50ch"
    />
  )
})

export { OrderBreadcrumbs }
