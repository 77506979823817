import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Collapsible } from './collapsible'

interface CollapsibleSectionProps {
  title?: string
  startExpanded?: boolean
  'data-cy'?: string
}

const CollapsibleSection: FC<CollapsibleSectionProps> = ({
  title,
  children,
  startExpanded = false,
  'data-cy': dataCy,
}) => {
  return (
    <Collapsible
      startExpanded={startExpanded}
      headerContent={<Title>{title}</Title>}
      data-cy={dataCy}
    >
      {children}
    </Collapsible>
  )
}

const Title = styled.h1({
  lineHeight: '28px',
  fontWeight: 'bold',
  fontSize: '20px',
  margin: 0,
})

export type { CollapsibleSectionProps }
export { CollapsibleSection }
