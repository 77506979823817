import type { FC } from 'react'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import type { EditorView } from '@codemirror/view'
import debounce from 'lodash/debounce'
import styled from '@emotion/styled'
import {
  Stack,
  Switch,
  Subheading,
  Information,
  DataProperty,
  Grid,
  Label,
  AccordionSection,
} from '@extend/zen'
import type { Environment } from '@helloextend/extend-sdk-client'
import { Extend as ExtendSDK } from '@helloextend/extend-sdk-client'
import { EXTEND_ENV } from '@helloextend/client-constants'
import { useStandardToast, ToastDuration, ToastColor } from '@helloextend/merchants-ui'
import {
  useUpdateThemeMutation,
  usePublishThemeMutation,
  useGetStoreQuery,
} from '@helloextend/extend-api-rtk-query'
import { useExtendAuth } from '@extend/package-okta-login'
import type { SDKConfig, OfferVersion } from '@extend/extend-sdk-react'
import { ExtendProvider, generateDefaultOfferConfig } from '@extend/extend-sdk-react'
import { useGetPublishedTheme } from '../../../../../../hooks/use-get-published-theme'
import { CssOverrideEditor } from './css-override-editor'
import { PreviewContainer } from './preview-container'
import { LeavePageGuard } from '../../../../../../components/leave-page-guard'
import styles from './css-override.module.css'

export type CssOverrideProps = {
  storeId: string
}

const CssOverride: FC<CssOverrideProps> = ({ storeId }) => {
  const history = useHistory()
  const { theme, isLoading: themeIsLoading } = useGetPublishedTheme(storeId)
  const { data: store, isLoading: storeIsLoading } = useGetStoreQuery({
    storeId,
    version: 'latest',
  })
  const [updateTheme, { isLoading: isUpdatingTheme }] = useUpdateThemeMutation()
  const [publishTheme, { isLoading: isPublishingTheme }] = usePublishThemeMutation()
  const { toast } = useStandardToast()

  const [cssText, setCssText] = useState('')
  const [prevCssText, setPrevCssText] = useState('')
  const [cssEnabled, setCssEnabled] = useState(false)
  const { user } = useExtendAuth()
  const [isTyping, setIsTyping] = useState(false)

  const allowEditing = ['SolutionEngineer'].includes(user?.role ?? '')

  const handleLeavePage = useCallback(
    (path: string): void => {
      history.push(path)
    },
    [history],
  )

  const editorDocumentChanged = (documentValue: string, editorView: EditorView) => {
    setCssText(documentValue)
    setIsTyping(true)
    debounceFn(editorView)
  }

  const editorSaved = async (documentValue: string): Promise<void> => {
    try {
      await updateTheme({
        storeId,
        themeId: theme?.themeId ?? '',
        name: theme?.name ?? '',
        cssConfig: {
          // as of right now any update to the css text will set the enabled flag to false
          // to force SEs/Merchants to review the changes before enabling
          enabled: false,
          css: documentValue,
        },
      })
      setCssEnabled(false)
      setPrevCssText(documentValue)
      await publishTheme({ storeId, themeId: theme?.themeId ?? '' })
      toast({
        message: 'CSS override saved to published theme. Ready for merchant review.',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.long,
      })
    } catch (error) {
      toast({
        message: 'Failed to save Custom Override CSS.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.long,
      })
    }
  }

  const debounceFn = useMemo(
    () =>
      debounce((view: EditorView) => {
        setIsTyping(false)
        // this is a bit of a hack but solves the issue of the editor losing focus
        // when an iframe is rendered (e.g. while typing and trying new changes)
        // possible that this can happen too fast but testing has been solid for me
        setTimeout(() => {
          view.focus()
        }, 100)
      }, 1000),
    [],
  )

  const getButtonRadiusText = useMemo(() => {
    if (!theme?.contents.global.buttonRadius) return ''
    return (
      theme?.contents.global.buttonRadius.size.toString() +
      theme?.contents.global.buttonRadius.measurement
    )
  }, [theme])

  const handleCssEnableToggled = async (): Promise<void> => {
    try {
      const cssEnabledSwitch = !cssEnabled
      await updateTheme({
        storeId,
        themeId: theme?.themeId ?? '',
        name: theme?.name ?? '',
        cssConfig: {
          enabled: cssEnabledSwitch,
          css: prevCssText,
        },
      })
      await publishTheme({ storeId, themeId: theme?.themeId ?? '' })
      setCssEnabled(cssEnabledSwitch)
      if (cssEnabledSwitch) {
        toast({
          message: 'Custom CSS override enabled.',
          toastColor: ToastColor.green,
          toastDuration: ToastDuration.long,
        })
      } else {
        toast({
          message: 'Custom CSS override disabled.',
          toastColor: ToastColor.blue,
          toastDuration: ToastDuration.long,
        })
      }
    } catch (error) {
      toast({
        message: 'Failed to toggle Custom CSS Override.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.long,
      })
    }
  }

  useEffect(() => {
    setPrevCssText(theme?.cssConfig?.css ?? '')
    setCssEnabled(theme?.cssConfig?.enabled ?? false)
  }, [theme])

  const providerConfig = useMemo<SDKConfig>(() => {
    const modalVersion = store?.offerModalVersion ?? 'V1'
    const { environment, referenceId } = generateDefaultOfferConfig({
      storeId,
      version: modalVersion as OfferVersion,
      env: EXTEND_ENV as Environment,
    })

    const offerConfig = {
      sdkThemeOverrides: theme?.contents,
      sdkCssOverrides: {
        enabled: true,
        css: cssText,
      },
      environment,
      referenceId,
      storeId,
    }

    ExtendSDK.config(offerConfig)

    return {
      sdk: ExtendSDK,
      ...offerConfig,
    }
  }, [theme, store, cssText])

  return (
    <Grid columns={2} spacing={4}>
      <LeavePageGuard isNavBlocked={cssText !== prevCssText} handleLeavePage={handleLeavePage} />
      <Stack>
        <Stack isRow align="center">
          <Subheading>Merchant Theme</Subheading>
          <Information buttonSize="xsmall">
            CSS Override is applied only to the published merchant theme.
          </Information>
        </Stack>
        <div className={styles['accordion-section-wrapper']}>
          <AccordionSection
            data-cy="css-theme-accordion"
            heading={`Published theme: ${theme?.name}`}
            headingSize="sub"
          >
            <Grid columns={{ lg: 2, md: 2, sm: 2 }} spacing={2}>
              <div>
                <Label isMuted>Store Logo</Label>
                {theme?.contents.global.storeLogoUrl && (
                  <Image src={theme?.contents.global.storeLogoUrl} />
                )}
                {!theme?.contents.global.storeLogoUrl && <br />}
              </div>
              <div />
              <div>
                <DataProperty
                  data-cy="css-theme-background-color"
                  label="Background Color"
                  value={theme?.contents.global.backgroundColor}
                />
              </div>
              <div />
              <div>
                <DataProperty
                  data-cy="css-theme-font-family"
                  label="Font Family"
                  value={theme?.contents.global.fontFamily}
                />
              </div>
              <div>
                <DataProperty
                  data-cy="css-theme-font-color"
                  label="Primary Font Color"
                  value={theme?.contents.global.color}
                />
              </div>
              <div>
                <DataProperty
                  data-cy="css-theme-button-background-color"
                  label="Button Color"
                  value={theme?.contents.global.buttonBackgroundColor}
                />
              </div>
              <div>
                <DataProperty
                  data-cy="css-theme-button-font-color"
                  label="Button Font Color"
                  value={theme?.contents.global.buttonFontColor}
                />
              </div>
              <div>
                <DataProperty
                  data-cy="css-theme-button-radius"
                  label="Button Radius"
                  value={getButtonRadiusText}
                />
              </div>
            </Grid>
          </AccordionSection>
        </div>
        <br />
        <Stack isRow align="center">
          <Subheading>Custom CSS Override By Extend</Subheading>
          <Information buttonSize="xsmall">
            Overrides merchant theme if conflicts occur.
          </Information>
        </Stack>
        <div>
          <Switch
            id="cssOverrideDeploymentStatus"
            data-cy="css-override-deployment-status"
            label="Deployment Enabled"
            isOn={cssEnabled}
            onChange={handleCssEnableToggled}
            labelPosition="before"
            isProcessing={isUpdatingTheme || isPublishingTheme}
            isDisabled={!allowEditing || !prevCssText}
          />
        </div>
        <br />
        <CssOverrideEditor
          theme={theme}
          allowEditing={allowEditing}
          saveConfirmation={cssEnabled}
          editorDocumentChanged={editorDocumentChanged}
          editorSaved={editorSaved}
        />
      </Stack>
      <Stack>
        <Subheading>Preview</Subheading>
        {!themeIsLoading && !storeIsLoading && (
          <ExtendProvider config={providerConfig}>
            <PreviewContainer isLoading={isTyping || isUpdatingTheme || isPublishingTheme} />
          </ExtendProvider>
        )}
      </Stack>
    </Grid>
  )
}

const Image = styled.img({
  width: 200,
  marginBottom: 16,
})

export { CssOverride }
