import type { Context } from '@datadog/browser-core'
import styled from '@emotion/styled'
import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { customLogger } from '@extend/client-helpers'
import { COLOR } from '@extend/zen'
import React from 'react'

interface AdjudicationErrorBoundaryProps {
  scriptItem: ScriptItem
}

interface AdjudicationErrorBoundaryState {
  hasError: boolean
  scriptItem?: ScriptItem
}

export class AdjudicationErrorBoundary extends React.Component<
  AdjudicationErrorBoundaryProps,
  AdjudicationErrorBoundaryState
> {
  state: AdjudicationErrorBoundaryState = {
    hasError: false,
    scriptItem: undefined,
  }

  constructor(props: AdjudicationErrorBoundaryProps) {
    super(props)
    this.state.scriptItem = props.scriptItem
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const { scriptItem } = this.state

    customLogger.error('Message block failed to render', {
      error: error.message,
      info: errorInfo.componentStack,
      // The context value from datadog doesn't allow for enums, and other properties that will map to a string,
      // so we're casting it to get around those issues since this object will be compliant with datadog
      scriptItem: scriptItem as unknown as Context,
    })
  }

  render(): React.ReactNode {
    const {
      state: { hasError },
      props: { children },
    } = this

    if (hasError) {
      return <ErrorText data-cy="error-text">Something went wrong.</ErrorText>
    }
    return children
  }

  static getDerivedStateFromError(): Partial<{ scriptItem: ScriptItem; hasError: boolean }> {
    return { hasError: true }
  }
}

const ErrorText = styled.div({
  color: COLOR.RED[400],
  padding: '4px 0',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
})
