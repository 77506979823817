import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Info, InlineAlert, InlineAlertColor } from '@extend/zen'
import type { Claim } from '@helloextend/extend-api-client'
import { ErrorDenial } from './error-denial'
import { ManualReview } from './manual-review'
import type { AdjudicationClaim } from './adjudication-rule'
import { AdjudicationRule } from './adjudication-rule'

// Typeguard to keep TS happy when we're rending this component
export function claimWithRuleset(claim: Claim): AdjudicationClaim {
  if (claim.ruleset !== undefined) {
    return claim as AdjudicationClaim
  }
  throw new Error("Claim object is missing the 'ruleset' property.")
}
type AdjudicationTabProps = {
  claim: Claim
}

const AdjudicationTab: FC<AdjudicationTabProps> = ({ claim }) => {
  const isFraudRisk = Boolean(claim && claim?.validationError === 'fraud_risk')
  const isInManualReview = Boolean(claim && claim.status === 'review' && !isFraudRisk)
  const isClaimDenied = Boolean(
    claim && claim.validationError && claim.status === 'denied' && !isFraudRisk,
  )

  return (
    <Container>
      <Title data-cy="claim-adjudication-title">Adjudication</Title>
      {isFraudRisk && (
        <InlineAlert data-cy="fraud-risk-inline-alert" color={InlineAlertColor.yellow} icon={Info}>
          This claim must be reviewed manually due to a Fraud Risk Score of{' '}
          <strong>{claim.fraudRiskDetails?.riskScore}</strong> on this consumer&apos;s profile.
        </InlineAlert>
      )}
      {isInManualReview && <ManualReview validationError={claim?.validationError} />}
      {isClaimDenied && claim.validationError && (
        <ErrorDenial validationError={claim.validationError} />
      )}
      {claim && claim.ruleset !== undefined && <AdjudicationRule claim={claimWithRuleset(claim)} />}
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  marginBottom: 32,
  padding: 40,
})

const Title = styled.h2({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '28px',
})

export { AdjudicationTab }
