import type { ServiceOrder, ServiceOrderStatus } from '@helloextend/extend-api-client'
import { ClaimServiceOrderType } from '@helloextend/extend-api-client/src/models/claim'
import type { ButtonEmphasis } from '@extend/zen'
import { ClaimDetailsTabs } from './claim-details/components/tab-section/types'
import { isDepotRepair, isOnsiteRepair } from '../../../utils/service-order-utils'

interface ServiceOrderModalConfig {
  text: string
  nextStep: string
  action: string
  buttonProps?: { emphasis: ButtonEmphasis }
}

type ServiceOrderModalMap = Record<
  ServiceOrderStatus,
  ServiceOrderModalConfig | { [key in ClaimServiceOrderType]?: ServiceOrderModalConfig }
>

function isServiceOrderModalConfig(object: unknown): object is ServiceOrderModalConfig {
  return (
    Object.prototype.hasOwnProperty.call(object, 'text') &&
    Object.prototype.hasOwnProperty.call(object, 'action')
  )
}

function getCreatedServiceOrderModal(serviceOrder: ServiceOrder): ServiceOrderModalConfig {
  if (serviceOrder.serviceType === 'replace') {
    return {
      text: 'Select Fulfillment Method',
      nextStep: 'accept',
      action: 'selectFulfillmentMethodModal',
    }
  }

  if (
    serviceOrder.serviceType === 'repair' &&
    !serviceOrder.configurations?.repairFulfillmentMethod
  ) {
    return {
      text: 'Select Fulfillment Method',
      nextStep: 'accept',
      action: 'selectRepairFulfillmentMethodModal',
    }
  }

  return {
    text: 'Assign Service Order',
    nextStep: 'assign',
    action: 'assignServiceOrderModal',
  }
}

const getServiceOrderStatusModalMap = (serviceOrder: ServiceOrder): ServiceOrderModalMap => ({
  created: getCreatedServiceOrderModal(serviceOrder),
  assigned: {
    text: 'Accept Service Order',
    nextStep: 'accept',
    action: 'acceptServiceOrderModal',
  },
  accepted: {
    [ClaimServiceOrderType.repair_onsite]: {
      text: 'Complete Repair',
      nextStep: 'complete-repair',
      action: 'fulfillServiceOrderModal',
    },
    [ClaimServiceOrderType.repair]: {
      text: 'Complete Repair',
      nextStep: 'complete-repair',
      action: 'fulfillServiceOrderModal',
    },
  },
  repair_shipped: {
    [ClaimServiceOrderType.repair_depot]: {
      text: 'Start Repair',
      nextStep: 'start-repair',
      action: 'startRepairServiceOrderModal',
    },
  },
  repair_started: {
    [ClaimServiceOrderType.repair_depot]: {
      text: 'Complete Repair',
      nextStep: 'complete-repair',
      action: 'fulfillServiceOrderModal',
    },
  },
  checked_in: {
    [ClaimServiceOrderType.repair_depot]: {
      text: 'Start Repair',
      nextStep: 'start-repair',
      action: 'startRepairServiceOrderModal',
    },
  },
  fulfilled: {
    text: 'Finalize Expenses',
    nextStep: 'update-expenses',
    action: ClaimDetailsTabs.Expenses,
    buttonProps: {
      emphasis: 'medium',
    },
  },
  payment_requested: {
    text: 'Review Expenses',
    nextStep: 'review-expenses',
    action: ClaimDetailsTabs.Expenses,
    buttonProps: {
      emphasis: 'medium',
    },
  },
  replacement_approved: {
    [ClaimServiceOrderType.replace]: {
      text: 'Resolve Service Order',
      nextStep: 'resolve',
      action: 'resolveServiceOrderModal',
    },
  },
  action_required: {},
  payment_approved: {
    text: 'Mark as Paid',
    nextStep: 'mark-as-paid',
    action: 'markAsPaidModal',
    buttonProps: {
      emphasis: 'medium',
    },
  },
  closed: {},
  paid: {},
})

export const getServiceOrderModalMap = (
  serviceOrder: ServiceOrder,
): ServiceOrderModalConfig | undefined => {
  const statusConfig = getServiceOrderStatusModalMap(serviceOrder)[serviceOrder.status]

  let { serviceType } = serviceOrder
  if (isDepotRepair(serviceOrder)) {
    serviceType = 'repair_depot'
  } else if (isOnsiteRepair(serviceOrder)) {
    serviceType = 'repair_onsite'
  }

  if (!isServiceOrderModalConfig(statusConfig)) {
    return statusConfig[serviceType]
  }
  return statusConfig
}
