import {
  useLazyFetchPlanQuery,
  usePrefetchPlanTerms,
  usePrefetchPlans,
} from '@helloextend/extend-api-rtk-query'
import type { SyntheticEvent } from 'react'
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import type { Column } from '@helloextend/merchants-ui'
import { Button } from '@extend/zen'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useExtendAuth } from '@extend/package-okta-login'
import * as selectors from '../../../../reducers/selectors'
import { getPlansColumns, toTablePlanItems } from './table-config'
import type { RootState } from '../../../../reducers'
import { plans as plansActions } from '../../../../actions'
import type { TablePlansSearch } from '../../../../types/plans'
import { DataTable } from '../../../../components/data-table'
import { Permission } from '../../../../lib/permissions'
import { usePermissions } from '../../../../hooks/use-permissions'

const ProductProtectionPlans: React.FC = () => {
  const history = useHistory()

  const dispatch = useDispatch()
  const { hasPermission } = usePermissions()

  const prefetchTerms = usePrefetchPlanTerms('listPlanTerms')
  const prefetchAttributes = usePrefetchPlans('listPlanAttributes')
  const [fetchPlan, { data: currentPlan }] = useLazyFetchPlanQuery()

  const [exportingPlan, setExportingPlan] = useState<string>('')
  const plans = useSelector((state: RootState) => selectors.getPlansSearchItems(state))
  const tablePlans = toTablePlanItems(plans)
  const { token: accessToken } = useExtendAuth()
  const isLoading = useSelector((state: RootState) => selectors.getIsPlansLoading(state))
  const searchFilter = useSelector((state: RootState) => selectors.getPlansSearchFilter(state))
  const [searchValue, setSearchValue] = useState(searchFilter)
  const nextPageCursor = useSelector((state: RootState) => selectors.getPlansSearchCursor(state))
  const hasPrevPage = useSelector((state: RootState) => selectors.getHasPlansPrevPage(state))
  const page = useSelector((state: RootState) => selectors.getPlansSearchPage(state))
  const lastFetchedPage = useSelector((state: RootState) =>
    selectors.getPlansLastFetchedPage(state),
  )

  const exportColumn: Column<TablePlansSearch> = {
    Header: '',
    accessor: 'id',
    Cell: (data: TablePlansSearch) => (
      <ButtonContainer>
        <Button
          isDisabled={!!exportingPlan}
          isProcessing={exportingPlan === data.id}
          text="Export"
          emphasis="low"
          onClick={(e) => {
            setExportingPlan(data.id)
            e.stopPropagation()
            fetchPlan(data.id)
          }}
        />
      </ButtonContainer>
    ),
  }

  const handleSearch = (_key: string, value: string): void => {
    if (accessToken && value) {
      setSearchValue(value)
      dispatch(plansActions.search(accessToken, value))
    }
  }

  const handleRowClick = (_e: SyntheticEvent, plan: TablePlansSearch): void => {
    history.push(`/admin/plans/${plan.id}`)
  }

  const handleCreateNewPlan = (): void => {
    history.push('/admin/plans/create')
  }

  const handleViewPlanAttributes = (): void => {
    history.push('/admin/plans/attributes')
  }

  const handlePlanImport = (): void => {
    history.push('/admin/plans/import')
  }

  const fetchNextPage = (): void => {
    if (accessToken && nextPageCursor && searchValue && page === lastFetchedPage) {
      dispatch(plansActions.nextPage(accessToken, searchValue, nextPageCursor))
    } else {
      dispatch(plansActions.incrementNextPage())
    }
  }

  const getPrevPage = (): void => {
    dispatch(plansActions.prevPage())
  }

  useEffect(() => {
    if (exportingPlan && currentPlan && 'id' in currentPlan && exportingPlan === currentPlan.id) {
      const { termsVersion, version, ...exportPlan } = currentPlan
      const fileData = JSON.stringify(exportPlan)
      const blob = new Blob([fileData], { type: 'text/plain' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.download = `Plan_${exportPlan.id}.json`
      link.href = url
      link.click()
      setExportingPlan('')
    }
  }, [currentPlan, exportingPlan])

  useEffect(() => {
    prefetchTerms()
    prefetchAttributes()
  }, [prefetchTerms, prefetchAttributes])
  return (
    <>
      <Header>
        <ButtonContainer>
          {hasPermission(Permission.ManagePlansAttributes) && (
            <Button
              text="View Plan Attributes"
              emphasis="medium"
              onClick={handleViewPlanAttributes}
              data-cy="view-plan-attributes-btn"
            />
          )}
          <Button
            data-cy="import-plan-btn"
            text="Import Plan"
            emphasis="medium"
            onClick={handlePlanImport}
          />
          <Button
            data-cy="create-new-plan-btn"
            text="Create New Plan"
            emphasis="medium"
            onClick={handleCreateNewPlan}
          />
        </ButtonContainer>
      </Header>
      <DataTable
        data={tablePlans}
        columns={getPlansColumns(exportColumn)}
        searchMode="server"
        onSearch={handleSearch}
        isLoading={isLoading}
        searchMessage="Search for plans by plan ID"
        rowClickEvent={handleRowClick}
        paginationType="plans"
        currPage={page}
        onPrevPage={getPrevPage}
        onNextPage={fetchNextPage}
        hasNext={Boolean(nextPageCursor) || page < lastFetchedPage}
        hasPrev={hasPrevPage}
        searchTerm={searchValue}
      />
    </>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  marginBottom: '-35px',
})

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
})

export { ProductProtectionPlans }
