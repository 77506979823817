import { isEqual } from 'lodash'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import type { FC } from 'react'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { BasicModal, ModalPosition, ModalBackground } from '../../../../../components/basic-modal'
import type { Contract20220201GetResponse } from '@helloextend/extend-api-client'
import { ContractNoteEntry } from './contract-note-entry'
import { ContractNoteForm } from './contract-note-form'
import type { ContractNote } from '@helloextend/extend-api-client'

interface ContractNotesModalProps {
  contract: Contract20220201GetResponse
  contractNotes: ContractNote[]
  isModalOpen: boolean
  toggle: () => void
  escalationExists: boolean
  handleEscalationNote?: (note: ContractNote) => void
}

const ContractNotesModal: FC<ContractNotesModalProps> = memo(
  ({ contract, isModalOpen, toggle, contractNotes, escalationExists, handleEscalationNote }) => {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

    // Warn user about unsaved changes if they try to close the window or navigate away
    useEffect(() => {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        if (hasUnsavedChanges) {
          const message = 'You have unsaved changes, are you sure you want to leave?'
          event.returnValue = message // Required for cross-browser compatibility
        }
      }

      window.addEventListener('beforeunload', handleBeforeUnload)

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }, [hasUnsavedChanges])

    const handleClose = useCallback((): void => {
      if (
        !hasUnsavedChanges ||
        window.confirm('You have unsaved changes. Do you still want to exit?')
      ) {
        toggle()
        setHasUnsavedChanges(false)
      }
    }, [hasUnsavedChanges, toggle])

    const memoizedContractNotes = useCallback(
      () =>
        contractNotes.map((note: ContractNote) => (
          <ContractNoteEntry
            key={note.id}
            contractNote={note}
            setHasUnsavedChanges={setHasUnsavedChanges}
            hasUnsavedChanges={hasUnsavedChanges}
            handleEscalationNote={handleEscalationNote}
          />
        )),
      [contractNotes, hasUnsavedChanges],
    )

    return (
      <BasicModal
        isVisible={isModalOpen}
        onClickClose={handleClose}
        width="574px"
        preventKeyboardNavigation
        data-cy="contract-notes-modal"
        maxHeight={`calc(100vh - 300px)`}
        modalPosition={ModalPosition.bottomRightPageBottom}
        modalBackground={ModalBackground.boxShadow}
        excludeBackground
      >
        <Title data-cy="contract-notes-title">{`${contract?.customer?.name}'s Contract Notes`}</Title>
        <ContractNoteForm
          contractId={contract.id}
          setHasUnsavedChanges={setHasUnsavedChanges}
          hasUnsavedChanges={hasUnsavedChanges}
          escalationExists={escalationExists}
        />
        {memoizedContractNotes()}
      </BasicModal>
    )
  },
  (prevProps, nextProps) =>
    prevProps.isModalOpen === nextProps.isModalOpen &&
    prevProps.escalationExists === nextProps.escalationExists &&
    isEqual(prevProps.contractNotes, nextProps.contractNotes),
)

const Title = styled.div({
  position: 'absolute',
  top: '32px',
  left: '32px',
  transform: 'translateY(-50%)',
  color: COLOR.BLACK,
  fontSize: 20,
  margin: 0,
  fontWeight: 700,
  lineHeight: '28px',
})

export { ContractNotesModal }
