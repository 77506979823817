// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0_webpack-cli@4.8.0_webpack-bundle-analyzer@4.4.2_webpack-dev-s_vrefs4evkprez4c3y7xwx6ru5q/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._6Yg0wZDnlG0iYZZ-Oy4EF {\n    height: 600px;\n    width: 400px;\n    margin-bottom: 24px;\n}\n\n._18zw-D_mBy7W43F1hChDTI {\n    height: 600px;\n    width: 400px;\n    margin-bottom: 24px;\n}\n\n._3muKKuoIwqalIDpkX8iAb4 {\n    height: 800px;\n    width: 400px;\n    margin-bottom: 24px;\n}\n\n._6Yg0wZDnlG0iYZZ-Oy4EF._246_h08Ni66XIaomvIP7a7 {\n    height: 570px;\n    width: 600px;\n}\n\n._18zw-D_mBy7W43F1hChDTI._246_h08Ni66XIaomvIP7a7 {\n    height: 700px;\n    width: 700px;\n}\n\n._3muKKuoIwqalIDpkX8iAb4._246_h08Ni66XIaomvIP7a7 {\n    height: 600px;\n    width: 600px;\n}\n\n._2fnh_QmJTZ5LS-ORxKxcz {\n    transform: translateX(20%);\n}\n\n._2KVZ-C1tCZOPli8YZELP6c {\n    display: flex;\n    justify-content: center;\n    margin-top: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"learn-more": "_6Yg0wZDnlG0iYZZ-Oy4EF",
	"offer": "_18zw-D_mBy7W43F1hChDTI",
	"aftermarket": "_3muKKuoIwqalIDpkX8iAb4",
	"desktop": "_246_h08Ni66XIaomvIP7a7",
	"cart": "_2fnh_QmJTZ5LS-ORxKxcz",
	"spinner": "_2KVZ-C1tCZOPli8YZELP6c"
};
module.exports = exports;
