import type { OktaUserStatus, UserGrant } from '@helloextend/extend-api-rtk-query'
import type { BadgeProps } from '@extend/zen'
import { getUserRolesFromGrants } from './user-role-mapper'

export type ExtendUserStatus = 'Active' | 'Deactivated' | 'Pending' | 'No Status'

export const getUserStatus = ({
  userStatus,
  userGrants,
  roleFilter,
  accountId,
}: {
  userStatus: OktaUserStatus
  userGrants: UserGrant[]
  roleFilter: 'internal' | 'merchant'
  accountId?: string
}): ExtendUserStatus => {
  if (mapOktaToExtendStatus(userStatus) !== 'Active') {
    return mapOktaToExtendStatus(userStatus)
  }

  const roles = getUserRolesFromGrants({
    grants: userGrants,
    roleFilter,
    accountId,
    includeEnterpriseRoles: true,
  })

  if (roles.length === 0) {
    return 'Deactivated'
  }

  return 'Active'
}

const mapOktaToExtendStatus = (value: OktaUserStatus): ExtendUserStatus => {
  switch (value) {
    case 'STAGED':
    case 'SUSPENDED':
    case 'DEPROVISIONED':
      return 'Deactivated'
    case 'PROVISIONED':
    case 'RECOVERY':
    case 'PASSWORD_EXPIRED':
    case 'LOCKED_OUT':
      return 'Pending'
    case 'ACTIVE':
      return 'Active'
    default:
      return 'No Status'
  }
}

export const mapStatusToBadge = (
  value: ExtendUserStatus,
): { badgeColor: BadgeProps['color']; text: string } => {
  switch (value) {
    case 'Deactivated':
      return {
        badgeColor: 'neutral',
        text: 'Deactivated',
      }
    case 'Pending':
      return {
        badgeColor: 'yellow',
        text: 'Pending',
      }
    case 'Active':
      return {
        badgeColor: 'green',
        text: 'Active',
      }

    default:
      return { badgeColor: 'neutral', text: 'No Status' }
  }
}
