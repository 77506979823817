import React from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'
import { Button, DataProperty, ModalController, Stack, Subheading, Edit } from '@extend/zen'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import { MidMccManagementModal } from './mid-mcc-management-modal'

interface MidMccManagementProps {
  settings: MerchantServicingSettings
  storeName: string
  isMidManagement: boolean // if true, treat as MID management, else, treat as MCC management
}

const MidMccManagement: FC<MidMccManagementProps> = ({ settings, storeName, isMidManagement }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const setting = isMidManagement ? settings.mids : settings.mccs

  return (
    <div data-cy="mid-mcc-management">
      <ContainerWithMargin>
        <Subheading>
          {isMidManagement
            ? 'Merchant Identification Numbers (MIDs)'
            : 'Merchant Category Codes (MCCs)'}
        </Subheading>
      </ContainerWithMargin>
      <ContainerWithMargin>
        <div>{`Enter ${
          isMidManagement ? 'MIDs' : 'MCCs'
        } to restrict customer's virtual cards to this store.`}</div>
      </ContainerWithMargin>
      <ContainerWithMargin>
        <Stack isRow spacing={5}>
          {setting &&
            setting.map((code, i) => (
              <DataProperty
                key={code}
                label={`${
                  isMidManagement
                    ? 'Merchant Identification Number (MID)'
                    : 'Merchant Category Code (MCC)'
                } - #${i + 1}`}
                value={code}
                data-cy={`mid-mcc-property-${code}`}
              />
            ))}
        </Stack>
      </ContainerWithMargin>
      <ContainerWithMargin>
        <Button
          onClick={() => setIsOpen(true)}
          icon={Edit}
          emphasis="medium"
          text={`Manage ${isMidManagement ? 'MIDs' : 'MCCs'}`}
          data-cy="open-mid-mcc-modal-btn"
        />
      </ContainerWithMargin>
      <ModalController isOpen={isOpen}>
        <MidMccManagementModal
          settings={settings}
          storeName={storeName}
          onClose={() => setIsOpen(false)}
          isMidManagement={isMidManagement}
        />
      </ModalController>
    </div>
  )
}

const ContainerWithMargin = styled.div({
  marginBottom: 24,
})

export { MidMccManagement }
