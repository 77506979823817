import type { FilterDef, SelectFilterOption } from '@extend/zen'
import type { ContractNote } from '@helloextend/extend-api-client'
import { contractStatusMap } from './contract-status-map'

export const getColumnFilterDefinitions = (
  contractNotes: ContractNote[],
  uniqueUserNameOptions: SelectFilterOption[],
) => {
  const { customerEmails, customerNames, storeNames } =
    getFilterOptionsFromContractNotes(contractNotes)
  return buildColumnFilterDefinitions(
    customerNames,
    customerEmails,
    uniqueUserNameOptions,
    storeNames,
  )
}

const contractStatusOptions = Object.entries(contractStatusMap).map(([value, display]) => ({
  value,
  display,
}))

const escalationStatusOptions = [
  { display: 'Active', value: 'false' },
  { display: 'Resolved', value: 'true' },
]

const escalationLevelOptions = [
  { display: 'Tier 1', value: 'tier1' },
  { display: 'Tier 2', value: 'tier2' },
  { display: 'Tier 3', value: 'tier3' },
]

export const buildColumnFilterDefinitions = (
  customerNameOptions: SelectFilterOption[],
  customerEmailOptions: SelectFilterOption[],
  ownerNameOptions: SelectFilterOption[],
  storeNameNameOptions: SelectFilterOption[],
): FilterDef[] => [
  {
    type: 'group',
    filterDefs: [
      {
        id: 'customerName',
        label: 'Customer Name',
        type: 'select',
        isMultiSelect: false,
        hasSearch: true,
        options: customerNameOptions,
      },
      {
        id: 'customerEmail',
        label: 'Customer Email',
        type: 'select',
        isMultiSelect: false,
        hasSearch: true,
        options: customerEmailOptions,
      },
      {
        id: 'contractStatus',
        label: 'Contract Status',
        type: 'select',
        isMultiSelect: true,
        hasSearch: false,
        options: contractStatusOptions,
      },
      {
        id: 'isResolved',
        label: 'Escalation Status',
        type: 'select',
        isMultiSelect: true,
        hasSearch: false,
        options: escalationStatusOptions,
      },
      {
        id: 'escalationLevel',
        label: 'Escalation Tier',
        type: 'select',
        isMultiSelect: true,
        hasSearch: false,
        options: escalationLevelOptions,
      },
      {
        id: 'createdAt',
        label: 'Created Date',
        type: 'dateRange',
      },
      {
        id: 'ownedAt',
        label: 'Owned Date',
        type: 'dateRange',
      },
      {
        id: 'ownedBy',
        label: 'Owner',
        type: 'select',
        isMultiSelect: false,
        hasSearch: true,
        options: ownerNameOptions,
      },
      {
        id: 'storeName',
        label: 'Store Name',
        type: 'select',
        isMultiSelect: false,
        hasSearch: true,
        options: storeNameNameOptions,
      },
    ],
  },
]

export const getFilterOptionsFromContractNotes = (contractNotes: ContractNote[] = []) => {
  const uniqueMap = new Map<string, string>()

  const customerEmails: SelectFilterOption[] = []
  const customerNames: SelectFilterOption[] = []
  const storeNames: SelectFilterOption[] = []

  contractNotes.forEach(({ customerEmail, customerName, storeName }) => {
    if (!uniqueMap.has(customerEmail)) {
      uniqueMap.set(customerEmail, customerName)
      customerEmails.push({ display: customerEmail, value: customerEmail })
    }

    if (!uniqueMap.has(customerName)) {
      uniqueMap.set(customerName, customerName)
      customerNames.push({ display: customerName, value: customerName })
    }

    if (!uniqueMap.has(storeName)) {
      uniqueMap.set(storeName, storeName)
      storeNames.push({ display: storeName, value: storeName })
    }
  })

  // Sort the arrays alphabetically by the 'value' property
  customerEmails.sort((a, b) => a.value.localeCompare(b.value))
  customerNames.sort((a, b) => a.value.localeCompare(b.value))
  storeNames.sort((a, b) => a.value.localeCompare(b.value))

  return { customerEmails, customerNames, storeNames }
}
