import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useGetAccountQuery } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import {
  COLOR,
  Breadcrumbs,
  Spinner,
  useToaster,
  ToastDuration,
  ToastColor,
  Icon,
  IconSize,
  Edit,
  format,
} from '@extend/zen'
import { useToggle } from '@helloextend/client-hooks'
import { useExtendAuth } from '@extend/package-okta-login'
import { TabMenu } from '../../../../../components/tab-menu/tab-menu'
import { Organizations as OrganizationsList } from '../../organizations/organizations'
import { Users as UsersList } from '../../users/users'
import { StoresDataTable } from '../../stores/stores-data-table'
import { AccountEditNameModal } from '../account-edit-name-modal/account-edit-name-modal'
import { MSA } from '../../msa/msa'
import { LDFlag } from 'src/constants/ld-flags'
import { useFlags } from 'launchdarkly-react-client-sdk'

enum AccountsTab {
  Organizations = 'organizations',
  Stores = 'stores',
  Users = 'users',
  MSA = 'MSA',
}

const AccountDetails: FC = () => {
  const { [LDFlag.MSAEnabled]: FF_MSA_ENABLED } = useFlags()
  const { toast } = useToaster()
  const { accountId, tab } = useParams<{ accountId: string; tab: AccountsTab }>()
  const [isAccountEditNameModalOpen, { on: toggleModalOn, off: toggleModalOff }] = useToggle()
  const history = useHistory()
  const { user } = useExtendAuth()

  const showEditAccountNameButton = [
    'admin',
    'MerchantPortalAdmin',
    'SolutionEngineer',
    'ProvisioningManager',
  ].includes(user?.role ?? '')

  const { data, isLoading } = useGetAccountQuery({ accountId })

  const handleTabClick = (tabKey: string): void => {
    history.push(`/admin/accounts/${accountId}/${tabKey}`)
  }

  const isLegacyAccount = useMemo(() => {
    const legacyAccount = data && `isLegacyAccount` in data
    return legacyAccount
  }, [data])

  if (isLoading) {
    return <Spinner color={COLOR.BLUE[800]} />
  }

  if (!data) {
    toast({
      message: 'Account not found',
      toastDuration: ToastDuration.short,
      toastColor: ToastColor.red,
    })

    return null
  }

  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs
          crumbs={[
            { text: 'Merchant Accounts', to: '/admin/accounts' },
            { text: data.name.length ? data.name : data.id },
          ]}
        />
      </BreadcrumbsWrapper>
      <HeaderContainer>
        <PageHeaderContainer>
          <Title data-cy="account-name-title">
            {data.name.length ? data.name : `Account ID: ${accountId}`}
          </Title>
          {showEditAccountNameButton && (
            <IconWrapper data-cy="edit-account-name-button" onClick={toggleModalOn}>
              <Icon icon={Edit} size={IconSize.small} color={COLOR.BLUE[800]} />
            </IconWrapper>
          )}
        </PageHeaderContainer>
        <HeaderInfoTitle data-cy="account-id-info">
          Account ID: <HeaderInfoText>{accountId}</HeaderInfoText>
        </HeaderInfoTitle>
        <HeaderInfoTitle data-cy="account-date-created-info">
          Date created:{' '}
          {data?.createdAt && (
            <HeaderInfoText>{format(data.createdAt, 'MMMM DD YYYY, HH:mm A z')}</HeaderInfoText>
          )}
        </HeaderInfoTitle>
      </HeaderContainer>
      <TabContent>
        <TabMenu
          tabs={[
            isLegacyAccount
              ? { text: 'Stores', key: AccountsTab.Stores }
              : { text: 'Organizations', key: AccountsTab.Organizations },
            { text: 'Users', key: AccountsTab.Users },
            ...(FF_MSA_ENABLED ? [{ text: 'MSA', key: AccountsTab.MSA }] : []),
          ]}
          onClick={handleTabClick}
          activeTab={tab}
        />
      </TabContent>
      {tab === AccountsTab.Stores && (
        <StoresDataTable accountId={accountId} isAccountIdColumnDisplayed={false} />
      )}
      {tab === AccountsTab.Organizations && <OrganizationsList />}
      {tab === AccountsTab.Users && <UsersList />}
      {FF_MSA_ENABLED && tab === AccountsTab.MSA && <MSA />}
      <AccountEditNameModal
        accountId={accountId}
        toggleModalOff={toggleModalOff}
        isModalOpen={isAccountEditNameModalOpen}
        isLegacyAccount={isLegacyAccount}
      />
    </>
  )
}

const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const HeaderInfoTitle = styled.div({
  color: COLOR.NEUTRAL[600],
  marginBottom: 5,
  display: 'flex',
})

const TabContent = styled.div({
  marginBottom: 32,
  marginTop: 40,
})

const HeaderInfoText = styled.span({
  color: COLOR.BLACK,
  marginLeft: 4,
})

const BreadcrumbsWrapper = styled.div({
  marginBottom: 24,
})

const IconWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 8,
  '&:hover': {
    cursor: 'pointer',
  },
})

const PageHeaderContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 24,
})

const Title = styled.h3({
  fontSize: 32,
  margin: 0,
  padding: 0,
})

export { AccountDetails }
