import React from 'react'
import type {
  AssignedUser,
  FraudRiskDetails,
} from '@helloextend/extend-api-client/src/models/claim'
import styled from '@emotion/styled'
import type { FilterOptions } from '@helloextend/merchants-ui'
import { CellText } from '@helloextend/merchants-ui'
import { date } from '@extend/client-helpers'
import type { ClaimStatus, ClaimsUser, InsuranceClaim } from '@helloextend/extend-api-client'
import { ClaimSelectStatus } from '@helloextend/extend-api-client'
import { Badge } from '@extend/zen'
import type { CellContext, ColumnDef, FilterDef, SelectFilterOption } from '@extend/zen'
import type { Escalation } from '@extend-services/claims-management'
import { escalationLevels } from '../../../lib/escalation-levels'
import { AssignUserDropdown } from '../../../components/assign-user-dropdown'
import type { TableClaimsSearch } from '../../../types/claims'
import { claimStatusDetails, getStatusBadgeDetails } from './claim-status'
import { DateAssignedDisplay } from './date-assigned-display'

export type InsuranceClaimRecord = InsuranceClaim & {
  [key: string]: unknown
}

const formatEscalationLevel = (cellData: CellContext<TableClaimsSearch, Escalation>): string => {
  if (cellData.getValue()) {
    const { escalationLevel, isResolved } = cellData.getValue()

    return isResolved ? 'Resolved' : escalationLevels[escalationLevel]
  }

  return ''
}

const formatEscalationUpdatedAt = (data?: Escalation): string => {
  if (data) {
    return date.format(data.updatedAt, 'DD MMM YYYY')
  }

  return ''
}

const getTableColumns = (): Array<ColumnDef<TableClaimsSearch>> => {
  return [
    {
      label: 'Claim Status',
      id: 'status',
      renderCell: ({ row }: CellContext<TableClaimsSearch, ClaimStatus>): JSX.Element => {
        const badgeData = getStatusBadgeDetails(row.original)
        return badgeData ? (
          <Badge
            text={badgeData.badgeText}
            emphasis="medium"
            color={badgeData.badgeColor}
            size="regular"
            details={badgeData.badgeDetails}
          />
        ) : (
          <div />
        )
      },
    },
    {
      label: 'Follow-Up Date',
      id: 'followUpDate',
      isSortable: true,
      renderCell: (cellData: CellContext<TableClaimsSearch, number>): JSX.Element => (
        <CellText data-cy="claim-followUpDate">
          {cellData.getValue() ? date.format(cellData.getValue(), 'DD MMM YYYY') : ''}
        </CellText>
      ),
    },
    {
      label: 'Escalation',
      id: 'escalation',
      isSortable: false,
      renderCell: (cellData: CellContext<TableClaimsSearch, Escalation>): JSX.Element => (
        <CellText data-cy="escalation">{formatEscalationLevel(cellData)}</CellText>
      ),
    },
    {
      label: 'Escalation Updated At',
      id: 'escalationUpdatedAt',
      isSortable: true,
      renderCell: (cellData: CellContext<TableClaimsSearch, Escalation>): JSX.Element => (
        <CellText data-cy="escalation">
          {formatEscalationUpdatedAt(cellData.row.original.escalation)}
        </CellText>
      ),
    },
    {
      label: 'Customer Name',
      id: 'customerName',
      renderCell: (cellData: CellContext<TableClaimsSearch, string>): JSX.Element => (
        <CellText data-cy="claim-customerName">{cellData.getValue()}</CellText>
      ),
    },
    {
      label: 'Customer Email',
      id: 'customerEmail',
      renderCell: (cellData: CellContext<TableClaimsSearch, string>): JSX.Element => (
        <CellText data-cy="claim-customerEmail">{cellData.getValue()}</CellText>
      ),
      search: 'explicit',
      validateSearch: (value: string): null | string => {
        if (value.length >= 3) {
          return null
        }
        return 'Must be at least 3 characters'
      },
    },
    {
      label: 'Date Reported',
      id: 'reportedAt',
      isSortable: true,
      renderCell: (cellData: CellContext<TableClaimsSearch, number>): JSX.Element => (
        <CellText data-cy="claim-reportedAt">
          {date.format(cellData.getValue(), 'DD MMM YYYY')}
        </CellText>
      ),
    },
    {
      label: 'Claim ID',
      id: 'claimId',
      renderCell: (cellData: CellContext<TableClaimsSearch, string>): JSX.Element => (
        <CellText data-cy="claim-id">{cellData.getValue()}</CellText>
      ),
      search: 'explicit',
      validateSearch: (value: string): null | string => {
        if (value.length >= 3) {
          return null
        }
        return 'Must be at least 3 characters'
      },
    },
    {
      label: 'Date Assigned',
      id: 'userAssignedAt',
      isSortable: true,
      renderCell: ({ row }: CellContext<TableClaimsSearch, ClaimStatus>): JSX.Element => {
        const claim = row.original as InsuranceClaim
        return <DateAssignedDisplay claim={claim} />
      },
    },
    {
      label: 'Service Order ID',
      id: 'serviceOrderId',
      search: 'explicit',
      isSearchOnly: true,
    },
    {
      label: 'Contract ID',
      id: 'contractId',
      search: 'explicit',
      isSearchOnly: true,
    },
    {
      label: 'Phone Number',
      id: 'phoneNumber',
      search: 'explicit',
      isSearchOnly: true,
    },
    {
      label: 'Assignee',
      id: 'assignee',
      renderCell: ({ row }: CellContext<TableClaimsSearch, AssignedUser>): JSX.Element => {
        const claim = row.original as InsuranceClaim
        return (
          <AssigneeCell data-cy="assignedUser">
            <AssignUserDropdown claim={claim} />
          </AssigneeCell>
        )
      },
    },
    {
      label: 'Fraud Risk Score',
      id: 'fraudRiskScore',
      isSortable: true,
      information: (
        <>
          Claims with a high fraud risk score at the time of filing will be marked as &lsquo;In
          Review&lsquo; and require further information.
        </>
      ),
      renderCell: ({ row }: CellContext<TableClaimsSearch, FraudRiskDetails>): JSX.Element => {
        const claim = row.original as InsuranceClaim
        if (!claim.fraudRiskDetails) return <>___</>
        return (
          <Badge
            color={claim.validationError === 'fraud_risk' ? 'red' : 'neutral'}
            emphasis="low"
            size="regular"
            details={claim.fraudRiskDetails.riskScore.toString()}
            text=""
          />
        )
      },
    },
  ]
}

const AssigneeCell = styled.div({
  display: 'flex',
  flexDirection: 'row',
  fontSize: 12,
  lineHeight: '24px',
  overflow: 'hidden',
  marginBlockStart: '1em',
  marginBlockEnd: '1em',
  gap: 8,
})

const searchOptions = [
  { label: 'Customer Email', value: 'containsCustomerEmail' },
  {
    label: 'Contract ID',
    value: 'containsContractId',
  },
  { label: 'Claim ID', value: 'containsClaimId' },
  { label: 'Phone Number', value: 'containsCustomerPhone' },
  { label: 'Service Order ID', value: 'containsServiceOrderId' },
]

const getFilterOptions = (users: ClaimsUser[]): Record<string, FilterOptions> => ({
  status: {
    label: 'Claim Status',
    type: 'nestedCheckbox',
    filters: {
      [ClaimSelectStatus.approved]: {
        label: claimStatusDetails.approved.badgeText,
      },
      [ClaimSelectStatus.denied]: {
        label: claimStatusDetails.denied.badgeText,
      },
      [ClaimSelectStatus.fulfilled]: {
        label: claimStatusDetails.fulfilled.badgeText,
      },
      [ClaimSelectStatus.review]: {
        label: claimStatusDetails.review.badgeText,
      },
      [ClaimSelectStatus.closed]: {
        label: claimStatusDetails.closed.badgeText,
        options: {
          no_service: 'No service required',
        },
      },
      [ClaimSelectStatus.pending_adjudication]: {
        label: claimStatusDetails.pending_adjudication.badgeText,
      },
    },
  },
  ...(users.length && {
    assignee: {
      label: 'Assignee',
      type: 'nestedCheckbox',
      includeSearchBar: true,
      filters: users.reduce((obj, user) => {
        return {
          ...obj,
          [user.id]: {
            label: `${user.firstName} ${user.lastName}`,
          },
        }
      }, {}),
    },
  }),
  dates: {
    type: 'group',
    label: 'Dates',
    filters: {
      reportedAtDate: {
        type: 'dateRange',
        label: 'Reported Date',
      },
    },
  },
  type: {
    label: 'Claim Type',
    type: 'checkbox',
    options: {
      extended_warranty: 'Extended Warranty',
      shipping_protection: 'Shipping Protection',
    },
  },
  serviceType: {
    label: 'Service Type',
    type: 'checkbox',
    options: {
      replace: 'Replacement',
      repair_depot: 'Depot Repair',
      repair_home: 'Home Repair',
      repair_irobot: 'iRobot Repair',
      repair_onsite: 'Onsite Repair',
    },
  },
})

const statusOptions = [
  { display: 'Approved', value: 'approved' },
  { display: 'Denied', value: 'denied' },
  { display: 'Fulfilled', value: 'fulfilled' },
  { display: 'Review', value: 'review' },
  { display: 'Closed', value: 'closed' },
  { display: 'Pending Adjudication', value: 'pending_adjudication' },
]

const claimTypeOptions = [
  { display: 'Extended Warranty', value: 'extended_warranty' },
  { display: 'Shipping Protection', value: 'shipping_protection' },
]

const serviceTypeOptions = [
  { display: 'Replacement', value: 'replace' },
  { display: 'Depot Repair', value: 'repair_depot' },
  { display: 'Home Repair', value: 'repair_home' },
  { display: 'iRobot Repair', value: 'repair_irobot' },
  { display: 'Onsite Repair', value: 'repair_onsite' },
  { display: 'Repair', value: 'repair' },
]

const escalationOptions = [
  { display: 'All', value: 'all' },
  { display: 'Resolved', value: 'resolved' },
  { display: 'Tier 1', value: 'tier1' },
  { display: 'Tier 2', value: 'tier2' },
  { display: 'Tier 3', value: 'tier3' },
]

const getFilterDefs = (users: ClaimsUser[]): FilterDef[] => {
  if (!users.length) return []
  return [
    {
      type: 'group',
      filterDefs: [
        {
          type: 'select',
          isMultiSelect: true,
          id: 'status',
          hasSearch: false,
          label: 'Claim Status',
          options: statusOptions,
        },
        {
          type: 'select',
          isMultiSelect: true,
          id: 'assignee',
          label: 'Assignee',
          options: [
            { display: 'Unassigned', value: 'unassigned' },
            ...users.map(
              (user) =>
                ({
                  display: user.fullName ?? '',
                  value: user.id,
                } as SelectFilterOption),
            ),
          ],
        },
        {
          type: 'group',
          label: 'Dates',
          filterDefs: [
            {
              type: 'dateRange',
              id: 'reportedAtDate',
              label: 'Reported Date',
            },
            {
              type: 'dateRange',
              id: 'followUpDate',
              label: 'Follow-Up Date',
            },
          ],
        },
        {
          type: 'select',
          isMultiSelect: true,
          id: 'claimType',
          hasSearch: false,
          label: 'Claim Type',
          options: claimTypeOptions,
        },
        {
          type: 'select',
          isMultiSelect: true,
          id: 'serviceType',
          hasSearch: false,
          label: 'Service Type',
          options: serviceTypeOptions,
        },
        {
          type: 'select',
          isMultiSelect: true,
          id: 'escalation',
          hasSearch: false,
          label: 'Escalation',
          options: escalationOptions,
        },
        {
          type: 'numberRange',
          id: 'fraudRiskScore',
          label: 'Fraud Risk Score',
        },
      ],
    },
  ]
}

export { getTableColumns, searchOptions, getFilterOptions, getFilterDefs }
