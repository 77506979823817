import type { FC } from 'react'
import React from 'react'
import { DisplayLarge, Button, Stack } from '@extend/zen'
import { useHistory } from 'react-router-dom'
import { TermsListTable } from './terms-data-table/terms-list-table'

const Terms: FC = () => {
  const history = useHistory()
  return (
    <Stack spacing={3}>
      <Stack isRow justify="space-between">
        <DisplayLarge>Terms & Conditions</DisplayLarge>
        <Button
          text="Create Terms"
          emphasis="medium"
          onClick={() => {
            history.push('/admin/terms/create')
          }}
          data-cy="create-terms-button"
        />
      </Stack>
      <TermsListTable />
    </Stack>
  )
}

export { Terms }
