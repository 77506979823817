import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@extend/zen'

interface ExclamationIconProps {
  size?: number
  strokeWidth?: 1 | 2 | 1.25
  color?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@extend/zen'`
 */
export const ExclamationIcon: FC<ExclamationIconProps> = ({
  size = 22,
  color = COLOR.YELLOW[600],
  strokeWidth,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="10.5" r="9.5" stroke={color} strokeWidth={strokeWidth} />
    <path
      d="M10.704 12.032L10.158 5.116L12.314 5.13L11.768 12.032H10.704ZM10.256 13.068H12.216V15H10.256V13.068Z"
      fill={color}
    />
  </svg>
)
