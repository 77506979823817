import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface ClaimDetailsState {
  activeView: string
  toastMessage: string
  lastUpdatedClaimId: string | null
}

const initialState: ClaimDetailsState = {
  activeView: '',
  toastMessage: '',
  lastUpdatedClaimId: '',
}

const claimDetailsSlice = createSlice({
  name: 'ClaimsDetails',
  initialState,
  reducers: {
    setClaimDetailsActiveView(state, action: PayloadAction<string>) {
      return {
        ...state,
        activeView: action.payload,
      }
    },
    setClaimDetailsToast(state, action: PayloadAction<string>) {
      return {
        ...state,
        toastMessage: action.payload,
      }
    },
    setLastUpdatedClaimId(state, action: PayloadAction<string | null>) {
      return {
        ...state,
        lastUpdatedClaimId: action.payload,
      }
    },
  },
})

const getClaimDetailsActiveView = (state: ClaimDetailsState): string => {
  return state.activeView
}

const getClaimDetailsToast = (state: ClaimDetailsState): string => {
  return state.toastMessage
}

const getLastUpdatedClaimId = (state: ClaimDetailsState): string | null => {
  return state.lastUpdatedClaimId
}

const claimDetailsReducer = claimDetailsSlice.reducer
const { setClaimDetailsActiveView, setClaimDetailsToast, setLastUpdatedClaimId } =
  claimDetailsSlice.actions

export type { ClaimDetailsState }
export {
  claimDetailsReducer,
  setClaimDetailsActiveView,
  getClaimDetailsActiveView,
  setClaimDetailsToast,
  getClaimDetailsToast,
  setLastUpdatedClaimId,
  getLastUpdatedClaimId,
}
