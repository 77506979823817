import styled from '@emotion/styled'
import type { DefaultMessage } from '@helloextend/extend-api-rtk-query'
import type { InputPrompt, Reply } from '@helloextend/extend-api-client'
import { Button, TextArea, Input, InputType } from '@extend/zen'
import { useFormik } from 'formik'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import type * as Yup from 'yup'
import { answerTextAreaSchema, DESCRIPTION_MAX } from './schema'
import { getMessagesContent } from '../../utils'
import type { UserInputComponentProps } from './types'

export interface AnswerTextAreaProps extends UserInputComponentProps<Reply & { prompt: InputPrompt }> {}
type Values = Yup.InferType<typeof answerTextAreaSchema>

const AnswerTextArea: FC<AnswerTextAreaProps> = ({
  isLoading,
  onSubmit,
  reply,
}) => {
  const { values, errors, handleChange, handleSubmit, resetForm } = useFormik({
    validateOnChange: true,
    initialValues: { description: '' },
    validationSchema: answerTextAreaSchema,
    onSubmit: (vals: Values): void => {
      if (reply.prompt?.slot) {
        onSubmit(reply.prompt.slot, vals.description)
      }
    },
  })

  useEffect(() => {
    resetForm({ values: { description: '' } })
  }, [reply, resetForm])

  const { validationType } = reply.prompt
  const content = getMessagesContent(reply.messages as DefaultMessage[])

  return (
    <TextAreaForm onSubmit={handleSubmit}>
      <div className="text-form-wrapper">
        {content && (
          <h2>
            <ReactMarkdown>{content}</ReactMarkdown>
          </h2>
        )}
        {validationType === 'number' ? (
          <Input
            type={InputType.number}
            id="description"
            data-cy="description-claim-number"
            onChange={handleChange}
            isDisabled={isLoading}
            isError={!!errors.description}
            errorFeedback={errors.description}
            placeholder="Enter a number"
            value={values.description}
          />
        ) : (
          <TextArea
            data-cy="description-claim-new"
            id="description"
            value={values.description}
            placeholder="Write a brief description of what happened or the state of the product, 1,000 characters max"
            maxLength={DESCRIPTION_MAX}
            onChange={handleChange}
            isDisabled={isLoading}
            isError={!!errors.description}
            errorFeedback={errors.description}
          />
        )}
      </div>
      <ButtonWrapper>
        <Button
          data-cy="continue-button"
          text="Continue"
          isDisabled={!values.description || isLoading}
          isProcessing={isLoading}
          type="submit"
        />
      </ButtonWrapper>
    </TextAreaForm>
  )
}

const TextAreaForm = styled.form({
  maxWidth: 816,
  margin: 'auto',
  h2: {
    textAlign: 'center',
  },
  '.text-form-wrapper': {
    margin: '0 auto 56px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  'div[class*="StyledTextArea"]': {
    alignSelf: 'center',
    maxWidth: 816,
    width: 650,
  },
  textarea: {
    height: 169,
  },
})

const ButtonWrapper = styled.div({
  marginTop: 32,
  display: 'flex',
  justifyContent: 'center',
})

export { AnswerTextArea }
