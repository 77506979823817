import type { FC } from 'react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Trash } from '@extend/zen'
import type { RuleStatus } from '@helloextend/extend-api-rtk-query'
import { removeConditionFromRule } from '../../../../../store/slices/amp-slice'

type RemoveConditionButtonProps = {
  ruleIndex: number
  conditionIndex: number
  rulesetType: RuleStatus
  removeKeys: (index: number) => void
}

const RemoveConditionButton: FC<RemoveConditionButtonProps> = ({
  ruleIndex,
  conditionIndex,
  rulesetType,
  removeKeys,
}) => {
  const dispatch = useDispatch()

  const handleButtonClick = (): void => {
    removeKeys(conditionIndex)
    dispatch(
      removeConditionFromRule({
        rulesetType,
        ruleIndex,
        conditionIndex,
      }),
    )
  }

  return (
    <Button
      icon={Trash}
      color="blue"
      emphasis="low"
      data-cy={`remove-${rulesetType}-rule${ruleIndex}-condition${conditionIndex}-condition-button`}
      onClick={handleButtonClick}
    />
  )
}

export { RemoveConditionButton }
