import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { GridColumnCount, GridSpacing } from '@extend/zen'
import { Checkbox, Grid, Information, Input, InputType } from '@extend/zen'
import { useFormik } from 'formik'
import type * as Yup from 'yup'
import { SaveChangesButtonGroup } from '../save-changes-button-group'

type CheckboxWithInputProps = {
  schema: Yup.Schema<any>
  checkboxLabel: string
  checkboxId: string
  inputId: string
  inputValue?: string
  inputLabel?: string
  isInitialChecked: boolean
  placeholder?: string
  inputType?: InputType
  suffix?: string
  informationChildren?: React.ReactNode
  isInputAlwaysDisplayed?: boolean
  isLoading?: boolean
  gridColumns?: GridColumnCount
  gridSpacing?: GridSpacing
  isSuccess?: boolean
  handleSave: (data: SaveClickProps) => void
}

type SaveClickProps = {
  id: string
  value?: string | boolean
  isChecked: boolean
  checkboxId: string
}

const CheckboxWithInput: FC<CheckboxWithInputProps> = ({
  schema,
  checkboxLabel,
  inputId,
  inputValue = '',
  inputLabel = '',
  placeholder = 'Enter value',
  isInitialChecked,
  inputType = InputType.text,
  suffix = '',
  checkboxId,
  informationChildren,
  isInputAlwaysDisplayed = true,
  isLoading = false,
  gridColumns = 2,
  gridSpacing = 0,
  handleSave,
}) => {
  const { values, errors, dirty, isValid, handleChange, handleReset, setFieldValue } = useFormik({
    initialValues: {
      [inputId]: inputValue,
      [checkboxId]: isInitialChecked,
    },
    onSubmit: () => {},
    validationSchema: schema,
    enableReinitialize: true,
  })

  const handleSaveClick = (): void => {
    handleSave({
      id: inputId,
      value: values[inputId],
      isChecked: Boolean(values[checkboxId]),
      checkboxId,
    })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(checkboxId, e.target.checked)
  }

  return (
    <>
      <BottomMargin>
        <Container>
          <Checkbox
            label={checkboxLabel}
            name={checkboxId}
            checked={values[checkboxId] as boolean}
            onChange={handleCheckboxChange}
            data-cy={`${checkboxId}`}
          />
          {informationChildren && (
            <Information buttonSize="small" data-cy={`${checkboxId}:info`}>
              {informationChildren}
            </Information>
          )}
        </Container>
      </BottomMargin>
      {(isInputAlwaysDisplayed || values[checkboxId]) && (
        <BottomMargin>
          <Grid spacing={gridSpacing} columns={gridColumns}>
            <Input
              label={inputLabel}
              id={inputId}
              value={values[inputId] as string}
              isError={Boolean(errors[inputId])}
              errorFeedback={errors[inputId]}
              onChange={handleChange}
              placeholder={placeholder}
              isDisabled={!values[checkboxId]}
              type={inputType}
              suffix={suffix}
              data-cy={`${inputId}`}
            />
          </Grid>
        </BottomMargin>
      )}
      {dirty && (
        <BottomMargin>
          <SaveChangesButtonGroup
            isSaveDisabled={!isValid || !dirty}
            isLoading={isLoading}
            id={inputId}
            handleSave={handleSaveClick}
            handleCancel={handleReset}
          />
        </BottomMargin>
      )}
    </>
  )
}

const BottomMargin = styled.div({
  marginBottom: 16,
})

const Container = styled.div({
  display: 'flex',
})

export type { SaveClickProps }
export { CheckboxWithInput }
