import {
  useGetShippingProtectionPlanQuery,
  useGetShippingProtectionPlanVersionsQuery,
} from '@helloextend/extend-api-rtk-query'
import {
  Badge,
  Breadcrumbs,
  Button,
  ButtonGroup,
  COLOR,
  DataProperty,
  DisplayLarge,
  Modal,
  ModalController,
  Spinner,
  Stack,
  format,
} from '@extend/zen'
import type { FC } from 'react'
import React, { useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { FlexColumn, FlexRowSpaceBetween } from '../../../../../styles'
import { SpPlanForm } from '../../sp-plan-form/sp-plan-form'
import type { SpPlanFormValues } from '../../sp-plan-form/sp-plan-form-schema';
import { getInitialValues } from '../../sp-plan-form/sp-plan-form-schema'

const SpPlanVersionDetails: FC = () => {
  const { planId, version } = useParams<{ planId: string; version: string }>()
  const createFormRef = useRef<{ submit: () => void; resetForm: () => void }>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [formProps, setFormProps] = useState({ isLoading: false, canSubmit: false })

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const {
    data: SpPlanVersionDetail,
    isLoading: isGettingPlanVersionDetail,
    isFetching: isFetchingPlanVersionDetail,
  } = useGetShippingProtectionPlanQuery({
    planId,
    version,
  })

  const {
    data: spPlanVersions,
    isLoading: isGettingPlanVersions,
    isError: isErrorGettingPlanVersions,
    isFetching: isFetchingPlanVersions,
  } = useGetShippingProtectionPlanVersionsQuery(planId)

  const handleRestore = (): void => {
    createFormRef.current?.submit()
    setIsEditing(false)
    setShowConfirmModal(false)
  }

  const handleCancelRestore = (): void => {
    setShowConfirmModal(false)
    setIsEditing(false)
    createFormRef.current?.resetForm()
  }

  const latestPlanVersion = useMemo(() => {
    if (spPlanVersions?.items.length) {
      return spPlanVersions.items.find((item) => item.active)?.version ?? 0
    }
    return 0
  }, [spPlanVersions?.items])

  if (
    isGettingPlanVersionDetail ||
    isGettingPlanVersions ||
    isFetchingPlanVersionDetail ||
    isFetchingPlanVersions
  ) {
    return (
      <Stack isRow justify="center">
        <Spinner color={COLOR.BLUE[800]} />
      </Stack>
    )
  }

  return (
    <div>
      <Breadcrumbs
        crumbs={[
          {
            text: 'Plans',
            to: '/admin/plans',
          },
          {
            text: 'Shipping Protection',
            to: '/admin/plans/shipping-protection',
          },
          {
            text: `${planId}`,
            to: `/admin/plans/shipping-protection/${planId}`,
          },
          {
            text: `Versions`,
            to: `/admin/plans/shipping-protection/${planId}?tab=versions`,
          },
          {
            text: `Version ${version}`,
          },
        ]}
      />
      {SpPlanVersionDetail && !isGettingPlanVersionDetail && !isErrorGettingPlanVersions ? (
        <>
          <HeaderContainer>
            <FlexRowSpaceBetween>
              <Stack spacing={1}>
                <DisplayLarge data-cy="heading">{`Version ${SpPlanVersionDetail.version}`}</DisplayLarge>
                <Badge
                  text={SpPlanVersionDetail.active ? 'Active' : 'Deactivated'}
                  color={SpPlanVersionDetail.active ? 'green' : 'yellow'}
                  data-cy="active-badge"
                />
              </Stack>
              {!SpPlanVersionDetail.active ? (
                <ButtonGroup>
                  {isEditing ? (
                    <>
                      <Button
                        text="Cancel"
                        emphasis="medium"
                        onClick={() => handleCancelRestore()}
                        data-cy="cancel-restore-button"
                      />
                      <Button
                        text="Restore"
                        emphasis="high"
                        isDisabled={formProps.isLoading}
                        onClick={() => setShowConfirmModal(true)}
                        data-cy="restore-button"
                      />
                    </>
                  ) : (
                    <Button
                      text="Edit & Restore"
                      emphasis="medium"
                      onClick={() => setIsEditing(true)}
                      data-cy="edit-restore-button"
                    />
                  )}
                </ButtonGroup>
              ) : null}
            </FlexRowSpaceBetween>
            <FlexColumn>
              <DataProperty
                label="Date Deactivated"
                value={format(SpPlanVersionDetail.updatedAt ?? 0)}
                isHorizontal
                data-cy="date-deactivated"
              />
              <DataProperty
                label="Date Activated"
                value={format(SpPlanVersionDetail.createdAt ?? 0)}
                isHorizontal
                data-cy="date-activated"
              />
            </FlexColumn>
          </HeaderContainer>
          <SpPlanForm
            initialValues={getInitialValues(SpPlanVersionDetail as SpPlanFormValues)}
            isExistingPlan
            handleFormProps={setFormProps}
            isEditing={isEditing}
            isRestoringPlan
            ref={createFormRef}
          />
          <ModalController isOpen={showConfirmModal}>
            <Modal
              heading="Restore Version"
              primaryButtonProps={{
                text: 'Restore',
                onClick: handleRestore,
                isProcessing: formProps.isLoading,
                isDisabled: formProps.isLoading,
                'data-cy': 'modal-restore-button',
              }}
              secondaryButtonProps={{
                text: 'Cancel',
                onClick: () => handleCancelRestore(),
                'data-cy': 'modal-cancel-button',
              }}
              onDismissRequest={() => handleCancelRestore()}
              data-cy="modal-confirmation"
            >
              <Stack data-cy="modal-confirmation-message">
                <p>
                  {`You are about to restore this version of the plan. The restored version will
                  become version`}{' '}
                  <b>{latestPlanVersion + 1}</b> {`of Plan ID: `}
                  <b>{planId}</b>.
                </p>
                <p>
                  {`
                  This may affect existing offers. Are you
                  sure you want to restore the version?`}
                </p>
              </Stack>
            </Modal>
          </ModalController>
        </>
      ) : null}
    </div>
  )
}

const HeaderContainer = styled.div({
  marginTop: '20px',
  marginBottom: '20px',
})

export { SpPlanVersionDetails }
