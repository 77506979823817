import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit'
import {
  auditApi,
  claimsApi,
  claimPhotosApi,
  contractsApi,
  productsApi,
  ordersApi,
  serviceOrdersApi,
  plansApi,
  servicersApi,
  productsMappingApi,
  skusApi,
  claimsAssignmentUsersApi,
  incredibotApi,
  insuranceProgramsApi,
  storesApi,
  virtualCardsApi,
  refundsApi,
  accountsApi,
  conversationsApi,
  conversationsSamplePhotos,
  usersV3Api,
  oktaApi,
  grantsApi,
  productsRdbApi,
  productCategoriesRdbApi,
  plansTermsApi,
  wdOrchestratorApi,
  warrantyDecisioningAPI,
  taxonomyApi,
  messagingApi,
  donationsApi,
  shippingOffersStoresConfigApi,
  adjudicationManagementApi,
  emsApi,
  offersApi,
  popaApi,
  themesApi,
  authApi,
  lineItemsApi,
  aisApi,
} from '@helloextend/extend-api-rtk-query'
import createSagaMiddleware from 'redux-saga'
import { EXTEND_ENV } from '@helloextend/client-constants'
import { logErrors } from '@helloextend/core-api-redux/src/middleware'
import type { DeepPartial, Store } from 'redux'
import rootReducer from '../reducers'
import type { RootState } from '../reducers'
import rootSaga from '../sagas'
import { load as loadState, save as saveState } from './persistence'

export default function configureStore(preLoadedState?: DeepPartial<RootState>): Store {
  const sagaMiddleware = createSagaMiddleware()

  const store = rtkConfigureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        sagaMiddleware,
        logErrors,
        auditApi.middleware,
        serviceOrdersApi.middleware,
        plansApi.middleware,
        claimsApi.middleware,
        claimPhotosApi.middleware,
        contractsApi.middleware,
        productsApi.middleware,
        servicersApi.middleware,
        productsMappingApi.middleware,
        skusApi.middleware,
        claimsAssignmentUsersApi.middleware,
        ordersApi.middleware,
        incredibotApi.middleware,
        insuranceProgramsApi.middleware,
        storesApi.middleware,
        refundsApi.middleware,
        accountsApi.middleware,
        virtualCardsApi.middleware,
        conversationsApi.middleware,
        conversationsSamplePhotos.middleware,
        oktaApi.middleware,
        usersV3Api.middleware,
        grantsApi.middleware,
        productsRdbApi.middleware,
        productCategoriesRdbApi.middleware,
        plansTermsApi.middleware,
        wdOrchestratorApi.middleware,
        warrantyDecisioningAPI.middleware,
        taxonomyApi.middleware,
        messagingApi.middleware,
        donationsApi.middleware,
        shippingOffersStoresConfigApi.middleware,
        adjudicationManagementApi.middleware,
        emsApi.middleware,
        offersApi.middleware,
        popaApi.middleware,
        themesApi.middleware,
        authApi.middleware,
        lineItemsApi.middleware,
        aisApi.middleware,
      ),
    preloadedState: preLoadedState ?? loadState(),
  })

  sagaMiddleware.run(rootSaga)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (EXTEND_ENV === 'local' && (globalThis.module as any)?.hot) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(globalThis.module as any).hot?.accept('../reducers', () => store.replaceReducer(rootReducer))
  }

  store.subscribe(() => {
    saveState(store.getState())
  })

  return store
}
