import type { FC } from 'react'
import React from 'react'
import type {
  LeadsCreatedConfigurationGetResponse,
  Store,
} from '@helloextend/extend-api-client/src/models'
import { MessageTypes } from '@helloextend/extend-api-client/src/models'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { AdvancedSelect, Checkbox, Grid, Input, InputType, Switch } from '@extend/zen'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import {
  useCreateConfigurationMutation,
  useUpdateStoreMutation,
} from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { MerchantExtendedWarrantyRevenueAgreementType } from '@helloextend/extend-api-client/src/models/store'
import { SaveChangesButtonGroup } from '../../stores/store-details/settings/components/save-changes-button-group'
import type { Values } from './schema'
import { partialReimbursementOptions, schema, revenueModelOptions } from './schema'
import { useDecimalsOnBlur } from '../../../../../hooks/use-decimals-on-blur'
import { Divider } from '../../../../../components/divider'
import { LDFlag } from '../../../../../constants/ld-flags'

type ProductProtectionFormProps = {
  store: Store
  messagingConfig?: LeadsCreatedConfigurationGetResponse
  handleCancel: () => void
}

const ProductProtectionForm: FC<ProductProtectionFormProps> = ({
  store,
  messagingConfig,
  handleCancel,
}) => {
  const { toastError, toastCompleted } = useStandardToast()
  const { [LDFlag.MessaginFeaturesConfigurationSendTermsSms]: FF_MESSAGING_SMS_TERMS } = useFlags()
  const [updateMessagingConfig, { isLoading: isUpdatingMessagingConfig }] =
    useCreateConfigurationMutation()

  const {
    merchantEwRevenueAgreement,
    merchantEwRevenueSharePercentage,
    partialReimbursementPercentage,
    extendCut,
    id,
    name: storeName,
    partialReimbursementEnabled,
    locationFilteringEnabled,
    partialReimbursementFixed,
    partialReimbursementType,
  } = store
  const partialReimbursementPercentageValue = partialReimbursementPercentage
    ? Number((partialReimbursementPercentage * 100).toFixed(1))
    : undefined

  const partialReimbursementFixedValue = partialReimbursementFixed
    ? Number(partialReimbursementFixed)
    : undefined

  const partialReimbursementSelection = partialReimbursementType
    ? String(partialReimbursementType)
    : undefined

  const customExtendCutPercentageValue = extendCut
    ? Number((extendCut * 100).toFixed(1))
    : undefined

  let partialReimbursementInitialValue

  if (partialReimbursementPercentageValue && partialReimbursementSelection === 'percentage') {
    partialReimbursementInitialValue = partialReimbursementPercentageValue
  } else if (partialReimbursementFixedValue && partialReimbursementSelection === 'fixed') {
    partialReimbursementInitialValue = partialReimbursementFixedValue
  }

  const { values, errors, dirty, isValid, handleChange, resetForm, setFieldValue, setValues } =
    useFormik<Values>({
      initialValues: {
        merchantEwRevenueAgreement: merchantEwRevenueAgreement || 'net_revenue_share',
        merchantEwRevenueSharePercentage: merchantEwRevenueSharePercentage
          ? merchantEwRevenueSharePercentage * 100
          : 0,
        partialReimbursementValue: partialReimbursementInitialValue,
        partialReimbursementSelection:
          partialReimbursementEnabled && partialReimbursementSelection
            ? partialReimbursementSelection
            : null,
        extendCut: customExtendCutPercentageValue,
        partialReimbursementEnabled: partialReimbursementEnabled ?? undefined,
        isExtendCutOn: Boolean(customExtendCutPercentageValue),
        locationFilteringEnabled,
        SendSMSTermsEnabled: messagingConfig?.enabled,
      },
      validationSchema: schema,
      onSubmit: () => {},
      validateOnChange: true,
    })

  const [updateStore, { isLoading }] = useUpdateStoreMutation()

  const { handleOnBlurCustom } = useDecimalsOnBlur(setFieldValue)

  const handleCancelClick = (): void => {
    resetForm()
    handleCancel()
  }
  const handleSaveClick = async (): Promise<void> => {
    const isWholesale = values.merchantEwRevenueAgreement === 'wholesale'

    const data = {
      merchantEwRevenueAgreement:
        values.merchantEwRevenueAgreement as MerchantExtendedWarrantyRevenueAgreementType,
      merchantEwRevenueSharePercentage: isWholesale
        ? null
        : (values.merchantEwRevenueSharePercentage as number) / 100,
      partialReimbursementEnabled: values.partialReimbursementEnabled,
      partialReimbursementPercentage:
        values.partialReimbursementSelection === 'percentage'
          ? (values.partialReimbursementValue as number) / 100
          : null,
      extendCut: values.isExtendCutOn ? (values.extendCut as number) / 100 : null,
      locationFilteringEnabled: values.locationFilteringEnabled,
      partialReimbursementType: values.partialReimbursementSelection,
      partialReimbursementFixed:
        values.partialReimbursementSelection === 'fixed'
          ? Number(values.partialReimbursementValue)
          : null,
    } as Partial<Store>

    try {
      await updateStore({
        storeId: id,
        data,
        version: 'latest',
      }).unwrap()

      if (FF_MESSAGING_SMS_TERMS) {
        await updateMessagingConfig({
          message: {
            name: MessageTypes.ORDER_RECEIVED_SEND_TERMS_SMS,
          },
          configuration: {
            enabled: values.SendSMSTermsEnabled,
            store: {
              id,
              name: storeName ?? '',
            },
          },
        }).unwrap()
      }

      handleCancel()
      toastCompleted('Product Protection Settings have been successfully updated.')
    } catch {
      toastError('Something went wrong. Please try again.')
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = e.currentTarget
    let propertyToUpdate = ''

    if (name === 'partialReimbursementEnabled') {
      propertyToUpdate = 'partialReimbursementValue'
    }

    if (name === 'isExtendCutOn') {
      propertyToUpdate = 'extendCut'
    }

    setValues({
      ...values,
      // update the checkbox value
      [name]: checked,
      // update the value of the input
      [propertyToUpdate]: checked ? undefined : null,
    })
  }

  return (
    <div data-cy="product-protection-form">
      <MarginBottom>
        <Grid columns={4} spacing={4}>
          <AdvancedSelect
            isNotClearable
            label="Revenue Model"
            id="merchantEwRevenueAgreement"
            value={values.merchantEwRevenueAgreement as string}
            onChange={handleChange}
            placeholder="Select"
            options={revenueModelOptions}
            multiple={false}
            isError={Boolean(errors?.merchantEwRevenueAgreement)}
            errorFeedback={errors?.merchantEwRevenueAgreement}
            data-cy="revenue-model-select"
          />
          {values.merchantEwRevenueAgreement !== 'wholesale' && (
            <Input
              id="merchantEwRevenueSharePercentage"
              onChange={handleChange}
              onBlur={handleOnBlurCustom}
              type={InputType.number}
              value={values.merchantEwRevenueSharePercentage?.toString() || ''}
              isError={Boolean(errors?.merchantEwRevenueSharePercentage)}
              errorFeedback={errors?.merchantEwRevenueSharePercentage}
              label="Merchant Revenue Share"
              suffix="%"
              placeholder="Enter a number"
              min={0}
              max={100}
              data-cy="merchant-cut-input"
            />
          )}
        </Grid>
      </MarginBottom>
      <Divider margin="0 0 24px 0" />
      <MarginBottom>
        <Checkbox
          label="Partial Reimbursement Merchant"
          name="partialReimbursementEnabled"
          checked={values.partialReimbursementEnabled ?? false}
          onChange={handleCheckboxChange}
          data-cy="partial-reimbursement-checkbox"
        />
        <Divider margin="0 0 12px 0" />
        {values.partialReimbursementEnabled && (
          <Grid columns={4} spacing={4}>
            <AdvancedSelect
              isNotClearable
              label="Partial Reimbursement Options"
              id="partialReimbursementSelection"
              value={values.partialReimbursementSelection as string}
              onChange={handleChange}
              placeholder="Select"
              options={partialReimbursementOptions}
              multiple={false}
              isError={Boolean(errors?.partialReimbursementSelection)}
              errorFeedback={errors?.partialReimbursementSelection}
              data-cy="partial-reimbursement-select"
            />
            {values.partialReimbursementSelection && (
              <Input
                label={
                  values.partialReimbursementSelection === 'percentage'
                    ? 'Partial Reimbursement Percentage'
                    : 'Fixed Partial Reimbursement'
                }
                id="partialReimbursementValue"
                value={values.partialReimbursementValue?.toString() || ''}
                suffix={values.partialReimbursementSelection === 'percentage' ? '%' : ''}
                placeholder="Enter a number"
                onChange={handleChange}
                onBlur={handleOnBlurCustom}
                isError={Boolean(errors?.partialReimbursementValue)}
                errorFeedback={errors?.partialReimbursementValue}
                data-cy="partialReimbursementValue"
              />
            )}
          </Grid>
        )}
      </MarginBottom>
      <MarginBottom>
        <Checkbox
          label="Custom ExtendCut"
          name="isExtendCutOn"
          checked={values.isExtendCutOn ?? false}
          onChange={handleCheckboxChange}
          data-cy="custom-extendCut-checkbox"
        />
        {values.isExtendCutOn && (
          <Grid columns={4}>
            <Input
              label="Custom Extend Cut Percentage"
              id="extendCut"
              value={values.extendCut?.toString() || ''}
              suffix="%"
              placeholder="Enter a number"
              onChange={handleChange}
              onBlur={handleOnBlurCustom}
              isError={Boolean(errors?.extendCut)}
              errorFeedback={errors?.extendCut}
              data-cy="extendCut"
            />
          </Grid>
        )}
      </MarginBottom>
      <MarginBottom>
        <Switch
          id="locationFilteringEnabled"
          data-cy="location-filtering-toggle"
          label="Location Filtering"
          isOn={values.locationFilteringEnabled ?? false}
          onChange={handleChange}
        />
      </MarginBottom>
      {FF_MESSAGING_SMS_TERMS && (
        <MarginBottom>
          <Switch
            id="SendSMSTermsEnabled"
            data-cy="send-sms-terms-toggle"
            label="SMS terms enabled"
            isOn={values.SendSMSTermsEnabled ?? false}
            onChange={handleChange}
          />
        </MarginBottom>
      )}
      <ButtonGroupWrapper>
        <SaveChangesButtonGroup
          id="product-protection"
          handleSave={handleSaveClick}
          handleCancel={handleCancelClick}
          isSaveDisabled={!dirty || !isValid}
          isLoading={isLoading || isUpdatingMessagingConfig}
        />
      </ButtonGroupWrapper>
    </div>
  )
}

const ButtonGroupWrapper = styled.div({
  marginTop: 16,
})

const MarginBottom = styled.div({
  marginBottom: 24,
})
export { ProductProtectionForm }
