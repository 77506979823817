import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    isbn: Yup.string(),
    sku: Yup.string(),
    barcode: Yup.string(),
    gtin: Yup.string(),
    upc: Yup.string(),
    asin: Yup.string(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export type { Values }
export { schema }
