import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import type { ThreadResponse } from '@helloextend/extend-api-rtk-query'
import { CustomerResponse } from '../customer-response'
import type { SelectedMessageBlock } from '../../../../../store/slices/amp-slice'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'
import { KaleyText } from '../kaley-text'

type DynamicPickerEditorProps = {
  thread: ThreadResponse
  dynamicPickerBlock: SelectedMessageBlock
  isThreadStructureLocked: boolean
}

const DynamicPickerEditor: FC<DynamicPickerEditorProps> = ({
  thread,
  dynamicPickerBlock,
  isThreadStructureLocked,
}) => {
  const selectedBlockIndex = useSelector((state: RootState) =>
    selectors.getSelectedMessageBlockIndex(state),
  )

  return (
    <>
      <KaleyWrapper>
        <KaleyText
          messageBlock={dynamicPickerBlock}
          isThreadStructureLocked={isThreadStructureLocked}
        />
      </KaleyWrapper>
      <CustomerResponse
        thread={thread}
        messageBlock={dynamicPickerBlock}
        messageBlockIndex={selectedBlockIndex}
        isThreadStructureLocked={isThreadStructureLocked}
      />
    </>
  )
}

const KaleyWrapper = styled.div({
  padding: '24px 0 32px 0',
})

export type { DynamicPickerEditorProps }
export { DynamicPickerEditor }
