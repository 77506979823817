import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import {
  useGetMerchantServicingSettingsQuery,
  useUpsertMerchantServicingSettingsMutation,
} from '@helloextend/extend-api-rtk-query'
import {
  Button,
  COLOR,
  Icon,
  IconSize,
  Input,
  OpenInNew,
  Switch,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { schema } from './schema'
import type { Values } from './schema'

interface WebhookConfigurationsProps {
  storeId: string
}

const WebhookConfigurations: FC<WebhookConfigurationsProps> = ({ storeId }) => {
  const { data: merchantServicingSettings, isFetching } = useGetMerchantServicingSettingsQuery({
    sellerId: storeId,
  })
  const [upsertMerchantServicingSettings, { isLoading, isSuccess, isError }] =
    useUpsertMerchantServicingSettingsMutation()

  const { toast } = useToaster()
  const initialValues = {
    webhookUrl: merchantServicingSettings?.webhookConfigurations?.url ?? '',
  }
  const { values, setFieldValue, submitForm, isValid } = useFormik<Values>({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    validateOnMount: true,
    onSubmit: () => {
      upsertMerchantServicingSettings({
        sellerId: storeId,
        webhookConfigurations: {
          url: values.webhookUrl,
          enabledClaimStatuses:
            merchantServicingSettings?.webhookConfigurations?.enabledClaimStatuses ?? [],
        },
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: 'Changes saved!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    }
    if (isError) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [isSuccess, isError, toast])

  const isDisabled = !isValid || isLoading || isFetching

  return (
    <ContentWrapper data-cy="payment-tab">
      <Header>
        <Title>Claim Status Webhooks</Title>
      </Header>
      <ViewInstructionsContainer>
        <SectionHeader>Connect merchant system with Extend, saving your webhook URL.</SectionHeader>
        <ViewInstructionsLink href="https://docs.extend.com/reference">
          View instructions
        </ViewInstructionsLink>
        <Icon icon={OpenInNew} size={IconSize.small} color={COLOR.BLUE[700]} />
      </ViewInstructionsContainer>
      <WebhookURLContainer>
        <InputWrapper>
          <Input
            value={values.webhookUrl}
            id="webhookUrl"
            label="Webhook URL"
            onChange={(e) => setFieldValue('webhookUrl', e.target.value)}
            data-cy="webhook-url-input"
          />
        </InputWrapper>
        <SaveButtonWrapper>
          <Button
            data-cy="save-button"
            isDisabled={isDisabled}
            text="Save"
            type="submit"
            onClick={() => submitForm()}
            isProcessing={isLoading}
          />
        </SaveButtonWrapper>
      </WebhookURLContainer>
      <EventsHeading>EVENTS</EventsHeading>
      <Switch
        label="Claim Approved"
        isOn={
          merchantServicingSettings?.webhookConfigurations?.enabledClaimStatuses?.includes(
            'approved',
          ) || false
        }
        isDisabled={isLoading || isFetching}
        onChange={() => {
          const enabledStatuses =
            merchantServicingSettings?.webhookConfigurations?.enabledClaimStatuses ?? []
          upsertMerchantServicingSettings({
            sellerId: storeId,
            webhookConfigurations: {
              url: merchantServicingSettings?.webhookConfigurations?.url ?? '',
              enabledClaimStatuses: enabledStatuses.includes('approved')
                ? enabledStatuses.filter((status) => status !== 'approved')
                : [...enabledStatuses, 'approved'],
            },
          })
        }}
        data-cy="claim-approved-switch"
      />
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[200]}`,
  marginBottom: 32,
  padding: 40,
  borderRadius: 4,
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  margin: 0,
})

const SectionHeader = styled.p({
  margin: '24px 0px',
  fontSize: 16,
  lineHeight: '20px',
  marginRight: 12,
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

const InputWrapper = styled.div({
  display: 'flex',
  flexGrow: 1,
  div: {
    width: '100%',
  },
})

const SaveButtonWrapper = styled.div({
  display: 'flex',
  alignSelf: 'flex-end',
})

const WebhookURLContainer = styled.div({
  display: 'flex',
  gap: 16,
  marginTop: 16,
  width: '100%',
})

const EventsHeading = styled.div({
  color: COLOR.BLACK[0],
  fontFamily: 'Nunito Sans',
  fontSize: 14,
  fontWeight: 700,
  lineHeight: '18px',
  marginTop: 16,
  marginBottom: 16,
})

const ViewInstructionsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
})

const ViewInstructionsLink = styled.a({
  textDecoration: 'none',
  color: COLOR.BLUE[700],
})

export { WebhookConfigurations }
