import React from 'react'
import type { FC } from 'react'
import { useDeletePlanSetMutation } from '@helloextend/extend-api-rtk-query'
import { Modal, useToaster, ToastColor, ToastDuration } from '@extend/zen'

type DeletePlanSetModalProps = {
  planSetId: string
  toggleModal: () => void
}

const DeletePlanSetModal: FC<DeletePlanSetModalProps> = ({ planSetId, toggleModal }) => {
  const { toast } = useToaster()

  const [deletePlanSet, { isLoading: isDeleting }] = useDeletePlanSetMutation()
  const handleDeleteConfirm = async (): Promise<void> => {
    try {
      await deletePlanSet({
        ids: [planSetId],
      }).unwrap()
      toast({
        message: 'The plan set has been successfully removed.',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    } catch {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    } finally {
      toggleModal()
    }
  }

  return (
    <Modal
      data-cy="delete-plan-set-modal"
      heading="Delete plan set"
      onDismissRequest={toggleModal}
      primaryButtonProps={{
        onClick: handleDeleteConfirm,
        text: 'Delete',
        color: 'red',
        isDisabled: isDeleting,
        isProcessing: isDeleting,
        'data-cy': 'delete-plan-set-modal-delete-button',
      }}
      secondaryButtonProps={{
        onClick: toggleModal,
        text: 'Cancel',
        color: 'blue',
        isDisabled: isDeleting,
        'data-cy': 'delete-plan-set-modal-cancel-button',
      }}
    >
      <>
        <p data-cy="modal-text">
          Are you sure you want to delete this plan set? Once removed this plan set will no longer
          be available.{' '}
        </p>
      </>
    </Modal>
  )
}

export { DeletePlanSetModal }
