import type { SPABTestTypes } from '@extend-services/shipping-offers/dist/src/client/ab-tests'

export interface StoreConfigCreateBody {
  abTest: {
    enabled: boolean
    type: SPABTestTypes
  }
}

export interface StoreConfigUpdateBody {
  abTest?: {
    enabled?: boolean
    type?: SPABTestTypes
  }
}

export enum LearnMoreModalVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export enum CartOfferVersion {
  V1 = 'V1',
}

export type SPSurfaceVersion = `${LearnMoreModalVersion}`

export type LearnMoreModalContentTypes =
  | 'shippingProtectionLearnMoreModal'
  | 'shippingProtectionLearnMoreModalV2'
export type CartOfferContentTypes = 'shippingProtectionCartOffer'

export interface LearnMoreModalVersions {
  [LearnMoreModalVersion.V1]: 'shippingProtectionLearnMoreModal'
  [LearnMoreModalVersion.V2]: 'shippingProtectionLearnMoreModalV2'
}

export interface CartOfferVersions {
  [CartOfferVersion.V1]: 'shippingProtectionCartOffer'
}

export interface SPSurfaceVersionsTypes {
  learnMoreModal: LearnMoreModalVersions
  cartOffer: CartOfferVersions
}

export const SP_SURFACE_VERSIONS: SPSurfaceVersionsTypes = {
  learnMoreModal: {
    [LearnMoreModalVersion.V1]: 'shippingProtectionLearnMoreModal',
    [LearnMoreModalVersion.V2]: 'shippingProtectionLearnMoreModalV2',
  },
  cartOffer: { [CartOfferVersion.V1]: 'shippingProtectionCartOffer' },
} as const

export interface SurfaceVersion {
  learnMoreModal: {
    enabled: boolean
    version: LearnMoreModalVersion
    content: LearnMoreModalContentTypes
  }
  cartOffer: {
    enabled: boolean
    version: CartOfferVersion
    content: CartOfferContentTypes
  }
}

export interface StoreConfig {
  storeId: string
  organizationId: string
  abTest: {
    enabled: boolean
    type: SPABTestTypes
  }
  surfaceVersion?: SurfaceVersion
  createdAt: number
  updatedAt: number
}
