import type { FC } from 'react'
import React, { useCallback } from 'react'
import { CaptionLegal, Modal, Paragraph, Stack } from '@extend/zen'
import { useFormik } from 'formik'
import { useGetStoreQuery, useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { number as numberHelper } from '@extend/client-helpers'
import type { Store } from '@helloextend/extend-api-client'
import type { Values } from '../../../store-details/shipping-protection/shipping-protection-form/schema'
import {
  getPlanIdDefault,
  getLocationFilteringDefault,
  schema,
} from '../../../store-details/shipping-protection/shipping-protection-form/schema'
import { RevenueShareInput } from '../../../store-details/shipping-protection/common/revenue-share-input'
import { ShippingProtectionPlansDropdown } from '../../../store-details/shipping-protection/shipping-protection-purchase-model/shipping-protection-plans-dropdown/shipping-protection-plans-dropdown'
import { LocationFilteringSelect } from '../../../store-details/shipping-protection/common/location-filtering-select'
import type { ToggleStatusType } from '../types'
import { LDFlag } from '../../../../../../../constants/ld-flags'
import { ShippingProtection } from '../../../store-details/shipping-protection/shipping-protection'

type ShippingProtectionModalProps = {
  storeName?: string
  storeId: string
  toggleType: ToggleStatusType
  dismissModal: () => void
  onSaved: () => void
}

const ShippingProtectionModal: FC<ShippingProtectionModalProps> = ({
  storeName,
  storeId,
  toggleType,
  dismissModal,
  onSaved,
}) => {
  const flags = useFlags()
  const spPurchaseModel = flags[LDFlag.SPPurchaseModel]

  const { data, isFetching, isUninitialized } = useGetStoreQuery({ storeId, version: 'latest' })
  const { toastCompleted, toastError } = useStandardToast()

  // used in the modal header and in the primary button inner text
  const modalMode = toggleType === 'enabled' ? 'Enable' : 'Approve'

  const handleSave = async (): Promise<void> => {
    const { approved, enabled, offerType, planId } = data?.shippingProtection || {}

    // if the store doesn't have required settings set, and a user is trying to both approve and enable the store
    const shouldDisplayErrorToast =
      (!offerType || !planId) &&
      ((approved && !enabled) || (!approved && enabled)) &&
      spPurchaseModel

    if (shouldDisplayErrorToast) {
      toastError(
        'Store cannot be Approved AND Enabled without first setting a valid Plan ID and Offer Type',
      )
      return
    }

    const revenueShare = values?.merchantSpRevenueSharePercentage
      ? numberHelper.getDecimalFromPercentage(values?.merchantSpRevenueSharePercentage)
      : null
    try {
      const updateData = spPurchaseModel
        ? {
            shippingProtection: {
              [toggleType]: true,
            },
          }
        : {
            merchantSpRevenueSharePercentage: revenueShare,
            shippingProtection: {
              [toggleType]: true,
              planId: values.planId,
              locationFilteringEnabled: values.locationFilteringEnabled === 'Enabled',
            },
          }
      await updateStore({
        storeId,
        version: 'latest',
        data: updateData,
      }).unwrap()

      onSaved()
      toastCompleted('Shipping Protection status has been updated.')
    } catch {
      toastError('Shipping Protection status could not be updated. Please try again later.')
    }
  }

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik<Values>({
    initialValues: {
      merchantSpRevenueSharePercentage: data?.merchantSpRevenueSharePercentage
        ? data.merchantSpRevenueSharePercentage * 100
        : null,
      planId: getPlanIdDefault(data?.shippingProtection?.planId),
      locationFilteringEnabled: getLocationFilteringDefault(
        data?.shippingProtection?.locationFilteringEnabled,
      ),
    },
    validationSchema: schema,
    onSubmit: handleSave,
  })

  const [updateStore, { isLoading: isProcessing }] = useUpdateStoreMutation()

  const handleCloseModal = useCallback(() => {
    dismissModal()
  }, [dismissModal])

  const handleSubmitClick = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  const hasRevenueShareInputError =
    Boolean(errors.merchantSpRevenueSharePercentage) && touched.merchantSpRevenueSharePercentage
  const hasPlanIdErrors = Boolean(errors.planId) && touched.planId
  const hasLocationFilteringEnabledErrors =
    Boolean(errors.locationFilteringEnabled) && touched.locationFilteringEnabled

  return (
    <Modal
      data-cy="shipping-protection-modal"
      heading={`${modalMode} Shipping Protection`}
      onDismissRequest={handleCloseModal}
      size={spPurchaseModel ? 'xl' : 'sm'}
      isLoading={isUninitialized || isFetching}
      secondaryButtonProps={{
        'data-cy': 'modal-cancel',
        onClick: handleCloseModal,
        text: 'Cancel',
      }}
      primaryButtonProps={{
        'data-cy': 'modal-submit',
        onClick: spPurchaseModel ? handleSave : handleSubmitClick,
        isProcessing,
        text: modalMode,
      }}
    >
      {spPurchaseModel ? (
        <ShippingProtection store={data || ({} as Store)} selectCategories={[]} />
      ) : (
        <Stack spacingY={2} data-cy="sp-form-stack">
          <div>
            {storeName && <Paragraph>{storeName}</Paragraph>}
            <CaptionLegal>Store ID: {storeId}</CaptionLegal>
          </div>
          <RevenueShareInput
            value={values.merchantSpRevenueSharePercentage}
            isError={hasRevenueShareInputError}
            errorFeedback={errors.merchantSpRevenueSharePercentage}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ShippingProtectionPlansDropdown
            onChange={handleChange}
            value={values.planId}
            isError={hasPlanIdErrors}
            errorFeedback={errors.planId}
            onBlur={handleBlur}
          />
          <LocationFilteringSelect
            value={values.locationFilteringEnabled}
            isError={hasLocationFilteringEnabledErrors}
            errorFeedback={errors.locationFilteringEnabled}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Stack>
      )}
    </Modal>
  )
}

export { ShippingProtectionModal }
