import type { FC } from 'react'
import React from 'react'
import { CurrencyInput, Grid, Input } from '@extend/zen'
import type { MappedStorePlanPricingToWholesalePricing } from '../../../../../../../../types/wholesale-pricing'

type WholesalePricingRowProps = {
  rowData: MappedStorePlanPricingToWholesalePricing
  isEditEnabled: boolean
  isLabelVisible?: boolean
  wholesalePrice?: number
  rowIndex: number
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}

const WholesalePricingRow: FC<WholesalePricingRowProps> = ({
  rowData,
  isEditEnabled,
  isLabelVisible,
  wholesalePrice,
  rowIndex,
  error,
  handleChange,
}) => {
  const { skuId, priceBandHigh, priceBandLow, premium, isWholesaleFieldDisabled } = rowData

  return (
    <Grid columns={5} spacing={4}>
      <Input
        id="skuId"
        value={skuId}
        isDisabled
        label={isLabelVisible ? 'SKU ID' : undefined}
        ariaLabel="SKU ID"
      />
      <CurrencyInput
        id="priceBandLow"
        currency="USD"
        value={priceBandLow?.toString() ?? ''}
        isDisabled
        label={isLabelVisible ? 'Price Band Low' : undefined}
        ariaLabel="Price Band Low"
      />
      <CurrencyInput
        id="priceBandHigh"
        currency="USD"
        value={priceBandHigh?.toString() ?? ''}
        isDisabled
        label={isLabelVisible ? 'Price Band High' : undefined}
        ariaLabel="Price Band High"
      />
      <CurrencyInput
        id="premium"
        currency="USD"
        value={premium?.toString() ?? ''}
        isDisabled
        label={isLabelVisible ? 'Premium' : undefined}
        ariaLabel="Premium"
      />
      <CurrencyInput
        currency="USD"
        data-cy={`wholesale-price-input-${rowIndex}`}
        id={`wholesalePrices[${rowIndex}]wholesalePrice`}
        value={wholesalePrice?.toString() ?? ''}
        isDisabled={!isEditEnabled || isWholesaleFieldDisabled}
        label={isLabelVisible ? 'Wholesale Price' : undefined}
        ariaLabel="Wholesale Price"
        onChange={handleChange}
        errorFeedback={error}
        isError={Boolean(error) && isEditEnabled}
      />
    </Grid>
  )
}

export { WholesalePricingRow }
