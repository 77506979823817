import type { FC } from 'react'
import React, { useCallback } from 'react'
import { Button, ButtonGroup, DisplayLarge, Stack } from '@extend/zen'
import { useHistory } from 'react-router-dom'
import { DataTable } from '../../../components/data-table'
import { useLazySearchSkuByIdQuery } from '@helloextend/extend-api-rtk-query'
import { skuTableColumns } from './premiums-table-config'
import type { Sku } from '@helloextend/extend-api-rtk-query'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'

const Premiums: FC = () => {
  const history = useHistory()

  const { hasPermission } = usePermissions()

  const canImportPremium = hasPermission(Permission.ManagePremiums)

  const [
    searchSkus,
    { isLoading: isSearchingSkus, isFetching: isFetchingSearchingSkus, data: skuSearchResult },
  ] = useLazySearchSkuByIdQuery()

  const handleLookup = useCallback(
    (_key: string, value: string) => {
      if (value) {
        searchSkus(value)
      }
    },
    [searchSkus],
  )

  const handleRowClick = (_e: React.SyntheticEvent, rowData: Sku): void => {
    history.push(`/admin/premiums/${rowData.id}`)
  }

  return (
    <>
      <Stack spacing={2}>
        <Stack isRow justify="space-between">
          <DisplayLarge>Premiums</DisplayLarge>
          {canImportPremium ? (
            <ButtonGroup>
              <Button
                text="Import"
                onClick={() => history.push('/admin/premiums/import')}
                emphasis="medium"
                data-cy="import-button"
              />
            </ButtonGroup>
          ) : null}
        </Stack>
        <DataTable
          data={skuSearchResult?.items ?? []}
          columns={skuTableColumns}
          searchMode="server"
          onSearch={handleLookup}
          isLoading={isSearchingSkus || isFetchingSearchingSkus}
          searchMessage="Search for premiums by SKU ID"
          rowClickEvent={handleRowClick}
        />
      </Stack>
    </>
  )
}

export { Premiums }
