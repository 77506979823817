import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { ArrowForward, Badge, COLOR, Icon, IconSize } from '@extend/zen'
import { useSelector } from 'react-redux'
import {
  getSelectedThread,
  getSingleUseThreadsMap,
  getConversationReusableThreads,
} from '../../../../../../reducers/selectors'
import type { RootState } from '../../../../../../reducers'

interface PromptWrapperProps {
  badgeValue: number | string
  hasBottomBorder?: boolean
  onBadgeClick: (val: number | string) => void
  promptIndex?: number
  threadId?: string
}

const AdjudicationPromptWrapper: FC<PromptWrapperProps> = ({
  children,
  badgeValue,
  hasBottomBorder,
  onBadgeClick,
  promptIndex,
  threadId,
}) => {
  const selectedThread = useSelector((state: RootState) => getSelectedThread(state))
  const singleUseThreads = useSelector((state: RootState) => getSingleUseThreadsMap(state))
  const reusableThreads = useSelector((state: RootState) => getConversationReusableThreads(state))
  const isEmptyPrompt = badgeValue === '-'
  const badgeEmphasis = typeof badgeValue === 'number' || isEmptyPrompt ? 'medium' : 'high'

  const badgeText = useMemo(() => {
    // if badge is not a number we don't have a target index to lookup: return the string to display
    if (typeof badgeValue !== 'number') return badgeValue

    // find the thread for looking up UI Index value
    let lookupThread = selectedThread?.id === threadId ? selectedThread : null
    if (!lookupThread && threadId) {
      lookupThread = singleUseThreads[threadId] ?? null
    }
    if (!lookupThread && threadId) {
      lookupThread = reusableThreads[threadId]
    }

    if (!lookupThread) return String(badgeValue)

    return String(badgeValue)
  }, [selectedThread, singleUseThreads, reusableThreads, badgeValue, threadId])

  const onClick = (): void => {
    if (!isEmptyPrompt) {
      onBadgeClick(badgeValue)
    }
  }

  return (
    <PromptWrapper
      hasBottomBorder={hasBottomBorder}
      data-cy={`adjudication-management-prompt-wrapper-${promptIndex || 0}`}
    >
      {children}
      <ArrowBadgeWrapper>
        <Icon
          icon={ArrowForward}
          size={IconSize.xsmall}
          color={COLOR.NEUTRAL[300]}
          data-cy="publish-modal:arrow-icon"
        />
        <Badge
          data-cy="adjudication-management-execute-badge"
          color="neutral"
          emphasis={badgeEmphasis}
          size="small"
          text={badgeText}
          onClick={onClick}
        />
      </ArrowBadgeWrapper>
    </PromptWrapper>
  )
}

const PromptWrapper = styled.div<{ hasBottomBorder?: boolean }>(({ hasBottomBorder }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    borderBottom: hasBottomBorder ? `1px solid ${COLOR.NEUTRAL[300]}` : undefined,
    ':last-child': hasBottomBorder ? { borderBottom: 0 } : undefined,
  }
})

const ArrowBadgeWrapper = PromptWrapper

export type { PromptWrapperProps }
export { AdjudicationPromptWrapper }
