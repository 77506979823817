import type { ParseResult } from 'papaparse'
import Papa from 'papaparse'
import { pipe } from 'lodash/fp'
import type { PlanCategory } from '@helloextend/extend-api-client'
import type {
  HeaderIndexMap,
  CsvImportParseResults,
} from '../merchants/stores/utils/csv-validation-models'
import {
  validateHeader,
  validateFields,
  validateMappingStatus,
  validateToDelete,
  validateExtendCategory,
} from './validators'
import { getCsvHeaderIndexes } from '../merchants/stores/utils/get-header-indexes'
import type { CategoryMappingsHeaderCSV } from './types'
import { formatPipeErrorOrWarningMessage } from '../merchants/stores/utils/shared-csv-validation'

const INVALID_HEADERS = 'The CSV headers in the uploaded file should match the sample file provided'
const DUPLICATE_MERCHANT_CATEGORY = 'The merchant category in the file must be unique'

export async function parseCategoryMappingsCsv(
  file: File,
  planCateogies: PlanCategory[] = [],
): Promise<CsvImportParseResults> {
  const parseResults: CsvImportParseResults = {
    data: [],
    errors: [],
  }

  const merchantCategorySet = new Set()
  let isHeaderScanned = false
  let headerIndexes: HeaderIndexMap<CategoryMappingsHeaderCSV>
  let currRowStep = 0 // for row #

  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      step: (result: ParseResult<string>, parser): void => {
        currRowStep += 1 // track current row being scanned in step fn
        if (!isHeaderScanned) {
          const headers = result.data
          isHeaderScanned = true
          if (!validateHeader(headers)) {
            parseResults.errors.push(INVALID_HEADERS)
            resolve(parseResults)
            parser.abort()
          }

          headerIndexes = getCsvHeaderIndexes(headers)
        } else {
          const validationResults = pipe(
            validateFields,
            validateMappingStatus,
            validateToDelete,
            (meta) => validateExtendCategory(meta, planCateogies),
          )({ errors: [], headerIndexes, rowData: result.data })

          // Ensure that merchant category is unique
          const merchanCateoryIndex = headerIndexes.merchantCategory
          const merchantCategoryVal = result.data[merchanCateoryIndex]
          if (merchantCategorySet.has(merchantCategoryVal)) {
            validationResults.errors.push(`${DUPLICATE_MERCHANT_CATEGORY} (${merchantCategoryVal})`)
          }
          merchantCategorySet.add(merchantCategoryVal)
          if (validationResults.errors.length) {
            const errorMessage = formatPipeErrorOrWarningMessage(
              currRowStep,
              validationResults.errors,
            )
            parseResults.errors.push(errorMessage)
          } else {
            parseResults.data.push(result.data)
          }
        }
      },
      complete: (): void => {
        resolve(parseResults)
      },
      error(err): void {
        reject(err)
      },
    })
  })
}
