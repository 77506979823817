import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@extend/zen'
import { AlertText, AlertContainer } from './styles'

interface InfoMessageProps {
  message: string
  hasIcon?: boolean
}

const InfoMessage: FC<InfoMessageProps> = ({ message }) => (
  <AlertContainer backgroundColor={COLOR.BLUE[100]}>
    <AlertText title={message} color={COLOR.BLUE[800]}>
      {message}
    </AlertText>
  </AlertContainer>
)

export { InfoMessage }
