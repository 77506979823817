import React from 'react'
import type { FC } from 'react'
import { ToastColor, ToastDuration, useToaster, Modal } from '@extend/zen'
import { useCreateCharityMutation } from '@helloextend/extend-api-rtk-query'
import { CharitableDonationsForm } from '../forms/charitable-donations-form'
import type { CharitySchema } from '../forms/charitable-donations-form-schema'

type AddCharityModalProps = {
  onClose: () => void
}

export const AddCharityModal: FC<AddCharityModalProps> = ({ onClose }) => {
  const { toast } = useToaster()
  const [createCharity, { isLoading }] = useCreateCharityMutation()
  const handleCreateNewCharity = async (formValues: CharitySchema): Promise<void> => {
    try {
      await createCharity({
        data: formValues,
      }).unwrap()
      toast({
        message: 'Charity has been successfully created',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    } catch (error) {
      toast({
        message: 'Error creating charity. Please try again.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    } finally {
      onClose()
    }
  }
  return (
    <Modal data-cy="add-charity-modal" heading="Add Charity" onDismissRequest={onClose} size="md">
      <CharitableDonationsForm
        isLoading={isLoading}
        onSubmit={handleCreateNewCharity}
        onClose={onClose}
      />
    </Modal>
  )
}
