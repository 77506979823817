import { currency } from '@extend/client-helpers'
import type { ServiceOrder, Claim, Contract } from '@helloextend/extend-api-client'
import type { PackingSlipConfiguration } from '@helloextend/extend-api-client/src/models/servicer'

const generateSchema = (
  serviceOrder?: ServiceOrder,
  claim?: Claim,
  contract?: Contract,
): Array<{
  key: keyof PackingSlipConfiguration | 'instructions'
  label: string
  value: string
}> => {
  const product = contract?.product ?? (contract?.productsList ? contract.productsList[0] : null)

  const schema: Array<{
    key: keyof PackingSlipConfiguration | 'instructions'
    label: string
    value: string
  }> = [
    { key: 'contractId', label: 'Contract ID', value: serviceOrder?.contractId ?? '' },
    { key: 'claimId', label: 'Claim ID', value: serviceOrder?.claimId ?? '' },
    { key: 'serviceOrderId', label: 'Service Order ID', value: serviceOrder?.id ?? '' },
    { key: 'orderNumber', label: 'Order Number', value: contract?.transactionId ?? '' },
    { key: 'customerName', label: 'Customer Name', value: claim?.customer.name ?? '' },
    { key: 'productName', label: 'Product Name', value: product?.title ?? '' },
    {
      key: 'productReferenceId',
      label: 'Product Reference ID',
      value: product?.referenceId ?? '',
    },
    {
      key: 'productListPrice',
      label: 'Product List Price',
      value: product?.listPrice ? currency.format(product?.listPrice) : '',
    },
    {
      key: 'productPurchasePrice',
      label: 'Product Purchase Price',
      value: product?.purchasePrice ? currency.format(product?.purchasePrice) : '',
    },
    { key: 'failureType', label: 'Failure Type', value: claim?.incident.failureType ?? '' },
    {
      key: 'failureDescription',
      label: 'Failure Description',
      value: claim?.incident.description ?? '',
    },
  ]

  if (serviceOrder?.attachments?.packingSlip?.instructions) {
    schema.push({
      key: 'instructions',
      label: 'Instructions',
      value: serviceOrder?.attachments?.packingSlip.instructions,
    })
  }

  return schema
}

export { generateSchema }
