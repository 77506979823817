import type { FC, SyntheticEvent } from 'react'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import type { DateValue } from '@extend/zen'
import {
  COLOR,
  DataProperty,
  DataPropertyType,
  Icon,
  IconSize,
  Label,
  OpenInNew,
} from '@extend/zen'
import { startCase } from 'lodash'
import { keyframes } from '@emotion/react'
import type {
  ChangeFunction,
  CurrencyInputItem,
  HyperlinkInputItem,
  InputItem as InputItemType,
  SetFieldFunction,
  StringInputItem,
} from './types'
import { TextAreaItem } from './text-area-item'
import { InputItem } from './input-item'
import { CurrencyItem } from './currency-item'
import { CheckboxItem } from './checkbox-item'
import { DatePickerItem } from './date-picker-item'
import { Dropdown } from '../dropdown'
import type { DropdownItem } from './form-text-group'

type FormTextGroupItemProps = {
  handleChange: ChangeFunction & SetFieldFunction
  handleBlur: (e: SyntheticEvent) => void
  setFieldValue?: (field: string, value: string | boolean, shouldValidate?: boolean) => void
  isDisabled: boolean
  isStatic: boolean
  numColumns: number
  item: InputItemType
  margin: number
  autoFocus?: boolean
}

const FormTextGroupItem: FC<FormTextGroupItemProps> = ({
  handleChange,
  handleBlur,
  setFieldValue,
  isDisabled,
  isStatic,
  numColumns,
  item,
  margin,
  autoFocus,
}) => {
  const fieldType = item.fieldType ?? 'input'

  const { name, value } = item as StringInputItem

  const handleCheckboxChange = useCallback(() => {
    const isTrue = value === 'true'
    if (setFieldValue) setFieldValue(name, !isTrue)
  }, [name, value, setFieldValue])

  const handleDateChange = useCallback(
    (date: DateValue) => {
      const newDate = date || new Date()
      const dateValue = newDate.toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
      if (setFieldValue) setFieldValue(name, dateValue.replace(',', ''))
    },
    [setFieldValue, name],
  )

  return (
    <InputWrapper
      key={item.name}
      width={item.columnCount ? (100 / numColumns) * item.columnCount : 100 / numColumns}
      block={item.block ?? false}
      margin={margin}
    >
      {!isStatic && fieldType === 'date' && (
        <DatePickerItem handleChange={handleDateChange} item={item as StringInputItem} />
      )}
      {!isStatic && fieldType === 'checkbox' && (
        <CheckboxItem
          handleChange={handleCheckboxChange}
          isDisabled={isDisabled}
          item={item as StringInputItem}
        />
      )}
      {!isStatic && fieldType === 'textarea' && (
        <TextAreaItem
          handleChange={handleChange}
          handleBlur={handleBlur}
          isDisabled={isDisabled}
          item={item as StringInputItem}
        />
      )}
      {!isStatic && fieldType === 'input' && (
        <InputItem
          handleChange={handleChange}
          handleBlur={handleBlur}
          isDisabled={isDisabled}
          item={item as StringInputItem}
          autoFocus={autoFocus}
        />
      )}

      {!isStatic && fieldType === 'dropdown' && (
        <DropdownWrapper>
          <Dropdown
            name={item.name}
            label={item.label}
            value={(item as DropdownItem).value}
            dataCy="dropdown-input"
            options={(item as DropdownItem).options}
            onChange={handleChange}
            disabled={item.isItemDisabled ?? isDisabled}
            errorMessage={item.error}
            isLoading={(item as DropdownItem).isLoading}
            labelFontWeight={800}
          />
        </DropdownWrapper>
      )}
      {fieldType === 'hyperlink' && (
        <>
          <Label htmlFor={name} isMuted>
            {item.label}
          </Label>
          <StyledLink
            href={(item as HyperlinkInputItem).hyperlinkProps.hyperlinkUrl}
            target="_blank"
          >
            <Value data-cy={item.name}>{(item as StringInputItem).value}</Value>
            <Icon icon={OpenInNew} size={IconSize.xsmall} color={COLOR.BLUE[700]} />
          </StyledLink>
        </>
      )}
      {!isStatic && fieldType === 'currency' && (
        <CurrencyItem
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          isDisabled={isDisabled}
          item={item as CurrencyInputItem}
          autoFocus={autoFocus}
        />
      )}
      {isStatic && fieldType === 'currency' && (
        <DataProperty
          type={DataPropertyType.currency}
          value={(item as CurrencyInputItem).value}
          label={item.label}
          data-cy={item.name}
          currencyCode={(item as CurrencyInputItem).currencyProps.codeValue}
        />
      )}
      {isStatic && fieldType !== 'currency' && fieldType !== 'hyperlink' && (
        <DataProperty
          value={
            item.name !== 'limitOfLiabilityAmountType'
              ? (item as StringInputItem).value
              : startCase((item as StringInputItem).value)
          }
          label={item.label}
          data-cy={item.name}
          includeCopyAction={(item as StringInputItem).includeCopyAction}
        />
      )}
    </InputWrapper>
  )
}

const InputWrapper = styled.div<{ width: number; block: boolean; margin: number }>(
  ({ width, block, margin }) => ({
    width: `calc(${width}% - ${margin}px)`, // subtract margin to avoid row wrapping
    marginRight: block ? `${100 - width}%` : margin,
    '& textarea': {
      border: `1px solid ${COLOR.NEUTRAL[300]}`,
    },
  }),
)

const DropdownWrapper = styled.div({
  label: {
    paddingBottom: 6,
    paddingTop: 2,
  },
  button: {
    height: 38,
  },
})

const StyledLink = styled.a({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const select = keyframes({
  to: {
    userSelect: 'text',
  },
})

const Value = styled.span<{ hasSelectAll?: boolean }>(({ hasSelectAll }) => ({
  lineHeight: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...(hasSelectAll && {
    userSelect: 'all',
    '&:focus': {
      animation: `${select} 100ms step-end forwards`,
    },
  }),
}))

export type { FormTextGroupItemProps }
export { FormTextGroupItem }
