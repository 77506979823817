import type { FC } from 'react'
import React from 'react'
import { CurrencyInput, Grid, Input, InputType } from '@extend/zen'
import type { FormikErrors, FormikHandlers, FormikValues } from 'formik'
import { getFormikError } from '../../../../../../../../../utils/form-utils'
import { ShippingProtectionPlansDropdown } from '../../shipping-protection-plans-dropdown/shipping-protection-plans-dropdown'
import type { SPPlanPriceBandMappingFormValues } from '../../schema'

type SpPriceBandRowProps = {
  index: number
  data: SPPlanPriceBandMappingFormValues
  errors: FormikErrors<FormikValues>
  isLabelDisplayed?: boolean
  handleOnChange: FormikHandlers['handleChange']
}

const SpPriceBandRow: FC<SpPriceBandRowProps> = ({
  index,
  data,
  errors,
  isLabelDisplayed,
  handleOnChange,
}) => {
  const priceBandsFormikId = `priceBands[${index}]`
  return (
    <>
      <Grid
        columns={
          data?.pbPartialReimbursementEnabled && data?.pbPartialReimbursementSelection ? 6 : 5
        }
        spacing={4}
      >
        <CurrencyInput
          data-cy={`price-band-low-${index}`}
          value={data?.low?.toString() || ''}
          id={`${priceBandsFormikId}low`}
          onChange={handleOnChange}
          currency="USD"
          label={isLabelDisplayed ? 'Price Band Low' : undefined}
          isError={Boolean(getFormikError(errors, `${priceBandsFormikId}low`))}
          errorFeedback={getFormikError(errors, `${priceBandsFormikId}low`)}
        />
        <CurrencyInput
          data-cy={`price-band-high-${index}`}
          value={data.high?.toString() || ''}
          id={`${priceBandsFormikId}high`}
          onChange={handleOnChange}
          currency="USD"
          label={isLabelDisplayed ? 'Price Band High' : undefined}
          isError={Boolean(getFormikError(errors, `${priceBandsFormikId}high`))}
          errorFeedback={getFormikError(errors, `${priceBandsFormikId}high`)}
        />
        <ShippingProtectionPlansDropdown
          dataCy={`sp-plan-${index}`}
          value={data?.planId || ''}
          id={`${priceBandsFormikId}planId`}
          onChange={handleOnChange}
          label={isLabelDisplayed ? 'Shipping Protection Plan' : ''}
          isError={Boolean(getFormikError(errors, `${priceBandsFormikId}planId`))}
          errorFeedback={getFormikError(errors, `${priceBandsFormikId}planId`)}
          isPlanDetailsDisplayed
        />
        <Input
          type={InputType.number}
          id={`${priceBandsFormikId}revenueShare`}
          suffix="%"
          data-cy={`revenue-share-per-shipment-${index}`}
          onChange={handleOnChange}
          value={data?.revenueShare?.toString() || ''}
          label={isLabelDisplayed ? 'Revenue Share Per Shipment' : undefined}
          isError={Boolean(getFormikError(errors, `${priceBandsFormikId}revenueShare`))}
          errorFeedback={getFormikError(errors, `${priceBandsFormikId}revenueShare`)}
          placeholder="Enter percentage"
        />
        <CurrencyInput
          data-cy={`price-band-fixed-premium-${index}`}
          value={data?.fixedPremium?.toString() || ''}
          id={`${priceBandsFormikId}fixedPremium`}
          onChange={handleOnChange}
          currency="USD"
          label={isLabelDisplayed ? 'Fixed Premium' : undefined}
          isError={Boolean(getFormikError(errors, `${priceBandsFormikId}fixedPremium`))}
          errorFeedback={getFormikError(errors, `${priceBandsFormikId}fixedPremium`)}
        />
        {data?.pbPartialReimbursementEnabled &&
          data.pbPartialReimbursementSelection === 'percentage' && (
            <Input
              type={InputType.number}
              id={`${priceBandsFormikId}partialReimbursement`}
              suffix="%"
              data-cy={`partial-reimbursement-pb-${index}`}
              value={data?.partialReimbursement?.toString() || ''}
              label={isLabelDisplayed ? 'Partial Reimbursement Percentage' : undefined}
              isError={Boolean(getFormikError(errors, `${priceBandsFormikId}partialReimbursement`))}
              errorFeedback={getFormikError(errors, `${priceBandsFormikId}partialReimbursement`)}
              onChange={handleOnChange}
              placeholder="Enter percentage"
            />
          )}
        {data?.pbPartialReimbursementEnabled &&
          data.pbPartialReimbursementSelection === 'fixed' && (
            <Input
              type={InputType.number}
              id={`${priceBandsFormikId}partialReimbursementFixed`}
              suffix=""
              data-cy={`partial-reimbursement-fixed-pb-${index}`}
              value={data?.partialReimbursementFixed?.toString() || ''}
              label={isLabelDisplayed ? 'Partial Reimbursement Fixed' : undefined}
              isError={Boolean(
                getFormikError(errors, `${priceBandsFormikId}partialReimbursementFixed`),
              )}
              errorFeedback={getFormikError(
                errors,
                `${priceBandsFormikId}partialReimbursementFixed`,
              )}
              onChange={handleOnChange}
              placeholder="Enter Fixed Value"
            />
          )}
      </Grid>
    </>
  )
}

export { SpPriceBandRow }
