import { isEqual } from 'lodash/fp'
import type { PlanCategory } from '@helloextend/extend-api-client'
import { CategoryMappingStatus } from '@helloextend/extend-api-client'
import { CategoryMappingsHeaderCsv } from '../types'
import type { CategoryMappingValidatorPipe } from '../types'

const mappingStatusMap: Map<string, CategoryMappingStatus> = new Map([
  ['Unmapped', CategoryMappingStatus.UNMAPPED],
  ['Warrantable', CategoryMappingStatus.WARRANTABLE],
  ['Non-Warrantable', CategoryMappingStatus.NON_WARRANTABLE],
])
export const validateHeader = (headers: string[]): boolean => {
  return isEqual(headers, CategoryMappingsHeaderCsv)
}

export const validateMappingStatus = (
  meta: CategoryMappingValidatorPipe,
): CategoryMappingValidatorPipe => {
  const { mappingStatus, extendCategory, merchantCategory } = meta.headerIndexes

  const mappingStatusVal = meta.rowData[mappingStatus]
  const extendCategoryVal = meta.rowData[extendCategory]
  const merchantCategoryVal = meta.rowData[merchantCategory]
  if (merchantCategoryVal && extendCategoryVal) {
    const status = mappingStatusMap.get(mappingStatusVal)
    if (!status || status === CategoryMappingStatus.UNMAPPED) {
      meta.errors.push('Mapping Status must be either Warrantable or Non-Warrantable')
    }
  }
  return meta
}

export const validateFields = (
  meta: CategoryMappingValidatorPipe,
): CategoryMappingValidatorPipe => {
  const { mappingStatus, merchantCategory } = meta.headerIndexes
  const mappingStatusVal = meta.rowData[mappingStatus]
  const merchantCategoryVal = meta.rowData[merchantCategory]
  if (!mappingStatusVal || !merchantCategoryVal) {
    meta.errors.push('Merchant Category and mapping status are required fields')
  }
  return meta
}

export const validateToDelete = (
  meta: CategoryMappingValidatorPipe,
): CategoryMappingValidatorPipe => {
  const { toDelete } = meta.headerIndexes
  const toDeleteVal = meta.rowData[toDelete]
  if (toDeleteVal === undefined) {
    meta.errors.push('toDelete column is missing')
  } else if (
    toDeleteVal &&
    toDeleteVal.toLowerCase() !== 'true' &&
    toDeleteVal.toLowerCase() !== 'false'
  ) {
    meta.errors.push(
      `toDelete must be one of three values: ['TRUE', 'FALSE', '' (blank)] needs review (${toDeleteVal})`,
    )
  }
  return meta
}

export const validateExtendCategory = (
  meta: CategoryMappingValidatorPipe,
  planCategories: PlanCategory[],
): CategoryMappingValidatorPipe => {
  const { extendCategory } = meta.headerIndexes
  const extendCategoryVal = meta.rowData[extendCategory]
  if (extendCategoryVal.length && !planCategories.find(({ name }) => name === extendCategoryVal)) {
    meta.errors.push(`Invalid Extend Category: ${extendCategoryVal}`)
  }
  return meta
}
