import type { SyntheticEvent } from 'react'
import { COLOR } from '@extend/zen'
import React from 'react'
import * as Yup from 'yup'
import type {
  Claim,
  Contract,
  Plan,
  ServiceOrder,
  ServiceType,
} from '@helloextend/extend-api-client'
import { FailureType } from '@helloextend/extend-api-client'
import styled from '@emotion/styled'
import {
  formatDate,
  formatStartCase,
  formatClaimType,
  isShippingProtectionType,
  formatCurrency,
} from '../../../util'
import type {
  CollapsibleInfoGroupProps,
  CustomComponentProps,
  FieldMapperConfig,
} from '../../../../../../components/collapsible-info-group'
import { fieldMapper } from '../../../../../../components/collapsible-info-group'
import { Select, SelectItem } from '../../../../../../components/select'
import type { PlanTermsVersionLanguage } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'

interface ClaimInfoValues {
  claim?: Claim
  contract?: Contract
  type?: string
  serviceOrder?: ServiceOrder
  plan?: Plan
}

const claimServiceTypeMap: Record<ServiceType, string> = {
  repair: 'Repair',
  replace: 'Replacement',
  repair_depot: 'Depot Repair',
  repair_home: 'Home Repair',
  repair_irobot: 'iRobot Repair',
  repair_onsite: 'Onsite Repair',
}

const failureTypes = Object.keys(FailureType)

const schema = Yup.object()
  .shape({
    failureType: Yup.mixed<FailureType>().default(FailureType.unanswered).required('Required'),
  })
  .defined()
type Values = Yup.InferType<typeof schema>

const getClaimInfo = <S extends Record<string, any>>(
  isEditing: boolean,
  claim: Claim,
  contract: Contract,
  serviceOrder?: ServiceOrder,
  plan?: Plan,
  formValues?: { [key: string]: any },
  planTerms?: PlanTermsVersionLanguage,
): CollapsibleInfoGroupProps['data'] => {
  const fields = [
    { key: 'type', label: 'Claim Type', transformFn: formatClaimType },
    { key: 'claim.reportedAt', label: 'Reported At Date', transformFn: formatDate },
    {
      key: 'claim.incident',
      label: 'Incident Date',
      transformFn: (incident: Claim['incident']) =>
        incident.IncidentDate
          ? formatDate(incident.IncidentDate as number)
          : formatDate(incident.occurredAt),
    },
    {
      key: 'contract.coverage',
      label: 'Coverage',
      isHidden: isShippingProtectionType(claim.type),
      isCustomComponent: true,
      CustomComponent: () => {
        return (
          <CoverageContainer>
            <CoverageHeader>Coverage</CoverageHeader>
            <CoverageRangeSpan data-cy="coverage-range">
              {!contract.coverage
                ? ''
                : `${formatDate(contract.coverage.starts)} - ${formatDate(contract.coverage.ends)}`}
            </CoverageRangeSpan>
            {planTerms?.url && plan?.name ? (
              <PlanLink href={planTerms.url} data-cy="plan-url">
                {plan.name}
              </PlanLink>
            ) : (
              <CoverageRangeSpan data-cy="plan-url-placeholder">—</CoverageRangeSpan>
            )}
          </CoverageContainer>
        )
      },
    },
    { key: 'contract.transactionDate', label: 'Transaction Date', transformFn: formatDate },
    { key: 'contract.transactionId', label: 'Transaction ID' },
    { key: 'contract.poNumber', label: 'PO Number' },
    { key: 'contract.sellerName', label: 'Store' },
    {
      key: 'claim.incident',
      formKey: 'failureType',
      label: 'Failure Type',
      transformFn: (incident: Claim['incident']) =>
        // use camel case 'failureType' as source of truth for rendering
        incident.failureType
          ? formatStartCase(incident.failureType)
          : formatStartCase(incident.FailureType as string),
      editable: isEditing,
      CustomComponent: ({ onChange: setFieldValue, value: formValue }: CustomComponentProps) => (
        <Select
          label="Failure Type"
          onChange={(e: SyntheticEvent<Element>): void => {
            const { value } = e.currentTarget as HTMLInputElement
            setFieldValue('failureType', value)
          }}
          value={formValue}
        >
          {failureTypes.map((type) => (
            <SelectItem
              key={type}
              value={type}
              label={formatStartCase(type)}
              data-cy={`select-item-${type}`}
            />
          ))}
        </Select>
      ),
    },
    {
      key: 'claim.serviceType',
      label: 'Service Type',
      transformFn: (type: ServiceType) => claimServiceTypeMap[type],
    },
    // TODO: [DEPOT-2821] allow grid-item to span multiple columns (zen)
    {
      key: 'claim.incident',
      label: 'Incident Description',
      transformFn: (incident: Claim['incident']) =>
        (incident.FailureDescription || incident.description || '').toString(),
    },
    {
      key: 'plan.repair_threshold',
      label: 'Repair Threshold',
      transformFn: (amount?: number) => (amount ? formatCurrency({ amount }) : 'N/A'),
    },
  ]
  return [
    {
      values: fieldMapper<ClaimInfoValues, S>(
        { claim, contract, serviceOrder, plan, type: claim.type },
        fields as Array<FieldMapperConfig<ClaimInfoValues>>,
        formValues,
      ),
    },
  ]
}

const CoverageContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})

const CoverageHeader = styled.span({
  color: COLOR.NEUTRAL[600],
  fontSize: 14,
  cursor: 'auto',
  lineHeight: '22px',
  fontWeight: 700,
})

const PlanLink = styled.a({
  lineHeight: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const CoverageRangeSpan = styled.span({
  lineHeight: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export { getClaimInfo, schema }
export type { Values }
