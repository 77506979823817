import type { FC } from 'react'
import React, { useEffect } from 'react'
import { Grid, Input } from '@extend/zen'
import { useFormik } from 'formik'
import type { Store } from '@helloextend/extend-api-client'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { Values } from './schema'
import { schema } from './schema'
import { SaveChangesButtonGroup } from '../save-changes-button-group'

type ContactFormProps = {
  store: Store
}

const ContactForm: FC<ContactFormProps> = ({ store }) => {
  const [updateStore, { isError, isSuccess, isLoading }] = useUpdateStoreMutation()
  const { toastCompleted, toastError } = useStandardToast()

  const { values, errors, dirty, isValid, handleChange, handleBlur, handleReset } =
    useFormik<Values>({
      initialValues: {
        supportNumber: store?.supportContact?.phoneNumber?.number || '',
        supportEmail: store?.supportContact?.email || '',
        supportURL: store?.supportContact?.url || '',
        supportHours: store?.supportContact?.hours || '',
        escalationName: store?.escalationContact?.contactName || '',
        escalationEmail: store?.escalationContact?.email || '',
        escalationNumber: store?.escalationContact?.phoneNumber?.number || '',
        escalationExtension: store?.escalationContact?.phoneNumber?.extension || '',
      },
      onSubmit: () => {},
      validationSchema: schema,
      enableReinitialize: true,
    })

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Contact settings have been successfully updated.')
    }
  }, [isSuccess, toastCompleted])

  useEffect(() => {
    if (isError) {
      toastError('Contact settings could not be updated. Please try again.')
    }
  }, [isError, toastError])

  const handleUpdateStore = (): void => {
    updateStore({
      storeId: store.id,
      data: {
        supportContact: {
          phoneNumber: {
            number: values.supportNumber || '',
          },
          email: values.supportEmail,
          url: values.supportURL,
          hours: values.supportHours,
        },
        escalationContact: {
          phoneNumber: {
            number: values.escalationNumber || '',
            extension: values.escalationExtension,
          },
          email: values.escalationEmail,
          contactName: values.escalationName,
        },
      },
      version: 'latest',
    })
  }

  if (!store) return null

  return (
    <>
      <FormSubheader>Customer Support</FormSubheader>
      <MarginBottom>
        <Grid columns={4} spacing={4}>
          <Input
            label="Phone Number"
            id="supportNumber"
            value={values.supportNumber || ''}
            isError={Boolean(errors.supportNumber)}
            errorFeedback={errors.supportNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter Phone Number"
            data-cy="phone-number"
          />
          <Input
            label="Email"
            id="supportEmail"
            value={values.supportEmail || ''}
            isError={Boolean(errors.supportEmail)}
            errorFeedback={errors.supportEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter Email"
            data-cy="email"
          />
          <Input
            label="URL"
            id="supportURL"
            value={values.supportURL || ''}
            isError={Boolean(errors.supportURL)}
            errorFeedback={errors.supportURL}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter URL"
            data-cy="url"
          />
          <Input
            label="Hours"
            id="supportHours"
            value={values.supportHours || ''}
            isError={Boolean(errors.supportHours)}
            errorFeedback={errors.supportHours}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter Hours"
            data-cy="hours"
          />
        </Grid>
      </MarginBottom>
      <FormSubheader>Escalation Contact</FormSubheader>
      <MarginBottom>
        <Grid columns={4} spacing={4}>
          <Input
            label="Escalation Name"
            id="escalationName"
            value={values.escalationName || ''}
            isError={Boolean(errors.escalationName)}
            errorFeedback={errors.escalationName}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter Escalation Name"
            data-cy="escalation-name"
          />
          <Input
            label="Email"
            id="escalationEmail"
            value={values.escalationEmail || ''}
            isError={Boolean(errors.escalationEmail)}
            errorFeedback={errors.escalationEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter Email"
            data-cy="escalation-email"
          />
          <Input
            label="Phone Number"
            id="escalationNumber"
            value={values.escalationNumber || ''}
            isError={Boolean(errors.escalationNumber)}
            errorFeedback={errors.escalationNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter Phone Number"
            data-cy="escalation-phone-number"
          />
          <Input
            label="Ext"
            id="escalationExtension"
            value={values.escalationExtension || ''}
            isError={Boolean(errors.escalationExtension)}
            errorFeedback={errors.escalationExtension}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter Ext"
            data-cy="escalation-ext"
          />
        </Grid>
      </MarginBottom>
      {dirty && (
        <MarginBottom>
          <SaveChangesButtonGroup
            id="contact-form"
            isSaveDisabled={!dirty || !isValid}
            isLoading={isLoading}
            handleSave={handleUpdateStore}
            handleCancel={handleReset}
            saveButtonText="Save Changes"
          />
        </MarginBottom>
      )}
    </>
  )
}

const FormSubheader = styled.h3({
  fontWeight: 700,
  fontSize: 14,
  marginBottom: 24,
  textTransform: 'uppercase',
})

const MarginBottom = styled.div({
  marginBottom: 24,
})

export { ContactForm }
