import type { WarrantyStatus } from '@helloextend/extend-api-client'
import type { BadgeProps } from '@extend/zen'

interface ProductBadgeProps extends Pick<BadgeProps, 'color' | 'text'> {}
export type ProductWarrantyStatus = Exclude<WarrantyStatus, 'review_required'>

type ProductStatusTooltipOption = {
  title: string
  description: string
  color: BadgeProps['color']
}

export const PRODUCT_WARRANTY_STATUS: Record<ProductWarrantyStatus, string> = {
  pending: 'Pending',
  warrantable: 'Matched',
  'non-warrantable': 'Non-warrantable',
}

const PRODUCT_WARRANTY_COLOR: Record<ProductWarrantyStatus, BadgeProps['color']> = {
  pending: 'yellow',
  warrantable: 'green',
  'non-warrantable': 'neutral',
}

const WARRANTY_STATUS_MAP_DESCRIPTION: Record<WarrantyStatus, string> = {
  pending:
    'The product does not yet have an associated Extend Warranty Plan that can be sold in the webstore.',
  warrantable: 'An Extend Warranty Plan can be sold for this product.',
  'non-warrantable': 'An Extend Warranty Plan cannot be sold for this product.',
  review_required:
    'The product does not yet have an associated Extend Warranty Plan that can be sold in the webstore.',
}

export const getProductBadgeProps = (warrantyStatus: ProductWarrantyStatus): ProductBadgeProps => {
  return {
    text: PRODUCT_WARRANTY_STATUS[warrantyStatus],
    color: PRODUCT_WARRANTY_COLOR[warrantyStatus],
  }
}

export const getProductStatusTooltipOptions = (): ProductStatusTooltipOption[] => [
  {
    title: PRODUCT_WARRANTY_STATUS.warrantable,
    description: WARRANTY_STATUS_MAP_DESCRIPTION.warrantable,
    color: PRODUCT_WARRANTY_COLOR.warrantable,
  },
  {
    title: PRODUCT_WARRANTY_STATUS.pending,
    description: WARRANTY_STATUS_MAP_DESCRIPTION.pending,
    color: PRODUCT_WARRANTY_COLOR.pending,
  },
  {
    title: PRODUCT_WARRANTY_STATUS['non-warrantable'],
    description: WARRANTY_STATUS_MAP_DESCRIPTION['non-warrantable'],
    color: PRODUCT_WARRANTY_COLOR['non-warrantable'],
  },
]
