import type { FC, ChangeEvent, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Information } from '@extend/zen'
import type { CurrencyCode } from '@helloextend/extend-api-client'
import { CurrencyInput } from '../currency-input'
import type { CurrencyInputAlignment } from '../currency-input/currency-input'

interface CurrencyProps {
  codeFieldName: string
  codeValue: CurrencyCode
}

interface CurrencyInputGroupProps {
  autoComplete?: string
  errorMessage?: string | null | false
  isDisabled?: boolean
  isCurrencyCodeDisabled?: boolean
  isRequired?: boolean
  label?: string
  name: string
  onBlur?: (e: SyntheticEvent<any> & ChangeEvent<any>) => void
  onChange?: (value: number | string | undefined, fieldName: string) => void
  invalid?: boolean
  type?: string
  value?: number
  showSymbol?: boolean
  placeholder?: string
  currencyProps: CurrencyProps
  isCurrencyCodeDisplayed?: boolean
  isGroup?: boolean
  alignment?: CurrencyInputAlignment
  'data-cy'?: string
  helperText?: string
  isZeroAllowed?: boolean
}

const CurrencyInputGroup: FC<CurrencyInputGroupProps> = ({
  errorMessage = '',
  isDisabled = false,
  isCurrencyCodeDisabled = false,
  label,
  name,
  value,
  showSymbol = true,
  invalid,
  placeholder,
  onChange,
  onBlur,
  currencyProps,
  isCurrencyCodeDisplayed = true,
  isGroup = true,
  alignment,
  'data-cy': dataCy,
  helperText,
  isZeroAllowed,
}) => (
  <FormGroup data-cy={dataCy}>
    {(!!label || !!helperText) && (
      <FieldHeader>
        {label && <Label htmlFor={name}>{label}</Label>}
        {helperText && (
          <InformationWrapper>
            <Information buttonSize="xsmall">{helperText}</Information>
          </InformationWrapper>
        )}
      </FieldHeader>
    )}
    <CurrencyGroupWrapper>
      <CurrencyInput
        disabled={isDisabled}
        name={name}
        onChange={(changeVal) => {
          if (onChange) {
            onChange(changeVal, name)
          }
        }}
        onBlur={onBlur}
        showSymbol={showSymbol}
        invalid={invalid}
        value={value}
        currencyCode={currencyProps?.codeValue || 'USD'}
        placeholder={placeholder}
        isGroup={isGroup}
        alignment={alignment}
        isZeroAllowed={isZeroAllowed}
      />
      {isCurrencyCodeDisplayed && (
        <CurrencyCodeField
          type="text"
          disabled={isCurrencyCodeDisabled || isDisabled}
          name={currencyProps?.codeFieldName}
          value={currencyProps?.codeValue || 'USD'}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value, currencyProps.codeFieldName)
            }
          }}
          onBlur={onBlur}
        />
      )}
    </CurrencyGroupWrapper>
    <ErrorMessage data-cy={`${dataCy}:error-message`}>{errorMessage}</ErrorMessage>
  </FormGroup>
)

const CurrencyCodeField = styled.input({
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  borderLeft: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: 10,
  paddingRight: 10,
  fontSize: 16,
  height: 38,
  padding: '0 12px',
  fontFamily: 'inherit',
  textAlign: 'center',
  width: '25%',
  '&:focus': {
    outline: 'none',
    borderColor: `${COLOR.BLUE[700]}`,
    boxShadow: `0 0 0 1px inset ${COLOR.BLUE[700]}`,
  },
  '&:disabled': {
    borderColor: COLOR.NEUTRAL[300],
    background: COLOR.NEUTRAL[100],
  },
})

const CurrencyGroupWrapper = styled.div({
  display: 'flex',
})

const FormGroup = styled.div({
  width: '100%',
  height: '100%',
})

const Label = styled.label({
  display: 'block',
  color: `${COLOR.BLUE[1000]}`,
  fontFamily: 'Nunito Sans',
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '22px',
  paddingBottom: 5,
})

const ErrorMessage = styled.div({
  color: COLOR.RED[700],
  fontFamily: 'Nunito Sans',
  fontSize: 15,
  lineHeight: '16px',
  marginBottom: 16,
  marginTop: 8,
})

const FieldHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
})

const InformationWrapper = styled.div({
  margin: -4,
})

export type { CurrencyInputGroupProps }
export { CurrencyInputGroup }
