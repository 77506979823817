import styled from '@emotion/styled'
import type { ScriptItem, ThreadListItem } from '@helloextend/extend-api-rtk-query'
import { useGetThreadQuery } from '@helloextend/extend-api-rtk-query'
import type { ButtonProps } from '@extend/zen'
import type { FC, MouseEventHandler } from 'react'
import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AdjudicationMessageBlock, AdjudicationTopnav } from '../components'
import { DashboardSpinner } from '../../../../components/dashboard-spinner'
import { AdjudicationTitleBlock } from '../components/message-block/adjudication-title-block'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'
import { handleNumericBadgeClick } from '../utils-ui'
import { EditWarningModal } from '../components/edit-warning-modal/edit-warning-modal'
import { defaultThreadItem } from '../mocks/mocked-thread-list-items'
import { setThread, resetThread } from '../../../../store/slices/amp-slice'

const AdjudicationThreadPreview: FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const { data: thread, isFetching, isLoading } = useGetThreadQuery(id, { skip: !id })
  const { hasPermission } = usePermissions()
  const hasEditPermission = hasPermission(Permission.ManageAdjudication)
  const [isEditWarningModalVisible, setIsEditWarningModalVisible] = useState<boolean>(false)
  const [threadToEdit, setThreadToEdit] = useState<ThreadListItem>(defaultThreadItem)

  const handleRoute = useCallback(
    (route: string): void => {
      history.push({
        pathname: route,
        search,
      })
    },
    [history, search],
  )

  const isEditButtonVisible = useMemo(() => {
    return thread && !['archived', 'override'].includes(thread.status) && hasEditPermission
  }, [thread, hasEditPermission])

  const handleEditClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    if (thread && thread?.status === 'published') {
      const threadListItem: ThreadListItem = {
        ...thread,
      }

      setThreadToEdit(threadListItem || defaultThreadItem)
      setIsEditWarningModalVisible(true)
    } else {
      handleRoute(`/admin/adjudication-management/threads/${thread?.id}/edit`)
    }
  }, [handleRoute, thread])

  const getThreadPreviewButtons = (): ButtonProps[] => {
    return [
      {
        text: 'Edit',
        isInverted: true,
        size: 'small',
        emphasis: 'high',
        onClick: handleEditClick,
        'data-cy': 'edit-button',
      },
    ]
  }

  const resetState = (): void => {
    setIsEditWarningModalVisible(false)
  }

  const handleEditWarningModalConfirm = (): void => {
    if (threadToEdit) {
      const contentPath = threadToEdit.type === 'adjudication' ? '/content' : ''
      handleRoute(`/admin/adjudication-management/threads/${threadToEdit.id}/edit${contentPath}`)
      resetState()
    }
  }

  const handleEditWarningModalCancel = (): void => {
    resetState()
  }

  const handleTopnavClose = (): void => {
    dispatch(resetThread())
    handleRoute('/admin/adjudication-management/threads')
  }

  const refs = useRef<{ [key: number]: HTMLElement }>({})

  const onBadgeClick = (value: number | string): void => {
    if (typeof value !== 'number') return
    handleNumericBadgeClick(refs, value)
  }

  // when thread is loaded it should be set as selected thread for UI Indexing to route correctly
  useEffect(() => {
    if (thread) {
      dispatch(setThread(thread))
    }
  }, [thread, dispatch])

  const scripts: ScriptItem[] = thread?.script || []
  return (
    <>
      <AdjudicationTopnav
        title="Thread Preview"
        name={thread?.title}
        status={thread?.status}
        isLoading={isLoading || isFetching}
        actionButtons={isEditButtonVisible ? getThreadPreviewButtons() : undefined}
        onClose={handleTopnavClose}
      />
      {isFetching || isLoading ? (
        <DashboardSpinner data-cy="thread-preview-spinner" />
      ) : (
        <MessageBlockContainer data-cy="message-block-container">
          <AdjudicationTitleBlock
            onClick={() => {
              // Go the start of the script when you click on the title block
              const startIndex = thread?.script[0]?.collect?.options[0]?.execute?.scriptIndex || 0
              onBadgeClick(startIndex)
            }}
          />
          {scripts.map((scriptItem, index) => {
            return (
              <AdjudicationMessageBlock
                key={JSON.stringify(scriptItem)}
                index={String(index + 1)}
                scriptItem={scriptItem}
                onBadgeClick={onBadgeClick}
                ref={(element: HTMLDivElement) => {
                  refs.current[index + 1] = element
                }}
                threadId={thread?.id}
              />
            )
          })}
        </MessageBlockContainer>
      )}
      {isEditWarningModalVisible && (
        <EditWarningModal
          isVisible={isEditWarningModalVisible}
          onConfirm={handleEditWarningModalConfirm}
          onCancel={handleEditWarningModalCancel}
          thread={threadToEdit}
        />
      )}
    </>
  )
}

const MessageBlockContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
})

export { AdjudicationThreadPreview }
