import { faker } from '@faker-js/faker/locale/en'
import type {
  ButtonsPrompt,
  InitialReply,
  SelectPromptOption,
  Reply,
  SelectPromptProduct,
  ShipmentSelectPromptOption,
  ShipmentSelectPrompt,
  ProductSelectPromptOption,
  ProductSelectPrompt,
} from '@helloextend/extend-api-client'
import { Slot, HelpTypeSlotValue } from '@helloextend/extend-api-client'
import { merge } from 'lodash/fp'

export const generateButtonsPrompt = (overrides: Partial<ButtonsPrompt> = {}): ButtonsPrompt => ({
  slot: Slot.HelpType,
  type: 'buttons',
  options: [
    generateButtonOption({ value: HelpTypeSlotValue.START_NEW_CLAIM, title: 'Start a Claim' }),
    generateButtonOption({ value: HelpTypeSlotValue.TRACK_CLAIM, title: 'Track a Claim' }),
  ],
  ...overrides,
})

const generateButtonOption = (overrides?: Partial<SelectPromptOption>): SelectPromptOption => ({
  title: 'Cool thing',
  value: faker.datatype.uuid(),
  outputText: faker.lorem.sentence(),
  redirectUrl: '',
  ...overrides,
})

export const generateInitialReply = (overrides?: Partial<InitialReply>): InitialReply => ({
  prompt: generateButtonsPrompt(),
  messages: [],
  accessToken: 'accessToken',
  ...overrides,
})

export const generateReply = (overrides: Partial<Reply> = {}): Reply => {
  return merge({
    prompt: { type: 'input', slot: Slot.FailureDescription },
    messages: [{ type: 'text', content: faker.random.words(2) }],
  })(overrides)
}

export const generateShipmentSelectPromptProduct = (
  overrides: Partial<SelectPromptProduct> = {},
): SelectPromptProduct => {
  const defaults: SelectPromptProduct = {
    title: faker.commerce.productName(),
    imageUrl: faker.image.imageUrl(),
  }
  return merge(defaults, overrides)
}

export const generateShipmentSelectPromptOption = (
  overrides: Partial<ShipmentSelectPromptOption> = {},
): ShipmentSelectPromptOption => {
  const defaults: ShipmentSelectPromptOption = {
    products: [],
    trackingUrl: faker.internet.url(),
    title: faker.commerce.productName(),
    value: faker.datatype.uuid(),
    outputText: faker.lorem.sentence(),
  }

  return merge(defaults, overrides)
}

export const generateProductSelectPromptOption = (
  overrides: Partial<ProductSelectPromptOption> = {},
): ProductSelectPromptOption => {
  const defaults: ProductSelectPromptOption = {
    title: faker.commerce.productName(),
    value: faker.datatype.uuid(),
    imageUrl: faker.image.imageUrl(),
  }

  return merge(defaults, overrides)
}

export const generateShipmentSelectPrompt = (
  overrides: Partial<ShipmentSelectPrompt> = {},
): ShipmentSelectPrompt => {
  const defaults: ShipmentSelectPrompt = {
    slot: Slot.Shipment,
    title: faker.random.words(2),
    type: 'shipmentSelect',
    options: [
      generateShipmentSelectPromptOption({
        products: [generateShipmentSelectPromptProduct()],
      }),
      generateShipmentSelectPromptOption({
        products: [generateShipmentSelectPromptProduct()],
      }),
    ],
  }

  return merge(defaults, overrides)
}

export const generateProductSelectPrompt = (
  overrides: Partial<ProductSelectPrompt> = {},
): ProductSelectPrompt => {
  const defaults: ProductSelectPrompt = {
    slot: Slot.Products,
    title: faker.random.words(2),
    type: 'productSelect',
    options: [generateProductSelectPromptOption(), generateProductSelectPromptOption()],
  }

  return merge(defaults, overrides)
}
