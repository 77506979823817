import { date } from '@extend/client-helpers'
import type { DateRangeFilterValues } from '../components/filters/types'
import { DateRange } from '../components/filters/types'

const dateRangeToString = (dateRange: DateRange): string => {
  switch (dateRange) {
    case DateRange.today:
      return 'Today'
    case DateRange.last7days:
      return 'Last 7 Days'
    case DateRange.last30days:
      return 'Last 30 Days'
  }

  return ''
}

const todayMinusDays = (days: number): number => {
  const d = new Date()
  d.setDate(d.getDate() - days)
  d.setHours(0, 0, 0, 0)
  return d.getTime()
}

const dateRangeFilterValueToDates = (
  filterValue: DateRangeFilterValues,
): { start: number | null; end: number | null } => {
  switch (filterValue?.range) {
    case DateRange.today:
      return {
        start: todayMinusDays(0),
        end: null,
      }

    case DateRange.last7days:
      return {
        start: todayMinusDays(7),
        end: null,
      }
    case DateRange.last30days:
      return {
        start: todayMinusDays(30),
        end: null,
      }
  }

  return {
    start: filterValue?.start ? date.getStartOfDay(filterValue.start).getTime() : null,
    end: filterValue?.end ? date.getEndOfDay(filterValue.end).getTime() : null,
  }
}

export { dateRangeToString, dateRangeFilterValueToDates }
