import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { Button, Filter } from '@extend/zen'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import { Menu } from '../menu'
import { FiltersMenu } from './filters-menu'
import type { FilterValues, FilterOptions } from './types'
import { DateRange } from './types'
import { FilterChip } from './filter-chip'

export type FiltersProps = {
  filters: Record<string, FilterOptions>
  values?: Record<string, FilterValues | null>
  onFilter: (filters: Record<string, FilterValues | null>) => void
  onClear: () => void
  isDisabled?: boolean
}

const buildChipFilterCount = (filterValue: FilterValues): number => {
  return filterValue.type === 'checkbox'
    ? filterValue.values.filter((value) => value !== null).length
    : 0
}

const setDefaultFilterValues = (type: string): FilterValues => {
  const lookup = {
    checkbox: { type, values: [] },
    nestedCheckbox: { type, values: {} },
    dateRange: {
      type,
      range: DateRange.custom,
      start: null,
      end: null,
    },
    text: { type, value: '' },
    numberRange: { type, low: null, high: null, isCurrency: false },
    advancedText: {
      type,
      caseSensitive: false,
      contains: null,
      excludes: null,
      startsWith: null,
      endsWith: null,
    },
  }

  return lookup[type as keyof typeof lookup] as FilterValues
}

const flattenFilters = (filters: Record<string, FilterOptions>): FilterOptions[] => {
  return Object.entries(filters).reduce<FilterOptions[]>(
    (acc, [key, filter]) =>
      filter.type === 'group'
        ? { ...acc, ...flattenFilters(filter.filters) }
        : { ...acc, [key]: filter },
    [],
  )
}

/**
 * @deprecated Instead of using this separate `<Filter />` component, use the filter features that exist within the
 * Zen DataTable.
 */
const Filters: FC<FiltersProps> = ({
  filters,
  values = { catagory: null },
  onFilter,
  onClear,
  isDisabled,
}) => {
  const [isMenuOpen, { toggle, off }] = useToggle()
  const [lockMenu, setLockMenu] = useState(false)
  // default to checkbox
  const [filterType, setFilterType] = useState<string>('checkbox')

  const handleMenuLock = useCallback((isLocked) => {
    setLockMenu(isLocked)
  }, [])

  const handleFilterChange = useCallback(
    (property: string, newValues: FilterValues | null) => {
      const updatedFilters = {
        ...values,
        [property]: newValues,
      }
      onFilter(updatedFilters)
      // default to checkbox
      setFilterType(newValues !== null ? newValues.type : 'checkbox')
    },
    [onFilter, values],
  )

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    if (!lockMenu) {
      off()
    }
  })

  const flattenedFilters = flattenFilters(filters)
  const hasMultipleFilters = Object.keys(filters).length > 1
  const hasMultipleFilterValues = Object.keys(values).length > 1
  const defaultFilterValues = setDefaultFilterValues(filterType)

  const renderFilterChip = Object.entries(flattenedFilters).map(([key, filter]) => {
    const chipFilterValues = values[key]
    const chipFilterValuesCount = chipFilterValues ? buildChipFilterCount(chipFilterValues) : 0

    if (hasMultipleFilters && chipFilterValues) {
      return (
        <FilterChip
          key={key}
          property={key}
          filter={filter}
          values={chipFilterValues}
          onFilterChange={handleFilterChange}
          chipFilterValuesCount={chipFilterValuesCount}
        />
      )
    }

    if (!hasMultipleFilters) {
      return (
        <FilterChip
          key={key}
          property={key}
          filter={filter}
          values={chipFilterValues ?? defaultFilterValues}
          onFilterChange={handleFilterChange}
          chipFilterValuesCount={chipFilterValuesCount}
        />
      )
    }

    return null
  })

  return (
    <FilterWrapper>
      {hasMultipleFilters && (
        <>
          <div ref={ref}>
            <Button
              emphasis="medium"
              isToggled={isMenuOpen}
              data-cy="filter-trigger"
              text="Filters"
              onClick={toggle}
              icon={Filter}
              isDisabled={isDisabled}
            />
            <MenuWrapper>
              <Menu isOpen={isMenuOpen} position="left" width={216}>
                <FiltersMenu
                  filters={filters}
                  values={values}
                  onMenuLock={handleMenuLock}
                  onFilterChange={handleFilterChange}
                />
              </Menu>
            </MenuWrapper>
          </div>
          <ChipWrapper filterButtonSpacing={hasMultipleFilters}>
            {renderFilterChip}
            {hasMultipleFilterValues && (
              <Button
                emphasis="low"
                onClick={onClear}
                text="Clear All"
                data-cy="clear-all-button"
              />
            )}
          </ChipWrapper>
        </>
      )}

      {!hasMultipleFilters && defaultFilterValues && (
        <ChipWrapper filterButtonSpacing={hasMultipleFilters}>{renderFilterChip}</ChipWrapper>
      )}
    </FilterWrapper>
  )
}

const FilterWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
  marginBottom: 12,
  marginTop: 32,
}))

const MenuWrapper = styled.div({
  width: '100%',
  position: 'absolute',
  top: 38,
})

const ChipWrapper = styled.div<{ filterButtonSpacing: boolean }>(({ filterButtonSpacing }) => ({
  marginLeft: filterButtonSpacing ? 16 : 0,
  display: 'flex',
  gap: 16,
  minHeight: 40,
  height: '100%',
  flexGrow: 1,
  alignItems: 'center',
  flexWrap: 'wrap',
}))

export { Filters }
