import type { ParseResult } from 'papaparse'
import Papa from 'papaparse'
import { getCsvHeaderIndexes } from '../get-header-indexes'
import type { CsvImportParseResults, HeaderIndexMap } from '../csv-validation-models'
import type {
  EmbeddedPromotionHeader,
  EmbeddedPromotionCsvValidatorPipe,
} from './embedded-promotion-csv-headers'
import { validateBoolean, validateHeader, validateString } from './validators'
import { formatPipeErrorOrWarningMessage } from '../shared-csv-validation'

const INVALID_HEADERS = 'The CSV headers in the uploaded file should match the sample file provided'

export async function parseEmbeddedPromotionCsv(file: File): Promise<CsvImportParseResults> {
  const parseResults: CsvImportParseResults = {
    data: [],
    errors: [],
    warnings: [],
  }

  let isHeaderScanned = false
  let headerIndexes: HeaderIndexMap<EmbeddedPromotionHeader>
  let currRowStep = 0 // for row #

  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      step: (result: ParseResult<string>, parser): void => {
        currRowStep += 1 // track current row being scanned in step fn
        if (!isHeaderScanned) {
          const headers = result.data
          isHeaderScanned = true
          if (!validateHeader(headers)) {
            parseResults.errors.push(INVALID_HEADERS)
            resolve(parseResults)
            parser.abort()
          }

          headerIndexes = getCsvHeaderIndexes(headers)
        } else {
          const rowData = result.data
          const meta: EmbeddedPromotionCsvValidatorPipe = {
            errors: [],
            warnings: [],
            rowData,
            headerIndexes,
          }

          validateString(meta, headerIndexes.referenceId)
          validateBoolean(meta, headerIndexes.toDelete)
          validateString(meta, headerIndexes.embeddedPlanId)

          if (meta.errors?.length) {
            const errorMessage = formatPipeErrorOrWarningMessage(currRowStep, meta.errors)
            parseResults.errors.push(errorMessage)
          } else if (meta.warnings?.length) {
            const warningMessage = formatPipeErrorOrWarningMessage(currRowStep, meta.warnings)
            parseResults.warnings?.push(warningMessage)
            parseResults.data.push(meta.rowData)
          } else {
            parseResults.data.push(meta.rowData)
          }
        }
      },
      complete: (): void => {
        resolve(parseResults)
      },
      error(error): void {
        reject(error)
      },
    })
  })
}
