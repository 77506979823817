import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { useGetServicersBySellerIdQuery, useGetStoreQuery } from '@helloextend/extend-api-rtk-query'
import { Breadcrumbs, Spinner, HeadingLarge, Subheading } from '@extend/zen'
import { Divider } from '../../../../../../../../components/divider'
import { SectionContainer } from '../../../../../../../../components/section-container'
import { AddServicingLocation } from '../add-servicing-location'
import { ServicingLocation } from '../servicing-location'

const ServicingLocations: FC = () => {
  const { storeId, referenceId } = useParams<{ storeId: string; referenceId?: string }>()
  const { isLoading: isLoadingStore, data: store } = useGetStoreQuery({ storeId })
  const { isLoading: isLoadingServicer, servicer } = useGetServicersBySellerIdQuery(storeId, {
    selectFromResult: ({ data, ...rest }) => ({
      servicer: data?.[0],
      ...rest,
    }),
  })

  if (isLoadingStore || isLoadingServicer) {
    return <Spinner />
  }
  if (!store || !servicer) {
    return null
  }

  return (
    <>
      <Breadcrumbs
        crumbs={[
          {
            text: 'Stores',
            to: '/admin/stores',
          },
          {
            text: store.name || 'Settings',
            to: referenceId
              ? `/admin/stores/${storeId}/products/${referenceId}`
              : `/admin/stores/${storeId}/settings`,
          },
          {
            text: 'Manage Servicing Locations',
          },
        ]}
        data-cy="store-details-breadcrumbs"
      />
      <HeadingLargeWrapper>Manage Servicing Locations</HeadingLargeWrapper>
      <SectionContainer title={`${store.name} Servicing Locations`}>
        {servicer?.locations?.map((location, index) => (
          <ServicingLocationContainer key={`location-${location.id}`}>
            <SubheadingWrapper>Location {index + 1}</SubheadingWrapper>
            <ServicingLocation location={location} />
            <Divider />
          </ServicingLocationContainer>
        ))}
        <AddServicingLocation
          servicerId={servicer.id}
          locationIndex={servicer?.locations ? servicer.locations.length + 1 : 1}
        />
      </SectionContainer>
    </>
  )
}

const HeadingLargeWrapper = styled(HeadingLarge)({
  margin: '24px 0 16px',
})

const SubheadingWrapper = styled(Subheading)({
  marginBottom: 16,
})

const ServicingLocationContainer = styled.div({
  margin: '40px 0',
})

export { ServicingLocations }
