import type { AdvancedSelectChangeEvent } from '@extend/zen'
import {
  AdvancedSelect,
  Box,
  Button,
  DataProperty,
  DataPropertyType,
  Grid,
  GridItem,
  Input,
  InputType,
  Label,
  Select,
  Stack,
  Trash,
} from '@extend/zen'
import {
  useListFailureCausesQuery,
  useListFailureTypesQuery,
} from '@helloextend/extend-api-rtk-query'
import type { ProductComponent } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'
import type { FormikErrors, FormikTouched } from 'formik'
import type { FC } from 'react'
import React from 'react'
import { mapToValueDisplay } from '../../../../../../utils/utils'

type ComponentBoxProps = {
  component: ProductComponent
  isCategoryEditMode: boolean
  index: number
  handleDeleteComponent: (index: number) => void
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement> | AdvancedSelectChangeEvent<string[]>,
  ) => void
  handleBlur: (e: React.FocusEvent<HTMLInputElement> | AdvancedSelectChangeEvent<string[]>) => void
  setFieldValue?: (field: string, value: { [x: string]: number }) => void
  errors?: FormikErrors<ProductComponent | Omit<ProductComponent, 'limitOfLiability'>>
  touched?: FormikTouched<ProductComponent | Omit<ProductComponent, 'limitOfLiability'>>
  type: 'covered' | 'notCovered'
  canDelete?: boolean
}

const ComponentBox: FC<ComponentBoxProps> = ({
  component,
  isCategoryEditMode,
  index,
  handleDeleteComponent,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  touched,
  type,
  canDelete,
}) => {
  const { data: failureTypes, isLoading: isLoadingFailureTypes } = useListFailureTypesQuery(
    undefined,
    { skip: !isCategoryEditMode },
  )
  const { data: failureCauses, isLoading: isLoadingFailureCauses } = useListFailureCausesQuery(
    undefined,
    {
      skip: !isCategoryEditMode,
    },
  )

  return (
    <Box>
      <Stack spacing={2}>
        {isCategoryEditMode ? (
          <>
            <Stack isRow justify="space-between">
              <Input
                id={`components.${index}.componentName`}
                label="Component"
                value={component.componentName}
                onChange={handleChange}
                onBlur={handleBlur}
                isError={!!errors?.componentName && !!touched?.componentName}
                errorFeedback={errors?.componentName}
                data-cy="component-name-input"
              />
              {canDelete ? (
                <Button
                  icon={Trash}
                  type="button"
                  color="red"
                  emphasis="low"
                  onClick={() => handleDeleteComponent(index)}
                  data-cy="delete-component-button"
                />
              ) : null}
            </Stack>
            <AdvancedSelect
              options={mapToValueDisplay(failureTypes?.items ?? [], 'id', 'failureType')}
              id={`components.${index}.failureTypes`}
              multiple
              value={component.failureTypes ?? []}
              label="Failure Types"
              onChange={handleChange}
              maxQuantityToDisplay={3}
              showSearch
              isLoading={isLoadingFailureTypes}
              data-cy="failure-types-advanced-select"
            />
            <AdvancedSelect
              options={mapToValueDisplay(failureCauses?.items ?? [], 'id', 'failureCause')}
              id={`components.${index}.failureCauses`}
              multiple
              value={component.failureCauses ?? []}
              label="Failure Causes"
              onChange={handleChange}
              maxQuantityToDisplay={3}
              showSearch
              isLoading={isLoadingFailureCauses}
              data-cy="failure-causes-advanced-select"
            />
            {type === 'covered' ? (
              <Stack spacing={1}>
                <Label>Limit Of Liability</Label>
                <Grid columns={4} spacing={2}>
                  <GridItem span={2}>
                    <Select
                      id="limitOfLiabilityType"
                      value={getDefinedPropertyName(component.limitOfLiability) ?? ''}
                      onChange={(e) => {
                        setFieldValue?.(`components.${index}.limitOfLiability`, {
                          [e.target.value]: 0,
                        })
                      }}
                      data-cy="limit-of-liability-type-select"
                    >
                      <option value="">Select</option>
                      <option value="count">Count</option>
                      <option value="percentage">Percentage</option>
                      <option value="amount">Amount</option>
                    </Select>
                  </GridItem>
                  <GridItem span={2}>
                    <Input
                      id={`components.${index}.limitOfLiability.${getDefinedPropertyName(
                        component.limitOfLiability,
                      )}`}
                      value={
                        component.limitOfLiability?.[
                          getDefinedPropertyName(component.limitOfLiability) ?? 'count'
                        ]?.toString() ?? ''
                      }
                      type={InputType.number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data-cy="limit-of-liability-input"
                    />
                  </GridItem>
                </Grid>
              </Stack>
            ) : null}
          </>
        ) : (
          <>
            <DataProperty
              label="Component"
              value={component.componentName}
              data-cy="component-name-data-property"
            />
            <DataProperty
              label="Failure Types"
              value={component.failureTypes?.join(', ')}
              data-cy="failure-types-data-property"
            />
            <DataProperty
              label="Failure Causes"
              value={component.failureCauses?.join(', ')}
              data-cy="failure-causes-data-property"
            />
            {type === 'covered' ? (
              <DataProperty
                label="Limit Of Liability"
                value={getDefinedPropertyValue(component.limitOfLiability)}
                type={DataPropertyType.number}
                prefix={getPrefix(getDefinedPropertyName(component.limitOfLiability))}
                data-cy="limit-of-liability-data-property"
              />
            ) : null}
          </>
        )}
      </Stack>
    </Box>
  )
}

function getDefinedPropertyName<T extends object>(obj: T | undefined): keyof T | undefined {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      return key
    }
  }
  return undefined
}

function getDefinedPropertyValue<T extends object>(obj: T | undefined): T[keyof T] | undefined {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      return obj[key]
    }
  }
  return undefined
}

const getPrefix = (property: string | undefined): string | undefined => {
  switch (property) {
    case 'amount':
      return '$'
    case 'percentage':
      return '%'
    case 'count':
      return 'count'
    default:
      return undefined
  }
}

export { ComponentBox }
