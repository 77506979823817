import styled from '@emotion/styled'
import {
  Add,
  AdvancedSelect,
  Badge,
  Button,
  ButtonGroup,
  COLOR,
  Info,
  Input,
  InputType,
  Radio,
  RadioGroup,
} from '@extend/zen'
import { useToggle } from '@helloextend/client-hooks'
import type { ChangeEvent, FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import type { ContractNoteSchemaValues } from './contract-note-schema';
import { NOTE_TEXT_MAX, contractNoteSchema } from './contract-note-schema'
import type { ContractNote } from '@helloextend/extend-api-client';
import { CONTRACT_NOTE_CATEGORIES } from '@helloextend/extend-api-client'
import {
  useCreateContractNoteMutation,
  useUpdateContractNoteMutation,
} from '@helloextend/extend-api-rtk-query'
import { contractNotesEscalationDescription } from 'src/lib/escalation-levels'
import { ContractNoteTextArea } from './contract-note-text-area'
import { formatTimestampToPST } from 'src/lib/date'

interface ContractNoteFormProps {
  contractId: string
  hasUnsavedChanges?: boolean
  setHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void
  contractNoteToEdit?: ContractNote
  setIsEdit?: (isEdit: boolean) => void
  escalationExists?: boolean
}

const ContractNoteForm: FC<ContractNoteFormProps> = ({
  contractId,
  setHasUnsavedChanges,
  hasUnsavedChanges,
  contractNoteToEdit,
  setIsEdit,
  escalationExists,
}) => {
  const [isFormOpen, { toggle, off }] = useToggle()
  const [isEscalate, setIsEscalate] = useState(false)
  const [createContractNote, { isLoading: isCreateLoading }] = useCreateContractNoteMutation()
  const [updateContractNote, { isLoading: isUpdateLoading }] = useUpdateContractNoteMutation()
  const isLoading = isCreateLoading || isUpdateLoading
  const noteDate = formatTimestampToPST(contractNoteToEdit?.createdAt)

  const {
    errors,
    handleSubmit,
    handleChange,
    values,
    resetForm,
    dirty,
    setFieldValue,
    validateField,
    isValid,
  } = useFormik<ContractNoteSchemaValues>({
    enableReinitialize: true,
    initialValues:
      (contractNoteToEdit as ContractNoteSchemaValues) ?? contractNoteSchema.getDefault(),
    validationSchema: contractNoteSchema,
    validateOnChange: false,
    onSubmit: async (formValues: ContractNoteSchemaValues) => {
      if (contractNoteToEdit) {
        await updateContractNote({
          contractId,
          noteId: contractNoteToEdit.id,
          data: formValues,
        })
      } else {
        await createContractNote({
          contractId,
          data: {
            ...formValues,
            type: isEscalate ? 'escalation' : formValues.type,
            escalationLevel: isEscalate ? formValues.escalationLevel : undefined,
          },
        })
      }
      handleCancel()
    },
  })

  useEffect(() => {
    if (setHasUnsavedChanges && hasUnsavedChanges !== undefined) {
      if (dirty && !hasUnsavedChanges) {
        setHasUnsavedChanges(true)
      } else if (!dirty && hasUnsavedChanges) {
        setHasUnsavedChanges(false)
      }
    }
  }, [values])

  const handleCancel = (): void => {
    if (setIsEdit && contractNoteToEdit) {
      setIsEdit(false)
    }

    off()
    resetForm()
    setHasUnsavedChanges && setHasUnsavedChanges(false)
  }

  const categorySelectOptions = CONTRACT_NOTE_CATEGORIES.map((category) => ({
    display: category,
    value: category,
  }))

  const handleNoteChange = (evt: ChangeEvent<{ value: string }>) => {
    handleChange(evt)

    if (setHasUnsavedChanges) {
      const { value } = evt.target
      setHasUnsavedChanges(value.length > 0)
    }
  }

  return (
    <Container>
      {!isFormOpen && !contractNoteToEdit && (
        <AddAndEscalateButtons
          toggle={toggle}
          setIsEscalate={setIsEscalate}
          escalationExists={escalationExists}
        />
      )}
      {(isFormOpen || contractNoteToEdit) && (
        <StyledForm onSubmit={handleSubmit} data-cy="contract-note-form">
          {contractNoteToEdit && (
            <NoteHeader>
              <NoteDate data-cy="claims-note-date">{noteDate}</NoteDate>
              <NoteAuthor>
                <Badge
                  color="neutral"
                  data-cy="notes-author-badge"
                  emphasis="low"
                  size="small"
                  text={contractNoteToEdit.createdBy}
                />
              </NoteAuthor>
            </NoteHeader>
          )}
          {(isEscalate || contractNoteToEdit?.escalationLevel) && values.escalationLevel && (
            <RadioGroup
              label="Escalation Level"
              layout="vertical"
              name="preferredMethod"
              onChange={(e) => setFieldValue('escalationLevel', e.target.value)}
              value={values.escalationLevel}
              data-cy={'contract-note-escalation-level'}
            >
              <Radio
                label={contractNotesEscalationDescription.tier1}
                value={'tier1'}
                data-cy={'escalation-tier1'}
              />
              <Radio
                label={contractNotesEscalationDescription.tier2}
                value={'tier2'}
                data-cy={'escalation-tier2'}
              />
              <Radio
                label={contractNotesEscalationDescription.tier3}
                value={'tier3'}
                data-cy={'escalation-tier3'}
              />
            </RadioGroup>
          )}
          <AdvancedSelect
            multiple={false}
            value={values.category}
            id="category"
            label="Note Category"
            placeholder="Select"
            onChange={handleChange}
            data-cy="contract-note-category-select"
            isError={!!errors.category}
            errorFeedback={errors.category}
            options={categorySelectOptions}
            isDisabled={isLoading}
          />
          {values.category === 'Other' && (
            <Input
              data-cy="contract-note-category-description"
              id="categoryDescription"
              label="Category Description"
              placeholder="Please specify..."
              isError={!!errors.categoryDescription}
              errorFeedback={errors.categoryDescription}
              onChange={handleChange}
              type={InputType.text}
              value={values.categoryDescription || ''}
              isDisabled={isLoading}
            />
          )}

          <ContractNoteTextArea
            label="Note"
            rows={6}
            maxLength={NOTE_TEXT_MAX}
            onChange={handleNoteChange}
            value={values.text}
            isError={!!errors.text}
            validateField={validateField}
            errorFeedback={errors.text}
            dataCy="contract-note-text"
            id="text"
            isDisabled={isLoading}
          />
          <ButtonGroup data-cy="">
            <Button
              data-cy="cancel-button"
              emphasis="medium"
              text="Cancel"
              onClick={handleCancel}
              isDisabled={isLoading}
              isProcessing={isLoading}
            />
            <Button
              data-cy="save-button"
              type="submit"
              text="Save"
              isDisabled={isLoading || !dirty || !isValid}
              isProcessing={isLoading}
            />
          </ButtonGroup>
        </StyledForm>
      )}
    </Container>
  )
}

const AddAndEscalateButtons: FC<{
  toggle: () => void
  setIsEscalate: (isEscalate: boolean) => void
  escalationExists?: boolean
}> = ({ toggle, setIsEscalate, escalationExists }) => {
  return (
    <ContractNoteButtonWrapper>
      <Button
        text="Add Note"
        icon={Add}
        emphasis="medium"
        onClick={() => {
          setIsEscalate(false)
          toggle()
        }}
        data-cy="add-note-button"
      />
      {!escalationExists && (
        <Button
          text="Escalate"
          icon={Info}
          emphasis="medium"
          onClick={() => {
            setIsEscalate(true)
            toggle()
          }}
          data-cy="escalate-note-button"
        />
      )}
    </ContractNoteButtonWrapper>
  )
}

const ContractNoteButtonWrapper = styled.div({
  display: 'flex',
  gap: 8,
  paddingTop: '8px',
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})

const StyledForm = styled.form({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  padding: 16,
  marginTop: 8,
  width: 'auto',
  gap: 8,
  boxShadow: `0px 4px 8px ${COLOR.NEUTRAL[300]}`,
})

const NoteHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 0,
})

const NoteDate = styled.div({
  fontSize: 15,
  fontWeight: 400,
  lineHeight: '20px',
  color: COLOR.BLACK[800],
})

const NoteAuthor = styled.div({
  textAlign: 'right',
})

export { ContractNoteForm }
