import * as Yup from 'yup'
import type { SPPlanPriceBandMapping, SPPurchaseModel, Store } from '@helloextend/extend-api-client'
import { number as numberHelper } from '@extend/client-helpers'
import type { SPPlanCategoryMapping } from '@helloextend/extend-api-client/src/models/store-shipping-protection'
import type { PartialReimbursementType } from '@helloextend/extend-api-client/src/models/store'
import { hasGapsInPriceband, hasOverlappingPriceBands } from '../../../utils'

const mapPriceBandsToFormValues = (
  priceBands: SPPlanPriceBandMapping[],
  pbPartialReimbursementSelection?: PartialReimbursementType | null,
  pbPartialReimbursementEnabled?: boolean,
): SPPlanPriceBandMappingFormValues[] =>
  priceBands.map(({ revenueShare, partialReimbursement, ...rest }) => ({
    ...rest,
    revenueShare: revenueShare ? numberHelper.getPercentageFromDecimal(revenueShare) : undefined,
    partialReimbursement: partialReimbursement
      ? numberHelper.getPercentageFromDecimal(partialReimbursement)
      : undefined,
    pbPartialReimbursementSelection,
    pbPartialReimbursementEnabled: pbPartialReimbursementEnabled || false,
  }))

const mapFormValuesToPriceBands = (
  priceBands: SPPlanPriceBandMappingFormValues[],
): SPPlanPriceBandMapping[] =>
  priceBands.map(
    ({
      revenueShare,
      high,
      fixedPremium,
      partialReimbursement,
      partialReimbursementFixed,
      pbPartialReimbursementSelection,
      pbPartialReimbursementEnabled,
      ...rest
    }) => ({
      ...rest,
      revenueShare: revenueShare ? numberHelper.getDecimalFromPercentage(revenueShare) : undefined,
      partialReimbursement: validatePartialReimbursementField(
        pbPartialReimbursementEnabled,
        'percentage',
        pbPartialReimbursementSelection,
        partialReimbursement,
      )
        ? numberHelper.getDecimalFromPercentage(partialReimbursement as number)
        : undefined,
      partialReimbursementFixed: validatePartialReimbursementField(
        pbPartialReimbursementEnabled,
        'fixed',
        pbPartialReimbursementSelection,
        partialReimbursementFixed,
      )
        ? partialReimbursementFixed
        : undefined,
      high: high ? +high : undefined,
      fixedPremium: fixedPremium ? +fixedPremium : undefined,
    }),
  )

const mapFormValuesToCategories = (
  categories: SPPlanCategoryMapping[],
  partialReimbursementEnabled: boolean,
  partialReimbursementSelection?: PartialReimbursementType | null,
): SPPlanCategoryMapping[] =>
  categories.map(({ partialReimbursement, revenueShare, partialReimbursementFixed, ...rest }) => ({
    revenueShare: revenueShare ? numberHelper.getDecimalFromPercentage(revenueShare) : undefined,
    partialReimbursement: validatePartialReimbursementField(
      partialReimbursementEnabled,
      'percentage',
      partialReimbursementSelection,
      partialReimbursement,
    )
      ? numberHelper.getDecimalFromPercentage(partialReimbursement as number)
      : undefined,
    partialReimbursementFixed: validatePartialReimbursementField(
      partialReimbursementEnabled,
      'fixed',
      partialReimbursementSelection,
      partialReimbursementFixed,
    )
      ? partialReimbursementFixed
      : undefined,
    ...rest,
  }))

const mapCategoryMappingsToFormValues = (
  categoryMappings: SPPlanCategoryMapping[],
): SPPlanCategoryMapping[] => {
  return categoryMappings.map(({ revenueShare, partialReimbursement, ...rest }) => ({
    ...rest,
    revenueShare: revenueShare ? numberHelper.getPercentageFromDecimal(revenueShare) : undefined,
    partialReimbursement: partialReimbursement
      ? numberHelper.getPercentageFromDecimal(partialReimbursement)
      : undefined,
  }))
}

const getInitialPurchaseModelValues = ({
  store,
  priceBands,
  categoryMappings,
}: {
  store: Store
  priceBands?: SPPlanPriceBandMapping[]
  categoryMappings?: SPPlanCategoryMapping[]
}): Values => {
  const { shippingProtection, merchantSpRevenueSharePercentage } = store
  return {
    purchaseModel: shippingProtection?.purchaseModel || 'SINGLE_PLAN',
    partialReimbursementEnabled: store.shippingProtection?.partialReimbursementEnabled || false,
    priceBands: priceBands
      ? mapPriceBandsToFormValues(
          priceBands,
          store.shippingProtection?.partialReimbursementType,
          store.shippingProtection?.partialReimbursementEnabled,
        )
      : [
          getInitialPriceBandValues(
            store.shippingProtection?.partialReimbursementEnabled || false,
            store.shippingProtection?.partialReimbursementType,
            true,
          ),
        ],
    planId: shippingProtection?.planId || '',
    merchantSpRevenueSharePercentage: convertMerchantSpRevenueSharePercentage(
      merchantSpRevenueSharePercentage,
    ),
    partialReimbursementSelection: store.shippingProtection?.partialReimbursementType,
    offersByCategoryEnabled: shippingProtection?.offersByCategoryEnabled || false,
    // a temporary default value until backend is updated
    partialReimbursementSPValue: setPartialReimbursementSPValue(
      store.shippingProtection?.partialReimbursementType,
      store.shippingProtection?.partialReimbursementFixed,
      store.shippingProtection?.partialReimbursementPercentage,
    ),
    categoryMappings: categoryMappings
      ? mapCategoryMappingsToFormValues(categoryMappings)
      : [getInitialCategoryMappingValues()],
  } as Values
}

export const convertMerchantSpRevenueSharePercentage = (
  number: number | null | undefined,
): number => {
  if (number === 0) {
    return 0
  }
  return number ? numberHelper.getPercentageFromDecimal(number) : (undefined as unknown as number)
}

const validatePartialReimbursementField = (
  enabled: boolean,
  reimbursementType: PartialReimbursementType | null,
  reimbursementSelection?: PartialReimbursementType | null,
  field?: number | null,
): boolean => {
  if (enabled && reimbursementType === reimbursementSelection && field && field > 0) {
    return true
  }
  return false
}
const setPartialReimbursementSPValue = (
  partialReimburmsementType?: string | null,
  fixed?: number | null,
  percentage?: number | null,
): number | undefined => {
  if (partialReimburmsementType && partialReimburmsementType === 'fixed' && fixed) {
    return fixed
  }
  if (partialReimburmsementType && partialReimburmsementType === 'percentage' && percentage) {
    return numberHelper.getPercentageFromDecimal(percentage)
  }
  return undefined
}
const getInitialPriceBandValues = (
  pbPartialReimbursementEnabled: boolean,
  pbPartialReimbursementSelection?: PartialReimbursementType | null,
  isFirstRow = false,
): SPPlanPriceBandMappingFormValues => ({
  low: isFirstRow ? 0 : (undefined as unknown as number),
  planId: '',
  pbPartialReimbursementEnabled,
  pbPartialReimbursementSelection,
})

const getInitialCategoryMappingValues = (): Partial<SPPlanCategoryMapping> => ({
  planCategoryId: '',
  spPlanId: '',
  storeId: '',
  revenueShare: undefined as unknown as number,
  partialReimbursement: undefined as unknown as number,
  partialReimbursementFixed: undefined as unknown as number,
})

const purchaseModelLabels: {
  [key in SPPurchaseModel]: string
} = {
  SINGLE_PLAN: 'Single Plan',
  CATEGORY: 'Category',
  PRICE_BAND: 'Price Band',
}

const partialReimbursementOptions = [
  {
    display: 'Fixed Price',
    value: 'fixed',
  },
  {
    display: 'Percentage',
    value: 'percentage',
  },
]
export interface SPPlanPriceBandMappingFormValues extends SPPlanPriceBandMapping {
  pbPartialReimbursementSelection?: PartialReimbursementType | null
  pbPartialReimbursementEnabled: boolean
}
const priceBandSchema = Yup.object<SPPlanPriceBandMappingFormValues>()
  .shape({
    low: Yup.number().required('Price band low is required'),
    high: Yup.number().notRequired(),
    planId: Yup.string().required('Plan is required'),
    fixedPremium: Yup.number().notRequired(),
    partialReimbursement: Yup.number()
      .when(['pbPartialReimbursementEnabled', 'pbPartialReimbursementSelection'], {
        is: (pbPartialReimbursementEnabled: boolean, pbPartialReimbursementSelection: string) =>
          pbPartialReimbursementEnabled && pbPartialReimbursementSelection === 'percentage',
        then: () => Yup.number().required('Partial reimbursement percentage is required'),
        otherwise: () => Yup.number().notRequired(),
      })
      .moreThan(0, 'Partial reimbursement should be greater than 0')
      .max(100, 'Partial reimbursement should be less than or equal to 100'),
    revenueShare: Yup.number()
      .required('Revenue share is required')
      .moreThan(0, 'Revenue share must be greater than 0')
      .max(100, 'Revenue share must be equal or less than 100'),
    partialReimbursementFixed: Yup.number()
      .when(['pbPartialReimbursementEnabled', 'pbPartialReimbursementSelection'], {
        is: (pbPartialReimbursementEnabled: boolean, pbPartialReimbursementSelection: string) =>
          pbPartialReimbursementEnabled && pbPartialReimbursementSelection === 'fixed',
        then: () => Yup.number().required('Partial reimbursement fixed value is required'),
        otherwise: () => Yup.number().notRequired(),
      })
      .moreThan(0, 'Partial reimbursement should be greater than 0'),
    pbPartialReimbursementSelection: Yup.string<PartialReimbursementType>().oneOf([
      'fixed',
      'percentage',
    ]),
    pbPartialReimbursementEnabled: Yup.boolean(),
  })
  .test('checkOverlap', 'Price bands cannot overlap', function checkOverlap(value) {
    const isOverlapping = hasOverlappingPriceBands(this.parent, value as SPPlanPriceBandMapping)

    return isOverlapping
      ? this.createError({
          message: 'Price bands must not overlap',
          path: `${this.path}.high`,
        })
      : true
  })
  .test('checkGap', 'Price bands cannot have gaps', function testGapsInPricebands(value) {
    const hasGaps = hasGapsInPriceband(this.parent, value as SPPlanPriceBandMapping)
    return hasGaps
      ? this.createError({
          message: 'Price bands must not have gaps',
          path: `${this.path}.low`,
        })
      : true
  })
  .test(
    'price band high is required if not last',
    'Price band high is required',
    function checkIfRequired(value) {
      const priceBands = this.parent as SPPlanPriceBandMapping[]
      const isLastRow = priceBands.length > 0 && this.path.endsWith(`[${priceBands.length - 1}]`)
      return !isLastRow && !value?.high
        ? this.createError({ message: 'Price band high is required', path: `${this.path}.high` })
        : true
    },
  )
  .defined()

const schema = Yup.object()
  .shape({
    purchaseModel: Yup.string<SPPurchaseModel>()
      .required('Purchase Model is required')
      .oneOf(['SINGLE_PLAN', 'CATEGORY', 'PRICE_BAND']),
    partialReimbursementEnabled: Yup.boolean(),
    partialReimbursementSelection: Yup.string<PartialReimbursementType>().oneOf([
      'fixed',
      'percentage',
    ]),
    priceBands: Yup.array().when('purchaseModel', {
      is: (purchaseModel: SPPurchaseModel) => purchaseModel === 'PRICE_BAND',
      then: () =>
        Yup.array<SPPlanPriceBandMappingFormValues>()
          .of(priceBandSchema)
          .required('At least one price band is required'),
      otherwise: () => Yup.array(),
    }),
    categoryMappings: Yup.array().when('purchaseModel', {
      is: (purchaseModel: SPPurchaseModel) => purchaseModel === 'CATEGORY',
      then: () =>
        Yup.array<SPPlanCategoryMapping>()
          .required('At least one category mapping is required')
          .when(['partialReimbursementEnabled', 'partialReimbursementSelection'], {
            is: (enabled: boolean, selection: string) => enabled && selection === 'percentage',
            then: () => Yup.array<SPPlanCategoryMapping>(),
            //   .of(
            //   Yup.object().shape({
            //     planCategoryId: Yup.string().required('Extend Category is required'),
            //     spPlanId: Yup.string().required('Shipping Protection Plan is required'),
            //     revenueShare: Yup.number()
            //       .required('Revenue share is required')
            //       .moreThan(0, 'Revenue share must be greater than 0')
            //       .max(100, 'Revenue share must be equal or less than 100'),
            //     partialReimbursement: Yup.number()
            //       .required('Partial reimbursement is required')
            //       .moreThan(0, 'Partial reimbursement should be greater than 0')
            //       .max(100, 'Partial reimbursement should be less than or equal to 100'),
            //     partialReimbursementFixed: Yup.number().nullable().notRequired(),
            //   }),
            // ),
          })
          .when(['partialReimbursementEnabled', 'partialReimbursementSelection'], {
            is: (enabled: boolean, selection: string) => enabled && selection === 'fixed',
            then: () => Yup.array<SPPlanCategoryMapping>(),
            //   .of(
            //   Yup.object().shape({
            //     planCategoryId: Yup.string().required('Extend Category is required'),
            //     spPlanId: Yup.string().required('Shipping Protection Plan is required'),
            //     revenueShare: Yup.number()
            //       .required('Revenue share is required')
            //       .moreThan(0, 'Revenue share must be greater than 0')
            //       .max(100, 'Revenue share must be equal or less than 100'),
            //     partialReimbursementFixed: Yup.number()
            //       .required('Partial reimbursement is required')
            //       .moreThan(0, 'Partial reimbursement fixed should be greater than 0'),
            //     partialReimbursement: Yup.number().nullable().notRequired(),
            //   }),
            // ),
          }),
      otherwise: () => Yup.array<SPPlanCategoryMapping>(),
      //   .of(
      //   Yup.object().shape({
      //     planCategoryId: Yup.string().required('Extend Category is required'),
      //     spPlanId: Yup.string().required('Shipping Protection Plan is required'),
      //     revenueShare: Yup.number()
      //       .required('Revenue share is required')
      //       .moreThan(0, 'Revenue share must be greater than 0')
      //       .max(100, 'Revenue share must be equal or less than 100'),
      //     partialReimbursement: Yup.number(),
      //   }),
      // ),
    }),
    planId: Yup.string().required('Plan ID is required'),
    merchantSpRevenueSharePercentage: Yup.number()
      .required('Must enter a revenue share percentage')
      .default(0)
      .min(0, 'Revenue share must be greater than or equal to 0')
      .max(100, 'Revenue share must be equal or less than 100'),
    partialReimbursementSPValue: Yup.number()
      .default(undefined)
      .when(['partialReimbursementEnabled', 'partialReimbursementSelection'], {
        is: (enabled: boolean, selection: string) => enabled && selection === 'percentage',
        then: () =>
          Yup.number()
            .required('Partial reimbursement percentage is required')
            .min(0, 'Partial reimbursement percentage must be greater than 0')
            .max(100, 'Partial reimbursement percentage must be equal or less than 100'),
      })
      .when(['partialReimbursementEnabled', 'partialReimbursementSelection'], {
        is: (enabled: boolean, selection: string) => enabled && selection === 'fixed',
        then: () =>
          Yup.number()
            .required('Fixed Partial reimbursement is required')
            .min(0, 'Partial reimbursement fixed must be greater than 0'),
      })
      .nullable(),
    offersByCategoryEnabled: Yup.boolean(),
  })
  .defined()

// workaround for regular Yup.InferType not working with conditional "when" schema properties
type Values = ReturnType<typeof schema.validateSync>

export type { Values }
export {
  schema,
  getInitialPurchaseModelValues,
  purchaseModelLabels,
  getInitialPriceBandValues,
  mapFormValuesToPriceBands,
  mapFormValuesToCategories,
  getInitialCategoryMappingValues,
  partialReimbursementOptions,
}
