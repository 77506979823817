import type { CSSObject } from '@emotion/styled'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

const commonProps: CSSObject = {
  fontFamily: 'Nunito Sans, sans-serif',
  fontWeight: 700,
  COLOR: COLOR.BLUE[1000],
}

/**
 * @deprecated Use Zen DisplayLarge component instead: `import { DisplayLarge } from '@extend/zen'`
 */
const H1 = styled.h1({
  ...commonProps,
  fontSize: 32,
  margin: '10px 0',
})

/**
 * @deprecated Use Zen HeadingLarge component instead: `import { HeadingLarge } from '@extend/zen'`
 */
const H2 = styled.h2({
  ...commonProps,
  fontSize: 24,
  margin: '8px 0',
})

export { H1, H2 }
