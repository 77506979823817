import type { Store } from '@helloextend/extend-api-client'
import {
  Button,
  ButtonGroup,
  Grid,
  Information,
  Input,
  InputType,
  Stack,
  Switch,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useFormik } from 'formik'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import { GetFormPropsAndLabels, getInitialValues, schema } from './loyalty-program-form-schema'

type LoyaltyProgramProps = {
  store: Store
}

const LoyaltyProgram: FC<LoyaltyProgramProps> = ({ store }) => {
  const { toast } = useToaster()

  const { shippingProtection } = store

  const [updateStore, { isLoading }] = useUpdateStoreMutation()

  const { values, handleChange, dirty, isValid, errors, resetForm, handleSubmit } = useFormik({
    initialValues: getInitialValues({
      isLoyaltyApproved: shippingProtection?.isLoyaltyApproved,
      loyaltyRevShare: shippingProtection?.loyaltyRevShare,
    }),
    validationSchema: schema,
    validateOnBlur: true,
    onSubmit: async (submitValues) => {
      try {
        const updatedStoreResult = await updateStore({
          storeId: store.id,
          data: {
            shippingProtection: {
              isLoyaltyApproved: submitValues.isLoyaltyApproved,
              loyaltyRevShare: submitValues.loyaltyRevShare,
            },
          },
          version: 'latest',
        }).unwrap()

        resetForm({
          values: getInitialValues({
            isLoyaltyApproved: updatedStoreResult.shippingProtection?.isLoyaltyApproved,
            loyaltyRevShare: updatedStoreResult.shippingProtection?.loyaltyRevShare,
          }),
        })

        toast({
          message: 'Loyalty Program settings saved',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
      } catch {
        toast({
          message: 'Error saving Loyalty Program settings',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    },
  })

  const formProps = useMemo(() => GetFormPropsAndLabels(), [])

  return (
    <Stack spacing={2}>
      <Stack isRow align="center">
        <Switch
          id={formProps.isLoyaltyApproved.property}
          label={formProps.isLoyaltyApproved.label}
          isOn={values.isLoyaltyApproved ?? false}
          onChange={handleChange}
          data-cy="is-loyalty-approved-switch"
        />
        <Information>
          When a customer is part of the merchant loyalty program, they will see the special offer.
          Loyalty program settings override the Purchase Model and Offer Type settings.
        </Information>
      </Stack>
      {values.isLoyaltyApproved && (
        <Grid columns={3}>
          <Input
            id={formProps.loyaltyRevShare.property}
            label={formProps.loyaltyRevShare.label}
            type={InputType.number}
            value={values.loyaltyRevShare?.toString() ?? ''}
            onChange={handleChange}
            placeholder="Enter Percentage"
            suffix="%"
            isError={!!errors.loyaltyRevShare}
            errorFeedback={errors.loyaltyRevShare}
            data-cy="loyalty-rev-share"
          />
        </Grid>
      )}
      {dirty && (
        <ButtonGroup data-cy="action-buttons">
          <Button
            text="Cancel"
            emphasis="medium"
            onClick={() => resetForm()}
            data-cy="cancel-button"
          />
          <Button
            text="Save"
            isDisabled={!isValid}
            onClick={() => handleSubmit()}
            isProcessing={isLoading}
            data-cy="save-button"
          />
        </ButtonGroup>
      )}
    </Stack>
  )
}

export { LoyaltyProgram }
