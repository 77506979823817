import type { SearchWDProductsRequest } from '@helloextend/extend-api-rtk-query'
import {
  useGetWDStoresListQuery,
  useGetMappingStatusOptionsQuery,
  useGetPlanCategoriesQuery,
} from '@helloextend/extend-api-rtk-query'
import type { AdvancedSelectOption } from '@extend/zen'

const optionSort = (a: AdvancedSelectOption, b: AdvancedSelectOption): number =>
  a.display.localeCompare(b.display)

const useLoadOptionData = (): {
  storeOptions:
    | Array<{
        display: string
        value: string
      }>
    | undefined
  storePDRDictionary: Record<string, string[]>
  mappingStatusOptions:
    | Array<{
        display: string
        value: string
      }>
    | undefined
  planCategoryIdOptions:
    | Array<{
        display: string
        value: string
      }>
    | undefined
  isDropdownSelectDataLoading: boolean
} => {
  const { storeOptions, storePDRDictionary, isStoreLoading } = useGetWDStoresListQuery(undefined, {
    selectFromResult: ({ data = { items: [] }, isLoading }) => ({
      storeOptions: data.items
        .map((store) => {
          return {
            display: store.storeName,
            value: store.storeId,
          }
        })
        .sort(optionSort),
      storePDRDictionary: data.items.reduce((acc, store) => {
        acc[store.storeId] = store.digitalPDRArray
        return acc
      }, {} as { [key: string]: string[] }), // Add index signature to allow indexing with a string
      isStoreLoading: isLoading,
    }),
  })
  const { mappingStatusOptions, isMappingStatusLoading } = useGetMappingStatusOptionsQuery(
    undefined,
    {
      selectFromResult: ({ data, isLoading }) => ({
        mappingStatusOptions: data?.mapping_status
          .map((mappingStatus) => {
            return { display: mappingStatus, value: mappingStatus }
          })
          .sort(optionSort),
        isMappingStatusLoading: isLoading,
      }),
    },
  )
  const { planCategoryIdOptions, isPlanCategoryLoading } = useGetPlanCategoriesQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      planCategoryIdOptions: data?.items
        .map((planCategory) => {
          return { display: planCategory.name, value: planCategory.id }
        })
        .sort(optionSort),
      isPlanCategoryLoading: isLoading,
    }),
  })

  const isDropdownSelectDataLoading =
    isMappingStatusLoading || isPlanCategoryLoading || isStoreLoading
  return {
    storeOptions,
    storePDRDictionary,
    mappingStatusOptions,
    planCategoryIdOptions,
    isDropdownSelectDataLoading,
  }
}

const getProductMappingData = (
  selectedStore: string,
  selectedMappingStatus?: string,
  selectedPlanCategory?: string,
): SearchWDProductsRequest => {
  const productFilters: SearchWDProductsRequest = {
    storeId: selectedStore,
  }
  if (selectedMappingStatus) productFilters.mappingStatus = selectedMappingStatus
  if (selectedPlanCategory) productFilters.planCategoryId = selectedPlanCategory
  return productFilters
}

export { useLoadOptionData, getProductMappingData }
