import type { EntitlementsSearchResponse } from '@extend-entitlements-mgmt/client/dist/client/api/client-types'
import type { EntitlementsResponse, CurrencyCode } from '@helloextend/extend-api-client'

export const getRemainingCoverageFromEntitlements = (
  contractEntitlementsResult?: EntitlementsSearchResponse,
): EntitlementsResponse | undefined => {
  if (!contractEntitlementsResult) return undefined

  const { products = [] } = contractEntitlementsResult

  const entitlements = products[0]?.entitlements
  const contract = entitlements?.length && entitlements[0].contract

  if (!contract) {
    console.error(`Contract or entitlements not found`)

    return {
      coverageAmountRemaining: {
        amount: 0,
        currencyCode: 'USD',
      },
    }
  }

  let { remainingLoL } = contract

  if (contract?.type === 'category') {
    remainingLoL = getRemainingCoverageForCategoryEntitlements(products)
  }

  if (remainingLoL === undefined) {
    console.error(`Remaining coverage amount not found for contract`)
  }

  return {
    coverageAmountRemaining: {
      amount: remainingLoL || 0,
      currencyCode: (contract?.currency || 'USD') as CurrencyCode,
    },
  }
}

const getRemainingCoverageForCategoryEntitlements = (
  products: EntitlementsSearchResponse['products'] = [],
) => {
  // Category entitlements have multiple products, so we need to sum
  // the remaining coverage amounts for each product's entitlements
  return products.reduce(
    (productTotal, { entitlements }) =>
      productTotal +
      entitlements.reduce(
        (entitlementTotal, { remainingCoverage }) =>
          entitlementTotal + (remainingCoverage?.validationProps?.remaining || 0),
        0,
      ),
    0,
  )
}
