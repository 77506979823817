import React from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'
import { Badge } from '@extend/zen'
import type { ClaimCode } from '@helloextend/extend-api-client'
import { getValidationErrorMessage } from './utils'

type ManualReviewProps = {
  validationError?: keyof typeof ClaimCode
}

const ManualReview: FC<ManualReviewProps> = ({ validationError }) => {
  const claimMessage = validationError && getValidationErrorMessage(validationError)

  return (
    <Container>
      <ClaimResult>
        <Description>AMP claim result:</Description>
        <Badge
          data-cy="claim-manual-review-badge"
          color="yellow"
          emphasis="medium"
          text="Claim In Review"
        />
      </ClaimResult>
      <Description data-cy="manual-review-description">
        {validationError
          ? claimMessage
          : 'No defined rule in AMP is applied for adjudicating the claim.'}
      </Description>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
})

const ClaimResult = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
})

const Description = styled.p({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
})

export { ManualReview }
