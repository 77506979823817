import React, { useState } from 'react'
import type { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import type { Claim, Contract, ServiceOrder } from '@helloextend/extend-api-client'
import { ClaimType } from '@helloextend/extend-api-client'
import styled from '@emotion/styled'
import {
  useGetMerchantServicingSettingsQuery,
  useSelectFulfillmentMethodMutation,
} from '@helloextend/extend-api-rtk-query'
import type { ReplacementFulfillmentMethod } from '@helloextend/extend-api-rtk-query/src/service-orders/types'
import type { RowOptionProps } from '@extend/zen'
import {
  Button,
  COLOR,
  useToaster,
  Loading,
  ToastColor,
  ToastDuration,
  Stack,
  RowOptionGroup,
  RowOption,
} from '@extend/zen'
import { currency } from '@extend/client-helpers'
import { useDispatch } from 'react-redux'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { ClaimDetailsTabs } from '../tab-section/types'
import { LDFlag } from '../../../../../../constants/ld-flags'

interface SelectFulfillmentContentProps {
  claim: Claim
  serviceOrder: ServiceOrder
  contract: Contract
}

const SelectFulfillmentContent: FC<SelectFulfillmentContentProps> = ({
  claim,
  serviceOrder,
  contract,
}) => {
  const { [LDFlag.AutomatedReplacementFulfillment]: FF_AUTOMATED_REPLACEMENT } = useFlags()
  const { data: merchantServicingSettings, isLoading } = useGetMerchantServicingSettingsQuery({
    sellerId: claim.sellerId,
  })

  const getDefaultPayoutMethod = (): ReplacementFulfillmentMethod => {
    return !merchantServicingSettings?.supportedCustomerFulfillmentMethods ||
      merchantServicingSettings.supportedCustomerFulfillmentMethods.includes('virtual_card')
      ? 'virtual_card'
      : 'direct_payment'
  }

  const calculateVirtualCardBuffer = (): number => {
    if (claim.type === 'shipping_protection' || !serviceOrder.totalItemsValue) return 0

    return serviceOrder.totalItemsValue.amount > 10000
      ? Math.round(serviceOrder.totalItemsValue.amount * 0.2)
      : 2000
  }

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedOption(e.target.value as ReplacementFulfillmentMethod)
  }

  const [selectedOption, setSelectedOption] = useState<ReplacementFulfillmentMethod>(
    getDefaultPayoutMethod(),
  )
  const dispatch = useDispatch()
  const [setPayoutMethod] = useSelectFulfillmentMethodMutation()
  const { toast } = useToaster()

  const handleConfirmPayoutClick = async (): Promise<void> => {
    if (!serviceOrder) return
    try {
      const response = await setPayoutMethod({
        serviceOrderId: serviceOrder?.id,
        fulfillmentSelection: selectedOption,
      })
      if ((response as { error: { status: number; data: unknown } }).error) {
        toast({
          message: 'An error occurred',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
        return
      }
      dispatch(setClaimDetailsToast('Fulfillment option successfully selected!'))
      dispatch(setClaimDetailsActiveView(ClaimDetailsTabs.ServiceOrder))
    } catch (e) {
      toast({
        message: 'An error occurred',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  const generateRowOptionProps = (claimType?: ClaimType): RowOptionProps[] => {
    const props: RowOptionProps[] = []
    if (!merchantServicingSettings) return props
    if (
      !merchantServicingSettings.supportedCustomerFulfillmentMethods ||
      merchantServicingSettings.supportedCustomerFulfillmentMethods.includes('virtual_card')
    ) {
      props.push({
        'data-cy': 'virtual-card-option',
        value: 'virtual_card',
        badgeText: 'Best value',
        label: `Virtual Visa Card for ${contract.sellerName ?? 'store'}`,
        description: 'Issued instantly for your purchase with the same merchant',
      })
    }
    if (
      !merchantServicingSettings.supportedCustomerFulfillmentMethods ||
      merchantServicingSettings.supportedCustomerFulfillmentMethods.includes('direct_payment')
    ) {
      props.push({
        'data-cy': 'direct-payment-option',
        value: 'direct_payment',
        label: 'Bank account transfer',
        description: 'A direct transfer of funds. It takes 3-5 business days to process',
      })
    }

    if (merchantServicingSettings.supportedCustomerFulfillmentMethods?.includes('manual')) {
      props.push({
        'data-cy': 'manual-option',
        value: 'manual',
        label: 'Original product replacement',
        description: 'Replacement product shipped by the merchant, pending availability',
      })
    }

    if (
      merchantServicingSettings.supportedCustomerFulfillmentMethods?.includes(
        'automated_replacement',
      ) &&
      claimType === ClaimType.SHIPPING_PROTECTION &&
      FF_AUTOMATED_REPLACEMENT
    ) {
      props.push({
        'data-cy': 'automated-replacement-option',
        value: 'automated_replacement',
        label: 'Automated replacement',
        description: 'Automatically replaced with the same product, pending availability',
      })
    }
    return props
  }

  if (!merchantServicingSettings) {
    if (isLoading) {
      return <Loading />
    }
    toast({
      message: 'An error occurred',
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.short,
    })
    dispatch(setClaimDetailsActiveView(ClaimDetailsTabs.ServiceOrder))
    return null
  }

  return (
    <Container data-cy="customer-payout-timeline">
      <Stack spacing={2} justify="start">
        <RowOptionGroup
          value={selectedOption}
          name="payout_option"
          onChange={handleOptionChange}
          data-cy="payout-option-group"
        >
          {generateRowOptionProps(serviceOrder.claimType).map((props) => (
            <RowOption key={props.value} {...props} />
          ))}
        </RowOptionGroup>
        {selectedOption !== 'manual' && selectedOption !== 'automated_replacement' && (
          <PaymentContainer>
            <PaymentHeader data-cy="payment-header">
              Payout Total: &nbsp;{' '}
              <span className="amount" data-cy="total-amount">
                {currency.format(
                  (serviceOrder.totalItemsValue?.amount ?? 0) +
                    (selectedOption === 'virtual_card' ? calculateVirtualCardBuffer() : 0),
                )}
              </span>
            </PaymentHeader>
            <PaymentDetailsContainer>
              <p data-cy="base-price">
                Product price: {currency.format(serviceOrder.totalItemsValue?.amount ?? 0)}
              </p>
            </PaymentDetailsContainer>
          </PaymentContainer>
        )}
      </Stack>
      <Footer>
        <Button
          data-cy="confirm-payout-options-button"
          text="Confirm payout option"
          emphasis="high"
          onClick={handleConfirmPayoutClick}
        />
      </Footer>
    </Container>
  )
}

const PaymentContainer = styled.div({
  color: COLOR.NEUTRAL[600],
  fontWeight: 500,
  fontSize: 13,
})

const PaymentHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontWeight: 600,
  '.amount': {
    color: COLOR.NEUTRAL[1000],
    fontWeight: 500,
    fontSize: 15,
  },
})

const Footer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
})

const Container = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  button: {
    display: 'block !important',
  },
  gap: 15,
})

const PaymentDetailsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export { SelectFulfillmentContent }
