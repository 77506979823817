import type { SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'
import { useSortBy } from '@helloextend/client-hooks'
import {
  CellText,
  ColumnHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableSkeleton,
  SolidArrow,
  TableRowCollapsible,
  Pagination as NewPagination,
  PaginationType as NewPaginationType,
} from '@helloextend/merchants-ui'
import type { Column, SearchMode, PaginationDirection } from '@helloextend/merchants-ui'
import { SearchBar } from '../search-bar'
import { EmptyMessage } from './empty-message'
import { DataFilterBar } from '../data-filter-bar'
import { Filters } from './filters'
import type { FilterOptions, FilterValues } from './filters/types'
import type { PaginationType } from '../pagination'
import { Pagination } from '../pagination'

type DataTableSearchMode = SearchMode | 'fuzzy'

/**
 * @param {Array.<Object>}data - data to be used to populate table
 * @param {Column[]}columns - determines the column structure for the table
 */
type DataTableProps<TData extends Record<string, any>> = {
  data: TData[]
  columns: Array<Column<TData>>
  rowClickEvent?: (e: SyntheticEvent, rowData: TData) => void
  emptyMessage?: string
  searchMode?: DataTableSearchMode
  onSearch?: (key: string, value: string) => void
  isLoading?: boolean
  searchPlaceholder?: string
  searchMessage?: string
  searchMessageDetail?: string
  searchTerm?: string
  searchKey?: string
  pageSize?: number
  hasCollapsibleRow?: boolean
  type?: string
  paginationType?: PaginationType
  currPage?: number
  hasNext?: boolean
  hasPrev?: boolean
  collapsibleRowAccessor?: keyof TData
  CollapsibleRow?: React.ComponentType<{ data: TData[keyof TData] }>
  handleSort?: (key: string | null) => void
  sortDirection?: 'asc' | 'desc' | null
  sortKey?: keyof TData | null
  filters?: Record<string, FilterOptions>
  filterValues?: Record<string, FilterValues>
  onFilter?: (filters: Record<string, FilterValues>) => void
  handlePagination?: (paginationDirection: PaginationDirection) => void
  onPageSizeChange?: () => void
  onPrevPage?: () => void
  onNextPage?: () => void
  shouldAutoSubmitOnEmpty?: boolean
  count?: number
}

const DataTable = <TData extends Record<string, any>>({
  data,
  columns,
  rowClickEvent,
  emptyMessage = '',
  searchMode,
  onSearch,
  isLoading,
  searchPlaceholder = '',
  searchMessage = '',
  searchMessageDetail = '',
  pageSize = 25,
  paginationType,
  type,
  currPage = 1,
  hasNext,
  hasPrev,
  hasCollapsibleRow = false,
  CollapsibleRow,
  collapsibleRowAccessor,
  handleSort,
  sortKey = null,
  sortDirection = null,
  onFilter,
  filters,
  filterValues = {},
  handlePagination = () => {},
  onPageSizeChange = () => {},
  onPrevPage = () => {},
  onNextPage = () => {},
  searchTerm = '',
  searchKey,
  shouldAutoSubmitOnEmpty,
  count = 0,
}: DataTableProps<TData>): JSX.Element => {
  const [filteredData, setFilteredData] = useState(data)
  const [hasSearched, setHasSearched] = useState(false)
  const [openRow, setOpenRow] = useState('')
  const { sortedData, sortBy, direction, setSortBy } = useSortBy(filteredData, null, null)

  useEffect(() => {
    setFilteredData(data)
  }, [data, setFilteredData])

  const searchOptions = columns
    .filter((col) => col.canSearch)
    .map((col) => ({
      label: col.Header,
      value: col.accessor as string,
    }))

  const onClickSort = (
    key: string,
    newDirection: 'asc' | 'desc' | null,
    isSelectable?: boolean,
  ): void => {
    if (isSelectable) {
      if (handleSort) {
        handleSort(key)
      } else {
        setSortBy(key, newDirection)
      }
    }
  }

  const handleRowClick = (e: SyntheticEvent, rowData: TData): void => {
    if (typeof rowClickEvent !== 'undefined') {
      rowClickEvent(e, rowData)
    }

    if (hasCollapsibleRow) {
      setOpenRow(openRow === rowData.id ? '' : rowData.id)
    }
  }

  const localSearch = (key: string, value: string): void => {
    const filtered = data.filter((item) => item[key].toLowerCase().includes(value.toLowerCase()))
    setFilteredData(filtered)
  }

  const handleSearch = (key: string, value: string): void => {
    setHasSearched(true)
    if (onSearch) {
      onSearch(key, value)
    } else {
      localSearch(key, value)
    }
  }

  const handleFilterSearch = (e: SyntheticEvent<HTMLInputElement>): void => {
    setHasSearched(true)
    if (searchMode === 'fuzzy' && onSearch) {
      onSearch('', e.currentTarget.value)
    }
  }

  return (
    <>
      {searchMode === 'fuzzy' ? (
        <DataFilterBar
          onChange={handleFilterSearch}
          value={searchTerm}
          placeholder={searchPlaceholder}
        />
      ) : (
        searchOptions.length > 0 && (
          <SearchBar
            id="table-search"
            options={searchOptions}
            onSubmit={handleSearch}
            isLoading={isLoading}
            initialValue={searchTerm}
            initialKey={searchKey}
            shouldAutoSubmitOnEmpty={shouldAutoSubmitOnEmpty}
            data-cy="table-search"
          />
        )
      )}
      {filters && onFilter && (
        <Filters onFilter={onFilter} values={filterValues} filters={filters} />
      )}
      {isLoading && <TableSkeleton columns={columns.length} rows={pageSize} />}
      {sortedData.length > 0 && !isLoading && (
        <Table data-cy="data-table">
          <TableHead>
            {columns.map((column) => (
              <ColumnHead
                data-cy="plans-table-text"
                key={`head-${String(column.accessor)}`}
                columnWidth={column.columnWidth ?? 100}
                active={sortBy === column.accessor}
                isSelectable={column.isSelectable}
                onClick={() =>
                  onClickSort(column.accessor.toString(), direction, column.isSelectable)
                }
              >
                {column.Header}
                {column.isSelectable && (
                  <SolidArrow
                    active={(sortKey ?? sortBy) === column.accessor}
                    direction={sortDirection ?? direction}
                    width="4px"
                  />
                )}
              </ColumnHead>
            ))}
          </TableHead>
          {sortedData.map((row) => (
            <React.Fragment key={row.id as string}>
              <TableBody data-cy="data-table-head">
                <TableRow
                  data-cy="table-row"
                  onClick={(e: SyntheticEvent) => handleRowClick(e, row)}
                >
                  {columns.map((column) => {
                    const cell = row[column.accessor as keyof typeof row]
                    const element = column.Cell?.(row) ?? <CellText>{`${cell ?? '--'}`}</CellText>

                    return (
                      <TableCell
                        columnWidth={column.columnWidth ?? 100}
                        key={String(column.accessor)}
                        isCollapsible={column.isCollapsible}
                      >
                        {element}
                        {column.isCollapsible && (
                          <SolidArrow width="4px" active={openRow === row.id} direction="asc" />
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableBody>
              {hasCollapsibleRow && (
                <TableRowCollapsible open={hasCollapsibleRow && openRow === row.id}>
                  {CollapsibleRow && collapsibleRowAccessor && (
                    <CollapsibleRow data={row[collapsibleRowAccessor]} />
                  )}
                </TableRowCollapsible>
              )}
            </React.Fragment>
          ))}
        </Table>
      )}
      {hasSearched && sortedData.length === 0 && !isLoading && (
        <EmptyMessage header="We can't find a match for that..." message={emptyMessage} />
      )}
      {!hasSearched && searchMode === 'server' && data.length === 0 && !isLoading && (
        <EmptyMessage header={searchMessage} message={searchMessageDetail} />
      )}
      {type && (
        <NewPagination
          paginationType={NewPaginationType.ENHANCED}
          count={count}
          type={type}
          currPage={currPage}
          pageSize={50}
          hasNext={hasNext}
          hasPrev={hasPrev}
          handlePagination={handlePagination}
          onPageSizeChange={onPageSizeChange}
          overrideCountDisplay
        />
      )}
      {paginationType && (
        <Pagination
          count={data.length}
          type={paginationType}
          currPage={currPage}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
          hasNext={hasNext}
          hasPrev={hasPrev}
          pageSize={pageSize}
        />
      )}
    </>
  )
}

export type { DataTableProps }
export { DataTable }
