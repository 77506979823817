import type { FC } from 'react'
import React, { useCallback } from 'react'
import { Input, COLOR } from '@extend/zen'
import styled from '@emotion/styled'
import type { TextFilterValues } from './types'

export type TextFilterProps = {
  onFilterChange: (property: string, values: TextFilterValues | null) => void
  property: string
  values?: TextFilterValues
}

const TextFilter: FC<TextFilterProps> = ({
  property,
  values = {
    type: 'text',
    value: null,
  },
  onFilterChange,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const text = e.target.value

      onFilterChange(
        property,
        text.length > 0
          ? {
              type: 'text',
              value: text,
            }
          : null,
      )
    },
    [property, onFilterChange],
  )

  return (
    <>
      <InputWrapper>
        <Input
          placeholder="Filter"
          id="text-filter"
          onChange={handleChange}
          value={values.value === null ? '' : values.value}
        />
      </InputWrapper>
    </>
  )
}

const InputWrapper = styled.div({
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: COLOR.NEUTRAL[100],
  },
})

export { TextFilter }
