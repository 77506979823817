import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { Checkbox, Modal, Stack, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type {
  CategoryMapping,
  DraftCategoryMapping,
  StoreIdAndName,
} from '@helloextend/extend-api-client'
import { CategoryMappingStatus } from '@helloextend/extend-api-client'
import {
  useCreateBatchCategoryMappingsMutation,
  useListCategoryMappingsQuery,
} from '@helloextend/extend-api-rtk-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../../../../constants/ld-flags'
import { InlineAlerts } from '../inline-alerts'
import { WarrantyStatusSelector } from '../warranty-status-selector'
import { CategorySelector } from '../category-selector'
import { PlanSetSelector } from '../plan-set-selector'
import { NON_WARRANTABLE_INFO, OVERRIDE_MAPPING_DIFFERENT_PLAN_SET } from '../../../lib/constants'
import {
  checkNonUniqueWarrantyStatus,
  checkDifferentPlanSetsForCategory,
} from '../../../lib/validations'

export interface CategoryMapperModalProps {
  toggleModal: () => void
  mappings: DraftCategoryMapping[]
  categories: Array<{ display: string; value: string; planCategoryId?: string }>
  storeIdAndName: StoreIdAndName
}

const CategoryMapperModal: FC<CategoryMapperModalProps> = ({
  toggleModal,
  mappings,
  categories,
  storeIdAndName,
}) => {
  const flags = useFlags()
  const offersFeatures = flags[LDFlag.OffersFeatures]
  const { id: storeId, name: storeName } = storeIdAndName
  // since this same query was used in the table, we can just use the cached data
  const { data: { items: allMappings = [] } = {}, isFetching: isMappingLoading } =
    useListCategoryMappingsQuery({ storeId })
  const [mappingStatus, setMappingStatus] = useState<string>(getMappingStatus(mappings))
  const [isCreateProcessing, setIsCreateProcessing] = useState<boolean>(false)
  const [category, setCategory] = useState<string>(getMappingCategory(mappings))
  const [planCategoryId, setPlanCategoryId] = useState<string>('')
  const [planSetId, setPlanSetId] = useState<string>(getPlanSet(mappings))
  const [create] = useCreateBatchCategoryMappingsMutation()
  const { toast } = useToaster()

  // validations
  const nonUniqueWarrantyStatusWarning = checkNonUniqueWarrantyStatus(mappings)
  const [differentPlanSetWarnings, setDifferentPlanSetWarnings] = useState<string[]>([])
  const [confirmPlanSetOverrideCheckbox, setConfirmPlanSetOverrideCheckbox] =
    useState<boolean>(false)

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsCreateProcessing(true)
      const transformedMappings: DraftCategoryMapping[] = mappings.map(
        ({ id, merchantCategory }) => {
          return buildDraftMapping(
            merchantCategory,
            mappingStatus as CategoryMappingStatus,
            storeId,
            category,
            planSetId,
            planCategoryId,
            id,
          )
        },
      )

      // Await directly on create(transformedMappings) to get the result
      // TODO: [OFFERS-2185] once plan sets with PDR changes are in place, we will need to update this logic.
      // For now you can select a category, but it will not filter the plan set. Also note that plan sets will be decoupled
      // from category mappings.
      const result = await create(transformedMappings)

      // Check if result fulfills the expected shape
      if ('data' in result && Array.isArray(result.data)) {
        const ids = result.data.map((mapping: CategoryMapping) => mapping.id)
        toast({
          message: `Category mapping(s) created: ${ids.join(', ')}`,
          toastColor: ToastColor.blue,
          toastDuration: ToastDuration.short,
        })
      } else {
        throw new Error('Unexpected API response shape')
      }

      setIsCreateProcessing(false)
      toggleModal()
    } catch (e) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.long,
      })
    }
  }

  const handleMappingStatusChange = useCallback((e: AdvancedSelectChangeEvent<string>) => {
    const status = e.target.value
    setMappingStatus(status)
    if (status === CategoryMappingStatus.NON_WARRANTABLE || !status) {
      setCategory('')
      setPlanSetId('')
    }
  }, [])

  const handleCategoryChange = useCallback(
    (e: AdvancedSelectChangeEvent<string>) => {
      if (offersFeatures.pdr?.enabled) {
        const taxonomyCategory = categories.find((cat) => cat.value === e.target.value)
        setPlanCategoryId(taxonomyCategory?.planCategoryId || '')
      }
      setCategory(e.target.value)
      setPlanSetId('')
    },
    [categories, offersFeatures],
  )
  const handlePlanSetChange = useCallback(
    (e: AdvancedSelectChangeEvent<string>) => {
      const newPlanSetId = e.target.value
      setPlanSetId(newPlanSetId)
      setConfirmPlanSetOverrideCheckbox(false)
      // if a plan set is selected, check if the category is mapped to a different plan set
      if (newPlanSetId.length) {
        const warnings = checkDifferentPlanSetsForCategory(
          allMappings,
          category,
          newPlanSetId,
          storeName,
        )
        // if it is, set warning for user to confirm
        if (warnings.length) {
          setDifferentPlanSetWarnings((oldWarnings) => [...oldWarnings, warnings])
        }
      } else {
        setDifferentPlanSetWarnings([])
      }
    },
    [allMappings, category, storeName],
  )

  const isSaveDisabled = (): boolean =>
    isCreateProcessing ||
    (mappingStatus !== CategoryMappingStatus.NON_WARRANTABLE &&
      (!mappingStatus ||
        !category ||
        // PlanSetId only impacts saving when PDR flag is set to false
        (!planSetId && !offersFeatures.pdr.postLaunchEnabled))) ||
    (differentPlanSetWarnings.length > 0 && !confirmPlanSetOverrideCheckbox)

  return (
    <Modal
      heading={getHeading(mappings)}
      onDismissRequest={toggleModal}
      data-cy="category-mapper-modal"
      primaryButtonProps={{
        'data-cy': 'modal-submit',
        onClick: handleSubmit,
        text: 'Save',
        isDisabled: isSaveDisabled(),
        isProcessing: isCreateProcessing || isMappingLoading,
      }}
      secondaryButtonProps={{
        'data-cy': 'modal-cancel',
        onClick: toggleModal,
        text: 'Cancel',
      }}
    >
      <Stack spacing={2}>
        <WarrantyStatusSelector
          isDisabled={isCreateProcessing || isMappingLoading}
          onChange={handleMappingStatusChange}
          value={mappingStatus}
        />
        <CategorySelector
          onChange={handleCategoryChange}
          value={category}
          categories={categories}
          isDisabled={Boolean(!mappingStatus) || isCreateProcessing || isMappingLoading}
          showSelector={showSelector(mappingStatus)}
        />
        <PlanSetSelector
          onChange={handlePlanSetChange}
          planSetId={planSetId}
          category={categories.find((cat) => cat.display === category)?.planCategoryId ?? ''}
          isDisabled={!mappingStatus || !category || isCreateProcessing || isMappingLoading}
          // PlanSetSelector is always hidden when pdr flag is enabled, otherwise applies standard logic
          showSelector={showSelector(mappingStatus) && !offersFeatures.pdr.postLaunchEnabled}
        />
        {Boolean(nonUniqueWarrantyStatusWarning.length) && (
          <InlineAlerts
            alerts={[...nonUniqueWarrantyStatusWarning]}
            alertType="warning"
            data-cy="batch-update-warning"
          />
        )}
        {Boolean(mappingStatus === CategoryMappingStatus.NON_WARRANTABLE) && (
          <>
            <InlineAlerts
              alerts={[NON_WARRANTABLE_INFO]}
              alertType="info"
              data-cy="non-warrantable-disclaimer"
            />
          </>
        )}
        {Boolean(differentPlanSetWarnings.length) && (
          <>
            <InlineAlerts
              alerts={[...differentPlanSetWarnings]}
              alertType="warning"
              data-cy="diff-planset-warning"
            />
            <Checkbox
              data-cy="confirm-plan-set-override-checkbox"
              checked={confirmPlanSetOverrideCheckbox}
              onChange={() => setConfirmPlanSetOverrideCheckbox(!confirmPlanSetOverrideCheckbox)}
              label={OVERRIDE_MAPPING_DIFFERENT_PLAN_SET}
            />
          </>
        )}
      </Stack>
    </Modal>
  )
}

export const showSelector = (warrantyStatus: string): boolean =>
  warrantyStatus !== CategoryMappingStatus.NON_WARRANTABLE

export const getMappingStatus = (mappings: DraftCategoryMapping[]): string =>
  mappings.length === 1 && mappings[0].mappingStatus !== CategoryMappingStatus.UNMAPPED
    ? mappings[0].mappingStatus
    : ''

export const getMappingCategory = (mappings: DraftCategoryMapping[]): string =>
  mappings.length === 1 && mappings[0].extendCategory ? mappings[0].extendCategory : ''

export const getPlanSet = (mappings: DraftCategoryMapping[]): string =>
  mappings.length === 1 && mappings[0].planSetId ? mappings[0].planSetId : ''

export const getHeading = (mappings: DraftCategoryMapping[]): string =>
  mappings.length > 1
    ? `Mapping ${mappings.length} Merchant Categories`
    : `Mapping Merchant Category: ${mappings[0].merchantCategory}`

export const buildDraftMapping = (
  merchantCategory: string,
  mappingStatus: CategoryMappingStatus,
  storeId: string,
  category: string,
  planSetId: string,
  planCategoryId: string,
  id?: string,
): DraftCategoryMapping => ({
  storeId,
  mappingStatus,
  merchantCategory,
  id,
  planCategoryId,
  ...(mappingStatus === CategoryMappingStatus.WARRANTABLE
    ? { extendCategory: category, planSetId }
    : {}),
})

export { CategoryMapperModal }
