import type { FC } from 'react'
import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { AdvancedSelect, COLOR, Modal, ModalController, Spinner, Paragraph } from '@extend/zen'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { useAddPlanMappingsMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { listToAdvancedSelectOptions } from '../../../../../../../../utils/form-utils'

type AddPlanModalProps = {
  storeId: string
  isModalOpen: boolean
  isLoading?: boolean
  planIds?: string[]
  closeModal: () => void
}

const schema = Yup.object()
  .shape({
    plans: Yup.array().of(Yup.string()),
  })
  .defined()

const AddPlanModal: FC<AddPlanModalProps> = ({
  storeId,
  isModalOpen,
  closeModal,
  isLoading,
  planIds,
}) => {
  const planIdsOptions = useMemo(() => listToAdvancedSelectOptions(planIds || []), [planIds])

  const { toastCompleted, toastError } = useStandardToast()

  const { values, handleChange, handleReset, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    initialValues: {
      plans: [],
    },
    onSubmit: () => {},
  })

  const [addPlan, { isLoading: isPlanAdding }] = useAddPlanMappingsMutation()

  const handleCloseModal = (): void => {
    closeModal()
    resetForm()
  }

  const handleAddPlan = async (): Promise<void> => {
    try {
      const response = await addPlan({
        storeId,
        data: values.plans,
      }).unwrap()

      interface AddPlanResponse {
        planId: string
        reason: string
      }

      if (response.errors) {
        const errorResponse = JSON.parse(JSON.stringify(response.errors)) as AddPlanResponse[]

        for (let i = 0; i < errorResponse.length; i++) {
          toastError(
            `Plan ${errorResponse[i].planId} failed to add. Reason: ${errorResponse[i].reason}`,
          )
        }
      }

      if (response.created) {
        const createdResponse = JSON.parse(JSON.stringify(response.created)) as AddPlanResponse[]

        for (let i = 0; i < createdResponse.length; i++) {
          toastCompleted(`Plan ${createdResponse[i]} has been successfully added`)
        }
      }

      handleCloseModal()
    } catch (error) {
      toastError('Something went wrong. Please try again.')
    }
  }

  return (
    <ModalController isOpen={isModalOpen}>
      <Modal
        heading="Add Plan"
        size="md"
        onDismissRequest={handleCloseModal}
        primaryButtonProps={{
          onClick: handleAddPlan,
          text: 'Add',
          'data-cy': 'add-plan-modal-add-button',
          isProcessing: isPlanAdding,
          isDisabled: !values.plans.length,
        }}
        secondaryButtonProps={{
          onClick: handleCloseModal,
          text: 'Cancel',
          'data-cy': 'add-plan-modal-cancel-button',
        }}
      >
        <ParagraphWrapper>
          The added plans are the only plans can be used to mapped out the products in this store.
        </ParagraphWrapper>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner color={COLOR.BLUE[800]} />
          </SpinnerContainer>
        ) : (
          <AdvancedSelect
            label="Select Plans"
            options={planIdsOptions || []}
            multiple
            showSearch
            onChange={handleChange}
            value={values.plans}
            id="plans"
            data-cy="select-plans"
            placeholder="Select"
            maxQuantityToDisplay={10}
            pageSize={25}
            footerActions={[
              {
                onClick: handleReset,
                children: 'Clear Selection',
                'data-cy': 'add-plan-clear-selection',
                disableAutoDismiss: true,
              },
            ]}
          />
        )}
      </Modal>
    </ModalController>
  )
}

const ParagraphWrapper = styled(Paragraph)({
  marginBottom: 16,
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export { AddPlanModal }
