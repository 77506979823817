import type { FC, ChangeEvent, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Label } from '@extend/zen'
import type { CurrencyCode } from '@helloextend/extend-api-client'
import { CurrencyInput } from './currency-input'

interface CurrencyProps {
  codeFieldName: string
  codeValue: CurrencyCode
}

interface CurrencyInputGroupProps {
  autoComplete?: string
  errorMessage?: string | null | false
  isDisabled?: boolean
  isCurrencyCodeDisabled?: boolean
  isRequired?: boolean
  label?: string
  name: string
  onBlur?: (e: SyntheticEvent<any> & ChangeEvent<any>) => void
  onChange?: (name: string, value: any) => void // expecting formik.setFieldValue
  invalid?: boolean
  type?: string
  value?: number
  showSymbol?: boolean
  placeholder?: string
  justifyStart?: boolean
  currencyProps: CurrencyProps
  autoFocus?: boolean
  'data-cy'?: string
}

const CurrencyInputGroup: FC<CurrencyInputGroupProps> = ({
  errorMessage = '',
  isDisabled = false,
  isCurrencyCodeDisabled = false,
  label,
  name,
  value,
  showSymbol = true,
  invalid,
  placeholder,
  onChange,
  onBlur,
  currencyProps,
  autoFocus,
  justifyStart = false,
  'data-cy': dataCy,
}) => (
  <FormGroup justifyStart={justifyStart}>
    <Label htmlFor={name}>{label}</Label>
    <CurrencyGroupWrapper>
      <CurrencyInput
        data-cy={dataCy && `${dataCy}:currency-input`}
        disabled={isDisabled}
        name={name}
        onChange={(changeVal) => {
          if (onChange) {
            onChange(name, changeVal)
          }
        }}
        onBlur={onBlur}
        showSymbol={showSymbol}
        invalid={invalid}
        value={value}
        currencyCode={currencyProps.codeValue || 'USD'}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
      <CurrencyCodeField
        type="text"
        disabled={isCurrencyCodeDisabled || isDisabled}
        name={currencyProps.codeFieldName}
        value={currencyProps.codeValue || 'USD'}
        onChange={(e) => {
          if (onChange) {
            onChange(currencyProps.codeFieldName, e.target.value)
          }
        }}
        onBlur={onBlur}
      />
    </CurrencyGroupWrapper>
    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </FormGroup>
)

const CurrencyCodeField = styled.input({
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  borderLeft: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: 10,
  paddingRight: 10,
  fontSize: 16,
  height: 38,
  padding: '0 12px',
  fontFamily: 'inherit',
  textAlign: 'center',
  width: '25%',
  '&:focus': {
    outline: 'none',
    borderColor: `${COLOR.BLUESKY}`,
    boxShadow: `0 0 0 1px inset ${COLOR.BLUESKY}`,
  },
  '&:disabled': {
    borderColor: COLOR.NEUTRAL[300],
    background: COLOR.NEUTRAL[100],
  },
})

const CurrencyGroupWrapper = styled.div({
  display: 'flex',
})

const FormGroup = styled.div<{ justifyStart?: boolean }>(({ justifyStart = false }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: justifyStart ? 'start' : 'center',
  gap: 4,
}))

export const ErrorMessage = styled.div({
  color: COLOR.RED[700],
  fontFamily: 'Nunito Sans',
  fontSize: 15,
  lineHeight: '20px',
  marginBottom: 16,
})

export type { CurrencyInputGroupProps }
export { CurrencyInputGroup }
