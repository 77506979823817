import * as Yup from 'yup'

export const rateSchema = Yup.object()
  .shape({
    type: Yup.string().required('Type is required').default(''),
    description: Yup.string().required('Description is required').default(''),
    qtyLow: Yup.number().required('Quantity Low is required'),
    qtyHigh: Yup.number(),
    costLow: Yup.object()
      .shape({
        amount: Yup.number().required('Cost Low is required'),
        currencyCode: Yup.string().default('USD').optional(),
        formattedAmount: Yup.string().default('').optional(),
      })
      .defined(),
    costHigh: Yup.object().shape({
      amount: Yup.number().optional(),
      currencyCode: Yup.string().default('USD').optional(),
      formattedAmount: Yup.string().default('').optional(),
    }),
    rate: Yup.object().shape({
      amount: Yup.number().optional(),
      currencyCode: Yup.string().default('USD').optional(),
      formattedAmount: Yup.string().default('').optional(),
    }),
  })
  .defined()

export const schema = Yup.object()
  .shape({
    isActive: Yup.boolean().default(true).required(),
    startDate: Yup.mixed<number>()
      .test({
        name: 'isValidDate',
        message: 'Start Date is required',
        test: (value) => value != null && value !== undefined && value > 0,
      })
      .default(undefined),
    endDate: Yup.mixed<number>().default(undefined),
    rates: Yup.array().of(rateSchema).default([]),
  })
  .defined()

export type Values = Yup.InferType<typeof schema>
export type RateValues = Yup.InferType<typeof rateSchema>
