import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import type { Contract20220201GetResponse } from '@helloextend/extend-api-client'
import { date } from '@extend/client-helpers'
import { useResendContractEmailMutation } from '@helloextend/extend-api-rtk-query'
import { Grid, DataProperty, Button, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { usePrevious } from '@helloextend/client-hooks'
import { BasicModal } from '../../../../../components/basic-modal'

export interface ResendDetailsModalProps {
  isVisible?: boolean
  contract?: Contract20220201GetResponse
  toggle: () => void
}

const ResendDetailsModal: FC<ResendDetailsModalProps> = ({
  isVisible = false,
  contract,
  toggle,
}) => {
  const { toast } = useToaster()
  const [sendEmail, { isSuccess, isLoading }] = useResendContractEmailMutation()
  const prevSuccess = usePrevious(isSuccess)

  const handleSubmit = (): void => {
    sendEmail({ contractId: contract?.id ?? '' })
  }

  useEffect(() => {
    if (isSuccess && !prevSuccess) {
      toast({
        message: 'Email sent!',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
      toggle()
    }
  }, [isSuccess, prevSuccess, toggle, toast])

  if (!contract) return null

  return (
    <BasicModal onClickClose={toggle} isVisible={isVisible} width="640px" data-cy="resend-modal">
      <Title>Are you sure you want to resend this contract&apos;s email notification?</Title>
      <Grid columns={1}>
        <DataProperty label="Contract ID" value={contract.id} data-cy="contract-id" />
        <DataProperty label="Seller" value={contract.sellerName} data-cy="contract-seller" />
        <DataProperty
          label="Transaction Date"
          value={contract.purchaseDate ? date.format(contract.purchaseDate / 1000) : '—'}
          data-cy="contract-transaction-date"
        />
        <DataProperty
          label="Customer Name"
          value={contract.customer.name}
          data-cy="contract-customer-name"
        />
        <DataProperty
          label="Customer Email"
          value={contract.customer.email}
          data-cy="contract-customer-email"
        />
      </Grid>
      <ButtonGroup>
        <Button onClick={toggle} text="Cancel" emphasis="medium" isDisabled={isLoading} />
        <Button
          onClick={handleSubmit}
          text="Send Email"
          data-cy="send-email-button"
          isProcessing={isLoading}
          isDisabled={isLoading}
        />
      </ButtonGroup>
    </BasicModal>
  )
}

const Title = styled.h3({
  fontSize: 20,
  marginTop: 0,
  marginBottom: 16,
})

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 8,
  marginTop: 40,
})

export { ResendDetailsModal }
