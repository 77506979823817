import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { TableCell } from '../../../../../../../../components/table'

const EmptyTableMessage: FC<{ cols: number }> = ({ cols }) => {
  return (
    <EmptyRow data-cy="no-expenses-message">
      <TableCell colspan={cols}>
        <RowText>
          No expenses added. At least one expense must be added to submit an estimate.
        </RowText>
      </TableCell>
    </EmptyRow>
  )
}

const EmptyRow = styled.tr({
  borderRight: 0,
  borderLeft: 0,
  borderTop: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
  backgroundColor: 'transparent',
})

const RowText = styled.p({
  fontSize: 13,
  lineHeight: '24px',
  color: COLOR.NEUTRAL[800],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: 'center',
})

export { EmptyTableMessage }
