import type { FC } from 'react'
import React, { useState, useMemo } from 'react'
import type { SortingState, ColumnFiltersState, VisibilityState } from '@extend/zen'
import { HeadingLarge, DataTable } from '@extend/zen'
import type { OrdersSearchOptions, OrderSearchItem } from '@helloextend/extend-api-rtk-query'
import { useSearchOrdersQuery } from '@helloextend/extend-api-rtk-query'
import { ordersTableColumns } from './table-config'
import { prepareOrdersSearchArgs } from './utils'
import { sortItems } from '../../../utils/sort-table-items'

const Orders: FC = () => {
  const [sorting, setSorting] = useState<SortingState>([{ id: 'storeName', desc: false }])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    merchantCustomerId: false,
    poNumber: false,
    totalDiscounts: false,
    productCostTotal: false,
    shippingCostTotal: false,
    taxCostTotal: false,
    total: false,
  })

  const argsForApi: OrdersSearchOptions = useMemo(
    () => prepareOrdersSearchArgs(columnFilters),
    [columnFilters],
  )

  const { data, isFetching, isError } = useSearchOrdersQuery(argsForApi, {
    skip: columnFilters.length === 0,
  })

  const orders = useMemo(() => sortItems(data?.orders || [], sorting), [data?.orders, sorting])

  const handleRowClick = (order: OrderSearchItem): void => {
    window.open(`/admin/orders/${order.id}`, '_blank')
  }

  return (
    <DataTable
      heading={<HeadingLarge>Orders</HeadingLarge>}
      itemName="order"
      data-cy="orders"
      isLoading={isFetching}
      isError={isError}
      data={orders ?? []}
      columns={ordersTableColumns}
      rowCount={orders?.length}
      sorting={sorting}
      onSortingChange={setSorting}
      hasManualFiltering
      hasManualSorting
      columnFilters={columnFilters}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      onColumnFiltersChange={setColumnFilters}
      getRowId={(row) => row.id}
      stateViews={{
        preFilter: {
          heading: 'Search for orders',
          description: 'Select your search criteria before submitting.',
        },
      }}
      getRowActions={(row) => [
        {
          onClick: () => handleRowClick(row),
          text: 'View',
          emphasis: 'low',
        },
      ]}
      hasRequiredFilter
    />
  )
}

export { Orders }
