import * as Yup from 'yup'
import { validate } from '@extend/client-helpers'

const schema = Yup.object()
  .shape({
    name: Yup.string(),
    secondaryName: Yup.string(),
    id: Yup.string().required('Store ID is required'),
    accountId: Yup.string().required('Account ID is required'),
    domain: Yup.string().matches(validate.urlRegExp, 'Please enter a valid URL'),
    currencyCode: Yup.string().required().default('USD'),
    adminDealerNumber: Yup.string(),
    adminLocationNumber: Yup.string(),
    adminManufacturer: Yup.string(),
    syncOrderProducts: Yup.boolean(),
    isTestStore: Yup.boolean(),
    unmappedProductContracts: Yup.boolean(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export type { Values }
export { schema }
