import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { generateModalWarningText } from '../../threads/warning-text-generator'
import type { AMPModalType } from '../../utils'

type ModalWarningTextProps = {
  modalType: AMPModalType
  threadTitle: string
  hasReferencedConversations: boolean
}

const ModalWarningText: FC<ModalWarningTextProps> = ({
  modalType,
  threadTitle,
  hasReferencedConversations,
}) => {
  return (
    <Text data-cy="amp-modal-warning-text">
      {generateModalWarningText(modalType, threadTitle, hasReferencedConversations)}
    </Text>
  )
}

const Text = styled.p({
  color: COLOR.NEUTRAL[800],
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
})

export type { ModalWarningTextProps }
export { ModalWarningText }
