import type { FC } from 'react'
import React from 'react'
import type { AdvancedSelectChangeEvent, AdvancedSelectOption } from '@extend/zen'
import { AdvancedSelect } from '@extend/zen'
import { useListPlanSetsQuery } from '@helloextend/extend-api-rtk-query'
import type { PlanSet } from '@helloextend/extend-api-client'

export interface PlanSetSelectorProps {
  onChange: (e: AdvancedSelectChangeEvent<string>) => void
  planSetId: string
  category: string
  isDisabled: boolean
  showSelector: boolean
}

const PlanSetSelector: FC<PlanSetSelectorProps> = ({
  onChange,
  planSetId,
  category,
  isDisabled,
  showSelector,
}) => {
  const { data: planSets = [], isFetching = false } = useListPlanSetsQuery(
    category ? `category=${category}` : '',
  )

  if (!showSelector) return null

  return (
    <AdvancedSelect
      id="plan-set-select"
      data-cy="plan-set-select"
      label="Plan Set"
      placeholder="Select"
      onChange={onChange}
      value={planSetId}
      options={getPlanSetOptions(planSets)}
      multiple={false}
      isDisabled={isDisabled}
      isLoading={Boolean(category && isFetching)}
    />
  )
}

export const getPlanSetOptions = (planSets: PlanSet[]): AdvancedSelectOption[] =>
  planSets.map((planSet) => ({
    display: planSet.name ?? 'No name found!',
    value: planSet.id,
  }))

export { PlanSetSelector }
