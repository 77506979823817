import type { FC } from 'react'
import React, { useCallback } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import {
  Checkbox,
  Grid,
  GridItem,
  Select,
  Button,
  useToaster,
  ToastDuration,
  ToastColor,
} from '@extend/zen'
import { useUpdateOffersStoreConfigurationMutation } from '@helloextend/extend-api-rtk-query'
import type { OffersStoreConfig } from '@helloextend/extend-api-client'
import { schema } from './offer-surface-management-form-schema'
import {
  mapOfferSurfaceVersionToFormValues,
  mapOfferSurfaceVersionFromFormValues,
} from './offer-surface-management-helpers'

export interface OfferSurfaceManagementProps {
  storeId: string
  offersStoreConfig?: OffersStoreConfig
}

export const OfferSurfaceManagement: FC<OfferSurfaceManagementProps> = ({
  storeId,
  offersStoreConfig,
}) => {
  const { toast } = useToaster()
  const surfaceVersion = offersStoreConfig?.surfaceVersion
  const [updateOffersStoreConfig] = useUpdateOffersStoreConfigurationMutation()

  const formik = useFormik({
    initialValues: mapOfferSurfaceVersionToFormValues(surfaceVersion),
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const payload = mapOfferSurfaceVersionFromFormValues(values)
        const updatedOfferStoreConfig: OffersStoreConfig = {
          ...offersStoreConfig,
          surfaceVersion: payload,
          storeId,
        }

        await updateOffersStoreConfig({
          offersStoreConfig: updatedOfferStoreConfig,
          storeId,
        }).unwrap()

        formik.resetForm({
          values: mapOfferSurfaceVersionToFormValues(updatedOfferStoreConfig.surfaceVersion),
        })

        toast({
          message: 'Offer surface configuration updated.',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
      } catch (error) {
        toast({
          message: 'Failed to update offer surface configuration',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    },
  })

  const handleCancel = useCallback(() => formik.resetForm(), [])
  const isDisabled = !formik.dirty || !formik.isValid

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid columns={3} spacing={6}>
        <GridItem>
          <Checkbox
            label="Learn More Modal"
            name="isLearnMoreModal"
            data-cy="learn-more-modal-checkbox"
            checked={formik.values.isLearnMoreModal}
            onChange={formik.handleChange}
          />
          <Select
            id="learnMoreModalSelect"
            label="Version"
            data-cy="learn-more-modal-select"
            onChange={formik.handleChange}
            isDisabled={!formik.values.isLearnMoreModal}
            placeholder="Select"
            value={formik.values.learnMoreModalSelect}
            isError={!!formik.errors.learnMoreModalSelect}
            errorFeedback={formik.errors.learnMoreModalSelect}
            subtext={`Content type: ${
              surfaceVersion?.learnMoreModal?.contentType ?? 'learnMoreModal'
            }`}
            disablePlaceholder
          >
            <option value="V1">V1</option>
          </Select>
        </GridItem>

        <GridItem>
          <Checkbox
            label="Offer Cart"
            name="isOfferCart"
            data-cy="offer-cart-checkbox"
            checked={formik.values.isOfferCart}
            onChange={formik.handleChange}
          />
          <Select
            id="offerCartSelect"
            label="Version"
            onChange={formik.handleChange}
            data-cy="offer-cart-select"
            placeholder="Select"
            isDisabled={!formik.values.isOfferCart}
            value={formik.values.offerCartSelect}
            isError={!!formik.errors.offerCartSelect}
            errorFeedback={formik.errors.offerCartSelect}
            subtext={`Content type: ${surfaceVersion?.offerCart?.contentType ?? 'offerTypeCTA'}`}
            disablePlaceholder
          >
            <option value="V1">V1</option>
            <option value="V2">V2</option>
          </Select>
        </GridItem>

        <GridItem>
          <Checkbox
            label="Offer Modal"
            name="isOfferModal"
            data-cy="offer-modal-checkbox"
            checked={formik.values.isOfferModal}
            onChange={formik.handleChange}
          />
          <Select
            id="offerModalSelect"
            label="Version"
            onChange={formik.handleChange}
            data-cy="offer-modal-select"
            placeholder="Select"
            isDisabled={!formik.values.isOfferModal}
            value={formik.values.offerModalSelect}
            isError={!!formik.errors.offerModalSelect}
            errorFeedback={formik.errors.offerModalSelect}
            subtext={`Content type: ${surfaceVersion?.offerModal?.contentType ?? 'offerTypeModal'}`}
            disablePlaceholder
          >
            <option value="V1">V1</option>
            <option value="V2">V2</option>
          </Select>
        </GridItem>

        <GridItem>
          <Checkbox
            label="Product Details Page"
            name="isProductDetailsPage"
            data-cy="product-details-page-checkbox"
            checked={formik.values.isProductDetailsPage}
            onChange={formik.handleChange}
          />
          <Select
            id="productDetailsPageSelect"
            label="Version"
            onChange={formik.handleChange}
            data-cy="product-details-page-select"
            isDisabled={!formik.values.isProductDetailsPage}
            value={formik.values.productDetailsPageSelect}
            isError={!!formik.errors.productDetailsPageSelect}
            errorFeedback={formik.errors.productDetailsPageSelect}
            subtext={`Content type: ${surfaceVersion?.pdp?.contentType ?? 'offerTypeButtons'}`}
            disablePlaceholder
          >
            <option value="V1">V1</option>
            <option value="V2">V2</option>
          </Select>
        </GridItem>

        <GridItem>
          <Checkbox
            label="Order Confirmation Offer"
            name="isOrderConfirmationOffer"
            data-cy="order-confirmation-offer-checkbox"
            checked={formik.values.isOrderConfirmationOffer}
            onChange={formik.handleChange}
          />
          <Select
            id="orderConfirmationOfferSelect"
            label="Version"
            onChange={formik.handleChange}
            data-cy="order-confirmation-offer-select"
            isDisabled={!formik.values.isOrderConfirmationOffer}
            value={formik.values.orderConfirmationOfferSelect}
            isError={!!formik.errors.orderConfirmationOfferSelect}
            errorFeedback={formik.errors.orderConfirmationOfferSelect}
            subtext={`Content type: ${
              surfaceVersion?.orderConfirmationOffer?.contentType ?? 'orderConfirmationOfferV1'
            }`}
            disablePlaceholder
          >
            <option value="V1">V1</option>
          </Select>
        </GridItem>
      </Grid>
      <Row>
        {formik.dirty && (
          <>
            <Button emphasis="medium" text="Cancel" onClick={handleCancel} />
            <Button type="submit" text="Save" isDisabled={isDisabled} />
          </>
        )}
      </Row>
    </form>
  )
}

const Row = styled.div({
  marginTop: '2rem',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
  flexDirection: 'row',
})
