import * as Yup from 'yup'
import { validate } from '@extend/client-helpers'
import {
  validateBSDs,
  validateARs,
} from '../../../utils/allowed-region-blocked-subdivision-validation'
import { hasOverlappingPriceBands, hasGapsInPriceband } from '../../../utils/pricing-sku-validation'

// workaround since Yup allows spaces mid-number
const whitespaceTransform = (value: number, originalValue: string): number =>
  /\s/.test(originalValue) ? NaN : value

// transforms empty strings to null to avoid parsing "''" as an empty string / 0
const nullTransform = (
  value: number | string | null,
  originalValue: number | string | null,
): number | null => {
  if (typeof originalValue === 'string' && originalValue === '') return null
  return Number(value)
}

const pricingRowSchema = Yup.object({
  vendorSku: Yup.string().required('Vendor SKU is required'),
  priceBandLow: Yup.number().required('Price Band Low is required'),
  priceBandHigh: Yup.number().required('Price Band High is required'),
  cost: Yup.number().required('Cost is required'),
  retailTarget: Yup.number().required('Retail Target is required'),
  fixedPrice: Yup.number().transform(nullTransform).nullable(),
  retailMax: Yup.number().required('Retail Max is required'),
})
  .required()
  .test('overlapping pricebands', '', function testOverlappingPricebands(value) {
    const pricing = this.parent
    const containsOverlappingPricebands = hasOverlappingPriceBands(pricing, value)
    return containsOverlappingPricebands
      ? this.createError({
        message: 'Pricebands must not overlap',
        path: `${this.path}.priceBandLow`,
      })
      : true
  })
  .test('gaps in pricebands', '', function testGapsInPricebands(value) {
    const pricing = this.parent
    const containsPricebandsGap = hasGapsInPriceband(pricing, value)
    return containsPricebandsGap
      ? this.createError({
        message: 'Pricebands cannot have gaps',
        path: `${this.path}.priceBandLow`,
      })
      : true
  })

// TODO: Remove unnecessary validation (for no whitespace) from dropdown fields once the dropdowns aren't wrapped in a feature flag
const schema = Yup.object()
  .shape({
    id: Yup.string()
      .required('Plan ID is required')
      .test('whitespace', 'Spaces are not allowed', (value): boolean => {
        return !value || !validate.hasWhitespace(value.trim())
      }).default(''),
    name: Yup.string().required('Plan name is required').default(''),
    adjudicationCategory: Yup.string().required('Adjudication Category is required').default(''),
    secondaryName: Yup.string().required('Secondary Name is required').default(''),
    coverageStarts: Yup.string().required('Coverage Starts is required').default(''),
    coverageIncludes: Yup.string().required('Coverage Includes is required').default(''),
    termLength: Yup.number()
      .typeError('Please enter a number between 1 and 999')
      .transform(whitespaceTransform)
      .min(1, 'Please enter a number between 1 and 999')
      .max(999, 'Please enter a number between 1 and 999')
      .required('Term Length is required')
      .nullable()
      .default(null),
    active: Yup.boolean()
      .required('Active is required')
      .typeError('Please enter a boolean (true or false)')
      .nullable()
      .default(null),
    underwriter: Yup.string().required('Underwritter is required').default(''),
    serviceType: Yup.string().required('Service Type is required').default(''),
    replacementType: Yup.string().notRequired(),
    deductible: Yup.number()
      .notRequired()
      .typeError('Please enter a number')
      .transform(whitespaceTransform)
      .default(null),
    administrator: Yup.string().notRequired(),
    termsId: Yup.string().required('Terms Id is required').default(''),
    servicerId: Yup.string()
      .notRequired()
      .test('whitespace', 'Spaces are not allowed', (value): boolean => {
        return !value || !validate.hasWhitespace(value.trim())
      }),
    productNotes: Yup.string().notRequired(),
    currencyCode: Yup.string().required('Currency Code is required').default(''),
    allowedRegions: Yup.string()
      .required('Allowed Regions is required')
      .default('')
      .test(
        'leading or trailing spaces',
        'No leading or trailing spaces are allowed',
        (value): boolean => {
          return value === value?.trim()
        },
      )
      .test('allowed_regions field is valid', '', function testAllowedRegionInput(value) {
        const message = validateARs(value || '')
        return message ? this.createError({ message }) : true
      }),
    blockedSubDivisions: Yup.string()
      .test(
        'leading or trailing spaces',
        'No leading or trailing spaces are allowed',
        (value): boolean => {
          return value === value?.trim()
        },
      )
      .test(
        'blocked_sub_divisions field is valid',
        '',
        function testBlockedSubdivisionInput(value) {
          const ARFieldText = this.parent.allowedRegions
          const message = validateBSDs(value || '', ARFieldText || '')
          return message ? this.createError({ message }) : true
        },
      ),
    productTypes: Yup.string()
      .required('Product Types is required')
      .default('')
      .test('whitespace', 'Spaces are not allowed', (value): boolean => {
        return !value || !validate.hasWhitespace(value.trim())
      }),
    vendor: Yup.string().required('Vendor is required').default(''),
    pcmiCategory: Yup.string().required('PCMI Category is required').default(''),
    pcmiSubcategory: Yup.string().required('PCMI Subcategory is required').default(''),
    tags: Yup.string().notRequired(),
    repairThreshold: Yup.number()
      .typeError('Please enter a number')
      .integer('Please enter a whole dollar amount')
      .moreThan(-1, 'Please enter a positive number')
      .notRequired()
      .transform(whitespaceTransform)
      .transform(nullTransform)
      .nullable()
      .default(null),
    program: Yup.string().required('Program is required').default(''),
    subProgram: Yup.string().required('Sub-Program is required').default(''),
    planCategory: Yup.string().required('Plan Category is required').default(''),
    wdTags: Yup.string()
      .test(
        'leading or trailing spaces',
        'No leading or trailing spaces are allowed',
        (value): boolean => {
          return value === value?.trim()
        },
      ),
    pricing: Yup.array().of(pricingRowSchema).required().default([]),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export type { Values }
export { schema }
