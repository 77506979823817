import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.search>

export default function* search(action: Action): SagaIterator {
  const { accessToken, filter, limit, cursor } = action.payload

  yield put(actions.searchRequest())

  const response = yield call(client.plans.search, accessToken, filter, limit, cursor)

  if (isErrorResponse(response)) {
    yield put(actions.searchFailure(response.data.message, response.status))
    return
  }

  yield put(actions.searchSuccess(response.data.items, response.data.nextPageCursor || null))
}
