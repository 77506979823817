export enum ClaimDetailsTabs {
  ServiceOrder = 'serviceOrder',
  Expenses = 'expenses',
  Attachments = 'attachments',
  IncidentReport = 'incidentReport',
  Adjudication = 'adjudication',
  FraudReport = 'fraudReport',
}

export enum ClaimPageTabs {
  ClaimDetails = 'claimDetails',
  ClaimHistory = 'claimHistory',
}
