import { useListAllStoreIdsWithNameQuery } from '@helloextend/extend-api-rtk-query'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { AdvancedSelect, DataProperty, DataPropertyType } from '@extend/zen'
import type { FC } from 'react'
import React, { useMemo } from 'react'

type StoresDropdownProps = {
  onChange: (e: AdvancedSelectChangeEvent) => void
  value?: string
}

const StoresDropdown: FC<StoresDropdownProps> = ({ onChange, value = '' }) => {
  const { data, isLoading } = useListAllStoreIdsWithNameQuery()

  const options = useMemo(() => {
    return data
      ? data.map((item) => ({ display: `${item.name} - ${item.id}`, value: item.id }))
      : [{ display: '', value: '' }]
  }, [data])

  if (isLoading)
    return (
      <DataProperty
        label="Store ID"
        type={DataPropertyType.string}
        data-cy="configuration-storeId-data-property"
        isLoading={isLoading}
      />
    )
  return (
    <AdvancedSelect
      id="storeId"
      label="Store ID"
      placeholder={value === '' ? 'Select' : value}
      value={value}
      onChange={onChange}
      options={options}
      data-cy="configuration-storeId-selector"
      showSearch
      maxQuantityToDisplay={10}
      pageSize={25}
      multiple={false}
    />
  )
}

export { StoresDropdown }
