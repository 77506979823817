import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { MessageType } from '@helloextend/extend-api-rtk-query'

export const generateShipmentPicker = (): ScriptItem => ({
  reply: {
    messages: [
      {
        content: '',
        type: MessageType.text,
      },
    ],
    prompt: {
      action: 'replace',
      replace: {
        invoke: 'createShipmentSelectorReply',
        expects: { type: 'object', schema: 'Prompt' },
      },
    },
  },
  collect: {
    options: [
      {
        action: 'execute',
        execute: {
          scriptIndex: -1,
        },
        default: true,
      },
    ],
  },
})
