import React, { useMemo, useState } from 'react'
import type { FC } from 'react'
import {
  Grid,
  Stack,
  Switch,
  AdvancedSelect,
  ToastColor,
  ToastDuration,
  useToaster,
  ModalController,
  ButtonGroup,
  Button,
  Divider,
  DataProperty,
} from '@extend/zen'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import {
  useGetAllCharitiesListQuery,
  useGetMerchantCharityConfigQuery,
  useCreateMerchantCharityConfigMutation,
  useUpdateMerchantCharityConfigMutation,
} from '@helloextend/extend-api-rtk-query'
import { AddCharityModal } from './modals/add-charity-modal'
import { EditCharityModal } from './modals/edit-charity-modal'

export type SelectedCharity = {
  id: string
  name: string
  contactName: string
  contactPhone: string
  contactEmail: string
  address: string
}

interface CharitableDonationsProps {
  storeId: string
}

const emptySelectedCharity: SelectedCharity = {
  id: '',
  name: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  address: '',
}

export const CharitableDonations: FC<CharitableDonationsProps> = ({ storeId }) => {
  const { toast } = useToaster()
  const [isAddCharityModalVisible, setIsAddCharityModalVisible] = useState(false)
  const [isEditCharityModalVisible, setIsEditCharityModalVisible] = useState(false)
  const [enableEditCharitableDonations, setEditCharitableDonations] = useState(false)
  const [selectedCharity, setSelectedCharity] = useState(emptySelectedCharity)
  const closeAddCharityModal = (): void => setIsAddCharityModalVisible(false)
  const closeEditCharityModal = (): void => setIsEditCharityModalVisible(false)
  const { data: allCharities } = useGetAllCharitiesListQuery()
  const { data: merchantCharityConfig, error: merchantCharityConfigError } =
    useGetMerchantCharityConfigQuery({ storeId })

  const [createMerchantCharityConfig] = useCreateMerchantCharityConfigMutation()

  const [updateMerchantCharityConfig, { isLoading: isLoadingMerchantCharityConfigUpdate }] =
    useUpdateMerchantCharityConfigMutation()

  const isMerchantConfigEnabled = useMemo(() => {
    return !!merchantCharityConfig?.enabled
  }, [merchantCharityConfig, merchantCharityConfigError])

  // TODO: Rename this variable to reflect that it is also setting the selected charity with the matching charity or an empty charity
  const isMerchantConfiguredWithCharity = useMemo(() => {
    const merchantCharities = merchantCharityConfig?.charities
    if (!merchantCharities?.length) return false

    const merchantSetCharity = merchantCharities.find(({ enabled }) => enabled)

    const matchingCharity = allCharities?.find(({ id }) => id === merchantSetCharity?.charityId)

    setSelectedCharity(matchingCharity || emptySelectedCharity)
    return true
  }, [merchantCharityConfig, allCharities])

  const handleToggleChange = async (): Promise<void> => {
    try {
      if (!merchantCharityConfig) {
        await createMerchantCharityConfig({
          data: {
            referenceId: storeId,
            referenceType: 'store',
            enabled: true,
            calcType: 'percentage',
            calcPercentage: 0.1,
            calcPercentageDonationMinAmount: 1,
            calcPercentageDonationMaxAmount: 100,
          },
        }).unwrap()

        toast({
          message: 'Charitable Donations have been enabled!',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
      } else {
        await updateMerchantCharityConfig({
          storeId,
          data: { enabled: !isMerchantConfigEnabled },
        }).unwrap()
      }
    } catch (error) {
      toast({
        message: 'There was an error updating the merchant charity config',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  const handleSubmit = async (): Promise<void> => {
    const disableCharities = (merchantCharityConfig?.charities || []).map((charity) => ({
      referenceId: storeId,
      charityId: charity.charityId,
      enabled: false,
    }))

    try {
      await updateMerchantCharityConfig({
        storeId,
        data: {
          charities: [
            ...disableCharities,
            { referenceId: storeId, charityId: selectedCharity.id, enabled: true },
          ],
        },
      }).unwrap()
      toast({
        message: 'Charitable Donations have been successfully saved!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    } catch (error) {
      toast({
        message: 'There was an error updating the merchant charity config',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    } finally {
      setEditCharitableDonations(false)
    }
  }

  const handleCharityListChange = (event: AdvancedSelectChangeEvent): void => {
    const { value } = event.target

    if (value === 'addNew') {
      setIsAddCharityModalVisible(true)
    } else {
      const foundCharity = allCharities?.find((charity) => charity.id === value)

      if (foundCharity) setSelectedCharity(foundCharity)
    }
  }

  const charitiesAdvancedSelectOptions = useMemo(() => {
    if (!allCharities) return []
    return allCharities.map((charity) => ({
      display: charity.name,
      value: charity.id,
    }))
  }, [allCharities])

  const showButtonGroup =
    (!isMerchantConfiguredWithCharity && isMerchantConfigEnabled) ||
    (enableEditCharitableDonations && isMerchantConfigEnabled)

  const disableToggleSwitch =
    !enableEditCharitableDonations && isMerchantConfigEnabled && isMerchantConfiguredWithCharity

  const showEditCharitableDonationButton =
    !enableEditCharitableDonations && isMerchantConfigEnabled && isMerchantConfiguredWithCharity

  const showCharitySelector =
    (isMerchantConfigEnabled && !isMerchantConfiguredWithCharity) ||
    (enableEditCharitableDonations && isMerchantConfigEnabled)

  return (
    <Stack spacing={2}>
      <Stack>
        <Grid columns={2}>
          <Switch
            isDisabled={disableToggleSwitch}
            label="Charitable donations enabled"
            isOn={isMerchantConfigEnabled}
            onChange={handleToggleChange}
            data-cy="charitable-donations-toggle-switch"
            isProcessing={isLoadingMerchantCharityConfigUpdate}
          />
          {showEditCharitableDonationButton && (
            <Stack isRow justify="end">
              <Button
                emphasis="low"
                onClick={() => setEditCharitableDonations(true)}
                size="small"
                text="Edit"
              />
            </Stack>
          )}
        </Grid>
      </Stack>
      {showCharitySelector && (
        <Grid columns={3}>
          <AdvancedSelect
            label="Charity"
            value={selectedCharity.name}
            placeholder="Select"
            onChange={handleCharityListChange}
            id="charitableDonationId"
            options={[...charitiesAdvancedSelectOptions, { display: 'Add new', value: 'addNew' }]}
            multiple={false}
            data-cy="charity-list-selector"
          />
        </Grid>
      )}
      {selectedCharity.id && isMerchantConfigEnabled && (
        <Stack spacing={2}>
          <Divider />
          <Stack spacing={2}>
            <Stack isRow justify="space-between" spacing={3}>
              <h2>Contact Information</h2>
              {enableEditCharitableDonations && (
                <Button
                  emphasis="low"
                  onClick={() => setIsEditCharityModalVisible(true)}
                  size="small"
                  text="Edit"
                />
              )}
            </Stack>
            <Grid
              columns={{
                lg: 3,
                sm: 2,
                xs: 1,
              }}
              spacing={{
                md: 6,
                sm: 4,
                xs: 2,
              }}
            >
              <DataProperty label="Contact Name" value={selectedCharity.contactName} />
              <DataProperty label="Email Address" value={selectedCharity.contactEmail} />
              <DataProperty label="Phone Number" value={selectedCharity.contactPhone} />
              <DataProperty label="Address" value={selectedCharity.address} />
            </Grid>
          </Stack>
          <Divider />
          <h2>Donation Settings</h2>
          <DataProperty
            label="Donation Amount"
            value={`${JSON.stringify(merchantCharityConfig?.calcPercentage)}%`}
          />
        </Stack>
      )}
      {showButtonGroup && (
        <>
          <Divider />
          <ButtonGroup>
            <Divider />
            <Button
              emphasis="medium"
              text="Cancel"
              data-cy="cancel-button"
              onClick={() => setEditCharitableDonations(false)}
            />
            <Button
              text="Save"
              data-cy="save-button"
              isDisabled={!selectedCharity.id}
              onClick={handleSubmit}
            />
          </ButtonGroup>
        </>
      )}

      <>
        <ModalController isOpen={isAddCharityModalVisible}>
          <AddCharityModal onClose={closeAddCharityModal} />
        </ModalController>

        <ModalController isOpen={isEditCharityModalVisible}>
          <EditCharityModal selectedCharity={selectedCharity} onClose={closeEditCharityModal} />
        </ModalController>
      </>
    </Stack>
  )
}
