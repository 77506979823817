import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Spinner, COLOR } from '@extend/zen'

type DashboardSpinnerProps = {
  'data-cy'?: string
}

const DashboardSpinner: FC<DashboardSpinnerProps> = ({ 'data-cy': dataCy }) => {
  return (
    <LoadingContainer data-cy={dataCy}>
      <Spinner color={COLOR.BLUE[700]} />
    </LoadingContainer>
  )
}

const LoadingContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { DashboardSpinner }
