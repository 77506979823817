import type { FC } from 'react'
import React from 'react'
import { Button, ButtonGroup, ModalController, Trash } from '@extend/zen'
import { useHistory } from 'react-router-dom'
import { useToggle } from '@helloextend/client-hooks'
import styled from '@emotion/styled'
import type { MappedStorePlanMapping } from '../types'
import { DeletePlanModal } from '../../../delete-plan-modal/delete-plan-modal'

type AssignedPlansRowButtonsProps = {
  storePlanMapping: MappedStorePlanMapping
}

const AssignedPlansRowButtons: FC<AssignedPlansRowButtonsProps> = ({ storePlanMapping }) => {
  const { isWholesale, storeId, planId } = storePlanMapping

  const history = useHistory()
  const [isModalOpen, { on: toggleModalOn, off: toggleModalOff }] = useToggle(false)

  const handleWholesalePricingClick = (): void => {
    history.push(`/admin/stores/${storeId}/product-protection/${planId}/wholesale-pricing`)
  }

  const handlePricingClick = (): void => {
    history.push(`/admin/stores/${storeId}/product-protection/${planId}/pricing`)
  }

  const handleDeletePlanClick = (): void => {
    toggleModalOn()
  }

  // this is needed to prevent the whole row onClick event when inside this cell or modal content is clicked
  const handleRowClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
  }

  return (
    <Container onClick={handleRowClick}>
      <ButtonGroup isReversed>
        <Button
          icon={Trash}
          size="small"
          color="neutral"
          emphasis="low"
          onClick={handleDeletePlanClick}
          data-cy="delete-plan-button"
        />

        <Button
          text="Pricing Details"
          data-cy="pricing-details-button"
          size="small"
          color="neutral"
          emphasis="medium"
          onClick={handlePricingClick}
        />
        {isWholesale && (
          <Button
            text="Wholesale Pricing"
            data-cy="wholesale-pricing-button"
            size="small"
            color="neutral"
            emphasis="medium"
            onClick={handleWholesalePricingClick}
          />
        )}
      </ButtonGroup>
      <ModalController isOpen={isModalOpen}>
        <DeletePlanModal planId={planId} storeId={storeId} toggleModalOff={toggleModalOff} />
      </ModalController>
    </Container>
  )
}

const Container = styled.div({})

export { AssignedPlansRowButtons }
