import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { AdvancedSelect, Grid } from '@extend/zen'
import { formattedFulfillmentOptions } from './utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../../../../../../constants/ld-flags'
import type { PlatformType } from '@helloextend/extend-api-client'

type FulfillmentOptionsProps = {
  selectedCustomerFulfillmentSettings: string[]
  handleSelectCustomerFulfillment: (e: AdvancedSelectChangeEvent<string | string[]>) => void
  storePlatform: PlatformType
}

const FulfillmentOptions: FC<FulfillmentOptionsProps> = ({
  selectedCustomerFulfillmentSettings,
  handleSelectCustomerFulfillment,
  storePlatform,
}) => {
  const { [LDFlag.AutomatedReplacementFulfillment]: FF_AUTOMATED_REPLACEMENT_FULFILLMENT } =
    useFlags()

  const options = formattedFulfillmentOptions.filter((option) => {
    // This feature is currently only available for Shopify merchants (SP Only)
    if (option.value === 'automated_replacement') {
      return FF_AUTOMATED_REPLACEMENT_FULFILLMENT && storePlatform === 'shopify'
    }

    return option.value !== 'manual'
  })

  return (
    <div data-cy="fulfillment-options">
      <ContainerWithMargin>
        <SwitchOrGridContainer>
          <Grid columns={4}>
            <AdvancedSelect
              id="customerFulfillmentOptions"
              label="Customer Fulfillment Options"
              data-cy="fulfillment-options-dropdown"
              maxQuantityToDisplay={5}
              multiple
              onChange={handleSelectCustomerFulfillment}
              placeholder="-Select-"
              value={selectedCustomerFulfillmentSettings}
              options={options}
            />
          </Grid>
        </SwitchOrGridContainer>
      </ContainerWithMargin>
    </div>
  )
}

const SwitchOrGridContainer = styled.div({
  marginBottom: 16,
})

const ContainerWithMargin = styled.div({
  marginBottom: 24,
})

export { FulfillmentOptions }
