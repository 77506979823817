import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useListConversationsQuery } from '@helloextend/extend-api-rtk-query'
import { AdvancedSelect, DataProperty, DataPropertyType } from '@extend/zen'

type ConversationsDropdownProps = {
  onChange: (e: any) => void
  value?: string
  conversationsType?: 'all' | 'published'
}

const ConversationsDropdown: FC<ConversationsDropdownProps> = ({
  onChange,
  value = '',
  conversationsType = 'all',
}) => {
  const { data, isLoading } = useListConversationsQuery()

  const conversations = useMemo(() => {
    if (conversationsType === 'published') {
      return data?.filter((conversation) => conversation.status === 'published')
    }
    return data
  }, [data, conversationsType])

  const options = useMemo(() => {
    if (!conversations) return []
    return [...conversations]
      .sort((a, b) => {
        if (a.title > b.title) return 1
        if (b.title > a.title) return -1
        return 0
      })
      .map((conversation) => {
        return {
          display: conversation.title,
          value: conversation.id,
        }
      })
  }, [conversations])

  if (isLoading)
    return (
      <DataProperty
        label="Conversation*"
        type={DataPropertyType.string}
        data-cy="configuration-conversation-data-property"
        isLoading={isLoading}
      />
    )

  return (
    <AdvancedSelect
      id="conversation"
      label="Conversation*"
      placeholder={value === '' ? 'Select' : value}
      value={value}
      onChange={onChange}
      options={options}
      data-cy="conversation-configuration-conversation-dropdown"
      showSearch
      maxQuantityToDisplay={10}
      multiple={false}
    />
  )
}

export type { ConversationsDropdownProps }
export { ConversationsDropdown }
