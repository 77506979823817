import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { ButtonGroup, Button } from '@extend/zen'
import { Collapsible } from '@helloextend/merchants-ui'

type CollapsibleFormGroupEditWrapperProps = {
  dataCy?: string
  handleSubmit: () => void
  handleCancel: () => void
  handleClickEdit: (section: string) => void
  isEditing: boolean
  isEditable?: boolean
  isSubmitDisabled: boolean
  isSubmitting: boolean
  title: string
  shouldStartExpanded?: boolean
}

const CollapsibleFormGroupEditWrapper: FC<CollapsibleFormGroupEditWrapperProps> = ({
  dataCy,
  handleSubmit,
  handleCancel,
  handleClickEdit,
  isEditing,
  isEditable,
  isSubmitDisabled,
  isSubmitting,
  children,
  title,
  shouldStartExpanded,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!shouldStartExpanded && !isEditing)

  const handleToggle = (): void => {
    if (!isCollapsed && isEditing) {
      setIsCollapsed(true)
      handleCancel()
    } else {
      setIsCollapsed(!isCollapsed)
    }
  }

  const handleEdit = (): void => {
    handleClickEdit(title)
    setIsCollapsed(false)
  }

  const handleClickCancel = (): void => {
    handleCancel()
  }

  return (
    <Collapsible
      data-cy={dataCy}
      controlledValue={isCollapsed}
      onControlledToggle={handleToggle}
      startExpanded={shouldStartExpanded}
      headerContent={
        <HeaderContainer>
          <SubSectionTitle>{title}</SubSectionTitle>
          {isEditable ? (
            <div>
              {isEditing ? (
                <Button
                  color="red"
                  emphasis="low"
                  type="button"
                  size="small"
                  onClick={handleClickCancel}
                  text="Cancel"
                />
              ) : (
                <Button
                  color="blue"
                  emphasis="low"
                  size="small"
                  type="button"
                  onClick={handleEdit}
                  text="Edit"
                  data-cy="edit"
                />
              )}
            </div>
          ) : null}
        </HeaderContainer>
      }
    >
      <ContentContainer>
        <ChildrenContainer>{children}</ChildrenContainer>
        {isEditing && (
          <div css={{ alignSelf: 'flex-end' }}>
            <ButtonGroup>
              <Button
                type="button"
                size="regular"
                emphasis="medium"
                text="Cancel"
                onClick={handleCancel}
              />
              <Button
                type="submit"
                size="regular"
                text="Save Changes"
                isDisabled={isSubmitDisabled}
                onClick={handleSubmit}
                isProcessing={isSubmitting}
                data-cy="save-changes"
              />
            </ButtonGroup>
          </div>
        )}
      </ContentContainer>
    </Collapsible>
  )
}

const HeaderContainer = styled.section({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

const ContentContainer = styled.section({
  display: 'flex',
  flexDirection: 'column',
})

const ChildrenContainer = styled.section({
  marginBottom: 32,
})

const SubSectionTitle = styled.h2({
  fontSize: 20,
  fontWeight: 'bold',
})

export { CollapsibleFormGroupEditWrapper }
