import type { FC } from 'react'
import React, { memo } from 'react'
import styled from '@emotion/styled'
import type { Contract20220201GetResponse } from '@helloextend/extend-api-client'
import { ContractType } from '@helloextend/extend-api-client'
import { get } from 'lodash'
import { COLOR } from '@extend/zen'
import { getContractTypeCopy } from '../../../../../lib/contract-type'
import { headerInformationFields } from '../../fields'

export interface HeaderDetailsProps {
  contract?: Contract20220201GetResponse
}

export interface DetailsProps {
  id: string
  label: string
  value: string
}

const Details: FC<DetailsProps> = memo(({ id: key, value, label }) => (
  <ContractDetail key={key}>
    <LabelHeading data-cy={`${key}-label`}>{`${label}:`}</LabelHeading>
    <LabelValue data-cy={`${key}-value`}>{value}</LabelValue>
  </ContractDetail>
))

const HeaderDetails: FC<HeaderDetailsProps> = ({ contract }) => {
  if (!contract) return null

  const contractType = contract?.type

  return (
    <>
      {headerInformationFields.map(({ key, label }, idx) => {
        let value = get(contract, key)

        if (key === 'type') value = getContractTypeCopy(value)
        else if (key === 'product.name' && contractType === ContractType.SHIPPING_PROTECTION) {
          return null
        }

        if (key === 'product.name' && contractType === ContractType.CATEGORY) {
          return null
        }

        if (
          !value &&
          key === 'product.name' &&
          contractType === ContractType.PRODUCT_PROTECTION_BUNDLE
        ) {
          const productsList = contract.productsList || []
          const lastItem = productsList[productsList.length - 1]
          return (
            <Details key={idx} id={key} label={label} value={lastItem?.title || 'Bundle Product'} />
          )
        }

        return <Details key={idx} id={key} label={label} value={value as string} />
      })}
    </>
  )
}

const LabelValue = styled.span()

const LabelHeading = styled.span({
  marginRight: 12,
  color: COLOR.NEUTRAL[600],
  fontWeight: 'bold',
})

const ContractDetail = styled.p({
  fontSize: 16,
  marginTop: 0,
  marginBottom: 7,
})

export { HeaderDetails }
