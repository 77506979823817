import { Information, Switch, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import type { StoreShippingProtection } from '@helloextend/extend-api-client'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'

type LocationFilteringSwitchProps = {
  storeId: string
  shippingProtection: StoreShippingProtection
}

const LocationFilteringSwitch: FC<LocationFilteringSwitchProps> = ({
  storeId,
  shippingProtection,
}) => {
  const [isOn, setIsOn] = useState(shippingProtection.locationFilteringEnabled ?? true)
  const { toast } = useToaster()
  const [updateStore, { isLoading }] = useUpdateStoreMutation()

  const handleChange = async (): Promise<void> => {
    try {
      await updateStore({
        storeId,
        data: {
          shippingProtection: {
            locationFilteringEnabled: !isOn,
          },
        },
        version: 'latest',
      }).unwrap()
      setIsOn(!isOn)
      toast({
        message: 'Shipping Protection Settings have been successfully updated.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    } catch {
      toast({
        message: 'Something went wrong. Please try again.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return (
    <LocationFilteringContainer>
      <Switch
        label="Location Filtering"
        id="locationFilteringEnabled"
        onChange={handleChange}
        isOn={isOn}
        isProcessing={isLoading}
        data-cy="location-filtering-switch"
      />
      <Information data-cy="location-filtering-info">
        <p>
          Shipping Protection offers will be blocked in certain regions if this setting is set to
          Undefined or Enabled. SP Offers will ignore region if this setting is set to Disabled.
          Blocked regions/subdivisions are defined on the SP Plan.
        </p>
      </Information>
    </LocationFilteringContainer>
  )
}

const LocationFilteringContainer = styled.div({
  display: 'flex',
})

export { LocationFilteringSwitch }
