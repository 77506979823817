import React from 'react'
import { date } from '@extend/client-helpers'
import type {
  CellContext,
  ColumnDefs,
  ColumnFiltersState,
  FilterDef,
  SelectFilterOption,
} from '@extend/zen'
import type { AISSearchOptions, AISSearchRecord } from '@helloextend/extend-api-rtk-query'
import { ReferenceIdLink } from './reference-id-link'

export const getTableColumns = (): ColumnDefs<AISSearchRecord> => [
  {
    id: 'refId',
    label: 'Reference ID',
    search: 'explicit',
    isSortable: true,
    renderCell: (data: CellContext<AISSearchRecord, string>) => {
      const { original } = data?.row || {}
      const { refId } = original
      return <ReferenceIdLink referenceId={refId} />
    },
  },
  {
    id: 'status',
    label: 'Record Status',
  },
  {
    id: 'merchantName',
    label: 'Merchant',
  },
  {
    id: 'fileName',
    label: 'File',
  },
  {
    id: 'processType',
    label: 'Type',
  },
  {
    id: 'date',
    label: 'Date',
    renderCell: (data: CellContext<AISSearchRecord, string>) => {
      return date.format(new Date(data.getValue()).getTime(), 'YYYY-MM-DD h:mm:sa')
    },
  },
]

export const getTableFilterOptions = (fileNames: string[]): FilterDef[] => [
  {
    type: 'group',
    label: 'Filters',
    filterDefs: [
      {
        id: 'filename',
        type: 'select',
        label: 'File Name',
        options: convertToFilterOptions(fileNames),
        isMultiSelect: true,
      },
    ],
  },
]

export const convertToFilterOptions = (categories: string[]): SelectFilterOption[] => {
  return categories.map((category) => ({
    value: category,
    display: category,
  }))
}

export const getTableFilters = (
  columnFilters: ColumnFiltersState,
): Partial<AISSearchArgumentsFilters> => {
  let filters: Partial<AISSearchArgumentsFilters> = {}
  if (columnFilters.length > 0) {
    filters = columnFilters.reduce((acc, curr) => {
      const currentFilter: Partial<AISSearchArgumentsFilters> = {}
      switch (curr.id) {
        // break down the price range filter into priceMin and priceMax
        case 'price':
          if ((curr?.value as number[])?.[0] > 0) {
            currentFilter.priceMin = (curr.value as number[])?.[0]
          }
          if ((curr?.value as number[])?.[1] > 0) {
            currentFilter.priceMax = (curr.value as number[])?.[1]
          }
          break
        // needs to be passed as a comma separated string of planIds
        case 'planId':
          currentFilter.planId = (curr.value as string[]).join(',')
          break
        case 'enabled':
          currentFilter.active = curr.value as boolean
          break
        default:
          currentFilter[curr.id] = curr.value
          break
      }
      return { ...acc, ...currentFilter }
    }, {})
  }
  return filters
}

// used to convert the query string params into the format that the table filters expect
export const getSearchFiltersFromQSParams = (
  columnFilters: Partial<AISSearchArgumentsFilters>,
): ColumnFiltersState => {
  let filters: ColumnFiltersState = []
  if (columnFilters) {
    filters = Object.keys(columnFilters).reduce((acc: ColumnFiltersState, curr) => {
      const currentFilter: ColumnFiltersState = []
      switch (curr) {
        // break down the price range filter into priceMin and priceMax
        case 'active':
          currentFilter.push({
            id: 'enabled',
            value: columnFilters.active as boolean,
          })
          break
        // do not add anything for search filters as they are handled separately
        case 'name':
        case 'referenceId':
          break
        default:
          currentFilter.push({
            id: curr,
            value: columnFilters[curr],
          })
          break
      }
      return [...acc, ...currentFilter]
    }, [])
  }
  return filters
}

// used as a workaround for the fact that the filter values are not typed
export interface AISSearchArgumentsFilters extends AISSearchOptions {
  [key: string]: unknown
}
