import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { useGetServicerQuery, useUpdateServicerMutation } from '@helloextend/extend-api-rtk-query'
import { COLOR, useToaster, ToastDuration, ToastColor, Checkbox } from '@extend/zen'
import { schema, serviceTypeFields, settingsFields } from './schema'
import type { Values } from './schema'

const ServiceCapabilitiesTab: FC = () => {
  const { servicerId } = useParams<{ servicerId: string }>()
  const { toast } = useToaster()
  const { data: servicer } = useGetServicerQuery(servicerId)
  const servicerSettings = servicer
    ? servicer.settings
    : {
        depotRepairEnabled: false,
        onsiteRepairEnabled: false,
        fieldDestroyEnabled: false,
        isSelfRepairEnabled: false,
        isCarryInEnabled: false,
        isCleaningKitEnabled: false,
        preventAutoAccept: false,
      }
  const [updateServicer, { isLoading }] = useUpdateServicerMutation()
  const initialValues = {
    depotRepairEnabled: servicerSettings.depotRepairEnabled,
    onsiteRepairEnabled: servicerSettings.onsiteRepairEnabled,
    isCleaningKitEnabled: servicerSettings.isCleaningKitEnabled ?? false,
    isCarryInEnabled: servicerSettings.isCarryInEnabled ?? false,
    isSelfRepairEnabled: servicerSettings.isSelfRepairEnabled ?? false,
    preventAutoAccept: servicerSettings.preventAutoAccept,
  }
  const { values, setFieldValue, submitForm } = useFormik<Values>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (rawBody) => {
      updateServicer({
        servicerId,
        body: {
          settings: {
            ...servicerSettings,
            ...rawBody,
          },
        },
      })
      toast({
        message: 'Servicing capabilities updated!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    },
  })

  const toggleField = (fieldName: keyof Values): void => {
    setFieldValue(fieldName, !values[fieldName])
    submitForm()
  }

  return (
    <ContentWrapper>
      <Header>
        <Title data-cy="service-caps">Service Capabilities</Title>
      </Header>
      <SectionHeader data-cy="service-types">Service Types</SectionHeader>
      <CheckboxWrapper>
        {serviceTypeFields.map(({ key, label, dataCy }) => (
          <Checkbox
            key={key}
            data-cy={dataCy}
            checked={values[key]}
            name={key}
            disabled={isLoading}
            label={label}
            onChange={() => toggleField(key)}
          />
        ))}
      </CheckboxWrapper>
      <SectionHeader data-cy="servicing-settings">Servicing Settings</SectionHeader>
      <CheckboxWrapper>
        {settingsFields.map(({ key, label, dataCy }) => (
          <Checkbox
            key={key}
            data-cy={dataCy}
            checked={values[key]}
            name={key}
            disabled={isLoading}
            label={label}
            onChange={() => toggleField(key)}
          />
        ))}
      </CheckboxWrapper>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  marginBottom: 32,
  padding: 40,
})

const CheckboxWrapper = styled.div({
  display: 'flex',
  gap: 8,
  flexDirection: 'column',
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  margin: 0,
})

const SectionHeader = styled.p({
  margin: '24px 0px',
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 'bold',
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

export { ServiceCapabilitiesTab }
