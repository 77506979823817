import { validate } from '@extend/client-helpers'
import * as Yup from 'yup'

const PHONE_NUMBER_MIN_LENGTH_USA = 10

const schema = Yup.object()
  .shape({
    supportNumber: Yup.string()
    .test('phone number validation', 'Please enter a valid phone number', (value) => {
      return !value || validate.validateIsNumberEntered(value, PHONE_NUMBER_MIN_LENGTH_USA)
    }),
    supportEmail: Yup.string().email('Please enter a valid email'),
    supportURL: Yup.string().matches(validate.urlRegExp, 'Please enter a valid URL'),
    supportHours: Yup.string(),
    escalationName: Yup.string(),
    escalationEmail: Yup.string().email('Please enter a valid email'),
    escalationNumber: Yup.string()
    .test('phone number validation', 'Please enter a valid phone number', (value) => {
      return !value || validate.validateIsNumberEntered(value, PHONE_NUMBER_MIN_LENGTH_USA)
    }),
    escalationExtension: Yup.string(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export type { Values }
export { schema }
