import styled from '@emotion/styled'
import type { Product } from '@helloextend/extend-api-client'
import { useUpdateProductMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { Grid, Input, InputType } from '@extend/zen'
import { useFormik } from 'formik'
import type { FC } from 'react'
import React from 'react'
import { SaveChangesButtonGroup } from '../../../../settings/components/save-changes-button-group'
import type { Values } from './schema'
import { schema } from './schema'

type ManufacturerInfoFormProps = {
  product: Product
}

const ManufacturerInfoForm: FC<ManufacturerInfoFormProps> = ({ product }) => {
  const { values, errors, dirty, isValid, handleChange, handleBlur, handleReset, handleSubmit } =
    useFormik<Values>({
      initialValues: {
        url: product?.mfrWarranty?.url,
        parts: product?.mfrWarranty?.parts,
        labor: product?.mfrWarranty?.labor,
      },
      onSubmit: () => {},
      validationSchema: schema,
      enableReinitialize: true,
    })
  const { toastError, toastCompleted } = useStandardToast()

  const [updateProduct, { isLoading }] = useUpdateProductMutation()

  const handleUpdateProduct = async (): Promise<void> => {
    try {
      await updateProduct({
        version: 'latest',
        productId: product.referenceId,
        storeId: product.storeId,
        data: {
          mfrWarranty: {
            url: values.url,
            parts: values.parts,
            labor: values.labor,
          },
        },
      }).unwrap()

      toastCompleted('Product Settings have been updated successfully.')
    } catch (error) {
       
      console.error(error as Error)
      toastError('Product Settings could not be updated. Please try again.')
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <Grid columns={4} spacing={4}>
        <Input
          id="parts"
          value={values.parts?.toString() || ''}
          type={InputType.number}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter mfr warranty length"
          label="Mfr Warranty Length - Parts"
          isError={Boolean(errors.parts)}
          errorFeedback={errors.parts}
        />
        <Input
          id="labor"
          value={values.labor?.toString() || ''}
          type={InputType.number}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter mfr warranty length"
          label="Mfr Warranty Length - Labor"
          isError={Boolean(errors.labor)}
          errorFeedback={errors.labor}
        />
        <Input
          id="url"
          value={values.url || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter URL"
          label="URL"
          isError={Boolean(errors.url)}
          errorFeedback={errors.url}
        />
      </Grid>
      {dirty && (
        <ButtonGroup>
          <SaveChangesButtonGroup
            id="manufacturer-info-save-buttons-group"
            isSaveDisabled={!dirty || !isValid}
            handleCancel={handleReset}
            handleSave={handleUpdateProduct}
            isLoading={isLoading}
            saveButtonText="Save Changes"
          />
        </ButtonGroup>
      )}
    </form>
  )
}

const ButtonGroup = styled.div({
  marginTop: 24,
})

export { ManufacturerInfoForm }
