import * as fetchActions from './fetch'
import * as fetchAllActions from './fetch-all'
import * as updateActions from './update'
import * as searchActions from './search'
import * as refundActions from './refund'
import * as resendActions from './resend'
import * as createActions from './create'
import * as errorActions from './error'
import * as resetActions from './list-reset'
import * as auditActions from './fetch-audit-history'
import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof fetchActions>
  | ExtractActions<typeof resendActions>
  | ExtractActions<typeof refundActions>
  | ExtractActions<typeof fetchAllActions>
  | ExtractActions<typeof searchActions>
  | ExtractActions<typeof updateActions>
  | ExtractActions<typeof createActions>
  | ExtractActions<typeof errorActions>
  | ExtractActions<typeof resetActions>
  | ExtractActions<typeof auditActions>

export type ActionType = Action['type']

const actions = {
  ...fetchActions,
  ...fetchAllActions,
  ...refundActions,
  ...searchActions,
  ...updateActions,
  ...resendActions,
  ...createActions,
  ...errorActions,
  ...resetActions,
  ...auditActions,
}

export { actions }

export type { AdminContractsCreateRequest } from './create'
export type { ContractsByStoreIdSearch, ContractsStoreFetch } from './fetch'
