import type { CategoryMappingDownloadResponse } from '@helloextend/extend-api-client'
import { faker } from '@faker-js/faker/locale/en'

export const generateCategoryMappingDownloadResponse = (
  overrides: Partial<CategoryMappingDownloadResponse> = {},
): CategoryMappingDownloadResponse => {
  return {
    url: faker.internet.url(),
    message: 'Category mapping download is in progress',
    ...overrides,
  }
}
