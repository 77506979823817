import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

import { TabMenu } from '../../../../components/tab-menu/tab-menu'

enum AdjudicationTabsEnum {
  Conversations = 'conversations',
  Threads = 'threads',
  ConversationConfiguration = 'conversation-configuration',
}

const AdjudicationTabs: FC = () => {
  const history = useHistory()

  const handleTabClick = (tab: string): void => {
    switch (tab) {
      case AdjudicationTabsEnum.Conversations:
        history.push('/admin/adjudication-management/conversations')
        break
      case AdjudicationTabsEnum.Threads:
        history.push('/admin/adjudication-management/threads')
        break
      case AdjudicationTabsEnum.ConversationConfiguration:
        history.push('/admin/adjudication-management/conversation-configuration')
    }
  }

  const activeTab = useMemo((): AdjudicationTabsEnum => {
    const tabPath = history.location.pathname.split('/').pop()
    switch (tabPath) {
      case `conversations`:
        return AdjudicationTabsEnum.Conversations
      case `threads`:
        return AdjudicationTabsEnum.Threads
      case `conversation-configuration`:
        return AdjudicationTabsEnum.ConversationConfiguration
      default:
        return AdjudicationTabsEnum.Conversations
    }
  }, [history.location.pathname])

  return (
    <AdjudicationHeader>
      <AdjudicationTitle>Adjudication Management</AdjudicationTitle>
      <TabMenu
        tabs={[
          { text: 'Conversations', key: AdjudicationTabsEnum.Conversations },
          { text: 'Threads', key: AdjudicationTabsEnum.Threads },
          {
            text: 'Conversation Configurations',
            key: AdjudicationTabsEnum.ConversationConfiguration,
          },
        ]}
        activeTab={activeTab}
        onClick={handleTabClick}
        data-cy="adjudication-management-tabs"
      />
    </AdjudicationHeader>
  )
}

const AdjudicationHeader = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 32,
})

const AdjudicationTitle = styled.h1({
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
  marginTop: 0,
  marginBottom: 24,
})

export { AdjudicationTabs }
