import type { CsvUploadValidatorPipe } from '../csv-validation-models'

export const embeddedPromotionHeader = [
  'referenceId',
  'toDelete',
  'embeddedPlanId',
  'upsellPlanId1',
  'upsellPlanId2',
  'upsellPlanId3',
  'upsellPlanId4',
  'upsellPlanId5',
] as const

export type EmbeddedPromotionHeader = typeof embeddedPromotionHeader[number]
export type EmbeddedPromotionCsvValidatorPipe = CsvUploadValidatorPipe<EmbeddedPromotionHeader>
