import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'

export const auditApi = createApi({
  baseQuery,
  reducerPath: 'auditApi',
  tagTypes: [
    'auditLog',
    'claimsAuditLog',
    'serviceOrdersAuditLog',
    'shipmentsAuditLog',
    'expensesAuditLog',
  ],
  endpoints: (build) => ({
    getAuditLog: build.query<any, { entityType: string; entityId: string }>({
      query: ({ entityType, entityId }) => ({
        url: `audit/${entityType}/${entityId}/history`,
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
  }),
})

export const { useGetAuditLogQuery, useLazyGetAuditLogQuery } = auditApi
