import type { FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import {
  Badge,
  Breadcrumbs,
  Grid,
  DataProperty,
  COLOR,
  Button,
  More,
  useToaster,
  ToastColor,
  ToastDuration,
  Spinner,
  Menu,
} from '@extend/zen'
import {
  useGetAccountQuery,
  useGetUserDetailsQuery,
  useGetUserGrantsListQuery,
} from '@helloextend/extend-api-rtk-query'
import { getUserRolesFromGrants, isMerchantRole } from '../../../../../utils/user-role-mapper'
import { PageHeader } from '../../../../../components/page-header'
import { DeactivateUserModal } from '../../components/deactivate-user-modal/deactivate-user-modal'
import AdminToggle from './admin-toggle'
import { UserDetailsToastErrorText } from './user-details-toast-error-text'
import { usePermissions } from '../../../../../hooks/use-permissions'
import { Permission } from '../../../../../lib/permissions'

const UserDetails: FC = () => {
  const { toast } = useToaster()
  const { userId, accountId } = useParams<{ userId: string; accountId: string }>()
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] = useState<boolean>(false)
  const { hasPermission } = usePermissions()

  const {
    data: userDetails,
    isLoading: isGettingUserDetails,
    isError: isErrorGettingUserDetails,
  } = useGetUserDetailsQuery(userId)

  const { data: accountDetails, isLoading: isGettingAccountDetails } = useGetAccountQuery({
    accountId,
  })

  const { data: userGrants, isLoading: isGettingGrants } = useGetUserGrantsListQuery(
    userDetails?.email ?? '',
    { skip: !userDetails?.email },
  )

  const toggleDeactivateModal = useCallback((): void => {
    setIsDeactivateModalVisible(!isDeactivateModalVisible)
  }, [isDeactivateModalVisible])

  const isActiveUser = useMemo(() => {
    return userGrants?.grants?.length && userGrants.grants.some((x) => isMerchantRole(x.role))
  }, [userGrants])

  const canEditUser = useMemo(() => {
    return hasPermission(Permission.ManageMerchantUsers)
  }, [hasPermission])

  if (isGettingUserDetails || isGettingAccountDetails) {
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  if (isErrorGettingUserDetails) {
    toast({
      message: <UserDetailsToastErrorText />,
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.short,
    })
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs
          data-cy="account-user-breadcrumbs"
          crumbs={[
            { text: 'Accounts', to: '/admin/accounts' },
            {
              text: `${accountId}`,
              to: `/admin/accounts/${accountId}/${
                accountDetails && `isLegacyAccount` in accountDetails ? 'stores' : 'organizations'
              }`,
            },
            { text: `Users`, to: `/admin/accounts/${accountId}/users` },
            { text: `${userDetails?.firstName} ${userDetails?.lastName}` },
          ]}
        />
      </BreadcrumbsWrapper>
      {userDetails && (
        <>
          <FlexCol>
            <HeaderContainer>
              <HeaderLeft>
                <PageHeaderContainer>
                  <PageHeader
                    data-cy="page-header-title"
                    title={`${userDetails.firstName} ${userDetails.lastName}`}
                  />
                  {userDetails.federated && (
                    <FederatedBadge>
                      <Badge text="Federated" color="neutral" data-cy="federated-status-badge" />
                    </FederatedBadge>
                  )}
                </PageHeaderContainer>
                <AdminToggle
                  user={userDetails}
                  accountId={accountId}
                  userGrants={userGrants?.grants || []}
                  isLoading={isGettingGrants}
                  isdisabled={!canEditUser}
                />
              </HeaderLeft>
              {isActiveUser && canEditUser ? (
                <HeaderRight>
                  <Menu
                    items={[
                      {
                        label: 'Deactivate',
                        type: 'button',
                        onClick: toggleDeactivateModal,
                        'data-cy': 'account-user-deactivate',
                      },
                    ]}
                    triggerRenderer={() => (
                      <Button data-cy="user-actions-button" emphasis="medium" icon={More} />
                    )}
                  />
                </HeaderRight>
              ) : null}
            </HeaderContainer>
            <GridContainer>
              <Grid
                columns={{
                  lg: 3,
                  md: 2,
                  sm: 1,
                }}
                data-cy="merchant-account-user-details"
                spacing={{
                  lg: 6,
                  md: 4,
                  sm: 2,
                }}
              >
                <DataProperty
                  data-cy="firstName"
                  label="First Name"
                  value={userDetails.firstName}
                />
                <DataProperty label="Last Name" value={userDetails.lastName} />
                <DataProperty label="Email" value={userDetails.email} />
                <DataProperty
                  label="Role(s)"
                  value={getUserRolesFromGrants({
                    grants: userGrants?.grants ?? [],
                    roleFilter: 'merchant',
                    accountId,
                  })
                    .map((x) => x.display)
                    .join(',  ')}
                  data-cy="roles"
                  isLoading={isGettingGrants}
                />
              </Grid>
            </GridContainer>
          </FlexCol>
          <DeactivateUserModal
            user={userDetails}
            isVisible={isDeactivateModalVisible}
            toggle={toggleDeactivateModal}
          />
        </>
      )}
    </>
  )
}

const FlexCol = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
})

const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const HeaderLeft = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const HeaderRight = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const BreadcrumbsWrapper = styled.div({
  marginBottom: 24,
})

const GridContainer = styled.div({
  justifyContent: 'space-between',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  padding: 40,
})

const SpinnerContainer = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexWrap: 'wrap',
  padding: 24,
})

const PageHeaderContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const FederatedBadge = styled.div({
  marginBottom: 24,
  marginLeft: 10,
})

export { UserDetails }
