import type { FC } from 'react'
import React, { useMemo, Fragment } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import type { RuleStatus } from '@helloextend/extend-api-rtk-query'
import { useSelector } from 'react-redux'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'
import { useGetUniqueKeys } from '../../../../../hooks/use-get-unique-keys'
import { AddRuleButton } from './add-rule-button'
import { Rule } from './rule'

type RulesetProps = {
  rulesetType: RuleStatus
}

const Ruleset: FC<RulesetProps> = ({ rulesetType }) => {
  const ruleset = useSelector((state: RootState) => selectors.getRuleset(state))
  const { keys, addKey, removeKey: removeRuleKey, generateInitialKeys } = useGetUniqueKeys()

  const rulesToRender = useMemo(() => {
    if (rulesetType === 'review') {
      return ruleset?.reviewRules || []
    }
    if (rulesetType === 'approved') {
      return ruleset?.approveRules || []
    }
    if (rulesetType === 'denied') {
      return ruleset?.denyRules || []
    }
    return []
  }, [rulesetType, ruleset])

  if (keys.length === 0 && rulesToRender.length > 0) {
    generateInitialKeys(rulesToRender.length)
    return null
  }

  const addRuleKey = (): void => {
    addKey(rulesToRender.length)
  }

  const ruleGroupHeader = {
    approved: (
      <SubText data-cy="approve-claim-subtext">
        Rules for <GreenText data-cy="ruleset-approving-text">approving</GreenText> a claim
      </SubText>
    ),
    denied: (
      <SubText data-cy="deny-claim-subtext">
        Rules for <RedText data-cy="ruleset-denying-text">denying</RedText> a claim
      </SubText>
    ),
    review: (
      <SubText data-cy="review-claim-subtext">
        Rules for requiring
        <YellowText data-cy="ruleset-review-text"> manual review</YellowText>
      </SubText>
    ),
  }

  return (
    <RulesetWrapper data-cy="claim-validation-ruleset">
      {ruleGroupHeader[rulesetType]}
      {rulesToRender?.map((_, ruleIndex) => {
        return (
          <Fragment key={keys[ruleIndex]}>
            <Rule
              ruleIndex={ruleIndex}
              rulesetType={rulesetType}
              hasBottomBorder={ruleIndex < rulesToRender.length - 1}
              removeRuleKey={removeRuleKey}
            />
          </Fragment>
        )
      })}
      <AddRuleButton rulesetType={rulesetType} addRuleKey={addRuleKey} />
    </RulesetWrapper>
  )
}

const RulesetWrapper = styled.div({
  marginBottom: 48,
  display: 'flex',
  flexDirection: 'column',
})

const SubText = styled.p({
  fontWeight: 700,
  textTransform: 'uppercase',
  fontSize: 14,
  lineHeight: '18px',
  color: COLOR.NEUTRAL[1000],
})

const RedText = styled.span({
  color: COLOR.RED[700],
})

const GreenText = styled.span({
  color: COLOR.GREEN[600],
})

const YellowText = styled.span({
  color: COLOR.YELLOW[800],
})

export { Ruleset }
