import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { keyframes } from '@emotion/core'
import { AnimatedSpinnerCheck } from '@helloextend/merchants-ui'

interface SuccessHeaderPopupProps {
  message: string
  onAnimationEnd?: () => void
}

const SuccessHeaderPopup: FC<SuccessHeaderPopupProps> = ({ message, onAnimationEnd }) => {
  const [showClass, setShowClass] = useState<string>('show')

  const hide = (): void => {
    setShowClass('exit-success')
    if (onAnimationEnd) {
      onAnimationEnd()
    }
  }

  return (
    <SuccessPopupWrapper
      data-cy="success-message-info-updated"
      className={showClass}
      onAnimationEnd={hide}
    >
      <Container>
        <AnimatedSpinnerCheck
          size={32}
          loading={false}
          thickness={1}
          color={COLOR.WHITE}
          hideMargin
        />
        <Message>{message}</Message>
      </Container>
    </SuccessPopupWrapper>
  )
}

const Message = styled.div({
  paddingLeft: 16,
  fontSize: 18,
  fontWeight: 700,
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 100,
  width: '100%',
  whiteSpace: 'nowrap',
})

const drop = keyframes({
  '0%': {
    transform: 'translateY(-100%)',
  },
  '100%': {
    transform: 'translateY(0%)',
  },
})

const out = keyframes({
  '0%': {
    transform: 'translateY(0%)',
  },
  '100%': {
    transform: 'translateY(-100%)',
  },
})

const SuccessPopupWrapper = styled.div({
  display: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: COLOR.BLUE[700],
  height: 59,
  width: '100%',
  boxSizing: 'border-box',
  padding: '10px 20px 8px',
  alignItems: 'center',
  justifyContent: 'center',
  color: COLOR.WHITE,
  zIndex: 1000000,
  '&.show': {
    display: 'flex',
    animation: `${drop} 0.6s`,
  },
  '&.exit-success': {
    display: 'flex',
    animation: `${out} 0.6s forwards`,
    animationDelay: '1000ms',
  },
  '&.hide': {
    display: 'none',
  },
})

export { SuccessHeaderPopup }
