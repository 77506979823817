import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import type {
  RuleStatus,
  Operator,
  NumericCondition,
  ConditionTypes,
} from '@helloextend/extend-api-rtk-query'
import type { AdvancedSelectOption, AdvancedSelectChangeEvent } from '@extend/zen'
import { AdvancedSelect } from '@extend/zen'
import { updateConditionOperator } from '../../../../../../store/slices/amp-slice'
import * as selectors from '../../../../../../reducers/selectors'
import type { RootState } from '../../../../../../reducers'

type OperatorSelectorProps = {
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
  conditionType: ConditionTypes
  isDisabled?: boolean
}

export const getOperatorOptions = (conditionType: ConditionTypes): AdvancedSelectOption[] => {
  const isNumericScript = conditionType === 'numericScript'
  const greaterThanText = isNumericScript ? 'Later' : 'Greater'
  const lessThanText = isNumericScript ? 'Earlier' : 'Less'
  return [
    {
      display: `${greaterThanText} than`,
      value: '>',
    },
    {
      display: `${greaterThanText} than or equal to`,
      value: '>=',
    },
    {
      display: 'Equal to',
      value: '=',
    },
    {
      display: `${lessThanText} than`,
      value: '<',
    },
    {
      display: `${lessThanText} than or equal to`,
      value: '<=',
    },
  ]
}

const OperatorSelector: FC<OperatorSelectorProps> = ({
  rulesetType,
  ruleIndex,
  conditionIndex,
  conditionType,
  isDisabled,
}) => {
  const dispatch = useDispatch()

  const condition = useSelector((state: RootState) =>
    selectors.getCondition({ state, rulesetType, ruleIndex, conditionIndex }),
  )

  const selectorValue = (condition as NumericCondition)?.operator || ''

  const handleChange = (e: AdvancedSelectChangeEvent): void => {
    const operator = e.target.value as Operator
    dispatch(updateConditionOperator({ rulesetType, ruleIndex, conditionIndex, value: operator }))
  }

  return (
    <Wrapper>
      <AdvancedSelect
        data-cy="claim-validation-operator-selector"
        id="claim-validation-operator-selector"
        onChange={handleChange}
        options={getOperatorOptions(conditionType)}
        value={selectorValue}
        multiple={false}
        isDisabled={isDisabled}
        isNotClearable
      />
    </Wrapper>
  )
}

const Wrapper = styled.div({
  flex: '0 0 240px',
})

export { OperatorSelector }
