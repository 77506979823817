import type { PhotoRequirement } from '@extend-services/claims-management'
import { faker } from '@faker-js/faker/locale/en'
import type { ClaimPhotoDetail, ClaimPhotosCreateRequest } from '@helloextend/extend-api-client'
import type { PresignedPost } from 'aws-sdk/clients/s3'

export function generateClaimPhotosCreateRequest(
  overrides: Partial<ClaimPhotosCreateRequest> = {},
): ClaimPhotosCreateRequest {
  return {
    description: faker.random.words(),
    name: 'tire_tread_depth',
    source: 'ops_admin',
    ...overrides,
  }
}

export function generatePresignedPost(): PresignedPost {
  return {
    url: 'https://www.extend.com',
    fields: {
      Policy: 'string',
      'X-Amz-Signature': 'string',
    },
  }
}

export const generateClaimPhotoRequirement = (
  overrides: Partial<PhotoRequirement> = {},
): PhotoRequirement => {
  return {
    id: faker.datatype.uuid(),
    version: 0,
    threadId: faker.datatype.uuid(),
    imageUrl: faker.image.imageUrl(),
    ...overrides,
  }
}

export interface GenerateClaimPhotoDetailsOptions {
  numberOfPhotos?: number
  requirements?: PhotoRequirement[]
}

export function generateClaimPhotoDetails(
  overrides?: GenerateClaimPhotoDetailsOptions,
): ClaimPhotoDetail[] {
  const numberOfPhotos = overrides?.numberOfPhotos
  const requirements = overrides?.requirements

  return Array.from({ length: numberOfPhotos || 4 }, (_, index) => ({
    id: faker.datatype.uuid(),
    description: faker.lorem.paragraph(),
    name: faker.random.word(),
    source: 'ops_admin',
    createdBy: 'Rick Astley',
    createdAt: Date.now(),
    url: faker.image.imageUrl(),
    requirement: requirements?.[index] || generateClaimPhotoRequirement(),
    status: 'saved',
  }))
}
