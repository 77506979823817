export const formatDate = (epoch: number): string => {
  const date = new Date(epoch)

  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'America/Los_Angeles', // PST timezone
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  const formattedTime = date.toLocaleTimeString('en-US', options)

  return `${formattedTime} PST`
}
