import type { FC } from 'react'
import React from 'react'
import { Modal, ModalController } from '@extend/zen'
import { useHistory } from 'react-router'

type LogoutNotificationModalProps = {
  isVisible: boolean
  toggleOff: () => void
}

const LogoutNotificationModal: FC<LogoutNotificationModalProps> = ({ isVisible, toggleOff }) => {
  const history = useHistory()
  const handleLogoutClick = (): void => {
    history.push('/logout')
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Log in again to see changes made in Okta"
        onDismissRequest={toggleOff}
        primaryButtonProps={{
          onClick: handleLogoutClick,
          text: 'Logout',
          'data-cy': 'logout-button',
        }}
        secondaryButtonProps={{
          onClick: toggleOff,
          text: 'Cancel',
          'data-cy': 'cancel-button',
        }}
        size="sm"
        data-cy="logout-notification-modal"
      >
        <div>
          Any profile updates made in Okta will only show in the Extend portal after you log out and
          log in again.
        </div>
      </Modal>
    </ModalController>
  )
}

export { LogoutNotificationModal }
