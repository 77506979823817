import React, { useState, useMemo } from 'react'
import type { FC } from 'react'
import type { ColumnFiltersState, PaginationState, SortingState } from '@extend/zen'
import { Add, DataTable, HeadingLarge } from '@extend/zen'
import {
  useGetStoresSearchCountQuery,
  useStoresSearchQuery,
} from '@helloextend/extend-api-rtk-query'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import type { StoreSearchParams } from '@helloextend/extend-api-rtk-query/src/stores/types'
import { storesColumns } from './stores-data-table-config'
import {
  filterOptions,
  getFiltersForApi,
} from './stores-data-table-filters/stores-data-table-filters'
import { usePermissions } from '../../../../../hooks/use-permissions'
import { Permission } from '../../../../../lib/permissions'
import { CreateStoreModal } from '../../accounts/create-store-modal'

type StoresTableProps = {
  accountId?: string
  isAccountIdColumnDisplayed?: boolean
}

const StoresDataTable: FC<StoresTableProps> = ({
  accountId,
  isAccountIdColumnDisplayed = true,
}) => {
  const [sorting, setSorting] = useQueryStringState<SortingState>('sorting', [
    { id: 'storeId', desc: false },
  ])
  const { hasPermission } = usePermissions()
  const [isCreateStoreModalOpen, setIsCreateStoreModalOpen] = useState(false)
  const [columnFilters, setColumnFilters] = useQueryStringState<ColumnFiltersState>('filter', [], {
    encode: true,
  })

  const [pagination, setPagination] = useQueryStringState<PaginationState>(
    'pagination',
    {
      pageIndex: 0,
      pageSize: 25,
    },
    { encode: true },
  )

  const paramsForApi: StoreSearchParams = useMemo(
    () => ({
      offset: pagination.pageIndex * pagination.pageSize,
      sortDirection: sorting[0].desc ? 'desc' : 'asc',
      sortField: sorting[0].id,
      limit: pagination.pageSize,
      includeDeleted: undefined,
      ...getFiltersForApi(columnFilters),
      ...(accountId && { accountId }),
    }),
    [pagination.pageIndex, pagination.pageSize, sorting, columnFilters, accountId],
  )

  const { data, isFetching, isError } = useStoresSearchQuery(paramsForApi)
  const { data: storesCount, isFetching: isStoresCountFetching } =
    useGetStoresSearchCountQuery(paramsForApi)

  const mappedData = useMemo(
    () =>
      storesCount && data?.stores
        ? data.stores.map((store) => ({
            ...store,
            enabled: !!store.enabled,
            approved: !!store.approved,
            shippingProtectionEnabled: !!store.shippingProtectionEnabled,
            shippingProtectionApproved: !!store.shippingProtectionApproved,
            isTestStore: !!store.isTestStore,
            isDeleted: !!store.isDeleted,
          }))
        : [],
    [data, storesCount],
  )

  const getTableActions =
    hasPermission(Permission.CreateStores) && accountId
      ? () => [
          {
            icon: Add,
            text: 'Create Store',
            emphasis: 'medium' as const,
            onClick: () => setIsCreateStoreModalOpen(true),
            isDisabled: false,
          },
        ]
      : undefined

  return (
    <>
      <DataTable
        data-cy="stores-table"
        heading={<HeadingLarge>Stores</HeadingLarge>}
        itemName="store"
        getTableActions={getTableActions}
        data={mappedData}
        isLoading={isFetching || isStoresCountFetching}
        isError={isError}
        stateViews={{
          skeleton: { message: 'Please wait while we retrieve store data' },
          error: { heading: 'Unable to retrieve store data' },
          noData: {
            heading: 'No stores found under this account',
          },
        }}
        getRowId={(row) => row.storeId}
        rowCount={storesCount?.totalStores}
        filterDefs={filterOptions}
        columns={storesColumns}
        hasConfigurableColumns={false}
        columnVisibility={{ accountId: isAccountIdColumnDisplayed }}
        sorting={sorting}
        columnFilters={columnFilters}
        pagination={pagination}
        onSortingChange={setSorting}
        onColumnFiltersChange={setColumnFilters}
        onPaginationChange={setPagination}
        hasManualFiltering
        hasManualSorting
        hasManualPagination
      />
      <CreateStoreModal isOpen={isCreateStoreModalOpen} toggleModal={setIsCreateStoreModalOpen} />
    </>
  )
}

export { StoresDataTable }
