import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import type * as Yup from 'yup'
import {
  AdvancedSelect,
  Checkbox,
  Grid,
  Information,
  Spinner,
  Stack,
  Subheading,
} from '@extend/zen'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { ClaimsUser } from '@helloextend/extend-api-client'
import {
  useUpsertMerchantServicingSettingsMutation,
  useListClaimsAssignmentUsersQuery,
} from '@helloextend/extend-api-rtk-query'
import { useFormik } from 'formik'
import { SaveChangesButtonGroup } from '../save-changes-button-group'
import { getClaimAssigneeOptions } from './utils'
import type { ClaimAssigneeValues } from './schema'

type PrimaryClaimAssigneeProps = {
  sellerId: string
  schema: Yup.ObjectSchema<any>
  initialValues: ClaimAssigneeValues
  checkboxProperty: 'autoAssignUser'
  dropdownUserIdProperty: 'primaryUserId'
  dataCy?: string
}

const PrimaryClaimAssignee: FC<PrimaryClaimAssigneeProps> = ({
  sellerId,
  schema,
  initialValues,
  checkboxProperty,
  dropdownUserIdProperty,
  dataCy = 'claim-assignee',
}) => {
  const [updateSettings, { isLoading, isError, isSuccess }] =
    useUpsertMerchantServicingSettingsMutation()
  const { activeUsers: items, isFetching } = useListClaimsAssignmentUsersQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      activeUsers: [...(data?.items || [])]
        .filter((user: ClaimsUser) => user.isActive)
        .map((user) => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
        }))
        .sort((a, b) => b.createdAt - a.createdAt),
      ...rest,
    }),
  })

  const { toastCompleted, toastError } = useStandardToast()
  const { values, setFieldValue, submitForm, errors, dirty, handleChange, handleReset } =
    useFormik<ClaimAssigneeValues>({
      enableReinitialize: true,
      initialValues,
      validationSchema: schema,
      onSubmit: (fieldValues) => {
        updateSettings({ ...fieldValues, sellerId })
      },
    })

  const toggleAutoAssign = (): void => {
    setFieldValue(checkboxProperty, !values[checkboxProperty])
  }

  const claimAssigneeOptions = useMemo(
    () => getClaimAssigneeOptions(items, isFetching),
    [items, isFetching],
  )

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Servicing Settings have been successfully updated.')
    }
  }, [isSuccess, toastCompleted])

  useEffect(() => {
    if (isError) {
      toastError('There was an error updating the Servicing Settings. Please try again.')
    }
  }, [isError, toastError])

  if (isFetching) {
    return <Spinner />
  }

  return (
    <div data-cy={dataCy}>
      <ContainerWithMargin>
        <Stack isRow align="center">
          <Subheading>Claim Assignment - Primary Assignee</Subheading>
          <Information buttonSize="xsmall">User to automatically assign to a claim.</Information>
        </Stack>
      </ContainerWithMargin>
      <CheckboxContainer>
        <Checkbox
          name={checkboxProperty}
          label="Automatically assign"
          checked={values[checkboxProperty]}
          onChange={toggleAutoAssign}
        />
      </CheckboxContainer>
      {values[checkboxProperty] && (
        <Grid columns={4}>
          <ContainerWithMargin>
            <AdvancedSelect
              id={dropdownUserIdProperty}
              isNotClearable
              data-cy={dropdownUserIdProperty}
              label="User Name"
              multiple={false}
              onChange={handleChange}
              value={values[dropdownUserIdProperty]}
              options={claimAssigneeOptions}
              isError={Boolean(errors[dropdownUserIdProperty])}
              errorFeedback={errors[dropdownUserIdProperty]}
            />
          </ContainerWithMargin>
        </Grid>
      )}
      {dirty && (
        <ContainerWithMargin>
          <SaveChangesButtonGroup
            handleSave={submitForm}
            handleCancel={handleReset}
            isLoading={isLoading}
            isSaveDisabled={isLoading || Boolean(Object.keys(errors).length)}
            id="claim-assignee"
          />
        </ContainerWithMargin>
      )}
    </div>
  )
}

const CheckboxContainer = styled.div({
  marginBottom: 16,
})

const ContainerWithMargin = styled.div({
  marginBottom: 24,
})

export type { PrimaryClaimAssigneeProps }
export { PrimaryClaimAssignee }
