import type { UploadValidatorPipe } from './csv-validation'
import { getColumnValue } from './csv-validation'

const expectedNumericColumns = [
  {
    name: 'termLength',
    required: true,
    userFriendlyColumnName: 'Term Length',
    isPercentage: false,
    canBeZero: true,
  },
  {
    name: 'lossCost',
    required: true,
    userFriendlyColumnName: 'Loss Cost',
    isPercentage: false,
    canBeZero: true,
  },
  {
    name: 'rateFeePercentage',
    required: false,
    userFriendlyColumnName: 'Rate Fee Percentage',
    isPercentage: true,
    canBeZero: true,
  },
  {
    name: 'deductibleAmount',
    required: false,
    userFriendlyColumnName: 'Deductible Amount',
    isPercentage: false,
    canBeZero: true,
  },
  {
    name: 'priceBandLow',
    required: true,
    userFriendlyColumnName: 'Price Band Low',
    isPercentage: false,
    canBeZero: true,
  },
  {
    name: 'priceBandHigh',
    required: true,
    userFriendlyColumnName: 'Price Band High',
    isPercentage: false,
    canBeZero: true,
  },
  {
    name: 'targetLossRatio',
    required: true,
    userFriendlyColumnName: 'Target Loss Ratio',
    isPercentage: true,
    canBeZero: false,
  },
  {
    name: 'adminFee',
    required: false,
    userFriendlyColumnName: 'Admin Fee',
    isPercentage: false,
    canBeZero: true,
  },
  {
    name: 'lossReserve',
    required: true,
    userFriendlyColumnName: 'Loss Reserve',
    isPercentage: false,
    canBeZero: false,
  },
  {
    name: 'rateFee',
    required: true,
    userFriendlyColumnName: 'Rate Fee',
    isPercentage: false,
    canBeZero: true,
  },
  {
    name: 'premium',
    required: true,
    userFriendlyColumnName: 'Premium',
    isPercentage: false,
    canBeZero: false,
  },
  {
    name: 'frequency',
    required: false,
    userFriendlyColumnName: 'frequency',
    isPercentage: false,
    canBeZero: false,
  },
  {
    name: 'severity',
    required: false,
    userFriendlyColumnName: 'severity',
    isPercentage: false,
    canBeZero: false,
  },
]

const isDeductibleSelected = (validationPipeResults: UploadValidatorPipe): boolean => {
  const deductible = getColumnValue(validationPipeResults, 'deductible')
  return deductible === 'TRUE'
}

/**
 * Ensures that the numeric values are:
 * - not parsed to a NaN
 * - an integer
 * - greater than/equal to zero
 */
const validateNumericColumns = (
  validationPipeResults: UploadValidatorPipe,
): UploadValidatorPipe => {
  expectedNumericColumns.forEach((column) => {
    const value = getColumnValue(validationPipeResults, column.name)
    // only verify deductibleAmount if deductible value is 'TRUE'
    if (
      column.name === 'deductibleAmount' &&
      value === '' &&
      isDeductibleSelected(validationPipeResults)
    ) {
      validationPipeResults.errors.push(`${column.userFriendlyColumnName} is required`)
      return
    }
    // stop verification if value isn't required
    // value can be 'null' (as a string) when parsing from csv
    if ((value === '' || value === 'null') && !column.required) return

    if (value === '' && column.required) {
      validationPipeResults.errors.push(`${column.userFriendlyColumnName} is required`)
      return
    }

    const colValueToNum = Number(value)
    const isNonNumeric = Number.isNaN(colValueToNum)
    const isLessThanZero = colValueToNum < 0
    const isLargerThanLimit = colValueToNum > Number.MAX_SAFE_INTEGER

    if (isNonNumeric || isLessThanZero) {
      validationPipeResults.errors.push(
        `${column.userFriendlyColumnName} should be a positive number`,
      )
    }

    if (isLargerThanLimit) {
      validationPipeResults.errors.push(
        `${column.userFriendlyColumnName} should be less than ${Number.MAX_SAFE_INTEGER}`,
      )
    }
    if (column.isPercentage && colValueToNum > 100) {
      validationPipeResults.errors.push(`${column.userFriendlyColumnName} should be less than 100`)
    }
    if (!column.canBeZero && colValueToNum === 0) {
      validationPipeResults.errors.push(`${column.userFriendlyColumnName} should be more than 0`)
    }
  })

  return validationPipeResults
}

export { isDeductibleSelected, validateNumericColumns }
