import { CurrencyInput, Input } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import type { CompactFormFieldData } from './types'

const CompactFormInput: FC<CompactFormFieldData> = ({
  id,
  value,
  label,
  isEditable,
  inputType,
  dataPropertyType,
  currencyCode,
}) => {
  // Note: Using a switch statement as we will add more input types in the future (date picker, text area, etc.)
  switch (dataPropertyType) {
    case 'currency':
      return (
        <CurrencyInput
          id={id}
          value={String(value)}
          label={label}
          currency={currencyCode || 'USD'}
          data-cy={`compact-form-currency-input:${label}`}
          isDisabled={!isEditable}
        />
      )
    default:
      return (
        <Input
          id={id}
          type={inputType}
          isDisabled={!isEditable}
          label={label}
          value={String(value)}
          data-cy={`compact-form-input:${label}`}
        />
      )
  }
}

export { CompactFormInput }
