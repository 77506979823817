import React, { useMemo } from 'react'
import type { FC } from 'react'
import { AdvancedSelect, Grid } from '@extend/zen'
import {
  useGetPlanCategoriesQuery,
  useProductWarrantyMappingMutation,
} from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useFormik } from 'formik'
import type { Product } from '@helloextend/extend-api-client'
import { SaveChangesButtonGroup } from '../../../../settings/components/save-changes-button-group'

type ProductPlanCategoryProps = {
  product: Product
}

export const ProductPlanCategory: FC<ProductPlanCategoryProps> = ({ product }) => {
  const { toastError, toastCompleted } = useStandardToast()
  const { data: planCategories, isLoading: isPlanCategoriesLoading } = useGetPlanCategoriesQuery()

  const [updateProductPlanCategory, { isLoading: isUpdateProductPlanCategoryUpdating }] =
    useProductWarrantyMappingMutation()

  const { values, dirty, handleReset, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    initialValues: {
      planCategoryId: product.planCategoryId ?? '',
    },
    onSubmit: async (values) => {
      try {
        const { planCategoryId } = values

        const response = await updateProductPlanCategory({
          storeId: product.storeId,
          data: [
            {
              referenceId: product.referenceId,
              planCategoryId,
            },
          ],
        }).unwrap()

        if (response.errors.length) {
          toastError(`${response.errors[0].reason}`)
        } else {
          toastCompleted('Product plan category has been successfully updated.')
        }
      } catch {
        toastError('Something went wrong. Please try again.')
      }
    },
  })

  const planCategoryOptions = useMemo(() => {
    return planCategories?.items.map((planCategory) => {
      return {
        display: planCategory.name,
        value: planCategory.id,
      }
    })
  }, [planCategories])

  return (
    <>
      <Container>
        <Grid columns={4}>
          <AdvancedSelect
            id="planCategoryId"
            label="Plan Category"
            data-cy="product-plan-category-dropdown"
            placeholder="select category"
            multiple={false}
            isLoading={isPlanCategoriesLoading}
            value={values.planCategoryId}
            options={planCategoryOptions || []}
            onChange={handleChange}
            isNotClearable
          />
        </Grid>
      </Container>
      {dirty && (
        <SaveChangesButtonGroup
          id="product-plan-category-buttons"
          handleSave={handleSubmit}
          handleCancel={handleReset}
          isLoading={isUpdateProductPlanCategoryUpdating}
          saveButtonText="Save"
        />
      )}
    </>
  )
}

const Container = styled.div({
  margin: '16px 0',
})
