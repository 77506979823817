import {
  AdvancedSelect,
  DataProperty,
  Grid,
  Input,
  InputType,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useFormikContext } from 'formik'
import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { useListPlanTermsQuery } from '@helloextend/extend-api-rtk-query'
import type { SpPlanFormValues } from '../sp-plan-form-schema'
import { GetFormPropsAndLabels } from '../sp-plan-form-schema'

type SpPlanTermsSectionProps = {
  mode: 'view' | 'edit'
  modalRender?: boolean
}

type PlanTermsProps =
  | 'termLength'
  | 'termsId'
  | 'active'
  | 'obligor'
  | 'insuranceProgram'
  | 'premiumRate'
  | 'premiumMin'
  | 'shipCoverageMin'
  | 'shipCoverageMax'
  | 'programType'
  | 'serviceType'
  | 'currencyCode'

const SpPlanTermsSection: FC<SpPlanTermsSectionProps> = ({ mode, modalRender }) => {
  const { toast } = useToaster()
  const { values, handleChange, errors, touched, handleBlur } = useFormikContext<SpPlanFormValues>()

  const {
    data: planTerms,
    isLoading: isGettingPlanTerms,
    isError: isErrorGettingPlanTerms,
  } = useListPlanTermsQuery(undefined, { skip: mode === 'view' })

  useEffect(() => {
    if (isErrorGettingPlanTerms) {
      toast({
        message: 'Cannot get plan terms. Please try again.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [isErrorGettingPlanTerms, toast])

  const formProps = useMemo(() => GetFormPropsAndLabels<PlanTermsProps>(), [])

  return (
    <Grid columns={modalRender ? 2 : 4} spacing={4} data-cy="plan-terms-section">
      {mode === 'edit' ? (
        <>
          <Input
            id={formProps.termLength.property}
            value={values.termLength.toString()}
            label={formProps.termLength.label}
            suffix="months"
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.termLength && touched.termLength}
            errorFeedback={errors.termLength}
            type={InputType.number}
            data-cy={formProps.termLength.property}
          />
          <AdvancedSelect
            options={[
              { value: 'true', display: 'True' },
              { value: 'false', display: 'False' },
            ]}
            id={formProps.active.property}
            value={values.active.toString()}
            label={formProps.active.label}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.active && touched.active}
            errorFeedback={errors.active}
            multiple={false}
            data-cy={formProps.active.property}
          />

          <AdvancedSelect
            options={[{ value: 'otis', display: 'Otis' }]}
            id={formProps.obligor.property}
            value={values.obligor}
            label={formProps.obligor.label}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.obligor && touched.obligor}
            errorFeedback={errors.obligor}
            multiple={false}
            // repeat for rest
            data-cy={formProps.obligor.property}
          />
          <AdvancedSelect
            options={
              planTerms?.map((term) => ({
                value: term.termsId,
                display: term.termsId,
              })) ?? []
            }
            showSearch
            id={formProps.termsId.property}
            value={values.termsId}
            label={formProps.termsId.label}
            isLoading={isGettingPlanTerms}
            isDisabled={isGettingPlanTerms}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.termsId && touched.termsId}
            errorFeedback={errors.termsId}
            multiple={false}
            data-cy={formProps.termsId.property}
          />
          <AdvancedSelect
            options={[{ value: 'ship_protect_1', display: 'ship_protect_1' }]}
            showSearch
            id={formProps.insuranceProgram.property}
            value={values.insuranceProgram}
            label={formProps.insuranceProgram.label}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.insuranceProgram && touched.insuranceProgram}
            errorFeedback={errors.insuranceProgram}
            multiple={false}
            data-cy={formProps.insuranceProgram.property}
          />
          <Input
            id={formProps.premiumRate.property}
            value={values.premiumRate.toString()}
            label={formProps.premiumRate.label}
            suffix="%"
            type={InputType.number}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.premiumRate && touched.premiumRate}
            errorFeedback={errors.premiumRate}
            data-cy={formProps.premiumRate.property}
          />
          <Input
            id={formProps.premiumMin.property}
            value={values.premiumMin.toString()}
            label={formProps.premiumMin.label}
            prefix="$"
            type={InputType.number}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.premiumMin && touched.premiumMin}
            errorFeedback={errors.premiumMin}
            data-cy={formProps.premiumMin.property}
          />
          <Input
            id={formProps.shipCoverageMin.property}
            value={values.shipCoverageMin.toString()}
            label={formProps.shipCoverageMin.label}
            prefix="$"
            type={InputType.number}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.shipCoverageMin && touched.shipCoverageMin}
            errorFeedback={errors.shipCoverageMin}
            data-cy={formProps.shipCoverageMin.property}
          />
          <Input
            id={formProps.shipCoverageMax.property}
            value={values.shipCoverageMax.toString()}
            label={formProps.shipCoverageMax.label}
            prefix="$"
            type={InputType.number}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.shipCoverageMax && touched.shipCoverageMax}
            errorFeedback={errors.shipCoverageMax}
            data-cy={formProps.shipCoverageMax.property}
          />
          <AdvancedSelect
            options={[
              { value: 'general_shipping_protection', display: 'General Shipping Protection' },
              { value: 'safe_package_guarantee', display: 'Safe Package Guarantee' },
            ]}
            id={formProps.programType.property}
            value={values.programType.toString()}
            label={formProps.programType.label}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.programType && touched.programType}
            errorFeedback={errors.programType}
            multiple={false}
            data-cy={formProps.programType.property}
          />
          <AdvancedSelect
            options={[{ value: 'replace', display: 'Replace' }]}
            id={formProps.serviceType.property}
            value={values.serviceType.toString()}
            label={formProps.serviceType.label}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.serviceType && touched.serviceType}
            errorFeedback={errors.serviceType}
            multiple={false}
            data-cy={formProps.serviceType.property}
          />
          <AdvancedSelect
            options={[{ value: 'usd', display: 'USD' }]}
            id={formProps.currencyCode.property}
            value={values.currencyCode}
            label={formProps.currencyCode.label}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.currencyCode && touched.currencyCode}
            errorFeedback={errors.currencyCode}
            multiple={false}
            data-cy={formProps.currencyCode.property}
          />
        </>
      ) : (
        <>
          <DataProperty
            value={values.termLength.toString()}
            label={formProps.termLength.label}
            suffix="months"
            data-cy={formProps.termLength.property}
          />
          <DataProperty
            value={values.active.toString()}
            label={formProps.active.label}
            data-cy={formProps.active.property}
          />
          <DataProperty
            value={values.obligor}
            label={formProps.obligor.label}
            data-cy={formProps.obligor.property}
          />
          <DataProperty
            value={values.termsId}
            label={formProps.termsId.label}
            data-cy={formProps.termsId.property}
          />
          <DataProperty
            value={values.insuranceProgram}
            label={formProps.insuranceProgram.label}
            data-cy={formProps.insuranceProgram.property}
          />
          <DataProperty
            value={values.premiumRate.toString()}
            label={formProps.premiumRate.label}
            suffix="%"
            data-cy={formProps.premiumRate.property}
          />
          <DataProperty
            value={values.premiumMin.toString()}
            label={formProps.premiumMin.label}
            prefix="$"
            data-cy={formProps.premiumMin.property}
          />
          <DataProperty
            value={values.shipCoverageMin.toString()}
            label={formProps.shipCoverageMin.label}
            prefix="$"
            data-cy={formProps.shipCoverageMin.property}
          />
          <DataProperty
            value={values.shipCoverageMax.toString()}
            label={formProps.shipCoverageMax.label}
            prefix="$"
            data-cy={formProps.shipCoverageMax.property}
          />
          <DataProperty
            value={values.programType}
            label={formProps.programType.label}
            data-cy={formProps.programType.property}
          />
          <DataProperty
            value={values.serviceType}
            label={formProps.serviceType.label}
            data-cy={formProps.serviceType.property}
          />
          <DataProperty
            value={values.currencyCode}
            label={formProps.currencyCode.label}
            data-cy={formProps.currencyCode.property}
          />
        </>
      )}
    </Grid>
  )
}

export { SpPlanTermsSection }
