import styled from '@emotion/styled'
import { useGetShippingProtectionPlanQuery } from '@helloextend/extend-api-rtk-query'
import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  COLOR,
  DataProperty,
  DisplayLarge,
  IconSize,
  Spinner,
  Stack,
  TabSection,
  TabSections,
  ToastColor,
  ToastDuration,
  useToaster,
  format,
} from '@extend/zen'
import type { FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import type { SpPlanFormValues } from '../sp-plan-form/sp-plan-form-schema';
import { getInitialValues } from '../sp-plan-form/sp-plan-form-schema'
import { FlexRowCenter, FlexRowSpaceBetween } from '../../../../styles'
import { SpPlanDuplicateModal } from '../sp-plan-duplicate-modal/sp-plan-duplicate-modal'
import { SpPlanForm } from '../sp-plan-form/sp-plan-form'
import { SpPlanVersions } from './sp-plan-versions/sp-plan-versions'

const SpPlanDetails: FC = () => {
  const params = useParams<{ planId: string }>()
  const history = useHistory()
  const { toast } = useToaster()

  const createFormRef = useRef<{ submit: () => void; resetForm: () => void }>(null)
  const [formProps, setFormProps] = useState({ isLoading: false, canSubmit: false })

  const [isEditing, setIsEditing] = React.useState(false)
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = React.useState(false)

  const {
    data: spDetails,
    isLoading: isGettingSpPlanDetails,
    isError: isErrorGettingSpPlanDetails,
  } = useGetShippingProtectionPlanQuery({ planId: params.planId })

  useEffect(() => {
    if (isErrorGettingSpPlanDetails) {
      toast({
        message: 'Cannot get plan details. Please try again.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
      history.push('/admin/plans?tab=shipping-protection')
    }
  }, [history, isErrorGettingSpPlanDetails, toast])

  return (
    <div>
      <Breadcrumbs
        crumbs={[
          {
            text: 'Plans',
            to: '/admin/plans',
          },
          {
            text: 'Shipping Protection',
            to: '/admin/plans?tab=shipping-protection',
          },
          {
            text: `${params.planId}`,
          },
        ]}
      />
      {!isGettingSpPlanDetails && spDetails ? (
        <>
          <HeaderContainer>
            <FlexRowSpaceBetween>
              <DisplayLarge>{spDetails.id}</DisplayLarge>
            </FlexRowSpaceBetween>
          </HeaderContainer>
          <TabSections id="tab" isQSControlled>
            <TabSection tabLabel="Details">
              <>
                <Stack spacing={2}>
                  <Stack isRow justify="space-between">
                    <Stack>
                      <DataProperty
                        label="Version"
                        value={spDetails.version.toString()}
                        isHorizontal
                        data-cy="version-data-property"
                      />
                      <DataProperty
                        label="Last Updated"
                        value={format(spDetails.updatedAt ?? 0)}
                        isHorizontal
                        data-cy="last-updated-data-property"
                      />
                      <DataProperty
                        label="Date created"
                        value={format(spDetails.createdAt ?? 0)}
                        isHorizontal
                        data-cy="date-created-data-property"
                      />
                    </Stack>
                    <ButtonGroup>
                      {isEditing ? (
                        <>
                          <Button
                            text="Cancel"
                            emphasis="medium"
                            onClick={() => {
                              createFormRef.current?.resetForm?.()
                              setIsEditing(false)
                            }}
                            data-cy="cancel-edit-button"
                          />
                          <Button
                            text="Save"
                            emphasis="high"
                            isDisabled={!formProps.canSubmit || formProps.isLoading}
                            isProcessing={formProps.isLoading}
                            onClick={() => {
                              createFormRef.current?.submit()
                              setIsEditing(false)
                            }}
                            data-cy="save-button"
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            text="Edit"
                            emphasis="medium"
                            onClick={() => setIsEditing(true)}
                            data-cy="edit-plan-button"
                          />
                          <Button
                            text="Duplicate"
                            emphasis="medium"
                            onClick={() => setIsDuplicateModalOpen(true)}
                            data-cy="duplicate-plan-button"
                          />
                        </>
                      )}
                    </ButtonGroup>
                  </Stack>
                  <SpPlanForm
                    initialValues={getInitialValues(spDetails as SpPlanFormValues)}
                    handleFormProps={setFormProps}
                    isExistingPlan
                    isEditing={isEditing}
                    ref={createFormRef}
                  />
                </Stack>
                <SpPlanDuplicateModal
                  planId={spDetails.id}
                  isOpen={isDuplicateModalOpen}
                  closeModal={() => setIsDuplicateModalOpen(false)}
                />
              </>
            </TabSection>
            <TabSection tabLabel="Versions">
              <SpPlanVersions planId={params.planId} />
            </TabSection>
          </TabSections>
        </>
      ) : (
        <FlexRowCenter>
          <Spinner color={COLOR.BLUE[800]} size={IconSize.xlarge} />
        </FlexRowCenter>
      )}
    </div>
  )
}

const HeaderContainer = styled.div({
  marginTop: '20px',
  marginBottom: '20px',
})

export { SpPlanDetails }
