import React from 'react'
import type { Column as MerchantsUiColumn } from '@helloextend/merchants-ui'
import { currency } from '@extend/client-helpers'
import { Badge } from '@extend/zen'
import type { Sku } from '@helloextend/extend-api-rtk-query'

const skuTableColumns: Array<MerchantsUiColumn<Sku>> = [
  {
    Header: 'Premium ID',
    accessor: 'id',
    canSearch: true,
  },
  {
    Header: 'Premium Name',
    accessor: 'name',
  },
  {
    Header: 'Premium',
    accessor: 'premium',
    Cell: (data: Sku) => <div>{currency.format(data.premium)}</div>,
  },
  {
    Header: 'Program',
    accessor: 'program',
  },
  {
    Header: 'Subprogram',
    accessor: 'subProgram',
  },
  {
    Header: 'Coverage Type',
    accessor: 'coverageType',
  },
  {
    Header: 'Term Length',
    accessor: 'term',
  },
  {
    Header: 'Service Type',
    accessor: 'serviceType',
  },
  {
    Header: 'Status',
    accessor: 'isActive',
    Cell: (data: Sku) => {
      const isActive = Boolean(data.isActive)
      const badgeColor = isActive ? 'green' : 'yellow'
      return <Badge color={badgeColor} emphasis="medium" text={isActive ? 'Active' : 'Inactive'} />
    },
  },
]

export { skuTableColumns }
