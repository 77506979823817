import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import type { AdvancedSelectOption } from '@extend/zen'
import {
  AdvancedSelect,
  ButtonGroup,
  Button,
  ArrowDownward,
  ArrowUpward,
  Trash,
  Add,
} from '@extend/zen'

export type OptionGroup = {
  label: string
  options: AdvancedSelectOption[]
}

type AdvancedSelectListProps = {
  optionGroups: OptionGroup[]
  initialSelectedValues?: string[]
  onSelectedValuesChange?: (selectedValues: string[]) => void
}

const AdvancedSelectList: FC<AdvancedSelectListProps> = ({
  optionGroups = [],
  initialSelectedValues = [],
  onSelectedValuesChange = () => {},
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    initialSelectedValues.length === 0 ? ['', '', '', ''] : initialSelectedValues, // default to 4 empty dropdowns
  )

  const handleAddRow = (): void => {
    setSelectedValues([...selectedValues, ''])
    onSelectedValuesChange([...selectedValues, '']) // Notify parent of the new selected values
  }

  const handleValueChange = (index: number, value: string): void => {
    const updatedValues = [...selectedValues]
    updatedValues[index] = value
    setSelectedValues(updatedValues)
    onSelectedValuesChange(updatedValues) // Notify parent of the updated selected values
  }

  const handleMoveUp = (index: number): void => {
    if (index > 0) {
      const updatedValues: string[] = [...selectedValues]
      const temp = updatedValues[index - 1]
      updatedValues[index - 1] = updatedValues[index]
      updatedValues[index] = temp
      setSelectedValues(updatedValues)
      onSelectedValuesChange(updatedValues) // Notify parent of the updated selected values
    }
  }

  const handleMoveDown = (index: number): void => {
    if (index < selectedValues.length - 1) {
      const updatedValues = [...selectedValues]
      const temp = updatedValues[index + 1]
      updatedValues[index + 1] = updatedValues[index]
      updatedValues[index] = temp
      setSelectedValues(updatedValues)
      onSelectedValuesChange(updatedValues) // Notify parent of the updated selected values
    }
  }

  const handleDelete = (index: number): void => {
    const updatedValues = [...selectedValues]
    updatedValues.splice(index, 1)
    setSelectedValues(updatedValues)
    onSelectedValuesChange(updatedValues) // Notify parent of the updated selected values
  }

  return (
    <>
      {selectedValues.map((value, index) => (
        <AdvancedSelectRow data-cy={`dropdown-row-${index}`} key={`select-row-${index}`}>
          <Label>{`#${index + 1}`}</Label>
          <AdvancedSelectWrapper>
            <AdvancedSelect
              id={`advanced-select-id-${index}`}
              data-cy={`advanced-select-${index}`}
              multiple={false}
              isLoading={optionGroups.length === 0}
              options={optionGroups}
              value={value}
              onChange={(option) => handleValueChange(index, option?.target?.value ?? '')}
              showSearch
            />
          </AdvancedSelectWrapper>
          <ButtonGroup>
            <Button
              size="small"
              color="blue"
              icon={ArrowUpward}
              emphasis="low"
              onClick={() => handleMoveUp(index)}
              isDisabled={index === 0} // Disable up button if first item
              data-cy={`up-button-${index}`}
            />
            <Button
              size="small"
              color="blue"
              icon={ArrowDownward}
              emphasis="low"
              onClick={() => handleMoveDown(index)}
              isDisabled={selectedValues.length === index + 1} // Disable down button if last item
              data-cy={`down-button-${index}`}
            />
            <Button
              size="small"
              color="blue"
              icon={Trash}
              emphasis="low"
              onClick={() => handleDelete(index)}
              data-cy={`delete-button-${index}`}
            />
          </ButtonGroup>
        </AdvancedSelectRow>
      ))}
      <Button
        size="small"
        color="blue"
        icon={Add}
        emphasis="low"
        text="Add Config"
        onClick={handleAddRow}
        data-cy="add-button"
      />
    </>
  )
}

const Label = styled.h3({
  fontWeight: 700,
})

const AdvancedSelectRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
})

const AdvancedSelectWrapper = styled.div({
  flex: 0.5,
})

export { AdvancedSelectList }
