import type { FC } from 'react'
import ReactHTMLParser from 'react-html-parser'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Badge, Button, ButtonGroup, COLOR, Edit, Info } from '@extend/zen'
import type { ContractNote } from '@helloextend/extend-api-client'
import { ContractNoteForm } from 'src/pages/admin/contract/components/contract-notes/contract-note-form'
import { escalationLevels } from 'src/lib/escalation-levels'
import { CategoryText, NoteAuthor, NoteDate, NoteHeader } from './contract-resolution-note'
import { ContractNoteResolution } from './contract-note-resolution'
import { formatTimestampToPST } from 'src/lib/date'

interface ContractNoteEntryProps {
  contractNote: ContractNote
  hasUnsavedChanges?: boolean
  setHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void
  handleEscalationNote?: (note: ContractNote) => void
}

const ContractNoteEntry: FC<ContractNoteEntryProps> = ({
  contractNote,
  setHasUnsavedChanges,
  hasUnsavedChanges,
  handleEscalationNote,
}) => {
  const {
    contractId,
    text,
    category,
    createdAt,
    createdBy,
    categoryDescription,
    updatedAt,
    updatedBy,
    escalationLevel,
  } = contractNote

  const [showMore, setShowMore] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const isLongNote = text.length >= 200
  const contractNoteText =
    showMore || !isLongNote ? text + ' ' : `${text.substring(0, 200).trim()}... `
  const noteDate = formatTimestampToPST(createdAt)
  const noteUpdatedDate = formatTimestampToPST(updatedAt)
  const edited = updatedAt !== createdAt
  const [displayResolutionNote, setDisplayResolutionNote] = useState(false)

  const isEscalation = contractNote.type === 'escalation'
  const displayResolutionButton =
    isEscalation && !contractNote?.isResolved && !displayResolutionNote

  return (
    <>
      {!isEdit && (
        <ContentWrapper data-cy="contract-note-entry" className="ql-editor">
          <NoteHeader>
            <NoteDate data-cy="claims-note-date">{noteDate}</NoteDate>
            <NoteAuthor>
              <Badge
                color="neutral"
                data-cy="notes-author-badge"
                emphasis="low"
                size="small"
                text={createdBy}
              />
            </NoteAuthor>
          </NoteHeader>
          {edited && (
            <NoteHeader>
              <NoteDate data-cy="claims-note-date">
                {noteUpdatedDate}
                <Badge
                  color="neutral"
                  data-cy="notes-edited-badge"
                  emphasis="low"
                  size="small"
                  text="EDITED"
                />
              </NoteDate>
              <NoteAuthor>
                <Badge
                  color="neutral"
                  data-cy="notes-updated-by-badge"
                  emphasis="low"
                  size="small"
                  text={updatedBy || 'Unknown'}
                />
              </NoteAuthor>
            </NoteHeader>
          )}
          <NoteCategory data-cy="contract-note-category">
            <CategoryText>Category:</CategoryText> {category}
          </NoteCategory>
          {categoryDescription && (
            <NoteCategory data-cy="contract-note-category-description">
              <CategoryText>Category Description:</CategoryText> {categoryDescription}
            </NoteCategory>
          )}
          <NoteTextContainer>
            <NoteText data-cy="contract-note-entry-text">
              {ReactHTMLParser(contractNoteText)}
              {isLongNote && (
                <ShowMoreButton className="see-more-btn" onClick={() => setShowMore(!showMore)}>
                  {showMore ? 'see less' : ' see more'}
                </ShowMoreButton>
              )}
            </NoteText>
          </NoteTextContainer>
          <NoteFooter>
            {escalationLevel && (
              <Badge
                text="Escalation"
                color={contractNote.isResolved ? 'neutral' : 'red'}
                icon={Info}
                emphasis="medium"
                details={escalationLevels[escalationLevel]}
                data-cy={'contract-note-escalation-level-badge'}
              />
            )}
            <EditButtonContainer escalationLevel={escalationLevel}>
              <ButtonGroup justify="end">
                {displayResolutionButton && (
                  <Button
                    emphasis="medium"
                    color="blue"
                    size="regular"
                    data-cy="resolve-contract-escalation"
                    text="Resolve Escalation"
                    onClick={() => setDisplayResolutionNote(true)}
                  />
                )}
                <Button
                  emphasis="low"
                  icon={Edit}
                  data-cy="contract-note-edit"
                  color="neutral"
                  onClick={() => setIsEdit(!isEdit)}
                />
              </ButtonGroup>
            </EditButtonContainer>
          </NoteFooter>
          {isEscalation && (
            <ContractNoteResolution
              contractNote={contractNote}
              displayResolutionNote={displayResolutionNote}
              setDisplayResolutionNote={setDisplayResolutionNote}
              isEscalation={isEscalation}
              setHasUnsavedChanges={setHasUnsavedChanges}
              handleEscalationNote={handleEscalationNote}
            />
          )}
        </ContentWrapper>
      )}
      {isEdit && (
        <ContractNoteForm
          contractId={contractId}
          contractNoteToEdit={contractNote}
          setIsEdit={setIsEdit}
          setHasUnsavedChanges={setHasUnsavedChanges}
          hasUnsavedChanges={hasUnsavedChanges}
        />
      )}
    </>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  padding: 16,
  marginTop: 8,
  width: 'auto',
  gap: 8,
})

const NoteCategory = styled.div({
  fontSize: 15,
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  color: 'inherit',
})

const NoteTextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const NoteText = styled.div({
  fontSize: 16,
  overflow: 'hidden',
  wordWrap: 'break-word',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  color: 'inherit',
})

const NoteFooter = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const EditButtonContainer = styled.div<{
  escalationLevel?: string
}>(({ escalationLevel }) => ({
  ...(!escalationLevel && { marginLeft: 'auto' }),
}))

const ShowMoreButton = styled.a({
  cursor: 'pointer',
  textAlign: 'right',
  fontWeight: 700,
  color: COLOR.NEUTRAL[700],
  whiteSpace: 'nowrap',
})

export { ContractNoteEntry }
