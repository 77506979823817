import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import {
  MenuButtonItem,
  COLOR,
  Icon,
  ToastColor,
  ToastDuration,
  useToaster,
  Dots,
  Check,
} from '@extend/zen'
import type { UserGrant } from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useExtendAuth } from '@extend/package-okta-login'
import { LDFlag } from '../../constants/ld-flags'
import type { UserRole } from '../../utils/user-role-mapper'
import { isInternalRole, mapUserType } from '../../utils/user-role-mapper'

const RoleSwitcher: FC = () => {
  const history = useHistory()
  const { toast } = useToaster()
  const {
    [LDFlag.EnterpriseRoles]: FF_ENTERPRISE_ROLES,
    [LDFlag.LegacyAndEnterpriseRoles]: FF_ENTERPRISE_AND_LEGACY_ROLES,
  } = useFlags()

  const { user, userGrants, switchRole, isLoading } = useExtendAuth()

  const handleOnClick = async (grant: UserGrant): Promise<void> => {
    try {
      await switchRole(grant)
    } catch (error) {
      toast({
        message: 'Something went wrong when selecting a role',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      history.push('/')
    }
  }

  const validRoles: UserGrant[] | undefined = useMemo(() => {
    if (!userGrants) return undefined
    return userGrants.filter(
      (grant) =>
        isInternalRole(grant.role, FF_ENTERPRISE_ROLES || FF_ENTERPRISE_AND_LEGACY_ROLES) &&
        grant.role !== 'conversationsadmin', // role not supported by front-end
    )
  }, [userGrants, FF_ENTERPRISE_ROLES, FF_ENTERPRISE_AND_LEGACY_ROLES])

  return (
    <>
      <MenuButtonItem data-cy="role-switcher-menu" disabled>
        <MenuHeaderText>SWITCH ROLES</MenuHeaderText>
      </MenuButtonItem>

      {isLoading ? (
        <Dots />
      ) : (
        <AvailableRolesWrapper>
          {validRoles ? (
            validRoles.map((grant) => {
              const selectedRole = user && user.role === grant.role
              return (
                <MenuButtonItem
                  rightAlignAdornment
                  data-cy="role-switcher-menu-item"
                  endAdornment={
                    selectedRole ? <Icon icon={Check} color={COLOR.BLUE[800]} /> : undefined
                  }
                  onClick={() => handleOnClick(grant)}
                  key={`${grant.role}-${grant.ern}`}
                >
                  {mapUserType(grant.role as UserRole)}
                </MenuButtonItem>
              )
            })
          ) : (
            <p data-cy="no-roles-message">No User Roles</p>
          )}
        </AvailableRolesWrapper>
      )}
    </>
  )
}

const MenuHeaderText = styled.div({
  fontWeight: 700,
  fontSize: 12,
  color: COLOR.NEUTRAL[700],
  letterSpacing: 1,
  background: COLOR.WHITE,
})

const AvailableRolesWrapper = styled.div({
  overflow: 'auto',
  maxHeight: 440,
})

export { RoleSwitcher }
