import type { FC } from 'react'
import React from 'react'
import { HeaderLogoLockup, Stack } from '@extend/zen'
import type { NavDropdown, NavLink } from './types'
import { DashboardNavLink } from './dashboard-navlink'

type DashboardNavLinksProps = {
  navList: Array<NavLink | NavDropdown>
}

const DashboardNavLinks: FC<React.PropsWithChildren<DashboardNavLinksProps>> = ({
  navList,
}: React.PropsWithChildren<DashboardNavLinksProps>) => {
  return (
    <Stack isRow align="center" spacing={4}>
      <HeaderLogoLockup wordmark="Portal" />
      <Stack isRow spacing={2}>
        {Object.entries(navList).map(([, navLink]) => {
          return <DashboardNavLink navLink={navLink} key={navLink.text} />
        })}
      </Stack>
    </Stack>
  )
}

export { DashboardNavLinks }
