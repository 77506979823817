import type { ChangeEvent, FC } from 'react'
import { useDispatch } from 'react-redux'
import React, { useCallback, useMemo, useState } from 'react'
import Fuse from 'fuse.js'
import type {
  ClaimsSearchClaim} from '@helloextend/extend-api-rtk-query';
import {
  useAssignClaimMutation,
  useListClaimsAssignmentUsersQuery,
  useLazyGetInsuranceClaimQuery
} from '@helloextend/extend-api-rtk-query'
import {
  Button,
  Input,
  MenuButtonItem,
  Popover,
  PopoverAlignment,
  PopoverDirection,
  Stack,
  usePopover,
  ArrowDropDown,
  Search,
} from '@extend/zen'
import type { InsuranceClaim, ClaimsUser } from '@helloextend/extend-api-client'
import styled from '@emotion/styled'
import { setLastUpdatedClaimId } from '../../store/slices/claim-details'

interface AssignUserDropdownProps {
  claim?: InsuranceClaim | ClaimsSearchClaim
  alignment?: PopoverAlignment
}

const AssignUserDropdown: FC<AssignUserDropdownProps> = ({
  claim: inputClaim,
  alignment = PopoverAlignment.end,
}): JSX.Element => {
  const [filter, setFilter] = useState('')
  const [claim, setClaim] = useState(inputClaim)
  const dispatch = useDispatch()

  const { popoverRef, triggerRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLButtonElement>()

  const { data, isSuccess } = useListClaimsAssignmentUsersQuery(undefined)
  const [getClaim] = useLazyGetInsuranceClaimQuery()

  const userList = useMemo(() => data?.items ?? [], [data?.items])

  const [assignUserToClaim] = useAssignClaimMutation()

  const handleSelection = useCallback(
    async (userId: string): Promise<void> => {
      const user = userList.find((u: ClaimsUser) => u.id === userId)
      if (!user || !claim) return
      await assignUserToClaim({
        claimId: claim.id,
        user: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        },
      })
      toggle()
      dispatch(setLastUpdatedClaimId(claim.id))

      const insuranceClaim = await getClaim({ claimId: claim?.id ?? '' })
      if (insuranceClaim?.data) {
        setClaim(insuranceClaim.data)
      }
      dispatch(setLastUpdatedClaimId(null))
    },
    [assignUserToClaim, claim, userList, toggle, dispatch, getClaim],
  )

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFilter(e.currentTarget.value)
  }

  const filteredData = useMemo(() => {
    if (!userList) return []
    if (filter === '') {
      return userList
    }

    const fuse = new Fuse(userList, {
      shouldSort: true,
      keys: ['fullName'],
      minMatchCharLength: 3,
      distance: 100,
      threshold: 0.3,
    })

    return fuse.search(filter)
  }, [userList, filter])

  return claim && isSuccess ? (
    <>
      <AssigneeName>
        {claim?.assignedUser
          ? `${claim?.assignedUser?.firstName} ${claim?.assignedUser?.lastName}`
          : 'Unassigned'}
      </AssigneeName>
      <Button
        ref={triggerRef}
        icon={ArrowDropDown}
        color="blue"
        emphasis="low"
        size="xsmall"
        onClick={toggle}
        data-cy="user-dropdown-button"
        isToggled={isPresent}
      />
      <Popover
        data-cy="assign-user-popover"
        ref={popoverRef}
        direction={PopoverDirection.down}
        alignment={PopoverAlignment[alignment]}
        isPresent={isPresent}
        triggerBoundingBox={triggerBoundingBox}
        maxHeight={298}
        maxWidth={222}
      >
        <Stack padding={1}>
          <Input
            onChange={handleSearchChange}
            id="search"
            value={filter}
            icon={Search}
            placeholder="Search"
            data-cy="dropdown-search-input"
          />
          {filteredData.length ? (
            filteredData.map((user) => (
              <MenuButtonItem
                data-cy={user.fullName || 'select-item'}
                key={user.id}
                onClick={() => handleSelection(user.id)}
              >
                {user.fullName || ''}
              </MenuButtonItem>
            ))
          ) : (
            <MenuButtonItem data-cy="no-matches">No matches found</MenuButtonItem>
          )}
        </Stack>
      </Popover>
    </>
  ) : (
    <></>
  )
}

const AssigneeName = styled.span({})

export { AssignUserDropdown }
