export const expenseOwners = [
  { value: 'extend', display: 'Extend' },
  { value: 'servicer', display: 'Servicer' },
  { value: 'manufacturer', display: 'Manufacturer' },
]

export const expenseTypes = [
  { value: 'diagnosis_fee', display: 'Diagnostic Fee' },
  { value: 'labor', display: 'Labor - Hourly Rate' },
  { value: 'parts', display: 'Parts' },
  { value: 'refurbished_replacement', display: 'Refurbished Replacement' },
  { value: 'replacement', display: 'Replacement' },
  { value: 'taxes', display: 'Taxes' },
  { value: 'trip_charges', display: 'Trip Charges' },
  { value: 'cleaning_kit', display: 'Cleaning Kit' },
  { value: 'shipping', display: 'Shipping & Handling' },
]
