import { useCallback, useEffect, useState } from 'react'
import { useLazyGetAuditLogQuery } from '@helloextend/extend-api-rtk-query'
import type {
  AuditItem,
  Claim,
  ServiceOrder,
  Expense,
  ServiceOrderShipment,
} from '@helloextend/extend-api-client'
import { flatten } from 'lodash/fp'

type AuditLog = AuditItem<Claim | ServiceOrder | Expense | ServiceOrderShipment>
type ReturnType = { data: AuditLog[]; isLoading: boolean; isError: boolean; error: any }

interface HookOptions {
  skip?: boolean
  claimId: string
  expenseIds?: string[]
  serviceOrderIds?: string[]
  serviceOrderShipmentIds?: string[]
}

export const useCombinedAuditLogQuery = (options: HookOptions): ReturnType => {
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([])
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<any>(null)
  const [getAuditLog] = useLazyGetAuditLogQuery()

  const fetchAllData = useCallback(async (): Promise<void> => {
    setIsLoading(true)

    try {
      const fetchPromises: Array<Promise<AuditLog>> = []
      const getClaim = async (): Promise<AuditLog> => {
        const response = await getAuditLog({
          entityType: 'CLAIM',
          entityId: options.claimId,
        }).unwrap()
        return response.items
      }

      fetchPromises.push(getClaim())
      if (options.expenseIds) {
        fetchPromises.push(
          ...options.expenseIds.map(async (id) => {
            const response = await getAuditLog({ entityType: 'EXPENSE', entityId: id }).unwrap()
            return response.items
          }),
        )
      }
      if (options.serviceOrderIds) {
        fetchPromises.push(
          ...options.serviceOrderIds.map(async (id) => {
            const response = await getAuditLog({
              entityType: 'SERVICE_ORDER',
              entityId: id,
            }).unwrap()
            return response.items
          }),
        )
      }
      if (options.serviceOrderShipmentIds) {
        fetchPromises.push(
          ...options.serviceOrderShipmentIds.map(async (id) => {
            const response = await getAuditLog({
              entityType: 'SERVICE_ORDER_SHIPMENT',
              entityId: id,
            }).unwrap()
            return response.items
          }),
        )
      }

      const results = flatten(await Promise.all(fetchPromises)).sort(
        (a, b) => a.eventTime - b.eventTime,
      )
      setAuditLogs(results)
      setIsSuccess(true)
    } catch (err: unknown) {
      setError(err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [options, getAuditLog, setAuditLogs, setIsLoading, setIsSuccess])

  useEffect(() => {
    if (options.skip || isLoading || isError || isSuccess) return
    fetchAllData()
  }, [
    getAuditLog,
    fetchAllData,
    auditLogs,
    isLoading,
    isError,
    isSuccess,
    options.claimId,
    options.expenseIds,
    options.serviceOrderIds,
    options.serviceOrderShipmentIds,
    options.skip,
  ])

  return { data: auditLogs, isLoading, isError, error }
}
