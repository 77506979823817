const skuCsvHeaders = [
  'skuId',
  'skuName',
  'lineOfBusiness',
  'program',
  'subprogram',
  'underwriter',
  'insuranceProgramId',
  'coverageType',
  'termLength',
  'serviceType',
  'productCondition',
  'purchasePriceOfProduct',
  'limitOfLiabilityBasedUpon',
  'paymentModel',
  'cancellationId',
  'deductible',
  'deductibleAmount',
  'currency',
  'priceBandLow',
  'priceBandHigh',
  'lossCost',
  'targetLossRatio',
  'rateFeePercentage',
  'adminFee',
  'lossReserve',
  'rateFee',
  'premium',
  'activateTo',
  'activateFrom',
  'frequency',
  'severity',
  'source',
]

export { skuCsvHeaders }
