import type { FC } from 'react'
import React from 'react'
import { Checkbox } from '@extend/zen'

export interface GoToMarketCheckboxProps {
  checked: boolean
  onChange: () => void
  isDisabled: boolean
}

const GoToMarketCheckbox: FC<GoToMarketCheckboxProps> = ({ checked, onChange, isDisabled }) => {
  return (
    <Checkbox
      name="go-to-market"
      value="go-to-market"
      label="Set this as the Go-To-Market plan set for the selected plan category"
      checked={checked}
      data-cy="gtm-checkbox"
      onChange={onChange}
      disabled={isDisabled}
    />
  )
}

export { GoToMarketCheckbox }
