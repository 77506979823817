import { DataTable, ToastColor, ToastDuration, Visibility, useToaster } from '@extend/zen'
import type { DataTableAction, DataTableMenuItem } from '@extend/zen'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import {
  useDeletePlanTermsMutation,
  useLazyGetPlanTermsVersionLanguageUrlQuery,
  useLazyGetPlanTermsVersionLanguagesQuery,
  useListPlanTermsQuery,
} from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router-dom'
import { useCopyToClipboard } from '@helloextend/merchants-ui'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { useExtendAuth } from '@extend/package-okta-login'
import { findLatestVersion, tableColumns } from './terms-list-table-config'
import { userRoles } from '../../../../utils/user-role-mapper'
import type { PlanTerms } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'

const TermsListTable: FC = () => {
  const { user } = useExtendAuth()
  const { data, isFetching } = useListPlanTermsQuery()
  const [getVersionLanguages] = useLazyGetPlanTermsVersionLanguagesQuery()
  const [getVersionLanguageUrl] = useLazyGetPlanTermsVersionLanguageUrlQuery()

  const [
    deleteTerms,
    { isSuccess: isTermsDeleteSuccess, isError: isTermsDeleteError, error: termsDeleteError },
  ] = useDeletePlanTermsMutation()

  const history = useHistory()

  const { toast } = useToaster()

  const copyToClipboard = useCopyToClipboard()

  const getRowActions = (row: PlanTerms): DataTableAction[] => {
    return [
      {
        icon: Visibility,
        onClick: () => handleGetVersionUrlClick(row),
        emphasis: 'low',
        'data-cy': 'view-terms-button',
      },
    ]
  }

  const getRowMenuItems = (row: PlanTerms): DataTableMenuItem[] => {
    return [
      ...(row.versions.length > 1
        ? [
            {
              children: 'View versions',
              onClick: () => handleViewVersionsClick(row),
              'data-cy': 'view-versions-button',
            },
          ]
        : []),
      {
        children: 'Copy link',
        onClick: () => handleCopyToClipboard(row),
        'data-cy': 'copy-link-button',
      },
      {
        children: 'Upload new version',
        onClick: () => handleUploadNewVersion(row),
        'data-cy': 'upload-new-version-button',
      },
      ...(user?.role === userRoles.ComplianceManager.value
        ? [
            {
              children: 'Delete',
              onClick: () => deleteTerms(row.termsId),
              'data-cy': 'delete-button',
            },
          ]
        : []),
    ]
  }

  const getVersionPdfUrl = async (row: PlanTerms): Promise<string> => {
    const latestVersion = findLatestVersion(row.versions).version
    let languages

    try {
      languages = await getVersionLanguages({
        termsId: row.termsId,
        version: latestVersion,
      }).unwrap()
    } catch (error) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }

    const url = ''
    if (!languages) return url

    try {
      const res = await getVersionLanguageUrl({
        version: latestVersion,
        termsId: row.termsId,
        language: languages[0].language || 'en',
      }).unwrap()
      return res.url ?? ''
    } catch (error) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }

    return url
  }

  const handleUploadNewVersion = (row: PlanTerms): void => {
    history.push(`/admin/terms/${row.termsId}/create-version`)
  }

  const handleGetVersionUrlClick = async (row: PlanTerms): Promise<void> => {
    const url = await getVersionPdfUrl(row)
    window.open(url, '_blank')
  }

  const handleCopyToClipboard = async (row: PlanTerms): Promise<void> => {
    const url = await getVersionPdfUrl(row)
    copyToClipboard(`${row.termsId} URL`, url, undefined, ToastColor.blue)
  }

  const handleViewVersionsClick = (row: PlanTerms): void => {
    history.push(
      `/admin/terms/${row.termsId}/versions/${
        findLatestVersion(row.versions).version
      }?tab=versions`,
    )
  }

  useEffect(() => {
    if (isTermsDeleteSuccess) {
      toast({
        message: 'Terms deleted successfully',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
    }

    if (isTermsDeleteError) {
      const { status, data } = termsDeleteError as FetchBaseQueryError

      const errorMessage =
        status === 400
          ? (data as { message: string }).message
          : 'Something went wrong. Please try again.'
      toast({
        message: errorMessage,
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isTermsDeleteSuccess, isTermsDeleteError, history, toast])

  return (
    <DataTable
      data-cy="terms-data-table"
      isLoading={isFetching}
      columns={tableColumns}
      data={data || []}
      getRowMenuItems={getRowMenuItems}
      getRowActions={getRowActions}
    />
  )
}

export { TermsListTable }
