import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import type { ServiceOrderExpensesCreateRequest } from '@helloextend/extend-api-client'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'
import { fetch } from './fetch'

type Action = ReturnType<typeof actions.create>

function* create(payload: {
  serviceOrderId: string
  accessToken: string
  body: ServiceOrderExpensesCreateRequest
}): SagaIterator {
  const { serviceOrderId, body, accessToken } = payload
  yield put(actions.createRequest())

  try {
    const response: ResolvedType<typeof client.serviceOrders.createExpense> = yield call(
      client.serviceOrders.createExpense,
      serviceOrderId,
      body,
      accessToken,
    )

    if (isErrorResponse(response)) {
      yield put(actions.createFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.createFailure(`An unknown error occurred`, response.status))
      return
    }

    yield put(actions.createSuccess(response.data))
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.createFailure(e.message))
    }
  }
}

export default function* addExpenseAndRefetch(action: Action): SagaIterator {
  yield call(create, action.payload)
  yield call(fetch, action.payload)
}
