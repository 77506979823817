import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import {
  AdvancedSelect,
  Paragraph,
  getFormattedValue,
  Visibility,
  VisibilityOff,
} from '@extend/zen'
import { validate } from '@extend/client-helpers'
import type { FormikHandlers } from 'formik'
import { useGetShippingProtectionPlansQuery } from '@helloextend/extend-api-rtk-query'
import { listToAdvancedSelectOptions } from '../../../../../../../../utils/form-utils'

type ShippingProtectionPlansDropdownProps = {
  onChange: FormikHandlers['handleChange']
  value: string
  isError?: boolean
  errorFeedback?: string
  onBlur?: (e: Event) => void
  isDisabled?: boolean
  label?: string
  id?: string
  dataCy?: string
  isPlanDetailsDisplayed?: boolean
  category?: string
}
/**
 * select fields required to toggle shippingProtection "enabled" property
 */
const ShippingProtectionPlansDropdown: FC<ShippingProtectionPlansDropdownProps> = ({
  onChange,
  value,
  isError,
  errorFeedback,
  isDisabled,
  onBlur,
  label = 'Plan ID',
  id = 'planId',
  dataCy = 'planId-dropdown',
  isPlanDetailsDisplayed = false,
  category,
}) => {
  const [isGuidSPPlansFiltered, setIsGuidSPPlansFiltered] = useState(false)

  const { data: spPlans, isLoading: isSpPlansListLoading } = useGetShippingProtectionPlansQuery()

  const spAdditionalDetails = useMemo(() => {
    const spDetails = spPlans?.find((plan) => plan.id === value)
    return (
      <>
        <Paragraph>
          shipCoverageMax:{' '}
          {spDetails?.shipCoverageMax ? (
            getFormattedValue(spDetails?.shipCoverageMax?.toString())
          ) : (
            <span>&mdash;</span>
          )}
        </Paragraph>
        <Paragraph>
          productCategory:{' '}
          {spDetails?.productCategory ? spDetails?.productCategory : <span>&mdash;</span>}
        </Paragraph>
      </>
    )
  }, [spPlans, value])

  const filtered = useMemo(() => {
    let filteredPlans = spPlans
    if (category) {
      filteredPlans = filteredPlans?.filter(
        (plan) => plan.productCategory === category || plan.productCategory === 'General', // A shipping protection plan with a “General” designation can be mapped to any category in a Purchase Model.
      )
    }

    return isGuidSPPlansFiltered
      ? filteredPlans?.filter((plan) => !validate.isValidUuid(plan.id))
      : filteredPlans
  }, [category, isGuidSPPlansFiltered, spPlans])

  const filteredOptions = useMemo(() => {
    return listToAdvancedSelectOptions(
      filtered?.map((plan) => plan.id) || [],
      isPlanDetailsDisplayed ? value : undefined,
      isPlanDetailsDisplayed ? spAdditionalDetails : undefined,
    )
  }, [filtered, isPlanDetailsDisplayed, spAdditionalDetails, value])

  return (
    <>
      <AdvancedSelect
        label={label}
        id={id}
        isNotClearable
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder="Select"
        isError={isError}
        isLoading={isSpPlansListLoading}
        showSearch
        errorFeedback={errorFeedback}
        options={filteredOptions}
        multiple={false}
        data-cy={dataCy}
        pageSize={50}
        isDisabled={isDisabled}
        footerActions={[
          {
            onClick: () => setIsGuidSPPlansFiltered(!isGuidSPPlansFiltered),
            children: isGuidSPPlansFiltered ? 'Show Test Plans' : 'Hide Test Plans',
            icon: isGuidSPPlansFiltered ? Visibility : VisibilityOff,
            'data-cy': 'toggle-test-plans',
            disableAutoDismiss: true,
          },
        ]}
      />
    </>
  )
}

export { ShippingProtectionPlansDropdown }
