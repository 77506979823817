import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { date } from '@extend/client-helpers'
import type { Claim } from '@helloextend/extend-api-client'
import { COLOR, Grid, DataProperty, Radio, DataPropertyType } from '@extend/zen'
import { uuid } from '@helloextend/extend-sdk-client/lib/utils/util'
import { useClaimSessionLogs } from '../../hooks/use-claim-session-logs'
import { labelMap, outputTextMap } from './utils'

interface IncidentReportTabProps {
  claim: Claim
}

const IncidentReportTab: FC<IncidentReportTabProps> = ({ claim }) => {
  const { answerMessageList } = useClaimSessionLogs({
    sessionId: claim.sessionId ?? '',
    claimId: claim.id,
  })

  return (
    <Container>
      <Header>
        <Title>Incident Report</Title>
      </Header>
      <AdjudicationWrapper>
        <Grid
          columns={{
            lg: 1,
            md: 1,
            sm: 1,
          }}
          spacing={{
            lg: 6,
            md: 4,
            sm: 2,
          }}
        >
          {answerMessageList?.map(({ type, prompt, answer, options }) => {
            const label = labelMap[prompt ?? ''] ?? prompt

            const imageUploadLabelText =
              label.indexOf('}') > 0 ? (
                <>
                  {label?.substring(1, label.indexOf('}'))}
                  <br />
                  {label?.substring(label.indexOf('}') + 2)}{' '}
                </>
              ) : (
                label
              )

            switch (type) {
              case 'radio':
                return (
                  <RadioGroup key={`${prompt}-${type}-${answer}`}>
                    <LabelWrapper>
                      <LabelText>{label}</LabelText>
                    </LabelWrapper>
                    {options?.map(({ value, outputText }) => (
                      <Radio
                        key={`${value}${uuid()}`}
                        value={value as string}
                        name={`${value}${uuid()}`}
                        label={outputTextMap[outputText || ''] || (outputText ?? '')}
                        checked={answer === value}
                        disabled
                      />
                    ))}
                  </RadioGroup>
                )
              case 'datepicker':
                return (
                  <DataProperty
                    key={`${prompt}-${type}-${answer}`}
                    data-cy="data-property-date"
                    label={label}
                    value={date.format(answer as number)}
                  />
                )
              case 'imageUpload':
                return (
                  <ImageAnswer key={`${prompt}-${type}-${answer}`}>
                    <LabelWrapper>
                      <LabelText>{imageUploadLabelText}</LabelText>
                    </LabelWrapper>
                    <Image src={answer as string} />
                  </ImageAnswer>
                )
              default:
                return (
                  <DataProperty
                    key={`${prompt}-${type}-${answer}`}
                    data-cy="data-property"
                    label={label}
                    value={answer?.toString() ?? ''}
                    type={DataPropertyType.string}
                  />
                )
            }
          })}
        </Grid>
      </AdjudicationWrapper>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  marginBottom: 32,
  padding: 40,
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginBottom: 16,
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

const AdjudicationWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 32,
})

const RadioGroup = styled.div()

const ImageAnswer = styled.div()

const Image = styled.img({
  width: 200,
})

const LabelWrapper = styled.label({
  fontFamily: 'Nunito Sans, sans-serif',
})

const LabelText = styled.div({
  color: COLOR.NEUTRAL[600],
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
  lineHeight: '24px',
})

export { IncidentReportTab }
