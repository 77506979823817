import { COLOR } from '@extend/zen'

export const getBorderColor = (error?: boolean, disabled?: boolean): string => {
  if (disabled) {
    return COLOR.NEUTRAL[300]
  }

  if (error) {
    return COLOR.RED[700]
  }

  return COLOR.NEUTRAL[400]
}
