import type { FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import type { Claim, ClaimCustomerInformation } from '@helloextend/extend-api-client'
import type { CollapsibleInfoGroupProps } from '../../../../../../components/collapsible-info-group'
import { CollapsibleInfoGroup } from '../../../../../../components/collapsible-info-group'
import type { Values } from './schema'
import { getCustomerInfo, schema } from './schema'
import {
  formatPhoneNumberIso,
  formatPhoneNumberLocal,
  formatPhoneNumberOnChange,
  validatePhoneNumber,
} from '@extend/client-helpers'
import { useUpdateClaim } from '../../../../../../hooks'

interface CustomerInfoSectionProps {
  claim: Claim
}

const CustomerInfoSection: FC<CustomerInfoSectionProps> = ({ claim }) => {
  const { updateClaim } = useUpdateClaim()
  const [isEditing, setIsEditing] = useState(false)
  const [isCustomerPhoneValid, setIsCustomerPhoneValid] = useState(validatePhoneNumber(claim.customer?.phone))
  const customer = claim?.customer

  const { values, errors, resetForm, submitForm, setFieldValue } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: {
      ...customer,
      phone: formatPhoneNumberLocal(customer.phone as string),
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: async (body) => {
      const customer = body as ClaimCustomerInformation
      await updateClaim({
        claimId: claim.id,
        body: { customer },
      })
      toggleIsEditing()
    },
  })

  const customerInfo = useMemo(
    () => getCustomerInfo(isEditing, customer, values, errors),
    [isEditing, customer, values, errors],
  )

  const handleChange = (key: string, value: any): void => {
    if (key === 'phone') {
      setFieldValue(key, formatPhoneNumberOnChange(value))
      setIsCustomerPhoneValid(validatePhoneNumber(value))
    } else {
      setFieldValue(key, value)
    }
  }

  const handleSubmitForm = (): void => {
    setFieldValue('shippingAddress', values.shippingAddress)
    // format the phone number to e.164 format
    setFieldValue('phone', formatPhoneNumberIso(values.phone as string))
    submitForm()
  }
  const toggleIsEditing = useCallback(() => {
    setIsEditing(!isEditing)
  }, [setIsEditing, isEditing])

  return (
    <CollapsibleInfoGroup
      data-cy="customer-info-section"
      title="Customer Contact"
      data={customerInfo}
      handleChange={handleChange as CollapsibleInfoGroupProps['handleChange']}
      numColumns={3}
      hasEditButton
      isEditing={isEditing}
      onEditButtonClick={toggleIsEditing}
      primaryButtonText={isEditing ? 'Save Changes' : undefined}
      onPrimaryButtonClick={handleSubmitForm}
      secondaryButtonText={isEditing ? 'Cancel' : undefined}
      onSecondaryButtonClick={() => {
        resetForm()
        toggleIsEditing()
      }}
      buttonAlignment="right"
      isDefaultCollapsed={false}
      warning={!isCustomerPhoneValid ? 'Phone number needs to be entered correctly' : undefined}
    />
  )
}

export { CustomerInfoSection }
