import type { FC } from 'react'
import React, { useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { Button, Add as AddIcon } from '@extend/zen'
import { PageHeader } from '../../../../components/page-header'
import { CreateAccountModal } from '../components/create-account-modal/create-account-modal'
import { AccountsDataTable } from './accounts-data-table/accounts-data-table'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'

const Accounts: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { hasPermission } = usePermissions()

  const toggleModal = useCallback((): void => {
    setIsModalVisible(!isModalVisible)
  }, [isModalVisible])

  return (
    <>
      <HeaderContainer>
        <PageHeader title="Merchant Accounts" />
        {hasPermission(Permission.CreateAccounts) && (
          <Button
            text="Create Account"
            onClick={toggleModal}
            data-cy="create-account-button"
            emphasis="medium"
            icon={AddIcon}
          />
        )}
      </HeaderContainer>
      <CreateAccountModal isVisible={isModalVisible} onClickClose={toggleModal} />
      <AccountsDataTable />
    </>
  )
}

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export { Accounts }
