import { useRef } from 'react'

type UseWaitForEventParameters = {
  refetchCallback: () => void
  successCallback: () => void
  errorCallback: () => void
  hasEventProcessed: boolean
  retries?: number
  delay?: number
}

const useWaitForEvent = ({
  refetchCallback,
  successCallback,
  errorCallback,
  hasEventProcessed,
  retries = 3,
  delay = 1000,
}: UseWaitForEventParameters): { waitForUpdate: () => void } => {
  const prevInterval = useRef<NodeJS.Timeout | null>(null)

  const pollingInterval = (): void => {
    let remainingRetries = retries
    const interval = setInterval(async () => {
      if (remainingRetries > 0) {
        refetchCallback()
      } else if (remainingRetries === 0) {
        errorCallback()
        clearInterval(interval)
      }
      remainingRetries -= 1
    }, delay)
    prevInterval.current = interval
  }

  if (hasEventProcessed && prevInterval.current) {
    clearInterval(prevInterval.current)
    successCallback()
  }

  return { waitForUpdate: pollingInterval }
}

export type { UseWaitForEventParameters }
export { useWaitForEvent }
