import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { GridColumnCount } from '@extend/zen'
import { COLOR, Grid, DataProperty } from '@extend/zen'

interface GroupItem {
  name: string
  value?: string
  label: string
  columnCount?: number
  block?: boolean
  maxCount?: number
  includeCopyAction?: boolean
  to?: string
  openInNew?: boolean
}

interface GroupSection {
  header?: string
  values: Array<GroupItem | null>
}

type DataDisplayGroupProps = {
  data: GroupSection[]
  numColumns?: GridColumnCount
}

const DataDisplayGroup: FC<DataDisplayGroupProps> = ({ data, numColumns = 1, children }) => {
  return (
    <ContentWrapper data-cy="data-display-group">
      {data.map((section: GroupSection, idx: number) => {
        return (
          <SectionWrapper isLast={Boolean(!data[idx + 1])} key={`section-${idx + 1}`}>
            {Boolean(section.header) && <SectionHeader>{section.header}</SectionHeader>}
            <Grid
              columns={{
                lg: numColumns ?? 2,
                md: 2,
                sm: 1,
              }}
              spacing={{
                lg: 6,
                md: 4,
                sm: 4,
              }}
            >
              {section.values.map((item: GroupItem | null) => {
                if (item == null) return null

                const { columnCount, ...itemProps } = item

                return (
                  <DataProperty
                    key={itemProps.name}
                    data-cy={itemProps.label}
                    label={itemProps.label}
                    value={itemProps.value}
                    includeCopyAction={itemProps.includeCopyAction}
                    to={itemProps.to}
                    openInNew={itemProps.openInNew}
                  />
                )
              })}
            </Grid>
          </SectionWrapper>
        )
      })}
      {children}
    </ContentWrapper>
  )
}

const SectionHeader = styled.h5({
  textTransform: 'uppercase',
})

const SectionWrapper = styled.div<{ isLast: boolean }>(({ isLast }) => ({
  marginTop: 32,
  borderBottom: isLast ? undefined : `1px solid ${COLOR.NEUTRAL[600]}`,
  paddingBottom: isLast ? 0 : 16,
  width: '100%',
}))

const ContentWrapper = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export type { DataDisplayGroupProps, GroupItem, GroupSection }
export { DataDisplayGroup }
