import type { PlanTermsVersionLanguage } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'
import { DataProperty, DataPropertyType, Grid, Section } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'

type DocumentLinksProps = {
  languages: PlanTermsVersionLanguage[]
}

const DocumentLinks: FC<DocumentLinksProps> = ({ languages }) => {
  return (
    <Section heading="Document Links">
      <Grid columns={4} spacing={2}>
        {languages.map((language) => {
          return (
            <DataProperty
              key={language.language}
              label={language.language}
              type={DataPropertyType.string}
              value={getFileName(language.url)}
              to={language.url}
            />
          )
        })}
      </Grid>{' '}
    </Section>
  )
}

const getFileName = (url?: string) => {
  if (!url) {
    return undefined
  }
  const urlParts = url.split('/')
  return urlParts[urlParts.length - 1]
}

export { DocumentLinks }
