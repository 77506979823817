import type { FC, SyntheticEvent } from 'react'
import React, { memo } from 'react'
import styled from '@emotion/styled'
import { Modal, ModalController, Stack, Input, CurrencyInput, Label, Paragraph } from '@extend/zen'

import type { CategoryProduct } from '@helloextend/extend-api-client'
import { date } from '@extend/client-helpers'
import images from '../../../../../images'
import { NumberInput } from '../../../../../components/number-input'
import { FormDatePicker } from '../../../../../components/collapsible-info-group/form-date-picker'

type SelectedCategoryProduct = CategoryProduct & {
  quantity?: number
}

type ProductModalProps = {
  isOpen: boolean
  setOpenModal: (value: boolean) => void
  handleSave: (value: SelectedCategoryProduct | undefined) => void
  selectedProduct: SelectedCategoryProduct | undefined
  onDateChange: (key: string, value: number | null) => void
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void
}

export const ProductModal: FC<ProductModalProps> = memo(
  ({ isOpen, setOpenModal, handleSave, selectedProduct, onDateChange, onChange }) => {
    const hasQuantity = selectedProduct && 'quantity' in selectedProduct
    const isStandardBundle = selectedProduct && selectedProduct?.type === 'standard_bundle'
    const isCustomBundle = selectedProduct && selectedProduct?.type === 'custom_bundle'
    const isStandardOrCustomBundle = isStandardBundle || isCustomBundle

    return (
      <ModalController isOpen={isOpen}>
        <Modal
          heading="Product Details"
          onDismissRequest={() => setOpenModal(false)}
          primaryButtonProps={{
            onClick: () => setOpenModal(false),
            text: 'Close',
          }}
          secondaryButtonProps={{
            onClick: () => handleSave(selectedProduct),
            text: 'Save',
          }}
          size="md"
        >
          <ModalBody>
            <Stack spacing={3}>
              <Stack isRow spacing={3}>
                <StyledImage src={selectedProduct?.imageUrl || images.productPlaceholder} />
                <InputWrapper>
                  <Input
                    data-cy="product-title"
                    id="title"
                    label="Product Title"
                    onChange={onChange}
                    placeholder={selectedProduct?.title || ''}
                    value={selectedProduct?.title || ''}
                  />
                </InputWrapper>
              </Stack>
              <Stack spacing={6}>
                <Label>Product Description</Label>
                <ContainDescription>
                  {selectedProduct?.description ? (
                    <Paragraph>{selectedProduct?.description}</Paragraph>
                  ) : (
                    '_'
                  )}
                </ContainDescription>
              </Stack>
              <Stack isRow spacing={6}>
                <CustomInputWrapper>
                  <NumberInput
                    data-cy="manufacturer-warranty-length-labor"
                    id="manufacturerWarrantyLengthLabor"
                    label="Manufacturer Warranty Length Labor"
                    disabled={false}
                    onChange={onChange}
                    value={selectedProduct?.manufacturerWarrantyLengthLabor}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper>
                  <NumberInput
                    data-cy="manufacturer-warranty-length-parts"
                    id="manufacturerWarrantyLengthParts"
                    label="Manufacturer Warranty Length Parts"
                    onChange={onChange}
                    value={selectedProduct?.manufacturerWarrantyLengthParts}
                    disabled={false}
                  />
                </CustomInputWrapper>
              </Stack>
              <Stack isRow spacing={6}>
                <CustomInputWrapper>
                  <Label>List Price</Label>
                  <ContainCurrencyInput>
                    <CurrencyInput
                      data-cy="list-price"
                      currency="USD"
                      id="listPrice"
                      onChange={onChange}
                      value={selectedProduct?.listPrice.toString() || ''}
                    />
                  </ContainCurrencyInput>
                </CustomInputWrapper>
                <CustomInputWrapper>
                  <Label>Purchase Price</Label>
                  <ContainCurrencyInput>
                    <CurrencyInput
                      data-cy="purchase-price"
                      currency="USD"
                      id="purchasePrice"
                      onChange={onChange}
                      value={selectedProduct?.purchasePrice.toString() || ''}
                    />
                  </ContainCurrencyInput>
                </CustomInputWrapper>
              </Stack>
              <Stack isRow spacing={6}>
                <CustomInputWrapper>
                  <Input
                    data-cy="reference-id"
                    id="referenceId"
                    label="Reference ID"
                    onChange={onChange}
                    placeholder={selectedProduct?.referenceId || ''}
                    value={selectedProduct?.referenceId || ''}
                    isDisabled
                  />
                </CustomInputWrapper>
                <CustomInputWrapper>
                  <Input
                    data-cy="serial-number"
                    id="serialNumber"
                    label="Serial Number"
                    onChange={onChange}
                    placeholder={selectedProduct?.serialNumber || ''}
                    value={selectedProduct?.serialNumber || ''}
                    isDisabled={isStandardOrCustomBundle}
                  />
                </CustomInputWrapper>
              </Stack>
              <Stack isRow spacing={6}>
                <CustomInputWrapper>
                  <FormDatePicker
                    label="Transaction Date"
                    date={
                      selectedProduct?.transactionDate
                        ? new Date(selectedProduct?.transactionDate)
                        : null
                    }
                    onChange={(date) => onDateChange('transactionDate', date)}
                    maxDate={new Date()}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper>
                  <Input
                    data-cy="transaction-id"
                    id="transactionId"
                    label="Transaction ID"
                    onChange={onChange}
                    placeholder={selectedProduct?.transactionId || ''}
                    value={selectedProduct?.transactionId || ''}
                  />
                </CustomInputWrapper>
              </Stack>
              <Stack isRow spacing={6}>
                <CustomInputWrapper>
                  <Input
                    data-cy="line-item-id"
                    id="lineItemId"
                    label="Line Item ID"
                    onChange={onChange}
                    placeholder={selectedProduct?.lineItemId || ''}
                    value={selectedProduct?.lineItemId || ''}
                    isDisabled
                  />
                </CustomInputWrapper>
                <CustomInputWrapper>
                  {!isStandardOrCustomBundle && (
                    <FormDatePicker
                      label="Fulfillment Date"
                      date={
                        selectedProduct?.fulfillmentDate
                          ? new Date(selectedProduct?.fulfillmentDate)
                          : null
                      }
                      onChange={(date) => onDateChange('fulfillmentDate', date)}
                      maxDate={new Date()}
                    />
                  )}
                  {isStandardOrCustomBundle && (
                    <Input
                      data-cy="Fulfillment Date"
                      id="fulfillmentDate"
                      label="Fulfillment Date"
                      onChange={onChange}
                      placeholder={
                        date.format(selectedProduct?.fulfillmentDate || Date.now(), 'MM/DD/YYYY') ||
                        ''
                      }
                      value={
                        date.format(selectedProduct?.fulfillmentDate || Date.now(), 'MM/DD/YYYY') ||
                        ''
                      }
                      isDisabled={isStandardOrCustomBundle}
                    />
                  )}
                </CustomInputWrapper>
              </Stack>

              {hasQuantity && (
                <Stack isRow spacing={6}>
                  <CustomInputWrapper>
                    <NumberInput
                      data-cy="product-quantity"
                      id="quantity"
                      label="Quantity"
                      onChange={onChange}
                      value={selectedProduct?.quantity || 1}
                      disabled
                    />
                  </CustomInputWrapper>
                </Stack>
              )}
            </Stack>
            <br />
          </ModalBody>
        </Modal>
      </ModalController>
    )
  },
)

const ModalBody = styled.div({
  margin: '0.2rem',
  maxWidth: '100%',
})

const StyledImage = styled.img({
  width: '6.3rem',
})

const InputWrapper = styled.div({
  width: 450,
  marginBottom: 5,
})

const CustomInputWrapper = styled.div({
  width: 270,
  marginBottom: 5,
})

const ContainCurrencyInput = styled.div({
  marginTop: '0.35rem',
})

const ContainDescription = styled.div({
  marginTop: '-2.35rem',
})
