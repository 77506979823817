import extendIcon from './extend-icon.png'
import extendLogo from './extend-logo.png'
import extendLogoWhite from './extend-logo-white.png'
import checkmark from './checkmark.svg'
import crossmark from './crossmark.svg'
import shopify from './shopify.png'
import magento from './magento.png'
import bigCommerce from './big-commerce.png'
import wooCommerce from './woocommerce.png'
import volusion from './volusion.png'
import salesforce from './salesforce-commerce.png'
import custom from './custom.png'
import products from './products.png'
import rocket from './rocket.png'
import headphones from './onboarding-preview-headphones.png'
import puzzleSparkle from './puzzle-sparkle.gif'
import wavingHand from './waving-hand.png'
import hands from './hands-celebration.png'
import shopifyInstall from './shopify-install.svg'
import placeholderImage from './extend-placeholder-mountains.svg'
import helpBasicsImage from './extend-help-basics-thumbnail.svg'
import helpBillingImage from './extend-help-billing-thumbnail.svg'
import helpLeadsImage from './extend-help-leads-thumbnail.svg'
import cloudsError from './clouds-error.svg'

const Images = {
  extendIcon,
  extendLogo,
  extendLogoWhite,
  checkmark,
  crossmark,
  shopify,
  magento,
  bigCommerce,
  wooCommerce,
  volusion,
  salesforce,
  custom,
  products,
  rocket,
  headphones,
  puzzleSparkle,
  wavingHand,
  hands,
  shopifyInstall,
  placeholderImage,
  helpBasicsImage,
  helpBillingImage,
  helpLeadsImage,
  cloudsError,
}

export type Image = keyof typeof Images
export default Images
