import styled from '@emotion/styled'
import { Subheading, Paragraph, Button, Input, COLOR } from '@extend/zen'

export const FooterContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1rem',
})

export const DateRangeContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '1.5rem',
  justifyContent: 'space-between',
  width: '100%',
})

export const DateInput = styled(Input)({
  width: '100%',
})

export const StyledSubheading = styled(Subheading)({
  marginTop: '1.5rem',
})

export const StyledParagraph = styled(Paragraph)({
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
})

export const UploadButton = styled(Button)({ marginTop: '1.5rem' })

export const Title = styled.h1({
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  margin: '24px 0 8px',
})

export const PromoptionIdContainer = styled.p({
  marginTop: '0',
})

export const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '1rem',
  marginTop: '0.5rem',
  marginBottom: '1rem',
})

export const IconContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'right',
  marginTop: '0.2rem',
  cursor: 'pointer',
})

export const Head = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '1rem',
})

export const IconText = styled(Paragraph)({
  marginLeft: '0.2rem',
  color: COLOR.BLUE[700],
  fontWeight: 700,
})

export const Body = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

export const PromotionName = styled.div({
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
})

export const Item = styled.div({
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10%',
})

export const Key = styled(Paragraph)({
  fontWeight: 'bold',
  fontSize: '14px',
  color: COLOR.NEUTRAL[600],
})

export const ContainTitle = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

export const ContainBadge = styled.div({
  marginLeft: '1rem',
  marginTop: '2.2rem',
})

export const ContainProductsDownload = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

export const DownloadIconContainer = styled.div({
  marginLeft: '0.5rem',
  cursor: 'pointer',
})
