import { useState } from 'react'
import {
  useSaveThreadMutation,
  useUpdateConversationMutation,
  usePublishConversationMutation,
} from '@helloextend/extend-api-rtk-query'
import { useToaster, ToastColor, ToastDuration } from '@extend/zen'
import { useSelector } from 'react-redux'
import * as selectors from '../reducers/selectors'
import type { RootState } from '../reducers'
import type { FullConversation } from '../types/conversations'
import type { SingleUseThreadsMap } from '../store/slices/amp-slice'
import { compareThreadsDeepEquality } from '../pages/admin/adjudication-management/utils'

type HandlePersistConversationArgs = {
  fullConversation: FullConversation
  singleUseThreadsMap: SingleUseThreadsMap
  isSavingAndPublishing: boolean
}

type UsePersistConversationProperties = {
  handlePersistConversation: (args: HandlePersistConversationArgs) => Promise<void>
  isPersistConversationProcessing: boolean
  hasSuccessfullyPublished: boolean
  hasSuccessfullySaved: boolean
}

function usePersistConversation(): UsePersistConversationProperties {
  const selectedConversation = useSelector((state: RootState) =>
    selectors.getSelectedConversation(state),
  )
  const { toast } = useToaster()

  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [hasSuccessfullyPublished, setHasSuccessfullyPublished] = useState<boolean>(false)
  // marker for removing search query parameters
  const [hasSuccessfullySaved, setHasSuccessfullySaved] = useState<boolean>(false)

  const [saveThread] = useSaveThreadMutation()
  const [saveConversation] = useUpdateConversationMutation()
  const [publishConversation] = usePublishConversationMutation()

  const handlePersistConversation = async ({
    fullConversation,
    singleUseThreadsMap,
    isSavingAndPublishing,
  }: HandlePersistConversationArgs): Promise<void> => {
    setIsProcessing(true)

    const changedSingleUseThreads = Object.values(singleUseThreadsMap).filter((thread) => {
      const fullThread = fullConversation.threads.find((conversationThread) => {
        return conversationThread?.id === thread.id
      })
      return !compareThreadsDeepEquality(fullThread, thread)
    })

    const threadUpdates = changedSingleUseThreads.map(async (thread) => {
      return saveThread({ threadId: thread.id, thread }).unwrap()
    })

    try {
      // Save all threads that were modified
      await Promise.all(threadUpdates)

      // Save conversation
      if (!selectedConversation) throw new Error('No active conversation to save')
      const saveConversationResponse = await saveConversation({
        conversationId: selectedConversation.id,
        conversation: selectedConversation,
      }).unwrap()

      // when only saving clear search query parameters
      if (!isSavingAndPublishing) {
        setHasSuccessfullySaved(true)
      }

      // publish conversation when boolean is true
      if (isSavingAndPublishing) {
        await publishConversation({
          conversationId: selectedConversation.id,
          version: saveConversationResponse.version,
        }).unwrap()

        setHasSuccessfullyPublished(true)
      }

      toast({
        message: `${selectedConversation?.title} has been successfully ${
          isSavingAndPublishing ? 'published' : 'saved'
        }.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    } catch (e: unknown) {
      console.error(e)
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    } finally {
      setIsProcessing(false)
    }
  }
  return {
    handlePersistConversation,
    isPersistConversationProcessing: isProcessing,
    hasSuccessfullyPublished,
    hasSuccessfullySaved,
  }
}

export type { HandlePersistConversationArgs, UsePersistConversationProperties }
export { usePersistConversation }
