import type { Store } from '@helloextend/extend-api-client'
import type { FC } from 'react'
import React from 'react'
import { FormikProvider, useFormik } from 'formik'
import {
  useGetListSPPlanCategoryMappingsQuery,
  useGetSPPriceBandMappingsQuery,
  useUpdateSPPriceBandMappingsMutation,
  useUpdateStoreMutation,
  usePutSPPlanCategoryMappingMutation,
  useDeleteSPPlanCategoryMappingMutation,
} from '@helloextend/extend-api-rtk-query'
import {
  Button,
  ButtonGroup,
  COLOR,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import styled from '@emotion/styled'
import { number as numberHelper } from '@extend/client-helpers'
import type { SPPlanCategoryMapping } from '@helloextend/extend-api-client/src/models/store-shipping-protection'
import type { SPPlanPriceBandMappingFormValues, Values } from './schema'
import {
  mapFormValuesToCategories,
  mapFormValuesToPriceBands,
  getInitialPurchaseModelValues,
  schema,
  purchaseModelLabels,
} from './schema'
import { SpSinglePlanModel } from './sp-single-plan-model/sp-single-plan-model'
import { SpPriceBandModel } from './sp-price-band-model/sp-price-band-model'
import { SpCategoryModel } from './sp-category-model/sp-category-model'

type ShippingProtectionPurchaseModelProps = {
  store: Store
}

const ShippingProtectionPurchaseModel: FC<ShippingProtectionPurchaseModelProps> = ({ store }) => {
  const { toast } = useToaster()

  const { data: priceBandData, isLoading: isLoadingPriceBands } = useGetSPPriceBandMappingsQuery(
    store.id,
  )

  const { data: categoryMappingData, isLoading: isLoadingCategories } =
    useGetListSPPlanCategoryMappingsQuery(store.id)

  const [updateSPPriceBandMappings, { isLoading: isUpdatingPriceBands }] =
    useUpdateSPPriceBandMappingsMutation()
  const [updateSPCategoryMapping, { isLoading: isUpdatingCategories }] =
    usePutSPPlanCategoryMappingMutation()
  const [deleteSPCategoryMapping, { isLoading: isDeletingCategories }] =
    useDeleteSPPlanCategoryMappingMutation()

  const [updateStore, { isLoading: isUpdatingStore }] = useUpdateStoreMutation()

  const isLoadingAnything =
    isLoadingPriceBands ||
    isLoadingCategories ||
    isUpdatingStore ||
    isUpdatingPriceBands ||
    isUpdatingCategories ||
    isDeletingCategories
  const isLoadingInitial = isLoadingPriceBands || isLoadingCategories

  const handleSave = async (formValues: Values): Promise<void> => {
    const errorMessage = 'Something went wrong. Please try again.'
    const {
      priceBands,
      categoryMappings,
      purchaseModel,
      offersByCategoryEnabled,
      planId,
      merchantSpRevenueSharePercentage,
      partialReimbursementEnabled,
      partialReimbursementSPValue,
      partialReimbursementSelection,
    } = formValues
    const {
      shippingProtection,
      id,
      merchantSpRevenueSharePercentage: existingSpRevenueSharePercentage,
    } = store
    const shouldUpdateStore =
      shippingProtection?.purchaseModel !== purchaseModel ||
      shippingProtection?.planId !== planId ||
      existingSpRevenueSharePercentage !==
        numberHelper.getDecimalFromPercentage(merchantSpRevenueSharePercentage) ||
      shippingProtection?.offersByCategoryEnabled !== formValues.offersByCategoryEnabled ||
      shippingProtection?.partialReimbursementEnabled !== partialReimbursementEnabled ||
      (shippingProtection?.partialReimbursementPercentage !== partialReimbursementSPValue &&
        shippingProtection?.partialReimbursementType === 'percentage') ||
      (shippingProtection?.partialReimbursementFixed !== partialReimbursementSPValue &&
        shippingProtection?.partialReimbursementType === 'fixed') ||
      shippingProtection?.partialReimbursementType !== partialReimbursementSelection
    // update store if the purchaseModel or fallback plan details have been updated
    if (shouldUpdateStore) {
      try {
        await updateStore({
          storeId: id,
          data: {
            shippingProtection: {
              purchaseModel,
              planId,
              offersByCategoryEnabled,
              partialReimbursementEnabled,
              partialReimbursementPercentage:
                partialReimbursementSelection === 'percentage' &&
                partialReimbursementSPValue &&
                partialReimbursementEnabled
                  ? numberHelper.getDecimalFromPercentage(partialReimbursementSPValue)
                  : null,
              partialReimbursementFixed:
                partialReimbursementSelection === 'fixed' &&
                partialReimbursementSPValue &&
                partialReimbursementEnabled
                  ? Number(partialReimbursementSPValue)
                  : null,
              partialReimbursementType: partialReimbursementEnabled
                ? partialReimbursementSelection
                : undefined,
            },
            merchantSpRevenueSharePercentage: numberHelper.getDecimalFromPercentage(
              merchantSpRevenueSharePercentage,
            ),
          },
          version: 'latest',
        }).unwrap()
      } catch {
        toast({
          message: errorMessage,
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    }

    if (purchaseModel === 'PRICE_BAND') {
      try {
        await updateSPPriceBandMappings({
          storeId: store.id,
          priceBands: mapFormValuesToPriceBands(priceBands as SPPlanPriceBandMappingFormValues[]),
        }).unwrap()
        toast({
          message: 'Purchase Model has been successfully updated.',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.green,
        })
      } catch {
        toast({
          message: errorMessage,
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    }

    if (purchaseModel === 'CATEGORY') {
      try {
        const keepAlive = (categoryMappings as SPPlanCategoryMapping[]).map(
          (mapping) => mapping.planCategoryId,
        )
        await Promise.all([
          ...mapFormValuesToCategories(
            categoryMappings as SPPlanCategoryMapping[],
            partialReimbursementEnabled ?? false,
            partialReimbursementSelection,
          ).map((category) =>
            updateSPCategoryMapping({
              ...category,
              storeId: store.id,
            }).unwrap(),
          ),
          ...(categoryMappingData?.items || ([] as SPPlanCategoryMapping[]))
            .filter((mapping) => {
              return !keepAlive.includes(mapping.planCategoryId)
            })
            .map((category) =>
              deleteSPCategoryMapping({
                storeId: store.id,
                planCategoryId: category.planCategoryId,
              }).unwrap(),
            ),
        ] as Array<Promise<unknown>>)

        toast({
          message: 'Purchase Model has been successfully updated.',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.green,
        })
      } catch {
        toast({
          message: errorMessage,
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    }
  }

  const formik = useFormik<Values>({
    initialValues: getInitialPurchaseModelValues({
      store,
      priceBands: priceBandData?.priceBands,
      categoryMappings: categoryMappingData?.items,
    }),
    validationSchema: schema,
    onSubmit: handleSave,
    enableReinitialize: true,
  })
  const { dirty, isValid, handleSubmit, handleReset, values, handleChange, setFieldValue } = formik

  const handlePurchaseModalOptionChange = (event: React.ChangeEvent<{ value: string }>): void => {
    if (event.target?.value === 'CATEGORY') {
      setFieldValue('offersByCategoryEnabled', true)
    }
    handleChange(event)
  }

  if (isLoadingInitial)
    return (
      <SpinnerContainer>
        <Spinner data-cy="loading-spinner" color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )

  return (
    <FormikProvider value={formik}>
      <Stack spacing={2}>
        <RadioGroup
          data-cy="sp-purchase-model-radio"
          label="Select a Purchase Model"
          name="purchaseModel"
          onChange={handlePurchaseModalOptionChange}
          value={values.purchaseModel}
        >
          <Radio label={purchaseModelLabels.SINGLE_PLAN} value="SINGLE_PLAN" />
          <Radio label={purchaseModelLabels.CATEGORY} value="CATEGORY" />
          <Radio label={purchaseModelLabels.PRICE_BAND} value="PRICE_BAND" />
        </RadioGroup>

        <>
          {values.purchaseModel === 'CATEGORY' && <SpCategoryModel />}
          {values.purchaseModel === 'PRICE_BAND' && <SpPriceBandModel />}

          <SpSinglePlanModel />
        </>

        {dirty ? (
          <ButtonGroup>
            <Button
              text="Cancel"
              onClick={handleReset}
              emphasis="medium"
              isDisabled={isLoadingAnything}
              data-cy="price-band-cancel-button"
            />
            <Button
              text="Save"
              onClick={() => handleSubmit()}
              emphasis="high"
              isDisabled={!isValid}
              isProcessing={isLoadingAnything}
              data-cy="price-band-save-button"
            />
          </ButtonGroup>
        ) : null}
      </Stack>
    </FormikProvider>
  )
}

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { ShippingProtectionPurchaseModel }
