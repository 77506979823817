import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    firstName: Yup.string().default('').required('Required'),
    lastName: Yup.string().default('').required('Required'),
    email: Yup.string()
      .default('')
      .required('Required')
      .email('Please enter a valid email address'),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export { schema }
export type { Values }
