import React, { useState } from 'react'
import type { FC } from 'react'
import {
  Input,
  Modal,
  ModalController,
  ModalFooter,
  Select,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useParams } from 'react-router-dom'
import { useCreateStoreMutation, useGetAccountQuery } from '@helloextend/extend-api-rtk-query'
import type { PlatformType } from '@helloextend/extend-api-rtk-query/src/stores/types'
import styled from '@emotion/styled'
import { validate } from '@extend/client-helpers'

export const CreateStoreModal: FC<{
  isOpen: boolean
  toggleModal: (value: boolean) => void
}> = ({ isOpen, toggleModal }) => {
  const { accountId } = useParams<{ accountId: string }>()
  const { data } = useGetAccountQuery({ accountId })
  const [storeName, setStoreName] = useState('')
  const [storeURL, setStoreURL] = useState('')
  const [storeURLHasBlurred, setStoreURLHasBlurred] = useState(false)
  const [storeNameHasBlurred, setStoreNameHasBlurred] = useState(false)
  const [platform, setPlatform] = useState('custom' as PlatformType)
  const isStoreURLValid = validate.validateUrl(storeURL)
  const isSubmitDisabled = !storeName || !storeURL || !isStoreURLValid || !platform
  const { toast } = useToaster()
  const [createStore, { isLoading }] = useCreateStoreMutation()
  const closeModalAndResetForm = (): void => {
    toggleModal(false)
    setStoreName('')
    setStoreURL('')
    setStoreNameHasBlurred(false)
    setStoreURLHasBlurred(false)
  }

  const handleSubmit = async (event: React.SyntheticEvent): Promise<void> => {
    event?.preventDefault()
    try {
      await createStore({
        body: {
          accountId: data?.id || '',
          name: storeName,
          platform,
          domain: storeURL || '',
        },
      })
      closeModalAndResetForm()
      toast({
        message:
          'Store created successfully. It will appear in the list shortly. Refresh the page.',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
    } catch {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  return (
    <ModalController isOpen={isOpen}>
      <Modal heading="Create Store" onDismissRequest={closeModalAndResetForm} size="sm">
        <Form id="createStoreForm" onSubmit={handleSubmit}>
          <Input
            label="Account Name"
            placeholder={data?.name}
            value=""
            isDisabled
            id="accountName"
          />
          <Input label="Account ID" placeholder={data?.id} value="" isDisabled id="accountID" />
          <Input
            label="Store Name"
            value={storeName}
            id="storeName"
            onBlur={() => setStoreNameHasBlurred(true)}
            isError={storeNameHasBlurred && !storeName}
            errorFeedback="Please enter a Store Name"
            onChange={(e) => setStoreName(e.target.value)}
          />
          <Input
            label="Store URL"
            value={storeURL}
            id="storeURL"
            onBlur={() => setStoreURLHasBlurred(true)}
            isError={storeURLHasBlurred && !isStoreURLValid}
            errorFeedback="Please enter a valid Store URL"
            onChange={(e) => setStoreURL(e.target.value)}
          />
          <Select
            label="Platform"
            value={platform}
            id="platform"
            onChange={(e) => setPlatform(e.target.value as PlatformType)}
          >
            <option value="custom">Custom</option>
            <option value="shopify">Shopify</option>
            <option value="big-commerce">BigCommerce</option>
            <option value="magento">Magento</option>
          </Select>
          <ModalFooter
            primaryButtonProps={{
              text: 'Create Store',
              type: 'submit',
              formId: 'createStoreForm',
              isDisabled: isSubmitDisabled,
              isProcessing: isLoading,
            }}
            secondaryButtonProps={{
              onClick: closeModalAndResetForm,
              text: 'Cancel',
            }}
          />
        </Form>
      </Modal>
    </ModalController>
  )
}

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})
