import { faker } from '@faker-js/faker/locale/en'
import type { AISSearchRecord } from '@helloextend/extend-api-rtk-query'

export function generateAisRecordSearchItem(
  overrides: Partial<AISSearchRecord> = {},
): AISSearchRecord {
  return {
    status: faker.helpers.arrayElement(['SUCCEEDED', 'FAILED']),
    fileName: faker.system.fileName(),
    merchantName: faker.finance.accountName(),
    refId: faker.datatype.uuid(),
    storeId: faker.datatype.uuid(),
    processType: 'published-record',
    recordType: 'ORDER',
    date: faker.date.recent().getTime(),
    error: '',
    id: faker.datatype.uuid(),
    ...overrides,
  }
}
