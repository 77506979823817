import type { FC, SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'
import {
  ModalController,
  Modal,
  Input,
  useToaster,
  Grid,
  Checkbox,
  AdvancedSelect,
  COLOR,
  ToastColor,
  ToastDuration,
} from '@extend/zen'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  useCreateEmbeddedPromotionMutation,
  useGetEmbeddedPromotionUploadPresignedUrlMutation,
} from '@helloextend/extend-api-rtk-query'
import { useParams } from 'react-router'
import { FormDatePicker } from '../../../../../../../components/collapsible-info-group/form-date-picker'
import { PromotionCSVImport } from './promotion-csv-import'
import { ImportState, TIMES, TIME_ZONES } from './constants'
import { parseTime } from './utils'

interface CreatePromotionFormFields {
  name: string
  startDate?: number | null
  startTime?: string
  hasEndDate: boolean
  endDate?: number | null
  endTime?: string
  timezone?: string
}

const schema: Yup.ObjectSchema<CreatePromotionFormFields> = Yup.object()
  .shape({
    name: Yup.string().required('Name is required'),
    startDate: Yup.number().optional().nullable(),
    startTime: Yup.string().optional(),
    hasEndDate: Yup.boolean().default(false),
    endDate: Yup.number().optional().nullable(),
    endTime: Yup.string().optional(),
    timezone: Yup.string().optional(),
  })
  .test({
    name: 'dateValidation',
    test(values) {
      const start = parseTime(values.startDate, values.startTime, values.timezone)
      const end = parseTime(values.endDate, values.endTime, values.timezone)
      if (!start && end) {
        return this.createError({
          message: 'Start date must be before end date',
          path: `startDate`,
        })
      }

      if (end?.isBefore(start)) {
        return this.createError({
          message: 'Start date must be before end date',
          path: `startDate`,
        })
      }

      return true
    },
  })
  .defined()

type CreatePromotionModalProps = {
  isVisible: boolean
  onCancel: () => void
}

export const CreatePromotionModal: FC<CreatePromotionModalProps> = ({ isVisible, onCancel }) => {
  const now = new Date()
  const { storeId } = useParams<{ storeId: string }>()

  const { toast } = useToaster()

  const [createPromotion, { isLoading: isCreatePromotionLoading }] =
    useCreateEmbeddedPromotionMutation()

  const [getPromotionUploadUrl, { isLoading: isGettingPromotionUploadUrl }] =
    useGetEmbeddedPromotionUploadPresignedUrlMutation()

  const [importState, setImportState] = useState<ImportState>(ImportState.READY)
  const [presignedUrl, setPresignedUrl] = useState<string | null>(null)

  const {
    handleChange,
    setFieldValue,
    values,
    errors,
    handleBlur,
    touched,
    isValid,
    dirty: isDirty,
  } = useFormik<Yup.InferType<typeof schema>>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      startDate: undefined,
      startTime: undefined,
      hasEndDate: false,
      endDate: undefined,
      endTime: undefined,
      timezone: undefined,
    },
    validationSchema: schema,
    onSubmit: () => {},
  })

  const getPresignedUrl = async (promoId: string): Promise<string | null> => {
    if (!promoId) return null
    let response
    try {
      response = await getPromotionUploadUrl({
        storeId,
        promotionId: promoId,
      }).unwrap()
      return response?.url || null
    } catch {
      setImportState(ImportState.UPLOAD_FAILED)
      return null
    }
  }

  const handleOnClick = async (): Promise<void> => {
    if (importState === ImportState.FILE_SELECTED) {
      setImportState(ImportState.UPLOADING)

      const startTime = parseTime(values.startDate, values.startTime, values.timezone)
      const endTime = parseTime(values.endDate, values.endTime, values.timezone)

      try {
        const res = await createPromotion({
          storeId,
          body: {
            name: values.name,
            ...(startTime && { startTime: startTime.unix() }),
            ...(endTime && { endTime: endTime.unix() }),
          },
        }).unwrap()
        const url = await getPresignedUrl(res.id)
        setPresignedUrl(url)
      } catch {
        setImportState(ImportState.UPLOAD_FAILED)
      }
    }
  }

  const handleSetFieldFromCheckbox =
    (field: keyof CreatePromotionFormFields) =>
    (e: SyntheticEvent<HTMLInputElement>): void => {
      if (setFieldValue) {
        setFieldValue(field, e.currentTarget.checked)
        if (field === 'hasEndDate' && e.currentTarget.checked === false) {
          setFieldValue('endDate', undefined)
          setFieldValue('endTime', undefined)
        }
      }
    }

  const handleSetFieldFromEvent =
    (field: keyof CreatePromotionFormFields) =>
    (e: SyntheticEvent<HTMLInputElement>): void => {
      if (setFieldValue) {
        setFieldValue(field, e.currentTarget.value)
      }
    }

  const handleSetFieldFromValue =
    (field: keyof CreatePromotionFormFields) =>
    (value: number | null): void => {
      if (setFieldValue) {
        setFieldValue(field, value)
      }
    }

  const isDisabled = !(isDirty && isValid) || importState !== ImportState.FILE_SELECTED
  const isProcessing =
    isCreatePromotionLoading || isGettingPromotionUploadUrl || importState === ImportState.UPLOADING

  useEffect(() => {
    if (importState === ImportState.UPLOADED) {
      toast({
        message:
          'Promotions have been successfully imported. Check back in a bit to see your uploaded promotion.',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
      onCancel()
    }

    if (importState === ImportState.UPLOAD_FAILED) {
      toast({
        message: 'Upload of promotions has failed. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      onCancel()
    }
  }, [importState])

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="create-promotion-modal"
        heading="Create Promotion"
        primaryButtonProps={{
          'data-cy': 'create-promotion-modal-submit',
          onClick: handleOnClick,
          text: 'Create',
          isDisabled: isDisabled || isProcessing,
          isProcessing,
        }}
        secondaryButtonProps={{
          'data-cy': 'create-promotion-modal-cancel',
          onClick: onCancel,
          text: 'Cancel',
        }}
        onDismissRequest={onCancel}
      >
        <>
          <MarginDiv>
            <Input
              data-cy="promotion-name"
              id="name"
              label="Name"
              onChange={handleSetFieldFromEvent('name')}
              onBlur={handleBlur}
              value={values.name}
              isError={Boolean(errors.name) && touched.name}
              errorFeedback={errors.name}
            />
          </MarginDiv>
          <Grid columns={{ sm: 1, md: 2 }} spacing={2}>
            <CustomInputWrapper>
              <FormDatePicker
                data-cy="start-date"
                label="Start date"
                date={values.startDate ? new Date(values.startDate) : undefined}
                onChange={handleSetFieldFromValue('startDate')}
                invalid={false}
                minDate={now}
                maxDate={null}
              />
              {errors.startDate ? <div style={{ color: '#B50318' }}>{errors.startDate}</div> : null}
            </CustomInputWrapper>
            <AdvancedSelect
              onChange={handleChange}
              id="startTime"
              label="Start time"
              options={TIMES}
              value={values.startTime || ''}
              isError={Boolean(errors.startTime)}
              errorFeedback={errors.startTime}
              multiple={false}
            />
          </Grid>
          <MarginDiv>
            <Checkbox
              label="Set end date"
              checked={values.hasEndDate}
              onChange={handleSetFieldFromCheckbox('hasEndDate')}
            />
          </MarginDiv>
          {values.hasEndDate && (
            <MarginDiv>
              <Grid columns={{ sm: 1, md: 2 }} spacing={2}>
                <CustomInputWrapper>
                  <FormDatePicker
                    data-cy="end-date"
                    label="End date"
                    date={values.endDate ? new Date(values.endDate) : undefined}
                    onChange={handleSetFieldFromValue('endDate')}
                    invalid={false}
                    minDate={now}
                    maxDate={null}
                  />
                </CustomInputWrapper>
                <AdvancedSelect
                  onChange={handleChange}
                  id="endTime"
                  label="End time"
                  options={TIMES}
                  value={values.endTime || ''}
                  isError={Boolean(errors.endTime && errors.endTime)}
                  errorFeedback={errors.endTime}
                  multiple={false}
                />
              </Grid>
            </MarginDiv>
          )}
          <MarginDiv>
            <AdvancedSelect
              label="Time Zone"
              onChange={handleChange}
              id="timezone"
              options={TIME_ZONES}
              placeholder="Select Time Zone"
              value={values.timezone || ''}
              isError={Boolean(errors.timezone && errors.timezone)}
              errorFeedback={errors.timezone ?? ''}
              multiple={false}
            />
          </MarginDiv>
          <PaddedDiv>
            <PromotionCSVImport
              importState={importState}
              setImportState={setImportState}
              presignedUrl={presignedUrl}
            />
          </PaddedDiv>
        </>
      </Modal>
    </ModalController>
  )
}

const MarginDiv = styled.div({
  marginBottom: 16,
})

const PaddedDiv = styled.div({
  paddingTop: 24,
})

const CustomInputWrapper = styled.div({
  '& label > div': {
    color: COLOR.BLACK,
    marginBottom: '2px',
  },
  '.react-datepicker-wrapper input': {
    borderColor: '#A9B1BB !important',
  },
})
