import type { ConversationConfigurationResponse } from '@helloextend/extend-api-rtk-query'
import type { ColumnDef, CellContext } from '@extend/zen'
import { date as dateHelper } from '@extend/client-helpers'
import { formatDateWithTimezone } from '../../../../utils/date-formatting'

export interface ConversationConfigurationResponseTableRow
  extends ConversationConfigurationResponse,
    Partial<Record<string, string>> {
  conversationName?: string
}

const { formatToMilliseconds } = dateHelper

export const configurationColumns: Array<ColumnDef<ConversationConfigurationResponse>> = [
  {
    id: 'conversationName',
    label: 'Conversation',
    search: 'explicit',
    renderCell: (data: CellContext<ConversationConfigurationResponse, string>) => data.getValue(),
  },
  {
    id: 'category',
    label: 'Adjudication Category',
    renderCell: (data: CellContext<ConversationConfigurationResponse, string>) => data.getValue(),
    search: 'explicit',
  },
  {
    id: 'storeId',
    label: 'Store ID',
    renderCell: (data: CellContext<ConversationConfigurationResponse, string>) => data.getValue(),
    search: 'explicit',
  },
  {
    id: 'planId',
    label: 'Plan ID',
    renderCell: (data: CellContext<ConversationConfigurationResponse, string>) => data.getValue(),
    search: 'explicit',
  },
  {
    id: 'updatedAt',
    label: 'Last Update',
    renderCell: (data: CellContext<ConversationConfigurationResponse, string>) =>
      `${formatDateWithTimezone(formatToMilliseconds(data.getValue()))} by ${
        data.row.original.editedBy
      }`,
    isSortable: true,
  },
]

export const mapConfigurationsTotableData = (
  configurations?: Array<ConversationConfigurationResponse & { conversationName?: string }>,
): ConversationConfigurationResponseTableRow[] => {
  if (!configurations) return []
  return configurations.map((configuration) => {
    return {
      ...configuration,
      conversationName: configuration.conversationName ?? '—',
      storeId: configuration.storeId || '—',
      planId: configuration.planId || '—',
    }
  })
}
