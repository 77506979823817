import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useFormik } from 'formik'
import { useCreateClaimNotesMutation } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { TextArea, Button, ButtonGroup, COLOR, RadioGroup, Radio, Add, Warning } from '@extend/zen'
import { useToggle } from '@helloextend/client-hooks'
import type { ClaimNoteCreateRequest } from '@helloextend/extend-api-client'
import { formSchema, NOTETEXT_MAX } from './schema'
import { DatepickerMenu } from '../../../../../../components/datepicker-menu'
import type { Values } from './schema'
import { escalationLevelsDescription } from '../../../../../../lib/escalation-levels'
import { FollowUpBadge } from './follow-up-badge'
import type { EscalationSchemaValues } from './escalation-schema'
import { escalationSchema } from './escalation-schema'
import type { ClaimNotesCreateRequest } from '@extend-services/claims-management/dist/src/handlers/http/types'

interface ClaimNotesFormProps {
  claimId: string
  hasEscalation: boolean
}

const ClaimNotesForm: FC<ClaimNotesFormProps> = ({ claimId, hasEscalation }) => {
  const [isFormOpen, { toggle, off }] = useToggle()
  const [isEscalationFormOpen, { toggle: toggleEscalationForm, off: closeEscalationForm }] =
    useToggle()
  const [createClaimNote, { isLoading }] = useCreateClaimNotesMutation()
  const { errors, handleSubmit, handleChange, setFieldValue, values, resetForm } =
    useFormik<Values>({
      enableReinitialize: true,
      initialValues: formSchema.getDefault(),
      validationSchema: formSchema,
      validateOnChange: false,
      onSubmit: async (formValues: Values) => {
        await createClaimNote({ claimId, body: formValues as ClaimNoteCreateRequest })
        handleClose()
      },
    })
  const {
    errors: escalationFormErrors,
    handleSubmit: handleSubmitEscalation,
    handleChange: handleEscalationFormChange,
    setFieldValue: setEscalationFormFieldValue,
    values: escalationFormValues,
    resetForm: resetEscalationForm,
  } = useFormik<EscalationSchemaValues>({
    enableReinitialize: true,
    initialValues: escalationSchema.getDefault(),
    validationSchema: escalationSchema,
    validateOnChange: false,
    onSubmit: async (formValues: EscalationSchemaValues) => {
      await createClaimNote({
        claimId,
        body: formValues as ClaimNotesCreateRequest,
      })
      handleClose()
    },
  })

  const today = useMemo(() => {
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    return d
  }, [])

  const handleClose = (): void => {
    off()
    closeEscalationForm()
    resetForm()
    resetEscalationForm()
  }

  const areClaimNoteFormsClosed = !isFormOpen && !isEscalationFormOpen

  return (
    <ContentWrapper>
      {areClaimNoteFormsClosed && (
        <ClaimNoteButtonWrapper>
          <Button
            text="Add a Note"
            icon={Add}
            emphasis="medium"
            onClick={toggle}
            data-cy="add-note-button"
          />
          {!hasEscalation && (
            <Button
              text="Escalate"
              icon={Warning}
              emphasis="medium"
              onClick={toggleEscalationForm}
              data-cy="escalate-button"
            />
          )}
        </ClaimNoteButtonWrapper>
      )}
      {isFormOpen && (
        <form onSubmit={handleSubmit} data-cy="follow-up-form">
          <FollowUpWrapper>
            <DatepickerMenu
              buttonText="Follow-up Date"
              minDate={today}
              date={values.followUpDate}
              onChange={(v) => {
                if (v !== values.followUpDate) {
                  setFieldValue('followUpDate', v)
                  setFieldValue('type', v == null ? 'basic' : 'follow-up')
                }
              }}
            />
            {values.followUpDate && <FollowUpBadge timestamp={values.followUpDate} />}
          </FollowUpWrapper>
          <TextArea
            label=""
            maxLength={NOTETEXT_MAX}
            onChange={handleChange}
            value={values.text}
            errorFeedback={errors.text}
            data-cy="note-text"
            id="text"
          />
          <ButtonWrapper>
            <ButtonGroup>
              <Button
                text="Cancel"
                emphasis="medium"
                data-cy="cancel-button"
                onClick={handleClose}
              />
              <Button
                type="submit"
                text="Save"
                data-cy="notes-save-button"
                isDisabled={isLoading}
              />
            </ButtonGroup>
          </ButtonWrapper>
        </form>
      )}
      {isEscalationFormOpen && (
        <form onSubmit={handleSubmitEscalation} data-cy="escalation-form">
          <RadioGroup
            label="Escalation Level"
            layout="vertical"
            name="preferredMethod"
            onChange={(e) => setEscalationFormFieldValue('escalationLevel', e.target.value)}
            value={escalationFormValues.escalationLevel}
          >
            <Radio label={`Tier 1: ${escalationLevelsDescription.tier1}`} value="tier1" />
            <Radio label={`Tier 2: ${escalationLevelsDescription.tier2}`} value="tier2" />
            <Radio label={`Tier 3: ${escalationLevelsDescription.tier3}`} value="tier3" />
          </RadioGroup>
          <TextArea
            label=""
            maxLength={NOTETEXT_MAX}
            onChange={handleEscalationFormChange}
            value={escalationFormValues.text}
            errorFeedback={escalationFormErrors.text}
            data-cy="note-text"
            id="text"
            placeholder="Type notes for Escalation team"
          />
          <ButtonWrapper>
            <ButtonGroup>
              <Button
                text="Cancel"
                emphasis="medium"
                data-cy="cancel-escalation-button"
                onClick={handleClose}
              />
              <Button
                type="submit"
                text="Save"
                data-cy="notes-save-escalation-button"
                isDisabled={isLoading}
              />
            </ButtonGroup>
          </ButtonWrapper>
        </form>
      )}
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  flex: 1,
})

export const ErrorMessage = styled.div({
  color: COLOR.RED[700],
  fontFamily: 'Nunito Sans',
  fontSize: 12,
  lineHeight: '16px',
  marginBottom: 16,
  marginTop: 8,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

const FollowUpWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginBottom: 8,
})

const ClaimNoteButtonWrapper = styled.div({
  display: 'flex',
  gap: 8,
})

export { ClaimNotesForm }
