import breakerBox from './breaker-box.png'
import disconnectedPlug from './disconnected-plug-404.png'
import extendLogoWhite from './extend-logo-white.png'
import sparksImage from './sparks-image.png'

const images = {
  breakerBox,
  disconnectedPlug,
  extendLogoWhite,
  sparksImage,
}

export default images
