import type {
  PlanTermCoveredInfo,
  PlanTermNotCovered,
  ProductComponent,
} from '@helloextend/extend-api-rtk-query/src/plan-terms/types'
import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import {
  Add,
  AdvancedSelect,
  Box,
  Button,
  COLOR,
  CaptionLegal,
  Check,
  Close,
  DataProperty,
  Edit,
  Grid,
  GridItem,
  Icon,
  Modal,
  ModalController,
  Stack,
  TextArea,
  ToastColor,
  ToastDuration,
  Trash,
  format,
  useToaster,
} from '@extend/zen'
import type { FormikErrors, FormikTouched } from 'formik'
import { useFormik } from 'formik'
import {
  useGetPlanCategoriesQuery,
  useUpdateCoveredInfoPlanCategoryMutation,
  useUpdateNotCoveredInfoPlanCategoryMutation,
  useCreateCoveredInfoMutation,
  useCreateNotCoveredInfoMutation,
  useDeleteCoveredInfoPlanCategoryMutation,
  useDeleteNotCoveredInfoPlanCategoryMutation,
} from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { ComponentBox } from './component-box'
import { schema } from './category-box-schema'
import { mapToValueDisplay } from '../../../../../../utils/utils'

type CoverageInfoProps = {
  termsId: string
  version: number
  type: 'covered' | 'notCovered'
  category?: PlanTermCoveredInfo | PlanTermNotCovered
  closeModal?: () => void
}

const CategoryBox: FC<CoverageInfoProps> = ({ termsId, version, type, category, closeModal }) => {
  const { toast } = useToaster()
  const [editMode, setEditMode] = useState(!category)
  const [confirmationModal, setConfirmationModal] = useState(false)

  const [updateReason, setUpdateReason] = useState('')

  const { data: planCategories, isLoading: isLoadingPlanCategories } = useGetPlanCategoriesQuery()

  const [
    createCoveredCategory,
    { isLoading: isCreatingCoveredCategory, isError: isErrorCreatingCoveredCategory },
  ] = useCreateCoveredInfoMutation()
  const [
    createNotCoveredCategory,
    { isLoading: isCreatingNotCoveredCategory, isError: isErrorCreatingNotCoveredCategory },
  ] = useCreateNotCoveredInfoMutation()

  const [
    updateCovered,
    { isLoading: updatingCoveredCategory, isError: isErrorUpdatingCoveredCategory },
  ] = useUpdateCoveredInfoPlanCategoryMutation()
  const [
    updateNotCovered,
    { isLoading: updatingNotCoveredCategory, isError: isErrorUpdatingNotCoveredCategory },
  ] = useUpdateNotCoveredInfoPlanCategoryMutation()

  const [
    deleteCoveredCategory,
    { isLoading: isDeletingCoveredCategory, isError: isErrorDeletingCoveredCategory },
  ] = useDeleteCoveredInfoPlanCategoryMutation()
  const [
    deleteNotCoveredCategory,
    { isLoading: isDeletingNotCoveredCategory, isError: isErrorDeletingNotCoveredCategory },
  ] = useDeleteNotCoveredInfoPlanCategoryMutation()

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    resetForm,
    setFieldValue,
    dirty,
  } = useFormik({
    initialValues: {
      planCategoryId: category?.planCategoryId ?? '',
      planCategoryName: category?.planCategoryName ?? ' ',
      components: category?.components ?? [],
    },
    validationSchema: schema,
    validateOnMount: false,
    validateOnBlur: true,
    onSubmit: async (submitValues) => {
      if (!submitValues.planCategoryId) {
        return
      }

      switch (type) {
        case 'covered':
          if (!category) {
            await createCoveredCategory({
              termsId,
              version,
              covered: {
                planCategoryId: submitValues.planCategoryId,
                components: submitValues.components,
              },
            })
          } else {
            await updateCovered({
              termsId,
              version,
              planCategoryId: submitValues.planCategoryId,
              components: submitValues.components ?? [],
              updateReason,
            })
          }
          break

        case 'notCovered':
          if (!category) {
            await createNotCoveredCategory({
              termsId,
              version,
              notCovered: {
                planCategoryId: submitValues.planCategoryId,
                components: submitValues.components,
              },
            })
          } else {
            await updateNotCovered({
              termsId,
              version,
              planCategoryId: submitValues.planCategoryId,
              components: submitValues.components ?? [],
              updateReason,
            })
          }
          break

        default:
          break
      }
    },
  })

  const handleDeleteComponent = (index: number): void => {
    const newComponents = values.components?.filter((_, i) => i !== index)
    setFieldValue('components', newComponents)
  }

  const handleDeleteCategory = (): void => {
    switch (type) {
      case 'covered':
        deleteCoveredCategory({ termsId, version, planCategoryId: values.planCategoryId })
        break
      case 'notCovered':
        deleteNotCoveredCategory({ termsId, version, planCategoryId: values.planCategoryId })
        break
      default:
        break
    }
  }

  const isCreatingNew = useMemo(() => !category, [category])

  if (isErrorCreatingCoveredCategory || isErrorCreatingNotCoveredCategory) {
    toast({
      message: 'Error creating category',
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.short,
    })
  }

  if (isErrorUpdatingCoveredCategory || isErrorUpdatingNotCoveredCategory) {
    toast({
      message: 'Error updating category',
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.short,
    })
  }

  if (isErrorDeletingCoveredCategory || isErrorDeletingNotCoveredCategory) {
    toast({
      message: 'Error deleting category',
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.short,
    })
  }

  return (
    <>
      <Box>
        <Stack spacingY={2} data-cy="category-section">
          <Grid columns={2} spacing={2}>
            <GridItem>
              <Stack>
                {editMode && isCreatingNew ? (
                  <AdvancedSelect
                    options={mapToValueDisplay(planCategories?.items ?? [], 'id', 'name')}
                    id="planCategoryId"
                    value={values.planCategoryId}
                    label="Plan Category"
                    showSearch
                    onChange={(e) => {
                      handleChange(e)
                      setFieldValue('components', [{ componentName: '' }])
                    }}
                    onBlur={handleBlur}
                    multiple={false}
                    isError={!!errors.planCategoryId && touched.planCategoryId}
                    errorFeedback={errors.planCategoryId}
                    isLoading={isLoadingPlanCategories}
                    data-cy="planCategory-select-dropdown"
                  />
                ) : (
                  <DataProperty
                    label="Category"
                    value={values.planCategoryName}
                    data-cy="planCategory-display-property"
                  />
                )}
              </Stack>
            </GridItem>
            <GridItem>
              <Stack isRow spacingX={2} isReversed>
                {editMode ? (
                  <>
                    <Button
                      icon={Close}
                      type="button"
                      text="Cancel"
                      emphasis="medium"
                      onClick={() => {
                        setEditMode(false)
                        resetForm()
                        if (closeModal) {
                          closeModal()
                        }
                      }}
                      data-cy="cancel-category-button"
                    />
                    <Button
                      icon={Check}
                      type="button"
                      text={category ? 'Save' : 'Add'}
                      emphasis="medium"
                      onClick={() => {
                        if (category) {
                          setConfirmationModal(true)
                        } else {
                          handleSubmit()
                        }
                      }}
                      isProcessing={isCreatingCoveredCategory || isCreatingNotCoveredCategory}
                      isDisabled={!dirty || Object.keys(errors).length > 0}
                      data-cy="save-category-button"
                    />
                  </>
                ) : (
                  <>
                    <Button
                      icon={Trash}
                      type="button"
                      text="Delete"
                      emphasis="medium"
                      color="red"
                      onClick={handleDeleteCategory}
                      isProcessing={isDeletingCoveredCategory || isDeletingNotCoveredCategory}
                      data-cy="delete-category-button"
                    />
                    <Button
                      icon={Edit}
                      type="button"
                      text="Edit"
                      emphasis="medium"
                      onClick={() => setEditMode(true)}
                      data-cy="edit-category-button"
                    />
                  </>
                )}
              </Stack>
            </GridItem>
          </Grid>
          {values.planCategoryId ? (
            <Grid columns={4} spacing={2} data-cy="components-section">
              {values.components.map((component, index) => (
                <div key={`${type}-component-${index}`} data-cy="component-input">
                  <ComponentBox
                    component={component}
                    isCategoryEditMode={editMode}
                    index={index}
                    handleDeleteComponent={handleDeleteComponent}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    errors={
                      errors.components
                        ? (errors.components[index] as
                            | FormikErrors<
                                ProductComponent | Omit<ProductComponent, 'limitOfLiability'>
                              >
                            | undefined)
                        : {}
                    }
                    touched={
                      touched.components
                        ? (touched.components[index] as
                            | FormikTouched<
                                ProductComponent | Omit<ProductComponent, 'limitOfLiability'>
                              >
                            | undefined)
                        : {}
                    }
                    type={type}
                    canDelete={values.components.length > 1}
                  />
                </div>
              ))}
              {editMode ? (
                <Box padding={6}>
                  <StyledButton
                    onClick={() =>
                      setFieldValue('components', [...values.components, { componentName: '' }])
                    }
                    data-cy="add-component-button"
                  >
                    <Icon icon={Add} color={COLOR.BLUE[800]} />
                  </StyledButton>
                </Box>
              ) : null}
            </Grid>
          ) : null}
          {category ? (
            <CaptionLegal
              style={{ fontStyle: 'italic', color: COLOR.NEUTRAL[500] }}
            >{`Last updated by ${category.lastEditedBy} on ${format(
              category.updatedAt,
            )}`}</CaptionLegal>
          ) : null}
        </Stack>
      </Box>

      <ModalController isOpen={confirmationModal}>
        <Modal
          heading="Update Reason"
          primaryButtonProps={{
            text: 'Save',
            onClick: () => handleSubmit(),
            isProcessing: updatingCoveredCategory || updatingNotCoveredCategory,
            isDisabled: updateReason === '',
            'data-cy': 'update-reason-modal-save-button',
          }}
          secondaryButtonProps={{
            text: 'Cancel',
            onClick: () => {
              setUpdateReason('')
              setConfirmationModal(false)
            },
          }}
          data-cy="update-confirmation-modal"
        >
          <TextArea
            value={updateReason}
            id="updateReason"
            onChange={(e) => setUpdateReason(e.target.value)}
            isDisabled={updatingCoveredCategory || updatingNotCoveredCategory}
            data-cy="update-reason-text-area"
          />
        </Modal>
      </ModalController>
    </>
  )
}

const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: 2px dotted #007bff;
  background-color: #e6f7ff;
  color: #007bff;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover {
    background-color: #cceeff;
  }

  &:active {
    background-color: #b3e6ff;
  }
`

export { CategoryBox }
