import type { StylesConfig } from 'react-select'
import { COLOR } from '@extend/zen'
import { bp } from '@extend/client-helpers'
import type { OptionType } from '@helloextend/google-api'

export const customSelectStyles = (error: boolean): StylesConfig<OptionType, false> => {
  return {
    container: (defaultProvidedStyles) => ({
      ...defaultProvidedStyles,
      flex: 1,
      [bp.md]: {
        flex: 2,
      },
    }),
    control: (defaultProvidedStyles, state) => ({
      ...defaultProvidedStyles,
      height: 40,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: error ? COLOR.RED[700] : COLOR.NEUTRAL[400],
      boxShadow: 'none',
      ...(state.isFocused && {
        outline: 'none',
        borderColor: error ? COLOR.RED[700] : COLOR.BLUE[700],
      }),
      '&:hover': {
        borderColor: error ? COLOR.RED[700] : COLOR.BLUE[700],
      },
    }),

    option: (defaultProvidedStyles, { isFocused }) => ({
      ...defaultProvidedStyles,
      padding: 8,
      backgroundColor: isFocused ? COLOR.BLUE[100] : 'white',
      color: isFocused ? COLOR.BLUE[700] : 'black',
      ':last-child': {
        border: 'none',
      },
      [bp.md]: {
        ':first-of-type': {
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        },
        ':last-child': {
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        },
      },
    }),

    menu: (defaultProvidedStyles) => ({
      ...defaultProvidedStyles,
      borderRadius: 0,
      width: '100vw',
      marginBottom: 0,
      padding: 0,
      [bp.md]: { width: '100%', borderRadius: 4, marginBottom: 8 },
    }),

    menuList: (defaultProvidedStyles) => ({
      ...defaultProvidedStyles,
      padding: 0,
    }),
  }
}
