import type { FC } from 'react'
import React from 'react'
import { Information } from '@extend/zen'
import { TooltipOptions } from './tooltip-options'

const ProductStatusInfo: FC = () => {
  return (
    <Information data-cy="product-status-info">
      <TooltipOptions />
    </Information>
  )
}

export { ProductStatusInfo }
