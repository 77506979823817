import React, { useMemo } from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'
import {
  Info,
  InlineAlert,
  InlineAlertColor,
  Input,
  Modal,
  Stack,
  Button,
  Add,
  Trash,
  ButtonGroup,
} from '@extend/zen'
import { useUpsertMerchantServicingSettingsMutation } from '@helloextend/extend-api-rtk-query'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'

interface MidMccManagementModalProps {
  settings: MerchantServicingSettings
  storeName: string
  onClose: () => void
  isMidManagement: boolean
}

const MidMccManagementModal: FC<MidMccManagementModalProps> = ({
  settings,
  storeName,
  onClose,
  isMidManagement,
}) => {
  const setting = isMidManagement ? settings.mids : settings.mccs
  const [codes, setCodes] = React.useState(setting?.length ? setting : [''])
  const [upsertSettings] = useUpsertMerchantServicingSettingsMutation()

  const isSaveButtonDisabled = useMemo(
    () => codes.length > 1 && codes.some((code) => code === ''),
    [codes],
  )

  const handleMidMccChange = (value: string, index: number): void => {
    const newCodes = [...codes]
    newCodes[index] = value
    setCodes(newCodes)
  }

  const handleAddButtonClick = (): void => {
    setCodes([...codes, ''])
  }

  const handleDeleteButtonClick = (index: number): void => {
    const newCodes = [...codes]
    newCodes.splice(index, 1)
    setCodes(newCodes)
  }

  const handleSaveButtonClick = async (): Promise<void> => {
    const codesToUpdate = codes.length === 1 && codes[0] === '' ? [] : codes
    await upsertSettings({ ...settings, [isMidManagement ? 'mids' : 'mccs']: codesToUpdate })
    onClose()
  }

  return (
    <Modal
      heading={`Manage ${isMidManagement ? 'MIDs' : 'MCCs'} for ${storeName}`}
      onDismissRequest={onClose}
      footerContent={
        <ButtonGroup isReversed>
          <Button
            data-cy="save-mids-mccs-btn"
            text="Save"
            onClick={handleSaveButtonClick}
            isDisabled={isSaveButtonDisabled}
          />
          <Button
            data-cy="cancel-mid-mcc-modal-btn"
            emphasis="medium"
            onClick={onClose}
            text="Cancel"
          />
        </ButtonGroup>
      }
      data-cy="mid-mcc-management-modal"
    >
      <ContainerWithMargin>
        <div>{`Enter ${
          isMidManagement ? 'MIDs' : 'MCCs'
        } to restrict customer's virtual cards to this store.`}</div>
      </ContainerWithMargin>
      {codes.length === 1 && codes[0] === '' && (
        <ContainerWithMargin>
          <InlineAlert color={InlineAlertColor.yellow} icon={Info} data-cy="mid-mcc-alert">
            {`Leaving the ${
              isMidManagement ? 'MID' : 'MCC'
            } empty will not restrict virtual cards for this store.`}
          </InlineAlert>
        </ContainerWithMargin>
      )}
      {codes.map((code, i) => (
        <ContainerWithMargin>
          <Stack key={i} isRow align="center" spacing={1}>
            <Input
              id={`mid-mcc-${i}`}
              value={code}
              label={`${
                isMidManagement
                  ? 'Merchant Identification Number (MID)'
                  : 'Merchant Category Code (MCC)'
              } - #${i + 1}`}
              onChange={(e) => handleMidMccChange(e.currentTarget.value, i)}
            />
            {codes.length > 1 && (
              <ButtonContainer>
                <Button
                  data-cy="remove-mid-mcc-btn"
                  icon={Trash}
                  emphasis="low"
                  color="neutral"
                  onClick={() => handleDeleteButtonClick(i)}
                />
              </ButtonContainer>
            )}
          </Stack>
        </ContainerWithMargin>
      ))}
      <Button
        data-cy="add-mid-mcc-btn"
        emphasis="low"
        icon={Add}
        text={`Add ${isMidManagement ? 'MID' : 'MCC'}`}
        onClick={handleAddButtonClick}
      />
    </Modal>
  )
}

const ContainerWithMargin = styled.div({
  marginBottom: 24,
})

const ButtonContainer = styled.div({
  marginTop: 24,
})

export { MidMccManagementModal }
