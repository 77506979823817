import React from 'react'
import type { ProductsSearchItem } from '@helloextend/extend-api-client'
import { currency, date } from '@extend/client-helpers'
import type {
  CellContext,
  ColumnDefs,
  ColumnFiltersState,
  FilterDef,
  SelectFilterOption,
} from '@extend/zen'
import { Badge } from '@extend/zen'
import type { ProductsSearchArguments } from '@helloextend/extend-api-rtk-query'
import type { ProductWarrantyStatus } from '../../../../../../../utils/products'
import { getProductBadgeProps, PRODUCT_WARRANTY_STATUS } from '../../../../../../../utils/products'
import { DisplayOfferToggle } from '../../plan-and-pricing/components/display-offer-toggle'
import { ReferenceIdLink } from './reference-id-link'
import { VariantsCountLink } from './variants-count-link'
import { PlanCategoryMapper } from './plan-category-mapper'

export const getTableColumns = (
  isVariantsTable = false,
  options?: { currencyCode?: string },
): ColumnDefs<ProductsSearchItem> => [
  {
    id: 'imageUrl',
    label: 'Image',
    renderCell: (data: CellContext<ProductsSearchItem, string>) => (
      <img src={`${data.getValue()}`} height="40" width="40" alt="Product Thumbnail" />
    ),
  },
  {
    id: 'name',
    label: 'Name',
    search: isVariantsTable ? undefined : 'explicit',
    isSortable: true,
    renderCell: (data: CellContext<ProductsSearchItem, string>) => {
      const { original } = data?.row || {}
      const { referenceId, name } = original
      return (
        <ReferenceIdLink
          referenceId={referenceId}
          productName={name || ''}
          isVariantsTable={isVariantsTable}
        />
      )
    },
  },
  {
    id: 'referenceId',
    label: 'Reference ID',
    search: isVariantsTable ? undefined : 'explicit',
    isSortable: true,
  },
  {
    id: 'category',
    label: 'Program',
  },
  {
    id: 'subcategory',
    label: 'Subprogram',
  },
  {
    id: 'planCategoryId',
    label: 'Plan Category',
    renderCell: (data: CellContext<ProductsSearchItem, string>) => {
      const { planCategoryId } = data.row.original

      if (planCategoryId) {
        return <PlanCategoryMapper planCategoryId={planCategoryId} />
      }
      return <span>&#8212;</span>
    },
  },
  {
    id: 'price',
    label: `Price${options?.currencyCode ? ` (${options.currencyCode})` : ''}`,
    renderCell: (data: CellContext<ProductsSearchItem, number>) => {
      const { original } = data?.row || {}
      const isVariantProduct = original.variantCount > 0

      return isVariantProduct ? (
        <span>&#8212;</span>
      ) : (
        `${currency.formatWithoutCurrencySymbol(data.getValue())}`
      )
    },
  },
  {
    id: 'warrantyStatus',
    label: 'Status',
    renderCell: (data: CellContext<ProductsSearchItem, ProductWarrantyStatus>) => {
      const productBadge = getProductBadgeProps(data.getValue())
      const { original } = data?.row || {}
      const isVariantProduct = original.variantCount > 0
      return productBadge.text && !isVariantProduct ? (
        <Badge {...productBadge} />
      ) : (
        <span>&#8212;</span>
      )
    },
  },
  {
    id: 'enabled',
    label: 'Display Offer',
    renderCell: (data: CellContext<ProductsSearchItem, boolean>) => {
      const { original } = data?.row || {}
      const isVariantProduct = original.variantCount > 0
      const { warrantyStatus } = original || {}
      const isToggleHidden = warrantyStatus !== 'warrantable'

      return isToggleHidden || isVariantProduct ? (
        <span>&#8212;</span>
      ) : (
        <DisplayOfferToggle isEnabled={data.getValue()} product={original} />
      )
    },
  },
  ...(!isVariantsTable
    ? [
        {
          id: 'variantCount',
          label: 'Variants',
          renderCell: (data: CellContext<ProductsSearchItem, string>) => {
            const { original } = data?.row || {}
            const { referenceId } = original
            return <VariantsCountLink referenceId={referenceId} count={data.getValue()} />
          },
        },
      ]
    : []),
  {
    id: 'brand',
    label: 'Brand',
  },
  {
    id: 'mfrWarrantyParts',
    label: 'mfrWarrantyParts',
  },
  {
    id: 'mfrWarrantyLabor',
    label: 'mfrWarrantyLabor',
  },
  // TODO: PUPS-1233
  // {
  //   id: 'shipEnabled',
  //   label: 'Shipping Protection Enable',
  // },
  // {
  //   id: 'shippable',
  //   label: 'Shippable',
  // },
  // {
  //   id: 'partialReplacement',
  //   label: 'Partial Replacement',
  // },
  // {
  //   id: 'productReturn',
  //   label: 'Product Return',
  // },
  {
    id: 'createdDt',
    label: 'Created',
    renderCell: (data: CellContext<ProductsSearchItem, string>) => {
      return date.format(new Date(data.getValue()).getTime(), 'YYYY-MM-DD h:mm:sa')
    },
  },
  {
    id: 'planId',
  },
]

export const getTableFilterOptions = (categories: string[], plansList: string[]): FilterDef[] => [
  {
    type: 'group',
    label: 'Filters',
    filterDefs: [
      {
        id: 'category',
        type: 'select',
        label: 'Program',
        options: convertToFilterOptions(categories),
        isMultiSelect: true,
      },
      {
        id: 'subcategory',
        type: 'select',
        label: 'Subprogram',
        options: convertToFilterOptions(categories),
        isMultiSelect: true,
      },
      {
        id: 'price',
        type: 'currencyRange',
        currency: 'USD',
        locale: 'en-US',
        label: 'Price',
        inputLabel: 'Price Range',
      },
      {
        id: 'warrantyStatus',
        type: 'select',
        label: 'Status',
        isMultiSelect: true,
        options: Object.keys(PRODUCT_WARRANTY_STATUS).map((key) => ({
          value: key,
          display: PRODUCT_WARRANTY_STATUS[key as ProductWarrantyStatus],
        })),
      },
      {
        id: 'enabled',
        type: 'select',
        label: 'Display Offer',
        options: [
          { value: 'true', display: 'Enabled' },
          { value: 'false', display: 'Disabled' },
        ],
      },
      {
        id: 'planId',
        type: 'select',
        label: 'Plan',
        isMultiSelect: true,
        options: convertToFilterOptions(plansList),
      },
    ],
  },
]

export const convertToFilterOptions = (categories: string[]): SelectFilterOption[] => {
  return categories.map((category) => ({
    value: category,
    display: category,
  }))
}

export const getTableFilters = (
  columnFilters: ColumnFiltersState,
): Partial<ProductsSearchArgumentsFilters> => {
  let filters: Partial<ProductsSearchArgumentsFilters> = {}
  if (columnFilters.length > 0) {
    filters = columnFilters.reduce((acc, curr) => {
      const currentFilter: Partial<ProductsSearchArgumentsFilters> = {}
      switch (curr.id) {
        // break down the price range filter into priceMin and priceMax
        case 'price':
          if ((curr?.value as number[])?.[0] > 0) {
            currentFilter.priceMin = (curr.value as number[])?.[0]
          }
          if ((curr?.value as number[])?.[1] > 0) {
            currentFilter.priceMax = (curr.value as number[])?.[1]
          }
          break
        // needs to be passed as a comma separated string of planIds
        case 'planId':
          currentFilter.planId = (curr.value as string[]).join(',')
          break
        case 'enabled':
          currentFilter.active = curr.value as boolean
          break
        default:
          currentFilter[curr.id] = curr.value
          break
      }
      return { ...acc, ...currentFilter }
    }, {})
  }
  return filters
}

// used to convert the query string params into the format that the table filters expect
export const getSearchFiltersFromQSParams = (
  columnFilters: Partial<ProductsSearchArgumentsFilters>,
): ColumnFiltersState => {
  let filters: ColumnFiltersState = []
  if (columnFilters) {
    filters = Object.keys(columnFilters).reduce((acc: ColumnFiltersState, curr) => {
      const currentFilter: ColumnFiltersState = []
      switch (curr) {
        // break down the price range filter into priceMin and priceMax
        case 'priceMin':
        case 'priceMax':
          // if the filter is already added to accumulator, skip it to avoid pushing into the array twice
          if (acc.find((filter) => filter.id === 'price')) break

          currentFilter.push({
            id: 'price',
            value: [columnFilters.priceMin, columnFilters.priceMax],
          })
          break
        // needs to be passed as a comma separated string of planIds
        case 'planId':
          currentFilter.push({
            id: 'planId',
            value: (columnFilters.planId as string).split(','),
          })
          break
        case 'active':
          currentFilter.push({
            id: 'enabled',
            value: columnFilters.active as boolean,
          })
          break
        // do not add anything for search filters as they are handled separately
        case 'name':
        case 'referenceId':
          break
        default:
          currentFilter.push({
            id: curr,
            value: columnFilters[curr],
          })
          break
      }
      return [...acc, ...currentFilter]
    }, [])
  }
  return filters
}

// used as a workaround for the fact that the filter values are not typed
export interface ProductsSearchArgumentsFilters extends ProductsSearchArguments {
  [key: string]: unknown
}
