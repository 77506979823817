function mapToValueDisplay<T>(
  array: T[] | undefined,
  valueProperty: keyof T,
  displayProperty: keyof T,
): Array<{ value: string; display: string }> {
  if (!array) {
    return []
  }
  return array.map((item) => ({
    value: item[valueProperty] as string,
    display: item[displayProperty] as string,
  }))
}

export { mapToValueDisplay }

export function hashCode(obj: any): number {
  const str = JSON.stringify(obj)
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32-bit integer
  }
  return hash
}
