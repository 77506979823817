import React from 'react'
import type { WDProductFrontend } from '@helloextend/extend-api-rtk-query'
import type { ColumnDefs, CellContext } from '@extend/zen'
import type { AdvancedSelectOption } from '@extend/zen'

const getTableColumns = (
  planCategoryListItems: AdvancedSelectOption[] = [],
): ColumnDefs<WDProductFrontend> => {
  type PlanCategoryDict = Record<string, string>

  const planCategoryDictionary: PlanCategoryDict = planCategoryListItems.reduce(
    (obj, planCategory) => ({ ...obj, [planCategory.value]: planCategory.display }),
    {},
  )
  return [
    {
      label: 'Product Id',
      id: 'productId',
      search: 'both',
      isSortable: true,
    },
    {
      label: 'Product Title',
      id: 'title',
      isSortable: true,
    },
    {
      label: 'Merchant Category',
      id: 'category',
      isSortable: true,
    },
    {
      label: 'SKU',
      id: 'sku',
      isSortable: true,
    },
    {
      label: 'Description',
      id: 'description',
    },
    {
      label: 'Price',
      id: 'price',
      isSortable: true,
    },
    {
      label: 'Plan Category',
      id: 'planCategoryId',
      renderCell: (data: CellContext<WDProductFrontend, string>) => {
        const { planCategoryId } = data.row.original
        if (planCategoryId) {
          return planCategoryDictionary[planCategoryId] ?? ''
        }
        return <span>&#8212;</span>
      },
      isSortable: true,
    },
    {
      label: 'Agent Plan Selection',
      id: 'plansArray',
      renderCell: (data: CellContext<WDProductFrontend, string[]>) => {
        const { plansArray } = data.row.original
        if (plansArray && plansArray.length > 0) {
          return plansArray.join(', ')
        }
        return <span>&#8212;</span>
      },
    },
    {
      label: 'Mapping Status',
      id: 'mappingStatus',
      isSortable: true,
    },
    {
      label: 'Last Updated',
      id: 'wdUpdatedAt',
      isSortable: true,
      renderCell: (data: CellContext<WDProductFrontend, number>) => {
        const { wdUpdatedAt } = data.row.original
        return `${new Date(wdUpdatedAt)
          .toLocaleString('en-US', { timeZone: 'UTC' })
          .replace(',', ' @')}`
      },
    },
    {
      label: 'Updated By',
      id: 'wdUpdatedBy',
      isSortable: true,
    },
    {
      label: 'Warranty Status',
      id: 'warrantyStatus',
      isSortable: true,
    },
    {
      label: 'Extend Category',
      id: 'extendCategory',
      isSortable: true,
    },
  ]
}

export { getTableColumns }
