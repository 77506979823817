import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { Switch, AdvancedSelect, Checkbox, Grid, Information, Stack, Subheading } from '@extend/zen'
import { useUpsertMerchantServicingSettingsMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import type { V2 } from '@extend-services/service-orders'
import { isEqual } from 'lodash/fp'
import { SaveChangesButtonGroup } from '../save-changes-button-group'
import {
  formattedAutomatedReplacementOrderStatusOptions,
  formattedFulfillmentOptions,
} from './utils'
import { FulfillmentOptions } from './fulfillment-options'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../../../../../../constants/ld-flags'
import type { PlatformType } from '@helloextend/extend-api-client'

type ReplacementFulfillmentProps = {
  merchantServicingSettings: MerchantServicingSettings
  storePlatform: PlatformType
}

const ReplacementFulfillment: FC<ReplacementFulfillmentProps> = ({
  merchantServicingSettings,
  storePlatform,
}) => {
  const {
    replacementFulfillmentMethod,
    isCustomerFulfillmentSelectionDisabled,
    supportedCustomerFulfillmentMethods,
    defaultReplacementOrderStatus,
  } = merchantServicingSettings
  const { [LDFlag.AutomatedReplacementFulfillment]: FF_AUTOMATED_REPLACEMENT_FULFILLMENT } =
    useFlags()

  const [activeDropdownSelection, setActiveDropdownSelection] = useState(
    replacementFulfillmentMethod || 'manual',
  )

  const [selectedCustomerFulfillmentSettings, setSelectedCustomerFulfillmentSettings] = useState(
    supportedCustomerFulfillmentMethods ?? ['direct_payment', 'virtual_card'],
  )

  const [defaultReplacementOrderStatusSelection, setDefaultReplacementOrderStatusSelection] =
    useState(defaultReplacementOrderStatus ?? 'pending')

  const [hasToggleChanged, setHasToggleChanged] = useState(false)
  const [merchantFulfillmentEnabled, setMerchantFulfillmentEnabled] = useState(
    merchantServicingSettings.fulfillmentEnabled ?? false,
  )

  const [isCustomerFulfillmentDisplayed, toggleCustomerFulfillment] = useState(
    !isCustomerFulfillmentSelectionDisabled,
  )

  const { toastCompleted, toastError } = useStandardToast()
  const [upsertMerchantServicingSettings, { isSuccess, isError, isLoading }] =
    useUpsertMerchantServicingSettingsMutation()

  const handleSave = async (): Promise<void> => {
    const settings: MerchantServicingSettings = {
      sellerId: merchantServicingSettings.sellerId,
      replacementFulfillmentMethod: activeDropdownSelection,
      isCustomerFulfillmentSelectionDisabled: !isCustomerFulfillmentDisplayed,
      supportedCustomerFulfillmentMethods:
        selectedCustomerFulfillmentSettings as V2.Models.ReplacementFulfillmentMethod[],
      fulfillmentEnabled: merchantFulfillmentEnabled,
    }

    // Only include defaultReplacementOrderStatus if the replacementFulfillmentMethod or supportedCustomerFulfillmentMethods includes automated_replacement
    if (
      settings.replacementFulfillmentMethod === 'automated_replacement' ||
      settings.supportedCustomerFulfillmentMethods?.includes('automated_replacement')
    ) {
      settings.defaultReplacementOrderStatus = defaultReplacementOrderStatusSelection
    }

    await upsertMerchantServicingSettings(settings).unwrap()

    if (activeDropdownSelection !== 'manual') {
      setMerchantFulfillmentEnabled(false)
    }
  }

  const handleCancel = (): void => {
    setActiveDropdownSelection(replacementFulfillmentMethod || 'manual')
    setMerchantFulfillmentEnabled(merchantServicingSettings.fulfillmentEnabled ?? false)
    setSelectedCustomerFulfillmentSettings(
      supportedCustomerFulfillmentMethods || ['direct_payment', 'virtual_card'],
    )
    toggleCustomerFulfillment(!isCustomerFulfillmentSelectionDisabled)
    setDefaultReplacementOrderStatusSelection(defaultReplacementOrderStatus || 'pending')
  }

  const handleNewDropdownSelection = (e: AdvancedSelectChangeEvent): void => {
    // disallow clearing the selection
    if (e.target.value) {
      setActiveDropdownSelection(e.target.value as V2.Models.ReplacementFulfillmentMethod)
    }
  }

  const handleOfferFulfillmentOptions = (): void => {
    toggleCustomerFulfillment(!isCustomerFulfillmentDisplayed)
  }

  const handleSelectCustomerFulfillment = (
    e: AdvancedSelectChangeEvent<string | string[]>,
  ): void => {
    setSelectedCustomerFulfillmentSettings(e.target.value as string[])
  }

  const handleToggleMerchantFulfillmentEnabled = (): void => {
    setMerchantFulfillmentEnabled(!merchantFulfillmentEnabled)
  }

  const handleAutomatedReplacementOrderStatusSelection = (e: AdvancedSelectChangeEvent): void => {
    setDefaultReplacementOrderStatusSelection(
      e.target.value as V2.Models.DefaultReplacementOrderStatus,
    )
  }

  useEffect(() => {
    if (isSuccess && hasToggleChanged) {
      setHasToggleChanged(false)
    }
  }, [hasToggleChanged, isSuccess])

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Servicing Settings have been successfully updated.')
    }
  }, [isSuccess, toastCompleted])

  useEffect(() => {
    if (isError) {
      toastError('There was an error updating the Servicing Settings. Please try again.')
    }
  }, [isError, toastError])

  useEffect(() => {
    if (activeDropdownSelection === 'automated_replacement') {
      setMerchantFulfillmentEnabled(true)
    }
  }, [activeDropdownSelection, setMerchantFulfillmentEnabled])

  const fulfillmentOptions = formattedFulfillmentOptions.filter((option) => {
    // This feature is currently only available for Shopify merchants (SP Only)
    if (option.value === 'automated_replacement') {
      return FF_AUTOMATED_REPLACEMENT_FULFILLMENT && storePlatform === 'shopify'
    }

    return true
  })

  return (
    <div data-cy="replacement-fulfillment">
      <ContainerWithMargin>
        <Stack isRow align="center">
          <Subheading>Replacement fulfillment</Subheading>
          <Information buttonSize="xsmall">
            Control whether the Merchant or Extend fulfills replacement claims.
          </Information>
        </Stack>
      </ContainerWithMargin>
      <ContainerWithMargin>
        <SwitchOrGridContainer>
          <Grid columns={4}>
            <AdvancedSelect
              id="replacement-fulfillment"
              isNotClearable
              data-cy="replacement-fulfillment-dropdown"
              label="Primary Fulfillment"
              multiple={false}
              onChange={handleNewDropdownSelection}
              value={activeDropdownSelection}
              options={fulfillmentOptions}
              isDisabled={isCustomerFulfillmentDisplayed}
            />
          </Grid>
        </SwitchOrGridContainer>
        <SwitchOrGridContainer>
          {activeDropdownSelection === 'manual' && (
            <Switch
              label="Merchant owns fulfillment"
              isOn={merchantFulfillmentEnabled}
              onChange={handleToggleMerchantFulfillmentEnabled}
              isDisabled={isCustomerFulfillmentDisplayed}
            />
          )}
        </SwitchOrGridContainer>
        <SwitchOrGridContainer>
          {activeDropdownSelection === 'automated_replacement' && (
            <Switch
              data-cy="merchant-owns-fulfillment-toggle"
              label="Merchant owns fulfillment"
              isOn={true}
              onChange={handleToggleMerchantFulfillmentEnabled}
              isDisabled={true}
            />
          )}
        </SwitchOrGridContainer>
        <SwitchOrGridContainer>
          {activeDropdownSelection && (
            <Checkbox
              label="Offer customer fulfillment options"
              onChange={handleOfferFulfillmentOptions}
              checked={isCustomerFulfillmentDisplayed}
            />
          )}
        </SwitchOrGridContainer>
        <SwitchOrGridContainer>
          {isCustomerFulfillmentDisplayed && (
            <FulfillmentOptions
              selectedCustomerFulfillmentSettings={selectedCustomerFulfillmentSettings}
              handleSelectCustomerFulfillment={handleSelectCustomerFulfillment}
              storePlatform={storePlatform}
            />
          )}
        </SwitchOrGridContainer>
        {(activeDropdownSelection === 'automated_replacement' ||
          selectedCustomerFulfillmentSettings.includes('automated_replacement')) && (
          <SwitchOrGridContainer>
            <Grid columns={4}>
              <AdvancedSelect
                id="default-automated-replacement-order-status"
                label="Default Automated Replacement Order Status"
                helperText={`The desired status for the replacement order to be in when created in the merchant's system. This does not impact the Extend order, claim or service-order status.`}
                data-cy="default-automated-replacement-order-status-dropdown"
                isNotClearable
                multiple={false}
                onChange={handleAutomatedReplacementOrderStatusSelection}
                value={defaultReplacementOrderStatusSelection}
                options={formattedAutomatedReplacementOrderStatusOptions}
              />
            </Grid>
          </SwitchOrGridContainer>
        )}
        {((!isEqual(supportedCustomerFulfillmentMethods, selectedCustomerFulfillmentSettings) &&
          isCustomerFulfillmentDisplayed) ||
          activeDropdownSelection !== replacementFulfillmentMethod ||
          merchantFulfillmentEnabled !== merchantServicingSettings.fulfillmentEnabled ||
          isCustomerFulfillmentDisplayed !== !isCustomerFulfillmentSelectionDisabled ||
          defaultReplacementOrderStatusSelection !== defaultReplacementOrderStatus) && (
          <SaveChangesButtonGroup
            handleSave={handleSave}
            handleCancel={handleCancel}
            isLoading={isLoading}
            isSaveDisabled={isLoading}
            id="replacement-fulfillment"
          />
        )}
      </ContainerWithMargin>
    </div>
  )
}

const SwitchOrGridContainer = styled.div({
  marginBottom: 16,
})

const ContainerWithMargin = styled.div({
  marginBottom: 24,
})

export { ReplacementFulfillment }
