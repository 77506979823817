import styled from '@emotion/styled'

export const FlexRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

export const FlexColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const FlexRowSpaceBetween = styled(FlexRow)({
  justifyContent: 'space-between',
})

export const FlexRowCenter = styled(FlexRow)({
  justifyContent: 'center',
})
