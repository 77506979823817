import type { FC } from 'react'
import React, { useEffect, useCallback, useState } from 'react'
import { DataTable } from '@extend/zen'
import {
  useGetPlanSetMappingsQuery,
  useLazyListPlanSetsByPlanSetIdsQuery,
} from '@helloextend/extend-api-rtk-query'
import type { PlanCategory, PlanSet, StorePlanSetMapping } from '@helloextend/extend-api-client'
import type { StorePlanSetTableData } from './plan-sets-data-table-config'
import { planSetsDataTableColumns } from './plan-sets-data-table-config'

type PlanSetsDataTableProps = {
  storeId: string
  categoryList: PlanCategory[]
}

const PlanSetsDataTable: FC<PlanSetsDataTableProps> = ({ storeId, categoryList }) => {
  const [tableData, setTableData] = useState<StorePlanSetTableData[]>()
  const {
    data: getPlanSetMappingListResponse,
    isLoading: isLoadingPlanSetMappingList,
    isError: isPlanSetMappingListError,
    isFetching: isFetchingPlanSetMappingList,
  } = useGetPlanSetMappingsQuery({ storeId })

  const [
    getPlanSetsById,
    {
      data: planSetListData,
      isLoading: isLoadingPlanSetListData,
      isFetching: isFetchingPlanSetListData,
      isError: isPlanSetListDataError,
    },
  ] = useLazyListPlanSetsByPlanSetIdsQuery()

  const getPlanSets = useCallback(
    async (planIds: string, planSetMapping: StorePlanSetMapping[]) => {
      const res = await getPlanSetsById(planIds)

      if (!res.data) {
        return
      }

      const planSetListDataOptimized: Record<string, PlanSet> = res.data.items.reduce(
        (accumulator: Record<string, PlanSet>, category: PlanSet) => {
          accumulator[category.id] = { ...category }
          return accumulator
        },
        {},
      )

      const categoriesListOptimized = categoryList.reduce(
        (accumulator: Record<string, PlanCategory>, category: PlanCategory) => {
          accumulator[category.id] = { ...category }
          return accumulator
        },
        {},
      )

      const tempTableData: StorePlanSetTableData[] = planSetMapping.reduce(
        (accumulator: StorePlanSetTableData[], mapping: StorePlanSetMapping) => {
          if (
            categoriesListOptimized[mapping.planCategoryId] &&
            planSetListDataOptimized[mapping.planSetId]
          ) {
            const combinedData = {
              planSetMapping: { ...mapping },
              planCategory: categoriesListOptimized[mapping.planCategoryId],
              planSet: planSetListDataOptimized[mapping.planSetId],
            }
            accumulator.push(combinedData)
          }
          return accumulator
        },
        [],
      )

      const orderedTableData = tempTableData
        .slice()
        .sort((a, b) => a.planSet.name.localeCompare(b.planSet.name))

      setTableData(orderedTableData)
    },
    [getPlanSetsById, categoryList],
  )

  useEffect(() => {
    if (getPlanSetMappingListResponse) {
      const planIds = getPlanSetMappingListResponse.items.map((mapping: StorePlanSetMapping) => {
        return mapping.planSetId
      })

      getPlanSets(planIds?.join(','), getPlanSetMappingListResponse.items)
    }
  }, [getPlanSetMappingListResponse, getPlanSets, planSetListData, storeId])

  const isLoadingTableData =
    isLoadingPlanSetListData ||
    isLoadingPlanSetMappingList ||
    isFetchingPlanSetListData ||
    isFetchingPlanSetMappingList

  return (
    <>
      <DataTable
        data-cy="plan-sets-data-table"
        isLoading={isLoadingTableData}
        isError={isPlanSetMappingListError || isPlanSetListDataError}
        data={tableData || []}
        stateViews={{
          skeleton: { message: 'Please wait while we retrieve store plan set data' },
          error: { heading: 'Unable to retrieve store plan set data' },
          noData: {
            heading: 'No plan sets yet',
            description: 'Click “Add Plan Set” to select plan sets.',
          },
        }}
        columns={planSetsDataTableColumns}
        hasConfigurableColumns={false}
      />
    </>
  )
}

export { PlanSetsDataTable }
