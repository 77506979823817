import type { FC } from 'react'
import React, { useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'
import {
  autocompletion,
  closeBrackets,
  closeBracketsKeymap,
  completionKeymap,
} from '@codemirror/autocomplete'
import { highlightSelectionMatches, searchKeymap } from '@codemirror/search'
import { EditorState, Compartment } from '@codemirror/state'
import type { ViewUpdate } from '@codemirror/view'
import {
  crosshairCursor,
  drawSelection,
  dropCursor,
  EditorView,
  highlightActiveLine,
  highlightActiveLineGutter,
  highlightSpecialChars,
  keymap,
  lineNumbers,
  rectangularSelection,
} from '@codemirror/view'
import { defaultKeymap, indentWithTab, history, historyKeymap } from '@codemirror/commands'
import { css } from '@codemirror/lang-css'
import {
  bracketMatching,
  defaultHighlightStyle,
  foldGutter,
  foldKeymap,
  indentOnInput,
  syntaxHighlighting,
} from '@codemirror/language'
import { Stack, Button } from '@extend/zen'
import type { Theme } from '@helloextend/extend-api-rtk-query'
import { SaveChangesButtonGroup } from '../settings/components/save-changes-button-group'
import { ConfirmationModal } from '../../../../../../components/confirmation-modal'

export type CssOverrideEditorProps = {
  theme: Theme | undefined
  allowEditing: boolean
  saveConfirmation?: boolean
  editorDocumentChanged: (documentValue: string, view: EditorView) => void
  editorSaved: (documentValue: string) => Promise<void>
}

const CssOverrideEditor: FC<CssOverrideEditorProps> = ({
  theme,
  allowEditing,
  saveConfirmation,
  editorDocumentChanged,
  editorSaved,
}) => {
  const [prevCssText, setPrevCssText] = useState('')
  const [editableCompartment] = useState(new Compartment())
  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false)

  const editor = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const editorTheme = EditorView.theme({
      '&.cm-editor': {
        height: '600px',
        outline: 'none',
      },
    })

    const setEditingExtension = EditorView.updateListener.of((update: ViewUpdate) => {
      if (update.startState != update.state) {
        setIsEditing(update.state.facet(EditorView.editable))
      }

      if (update.docChanged) {
        editorDocumentChanged(update.state.doc.toString(), update.view)
      }
    })

    const startState = EditorState.create({
      extensions: [
        lineNumbers(),
        highlightActiveLineGutter(),
        highlightSpecialChars(),
        history(),
        foldGutter(),
        drawSelection(),
        dropCursor(),
        EditorState.allowMultipleSelections.of(true),
        indentOnInput(),
        syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
        bracketMatching(),
        closeBrackets(),
        autocompletion(),
        rectangularSelection(),
        crosshairCursor(),
        highlightActiveLine(),
        highlightSelectionMatches(),
        keymap.of([
          ...closeBracketsKeymap,
          ...defaultKeymap,
          ...searchKeymap,
          ...historyKeymap,
          ...foldKeymap,
          ...completionKeymap,
          indentWithTab,
        ]),
        css(),
        editableCompartment.of(EditorView.editable.of(false)),
        editorTheme,
        setEditingExtension,
      ],
    })

    const view = new EditorView({ state: startState, parent: editor.current ?? undefined })

    return () => {
      view.destroy()
    }
  }, [editableCompartment])

  const save = async (): Promise<void> => {
    if (!editor.current) return
    try {
      setIsSaving(true)
      const view = EditorView.findFromDOM(editor.current)
      const cssText = view?.state.doc.toString() ?? ''
      await editorSaved(cssText)

      // this shouldn't ever truly be necessary as it's set when the edit button is clicked but just to be safe
      setPrevCssText(cssText)
    } catch (error) {
      // the outer called function should handle showing a toast, nothing needs to happen here
    }

    const view = EditorView.findFromDOM(editor.current)

    view?.dispatch({
      effects: editableCompartment.reconfigure(EditorView.editable.of(false)),
    })
    setIsSaving(false)
  }

  const handleSaveClick = async (): Promise<void> => {
    if (saveConfirmation) {
      setIsConfirmationModalVisible(true)
      return
    }

    await save()
  }

  const handleCancelClick = (): void => {
    if (!editor.current) return
    const view = EditorView.findFromDOM(editor.current)
    if (view?.state.doc.toString() === prevCssText) {
      view?.dispatch({
        effects: editableCompartment.reconfigure(EditorView.editable.of(false)),
      })
      return
    }
    view?.dispatch({
      changes: {
        from: 0,
        to: view.state.doc.length,
        insert: prevCssText,
      },
      effects: editableCompartment.reconfigure(EditorView.editable.of(false)),
    })
  }

  const handleModalConfirm = async (): Promise<void> => {
    await save()
    setIsConfirmationModalVisible(false)
  }

  const handleModalCancel = (): void => {
    setIsConfirmationModalVisible(false)
  }

  const handleEditClicked = (): void => {
    if (editor.current) {
      const view = EditorView.findFromDOM(editor.current)

      setPrevCssText(view?.state.doc.toString() ?? '')

      view?.dispatch({
        effects: editableCompartment.reconfigure(EditorView.editable.of(true)),
      })
    }
  }

  useEffect(() => {
    if (editor.current) {
      const view = EditorView.findFromDOM(editor.current)
      view?.dispatch({
        changes: {
          from: 0,
          to: view.state.doc.length,
          insert: theme?.cssConfig?.css ?? '',
        },
      })
    }
  }, [theme])

  return (
    <Stack>
      <ConfirmationModal
        data-cy="css-confirmation-modal"
        isVisible={isConfirmationModalVisible}
        onCancel={handleModalCancel}
        onConfirm={handleModalConfirm}
        isProcessing={isSaving}
        confirmButtonColor="blue"
        confirmButtonText="Proceed with Save"
        cancelButtonText="Cancel"
        cancelButtonColor="blue"
        heading="Saving the CSS Override disables the current deployment"
        description="To activate the new code, you will need to switch on the toggle again after saving. Do you wish to proceed?"
        size="md"
      />
      <div data-cy="css-override-codemirror" ref={editor} />
      <ButtonGroupWrapper>
        {!isEditing && (
          <Button
            text="Edit"
            onClick={handleEditClicked}
            emphasis="medium"
            data-cy="css-override-edit-button"
            isDisabled={!allowEditing}
          />
        )}
        {isEditing && (
          <SaveChangesButtonGroup
            id="css-override"
            handleSave={handleSaveClick}
            handleCancel={handleCancelClick}
            isLoading={isSaving}
            isSaveDisabled={!allowEditing}
          />
        )}
      </ButtonGroupWrapper>
    </Stack>
  )
}

const ButtonGroupWrapper = styled.div({
  marginTop: 16,
})

export { CssOverrideEditor }
