import { faker } from '@faker-js/faker/locale/en'
import type {
  Action,
  Collect,
  ConversationConfigurationResponse,
  ConversationCreateRequest,
  ConversationResponse,
  DefaultMessage,
  DefaultReply,
  Execute,
  Expects,
  Forward,
  InvokeNeed,
  LookupNeed,
  Message,
  Need,
  NonNumericCondition,
  NonNumericConditionWithComparand,
  NonNumericConditionWithScript,
  NumericCondition,
  NumericConditionWithScript,
  NumericConditionWithValue,
  NumericInvokableFunction,
  Operator,
  Option,
  Pattern,
  Poll,
  Prompt,
  PromptOption,
  PromptType,
  Replacement,
  ReplacementMessage,
  ReplacementReply,
  Reply,
  RuleCreate,
  RuleResponse,
  RulesetResponse,
  RulesetType,
  ScriptItem,
  ThreadCreateRequest,
  ThreadListItem,
  ThreadResponse,
  UsedByResponse,
} from '@helloextend/extend-api-rtk-query'
import { MessageType, AdjudicationCategory } from '@helloextend/extend-api-rtk-query'

export function generateThreadListResponse(): ThreadListItem[] {
  return Array.from({ length: faker.datatype.number({ min: 1, max: 10 }) }, () =>
    generateThreadListItem(),
  )
}
export function generateThreadListItem(overrides: Partial<ThreadListItem> = {}): ThreadListItem {
  return {
    id: faker.datatype.uuid(),
    title: `${faker.word.adjective()} ${faker.word.noun()}`,
    type: 'adjudication',
    createdAt: faker.date.past().toString(),
    updatedAt: faker.date.recent().toString(),
    editedBy: `${faker.name.firstName()} ${faker.name.lastName()}`,
    status: faker.helpers.arrayElement(['pending_changes', 'published', 'draft']),
    ...overrides,
  }
}

const REQUIRED_SLOTS = ['IncidentDate', 'FailureType']
export function generateScript({
  lastAction = 'next',
  scriptCount = REQUIRED_SLOTS.length,
  messageCount = 1,
  optionCount = 1,
  hasNeeds = false,
}: {
  lastAction?: Exclude<Action, 'inject' | 'replace'>
  scriptCount?: number
  messageCount?: number
  optionCount?: number
  hasNeeds?: boolean
} = {}): ScriptItem[] {
  return Array.from({ length: scriptCount }, (_i, i) => {
    const isLastScript = i === scriptCount - 1

    const collectOptions = Array.from({ length: optionCount }, (_j, j) => {
      const isLastOption = isLastScript && j === optionCount - 1
      return generateOption({
        action: isLastOption ? lastAction : 'execute',
        default: true,
        execute: isLastOption ? undefined : generateExecute({ scriptIndex: i + 1 }),
      })
    })
    const collect = hasNeeds
      ? generateCollect({ options: collectOptions })
      : generateCollect({ options: collectOptions, needs: undefined })

    const messages = Array.from({ length: messageCount }, () => generateDefaultMessage())
    const promptOptions = Array.from({ length: optionCount }, () => generatePromptOption())
    const reply = generateDefaultReply({
      messages,
      prompt: generatePrompt({
        slot: REQUIRED_SLOTS[i] ?? faker.random.word(),
        options: promptOptions,
      }),
    })

    return {
      collect,
      reply,
    }
  })
}

export function generateThreadResponse(overrides: Partial<ThreadResponse> = {}): ThreadResponse {
  return {
    id: faker.datatype.uuid(),
    type: 'adjudication',
    title: faker.lorem.sentence(),
    script: generateScript({ lastAction: 'next' }),
    status: 'draft',
    editedBy: faker.name.firstName(),
    createdAt: faker.date.recent().getTime().toString(),
    updatedAt: faker.date.recent().getTime().toString(),
    version: Number(faker.random.numeric(2)),
    ...overrides,
  }
}

export function generateThreadCreateRequest(
  overrides: Partial<ThreadCreateRequest> = {},
): ThreadCreateRequest {
  return {
    type: 'adjudication',
    title: faker.lorem.sentence(),
    script: generateScript({ lastAction: 'next' }),
    ...overrides,
  }
}

export function generateScriptItemNonCustomerFacing(
  overrides: Partial<ScriptItem> = {},
): ScriptItem {
  return {
    collect: generateCollect(),
    ...overrides,
  }
}

export function generateStatementScriptItem(overrides: Partial<ScriptItem> = {}): ScriptItem {
  return {
    collect: {
      options: [{ action: 'stop', default: true }],
    },
    reply: {
      messages: [
        {
          type: MessageType.text,
          content: faker.lorem.words(),
        },
      ],
    },
    ...overrides,
  }
}

export function generateImageUploadScriptItem(
  overrides: Partial<ScriptItem> = {},
): Required<Pick<ScriptItem, 'collect' | 'reply'>> {
  return {
    collect: {
      options: [
        { action: 'execute', default: true, execute: generateExecute({ scriptIndex: -1 }) },
      ],
    },
    reply: {
      messages: [
        {
          type: MessageType.text,
          content: faker.lorem.words(),
        },
      ],
      prompt: {
        slot: 'ClaimPhotoUpload',
        type: 'imageUpload',
        presignedPost: {
          action: 'replace',
          replace: {
            invoke: 'generatePresignedPost',
            expects: {
              type: 'object',
              schema: 'PresignedPost',
            },
          },
        },
        options: [],
      },
    },
    ...overrides,
  }
}

export function generateScriptItem(overrides: Partial<ScriptItem> = {}): ScriptItem {
  return {
    collect: generateCollect(),
    reply: generateReply(),
    ...overrides,
  }
}

export function generateCollect(overrides: Partial<Collect> = {}): Collect {
  return {
    needs: [generateNeed()],
    options: [generateOption()],
    ...overrides,
  }
}

export function generateNeed(overrides: Partial<Need> = {}): Need {
  return {
    ...faker.helpers.arrayElement([generateLookupNeed(), generateInvokeNeed()]),
    ...overrides,
  }
}

export function generateLookupNeed(overrides: Partial<LookupNeed> = {}): LookupNeed {
  return {
    lookup: faker.random.word(),
    expects: generateExpects(),
    ...overrides,
  }
}

export function generateInvokeNeed(overrides: Partial<InvokeNeed> = {}): InvokeNeed {
  return {
    invoke: faker.random.word(),
    expects: generateExpects(),
    ...overrides,
  }
}

export function generateExpects(overrides: Partial<Expects> = {}): Expects {
  return {
    type: faker.helpers.arrayElement(['string', 'number', 'boolean', 'object']),
    schema: faker.random.word(),
    ...overrides,
  }
}

export function generateOption(overrides: Partial<Option> = {}): Option {
  return {
    default: faker.datatype.boolean(),
    patterns: [generatePattern()],
    action: faker.helpers.arrayElement(['next', 'repeat', 'execute', 'stop']),
    execute: generateExecute(),
    ...overrides,
  }
}

export function generatePattern(overrides: Partial<Pattern> = {}): Pattern {
  return {
    input: faker.random.word(),
    params: {
      [faker.random.word()]: faker.random.word(),
    },
    ...overrides,
  }
}

export function generateExecute(overrides: Partial<Execute> = {}): Execute {
  return {
    scriptIndex: parseInt(faker.random.numeric(), 10),
    forward: [generateForward()],
    ...overrides,
  }
}

export function generateForward(overrides: Partial<Forward> = {}): Forward {
  return {
    ...faker.helpers.arrayElement([
      { key: faker.random.word(), path: faker.random.word() },
      { key: faker.random.word(), value: faker.random.word() },
    ]),
    ...overrides,
  }
}

export function generateReply(overrides: Partial<Reply> = {}): Reply {
  return {
    ...faker.helpers.arrayElement([generateReplacementReply(), generateDefaultReply()]),
    ...overrides,
  }
}
export function generateReplacementReply(
  overrides: Partial<ReplacementReply> = {},
): ReplacementReply {
  return {
    ...generateReplacement(),
    ...overrides,
  }
}

export function generateReplacement(overrides: Partial<Replacement> = {}): Replacement {
  return {
    action: 'replace',
    replace: {
      invoke: faker.random.word(),
      expects: generateExpects(),
    },
    ...overrides,
  }
}

export function generateDefaultReply(overrides: Partial<DefaultReply> = {}): DefaultReply {
  return {
    needs: [generateNeed()],
    messages: [generateMessage()],
    prompt: generatePrompt(),
    poll: generatePoll(),
    claimId: faker.datatype.uuid(),
    ...overrides,
  }
}

export function generatePoll(overrides: Partial<Poll> = {}): Poll {
  return {
    url: faker.internet.url(),
    accessToken: faker.random.word(),
    ...overrides,
  }
}

export function generateMessage(overrides: Partial<Message> = {}): Message {
  return {
    ...faker.helpers.arrayElement([generateReplacementMessage(), generateDefaultMessage()]),
    ...overrides,
  }
}

export function generateReplacementMessage(
  overrides: Partial<ReplacementMessage> = {},
): ReplacementMessage {
  return {
    ...generateReplacement(),
    ...overrides,
  }
}

export function generateDefaultMessage(overrides: Partial<DefaultMessage> = {}): DefaultMessage {
  return {
    type: 'text' as MessageType,
    content: faker.lorem.sentence(),
    imageUrl: faker.internet.url(),
    ...overrides,
  }
}

export function generatePrompt(overrides: Partial<Prompt> = {}): Prompt {
  return {
    slot: faker.random.word(),
    type: faker.helpers.arrayElement([
      'input',
      'carousel',
      'orderCarousel',
      'datepicker',
      'imageUpload',
      'addressVerification',
    ]) as PromptType,
    placeholder: faker.random.word(),
    options: [generatePromptOption()],
    card: generateReplacement(),
    ...overrides,
  }
}

export function generatePromptOption(overrides: Partial<PromptOption> = {}): PromptOption {
  return {
    title: faker.random.word(),
    value: faker.random.word(),
    outputText: faker.lorem.sentence(),
    ...overrides,
  }
}

export function generateConversationListResponse(): ConversationResponse[] {
  return Array.from({ length: faker.datatype.number({ min: 1, max: 10 }) }, () =>
    generateConversationResponse(),
  )
}

export function generateConversationResponse(
  overrides: Partial<ConversationResponse> = {},
): ConversationResponse {
  return {
    id: faker.datatype.uuid(),
    createdAt: faker.date.past().getTime().toString(),
    description: faker.lorem.sentence(),
    editedBy: `${faker.name.firstName()} ${faker.name.lastName()}`,
    status: faker.helpers.arrayElement(['published', 'draft', 'archived']),
    threads: Array.from({ length: faker.datatype.number({ min: 1, max: 10 }) }, () =>
      faker.random.word(),
    ),
    title: getRandomAdjudicationCategory(),
    updatedAt: faker.date.recent().getTime().toString(),
    version: 0,
    ...overrides,
  }
}

export function generateConversationCreateRequest(
  overrides: Partial<ConversationCreateRequest> = {},
): ConversationCreateRequest {
  return {
    title: faker.random.word(),
    description: faker.lorem.sentence(),
    threads: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      () =>
        `${faker.datatype.uuid()}:${faker.helpers.arrayElement([
          'adjudication',
          'troubleshooting',
        ])}`,
    ),
    ...overrides,
  }
}

export function generateUsedByResponse(numUsedBy: number): UsedByResponse[] {
  return Array.from({ length: numUsedBy }, () => {
    return { title: faker.random.word(), status: 'published' }
  })
}

const getRandomAdjudicationCategory = (): AdjudicationCategory => {
  const values = Object.keys(AdjudicationCategory) as AdjudicationCategory[]
  const randomIndex = Math.floor(Math.random() * values.length)
  return values[randomIndex]
}

export function generateConversationConfigurationResponse(
  overrides: Partial<ConversationConfigurationResponse> = {},
): ConversationConfigurationResponse {
  return {
    id: faker.datatype.uuid(),
    category: getRandomAdjudicationCategory(),
    createdAt: faker.date.past().getTime().toString(),
    editedBy: `${faker.name.firstName()} ${faker.name.lastName()}`,
    updatedAt: faker.date.recent().getTime().toString(),
    conversationId: faker.datatype.uuid(),
    storeId: faker.datatype.uuid(),
    planId: faker.datatype.uuid(),
    ...overrides,
  }
}

export function generateConversationConfigurationListResponse(): ConversationConfigurationResponse[] {
  return Array.from({ length: faker.datatype.number({ min: 1, max: 10 }) }, () =>
    generateConversationConfigurationResponse(),
  )
}

export function generateRulesetResponse(overrides: Partial<RulesetResponse> = {}): RulesetResponse {
  return {
    id: faker.datatype.uuid(),
    type: 'THREAD' as RulesetType,
    version: Number(faker.random.numeric(2)),
    editedBy: faker.name.firstName(),
    createdAt: faker.date.recent().getTime().toString(),
    updatedAt: faker.date.recent().getTime().toString(),
    isDeleted: false,
    approveRules: [generateRuleResponse({ status: 'approved', ...overrides })],
    denyRules: [generateRuleResponse({ status: 'denied', ...overrides })],
    reviewRules: [],
    ...overrides,
  }
}

export const generateRuleResponse = (overrides: Partial<RuleResponse> = {}): RuleResponse => {
  return {
    id: faker.datatype.uuid(),
    status: 'approved',
    index: 0,
    conditions: [generateNumericCondition(), generateNonNumericCondition()],
    version: 0,
    createdAt: faker.date.recent().getTime().toString(),
    updatedAt: faker.date.recent().getTime().toString(),
    ...overrides,
  }
}

export const generateRuleResponseExpanded = (
  overrides: Partial<RuleResponse> = {},
): RuleResponse => {
  return {
    id: faker.datatype.uuid(),
    status: faker.helpers.arrayElement(['approved', 'denied']),
    index: 0,
    conditions: [
      generateNumericConditionWithScript({
        script: generateScriptItem({
          reply: generateDefaultReply({
            messages: [generateDefaultMessage(), generateDefaultMessage()],
          }),
        }),
      }),
      generateNonNumericConditionWithScript({
        script: generateScriptItem({
          reply: generateDefaultReply({
            messages: [generateDefaultMessage(), generateDefaultMessage()],
          }),
        }),
      }),
    ],
    version: 0,
    createdAt: faker.date.recent().getTime().toString(),
    updatedAt: faker.date.recent().getTime().toString(),
    ...overrides,
  }
}

export const generateRuleCreate = (overrides: Partial<RuleCreate> = {}): RuleCreate => {
  return {
    conditions: [generateNumericCondition(), generateNonNumericCondition()],
    ...overrides,
  }
}

export const generateNumericCondition = (
  overrides: Partial<NumericCondition> = {},
): NumericCondition => {
  if (overrides.script) {
    return generateNumericConditionWithScript(overrides)
  }
  if (overrides.value) {
    return generateNumericConditionWithValue(overrides)
  }
  return faker.helpers.arrayElement([
    generateNumericConditionWithScript(overrides as NumericConditionWithScript),
    generateNumericConditionWithValue(overrides as NumericConditionWithValue),
  ])
}

export const generateNumericConditionWithScript = (
  overrides: Partial<NumericConditionWithScript> = {},
): NumericConditionWithScript => {
  return {
    script: faker.datatype.number({ min: 0, max: 10 }),
    comparand: getRandomNumericInvokableFunction(),
    operator: getRandomOperator(),
    offset: faker.datatype.number(),
    ...overrides,
  }
}

export const generateNumericConditionWithValue = (
  overrides: Partial<NumericConditionWithValue> = {},
): NumericConditionWithValue => {
  return {
    value: faker.datatype.number(),
    comparand: getRandomNumericInvokableFunction(),
    operator: getRandomOperator(),
    offset: faker.datatype.number(),
    ...overrides,
  }
}

export const generateNonNumericCondition = (
  overrides: Partial<NonNumericCondition> = {},
): NonNumericCondition => {
  if (overrides.script) {
    return generateNonNumericConditionWithScript(overrides)
  }
  if (overrides.comparand) {
    return generateNonNumericConditionWithComparand(overrides)
  }
  return faker.helpers.arrayElement([
    generateNonNumericConditionWithScript(overrides as NonNumericConditionWithScript),
    generateNonNumericConditionWithComparand(overrides as NonNumericConditionWithComparand),
  ])
}

export const generateNonNumericConditionWithScript = (
  overrides: Partial<NonNumericConditionWithScript> = {},
): NonNumericConditionWithScript => {
  return {
    script: faker.datatype.number({ min: 0, max: 10 }),
    value: [faker.random.word()],
    ...overrides,
  }
}

export const generateNonNumericConditionWithComparand = (
  overrides: Partial<NonNumericConditionWithComparand> = {},
): NonNumericConditionWithComparand => {
  return {
    comparand: 'planHasCoverageType',
    value: [faker.random.word()],
    ...overrides,
  }
}

export const getRandomNumericInvokableFunction = (): NumericInvokableFunction => {
  return faker.helpers.arrayElement([
    'contractTransactionDate',
    'contractEndDate',
    'productDeliveryDate',
  ])
}

export const getRandomOperator = (): Operator => {
  return faker.helpers.arrayElement(['>', '>=', '=', '<=', '<'])
}

export const generateMultipleChoiceMB = (
  slot: string,
  options: Array<{ text: string; execute?: number }>,
): ScriptItem => {
  return {
    collect: {
      options: options.map((option) => {
        return {
          action: option.execute ? 'execute' : 'next',
          execute: option.execute ? { scriptIndex: option.execute } : undefined,
          patterns: [
            {
              input: option.text,
            },
          ],
        }
      }),
    },
    reply: {
      messages: [
        {
          type: MessageType.text,
          content: `This is a ${slot} MB`,
        },
      ],
      prompt: {
        type: 'buttons',
        slot,
        options: options.map((option) => {
          return {
            title: option.text,
            value: option.text,
            outputText: '',
          }
        }),
      },
    },
  }
}

export const generateMultiChoiceMessageBlock = (
  {
    slot = undefined,
    firstReplyOptionTitle = undefined,
    secondReplyOptionTitle = undefined,
  }: {
    slot?: string
    firstReplyOptionTitle?: string
    secondReplyOptionTitle?: string
  } = {
    slot: undefined,
    firstReplyOptionTitle: undefined,
    secondReplyOptionTitle: undefined,
  },
): ScriptItem => {
  const firstOptionText = firstReplyOptionTitle || 'First Button Text'
  const secondOptionText = secondReplyOptionTitle || 'Second Button Text'

  return {
    collect: {
      options: [
        {
          action: 'execute',
          execute: {
            scriptIndex: 0,
          },
          patterns: [
            {
              input: firstOptionText,
            },
          ],
        },
        {
          action: 'execute',
          execute: {
            scriptIndex: 1,
          },
          patterns: [
            {
              input: secondOptionText,
            },
          ],
        },
      ],
    },
    reply: {
      messages: [
        {
          type: MessageType.text,
          content: '',
        },
      ],
      prompt: {
        type: 'buttons',
        slot: slot || faker.random.word(),
        options: [
          {
            title: firstOptionText,
            value: firstOptionText,
            outputText: '',
          },
          {
            title: secondOptionText,
            value: secondOptionText,
            outputText: '',
          },
        ],
      },
    },
  }
}
