import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(timezone)
dayjs.extend(utc)

export const DEFAULT_TIME_ZONE = 'America/Los_Angeles'

export function parseTime(
  dateValue?: number | string | null,
  timeValue?: string,
  timezoneValue?: string,
): dayjs.Dayjs | undefined {
  if (!dateValue) {
    return undefined
  }
  let tz = DEFAULT_TIME_ZONE
  if (timezoneValue) {
    tz = timezoneValue
  }

  let date = dayjs.tz(dateValue, tz)
  if (date.isValid()) {
    if (timeValue) {
      const time = parseInt(timeValue)
      if (time && time >= 0 && time <= 23) {
        date = date.hour(time)
      }
    }

    const now = dayjs.tz(new Date(), tz)
    if (now.isAfter(date)) {
      return now.add(1, 'hour')
    }

    return date
  }

  return undefined
}
