import type { FC } from 'react'
import React, { useState } from 'react'
import type { InsuranceClaim, ClaimPhotosGetResponse } from '@helloextend/extend-api-client'
import styled from '@emotion/styled'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Info, InlineAlert, InlineAlertColor } from '@extend/zen'
import { ClaimPhotoCard } from '../../../../../../components/claim-photo-card'
import { ConfirmPhotoDeleteModal } from '../confirm-photo-delete'
import { AttachmentsUpload } from '../tab-section/tabs/attachments/attachments-upload'
import { LDFlag } from '../../../../../../constants/ld-flags'
import { PhotoRequirementUploadCard } from './photo-requirement-upload-card'

const photoIdRegex = /photo\/([A-Za-z0-9-]*)/

interface ClaimPhotosSectionProps {
  claim: InsuranceClaim
  photoData?: ClaimPhotosGetResponse
}

const ClaimPhotosSection: FC<ClaimPhotosSectionProps> = ({ claim, photoData }) => {
  const { [LDFlag.NewClaimAttachments]: FF_NEW_CLAIM_ATTACHMENTS } = useFlags()

  const [deletePhoto, setDeletePhoto] = useState<{ claimId: string; photoId: string } | null>(null)

  const handleDownloadClick = (url?: string): void => {
    if (!url) return
    window.open(url, '_blank')
  }

  const handleDeleteClick = (url?: string): void => {
    if (!url) return
    const regexResult = photoIdRegex.exec(url)
    if (!regexResult || regexResult.length < 2) return
    const photoId = regexResult[1]
    setDeletePhoto({ claimId: claim.id, photoId })
  }

  const handleModalClose = (): void => {
    setDeletePhoto(null)
  }

  const requirementsWithoutPhotos =
    claim.photoRequirements?.filter(
      (requirement) =>
        !photoData?.photoDetails.some((photo) => photo.requirement?.id === requirement.id),
    ) ?? []

  return (
    <>
      {photoData?.photoDetails && (
        <SectionContainer>
          <HeaderTitle>Attachments</HeaderTitle>
          {claim.photoRequirements?.length && (
            <>
              <InlineAlert
                data-cy="attachments-inline-alert"
                color={InlineAlertColor.yellow}
                icon={Info}
              >
                <strong>Do not upload photos with PII or photos that violate HIPPA</strong>
                <br />
                Personally identifiable information (PII) is any information connected to a specific
                individual that can be used to uncover the individual&apos;s identity. PII includes
                but not limited to social security number, full name, address or email address.
                Health Insurance Portability and Accountability act (HIPPA) Title II mandates that
                anyone who deals with individuals&apos; medical data take active steps to keep that
                data private and secure. To avoid any liability for HIPPA violations, do not upload
                photos with medical data.
              </InlineAlert>
              <PhotoRequirementsContainer data-cy="photos-section-image-container">
                {requirementsWithoutPhotos.map((photoRequirement) => (
                  <PhotoRequirementUploadCard
                    photoRequirement={photoRequirement}
                    claim={claim}
                    key={photoRequirement.id}
                  />
                ))}
              </PhotoRequirementsContainer>
            </>
          )}
          <ImagesContainer data-cy="photos-section-image-container">
            {photoData.photoDetails.map((detail) => {
              return (
                <ClaimPhotoCard
                  photoDetail={detail}
                  category={claim.adjudicationCategory}
                  onDeleteClick={() => handleDeleteClick(detail.url)}
                  onDownloadClick={() => handleDownloadClick(detail.url)}
                  key={detail.url ?? 'key'}
                />
              )
            })}
          </ImagesContainer>
        </SectionContainer>
      )}
      {FF_NEW_CLAIM_ATTACHMENTS && (
        <AttachmentsUpload
          claimId={claim.id}
          includeDisclaimer={!claim.photoRequirements?.length}
        />
      )}
      <ConfirmPhotoDeleteModal
        isVisible={Boolean(deletePhoto)}
        onClose={handleModalClose}
        claimId={deletePhoto?.claimId}
        photoId={deletePhoto?.photoId}
        contractId={claim.contractId}
      />
    </>
  )
}

const SectionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 8,
  flex: '1 0 0',
  paddingBottom: 16,
})

const ImagesContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  padding: 0,
  gap: 16,
})

const HeaderTitle = styled.h2({
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '28px',
})

const PhotoRequirementsContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: 0,
  margin: '15px 0',
  gap: 40,
})

export { ClaimPhotosSection }
