import type {
  FailureCause,
  FailureCausesMapping,
  FailureType,
  FailureTypesMapping,
} from '@helloextend/extend-api-rtk-query/src/plans/types'
import * as Yup from 'yup'
import type { FailureTypesMappingDisplay } from '../table-config/failure-types-data-table-config'

export const SHIPPING_FAILURE_TYPE_CATEGORY_ID = 'shipping_failures'
export const SHIPPING_FAILURE_CAUSE_CATEGORY_ID = 'shipping'

const schema = Yup.object()
  .shape({
    id: Yup.string()
      .matches(/^[A-Z0-9]+$/, 'Only Uppercase letters and numbers are allowed')
      .required('Plan Id is required'),
    premiumRate: Yup.number()
      .required('Premium Rate is required')
      .min(0, 'Premium Rate must be minimum of 0'),
    shipCoverageMin: Yup.number()
      .required('Ship Coverage Min is required')
      .min(0, 'Shipping Coverage Min must be minimum of 0'),
    premiumMin: Yup.number()
      .required('Premium Min is required')
      .min(0, 'Premium Min must be minimum of 0'),
    shipCoverageMax: Yup.number()
      .required('Ship Coverage Max is required')
      .min(0, 'Shipping Coverage Max must be minimum of 0'),
    name: Yup.string().required('Plan Name is required'),
    termLength: Yup.number()
      .required('Term Length is required')
      .min(1, 'Term Length must be greater than 0'),
    termsId: Yup.string().required('Terms Id is required'),
    obligor: Yup.string().required('Underwriter is required'),
    productCategory: Yup.string().required('Plan Category is required'),
    active: Yup.boolean().required('Active is required'),
    programType: Yup.string().required('Program Type is required'),
    serviceType: Yup.string().required('Service Type is required'),
    insuranceProgram: Yup.string().required('Insurance Program is required'),
    allowedRegions: Yup.array()
      .of(Yup.string().defined())
      .min(1, 'At least one Allowed Region is required'),
    currencyCode: Yup.string().required('Currency Code is required'),
    failureTypesMapping: Yup.object(), // this field is currently uneditable and being defaulted based on program type
    blockedSubdivisions: Yup.array().of(Yup.string().defined()),
  })
  .defined()

type SpPlanFormValues = Yup.InferType<typeof schema>

const labels: { [key in keyof SpPlanFormValues]: string } = {
  id: 'Plan ID',
  premiumRate: 'Premium Rate',
  shipCoverageMin: 'Shipping Coverage Min',
  premiumMin: 'Premium Min',
  shipCoverageMax: 'Shipping Coverage Max',
  name: 'Plan Name',
  termLength: 'Term Length',
  termsId: 'Terms ID',
  obligor: 'Underwriter',
  productCategory: 'Plan Category',
  active: 'Active',
  programType: 'Program Type',
  serviceType: 'Service Type',
  insuranceProgram: 'Insurance Program',
  allowedRegions: 'Allowed Regions',
  currencyCode: 'Currency Code',
  blockedSubdivisions: 'Blocked Subdivisions',
  failureTypesMapping: 'Failure Types',
}

const GetFormPropsAndLabels = <T extends keyof SpPlanFormValues>(): {
  [key in T]: { property: string; label: string }
} => {
  return Object.keys(schema.fields).reduce(
    (mapping, property) => {
      const field = schema.fields[property as keyof SpPlanFormValues]
      if (field && 'label' in field) {
        const newMapping = {
          property,
          label: labels[property as keyof SpPlanFormValues],
        }
        return {
          ...mapping,
          [property]: newMapping,
        }
      }
      return mapping
    },
    {} as {
      [key in T]: { property: string; label: string }
    },
  )
}

const getInitialValues = (spDetails?: SpPlanFormValues): SpPlanFormValues => {
  return {
    id: spDetails?.id ?? '',
    premiumRate: spDetails?.premiumRate ?? 0,
    shipCoverageMin: spDetails?.shipCoverageMin ?? 0,
    premiumMin: spDetails?.premiumMin ?? 0,
    shipCoverageMax: spDetails?.shipCoverageMax ?? 0,
    name: spDetails?.name ?? '',
    termLength: spDetails?.termLength ?? 0,
    termsId: spDetails?.termsId ?? '',
    obligor: spDetails?.obligor ?? 'otis',
    productCategory: spDetails?.productCategory ?? '',
    active: spDetails?.active ?? true,
    programType: spDetails?.programType ?? 'general_shipping_protection',
    serviceType: spDetails?.serviceType ?? 'replace',
    insuranceProgram: spDetails?.insuranceProgram ?? 'ship_protect_1',
    allowedRegions: spDetails?.allowedRegions ?? [],
    currencyCode: spDetails?.currencyCode ?? 'usd',
    failureTypesMapping: spDetails?.failureTypesMapping ?? {},
    blockedSubdivisions: spDetails?.blockedSubdivisions,
  }
}
function getDefaultFailureTypesMapping(
  programType: string,
  failureTypes: FailureType[],
  failureCauses: FailureCause[],
): { defaults: FailureTypesMapping; displays: FailureTypesMappingDisplay[] } {
  const defaults: FailureTypesMapping = {}

  failureTypes.forEach((failureType) => {
    const failureTypeName = failureType.failureType
    const failureCausesMapping: FailureCausesMapping = {}
    // failureTypesMapping defaults are set based on SPG, GSP Matrices in https://helloextend.atlassian.net/wiki/spaces/ENG/pages/1807351834/SPG+Solution+Design
    if (programType === 'safe_package_guarantee') {
      switch (true) {
        case failureTypeName.includes('lost'):
          failureCausesMapping['*'] = {
            liable: 'merchant',
            covered: true,
          }
          break
        case failureTypeName.includes('stolen'):
          failureCausesMapping['*'] = {
            liable: 'extend',
            covered: true,
          }
          break
        case failureTypeName.includes('damaged_partial'):
          failureCausesMapping['*'] = {
            liable: 'none',
            covered: false,
          }
          break
        case failureTypeName.includes('damaged'):
          failureCauses.forEach((cause) => {
            if (cause.failureCause === 'shipping_arrived_damaged') {
              failureCausesMapping[cause.id] = {
                liable: 'merchant',
                covered: true,
              }
            }
            if (cause.failureCause === 'unboxing') {
              failureCausesMapping[cause.id] = {
                liable: 'none',
                covered: false,
              }
            }
            if (cause.failureCause === 'post_delivery') {
              failureCausesMapping[cause.id] = {
                liable: 'none',
                covered: false,
              }
            }
          })
          break
        default:
          failureCausesMapping['*'] = {
            liable: 'none',
            covered: false,
          }
      }
    }
    if (programType === 'general_shipping_protection') {
      switch (true) {
        case failureTypeName.includes('lost'):
          failureCausesMapping['*'] = {
            liable: 'extend',
            covered: true,
          }
          break
        case failureTypeName.includes('stolen'):
          failureCausesMapping['*'] = {
            liable: 'extend',
            covered: true,
          }
          break
        case failureTypeName.includes('damaged_partial'):
          failureCausesMapping['*'] = {
            liable: 'none',
            covered: false,
          }
          break
        case failureTypeName.includes('damaged'):
          failureCauses.forEach((cause) => {
            if (cause.failureCause === 'shipping_arrived_damaged') {
              failureCausesMapping[cause.id] = {
                liable: 'extend',
                covered: true,
              }
            }
            if (cause.failureCause === 'unboxing') {
              failureCausesMapping[cause.id] = {
                liable: 'extend',
                covered: false,
              }
            }
            if (cause.failureCause === 'post_delivery') {
              failureCausesMapping[cause.id] = {
                liable: 'none',
                covered: false,
              }
            }
          })
          break
        default:
          failureCausesMapping['*'] = {
            liable: 'none',
            covered: false,
          }
          break
      }
    }

    defaults[failureType.id] = { failureCauses: failureCausesMapping }
  })

  return {
    defaults,
    displays: convertFailureTypesToDisplay(defaults, failureTypes, failureCauses),
  }
}
function convertFailureTypesToDisplay(
  failureTypesMapping: FailureTypesMapping,
  failureTypes: FailureType[],
  failureCauses: FailureCause[],
): FailureTypesMappingDisplay[] {
  const displayList: FailureTypesMappingDisplay[] = []

  for (const [failureTypeId, failureTypeInfo] of Object.entries(failureTypesMapping)) {
    for (const [failureCauseId, failureCauseInfo] of Object.entries(
      failureTypeInfo.failureCauses,
    )) {
      const failureTypeName = failureTypes.find((ft) => ft.id === failureTypeId)?.failureType ?? ''
      const displayName = failureTypeName.replace('shipment_', '').replace('_', ' ').trim()
      const failureCauseName =
        failureCauses.find((ft) => ft.id === failureCauseId)?.failureCause ?? ''
      const displayNameCause = failureCauseName.replace('shipping_', '').replace('_', ' ').trim()
      const displayEntry: FailureTypesMappingDisplay = {
        failureType: displayName,
        failureCause: displayNameCause,
        liableParty: failureCauseInfo.liable,
        covered: failureCauseInfo.covered ? 'yes' : 'no',
      }
      displayList.push(displayEntry)
    }
  }

  return displayList
}

export type { SpPlanFormValues }
export {
  schema,
  GetFormPropsAndLabels,
  getInitialValues,
  getDefaultFailureTypesMapping,
  convertFailureTypesToDisplay,
}
