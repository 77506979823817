import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { Badge, COLOR, Spinner } from '@extend/zen'
import { useGetThreadUsedByQuery } from '@helloextend/extend-api-rtk-query'
import { v4 } from 'uuid'

interface ReferencedConversationsProps {
  'data-cy'?: string
  threadId: string
  hasReferencedConversations: boolean
}

const ReferencedConversations: FC<ReferencedConversationsProps> = ({
  threadId,
  hasReferencedConversations,
  'data-cy': dataCy,
}) => {
  const maxConversations = 5
  const {
    data: usedByList,
    isLoading: isUsedByLoading,
    isSuccess: isUsedBySuccess,
  } = useGetThreadUsedByQuery(threadId)

  const conversations = useMemo(() => {
    if (usedByList && usedByList.length > 0) {
      return usedByList?.map((usedByItem) => ({
        title: usedByItem.title,
        status: usedByItem.status,
        key: v4(),
      }))
    }
    return []
  }, [usedByList])

  const preOverflowConversations = conversations
    .sort((a, b) => {
      if (a.title < b.title) {
        return -1
      }
      if (a.title > b.title) {
        return 1
      }
      return 0
    })
    .slice(0, maxConversations)
  const hasConversationsOverflow = conversations.length > 5

  return (
    <>
      {isUsedByLoading && (
        <LoadingContainer>
          <Spinner color={COLOR.BLUE[800]} data-cy="referenced-conversations-spinner" />
        </LoadingContainer>
      )}
      {isUsedBySuccess && hasReferencedConversations && (
        <>
          <List data-cy={dataCy}>
            {preOverflowConversations.map((conversation) => (
              <ListItem key={conversation.key} data-cy={`${conversation.key}:list-item`}>
                {conversation.title}
                <BadgeContainer>
                  <Badge
                    text={conversation.status === 'published' ? 'Published' : 'Draft'}
                    color={conversation.status === 'published' ? 'green' : 'yellow'}
                    size="small"
                    data-cy={`${conversation.key}:badge`}
                  />
                </BadgeContainer>
              </ListItem>
            ))}
          </List>
          {hasConversationsOverflow && <OverflowParagraph>and more</OverflowParagraph>}
        </>
      )}
    </>
  )
}

const List = styled.ul({
  listStyleType: 'none',
  margin: 0,
  paddingBottom: 8,
  paddingLeft: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
})

const ListItem = styled.li({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
})

const BadgeContainer = styled.div({
  display: 'inline-flex',
  marginLeft: 16,
  alignItems: 'center',
})

const LoadingContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px 0 60px 0',
})

// To override the default margin of 16(top & bottom)
const OverflowParagraph = styled.p({
  margin: 0,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '28px',
})

export type { ReferencedConversationsProps }
export { ReferencedConversations }
