import styled from '@emotion/styled'
import { Badge, COLOR, Icon, IconSize, ArrowForward } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'

const AdjudicationPromptDefault: FC<{
  badgeValues: Array<string | number>
  onBadgeClick: (val: number | string) => void
}> = ({ badgeValues, onBadgeClick }) => {
  return (
    <PromptWrapper>
      <Icon
        icon={ArrowForward}
        size={IconSize.xsmall}
        color={COLOR.NEUTRAL[300]}
        data-cy="publish-modal:arrow-icon"
      />
      {badgeValues.map((badgeValue: string | number) => {
        const onClick = (): void => {
          onBadgeClick(badgeValue)
        }
        return (
          <Badge
            data-cy="adjudication-management-execute-badge"
            key={badgeValue}
            color="neutral"
            emphasis={typeof badgeValue === 'number' ? 'medium' : 'high'}
            size="small"
            text={String(badgeValue)}
            onClick={onClick}
          />
        )
      })}
    </PromptWrapper>
  )
}

const PromptWrapper = styled.div(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  }
})

export { AdjudicationPromptDefault }
