import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

type TextAlign = 'left' | 'right' | 'center'

const CellText = styled.p<{ align?: TextAlign }>(({ align = 'left' }) => ({
  fontSize: 12,
  lineHeight: '24px',
  color: COLOR.NEUTRAL[800],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: align,
  border: 0,
}))

const ColumnHead = styled.th<{
  isSelectable?: boolean
  columnWidth: number
  active: boolean
  textTransform?: 'uppercase'
  textAlign?: 'left' | 'center' | 'right'
}>(({ isSelectable, columnWidth = 100, active, textTransform, textAlign = 'left' }) => ({
  textAlign,
  textTransform,
  fontWeight: 'bold',
  cursor: isSelectable ? 'pointer' : 'initial',
  lineHeight: 4,
  width: `${columnWidth}%`,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: active ? COLOR.BLACK : COLOR.NEUTRAL[800],
}))

const TableRow = styled.tr({
  borderTop: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
  cursor: 'pointer',
  backgroundColor: 'transparent',
  transition: 'background-color .2s ease-in',
  '&:hover': {
    backgroundColor: COLOR.NEUTRAL[100],
  },
})

const TableBody = styled.tbody({})

const Table = styled.table({
  width: '100%',
  fontFamily: 'Nunito Sans, sans-serif',
  fontSize: 12,
  lineHeight: '16px',
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
})

const TableFoot = styled.tfoot({
  textAlign: 'left',
  paddingLeft: 24,
  fontWeight: 'bold',
  lineHeight: 4,
  width: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: COLOR.NEUTRAL[800],
})

export type { TextAlign }

export { CellText, ColumnHead, TableRow, TableBody, Table, TableFoot }
