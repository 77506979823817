import type { CellContext, ColumnDefs } from '@extend/zen'

const failureTypeMappingColumns: ColumnDefs<FailureTypesMappingDisplay> = [
  {
    label: 'Failure Type',
    id: 'failureType',
    renderCell: (cellData: CellContext<FailureTypesMappingDisplay, string>) => cellData.getValue(),
  },
  {
    label: 'Failure Cause',
    id: 'failureCause',
    renderCell: (cellData: CellContext<FailureTypesMappingDisplay, string>) => cellData.getValue(),
  },
  {
    label: 'Liable Party',
    id: 'liableParty',
    renderCell: (cellData: CellContext<FailureTypesMappingDisplay, boolean>) => cellData.getValue(),
  },
  {
    label: 'Covered',
    id: 'covered',
    renderCell: (cellData: CellContext<FailureTypesMappingDisplay, string>) => cellData.getValue(),
  },
]

export type FailureTypesMappingDisplay = {
  failureType: string
  failureCause?: string
  liableParty: string
  covered: string
}

export { failureTypeMappingColumns }
