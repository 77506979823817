import React, { useState } from 'react'
import type { FC } from 'react'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import styled from '@emotion/styled'
import type { RepairFulfillmentMethod } from '@helloextend/extend-api-rtk-query/src/service-orders/types'
import {
  Button,
  useToaster,
  ToastColor,
  ToastDuration,
  Stack,
  RowOptionGroup,
  RowOption,
} from '@extend/zen'
import { useDispatch } from 'react-redux'
import { useSelectRepairFulfillmentMethodMutation } from '@helloextend/extend-api-rtk-query'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { ClaimDetailsTabs } from '../tab-section/types'

interface SelectRepairFulfillmentContentProps {
  serviceOrder: ServiceOrder
}

export const SelectRepairFulfillmentContent: FC<SelectRepairFulfillmentContentProps> = ({
  serviceOrder,
}) => {
  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedOption(e.target.value as RepairFulfillmentMethod)
  }

  const [selectedOption, setSelectedOption] = useState<RepairFulfillmentMethod>('self_repair')
  const dispatch = useDispatch()
  const [setFulfillmentMethod] = useSelectRepairFulfillmentMethodMutation()
  const { toast } = useToaster()

  const handleConfirmFulfillmentMethodClick = async (): Promise<void> => {
    if (!serviceOrder) return
    try {
      const response = await setFulfillmentMethod({
        serviceOrderId: serviceOrder?.id,
        repairFulfillmentMethod: selectedOption,
      })
      if ((response as { error: { status: number; data: unknown } }).error) {
        toast({
          message: 'An error occurred',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
        return
      }
      dispatch(setClaimDetailsToast('Fulfillment option successfully selected!'))
      dispatch(setClaimDetailsActiveView(ClaimDetailsTabs.ServiceOrder))
    } catch (e) {
      toast({
        message: 'An error occurred',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  const rowOptions = [
    {
      'data-cy': 'self-repair-option',
      value: 'self_repair',
      label: 'Self-repair',
      description:
        'Select this option if you are sending the customer a part to be able to resolve the issue themselves.',
    },
    {
      'data-cy': 'cleaning-kit-option',
      value: 'cleaning_kit',
      label: 'Cleaning kit',
      description:
        'Select this option for stain claims when a customer will be receiving a cleaning kit in the mail.',
    },
    {
      'data-cy': 'carry-in-option',
      value: 'carry_in',
      label: 'Carry-in',
      description:
        'Select this option if the customer has opted to drop their product off at a location to be serviced, no shipping required.',
    },
    {
      'data-cy': 'repair-onsite-option',
      value: 'repair_onsite',
      label: 'Onsite Repair',
      description:
        "Select this option if a repair servicer will be dispatched to the customer's home to complete the repair",
    },
    {
      'data-cy': 'repair-depot-option',
      value: 'repair_depot',
      label: 'Depot Repair',
      description:
        'Select this option if the customer will need to mail in their product to be repaired',
    },
  ]

  return (
    <Container data-cy="repair-method-select-container">
      <Stack spacing={2} justify="start">
        <RowOptionGroup
          value={selectedOption}
          name="repair-fulfillment-method-option-group"
          onChange={handleOptionChange}
          data-cy="repair-fulfillment-method-option-group"
        >
          {rowOptions.map((props) => (
            <RowOption key={props.value} {...props} />
          ))}
        </RowOptionGroup>
      </Stack>
      <Footer>
        <Button
          data-cy="confirm-repair-fulfillment-method-options-button"
          text="Confirm Repair Fulfillment Method"
          emphasis="high"
          onClick={handleConfirmFulfillmentMethodClick}
        />
      </Footer>
    </Container>
  )
}

const Footer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
})

const Container = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  button: {
    display: 'block !important',
  },
  gap: 15,
})
