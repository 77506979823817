import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, AdvancedSelect } from '@extend/zen'
import type { AdvancedSelectOption, AdvancedSelectChangeEvent } from '@extend/zen'

type DropdownSelectlProps = {
  resetProducts: () => void
  setSelectedStore: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedMappingStatus: React.Dispatch<React.SetStateAction<string>>
  setSelectedPlanCategoryId: React.Dispatch<React.SetStateAction<string | undefined>>
  storeOptions?: AdvancedSelectOption[]
  mappingStatusOptions?: AdvancedSelectOption[]
  planCategoryIdOptions?: AdvancedSelectOption[]
  isDropdownSelectDataLoading: boolean
  selectedStore: string | undefined
  selectedMappingStatus: string | undefined
  selectedPlanCategoryId: string | undefined
}

const DropdownSelect: FC<DropdownSelectlProps> = ({
  resetProducts,
  setSelectedStore,
  setSelectedMappingStatus,
  setSelectedPlanCategoryId,
  storeOptions,
  mappingStatusOptions,
  planCategoryIdOptions,
  isDropdownSelectDataLoading,
  selectedStore,
  selectedMappingStatus,
  selectedPlanCategoryId,
}) => {
  // change handlers
  const handleOptionChangeStore = (event: AdvancedSelectChangeEvent): void => {
    setSelectedStore(event.target.value)
    resetProducts()
  }

  const handleOptionChangeMappingStatus = (event: AdvancedSelectChangeEvent): void => {
    setSelectedMappingStatus(event.target.value)
    resetProducts()
  }

  const handleOptionChangePlanCategoryId = (event: AdvancedSelectChangeEvent): void => {
    setSelectedPlanCategoryId(event.target.value)
    resetProducts()
  }

  return (
    <>
      <MerchantWrapper>
        <LabelSelect>
          <Label>Store Catalog</Label>
          <AdvancedSelect
            id="store-advanced-select"
            data-cy="store-advanced-select"
            isLoading={isDropdownSelectDataLoading}
            placeholder="Find a store"
            value={selectedStore ?? ''}
            options={storeOptions ?? []}
            onChange={handleOptionChangeStore}
            multiple={false}
            showSearch
            isNotClearable
          />
        </LabelSelect>
        <LabelSelect>
          <Label>Mapping Status</Label>
          <AdvancedSelect
            id="mapping-status-advanced-select"
            isLoading={isDropdownSelectDataLoading}
            options={mappingStatusOptions ?? []}
            value={selectedMappingStatus ?? ''}
            onChange={handleOptionChangeMappingStatus}
            multiple={false}
          />
        </LabelSelect>
        <LabelSelect>
          <Label>Plan Category</Label>
          <AdvancedSelect
            id="plan-category-id-advanced-select"
            isLoading={isDropdownSelectDataLoading}
            options={planCategoryIdOptions ?? []}
            value={selectedPlanCategoryId ?? ''}
            onChange={handleOptionChangePlanCategoryId}
            multiple={false}
            showSearch
          />
        </LabelSelect>
      </MerchantWrapper>
    </>
  )
}

const MerchantWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 64,
  alignItems: 'flex-end',
})

const LabelSelect = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})

const Label = styled.label({
  boxSizing: 'border-box',
  fontSize: 14,
  lineHeight: '19px',
  fontWeight: 700,
  paddingBottom: 8,
  color: COLOR.BLACK,
})

export { DropdownSelect }
