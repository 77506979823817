import type { FC } from 'react'
import React from 'react'
import type { AdvancedSelectChangeEvent, AdvancedSelectOption } from '@extend/zen'
import { AdvancedSelect } from '@extend/zen'
import { useListPlanSetsQuery } from '@helloextend/extend-api-rtk-query'
import type { PlanSet } from '@helloextend/extend-api-client'

export interface PlanSetSelectorProps {
  onChange: (e: AdvancedSelectChangeEvent<string>) => void
  planSetId: string
  categoryId: string
  isDisabled: boolean
  showSelector: boolean
}

const PlanSetSelector: FC<PlanSetSelectorProps> = ({
  onChange,
  planSetId,
  categoryId,
  isDisabled,
  showSelector,
}) => {
  const { data: planSets = [], isFetching = false } = useListPlanSetsQuery(`category=${categoryId}`)

  // Sort Plan Sets so isGTM sets are displayed first in list
  const planSetsCopy = [...planSets]
  const compareFn = (a: PlanSet, b: PlanSet): number => {
    if (a.isGTM > b.isGTM) {
      return -1
    }
    if (a.isGTM < b.isGTM) {
      return 1
    }
    return 0
  }

  if (!showSelector) return null

  return (
    <AdvancedSelect
      id="plan-set-select"
      data-cy="plan-set-select"
      label="Plan Set"
      placeholder="Select"
      onChange={onChange}
      value={planSetId}
      options={getPlanSetOptions(planSetsCopy.sort(compareFn))}
      multiple={false}
      isDisabled={isDisabled}
      isLoading={Boolean(categoryId && isFetching)}
    />
  )
}

export const getPlanSetOptions = (planSets: PlanSet[]): AdvancedSelectOption[] =>
  planSets.map((planSet) => ({
    display: planSet.isGTM ? `${planSet.name} (GTM)` : planSet.name ?? 'No name found!',
    value: planSet.id,
  }))

export { PlanSetSelector }
