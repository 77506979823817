import styled from '@emotion/styled'
import { HeadingLarge, TabSection, TabSections } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import { MSACurrentAmendment } from './msa-current-amendment'
import { MSADraft } from './msa-draft'
import { MSAAmendmentLog } from './msa-amendment-log'

const MSA: FC = () => {
  return (
    <Container>
      <HeadingLarge>Master Service Agreement</HeadingLarge>
      <TabSections data-cy="msa-tabs">
        <TabSection tabLabel="Current Amendment">
          <MSACurrentAmendment />
        </TabSection>
        <TabSection tabLabel="Draft">
          <MSADraft />
        </TabSection>
        <TabSection tabLabel="Amendment Log">
          <MSAAmendmentLog />
        </TabSection>
      </TabSections>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})

export { MSA }
