import { useToaster, ToastColor, ToastDuration } from '@extend/zen'
import type React from 'react'
import { useCallback } from 'react'

export const useCopyToClipboard = (): ((
  label: string,
  text: string,
  customMessage?: React.ReactNode,
  toastColor?: ToastColor,
) => void) => {
  const { toast } = useToaster()

  return useCallback(
    async (
      label: string,
      text: string,
      customMessage?: React.ReactNode,
      toastColor?: ToastColor,
    ): Promise<void> => {
      try {
        if (text) {
          await navigator.clipboard.writeText(text)
          toast({
            message: customMessage ?? `${label} copied to clipboard`,
            toastColor: toastColor || ToastColor.neutral,
            toastDuration: ToastDuration.short,
          })
        }
      } catch (e: unknown) {
        toast({
          message: `${label} failed to copy to clipboard`,
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      }
    },
    [toast],
  )
}
