import React from 'react'
import type { FC } from 'react'
import { ToastColor, ToastDuration, useToaster, Modal } from '@extend/zen'
import { useUpdateCharityMutation } from '@helloextend/extend-api-rtk-query'
import { CharitableDonationsForm } from '../forms/charitable-donations-form'
import type { SelectedCharity } from '../charitable-donations'
import type { CharitySchema } from '../forms/charitable-donations-form-schema'

type EditCharityModalProps = {
  onClose: () => void
  selectedCharity: SelectedCharity
}

export const EditCharityModal: FC<EditCharityModalProps> = ({ onClose, selectedCharity }) => {
  const { toast } = useToaster()
  const [updateCharity, { isLoading }] = useUpdateCharityMutation()
  const handleEditCharity = async (formValues: CharitySchema): Promise<void> => {
    try {
      await updateCharity({
        charityId: selectedCharity.id,
        data: formValues,
      }).unwrap()
      toast({
        message: 'Charity has been successfully updated',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    } catch (error) {
      toast({
        message: 'Error updating charity. Please try again.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    } finally {
      onClose()
    }
  }
  return (
    <Modal data-cy="edit-charity-modal" heading="Edit Charity" onDismissRequest={onClose} size="md">
      <CharitableDonationsForm
        isLoading={isLoading}
        charity={selectedCharity}
        onClose={onClose}
        onSubmit={handleEditCharity}
      />
    </Modal>
  )
}
