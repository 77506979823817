import type { CurrencyCode, DataPropertyType, InputType } from '@extend/zen'

export enum AdditionalDataPropertyTypes {
  badge = 'badge',
  url = 'url',
}

type CompactFormFieldDataTypes = DataPropertyType | AdditionalDataPropertyTypes

export interface CompactFormFieldData {
  id: string
  label: string
  inputType: InputType
  dataPropertyType: CompactFormFieldDataTypes
  value: string | number | null
  isEditable: boolean
  currencyCode?: CurrencyCode
  isDisabled?: boolean
  urlText?: string
}
