import type {
  ClaimNoteType,
  EscalationTier,
} from '@extend-services/claims-management/dist/src/models/claim-note'
import * as Yup from 'yup'

export const NOTETEXT_MAX = 3000
export const escalationSchema = Yup.object()
  .shape({
    type: Yup.string<ClaimNoteType>().default('escalation').required(),
    text: Yup.string().default('').required('Required'),
    escalationLevel: Yup.string<EscalationTier>().default('tier1').required(),
  })
  .defined()
export type EscalationSchemaValues = Yup.InferType<typeof escalationSchema>
