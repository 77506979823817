import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import type { Claim, ClaimNote } from '@helloextend/extend-api-client'
import styled from '@emotion/styled'
import { COLOR, Notes } from '@extend/zen'
import type { EscalationClaimNote } from '@extend-services/claims-management/dist/src/models/claim-note'
import { OverlayButton } from '../../../../../../components/overlay-button'
import {
  BasicModal,
  ModalPosition,
  ModalBackground,
} from '../../../../../../components/basic-modal'
import { ClaimNotesForm } from './claim-notes-form'
import { ClaimNotesEntry } from './claim-notes-entry'

interface ClaimNotesProps {
  claim: Claim
  claimNotes: ClaimNote[]
}

const ClaimNotes: FC<ClaimNotesProps> = ({ claim, claimNotes }) => {
  const [claimFormIsVisible, setClaimFormIsVisible] = useState(false)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = (): void => setWindowHeight(window.innerHeight)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  if (!claimFormIsVisible) {
    return (
      <OverlayButton
        data-cy="claim-notes-button"
        text={claimNotes.length ? `Claim Notes (${claimNotes.length})` : `Claim Notes`}
        size="large"
        icon={Notes}
        onClick={() => {
          setClaimFormIsVisible(true)
        }}
      />
    )
  }
  return (
    <BasicModal
      isVisible={claimFormIsVisible}
      onClickClose={() => {
        setClaimFormIsVisible(false)
      }}
      width="574px"
      preventKeyboardNavigation
      data-cy="claim-notes"
      maxHeight={`${Math.min(windowHeight - 150, 650)}px`}
      modalPosition={ModalPosition.bottomRight}
      modalBackground={ModalBackground.boxShadow}
      excludeBackground
    >
      <Title data-cy="claim-notes-title">{`${claim.customer?.name}'s Claim Notes`}</Title>
      <ClaimNotesForm
        claimId={claim.id}
        hasEscalation={claimNotes.some(
          (cn) => cn.type === 'escalation' && !(cn as EscalationClaimNote).isResolved,
        )}
      />
      <ClaimNotesCollection>
        {claimNotes?.map((note) => (
          <ClaimNotesEntry claimNote={note} key={note.id} />
        ))}
      </ClaimNotesCollection>
    </BasicModal>
  )
}

const ClaimNotesCollection = styled.div({
  marginTop: 8,
})

const Title = styled.h3({
  color: COLOR.BLACK,
  fontSize: 20,
  marginBottom: 16,
  marginTop: 0,
})

export { ClaimNotes }
