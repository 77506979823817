import type { FC, ChangeEvent } from 'react'
import React from 'react'
import { COLOR } from '@extend/zen'
import styled from '@emotion/styled'

interface TextAreaProps {
  dataCy?: string
  disabled?: boolean
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  id?: string
  maxLength?: number
  name?: string
  placeholder?: string
  required?: boolean
  resize?: 'none' | 'both' | 'horizontal' | 'vertical'
  value: string
  width?: number // used as a percentage value
  height?: number // px value
  hasError?: boolean
  errorMessage?: string
}

/**
 * @deprecated Use Zen TextArea component instead: `import { TextArea } from '@extend/zen'`
 */
const TextArea: FC<TextAreaProps> = ({
  dataCy,
  disabled = false,
  onChange,
  onBlur,
  id,
  maxLength,
  name,
  placeholder = '',
  required = false,
  value,
  width,
  height,
  hasError = false,
  errorMessage,
}) => {
  const remainingChars = maxLength ? `"${maxLength - value.length}"` : "''"

  return (
    <>
      <StyledTextArea remainingChars={remainingChars} width={width} height={height}>
        <textarea
          data-cy={dataCy}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          required={required}
          value={value}
        />
      </StyledTextArea>
      {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  )
}

interface StyledTextAreaProps {
  remainingChars: string
  width?: number
  height?: number
}

const StyledTextArea = styled.div<StyledTextAreaProps>(({ remainingChars, width, height }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  display: 'inline-flex',
  width: width ? `${width}%` : 'inherit',
  height: height ? `${height}px` : 'inherit',
  textarea: {
    position: 'relative',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '12px',
    fontSize: '14px',
    fontFamily: 'Nunito Sans, sans-serif',
    transition: 'border .25s ease-in-out',
    width: 'inherit',
    ':invalid': {
      border: `1px solid ${COLOR.RED[700]}`,
    },
    ':focus': {
      outline: 'none',
      border: `1px solid ${COLOR.BLUE[700]}`,
    },
    '::placeholder': {
      color: COLOR.NEUTRAL[400],
    },
    '&:disabled': {
      borderColor: COLOR.NEUTRAL[300],
      background: COLOR.NEUTRAL[100],
    },
  },
  '::after': {
    content: remainingChars,
    display: 'inline-table',
    position: 'absolute',
    width: 50,
    textAlign: 'end',
    bottom: -24,
    right: 0,
    color: COLOR.NEUTRAL[400],
    fontSize: '12px',
    height: 16,
  },
}))

const ErrorMessage = styled.span({
  color: COLOR.RED[600],
  fontSize: 12,
})

export { TextArea }
