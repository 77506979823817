import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { useGetServicerQuery, useUpdateServicerMutation } from '@helloextend/extend-api-rtk-query'
import {
  Button,
  COLOR,
  Icon,
  IconSize,
  Input,
  OpenInNew,
  Switch,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { schema } from './schema'
import type { Values } from './schema'

const IntegrationsTab: FC = () => {
  const { servicerId } = useParams<{ servicerId: string }>()
  const { data: servicer, isFetching: isFetchingServicer } = useGetServicerQuery(servicerId)
  const [updateServicer, { isLoading, isSuccess, isError }] = useUpdateServicerMutation()

  const { toast } = useToaster()
  const initialValues = {
    webhookUrl: servicer?.settings?.webhookConfigurations?.url ?? '',
  }
  const { values, setFieldValue, submitForm, isValid } = useFormik<Values>({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    validateOnMount: true,
    onSubmit: () => {
      updateServicer({
        servicerId,
        body: {
          settings: {
            webhookConfigurations: {
              url: values.webhookUrl,
              // The submit button will be disabled if the servicer is still fetching so we can assume this field will always be defined
              sendAssignedWebhook:
                servicer?.settings?.webhookConfigurations?.sendAssignedWebhook || false,
            },
          },
        },
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: 'Changes saved!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    }
    if (isError) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [isSuccess, isError, toast])

  const isDisabled = !isValid || isLoading || isFetchingServicer

  return (
    <ContentWrapper data-cy="payment-tab">
      <Header>
        <Title>Webhooks</Title>
      </Header>
      <ViewInstructionsContainer>
        <SectionHeader>Connect servicer system with Extend, saving your webhook URL.</SectionHeader>
        <ViewInstructionsLink href="https://docs.extend.com/reference/service-order-servicer-webhook">
          View instructions
        </ViewInstructionsLink>
        <Icon icon={OpenInNew} size={IconSize.small} color={COLOR.BLUE[700]} />
      </ViewInstructionsContainer>
      <WebhookURLContainer>
        <InputWrapper>
          <Input
            value={values.webhookUrl}
            id="webhookUrl"
            label="Webhook URL"
            onChange={(e) => setFieldValue('webhookUrl', e.target.value)}
            data-cy="webhook-url-input"
          />
        </InputWrapper>
        <SaveButtonWrapper>
          <Button
            data-cy="save-button"
            isDisabled={isDisabled}
            text="Save"
            type="submit"
            onClick={() => submitForm()}
            isProcessing={isLoading}
          />
        </SaveButtonWrapper>
      </WebhookURLContainer>
      <EventsHeading>EVENTS</EventsHeading>
      <Switch
        label="Service Order Assigned"
        isOn={servicer?.settings?.webhookConfigurations?.sendAssignedWebhook || false}
        isDisabled={isLoading || isFetchingServicer}
        onChange={() =>
          updateServicer({
            servicerId,
            body: {
              settings: {
                webhookConfigurations: {
                  // The toggle will be disabled if the servicer is still fetching so we can assume this field will always be defined
                  url: servicer?.settings?.webhookConfigurations?.url || '',
                  sendAssignedWebhook:
                    !servicer?.settings?.webhookConfigurations?.sendAssignedWebhook,
                },
              },
            },
          })
        }
        data-cy="service-order-assigned-switch"
      />
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  marginBottom: 32,
  padding: 40,
  borderRadius: 4,
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  margin: 0,
})

const SectionHeader = styled.p({
  margin: '24px 0px',
  fontSize: 16,
  lineHeight: '20px',
  marginRight: 12,
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

const InputWrapper = styled.div({
  display: 'flex',
  flexGrow: 1,
  div: {
    width: '100%',
  },
})

const SaveButtonWrapper = styled.div({
  display: 'flex',
  alignSelf: 'flex-end',
})

const WebhookURLContainer = styled.div({
  display: 'flex',
  gap: 16,
  marginTop: 16,
  width: '100%',
})

const EventsHeading = styled.div({
  color: COLOR.BLACK[0],
  fontFamily: 'Nunito Sans',
  fontSize: 14,
  fontWeight: 700,
  lineHeight: '18px',
  marginTop: 16,
  marginBottom: 16,
})

const ViewInstructionsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
})

const ViewInstructionsLink = styled.a({
  textDecoration: 'none',
  color: COLOR.BLUE[700],
})

export { IntegrationsTab }
