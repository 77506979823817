import type { FC } from 'react'
import React from 'react'
import { TabSections, TabSection } from '@extend/zen'
import type { LineItemGetResponse } from '@helloextend/extend-api-rtk-query'
import { AuditLog } from '../audit-log'
import { RelatedLineItemsTable, ShipmentDetailsForm, LineItemDetailsForm } from '../sections'

const LineItemPageTabBar: FC<{ lineItem: LineItemGetResponse; orderId: string }> = ({
  lineItem,
  orderId,
}) => {
  const hasRelatedLineItems = 'lineItemIds' in lineItem
  const hasShipments = 'shipmentInfo' in lineItem

  return (
    <TabSections data-cy="line-item-page-tab-bar">
      <TabSection tabLabel="Line Item Details">
        <LineItemDetailsForm lineItem={lineItem} />
        {hasShipments && <ShipmentDetailsForm shipments={lineItem.shipmentInfo || []} />}
        {hasRelatedLineItems && (
          <RelatedLineItemsTable relatedLineItems={lineItem.lineItemIds || []} orderId={orderId} />
        )}
      </TabSection>
      <TabSection tabLabel="Audit Log">
        <AuditLog lineItem={lineItem} />
      </TabSection>
    </TabSections>
  )
}

export { LineItemPageTabBar }
