import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorStatus } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'

export default function* update(action: ReturnType<typeof actions.update>): SagaIterator {
  const { id, data, accessToken } = action.payload

  yield put(actions.updateRequest())

  const response = yield call(client.stores.update, id, data, accessToken)

  if (isErrorStatus(response.status)) {
    yield put(actions.updateFailure(response.data.message, response.status))
    return
  }

  yield put(actions.updateSuccess(response.data))
}
