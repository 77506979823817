import type { CsvUploadValidatorPipe } from '../merchants/stores/utils/csv-validation-models'

export const CategoryMappingsHeaderCsv = [
  'merchantCategory',
  'mappingStatus',
  'extendCategory',
  'toDelete',
] as const

export type CategoryMappingsHeaderCSV = typeof CategoryMappingsHeaderCsv[number]

export type CategoryMappingValidatorPipe = CsvUploadValidatorPipe<CategoryMappingsHeaderCSV>
