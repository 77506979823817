import React from 'react'
import { CellText } from '@helloextend/merchants-ui'
import type { BadgeColor, ColumnDef, CellContext } from '@extend/zen'
import { Badge } from '@extend/zen'
import { startCase } from 'lodash'
import type { TableServicersSearch } from '../../../types/servicers'

const servicerColumns: Array<ColumnDef<TableServicersSearch>> = [
  {
    label: 'Servicer Name',
    id: 'servicerName',
    search: 'explicit',
    renderCell: (cellData: CellContext<TableServicersSearch, string>) => (
      <CellText title={cellData.getValue()}>{cellData.getValue()}</CellText>
    ),
    isSortable: true,
  },

  {
    label: 'Status',
    id: 'status',
    renderCell: ({ row }: CellContext<TableServicersSearch, 'active' | 'inactive'>) => {
      const data = row.original
      if (!data.status) return <CellText title="blank" />
      let badgeColor: BadgeColor
      switch (data.status) {
        case 'active':
          badgeColor = 'green'
          break
        case 'inactive':
          badgeColor = 'yellow'
          break
        default:
          badgeColor = 'red'
      }
      return <Badge color={badgeColor} emphasis="medium" text={startCase(data.status)} />
    },
  },
  {
    label: 'Email',
    id: 'email',
    renderCell: (cellData: CellContext<TableServicersSearch, string>) => (
      <CellText title={cellData.getValue()}>{cellData.getValue()}</CellText>
    ),
  },
  {
    label: 'Phone',
    id: 'phone',
    renderCell: (cellData: CellContext<TableServicersSearch, string>) => (
      <CellText title={cellData.getValue()}>{cellData.getValue()}</CellText>
    ),
  },
]

export { servicerColumns }
