import type { FC } from 'react'
import React, { useState } from 'react'
import { Grid } from '@extend/zen'
import type { LineItemGetResponse } from '@helloextend/extend-api-rtk-query'
import { CompactForm, CompactFormSection } from '../../../../../../components/compact-form'
import type { LineItemDetailsFormFields } from './field'
import { lineItemDetailsFormFields } from './field'

const LineItemDetailsForm: FC<{ lineItem: LineItemGetResponse }> = ({ lineItem }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const [isEditing, setIsEditing] = useState(false)

  const handleClickEdit = (): void => {
    setIsExpanded(true)
    setIsEditing(!isEditing)
  }

  const toggleIsExpanded = (): void => {
    setIsExpanded(!isExpanded)
  }

  const fields = lineItemDetailsFormFields(lineItem)

  return (
    <CompactForm
      dataCy="line-item-details-section"
      handleClickEdit={handleClickEdit}
      isSubmitDisabled={false}
      isEditable={false}
      isEditing={isEditing}
      isSubmitting={false}
      isExpanded={isExpanded}
      onToggleRequest={toggleIsExpanded}
      shouldStartExpanded
      heading="Line Item Details"
    >
      <CompactFormSections
        dataCy="line-item-details-compact-forms"
        fields={fields}
        isEditing={isEditing}
      />
    </CompactForm>
  )
}

const CompactFormSections: FC<{
  fields: LineItemDetailsFormFields
  isEditing: boolean
  dataCy?: string
}> = ({ fields, isEditing, dataCy }) => {
  return (
    <Grid columns={3} spacing={8} data-cy={dataCy}>
      {Object.keys(fields).map((subSectionTitle) => (
        <CompactFormSection
          key={subSectionTitle}
          subsectionTitle={subSectionTitle}
          isEditing={isEditing}
          fields={fields[subSectionTitle as keyof typeof fields] || []}
        />
      ))}
    </Grid>
  )
}

export { LineItemDetailsForm }
