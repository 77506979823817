import type { ChangeEvent, FC } from 'react'
import React, { useMemo } from 'react'
import { Add, Button, Close, TextArea } from '@extend/zen'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import {
  addReplyMessage,
  removeReplyMessage,
  updateReplyMessage,
} from '../../../../../store/slices/amp-slice'

interface KaleyTextMessageItemProps {
  message: string
  index: number
  isRemoveDisabled: boolean
  isThreadStructureLocked: boolean
  addKey: (index: number) => void
  removeKey: (index: number) => void
  isHidden: boolean
}

const KaleyTextMessageItem: FC<KaleyTextMessageItemProps> = ({
  message,
  index,
  isRemoveDisabled,
  isThreadStructureLocked,
  addKey,
  removeKey,
  isHidden,
}) => {
  const dispatch = useDispatch()

  const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    dispatch(updateReplyMessage({ index, content: e.target.value }))
  }

  const onMessageAdd = (_index: number): void => {
    dispatch(addReplyMessage(_index))
    addKey(_index + 1)
  }

  const onMessageRemove = (_index: number): void => {
    dispatch(removeReplyMessage(_index))
    removeKey(_index)
  }

  const isValid = useMemo(() => {
    return message.trim().length > 0
  }, [message])

  return (
    <Container data-cy={`kaley-message-item-container-${index}`} isHidden={isHidden}>
      <TextAreaContainer>
        <TextArea
          id="kaleyMessageTextArea"
          data-cy="kaley-message-text-area"
          value={message}
          aria-label="Add text"
          onChange={handleMessageChange}
          placeholder="Add text"
          isResizable
          isError={!isValid}
          errorFeedback="Text is required"
          rows={1}
        />
      </TextAreaContainer>
      <ButtonContainer>
        <Button
          color="neutral"
          data-cy="kaley-message-item-add-row-button"
          emphasis="low"
          icon={Add}
          iconPosition="left"
          onClick={() => {
            onMessageAdd(index)
          }}
          size="regular"
          tooltip="Add text bubble"
          type="button"
          isDisabled={isThreadStructureLocked}
        />
        <Button
          color="neutral"
          data-cy="kaley-message-item-remove-row-button"
          emphasis="low"
          icon={Close}
          iconPosition="right"
          onClick={() => {
            onMessageRemove(index)
          }}
          size="regular"
          tooltip="Remove text bubble"
          type="button"
          isDisabled={isRemoveDisabled || isThreadStructureLocked}
        />
      </ButtonContainer>
    </Container>
  )
}

export const TextAreaContainer = styled.div({
  width: 360,
})

export const Container = styled.div<{ isHidden: boolean }>(({ isHidden }) => ({
  display: isHidden ? 'none' : 'flex',
  justifyContent: 'space-between',
}))

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 8,
})

export type { KaleyTextMessageItemProps }
export { KaleyTextMessageItem }
