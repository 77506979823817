import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.fetch>

export default function* fetch(action: Action): SagaIterator {
  const { contractId, claimId, accessToken } = action.payload

  yield put(actions.fetchRequest())

  try {
    const response = yield call(client.claimsPhotos.fetch, contractId, claimId, accessToken)

    if (isErrorResponse(response)) {
      yield put(actions.fetchFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.fetchFailure(`Response from API is missing`, response.status))
      return
    }

    yield put(actions.fetchSuccess(claimId, response.data.photoDetails))
  } catch (error) {
    if (error instanceof Error) {
      yield put(actions.fetchFailure(error.message))
    }
  }
}
