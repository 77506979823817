import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import type { FormikErrors, FormikHandlers, FormikHelpers } from 'formik'
import { date as dateHelper } from '@extend/client-helpers'
import {
  useListInsuranceProgramsQuery,
  useListPlanAttributesQuery,
} from '@helloextend/extend-api-rtk-query'
import {
  AdvancedSelect,
  Box,
  CurrencyInput,
  Grid,
  GridItem,
  HeadingLarge,
  InlineAlert,
  InlineAlertColor,
  Input,
  InputType,
  Label,
  Paragraph,
  Stack,
  Warning,
} from '@extend/zen'
import type { Sku as SkuModel } from '@helloextend/extend-api-rtk-query'
import type { Values } from './form-schema'
import { obligorOptionsList } from '../../../../../utils/obligor-options-list'
import { DatePicker } from '@helloextend/merchants-ui'

type SkuFormProps = {
  values: Values
  errors: FormikErrors<Values>
  handleChange: FormikHandlers['handleChange']
  handleBlur: FormikHandlers['handleBlur']
  setFieldValue: FormikHelpers<Values>['setFieldValue']
  setErrors: FormikHelpers<Values>['setErrors']
  isDisabled?: boolean
  activeVersion?: SkuModel
}

const PremiumForm: FC<SkuFormProps> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setErrors,
  isDisabled = false,
  activeVersion,
}) => {
  const [warning, setWarning] = useState<string | undefined>()
  const { data: planAttributes, isLoading: isLoadingPlanAttributes } = useListPlanAttributesQuery()
  const { data: insuranceProgramIds, isLoading: isLoadingInsurancePrograms } =
    useListInsuranceProgramsQuery()

  // workaround for formik because date picker doesn't pass regular synthetic event, it passes a date object instead
  const handleDateChange = (name: string, date: Date | null): void => {
    const transformedDate = date ? dateHelper.formatToMilliseconds(date.toDateString()) : null
    if (
      name === 'activeFrom' &&
      activeVersion?.activeTo &&
      transformedDate &&
      transformedDate > activeVersion?.activeTo
    ) {
      const formatActiveTo = new Date(activeVersion?.activeTo)
      setWarning(
        `WARNING! There is a gap between unsaved "Active From" date: ${date?.toDateString()} and the active versions "Active To" date: ${formatActiveTo.toDateString()}.`,
      )
    } else {
      setWarning(undefined)
    }
    setFieldValue(name, transformedDate)
    setErrors({ ...errors })
  }

  const programsOptions = useMemo(() => {
    if (!planAttributes) return []
    return (
      planAttributes.items
        .find((item) => item.id === 'program')
        ?.values.map((value) => ({
          value,
          display: value,
        })) ?? []
    )
  }, [planAttributes])

  const subProgramsOptions = useMemo(() => {
    if (!planAttributes) return []
    return (
      planAttributes.items
        .find((item) => item.id === 'sub_program')
        ?.values.map((value) => ({
          value,
          display: value,
        })) ?? []
    )
  }, [planAttributes])

  const insuranceProgramsOptions = useMemo(() => {
    if (!insuranceProgramIds) return []
    return insuranceProgramIds.items.map((item) => ({
      value: item.id,
      display: item.id,
    }))
  }, [insuranceProgramIds])

  return (
    <Stack data-cy="premiums-form" spacing={2}>
      <Box padding={4}>
        <Stack spacing={2}>
          <HeadingLarge>Basic Info</HeadingLarge>
          <Grid columns={2} spacing={4}>
            <AdvancedSelect
              id="lineOfBusiness"
              label="Line of Business"
              placeholder="Select"
              options={[
                {
                  display: 'Service Contract',
                  value: 'serviceContract',
                },
              ]}
              value={values.lineOfBusiness}
              onChange={handleChange}
              isError={Boolean(errors.lineOfBusiness)}
              errorFeedback={errors.lineOfBusiness}
              multiple={false}
              isDisabled={isDisabled}
              onBlur={handleBlur}
            />
            <AdvancedSelect
              id="isActive"
              label="Active"
              placeholder="Select"
              options={[
                {
                  display: 'Yes',
                  value: 'yes',
                },
                {
                  display: 'No',
                  value: 'no',
                },
              ]}
              value={values.isActive}
              onChange={handleChange}
              isError={Boolean(errors.isActive)}
              errorFeedback={errors.isActive}
              multiple={false}
              isDisabled={isDisabled}
              onBlur={handleBlur}
              data-cy="is-active-select"
            />
          </Grid>
        </Stack>
      </Box>
      <Box padding={4}>
        <Stack spacing={2}>
          <HeadingLarge>Premium Details</HeadingLarge>
          <Grid columns={2} spacing={4}>
            <Input
              id="id"
              value={values.id}
              label="SKU ID"
              isError={Boolean(errors.id)}
              errorFeedback={errors.id}
              isDisabled
              onBlur={handleBlur}
            />
            <Input
              id="name"
              value={values.name}
              label="SKU Name"
              onChange={handleChange}
              isError={Boolean(errors.name)}
              errorFeedback={errors.name}
              isDisabled={isDisabled}
              onBlur={handleBlur}
            />
            <Stack>
              <Label htmlFor="activeFrom">Date active from</Label>
              <DatePicker
                id={'activeFrom'}
                onChange={(value) => handleDateChange('activeFrom', value)}
                selected={new Date(values.activeFrom)}
                errorFeedback={errors.activeFrom}
                disabled={isDisabled}
                fullWidth
              />
            </Stack>
            <Stack>
              <Label htmlFor="activeTo">Date active to</Label>
              <DatePicker
                id={'activeTo'}
                onChange={(value) => handleDateChange('activeTo', value)}
                selected={values.activeTo ? new Date(values.activeTo) : null}
                errorFeedback={errors.activeTo}
                disabled={isDisabled}
                fullWidth
              />
            </Stack>
            {warning && (
              <GridItem span={2}>
                <InlineAlert color={InlineAlertColor.yellow} icon={Warning}>
                  <Paragraph>{warning}</Paragraph>
                </InlineAlert>
              </GridItem>
            )}
            <AdvancedSelect
              id="program"
              label="Program"
              placeholder="Select"
              options={programsOptions}
              value={values.program}
              onChange={handleChange}
              isError={Boolean(errors.program)}
              errorFeedback={errors.program}
              multiple={false}
              isDisabled={isDisabled}
              isLoading={isLoadingPlanAttributes}
              onBlur={handleBlur}
            />
            <AdvancedSelect
              id="subProgram"
              label="Sub Program"
              placeholder="select"
              options={subProgramsOptions}
              value={values.subprogram}
              onChange={handleChange}
              isError={Boolean(errors.subprogram)}
              errorFeedback={errors.subprogram}
              multiple={false}
              isDisabled={isDisabled}
              isLoading={isLoadingPlanAttributes}
            />
            <AdvancedSelect
              id="obligor"
              label="Obligor"
              placeholder="select"
              options={
                obligorOptionsList.map((x) => {
                  return {
                    value: x.value,
                    display: x.label,
                  }
                }) ?? []
              }
              value={values.obligor}
              onChange={handleChange}
              isError={Boolean(errors.obligor)}
              errorFeedback={errors.obligor}
              multiple={false}
              isDisabled={isDisabled}
            />
            <AdvancedSelect
              id="insuranceProgramId"
              label="Insurance Program Id"
              placeholder="select"
              options={insuranceProgramsOptions}
              value={values.insuranceProgramId}
              onChange={handleChange}
              isError={Boolean(errors.insuranceProgramId)}
              errorFeedback={errors.insuranceProgramId}
              multiple={false}
              isDisabled={isDisabled}
              isLoading={isLoadingInsurancePrograms}
            />
            <AdvancedSelect
              id="coverageType"
              label="Coverage Type"
              placeholder="select"
              options={[
                { value: 'base', display: 'Base' },
                { value: 'adh', display: 'ADH' },
              ]}
              value={values.insuranceProgramId}
              onChange={handleChange}
              isError={Boolean(errors.insuranceProgramId)}
              errorFeedback={errors.insuranceProgramId}
              multiple={false}
              isDisabled={isDisabled}
            />
            <Input
              id="term"
              label="Term Length"
              type={InputType.number}
              value={values.term?.toString() ?? ''}
              onChange={handleChange}
              isError={Boolean(errors.term)}
              errorFeedback={errors.term}
              isDisabled={isDisabled}
            />
            <AdvancedSelect
              id="serviceType"
              label="Service Type"
              placeholder="select"
              options={[
                { value: 'repair', display: 'Repair' },
                { value: 'repairOnsite', display: 'Repair Onsite' },
                { value: 'repairIrobot', display: 'Repair IRobot' },
                { value: 'repairDepot', display: 'Repair Depot' },
                { value: 'replace', display: 'Replace' },
                { value: 'partialReplace', display: 'Partial Replace' },
              ]}
              value={values.serviceType}
              onChange={handleChange}
              isError={Boolean(errors.serviceType)}
              errorFeedback={errors.serviceType}
              multiple={false}
              isDisabled={isDisabled}
            />
            <AdvancedSelect
              id="productCondition"
              label="Product Condition"
              placeholder="select"
              options={[
                { value: 'new', display: 'New' },
                { value: 'used', display: 'Used' },
                { value: 'refurbished', display: 'Refurbished' },
                { value: 'other', display: 'Other' },
                { value: 'all', display: 'All' },
              ]}
              value={values.productCondition}
              onChange={handleChange}
              isError={Boolean(errors.productCondition)}
              errorFeedback={errors.productCondition}
              multiple={false}
              isDisabled={isDisabled}
            />
            <AdvancedSelect
              id="purchasePriceOfProduct"
              label="Purchase Price of Product"
              placeholder="select"
              options={[
                { value: 'item', display: 'Item' },
                { value: 'cart', display: 'Cart' },
                { value: 'bundle', display: 'Bundle' },
                { value: 'membership', display: 'Membership' },
              ]}
              value={values.purchasePriceOfProduct}
              onChange={handleChange}
              isError={Boolean(errors.purchasePriceOfProduct)}
              errorFeedback={errors.purchasePriceOfProduct}
              multiple={false}
              isDisabled={isDisabled}
            />
            <AdvancedSelect
              id="limitOfLiabilityBasedUpon"
              label="Limit of Liability Based Upon"
              placeholder="select"
              options={[
                { value: 'product', display: 'Product' },
                { value: 'contractLimit', display: 'Contract Limit' },
              ]}
              value={values.limitOfLiabilityBasedUpon}
              onChange={handleChange}
              isError={Boolean(errors.limitOfLiabilityBasedUpon)}
              errorFeedback={errors.limitOfLiabilityBasedUpon}
              multiple={false}
              isDisabled={isDisabled}
            />
            <AdvancedSelect
              id="paymentModel"
              label="Payment Model"
              placeholder="select"
              options={[
                { value: 'single', display: 'Single' },
                { value: 'monthly', display: 'Monthly' },
                { value: 'quarterly', display: 'Quarterly' },
              ]}
              value={values.paymentModel}
              onChange={handleChange}
              isError={Boolean(errors.paymentModel)}
              errorFeedback={errors.paymentModel}
              multiple={false}
              isDisabled={isDisabled}
            />
            <AdvancedSelect
              id="cancellation"
              label="Cancellation"
              placeholder="select"
              options={[{ value: 'TBD', display: 'TBD' }]}
              value={values.cancellation ?? ''}
              onChange={handleChange}
              isError={Boolean(errors.cancellation)}
              errorFeedback={errors.cancellation}
              multiple={false}
              isDisabled={isDisabled}
            />
            <Input
              id="frequency"
              label="Frequency"
              value={values.frequency?.toString() ?? ''}
              onChange={handleChange}
              isError={Boolean(errors.frequency)}
              errorFeedback={errors.frequency}
              isDisabled={isDisabled}
            />
            <Input
              id="severity"
              label="Severity"
              value={values.severity?.toString() ?? ''}
              onChange={handleChange}
              isError={Boolean(errors.severity)}
              errorFeedback={errors.severity}
              isDisabled={isDisabled}
            />
            <Input
              id="source"
              label="Source"
              value={values.source}
              onChange={handleChange}
              isError={Boolean(errors.source)}
              errorFeedback={errors.source}
              isDisabled={isDisabled}
            />
            <AdvancedSelect
              id="isDeductible"
              label="Deductible"
              placeholder="select"
              options={[
                { value: 'no', display: 'No' },
                { value: 'yes', display: 'Yes' },
              ]}
              value={values.isDeductible}
              onChange={handleChange}
              isError={Boolean(errors.isDeductible)}
              errorFeedback={errors.isDeductible}
              multiple={false}
              isDisabled={isDisabled}
            />
            {values.isDeductible === 'yes' && (
              <Input
                id="deductibleAmount"
                label="Deductible Amount"
                type={InputType.number}
                value={values.deductibleAmount?.toString() ?? ''}
                onChange={handleChange}
                isError={Boolean(errors.deductibleAmount)}
                errorFeedback={errors.deductibleAmount}
                isDisabled={isDisabled}
              />
            )}
          </Grid>
        </Stack>
      </Box>
      <Box padding={4}>
        <Stack>
          <HeadingLarge>Geographical Details</HeadingLarge>
          <Grid columns={2} spacing={4}>
            <AdvancedSelect
              id="currencyCode"
              label="Currency"
              placeholder="Select"
              options={[
                { value: 'CAD', display: 'Canadian Dollar' },
                { value: 'USD', display: 'United States Dollar' },
              ]}
              value={values.currencyCode}
              onChange={handleChange}
              isError={Boolean(errors.currencyCode)}
              errorFeedback={errors.currencyCode}
              multiple={false}
              isDisabled={isDisabled}
            />
          </Grid>
        </Stack>
      </Box>
      <Box padding={4}>
        <Stack>
          <HeadingLarge>Rates</HeadingLarge>
          <Grid columns={2} spacing={4}>
            <CurrencyInput
              id="priceBandLow"
              label="Price Band Low"
              currency="USD"
              value={values.priceBandLow?.toString()}
              onChange={handleChange}
              isError={Boolean(errors.priceBandLow)}
              errorFeedback={errors.priceBandLow}
              isDisabled={isDisabled}
            />
            <CurrencyInput
              id="priceBandHigh"
              label="Price Band High"
              currency="USD"
              value={values.priceBandHigh?.toString()}
              onChange={handleChange}
              isError={Boolean(errors.priceBandHigh)}
              errorFeedback={errors.priceBandHigh}
              isDisabled={isDisabled}
            />
            <GridItem span={2}>
              <CurrencyInput
                id="lossCost"
                label="Loss Cost"
                currency="USD"
                value={values.lossCost?.toString()}
                onChange={handleChange}
                isError={Boolean(errors.lossCost)}
                errorFeedback={errors.lossCost}
                isDisabled={isDisabled}
              />
            </GridItem>
            <Input
              id="targetLossRatio"
              label="Target Loss Ratio"
              type={InputType.number}
              value={values.targetLossRatio?.toString() ?? ''}
              onChange={handleChange}
              isError={Boolean(errors.targetLossRatio)}
              errorFeedback={errors.targetLossRatio}
              isDisabled={isDisabled}
              suffix="%"
            />
            <CurrencyInput
              id="lossReserve"
              label="Loss Reserve"
              currency="USD"
              value={values.lossReserve?.toString()}
              onChange={handleChange}
              isError={Boolean(errors.lossReserve)}
              errorFeedback={errors.lossReserve}
              isDisabled={isDisabled}
            />
            <Input
              id="rateFeePercentage"
              label="Rate Fee Percentage"
              type={InputType.number}
              value={values.rateFeePercentage?.toString() ?? ''}
              onChange={handleChange}
              isError={Boolean(errors.rateFeePercentage)}
              errorFeedback={errors.rateFeePercentage}
              isDisabled={isDisabled}
              suffix="%"
            />
            <CurrencyInput
              id="rateFee"
              label="Rate Fee"
              currency="USD"
              value={values.rateFee?.toString()}
              onChange={handleChange}
              isError={Boolean(errors.rateFee)}
              errorFeedback={errors.rateFee}
              isDisabled={isDisabled}
            />
            <CurrencyInput
              id="adminFee"
              label="Admin Fee"
              currency="USD"
              value={values.adminFee?.toString() ?? ''}
              onChange={handleChange}
              isError={Boolean(errors.adminFee)}
              errorFeedback={errors.adminFee}
              isDisabled={isDisabled}
              helperText="This is a fixed amount doesn't associate with Obligor Fee Rate"
            />
            <CurrencyInput
              id="premium"
              label="Premium"
              currency="USD"
              value={values.premium?.toString()}
              onChange={handleChange}
              isError={Boolean(errors.premium)}
              errorFeedback={errors.premium}
              isDisabled={isDisabled}
            />
          </Grid>
        </Stack>
      </Box>
    </Stack>
  )
}
export { PremiumForm }
