import type { ColumnFiltersState, SortingState } from '@extend/zen'
import { Add, DataTable } from '@extend/zen'
import type { ClaimsSearchClaim, ClaimsSearchParameters } from '@helloextend/extend-api-rtk-query'
import { useLazyGetServiceOrderQuery } from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router-dom'
import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNewClaimsSearch } from '../../../hooks/use-new-claims-search'
import { getFilterDefs, formatClaimItems, getTableColumns } from './new-table-config'
import type { ClaimsUser } from '@helloextend/extend-api-client'

interface ClaimsSearchTableProps {
  users?: ClaimsUser[]
}

const QUERY_PARAMS = [
  'claimId',
  'contractId',
  'customerEmail',
  'customerPhone',
  'assignedUserId',
  'serviceOrderId',
]

export const ClaimsSearchTable: FC<ClaimsSearchTableProps> = ({ users }) => {
  const [params, setParams] = useState<ClaimsSearchParameters & { serviceOrderId?: string }>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({ type: false })
  const [getServiceOrder] = useLazyGetServiceOrderQuery()
  const { data, isLoading } = useNewClaimsSearch(params, !Object.keys(params).length)
  const filterDefs = useMemo(() => getFilterDefs(), [])

  const [sorting, setSorting] = useState<SortingState>([{ id: 'dateReported', desc: true }])
  const { push } = useHistory()

  const handleFileClaim = (): void => {
    push('/admin/contracts')
  }

  const handleRowClick = (row: ClaimsSearchClaim): void => {
    window.open(`/admin/claims/${row.id}`, '_blank', 'noreferrer')
  }

  const handleServiceOrderIdSearch = useCallback(async (serviceOrderId: string) => {
    try {
      const serviceOrder = await getServiceOrder({ serviceOrderId }).unwrap()
      const newColumnFilters = columnFilters.filter((filter) => filter.id !== 'serviceOrderId')
      newColumnFilters.push({ id: 'claimId', value: serviceOrder.claimId })
      setColumnFilters(newColumnFilters)
      setParams({ claimId: serviceOrder.claimId })
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    // Clear the table filters and display no claims if no search params are set
    const currentParam = Object.keys(params).length ? Object.keys(params)[0] : undefined
    if (currentParam && !columnFilters.some((filter) => QUERY_PARAMS.includes(filter.id))) {
      setParams({})
      setColumnFilters([])
      return
    }

    const newParamFilter = columnFilters.find((filter) => QUERY_PARAMS.includes(filter.id))
    // Do nothing if the current query param and value still exist in the filters
    if (
      !newParamFilter ||
      (newParamFilter.id === currentParam &&
        currentParam &&
        newParamFilter.value === params[currentParam as keyof ClaimsSearchParameters])
    )
      return

    // If an assignee name is entered, find the matching user and query by id
    if (newParamFilter.id === 'assignedUserId') {
      if (((newParamFilter.value as string) ?? '').toLowerCase() === 'unassigned') {
        setParams({ assignedUserId: 'unassigned' })
        return
      }

      const matchingUser = users?.find(
        (user) =>
          user.fullName?.toLowerCase() === ((newParamFilter.value as string) ?? '')?.toLowerCase(),
      )
      if (!matchingUser) {
        setParams({})
        setColumnFilters([])
        return
      }

      setParams({ assignedUserId: matchingUser.id })
      const newColumnFilters = columnFilters.filter((filter) => filter.id !== 'assignedUserId')
      newColumnFilters.push({ id: 'assignedUserId', value: matchingUser.id })
      setColumnFilters(newColumnFilters)
      return
    }

    // For serviceOrderId, fetch the service order and use the claimId as the serviceOrderId param
    // It will be used as the claimId param in the request
    if (newParamFilter.id === 'serviceOrderId') {
      handleServiceOrderIdSearch(newParamFilter.value as string)
    }

    setParams({ [newParamFilter.id]: newParamFilter.value })
  }, [columnFilters])

  return (
    <DataTable
      data-cy="claims-search-table"
      data={data.map(formatClaimItems)}
      isLoading={isLoading && Object.keys(params).length > 0}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      sorting={sorting}
      onSortingChange={setSorting}
      filterDefs={filterDefs}
      columns={getTableColumns(true)}
      hasRequiredFilter
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      pageSizeOptions={[50, 100]}
      getRowId={(row) => row.id}
      stateViews={{
        preFilter: {
          heading: 'Search for claims',
          description:
            'Select an option from the dropdown and provide a term to search for claims.',
        },
      }}
      getRowActions={(row) => [
        {
          onClick: () => handleRowClick(row),
          text: 'View',
          emphasis: 'low',
        },
      ]}
      getTableActions={() => [
        {
          emphasis: 'medium',
          text: 'File a new claim',
          onClick: handleFileClaim,
          icon: Add,
        },
      ]}
    />
  )
}
