import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@extend/zen'

type PlansIconProps = {
  fill?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@extend/zen'`
 */
const PlansIcon: FC<PlansIconProps> = ({ fill = COLOR.NEUTRAL[600] }) => (
  <svg viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.99956 12.6305C4.95588 12.6305 4.91167 12.6194 4.8722 12.5973C4.67327 12.4873 0 9.86386 0 6.57784V0.871502C0 0.767827 0.0610473 0.673625 0.155776 0.631523C0.25103 0.589422 0.362073 0.606262 0.438909 0.67573C0.969388 1.15095 1.65459 1.41251 2.36821 1.41251C3.34076 1.41251 4.24278 0.928339 4.78168 0.117358C4.8301 0.0442066 4.91272 0 5.00061 0C5.08902 0 5.17112 0.0442066 5.22006 0.117884C5.75739 0.928865 6.65941 1.41303 7.63196 1.41303C8.33768 1.41303 9.01604 1.15727 9.54494 0.691518C9.66125 0.567318 9.91596 0.60521 9.9828 0.777826C9.99701 0.815717 10.0023 0.855714 9.99911 0.895184V6.57889C9.99911 9.86439 5.32584 12.4884 5.12691 12.5984C5.08744 12.6194 5.04324 12.6305 4.99956 12.6305ZM0.526269 1.40146V6.57837C0.526269 9.27392 4.25647 11.6221 4.99956 12.0631C5.74212 11.6221 9.47285 9.27129 9.47285 6.57837V1.40198C8.92658 1.7509 8.28927 1.9393 7.63196 1.9393C6.60626 1.9393 5.64687 1.48355 4.99956 0.703622C4.3533 1.48303 3.39391 1.9393 2.36821 1.9393C1.71037 1.9393 1.07306 1.7509 0.526269 1.40146Z"
      fill={fill}
    />
    <path
      d="M5.00036 11.4021C4.9572 11.4021 4.91352 11.3911 4.87405 11.3695C4.78985 11.3232 4.73722 11.2348 4.73722 11.139V6.84148H1.31489C1.2449 6.84148 1.17806 6.81359 1.12859 6.76464C1.07912 6.7157 1.05176 6.64834 1.05176 6.57834L1.05228 2.59554C1.05228 2.51449 1.08965 2.43713 1.15385 2.38714C1.21859 2.33662 1.30279 2.31925 1.3812 2.3403C2.5611 2.64501 3.87993 2.36609 4.83511 1.60036C4.91299 1.53669 5.02141 1.52458 5.1135 1.56774C5.20507 1.61194 5.26296 1.70404 5.26296 1.80561V6.31521H8.68266C8.82791 6.31521 8.94579 6.43309 8.94579 6.57834C8.94632 8.75605 6.035 10.789 5.14192 11.3606C5.09877 11.3884 5.04982 11.4021 5.00036 11.4021ZM5.26349 6.84148V10.648C6.75757 9.61597 8.22533 8.17136 8.40216 6.84148H5.26349ZM1.57855 6.31521H4.73669V2.31346C3.8073 2.89236 2.66109 3.11391 1.57855 2.9213V6.31521Z"
      fill={fill}
    />
  </svg>
)

export { PlansIcon }
