import type { AssignedUser, ClaimStatus } from '@extend-services/claims-management'
import { CellText } from '@helloextend/merchants-ui'
import { date } from '@extend/client-helpers'
import type { CellContext, ColumnDef, FilterDef, SelectFilterOption } from '@extend/zen';
import { Badge } from '@extend/zen'
import type { ClaimsSearchClaim } from '@helloextend/extend-api-rtk-query'
import { escalationLevels } from '../../../lib/escalation-levels'
import React from 'react'
import { getStatusBadgeDetailsClaimsSearchClaim } from './claim-status'
import { DateAssignedDisplay } from './date-assigned-display'
import styled from '@emotion/styled'
import { AssignUserDropdown } from '../../../components/assign-user-dropdown'
import type { EscalationTier } from '@helloextend/extend-api-client/src/models/claim-note'
import { startCase } from 'lodash/fp'
import type { NewTableClaimsSearch } from '../../../types/claims'
import type { ClaimsUser } from '../../../../packages/extend-api-client'

const formatEscalationLevel = (
  cellData: CellContext<ClaimsSearchClaim, string | undefined>,
): string => {
  const data = cellData.getValue()
  if (!data) return ''
  if (data === 'resolved') return 'Resolved'
  return escalationLevels[data as EscalationTier] ?? ''
}

const formatStartCase = (value?: string): string => startCase(value ?? '')

export const getTableColumns = (
  includeServiceOrderIdAndAssigneeSearch = false,
): Array<ColumnDef<ClaimsSearchClaim>> => [
  {
    label: 'Claim Status',
    id: 'status',
    isSortable: true,
    renderCell: ({
      row,
    }: CellContext<ClaimsSearchClaim, ClaimsSearchClaim['status']>): JSX.Element => {
      const badgeData = getStatusBadgeDetailsClaimsSearchClaim(row.original)
      return badgeData ? (
        <Badge
          text={badgeData.badgeText}
          emphasis="medium"
          color={badgeData.badgeColor}
          size="regular"
          details={badgeData.badgeDetails}
        />
      ) : (
        <div />
      )
    },
  },
  {
    label: 'Follow-Up Date',
    id: 'followUpDate',
    isSortable: true,
    renderCell: (cellData: CellContext<ClaimsSearchClaim, number>): JSX.Element => (
      <CellText data-cy="claim-followUpDate">
        {cellData.getValue() ? date.format(cellData.getValue(), 'DD MMM YYYY') : ''}
      </CellText>
    ),
  },
  {
    label: 'Escalation',
    id: 'escalationLevel',
    isSortable: true,
    renderCell: (cellData: CellContext<ClaimsSearchClaim, string | undefined>): JSX.Element => (
      <CellText data-cy="escalation">{formatEscalationLevel(cellData)}</CellText>
    ),
  },
  {
    label: 'Escalation Updated At',
    id: 'escalationUpdatedAt',
    isSortable: true,
    renderCell: (cellData: CellContext<ClaimsSearchClaim, number | undefined>): JSX.Element => (
      <CellText data-cy="escalation">
        {cellData.getValue() ? date.format(cellData.getValue() ?? 0, 'DD MMM YYYY') : ''}
      </CellText>
    ),
  },
  {
    label: 'Customer Name',
    id: 'customerName',
    renderCell: (cellData: CellContext<ClaimsSearchClaim, string>): JSX.Element => (
      <CellText data-cy="claim-customerName">{cellData.getValue()}</CellText>
    ),
  },
  {
    label: 'Customer Email',
    id: 'customerEmail',
    renderCell: (cellData: CellContext<ClaimsSearchClaim, string>): JSX.Element => (
      <CellText data-cy="claim-customerEmail">{cellData.getValue()}</CellText>
    ),
    search: 'explicit',
    validateSearch: (value: string): null | string => {
      if (value.length >= 3) {
        return null
      }
      return 'Must be at least 3 characters'
    },
  },
  {
    label: 'Date Reported',
    id: 'dateReported',
    isSortable: true,
    renderCell: (cellData: CellContext<ClaimsSearchClaim, number>): JSX.Element => (
      <CellText data-cy="claim-reportedAt">
        {date.format(cellData.getValue(), 'DD MMM YYYY')}
      </CellText>
    ),
  },
  {
    label: 'Claim ID',
    id: 'claimId',
    renderCell: (cellData: CellContext<ClaimsSearchClaim, string>): JSX.Element => (
      <CellText data-cy="claim-id">{cellData.getValue()}</CellText>
    ),
    search: 'explicit',
    validateSearch: (value: string): null | string => {
      if (value.length >= 3) {
        return null
      }
      return 'Must be at least 3 characters'
    },
  },
  {
    label: 'Date Assigned',
    id: 'userAssignedAt',
    isSortable: true,
    renderCell: ({ row }: CellContext<ClaimsSearchClaim, ClaimStatus>): JSX.Element => {
      const claim = row.original as ClaimsSearchClaim
      return <DateAssignedDisplay claim={claim} />
    },
  },
  {
    label: 'Contract ID',
    id: 'contractId',
    search: 'explicit',
    isSearchOnly: true,
  },
  {
    label: 'Phone Number',
    id: 'customerPhone',
    search: 'explicit',
    isSearchOnly: true,
  },
  ...(includeServiceOrderIdAndAssigneeSearch
    ? ([
        { label: 'Service Order ID', id: 'serviceOrderId', search: 'explicit', isSearchOnly: true },
      ] as Array<ColumnDef<ClaimsSearchClaim>>)
    : []),
  {
    label: 'Type',
    id: 'type',
    isSortable: true,
    renderCell: (cellData: CellContext<ClaimsSearchClaim, string>): JSX.Element => (
      <CellText data-cy="claim-type">{formatStartCase(cellData.getValue())}</CellText>
    ),
  },
  {
    label: 'Assignee',
    id: 'assignedUserId',
    isSortable: true,
    search: includeServiceOrderIdAndAssigneeSearch ? 'explicit' : undefined,
    renderCell: ({ row }: CellContext<ClaimsSearchClaim, AssignedUser>): JSX.Element => {
      const claim = row.original as ClaimsSearchClaim
      return (
        <AssigneeCell data-cy="assignedUser">
          <AssignUserDropdown claim={claim} />
        </AssigneeCell>
      )
    },
  },
  {
    label: 'Fraud Risk Score',
    id: 'fraudRiskScore',
    isSortable: true,
    information: (
      <>
        Claims with a high fraud risk score at the time of filing will be marked as &lsquo;In
        Review&lsquo; and require further information.
      </>
    ),
    renderCell: ({ row }: CellContext<ClaimsSearchClaim, number>): JSX.Element => {
      const claim = row.original as ClaimsSearchClaim
      if (!claim.fraudRiskScore) return <>___</>
      return (
        <Badge
          color={claim.validationError === 'fraud_risk' ? 'red' : 'neutral'}
          emphasis="low"
          size="regular"
          details={claim.fraudRiskScore.toString()}
          text=""
        />
      )
    },
  },
  {
    label: 'Service Type',
    id: 'serviceType',
    isSortable: true,
    renderCell: (cellData: CellContext<ClaimsSearchClaim, string>): JSX.Element => (
      <CellText data-cy="claim-service-type">{formatStartCase(cellData.getValue())}</CellText>
    ),
  },
]

const AssigneeCell = styled.div({
  display: 'flex',
  flexDirection: 'row',
  fontSize: 12,
  lineHeight: '24px',
  overflow: 'hidden',
  marginBlockStart: '1em',
  marginBlockEnd: '1em',
  gap: 8,
})

const statusOptions = [
  { display: 'Approved', value: 'approved' },
  { display: 'Denied', value: 'denied' },
  { display: 'Fulfilled', value: 'fulfilled' },
  { display: 'Review', value: 'review' },
  { display: 'Closed', value: 'closed' },
  { display: 'Pending Adjudication', value: 'pending_adjudication' },
]

const claimTypeOptions = [
  { display: 'Extended Warranty', value: 'extended_warranty' },
  { display: 'Shipping Protection', value: 'shipping_protection' },
]

const serviceTypeOptions = [
  { display: 'Replacement', value: 'replace' },
  { display: 'Depot Repair', value: 'repair_depot' },
  { display: 'Home Repair', value: 'repair_home' },
  { display: 'iRobot Repair', value: 'repair_irobot' },
  { display: 'Onsite Repair', value: 'repair_onsite' },
  { display: 'Repair', value: 'repair' },
]

const escalationOptions = [
  { display: 'Resolved', value: 'resolved' },
  { display: 'Tier 1', value: 'tier1' },
  { display: 'Tier 2', value: 'tier2' },
  { display: 'Tier 3', value: 'tier3' },
]

export const getFilterDefs = (users?: ClaimsUser[]): FilterDef[] => [
  {
    type: 'group',
    filterDefs: [
      {
        type: 'select',
        isMultiSelect: true,
        id: 'status',
        hasSearch: false,
        label: 'Claim Status',
        options: statusOptions,
      },
      ...(users
        ? [
            {
              type: 'select',
              isMultiSelect: true,
              id: 'assignedUserId',
              label: 'Assignee',
              options: [
                { display: 'Unassigned', value: 'unassigned' },
                ...users.map(
                  (user) =>
                    ({
                      display: user.fullName ?? '',
                      value: user.id,
                    } as SelectFilterOption),
                ),
              ],
            } as FilterDef,
          ]
        : []),
      {
        type: 'group',
        label: 'Dates',
        filterDefs: [
          {
            type: 'dateRange',
            id: 'dateReported',
            label: 'Reported Date',
          },
          {
            type: 'dateRange',
            id: 'followUpDate',
            label: 'Follow-Up Date',
          },
        ],
      },
      {
        type: 'select',
        isMultiSelect: true,
        id: 'type',
        hasSearch: false,
        label: 'Claim Type',
        options: claimTypeOptions,
      },
      {
        type: 'select',
        isMultiSelect: true,
        id: 'serviceType',
        hasSearch: false,
        label: 'Service Type',
        options: serviceTypeOptions,
      },
      {
        type: 'select',
        isMultiSelect: true,
        id: 'escalationLevel',
        hasSearch: false,
        label: 'Escalation',
        options: escalationOptions,
      },
      {
        type: 'numberRange',
        id: 'fraudRiskScore',
        label: 'Fraud Risk Score',
      },
    ],
  },
]

export const formatClaimItems = (claim: ClaimsSearchClaim): NewTableClaimsSearch => ({
  ...claim,
  status: claim.status,
  customerName: claim.customer.name,
  customerEmail: claim.customer.email,
  dateReported: claim.reportedAt,
  claimId: claim.id,
  dateAssigned: claim.userAssignedAt ?? 0,
  escalationUpdatedAt: claim.escalation?.updatedAt ?? 0,
  escalationLevel: claim.escalation?.isResolved ? 'resolved' : claim.escalation?.escalationLevel,
  customerPhone: claim.customer.phone,
  followUpDate: claim.followUpDate ?? 0,
})
