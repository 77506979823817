import type {
  ProductSearchRequest,
  StoreConfigurationResponse,
} from '@helloextend/extend-api-rtk-query'
import {
  useGetMappingStoresQuery,
  useGetWarrantyStatusesQuery,
  useGetMappingStatusesQuery,
  useGetStoreConfigurationQuery,
} from '@helloextend/extend-api-rtk-query'
import type { MultiValue } from 'react-select'
import type { SelectOption } from '../../../../../../types/products'

const optionSort = (a: SelectOption, b: SelectOption): number => a.label.localeCompare(b.label)

const useLoadOptionData = (): {
  storeOptions:
    | Array<{
        label: string
        value: string
        product_count_by_category: Record<string, string>
      }>
    | undefined
  warrantyStatusOptions:
    | Array<{
        label: string
        value: string
      }>
    | undefined
  mappingStatusOptions:
    | Array<{
        label: string
        value: string
      }>
    | undefined
  storeExtendCategories: StoreConfigurationResponse | undefined
  isDropdownSelectDataLoading: boolean
} => {
  const { storeOptions, isStoreLoading } = useGetMappingStoresQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      storeOptions: data?.store_list
        .map((store) => {
          return {
            label: store.store_name,
            value: store.store_id,
            product_count_by_category: store.product_count_by_category,
          }
        })
        .sort(optionSort),
      isStoreLoading: isLoading,
    }),
  })
  const { warrantyStatusOptions, isWarrantyStatusLoading } = useGetWarrantyStatusesQuery(
    undefined,
    {
      selectFromResult: ({ data, isLoading }) => ({
        warrantyStatusOptions: data?.warranty_status
          .map((warrantyStatus) => {
            return { label: warrantyStatus, value: warrantyStatus }
          })
          .sort(optionSort),
        isWarrantyStatusLoading: isLoading,
      }),
    },
  )
  const { mappingStatusOptions, isMappingStatusLoading } = useGetMappingStatusesQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      mappingStatusOptions: data?.mapping_status
        .map((mappingStatus) => {
          return { label: mappingStatus, value: mappingStatus }
        })
        .sort(optionSort),
      isMappingStatusLoading: isLoading,
    }),
  })
  const { storeExtendCategories, isStoreExtendCategoriesLoading } = useGetStoreConfigurationQuery(
    undefined,
    {
      selectFromResult: (useGetStoreConfigurationResult) => ({
        storeExtendCategories: useGetStoreConfigurationResult.data,
        isStoreExtendCategoriesLoading: useGetStoreConfigurationResult.isLoading,
      }),
    },
  )

  const isDropdownSelectDataLoading =
    isStoreExtendCategoriesLoading ||
    isMappingStatusLoading ||
    isWarrantyStatusLoading ||
    isStoreLoading
  return {
    storeOptions,
    warrantyStatusOptions,
    mappingStatusOptions,
    storeExtendCategories,
    isDropdownSelectDataLoading,
  }
}

const getProductMappingData = (
  selectedStore: SelectOption,
  selectedMappingStatuses: MultiValue<SelectOption>,
  selectedExtendCategories?: MultiValue<SelectOption>,
  selectedMerchantCategories?: MultiValue<SelectOption>,
  selectedWarrantyStatuses?: MultiValue<SelectOption>,
): ProductSearchRequest => {
  const productFilters: ProductSearchRequest = {
    store_id: selectedStore.value,
  }

  if (selectedMappingStatuses.length)
    productFilters.mapping_status = selectedMappingStatuses.map(
      (mappingStatus: SelectOption) => mappingStatus.value,
    )
  if (selectedWarrantyStatuses && selectedWarrantyStatuses.length)
    productFilters.warranty_status = selectedWarrantyStatuses.map(
      (warrantyStatus: SelectOption) => warrantyStatus.value,
    )
  if (selectedExtendCategories && selectedExtendCategories.length)
    productFilters.extend_category = selectedExtendCategories.map(
      (extend_category: SelectOption) => extend_category.value,
    )
  if (selectedMerchantCategories && selectedMerchantCategories.length)
    productFilters.merchant_category = selectedMerchantCategories.map(
      (merchant_category: SelectOption) => merchant_category.value,
    )

  return productFilters
}

export { useLoadOptionData, getProductMappingData }
