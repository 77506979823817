import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import {
  Badge,
  Breadcrumbs,
  COLOR,
  Information,
  Spinner,
  HeadingLarge,
  HeadingSmall,
  Paragraph,
} from '@extend/zen'
import type { Product } from '@helloextend/extend-api-client'
import { ProductImageCell } from '@helloextend/merchants-ui'
import { useGetCountsQuery, useGetStoreQuery } from '@helloextend/extend-api-rtk-query'
import { Link } from 'react-router-dom'
import type { ProductWarrantyStatus } from '../../../../../../../../../utils/products'
import { formatDateWithTimezone } from '../../../../../../../../../utils/date-formatting'
import { getProductBadgeProps } from '../../../../../../../../../utils/products'
import { DisplayOfferToggle } from '../../../../plan-and-pricing/components/display-offer-toggle'
import { ProductStatusInfo } from '../product-status-info'

type ProductHeaderProps = {
  product: Product
}

const ProductHeader: FC<ProductHeaderProps> = ({ product }) => {
  const {
    storeId,
    referenceId,
    title,
    enabled,
    updatedAt,
    warrantyStatus,
    imageUrl,
    parentReferenceId,
  } = product || {}
  const productBadge = getProductBadgeProps(warrantyStatus as ProductWarrantyStatus)
  const { data: storeData, isLoading: isStoreLoading } = useGetStoreQuery(
    { storeId },
    { skip: !storeId },
  )

  const { data: countsData, isLoading: isLoadingCounts } = useGetCountsQuery({
    storeId,
    referenceId,
  })

  if (isStoreLoading || isLoadingCounts) {
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  if (!product || !storeData) {
    return null
  }

  return (
    <div data-cy="product-header">
      <Breadcrumbs
        crumbs={[
          {
            text: 'Stores',
            to: '/admin/stores',
          },
          {
            text: storeData?.name || 'Store',
            to: `/admin/stores/${storeId}/products`,
          },
          ...(parentReferenceId
            ? [
                {
                  text: `Variants: ${parentReferenceId}`,
                  to: `/admin/stores/${storeId}/products/${parentReferenceId}/variants`,
                },
              ]
            : []),
          {
            text: title,
          },
        ]}
        data-cy="product-header-breadcrumbs"
      />
      <InfoContainer>
        <ProductImageCell imageUrl={imageUrl} title={title} />
        <div>
          <Container>
            <Heading>
              <HeadingLarge data-cy="product-title">{title}</HeadingLarge>
            </Heading>
            <Badge {...productBadge} data-cy="product-status" />
            <ProductStatusInfo />
          </Container>
          <SubheadingOption>
            <Subheading>Display Offer: </Subheading>
            <DisplayOfferToggle product={product} isEnabled={enabled || false} />
          </SubheadingOption>
          <SubheadingOption>
            <Subheading>Reference ID: </Subheading>
            <SubheadingText>{referenceId}</SubheadingText>
          </SubheadingOption>
          {parentReferenceId && (
            <SubheadingOption data-cy="parent-reference-id">
              <Subheading>Base Product: </Subheading>
              <SubheadingText>{parentReferenceId}</SubheadingText>
              <Information>
                <Paragraph>
                  A base product is the parent of one or more “variant” products. Extend Warranty
                  Plans are mapped to the variants of the base product. Find a list of the variants
                  for this product by clicking the Variants link below.
                </Paragraph>
              </Information>
            </SubheadingOption>
          )}
          {product.settings?.isBundle && (
            <SubheadingOption>
              <Subheading># of Bundle Items:</Subheading>
              <SubheadingText data-cy="bundle-items-amount">
                {product?.subproducts?.length || 0}
              </SubheadingText>
            </SubheadingOption>
          )}
          <SubheadingOption>
            <Subheading>Last Update: </Subheading>
            <SubheadingText>{formatDateWithTimezone(updatedAt)}</SubheadingText>
          </SubheadingOption>
          {countsData && countsData.childCount > 0 && (
            <SubheadingOption data-cy="variants-count">
              <Subheading>Variants: </Subheading>
              <SubheadingText>
                <StyledLink to={`/admin/stores/${storeId}/products/${referenceId}/variants`}>
                  {countsData.childCount}
                </StyledLink>
              </SubheadingText>
            </SubheadingOption>
          )}
        </div>
      </InfoContainer>
    </div>
  )
}

const InfoContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: 32,
  columnGap: 32,
})

const Container = styled.div({
  display: 'flex',
  alignItems: 'start',
})

const Heading = styled.div({
  marginRight: 16,
})

const Subheading = styled(HeadingSmall)({
  fontSize: 16,
  color: COLOR.NEUTRAL[600],
  marginRight: 8,
})

const SubheadingOption = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: 8,
})

const SubheadingText = styled(Paragraph)({
  color: COLOR.NEUTRAL[1000],
  lineHeight: '28px',
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const StyledLink = styled(Link)({
  color: COLOR.BLUE[800],
  '&:hover': {
    textDecoration: 'underline',
  },
})

export { ProductHeader }
