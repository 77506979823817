import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    isLoyaltyApproved: Yup.boolean(),
    loyaltyRevShare: Yup.number()
      .min(0, 'Revenue Share Per Shipment must be greater than 0')
      .max(100, 'Revenue Share Per Shipment must be equal or less than 100'),
  })
  .defined()

type LoyaltyProgramFormValues = Yup.InferType<typeof schema>

const labels: { [key in keyof LoyaltyProgramFormValues]: string } = {
  isLoyaltyApproved: 'Loyalty Program',
  loyaltyRevShare: 'Revenue Share Per Shipment',
}

const GetFormPropsAndLabels = <T extends keyof LoyaltyProgramFormValues>(): {
  [key in T]: { property: string; label: string }
} => {
  return Object.keys(schema.fields).reduce(
    (mapping, property) => {
      const field = schema.fields[property as keyof LoyaltyProgramFormValues]
      if (field && 'label' in field) {
        const newMapping = {
          property,
          label: labels[property as keyof LoyaltyProgramFormValues],
        }
        return {
          ...mapping,
          [property]: newMapping,
        }
      }
      return mapping
    },
    {} as {
      [key in T]: { property: string; label: string }
    },
  )
}

const getInitialValues = (
  loyaltyProgramDetails?: LoyaltyProgramFormValues,
): LoyaltyProgramFormValues => ({
  isLoyaltyApproved: loyaltyProgramDetails?.isLoyaltyApproved ?? false,
  loyaltyRevShare: loyaltyProgramDetails?.loyaltyRevShare,
})

export { schema, getInitialValues, GetFormPropsAndLabels }
export type { LoyaltyProgramFormValues }
