import type { Servicer, ServicerLocation, ClaimsUser } from '@helloextend/extend-api-client'
import type { AdvancedSelectProps } from '@extend/zen'
import type { V2 } from '@extend-services/service-orders'

const formattedFulfillmentOptions = [
  {
    display: 'Extend virtual card',
    value: 'virtual_card',
  },
  {
    display: 'Direct Payment',
    value: 'direct_payment',
  },
  {
    display: 'Auto-Replacement (SP Only)',
    value: 'automated_replacement',
  },
  {
    display: 'Manual via portal',
    value: 'manual',
  },
]

const formattedAutomatedReplacementOrderStatusOptions: Array<{
  display: string
  value: V2.Models.DefaultReplacementOrderStatus
}> = [
  {
    display: 'Pending',
    value: 'pending',
  },
  {
    display: 'Paid',
    value: 'paid',
  },
]

const formatLocation = (servicerLocation: ServicerLocation): string => {
  if (!servicerLocation) return 'Extend Warehouse'
  const { address, businessName } = servicerLocation
  return `${businessName} - ${address.city} ${address.postalCode}`
}

const getServicerLocationOptions = (
  locations: ServicerLocation[] | undefined,
): AdvancedSelectProps['options'] => {
  const options = []

  options.push({ display: 'Extend Warehouse', value: 'extend' })

  if (!locations?.length) return options

  locations.forEach((location) => {
    options.push({ display: formatLocation(location), value: location.id })
  })
  return options
}

const getServicerOptions = (
  servicers: Servicer[] | undefined,
  isLoading = false,
): AdvancedSelectProps['options'] => {
  if (!servicers && isLoading) {
    return [{ display: 'Loading...', value: '' }]
  }
  if (!servicers) {
    return []
  }
  return servicers.map((s) => ({ display: s.businessName, value: s.id }))
}

const getClaimAssigneeOptions = (
  users: ClaimsUser[] | undefined,
  isLoading = false,
): AdvancedSelectProps['options'] => {
  if (!users && isLoading) {
    return [{ display: 'Loading...', value: '' }]
  }
  if (!users) {
    return []
  }
  return users.map((u) => ({ display: u.fullName || u.email, value: u.id }))
}

export {
  formattedFulfillmentOptions,
  getServicerLocationOptions,
  getServicerOptions,
  getClaimAssigneeOptions,
  formattedAutomatedReplacementOrderStatusOptions,
}
