import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { useSubmitClaimMutation } from '@helloextend/extend-api-rtk-query'
import { useCallback, useState } from 'react'
import { mapClaimsManagementServiceError } from '../pages/admin/claim/util'

const useSubmitClaim = (): {
  submitClaim: (args: Parameters<typeof submitClaimMutation>[0]) => Promise<void>
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  error: unknown
} => {
  const { toast } = useToaster()
  const [submitClaimMutation] = useSubmitClaimMutation()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState<unknown>(null)

  const isErrorWithCode = (err: unknown): err is { data: { code: string } } => {
    return typeof err === 'object' && err !== null && 'data' in err && typeof (err as any).data?.code === 'string'
  }

  const submitClaim = useCallback(async (args: Parameters<typeof submitClaimMutation>[0]) => {
    setIsLoading(true)
    setIsError(false)
    setIsSuccess(false)
    setError(null)

    try {
      await submitClaimMutation(args).unwrap()
      setIsSuccess(true)
    } catch (err: unknown) {
      setIsError(true)
      setError(err)
      if (isErrorWithCode(err)) {
        toast({
          message: mapClaimsManagementServiceError(err.data.code),
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [submitClaimMutation, toast])

  return {
    isLoading,
    isError,
    isSuccess,
    error,
    submitClaim,
  }
}

export { useSubmitClaim }