import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { Ruleset } from './ruleset'

type ClaimValidationProps = {
  isPublished: boolean
}

const ClaimValidation: FC<ClaimValidationProps> = ({ isPublished }) => {
  return (
    <>
      <Heading>Thread Adjudication Rules</Heading>
      {isPublished ? (
        <>
          <Description data-cy="adjudication-ruleset-description">
            If the customer&apos;s responses don&apos;t match any of the rules, the claim will be
            sent for manual review. Claims filed outside of the coverage period will be denied
            automatically.
          </Description>
          <Ruleset rulesetType="review" />
          <Ruleset rulesetType="denied" />
          <Ruleset rulesetType="approved" />
        </>
      ) : (
        <Description data-cy="adjudication-ruleset-description">
          Only the claim validation rule sets of Published and Changes Pending threads can be
          defined.
        </Description>
      )}
    </>
  )
}

const Heading = styled.h3({
  fontSize: 20,
  fontWeight: 700,
  marginTop: 24,
  marginBottom: 12,
  color: COLOR.NEUTRAL[1000],
  lineHeight: '28px',
})

const Description = styled.p({
  fontSize: 16,
  lineHeight: '28px',
  marginTop: 0,
})

export { ClaimValidation }
