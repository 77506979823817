import type { FC } from 'react'
import React, { useEffect } from 'react'
import type { Store } from '@helloextend/extend-api-client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import type { AdvancedSelectOption } from '@extend/zen'
import {
  Info,
  InlineAlertColor,
  InlineAlert,
  Paragraph,
  Box,
  HeadingLarge,
  Stack,
} from '@extend/zen'
import styled from '@emotion/styled'
import { useSearchProductsQuery, useGetStoreConfigQuery } from '@helloextend/extend-api-rtk-query'
import { useExtendAuth } from '@extend/package-okta-login'
import { ShippingProtectionForm } from './shipping-protection-form/shipping-protection-form'
import { ShippingProtectionOfferType } from './shipping-protection-offer-type/shipping-protection-offer-type'
import { LDFlag } from '../../../../../../constants/ld-flags'
import { ShippingProtectionPurchaseModel } from './shipping-protection-purchase-model/shipping-protection-purchase-model'
import { LocationFilteringSwitch } from './location-filtering-switch/location-filtering-switch'
import { LoyaltyProgram } from './loyalty-program/loyalty-program'
import { ABStoreConfigShippingProtection } from '../ab-config/ab-config-shipping-protection'
import { CharitableDonations } from './charitable-donations/charitable-donations'
import { SPOfferSurfaceManagement } from './sp-offer-surface-management/sp-offer-surface-management'

type ShippingProtectionProps = {
  store: Store
  selectCategories: AdvancedSelectOption[]
}

const ShippingProtection: FC<ShippingProtectionProps> = ({ store }) => {
  const flags = useFlags()
  const FF_OFFERS = flags[LDFlag.OffersFeatures]
  const FF_SP_PURCHASE_MODEL = flags[LDFlag.SPPurchaseModel]
  const FF_LOYALTY_PROGRAM = flags[LDFlag.LoyaltyProgram]
  const FF_CHARITABLE_DONATIONS = flags[LDFlag.CharitableDonations]
  const { shippingProtection, id, merchantSpRevenueSharePercentage } = store
  const {
    data,
    isSuccess: isQuerySearchSuccess,
    refetch,
  } = useSearchProductsQuery({
    storeId: id,
    limit: 1,
    shippable: true,
    shipEnabled: true,
  })
  const { data: storeConfig, isLoading: isStoreConfigLoading } = useGetStoreConfigQuery({
    storeId: store.id,
  })

  useEffect(() => {
    refetch()
  }, [store, id, refetch])

  const { user } = useExtendAuth()
  const hasAppropriateRole = ['MerchantSuccess', 'Engineer'].includes(user?.role || '')

  return (
    <>
      {isQuerySearchSuccess && data?.products.length === 0 && (
        <InlineAlertContainer>
          <InlineAlert
            icon={Info}
            color={InlineAlertColor.yellow}
            data-cy="product-shippable-and-shipEnabled-alert"
          >
            <Paragraph>
              No products in the store have both shippable status and shipping protection enabled.
            </Paragraph>
          </InlineAlert>
        </InlineAlertContainer>
      )}
      <Stack spacing={2}>
        <Box>
          <Stack>
            <h2>Shipping Protection</h2>
            {FF_SP_PURCHASE_MODEL ? (
              <LocationFilteringSwitch storeId={id} shippingProtection={shippingProtection || {}} />
            ) : (
              <ShippingProtectionForm
                storeId={id}
                merchantSpRevenueSharePercentage={merchantSpRevenueSharePercentage}
                shippingProtection={shippingProtection || {}}
              />
            )}
          </Stack>
        </Box>
        {FF_SP_PURCHASE_MODEL ? (
          <Box>
            <Stack>
              <h2>Purchase Model</h2>
              <ShippingProtectionPurchaseModel store={store} />
            </Stack>
          </Box>
        ) : null}
        <Box>
          <Stack>
            <h2>Offer Type</h2>
            <ShippingProtectionOfferType store={store} />
          </Stack>
        </Box>
        {!isStoreConfigLoading && hasAppropriateRole && (
          <Box>
            <Stack>
              <h2>Offer Surface Management</h2>
              <SPOfferSurfaceManagement storeConfig={storeConfig} storeId={id} />
            </Stack>
          </Box>
        )}
        {!isStoreConfigLoading && FF_OFFERS.spABTesting?.enabled && (
          <Box data-cy="ab-testing-section">
            <h2>A/B Testing</h2>
            <ABStoreConfigShippingProtection storeId={store.id} storeConfig={storeConfig} />
          </Box>
        )}
        {FF_LOYALTY_PROGRAM && (
          <Box data-cy="loyalty-program-section">
            <Stack spacing={2}>
              <HeadingLarge>Loyalty Program</HeadingLarge>
              <LoyaltyProgram store={store} />
            </Stack>
          </Box>
        )}
        {FF_CHARITABLE_DONATIONS && (
          <Box data-cy="charitable-donation-section">
            <Stack>
              <HeadingLarge>Charitable Donations</HeadingLarge>
              <CharitableDonations storeId={store.id} />
            </Stack>
          </Box>
        )}
      </Stack>
    </>
  )
}

export { ShippingProtection }

const InlineAlertContainer = styled.div({
  marginBottom: 16,
})
