import React from 'react'
import { startCase } from 'lodash/fp'
import { currency } from '@extend/client-helpers'
import { COLOR } from '@extend/zen'
import type {
  Expense,
  PartsExpense,
  ReplacementExpense,
  GiftCardReplacementExpense,
} from '@helloextend/extend-api-client'
import styled from '@emotion/styled'
import type { Column } from '../../../../../../../../components/table'
import { CellText } from '../../../../../../../../components/table'
import { expenseOwners } from './mappings'

export const formatExpenseType = (type: Expense['type']): string => {
  switch (type) {
    case 'replacement': {
      return 'Product Replacement'
    }
    case 'parts': {
      return 'Part(s)'
    }
    case 'labor': {
      return 'Labor - Hourly Rate'
    }
    case 'gift_card_misc_fees': {
      return 'Gift Card Fees'
    }
    default: {
      return startCase(type)
    }
  }
}

export const checkMetaData = (expense: Expense): string => {
  switch (expense.type) {
    case 'replacement':
    case 'refurbished_replacement':
      return `Product Name: ${(expense as ReplacementExpense).metaData.productName}`
    case 'parts':
      return `Part Number: ${(expense as PartsExpense).metaData.partNumber}`
    case 'gift_card':
      return `Provider: ${(expense as GiftCardReplacementExpense).metaData.provider}`
    default:
      // Not all expense types have metadata
      return ''
  }
}

const getTableColumns = (): Array<Column<Expense>> => {
  const columns: Array<Column<Expense>> = [
    {
      Header: 'Type',
      accessor: 'type',
      columnWidth: 25,
      Cell: (data: Expense) => (
        <CellText title={data.type}>{formatExpenseType(data.type)}</CellText>
      ),
    },
    {
      Header: 'Description',
      accessor: 'description',
      columnWidth: 50,
      Cell: (data: Expense): JSX.Element => {
        const metaData = checkMetaData(data)
        return (
          <>
            {Boolean(metaData) && <DoubleCellText title="metaData">{metaData}</DoubleCellText>}
            <DoubleCellText>{data.description}</DoubleCellText>
          </>
        )
      },
    },
    {
      Header: 'Rate',
      accessor: 'cost',
      columnWidth: 25,
      Cell: (data: Expense) => (
        <CellText title={`${data.cost}`}>{`${currency
          .format(data.cost.amount)
          .slice(1)}`}</CellText>
      ),
    },
    {
      Header: 'Wholesale Cost',
      accessor: 'cost',
      columnWidth: 25,
      Cell: (data: Expense) => (
        <CellText title={`${data.wholesaleCost ?? 'wholesale'}`}>
          {data.wholesaleCost ? `${currency.format(data.wholesaleCost.amount).slice(1)}` : ''}
        </CellText>
      ),
    },
    {
      Header: 'Goodwill',
      accessor: 'isGoodwill',
      columnWidth: 25,
      align: 'left',
      Cell: (data: Expense) => (
        <>
          {data.isGoodwill ? (
            <GoodwillText>
              <GoodwillDot />
              <CellText title={`${data.quantity || 1}`} align="right">
                Goodwill
              </CellText>
            </GoodwillText>
          ) : null}
        </>
      ),
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      columnWidth: 25,
      align: 'right',
      Cell: (data: Expense) => (
        <CellText title={`${data.quantity || 1}`} align="right">
          {data.quantity || 1}
        </CellText>
      ),
    },
    {
      Header: 'Total',
      accessor: 'totalCost',
      columnWidth: 25,
      align: 'right',
      Cell: (data: Expense) => (
        <CellText title={`${data.totalCost || data.cost.amount}`} align="right">{`${currency
          .format(data.totalCost || data.cost.amount)
          .slice(1)}`}</CellText>
      ),
    },
  ]

  columns.splice(2, 0, {
    Header: 'Incurred By',
    accessor: 'incurredBy',
    columnWidth: 10,
    Cell: (data: Expense) => (
      <CellText title={`${data.incurredBy}`}>
        {expenseOwners.find((el) => el.value === data.incurredBy)?.display}
      </CellText>
    ),
  })

  return columns
}

const DoubleCellText = styled.p({
  fontSize: 12,
  lineHeight: '16px',
  color: COLOR.BLACK,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: 'left',
  margin: 0,
})

const GoodwillText = styled.div({
  justifyContent: 'flex-start',
  color: COLOR.NEUTRAL[600],
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
})

const GoodwillDot = styled.div({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: COLOR.NEUTRAL[600],
})

export { getTableColumns }
