import { Box, HeadingLarge, Stack, Divider } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'

type SpPlanSectionShellProps = {
  heading: string
  dataCy: string
  modalRender?: boolean
  divider?: boolean
}

const SpPlanSectionShell: FC<SpPlanSectionShellProps> = ({
  children,
  heading,
  dataCy,
  modalRender,
  divider,
}) => {
  return (
    <>
      {modalRender ? (
        <>
          <Stack spacing={2}>
            <HeadingLarge data-cy={`${dataCy}-heading`}>{heading}</HeadingLarge>

            {children}
          </Stack>
          {divider ? <Divider data-cy={`${dataCy}-divider`} /> : null}
        </>
      ) : (
        <>
          <Box data-cy={`${dataCy}-box`}>
            <Stack spacing={2}>
              <HeadingLarge data-cy={`${dataCy}-heading`}>{heading}</HeadingLarge>

              {children}
            </Stack>
          </Box>
        </>
      )}
    </>
  )
}

export { SpPlanSectionShell }
