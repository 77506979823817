import type { FC, ReactNode } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

interface SectionContainerProps {
  children: ReactNode
  title?: string
  actionItem?: ReactNode
  'data-cy'?: string
}

const SectionContainer: FC<SectionContainerProps> = ({
  children,
  title,
  actionItem,
  'data-cy': dataCy,
}) => {
  return (
    <Container data-cy={dataCy}>
      <Header>
        {title && <Title>{title}</Title>}
        {actionItem && <div data-cy="action-item">{actionItem}</div>}
      </Header>
      <div data-cy="section-container">{children}</div>
    </Container>
  )
}

const Container = styled.div({
  border: `1px solid ${COLOR.NEUTRAL[200]}`,
  boxSizing: 'border-box',
  padding: 40,
  marginBottom: 32,
  position: 'relative',
})

const Title = styled.h2({
  fontSize: 20,
  lineHeight: '28px',
  color: COLOR.BLUE[1000],
  marginBottom: 24,
  marginTop: 0,
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

export { SectionContainer }
