import type { AdvancedSelectOption } from '@extend/zen'
import type { PlanCategory } from '@helloextend/extend-api-client'

export const sortPlanCategoriesAsSelectOptions = (
  categories: PlanCategory[],
): AdvancedSelectOption[] =>
  [...categories]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ name }) => ({
      display: name,
      value: name,
    }))
