import { currency } from '@extend/client-helpers'
import type { Money } from '@helloextend/extend-api-client'
import type { FullSubproductDetails } from '@helloextend/extend-api-client/src/models/product'
import type { CellContext, ColumnDefs } from '@extend/zen'
import { Badge } from '@extend/zen'
import React from 'react'
import { getProductBadgeProps } from '../../../../../../../../../utils/products'
import type { ProductWarrantyStatus } from '../../../../../../../../../utils/products'

const tableColumns: ColumnDefs<FullSubproductDetails> = [
  {
    id: 'title',
    label: 'Item Name',
  },
  {
    id: 'referenceId',
    label: 'Reference ID',
  },
  {
    id: 'price',
    label: 'Item Price (USD)',
    renderCell: (data: CellContext<FullSubproductDetails, Money>) => {
      const { amount } = data.getValue() || {}
      return currency.formatWithoutCurrencySymbol(amount)
    },
  },
  {
    id: 'warrantyStatus',
    label: 'Status',
    renderCell: (data: CellContext<FullSubproductDetails, ProductWarrantyStatus>) => {
      const productBadge = getProductBadgeProps(data.getValue())
      return productBadge.text ? <Badge {...productBadge} /> : <span>&#8212;</span>
    },
  },
  {
    id: 'quantity',
    label: 'Quantity',
  },
]

export { tableColumns }
