import React from 'react'
import { CellText } from '@helloextend/merchants-ui'
import type { Column } from '@helloextend/merchants-ui'
import type { PlanSearch } from '@helloextend/extend-api-client'
import type { TablePlansSearch } from '../../../../types/plans'

export const toTablePlanItem = (planSearch: PlanSearch): TablePlansSearch => ({
  id: planSearch.planId,
  planId: planSearch.planId,
  version: planSearch.versions.sort((a, b) => b.version - a.version)[0].version,
})

export const toTablePlanItems = (planSearchItems: PlanSearch[]): TablePlansSearch[] =>
  planSearchItems.map(toTablePlanItem)

const getPlansColumns = (
  exportColumn: Column<TablePlansSearch>,
): Array<Column<TablePlansSearch>> => [
  {
    Header: 'PlanId',
    accessor: 'planId',
    columnWidth: 50,
    Cell: (data: TablePlansSearch) => <CellText title={data.planId}>{data.planId}</CellText>,
    canSearch: true,
  },
  {
    Header: 'Version',
    accessor: 'version',
    columnWidth: 50,
    Cell: (data: TablePlansSearch) => <CellText title={`${data.version}`}>{data.version}</CellText>,
  },
  exportColumn,
]

export { getPlansColumns }
