import { customLogger } from '@extend/client-helpers'
import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { Reply } from '@helloextend/extend-api-client'
import { Button, COLOR } from '@extend/zen'
import { ErrorBrokenLinks, ErrorCrossCircle } from '../../../../components/icons'

interface ErrorMessageProps {
  contractId: string
  reply: Reply | undefined
}

const ErrorMessage: FC<ErrorMessageProps> = ({ contractId, reply = null }) => {
  customLogger.error('User encountered an unhandled claims-assistant session', {
    reply: JSON.stringify(reply, null, '\t'),
    contractId,
  })

  const handleClickReload = (): void => {
    window.location.reload()
  }

  return (
    <Wrapper>
      <IconGroup>
        <ErrorCrossCircle />
        <ErrorBrokenLinks />
      </IconGroup>
      <h2>Extend has encountered a problem...</h2>
      <Body>
        <p>
          We’re sorry - a system error has occurred and we are unable to continue to the next page.
          Any progress cannot be saved at this time.
        </p>
        <p>
          If you need immediate help, contact our support team at{' '}
          <a href="mailto:support@extend.com">support@extend.com</a> and provide this contract
          ID:&nbsp;{contractId}
        </p>
      </Body>
      <Button ariaLabel="refresh" onClick={handleClickReload} text="Try again" size="small" />
    </Wrapper>
  )
}

const IconGroup = styled.div({
  marginBottom: 32,
  svg: {
    margin: '0 12px',
  },
})

const Wrapper = styled.div({
  margin: '0 auto',
  maxWidth: 826,
  textAlign: 'center',
})

const Body = styled.div({
  margin: '40px 0 56px',
  fontSize: 20,
  a: {
    color: COLOR.BLUE[700],
    textDecoration: 'underline',
  },
})

export { ErrorMessage }
