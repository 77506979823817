import type { FC } from 'react'
import React from 'react'
import { Modal, Paragraph } from '@extend/zen'
import { getProductServicingSettingsModalDescription } from '../../../utils'
import type { ProductServicingSettingsModalType } from '../../../types'

type ProductServicingSettingsModalProps = {
  handleModalOff: () => void
  handleConfirmClick: (modalType: ProductServicingSettingsModalType, isEnabled: boolean) => void
  modalType: ProductServicingSettingsModalType
  isEnabled: boolean
  isLoading: boolean
}

const ProductServicingSettingsModal: FC<ProductServicingSettingsModalProps> = ({
  handleModalOff,
  isEnabled,
  modalType,
  isLoading,
  handleConfirmClick,
}) => {
  const modalMode = isEnabled ? 'Disable' : 'Enable'
  const modalTypeText =
    modalType === 'partialReplacementEligible' ? '"Partial Replacement"' : '"Product Return"'
  const modalHeading = `${modalMode} ${modalTypeText}`
  return (
    <Modal
      heading={modalHeading}
      onDismissRequest={handleModalOff}
      primaryButtonProps={{
        text: modalMode,
        onClick: () => handleConfirmClick(modalType, isEnabled),
        isProcessing: isLoading,
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        onClick: handleModalOff,
      }}
    >
      <Paragraph>{getProductServicingSettingsModalDescription(modalType, isEnabled)}</Paragraph>
    </Modal>
  )
}

export type { ProductServicingSettingsModalProps }
export { ProductServicingSettingsModal }
