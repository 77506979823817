import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  PlanAttributesListResponse,
  Plan,
  PlanCreateRequest,
  PlanAttribute,
  PlansAttributesUpsertBody,
  PlanFetchAllResponse,
  PlanDetails,
  PlanSet,
  PlanSetListResponse,
  PlanSetCreateBody,
  PlanSetListResponseByPlanSetIds,
} from '@helloextend/extend-api-client'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type { PlanVersionFull } from '@helloextend/extend-api-client/src/models/plan-version'
import type { PlanCategoriesListResponse } from '@helloextend/extend-api-client/src/models/plan-attributes'
import type { PlanIdsCoverageTypeMap } from '@helloextend/extend-api-client/src/models/plan-sets'
import { baseQuery } from '../base-query'
import type {
  FailureCategory,
  FailureCause,
  FailureType,
  PlanSetDeleteRequest,
  PlanSetDeleteResponse,
  PlanVersionRequest,
  ShippingProtectionPlan,
  ShippingProtectionPlanCreateRequest,
} from './types'

export const plansApi = createApi({
  baseQuery,
  reducerPath: 'Plans',
  tagTypes: [
    'Plans',
    'PlansAttributes',
    'PlanSets',
    'PlanSetsByPlanSetIds',
    'ShippingProtectionPlans',
    'ShippingProtectionPlan',
    'ShippingProtectionPlanVersions',
    'FailureTypes',
    'FailureTypeCategories',
    'FailureCauses',
  ],
  endpoints: (build) => ({
    fetchPlan: build.query<Plan, string>({
      query: (planId: string) => ({
        url: `/plans/${planId}?cc=${Date.now()}`, // Add the current time stamp to break caching.
      }),
      providesTags: (_, _err, planId) => [{ type: 'Plans', id: planId }],
    }),
    fetchPlanDetails: build.query<PlanDetails, string>({
      query: (planId: string) => ({
        url: `/plans/${planId}/details`,
      }),
      providesTags: (_, _err, planId) => [{ type: 'Plans', id: planId }],
    }),
    createPlan: build.mutation<Plan, PlanCreateRequest>({
      query: (request) => {
        return {
          url: 'plans',
          method: 'POST',
          body: request,
        }
      },
      invalidatesTags: ['Plans'],
    }),
    listPlanAttributes: build.query<PlanAttributesListResponse, string | void>({
      query: (cursor?) => ({
        url: '/plans/attributes/list',
        ...(cursor ? { params: { cursor } } : {}),
      }),
      providesTags: (_, _err) => [{ type: 'PlansAttributes' }],
    }),
    listPlanCategories: build.query<PlanCategoriesListResponse, void>({
      query: () => ({
        url: `/plans/attributes/plan_category`,
      }),
    }),
    updatePlanAttributes: build.mutation<PlanAttribute, PlansAttributesUpsertBody>({
      query: (request) => {
        return {
          url: '/plans/attributes',
          method: 'PUT',
          body: request,
        }
      },
      invalidatesTags: ['PlansAttributes'],
    }),
    listAllPlanIds: build.query<string[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let nextPageCursor
        const data: string[] = []

        do {
          const res: QueryReturnValue = await fetchWithBQ(
            nextPageCursor ? `/plans?limit=500&cursor=${nextPageCursor}` : '/plans',
          )
          if (res.error) throw res.error

          const payload = res.data as PlanFetchAllResponse
          payload.items.forEach((plan: Plan) => {
            if (!data.includes(plan.id)) {
              data.push(plan.id)
            }
          })
          nextPageCursor = payload.nextPageCursor
        } while (nextPageCursor)

        return { data }
      },
    }),
    listAllPlanIdsWithCoverageType: build.query<PlanIdsCoverageTypeMap, void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let nextPageCursor
        const data: PlanIdsCoverageTypeMap = {}

        do {
          const res: QueryReturnValue = await fetchWithBQ(
            nextPageCursor ? `/plans?limit=500&cursor=${nextPageCursor}` : '/plans',
          )
          if (res.error) throw res.error

          const payload = res.data as PlanFetchAllResponse
          payload.items.forEach((plan: Plan) => {
            const {
              id: planId,
              contract: { coverage_includes: coverageType },
            } = plan
            if (!data[planId]) {
              data[planId] = coverageType
            }
          })
          nextPageCursor = payload.nextPageCursor
        } while (nextPageCursor)

        return { data }
      },
    }),
    fetchPlanVersion: build.query<Plan, PlanVersionRequest>({
      query: ({ planId, versionId }) => ({
        url: `/plans/${planId}?version=${versionId}&cc=${Date.now()}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, { planId }) => [{ type: 'Plans', id: planId }],
    }),
    fetchPlanVersionsList: build.query<PlanVersionFull, string>({
      query: (planId: string) => ({
        url: `/plans/${planId}/list?cc=${Date.now()}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, planId) => [{ type: 'Plans', id: planId }],
    }),
    listPlanSetsByPlanSetIds: build.query<PlanSetListResponseByPlanSetIds, void | string>({
      query: (ids: string) => ({
        url: `/plans/sets?ids=${ids}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: [{ type: 'PlanSetsByPlanSetIds' }],
    }),
    listPlanSets: build.query<PlanSet[], void | string>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const baseUrl = _arg ? `/plans/sets?limit=500&filter=${_arg}` : '/plans/sets?limit=500'

        const getPlanSets = async (cursor = ''): Promise<PlanSet[]> => {
          const queryUrl = cursor ? `${baseUrl}&cursor=${cursor}` : baseUrl
          const response: QueryReturnValue = await fetchWithBQ(queryUrl)
          if (response.error) throw response.error
          const { items, nextPageCursor } = response.data as PlanSetListResponse

          return [...items, ...(nextPageCursor ? await getPlanSets(nextPageCursor) : [])]
        }

        const data = await getPlanSets()
        return { data }
      },
      providesTags: [{ type: 'PlanSets' }],
    }),
    createPlanSet: build.mutation<PlanSet, PlanSetCreateBody>({
      query: (data) => {
        return {
          url: `/plans/sets`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['PlanSets'],
    }),
    deletePlanSet: build.mutation<PlanSetDeleteResponse, PlanSetDeleteRequest>({
      query: (data) => {
        return {
          url: `/plans/sets`,
          method: 'DELETE',
          body: data,
        }
      },
      invalidatesTags: ['PlanSets'],
    }),
    getPlansByIds: build.query<Plan[], string[]>({
      async queryFn(planIds, _queryApi, _extraOptions, fetchWithBQ) {
        const plans = await Promise.allSettled(
          planIds.map(async (id) => {
            const response = await fetchWithBQ({
              url: `/plans/${id}`,
              headers: { accept: 'application/json; version=latest;' },
            })
            if (response.error) {
              console.error(response.error)
            }
            return response.data as Plan
          }),
        )

        const fulfilledPlans = plans
          .filter((result) => result.status === 'fulfilled')
          .map((result) => (result as PromiseFulfilledResult<Plan>).value)

        return { data: fulfilledPlans }
      },
      providesTags: (result) => (result || []).map((plan) => ({ type: 'Plans', id: plan?.id })),
    }),
    createShippingProtectionPlan: build.mutation<
      ShippingProtectionPlan,
      ShippingProtectionPlanCreateRequest
    >({
      query: (request) => {
        return {
          url: '/plans/shippingprotection',
          method: 'POST',
          body: request,
        }
      },
      invalidatesTags: (_, _err, { id }) => [
        'ShippingProtectionPlans',
        { type: 'ShippingProtectionPlan', id },
        { type: 'ShippingProtectionPlanVersions', id },
      ],
    }),
    getShippingProtectionPlans: build.query<ShippingProtectionPlan[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const baseUrl = '/plans/shippingprotection/list'

        const getSpPlans = async (cursor?: string): Promise<ShippingProtectionPlan[]> => {
          const queryUrl = cursor ? `${baseUrl}?cursor=${cursor}` : baseUrl
          const response: QueryReturnValue = await fetchWithBQ(queryUrl)
          if (response.error) throw response.error
          const { items, nextPageCursor } = response.data as {
            items: ShippingProtectionPlan[]
            nextPageCursor: string
          }

          return [...items, ...(nextPageCursor ? await getSpPlans(nextPageCursor) : [])]
        }

        const data = await getSpPlans()

        const sortedPlans = data.sort((plan1, plan2) =>
          plan1.id.toLowerCase() > plan2.id.toLowerCase() ? 1 : -1,
        )
        return { data: sortedPlans }
      },
      providesTags: [{ type: 'ShippingProtectionPlans' }],
    }),
    getShippingProtectionPlan: build.query<
      ShippingProtectionPlan,
      { planId: string; version?: string }
    >({
      query: ({ planId, version }) => ({
        url: `/plans/shippingprotection/${planId}?cc=${Date.now()}`,
        params: { version },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, { planId }) => [{ type: 'ShippingProtectionPlan', id: planId }],
    }),
    getShippingProtectionPlanVersions: build.query<{ items: ShippingProtectionPlan[] }, string>({
      query: (planId: string) => ({
        url: `/plans/shippingprotection/${planId}/versions`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, planId) => [{ type: 'ShippingProtectionPlanVersions', id: planId }],
    }),
    listFailureTypes: build.query<{ items: FailureType[] }, string | undefined | void>({
      query: (categoryId?: string) => ({
        url: `/plans/failures/types?category=${categoryId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: [{ type: 'FailureTypes' }],
    }),
    listFailureCauses: build.query<{ items: FailureCause[] }, string | undefined | void>({
      query: (categoryId?: string) => ({
        url: `/plans/failures/causes?category=${categoryId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: [{ type: 'FailureCauses' }],
    }),
    listFailureTypeCategories: build.query<{ items: FailureCategory[] }, void>({
      query: () => ({
        url: `/plans/failures/categories`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: [{ type: 'FailureTypeCategories' }],
    }),
  }),
})

export const {
  useCreatePlanMutation,
  useFetchPlanQuery,
  useFetchPlanDetailsQuery,
  useLazyFetchPlanQuery,
  usePrefetch: usePrefetchPlans,
  useListPlanAttributesQuery,
  useListPlanCategoriesQuery,
  useLazyListPlanAttributesQuery,
  useUpdatePlanAttributesMutation,
  useListAllPlanIdsQuery,
  useListAllPlanIdsWithCoverageTypeQuery,
  useFetchPlanVersionQuery,
  useFetchPlanVersionsListQuery,
  useListPlanSetsQuery,
  useLazyListPlanSetsQuery,
  useCreatePlanSetMutation,
  useDeletePlanSetMutation,
  useGetPlansByIdsQuery,
  useLazyGetPlansByIdsQuery,
  useListPlanSetsByPlanSetIdsQuery,
  useLazyListPlanSetsByPlanSetIdsQuery,
  useCreateShippingProtectionPlanMutation,
  useGetShippingProtectionPlansQuery,
  useLazyGetShippingProtectionPlanQuery,
  useGetShippingProtectionPlanQuery,
  useGetShippingProtectionPlanVersionsQuery,
  useListFailureTypesQuery,
  useListFailureCausesQuery,
  useListFailureTypeCategoriesQuery,
} = plansApi
export const { fetchPlan } = plansApi.endpoints
