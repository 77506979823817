import type { FC } from 'react'
import React, { useState } from 'react'
import type { Plan } from '@helloextend/extend-api-client'
import type { DataTableAction, PaginationState } from '@extend/zen'
import { Add, Button, DataTable, Stack, Trash, Paragraph } from '@extend/zen'
import { tableColumns } from './product-plans-table-config'

type ProductPlansTableProps = {
  plansData: Plan[]
  isLoading: boolean
  toggleModalOn: () => void
  handleDeleteItem: (id: string) => void
}

const ProductPlansTable: FC<ProductPlansTableProps> = ({
  plansData,
  isLoading,
  toggleModalOn,
  handleDeleteItem,
}) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })
  const getRowActions = (row: Plan): DataTableAction[] => [
    {
      onClick: () => handleDeleteItem(row.id),
      'data-cy': 'delete-plan-button',
      icon: Trash,
      emphasis: 'low',
      color: 'neutral',
    },
  ]

  const getTableActions = (): DataTableAction[] => [
    {
      text: 'Add Plan',
      'data-cy': 'add-plan-button',
      icon: Add,
      emphasis: 'medium',
      onClick: toggleModalOn,
    },
  ]

  return (
    <>
      {plansData.length === 0 && !isLoading ? (
        <Stack isRow justify="space-between">
          <p>No plans added.</p>
          <Button {...getTableActions()[0]} />
        </Stack>
      ) : (
        <DataTable
          data-cy="product-plans-table"
          isLoading={isLoading}
          columns={tableColumns}
          data={plansData}
          getRowActions={getRowActions}
          getTableActions={getTableActions}
          pageSizeOptions={[10, 20, 50]}
          pagination={pagination}
          onPaginationChange={setPagination}
          heading={<Paragraph>Plans are currently available to the product.</Paragraph>}
        />
      )}
    </>
  )
}

export { ProductPlansTable }
