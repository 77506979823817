import type { FullSubproductDetails } from '@helloextend/extend-api-client/src/models/product'
import type { DataTableAction } from '@extend/zen'
import { Add, Button, DataTable, Stack, Trash, Paragraph } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import { tableColumns } from './bundle-management-table-config'

type BundleManagementTableProps = {
  subproducts: FullSubproductDetails[]
  toggleModalOn: () => void
  handleDeleteItem: (referenceId: string) => void
  isDeletingProduct: boolean
}

const BundleManagementTable: FC<BundleManagementTableProps> = ({
  subproducts,
  isDeletingProduct,
  toggleModalOn,
  handleDeleteItem,
}) => {
  const getRowActions = (row: FullSubproductDetails): DataTableAction[] => [
    {
      onClick: () => handleDeleteItem(row.referenceId),
      'data-cy': 'delete-plan-button',
      icon: Trash,
      emphasis: 'low',
      color: 'neutral',
      isProcessing: isDeletingProduct,
    },
  ]

  const getTableActions = (): DataTableAction[] => [
    {
      text: 'Add Item',
      'data-cy': 'add-plan-button',
      icon: Add,
      emphasis: 'medium',
      onClick: toggleModalOn,
    },
  ]
  return (
    <>
      {subproducts?.length === 0 ? (
        <Stack isRow justify="space-between">
          <p>No items added.</p>
          <Button {...getTableActions()[0]} />
        </Stack>
      ) : (
        <DataTable
          data={subproducts}
          columns={tableColumns}
          data-cy="bundle-items-table"
          isLoading={false}
          getRowActions={getRowActions}
          getTableActions={getTableActions}
          heading={<Paragraph>The items that are included in the bundle product. </Paragraph>}
        />
      )}
    </>
  )
}

export { BundleManagementTable }
