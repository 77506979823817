import type { FC } from 'react'
import React from 'react'
import { ModalController, Modal } from '@extend/zen'

type LeavePageModalProps = {
  handleLeavePage: () => void
  handleCloseModal: () => void
  isVisible: boolean
  mainText?: string
  detail?: string
  confirmation?: string
  submitButtonText?: string
  overflow?: string
}

const LeavePageModal: FC<LeavePageModalProps> = ({
  handleLeavePage,
  handleCloseModal,
  isVisible,
  mainText = 'Hang on! You have some unsaved changes on this page',
  detail = 'If you leave, we will discard your unsaved changes, or you can click cancel and save your changes.',
  confirmation = 'Are you sure you want to leave?',
  submitButtonText = 'Leave Page',
}) => (
  <ModalController isOpen={isVisible}>
    <Modal
      heading={mainText}
      data-cy="hang-on-unsaved-message"
      primaryButtonProps={{
        'data-cy': 'leave-page-btn',
        text: submitButtonText,
        color: 'red',
        onClick: handleLeavePage,
      }}
      secondaryButtonProps={{
        'data-cy': 'cancel-btn',
        text: 'Cancel',
        onClick: handleCloseModal,
      }}
      onDismissRequest={handleCloseModal}
    >
      {Boolean(detail) && <p data-cy="unsaved-message-copy">{detail}</p>}
      {Boolean(confirmation) && <p data-cy="modal-leave-confirmation">{confirmation}</p>}
    </Modal>
  </ModalController>
)

export type { LeavePageModalProps }
export { LeavePageModal }
