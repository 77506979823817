import React from 'react'
import type { ColumnDefs, CellContext } from '@extend/zen'
import { Link } from 'react-router-dom'
import type { PlanSet } from '@helloextend/extend-api-client/src/models/plan-sets'
import type { PlanCategory, StorePlanSetMapping } from '@helloextend/extend-api-client'
import { PlanSetRowButtons } from './table-components/plan-set-row-buttons'

export type StorePlanSetTableData = {
  planSetMapping: StorePlanSetMapping
  planCategory: PlanCategory
  planSet: PlanSet
}
export const planSetsDataTableColumns: ColumnDefs<StorePlanSetTableData> = [
  {
    id: 'name',
    label: 'Name',
    isAlwaysPinned: true,
    renderCell: (data: CellContext<StorePlanSetTableData, PlanSet>) => (
      <Link
        to={`/admin/stores/${data.row.original.planSetMapping.storeId}/plan-set/${data.row.original.planSet.id}`}
      >
        {data.row.original.planSet.name}
      </Link>
    ),
  },
  {
    id: 'planCategory',
    label: 'Extend Plan Category',
    renderCell: (data: CellContext<StorePlanSetTableData, PlanCategory>) =>
      data.row.original.planCategory.name,
  },
  {
    id: 'priceBands',
    label: '# of Price Bands',
    renderCell: (data: CellContext<StorePlanSetTableData, PlanSet>) =>
      data.row.original.planSet.priceBands.length,
  },
  {
    id: 'button',
    label: '',
    align: 'right',
    renderCell: (cellData: CellContext<StorePlanSetTableData, StorePlanSetMapping>) => {
      return (
        <PlanSetRowButtons
          planSetMappingId={cellData.row.original.planSetMapping.id}
          planSetMappingName={cellData.row.original.planSet.name}
          storeId={cellData.row.original.planSetMapping.storeId}
          category={cellData.row.original.planCategory.name}
          categoryId={cellData.row.original.planCategory.id}
        />
      )
    },
  },
]
