export const escalationLevels = {
  tier1: 'Tier 1',
  tier2: 'Tier 2',
  tier3: 'Tier 3',
}

export const escalationLevelsDescription = {
  tier1: 'Needs an answer or help to progress the claim',
  tier2: 'Cannot move forward with the claim, assigns claim to Extend',
  tier3: 'Consumer complaint, requires supervisor/management resolution',
}

export const contractNotesEscalationDescription = {
  tier1: 'Tier 1: Needs an answer or help to progress the contract request',
  tier2: 'Tier 2: Cannot move forward with the contract request, assigns contract to Extend',
  tier3: 'Tier 3: Consumer complaint, requires supervisor/management resolution',
}
