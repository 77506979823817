import type { AdvancedSelectOption, FilterDef } from '@extend/zen'

// TODO: [OFFERS-1752] Once Zen DataTable supports single selection for options, update below code to use that
export const getPlanSetFilterDefs = (
  sortedPlanSetCategories: AdvancedSelectOption[],
): FilterDef[] => {
  return [
    {
      type: 'group',
      label: 'Filters',
      filterDefs: [
        {
          id: 'planCategory',
          type: 'select',
          label: 'Extend Plan Category',
          options: sortedPlanSetCategories,
        },
        {
          id: 'isGTMString',
          type: 'select',
          label: 'GTM',
          options: [
            { display: 'Yes', value: 'Yes' },
            { display: 'No', value: 'No' },
          ],
        },
      ],
    },
  ]
}
