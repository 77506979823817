import { useState, useEffect } from 'react'
import {
  useGetSessionLogsListQuery,
  useGetClaimPhotosQuery,
} from '@helloextend/extend-api-rtk-query'
import type { AdjudicationAnswerMessageList } from '../../../../types'
import { compareByCreatedAt, transformSortedClaimSessionLogs } from './utils'

// gets and transforms claim session logs for display
export const useClaimSessionLogs = ({
  sessionId,
  claimId,
}: {
  sessionId: string
  claimId: string
}): { answerMessageList: AdjudicationAnswerMessageList } => {
  const [answerMessageList, setAnswerMessageList] = useState<AdjudicationAnswerMessageList>([])
  const { data: logs, isSuccess: isLogsSuccess } = useGetSessionLogsListQuery(sessionId)
  const { data: photos, isSuccess: isPhotosSuccess } = useGetClaimPhotosQuery({ claimId })

  useEffect(() => {
    if (logs?.[0]?.sessionId === sessionId && isLogsSuccess && isPhotosSuccess) {
      const sortedLogs = [...logs].sort(compareByCreatedAt)
      const transformedLogs = transformSortedClaimSessionLogs(sortedLogs, photos?.photoDetails)
      setAnswerMessageList(transformedLogs)
    } else {
      setAnswerMessageList([])
    }
  }, [isLogsSuccess, isPhotosSuccess, logs, photos, sessionId])

  return { answerMessageList }
}
