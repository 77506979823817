import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.userDelete>

export default function* destroy(action: Action): SagaIterator {
  const { user, accessToken } = action.payload
  yield put(actions.userDeleteRequest())

  try {
    const response: ResolvedType<typeof client.users.deleteUser> = yield call(
      client.users.deleteUser,
      user.id,
      accessToken,
    )
    if (isErrorResponse(response)) {
      yield put(actions.userDeleteFailure(response.data.message, response.status))
      return
    }

    yield put(actions.userDeleteSuccess(user))
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.userDeleteFailure(e.message))
    }
  }
}
