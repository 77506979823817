import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useExtendAuth } from '@extend/package-okta-login'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { Button, COLOR } from '@extend/zen'
import { HeadTag } from '../../components/head-tag'
import images from '../../images'

const Login: FC = () => {
  const { isAuthenticated, login } = useExtendAuth()
  const history = useHistory()

  const handleLogin = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()

    await login()
  }

  if (isAuthenticated) {
    history.push('/')
  }

  return (
    <Wrapper>
      <Card>
        <Logo src={images.extendLogo} alt="Extend" />
        <HeadTag siteTitle="Extend | Login" />
        <CardWrapper>
          <Form onSubmit={handleLogin}>
            <Button
              size="regular"
              isProcessing={false}
              type="submit"
              fillContainer
              text="Log in"
              data-cy="login-button"
            />
          </Form>
        </CardWrapper>
      </Card>
    </Wrapper>
  )
}

const Form = styled.form({
  margin: '0 auto',
  maxWidth: 400,
})

const CardWrapper = styled.div({
  width: 355,
  marginTop: 48,
  [bp.md]: {
    maxHeight: 'none',
  },
})

const Wrapper = styled.section({
  color: COLOR.NEUTRAL[1000],
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLOR.NEUTRAL[700],
  '*': {
    boxSizing: 'border-box',
  },
})

const Card = styled.div({
  width: 436,
  borderRadius: 12,
  maxWidth: '100%',
  padding: '40px',
  backgroundColor: COLOR.WHITE,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  boxShadow: `0px 0px 40px 0px rgb(163 163 163 / 8%)`,
})

const Logo = styled.img({
  width: 104,
  height: 24,
})

export { Login }
