import type { FC } from 'react'
import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import type {
  Claim,
  Money,
  ServiceOrder,
  ClaimPhotosGetResponse,
} from '@helloextend/extend-api-client'
import { claimsApi } from '@helloextend/extend-api-rtk-query'
import { usePrevious } from '@helloextend/client-hooks'
import { Badge } from '@extend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { TabMenu } from '../../../../../../components/tab-menu/tab-menu'
import { AdjudicationTab } from './tabs/adjudication'
import { FraudReportTab } from './tabs/fraud-report'
import { ServiceOrderTab } from './tabs/service-order'
import { ExpensesTab } from './tabs/expenses'
import { ClaimDetailsTabs } from './types'
import { AttachmentsTab } from './tabs/attachments'
import { IncidentReportTab } from './tabs/incident-report'
import * as selectors from '../../../../../../reducers/selectors'
import type { RootState } from '../../../../../../reducers'
import { setClaimDetailsActiveView } from '../../../../../../store/slices/claim-details'
import { LDFlag } from '../../../../../../constants/ld-flags'

interface TabSectionProps {
  claim: Claim
  photoData?: ClaimPhotosGetResponse
  serviceOrders?: ServiceOrder[] | undefined
  entitlements?: { coverageAmountRemaining: Money }
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<ClaimDetailsTabs>>
}

const serviceOrderTabs = [
  { text: 'Service Orders', key: ClaimDetailsTabs.ServiceOrder },
  { text: 'Expenses', key: ClaimDetailsTabs.Expenses },
]
const initialClaimsTabs: Array<{
  text: string | React.ReactNode
  key: ClaimDetailsTabs
  'data-cy'?: string
}> = [
  { text: 'Incident Report', key: ClaimDetailsTabs.IncidentReport },
  { text: 'Attachments', key: ClaimDetailsTabs.Attachments, 'data-cy': 'attachments-tab' },
  { text: 'Adjudication', key: ClaimDetailsTabs.Adjudication },
  { text: 'Fraud Report', key: ClaimDetailsTabs.FraudReport },
]

const TabSection: FC<TabSectionProps> = ({
  claim,
  photoData,
  serviceOrders,
  entitlements,
  activeTab,
  setActiveTab,
}) => {
  const { [LDFlag.ExpandedServiceOrdersSection]: FF_EXPANDED_SERVICE_ORDER_SECTION } = useFlags()

  const claimTabs = useMemo(() => {
    let resultTabs = initialClaimsTabs

    if (!claim.fraudRiskDetails?.riskScore) {
      resultTabs = resultTabs.filter((tab) => tab.key !== ClaimDetailsTabs.FraudReport)
    }
    if (!claim.conversationId) {
      resultTabs = resultTabs.filter((tab) => tab.key !== ClaimDetailsTabs.Adjudication)
    }

    const photoDetails = photoData?.photoDetails
    if (photoDetails) {
      resultTabs = resultTabs.map((tab) => {
        if (tab.key === ClaimDetailsTabs.Attachments) {
          tab.text =
            photoDetails.length > 0
              ? ((
                  <>
                    Attachments{' '}
                    <Badge
                      data-cy={`attachments-badge-${photoDetails.length}`}
                      color="blue"
                      text={String(photoDetails.length)}
                    />
                  </>
                ) as React.ReactNode)
              : 'Attachments'
        }
        return tab
      })
    }

    return resultTabs
  }, [claim.conversationId, claim.fraudRiskDetails?.riskScore, photoData])

  const dispatch = useDispatch()
  const scrollRef = useRef<HTMLDivElement>(null)
  const activeView = useSelector((state: RootState) => selectors.getActiveClaimDetailsView(state))
  const filteredServiceOrderTabs = serviceOrderTabs.filter((tab) => {
    if (tab.key === ClaimDetailsTabs.Expenses) {
      return !FF_EXPANDED_SERVICE_ORDER_SECTION
    }
    return true
  })
  const tabs = useMemo(
    () => [...((serviceOrders?.length && filteredServiceOrderTabs) || []), ...claimTabs],
    [serviceOrders?.length, filteredServiceOrderTabs, claimTabs],
  )
  const prevServiceOrder = usePrevious(serviceOrders?.length)
  const prevActiveView = usePrevious(activeView)

  useEffect(() => {
    if (serviceOrders?.length && !prevServiceOrder) {
      setActiveTab(ClaimDetailsTabs.ServiceOrder)
    }

    if (activeView !== prevActiveView && tabs.some(({ key }) => key === activeView)) {
      setActiveTab(activeView as ClaimDetailsTabs)
      const element = scrollRef?.current
      if (element) {
        setTimeout(() =>
          element.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' }),
        )
        dispatch(setClaimDetailsActiveView(''))
      }
    }
    if (activeView === ClaimDetailsTabs.Expenses && FF_EXPANDED_SERVICE_ORDER_SECTION) {
      setActiveTab(ClaimDetailsTabs.ServiceOrder)
    }
  }, [
    activeView,
    dispatch,
    serviceOrders?.length,
    prevServiceOrder,
    prevActiveView,
    scrollRef,
    tabs,
    FF_EXPANDED_SERVICE_ORDER_SECTION,
    setActiveTab,
  ])

  const handleTabClick = (tab: string): void => {
    dispatch(setClaimDetailsActiveView(tab))
    if (tab === ClaimDetailsTabs.Attachments) {
      dispatch(claimsApi.util.invalidateTags(['claim-photos']))
    }
  }

  return (
    <div id="claim-tabs-section" data-cy="tab-section">
      <TabMenu tabs={tabs} onClick={(tab) => handleTabClick(tab)} activeTab={activeTab} />
      <TabContent>
        {activeTab === ClaimDetailsTabs.ServiceOrder && (
          <ServiceOrderTab serviceOrders={serviceOrders} entitlements={entitlements} />
        )}
        {activeTab === ClaimDetailsTabs.Expenses && (
          // using first service order for now until we refactor expenses tab to use multiple service orders in a separate story
          <ExpensesTab serviceOrder={serviceOrders?.[0]} entitlements={entitlements} />
        )}
        {activeTab === ClaimDetailsTabs.Attachments && (
          // using first service order for now until we refactor attachments tab to use multiple service orders in a separate story
          <AttachmentsTab serviceOrder={serviceOrders?.[0]} claim={claim} photoData={photoData} />
        )}
        {activeTab === ClaimDetailsTabs.IncidentReport && <IncidentReportTab claim={claim} />}
        {activeTab === ClaimDetailsTabs.Adjudication && <AdjudicationTab claim={claim} />}
        {activeTab === ClaimDetailsTabs.FraudReport && <FraudReportTab claim={claim} />}
      </TabContent>
      <div ref={scrollRef} />
    </div>
  )
}

const TabContent = styled.div({
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
})

export { TabSection }
