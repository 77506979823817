import type { FC, SyntheticEvent } from 'react'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { useFulfillServiceOrderMutation } from '@helloextend/extend-api-rtk-query'
import type {
  GiftCardServiceOrdersFulfillRequest,
  GiftCardProvider,
} from '@helloextend/extend-api-client/src/models/service-order'
import {
  Button,
  ButtonGroup,
  InlineAlert,
  InlineAlertColor,
  Input,
  ToastColor,
  ToastDuration,
  useToaster,
  Info,
} from '@extend/zen'
import { giftCardFormSchema } from './schema'
import type { GiftCardValues } from './schema'
import { setClaimDetailsActiveView } from '../../../../../../store/slices/claim-details'
import { CurrencyInputGroup } from '../../../../../../components/currency-input-group'
import { Select, SelectItem } from '../../../../../../components/select'
import { formatCurrency } from '../../../util'

interface ResolveGiftCardFormProps {
  serviceOrder: ServiceOrder
}

const ResolveGiftCardForm: FC<ResolveGiftCardFormProps> = ({ serviceOrder }) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()
  const [fulfillServiceOrder, { isSuccess, isLoading }] = useFulfillServiceOrderMutation()

  const giftCardMap: Record<GiftCardProvider, string> = {
    merchant: 'Merchant',
    mastercard: 'Mastercard',
    visa: 'Visa',
    amex: 'Amex',
  }

  const {
    values,
    errors,
    handleSubmit,
    handleChange: formikHandleChange,
    setFieldValue,
  } = useFormik<GiftCardValues>({
    enableReinitialize: true,
    initialValues: giftCardFormSchema.getDefault(),
    validationSchema: giftCardFormSchema,
    onSubmit: (formValues: GiftCardValues) => {
      const payload = { ...formValues }
      if (!formValues.miscFees?.amount) {
        // @ts-ignore
        delete payload.miscFees
      }

      if (!formValues.wholesaleCost?.amount) {
        delete payload.wholesaleCost
      }

      fulfillServiceOrder({
        serviceOrderId: serviceOrder?.id ?? '',
        body: payload as GiftCardServiceOrdersFulfillRequest,
      })
    },
  })

  const handleChange = (field: string, value: string | number | undefined): void => {
    setFieldValue(field, value)
  }

  const handleSelectChange = (field: string, e: SyntheticEvent<Element>): void => {
    const { value } = e.currentTarget as HTMLInputElement
    handleChange(field, value)
  }

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: 'SO Resolved!',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
      dispatch(setClaimDetailsActiveView(''))
    }
  }, [isSuccess, values, dispatch, toast])

  return (
    <form onSubmit={handleSubmit}>
      <ContentWrapper>
        <ContentItemWrapper width="25%">
          <Select
            label="Gift Card Provider"
            labelMarginBottom={2}
            labelDisplayType="block"
            menuHeight={175}
            onChange={(e: SyntheticEvent<Element>) => handleSelectChange('provider', e)}
            value={values.provider || ''}
            error={Boolean(errors.provider)}
            errorMessage={errors.provider}
            darkErrorMessageLabel={Boolean(false)}
            data-cy="gift-card-provider-select"
          >
            {Object.keys(giftCardMap).map((key: string) => (
              <SelectItem
                key={key}
                value={key}
                label={giftCardMap[key as GiftCardProvider]}
                data-cy={`select-item-${key}`}
              />
            ))}
          </Select>
        </ContentItemWrapper>
        <ContentItemWrapper width="25%">
          <CurrencyInputGroup
            data-cy="gift-card-currency-input-field"
            label="Gift Card Value"
            name="gift-card-value"
            errorMessage={errors.amount?.amount}
            invalid={Boolean(errors.amount?.amount)}
            onChange={(v) => {
              handleChange('amount.amount', v)
            }}
            value={values.amount?.amount}
            currencyProps={{
              codeFieldName: 'amount.currencyCode',
              codeValue: serviceOrder.product.value.currencyCode || 'USD',
            }}
            isCurrencyCodeDisabled
          />
        </ContentItemWrapper>
        <ContentItemWrapper width="25%">
          <CurrencyInputGroup
            data-cy="gift-card-misc-fees-input-field"
            label="Misc Fees (Optional)"
            name="misc-fees"
            onChange={(v) => handleChange('miscFees.amount', v)}
            value={values.miscFees?.amount}
            currencyProps={{
              codeFieldName: 'miscFees.currencyCode',
              codeValue: serviceOrder.product.value.currencyCode || 'USD',
            }}
            isCurrencyCodeDisabled
          />
        </ContentItemWrapper>
        <ContentItemWrapper width="25%">
          <Input
            data-cy="total-expenses"
            label="Total Expense"
            onChange={() => {}}
            value={formatCurrency({
              amount: (values.amount?.amount ?? 0) + (values.miscFees?.amount ?? 0),
            })}
            id="total-expenses"
            isDisabled
          />
        </ContentItemWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <ContentItemWrapper width="22%">
          <CurrencyInputGroup
            data-cy="wholesale-cost-currency-input-field"
            label="Wholesale Cost (Optional)"
            name="fulfillment-expense"
            onChange={(v) => handleChange('wholesaleCost.amount', v)}
            value={values.wholesaleCost?.amount}
            currencyProps={{
              codeFieldName: 'wholesaleCost.currencyCode',
              codeValue: serviceOrder.product.value.currencyCode || 'USD',
            }}
            isCurrencyCodeDisabled
            showSymbol={false}
          />
        </ContentItemWrapper>
        <ContentItemWrapper width="75%">
          <Input
            data-cy="expense-description-input-field"
            label="Expense Description (Optional)"
            maxLength={3000}
            onChange={formikHandleChange}
            value={values.expenseDescription ?? ''}
            isError={Boolean(errors.expenseDescription)}
            errorFeedback={errors.expenseDescription}
            id="expenseDescription"
          />
        </ContentItemWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <ContentItemWrapper width="100%" marginTop={10}>
          <InlineAlert
            color={InlineAlertColor.blue}
            icon={Info}
            data-cy="service-order-resolve-gift-card-alert"
            isDismissable={false}
          >
            <Text>
              Resolution cannot be edited once submitted. Please review before clicking
              &quot;Resolve&quot;.
            </Text>
          </InlineAlert>
        </ContentItemWrapper>
      </ContentWrapper>
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
            isDisabled={isLoading}
          />
          <Button
            type="submit"
            text="Resolve"
            data-cy="resolve-button"
            isDisabled={isLoading}
            isProcessing={isLoading}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </form>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
})

const ContentItemWrapper = styled.div<{ width?: string; marginTop?: number }>(
  ({ width, marginTop }) => ({
    width,
    ...(marginTop && { marginTop }),
  }),
)

const ButtonWrapper = styled.div({
  marginTop: 10,
  display: 'flex',
  justifyContent: 'flex-end',
})

const Text = styled.p({
  fontSize: 14,
  margin: 0,
  fontWeight: 400,
})

export { ResolveGiftCardForm }
