import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import type { FC } from 'react'
import React, { useState } from 'react'
import type { DefaultMessage } from '@helloextend/extend-api-rtk-query'
import { MessageType } from '@helloextend/extend-api-rtk-query'
import { KaleyTextMessageItem } from './kaley-text-message-item'
import { getIsReplacementMessage, getIsReplacementReply } from '../../utils'
import { useGetUniqueKeys } from '../../../../../hooks/use-get-unique-keys'
import type { SelectedMessageBlock } from '../../../../../store/slices/amp-slice'

interface KaleyMessage {
  message: string
  type?: MessageType
}

type KaleyTextProps = {
  messageBlock: SelectedMessageBlock
  isThreadStructureLocked: boolean
}

const KaleyText: FC<KaleyTextProps> = ({ messageBlock, isThreadStructureLocked }) => {
  const [currBlockIndex, setCurrBlockIndex] = useState<number | undefined>(messageBlock.index)

  const { keys, addKey, removeKey, generateInitialKeys } = useGetUniqueKeys()

  const { reply } = messageBlock.script

  const messages: KaleyMessage[] =
    reply && !getIsReplacementReply(reply)
      ? reply.messages.map((message) => {
          const isReplacementMessage = getIsReplacementMessage(message)
          const messageContent = isReplacementMessage
            ? 'This Kaley text is dynamic based on the customer input.'
            : (message as DefaultMessage).content
          const messageType = isReplacementMessage ? undefined : (message as DefaultMessage).type
          return {
            message: messageContent,
            type: messageType,
          }
        })
      : []

  // generate keys pool if missing, regenerate pool when active block changes
  // wait for keys before rendering editor
  if (messages.length > 0 && (keys.length === 0 || currBlockIndex !== messageBlock.index)) {
    generateInitialKeys(messages.length)
    setCurrBlockIndex(messageBlock.index)
    return null
  }

  if (messages.length === 0) return null

  return (
    <TemplateWrapper data-cy="kaley-text-wrapper">
      <HeadingRow>
        <KaleyMessageTitle>Kaley Text</KaleyMessageTitle>
      </HeadingRow>
      <MessagesWrapper data-cy="kaley-text-messages">
        {messages.reduce((acc: JSX.Element[], item, index) => {
          if (item.type !== MessageType.textSelect) {
            acc.push(
              <KaleyTextMessageItem
                key={keys[index]}
                message={item.message}
                index={index}
                addKey={addKey}
                removeKey={removeKey}
                isRemoveDisabled={messages.length === 1}
                isThreadStructureLocked={isThreadStructureLocked}
                isHidden={item.type === MessageType.image}
              />,
            )
          }
          return acc
        }, [])}
      </MessagesWrapper>
    </TemplateWrapper>
  )
}

const TemplateWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const HeadingRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const KaleyMessageTitle = styled.div({
  width: 98,
  height: 28,
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '28px',
  color: COLOR.NEUTRAL[1000],
  flex: 'none',
  order: 0,
  flexGrow: 0,
})

const MessagesWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  margin: '8px 0 0 0',
})

export type { KaleyTextProps, KaleyMessage }
export { KaleyText }
