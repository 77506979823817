import type { ParseResult } from 'papaparse'
import Papa from 'papaparse'
import { pipe } from 'lodash/fp'
import { validateCategoriesHeader } from './validate-header'
import { validateCategory } from './validate-category'
import { validateBooleanColumns } from './validate-boolean-columns'
import { formatPipeErrorOrWarningMessage } from '../shared-csv-validation'
import { getCsvHeaderIndexes } from '../get-header-indexes'
import type { CsvImportParseResults, HeaderIndexMap } from '../csv-validation-models'
import type { CategoryHeader } from './category-csv-headers'

const INVALID_HEADERS = 'The CSV headers in the uploaded file should match the sample file provided'
const DUPLICATE_CATEGORY_NAME = 'Duplicate category name found'

async function parseCategoryCsv(file: File): Promise<CsvImportParseResults> {
  const parseResults: CsvImportParseResults = {
    data: [],
    errors: [],
  }

  let currRowStep = 0 // for row #
  let headerIndexes: HeaderIndexMap<CategoryHeader>
  const categoryNameSet = new Set()

  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      step: (result: ParseResult<string>, parser: Papa.Parser): void => {
        if (currRowStep === 0) {
          const headers = result.data
          if (!validateCategoriesHeader(headers)) {
            parseResults.errors.push(INVALID_HEADERS)
            resolve(parseResults)
            parser.abort()
          } else {
            headerIndexes = getCsvHeaderIndexes(headers)
          }
        } else {
          const validationResults = pipe(
            validateCategory,
            validateBooleanColumns,
          )({ errors: [], rowData: result.data, headerIndexes })

          // Ensures that all category names in the file are unique (since duplicates would overwrite one another)
          const categoryNameIndex = headerIndexes.category
          const categoryName = result.data[categoryNameIndex]
          if (categoryNameSet.has(categoryName)) {
            validationResults.errors.push(`${DUPLICATE_CATEGORY_NAME} (${categoryName})`)
          } else {
            categoryNameSet.add(categoryName)
          }

          if (validationResults.errors.length) {
            const errorMessage = formatPipeErrorOrWarningMessage(
              currRowStep,
              validationResults.errors,
            )
            parseResults.errors.push(errorMessage)
          } else {
            parseResults.data.push(validationResults.rowData)
          }
        }
        currRowStep += 1
      },
      complete: (): void => {
        resolve(parseResults)
      },
      error(error): void {
        reject(error.message)
      },
    })
  })
}

export { parseCategoryCsv }
