import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'
import { fetch } from './fetch'

type Action = ReturnType<typeof actions.voidExpense>

export function* voidExpense(payload: {
  serviceOrderId: string
  expenseId: string
  accessToken: string
}): SagaIterator {
  const { serviceOrderId, expenseId, accessToken } = payload
  yield put(actions.voidExpenseRequest())

  try {
    const response: ResolvedType<typeof client.serviceOrders.serviceOrderExpensesVoid> = yield call(
      client.serviceOrders.serviceOrderExpensesVoid,
      serviceOrderId,
      expenseId,
      accessToken,
    )

    if (isErrorResponse(response)) {
      yield put(actions.voidExpenseFailure(response.data.message, response.status))
      return
    }

    yield put(actions.voidExpenseSuccess())
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.voidExpenseFailure(e.message))
    }
  }
}

export default function* addExpenseAndRefetch(action: Action): SagaIterator {
  yield call(voidExpense, action.payload)
  yield call(fetch, action.payload)
}
