import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import type {
  ServiceOrder,
  ServiceOrderAssignRequest,
  Servicer,
} from '@helloextend/extend-api-client'
import type {
  AdvancedSelectChangeEvent,
  AdvancedSelectOption} from '@extend/zen';
import {
  AdvancedSelect,
  Badge,
  Button,
  ButtonGroup,
  DataProperty,
  Stack,
} from '@extend/zen'
import { useServiceOrderAssignMutation } from '@helloextend/extend-api-rtk-query'
import { ServicerSelect } from '../../../../../../components/servicer-select'
import { servicers as servicersActions } from '../../../../../../actions'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { formSchema } from './schema'
import type { Values } from './schema'
import { transformAddress } from '../../../../../../utils/transform-claim-address'

interface AssignServiceOrderFormProps {
  serviceOrder: ServiceOrder
}

const AssignServiceOrderForm: FC<AssignServiceOrderFormProps> = ({ serviceOrder }) => {
  const dispatch = useDispatch()
  const [assignServiceOrder, { isSuccess, isLoading }] = useServiceOrderAssignMutation()
  const [selectedServicer, setSelectedServicer] = React.useState<Servicer | null>(null)
  const { values, errors, handleSubmit, setFieldValue } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: formSchema.getDefault(),
    validationSchema: formSchema,
    onSubmit: (formValues: Values) => {
      assignServiceOrder({
        serviceOrderId: serviceOrder?.id ?? '',
        body: formValues as ServiceOrderAssignRequest,
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      dispatch(setClaimDetailsToast('Service order assigned!'))
      dispatch(setClaimDetailsActiveView(''))
    }
  }, [isSuccess, dispatch])

  useEffect(() => {
    return () => {
      dispatch(servicersActions.searchDropdownSet(''))
    }
  }, [dispatch])

  const options: AdvancedSelectOption[] = (selectedServicer?.locations ?? []).map((location) => ({
    value: location.id,
    display: `${location.businessName} ${transformAddress({ address: location.address })}`,
    badgeColor: 'neutral',
    badgeText:
      location.id === selectedServicer?.settings.defaultServicingLocation ? 'Default' : undefined,
  }))

  const handleLocationChange = (e: AdvancedSelectChangeEvent): void => {
    setFieldValue('servicerLocationId', e.target.value)
  }

  const getLocationDisplay = () => {
    if (!selectedServicer) return null
    if ((selectedServicer.locations ?? []).length > 1) {
      return (
        <SelectWrapper>
          <AdvancedSelect
            label="Servicer Location"
            id="servicer-location-select"
            data-cy="servicer-location-advanced-select"
            value={values.servicerLocationId}
            options={options}
            onChange={handleLocationChange}
            multiple={false}
            isNotClearable
          />
        </SelectWrapper>
      )
    }

    if (!selectedServicer?.locations || selectedServicer.locations.length === 0) {
      return (
        <DataProperty label="Servicer Location" data-cy="servicer-location-missing" value={'—'} />
      )
    }

    return (
      <SelectWrapper>
        <Stack isRow spacing={1}>
          <DataProperty
            label="Servicer Location"
            data-cy="servicer-location-data-property"
            value={`${selectedServicer.locations[0].businessName} ${transformAddress({
              address: selectedServicer.locations[0].address,
            })}`}
          />
          <BadgeWrapper data-cy="default-location-badge">
            <Badge color="neutral" text="Default" />
          </BadgeWrapper>
        </Stack>
      </SelectWrapper>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <Stack spacing={3} isRow>
          <SelectWrapper>
            <ServicerSelect
              name="servicerId"
              error={Boolean(errors.servicerId)}
              serviceType={
                serviceOrder.configurations?.repairFulfillmentMethod ?? serviceOrder?.serviceType
              }
              errorMessage={errors.servicerId}
              onChange={(name, servicer: Servicer | null) => {
                setFieldValue(name, servicer?.id ?? '')
                setSelectedServicer(servicer)
                if ((servicer?.locations?.length ?? 0) > 1) return
                setFieldValue('servicerLocationId', servicer?.locations?.[0].id ?? '', false)
              }}
            />
          </SelectWrapper>
          {getLocationDisplay()}
        </Stack>
        <ButtonWrapper>
          <ButtonGroup>
            <Button
              text="Cancel"
              emphasis="medium"
              data-cy="cancel-button"
              onClick={() => dispatch(setClaimDetailsActiveView(''))}
              isDisabled={isLoading}
            />
            <Button
              type="submit"
              text="Assign"
              data-cy="assign-button"
              isDisabled={isLoading}
              isProcessing={isLoading}
            />
          </ButtonGroup>
        </ButtonWrapper>
      </Stack>
    </form>
  )
}

const SelectWrapper = styled.div({
  maxWidth: 376,
  minWidth: 200,
  width: '100%',
  overflow: 'hidden',
  span: {
    whiteSpace: 'break-spaces',
  },
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

const BadgeWrapper = styled.div({
  paddingTop: 25,
})

export { AssignServiceOrderForm }
