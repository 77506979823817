import type { FC, SyntheticEvent } from 'react'
import React, { useCallback, useEffect } from 'react'
import { date as dateHelper } from '@extend/client-helpers'
import { Form, useFormikContext } from 'formik'
import { COLOR, InputType, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import {
  useCreateInsuranceProgramMutation,
  useLazyGetInsuranceProgramQuery,
} from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router-dom'
import { useToggle } from '@helloextend/client-hooks'
import type { InsuranceProgram } from '@helloextend/extend-api-client'
import styled from '@emotion/styled'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { FormTextGroup } from '../../../components/form-text-group'
import type {
  DropdownItem,
  InputItem,
  TextAreaInputItem,
} from '../../../components/form-text-group/form-text-group'
import type { Values } from './schema'
import { CreateFormHeader } from '../../../components/create-form-header/create-form-header'
import { InsuranceProgramObligorSection } from './insurance-program-obligor-section'
import { useDecimalsOnBlur } from '../../../hooks/use-decimals-on-blur'
import { insuranceProgramPropertiesMapper } from '../../../utils/insurance-program-property-mapper'
import { InsuranceProgramEditHeader } from './insurance-program-edit-header'
import { obligorOptionsList } from '../../../utils/obligor-options-list'

type InsuranceProgramFormProps = {
  isCreateForm?: boolean
  insuranceData?: InsuranceProgram
}

// to use this form, it must be wrapped in a Formik component in the parent component
const InsuranceProgramForm: FC<InsuranceProgramFormProps> = ({
  isCreateForm = false,
  insuranceData = {} as InsuranceProgram,
}) => {
  const [create, { isLoading, isSuccess, isError, data }] = useCreateInsuranceProgramMutation()
  const [
    getInsuranceProgram,
    {
      data: existingInsuranceProgram,
      isSuccess: isExistingInsuranceProgramSuccess,
      isError: isErrorInsuranceProgram,
      error,
    },
  ] = useLazyGetInsuranceProgramQuery()
  const [isEditDisabled, onEditDisabledToggle] = useToggle(!isCreateForm)
  const history = useHistory()
  const { toast } = useToaster()
  const {
    values,
    errors,
    touched,
    dirty,
    isValid,
    status,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
    setErrors,
    setFieldTouched,
    handleSubmit,
    resetForm,
  } = useFormikContext<Values>()

  const handleDateChange = useCallback(
    (name: string, date: Date | null): void => {
      const transformedDate = date ? dateHelper.formatToMilliseconds(date.toDateString()) : null
      setFieldValue(name, transformedDate)
      setFieldTouched(name, true)
      setErrors({ ...errors })
    },
    [errors, setErrors, setFieldTouched, setFieldValue],
  )
  const handleDropdownChange = useCallback(
    (e: SyntheticEvent<HTMLSelectElement | HTMLButtonElement>): void => {
      const { value, name } = e.currentTarget
      setFieldValue(name, value)
      setFieldTouched(name, true)
      setErrors({ ...errors })
    },
    [errors, setErrors, setFieldTouched, setFieldValue],
  )

  const { handleOnBlurCustom } = useDecimalsOnBlur(setFieldValue)

  const handleSaveClick = useCallback(
    (updatedNote?: string) => {
      const mappedValues = insuranceProgramPropertiesMapper(values, updatedNote)
      create(mappedValues)
    },
    [create, values],
  )

  const handleSearchById = useCallback(
    (e) => {
      const { value } = e.currentTarget
      if (value.length) {
        getInsuranceProgram(value)
      }
    },
    [getInsuranceProgram],
  )

  useEffect(() => {
    if (existingInsuranceProgram?.id && isExistingInsuranceProgramSuccess) {
      setFieldError('id', `${existingInsuranceProgram?.id} is an existing ID`)
    }
    if (isErrorInsuranceProgram && (error as FetchBaseQueryError)?.status === 404) {
      setFieldError('id', undefined)
    }
  }, [
    existingInsuranceProgram,
    isErrorInsuranceProgram,
    isExistingInsuranceProgramSuccess,
    setFieldError,
    error,
  ])

  useEffect(() => {
    if (isSuccess && data) {
      toast({
        message: `Insurance Program ${data.id} has been ${isCreateForm ? 'created' : 'updated'}.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })

      if (isCreateForm) {
        history.push(`/admin/insurance-programs/${data.id}`)
      }
    } else if (isError) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [data, history, isCreateForm, isError, isSuccess, toast])

  useEffect(() => {
    if (isSuccess) {
      onEditDisabledToggle.on()
    }
  }, [isSuccess, onEditDisabledToggle])

  return (
    <>
      {isCreateForm && (
        <CreateFormHeader
          title="Create insurance program"
          createButtonText="Create"
          titleCy="create-insurance-program-title"
          createButtonCy="create-insurance-program-button"
          isFormDirty={dirty}
          isCreateSuccess={isSuccess}
          isFormValid={isValid && status?.feesAdded}
          isProcessing={isLoading}
          resetForm={resetForm}
          handleSave={handleSaveClick}
        />
      )}
      {!isCreateForm && (
        <InsuranceProgramEditHeader
          insuranceData={insuranceData}
          isFormDirty={dirty}
          isCreateSuccess={isSuccess}
          isFormValid={isValid && status?.feesAdded}
          isProcessing={isLoading}
          editButtonCy="edit-insurance-program-button"
          isEditDisabled={isEditDisabled}
          handleIsEditDisabled={onEditDisabledToggle.toggle}
          resetForm={resetForm}
          handleSave={handleSaveClick}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <FormTextGroup
          title="Basic Info"
          handleChange={handleChange}
          handleBlur={handleBlur}
          isDisabled={isEditDisabled}
          numColumns={2}
          dataCy="insurance-program-basic-info"
          values={[
            {
              name: 'id',
              label: 'Insurance Program ID',
              value: values.id,
              error: errors.id,
              isItemDisabled: isEditDisabled || !isCreateForm,
              placeholder: 'Fort_Ext_1',
              handleOnBlurCustom: handleSearchById,
              dataCy: 'insurance-id-input',
            },
            {
              name: 'name',
              label: 'Insurance Program Name',
              value: values.name,
              error: errors.name,
              isItemDisabled: isEditDisabled,
              placeholder: 'Enter insurance program name',
            },
            {
              name: 'activateFrom',
              label: 'Activate From',
              value: values.activateFrom ? new Date(values.activateFrom) : null,
              error: errors.activateFrom,
              fieldType: 'date',
              selected: values.activateFrom ? new Date(values.activateFrom) : null,
              touched: Boolean(values.activateFrom),
              fullWidth: true,
              isItemDisabled: isEditDisabled,
              placeholder: 'Select date',
              handleDateChange,
            },
            {
              name: 'activateTo',
              label: 'Activate To',
              value: values.activateTo ? new Date(values.activateTo) : null,
              error: errors.activateTo,
              fieldType: 'date',
              selected: values.activateTo ? new Date(values.activateTo) : null,
              touched: Boolean(values.activateTo),
              fullWidth: true,
              isItemDisabled: isEditDisabled,
              placeholder: 'Select date',
              handleDateChange,
            },
            {
              name: 'permittedGeo',
              label: 'Permitted Geo',
              value: values.permittedGeo,
              error: errors.permittedGeo,
              touched: touched.permittedGeo,
              isItemDisabled: isEditDisabled,
              fieldType: 'dropdown',
              options: [
                { value: 'CA', label: 'Canada' },
                { value: 'EU', label: 'European Union' },
                { value: 'UK', label: 'United Kingdom' },
                { value: 'US', label: 'United States' },
              ],
              handleDropdownChange,
            },
            {
              name: 'insuranceProgramNotes',
              label: 'Insurance Program Notes',
              value: values.insuranceProgramNotes,
              error: errors.insuranceProgramNotes,
              touched: touched.insuranceProgramNotes,
              isItemDisabled: isEditDisabled,
              fieldType: 'textarea',
              rows: 1,
            } as TextAreaInputItem,
          ]}
        />
        <InsuranceProgramObligorSection
          isDisabled={isEditDisabled}
          handleDropdownChange={handleDropdownChange}
        />
        <FormTextGroup
          title="Financial Details"
          handleChange={handleChange}
          handleBlur={handleBlur}
          isDisabled={isEditDisabled}
          numColumns={2}
          dataCy="insurance-program-financial-details"
          values={[
            {
              name: 'profitSharePrimaryObligor',
              label: 'Profit Share for Primary Obligor',
              value: values.profitSharePrimaryObligor,
              error: errors.profitSharePrimaryObligor,
              touched: touched.profitSharePrimaryObligor,
              fieldType: 'input',
              suffix: '%',
              placeholder: 'Enter percentage',
              type: InputType.number,
              handleOnBlurCustom,
            } as InputItem,
            {
              name: 'profitSharePartner',
              label: 'Profit Share Partner',
              value: values.profitSharePartner,
              error: errors.profitSharePartner,
              fieldType: 'dropdown',
              isItemDisabled: isEditDisabled,
              options: obligorOptionsList,
              handleDropdownChange,
            } as DropdownItem,
            {
              name: 'extendProfitShare',
              label: 'Extend Profit Share',
              value: values.extendProfitShare,
              error: errors.extendProfitShare,
              touched: touched.extendProfitShare,
              suffix: '%',
              placeholder: 'Enter percentage',
              type: InputType.number,
              handleOnBlurCustom,
            } as InputItem,
            {
              name: 'lossAccrual',
              label: 'Loss Accrual',
              value: values.lossAccrual,
              error: errors.lossAccrual,
              touched: touched.lossAccrual,
              suffix: '%',
              placeholder: 'Enter percentage',
              type: InputType.number,
              handleOnBlurCustom,
            } as InputItem,
            {
              name: 'targetLossToReserveRatio',
              label: 'Target Loss to Reserve Ratio',
              value: values.targetLossToReserveRatio,
              error: errors.targetLossToReserveRatio,
              touched: touched.targetLossToReserveRatio,
              suffix: '%',
              placeholder: 'Enter percentage',
              type: InputType.number,
              handleOnBlurCustom,
            } as InputItem,
            {
              name: 'actualLossToReserveRatio',
              label: 'Actual Loss to Reserve Ratio',
              value: values.actualLossToReserveRatio,
              error: errors.actualLossToReserveRatio,
              touched: touched.actualLossToReserveRatio,
              suffix: '%',
              isItemDisabled: true,
              type: InputType.number,
              handleOnBlurCustom,
            } as InputItem,
            {
              name: 'plannedLossToPremiumRatio',
              label: 'Planned Loss to Premium Ratio',
              value: values.plannedLossToPremiumRatio,
              error: errors.plannedLossToPremiumRatio,
              touched: touched.plannedLossToPremiumRatio,
              suffix: '%',
              placeholder: 'Enter percentage',
              type: InputType.number,
              handleOnBlurCustom,
            } as InputItem,
            {
              name: 'actualLossToPremiumRatio',
              label: 'Actual Loss to Premium Ratio',
              value: values.actualLossToPremiumRatio,
              error: errors.actualLossToPremiumRatio,
              touched: touched.actualLossToPremiumRatio,
              suffix: '%',
              isItemDisabled: true,
              type: InputType.number,
              handleOnBlurCustom,
            } as InputItem,
          ]}
        />
      </Form>
      {!isCreateForm && insuranceData.updatedNote && (
        <UpdatedReasonSection data-cy="insurance-program-notes-section">
          <h2>Updated Reason</h2>
          <p>{insuranceData.updatedNote}</p>
        </UpdatedReasonSection>
      )}
    </>
  )
}

const UpdatedReasonSection = styled.div({
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  padding: '20px 40px 40px',
})

export { InsuranceProgramForm }
