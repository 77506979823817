import type { SupportedCurrencyCode } from '@helloextend/extend-api-client/src/models/currency'
import type { VirtualCardFulfillmentMetaData } from '@helloextend/extend-api-client/src/models/service-order'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery, X_EXTEND_ACCESS_TOKEN } from '../base-query'
import { serviceOrdersApi } from '../service-orders'
import type { ServiceOrder } from '../service-orders/types'
import type { CloseWalletRequest, WalletGetResponse } from './types'

export const virtualCardsApi = createApi({
  baseQuery,
  reducerPath: 'VirtualCards',
  tagTypes: ['wallet', 'marqeta-access-token'],
  endpoints: (build) => ({
    activateCard: build.mutation<
      { walletId: string; cardId: string },
      { walletId: string; accessToken?: string }
    >({
      query: ({ walletId, accessToken }) => ({
        url: `/virtual-cards/wallet/${walletId}/activate`,
        headers: {
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
        method: 'POST',
      }),
      invalidatesTags: ['wallet'],
    }),
    getMarqetaAccessToken: build.query<
      { accessToken: string },
      { walletId: string; accessToken?: string }
    >({
      query: ({ walletId, accessToken }) => ({
        url: `/virtual-cards/wallet/${walletId}/access-token`,
        headers: {
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
        method: 'GET',
      }),
      providesTags: (_, _err, accessToken) => [
        { type: 'marqeta-access-token', marqetaAccessToken: accessToken },
      ],
    }),
    getVirtualCardRemainingValue: build.query<
      { remainingValue: { amount: number; currencyCode?: SupportedCurrencyCode } },
      { walletId: string; accessToken?: string }
    >({
      query: ({ walletId, accessToken }) => ({
        url: `/virtual-cards/wallet/${walletId}/remaining-value`,
        headers: {
          [X_EXTEND_ACCESS_TOKEN]: accessToken,
        },
        method: 'GET',
      }),
      providesTags: (_, _err, walletId) => [{ type: 'wallet', walletId }],
    }),
    closeWallet: build.mutation<{ id: string }, CloseWalletRequest>({
      query: ({ walletId, body }) => ({
        url: `/virtual-cards/wallet/${walletId}/close`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ serviceOrderId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          serviceOrdersApi.util.updateQueryData(
            'getServiceOrder',
            { serviceOrderId },
            (serviceOrder: ServiceOrder) => {
              return {
                ...serviceOrder,
                status: 'closed',
                closedMetaData: {
                  resolution: 'service_change',
                  closedAt: Date.now(),
                },
                fulfillmentMetaData: {
                  ...(serviceOrder.fulfillmentMetaData as VirtualCardFulfillmentMetaData),
                  status: 'cancelled',
                },
              }
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: ['wallet'],
    }),
    getWallet: build.query<WalletGetResponse, string>({
      query: (walletId) => ({
        url: `/virtual-cards/wallet/${walletId}`,
        method: 'GET',
      }),
      providesTags: (_, _err, walletId) => [{ type: 'wallet', walletId }],
    }),
  }),
})

export const {
  useActivateCardMutation,
  useGetMarqetaAccessTokenQuery,
  useGetVirtualCardRemainingValueQuery,
  useGetWalletQuery,
  useCloseWalletMutation,
} = virtualCardsApi
