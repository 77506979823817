import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { CurrencyInputGroup } from '@helloextend/merchants-ui'
import type { CurrencyInputItem } from './types'

type CurrencyItemProps = {
  handleBlur: (e: SyntheticEvent) => void
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
  isDisabled: boolean
  item: CurrencyInputItem
  autoFocus?: boolean
}

const CurrencyItem: FC<CurrencyItemProps> = ({
  setFieldValue,
  handleBlur,
  isDisabled,
  item,
  autoFocus,
}) => {
  return (
    <TextInputWrapper>
      <CurrencyInputGroup
        showSymbol={false}
        isCurrencyCodeDisabled
        name={item.name}
        label={item.label}
        value={item.value}
        onChange={setFieldValue}
        onBlur={handleBlur}
        isDisabled={item.isItemDisabled ?? isDisabled}
        errorMessage={item.error ?? ''}
        currencyProps={item.currencyProps}
        autoFocus={autoFocus}
      />
    </TextInputWrapper>
  )
}

const TextInputWrapper = styled.div({
  width: '100%',
  marginRight: 24,
  label: {
    fontWeight: 800,
  },
})

export type { CurrencyItemProps }
export { CurrencyItem }
