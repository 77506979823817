import styled from '@emotion/styled'
import { Button, InputType, Add, Close } from '@extend/zen'
import type { FormikErrors } from 'formik'
import { FieldArray, useFormikContext, getIn } from 'formik'
import type { FC } from 'react'
import React from 'react'
import type { DropdownProps } from '../../../components/dropdown'
import { FormTextGroup } from '../../../components/form-text-group'
import { useDecimalsOnBlur } from '../../../hooks/use-decimals-on-blur'
import { obligorOptionsList } from '../../../utils/obligor-options-list'
import { InsuranceProgramFeesSection } from './insurance-program-fees-section'
import type { Values } from './schema'

type InsuranceProgramObligorSectionProps = {
  isDisabled?: boolean
  handleDropdownChange: DropdownProps['onChange']
}

const getFormikError = (errors: FormikErrors<Values>, index: number, fieldName: string): string => {
  const error = getIn(errors, `obligor.[${index}].${fieldName}`)
  return error ?? ''
}

// workaround for using index in the key property
const getElementKey = (fieldName: string, index: number): string => `${fieldName}-${index}`

const InsuranceProgramObligorSection: FC<InsuranceProgramObligorSectionProps> = ({
  isDisabled = false,
  handleDropdownChange,
}) => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<Values>()

  const { handleOnBlurCustom } = useDecimalsOnBlur(setFieldValue)

  return (
    <FieldArray
      name="obligor"
      render={({ push, remove }) => (
        <>
          {/* [''] is used to render at least one obligor section */}
          {(values.obligor || ['']).map((obligor, index) => (
            <div key={getElementKey('obligorSection', index)} data-cy={`obligor-section-${index}`}>
              <FormTextGroupWrapper
                title={`${index === 0 ? 'Primary' : 'Secondary'} Obligor`}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isDisabled={isDisabled}
                numColumns={2}
                values={[
                  {
                    name: `obligor[${index}]name`,
                    label: 'Obligor',
                    value: obligor.name,
                    error: getFormikError(errors, index, 'name'),
                    touched: touched?.obligor?.[index]?.name,
                    fieldType: 'dropdown',
                    isItemDisabled: isDisabled,
                    options: obligorOptionsList,
                    handleDropdownChange,
                  },
                  {
                    name: `obligor[${index}]riskOwnership`,
                    label: `Risk ${index === 0 ? 'Ownership' : 'Transfer'}`,
                    value: obligor.riskOwnership,
                    error: getFormikError(errors, index, 'riskOwnership'),
                    touched: touched?.obligor?.[index]?.riskOwnership,
                    isItemDisabled: isDisabled,
                    // @ts-ignore
                    suffix: '%',
                    placeholder: 'Enter percentage',
                    type: InputType.number,
                    handleOnBlurCustom,
                  },
                  {
                    name: `obligor[${index}]notes`,
                    label: 'Notes',
                    value: obligor.notes ?? '',
                    isItemDisabled: isDisabled,
                    error: getFormikError(errors, index, 'notes'),
                    touched: touched?.obligor?.[index]?.notes,
                    fieldType: 'textarea',
                    rows: 1,
                  },
                ]}
              >
                {index > 0 && (
                  <DeleteButtonWrapper>
                    <Button
                      icon={Close}
                      emphasis="low"
                      color="neutral"
                      onClick={() => remove(index)}
                      data-cy={`remove-secondary-obligor-button-${index}`}
                    />
                  </DeleteButtonWrapper>
                )}
                <InsuranceProgramFeesSection
                  obligorIndex={index}
                  handleDropdownChange={handleDropdownChange}
                  isDisabled={isDisabled}
                />
              </FormTextGroupWrapper>
            </div>
          ))}
          <AddButtonWrapper>
            <Button
              text="Add Obligor"
              onClick={() => push('')}
              emphasis="medium"
              icon={Add}
              data-cy="add-secondary-obligor-button"
              isDisabled={isDisabled}
            />
          </AddButtonWrapper>
        </>
      )}
    />
  )
}

const FormTextGroupWrapper = styled(FormTextGroup)({
  position: 'relative',
})

const AddButtonWrapper = styled.div({
  marginBottom: 24,
  marginTop: -16,
})

const DeleteButtonWrapper = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
  transform: 'translate(-50%, 50%)',
})

export { InsuranceProgramObligorSection }
