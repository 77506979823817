import type { FC } from 'react'
import React from 'react'
import type { DefaultMessage } from '@helloextend/extend-api-rtk-query'
import ReactMarkdown from 'react-markdown'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import type { ProductSelectPrompt, Reply } from '@helloextend/extend-api-client'
import { ProductSelector } from '@helloextend/kaley-ui'
import { getMessagesContent } from '../../utils'
import type { UserInputComponentProps } from './types'

export interface AnswerProductSelectionProps extends UserInputComponentProps<Reply & { prompt: ProductSelectPrompt }> {}

export const AnswerProductSelection: FC<AnswerProductSelectionProps> = ({
  isLoading,
  onSubmit,
  reply,
}) => {
  const content = getMessagesContent(reply.messages as DefaultMessage[])

  return (
    <ContentWrapper data-cy="answer-product-selection">
      {content && (
        <h2 data-cy="message-content">
          <ReactMarkdown>{content}</ReactMarkdown>
        </h2>
      )}
      <SelectorWrapper>
        <ProductSelector
          prompt={reply.prompt}
          onSelect={onSubmit}
          isLoading={isLoading || false}
          data-cy="product-selector"
        />
      </SelectorWrapper>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  width: '100%',
  maxWidth: 816,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
})

const SelectorWrapper = styled.div({
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: '8px',
  padding: '32px 0px',
  width: '640px',
  maxHeight: '400px',
  marginBottom: '20px',
  overflowY: 'auto',
})
