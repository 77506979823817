import type { FC, MouseEventHandler } from 'react'
import React, { useRef, useMemo, useEffect, useCallback, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import type { ThreadResponse, ThreadTypes, ThreadListItem } from '@helloextend/extend-api-rtk-query'
import { useGetConversationQuery, useGetThreadQuery } from '@helloextend/extend-api-rtk-query'
import { batch, useDispatch, useSelector } from 'react-redux'
import {
  getSelectedConversation,
  getEditorVisibility,
  getSelectedThread,
  getSelectedThreadMessageBlock,
  getSingleUseThreadsMap,
  getReusableThreadPickerVisibility,
  getIsPublishValidationModeActive,
  getCurrentMessageBlockReferenceMap,
  getIsRenameConversationModalVisible,
} from '../../../../reducers/selectors'
import type { RootState } from '../../../../reducers'
import {
  setIsEditorPanelVisible,
  setSelectedConversation,
  resetSelectedConversation,
  resetThread,
  updateSingleUseThreads,
  setIsReusableThreadPickerVisible,
  resetSingleUseThreads,
  setIsPublishValidationModeActive,
  setMessageBlockReferenceCountingMapFromFullConversation,
  getReusableThreadTypes,
  setIsRenameConversationModalVisible,
} from '../../../../store/slices/amp-slice'
import { AdjudicationTopnav } from '../components'
import { ConversationAppendButtons } from '../components/conversation-append-buttons'
import { ConversationEditorThreadPanel } from '../components/conversation-editor-thread-panel'
import { BlockEditor } from '../components/block-editor'
import { CollapsibleConversation } from '../components/collapsible-conversation/collapsible-conversation'
import { useGetFullConversation } from '../../../../hooks/use-get-full-conversation'
import { usePersistConversation } from '../../../../hooks/use-persist-conversation'
import { compareConversationsDeepEquality, isMessageBlockTerminating } from '../utils'
import type { Conversation, FullConversation } from '../../../../types/conversations'
import { ThreadPlaceholder } from '../components/thread-placeholder/thread-placeholder'
import { ConfirmationModal } from '../../../../components/confirmation-modal'
import { ConversationPublishInlineAlert } from '../components/conversation-publish-inline-alert'
import { LeavePageGuard } from '../../../../components/leave-page-guard'
import type { PublishValidationDetails } from '../../../../store/slices/amp-validation'
import {
  getIsMessageBlockTextAssigned,
  isConversationStructureValid,
} from '../../../../store/slices/amp-validation'
import { defaultThreadItem } from '../mocks/mocked-thread-list-items'
import { EditWarningModal } from '../components/edit-warning-modal/edit-warning-modal'
import { RenameConversationModal } from '../components/rename-conversation-modal'

const AdjudicationConversationEdit: FC = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const selectedConversation = useSelector((state: RootState) => getSelectedConversation(state))
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState<boolean>(false)
  const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(false)
  const [publishValidationMap, setPublishValidationDetails] = useState<PublishValidationDetails>({})
  const [isEditWarningModalVisible, setIsEditWarningModalVisible] = useState<boolean>(false)
  const [threadToEdit, setThreadToEdit] = useState<ThreadListItem>(defaultThreadItem)

  const { data: partialConversation, isLoading } = useGetConversationQuery(id)

  const lastThreadId = useMemo(() => {
    const lastThreadIdKey = selectedConversation?.threads[selectedConversation?.threads.length - 1]
    if (lastThreadIdKey) {
      const sanitizedId = lastThreadIdKey.split(':').shift()

      return sanitizedId !== undefined ? sanitizedId : ''
    }
    return ''
  }, [selectedConversation])

  const { data: lastThreadInConversation } = useGetThreadQuery(lastThreadId, {
    skip: !lastThreadId,
  })

  const history = useHistory()
  const isEditorPanelVisible = useSelector((state: RootState) => getEditorVisibility(state))

  const previewRef = useRef<HTMLDivElement>(null)
  const whitespaceRef = useRef<HTMLDivElement>(null)
  const conversationBlocksRef = useRef<HTMLDivElement>(null)
  const selectedThread = useSelector((state: RootState) => getSelectedThread(state))

  const singleUseThreadsMap = useSelector((state: RootState) => getSingleUseThreadsMap(state))
  const selectedMessageBlock = useSelector((state: RootState) =>
    getSelectedThreadMessageBlock(state),
  )
  const isPublishValidationModeActive = useSelector((state: RootState) =>
    getIsPublishValidationModeActive(state),
  )

  const messageBlockReferenceMap = useSelector((state: RootState) =>
    getCurrentMessageBlockReferenceMap(state),
  )

  // single-use thread status always matches the conversations
  // checking conversation status sets if thread structures are locked or not
  const isThreadStructureLocked = selectedConversation?.status !== 'draft'
  const {
    conversation: fullConversation,
    isLoading: isFullConversationLoading,
    error: hasFullConversationError,
  } = useGetFullConversation(id)
  const {
    handlePersistConversation,
    isPersistConversationProcessing,
    hasSuccessfullyPublished,
    hasSuccessfullySaved,
  } = usePersistConversation()

  // put fetched single use threads into redux
  useEffect(() => {
    if (fullConversation && !isFullConversationLoading && !hasFullConversationError) {
      fullConversation.threads?.forEach((thread) => {
        if (!thread || thread.type !== 'single_use') return
        dispatch(updateSingleUseThreads(thread))
      })
      dispatch(setMessageBlockReferenceCountingMapFromFullConversation(fullConversation))
    }
  }, [dispatch, fullConversation, hasFullConversationError, isFullConversationLoading])

  const isConversationDirty = useMemo(() => {
    if (!fullConversation || !selectedConversation || !partialConversation) return false
    const fullActiveConversationThreads = selectedConversation.threads.map(
      (threadString: string) => {
        const threadId = threadString.split(':').shift() as string
        const fullThread =
          singleUseThreadsMap?.[threadId] ??
          fullConversation.threads.find((fullConvoThread) => fullConvoThread?.id === threadId)
        return fullThread
      },
    )

    const fullSelectedConversation: FullConversation = {
      ...selectedConversation,
      threads: fullActiveConversationThreads ?? [],
    }

    return !compareConversationsDeepEquality(fullConversation, fullSelectedConversation)
  }, [selectedConversation, fullConversation, singleUseThreadsMap, partialConversation])

  const isReusableThreadPickerVisible = useSelector((state: RootState) =>
    getReusableThreadPickerVisibility(state),
  )
  const isEndOfConversationsCTAsVisible = useMemo(() => {
    // always render when conversation is empty
    if (selectedConversation?.threads.length === 0 && !isReusableThreadPickerVisible) return true

    // selected threads will handle CTAs via end-of-thread-ctas
    if (selectedThread) {
      return false
    }

    // when there is not a selectedThread: 3 scenarios to check
    // 1) last thread is reusable: render ctas
    if (
      !isReusableThreadPickerVisible &&
      lastThreadInConversation &&
      lastThreadInConversation.type !== 'single_use'
    ) {
      return true
    }

    // 2) last thread single_use & ends with termination block: render ctas
    if (
      !isReusableThreadPickerVisible &&
      lastThreadInConversation &&
      lastThreadId &&
      singleUseThreadsMap[lastThreadId]
    ) {
      const lastMessageBlockIndex = singleUseThreadsMap[lastThreadId].script.length - 1
      const lastMessageBlock = singleUseThreadsMap[lastThreadId].script[lastMessageBlockIndex]
      return isMessageBlockTerminating(lastMessageBlock)
    }

    // 3) last thread is single_use & does not end with termination block: don't render ctas
    return false
  }, [
    selectedConversation?.threads.length,
    selectedThread,
    isReusableThreadPickerVisible,
    lastThreadInConversation,
    lastThreadId,
    singleUseThreadsMap,
  ])

  const isPlaceholderVisible = useMemo(() => {
    // selected threads will handle placeholder via end-of-thread placeholder
    if (selectedThread) {
      return false
    }

    return isReusableThreadPickerVisible
  }, [isReusableThreadPickerVisible, selectedThread])

  useEffect(() => {
    const maybeToggleEditPane = (event: MouseEvent): void => {
      const clickedElement = event.target as Node

      // Close editor pane on preview white space click
      if (
        clickedElement === previewRef.current ||
        clickedElement.nodeName === 'SECTION' ||
        clickedElement === conversationBlocksRef.current ||
        (whitespaceRef.current && whitespaceRef.current.contains(clickedElement))
      ) {
        dispatch(setIsEditorPanelVisible(false))
        dispatch(setIsReusableThreadPickerVisible(false))
        dispatch(resetThread())
      }
    }
    window.addEventListener('click', maybeToggleEditPane)

    return () => {
      window.removeEventListener('click', maybeToggleEditPane)
    }
  })

  const isSingleUseThreadValidForSaving = (thread?: ThreadResponse): boolean => {
    if (!thread) return false
    return thread.script?.every((script) => getIsMessageBlockTextAssigned(script))
  }

  const isConversationValidForSaving = useMemo(() => {
    return Object.values(singleUseThreadsMap)?.every((singleUseThread) =>
      isSingleUseThreadValidForSaving(singleUseThread),
    )
  }, [singleUseThreadsMap])

  const isConversationValidForPublishing = useMemo(() => {
    const conversationForValidityChecking: Conversation = {
      singleUseThreads: Object.values(singleUseThreadsMap),
      reusableThreadsTypes: selectedConversation
        ? getReusableThreadTypes(selectedConversation)
        : [],
      firstThreadType: selectedConversation
        ? (selectedConversation.threads[0]?.split(':')[1] as ThreadTypes)
        : null,
      messageBlockReferenceMap,
    }
    const [isStructureValid, publishDetails] = isConversationStructureValid(
      conversationForValidityChecking,
    )
    setPublishValidationDetails(publishDetails)
    return isConversationValidForSaving && isStructureValid
  }, [
    isConversationValidForSaving,
    selectedConversation,
    singleUseThreadsMap,
    messageBlockReferenceMap,
  ])

  const isSaveEnabled = useMemo(() => {
    return (
      isConversationDirty &&
      Boolean(fullConversation) &&
      !hasFullConversationError &&
      isConversationValidForSaving
    )
  }, [
    fullConversation,
    hasFullConversationError,
    isConversationDirty,
    isConversationValidForSaving,
  ])

  const threadIds: string[] = useMemo(() => {
    return selectedConversation?.threads.map((thread) => thread.split(':').shift() as string) || []
  }, [selectedConversation])

  const isPublishEnabled = useMemo(() => {
    return partialConversation?.status === 'draft'
  }, [partialConversation])

  // We don't need to consider detecting a terminating condition here because the CTA visibility flag will decide if this is even consumed for styling.
  const lastMessageBlockIndex = useMemo(() => {
    if (lastThreadInConversation && lastThreadInConversation.type === 'single_use')
      return lastThreadInConversation.script.length - 1
    return -1
  }, [lastThreadInConversation])

  useEffect(() => {
    if (partialConversation && !isLoading) {
      dispatch(setSelectedConversation(partialConversation))
    }
  }, [partialConversation, dispatch, isLoading])

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(resetSelectedConversation())
        dispatch(resetThread())
        dispatch(setIsEditorPanelVisible(false))
        dispatch(resetSingleUseThreads())
      })
    }
  }, [dispatch])

  const handleLeaveBuilder = useCallback(
    (path = '/admin/adjudication-management/conversations', hasSearch = true): void => {
      batch(() => {
        dispatch(resetThread())
        dispatch(setIsEditorPanelVisible(false))
        history.push({
          pathname: path,
          search: hasSearch ? search : '',
          state: {
            from: history.location?.pathname || '/admin/adjudication-management/conversations',
          },
        })
      })
    },
    [dispatch, history, search],
  )

  if (hasSuccessfullySaved && search) {
    // after first successful save clear search values from query parameters
    handleLeaveBuilder(history.location?.pathname, false)
  }

  if (hasSuccessfullyPublished) {
    handleLeaveBuilder('/admin/adjudication-management/conversations', false)
  }

  const handleClickEditThread = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    if (!selectedThread) return
    const threadListItem: ThreadListItem = {
      ...selectedThread,
    }

    setThreadToEdit(threadListItem || defaultThreadItem)
    setIsEditWarningModalVisible(true)
  }, [selectedThread])

  const resetEditWarningModalState = (): void => {
    setIsEditWarningModalVisible(false)
  }

  const handleEditWarningModalConfirm = (): void => {
    if (threadToEdit) {
      const contentPath = threadToEdit.type === 'adjudication' ? '/content' : ''
      history.push(`/admin/adjudication-management/threads/${threadToEdit.id}/edit${contentPath}`, {
        from: history.location?.pathname || '/admin/adjudication-management/conversations',
      })
      resetEditWarningModalState()
    }
  }

  const handleEditWarningModalCancel = (): void => {
    resetEditWarningModalState()
  }

  const handleSave = async (): Promise<void> => {
    dispatch(setIsPublishValidationModeActive(false))
    if (fullConversation) {
      handlePersistConversation({
        fullConversation,
        singleUseThreadsMap,
        isSavingAndPublishing: false,
      })
    }
  }
  const handlePublish = async (): Promise<void> => {
    if (fullConversation) {
      await handlePersistConversation({
        fullConversation,
        singleUseThreadsMap,
        isSavingAndPublishing: true,
      })
    }
  }
  const handleToggleConfirmationModal = useCallback((): void => {
    if (isConversationValidForPublishing) {
      setIsConfirmationModalVisible(!isConfirmationModalVisible)
      return
    }

    dispatch(setIsPublishValidationModeActive(true))
  }, [isConfirmationModalVisible, dispatch, isConversationValidForPublishing])

  const handleToggleCancelModal = useCallback((): void => {
    setIsCancelModalVisible(!isCancelModalVisible)
  }, [isCancelModalVisible])

  const handleClose = (): void =>
    isConversationDirty ? handleToggleCancelModal() : handleLeaveBuilder()

  const handleConfirmCancelModel = (): void => handleLeaveBuilder()

  const isPublishAlertVisible = useMemo(() => {
    return isPublishValidationModeActive && !isConversationValidForPublishing
  }, [isPublishValidationModeActive, isConversationValidForPublishing])

  const isRenameModalVisible = useSelector((state: RootState) =>
    getIsRenameConversationModalVisible(state),
  )

  const handleToggleRenameConversation = (): void => {
    dispatch(setIsRenameConversationModalVisible(!isRenameModalVisible))
  }
  return (
    <>
      <LeavePageGuard
        isNavBlocked={isConversationDirty && !isCancelModalVisible}
        handleLeavePage={handleLeaveBuilder}
        mainText="Hang on! You have some unsaved changes"
        detail="If you leave the page without saving, all unsaved changes will be lost."
        overflow="auto"
      />
      <ConfirmationModal
        isVisible={isCancelModalVisible}
        onCancel={handleToggleCancelModal}
        onConfirm={handleConfirmCancelModel}
        isProcessing={false}
        confirmButtonColor="red"
        confirmButtonText="Leave page"
        heading="Hang on! You have some unsaved changes"
        description={`If you leave the page without saving, all unsaved changes will be lost. \n\n Are you sure you want to leave?`}
      />
      {isRenameModalVisible && (
        <RenameConversationModal
          isVisible={isRenameModalVisible}
          onCancel={handleToggleRenameConversation}
          conversation={selectedConversation}
        />
      )}
      <ConfirmationModal
        isVisible={isConfirmationModalVisible}
        onCancel={handleToggleConfirmationModal}
        onConfirm={handlePublish}
        confirmButtonColor="blue"
        confirmButtonText="Save & Publish"
        heading="Save & publish the conversation"
        description={`You are about to publish the ${selectedConversation?.title}. The surfacing of the published conversations can be managed in the conversation configuration settings.`}
        isProcessing={isPersistConversationProcessing}
        data-cy="conversation-builder-publish-confirmation-modal"
      />
      <AdjudicationTopnav
        title="Conversation Builder"
        name={partialConversation?.title ?? ''}
        status={partialConversation?.status}
        isLoading={isLoading}
        onClose={handleClose}
        actionButtons={[
          {
            text: 'Save',
            emphasis: 'medium',
            color: 'blue',
            size: 'small',
            isDisabled: !isSaveEnabled,
            onClick: handleSave,
            'data-cy': 'conversation-builder-save-button',
            isProcessing: isPersistConversationProcessing,
          },
          {
            text: 'Publish',
            size: 'small',
            isDisabled: !isPublishEnabled,
            onClick: handleToggleConfirmationModal,
            'data-cy': 'conversation-builder-publish-button',
          },
        ]}
      />
      <Builder>
        <EditorPanel isVisible={isEditorPanelVisible} data-cy="conversation-editor-panel">
          {selectedThread && selectedThread.type === 'single_use' && selectedMessageBlock ? (
            <BlockEditor
              thread={selectedThread}
              block={selectedMessageBlock}
              isThreadStructureLocked={isThreadStructureLocked}
              isThreadEditor={false}
            />
          ) : (
            <ConversationEditorThreadPanel onClickEditThread={handleClickEditThread} />
          )}
        </EditorPanel>
        <PreviewPanel
          isFullWidth={isEditorPanelVisible}
          ref={previewRef}
          data-cy="conversation-preview-panel"
        >
          {isPublishAlertVisible && (
            <ConversationPublishInlineAlert publishValidationMap={publishValidationMap} />
          )}
          <ConversationWrapper isPublishAlertVisible={isPublishAlertVisible}>
            <CollapsibleConversation threadIds={threadIds} isEditMode ref={conversationBlocksRef} />
          </ConversationWrapper>
          {isEndOfConversationsCTAsVisible && (
            <CTAContainerStyled index={lastMessageBlockIndex}>
              <ConversationAppendButtons description="end-of-conversation" />
            </CTAContainerStyled>
          )}
          {isPlaceholderVisible && (
            <CTAContainerStyled index={lastMessageBlockIndex}>
              <ThreadPlaceholder description="end-of-conversation" />{' '}
            </CTAContainerStyled>
          )}
          <Whitespace ref={whitespaceRef} data-cy="amp-conversation-editor-whitespace" />
        </PreviewPanel>
      </Builder>
      {isEditWarningModalVisible && (
        <EditWarningModal
          isVisible={isEditWarningModalVisible}
          onConfirm={handleEditWarningModalConfirm}
          onCancel={handleEditWarningModalCancel}
          thread={threadToEdit}
        />
      )}
    </>
  )
}

const Builder = styled.div({
  display: 'flex',
  margin: '-40px -32px', // negative margin to offset <DashboardLayout /> padding
})

const ConversationWrapper = styled.div<{ isPublishAlertVisible?: boolean }>(
  ({ isPublishAlertVisible }) => ({
    paddingTop: isPublishAlertVisible ? 80 : 0,
  }),
)

const EditorPanel = styled.div<{ isVisible: boolean }>(({ isVisible }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 520,
  transform: isVisible ? 'translateX(0)' : 'translateX(-100%)',
  transition: '0.25s',
  height: 'calc(100% - 96px)',
  position: 'fixed',
  top: 32,
  left: 0,
  padding: '32px 0 32px',
  borderRight: `1px solid ${COLOR.NEUTRAL[300]}`,
  overflowY: 'auto',
  overflowX: 'hidden',
}))

const PreviewPanel = styled.div<{ isFullWidth: boolean }>(({ isFullWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  marginLeft: isFullWidth ? 520 : 0,
  transition: '0.25s',
  width: '100%',
  padding: `40px 32px`,
  minHeight: '100vh',
}))

const CTAContainerStyled = styled.div<{ index: number }>(({ index }) => ({
  paddingLeft: index >= 9 ? 8 : 0,
}))

const Whitespace = styled.div({
  minHeight: 'calc(100vh - 112px)',
  width: '100%',
})

export { AdjudicationConversationEdit }
