import React from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'

import { Permissions } from './permissions'
import { Roles } from './roles'

export const RoleManagement: FC = () => {
  return (
    <>
      <h1>Role Management</h1>
      <Container>
        <Roles />
        <Permissions />
      </Container>
    </>
  )
}

const Container = styled.main({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
})
