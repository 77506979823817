import extendIcon from './extend-icon.png'
import extendLogoWhite from './extend-logo-white.png'
import extendLogo from './extend-logo.png'
import checkmark from './icons/checkmark.svg'
import crossmark from './icons/crossmark.svg'
import bigCommerce from './platforms/big-commerce.png'
import magento from './platforms/magento.png'
import shopify from './platforms/shopify.png'
import custom from './platforms/custom.png'
import salesforce from './platforms/salesforce-commerce.png'
import volusion from './platforms/volusion.png'
import wooCommerce from './platforms/woocommerce.png'
import products from './products.png'
import rocket from './rocket.png'
import hands from './hands-celebration.png'
import kaleyFullColor from './kaley-full-color.png'
import headphones from './onboarding-preview-headphones.png'
import productPlaceholder from './product-placeholder.png'
import puzzleSparkle from './puzzle-sparkle.gif'
import wavingHand from './waving-hand.png'

const images = {
  extendIcon,
  extendLogo,
  extendLogoWhite,
  checkmark,
  crossmark,
  shopify,
  magento,
  bigCommerce,
  wooCommerce,
  volusion,
  salesforce,
  custom,
  products,
  rocket,
  headphones,
  puzzleSparkle,
  wavingHand,
  hands,
  kaleyFullColor,
  productPlaceholder,
}

export default images
