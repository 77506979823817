import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { DynamicPickerTypes } from '../utils'
import { generateShipmentPicker } from './shipment-picker'
import { generateProductPicker } from './product-picker'

export const getDynamicPickerByType = (type: DynamicPickerTypes): ScriptItem | undefined => {
  const typeToBlockMap: { [key in DynamicPickerTypes]: ScriptItem | undefined } = {
    Shipment: generateShipmentPicker(),
    Item: generateProductPicker(),
  }
  return typeToBlockMap[type]
}

export function getDynamicPickerTitle(pickerType: DynamicPickerTypes): string | undefined {
  switch (pickerType) {
    case DynamicPickerTypes.shipment:
      return 'Shipment'
    case DynamicPickerTypes.item:
      return 'Item'
    default:
      return pickerType
  }
}
