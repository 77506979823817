import { faker } from '@faker-js/faker/locale/en'
import type {
  BundleComponent,
  CategoryProduct,
  CustomBundle,
  Product,
  ProductsSearchItem,
  StandardBundle,
} from '@helloextend/extend-api-client'

export function generateProduct(overrides: any = {}): Product {
  const productName = faker.commerce.productName()
  const skuLike = faker.datatype.uuid()
  const plan1Id = '10001-misc-elec-adh-replace-1y'
  const plan2Id = '10001-misc-elec-adh-replace-2y'

  return {
    brand: faker.company.name(),
    category: faker.commerce.department(),
    description: `Description for ${productName}`,
    identifiers: {
      gtin: `gtin-${skuLike}`,
      sku: `sku-${skuLike}`,
      ...overrides.identifiers,
    },
    imageUrl: faker.image.cats(),
    mfrWarranty: {
      labor: faker.datatype.number(100),
      parts: faker.datatype.number(100),
      url: faker.internet.url(),
      ...overrides.mfrWarranty,
    },
    createdAt: faker.date.past().getTime(),
    plans: [plan1Id, plan2Id],
    price: faker.datatype.number(10000),
    referenceId: skuLike,
    storeId: faker.datatype.uuid(),
    title: `Title - ${productName}`,
    approved: true,
    enabled: true,
    settings: {
      isBundle: false,
      partialReplacementEligible: false,
      isProductReturn: true,
      servicingLocationId: null,
    },
    subproducts: [
      { id: faker.datatype.uuid(), quantity: 1 },
      { id: faker.datatype.uuid(), quantity: 2 },
    ],
    ...overrides,
  }
}

export function generateRdsProduct(
  overrides: Partial<ProductsSearchItem> = {},
): ProductsSearchItem {
  return {
    imageUrl: faker.image.cats(),
    name: `${faker.commerce.productName()}`,
    referenceId: faker.datatype.uuid(),
    parentReferenceId: '',
    matchedVariantCount: 0,
    sku: faker.datatype.uuid(),
    price: faker.datatype.number(10000),
    enabled: true,
    warrantyStatus: 'pending',
    variantCount: 0,
    category: faker.commerce.department(),
    ...overrides,
  }
}

export function generateCategoryProduct(overrides: Partial<CategoryProduct> = {}): CategoryProduct {
  return {
    createdAt: faker.date.past().getTime(),
    updatedAt: faker.date.past().getTime(),
    contractId: faker.datatype.uuid(),
    email: faker.internet.email(),
    fulfillmentDate: faker.date.past().getTime(),
    limitOfLiability: faker.datatype.number(1000),
    lineItemId: faker.datatype.uuid(),
    listPrice: faker.datatype.number(1000),
    manufacturerWarrantyLengthLabor: faker.datatype.number(100),
    manufacturerWarrantyLengthParts: faker.datatype.number(100),
    purchasePrice: faker.datatype.number(1000),
    referenceId: faker.datatype.uuid(),
    title: faker.commerce.productName(),
    transactionDate: faker.date.past().getTime(),
    transactionId: faker.datatype.uuid(),
    status: 'live',
    ...overrides,
  }
}

export function generateCustomBundle(overrides: Partial<CustomBundle> = {}): CustomBundle {
  const { coverage, ...rest } = overrides

  return {
    type: 'custom_bundle',
    contractId: faker.datatype.uuid(),
    limitOfLiability: faker.datatype.number(1000),
    listPrice: faker.datatype.number(1000),
    manufacturerWarrantyLengthLabor: faker.datatype.number(100),
    manufacturerWarrantyLengthParts: faker.datatype.number(100),
    purchasePrice: faker.datatype.number(1000),
    referenceId: faker.datatype.uuid(),
    title: faker.commerce.productName(),
    transactionDate: faker.date.recent().getTime(),
    transactionId: faker.datatype.uuid(),
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    lineItemId: faker.datatype.uuid(),
    ...rest,
  }
}

export function generateStandardBundle(overrides: Partial<StandardBundle> = {}): StandardBundle {
  const { coverage, ...rest } = overrides

  return {
    type: 'standard_bundle',
    contractId: faker.datatype.uuid(),
    limitOfLiability: faker.datatype.number(1000),
    listPrice: faker.datatype.number(1000),
    manufacturerWarrantyLengthLabor: faker.datatype.number(100),
    manufacturerWarrantyLengthParts: faker.datatype.number(100),
    purchasePrice: faker.datatype.number(1000),
    referenceId: faker.datatype.uuid(),
    title: faker.commerce.productName(),
    transactionDate: faker.date.recent().getTime(),
    transactionId: faker.datatype.uuid(),
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    lineItemId: faker.datatype.uuid(),
    status: 'live',
    ...rest,
  }
}

export function generateBundleComponent(overrides: Partial<BundleComponent> = {}): BundleComponent {
  const { coverage, ...rest } = overrides

  return {
    type: 'bundle_component',
    bundleId: faker.datatype.uuid(),
    contractId: faker.datatype.uuid(),
    fulfillmentDate: faker.date.recent().getTime(),
    limitOfLiability: faker.datatype.number(1000),
    lineItemId: faker.datatype.uuid(),
    listPrice: faker.datatype.number(1000),
    manufacturerWarrantyLengthLabor: faker.datatype.number(100),
    manufacturerWarrantyLengthParts: faker.datatype.number(100),
    purchasePrice: faker.datatype.number(1000),
    referenceId: faker.datatype.uuid(),
    title: faker.commerce.productName(),
    transactionDate: faker.date.recent().getTime(),
    transactionId: faker.datatype.uuid(),
    status: 'live',
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    ...rest,
  }
}

export const generateBundleComponents = (bundleId: string, count: number) => {
  return Array.from({ length: count }, () => generateBundleComponent({ bundleId }))
}
