import type { FC } from 'react'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Button, Checkbox, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import {
  useCreateServicerLocationMutation,
  useUpdateServicerLocationMutation,
  useUpdateServicerMutation,
  useDeleteServicerLocationMutation,
} from '@helloextend/extend-api-rtk-query'
import { useFormik } from 'formik'
import { CollapsibleInfoGroup } from '../../../../../components/collapsible-info-group'
import type { CollapsibleInfoGroupProps } from '../../../../../components/collapsible-info-group/collapsible-info-group'
import type { Values } from './schema'
import { getLocationInfoData, schema } from './schema'
import type { Servicer } from '@extend-services/service-orders'
import type { ServicerLocation } from '@helloextend/extend-api-client'
import styled from '@emotion/styled'
import type {
  CreateLocationRequest,
  UpdateLocationRequest,
} from '@helloextend/extend-api-rtk-query/src/servicers/types'

interface ServicerLocationFormProps {
  location?: ServicerLocation
  servicer?: Servicer
  isSuccess?: boolean
  locationIndex?: number
}
const ServicerLocationForm: FC<ServicerLocationFormProps> = (props) => {
  const { location, isSuccess, servicer, locationIndex } = props
  const { toast } = useToaster()

  const [isEditable, setIsEditable] = useState(false)
  const [createLocation] = useCreateServicerLocationMutation()
  const [updateLocation] = useUpdateServicerLocationMutation()
  const [updateServicer] = useUpdateServicerMutation()
  const [deleteServicerLocation] = useDeleteServicerLocationMutation()

  const isPrimaryLocation = servicer?.settings.defaultServicingLocation === location?.id

  useLayoutEffect(() => {
    if (isSuccess && !location) {
      setIsEditable(true)
    }
  }, [isSuccess, location, setIsEditable])

  const { values, errors, resetForm, setFieldValue, handleSubmit } = useFormik<Values>({
    validationSchema: schema,
    initialValues: location || { ...schema.getDefault() },
    onSubmit: () => {
      if (!servicer) return

      const locationProps = {
        servicerId: servicer.id,
        body: {
          contact: servicer.contact,
          ...values,
        },
      }

      if (location) {
        updateLocation({
          ...locationProps,
          servicerLocationId: location.id,
        } as UpdateLocationRequest)
      } else {
        createLocation(locationProps as CreateLocationRequest)
      }

      toast({
        message: `Location ${location?.id ? 'updated' : 'added'}!`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
      toggleIsEditable()
    },
  })

  const toggleIsEditable = useCallback(() => {
    setIsEditable(!isEditable)
  }, [setIsEditable, isEditable])

  const locationInfo = useMemo(
    () => getLocationInfoData(isEditable, location, values, errors),
    [isEditable, location, values, errors],
  )

  const handleChange = (key: string, value: any, shouldValidate?: boolean): void => {
    setFieldValue(key, value, shouldValidate)
  }
  const handleDefaultLocation = (checked: boolean): void => {
    if (checked && servicer?.id) {
      updateServicer({
        servicerId: servicer?.id,
        body: {
          settings: {
            defaultServicingLocation: location?.id,
          },
        },
      })
    }
  }

  const handleDeleteLocation = (): void => {
    if (location && !isPrimaryLocation) {
      deleteServicerLocation({ servicerId: servicer?.id || '', servicerLocationId: location.id })
      toast({
        message: 'Location deleted!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    }
  }

  const locationText = isEditable
    ? `${location ? 'Edit' : 'Add'} Location`
    : `Location ${locationIndex}`

  return (
    <>
      <CollapsibleInfoGroup
        data-cy={locationIndex ? `location-form-${locationIndex}` : 'new-location-form'}
        title={locationText}
        data={locationInfo}
        handleChange={handleChange as CollapsibleInfoGroupProps['handleChange']}
        numColumns={4}
        isCollapsible={false}
        primaryButtonText={isEditable ? 'Save' : undefined}
        onPrimaryButtonClick={handleSubmit}
        secondaryButtonText={location && isEditable ? 'Cancel' : undefined}
        onSecondaryButtonClick={() => {
          resetForm()
          toggleIsEditable()
        }}
        buttonAlignment={isEditable ? 'right' : 'left'}
        hasEditButton={location && !isEditable}
        isEditing={location && isEditable}
        onEditButtonClick={toggleIsEditable}
        children={
          location && (
            <ButtonsContainer>
              <Button
                text="Delete Location"
                onClick={handleDeleteLocation}
                color="blue"
                isDisabled={!!isPrimaryLocation}
                data-cy="delete-location-button"
              ></Button>
              <Checkbox
                checked={isPrimaryLocation}
                onChange={(e) => handleDefaultLocation(e.target.checked)}
                label="Default Location"
                disabled={isPrimaryLocation}
                data-cy="default-location-checkbox"
              />
            </ButtonsContainer>
          )
        }
      />
    </>
  )
}

const ButtonsContainer = styled.div({
  display: 'flex',
  gap: 8,
  justifyContent: 'flex-end',
  marginTop: 16,
})
export { ServicerLocationForm }
