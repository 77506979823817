import type { Plan } from '@helloextend/extend-api-client'
import type { ProductCsvValidatorPipe, ProductHeader } from './product-csv-headers'
import { MAX_TOTAL_PRODUCT_PLANS } from '../constants'

/**
 * Validates that all plans in the product share the same adjudication category
 * "adjudication category" === `plan.contract.category`
 * @param meta - piped object
 */
const validatePlanAdjudicationCategories = (
  meta: ProductCsvValidatorPipe,
  plansById: Record<string, Plan> = {},
): ProductCsvValidatorPipe => {
  const warrantyCategorySet = new Set<string>()
  for (let planIdNumber = 1; planIdNumber <= MAX_TOTAL_PRODUCT_PLANS; planIdNumber += 1) {
    const planIdIndex = meta.headerIndexes[`planId${planIdNumber}` as ProductHeader]
    const planId = meta.rowData[planIdIndex]
    const plan = plansById[planId]

    if (plan) {
      warrantyCategorySet.add(plan.contract.category)
    }
  }

  if (warrantyCategorySet.size > 1) {
    meta.errors.push(
      `All plans must be in the same adjudication category. Found categories: ${Array.from(
        warrantyCategorySet,
      ).join(', ')}`,
    )
  }

  return meta
}

export { validatePlanAdjudicationCategories }
