import type { ContractNote } from '@helloextend/extend-api-client'
import type { FC} from 'react';
import React, { useMemo, useState } from 'react'
import type { ColumnFiltersState, VisibilityState } from '@extend/zen';
import { DataTable } from '@extend/zen'
import {
  useListClaimsAssignmentUsersQuery,
  useSearchContractNotesQuery,
} from '@helloextend/extend-api-rtk-query'
import {
  filteredContractNotes,
  getUniqueClaimsUsers,
  defaultColumnVisibility,
  getTableColumns,
  getColumnFilterDefinitions,
  getTableData,
  defaultColumnFilterState,
} from '../utils'
import { ContractNoteTableRowDetail } from './contract-note-table-row-detail'

export const ContractNotesTable: FC = () => {
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(defaultColumnVisibility)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(defaultColumnFilterState)

  const { data: usersList } = useListClaimsAssignmentUsersQuery(undefined)

  const {
    data: contractNotes = [],
    isLoading,
    isFetching,
  } = useSearchContractNotesQuery({
    type: 'escalation',
  })

  const { uniqueClaimsUsersMap, uniqueClaimsUsersArray, uniqueClaimsUserNameOptions } =
    useMemo(() => {
      return getUniqueClaimsUsers(usersList?.items || [])
    }, [usersList?.items])

  const columns = useMemo(() => {
    return getTableColumns(uniqueClaimsUsersMap, uniqueClaimsUsersArray)
  }, [uniqueClaimsUsersMap, uniqueClaimsUsersArray])

  const columnFilterDefinitions = useMemo(() => {
    return getColumnFilterDefinitions(contractNotes, uniqueClaimsUserNameOptions)
  }, [contractNotes, uniqueClaimsUserNameOptions])

  const filteredNotes = useMemo(() => {
    return filteredContractNotes(contractNotes, columnFilters)
  }, [contractNotes, columnFilters])

  const contractNotesColumnData = useMemo(() => {
    return getTableData(filteredNotes)
  }, [filteredNotes])

  const handleRowClick = ({ contractId }: ContractNote): void => {
    window.open(`/admin/contracts/${contractId}`, '_blank')
  }

  return (
    <DataTable
      itemName="contract-note"
      data-cy="contracts-notes-table"
      filterDefs={columnFilterDefinitions}
      columns={columns}
      data={contractNotesColumnData as ContractNote[]}
      isLoading={isLoading || isFetching}
      rowDetailRenderer={(row) => <ContractNoteTableRowDetail key={row.id} contractNote={row} />}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      hasManualSorting
      hasManualFiltering
      getRowActions={(row) => [
        {
          onClick: () => handleRowClick(row),
          text: 'View',
          emphasis: 'low',
        },
      ]}
    />
  )
}
