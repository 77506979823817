export enum LDFlag {
  OffersFeatures = 'offers-features',
  /* WARP FF */
  LegacyAndEnterpriseRoles = 'legacy-and-enterprise-roles',
  EnterpriseRoles = 'enterprise-roles',
  EnterpriseAccountManagement = 'enterprise-accounts-management',
  EnterpriseAccountProvision = 'enterprise-account-provision',
  OrganizationsManagement = 'organizations-management',
  AssignedPlanSets = 'assigned-plan-sets',
  ShippingProtectionPlans = 'shipping-protection-plans',
  SPPurchaseModel = 'sp-purchase-model',
  LoyaltyProgram = 'loyalty-program',
  MessaginFeaturesConfigurationSendTermsSms = 'messaging-features-configuration-send-terms-sms',
  useV3Token = 'use-v3-token',
  TermsContractRefundInfo = 'terms-contract-refund-info',
  TermsCoverageInfo = 'terms-coverage-info',
  /* WARP FF */
  ExpandedServiceOrdersSection = 'expanded-service-orders-section', // on in production 02/27/23
  WDProductMappingFlag = 'wd-product-mapping-flag',
  ResendRewardEmail = 'so-direct-payment-resend-email', // on in production 08/02/23
  ServicerTwoDayShipping = 'servicer-two-day-shipping', // on in production 08/02/23
  RolePermissionManagement = 'role-permission-mgmt',
  FileShippingClaim = 'file-shipping-claim',
  GoodwillExpense = 'goodwill-expense',
  CharitableDonations = 'charitable-donations',
  PartialReimbursementOptionality = 'partial-reimbursement-optionality',
  NewClaimAttachments = 'new-claim-attachments',
  UseEMSAPI = 'use-ems-api',
  PlanFailureTypeMappingEnabled = 'plans-failure-type-mapping-display-enabled',
  ContractNotes = 'contract-notes-enabled',
  // TODO: [MINT-2603] Remove FF
  AutomatedReplacementFulfillment = 'automated-replacement-fulfillment',
  RateCardTab = 'rate-card-tab',
  NewClaimsPage = 'new-claims-page',
  MSAEnabled = 'msa-enabled',
}

export type LDFlagType = boolean | string | number | JSON

export const defaultLDFlagValues: Record<LDFlag, LDFlagType> = {
  [LDFlag.OffersFeatures]: {
    pdr: { enabled: false },
    spABTesting: { enabled: false },
    ppABTesting: { enabled: false },
    offerCustomization: { enabled: false },
  } as unknown as JSON,
  /* WARP FF */
  [LDFlag.LegacyAndEnterpriseRoles]: false,
  [LDFlag.EnterpriseRoles]: false,
  [LDFlag.EnterpriseAccountManagement]: false,
  [LDFlag.EnterpriseAccountProvision]: false,
  [LDFlag.OrganizationsManagement]: false,
  [LDFlag.AssignedPlanSets]: false,
  [LDFlag.ShippingProtectionPlans]: false,
  [LDFlag.SPPurchaseModel]: false,
  [LDFlag.LoyaltyProgram]: false,
  [LDFlag.MessaginFeaturesConfigurationSendTermsSms]: false,
  [LDFlag.useV3Token]: false,
  [LDFlag.TermsContractRefundInfo]: false,
  [LDFlag.TermsCoverageInfo]: false,
  /* WARP FF */
  [LDFlag.ExpandedServiceOrdersSection]: false,
  [LDFlag.WDProductMappingFlag]: false,
  [LDFlag.ResendRewardEmail]: false,
  [LDFlag.ServicerTwoDayShipping]: false,
  [LDFlag.RolePermissionManagement]: false,
  [LDFlag.FileShippingClaim]: false,
  [LDFlag.GoodwillExpense]: false,
  [LDFlag.CharitableDonations]: false,
  [LDFlag.PartialReimbursementOptionality]: false,
  [LDFlag.NewClaimAttachments]: false,
  [LDFlag.UseEMSAPI]: false,
  [LDFlag.PlanFailureTypeMappingEnabled]: false,
  [LDFlag.ContractNotes]: false,
  // TODO: [MINT-2603] Remove FF
  [LDFlag.AutomatedReplacementFulfillment]: false,
  [LDFlag.RateCardTab]: false,
  [LDFlag.NewClaimsPage]: false,
  [LDFlag.MSAEnabled]: false,
}
