import type { StoreIntegrationSettings } from '@helloextend/extend-api-client'
import * as Yup from 'yup'

type IntegrationSettingsCheckbox = {
  name: keyof StoreIntegrationSettings
  label: string
  platform: 'shopify' | 'all'
}

const checkboxConfig: IntegrationSettingsCheckbox[] = [
  {
    label: 'Auto refunds',
    name: 'autoRefunds',
    platform: 'all',
  },
  {
    label: 'Auto lead creation',
    name: 'createLeads',
    platform: 'all',
  },
  {
    label: 'Plan barcode generation',
    name: 'barcodes',
    platform: 'shopify',
  },
  {
    label: 'Auto fullfil plans for shopint-2',
    name: 'autoFulfillPlans',
    platform: 'shopify',
  },
  {
    label: 'Claim fulfillment',
    name: 'claimsFullfillment',
    platform: 'all',
  },
  {
    label: 'Auto sync on product update',
    name: 'productsUpdate',
    platform: 'all',
  },
  {
    label: 'Auto sync on new product creation',
    name: 'productsCreate',
    platform: 'all',
  },
  {
    label: 'Auto sync on product deletion',
    name: 'productsDelete',
    platform: 'all',
  },
]

const schema = Yup.object()
  .shape({
    offerSyncType: Yup.string<StoreIntegrationSettings['offerSyncType']>().default('off'),
    contractEvent: Yup.string<StoreIntegrationSettings['contractEvent']>().default('orderCreate'),
    createContracts: Yup.boolean().default(false),
    autoRefunds: Yup.boolean(),
    createLeads: Yup.boolean(),
    barcodes: Yup.boolean(),
    autoFulfillPlans: Yup.boolean(),
    claimsFullfillment: Yup.boolean(),
    productsUpdate: Yup.boolean(),
    productsCreate: Yup.boolean(),
    productsDelete: Yup.boolean(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export type { Values }
export { checkboxConfig, schema }
