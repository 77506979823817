import type { Store } from '@helloextend/extend-api-client'
import { reduce } from 'lodash/fp'
import type { Action } from '../../actions'

export type StoresByIdReducerState = Record<string, Store>

const initialState: StoresByIdReducerState = {}

export default function byId(state = initialState, action: Action): StoresByIdReducerState {
  switch (action.type) {
    case 'STORES_FETCH_ALL_SUCCESS':
      return reduce(addStore, state)(action.payload)
    case 'STORES_FETCH_SUCCESS':
      return addStore(state, action.payload)
    default:
      return state
  }
}

function addStore(state: StoresByIdReducerState, store: Store): StoresByIdReducerState {
  return {
    ...state,
    [store.id]: store,
  }
}
