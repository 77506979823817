import type { User } from '@helloextend/extend-api-rtk-query/src/users-v3-api'
import { useLazyResendInviteUserQuery } from '@helloextend/extend-api-rtk-query/src/users-v3-api'
import {
  Button,
  COLOR,
  Menu,
  More,
  ToastColor,
  ToastDuration,
  useToaster,
  Badge,
} from '@extend/zen'
import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { useGetUserGrantsListQuery } from '@helloextend/extend-api-rtk-query'
import { DeactivateUserModal } from './modals/deactivate-user-modal'
import { ResetPasswordModal } from './modals/reset-password-modal'
import { getUserStatus } from '../../../../utils/user-status'
import UserStatusBadge from '../../../../components/user-status-badge/user-status-badge'
import ActivateUserModal from './modals/activate-user-modal'

type UserDetailsHeaderProps = {
  user: User
  canEditUser?: boolean
}

const UserDetailsHeader: FC<UserDetailsHeaderProps> = ({ user, canEditUser }) => {
  const [isActivateModalVisible, setIsActivatedModalVisible] = useState(false)
  const [isDeactivateModalVisible, setIsDeactivatedModalVisible] = useState(false)
  const [isResetPasswordModalVisible, setIsResetPasswordModalVisible] = useState(false)

  const { toast } = useToaster()

  const { data: userGrants } = useGetUserGrantsListQuery(user.email)

  const [resendInvite] = useLazyResendInviteUserQuery()

  const toggleUserActivateModal = useCallback((): void => {
    setIsActivatedModalVisible(!isActivateModalVisible)
  }, [isActivateModalVisible])

  const toggleUserDeactivateModal = useCallback((): void => {
    setIsDeactivatedModalVisible(!isDeactivateModalVisible)
  }, [isDeactivateModalVisible])

  const toggleResetPasswordModal = useCallback((): void => {
    setIsResetPasswordModalVisible(!isResetPasswordModalVisible)
  }, [isResetPasswordModalVisible])

  const handleResendEmailInvite = async (): Promise<void> => {
    if (!user) {
      return
    }
    const response = await resendInvite(user.email)
    if ('error' in response) {
      toast({
        message: `An error occurred when resending invite, please try again later.`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
      return
    }
    toast({
      message: 'Email invite resent!',
      toastDuration: ToastDuration.short,
      toastColor: ToastColor.blue,
    })
  }

  const status = getUserStatus({
    userStatus: user.status,
    userGrants: userGrants?.grants ?? [],
    roleFilter: 'internal',
  })

  return (
    <>
      <SpaceBetween data-cy="user-details-header">
        <AlignCenter>
          <NameHeading data-cy="name-display">{`${user.firstName} ${user.lastName}`}</NameHeading>
          <StatusBadge data-cy="status-badge">
            {user.federated && (
              <FederatedBadge>
                <Badge text="Federated" color="neutral" data-cy="federated-status-badge" />
              </FederatedBadge>
            )}
            {userGrants && (
              <UserStatusBadge
                grants={userGrants.grants}
                oktaUserStatus={user.status}
                roleFilter="internal"
              />
            )}
          </StatusBadge>
        </AlignCenter>
        {canEditUser && userGrants ? (
          <AlignCenter>
            {status === 'Active' && (
              <Menu
                role="listbox"
                triggerRenderer={() => (
                  <Button data-cy="user-actions-menu" icon={More} emphasis="medium" />
                )}
                items={[
                  {
                    label: 'Reset password',
                    type: 'button',
                    onClick: toggleResetPasswordModal,
                    'data-cy': 'reset-password-button',
                  },
                  {
                    label: 'Deactivate',
                    type: 'button',
                    onClick: toggleUserDeactivateModal,
                    'data-cy': 'deactivate-button',
                  },
                ]}
              />
            )}
            {status === 'Pending' && (
              <Button
                color="blue"
                data-cy="resend-email-invite-button"
                emphasis="medium"
                size="regular"
                text="Resend Email Invite"
                onClick={handleResendEmailInvite}
              />
            )}
            {status === 'Deactivated' && (
              <Button
                color="blue"
                emphasis="medium"
                size="regular"
                text="Activate"
                data-cy="activate-button"
                onClick={toggleUserActivateModal}
              />
            )}
          </AlignCenter>
        ) : null}
      </SpaceBetween>

      {/* modals */}
      <ResetPasswordModal
        isVisible={isResetPasswordModalVisible}
        onClickClose={toggleResetPasswordModal}
      />
      <DeactivateUserModal
        email={user?.email ?? ''}
        isVisible={isDeactivateModalVisible}
        onClickClose={toggleUserDeactivateModal}
      />
      <ActivateUserModal
        user={user}
        visible={isActivateModalVisible}
        onClickClose={toggleUserActivateModal}
      />
      {/* modals */}
    </>
  )
}

const SpaceBetween = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const AlignCenter = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
})
const NameHeading = styled.p({
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '24px',
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
})

const StatusBadge = styled.div({
  marginLeft: '2em',
  display: 'flex',
})

const FederatedBadge = styled.div({
  marginRight: 5,
})

export default UserDetailsHeader
