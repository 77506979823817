import {
  DataTable,
  HeadingSmall,
  Info,
  InlineAlert,
  InlineAlertColor,
  Paragraph,
} from '@extend/zen'
import { useFormikContext } from 'formik'
import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import {
  useListFailureCausesQuery,
  useListFailureTypesQuery,
} from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import type { SpPlanFormValues } from '../sp-plan-form-schema'
import {
  SHIPPING_FAILURE_CAUSE_CATEGORY_ID,
  SHIPPING_FAILURE_TYPE_CATEGORY_ID,
  convertFailureTypesToDisplay,
  getDefaultFailureTypesMapping,
} from '../sp-plan-form-schema'
import type { FailureTypesMappingDisplay } from '../../table-config/failure-types-data-table-config'
import { failureTypeMappingColumns } from '../../table-config/failure-types-data-table-config'

type SpPlanFailureTypesSectionProps = {
  mode: 'view' | 'edit'
  isExistingPlan?: boolean
}

const SpPlanFailureTypesSection: FC<SpPlanFailureTypesSectionProps> = ({
  mode,
  isExistingPlan,
}) => {
  const { values } = useFormikContext<SpPlanFormValues>()
  const [programType, setProgramType] = useState<string>('')
  const {
    data: failureTypes,
    isFetching: isFetchingTypes,
    isError: isErrorTypes,
  } = useListFailureTypesQuery(SHIPPING_FAILURE_TYPE_CATEGORY_ID)
  const {
    data: failureCauses,
    isFetching: isFetchingCauses,
    isError: isErrorCauses,
  } = useListFailureCausesQuery(SHIPPING_FAILURE_CAUSE_CATEGORY_ID)

  const { displayFailureTypes } = useMemo(() => {
    let failureTypesMappingDisplay: FailureTypesMappingDisplay[] = []
    if (isFetchingTypes || isFetchingCauses) {
      return { displayFailureTypes: failureTypesMappingDisplay }
    }
    if (programType !== values.programType || !isExistingPlan) {
      const { defaults, displays } = getDefaultFailureTypesMapping(
        values.programType,
        failureTypes?.items ?? [],
        failureCauses?.items ?? [],
      )
      failureTypesMappingDisplay = displays
      if (mode === 'edit') {
        values.failureTypesMapping = defaults
      }
    } else {
      failureTypesMappingDisplay = convertFailureTypesToDisplay(
        values.failureTypesMapping ?? {},
        failureTypes?.items ?? [],
        failureCauses?.items ?? [],
      )
    }
    setProgramType(values.programType)
    return { displayFailureTypes: failureTypesMappingDisplay }
  }, [
    failureCauses?.items,
    failureTypes?.items,
    isExistingPlan,
    isFetchingCauses,
    isFetchingTypes,
    mode,
    programType,
    values,
  ])

  return (
    <>
      <>
        <InlineAlertContainer>
          <InlineAlert
            icon={Info}
            color={InlineAlertColor.neutral}
            data-cy="failure-type-mapping-info"
          >
            <Paragraph>
              SP Plan FailureType Mapping is set based on the Plan Program Type. Defaults are set
              based on SPG, GSP Matrices outlined in{' '}
              <a href="https://helloextend.atlassian.net/wiki/spaces/ENG/pages/1807351834/SPG+Solution+Design">
                SPG Solution Design
              </a>
              . <br />
              Any shipping failure types not specified in the matrix will appear with coverage set
              to false and no liable party assigned. To modify defaults, please contact the PREPX
              team.
            </Paragraph>
          </InlineAlert>
        </InlineAlertContainer>
        <DataTable
          data-cy="failure-types-mapping-table"
          heading={
            <HeadingSmall>
              {programType === 'safe_package_guarantee'
                ? 'Safe Package Guarantee'
                : 'General Shipping Protection'}
            </HeadingSmall>
          }
          itemName="failureTypesMapping"
          data={displayFailureTypes}
          isLoading={isFetchingTypes || isFetchingCauses}
          isError={isErrorTypes || isErrorCauses}
          stateViews={{
            skeleton: { message: 'Please wait while we retrieve failure Types Mapping data' },
            error: { heading: 'Unable to retrieve failure Types Mapping data' },
            noData: {
              heading: 'No failure Types Mapping for this plan',
            },
          }}
          columns={failureTypeMappingColumns}
          hasConfigurableColumns={false}
        />
      </>
    </>
  )
}

const InlineAlertContainer = styled.div({
  marginBottom: 16,
})

export { SpPlanFailureTypesSection }
