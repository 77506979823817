import type { FC } from 'react'
import React from 'react'
import type { LeadsCreatedConfigurationGetResponse, Store } from '@helloextend/extend-api-client'
import { DataProperty, Grid } from '@extend/zen'
import { string as stringHelper } from '@extend/client-helpers'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { revenueModelOptions } from '../../../../../components/product-protection-form/schema'
import { LDFlag } from '../../../../../../../../constants/ld-flags'

type ProductProtectionInfoProps = {
  store: Store
  messagingConfig?: LeadsCreatedConfigurationGetResponse
}

const ProductProtectionInfo: FC<ProductProtectionInfoProps> = ({ store, messagingConfig }) => {
  const { [LDFlag.MessaginFeaturesConfigurationSendTermsSms]: FF_MESSAGING_SMS_TERMS } = useFlags()
  const {
    merchantEwRevenueAgreement,
    merchantEwRevenueSharePercentage,
    partialReimbursementPercentage,
    partialReimbursementEnabled,
    extendCut,
    locationFilteringEnabled,
  } = store
  const revenueModelDisplayValue = revenueModelOptions?.find(
    (option) => option.value === merchantEwRevenueAgreement,
  )?.display

  if (!store) {
    return null
  }
  return (
    <div data-cy="product-protection-info">
      <Grid columns={5} spacing={4}>
        <DataProperty
          data-cy="revenue-model"
          label="Revenue Model"
          value={revenueModelDisplayValue ?? ''}
        />
        <DataProperty
          label="Merchant Revenue Share"
          data-cy="merchant-revenue-share"
          value={
            merchantEwRevenueAgreement !== 'wholesale' && merchantEwRevenueSharePercentage
              ? stringHelper.getPercentageStringFromDecimal(merchantEwRevenueSharePercentage)
              : ''
          }
        />
        <DataProperty
          label="Partial Reimbursement Merchant"
          data-cy="partial-reimbursement-merchant"
          value={
            partialReimbursementEnabled && partialReimbursementPercentage
              ? stringHelper.getPercentageStringFromDecimal(partialReimbursementPercentage)
              : 'Off'
          }
        />
        <DataProperty
          label="Custom ExtendCut"
          data-cy="custom-extendCut"
          value={extendCut ? stringHelper.getPercentageStringFromDecimal(extendCut) : 'Off'}
        />
        <DataProperty
          label="Location Filtering"
          data-cy="location-filtering"
          value={locationFilteringEnabled ? 'On' : 'Off'}
        />
        {FF_MESSAGING_SMS_TERMS && (
          <DataProperty
            label="SMS Terms Enabled"
            data-cy="sms-terms-enabled"
            value={messagingConfig?.enabled ? 'On' : 'Off'}
          />
        )}
      </Grid>
    </div>
  )
}

export { ProductProtectionInfo }
