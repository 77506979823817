import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

type TextAlign = 'left' | 'right' | 'center'

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@extend/zen'`
 */
const CellText = styled.p<{ align?: TextAlign }>(({ align = 'left' }) => ({
  fontSize: 12,
  lineHeight: '24px',
  color: COLOR.NEUTRAL[1000],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: align,
}))

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@extend/zen'`
 */
const CellMarkup = styled.div<{ align?: TextAlign }>(({ align = 'left' }) => ({
  fontSize: 12,
  lineHeight: '24px',
  color: COLOR.NEUTRAL[1000],
  overflow: 'hidden',
  textAlign: align,
  marginBlockStart: '1em',
  marginBlockEnd: '1em',
}))

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@extend/zen'`
 */
const ColumnHead = styled.th<{
  isSelectable?: boolean
  columnWidth?: number | string
  active?: boolean
  textAlign?: TextAlign
}>(({ isSelectable, columnWidth = 100, active, textAlign = 'left' }) => ({
  textAlign,
  paddingLeft: textAlign !== 'right' ? 12 : 1,
  paddingRight: textAlign === 'right' ? 12 : 1,
  paddingTop: 4,
  paddingBottom: 4,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  cursor: isSelectable ? 'pointer' : 'initial',
  lineHeight: 4,
  width: `${columnWidth}%`,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: active ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[600],
}))

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@extend/zen'`
 */
const TableRow = styled.tr({
  borderTop: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
  cursor: 'pointer',
  backgroundColor: 'transparent',
  transition: 'background-color 50ms ease-in',
  '&:hover': {
    backgroundColor: COLOR.NEUTRAL[100],
  },
})

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@extend/zen'`
 */
const CollapsibleTableRow = styled(TableRow)({
  border: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
})

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@extend/zen'`
 */
const TableBody = styled.tbody({
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
})

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@extend/zen'`
 */
const Table = styled.table({
  width: '100%',
  fontFamily: 'Nunito Sans, sans-serif',
  fontSize: 12,
  lineHeight: '16px',
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
})

export type { TextAlign }

export { CellText, CellMarkup, ColumnHead, TableRow, TableBody, Table, CollapsibleTableRow }
