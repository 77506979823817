import type { DraftPriceBand, PriceBandErrors } from '@helloextend/extend-api-client'
import type { PlanIdsCoverageTypeMap } from '@helloextend/extend-api-client/src/models/plan-sets'
import * as PlanSetConstants from './constants'
import type { PriceBandInputFields } from '../components/price-band'
import { MIN_PRICEBAND_COUNT, MAX_COVERAGE_TYPE_COUNT } from './constants'

const {
  PLANSET_MISSING_PLAN_CATEGORY,
  PRICEBAND_INVALID_MIN_MAX_ORDER,
  PRICEBAND_INVALID_MIN_PRICE,
  PRICEBAND_INVALID_MIN_PRICE_HIGH,
  PRICEBAND_INVALID_MAX_PRICE,
  PRICEBAND_INVALID_MAX_PRICE_LOW,
  PRICEBAND_MISSING_PLANS,
  PRICEBAND_MAX_COVERAGE_TYPE_EXCEEDED,
} = PlanSetConstants.VALIDATION_MESSAGES

export const getPlanCategoryErrors = (value: string): string[] =>
  value === '' ? [PLANSET_MISSING_PLAN_CATEGORY] : []

export const getPriceBandFieldErrors = (
  priceBand: DraftPriceBand,
  field: PriceBandInputFields,
  planIdsCoverageTypeMap?: PlanIdsCoverageTypeMap,
): PriceBandErrors => {
  return {
    ...priceBand.errors,
    ...(field === 'plans'
      ? { plansErrors: getPriceBandPlansErrors(priceBand, planIdsCoverageTypeMap) }
      : { priceErrors: getPriceBandPriceErrors(priceBand) }),
  }
}

export const getPriceBandPriceErrors = (priceBand: DraftPriceBand): string[] => {
  const errors = []
  let { minPrice, maxPrice } = priceBand
  minPrice = Number(minPrice)
  maxPrice = Number(maxPrice)

  // Min must be less than max
  if (minPrice >= maxPrice) {
    errors.push(`- ${PRICEBAND_INVALID_MIN_MAX_ORDER}`)
  }

  // Validate min price >= floor (since it's inclusive)
  if (minPrice < PlanSetConstants.INCLUSIVE_PRICEBAND_MIN) {
    errors.push(`- ${PRICEBAND_INVALID_MIN_PRICE}`)
  }

  // Validate min price <= ceiling (since it's inclusive)
  if (minPrice >= PlanSetConstants.INCLUSIVE_PRICEBAND_MAX) {
    errors.push(`- ${PRICEBAND_INVALID_MIN_PRICE_HIGH}`)
  }

  // Validate max price <= ceiling (since it's inclusive)
  if (maxPrice > PlanSetConstants.INCLUSIVE_PRICEBAND_MAX) {
    errors.push(`- ${PRICEBAND_INVALID_MAX_PRICE}`)
  }

  // Validate max price >= floor (since it's inclusive)
  if (maxPrice <= PlanSetConstants.INCLUSIVE_PRICEBAND_MIN) {
    errors.push(`- ${PRICEBAND_INVALID_MAX_PRICE_LOW}`)
  }

  return errors
}

export const getPriceBandPlansErrors = (
  priceBand: DraftPriceBand,
  planIdsCoverageTypeMap?: PlanIdsCoverageTypeMap,
): string[] => {
  const errors = []
  const { plans } = priceBand

  if (plans.length < MIN_PRICEBAND_COUNT) {
    errors.push(`- ${PRICEBAND_MISSING_PLANS}`)
  }

  if (!planIdsCoverageTypeMap) return errors

  const basePlanCount = plans.filter((planId) => planIdsCoverageTypeMap[planId] === 'base').length
  const adhPlanCount = plans.filter((planId) => planIdsCoverageTypeMap[planId] === 'adh').length

  if (basePlanCount > MAX_COVERAGE_TYPE_COUNT || adhPlanCount > MAX_COVERAGE_TYPE_COUNT) {
    errors.push(`- ${PRICEBAND_MAX_COVERAGE_TYPE_EXCEEDED}`)
  }

  return errors
}

export function getPriceBandSetErrors(priceBands: DraftPriceBand[]): string[] {
  const errors = []
  const newPriceBand = [...priceBands]
  // We only need to run the overlapping logic if there is more than 1 band
  if (priceBands.length > 1) {
    // Sort the bands by min price O(nLogn)
    const sortedBands = newPriceBand.sort((a: DraftPriceBand, b: DraftPriceBand): number => {
      return Number(a.minPrice) - Number(b.minPrice)
    })

    // Loop through and verify no plan set violations exist
    // We're starting at 1 since there is no previous to check against the first
    // price band in the array.
    for (let i = 1; i < sortedBands.length; i += 1) {
      const { [i - 1]: previousPriceBand, [i]: currentPriceBand } = sortedBands

      // verify there are no overlaps in price bands
      if (Number(previousPriceBand.maxPrice) >= Number(currentPriceBand.minPrice)) {
        errors.push(
          `- Price bands ${priceBands.indexOf(previousPriceBand) + 1} and ${
            priceBands.indexOf(currentPriceBand) + 1
          } overlap`,
        )
      }

      // verify there are no gaps in price bands
      if (currentPriceBand.minPrice - previousPriceBand.maxPrice > 1) {
        errors.push(
          `- Price bands ${priceBands.indexOf(previousPriceBand) + 1} and ${
            priceBands.indexOf(currentPriceBand) + 1
          } have a gap`,
        )
      }
    }
  }

  return errors
}

export const validPlanSetNameInput = (name: string): boolean => {
  const pattern = /^[a-zA-Z0-9-\s]*$/
  return pattern.test(name)
}
