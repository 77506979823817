import type { Permission } from '../../lib/permissions'

export type NavLink = {
  to: string
  text: string
  icon?: React.ReactNode
  permission?: Permission
  dataCy?: string
}

export type NavDropdown = Omit<NavLink, 'to'> & {
  dropdownItems: NavLink[]
}
export const instanceOfDropdown = (object: NavLink | NavDropdown): object is NavDropdown => {
  return 'dropdownItems' in object
}
