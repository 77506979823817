import type { FC } from 'react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import type { Money, Plan, Product } from '@helloextend/extend-api-client'
import { Paragraph, InlineAlert, InlineAlertColor, ModalController } from '@extend/zen'
import {
  useGetPlansByIdsQuery,
  useUpdateProductMutation,
  useGetPlanMappingsQuery,
  useLazyGetPlansByIdsQuery,
} from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { useToggle } from '@helloextend/client-hooks'
import { useStandardToast } from '@helloextend/merchants-ui'
import { arePlanWarrantyCategoriesValid } from '../../../utils'
import { SectionContainer } from '../../../../../../../../../components/section-container'
import { AddPlanModal } from '../add-plan-modal'
import { SaveChangesButtonGroup } from '../../../../settings/components/save-changes-button-group'
import { ProductPlansTable } from '../product-plans-table/product-plans-table'

type ProductPlansProps = {
  product: Product
}

interface PlanWithProductPrice extends Plan {
  productPrice?: Money
}

const mapProductPriceToPlan = (plans: Plan[], productPrice?: Money): PlanWithProductPrice[] =>
  plans?.map((plan) => ({ ...plan, productPrice }))

const ProductPlans: FC<ProductPlansProps> = ({ product }) => {
  const [allPlansData, setAllPlansData] = useState<Plan[]>([])
  const {
    data: plansByIds,
    isLoading,
    isSuccess,
  } = useGetPlansByIdsQuery(product.plans, {
    skip: !product?.plans?.length,
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      data: mapProductPriceToPlan(data || [], product?.price),
    }),
  })
  const { toastCompleted, toastError } = useStandardToast()

  const [isModalOpen, { on: toggleModalOn, off: toggleModalOff }] = useToggle(false)

  const isFirstRender = useRef(true)

  const [fetchAdditionalPlans, { isFetching: isAdditionalPlansLoading }] =
    useLazyGetPlansByIdsQuery()
  const { planIds, isLoading: isPlansListLoading } = useGetPlanMappingsQuery(
    { storeId: product.storeId },
    {
      selectFromResult: ({ data, ...rest }) => ({
        planIds: data?.map((item) => item.planId) || [],
        ...rest,
      }),
    },
  )
  const [updateProduct, { isLoading: isUpdateProductLoading }] = useUpdateProductMutation()

  const { values, dirty, resetForm, setValues, initialValues } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      plans: product.plans,
    },
    onSubmit: () => {},
  })

  const planIdsToDisplay = useMemo(
    () => planIds.filter((planId: string) => !values.plans.includes(planId)),
    [planIds, values.plans],
  )

  const handleAddPlans = async (plansList: string[]): Promise<void> => {
    const plansToFetch = plansList.filter((plan) => !allPlansData.find((p: Plan) => p.id === plan))
    const res = await fetchAdditionalPlans(plansToFetch)
    const plansToAdd = res.data || []

    if (plansToAdd.length > 1 && !arePlanWarrantyCategoriesValid(plansToAdd, toastError)) {
      return
    }

    const newData = mapProductPriceToPlan(plansToAdd || [], product?.price)

    const newAllPlans = [...allPlansData, ...newData]
    setAllPlansData(newAllPlans)
    setValues({ plans: [...values.plans, ...plansList] })
  }

  const handleDeleteItem = (id: string): void => {
    const newPlans = values.plans.filter((plan) => plan !== id)
    setValues({ plans: newPlans })
    setAllPlansData(allPlansData.filter((plan) => plan.id !== id))
  }

  const handleSaveChangesClick = async (): Promise<void> => {
    try {
      await updateProduct({
        storeId: product.storeId,
        productId: product.referenceId,
        data: {
          plans: values.plans,
        },
      }).unwrap()
      toastCompleted('Plans have been successfully updated.')
    } catch (error) {
       
      console.error(error)
      toastError('Something went wrong. Please try again.')
    }
  }

  const handleCancelAllChangesClick = (): void => {
    resetForm()
    setAllPlansData(plansByIds || [])
  }

  useEffect(() => {
    if (isFirstRender.current && isSuccess) {
      setAllPlansData(plansByIds || [])
      isFirstRender.current = false
    }
  }, [isSuccess, plansByIds])

  const isInlineAlertVisible = values.plans.length - initialValues.plans.length > 3

  return (
    <SectionContainer title="Plans">
      {isInlineAlertVisible && (
        <InlineAlertWrapper>
          <InlineAlert data-cy="plans-alert" color={InlineAlertColor.yellow}>
            <Paragraph>Take caution. More than 3 plans have been selected.</Paragraph>
          </InlineAlert>
        </InlineAlertWrapper>
      )}

      <ModalController isOpen={isModalOpen}>
        <AddPlanModal
          planIds={planIdsToDisplay}
          arePlansLoading={isPlansListLoading || isAdditionalPlansLoading}
          handleAddPlans={handleAddPlans}
          toggleModalOff={toggleModalOff}
        />
      </ModalController>
      <ProductPlansTable
        plansData={allPlansData}
        isLoading={isLoading || isAdditionalPlansLoading}
        toggleModalOn={toggleModalOn}
        handleDeleteItem={handleDeleteItem}
      />
      {dirty && (
        <ButtonContainer>
          <SaveChangesButtonGroup
            id="save-changes-button-group"
            saveButtonText="Save Changes"
            handleSave={handleSaveChangesClick}
            handleCancel={handleCancelAllChangesClick}
            isSaveDisabled={isUpdateProductLoading}
            isLoading={isUpdateProductLoading}
          />
        </ButtonContainer>
      )}
    </SectionContainer>
  )
}

const ButtonContainer = styled.div({
  marginTop: 16,
})

const InlineAlertWrapper = styled.div({
  margin: '24px 0',
})

export { ProductPlans }
