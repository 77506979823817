import * as Yup from 'yup'
import type {
  ContractEscalationLevel,
  ContractNoteCategory,
  ContractNoteType,
} from '@helloextend/extend-api-client'

export const NOTE_TEXT_MAX = 3000
export const contractNoteSchema = Yup.object()
  .shape({
    type: Yup.string<ContractNoteType>().default('basic').required('Required'),
    category: Yup.string<ContractNoteCategory>().required('Please select a category'),
    categoryDescription: Yup.string()
      .default('')
      .when('category', {
        is: 'Other',
        then: () => Yup.string().required('Please enter a category description'),
        otherwise: () => Yup.string().nullable().notRequired(),
      }),
    text: Yup.string().default('').required('Please enter a note').max(NOTE_TEXT_MAX),
    escalationLevel: Yup.string<Exclude<ContractEscalationLevel, undefined>>().default('tier1'),
  })
  .defined()

export const contractResolutionNoteSchema = Yup.object()
  .shape({
    isResolved: Yup.boolean().default(false).required('Required'),
    resolutionText: Yup.string()
      .default('')
      .required('Please enter a resolution note')
      .max(NOTE_TEXT_MAX),
  })
  .defined()

export type ContractNoteSchemaValues = Yup.InferType<typeof contractNoteSchema>
export type ContractResolutionNoteSchemaValues = Yup.InferType<typeof contractResolutionNoteSchema>
