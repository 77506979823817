import type {
  Collect,
  DefaultPrompt,
  DefaultReply,
  Option,
  Pattern,
  PromptOption,
} from '@helloextend/extend-api-rtk-query'
import React from 'react'
import { getIndex } from '../../../utils'
import { AdjudicationPromptButton } from './adjudication-prompt-button'
import { AdjudicationPromptDatePicker } from './adjudication-prompt-date-picker'
import { AdjudicationPromptDefault } from './adjudication-prompt-default'
import { AdjudicationPromptTextInput } from './adjudication-prompt-text-input'

interface ScriptItem {
  reply: DefaultReply
  collect: Collect
}

export function renderPromptElements(
  scriptItem: ScriptItem,
  onBadgeClick: (val: number | string) => void,
  threadId?: string,
): JSX.Element | JSX.Element[] | undefined {
  const {
    reply,
    collect: { options },
  } = scriptItem
  const prompt = reply.prompt as DefaultPrompt
  switch (prompt?.type) {
    case 'input':
    case 'addressInput':
      return inputPrompt(prompt, options, onBadgeClick, threadId)
    case 'imageUpload':
      return inputPrompt(
        { ...prompt, placeholder: 'Upload Photo' },
        options,
        onBadgeClick,
        threadId,
      )
    case 'datepicker':
      return datePrompt(prompt, options, onBadgeClick, threadId)
    case 'buttons':
    case 'multiselect':
      return buttonPrompt(prompt, options, onBadgeClick, threadId)
    default:
      return defaultPrompt(options, onBadgeClick)
  }
}

function inputPrompt(
  prompt: DefaultPrompt,
  options: Option[],
  onBadgeClick: (val: number | string) => void,
  threadId?: string,
): JSX.Element {
  // Input prompts can only have a default action since we cannot match against free text input in Kaley
  const option: Option = options.find((o: Option) => o.default) as Option
  const index: string | number = getIndex(option)

  return (
    <AdjudicationPromptTextInput
      placeholder={prompt?.placeholder || 'Text input'}
      badgeValue={index}
      onBadgeClick={onBadgeClick}
      threadId={threadId}
    />
  )
}

function datePrompt(
  prompt: DefaultPrompt,
  options: Option[],
  onBadgeClick: (val: number | string) => void,
  threadId?: string,
): JSX.Element {
  // Date prompts can only have a default action since we cannot match against dates in Kaley
  const option: Option = options.find((o: Option) => o.default) as Option
  const index: string | number = getIndex(option)

  return (
    <AdjudicationPromptDatePicker
      placeholder={prompt?.placeholder}
      disabled
      onChange={() => {}}
      badgeValue={index}
      onBadgeClick={onBadgeClick}
      threadId={threadId}
    />
  )
}

function getRedirectValue(redirectUrl: string): string {
  switch (redirectUrl) {
    case '/my_claims':
      return 'My Claims'
    case '/sign_up':
      return 'My Extend Login'
    default:
      return 'Support Form'
  }
}

function buttonPrompt(
  prompt: DefaultPrompt,
  options: Option[],
  onBadgeClick: (val: number | string) => void,
  threadId?: string,
): JSX.Element[] | undefined {
  const defaultOption: Option | undefined = options.find((o: Option) => o.default)
  return prompt.options?.map((promptOption: PromptOption, index: number) => {
    let badgeValue: number | string
    const { redirectUrl } = promptOption
    if (redirectUrl) {
      badgeValue = getRedirectValue(redirectUrl)
    } else {
      const match: Option | undefined = options.find(
        (option: Option) =>
          !!option.patterns?.find((pattern: Pattern) => pattern.input === promptOption.value),
      )

      if (match) {
        badgeValue = getIndex(match)
      } else if (defaultOption) {
        badgeValue = getIndex(defaultOption)
      } else {
        throw new Error('Cannot match index to prompt option')
      }
    }

    return (
      <AdjudicationPromptButton
        key={`${promptOption.title}-${index}`}
        text={promptOption.title}
        bordered={(prompt.options?.length || 0) <= 3}
        badgeValue={badgeValue}
        onBadgeClick={onBadgeClick}
        promptIndex={index}
        threadId={threadId}
      />
    )
  })
}

function defaultPrompt(
  options: Option[],
  onBadgeClick: (val: number | string) => void,
): JSX.Element {
  const badgeValues = options.map((option: Option): string | number => {
    return getIndex(option)
  })

  return <AdjudicationPromptDefault badgeValues={badgeValues} onBadgeClick={onBadgeClick} />
}
