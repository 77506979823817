import type { FC } from 'react'
import React from 'react'
import { Badge } from '@extend/zen'
import type { BadgeDescriptionItem } from '@helloextend/merchants-ui'
import { CellMarkup } from '@helloextend/merchants-ui'

type CellStatusBadgeProps = {
  badgeData: BadgeDescriptionItem
}

const CellStatusBadge: FC<CellStatusBadgeProps> = ({ badgeData }) => {
  return (
    <CellMarkup data-cy="badge-status">
      <Badge
        text={badgeData.badgeText}
        emphasis="medium"
        color={badgeData.badgeColor}
        size="regular"
      />
    </CellMarkup>
  )
}

export { CellStatusBadge }
