import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router'
import { useGetOrderQuery } from '@helloextend/extend-api-rtk-query'
import { OrderDetailsPageHeader, OrderPageTabBar } from './components'
import { DashboardSpinner } from '../../../components/dashboard-spinner'

const Order: FC = () => {
  const { orderId } = useParams<{ orderId: string }>()
  const { data: order, isLoading: isOrderLoading, isError } = useGetOrderQuery(orderId)

  if (isError)
    return <span data-cy="order-not-found-message">No order found for order ID: {orderId}</span>

  if (!order || isOrderLoading) return <DashboardSpinner data-cy="dashboard-spinner" />

  return (
    <>
      <OrderDetailsPageHeader order={order} />
      <OrderPageTabBar order={order} />
    </>
  )
}

export { Order }
