import * as Yup from 'yup'
import { validate } from '@extend/client-helpers'

const schema = Yup.object()
  .shape({
    isBundle: Yup.string().required('Product Type is required').default(''),
    warrantyStatus: Yup.string().required('Matched Status is required').default(''),
    title: Yup.string().required('Product name is required').default(''),
    category: Yup.string().required('Merchant Category is required').default(''),
    priceAmount: Yup.number().required('Product List Price is required').nullable(),
    imageUrl: Yup.string()
      .required('Product Image is required')
      .matches(validate.urlRegExp, 'Please enter a valid Image URL')
      .default(''),
    description: Yup.string(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export type { Values }
export { schema }
