import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { ButtonProps } from '@extend/zen'
import { COLOR, Button, Icon, Close } from '@extend/zen'

interface AdjudicationBottomnavProps {
  className?: string
  name?: string
  actionButtons?: ButtonProps[]
  onClose?: () => void
}

const AdjudicationBottomnav: FC<AdjudicationBottomnavProps> = ({
  className,
  actionButtons,
  onClose,
}) => {
  const navColor = COLOR.WHITE
  const iconColor = COLOR.BLUE[700]
  return (
    <BottomnavWrapper className={className} color={navColor}>
      <BottomnavSection>
        <ButtonGroup>
          {actionButtons &&
            actionButtons.map(({ text, ...props }) => <Button key={text} text={text} {...props} />)}
        </ButtonGroup>
        <IconWrapper onClick={onClose} data-cy="bottom-nav-close">
          <Icon icon={Close} size={24} color={iconColor} />
        </IconWrapper>
      </BottomnavSection>
    </BottomnavWrapper>
  )
}

const BottomnavWrapper = styled.div<{ color: string }>(({ color }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'space-between',
  height: 56,
  width: '100vw',
  background: color,
  boxShadow: `0px 4px 10px ${COLOR.NEUTRAL[300]}`,
  zIndex: 2,
  flexDirection: 'row-reverse',
  padding: '4px 0px 4px 0px',
}))

const BottomnavSection = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
})

const ButtonGroup = styled.div({
  display: 'flex',
  gap: 12,
})

const IconWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginRight: 40,
  '&:hover': {
    cursor: 'pointer',
  },
})

export { AdjudicationBottomnav }
