import type { FC } from 'react'
import React from 'react'
import { InlineAlert, InlineAlertColor, Error, Warning } from '@extend/zen'
import { uuid } from '@helloextend/extend-sdk-client/lib/utils/util'
import styled from '@emotion/styled'

export interface InlineAlertsProps {
  alerts: string[]
  alertType: 'warning' | 'error'
  'data-cy': string
}

const InlineAlerts: FC<InlineAlertsProps> = ({ alerts, alertType, 'data-cy': dataCy }) => {
  const icon = alertType === 'error' ? Error : Warning
  const color = alertType === 'error' ? InlineAlertColor.red : InlineAlertColor.yellow
  return (
    <InlineAlert data-cy={dataCy} key={uuid()} color={color} icon={icon}>
      {alerts.map((alert) => {
        // Adds div for line breaks when \n included in alert string
        return alert.split('\n').map((text) => <Text key={uuid()}>{text}</Text>)
      })}
    </InlineAlert>
  )
}

const Text = styled.div({
  minHeight: '20px',
})

export { InlineAlerts }
