import type { FC } from 'react'
import React from 'react'
import type { Product } from '@helloextend/extend-api-client'
import { ModalController, Switch, Subheading, Checkbox } from '@extend/zen'
import styled from '@emotion/styled'
import { useUpdateProductMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useToggle } from '@helloextend/client-hooks'
import { SectionContainer } from '../../../../../../../../../components/section-container'
import { Divider } from '../../../../../../../../../components/divider'
import { ProductServicingSettingsModal } from '../product-servicing-settings-modal/product-servicing-settings-modal'
import type { ProductServicingSettingsModalType } from '../../../types'
import { DefaultServicingLocation } from '../../default-servicing-location/default-servicing-location'

type ProductServicingSettingsProps = {
  product: Product
}

const ProductServicingSettings: FC<ProductServicingSettingsProps> = ({ product }) => {
  const { toastError, toastCompleted } = useStandardToast()
  const [updateProduct, { isLoading }] = useUpdateProductMutation()

  const [
    isPartialReplacementModalDisplayed,
    { on: handlePartialReplacementModalOn, off: handlePartialReplacementModalOff },
  ] = useToggle()

  const [
    isProductReturnModalDisplayed,
    { on: handleProductReturnModalOn, off: handleProductReturnModalOff },
  ] = useToggle()

  const handleUpdateProduct = async (
    modalType: ProductServicingSettingsModalType,
  ): Promise<void> => {
    try {
      const isPartialReplacement = modalType === 'partialReplacementEligible'
      await updateProduct({
        storeId: product.storeId,
        productId: product.referenceId,
        data: {
          settings: {
            [modalType]: (product.settings && !product.settings?.[modalType]) ?? true,
          },
        },
        version: 'latest',
      }).unwrap()
      toastCompleted('Servicing Settings have been successfully updated.')
      if (isPartialReplacement) {
        handlePartialReplacementModalOff()
      } else {
        handleProductReturnModalOff()
      }
    } catch (error) {
      console.error(error)
      toastError('Something went wrong. Please try again.')
    }
  }
  return (
    <SectionContainer title="Servicing Settings">
      <SubheadingContainer>Product Replacement Method</SubheadingContainer>
      <Switch
        isOn={product.settings?.partialReplacementEligible || false}
        onChange={handlePartialReplacementModalOn}
        label="Partial Replacement"
        data-cy="partial-replacement-switch"
      />
      <DividerContainer />
      <ModalController isOpen={isPartialReplacementModalDisplayed}>
        <ProductServicingSettingsModal
          handleModalOff={handlePartialReplacementModalOff}
          isEnabled={Boolean(product.settings?.partialReplacementEligible)}
          modalType="partialReplacementEligible"
          handleConfirmClick={() => handleUpdateProduct('partialReplacementEligible')}
          isLoading={isLoading}
        />
      </ModalController>

      <SubheadingContainer>Replacement Servicing Locations</SubheadingContainer>
      <Checkbox
        label="Product Return"
        checked={Boolean(product.settings?.isProductReturn)}
        onChange={handleProductReturnModalOn}
        data-cy="product-return-checkbox"
      />
      <DefaultServicingLocation product={product} />
      <ModalController isOpen={isProductReturnModalDisplayed}>
        <ProductServicingSettingsModal
          handleModalOff={handleProductReturnModalOff}
          isEnabled={Boolean(product.settings?.isProductReturn)}
          modalType="isProductReturn"
          handleConfirmClick={() => handleUpdateProduct('isProductReturn')}
          isLoading={isLoading}
        />
      </ModalController>
    </SectionContainer>
  )
}

const SubheadingContainer = styled(Subheading)({
  marginBottom: 24,
})

const DividerContainer = styled(Divider)({
  margin: '24px 0',
})

export { ProductServicingSettings }
