import type { FC } from 'react'
import React from 'react'
import { HeadingLarge, TabSection, TabSections } from '@extend/zen'
import { ContractNotesTable } from '../contract/components/contract-notes-table'
import styled from '@emotion/styled'
import { ContractsTable } from '../contract/components/contracts-table/contracts-table'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../constants/ld-flags'

const Contracts: FC = () => {
  const { [LDFlag.ContractNotes]: FF_CONTRACT_NOTES } = useFlags()
  return (
    <Container>
      <HeadingLarge>Contracts</HeadingLarge>
      <TabSections data-cy="contracts-tabs">
        <TabSection tabLabel="Contracts Search">
          <ContractsTable />
        </TabSection>
        {FF_CONTRACT_NOTES && (
          <TabSection tabLabel="Contract Escalations">
            <ContractNotesTable />
          </TabSection>
        )}
      </TabSections>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})

export { Contracts }
