import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router'
import { useGetLineItemQuery } from '@helloextend/extend-api-rtk-query'
import { LineItemDetailsPageHeader, LineItemPageTabBar } from './components'
import { DashboardSpinner } from '../../../components/dashboard-spinner'

const LineItem: FC = () => {
  const { orderId, lineItemId } = useParams<{ orderId: string; lineItemId: string }>()
  const { data: lineItem, isLoading: isLineItemLoading, isError } = useGetLineItemQuery(lineItemId)

  if (isError)
    return (
      <span data-cy="line-item-not-found-message">
        No line item found for order ID: {orderId} and line item ID: {lineItemId}
      </span>
    )

  if (!lineItem || isLineItemLoading) return <DashboardSpinner data-cy="dashboard-spinner" />

  return (
    <>
      <LineItemDetailsPageHeader lineItem={lineItem} orderId={orderId} />
      <LineItemPageTabBar lineItem={lineItem} orderId={orderId} />
    </>
  )
}

export { LineItem }
