import * as Yup from 'yup'

const locationFilteringOptions = ['Enabled', 'Disabled']

const getLocationFilteringDefault = (value?: boolean): string => {
  if (value === undefined) {
    return ''
  }
  return value ? 'Enabled' : 'Disabled'
}

const getPlanIdDefault = (value?: string): string => {
  if (value === undefined) {
    return ''
  }
  return value
}

// required values for shipping protection "enabled" property
const sharedSPEnabledSchema = Yup.object()
  .shape({
    planId: Yup.string().required('Plan ID is required').default(''),
  })
  .defined()

const schema = sharedSPEnabledSchema
  .shape({
    merchantSpRevenueSharePercentage: Yup.number()
      .required('Must enter a revenue share percentage')
      .default(0)
      .nullable()
      .min(0, 'Revenue share must be greater than or equal to 0')
      .max(100, 'Revenue share must be equal or less than 100'),
    locationFilteringEnabled: Yup.string()
      .default('')
      .oneOf([...locationFilteringOptions, '']),
  })
  .defined()

type Values = ReturnType<typeof schema.validateSync>

export type { Values }
export {
  sharedSPEnabledSchema,
  schema,
  locationFilteringOptions,
  getLocationFilteringDefault,
  getPlanIdDefault,
}
