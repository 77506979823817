import type { FC } from 'react'
import React, { useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { Button, Add as AddIcon } from '@extend/zen'

import { OrganizationsDataTable } from './organizations-data-table/organizations-data-table'
import { CreateOrganizationModal } from '../components/create-organization-modal/create-organization-modal'

const Organizations: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const toggleModal = useCallback((): void => {
    setIsModalVisible(!isModalVisible)
  }, [isModalVisible])

  return (
    <>
      <HeaderContainer>
        <Button
          text="Create Organization"
          onClick={toggleModal}
          data-cy="create-organization-button"
          emphasis="medium"
          icon={AddIcon}
        />
      </HeaderContainer>
      <CreateOrganizationModal isVisible={isModalVisible} onClickClose={toggleModal} />
      <OrganizationsDataTable />
    </>
  )
}

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export { Organizations }
