import type { FC } from 'react'
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { Button, ChevronStart, ChevronLeft, ChevronRight, ChevronEnd, COLOR } from '@extend/zen'
import { number } from '@extend/client-helpers'
import { List, ListItem } from '../list'
import { Menu } from '../menu'
import type { PaginationDirection, PaginationType } from './types'

const PER_PAGE = [10, 20, 50, 100]

type DeterminatePaginationProps = {
  paginationType: PaginationType.DETERMINATE_SERVER_SIDE
  count: number
  type: string
  currPage: number
  pageSize: number
  handlePagination: (type: PaginationDirection) => void
  onPageSizeChange: (pageSize: number) => void
  displayLastPageButton?: boolean
  shouldHidePageNumber?: boolean
}

const DeterminatePagination: FC<DeterminatePaginationProps> = ({
  count,
  type,
  currPage,
  pageSize,
  handlePagination,
  onPageSizeChange,
  displayLastPageButton,
  shouldHidePageNumber = false,
}) => {
  const isFirstPage = currPage === 0
  const numberOfPages = Math.max(Math.ceil(count / pageSize), 1)
  const lastPageIndex = numberOfPages - 1
  const isLastPage = currPage === lastPageIndex

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const pageSizeSButtonText = useMemo(() => {
    const firstUnitNumber = Math.min(pageSize * currPage + 1, count)
    const lastUnitNumber = Math.min(firstUnitNumber + pageSize - 1, count)
    const unitType = count === 1 ? type.slice(0, -1) : type
    const formattedTotal = number.formatInteger(count)
    return `${number.formatInteger(firstUnitNumber)} - ${number.formatInteger(
      lastUnitNumber,
    )} of ${formattedTotal} ${unitType}`
  }, [currPage, type, pageSize, count])

  const handlePrev = useCallback((): void => {
    if (!isFirstPage) {
      handlePagination('previous')
    }
  }, [isFirstPage, handlePagination])

  const handleNext = useCallback((): void => {
    if (!isLastPage) {
      handlePagination('next')
    }
  }, [isLastPage, handlePagination])

  const handleFirst = useCallback((): void => {
    if (!isFirstPage) {
      handlePagination('first')
    }
  }, [isFirstPage, handlePagination])

  const handleLast = useCallback((): void => {
    if (!isLastPage) {
      handlePagination('last')
    }
  }, [isLastPage, handlePagination])

  const handleClickMenuButton = useCallback((): void => {
    setIsMenuVisible((v) => !v)
  }, [])

  const handleSelectQuantity = useCallback(
    (quantity: number): void => {
      onPageSizeChange(quantity)
    },
    [onPageSizeChange],
  )

  useEffect(() => {
    if (!isMenuVisible) {
      return () => {
        // do nothing
      }
    }

    document.addEventListener('click', handleClickMenuButton)

    return function cleanup() {
      document.removeEventListener('click', handleClickMenuButton)
    }
  }, [handleClickMenuButton, isMenuVisible])

  return (
    <Wrapper>
      {count > 0 && (
        <Container>
          <div>
            <Button
              onClick={handleClickMenuButton}
              isToggled={isMenuVisible}
              text={pageSizeSButtonText}
              color="neutral"
              emphasis="low"
              size="small"
              data-cy="selectPageSize"
            />
            <Menu isOpen={isMenuVisible} position="right" verticalAlignment={-120} width={140}>
              <List fullWidth>
                {PER_PAGE.map((size: number) => (
                  <ListItem
                    type="button"
                    isSelected={size === pageSize}
                    isBold={size === pageSize}
                    color={COLOR.NEUTRAL[1000]}
                    key={size}
                    id={`${size}`}
                    hoverBackground={COLOR.NEUTRAL[100]}
                    onClick={() => handleSelectQuantity(size)}
                  >
                    {size} per view
                  </ListItem>
                ))}
              </List>
            </Menu>
          </div>
          <Button
            icon={ChevronStart}
            onClick={handleFirst}
            isDisabled={isFirstPage}
            color="neutral"
            emphasis="low"
            size="small"
            data-cy="start"
          />
          <Button
            icon={ChevronLeft}
            onClick={handlePrev}
            isDisabled={isFirstPage}
            color="neutral"
            emphasis="low"
            size="small"
            data-cy="prev"
          />
          {!shouldHidePageNumber && <PageText>Page {currPage + 1}</PageText>}
          <Button
            icon={ChevronRight}
            onClick={handleNext}
            isDisabled={isLastPage}
            color="neutral"
            emphasis="low"
            size="small"
            data-cy="next"
          />
          {displayLastPageButton && (
            <Button
              icon={ChevronEnd}
              onClick={handleLast}
              isDisabled={isLastPage}
              color="neutral"
              emphasis="low"
              size="small"
              data-cy="end"
            />
          )}
        </Container>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
})

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  color: COLOR.NEUTRAL[600],
  paddingTop: '16px',
})

const PageText = styled.span({
  fontSize: 14,
})

export type { DeterminatePaginationProps }
export { DeterminatePagination }
