import { createApi } from '@reduxjs/toolkit/query/react'
import { encodeURIComponentIfNotEncoded } from '@extend/client-helpers'
import type {
  AISSingleRecord,
  GetAISRecordRequest,
  AISSearchOptions,
  AISSearchResponse,
} from './types'
import { baseQuery } from '../base-query'
import { safeBtoa } from '../../helpers'

const buildCacheKey = (qs: AISSearchOptions): string => {
  const { cursor, limit, ...qsWithoutPagination } = qs
  return safeBtoa(JSON.stringify(qsWithoutPagination))
}

export const aisApi = createApi({
  baseQuery,
  reducerPath: 'AIS',
  tagTypes: ['AIS'],
  endpoints: (build) => ({
    getAISRecord: build.query<AISSingleRecord, GetAISRecordRequest>({
      query: ({ storeId, referenceId }) => ({
        url: `async-integrations/ais-records/${storeId}/${encodeURIComponentIfNotEncoded(
          referenceId,
        )}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=latest;`,
        },
      }),
      providesTags: (_, _err, { referenceId }) => [
        { type: 'AIS', id: encodeURIComponentIfNotEncoded(referenceId) },
      ],
    }),
    searchAISRecords: build.query<AISSearchResponse, AISSearchOptions>({
      query: (qs: AISSearchOptions) => ({
        url: 'async-integrations/ais-records/search',
        params: { ...qs },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, qs) => {
        return [{ type: 'AIS', id: buildCacheKey(qs) }]
      },
    }),
  }),
})

export const { useGetAISRecordQuery, useSearchAISRecordsQuery } = aisApi
