import type { FC } from 'react'
import React, { useState, useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import {
  Box,
  Grid,
  DataProperty,
  Stack,
  Button,
  COLOR,
  OpenInNew,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@extend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLazyGetUserGrantsListQuery } from '@helloextend/extend-api-rtk-query'
import { useExtendAuth } from '@extend/package-okta-login'
import { getUserRolesFromGrants } from '../../../utils/user-role-mapper'
import DashboardLayout from '../../../hoc/dashboard-layout'
import { DashboardSection } from '../../../hoc/dashboard-layout/dashboard-layout'
import { LDFlag } from '../../../constants/ld-flags'
import { LogoutNotificationModal } from './modals/logout-notification-modal'

const DashboardProfilePage: FC = () => {
  const flags = useFlags()
  const { toast } = useToaster()
  const { [LDFlag.LegacyAndEnterpriseRoles]: FF_ENTERPRISE_AND_LEGACY_ROLES } = useFlags()

  const { user } = useExtendAuth()

  const [showLogoutNotificationModal, setShowLogoutNotificationModal] = useState<boolean>(false)
  const [getGrants, { data: userGrants, isLoading }] = useLazyGetUserGrantsListQuery()

  const handleEditButtonClick = (): void => {
    // AO -1580 move this redirect url to environment variables
    window.open('https://extend.okta.com/enduser/settings', '_blank')
    setShowLogoutNotificationModal(true)
  }

  const closeModal = useCallback(() => {
    setShowLogoutNotificationModal(false)
  }, [])

  const editButtonHelperText =
    'Any profile updates made in Okta will only show in the Extend portal the next time you log in.'

  const getUsergrants = useCallback(async () => {
    if (!user) return

    const grantsResponse = await getGrants(user.email)
    if ('error' in grantsResponse) {
      toast({
        message: 'Error getting user roles',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [getGrants, toast, user])

  useEffect(() => {
    getUsergrants()
  }, [flags, getUsergrants])

  return (
    <DashboardLayout section={DashboardSection.Admin}>
      <ProfileHeadingContainer>
        <ProfileNameContainer data-cy="profile-name-container">
          {user?.firstName} {user?.lastName}&apos;s Profile
        </ProfileNameContainer>
        <Button
          color="blue"
          emphasis="medium"
          icon={OpenInNew}
          size="regular"
          text="Edit"
          data-cy="edit-button"
          onClick={handleEditButtonClick}
          tooltip={editButtonHelperText}
        />
      </ProfileHeadingContainer>
      <Box padding={4}>
        <Stack isRow align="start" spacing={3}>
          <DetailsText>Basic Info</DetailsText>
        </Stack>

        <Stack spacing={3}>
          <Grid
            columns={{
              lg: 3,
              md: 2,
              sm: 1,
            }}
            spacing={{
              lg: 6,
              md: 4,
              sm: 2,
            }}
          >
            <DataProperty label="First Name" value={user?.firstName} data-cy="first-name" />
            <DataProperty label="Last Name" value={user?.lastName} data-cy="last-name" />
            <DataProperty label="Email" value={user?.email} data-cy="email" />
            <DataProperty
              label="Role(s)"
              value={getUserRolesFromGrants({
                grants: userGrants?.grants ?? [],
                roleFilter: 'internal',
                includeEnterpriseRoles: FF_ENTERPRISE_AND_LEGACY_ROLES,
              })
                .map((role) => role.display)
                .join(', ')}
              data-cy="roles"
              isLoading={isLoading}
            />
          </Grid>
        </Stack>
      </Box>
      <LogoutNotificationModal isVisible={showLogoutNotificationModal} toggleOff={closeModal} />
    </DashboardLayout>
  )
}

const ProfileHeadingContainer = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1em',
})

const ProfileNameContainer = styled.p({
  margin: 0,
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '24px',
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
})

const DetailsText = styled.p({
  width: '90%',
  minWidth: '50%',
  marginTop: '8px',
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '20px',
  lineHeight: '28px',
  color: COLOR.BLUE[1000],
})

export { DashboardProfilePage }
