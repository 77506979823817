import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { ButtonProps } from '@extend/zen'
import { AdjudicationBottomnav } from '../components/adjudication-bottomnav'
import type { RootState } from '../../../../reducers'
import * as selectors from '../../../../reducers/selectors'
import { ThreadEditTabsEnum } from './types'

type ThreadEditorBottomnavProps = {
  isProcessing: boolean
  onClickClose: () => void
  onClickSave: () => void
  onClickPublish: () => void
  isThreadDirty: boolean
  isRulesetDirty: boolean
  isPhotosetDirty: boolean
  activeTab: ThreadEditTabsEnum
}

const ThreadEditorBottomnav: FC<ThreadEditorBottomnavProps> = ({
  isProcessing,
  onClickClose,
  onClickSave,
  onClickPublish,
  isThreadDirty,
  isRulesetDirty,
  isPhotosetDirty,
  activeTab,
}) => {
  const isThreadTextAssigned = useSelector((state: RootState) =>
    selectors.getIsSelectedThreadTextAssigned(state),
  )
  const hasValidConditions = useSelector((state: RootState) =>
    selectors.getHasValidConditions(state),
  )
  const isPhotosetValid = useSelector((state: RootState) => selectors.getIsPhotosetValid(state))
  const selectedThread = useSelector((state: RootState) => selectors.getSelectedThread(state))
  const isAdjudicationRulesTab = activeTab === ThreadEditTabsEnum.AdjudicationRules
  const isPhotosTab = activeTab === ThreadEditTabsEnum.Photos

  const isSaveButtonDisabled = useMemo(() => {
    if (isAdjudicationRulesTab) {
      return !isRulesetDirty || !hasValidConditions
    }
    if (isPhotosTab) {
      return !isPhotosetDirty || !isPhotosetValid
    }
    return !isThreadTextAssigned || !isThreadDirty
  }, [
    isThreadTextAssigned,
    isThreadDirty,
    isRulesetDirty,
    isPhotosetDirty,
    isAdjudicationRulesTab,
    hasValidConditions,
    isPhotosetValid,
  ])

  const isPublishButtonDisabled = useMemo(() => {
    return (
      !isThreadTextAssigned ||
      selectedThread?.script.length === 0 ||
      (selectedThread?.status === 'published' && !isThreadDirty)
    )
  }, [isThreadTextAssigned, selectedThread?.script.length, selectedThread?.status, isThreadDirty])

  return (
    <AdjudicationBottomnav
      onClose={onClickClose}
      actionButtons={[
        {
          text: 'Save',
          emphasis: isAdjudicationRulesTab ? 'high' : 'medium',
          color: isAdjudicationRulesTab ? 'blue' : 'neutral',
          onClick: onClickSave,
          size: 'regular',
          isDisabled: isSaveButtonDisabled,
          'data-cy': 'thread-save-changes-button',
          isProcessing,
        },
        ...(activeTab === 'content'
          ? [
              {
                text: 'Publish',
                size: 'regular',
                onClick: onClickPublish,
                isDisabled: isPublishButtonDisabled,
                'data-cy': 'thread-publish-changes-button',
              } as ButtonProps,
            ]
          : []),
      ]}
    />
  )
}

export type { ThreadEditorBottomnavProps }
export { ThreadEditorBottomnav }
