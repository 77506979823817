import { useMemo, useEffect, useState } from 'react'
import type { Theme } from '@helloextend/extend-api-rtk-query'
import {
  useFetchAllThemesQuery,
  useLazyFetchThemeQuery,
  ThemePublishedStatus,
} from '@helloextend/extend-api-rtk-query'

const useGetPublishedTheme = (
  storeId: string,
): { theme: Theme | undefined; isLoading: boolean } => {
  const [theme, setTheme] = useState<Theme | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { data: themesMinimal } = useFetchAllThemesQuery(storeId)
  const [fetchThemeQuery] = useLazyFetchThemeQuery()

  const publishedThemeId = useMemo(
    () =>
      themesMinimal?.themes?.find((th) => th.status === ThemePublishedStatus.published)?.themeId,
    [themesMinimal],
  )

  useEffect(() => {
    const fetchTheme = async (themeId: string): Promise<void> => {
      const fetchedTheme = await fetchThemeQuery({ storeId, themeId }).unwrap()
      setTheme(fetchedTheme)
      setIsLoading(false)
    }
    if (publishedThemeId) {
      try {
        fetchTheme(publishedThemeId)
      } catch (err) {
        setIsLoading(false)
      }
    }
  }, [publishedThemeId, storeId, fetchThemeQuery])

  return { theme, isLoading }
}

export { useGetPublishedTheme }
