import type { PlanTermContractRefundInfo } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'
import * as Yup from 'yup'

type ContractRefundInfo = Pick<
  PlanTermContractRefundInfo,
  | 'fullRefundCancellationDays'
  | 'refundAmountProrated'
  | 'deductClaims'
  | 'deductAdminFee'
  | 'adminFeeAmount'
>

const schema = Yup.object()
  .shape({
    fullRefundCancellationDays: Yup.number()
      .required('Full Refund Cancellation Days is required')
      .min(0, 'Full Refund Cancellation Days must be minimum of 0')
      .default(0),
    refundAmountProrated: Yup.boolean()
      .required('Refund Amount Prorated is required')
      .default(false),
    deductClaims: Yup.boolean().required('Deduct Claims is required').default(false),
    deductAdminFee: Yup.boolean().required('Deduct Admin Fee is required').default(false),
    adminFeeAmount: Yup.number()
      .required('Admin Fee Amount is required')
      .min(0, 'Admin Fee Amount must be minimum of 0')
      .default(0),
  })
  .defined()

type ContractRefundInfoFormValues = Yup.InferType<typeof schema>

const getInitialValues = (
  contractRefundInfo?: ContractRefundInfo,
): ContractRefundInfoFormValues => {
  return contractRefundInfo
    ? {
        fullRefundCancellationDays: contractRefundInfo.fullRefundCancellationDays,
        refundAmountProrated: contractRefundInfo.refundAmountProrated,
        deductClaims: contractRefundInfo.deductClaims,
        deductAdminFee: contractRefundInfo.deductAdminFee,
        adminFeeAmount: contractRefundInfo.adminFeeAmount,
      }
    : {
        fullRefundCancellationDays: 0,
        refundAmountProrated: false,
        deductClaims: false,
        deductAdminFee: false,
        adminFeeAmount: 0,
      }
}

const labels: { [key in keyof ContractRefundInfoFormValues]: string } = {
  fullRefundCancellationDays: 'Full Refund Cancellation Days',
  refundAmountProrated: 'Refund Amount Prorated',
  deductClaims: 'Deduct Claims',
  deductAdminFee: 'Deduct Admin Fee',
  adminFeeAmount: 'Admin Fee Amount',
}

const GetFormPropsAndLabels = <T extends keyof ContractRefundInfoFormValues>(): {
  [key in T]: { property: string; label: string }
} => {
  return Object.keys(schema.fields).reduce(
    (mapping, property) => {
      const field = schema.fields[property as keyof ContractRefundInfoFormValues]
      if (field && 'label' in field) {
        const newMapping = {
          property,
          label: labels[property as keyof ContractRefundInfoFormValues],
        }
        return {
          ...mapping,
          [property]: newMapping,
        }
      }
      return mapping
    },
    {} as {
      [key in T]: { property: string; label: string }
    },
  )
}

export type { ContractRefundInfoFormValues }
export { schema, getInitialValues, GetFormPropsAndLabels }
