import type { FC } from 'react'
import React from 'react'
import type { ExtendCategory, StoreConfigurationResponse } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import type { MultiValue, SingleValue } from 'react-select'
import Select from 'react-select'
import { COLOR } from '@extend/zen'
import type { SelectOption } from '../../../../../../types/products'

type DropdownSelectlProps = {
  resetProducts: () => void
  setSelectedStore: React.Dispatch<React.SetStateAction<SelectOption | undefined>>
  setSelectedExtendCategories: React.Dispatch<
    React.SetStateAction<MultiValue<SelectOption> | undefined>
  >
  setSelectedWarrantyStatuses: React.Dispatch<
    React.SetStateAction<MultiValue<SelectOption> | undefined>
  >
  setSelectedMerchantCategories: React.Dispatch<
    React.SetStateAction<MultiValue<SelectOption> | undefined>
  >
  setSelectedMappingStatuses: React.Dispatch<React.SetStateAction<MultiValue<SelectOption>>>
  setStoreExtendCategoryList: React.Dispatch<React.SetStateAction<ExtendCategory[] | undefined>>
  setMerchantCategoryOptions: React.Dispatch<React.SetStateAction<SelectOption[] | undefined>>
  uniqueExtendCategoriesFromFetchedProducts:
    | {
        categorySet: Set<string>
        options: SelectOption[]
      }
    | {
        options: never[]
      }
  storeOptions?: Array<{
    label: string
    value: string
    product_count_by_category: Record<string, string>
  }>
  warrantyStatusOptions?: SelectOption[]
  mappingStatusOptions?: SelectOption[]
  storeExtendCategories?: StoreConfigurationResponse
  isDropdownSelectDataLoading: boolean
  selectedStore: SelectOption | undefined
  selectedWarrantyStatuses: MultiValue<SelectOption> | undefined
  selectedMappingStatuses: MultiValue<SelectOption> | undefined
  selectedExtendCategories: MultiValue<SelectOption> | undefined
  merchantCategoryOptions: MultiValue<SelectOption> | undefined
  selectedMerchantCategories: MultiValue<SelectOption> | undefined
}

const DropdownSelect: FC<DropdownSelectlProps> = ({
  resetProducts,
  setSelectedStore,
  setSelectedExtendCategories,
  setSelectedWarrantyStatuses,
  setSelectedMerchantCategories,
  setSelectedMappingStatuses,
  setStoreExtendCategoryList,
  setMerchantCategoryOptions,
  uniqueExtendCategoriesFromFetchedProducts,
  storeOptions,
  warrantyStatusOptions,
  mappingStatusOptions,
  storeExtendCategories,
  isDropdownSelectDataLoading,
  selectedStore,
  selectedWarrantyStatuses,
  selectedMappingStatuses,
  selectedExtendCategories,
  merchantCategoryOptions,
  selectedMerchantCategories,
}) => {
  // utils
  const optionSort = (a: SelectOption, b: SelectOption): number => a.label.localeCompare(b.label)

  // change handlers
  const handleOptionChangeStore = (option: SingleValue<SelectOption>): void => {
    if (!option) return
    setSelectedStore(option)

    const mappedMerchantCategoryOptions = storeOptions?.find((s) => s.value === option.value)

    const merchantCategoryOptionsSelect = mappedMerchantCategoryOptions
      ? Object.entries(mappedMerchantCategoryOptions.product_count_by_category)
          .map(([category, category_count]) => {
            return { label: category.concat(' (', category_count.toString(), ')'), value: category }
          })
          .sort(optionSort)
      : []

    const extendCategoryList = storeExtendCategories
      ? storeExtendCategories[option.value]?.extend_categories
      : []

    setSelectedExtendCategories([])
    setStoreExtendCategoryList(extendCategoryList)
    setSelectedMerchantCategories([])
    setMerchantCategoryOptions(merchantCategoryOptionsSelect)
    resetProducts()
  }

  const handleOptionChangeWarrantyStatus = (options: MultiValue<SelectOption>): void => {
    setSelectedWarrantyStatuses(options.length === 0 ? [] : options)
    setSelectedMappingStatuses([])
    resetProducts()
  }

  const handleOptionChangeMappingStatus = (options: MultiValue<SelectOption>): void => {
    setSelectedMappingStatuses(options.length === 0 ? [] : options)
    setSelectedWarrantyStatuses([])
    resetProducts()
  }

  const handleOptionChangeMerchantCategory = (options: MultiValue<SelectOption>): void => {
    setSelectedMerchantCategories(!options || options.length === 0 ? [] : options)
    resetProducts()
  }

  const handleOptionChangeExtendCategory = (options: MultiValue<SelectOption>): void => {
    setSelectedExtendCategories(!options || options.length === 0 ? [] : options)
    resetProducts()
  }

  return (
    <>
      <MerchantWrapper>
        <LabelSelect data-cy="store-selector">
          <Label>Store Catalog</Label>
          <Select
            isLoading={isDropdownSelectDataLoading}
            placeholder="Find a store"
            options={storeOptions}
            onChange={handleOptionChangeStore}
          />
        </LabelSelect>
        <LabelSelect>
          <Label>Predicted Warranty Status</Label>
          <Select
            isLoading={isDropdownSelectDataLoading}
            options={warrantyStatusOptions}
            value={selectedWarrantyStatuses}
            isMulti
            onChange={handleOptionChangeWarrantyStatus}
          />
        </LabelSelect>
        <LabelSelect>
          <Label>Mapping Status</Label>
          <Select
            isLoading={isDropdownSelectDataLoading}
            options={mappingStatusOptions}
            value={selectedMappingStatuses}
            isMulti
            onChange={handleOptionChangeMappingStatus}
          />
        </LabelSelect>
        <LabelSelect>
          <Label>Extend Category</Label>
          <Select
            isLoading={isDropdownSelectDataLoading}
            options={selectedStore ? uniqueExtendCategoriesFromFetchedProducts.options : []}
            value={selectedExtendCategories}
            isDisabled={!selectedStore}
            isMulti
            onChange={handleOptionChangeExtendCategory}
          />
        </LabelSelect>
        <LabelSelect>
          <Label>Merchant Category</Label>
          <Select
            isLoading={isDropdownSelectDataLoading}
            options={selectedStore ? merchantCategoryOptions : []}
            value={selectedMerchantCategories}
            isDisabled={!selectedStore}
            isMulti
            onChange={handleOptionChangeMerchantCategory}
          />
        </LabelSelect>
      </MerchantWrapper>
    </>
  )
}

const MerchantWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 64,
  alignItems: 'flex-end',
})

const LabelSelect = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})

const Label = styled.label({
  boxSizing: 'border-box',
  fontSize: 14,
  lineHeight: '19px',
  fontWeight: 700,
  paddingBottom: 8,
  color: COLOR.BLACK,
})

export { DropdownSelect }
