import type { ChangeEvent, FC } from 'react'
import React, { createRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import {
  Button,
  Check,
  COLOR,
  Error,
  InlineAlert,
  InlineAlertColor,
  Radio,
  RadioGroup,
  HeadingLarge,
  Paragraph,
} from '@extend/zen'
import { useHistory, useParams } from 'react-router-dom'
import { useStandardToast } from '@helloextend/merchants-ui'
import {
  useGetPlanCategoriesQuery,
  useGetProductCategoriesImportUrlMutation,
  useGetProductsImportUrlMutation,
  useImportProductsMutation,
} from '@helloextend/extend-api-rtk-query'
import { useGetPlanMappingsAndFullPlans } from '../../../../../../../hooks/use-get-plan-mappings-and-full-plans'
import categoriesCsvSample from '../../../../../../../misc/categories-csv-sample.csv'
import productsCsvSample from '../../../../../../../misc/products-csv-sample.csv'
import { SaveChangesButtonGroup } from '../../settings/components/save-changes-button-group'
import { parseProductCsv } from '../../../utils/product-csv-upload/parse-product-csv'
import { parseCategoryCsv } from '../../../utils/category-csv-upload/parse-category-csv'
import { fileToBuffer } from '../../../../../../../utils/file-to-buffer'

interface AlertItem {
  key: string
  text: string
}

const convertToAlertItem = (alerts: string[]): AlertItem[] => {
  return alerts.map((error, index) => ({
    key: `error-${index}`,
    text: error,
  }))
}

const ProductImport: FC = () => {
  const history = useHistory()
  const { storeId } = useParams<{ storeId: string }>()

  const [selectedImportType, setSelectedImportType] = useState<string>('product')
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true)
  const [isParsing, setIsParsing] = useState<boolean>(false)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [importItemsNumber, setImportItemsNumber] = useState(0)
  const [errorsBanner, setErrorsBanner] = useState<AlertItem[]>([])
  const [warningsBanner, setWarningsBanner] = useState<AlertItem[]>([])
  const [hasValidated, setHasValidated] = useState(false)

  const isSelectedImportTypeProduct = selectedImportType === 'product'

  const { toastCompleted, toastError } = useStandardToast()

  const inputFileRef = createRef<HTMLInputElement>()

  const { data: planCategories, isLoading: isPlanCategoriesLoading } = useGetPlanCategoriesQuery()

  const {
    data: planMappingsWithPlans,
    isLoading: isPlanMappingsLoading,
    isError: isPlanMappingsError,
  } = useGetPlanMappingsAndFullPlans(storeId)

  const [getProductsUploadUrl, { isLoading: isGettingProductsUploadUrl }] =
    useGetProductsImportUrlMutation()

  const [getCategoriesUploadUrl, { isLoading: isGettingCategoriesUploadUrl }] =
    useGetProductCategoriesImportUrlMutation()

  const [uploadCsv] = useImportProductsMutation()

  const handleCancelClick = (): void => {
    history.push(`/admin/stores/${storeId}/products`)
  }

  const getUploadUrl = async (): Promise<string | null> => {
    if (!uploadedFile) return null
    let response
    try {
      switch (selectedImportType) {
        case 'product':
          response = await getProductsUploadUrl({
            storeId,
            fileName: uploadedFile?.name || '',
          }).unwrap()
          break
        case 'category':
          response = await getCategoriesUploadUrl({
            storeId,
            fileName: uploadedFile?.name || '',
          }).unwrap()
          break
        default:
          break
      }
      return response?.url || null
    } catch {
      toastError('Something went wrong. Please try again.')
      return null
    }
  }
  const handleImportClick = async (): Promise<void> => {
    const url = await getUploadUrl()
    if (!url || !uploadedFile) return

    const bufferArray = await fileToBuffer(uploadedFile)
    try {
      const importTypeText = isSelectedImportTypeProduct ? 'products' : 'categories'
      await uploadCsv({ url, fileArrayBuffer: bufferArray }).unwrap()
      toastCompleted(
        `${importItemsNumber} ${importTypeText} have been successfully imported. Check back in a bit to see your uploaded ${importTypeText}.`,
      )
      history.push(`/admin/stores/${storeId}/products`)
    } catch {
      toastError('Something went wrong. Please try again.')
    }
  }

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSelectedImportType(e.target.value)
  }

  const handleSelectFile = (): void => {
    resetUploadedFile()
    inputFileRef.current?.click()
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0] || null
    setUploadedFile(file)
    setHasValidated(false)
  }

  const resetUploadedFile = (): void => {
    setUploadedFile(null)
    setErrorsBanner([])
    setWarningsBanner([])
    setHasValidated(false)
    setIsSaveDisabled(true)
    const { current } = inputFileRef
    if (current?.value) {
      current.value = ''
    }
  }

  useEffect(() => {
    if (isPlanMappingsError) {
      toastError('Error fetching plan mappings. Unable to validate product plans.')
    }
  }, [toastError, isPlanMappingsError])

  useEffect(() => {
    if (!uploadedFile || isPlanMappingsLoading || hasValidated || isPlanCategoriesLoading) {
      return
    }
    setIsParsing(true)
    ;(async function parseAndValidateUploadedFile(): Promise<void> {
      try {
        let results
        switch (selectedImportType) {
          case 'product':
            results = await parseProductCsv(
              uploadedFile,
              planMappingsWithPlans,
              planCategories?.items,
              false,
              true, // TODO: PREPX-457 Remove isSPEnabled FF
            )
            break
          case 'category':
            results = await parseCategoryCsv(uploadedFile)
            break
          default:
            break
        }
        setImportItemsNumber(results?.data?.length as number)

        const errors = convertToAlertItem(results?.errors || [])
        const warnings = convertToAlertItem(results?.warnings || [])

        setErrorsBanner(errors)
        setWarningsBanner(warnings)
        setHasValidated(true)

        if (errors.length === 0) {
          setIsSaveDisabled(false)
        }
      } catch {
        toastError('Something went wrong. Please try again.')
      }
      setIsParsing(false)
    })()
  }, [
    hasValidated,
    isPlanMappingsLoading,
    planCategories,
    planMappingsWithPlans,
    selectedImportType,
    toastError,
    uploadedFile,
    isPlanCategoriesLoading,
  ])

  const alertPrimaryButtonProps = {
    text: 'Replace File',
    onClick: handleSelectFile,
    isProcessing: isParsing,
    isDisabled: isParsing,
  }

  return (
    <>
      <HeadingLarge>CSV Import</HeadingLarge>
      {uploadedFile && hasValidated && errorsBanner.length === 0 && (
        <InlineAlertWrapper>
          <InlineAlert
            color={InlineAlertColor.green}
            icon={Check}
            primaryButtonProps={alertPrimaryButtonProps}
            data-cy="import-success-alert"
          >
            {uploadedFile?.name} has been successfully validated! You are about to import{' '}
            {importItemsNumber} {isSelectedImportTypeProduct ? 'products' : 'categories'}. Note that
            if the {isSelectedImportTypeProduct ? 'product' : 'category'} already exists, the
            attributes provided in this file will update the existing{' '}
            {isSelectedImportTypeProduct ? 'product' : 'category'}.
          </InlineAlert>
        </InlineAlertWrapper>
      )}
      {uploadedFile && hasValidated && errorsBanner.length > 0 && (
        <InlineAlertWrapper>
          <InlineAlert
            color={InlineAlertColor.red}
            icon={Error}
            primaryButtonProps={alertPrimaryButtonProps}
            data-cy="import-error-alert"
          >
            {uploadedFile.name} failed to validate.
            {errorsBanner.map(({ key, text }) => (
              <li key={key}>{text}</li>
            ))}
          </InlineAlert>
        </InlineAlertWrapper>
      )}
      {uploadedFile && hasValidated && warningsBanner.length > 0 && (
        <InlineAlertWrapper>
          <InlineAlert
            color={InlineAlertColor.yellow}
            icon={Error}
            primaryButtonProps={alertPrimaryButtonProps}
            data-cy="import-error-alert"
          >
            {uploadedFile.name} failed to validate.
            {warningsBanner.map(({ key, text }) => (
              <li key={key}>{text}</li>
            ))}
          </InlineAlert>
        </InlineAlertWrapper>
      )}

      <ParagraphWrapper data-cy="download-links">
        <StyledDownload href={productsCsvSample}>Download product CSV example</StyledDownload> |{' '}
        <StyledDownload href={categoriesCsvSample}>category CSV example</StyledDownload>
      </ParagraphWrapper>
      {(!uploadedFile || isParsing) && (
        <>
          <RadioGroup
            name="Import Item"
            onChange={handleRadioChange}
            value={selectedImportType}
            label="Import Item"
            layout="vertical"
          >
            <Radio label="Merchant product" value="product" data-cy="product-radio" />
            <Radio label="Product category" value="category" data-cy="category-radio" />
          </RadioGroup>
          <ButtonContainer>
            <Button
              text="Select CSV File"
              onClick={handleSelectFile}
              emphasis="medium"
              isProcessing={isParsing}
              isDisabled={isParsing}
            />
          </ButtonContainer>
        </>
      )}
      <ButtonsGroupContainer>
        <SaveChangesButtonGroup
          saveButtonText="Import"
          id="import-buttons-group"
          handleCancel={handleCancelClick}
          handleSave={handleImportClick}
          isSaveDisabled={isSaveDisabled}
          isLoading={
            isGettingProductsUploadUrl || isPlanMappingsLoading || isGettingCategoriesUploadUrl
          }
        />
      </ButtonsGroupContainer>
      <HiddenInput
        id="inputFile"
        ref={inputFileRef}
        accept=".csv"
        type="file"
        onChange={handleFileChange}
      />
    </>
  )
}

const ButtonsGroupContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

const HiddenInput = styled.input({
  display: 'none',
})

const ButtonContainer = styled.div({
  margin: '32px 0 40px',
})

const InlineAlertWrapper = styled.div({
  marginTop: 24,
})

const ParagraphWrapper = styled(Paragraph)({
  padding: '24px 0',
})

const StyledDownload = styled.a({
  color: COLOR.BLUE[800],
  textDecoration: 'underline',
})

export { ProductImport }
