import { call, cancel, cancelled, fork, put, take } from 'redux-saga/effects'
import type { SagaIterator } from 'redux-saga'
import { client } from '@helloextend/extend-api-client'
import { isErrorStatus } from '@helloextend/extend-api-fetch'
import { customLogger } from '@extend/client-helpers'
import { actions as contractsActions } from '../actions'
import { fetchContractSearchPlans } from './fetch-search-plans'

/*
 * Allows the search flow to be interrupted by the "CONTRACT_SEARCH_RESET" action,
 * which gets called when the search page is unmounted.
 *
 * The search endpoint API is currently slow (~3sec TTFB), and we do not wish to
 * introduce delayed side effects in the global state reducer for a delayed response,
 * while other views may also have to update the contract state.
 *
 * Pattern Source: https://redux-saga.js.org/docs/advanced/NonBlockingCalls.html
 */
export default function* searchByStore(
  action: ReturnType<typeof contractsActions.searchByStore>,
): SagaIterator {
  const contractSearchTask = yield fork(searchByStoreSaga, action)

  yield take('CONTRACT_SEARCH_RESET')
  yield cancel(contractSearchTask)
}

export function* searchByStoreSaga(
  action: ReturnType<typeof contractsActions.searchByStore>,
): SagaIterator {
  const { accessToken, searchKey, storeId, value } = action.payload
  yield put(contractsActions.searchByStoreRequest())
  try {
    const response = yield call(client.stores.searchContracts, {
      accessToken,
      storeId,
      searchKey,
      value,
    })

    if (isErrorStatus(response.status)) {
      yield put(contractsActions.searchByStoreFailure(response.data.message, response.status))
      return
    }
    // makes a blocking call to fetch all the plans for the contracts
    //  before dispatching a "successful" action. This guarantees
    //  that all the required resources (plans, contracts) have been
    //  queried and loaded before displaying the results
    yield call(fetchContractSearchPlans, response.data)
    yield put(contractsActions.searchByStoreSuccess(response.data))
  } catch (error) {
    if (error instanceof Error) {
      customLogger.error('Caught an unexpected error with stores-contracts-search', {
        errorMessage: error.message,
      })
      yield put(contractsActions.searchByStoreFailure(error.message))
    }
  } finally {
    if (yield cancelled()) {
      // task cancellation logic
    }
  }
}
