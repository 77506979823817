import { useFormikContext } from 'formik'
import type { FC } from 'react'
import React from 'react'
import { Grid, Information, Input, Stack, Subheading, Switch } from '@extend/zen'
import { ShippingProtectionPlansDropdown } from '../shipping-protection-plans-dropdown/shipping-protection-plans-dropdown'
import { RevenueShareInput } from '../../common/revenue-share-input'
import type { Values } from '../schema'
import { FlexRow } from '../../../../../../../styles'
import { PartialReimbursementSelection } from '../partial-reimbursement-selection/partial-reimbursement-selection'
import { Divider } from '../../../../../../../../components/divider'

const SpSinglePlanModel: FC = () => {
  const { values, errors, handleChange, handleBlur } = useFormikContext<Values>()

  return (
    <>
      {values.purchaseModel === 'SINGLE_PLAN' ? (
        <>
          <Subheading>Single Plan Purchase Model</Subheading>
          <PartialReimbursementSelection />
        </>
      ) : (
        <>
          <Divider />
          <Subheading>Fall-back plan</Subheading>
        </>
      )}
      {values.partialReimbursementSelection && values.partialReimbursementEnabled && (
        <Grid columns={4} spacing={4}>
          <Input
            label={
              values.partialReimbursementSelection === 'percentage'
                ? 'Partial Reimbursement Percentage'
                : 'Fixed Partial Reimbursement'
            }
            id="partialReimbursementSPValue"
            value={values.partialReimbursementSPValue?.toString() || ''}
            suffix={values.partialReimbursementSelection === 'percentage' ? '%' : ''}
            placeholder="Enter a number"
            onChange={handleChange}
            onBlur={handleBlur}
            isError={Boolean(errors?.partialReimbursementSPValue)}
            errorFeedback={errors?.partialReimbursementSPValue}
            data-cy="fallback-sp-partial-reimbursement-value"
          />
        </Grid>
      )}
      <Stack spacing={1} data-cy="fallback-sp-settings">
        <Grid columns={3}>
          <ShippingProtectionPlansDropdown
            value={values.planId}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={Boolean(errors.planId)}
            errorFeedback={errors.planId}
            id="planId"
            isPlanDetailsDisplayed
          />
        </Grid>
        <Grid columns={3}>
          <RevenueShareInput
            value={values.merchantSpRevenueSharePercentage}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={Boolean(errors.merchantSpRevenueSharePercentage)}
            errorFeedback={errors.merchantSpRevenueSharePercentage}
          />
        </Grid>
        <FlexRow>
          <Switch
            label="Offers by Category Enabled"
            id="offersByCategoryEnabled"
            isOn={!!values?.offersByCategoryEnabled}
            onChange={handleChange}
            isDisabled={values.purchaseModel === 'CATEGORY'}
            data-cy="sp-offers-by-category-toggle"
          />
          <Information data-cy="sp-offers-by-category-info">
            <p>
              Extend can offer Shipping Protection on products that don&apos;t exist in the store
              product catalog if this setting is Enabled.
            </p>
          </Information>
        </FlexRow>
      </Stack>
    </>
  )
}

export { SpSinglePlanModel }
