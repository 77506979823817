import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import type { RuleStatus } from '@helloextend/extend-api-rtk-query'
import { Paragraph } from '@extend/zen'
import { KeySelector } from './key-selector'
import { ValueSelector } from './value-selector'
import type { RootState } from '../../../../../reducers'
import * as selectors from '../../../../../reducers/selectors'
import { identifyCondition } from '../../utils'
import { OperatorSelector } from './operator-selector'
import { OffsetSelector } from './offset-selector'
import { ComparandSelector } from './comparand-selector'
import { NumericInput } from './numeric-input'

type ConditionProps = {
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
  isDisabled?: boolean
}

const Condition: FC<ConditionProps> = ({
  children,
  rulesetType,
  ruleIndex,
  conditionIndex,
  isDisabled,
}) => {
  const condition = useSelector((state: RootState) =>
    selectors.getCondition({ state, rulesetType, ruleIndex, conditionIndex }),
  )
  const rule = useSelector((state: RootState) =>
    selectors.getRule({ state, rulesetType, ruleIndex }),
  )
  const conditionType = condition ? identifyCondition(condition) : null

  if (!conditionType) return null

  const isNonNumericCondition = conditionType.includes('nonNumeric')
  const isNumericCondition = conditionType === 'numericScript' || conditionType === 'numericValue'
  const isLastCondition = Boolean(rule && conditionIndex === rule?.conditions.length - 1)
  const isConditionEmpty = conditionType === 'nonNumericScript' && condition?.script === -1
  const isMonetaryValue = isNumericCondition && condition?.comparand === 'totalOfPurchasePrices'
  const isScriptCondition =
    conditionType === 'nonNumericScript' || conditionType === 'numericScript'

  return (
    <ConditionContainer
      data-cy={`amp-condition-rule${ruleIndex}-condition${conditionIndex}-${rulesetType}`}
    >
      <Row>
        <SelectorWrapper>
          <Text>{isScriptCondition ? `The answer of` : 'The value of'}</Text>
          <KeySelector
            source="thread"
            rulesetType={rulesetType}
            ruleIndex={ruleIndex}
            conditionIndex={conditionIndex}
            isDisabled={isDisabled}
          />
          <Text>is</Text>
          {isNonNumericCondition && (
            <ValueSelector
              rulesetType={rulesetType}
              ruleIndex={ruleIndex}
              conditionIndex={conditionIndex}
              isDisabled={isDisabled || isConditionEmpty}
              isLargeWidth={!isLastCondition || isNumericCondition}
            />
          )}
          {isNumericCondition && (
            <OperatorSelector
              rulesetType={rulesetType}
              ruleIndex={ruleIndex}
              conditionIndex={conditionIndex}
              isDisabled={isDisabled}
              conditionType={conditionType}
            />
          )}
        </SelectorWrapper>
        {isNonNumericCondition && <>{children}</>}
      </Row>
      {isNumericCondition && (
        <Row>
          <SelectorWrapper>
            {conditionType === 'numericScript' ? (
              <>
                <OffsetSelector
                  rulesetType={rulesetType}
                  ruleIndex={ruleIndex}
                  conditionIndex={conditionIndex}
                  isDisabled={isDisabled}
                />
                <ComparandSelector
                  rulesetType={rulesetType}
                  ruleIndex={ruleIndex}
                  conditionIndex={conditionIndex}
                  isDisabled={isDisabled}
                />
              </>
            ) : (
              <NumericInput
                rulesetType={rulesetType}
                ruleIndex={ruleIndex}
                conditionIndex={conditionIndex}
                isDisabled={isDisabled}
                isMonetaryValue={isMonetaryValue}
              />
            )}
            {children}
          </SelectorWrapper>
        </Row>
      )}
    </ConditionContainer>
  )
}

const ConditionContainer = styled.div({
  padding: '12px 0px',
  display: 'grid',
  gap: 12,
})

const Text = styled(Paragraph)({
  flex: 'max-content',
  textWrap: 'nowrap',
})

const Row = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 8,
})

const SelectorWrapper = styled.div({
  maxWidth: 704,
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  flexBasis: '100%',
})

export { Condition }
