import type { FC } from 'react'
import React from 'react'

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@extend/zen'`
 */
const TableHead: FC = ({ children }) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  )
}

export { TableHead }
