import type { FC } from 'react'
import React from 'react'
import type { AdvancedSelectChangeEvent, AdvancedSelectOption } from '@extend/zen'
import { AdvancedSelect } from '@extend/zen'
import { CategoryMappingStatus } from '@helloextend/extend-api-client'

export interface WarrantStatusSelectorProps {
  isDisabled: boolean
  onChange: (e: AdvancedSelectChangeEvent<string>) => void
  value: string
}

const WarrantyStatusSelector: FC<WarrantStatusSelectorProps> = ({
  isDisabled,
  onChange,
  value = '',
}) => {
  return (
    <AdvancedSelect
      isDisabled={isDisabled}
      id="mapping-status-select"
      data-cy="mapping-status-select"
      label="Warranty Status"
      placeholder="Select"
      onChange={onChange}
      value={value}
      options={getWarrantyStatusOptions()}
      multiple={false}
    />
  )
}

export const getWarrantyStatusOptions = (): AdvancedSelectOption[] => {
  const statusOptions = Object.values(CategoryMappingStatus).filter(
    (status) => status !== CategoryMappingStatus.UNMAPPED,
  )
  return statusOptions.map((status) => ({
    display: status,
    value: status,
  }))
}

export { WarrantyStatusSelector }
