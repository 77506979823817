import type { StoreConfig } from '@helloextend/extend-api-client'

export interface SPOfferSurfaceManagementForm {
  isLearnMoreModal: boolean
  isCartOffer: boolean
  learnMoreModalSelect: string
  cartOfferSelect: string
}

type SurfaceVersion = StoreConfig['surfaceVersion']

export const mapSPOfferSurfaceVersionToFormValues = (surfaceVersion: SurfaceVersion) => {
  if (!surfaceVersion) {
    return {
      isLearnMoreModal: false,
      isCartOffer: false,
      learnMoreModalSelect: 'V1',
      cartOfferSelect: 'V1',
    }
  }

  return {
    isLearnMoreModal: surfaceVersion.learnMoreModal?.enabled ?? false,
    isCartOffer: surfaceVersion.cartOffer?.enabled ?? false,
    learnMoreModalSelect: surfaceVersion.learnMoreModal?.version ?? 'V1',
    cartOfferSelect: surfaceVersion.cartOffer?.version ?? 'V1',
  }
}

export const mapSPOfferSurfaceVersionFromFormValues = (
  values: SPOfferSurfaceManagementForm,
): SurfaceVersion => {
  const result = {
    learnMoreModal: {
      version: values.learnMoreModalSelect,
      enabled: values.isLearnMoreModal,
    },
    cartOffer: {
      version: values.cartOfferSelect,
      enabled: values.isCartOffer,
    },
  }

  return result as SurfaceVersion
}
