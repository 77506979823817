import type { Product } from '@helloextend/extend-api-client'
import * as Yup from 'yup'

const schema = (product: Product): Yup.ObjectSchema<any> =>
  Yup.object()
    .shape({
      referenceId: Yup.string()
        .required('Reference ID is required')
        .test(
          'isUnique',
          'Bundle item already exists. To update the item quantity, delete the existing bundle item and reconfigure it accordingly.',
          (value) => {
            if (!value) {
              return true
            }
            return !product?.subproducts?.find((subproduct) => subproduct.referenceId === value)
          },
        )
        .test('isNotParent', 'The parent bundle item cannot be added to the bundle.', (value) => {
          if (!value) {
            return true
          }
          return value !== product.referenceId
        }),
      quantity: Yup.number().required('Quantity is required'),
    })
    .defined()

export { schema }
