import {
  AccordionSection,
  Add,
  Button,
  Divider,
  Modal,
  ModalController,
  Section,
  Stack,
} from '@extend/zen'
import type {
  PlanTermCoveredInfo,
  PlanTermNotCovered,
} from '@helloextend/extend-api-rtk-query/src/plan-terms/types'
import type { FC } from 'react'
import React, { useState } from 'react'
import { CategoryBox } from './category-box'

type CoverageInfoProps = {
  termsId: string
  version: number
  coverageInfo:
    | {
        covered: PlanTermCoveredInfo[]
        notCovered: PlanTermNotCovered[]
      }
    | undefined
}

const CoverageInfo: FC<CoverageInfoProps> = ({ coverageInfo, termsId, version }) => {
  const [editMode, setEditMode] = useState(false)

  const [addCategoryModal, setAddCategoryModal] = useState<'covered' | 'notCovered' | undefined>()

  if (!coverageInfo?.covered && !coverageInfo?.notCovered && !editMode) {
    return (
      <Button
        icon={Add}
        type="button"
        text="Add Coverage Info"
        emphasis="medium"
        onClick={() => setEditMode(true)}
        data-cy="add-coverage-info-button"
      />
    )
  }

  return (
    <>
      {' '}
      <Section heading="Coverage Info" data-cy="coverage-info-section">
        <Stack spacing={3}>
          <AccordionSection
            isExpanded
            headingSize="sub"
            heading="covered"
            data-cy="covered-info-section"
          >
            <Stack spacingY={2}>
              {coverageInfo?.covered
                ? coverageInfo.covered.map((category) => (
                    <div key={`covered-${category.planCategoryId}`}>
                      <CategoryBox
                        termsId={termsId}
                        version={version}
                        type="covered"
                        category={category}
                      />
                    </div>
                  ))
                : null}
              <Button
                icon={Add}
                type="button"
                text="Add Category"
                emphasis="medium"
                onClick={() => setAddCategoryModal('covered')}
                data-cy="add-covered-category-button"
              />
            </Stack>
          </AccordionSection>
          <Divider />
          <AccordionSection
            isExpanded
            headingSize="sub"
            heading="Not Covered"
            data-cy="not-covered-info-section"
          >
            <Stack spacingY={2}>
              {coverageInfo?.notCovered
                ? coverageInfo.notCovered.map((category) => (
                    <div key={`notCovered-${category.planCategoryId}`}>
                      <CategoryBox
                        termsId={termsId}
                        version={version}
                        type="notCovered"
                        category={category}
                      />
                    </div>
                  ))
                : null}
              <Button
                icon={Add}
                type="button"
                text="Add Category"
                emphasis="medium"
                onClick={() => setAddCategoryModal('notCovered')}
                data-cy="add-not-covered-category-button"
              />
            </Stack>
          </AccordionSection>
        </Stack>
      </Section>
      <ModalController isOpen={!!addCategoryModal}>
        <Modal
          heading={`Add ${addCategoryModal === 'covered' ? 'Covered' : 'Not Covered'} Category`}
          size="xl"
          data-cy="add-category-modal"
        >
          {addCategoryModal && (
            <CategoryBox
              termsId={termsId}
              version={version}
              type={addCategoryModal}
              closeModal={() => setAddCategoryModal(undefined)}
            />
          )}
        </Modal>
      </ModalController>
    </>
  )
}

export { CoverageInfo }
