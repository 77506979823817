import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import type { DataTableAction } from '@extend/zen'
import { useToaster, ToastColor, ToastDuration, DataTable, ChevronRight } from '@extend/zen'
import { ZeroState } from '@helloextend/merchants-ui'
import { useGetUsersListWithGrantsQuery } from '@helloextend/extend-api-rtk-query'
import {
  userManagementColumns,
  toTableUserManagementUsers,
  FILTER_DEFS,
} from './user-management-data-table-config'
import type { TableUserManagementUser } from './user-management-data-table-config'

const UserManagementDataTable: FC = () => {
  const { toast } = useToaster()
  const { push } = useHistory()

  const {
    data: userData,
    isLoading,
    isError,
  } = useGetUsersListWithGrantsQuery({
    internal: true,
  })

  const getRowActions = (row: TableUserManagementUser): DataTableAction[] => [
    {
      onClick: () => push(`/admin/user-management/users/${row.uuid}`),
      icon: ChevronRight,
      emphasis: 'low',
    },
  ]

  useEffect(() => {
    if (isError) {
      toast({
        message: 'Unable to retrieve user data',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      return
    }
    if (isLoading) {
      toast({
        message: 'Retrieving results...This might take a few moments',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isLoading, isError, toast])

  return (
    <>
      {!isLoading && !userData?.length ? (
        <ZeroState
          title="No Extend Users found under this account"
          text="Users should be listed here"
          data-cy="user-management-zero-state:message-container"
        />
      ) : (
        <DataTable
          data-cy="user-management"
          data={toTableUserManagementUsers(userData ?? [])}
          getRowActions={getRowActions}
          isLoading={isLoading}
          filterDefs={FILTER_DEFS}
          columns={userManagementColumns}
        />
      )}
    </>
  )
}

export { UserManagementDataTable }
