import * as Yup from 'yup'
import { SPABTests } from '@extend-services/shipping-offers/dist/src/client/ab-tests'

const schema = Yup.object()
  .shape({
    abEnabled: Yup.boolean().required(),
    abType: Yup.string().oneOf(Object.values(SPABTests)).required('AB Test Type is required'),
  })
  .defined()

export { schema }
