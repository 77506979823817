import type { FC } from 'react'
import React, { memo, useMemo, useContext, useCallback } from 'react'
import { startCase } from 'lodash'
import styled from '@emotion/styled'
import type { ClaimRecord } from '@helloextend/extend-api-rtk-query'
import { date } from '@extend/client-helpers'
import { CaptionLegal, Badge, DataTable, Icon, Notes as NotesIcon } from '@extend/zen'
import type { CellContext, DataTableAction } from '@extend/zen'
import type { ClaimNote } from '@helloextend/extend-api-client'
import { EmptyMessage } from '../../../components/data-table/empty-message'
import type { ContractContextValue } from '../contract/components/contract-container'
import { ContractContext } from '../contract/components/contract-container'
import { getStatusBadgeDetails } from '../claims/claim-status'
import { ClaimNotesEntry } from '../claim/claim-details/components/claim-notes/claim-notes-entry'

export interface ClaimAssigned {
  firstName: string
  lastName: string
}

const ClaimsTable: FC = memo(() => {
  const {
    contract,
    claimData,
    claimNotes,
    isClaimsSearchLoading: isLoading = false,
  } = useContext(ContractContext) as ContractContextValue

  const { items } = claimData || {}
  const claimsExist = items?.length > 0

  const columns = useMemo(() => {
    return [
      {
        label: 'Claim Status',
        id: 'status',
        columnWidth: 70,
        disableSortBy: true,
        renderCell: (cellData: CellContext<ClaimRecord, ClaimRecord>) => {
          const badgeData = getStatusBadgeDetails(cellData.row.original)
          return badgeData ? (
            <Badge
              text={badgeData.badgeText}
              emphasis="medium"
              color={badgeData.badgeColor}
              size="regular"
              details={badgeData.badgeDetails}
            />
          ) : (
            <div />
          )
        },
      },
      {
        label: 'Product Category',
        id: 'category',
        columnWidth: 85,
      },
      {
        label: 'Customer Name',
        id: 'customerName',
        columnWidth: 80,
      },
      {
        label: 'Customer Email',
        id: 'customerEmail',
      },
      {
        label: 'Date Reported',
        id: 'dateReported',
        disableSortBy: false,
        columnWidth: 80,
        renderCell: (cellData: CellContext<ClaimRecord, number>): JSX.Element => (
          <StyledCaptionLegal data-cy="claim-reportedAt">
            {date.format(cellData.getValue(), 'DD MMM YYYY')}
          </StyledCaptionLegal>
        ),
      },
      {
        label: 'Claim ID',
        id: 'id',
        columnWidth: 150,
      },
      {
        label: 'Merchant',
        id: 'merchant',
        columnWidth: 70,
      },
      {
        label: 'Assignee',
        headerTooltip: 'Assignee',
        id: 'assignee',
      },
    ]
  }, [])

  const getRowActions = (rowData: ClaimRecord): DataTableAction[] => {
    return [
      {
        onClick: () => {
          window.open(`/admin/claims/${rowData.id}`, '_blank', 'noreferrer')
        },
        type: 'button',
        text: 'View',
        'data-cy': 'view-claim-button',
      },
    ]
  }

  const claimsToDisplay = useMemo(() => {
    if (!items) return []
    const mappedClaims = items.map((claim: ClaimRecord) => {
      const { id, reportedAt, status, adjudicationCategory, customer } = claim
      const assignedUser = claim?.assignedUser as ClaimAssigned
      const assignee = assignedUser
        ? `${assignedUser?.firstName} ${assignedUser?.lastName}`
        : 'Unassigned'

      const category = (adjudicationCategory as string)?.split('_')?.map(startCase)?.join(' ')

      const notes = claimNotes?.filter((note: ClaimNote) => note.claimId === id) || []

      return {
        status,
        id,
        assignee,
        dateReported: reportedAt,
        customerName: customer.name,
        customerEmail: customer.email,
        category,
        merchant: contract.sellerName,
        claimNotes: notes,
        hasDetail: !!notes.length,
      }
    })

    return mappedClaims || []
  }, [contract.sellerName, items, claimNotes])

  const rowDetailRenderer = useCallback((data): React.ReactNode => {
    return (
      <ClaimNotesWrapper data-cy="claim-notes-wrapper">
        <ClaimNotesHeader>CLAIM NOTES</ClaimNotesHeader>
        {data?.claimNotes &&
          data.claimNotes.map((note: ClaimNote) => (
            <Notes id={note.id}>
              <Icon icon={NotesIcon} />
              <ClaimNotesEntry claimNote={note} key={note.id} isTableData />
            </Notes>
          ))}
      </ClaimNotesWrapper>
    )
  }, [])

  return (
    <>
      <DataTable
        data-cy="claims-table"
        data={claimsToDisplay}
        isLoading={isLoading}
        columns={columns}
        getRowActions={getRowActions}
        {...{
          rowDetailRenderer: claimNotes?.length ? rowDetailRenderer : undefined,
        }}
      />
      {!claimsExist && (
        <EmptyMessage
          header="No claims exist for this contract"
          message="When claims are added to the contract, changes will appear here"
        />
      )}
    </>
  )
})

const StyledCaptionLegal = styled(CaptionLegal)({
  fontSize: '12px',
})

const ClaimNotesHeader = styled.span({
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 700,
})

const ClaimNotesWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: '0 64px 0 16px',
})

const Notes = styled.div({
  display: 'flex',
  gap: 4,
})

export { ClaimsTable }
