import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.fetch>

export default function* fetch(action: Action): SagaIterator {
  const { storeId, accessToken } = action.payload

  yield put(actions.fetchRequest())

  try {
    const response: ResolvedType<typeof client.stores.fetch> = yield call(
      client.stores.fetch,
      storeId,
      accessToken,
    )

    if (isErrorResponse(response)) {
      yield put(actions.fetchFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.fetchFailure(`An unknown error occurred`, response.status))
      return
    }

    yield put(actions.fetchSuccess(response.data))
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.fetchFailure(e.message))
    }
  }
}
