import type { FC, ChangeEvent } from 'react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ModalController, Modal, Input, useToaster, ToastColor, ToastDuration } from '@extend/zen'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import type { ConversationResponse } from '@helloextend/extend-api-rtk-query'
import { usePatchConversationMutation } from '@helloextend/extend-api-rtk-query'
import * as Yup from 'yup'
import {
  setIsRenameConversationModalVisible,
  setSelectedConversation,
} from '../../../../../store/slices/amp-slice'

type RenameConversationModalProps = {
  isVisible: boolean
  onCancel: () => void
  conversation: ConversationResponse | null
}

const RenameConversationModal: FC<RenameConversationModalProps> = ({
  isVisible,
  onCancel,
  conversation,
}) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()

  const [renameConversation, { isLoading: isRenameConversationProcessing }] =
    usePatchConversationMutation()

  const schema = Yup.object()
    .shape({
      name: Yup.string().required('Conversation name is required'),
      description: Yup.string().default(''),
    })
    .defined()

  const {
    setFieldValue,
    values,
    errors,
    handleBlur,
    touched,
    isValid,
    dirty: isDirty,
  } = useFormik<Yup.InferType<typeof schema>>({
    enableReinitialize: true,
    initialValues: {
      name: conversation?.title ?? '',
      description: conversation?.description ?? '',
    },
    validationSchema: schema,
    onSubmit: () => {},
  })

  const isDisabled = !(isDirty && isValid)

  const handleNameInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue('name', e.target.value)
  }

  const handleDescriptionInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue('description', e.target.value)
  }

  const handleProceed = async (): Promise<void> => {
    if (!conversation) return
    try {
      let updatedConversation = conversation
      if (values.name !== conversation.title) {
        updatedConversation = await renameConversation({
          conversationId: conversation.id,
          patches: [
            {
              op: 'replace',
              path: '/title',
              value: values.name,
            },
          ],
        }).unwrap()
      }

      if (values.description !== conversation.description) {
        updatedConversation = await renameConversation({
          conversationId: conversation.id,
          patches: [
            {
              op: 'replace',
              path: '/description',
              value: values.description,
            },
          ],
        }).unwrap()
      }
      dispatch(setSelectedConversation(updatedConversation))
      dispatch(setIsRenameConversationModalVisible(false))
      toast({
        message: `${values.name} has been successfully updated.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    } catch (err: unknown) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="amp-rename-conversation-modal"
        heading="Rename conversation"
        primaryButtonProps={{
          'data-cy': 'amp-rename-conversation-modal-submit',
          onClick: handleProceed,
          text: 'Save',
          isDisabled: isDisabled || isRenameConversationProcessing,
          isProcessing: isRenameConversationProcessing,
        }}
        secondaryButtonProps={{
          'data-cy': 'amp-rename-conversation-modal-cancel',
          onClick: onCancel,
          text: 'Cancel',
        }}
        onDismissRequest={onCancel}
      >
        <>
          <NameWrapper>
            <Input
              data-cy="amp-rename-conversation-name"
              id="name"
              label="Name"
              onChange={handleNameInputChange}
              onBlur={handleBlur}
              value={values.name}
              isError={Boolean(errors.name) && touched.name}
              errorFeedback={errors.name}
            />
          </NameWrapper>
          <NameWrapper>
            <Input
              data-cy="amp-rename-conversation-description"
              id="description"
              label="description"
              onChange={handleDescriptionInputChange}
              onBlur={handleBlur}
              value={values.description}
            />
          </NameWrapper>
        </>
      </Modal>
    </ModalController>
  )
}

const NameWrapper = styled.div({
  marginBottom: 16,
})

export { RenameConversationModal }
