import type { FC } from 'react'
import React from 'react'
import { CurrencyInput, AdvancedSelect, Grid } from '@extend/zen'
import type { FormikErrors, FormikHandlers, FormikValues } from 'formik'
import type { SPCartPriceMap } from '@helloextend/extend-api-client/src/models/store-shipping-protection'
import { getFormikError } from '../../../../../../../../../utils/form-utils'

type OfferTypeByPriceBandRowProps = {
  index: number
  data: SPCartPriceMap
  errors: FormikErrors<FormikValues>
  isLabelDisplayed?: boolean
  handleOnChange: FormikHandlers['handleChange']
}

const OfferTypeByPriceBandRow: FC<OfferTypeByPriceBandRowProps> = ({
  index,
  data,
  errors,
  isLabelDisplayed,
  handleOnChange,
}) => {
  return (
    <Grid columns={3} spacing={4}>
      <CurrencyInput
        data-cy={`price-band-low-${index}`}
        value={data?.low?.toString() || ''}
        id={`priceBands[${index}]low`}
        onChange={handleOnChange}
        currency="USD"
        label={isLabelDisplayed ? 'Price Band Low' : undefined}
        isError={Boolean(getFormikError(errors, `priceBands.[${index}].low`))}
        errorFeedback={getFormikError(errors, `priceBands.[${index}].low`)}
      />
      <CurrencyInput
        data-cy={`price-band-high-${index}`}
        value={data.high?.toString() || ''}
        id={`priceBands[${index}]high`}
        onChange={handleOnChange}
        currency="USD"
        label={isLabelDisplayed ? 'Price Band High' : undefined}
        isError={Boolean(getFormikError(errors, `priceBands.[${index}].high`))}
        errorFeedback={getFormikError(errors, `priceBands.[${index}].high`)}
      />
      <AdvancedSelect
        data-cy={`offer-type-${index}`}
        value={data?.offerType || ''}
        id={`priceBands[${index}]offerType`}
        multiple={false}
        onChange={handleOnChange}
        options={[
          { display: 'Opt-In', value: 'OPT_IN' },
          { display: 'Opt-Out', value: 'OPT_OUT' },
          { display: 'Merchant Complete', value: 'OPT_MERCHANT' },
          { display: 'Safe Package Guarantee', value: 'SAFE_PACKAGE' },
        ]}
        label={isLabelDisplayed ? 'Offer Type' : undefined}
        isNotClearable
        placeholder="Select"
        isError={Boolean(getFormikError(errors, `priceBands.[${index}].offerType`))}
        errorFeedback={getFormikError(errors, `priceBands.[${index}].offerType`)}
      />
    </Grid>
  )
}

export { OfferTypeByPriceBandRow }
