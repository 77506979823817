import type { PrecheckResponse } from '@helloextend/extend-api-rtk-query'

export const getTooltipText = (data: PrecheckResponse | undefined, lineitemId?: string): string => {
  if (isClaimInProgress(data, lineitemId))
    return 'A claim is already in progress for this lineitem.'
  if (!data || data.status === 'success') return ''
  switch (data.validationError) {
    case 'active_claim_found':
      return 'A claim is already in progress for this contract.'
    case 'no_coverage_remaining':
      return 'There is no remaining coverage available'
    case 'expired':
      return 'This contract has expired.'
    case 'not_yet_active':
      return 'This contract is not yet active.'
    case 'service_type_invalid':
      return 'This contract does not support claims.'
    case 'mfr_warranty_active':
      return 'The manufacturer warranty is still active.'
    default:
      return ''
  }
}

export const isClaimInProgress = (
  data: PrecheckResponse | undefined,
  lineItemId?: string,
): boolean => {
  if (data?.status === 'failure') return false
  if (data?.status === 'success') {
    const { lineItems } = data
    if (!lineItemId || !lineItems) return false
    const lineItem = lineItems[lineItemId]
    return lineItem?.hasActiveClaim || false
  }
  return false
}
