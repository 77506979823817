import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import type { Claim as ApiClientClaim, ServiceOrder } from '@helloextend/extend-api-client'
import type { Claim } from '@extend-services/claims-management'
import { ClaimSelectStatus } from '@helloextend/extend-api-client'
import type { ButtonProps } from '@extend/zen'
import { ButtonGroup, Button, Stack, useToaster, ToastDuration, ToastColor } from '@extend/zen'
import {
  useLazySearchServiceOrdersQuery,
} from '@helloextend/extend-api-rtk-query'
import { isNil } from 'lodash'
import { setClaimDetailsActiveView } from '../../../../../../store/slices/claim-details'
import { ServiceOrderActionsMenu } from '../service-order-actions-menu'
import { ClaimActionsMenu } from '../claim-actions-menu'
import { getServiceOrderModalMap } from '../../../service-order-modal-map'
import { getIsClaimShippingAddressComplete } from '../../../util'
import { useUpdateClaim, useSubmitClaim } from '../../../../../../hooks'

interface ActionButtonsProps {
  claim: Claim | ApiClientClaim
  serviceOrder?: ServiceOrder
}

const ActionButtons: FC<ActionButtonsProps> = ({ claim, serviceOrder }) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()
  const { updateClaim, isSuccess, isLoading: isUpdating } = useUpdateClaim()
  const [searchServiceOrders] = useLazySearchServiceOrdersQuery()
  const { submitClaim, isLoading: isSubmitLoading } = useSubmitClaim()

  const serviceOrderStatusButton = useMemo((): ButtonProps[] => {
    if (!serviceOrder?.status) return []
    const { text, nextStep, action, buttonProps } = getServiceOrderModalMap(serviceOrder) ?? {}
    if (!action) return []
    return [
      {
        text: `${text}`,
        'data-cy': `${nextStep}-service-order-button`,
        onClick: () => {
          dispatch(setClaimDetailsActiveView(action))
        },
        ...buttonProps,
      },
    ]
  }, [dispatch, serviceOrder])

  const fetchServiceOrder = useCallback(() => {
    let retryAttempts = 0
    const intervalId = setInterval(() => {
      if (retryAttempts >= 4 || !isNil(serviceOrder)) {
        clearInterval(intervalId)
      }
      searchServiceOrders({ claimId: claim.id, includeShippingLabels: true })
      retryAttempts += 1
    }, 3000)
  }, [claim.id, serviceOrder, searchServiceOrders])

  const actions: ButtonProps[] = useMemo(() => {
    const approveButtonConfig: ButtonProps = {
      text: 'Approve Claim',
      emphasis: 'medium',
      'data-cy': 'approve-claim-button',
      isDisabled: isUpdating,
      onClick: async () => {
        if (claim.incident.failureType && claim.incident.failureType !== 'unanswered') {
          await updateClaim({
            claimId: claim.id,
            body: {
              status: ClaimSelectStatus.approved,
              incident: (claim as ApiClientClaim).incident,
            },
          })
        } else {
          dispatch(setClaimDetailsActiveView('approveClaimModal'))
        }
      },
    }

    const denyButtonConfig: ButtonProps = {
      text: 'Deny Claim',
      emphasis: 'medium',
      'data-cy': 'deny-claim-button',
      isDisabled: isUpdating,
      onClick: () => dispatch(setClaimDetailsActiveView('denyClaimModal')),
    }

    const closeClaimButton: ButtonProps[] = [
      {
        text: 'Close Claim',
        emphasis: 'low',
        'data-cy': 'close-claim-button',
        isDisabled: isUpdating,
        onClick: () => {
          dispatch(setClaimDetailsActiveView('closeClaimModal'))
        },
      },
    ]

    const adjudicateClaimButtonConfig: ButtonProps = {
      text: 'Adjudicate Claim',
      emphasis: 'medium',
      'data-cy': 'adjudicate-claim-button',
      isDisabled: isUpdating || !getIsClaimShippingAddressComplete(claim as Claim),
      onClick: async () => {
        await submitClaim({ claimId: claim.id })
      },
      isProcessing: isSubmitLoading,
      tooltip: !getIsClaimShippingAddressComplete(claim as Claim)
        ? "Please add the customer's shipping address before adjudicating"
        : undefined,
    }

    switch (claim?.status) {
      case 'review':
        return [approveButtonConfig, denyButtonConfig, ...closeClaimButton]
      case 'approved':
      case 'fulfilled':
        return serviceOrderStatusButton.length ? serviceOrderStatusButton : []
      case 'denied':
        return [approveButtonConfig]
      case 'pending_adjudication': {
        return [adjudicateClaimButtonConfig, ...closeClaimButton]
      }

      default:
        return []
    }
  }, [
    isUpdating,
    isSubmitLoading,
    claim?.status,
    claim.incident,
    claim.id,
    claim.customer,
    claim?.photoRequirements?.length,
    updateClaim,
    dispatch,
    submitClaim,
    serviceOrderStatusButton,
  ])

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: 'Claim approved!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })

      fetchServiceOrder()
    }
  }, [isSuccess, dispatch, fetchServiceOrder, toast])

  return (
    <ButtonContainer data-cy="action-buttons">
      <Stack isRow align="start" justify="end" spacing={1} doesWrap>
        <ButtonGroup>
          {actions.map((props) => {
            return <Button {...props} key={props.text} />
          })}
        </ButtonGroup>
        <ClaimActionsMenu claim={claim as ApiClientClaim} hasServiceOrder={Boolean(serviceOrder)} />
        <ServiceOrderActionsMenu
          serviceOrder={serviceOrder}
          cyPrefix="claim-details-page-header"
          claim={claim as ApiClientClaim}
        />
      </Stack>
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div({})

export { ActionButtons }
