import type { CsvUploadValidatorPipe } from '../csv-validation-models'

const productCsvHeaders = [
  'brand',
  'category',
  'description',
  'imageUrl',
  'mfrWarrantyParts',
  'mfrWarrantyLabor',
  'mfrWarrantyUrl',
  'price',
  'reimbursementAmount',
  'title',
  'referenceId',
  'toDelete',
  'sku',
  'gtin',
  'upc',
  'asin',
  'barcode',
  'warrantyProductStatus',
  'warrantyMatchStatus',
  'warrantyCategory',
  'planId1',
  'planId2',
  'planId3',
  'planId4',
  'planId5',
  'planId6',
  'planId7',
  'planId8',
  'planId9',
  'planId10',
  'planId11',
  'planId12',
  'planId13',
  'planId14',
  'planId15',
  'planId16',
  'planId17',
  'planId18',
  'shippable',
  'shipEnabled',
  'alternateLanguages',
  'planCategory',
] as const

const productCsvHeadersNewFields = [
  'brand',
  'category',
  'description',
  'imageUrl',
  'mfrWarrantyParts',
  'mfrWarrantyLabor',
  'mfrWarrantyUrl',
  'price',
  'reimbursementAmount',
  'title',
  'referenceId',
  'toDelete',
  'sku',
  'gtin',
  'upc',
  'asin',
  'barcode',
  'warrantyProductStatus',
  'warrantyMatchStatus',
  'warrantyCategory',
  'modelNumber',
  'itemDimensionsHeight',
  'itemDimensionsWidth',
  'itemDimensionsLength',
  'itemDimensionsUnit',
  'itemWeightValue',
  'itemWeightUnit',
  'subCategory1',
  'subCategory2',
  'subCategory3',
  'subCategory4',
  'subCategory5',
  'subCategory6',
  'planId1',
  'planId2',
  'planId3',
  'planId4',
  'planId5',
  'planId6',
  'planId7',
  'planId8',
  'planId9',
  'planId10',
  'planId11',
  'planId12',
  'planId13',
  'planId14',
  'planId15',
  'planId16',
  'planId17',
  'planId18',
  'shippable',
  'shipEnabled',
  'alternateLanguages',
  'planCategory',
] as const

export type ProductHeader = typeof productCsvHeaders[number]
export type ProductCsvValidatorPipe = CsvUploadValidatorPipe<ProductHeader>
export type ProductHeadersWithNewFields = typeof productCsvHeadersNewFields[number]
export type ProductCsvWithNewFieldsValidatorPipe = CsvUploadValidatorPipe<ProductHeader>

export { productCsvHeaders, productCsvHeadersNewFields }
