import type {
  ClaimsSearchParameters,
  ClaimsSearchClaim,
  InsuranceClaimsSearchResponse} from '@helloextend/extend-api-rtk-query';
import {
  useLazyClaimsSearchQuery
} from '@helloextend/extend-api-rtk-query'
import { useCallback, useEffect, useState } from 'react'
import { usePrevious } from '@helloextend/client-hooks'

export const useNewClaimsSearch = (
  params: ClaimsSearchParameters & { serviceOrderId?: string },
  skip = false,
): { data: ClaimsSearchClaim[]; isLoading: boolean } => {
  const [claims, setClaims] = useState<ClaimsSearchClaim[]>([])
  const [searchClaims] = useLazyClaimsSearchQuery()
  const [hasStartedFetching, setHasStartedFetching] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const prevParams = usePrevious(params)

  const fetchData = useCallback(async () => {
    let fetchedClaims: ClaimsSearchClaim[] = []
    let cursor: string | undefined = undefined
    const searchParams = { ...params }
    if (searchParams.serviceOrderId) {
      searchParams.claimId = searchParams.serviceOrderId
      delete searchParams.serviceOrderId
    }

    do {
      const response = (await searchClaims({
        cursor,
        ...searchParams,
      }).unwrap()) as InsuranceClaimsSearchResponse
      fetchedClaims = [...fetchedClaims, ...response.items]
      cursor = response.nextPageCursor
    } while (cursor)
    setClaims(fetchedClaims)
    setIsLoading(false)
  }, [setClaims, setIsLoading, params])

  useEffect(() => {
    if (skip) return
    if (prevParams) {
      const doesNotMatchPrevious = Object.entries(params).some(
        ([key, value]) => prevParams[key as keyof ClaimsSearchParameters] !== value,
      )
      if (doesNotMatchPrevious || Object.keys(params).length !== Object.keys(prevParams).length) {
        setClaims([])
        setIsLoading(true)
        fetchData()
      }
    }
  }, [params, prevParams, skip])

  useEffect(() => {
    if (skip) return
    if (!hasStartedFetching) {
      setHasStartedFetching(true)
      fetchData()
    }
  }, [hasStartedFetching, setHasStartedFetching, fetchData, skip])

  if (Object.keys(params).length === 0) {
    return { data: [], isLoading: false }
  }

  return {
    data: claims,
    isLoading,
  }
}
