import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

type TabMenuLinkDefinition = {
  text: string | React.ReactNode
  key: string
  'data-cy'?: string
}

export type TabMenuProps = {
  tabs: TabMenuLinkDefinition[]
  onClick: (idx: string) => void
  activeTab: string
}

const TabMenu: FC<TabMenuProps> = ({ tabs, onClick, activeTab = tabs[0].key }) => (
  <TabMenuWrapper>
    <List>
      {tabs.map((tab) => (
        <Tab
          data-cy={tab['data-cy'] || tab.text}
          key={tab.key}
          className={activeTab === tab.key ? 'active' : ''}
          onClick={() => onClick(tab.key)}
        >
          {tab.text}
        </Tab>
      ))}
    </List>
  </TabMenuWrapper>
)

const TabMenuWrapper = styled.div({
  height: 56,
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
})

const List = styled.ul({
  listStyleType: 'none',
  display: 'flex',
  justifyContent: 'flex-start',
  gap: 48,
  padding: 0,
  margin: 0,
})

const Tab = styled.div({
  cursor: 'pointer',
  color: COLOR.NEUTRAL[800],
  fontSize: 16,
  lineHeight: '24px',
  padding: `16px 0px`,
  zIndex: 1,
  '&.active': {
    color: COLOR.NEUTRAL[1000],
    borderBottom: `2px solid ${COLOR.BLUE[700]}`,
  },
})

export { TabMenu }
