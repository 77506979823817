import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@extend/zen'

type LogoutIconProps = {
  fill?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@extend/zen'`
 */
const LogoutIcon: FC<LogoutIconProps> = ({ fill = COLOR.BLACK }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M11.667 19.667H2.333c-1.133 0-2-.867-2-2V2.333c0-1.133.867-2 2-2h9.334c1.133 0 2 .867 2 2v4.334c0 .2-.134.333-.334.333S13 6.867 13 6.667V2.333C13 1.6 12.4 1 11.667 1H2.333C1.6 1 1 1.6 1 2.333v15.334C1 18.4 1.6 19 2.333 19h9.334C12.4 19 13 18.4 13 17.667v-4.334c0-.2.133-.333.333-.333s.334.133.334.333v4.334c0 1.133-.867 2-2 2z"
      fill={fill}
    />
    <path
      d="M19.333 10.333H8.667c-.2 0-.334-.133-.334-.333s.134-.333.334-.333h10.666c.2 0 .334.133.334.333s-.134.333-.334.333z"
      fill={fill}
    />
    <path
      d="M16 13.667c-.067 0-.2 0-.267-.067a.322.322 0 010-.467l3.067-3.066L15.733 7a.322.322 0 010-.467.322.322 0 01.467 0l3.333 3.334a.322.322 0 010 .466L16.2 13.667H16z"
      fill={fill}
    />
  </svg>
)

export { LogoutIcon }
