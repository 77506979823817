import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import type { ClaimUpdateRequest, Contract, InsuranceClaim, Reply, Slot } from '@helloextend/extend-api-client'
import {
  useListInsuranceClaimsQuery,
} from '@helloextend/extend-api-rtk-query'
import { Redirect } from 'react-router'
import { useHistory } from 'react-router-dom'
import { ContactForm } from './contact-form'
import { LeavePageGuard } from '../../../../../components/leave-page-guard'
import { ErrorMessage } from '../error-message'
import { PROMPT_MAP } from './types'
import { useSubmitClaim } from '../../../../../hooks'

interface UserInputProps {
  contract: Contract
  isIncredibotUpdating: boolean
  reply: Reply
  onIncredibotUpdate: (slot: Slot, slotValue: string | number | string[]) => void
  onUpdateClaim: (claimId: string, updates: ClaimUpdateRequest) => Promise<void>
  isClaimUpdateLoading: boolean
  toggleNavBlocked: (blocked: boolean) => void
  isNavBlocked: boolean
}

const UserInput: FC<UserInputProps> = ({
  contract,
  isIncredibotUpdating,
  reply,
  onIncredibotUpdate,
  onUpdateClaim,
  isClaimUpdateLoading,
  toggleNavBlocked,
  isNavBlocked,
}) => {
  const { prompt, poll } = reply
  const PromptComponent = prompt ? PROMPT_MAP[prompt.type] : null

  const [shouldPoll, setShouldPoll] = useState(false)
  const [isAdjudicated, setIsAdjudicated] = useState(false)
  const [claimCustomerInfo, setClaimCustomerInfo] = useState<InsuranceClaim['customer'] | undefined>(undefined)

  const email = poll?.customerEmail
  const phone = poll?.customerPhone
  const pollSessionId = poll?.sessionId

  const { submitClaim, isLoading: isSubmitLoading } = useSubmitClaim()

  const { data: { items: claims } = { items: [] }} = useListInsuranceClaimsQuery(
    {
      containsCustomerEmail: email,
      containsCustomerPhone: phone,
      sessionId: pollSessionId,
      minLimit: 500,
    },
    {
      skip: !shouldPoll,
      pollingInterval: 2000,
    },
  )

  const pendingAdjudicationClaim = claims.find(
    (claim: InsuranceClaim) => claim.status === 'pending_adjudication',
  )
  const isClaimPendingPhotoUploads =
    pendingAdjudicationClaim && !!pendingAdjudicationClaim?.photoRequirements?.length

  useEffect(() => {
    if (poll && !pendingAdjudicationClaim) {
      if (!shouldPoll) {
        setShouldPoll(true)
      }
    } else if (shouldPoll && claimCustomerInfo) {
      onUpdateClaim(pendingAdjudicationClaim?.id as string, { customer: {
        email: claimCustomerInfo.email,
        phone: claimCustomerInfo.phone,
        name: claimCustomerInfo.name,
        shippingAddress: claimCustomerInfo.shippingAddress,
      } })
      setShouldPoll(false)
    }
  }, [poll, shouldPoll, pendingAdjudicationClaim, setShouldPoll])

  const history = useHistory()

  const handleLeavePage = useCallback(
    (path: string): void => {
      history.push(path)
    },
    [history],
  )

  const handleSubmitClaimOrUpdateSession = async (
    slot: Slot,
    slotValue: string | number | string[],
  ): Promise<void> => {
    if (pendingAdjudicationClaim && poll && !isClaimUpdateLoading) {
      await submitClaim({ claimId: pendingAdjudicationClaim.id })
      setIsAdjudicated(true)
      return
    }
    // update the incredibot session
    onIncredibotUpdate(slot, slotValue)
  }

  const pageGuardProps = useMemo(
    () => ({
      isNavBlocked: true,
      handleLeavePage,
      mainText: `Cancel this customer’s claim?`,
      confirmation: 'Your progress will not be saved on this claim. Are you sure you want to exit?',
      submitButtonText: 'Confirm',
    }),
    [handleLeavePage],
  )

  if (
    pendingAdjudicationClaim &&
    (isAdjudicated || isClaimPendingPhotoUploads)
  ) {
    return (
      <Redirect
        to={`/admin/contracts/${contract.id}/file-a-claim/${pendingAdjudicationClaim.id
        }`}
      />
    )
  }

  const handleSaveClaimAddress = (updates: InsuranceClaim['customer']): void => {
    setClaimCustomerInfo(updates)
  }

  if (!claimCustomerInfo) {
    return (
      <UserInputWrapper>
        <LeavePageGuard {...pageGuardProps} isNavBlocked={isNavBlocked} />
        <ContactForm
          contract={contract}
          onSubmit={handleSaveClaimAddress}
          toggleNavBlocked={toggleNavBlocked}
        />
      </UserInputWrapper>
    )
  }

  return (
    <UserInputWrapper>
      <LeavePageGuard {...pageGuardProps} />
      {PromptComponent ? <PromptComponent
        onSubmit={handleSubmitClaimOrUpdateSession}
        reply={reply}
        isLoading={
          isIncredibotUpdating ||
          isSubmitLoading ||
          (poll && !pendingAdjudicationClaim) ||
          isClaimUpdateLoading
        }
        shouldAutoSelectOption={!!poll}
      />: <ErrorMessage contractId={contract.id} reply={reply} />}
    </UserInputWrapper>
  )
}

const UserInputWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 32,
  width: '100%',
  h2: {
    textAlign: 'center',
  },
})

export { UserInput }
