import React from 'react'
import { currency, date } from '@extend/client-helpers'
import type { CellContext, ColumnDef, ColumnFiltersState } from '@extend/zen'
import { Badge } from '@extend/zen'
import type { ContractStatus, ContractType } from '@helloextend/extend-api-client'
import { startCase } from 'lodash'
import type { TableContractsSearch } from '../../../types/contracts'
import { getContractStatusCopy } from '../../../lib/contract-status'
import { getContractTypeCopy } from '../../../lib/contract-type'

export const contractsColumns: Array<ColumnDef<TableContractsSearch>> = [
  {
    label: 'Status',
    id: 'status',
    renderCell: (cellData: CellContext<TableContractsSearch, ContractStatus>) => {
      const { badgeText, badgeColor } = getContractStatusCopy(cellData.getValue())
      return <Badge text={badgeText} color={badgeColor} />
    },
    isAlwaysPinned: true,
  },
  {
    label: 'Type',
    id: 'type',
    renderCell: (cellData: CellContext<TableContractsSearch, ContractType>) =>
      getContractTypeCopy(cellData.getValue()),
    isAlwaysPinned: true,
    isSortable: true,
  },
  {
    label: 'Plan Category',
    id: 'planCategory',
    renderCell: (cellData: CellContext<TableContractsSearch, string>) =>
      startCase(cellData.getValue()),
  },
  {
    label: 'Store',
    id: 'storeName',
    renderCell: (cellData: CellContext<TableContractsSearch, string>) => cellData.getValue() || '',
  },
  {
    label: 'Customer Name',
    id: 'customerName',
    renderCell: (cellData: CellContext<TableContractsSearch, string>) => cellData.getValue() || '',
  },
  {
    label: 'Customer Email',
    id: 'customerEmail',
    renderCell: (cellData: CellContext<TableContractsSearch, string>) => cellData.getValue() || '',
    search: 'explicit',
    validateSearch: (value: string): null | string => {
      if (value.length >= 3) {
        return null
      }
      return 'Must be at least 3 characters'
    },
  },
  {
    label: 'Phone Number',
    id: 'customerPhone',
    search: 'explicit',
    validateSearch: (value: string): null | string => {
      if (value.length >= 3) {
        return null
      }
      return 'Must be at least 3 characters'
    },
  },
  {
    label: 'Transaction ID',
    id: 'transactionId',
    renderCell: (cellData: CellContext<TableContractsSearch, string>) =>
      cellData.getValue() || null,
    search: 'explicit',
    validateSearch: (value: string): null | string => {
      if (value.length >= 3) {
        return null
      }
      return 'Must be at least 3 characters'
    },
  },
  {
    label: 'Contract ID',
    id: 'contractId',
    renderCell: (cellData: CellContext<TableContractsSearch, string>) => cellData.getValue(),
    search: 'explicit',
    validateSearch: (value: string): null | string => {
      if (value.length >= 3) {
        return null
      }
      return 'Must be at least 3 characters'
    },
  },
  {
    label: 'Product Name',
    id: 'productName',
    renderCell: (cellData: CellContext<TableContractsSearch, string>) => cellData.getValue(),
  },
  {
    label: 'Price',
    id: 'planPrice',
    renderCell: (cellData: CellContext<TableContractsSearch, number>) =>
      `${currency.formatWithoutCurrencySymbol(cellData.getValue())} ${
        cellData.row.original.currencyCode
      }`,
  },
  {
    label: 'Plan Purchase Date',
    id: 'purchaseDate',
    renderCell: (cellData: CellContext<TableContractsSearch, number>) =>
      date.format(cellData.getValue(), 'DD MMM YYYY'),
    isSortable: true,
  },
  {
    label: 'Product Serial Number',
    id: 'productSerialNumber',
    search: 'explicit',
  },
  {
    label: 'Merchant Customer ID',
    id: 'merchantCustomerId',
    search: 'explicit',
    validateSearch: (value: string): null | string => {
      if (value.length >= 3) {
        return null
      }
      return 'Must be at least 3 characters'
    },
  },
]

interface ContractsSearchArgumentsFilters {
  [key: string]: unknown
}

export const getTableFilters = (
  columnFilters: ColumnFiltersState,
): Partial<ContractsSearchArgumentsFilters> => {
  if (columnFilters.length > 0) {
    return {
      [columnFilters[0].id]: columnFilters[0].value,
    }
  }
  return {}
}
