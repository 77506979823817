import type { FC } from 'react'
import React, { useState } from 'react'
import type { WalletGetResponse } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import type {
  InsuranceClaim,
  Contract,
  Money,
  Order,
  ServiceOrder,
  Plan,
  Expense,
  ClaimNote,
  ClaimPhotosGetResponse,
} from '@helloextend/extend-api-client'
import { TabMenu } from '../../../../components/tab-menu/tab-menu'
import { ClaimDetailsTabs, ClaimPageTabs } from './components/tab-section/types'
import { ClaimHeader } from './components/claim-header'
import { ActionModal } from './components/action-modal'
import { ClaimNotes } from './components/claim-notes'
import { ClaimHistoryTab } from './tabs'
import { ClaimDetailsTab } from './tabs/claim-details-tab'

interface ClaimDetailsProps {
  claim: InsuranceClaim
  contract: Contract
  claimNotes: ClaimNote[]
  serviceOrders?: ServiceOrder[]
  entitlements?: { coverageAmountRemaining: Money }
  order?: Order
  expenses?: Expense[]
  plan?: Plan
  wallet?: WalletGetResponse
  photoData?: ClaimPhotosGetResponse
}

const ClaimDetails: FC<ClaimDetailsProps> = ({
  claim,
  contract,
  serviceOrders,
  entitlements,
  order,
  plan,
  expenses,
  wallet,
  claimNotes,
  photoData,
}) => {
  const [activePageTab, setActivePageTab] = useState<ClaimPageTabs>(ClaimPageTabs.ClaimDetails)
  const serviceOrder = serviceOrders && serviceOrders.length > 0 ? serviceOrders[0] : undefined
  const [activeDetailsTab, setActiveDetailsTab] = useState<ClaimDetailsTabs>(
    serviceOrders?.length ? ClaimDetailsTabs.ServiceOrder : ClaimDetailsTabs.IncidentReport,
  )

  return (
    <ClaimPageWrapper data-cy="claim-page-wrapper">
      <ClaimHeader
        claim={claim}
        product={contract.product}
        serviceOrder={serviceOrder}
        claimNotes={claimNotes}
        setActiveTab={setActiveDetailsTab}
      />
      <TabMenu
        tabs={[
          { text: 'Claim Details', key: ClaimPageTabs.ClaimDetails },
          { text: 'Claim History', key: ClaimPageTabs.ClaimHistory },
        ]}
        onClick={(tab) => setActivePageTab(tab as ClaimPageTabs)}
        activeTab={activePageTab}
      />
      <ClaimTabsWrapper>
        {activePageTab === ClaimPageTabs.ClaimDetails && (
          <ClaimDetailsTab
            claim={claim}
            serviceOrder={serviceOrder}
            serviceOrders={serviceOrders}
            plan={plan}
            order={order}
            contract={contract}
            claimNotes={claimNotes}
            setActiveTab={setActiveDetailsTab}
            activeTab={activeDetailsTab}
            photoData={photoData}
            entitlements={entitlements}
          />
        )}
        {activePageTab === ClaimPageTabs.ClaimHistory && (
          <ClaimHistoryTab
            claimId={claim.id}
            expenseIds={expenses?.map((doc) => doc.id) || []}
            serviceOrderIds={serviceOrders?.map((doc) => doc.id) || []}
          />
        )}
      </ClaimTabsWrapper>
      <ActionModal
        claim={claim}
        serviceOrders={serviceOrders}
        contract={contract}
        entitlements={entitlements}
        expenses={expenses}
        wallet={wallet}
      />
      <ClaimNotes claim={claim} claimNotes={claimNotes} />
    </ClaimPageWrapper>
  )
}

const ClaimPageWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

const ClaimTabsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 0,
})

export { ClaimDetails }
