import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import type { ButtonProps } from '@extend/zen'
import { COLOR, Button, Icon, Badge, Spinner, Close, Edit } from '@extend/zen'
import type { ConversationStatus, ThreadStatus } from '@helloextend/extend-api-rtk-query'
import { useDispatch } from 'react-redux'
import {
  setIsRenameThreadModalVisible,
  setIsRenameConversationModalVisible,
} from '../../../../store/slices/amp-slice'

interface AdjudicationTopnavProps {
  className?: string
  title: string
  name?: string
  status?: ConversationStatus | ThreadStatus
  actionButtons?: ButtonProps[]
  isLoading?: boolean
  onClose?: () => void
}

const THREAD_TITLES = ['Thread Preview', 'Thread Builder']
const THEMES = {
  blue: {
    navColor: COLOR.BLUE[800],
    iconColor: COLOR.NEUTRAL[100],
    titleColor: COLOR.WHITE,
  },
  white: {
    navColor: COLOR.WHITE,
    iconColor: COLOR.BLUE[700],
    titleColor: COLOR.BLACK,
  },
}

const AdjudicationTopnav: FC<AdjudicationTopnavProps> = ({
  className,
  title,
  name,
  status,
  actionButtons,
  isLoading,
  onClose,
}) => {
  const dispatch = useDispatch()
  const topNavStatusColor = status === 'published' ? 'green' : 'yellow'

  const isThreadHeader = THREAD_TITLES.includes(title)

  const handleRenameClick = (): void => {
    if (isThreadHeader) {
      dispatch(setIsRenameThreadModalVisible(true))
    } else {
      dispatch(setIsRenameConversationModalVisible(true))
    }
  }

  const { navColor, titleColor, iconColor } = useMemo(() => {
    return isThreadHeader ? THEMES.blue : THEMES.white
  }, [isThreadHeader])

  return (
    <TopnavWrapper className={className} color={navColor} data-cy="topnav-element">
      <TopnavSection>
        <TopnavTitle data-cy="topnav-title" color={titleColor}>
          {title}
        </TopnavTitle>
        <VerticalDivider />
        {isLoading ? (
          <Spinner data-cy="topnav-spinner" color={titleColor} />
        ) : (
          <>
            <TitleContainer
              includeButton={title === 'Thread Builder' || title === 'Conversation Builder'}
              data-cy="title-container"
            >
              <TopnavName data-cy="topnav-name" color={titleColor}>
                {name}
              </TopnavName>
              <div className="button-wrapper">
                <Button
                  icon={Edit}
                  onClick={handleRenameClick}
                  data-cy="rename-thread-button"
                  emphasis={isThreadHeader ? 'high' : 'low'}
                />
              </div>
            </TitleContainer>
            <TopnavStatus>
              <Badge
                data-cy="topnav-status"
                color={topNavStatusColor}
                emphasis="medium"
                text={getBadgeText(status as ConversationStatus | ThreadStatus)}
              />
            </TopnavStatus>
          </>
        )}
      </TopnavSection>
      <TopnavSection>
        <ButtonGroup>
          {actionButtons &&
            actionButtons.map(({ text, ...props }) => <Button key={text} text={text} {...props} />)}
        </ButtonGroup>
        <IconWrapper onClick={onClose} data-cy="topnav-close">
          <Icon icon={Close} size={20} color={iconColor} />
        </IconWrapper>
      </TopnavSection>
    </TopnavWrapper>
  )
}

const TopnavWrapper = styled.div<{ color: string }>(({ color }) => ({
  marginTop: 0,
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'space-between',
  height: 56,
  width: '100vw',
  background: color,
  boxShadow: `0px 4px 10px ${COLOR.NEUTRAL[300]}`,
  zIndex: 2,
}))

const TopnavSection = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
})

const TopnavTitle = styled.div<{ color: string }>(({ color }) => ({
  color,
  marginLeft: 32,
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '28px',
}))

const TopnavName = styled.div<{ color: string }>(({ color }) => ({
  color,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
}))

const TopnavStatus = styled.div({
  fontWeight: 700,
  fontSize: 13,
  lineHeight: '18px',
})

const VerticalDivider = styled.div({
  height: 30,
  borderLeft: `1px solid ${COLOR.NEUTRAL[300]}`,
})

const ButtonGroup = styled.div({
  display: 'flex',
  gap: 12,
})

const IconWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginRight: 40,
  '&:hover': {
    cursor: 'pointer',
  },
})

const TitleContainer = styled.div<{ includeButton?: boolean }>(({ includeButton = false }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  '.button-wrapper': {
    display: 'none',
  },
  ...(includeButton && {
    '&:hover': {
      cursor: 'pointer',
      '.button-wrapper': {
        display: 'inline',
      },
    },
  }),
}))

export const getBadgeText = (status: ConversationStatus | ThreadStatus): string => {
  const mapping = {
    draft: 'Draft',
    published: 'Published',
    pending_changes: 'Pending Changes',
    archived: 'Archived',
    override: 'Override',
  }
  return mapping[status] || 'Unknown'
}

export { AdjudicationTopnav }
