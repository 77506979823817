import type { SortingState, ColumnFiltersState} from '@extend/zen';
import { DataTable, Add } from '@extend/zen'
import { ContractType } from '@helloextend/extend-api-client'
import type {
  ContractSearchV1QueryStringOptions} from '@helloextend/extend-api-rtk-query';
import {
  useSearchContractsV1Query,
} from '@helloextend/extend-api-rtk-query'
import type { FC} from 'react';
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import type { TableContractsSearch } from 'src/types/contracts'
import { contractsColumns } from '../../../contracts/tableConfig'
import { prepareContractSearchArgs, formatAndSortContractItems } from '../../../contracts/utils'
import styled from '@emotion/styled'

export const ContractsTable: FC = () => {
  const history = useHistory()

  const [sorting, setSorting] = useState<SortingState>([{ id: 'type', desc: true }])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const argsForApi: ContractSearchV1QueryStringOptions = useMemo(
    () => prepareContractSearchArgs(columnFilters),
    [columnFilters],
  )

  const { data, isFetching, isError } = useSearchContractsV1Query(argsForApi, {
    skip: columnFilters.length === 0,
  })

  const contracts = useMemo(
    () => (data?.contracts ? formatAndSortContractItems(data.contracts, sorting) : []),
    [data?.contracts, sorting],
  )

  const handleClick = (): void => {
    history.push('/admin/contracts/create')
  }

  const handleRowClick = (contract: TableContractsSearch): void => {
    const contractTypes = [
      ContractType.PCRS,
      ContractType.CATEGORY,
      ContractType.SHIPPING_PROTECTION,
      ContractType.PRODUCT_PROTECTION_BUNDLE,
    ]

    if (contractTypes.includes(contract.type)) {
      window.open(`/admin/contracts/${contract.contractId}`, '_blank')
    }
  }

  return (
    <Container>
      <DataTable
        itemName="contract"
        data-cy="contracts"
        isError={isError}
        isLoading={isFetching}
        data={contracts}
        columns={contractsColumns}
        rowCount={contracts.length}
        sorting={sorting}
        onSortingChange={setSorting}
        hasManualSorting
        hasManualFiltering
        columnFilters={columnFilters}
        onColumnFiltersChange={setColumnFilters}
        getRowId={(row) => row.contractId}
        stateViews={{
          preFilter: {
            description: 'Select your search criteria before submitting.',
          },
        }}
        getRowActions={(row) => [
          {
            onClick: () => handleRowClick(row),
            text: 'View',
            emphasis: 'low',
          },
        ]}
        getTableActions={() => [
          {
            emphasis: 'medium',
            text: 'Create Contract',
            onClick: handleClick,
            icon: Add,
          },
        ]}
        hasRequiredFilter
      />
    </Container>
  )
}

// Temp fix to ensure the select input is rendered correctly within the tabs
const Container = styled.div({
  '[data-cy="contracts:search-input-with-select:select"]': {
    minWidth: '168px !important',
  },
})
