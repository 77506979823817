import React, { useEffect, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import type { FC } from 'react'
import type { InsuranceClaim } from '@helloextend/extend-api-client'
import type { ClaimsSearchClaim} from '@helloextend/extend-api-rtk-query';
import { useLazyGetInsuranceClaimQuery } from '@helloextend/extend-api-rtk-query'
import { CellText } from '@helloextend/merchants-ui'
import { date } from '@extend/client-helpers'
import type { RootState } from '../../../reducers'
import * as selectors from '../../../reducers/selectors'

interface DateAssignedDisplayProps {
  claim?: InsuranceClaim | ClaimsSearchClaim
}

const DateAssignedDisplay: FC<DateAssignedDisplayProps> = ({ claim: inputClaim }) => {
  const [claim, setClaim] = useState(inputClaim)
  const lastUpdatedClaimId = useSelector((state: RootState) =>
    selectors.getPreviouslyUpdatedClaimId(state),
  )

  const [getClaim] = useLazyGetInsuranceClaimQuery()

  const fetchClaim = useCallback(async () => {
    const fetchedClaim = await getClaim({ claimId: claim?.id ?? '' })
    if (fetchedClaim?.data) {
      setClaim(fetchedClaim.data)
    }
  }, [claim?.id, getClaim, setClaim])

  useEffect(() => {
    if (lastUpdatedClaimId && lastUpdatedClaimId === claim?.id) {
      fetchClaim()
    }
  }, [lastUpdatedClaimId, claim?.id, fetchClaim])

  return (
    <CellText data-cy="claim-userAssignedAt">
      {claim?.userAssignedAt ? date.format(claim.userAssignedAt, 'DD MMM YYYY') : ''}
    </CellText>
  )
}

export { DateAssignedDisplay }
