import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorStatus } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.forgotPassword>

export function* forgotPassword(action: Action): SagaIterator {
  const { username: email } = action.payload

  yield put(actions.forgotPasswordRequest())

  const response = yield call(client.auth.forgotPassword, email)

  if (isErrorStatus(response.status)) {
    yield put(actions.forgotPasswordFailure(response.data.message, response.status))
    return
  }

  yield put(actions.forgotPasswordSuccess())
}
