import type { FC } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { InlineAlert, InlineAlertColor, COLOR } from '@extend/zen'
import type { DefaultPrompt, PromptType, ThreadResponse } from '@helloextend/extend-api-rtk-query'
import { Divider } from '../../../../../components/divider'
import { KaleyText } from '../kaley-text'
import { CustomerResponse } from '../customer-response'
import { ThreadConnectorDropdown } from '../thread-connector-dropdown'
import {
  getIsReplacementReply,
  getMessageBlockTitle,
  getMessageBlockTypeFromScriptItem,
  MessageBlockTypes,
} from '../../utils'
import type { SelectedMessageBlock } from '../../../../../store/slices/amp-slice'
import { MessageBlockImageUploader } from '../message-block-image-uploader'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'

type MessageBlockEditorProps = {
  thread: ThreadResponse
  messageBlock: SelectedMessageBlock
  isThreadStructureLocked: boolean
  isThreadEditor?: boolean
}

const MessageBlockEditor: FC<MessageBlockEditorProps> = ({
  thread,
  messageBlock,
  isThreadStructureLocked,
  isThreadEditor = true,
}) => {
  const selectedBlockIndex = useSelector((state: RootState) =>
    selectors.getSelectedMessageBlockIndex(state),
  )

  const { reply } = messageBlock.script
  const messageType = !getIsReplacementReply(reply)
    ? getMessageBlockTitle((reply.prompt as DefaultPrompt)?.type)
    : ''
  const prompt = getIsReplacementReply(reply)
    ? { type: 'replacement' as PromptType, slot: 'REPLACEMENT' }
    : (reply?.prompt as DefaultPrompt)

  const messageBlockType = getMessageBlockTypeFromScriptItem(messageBlock.script)
  const slot = prompt?.slot ?? ''
  const isStatementAlertEnabled = messageType === 'Statement'
  const isImageUploadEnabled = prompt?.type === 'imageUpload'

  return (
    <div data-cy="message-block-editor">
      {isStatementAlertEnabled && (
        <StatementWrapper>
          <InlineAlert color={InlineAlertColor.blue} data-cy="message-block-editor-inline-alert">
            Statement Message block can only be used at the end of a conversation.
          </InlineAlert>
        </StatementWrapper>
      )}
      <Divider color={COLOR.NEUTRAL[300]} />
      <KaleyWrapper>
        <KaleyText messageBlock={messageBlock} isThreadStructureLocked={isThreadStructureLocked} />
      </KaleyWrapper>
      {isThreadEditor && messageBlockType !== MessageBlockTypes.statement && (
        <ThreadConnectorDropdown
          value={slot}
          isDisabled={isThreadStructureLocked}
          messageBlockType={messageBlockType}
        />
      )}
      {isImageUploadEnabled && (
        <ImageUploadWrapper>
          <MessageBlockImageUploader messageBlock={messageBlock} />
        </ImageUploadWrapper>
      )}
      <CustomerResponse
        thread={thread}
        messageBlock={messageBlock}
        messageBlockIndex={selectedBlockIndex}
        isThreadStructureLocked={isThreadStructureLocked}
      />
    </div>
  )
}

const StatementWrapper = styled.div({
  borderRadius: '6',
  padding: '16',
  margin: '10px 0 10px 0',
})

const KaleyWrapper = styled.div({
  padding: '24px 0 32px 0',
})

const ImageUploadWrapper = styled.div({
  padding: '24px 0 32px 0',
})

export type { MessageBlockEditorProps }
export { MessageBlockEditor }
