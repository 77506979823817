import styled from '@emotion/styled'
import type { FC } from 'react'
import React from 'react'
import type { TextAlign } from './table-styles'

type TableCellProps = {
  columnWidth?: number
  align?: TextAlign
  isCollapsible?: boolean
  colspan?: number
}

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@extend/zen'`
 */
const TableCell: FC<TableCellProps> = ({
  children,
  columnWidth = 100,
  align = 'left',
  isCollapsible = false,
  colspan = 1,
}) => (
  <CellContainer width={columnWidth} colSpan={colspan} align={align} isCollapsible={isCollapsible}>
    {children}
  </CellContainer>
)

const CellContainer = styled.td<{ width: number; align: TextAlign; isCollapsible?: boolean }>(
  ({ width, align, isCollapsible = false }) => ({
    width: `${width}%`,
    textAlign: align,
    paddingLeft: align !== 'right' ? 12 : 1,
    paddingRight: align === 'right' ? 12 : 1,
    ...(isCollapsible && {
      display: 'flex',
      alignItems: 'center',
    }),
  }),
)

export { TableCell }
