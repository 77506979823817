import type { FC } from 'react'
import React from 'react'
import {
  HeaderMenuItem,
  HeaderMenuLink,
  MenuLinkItem,
  Popover,
  Stack,
  usePopover,
} from '@extend/zen'
import type { NavDropdown, NavLink } from './types'
import { instanceOfDropdown } from './types'
import { usePermissions } from '../../hooks/use-permissions'

type DashboardNavLinkProps = {
  navLink: NavLink | NavDropdown
}

const DashboardNavLink: FC<DashboardNavLinkProps> = ({ navLink }: DashboardNavLinkProps) => {
  const { triggerRef, popoverRef, isPresent, toggle, hide, triggerBoundingBox } =
    usePopover<HTMLDivElement>()
  const { hasPermission } = usePermissions()

  if (navLink.permission && !hasPermission(navLink.permission)) {
    return null
  }

  if (instanceOfDropdown(navLink)) {
    const hasNoChildPermissions = !navLink.dropdownItems.some(
      (item) => !item.permission || hasPermission(item.permission),
    )
    if (hasNoChildPermissions) {
      return null
    }

    return (
      <>
        <HeaderMenuItem
          data-cy={`${navLink.dataCy}-btn`}
          ref={triggerRef}
          isToggled={isPresent}
          onClick={toggle}
          showArrow
        >
          {navLink.text}
        </HeaderMenuItem>
        <Popover ref={popoverRef} isPresent={isPresent} triggerBoundingBox={triggerBoundingBox}>
          <Stack padding={1}>
            {navLink.dropdownItems
              .filter((item) => !item.permission || hasPermission(item.permission))
              .map((item) => (
                <MenuLinkItem
                  data-cy={`${item.dataCy}-btn`}
                  to={item.to}
                  key={item.text}
                  onClick={hide}
                >
                  {item.text}
                </MenuLinkItem>
              ))}
          </Stack>
        </Popover>
      </>
    )
  }

  return (
    <HeaderMenuLink data-cy={`${navLink.dataCy}-btn`} to={navLink.to} key={navLink.text}>
      {navLink.text}
    </HeaderMenuLink>
  )
}

export { DashboardNavLink }
