import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styled from '@emotion/styled'
import { useGetServicerQuery, useUpdateServicerMutation } from '@helloextend/extend-api-rtk-query'
import { COLOR, ToastColor, ToastDuration, useToaster, Checkbox } from '@extend/zen'
import { schema, formFields } from './schema'
import type { Values } from './schema'
import { PackingSlip } from './packing-slip'
import { LDFlag } from '../../../../../constants/ld-flags'

const ShippingLabelTab: FC = () => {
  const { [LDFlag.ServicerTwoDayShipping]: FF_TWO_DAY_SHIPPING } = useFlags()
  const { servicerId } = useParams<{ servicerId: string }>()
  const { data: servicer } = useGetServicerQuery(servicerId)
  const shipmentSettings = servicer
    ? servicer.settings?.shipments
    : {
        rmaNumberRequired: false,
      }
  const [updateServicer, { isLoading }] = useUpdateServicerMutation()
  const initialValues = {
    rmaNumberRequired: shipmentSettings?.rmaNumberRequired ?? false,
    hasCustomReturnLabel: shipmentSettings?.hasCustomReturnLabel ?? false,
    hasCustomDefectiveLabel: shipmentSettings?.hasCustomDefectiveLabel ?? false,
    isSignatureTwoDayReturnShippingEnabled:
      shipmentSettings?.isSignatureTwoDayReturnShippingEnabled ?? false,
  }
  const { toast } = useToaster()
  const { values, setFieldValue, submitForm } = useFormik<Values>({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: (rawBody) => {
      updateServicer({
        servicerId,
        body: {
          settings: {
            shipments: { ...shipmentSettings, ...rawBody },
          },
        },
      })
      toast({
        message: 'Changes saved!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    },
  })

  const toggleField = (fieldName: keyof Values): void => {
    setFieldValue(fieldName, !values[fieldName])
    submitForm()
  }

  const conditionalFormFields = formFields.filter((el) => {
    if (!FF_TWO_DAY_SHIPPING && el.key === 'isSignatureTwoDayReturnShippingEnabled') return false
    return true
  })

  // the checkboxes for `hasCustomReturnLabel` and `hasCustomDefectiveLabel` display whether the label is autogenerated, so they're inverted
  const getValue = (key: keyof Values): boolean =>
    key === 'hasCustomDefectiveLabel' || key === 'hasCustomReturnLabel' ? !values[key] : values[key]

  return (
    <>
      <ContentWrapper>
        <Header>
          <Title data-cy="shipping-label">Shipping Label</Title>
        </Header>
        {conditionalFormFields.map((field) => (
          <FieldWrapper key={field.key}>
            <SectionHeader>{field.header}</SectionHeader>
            <CheckboxWrapper>
              <Checkbox
                data-cy={field.key}
                checked={getValue(field.key as keyof Values)}
                key={field.key}
                name={field.key}
                disabled={isLoading}
                label={field.label}
                onChange={() => toggleField(field.key as keyof Values)}
              />
            </CheckboxWrapper>
          </FieldWrapper>
        ))}
      </ContentWrapper>
      <PackingSlip servicerId={servicerId} />
    </>
  )
}

const FieldWrapper = styled.div()

const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  marginBottom: 32,
  padding: 40,
  borderRadius: 4,
})

const CheckboxWrapper = styled.div({
  display: 'flex',
  gap: 8,
  flexDirection: 'column',
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  margin: 0,
})

const SectionHeader = styled.p({
  margin: '24px 0px',
  fontSize: 16,
  lineHeight: '20px',
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

export { ShippingLabelTab }
