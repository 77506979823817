import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { AdvancedSelectChangeEvent, AdvancedSelectOption } from '@extend/zen'
import { AdvancedSelect } from '@extend/zen'
import type {
  DefaultPrompt,
  DefaultReply,
  NonNumericConditionWithComparand,
  NonNumericConditionWithScript,
  NumericConditionWithScript,
  RuleStatus,
} from '@helloextend/extend-api-rtk-query'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState } from '../../../../../../reducers'
import * as selectors from '../../../../../../reducers/selectors'
import { identifyCondition } from '../../../utils'
import { updateConditionValue } from '../../../../../../store/slices/amp-slice'
import { getCoverageTypeOptions } from '../../../utils-ui'

type ValueSelectorProps = {
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
  isDisabled?: boolean
  isLargeWidth?: boolean
}

const ValueSelector: FC<ValueSelectorProps> = ({
  rulesetType,
  ruleIndex,
  conditionIndex,
  isDisabled = false,
  isLargeWidth = false,
}) => {
  const dispatch = useDispatch()
  const condition = useSelector((state: RootState) =>
    selectors.getCondition({ state, rulesetType, ruleIndex, conditionIndex }),
  )
  const selectedThread = useSelector((state: RootState) => selectors.getSelectedThread(state))
  const conditionType = condition ? identifyCondition(condition) : null

  let options: AdvancedSelectOption[] = []
  let selectorValue: string | string[] = ''
  if (conditionType === 'nonNumericScript') {
    // get the scriptItem
    const scriptItem =
      selectedThread?.script[(condition as NumericConditionWithScript).script as number]
    // generate options list from scriptItem
    options = scriptItem
      ? (((scriptItem?.reply as DefaultReply)?.prompt as DefaultPrompt)?.options?.map((option) => {
          return {
            display: option.title,
            value: option.value,
          }
        }) as AdvancedSelectOption[])
      : []

    selectorValue =
      conditionType === 'nonNumericScript' ? (condition as NonNumericConditionWithScript).value : ''
  } else if (
    conditionType === 'nonNumericComparand' &&
    condition?.comparand === 'planHasCoverageType'
  ) {
    options = getCoverageTypeOptions()
    selectorValue = (condition as NonNumericConditionWithComparand).value as string[]
  }

  const handleChange = (e: AdvancedSelectChangeEvent<string[] | string>): void => {
    const value = e.target.value as unknown as string[]
    dispatch(updateConditionValue({ rulesetType, ruleIndex, conditionIndex, value }))
  }

  return (
    <Wrapper isLargeWidth={isLargeWidth}>
      <AdvancedSelect
        id="claim-validation-value-selector"
        onChange={handleChange}
        options={options}
        value={selectorValue}
        multiple
        data-cy="claim-validation-value-selector"
        isDisabled={isDisabled}
        isNotClearable
        willTruncateChips
      />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isLargeWidth: boolean }>(({ isLargeWidth }) => ({
  flexBasis: isLargeWidth ? 240 : 234.5,
  flexGrow: 0,
  flexShrink: 0,
  maxWidth: isLargeWidth ? 240 : 234.5,
}))

export { ValueSelector }
