import type { FC } from 'react'
import React, { useState, useMemo, useEffect } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { conversationsApi, usePatchThreadMutation } from '@helloextend/extend-api-rtk-query'
import type { ThreadResponse } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { KaleyConditionTypes, MessageBlockTypes, DynamicPickerTypes } from '../../utils'
import {
  BlockTypes,
  getBlockTypeFromScriptItem,
  getKaleyConditionFromScriptItem,
  getMessageBlockTypeFromScriptItem,
  getDynamicPickerTypeFromScriptItem,
} from '../../utils'
import type { SelectedMessageBlock } from '../../../../../store/slices/amp-slice'
import {
  removeMessageBlockFromThread,
  resetThread,
  setIsEditorPanelVisible,
  setSelectedMessageBlock,
} from '../../../../../store/slices/amp-slice'
import { ConfirmationModal } from '../../../../../components/confirmation-modal'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'
import { BlockTypeDropdown } from '../block-type-dropdown/block-type-dropdown'
import { MessageBlockEditor } from '../message-block-editor'
import { KaleyConditionEditor } from '../kaley-condition-editor/kaley-condition-editor'
import { DynamicPickerEditor } from '../dynamic-picker-editor/dynamic-picker-editor'

type BlockEditorProps = {
  thread: ThreadResponse
  block: SelectedMessageBlock
  isThreadStructureLocked: boolean
  isThreadEditor?: boolean
  isThreadDirty?: boolean
}

const BlockEditor: FC<BlockEditorProps> = ({
  thread,
  block,
  isThreadStructureLocked,
  isThreadEditor = true,
  isThreadDirty = false,
}) => {
  const dispatch = useDispatch()

  const { toastError } = useStandardToast()

  const [isConfirmDeleteBlockModalVisible, setIsConfirmDeleteBlockModalVisible] = useState(false)
  const [patchThread] = usePatchThreadMutation()
  const [blockValue, setBlockValue] = useState<
    MessageBlockTypes | KaleyConditionTypes | DynamicPickerTypes | string | null
  >(null)
  const selectedBlockIndex = useSelector((state: RootState) =>
    selectors.getSelectedMessageBlockIndex(state),
  )

  const uiIndex = useMemo(() => {
    return String(block.index + 1)
  }, [block])

  const blockType = useMemo(() => getBlockTypeFromScriptItem(block.script), [block.script])
  const isMessageBlock = blockType === BlockTypes.messageBlock
  const isKaleyCondition = blockType === BlockTypes.kaleyCondition
  const isDynamicPicker = blockType === BlockTypes.dynamicPicker

  useEffect(() => {
    if (isKaleyCondition) {
      const kaleyConditionType = getKaleyConditionFromScriptItem(block.script)
      setBlockValue(String(kaleyConditionType))
    } else if (isDynamicPicker) {
      const dynamicPickerType = getDynamicPickerTypeFromScriptItem(block.script)
      setBlockValue(String(dynamicPickerType))
    } else if (isMessageBlock) {
      const messageBlockType = getMessageBlockTypeFromScriptItem(block.script)
      setBlockValue(messageBlockType)
    }
  }, [isMessageBlock, isKaleyCondition, isDynamicPicker, block.script])

  const toggleConfirmDeleteMessageBlockModal = (): void => {
    setIsConfirmDeleteBlockModalVisible(!isConfirmDeleteBlockModalVisible)
  }

  const hideDeleteMessageBlockModal = (): void => {
    if (isConfirmDeleteBlockModalVisible) {
      toggleConfirmDeleteMessageBlockModal()
    }
  }

  const handleConfirmDeleteMessageBlock = async (): Promise<void> => {
    try {
      if (selectedBlockIndex === null)
        throw new Error('handleConfirmDeleteMessageBlock: selectedMessageBlockIndex is null')
      try {
        if (thread.status === 'override' || thread.status === 'archived') {
          toastError('Invalid thread status for deletion')
          hideDeleteMessageBlockModal()
          return
        }
        if (thread.status === 'pending_changes' || thread.status === 'published') {
          await patchThread({
            threadId: thread.id,
            patches: [{ op: 'remove', path: `/script/${selectedBlockIndex}` }],
          }).unwrap()
          batch(() => {
            dispatch(setIsEditorPanelVisible(false))
            dispatch(setSelectedMessageBlock(null))
            // Only if this is the last message block, call resetThread action
            if (thread.type === 'single_use' && selectedBlockIndex === 0) {
              dispatch(resetThread())
            }
            dispatch(conversationsApi.util.invalidateTags(['ThreadRuleset']))
            dispatch(conversationsApi.util.invalidateTags([{ type: 'Thread', id: thread.id }]))
          })
        } else {
          batch(() => {
            dispatch(removeMessageBlockFromThread(selectedBlockIndex))
            dispatch(setIsEditorPanelVisible(false))
            dispatch(setSelectedMessageBlock(null))
            // Only if this is the last message block, call resetThread action
            if (thread.type === 'single_use' && selectedBlockIndex === 0) {
              dispatch(resetThread())
            }
          })
        }
      } catch (e) {
        if (e instanceof Error) {
          toastError(e.message)
        }
      }
      hideDeleteMessageBlockModal()
    } catch (error: unknown) {
      toastError('Something went wrong. Please try again.')
      hideDeleteMessageBlockModal()
    }
  }

  return (
    <>
      <ConfirmationModal
        data-cy="delete-block-confirmation-modal"
        isVisible={isConfirmDeleteBlockModalVisible}
        onCancel={toggleConfirmDeleteMessageBlockModal}
        onConfirm={handleConfirmDeleteMessageBlock}
        isProcessing={false}
        confirmButtonColor="red"
        confirmButtonText="Delete"
        heading="Delete message block"
        description="This action cannot be undone. Are you sure you want to continue?"
        size="sm"
      />
      <EditorContainer data-cy="block-editor">
        <BlockTypeDropdown
          index={uiIndex}
          value={String(blockValue)}
          isDisabled={isThreadStructureLocked}
          handleDeleteButtonClick={toggleConfirmDeleteMessageBlockModal}
          isThreadDirty={isThreadDirty}
        />
        {isMessageBlock && (
          <div data-cy="message-block-editor">
            <MessageBlockEditor
              thread={thread}
              messageBlock={block}
              isThreadStructureLocked={isThreadStructureLocked}
              isThreadEditor={isThreadEditor}
            />
          </div>
        )}
        {isKaleyCondition && (
          <div data-cy="kaley-condition-editor">
            <KaleyConditionEditor
              thread={thread}
              conditionBlock={block}
              isThreadStructureLocked={isThreadStructureLocked}
            />
          </div>
        )}
        {isDynamicPicker && (
          <div data-cy="dynamic-picker-editor">
            <DynamicPickerEditor
              thread={thread}
              dynamicPickerBlock={block}
              isThreadStructureLocked={isThreadStructureLocked}
            />
          </div>
        )}
      </EditorContainer>
    </>
  )
}

const EditorContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  padding: '0px 32px',
})

export type { BlockEditorProps }
export { BlockEditor }
