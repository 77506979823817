import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import type { PromptType, ThreadResponse, DefaultPrompt } from '@helloextend/extend-api-rtk-query'
import { ResponseBlock } from './response-block'
import {
  getIsReplacementReply,
  getCustomerResponseBlockTitle,
  getJumpToValue,
  BlockTypes,
  getBlockTypeFromScriptItem,
  getKaleyConditionFromScriptItem,
  getDynamicPickerTypeFromScriptItem,
  DynamicPickerTypes,
} from '../../utils'
import { useGetUniqueKeys } from '../../../../../hooks/use-get-unique-keys'
import type { ResponseBlockItem } from './types'
import type { SelectedMessageBlock } from '../../../../../store/slices/amp-slice'
import { generateJumpToOptions } from '../../utils-ui'
import { getPatternValue } from '../kaley-condition/kaley-condition-utils'

type CustomerResponseProps = {
  thread: ThreadResponse
  messageBlock: SelectedMessageBlock
  messageBlockIndex?: number | null
  isThreadStructureLocked: boolean
}

type CustomerResponseOption = {
  choices: ResponseBlockItem[]
  responseType: PromptType | BlockTypes.kaleyCondition | BlockTypes.dynamicPicker | null
}

const CustomerResponse: FC<CustomerResponseProps> = ({
  thread,
  messageBlock,
  messageBlockIndex,
  isThreadStructureLocked,
}) => {
  const { reply, collect } = messageBlock.script

  const { keys, generateInitialKeys, addKey, removeKey } = useGetUniqueKeys()

  const [currBlockIndex, setCurrBlockIndex] = useState<number>(messageBlock.index)

  const scriptLinkOptions = useMemo(() => {
    return generateJumpToOptions(thread, messageBlock.script, messageBlockIndex)
  }, [thread, messageBlockIndex, messageBlock.script])

  const blockType = getBlockTypeFromScriptItem(messageBlock.script)
  const isKaleyCondition = blockType === BlockTypes.kaleyCondition
  const isDynamicPicker = blockType === BlockTypes.dynamicPicker

  const { choices, responseType } = useMemo<CustomerResponseOption>(() => {
    if (isKaleyCondition) {
      const { options } = collect
      const conditionType = getKaleyConditionFromScriptItem(messageBlock.script)

      const responseChoices: ResponseBlockItem[] = options.map((option, index) => {
        const patternValue = option.patterns ? getPatternValue(option.patterns) : undefined
        const [displayPattern] = option.patterns
          ? getPatternValue(option.patterns, conditionType)
          : ['none_of_the_above']
        const jumpToValue = getJumpToValue(messageBlock.script, index, patternValue)

        return {
          title: `CONDITION ${index + 1}`,
          selectorValue: jumpToValue,
          choiceText: displayPattern,
          scriptLinkOptions,
        }
      })

      return { choices: responseChoices, responseType: BlockTypes.kaleyCondition }
    }

    if (isDynamicPicker) {
      const pickerType = getDynamicPickerTypeFromScriptItem(messageBlock.script)
      const jumpToValue = getJumpToValue(messageBlock.script, 0)
      const responseChoices: ResponseBlockItem[] = [
        {
          title: `SELECT ${pickerType === DynamicPickerTypes.shipment ? 'A SHIPMENT' : 'ITEM(S)'}`,
          selectorValue: jumpToValue,
          choiceText: jumpToValue,
          scriptLinkOptions,
        },
      ]

      return { choices: responseChoices, responseType: BlockTypes.dynamicPicker }
    }

    const prompt = !getIsReplacementReply(reply) ? (reply.prompt as Required<DefaultPrompt>) : null
    if (!prompt) return { choices: [], responseType: null }
    const { options: responseOptions, type } = prompt
    if (!responseOptions || responseOptions.length === 0) {
      const jumpToValue = getJumpToValue(messageBlock.script, 0)
      const responseChoices: ResponseBlockItem[] = [
        // Not multi-choice response
        {
          title: getCustomerResponseBlockTitle(type).toUpperCase(),
          selectorValue: jumpToValue,
          choiceText: jumpToValue,
          scriptLinkOptions,
        },
      ]
      return { choices: responseChoices, responseType: type }
    }

    const responseChoices: ResponseBlockItem[] = responseOptions.map((option, index) => {
      const jumpToValue = !option.redirectUrl
        ? getJumpToValue(messageBlock.script, index)
        : 'Support Form'

      return {
        title: `CHOICE ${index + 1}`,
        selectorValue: jumpToValue,
        choiceText: option.title,
        scriptLinkOptions,
      }
    })

    return { choices: responseChoices, responseType: type }
  }, [reply, collect, scriptLinkOptions, messageBlock.script, isKaleyCondition, isDynamicPicker])

  if (!responseType) return null

  if (keys.length === 0 || currBlockIndex !== messageBlock.index) {
    generateInitialKeys(choices.length)
    setCurrBlockIndex(messageBlock.index)
    return null
  }

  const titleText = isKaleyCondition ? 'Conditions' : 'Customer Response'
  return (
    <TemplateWrapper>
      <HeadingRow>
        <Title data-cy="customer-response-title">{titleText}</Title>
      </HeadingRow>
      <CustomerResponseBlocks data-cy="customer-response-blocks">
        {choices.map((choice, index) => {
          const { title, choiceText, selectorValue, scriptLinkOptions: linkOptions } = choice
          return (
            <ResponseBlock
              type={responseType}
              key={keys[index]}
              addKey={addKey}
              removeKey={removeKey}
              title={title}
              choiceText={choiceText}
              selectorValue={selectorValue}
              selectorOptions={linkOptions}
              scriptItem={messageBlock.script}
              index={index}
              isRemoveDisabled={choices.length === 1}
              isThreadStructureLocked={isThreadStructureLocked}
            />
          )
        })}
      </CustomerResponseBlocks>
    </TemplateWrapper>
  )
}

const TemplateWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
})

const HeadingRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const Title = styled.div({
  width: 432,
  height: 28,
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '28px',
  color: COLOR.NEUTRAL[1000],
  flex: 'none',
  order: 0,
  flexGrow: 0,
})

const CustomerResponseBlocks = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})

export { CustomerResponse }
