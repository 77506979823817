import {
  AccordionSection,
  Box,
  Button,
  COLOR,
  DataProperty,
  Modal,
  ModalController,
  Spinner,
  TabSection,
  TabSections,
} from '@extend/zen'
import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useGetRolesQuery } from '@helloextend/extend-api-rtk-query'
import { enterpriseMerchantRoles, legacyMerchantRoles } from '../../utils/user-role-mapper'

export const ROLES_FETCH_ERROR_MESSAGE =
  'There was an error fetching Roles. Please try again later.'
export const Roles: FC = () => {
  const {
    data: internalRolesData,
    isLoading: isLoadingInternalRoles,
    isError: isErrorInternalRoles,
  } = useGetRolesQuery({ actorType: 'internal' })
  const {
    data: merchantRolesData,
    isLoading: isLoadingMerchantRoles,
    isError: isErrorMerchantRoles,
  } = useGetRolesQuery({ actorType: 'merchant' })
  const [isExpanded, setIsExpanded] = useState(false)
  const [roleModalDetails, setRoleModalDetails] = useState({
    isOpen: false,
    content: [] as JSX.Element[],
    description: '',
    heading: '',
  })

  const enterpriseMerchantRolesValues = Object.keys(enterpriseMerchantRoles)
  const merchantPortalRoles = [...enterpriseMerchantRolesValues, legacyMerchantRoles.user.value]

  const filteredMerchantRoleData =
    merchantRolesData &&
    merchantRolesData.roles.filter((role: { name: string }) =>
      merchantPortalRoles.includes(role.name),
    )

  return (
    <Box>
      <AdditionalPadding>
        <AccordionSection
          heading="Role Details by Actor Type"
          isExpanded={isExpanded || isErrorInternalRoles || isErrorMerchantRoles}
          onToggleRequest={() => setIsExpanded(!isExpanded)}
          toolbar={(isLoadingInternalRoles || isLoadingMerchantRoles) && <Spinner />}
          isToggleDisabled={isErrorInternalRoles || isErrorMerchantRoles}
        >
          {isErrorInternalRoles || isErrorMerchantRoles ? (
            <p>{ROLES_FETCH_ERROR_MESSAGE}</p>
          ) : (
            <Items>
              <TabSections id="main" isQSControlled>
                <TabSection tabLabel="Internal">
                  {internalRolesData?.roles.map((role) => {
                    return (
                      <Item key={role.name}>
                        <h4>{role.name}</h4>
                        <Button
                          emphasis="medium"
                          size="small"
                          text="View Scopes / Permissions / Role Collections"
                          onClick={() =>
                            setRoleModalDetails({
                              isOpen: true,
                              content: role.scopes.map((scope) => <li key={scope}>{scope}</li>),
                              description: role.shortDescription,
                              heading: role.name,
                            })
                          }
                        />
                      </Item>
                    )
                  })}
                </TabSection>
                <TabSection tabLabel="Merchant">
                  {filteredMerchantRoleData?.map((role) => {
                    return (
                      <Item key={role.name}>
                        <h4>{role.name}</h4>
                        <Button
                          emphasis="medium"
                          size="small"
                          text="View Scopes / Permissions / Role Collections"
                          onClick={() =>
                            setRoleModalDetails({
                              isOpen: true,
                              content: role.scopes.map((scope) => <li key={scope}>{scope}</li>),
                              description: role.shortDescription,
                              heading: role.name,
                            })
                          }
                        />
                      </Item>
                    )
                  })}
                </TabSection>
              </TabSections>
              <ModalController isOpen={roleModalDetails.isOpen}>
                <Modal
                  onDismissRequest={() =>
                    setRoleModalDetails({
                      isOpen: false,
                      description: '',
                      content: [],
                      heading: '',
                    })
                  }
                  heading={roleModalDetails.heading}
                >
                  {roleModalDetails.description && (
                    <DataProperty label="Role Description" value={roleModalDetails.description} />
                  )}
                  <ul>{roleModalDetails.content}</ul>
                </Modal>
              </ModalController>
            </Items>
          )}
        </AccordionSection>
      </AdditionalPadding>
    </Box>
  )
}

const Item = styled.div({
  display: 'grid',
  gridTemplateColumns: '33% 67%',
  borderBottom: `1px solid ${COLOR.NEUTRAL['300']}`,
  alignItems: 'center',
  button: {
    width: 'fit-content',
    alignSelf: 'unset',
  },
  '&:last-of-type': {
    borderBottom: 'none',
  },
})

const Items = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const AdditionalPadding = styled.div({
  padding: '20px 0',
})
