import * as Yup from 'yup'

const wholesalePriceRowSchema = Yup.object()
  .shape(
    {
      wholesalePrice: Yup.number().test({
        name: 'required',
        message: 'Wholesale Price is required',
        test(value) {
          const isDuplicateSkuId = this.parent.isWholesaleFieldDisabled
          return value !== undefined || isDuplicateSkuId
        },
      }),
      skuId: Yup.string().required('SKU ID is required'),
    },
    // to avoid cyclic dependency error
    [['wholesalePrice', 'wholesalePrice']],
  )
  .defined()

const schema = Yup.object()
  .shape({
    wholesalePrices: Yup.array().of(wholesalePriceRowSchema),
  })
  .defined()
type Values = Yup.InferType<typeof schema>

export type { Values }
export { schema }
