import * as Yup from 'yup'
import { validate } from '@extend/client-helpers'
import type { CurrencyCode } from '@extend/zen'
import type { Order } from '@helloextend/extend-api-client'

const PHONE_NUMBER_MIN_LENGTH_USA = 10
const phoneNumberValidation = Yup.string().test(
  'phone-number-validation',
  'Please enter a valid phone number',
  (value): boolean => {
    return !value || validate.validateIsNumberEntered(value, PHONE_NUMBER_MIN_LENGTH_USA)
  },
)

const billingAndShippingInformationSchema = Yup.object()
  .shape({
    address1: Yup.string().required('Address is required'),
    address2: Yup.string().notRequired(),
    city: Yup.string().required('City is required'),
    countryCode: Yup.string().required('Country Code is required'),
    postalCode: Yup.string().required('Postal code is required'),
    province: Yup.string().notRequired(),
  })
  .defined()

const customerInformationSchema = Yup.object()
  .shape({
    fullName: Yup.string().required('Full name is required'),
    phoneNumber: phoneNumberValidation,
    email: Yup.string()
      .required('Email is required')
      .max(100, 'Please enter a valid email address')
      .email('Please enter a valid email address'),
    merchantCustomerId: Yup.string().notRequired(),
  })
  .defined()

const transactionInformationSchema = Yup.object()
  .shape({
    transactionId: Yup.string().required('Transaction ID is required'),
    transactionDate: Yup.string().required('Transaction date is required'),
    currencyCode: Yup.string<CurrencyCode>().required('Currency code is required'),
    poNumber: Yup.string().notRequired(),
    merchantCustomerId: Yup.string().notRequired(),
    isTest: Yup.boolean().notRequired(),
    cartId: Yup.string().notRequired(),
  })
  .defined()

const orderTotalsSchema = Yup.object()
  .shape({
    shippingCostTotal: Yup.number().notRequired(),
    taxCostTotal: Yup.number().notRequired(),
    productCostTotal: Yup.number().notRequired(),
    orderTotal: Yup.number().notRequired(),
  })
  .defined()

const storeInformationSchema = Yup.object()
  .shape({
    storeName: Yup.string().notRequired(),
    storeId: Yup.string().required('Store ID is required'),
  })
  .defined()

type CustomerInformation = Yup.InferType<typeof customerInformationSchema>
type BillingInformation = Yup.InferType<typeof billingAndShippingInformationSchema>
type ShippingInformation = Yup.InferType<typeof billingAndShippingInformationSchema>
type TransactionInformation = Yup.InferType<typeof transactionInformationSchema>
type OrderTotals = Yup.InferType<typeof orderTotalsSchema>
type StoreInformation = Yup.InferType<typeof storeInformationSchema>

interface OrderDetailsFormSchemaInterface {
  customerInformation: CustomerInformation
  billingInformation: BillingInformation
  shippingInformation: ShippingInformation
  transactionInformation: TransactionInformation
  orderTotals: OrderTotals
  storeInformation: StoreInformation
}

const OderDetailsSchema = new Map()
OderDetailsSchema.set('customerInformation', customerInformationSchema)
OderDetailsSchema.set('billingInformation', billingAndShippingInformationSchema)
OderDetailsSchema.set('shippingInformation', customerInformationSchema)
OderDetailsSchema.set('transactionInformation', transactionInformationSchema)
OderDetailsSchema.set('orderTotals', orderTotalsSchema)
OderDetailsSchema.set('storeInformation', storeInformationSchema)

export type {
  OrderDetailsFormSchemaInterface,
  CustomerInformation,
  BillingInformation,
  ShippingInformation,
  TransactionInformation,
  OrderTotals,
  StoreInformation,
}
export { OderDetailsSchema }

export function mapOrderToOrderDetailsSchema(order: Order): OrderDetailsFormSchemaInterface {
  const { customer } = order
  const billingAddress = customer.billingAddress || {
    address1: '',
    city: '',
    country: '',
    countryCode: '',
    postalCode: '',
  }
  const shippingAddress = customer.shippingAddress || billingAddress

  return {
    customerInformation: {
      fullName: customer.name,
      phoneNumber: customer.phone,
      email: customer.email,
      merchantCustomerId: order?.merchantCustomerId,
    },
    billingInformation: {
      address1: billingAddress.address1,
      address2: billingAddress.address2,
      city: billingAddress.city,
      province: billingAddress.province,
      postalCode: billingAddress.postalCode,
      countryCode: billingAddress.countryCode,
    },
    shippingInformation: {
      address1: shippingAddress.address1,
      address2: shippingAddress.address2,
      city: shippingAddress.city,
      province: shippingAddress.province,
      postalCode: shippingAddress.postalCode,
      countryCode: shippingAddress.countryCode,
    },
    transactionInformation: {
      transactionId: order.transactionId,
      transactionDate: new Date(order.createdAt).toLocaleDateString(),
      currencyCode: order.currency,
      poNumber: order.poNumber,
      merchantCustomerId: order.merchantCustomerId,
      isTest: order.isTest || false,
      cartId: order.id || '',
    },
    orderTotals: {
      shippingCostTotal: order.shippingCostTotal,
      taxCostTotal: order.taxCostTotal,
      productCostTotal: order.productTotalCost,
      orderTotal: order.total,
    },
    storeInformation: {
      storeName: order.storeName,
      storeId: order.storeId,
    },
  }
}
