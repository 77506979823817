import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { Order, AuditHistoryResponse } from '@helloextend/extend-api-client'
import { useGetAuditLogQuery } from '@helloextend/extend-api-rtk-query'

import { AuditLogTable } from '../../../../../components/audit-log-table'
import { EmptyMessage } from '../../../../../components/data-table/empty-message'

const AuditLog: FC<{ order: Order }> = ({ order }) => {
  const entityType = 'ORDER'
  const { data, isLoading, isError, isSuccess } = useGetAuditLogQuery({
    entityType,
    entityId: order.id,
  })

  const auditLog: AuditHistoryResponse<Order> = data || {}

  if (isError) {
    return (
      <EmptyMessage
        header="Failed to load audit log"
        message="Please verify the orderId used is correct and try again"
      />
    )
  }

  return (
    <Container data-cy="audit-log-section">
      {isSuccess && (
        <AuditLogTable auditLog={auditLog} resourceType={entityType} isLoading={isLoading} />
      )}

      {!isLoading && auditLog.items?.length === 0 && (
        <EmptyMessage
          header="No changes have been recorded for this order"
          message="When this order is edited by a user or an automated process, changes will appear here"
        />
      )}
    </Container>
  )
}

const Container = styled.div({
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
})

export { AuditLog }
