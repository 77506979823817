import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@extend/zen'
import { AlertText, AlertContainer } from './styles'

interface SuccessMessageProps {
  message: string
  // will need when icons are established
  hasIcon?: boolean
}

const SuccessMessage: FC<SuccessMessageProps> = ({ message }) => (
  <AlertContainer backgroundColor={COLOR.GREEN[100]}>
    <AlertText title={message} color={COLOR.GREEN[800]}>
      {message}
    </AlertText>
  </AlertContainer>
)

export { SuccessMessage }
