import type { FC } from 'react'
import React, { useEffect, useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import { COLOR, Stack, Icon, ExpandMore } from '@extend/zen'
import { useGetThreadQuery } from '@helloextend/extend-api-rtk-query'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import type { RootState } from '../../../../../reducers'
import { DashboardSpinner } from '../../../../../components/dashboard-spinner'
import { AdjudicationMessageBlock } from '../message-block/adjudication-message-block'
import { ConversationAppendButtons } from '../conversation-append-buttons'
import {
  setSelectedMessageBlock,
  setIsEditorPanelVisible,
  setThread,
  updateSingleUseThreads,
  updateConversationReusableThreads,
} from '../../../../../store/slices/amp-slice'
import {
  getSelectedThreadMessageBlock,
  getSingleUseThreadsMap,
  getSelectedMessageBlockIndex,
  getSelectedThread,
  getReusableThreadPickerVisibility,
  getSelectedThreadIdToReplace,
  getCurrentPlaceholderMessageBlockIndex,
} from '../../../../../reducers/selectors'
import {
  getFirstSelectableMessageBlockIndex,
  isMessageBlockCustomerFacing,
  isMessageBlockTerminating,
} from '../../utils'
import { ThreadPlaceholder } from '../thread-placeholder/thread-placeholder'

type CollapsibleBlockProps = {
  'data-cy'?: string
  threadId: string
  isCollapsible?: boolean
  isDefaultCollapsed?: boolean
  isActive?: boolean
  firstThreadId?: string
  setFirstMessageBlockRef: (threadId: string, element: HTMLElement) => void
  onBadgeClick: (
    refs: React.MutableRefObject<{
      [key: number]: HTMLElement
    }>,
    value: number | string,
  ) => void
  isCollapsedMap: {
    [key: string]: boolean
  }
  setIsCollapsedMap: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean
    }>
  >
  onClick?: () => void
}

const CollapsibleBlock: FC<CollapsibleBlockProps> = ({
  threadId,
  isDefaultCollapsed = true,
  'data-cy': dataCy,
  isActive,
  setFirstMessageBlockRef,
  onBadgeClick: handleBadgeClick,
  isCollapsedMap,
  setIsCollapsedMap,
  onClick,
  firstThreadId,
}) => {
  const { data: thread, isFetching, isLoading } = useGetThreadQuery(threadId, { skip: !threadId })
  const dispatch = useDispatch()
  const locationObj = useLocation()
  const isPreview = locationObj.pathname.includes('/preview')

  const selectedMessageBlock = useSelector((state: RootState) =>
    getSelectedThreadMessageBlock(state),
  )
  const selectedThread = useSelector((state: RootState) => getSelectedThread(state))
  const messageBlockIndex = useSelector((state: RootState) => getSelectedMessageBlockIndex(state))
  const placeholderMessageBlockIndex = useSelector((state: RootState) =>
    getCurrentPlaceholderMessageBlockIndex(state),
  )
  const singleUseThreads = useSelector((state: RootState) => getSingleUseThreadsMap(state))

  const isReusableThreadPickerVisible = useSelector((state: RootState) =>
    getReusableThreadPickerVisibility(state),
  )

  const selectedThreadIdToReplace = useSelector((state: RootState) =>
    getSelectedThreadIdToReplace(state),
  )

  const handleCollapseToggle = (): void => {
    let isCollapsed = isCollapsedMap[threadId]
    if (isCollapsed === undefined) isCollapsed = isDefaultCollapsed
    return setIsCollapsedMap({ ...isCollapsedMap, [threadId]: !isCollapsed })
  }

  const refs = useRef<{ [key: number]: HTMLElement }>({})

  const onBadgeClick = (value: number | string): void => {
    handleBadgeClick(refs, value)
  }

  const handleSelectMessageBlock = async (index: number): Promise<void> => {
    batch(() => {
      dispatch(setSelectedMessageBlock(index))
      dispatch(setIsEditorPanelVisible(true))

      if (thread && (!selectedThread || selectedThread.id !== thread.id)) {
        dispatch(
          setThread(
            singleUseThreads && singleUseThreads[thread.id] ? singleUseThreads[thread.id] : thread,
          ),
        )
      }
    })
  }

  // This effect will:
  // a) auto-select the first thread
  // b) should only run the logic inside for loading existing single-use threads. It's gated specifically not to run
  //     in the case of creating a single-use thread since the custom hook already takes care of all the Redux actions needed.
  useEffect(() => {
    if (
      threadId &&
      thread &&
      threadId === firstThreadId &&
      thread.script.length > 0 &&
      !isFetching
    ) {
      batch(() => {
        dispatch(setThread(thread))
        dispatch(setIsEditorPanelVisible(true))

        if (thread.type === 'single_use') {
          if (isPreview) {
            // if this action is dispatched in the editor it erases the single_use thread from redux
            // this should only happen in the preview, where a single_use map has not been set yet
            dispatch(updateSingleUseThreads(thread))
          }
          // we only want to attempt to auto-select the first selectable message block if there are entries in the script array
          dispatch(setSelectedMessageBlock(getFirstSelectableMessageBlockIndex(thread)))
        }
      })
    }
    if (threadId && thread && thread.type === 'single_use' && !isFetching && isPreview) {
      dispatch(updateSingleUseThreads(thread))
    }
  }, [thread, threadId, firstThreadId, dispatch, isFetching, isPreview])

  useEffect(() => {
    if (
      thread &&
      selectedThread &&
      thread.type !== 'single_use' &&
      thread.id !== selectedThread.id
    ) {
      dispatch(updateConversationReusableThreads(thread))
    }
  }, [thread, selectedThread, dispatch])

  const scripts = useMemo(() => {
    if (thread?.type === 'single_use') {
      return singleUseThreads[threadId]?.script ?? []
    }

    return thread ? thread.script : []
  }, [singleUseThreads, thread, threadId])

  const isAddPlaceholderVisible = useMemo(() => {
    return Boolean(isReusableThreadPickerVisible && !selectedThreadIdToReplace)
  }, [isReusableThreadPickerVisible, selectedThreadIdToReplace])

  return (
    <>
      {thread?.type === 'single_use' ? (
        <>
          {scripts.map((scriptItem, index) => {
            const isAtThreadTermination =
              isMessageBlockTerminating(scriptItem) && scripts.length - 1 === index
            const uiIndex = String(index + 1)
            return (
              <>
                <AdjudicationMessageBlock
                  index={String(index + 1)}
                  scriptItem={scriptItem}
                  threadId={threadId}
                  onBadgeClick={onBadgeClick}
                  ref={(element: HTMLDivElement) => {
                    refs.current[index + 1] = element
                    if (index === 0) setFirstMessageBlockRef(threadId, element)
                  }}
                  onClick={
                    isMessageBlockCustomerFacing(scriptItem)
                      ? () => handleSelectMessageBlock(index)
                      : undefined
                  }
                  isActive={isActive && index === selectedMessageBlock?.index}
                />
                {placeholderMessageBlockIndex === index && isAddPlaceholderVisible ? (
                  <>
                    <CTAContainerStyled index={uiIndex}>
                      <ThreadPlaceholder description="add-collapsible-block" />
                    </CTAContainerStyled>
                  </>
                ) : (
                  <>
                    {messageBlockIndex === index && isActive && !isPreview && (
                      <CTAContainerStyled index={uiIndex}>
                        <ConversationAppendButtons
                          description="single-use-thread"
                          isAtThreadTermination={isAtThreadTermination}
                        />
                      </CTAContainerStyled>
                    )}
                  </>
                )}
              </>
            )
          })}
        </>
      ) : (
        <Container data-cy={dataCy ?? 'collapsible-block'} onClick={onClick} isActive={isActive}>
          {isFetching || isLoading ? (
            <DashboardSpinner data-cy="dashboard-spinner" />
          ) : (
            <>
              <HeaderStack isRow align="center">
                <Header>
                  <Stack isRow doesWrap>
                    <Title data-cy={thread?.title}>Thread - {thread?.title}</Title>
                  </Stack>
                  <Stack isRow align="center" spacing={1}>
                    <CaretWrapper
                      data-cy="collapse-caret"
                      isCollapsed={
                        isCollapsedMap[threadId] === undefined
                          ? isDefaultCollapsed
                          : isCollapsedMap[threadId]
                      }
                      onClick={handleCollapseToggle}
                    >
                      <Icon icon={ExpandMore} />
                    </CaretWrapper>
                  </Stack>
                </Header>
              </HeaderStack>
              <ContentWrapper
                data-cy="amp-collapsible-block-content-wrapper"
                isCollapsed={
                  isCollapsedMap[threadId] === undefined
                    ? isDefaultCollapsed
                    : isCollapsedMap[threadId]
                }
              >
                {scripts.map((scriptItem, index) => {
                  return (
                    <AdjudicationMessageBlock
                      key={JSON.stringify(scriptItem)}
                      index={String(index + 1)}
                      scriptItem={scriptItem}
                      threadId={threadId}
                      onBadgeClick={onBadgeClick}
                      ref={(element: HTMLDivElement) => {
                        refs.current[index + 1] = element
                        if (index === 0) setFirstMessageBlockRef(threadId, element)
                      }}
                    />
                  )
                })}
              </ContentWrapper>
            </>
          )}
        </Container>
      )}
    </>
  )
}

const HeaderStack = styled(Stack)()

const Header = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
})

const ContentWrapper = styled.div<{ isCollapsed?: boolean }>(({ isCollapsed }) => ({
  width: '100%',
  ...{
    overflow: isCollapsed ? 'hidden' : undefined,
    maxHeight: isCollapsed ? 0 : 'auto',
    marginTop: isCollapsed ? 0 : 6,
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
}))

const CaretWrapper = styled.div<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
  '& > svg': {
    transform: isCollapsed ? '' : 'rotate(-180deg)',
    transition: '200ms transform ease-in-out',
  },
  cursor: 'pointer',
  height: 32,
  width: 32,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  '&:hover': {
    background: COLOR.NEUTRAL.OPACITY[12],
  },
}))

const Container = styled.div<{
  isActive?: boolean
}>(({ isActive }) => ({
  display: 'flex',
  width: 446,
  padding: 16,
  marginLeft: 44,
  marginTop: 16,
  flexDirection: 'column',
  border: `1px solid ${isActive ? COLOR.BLUE[700] : COLOR.NEUTRAL[300]}`,
  borderRadius: 8,
  background: COLOR.NEUTRAL[100],
  '&:hover': {
    boxShadow: `0px 0px 8px ${COLOR.NEUTRAL[300]}`,
  },
}))

const Title = styled.h2({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  color: COLOR.NEUTRAL[800],
  margin: 0,
  marginRight: 12,
  alignItems: 'center',
})

const CTAContainerStyled = styled.div<{ index: string }>(({ index }) => {
  let indent = 0
  if (index.length === 2) {
    indent = 8
  }
  if (index.length === 3) {
    indent = 18
  }
  return {
    paddingLeft: indent,
  }
})

export type { CollapsibleBlockProps }
export { CollapsibleBlock }
