import type { DataTableAction, SortingState } from '@extend/zen'
import { DataTable, Visibility } from '@extend/zen'
import type { FC } from 'react'
import React, { useState } from 'react'
import type { PlanTermsVersion } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'
import { useParams } from 'react-router-dom'
import { useGetPlanTermsQuery } from '@helloextend/extend-api-rtk-query'
import { tableColumns } from './terms-versions-list-table-config'

const TermsDetailsVersions: FC = () => {
  const { termsId } = useParams<{ termsId: string }>()
  const [sorting, setSorting] = useState<SortingState>([{ id: 'version', desc: true }])

  const { data, isLoading, isError } = useGetPlanTermsQuery(termsId)

  const getRowActions = (row: PlanTermsVersion): DataTableAction[] => {
    const url = row.languages?.length ? row.languages[0].url : undefined

    if (!url) {
      return []
    }

    return [
      {
        icon: Visibility,
        onClick: () => window.open(url, '_blank'),
        emphasis: 'low',
        'data-cy': `view-terms-button-${row.version}`,
      },
    ]
  }

  return (
    <DataTable
      data-cy="terms-data-table"
      columns={tableColumns}
      data={data?.versions || []}
      sorting={sorting}
      onSortingChange={setSorting}
      hasManualSorting={false}
      getRowActions={getRowActions}
      isLoading={isLoading}
      isError={isError}
    />
  )
}

export { TermsDetailsVersions }
