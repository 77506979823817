import styled from '@emotion/styled'
import type {
  DateRangeValue} from '@extend/zen';
import {
  Button,
  ButtonGroup,
  Checkbox,
  COLOR,
  Input,
  Stack,
  Add,
  Box,
  Trash,
  Select,
  InputType,
  Date as DateIcon,
  Icon,
  usePopover,
  Popover,
  DateRangePicker,
  PopoverDirection,
  PopoverAlignment,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@extend/zen'
import type { FC } from 'react';
import React from 'react'
import type { CurrencyCode, Servicer } from '@helloextend/extend-api-client'
import { expenseTypes } from '../../../claim/claim-details/components/tab-section/tabs/expenses/mappings'
import { useFormik } from 'formik'
import type { RateValues, Values} from './schema';
import { rateSchema, schema } from './schema'
import { CurrencyInputGroup } from '../../../../../components/currency-input-group'
import { useCreateRateCardMutation } from '../../../../../../packages/extend-api-rtk-query'
import { hashCode } from '../../../../../utils/utils'
import { date } from '@extend/client-helpers'
import type { ServiceCardCreateRequest } from '@extend-services/service-orders/dist/src/client/api-rest/v2/models'

interface RateCardFormProps {
  servicer: Servicer
  index: number
  onCancelOrSave: () => void
}

export const RateCardForm: FC<RateCardFormProps> = ({ servicer, index, onCancelOrSave }) => {
  const [submitRateCard] = useCreateRateCardMutation()
  const { toast } = useToaster()
  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLInputElement>()

  const { values, errors, submitForm, setFieldValue, handleChange } = useFormik<Values>({
    validationSchema: schema,
    initialValues: {
      ...schema.getDefault(),
      // @ts-ignore
      rates: [rateSchema.getDefault()],
    },
    validate: (values) => {
      // If there are fewer than 2 rates, there can't be any duplicates
      if ((values.rates?.length ?? 0) < 2) return {}

      // Map hash of type + description combinations to indexes of rates that match the hash
      const typeDescriptionHashes = new Map()
      ;(values.rates ?? []).forEach((element: RateValues, i: number) => {
        const hash = hashCode({ type: element.type, description: element.description })
        if (typeDescriptionHashes.has(hash)) {
          typeDescriptionHashes.set(hash, [...typeDescriptionHashes.get(hash), i])
        } else {
          typeDescriptionHashes.set(hash, [i])
        }
      })

      // Add errors for each rate that has a duplicate type + description
      const newErrors = { rates: new Array(values.rates?.length ?? 0) }
      typeDescriptionHashes.forEach((indexes) => {
        if (indexes.length <= 1) return

        indexes.forEach((index: number) => {
          newErrors.rates[index] = { description: 'Description + Type must be unique' }
        })
      })

      // If there are no errors, return an empty object
      if ((errors.rates?.length ?? 0) === 0 && newErrors.rates.every((e) => !e)) {
        return {}
      }

      return newErrors
    },
    onSubmit: async (formValues: Values) => {
      // Delete all optional empty fields
      const formattedValues: ServiceCardCreateRequest = {
        ...formValues,
        startDate: formValues.startDate ?? 0,
        rates: (formValues.rates ?? []).map((rate) => {
          if (!rate.rate?.amount) {
            // @ts-ignore
            delete rate.rate
          }

          if (!rate.costHigh?.amount) {
            // @ts-ignore
            delete rate.costHigh
          }

          if (!rate.qtyHigh) {
            delete rate.qtyHigh
          }

          return rate as ServiceCardCreateRequest['rates'][0]
        }),
      }

      try {
        await submitRateCard({ servicerId: servicer.id, body: formattedValues }).unwrap()
        toast({
          toastColor: ToastColor.green,
          toastDuration: ToastDuration.short,
          message: 'Rate card successfully saved!',
        })
        onCancelOrSave()
      } catch (e) {
        toast({
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
          message: 'An error occurred',
        })
      }
    },
  })

  const handleDeleteLineItemClick = (index: number) => {
    const rates = [...(values.rates ?? [])]
    rates.splice(index, 1)
    setFieldValue('rates', rates)
  }

  const handleAddLineItemClick = () => {
    const rates = [...(values.rates ?? [])]
    // @ts-ignore
    rates.push(rateSchema.getDefault())
    setFieldValue('rates', rates)
  }

  const handleDateInputClick = () => {
    if (!isPresent) {
      toggle()
    }
  }

  const handleDateRangeChange = (value: DateRangeValue) => {
    const startValue = value.start ? value.start.getTime() : undefined
    const endValue = value.end ? value.end.getTime() : undefined
    setFieldValue('startDate', startValue)
    if (startValue === endValue) {
      setFieldValue('endDate', undefined)
    } else {
      setFieldValue('endDate', endValue)
    }
  }

  return (
    <ContentWrapper data-cy="rate-card-form">
      <Stack spacing={5}>
        <Header>
          <Title>Rate Card {index + 1}</Title>
        </Header>
        <Checkbox
          data-cy="is-active-checkbox"
          label="Is Active"
          checked={values.isActive}
          onChange={() => setFieldValue('isActive', !values.isActive)}
        />
        <Stack isRow spacing={2}>
          <Input
            endAdornment={<Icon icon={DateIcon} color={COLOR.BLUE[700]} />}
            placeholder="MM/DD/YYYY"
            data-cy="start-date"
            onClick={handleDateInputClick}
            label="Start Date"
            onChange={() => {}}
            isError={Boolean(errors.startDate)}
            errorFeedback={errors.startDate}
            value={values.startDate ? date.format(values.startDate, 'MM/DD/YYYY') : ''}
            id="startDate"
          />
          <Input
            endAdornment={<Icon icon={DateIcon} color={COLOR.BLUE[700]} />}
            placeholder="MM/DD/YYYY"
            label="End Date (optional)"
            data-cy="end-date"
            onClick={handleDateInputClick}
            onChange={() => {}}
            ref={triggerRef}
            isError={Boolean(errors.endDate)}
            errorFeedback={errors.endDate}
            value={values.endDate ? date.format(values.endDate, 'MM/DD/YYYY') : ''}
            id="endDate"
          />
        </Stack>
        <Popover
          ref={popoverRef}
          direction={PopoverDirection.down}
          alignment={PopoverAlignment.center}
          isPresent={isPresent}
          triggerBoundingBox={triggerBoundingBox}
        >
          <DateRangePicker
            hasDateDisplay
            value={{
              start: values.startDate ? new Date(values.startDate) : undefined,
              end: values.endDate ? new Date(values.endDate) : undefined,
            }}
            onChange={handleDateRangeChange}
          />
        </Popover>

        {(values.rates?.length ?? 0) > 0 &&
          values.rates?.map((rate: RateValues, index: number) => (
            <Stack spacing={2} key={index}>
              <SectionHeader>Line Item {index + 1}</SectionHeader>
              <Stack isRow spacing={4} align="center">
                <Box>
                  <Stack spacing={2} data-cy={`line-item-${index}-container`}>
                    <Stack isRow spacing={2}>
                      <Select
                        label="Type"
                        data-cy={`line-item-${index}-type`}
                        isError={errors.rates && (errors.rates[index] as any)?.type}
                        errorFeedback={errors.rates && (errors.rates[index] as any)?.type}
                        value={rate.type}
                        id={`rates[${index}].type`}
                        placeholder="Select"
                        onChange={handleChange}
                      >
                        {expenseTypes.map((type) => (
                          <option key={type.value} value={type.value}>
                            {type.display}
                          </option>
                        ))}
                      </Select>
                      <Input
                        value={rate.description}
                        isError={errors.rates && (errors.rates[index] as any)?.description}
                        errorFeedback={errors.rates && (errors.rates[index] as any)?.description}
                        data-cy={`line-item-${index}-description`}
                        label="Description"
                        id={`rates[${index}].description`}
                        onChange={handleChange}
                      />
                      <CurrencyInputWrapper>
                        <CurrencyInputGroup
                          name={`rates[${index}].rate.amount`}
                          label="Rate (optional)"
                          data-cy={`line-item-${index}-rate`}
                          errorMessage={errors.rates && (errors.rates[index] as any)?.rate?.amount}
                          currencyProps={{
                            codeFieldName: `rates[${index}].rate.currencyCode`,
                            codeValue: (rate.rate?.currencyCode || 'USD') as CurrencyCode,
                          }}
                          onChange={(changeVal) => {
                            setFieldValue(`rates[${index}].rate.amount`, changeVal)
                          }}
                          value={rate.rate?.amount}
                          showSymbol={true}
                          isGroup={false}
                        />
                      </CurrencyInputWrapper>
                      <Input
                        value={rate.qtyLow ? rate.qtyLow.toString() : ''}
                        data-cy={`line-item-${index}-qty-low`}
                        label="Quantity Low"
                        onChange={handleChange}
                        id={`rates[${index}].qtyLow`}
                        isError={errors.rates && (errors.rates[index] as any)?.qtyLow}
                        errorFeedback={errors.rates && (errors.rates[index] as any)?.qtyLow}
                        type={InputType.number}
                      />
                      <Input
                        value={rate.qtyHigh ? rate.qtyHigh.toString() : ''}
                        onChange={handleChange}
                        label="Quantity High (optional)"
                        data-cy={`line-item-${index}-qty-high`}
                        id={`rates[${index}].qtyHigh`}
                        isError={errors.rates && (errors.rates[index] as any)?.qtyHigh}
                        errorFeedback={errors.rates && (errors.rates[index] as any)?.qtyHigh}
                        type={InputType.number}
                      />
                    </Stack>
                    <Stack isRow spacing={2}>
                      <CurrencyInputWrapper>
                        <CurrencyInputGroup
                          name={`rates[${index}].costLow`}
                          data-cy={`line-item-${index}-cost-low`}
                          label="Cost Low"
                          errorMessage={
                            errors.rates && (errors.rates[index] as any)?.costLow?.amount
                          }
                          currencyProps={{
                            codeFieldName: `rates[${index}].costLow.currencyCode`,
                            codeValue: (rate.costLow?.currencyCode || 'USD') as CurrencyCode,
                          }}
                          onChange={(changeVal) => {
                            setFieldValue(`rates[${index}].costLow.amount`, changeVal)
                          }}
                          value={rate.costLow?.amount}
                          showSymbol={true}
                          isGroup={false}
                        />
                      </CurrencyInputWrapper>
                      <CurrencyInputWrapper>
                        <CurrencyInputGroup
                          name={`rates[${index}].costHigh`}
                          data-cy={`line-item-${index}-cost-high`}
                          errorMessage={
                            errors.rates &&
                            (errors.rates[index] as unknown as any)?.costHigh?.amount
                          }
                          label="Cost High (optional)"
                          currencyProps={{
                            codeFieldName: `rates[${index}].costHigh.currencyCode`,
                            codeValue: (rate.costHigh?.currencyCode || 'USD') as CurrencyCode,
                          }}
                          onChange={(changeVal) => {
                            setFieldValue(`rates[${index}].costHigh.amount`, changeVal)
                          }}
                          value={rate.costHigh?.amount}
                          showSymbol={true}
                          isGroup={false}
                        />
                      </CurrencyInputWrapper>
                    </Stack>
                  </Stack>
                </Box>
                <div>
                  <Button
                    emphasis="low"
                    color="neutral"
                    icon={Trash}
                    data-cy={`line-item-${index}-delete-line-item`}
                    onClick={() => handleDeleteLineItemClick(index)}
                  />
                </div>
              </Stack>
            </Stack>
          ))}
        <div>
          <Button
            text="Add Line Item"
            emphasis="low"
            icon={Add}
            onClick={handleAddLineItemClick}
            data-cy="add-line-item-button"
          />
        </div>
        <ButtonGroup>
          <div>
            <Button
              text="Cancel"
              emphasis="medium"
              onClick={onCancelOrSave}
              data-cy="cancel-button"
            />
          </div>
          <div>
            <Button text="Save" data-cy="save-button" onClick={() => submitForm()} />
          </div>
        </ButtonGroup>
      </Stack>
    </ContentWrapper>
  )
}

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  marginBottom: 32,
  padding: 40,
  borderRadius: 4,
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  margin: 0,
})

const SectionHeader = styled.p({
  fontWeight: 700,
  fontSize: 14,
  textTransform: 'uppercase',
})

const CurrencyInputWrapper = styled.div({
  marginTop: -1,
  width: 275,
})
