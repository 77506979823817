import type { FormikErrors, FormikTouched } from 'formik'
import { getIn } from 'formik'
import type { CurrencyInputItem, HyperlinkInputItem, InputItem } from './types'
import type { DropdownProps } from '../dropdown'

interface FormikMapperConfig {
  key: string
  label: string
  fieldType?: InputItem['fieldType']
  columnCount?: number
  isItemDisabled?: boolean
  dropdownOptions?: string[]
}

interface CurrencyConfig extends FormikMapperConfig {
  fieldType: 'currency'
  currencyProps: {
    codeFieldName: string
  }
  handleCurrencyChange: () => void
}

interface DropdownConfig extends DropdownProps, FormikMapperConfig {
  fieldType: 'dropdown'
}

interface HyperlinkConfig extends FormikMapperConfig {
  fieldType: 'hyperlink'
  hyperlinkProps: {
    hyperlinkUrl: string
  }
}

const mapCurrencyProps = <T>(item: CurrencyConfig, values: T): CurrencyInputItem => {
  const codeFieldName = item.currencyProps?.codeFieldName ?? 'currencyCode'
  return {
    name: item.key,
    value: getIn(values, item.key),
    ...item,
    currencyProps: {
      codeFieldName,
      codeValue: getIn(values, codeFieldName) ?? 'USD',
    },
  }
}

const mapHyperlinkProps = <T>(item: HyperlinkConfig, values: T): HyperlinkInputItem => {
  return {
    name: item.key,
    value: getIn(values, item.key),
    ...item,
    hyperlinkProps: {
      hyperlinkUrl: getIn(values, item.hyperlinkProps.hyperlinkUrl),
    },
  }
}

const formikMapper = <T>(
  values: T,
  touched: FormikTouched<T>,
  errors: FormikErrors<T>,
  data: Array<FormikMapperConfig | CurrencyConfig | HyperlinkConfig | DropdownConfig>,
): InputItem[] => {
  return data.map((item) => {
    if (item.fieldType === 'currency') {
      return mapCurrencyProps(item as CurrencyConfig, values)
    }
    if (item.fieldType === 'hyperlink') {
      return mapHyperlinkProps(item as HyperlinkConfig, values)
    }
    return {
      name: item.key,
      value: String(getIn(values, item.key) ?? ''),
      touched: getIn(touched, item.key),
      error: getIn(errors, item.key),
      fieldType: item.fieldType ?? 'input',
      ...item,
    }
  })
}

/**
 * Legacy function that controlled permissions to edit
 * contract form fields on a per-field basis.
 * rolePermissions can be any object type
 */
const applyEditPermissions = (
  formFields: FormikMapperConfig[],
  rolePermissions: any,
): FormikMapperConfig[] => {
  return formFields.map((item) => {
    const { key } = item
    if (rolePermissions[key]) {
      return item
    }
    return { ...item, isItemDisabled: true }
  })
}

export type { FormikMapperConfig, CurrencyConfig, HyperlinkConfig, DropdownConfig }
export { formikMapper, applyEditPermissions }
