import React from 'react'
import { Badge } from '@extend/zen'
import type { CellContext, ColumnDefs, BadgeProps } from '@extend/zen'
import { currency, date } from '@extend/client-helpers'
import type { OrderSearchItem } from '@helloextend/extend-api-rtk-query'

type OrderStatus = NonNullable<OrderSearchItem['status']>
const STATUS_TO_COLOR_MAPPER: Record<OrderStatus, BadgeProps['color']> = {
  open: 'green',
  closed: 'blue',
  refunded: 'blue',
  canceled: 'red',
  voided: 'red',
  inactive: 'neutral',
  pending: 'neutral',
}

const renderCell = (cellData: CellContext<OrderSearchItem, unknown>): unknown =>
  cellData.getValue() ?? ''
const renderDateCell = (
  data: CellContext<OrderSearchItem, string | number | undefined>,
): string => {
  const value = data.getValue()
  if (!value) return ''
  return date.format(new Date(value).getTime(), 'YYYY-MM-DD')
}
const renderMoneyCell = (data: CellContext<OrderSearchItem, number | undefined>): string => {
  const moneyValue = data.getValue()
  return moneyValue !== undefined ? `${currency.formatWithoutCurrencySymbol(moneyValue)}` : ''
}

const renderBadge = (data: CellContext<OrderSearchItem, string>): JSX.Element | string => {
  const status = data.getValue()
  if (!status) return ''
  const color = STATUS_TO_COLOR_MAPPER[status as OrderStatus]
  return <Badge text={status} color={color} />
}

const ordersTableColumns: ColumnDefs<OrderSearchItem> = [
  { label: 'Order ID', id: 'orderId', search: 'explicit', isSearchOnly: true },
  { label: 'Status', id: 'status', renderCell: renderBadge },
  { label: 'Store Name', id: 'storeName', renderCell, isSortable: true },
  { label: 'Customer Email', id: 'customerEmail', search: 'explicit', isSearchOnly: true },
  { label: 'Customer Email', id: 'customer.email', renderCell },
  { label: 'Customer Name', id: 'customer.name', renderCell },
  { label: 'Customer Phone', id: 'customer.phone', renderCell },
  { label: 'Order ID', id: 'id', renderCell },
  { label: 'Created Date', id: 'createdAt', renderCell: renderDateCell, isSortable: true },
  { label: 'Customer Phone', id: 'customerPhone', search: 'explicit', isSearchOnly: true },
  { label: 'Transaction ID', id: 'transactionId', renderCell, search: 'explicit' },
  { label: 'Merchant Customer ID', id: 'merchantCustomerId', renderCell, search: 'explicit' }, // also a search option
  { label: 'Purchase Order Number', id: 'poNumber', renderCell },
  { label: 'Discount Total', id: 'totalDiscounts', renderCell: renderMoneyCell },
  { label: 'Product Total', id: 'productCostTotal', renderCell: renderMoneyCell },
  { label: 'Shipping Total', id: 'shippingCostTotal', renderCell: renderMoneyCell },
  { label: 'Tax Total', id: 'taxCostTotal', renderCell: renderMoneyCell },
  { label: 'Order Total', id: 'total', renderCell: renderMoneyCell },
]

export { ordersTableColumns }
