import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import {
  Paragraph,
  Modal,
  ModalController,
  Stack,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useUpdatePlanSetMappingsMutation } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import { CategorySelector } from '../category-selector'
import { PlanSetSelector } from '../plan-set-selector'

export interface EditPlanSetModalProps {
  isModalOpen: boolean
  category: string
  categoryId: string
  storePlanSetMappingId: string
  closeModal: () => void
}

const EditPlanSetModal: FC<EditPlanSetModalProps> = ({
  isModalOpen,
  category,
  storePlanSetMappingId,
  closeModal,
  categoryId,
}) => {
  const { storeId } = useParams<{ storeId: string }>()
  const [planSetId, setPlanSetId] = useState<string>('')
  const [update, { isLoading }] = useUpdatePlanSetMappingsMutation()
  const { toast } = useToaster()

  const handleSubmit = async (): Promise<void> => {
    const response = await update({
      storePlanSetMappingId,
      planSetId,
      storeId,
    })

    if ('error' in response) {
      closeModal()
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.long,
      })
      return
    }
    closeModal()
    toast({
      message: `Plan Set Mapping Successfully updated.`,
      toastColor: ToastColor.blue,
      toastDuration: ToastDuration.short,
    })
  }

  const handlePlanSetChange = useCallback((e: AdvancedSelectChangeEvent<string>) => {
    setPlanSetId(e.target.value)
  }, [])

  const isSaveDisabled = (): boolean => isLoading || !category || !planSetId

  return (
    <ModalController isOpen={isModalOpen}>
      <Modal
        heading="Edit Plan Set"
        onDismissRequest={closeModal}
        data-cy="edit-plan-set-modal"
        primaryButtonProps={{
          'data-cy': 'modal-submit',
          onClick: handleSubmit,
          text: 'Save',
          isDisabled: isSaveDisabled(),
          isProcessing: isLoading,
        }}
        secondaryButtonProps={{
          'data-cy': 'modal-cancel',
          onClick: closeModal,
          text: 'Cancel',
        }}
      >
        <ParagraphWrapper data-cy="edit-plan-set-model-body-text">
          Only the added plan sets and plans can be mapped to the products in this store.
        </ParagraphWrapper>
        <Stack spacing={2}>
          <CategorySelector
            onChange={() => {}}
            value={category}
            categories={[]}
            isDisabled
            showSelector
            isError={false}
            errorFeedback=""
          />
          <PlanSetSelector
            onChange={handlePlanSetChange}
            planSetId={planSetId}
            categoryId={categoryId}
            isDisabled={!category || isLoading}
            showSelector
          />
        </Stack>
      </Modal>
    </ModalController>
  )
}

const ParagraphWrapper = styled(Paragraph)({
  marginBottom: 16,
})

export { EditPlanSetModal }
