import type { FC } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
import type { ThreadResponse } from '@helloextend/extend-api-rtk-query'
import { CustomerResponse } from '../customer-response'
import type { SelectedMessageBlock } from '../../../../../store/slices/amp-slice'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'

type KaleyConditionEditorProps = {
  thread: ThreadResponse
  conditionBlock: SelectedMessageBlock
  isThreadStructureLocked: boolean
}

const KaleyConditionEditor: FC<KaleyConditionEditorProps> = ({
  thread,
  conditionBlock,
  isThreadStructureLocked,
}) => {
  const selectedBlockIndex = useSelector((state: RootState) =>
    selectors.getSelectedMessageBlockIndex(state),
  )

  return (
    <>
      <CustomerResponse
        thread={thread}
        messageBlock={conditionBlock}
        messageBlockIndex={selectedBlockIndex}
        isThreadStructureLocked={isThreadStructureLocked}
      />
    </>
  )
}

export type { KaleyConditionEditorProps }
export { KaleyConditionEditor }
