import { ContractType } from '@helloextend/extend-api-client'

const getContractTypeCopy = (contractType: ContractType): string => {
  switch (contractType) {
    case ContractType.PCRS:
      return 'Product Protection'
    case ContractType.SHIPPING_PROTECTION:
      return 'Shipping Protection'
    case ContractType.CATEGORY:
      return 'Category Contract'
    case ContractType.PRODUCT_PROTECTION_BUNDLE:
      return 'Product Protection'
    default:
      return 'Unknown'
  }
}

export { getContractTypeCopy }
