import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { KaleyConditionTypes } from '../utils'
import { generateShipmentCount } from './shipment-count'
import { generateItemCount } from './item-count'

export const getConditionByType = (type: KaleyConditionTypes): ScriptItem | undefined => {
  const typeToBlockMap: { [key in KaleyConditionTypes]: ScriptItem | undefined } = {
    'Shipment Count': generateShipmentCount(),
    'Item Count': generateItemCount(),
  }
  return typeToBlockMap[type]
}

export const mapSuccessToCode = (type: KaleyConditionTypes): string => {
  const successCodeMap: { [key in KaleyConditionTypes]: string } = {
    [KaleyConditionTypes.numberOfShipments]: 'one_shipment',
    [KaleyConditionTypes.numberOfProducts]: 'one_product',
  }

  return successCodeMap[type] ?? 'success'
}
