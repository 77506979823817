import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { decode } from '@extend/client-helpers'
import { useToaster, Stack, Button, COLOR, Spinner, ToastColor, ToastDuration } from '@extend/zen'
import type {
  ConnectResponse} from '@helloextend/extend-api-rtk-query';
import {
  useConnectIncredibotMutation,
  useGetContractQuery,
  useUpdateIncredibotMutation
} from '@helloextend/extend-api-rtk-query'
import type {
  DecodedAccessToken,
  ClaimFiler,
  Reply,
  ClaimUpdateRequest,
 Slot} from '@helloextend/extend-api-client'
import { ClaimSource } from '@helloextend/extend-api-client'
import { usePrevious, useToggle } from '@helloextend/client-hooks'
import { useExtendAuth } from '@extend/package-okta-login'
import { UserInput } from './components/user-input'
import { ErrorMessage } from './components/error-message'
import { useUpdateClaim } from '../../../hooks'

const FileAClaim: FC = () => {
  const { toast } = useToaster()
  const history = useHistory()
  const [isNavBlocked, { toggle }] = useToggle()
  const { contractId, lineItemId } = useParams<{
    contractId: string
    lineItemId?: string
  }>()
  const { token: accessToken } = useExtendAuth()

  const { data: contract, isLoading: isGetContractLoading } = useGetContractQuery({
    contractId,
  })

  const { updateClaim, error: claimUpdateError, isLoading: isClaimUpdateLoading } = useUpdateClaim()

  const [
    connectIncredibot,
    {
      isLoading: isIncredibotConnectLoading,
      isSuccess: isIncredibotConnectSuccess,
      error: incredibotConnectError,
      data: incredibotConnectReply,
    },
  ] = useConnectIncredibotMutation()

  const [
    updateIncredibot,
    {
      isLoading: isIncredibotUpdating,
      isSuccess: isIncredibotUpdateSuccess,
      error: incredibotUpdateError,
      data: incredibotUpdateReply,
    },
  ] = useUpdateIncredibotMutation()

  const [currentReply, setCurrentReply] = useState<ConnectResponse | Reply | undefined>({
    messages: [],
  })

  const handleError = useCallback(() => {
    toast({
      message: 'Error occurred. Please try again.',
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.long,
    })
  }, [toast])

  const isLoading = isGetContractLoading || isIncredibotConnectLoading

  useEffect(() => {
    if (isIncredibotConnectSuccess) {
      setCurrentReply(incredibotConnectReply)
    }
  }, [incredibotConnectReply, isIncredibotConnectSuccess])

  useEffect(() => {
    if (isIncredibotUpdateSuccess) {
      setCurrentReply(incredibotUpdateReply)
    }
  }, [incredibotUpdateReply, isIncredibotUpdateSuccess])

  useEffect(() => {
    // only connect to incredibot once the contract info has been fetched
    if (accessToken && contractId) {
      const { firstName, lastName, email, accountId } = decode(accessToken) as DecodedAccessToken

      const filedBy: ClaimFiler = {
        firstName,
        lastName,
        email,
        accountId,
      }

      connectIncredibot({
        filedBy,
        contractId,
        ...(lineItemId && { lineItemIds: [lineItemId] }),
        source: ClaimSource.ops_admin,
      })
    }
  }, [accessToken, contractId, lineItemId, connectIncredibot])

  useEffect(() => {
    const claimFilingError = claimUpdateError || incredibotUpdateError || incredibotConnectError
    if (claimFilingError) {
      handleError()
    }
  }, [handleError, incredibotConnectError, incredibotUpdateError, claimUpdateError])

  const handleUpdateSession = (slot: Slot, slotValue: string | number | string[]): void => {
    const incredibotToken = incredibotConnectReply?.accessToken

    if (incredibotToken) {
      const incredibotData = {
        accessToken: incredibotToken,
        data: { slot, slotValue },
      }
      updateIncredibot(incredibotData)
    }
  }

  const handleUpdateClaim = async (claimId: string, updates: ClaimUpdateRequest): Promise<void> => {
    if (accessToken) {
      await updateClaim({ claimId, body: updates as ClaimUpdateRequest })
    }
  }

  const handleLeavePage = (path: string): void => {
    history.push(path)
  }

  const prevIsLoading = usePrevious<boolean>(isLoading)
  const isLoaded = prevIsLoading && !isLoading
  const hasError = claimUpdateError || incredibotConnectError || incredibotUpdateError

  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </LoadingContainer>
    )
  }

  if (isLoaded && hasError) {
    return <ErrorMessage contractId={contractId} reply={currentReply as Reply} />
  }

  return (
    <Container>
      <HeaderContainer>
        <Stack isRow justify="space-between" doesWrap>
          <HeaderDetailsContainer>
            <PageTitle>Filing a Claim</PageTitle>
            {contract &&
              (contract.type === 'shipping_protection' ? (
                <Stack isRow doesWrap>
                  <DetailName>Order Number: </DetailName>
                  {contract.orderId}
                </Stack>
              ) : (
                <Stack isRow doesWrap>
                  <DetailName>Claim in progress for: </DetailName>
                  {contract?.product?.name}
                </Stack>
              ))}
            {lineItemId && (
              <Stack isRow doesWrap data-cy="product-id-stack">
                <DetailName>Product ID: </DetailName>
                {
                  contract?.productsList?.find((product) => product.lineItemId === lineItemId)
                    ?.referenceId
                }
              </Stack>
            )}
            <Stack isRow doesWrap>
              <DetailName>Contract ID: </DetailName>
              <StyledLink to={`/admin/contracts/${contractId}`}>{contractId}</StyledLink>
            </Stack>
          </HeaderDetailsContainer>
          {!currentReply?.poll && (
            <Button
              emphasis="medium"
              text="Cancel claim"
              onClick={() => handleLeavePage(`/admin/contracts/${contractId}`)}
            />
          )}
        </Stack>
      </HeaderContainer>
      {contract && currentReply && (
        <UserInput
          contract={contract}
          reply={currentReply as Reply}
          isIncredibotUpdating={isIncredibotUpdating}
          onIncredibotUpdate={handleUpdateSession}
          onUpdateClaim={handleUpdateClaim}
          isClaimUpdateLoading={isClaimUpdateLoading}
          toggleNavBlocked={toggle}
          isNavBlocked={isNavBlocked}
        />
      )}
    </Container>
  )
}

const LoadingContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
const Container = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})
const HeaderContainer = styled.div({
  width: '100%',
})
const HeaderDetailsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})
const PageTitle = styled.p({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '32px',
  margin: 0,
  marginBottom: 4,
})
const DetailName = styled.span({
  fontSize: 16,
  fontWeight: 'bold',
  color: COLOR.NEUTRAL[600],
  marginRight: 4,
})
export const StyledLink = styled(Link)({
  color: COLOR.BLUE[800],
  fontSize: 16,
  lineHeight: '24px',
  '&:hover': {
    textDecoration: 'underline',
  },
})

export { FileAClaim }
