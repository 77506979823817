import type { Product } from './product'

export enum CategoryMappingStatus {
  UNMAPPED = 'Unmapped',
  WARRANTABLE = 'Warrantable',
  NON_WARRANTABLE = 'Non-Warrantable',
}

export interface DraftCategoryMapping {
  id?: string
  storeId: string
  planSetId?: string
  mappingStatus: CategoryMappingStatus
  extendCategory?: string
  planCategoryId: string
  merchantCategory: string
}

export interface CategoryMapping extends DraftCategoryMapping {
  id: string
  extendCategory: string
  planCategoryId: string
  planSetId: string
  createdAt: number
  updatedAt: number
  deletedAt?: number
  version: string
}

export interface CategoryMappingCreateBody extends DraftCategoryMapping {}

export interface CategoryMappingDownloadRequest {
  storeId: string
  email: string
}

export interface CategoryMappingDownloadResponse {
  url: string
  message: string
}

export interface CategoryMappingBatchCreateBody extends Array<DraftCategoryMapping> {}

export interface CategoryMappingListResponse {
  items: CategoryMapping[]
  limit: number
  nextPageCursor?: string
}

export interface CategoryMappingListRequest {
  storeId: string
  filter?: string
}

export interface InvalidBatchProductUpdateItem {
  product: Partial<Product>
  reason: string
}

export interface ProductsWarrantyMappingUpdateResponse {
  errors: InvalidBatchProductUpdateItem[]
  updated: Product[]
}

export interface ProductWarrantyMappingUpdate
  extends Partial<Pick<Product, 'plans' | 'warrantyStatus' | 'updatedBy' | 'planCategoryId'>> {
  referenceId: string
  labor?: number
  parts?: number
  url?: string
}

export interface ProducstWarrantyMappingUpdateRequest {
  storeId: string
  data: ProductWarrantyMappingUpdate[]
}

export interface CategoryMappingImportUrlRequest {
  storeId: string
}

export interface CategoryMappingImportUrlResponse {
  url: string
}

export interface CategoryMappingImportRequest {
  url: string
  fileBlob: Blob
}
