import * as Yup from 'yup'

const productComponentSchema = Yup.object().shape({
  componentName: Yup.string().required('Component Name is required'),
  failureTypes: Yup.array().of(Yup.string()).notRequired() as Yup.Schema<string[] | undefined>,
  failureCauses: Yup.array().of(Yup.string()).notRequired() as Yup.Schema<string[] | undefined>,
  limitOfLiability: Yup.object()
    .shape({
      amount: Yup.number().min(1, 'Limit of Liability must be minimum of 1').notRequired(),
      percentage: Yup.number().min(1, 'Limit of Liability must be minimum of 1').notRequired(),
      count: Yup.number().min(1, 'Limit of Liability must be minimum of 1').notRequired(),
    })
    .notRequired(),
})

const schema = Yup.object().shape({
  planCategoryId: Yup.string().required('Plan Category ID is required'),
  components: Yup.array().of(productComponentSchema).notRequired(),
})

const coverageSchema = Yup.object().shape({
  covered: Yup.array().of(schema).notRequired(),
  notCovered: Yup.array().of(schema).notRequired(),
})

export { schema, coverageSchema }
