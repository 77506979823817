import styled from '@emotion/styled'
import { HeadingLarge, Badge } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'

export const CustomerNameAndOrderStatus: FC<{ name: string; status: string }> = ({
  name,
  status,
}) => {
  const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1)
  const badgeColor = status === 'open' || status === 'pending' ? 'green' : 'red'
  return (
    <HeadingLarge data-cy="customer-name-and-order-status">
      <HeadingContainer>
        <NameContainer data-cy="customer-name">{name}&apos;s Order</NameContainer>
        <Badge data-cy="status-badge" text={capitalizedStatus} color={badgeColor} />
      </HeadingContainer>
    </HeadingLarge>
  )
}

const HeadingContainer = styled.div({
  display: 'flex',
  alignContent: 'center',
  margin: '8px 0',
})

const NameContainer = styled.div({
  marginRight: 8,
})
