import type { ClaimsSearchResponseV2 } from '@helloextend/extend-api-rtk-query'
import { useListInsuranceClaimsQuery } from '@helloextend/extend-api-rtk-query'

interface QueryParams {
  searchVersion?: string
  containsContractId?: string
  containsClaimId?: string
  matchesClaimStatus?: string[]
}

const useClaimsSearch = ({
  queryParams,
  skip = false,
}: {
  queryParams: QueryParams
  skip?: boolean
}): {
  data?: ClaimsSearchResponseV2
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
} => {
  const {
    data: claimsManagementClaimsSearchData,
    isLoading: isClaimsManagementClaimsSearchLoading,
    isError: claimsManagementSearchError,
    isSuccess: claimsManagementSearchSuccess,
  } = useListInsuranceClaimsQuery(queryParams, { skip })

  return {
    data: claimsManagementClaimsSearchData,
    isLoading: isClaimsManagementClaimsSearchLoading,
    isError: claimsManagementSearchError,
    isSuccess: claimsManagementSearchSuccess,
  }
}

export { useClaimsSearch }
