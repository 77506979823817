import styled from '@emotion/styled'
import {
  Button,
  DataProperty,
  DataPropertyType,
  Edit,
  Grid,
  Input,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useUpdatePlanTermsMutation } from '@helloextend/extend-api-rtk-query'
import { useExtendAuth } from '@extend/package-okta-login'
import { useFormik } from 'formik'
import type { FC } from 'react'
import React, { useState } from 'react'
import type * as Yup from 'yup'
import { userRoles } from '../../../../../../utils/user-role-mapper'
import { schema as termsMetaDataSchema } from './terms-metadata-schema'

type TermsMetaDataProps = {
  termsId: string
  termsDescription: string
}

const TermsMetaData: FC<TermsMetaDataProps> = ({ termsId, termsDescription }) => {
  const { user } = useExtendAuth()
  const [editMode, setEditMode] = useState(false)

  const [updatePlanTermsMetaData, { isLoading: updatingPlanTermsMetaData }] =
    useUpdatePlanTermsMutation()

  const { toast } = useToaster()

  const { values, handleChange, handleBlur, errors, touched, handleSubmit, setValues, dirty } =
    useFormik<TermsMetaDataValues>({
      initialValues: {
        description: termsDescription,
      },
      validationSchema: termsMetaDataSchema,
      onSubmit: async (submittedValues) => {
        const res = await updatePlanTermsMetaData({
          termsId,
          description: submittedValues.description,
        })

        if ('error' in res) {
          toast({
            message: 'Failed to update terms metadata',
            toastColor: ToastColor.red,
            toastDuration: ToastDuration.short,
          })
          return
        }

        toast({
          message: 'Plans metadata updated',
          toastColor: ToastColor.green,
          toastDuration: ToastDuration.short,
        })

        setEditMode(false)
      },
    })

  return (
    <TermsMetaDataSection>
      <div>
        <Grid columns={1} spacing={5}>
          <TermsMetaDataProperties>
            <DataProperty
              label="Terms ID"
              value={termsId}
              type={DataPropertyType.string}
              isHorizontal
              data-cy="terms-id-data-property"
            />
            {editMode ? (
              <Input
                id="description"
                label="Description"
                value={values.description}
                isDisabled={updatingPlanTermsMetaData}
                onChange={handleChange}
                onBlur={handleBlur}
                errorFeedback={errors.description}
                isError={!!errors.description && touched.description}
                data-cy="terms-description-input"
              />
            ) : (
              <DataProperty
                label="Description"
                value={values.description}
                type={DataPropertyType.string}
                isHorizontal
                data-cy="terms-description-data-property"
              />
            )}
          </TermsMetaDataProperties>
        </Grid>
      </div>
      {editMode ? (
        <>
          <Button
            type="button"
            text="Save Terms Meta Data"
            emphasis="high"
            onClick={() => handleSubmit()}
            isProcessing={updatingPlanTermsMetaData}
            isDisabled={!dirty}
            data-cy="save-terms-metadata-button"
            size="small"
          />
          <TermsButtonsSpacer />
          <Button
            type="button"
            text="Cancel"
            emphasis="medium"
            onClick={() => {
              setValues({ description: values.description })
              setEditMode(false)
            }}
            data-cy="cancel-edit-terms-metadata-button"
            size="small"
          />
        </>
      ) : (
        user?.role === userRoles.ComplianceManager.value && (
          <Button
            icon={Edit}
            type="button"
            text="Edit Terms Meta Data"
            emphasis="medium"
            onClick={() => {
              setEditMode(true)
            }}
            data-cy="edit-terms-metadata-button"
            size="small"
          />
        )
      )}
    </TermsMetaDataSection>
  )
}

type TermsMetaDataValues = Yup.InferType<typeof termsMetaDataSchema>

const TermsMetaDataSection = styled.div({ display: 'flex', flexDirection: 'row' })
const TermsMetaDataProperties = styled.div({ marginRight: '20px' })
const TermsButtonsSpacer = styled.div({ marginRight: '20px' })

export { TermsMetaData }
