import type { ContractStatus } from '@helloextend/extend-api-client'

export const contractStatusMap: Record<ContractStatus, string> = {
  live: 'Live',
  created: 'Created',
  fulfilled: 'Fulfilled',
  terminated: 'Terminated',
  cancelled: 'Cancelled',
  expired: 'Expired',
  delivered: 'Delivered',
  refund_accepted: 'Refund Accepted',
  refund_denied: 'Refund Denied',
  refund_paid: 'Refund Paid',
  refund_requested: 'Refund Requested',
  voided: 'Voided',
}
