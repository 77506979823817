import { get } from 'lodash/fp'
import type { GroupItem } from './data-display-group'

interface FieldMapperConfig<T> {
  key: string
  label: string
  transformFn?: (value: any) => string
  validateFn?: (values: T) => boolean
  columnCount?: number
  isHidden?: boolean
  to?: string
}

const fieldMapper = <T>(values: T, data: Array<FieldMapperConfig<T>>): Array<GroupItem | null> => {
  return data.map((item) => {
    const rawVal = get(item.key, values) ?? '—'
    if ((item.validateFn && !item.validateFn(values)) || item.isHidden) {
      return null
    }

    const value = item.transformFn ? item.transformFn(rawVal) : rawVal

    return {
      name: item.key,
      value,
      ...item,
    }
  })
}

export type { FieldMapperConfig }
export { fieldMapper }
