import type { LineItemGetResponse } from '@helloextend/extend-api-rtk-query'
import { DataPropertyType, InputType } from '@extend/zen'
import type { CompactFormFieldData } from '../../../../../../components/compact-form/types'
import { mapLineItemDetailsSchema } from './schema'
import type {
  GeneralInformation,
  ProductDetails,
  PlanInformation,
  CharityDonation,
  AdjustmentDetails,
  BundleInformation,
} from './schema'

export interface LineItemDetailsFormFields {
  'GENERAL INFORMATION': CompactFormFieldData[]
  'PLAN INFORMATION'?: CompactFormFieldData[]
  'CHARITY DONATION'?: CompactFormFieldData[]
  'ADJUSTMENT DETAILS'?: CompactFormFieldData[]
  'BUNDLE INFORMATION'?: CompactFormFieldData[]
  PRODUCT?: CompactFormFieldData[]
}

export const lineItemDetailsFormFields = (
  lineItem: LineItemGetResponse,
): LineItemDetailsFormFields => {
  const lineItemDetails = mapLineItemDetailsSchema(lineItem)

  const lineItemDetailsResponse: LineItemDetailsFormFields = {
    'GENERAL INFORMATION': getGeneralInformationFields(lineItemDetails.generalInformation),
  }

  const type = lineItem.type

  if (type !== 'shipments') {
    lineItemDetailsResponse['PRODUCT'] = getProductDetailsFields(
      lineItemDetails.productDetails || {},
    )
  }

  if (
    type === 'contract' ||
    type === 'shipments' ||
    type === 'bundle_contract' ||
    type === 'category_contract'
  ) {
    lineItemDetailsResponse['PLAN INFORMATION'] = getPlanInformationFields(
      lineItemDetails.planInformation || {
        planId: '',
        purchasePrice: 0,
        contractFailedReasons: [],
        premium: 0,
        quoteId: '',
        adjustedQuoteId: '',
      },
    )
  }

  if (type === 'shipments') {
    lineItemDetailsResponse['PLAN INFORMATION'] = getPlanInformationFields(
      lineItemDetails.planInformation || {
        planId: '',
        purchasePrice: 0,
        contractFailedReasons: [],
        premium: 0,
        quoteId: '',
        adjustedQuoteId: '',
      },
    )

    lineItemDetailsResponse['CHARITY DONATION'] = getCharityDonationFields(
      lineItemDetails.charityDonation || {
        charityId: '',
        charityName: '',
        charityDonationAmount: 0,
      },
    )
  }

  if (type === 'shipments' || type === 'category_contract') {
    lineItemDetailsResponse['ADJUSTMENT DETAILS'] = getAdjustmentDetailsFields(
      lineItemDetails.adjustmentDetails || {
        previousContractIds: [],
        productsRemoved: [],
      },
    )
  }

  if (type === 'bundle_component') {
    lineItemDetailsResponse['BUNDLE INFORMATION'] = getBundleInformationFields(
      lineItemDetails.bundleInformation || {},
    )
  }

  return lineItemDetailsResponse
}

const getGeneralInformationFields = ({
  transactionId = '',
  offerId = '',
  offerPlanId = '',
  leadToken = '',
  quantity = 0,
  fulfilledQuantity = 0,
  discountAmount = 0,
  taxCost = 0,
  fulfilledAt,
  refundedAt,
}: GeneralInformation): CompactFormFieldData[] => [
    {
      id: 'generalInformation.transactionId',
      label: 'Transaction ID',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: transactionId,
      isEditable: true,
    },
    {
      id: 'generalInformation.offerId',
      label: 'Offer ID',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: offerId,
      isEditable: true,
    },
    {
      id: 'generalInformation.offerPlanId',
      label: 'Offer Plan ID',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: offerPlanId,
      isEditable: true,
    },
    {
      id: 'generalInformation.leadToken',
      label: 'Lead Token',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: leadToken,
      isEditable: true,
    },
    {
      id: 'generalInformation.quantity',
      label: 'Quantity',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.number,
      value: quantity ? quantity.toString() : null,
      isEditable: true,
    },
    {
      id: 'generalInformation.fulfilledQuantity',
      label: 'Fulfilled Quantity',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.number,
      value: fulfilledQuantity,
      isEditable: true,
    },
    {
      id: 'generalInformation.discountAmount',
      label: 'Discount Amount',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.currency,
      value: discountAmount,
      isEditable: true,
    },
    {
      id: 'generalInformation.taxCost',
      label: 'Tax Cost',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.currency,
      value: taxCost,
      isEditable: true,
    },
    {
      id: 'generalInformation.fulfilledAt',
      label: 'Fulfilled At',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: fulfilledAt ? fulfilledAt : '',
      isEditable: true,
    },
    {
      id: 'generalInformation.refundedAt',
      label: 'Refunded At',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: refundedAt ? refundedAt : '',
      isEditable: true,
    },
  ]

const getProductDetailsFields = ({
  referenceId = '',
  productName = '',
  category = '',
  listPrice = 0,
  purchasePrice = 0,
  purchaseDate,
  fulfillmentDate,
  serialNumber = '',
}: ProductDetails): CompactFormFieldData[] => [
    {
      id: 'productDetails.referenceId',
      label: 'Reference ID',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: referenceId,
      isEditable: true,
    },
    {
      id: 'productDetails.productName',
      label: 'Product Name',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: productName,
      isEditable: true,
    },
    {
      id: 'productDetails.category',
      label: 'Category',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: category,
      isEditable: true,
    },
    {
      id: 'productDetails.listPrice',
      label: 'List Price',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.currency,
      value: listPrice,
      isEditable: true,
    },
    {
      id: 'productDetails.purchasePrice',
      label: 'Purchase Price',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.currency,
      value: purchasePrice,
      isEditable: true,
    },
    {
      id: 'productDetails.purchaseDate',
      label: 'Purchase Date',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: purchaseDate ? purchaseDate : '',
      isEditable: true,
    },
    {
      id: 'productDetails.fulfillmentDate',
      label: 'Fulfillment Date',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: fulfillmentDate ? fulfillmentDate : '',
      isEditable: true,
    },
    {
      id: 'productDetails.serialNumber',
      label: 'Serial Number',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: serialNumber,
      isEditable: true,
    },
  ]

const getPlanInformationFields = ({
  planId = '',
  purchasePrice = 0,
  contractFailedReasons = [],
  premium = 0,
  quoteId = '',
  adjustedQuoteId = '',
}: PlanInformation): CompactFormFieldData[] => [
    {
      id: 'planInformation.planId',
      label: 'Plan ID',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: planId,
      isEditable: true,
    },
    {
      id: 'planInformation.purchasePrice',
      label: 'Purchase Price',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.currency,
      value: purchasePrice,
      isEditable: true,
    },
    {
      id: 'planInformation.contractFailedReasons',
      label: 'Contract Failed Reasons',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: contractFailedReasons ? contractFailedReasons.join(', ') : null,
      isEditable: true,
    },
    {
      id: 'planInformation.premium',
      label: 'Premium',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.number,
      value: premium,
      isEditable: true,
    },
    {
      id: 'planInformation.quoteId',
      label: 'Quote ID',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: quoteId,
      isEditable: true,
    },
    {
      id: 'planInformation.adjustedQuoteId',
      label: 'Adjusted Quote ID',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: adjustedQuoteId,
      isEditable: true,
    },
  ]

const getCharityDonationFields = ({
  charityId = '',
  charityName = '',
  charityDonationAmount = 0,
}: CharityDonation): CompactFormFieldData[] => [
    {
      id: 'charityDonation.charityId',
      label: 'Charity ID',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: charityId,
      isEditable: true,
    },
    {
      id: 'charityDonation.charityName',
      label: 'Charity Name',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: charityName,
      isEditable: true,
    },
    {
      id: 'charityDonation.charityDonationAmount',
      label: 'Charity Donation Amount',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.currency,
      value: charityDonationAmount,
      isEditable: true,
    },
  ]

const getAdjustmentDetailsFields = ({
  previousContractIds = [],
  productsRemoved = [],
}: AdjustmentDetails): CompactFormFieldData[] => [
    {
      id: 'adjustmentDetails.previousContractIds',
      label: 'Previous Contract IDs',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: previousContractIds.join(', '),
      isEditable: true,
    },
    {
      id: 'adjustmentDetails.productsRemoved',
      label: 'Products Removed',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: productsRemoved ? productsRemoved.join(', ') : '',
      isEditable: true,
    },
  ]

const getBundleInformationFields = ({
  referenceId = '',
  productName = '',
  category = '',
  imageUrl = '',
  listPrice = 0,
  purchasePrice = 0,
  purchaseDate,
  fulfillmentDate,
  serialNumber = '',
  mfwLabor = 0,
  mfwParts = 0,
}: BundleInformation): CompactFormFieldData[] => [
    {
      id: 'bundleInformation.referenceId',
      label: 'Reference ID',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: referenceId,
      isEditable: true,
    },
    {
      id: 'bundleInformation.productName',
      label: 'Product Name',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: productName,
      isEditable: true,
    },
    {
      id: 'bundleInformation.category',
      label: 'Category',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: category,
      isEditable: true,
    },
    {
      id: 'bundleInformation.imageUrl',
      label: 'Image URL',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: imageUrl,
      isEditable: true,
    },
    {
      id: 'bundleInformation.listPrice',
      label: 'List Price',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.currency,
      value: listPrice,
      isEditable: true,
    },
    {
      id: 'bundleInformation.purchasePrice',
      label: 'Purchase Price',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.currency,
      value: purchasePrice,
      isEditable: true,
    },
    {
      id: 'bundleInformation.purchaseDate',
      label: 'Purchase Date',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: purchaseDate ? purchaseDate : '',
      isEditable: true,
    },
    {
      id: 'bundleInformation.fulfillmentDate',
      label: 'Fulfillment Date',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: fulfillmentDate ? fulfillmentDate : '',
      isEditable: true,
    },
    {
      id: 'bundleInformation.serialNumber',
      label: 'Serial Number',
      inputType: InputType.text,
      dataPropertyType: DataPropertyType.string,
      value: serialNumber,
      isEditable: true,
    },
    {
      id: 'bundleInformation.mfwLabor',
      label: 'MFW Labor',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.number,
      value: mfwLabor,
      isEditable: true,
    },
    {
      id: 'bundleInformation.mfwParts',
      label: 'MFW Parts',
      inputType: InputType.number,
      dataPropertyType: DataPropertyType.number,
      value: mfwParts,
      isEditable: true,
    },
  ]
