import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'

type ServicersListAction = ReturnType<typeof actions.list>

export function* list(action: ServicersListAction): SagaIterator {
  const { accessToken } = action.payload

  yield put(actions.listRequest())

  try {
    const response: ResolvedType<typeof client.servicers.listServicers> = yield call(
      client.servicers.listServicers,
      accessToken,
    )

    if (isErrorResponse(response)) {
      yield put(actions.listFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.listFailure(`An unknown error occurred`, response.status))
      return
    }

    yield put(actions.listSuccess(response.data))
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.listFailure(e.message))
    }
  }
}
