import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import type { Plan } from '@helloextend/extend-api-client'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.fetchAll>

export default function* fetchAll(action: Action): SagaIterator {
  const { accessToken } = action.payload

  yield put(actions.fetchAllRequest())

  try {
    let cursor
    const plans: Plan[] = []

    let response

    do {
      response = yield call(client.plans.fetchAll, accessToken, cursor)
      if (isErrorResponse(response)) {
        yield put(actions.fetchAllFailure(response.data.message, response.status))
        return
      }

      if (isExceptionResponse(response)) {
        yield put(actions.fetchAllFailure(`An unknown error occurred`, response.status))
        return
      }
      plans.push(...response.data.items)
      cursor = response.data.nextPageCursor
    } while (cursor)

    yield put(actions.fetchAllSuccess(plans))
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.fetchAllFailure(e.message))
    }
  }
}
