import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@extend/zen'

type ProductsIconProps = {
  fill?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@extend/zen'`
 */
const ProductsIcon: FC<ProductsIconProps> = ({ fill = COLOR.NEUTRAL[600] }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11">
    <path
      d="M4.296 4.657H.361A.36.36 0 010 4.297V.36A.36.36 0 01.36 0h3.936a.36.36 0 01.36.36v3.936a.36.36 0 01-.36.36zM.721 3.735c0 .11.09.2.2.2h2.814a.2.2 0 00.2-.2V.921a.2.2 0 00-.2-.2H.921a.2.2 0 00-.2.2v2.814zM10.076 4.657H6.141a.36.36 0 01-.361-.36V.36A.36.36 0 016.14 0h3.936a.36.36 0 01.36.36v3.936a.36.36 0 01-.36.36zm-3.575-.922c0 .11.09.2.2.2h2.814a.2.2 0 00.2-.2V.921a.2.2 0 00-.2-.2H6.701a.2.2 0 00-.2.2v2.814zM4.296 10.437H.361a.36.36 0 01-.361-.36V6.14a.36.36 0 01.36-.361h3.936a.36.36 0 01.36.36v3.936a.36.36 0 01-.36.36zM.721 9.515c0 .11.09.2.2.2h2.814a.2.2 0 00.2-.2V6.701a.2.2 0 00-.2-.2H.921a.2.2 0 00-.2.2v2.814zM10.076 10.437H6.141a.36.36 0 01-.361-.36V6.14a.36.36 0 01.36-.361h3.936a.36.36 0 01.36.36v3.936a.36.36 0 01-.36.36zm-3.575-.922c0 .11.09.2.2.2h2.814a.2.2 0 00.2-.2V6.701a.2.2 0 00-.2-.2H6.701a.2.2 0 00-.2.2v2.814z"
      fill={fill}
    />
  </svg>
)

export { ProductsIcon }
