import { date } from '@extend/client-helpers'
import type {
  AuditItem,
  Contract,
  Contract20220201GetResponse,
  ContractProduct,
  ContractsGetResponse,
  ContractsUpdateRequest,
  ContractsUpdateResponse,
  ContractsV2GetResponse,
  ContractCoveredProduct,
  Contracts20220201UpdateRequest,
  CategoryContract,
  ContractCustomer,
  CategoryProduct,
} from '@helloextend/extend-api-client'
import {
  ContractStatus,
  ContractType,
  ContractAdminSyncStatus,
} from '@helloextend/extend-api-client'
import type { PlanContract } from '@helloextend/extend-api-client/src/models/plan'
import type { clientTypes as ContractsClientTypes } from '@extend-contracts/client'
import dayjs from 'dayjs'
import { faker } from '@faker-js/faker/locale/en'
import _, { defaultsDeep } from 'lodash'
import { generateCustomer } from './customer'
import type { ContractNote } from '@helloextend/extend-api-client'

type ContractsSearchResponse = ContractsClientTypes.ContractsSearchResponseVersionsMap['latest']
type ContractsSearchIndividual = ContractsSearchResponse['contracts'][0]
type ShippingProtectionContract = ContractsSearchIndividual & { type: 'shipping_protection' }

export const generateNumber = (min = 500, max = 1000): number => {
  return faker.datatype.number({ min, max })
}

export const generateContract = (overrides: Partial<Contract> = {}): Contract => {
  const { product, ...rest } = overrides

  return {
    createdAt: faker.date.past().getTime(),
    customer: generateCustomer(overrides.customer),
    terms: {
      termsId: faker.datatype.uuid(),
      version: faker.datatype.uuid(),
    },
    isTest: true,
    id: faker.datatype.uuid(),
    plan: {
      planId: '10001-misc-elec-adh-replace-1y',
      purchasePrice: generateNumber(),
    },
    coverage: {
      starts: 1573212210205,
      ends: 1573212210205,
    },
    merchantCustomerId: faker.datatype.uuid(),
    product: generateContractProduct(product),
    purchaseDate: faker.date.recent().getTime(),
    sellerId: faker.datatype.uuid(),
    sellerName: faker.company.name(),
    status: ContractStatus.LIVE,
    transactionDate: faker.date.recent().getTime(),
    transactionId: faker.datatype.uuid(),
    transactionTotal: generateNumber(),
    updatedAt: faker.date.recent().getTime(),
    type: ContractType.PCRS,
    adminSyncStatus: ContractAdminSyncStatus.SYNCED,
    ...rest,
  }
}

export const generateContractGetResponse = (
  overrides: Partial<ContractsGetResponse> = {},
): ContractsGetResponse => {
  const transactionDate = overrides?.transactionDate ?? dayjs().subtract(7, 'month').valueOf()

  return _.merge(
    {
      contractVersion: '1.0',
      adminContractId: faker.datatype.uuid(),
      adminSyncError: 'error',
      adminSyncStatus: ContractAdminSyncStatus.SYNCED,
      cancelledAt: date.formatToMilliseconds('12/10/2019'),
      coverage: {
        starts: 1573212210205,
        ends: 1573212210205,
        ...overrides?.coverage,
      },
      createdAt: faker.date.past().getTime(),
      customer: {
        billingAddress: {
          address1: faker.address.streetAddress(),
          address2: faker.address.secondaryAddress(),
          city: faker.address.city(),
          countryCode: faker.address.countryCode(),
          postalCode: faker.address.zipCode(),
          provinceCode: faker.address.state(),
        },
        email: faker.internet.exampleEmail(),
        name: faker.name.firstName(),
        phone: faker.phone.number('###-###-####'),
        shippingAddress: {
          address1: faker.address.streetAddress(),
          address2: faker.address.secondaryAddress(),
          city: faker.address.city(),
          countryCode: faker.address.countryCode(),
          postalCode: faker.address.zipCode(),
          provinceCode: faker.address.state(),
        },
        type: 'consumer',
      },
      id: faker.datatype.uuid(),
      poNumber: faker.datatype.number().toString(),
      purchasePrice: generateNumber(),
      product: {
        condition: 'new',
        listPrice: {
          amount: generateNumber(),
          currencyCode: 'USD',
        },
        purchasePrice: {
          amount: generateNumber(),
          currencyCode: 'USD',
        },
        referenceId: faker.datatype.uuid(),
        reimbursementAmount: {
          amount: generateNumber(),
          currencyCode: 'USD',
        },
        title: faker.random.word(),
        transactionDate,
        transactionId: faker.datatype.uuid(),
      },
      plan: {
        id: '10001-misc-elec-adh-replace-1y',
        planId: '10001-misc-elec-adh-replace-1y',
        purchasePrice: {
          amount: generateNumber(),
          currencyCode: 'USD',
        },
        expectedPrice: {
          amount: generateNumber(),
          currencyCode: 'USD',
        },
        ...overrides.plan,
      },
      planDetails: {
        category: 'consumer_electronics',
        coverage_includes: 'adh',
        coverage_starts: 'after',
        deductible: 0,
        pcmi_category: 'Replacement',
        pcmi_subcategory: 'General Electronic Devices',
        replacement_type: 'new',
        service_type: 'replace',
        servicer_ids: ['100000001'],
        term_length: 12,
      },
      sellerName: faker.name.firstName(),
      sellerId: faker.datatype.uuid(),
      status: ContractStatus.LIVE,
      terms: {
        termsId: faker.datatype.uuid(),
        version: faker.datatype.uuid(),
      },
      transactionDate,
      transactionId: faker.datatype.uuid(),
      transactionTotal: {
        amount: generateNumber(),
        currencyCode: 'USD',
      },
      type: ContractType.PCRS,
      updatedAt: transactionDate,
    },
    overrides,
  )
}

export const generateContractUpdateResponse = (
  overrides: Partial<ContractsUpdateResponse> = {},
): ContractsUpdateResponse => ({
  contractVersion: '1.0',
  adminContractId: faker.datatype.uuid(),
  adminSyncError: 'error',
  adminSyncStatus: ContractAdminSyncStatus.SYNCED,
  cancelledAt: date.formatToMilliseconds('12/10/2019'),
  coverage: {
    starts: 1573212210205,
    ends: 1573212210205,
    ...overrides.coverage,
  },
  createdAt: faker.date.past().getTime(),
  customer: {
    billingAddress: {
      address1: faker.address.streetAddress(),
      address2: faker.address.secondaryAddress(),
      city: faker.address.city(),
      countryCode: faker.address.countryCode(),
      postalCode: faker.address.zipCode(),
      provinceCode: faker.address.state(),
    },
    email: faker.internet.exampleEmail(),
    name: faker.name.firstName(),
    phone: faker.phone.number('###-###-####'),
    shippingAddress: {
      address1: faker.address.streetAddress(),
      address2: faker.address.secondaryAddress(),
      city: faker.address.city(),
      countryCode: faker.address.countryCode(),
      postalCode: faker.address.zipCode(),
      provinceCode: faker.address.state(),
    },
    type: 'consumer',
  },
  id: faker.datatype.uuid(),
  poNumber: faker.datatype.number().toString(),
  purchasePrice: generateNumber(),
  product: {
    condition: 'new',
    listPrice: {
      amount: generateNumber(),
      currencyCode: 'USD',
    },
    purchasePrice: {
      amount: generateNumber(),
      currencyCode: 'USD',
    },
    referenceId: faker.datatype.uuid(),
    reimbursementAmount: {
      amount: generateNumber(),
      currencyCode: 'USD',
    },
    title: faker.random.word(),
    transactionDate: generateNumber(),
    transactionId: faker.datatype.uuid(),
    imageUrl: faker.image.cats(),
  },
  plan: {
    planId: '10001-misc-elec-adh-replace-1y',
    purchasePrice: {
      amount: generateNumber(),
      currencyCode: 'USD',
    },
    expectedPrice: {
      amount: generateNumber(),
      currencyCode: 'USD',
    },
  },
  planDetails: {
    category: 'consumer_electronics',
    coverage_includes: 'base',
    coverage_starts: 'after',
    deductible: 0,
    pcmi_category: 'Replacement',
    pcmi_subcategory: 'General Electronic Devices',
    replacement_type: 'new',
    service_type: 'replace',
    servicer_ids: ['100000001'],
    term_length: 12,
  },
  sellerName: faker.name.firstName(),
  sellerId: faker.datatype.uuid(),
  status: ContractStatus.LIVE,
  terms: {
    termsId: faker.datatype.uuid(),
    version: faker.datatype.uuid(),
  },
  transactionDate: Number(faker.date.past().toUTCString()),
  transactionId: faker.datatype.uuid(),
  transactionTotal: {
    amount: generateNumber(),
    currencyCode: 'USD',
  },
  type: ContractType.PCRS,
  updatedAt: Number(faker.date.past().toUTCString()),
  ...overrides,
})

export const generateContractV2GetResponse = (
  overrides: Partial<ContractsV2GetResponse> = {},
): ContractsV2GetResponse => ({
  contractVersion: '2.0',
  adminContractId: faker.datatype.uuid(),
  adminSyncError: 'error',
  adminSyncStatus: ContractAdminSyncStatus.SYNCED,
  coverage: {
    starts: 1573212210205,
    ends: 1573212210205,
    ...overrides.coverage,
  },
  createdAt: faker.date.past().getTime(),
  customer: {
    billingAddress: {
      address1: faker.address.streetAddress(),
      address2: faker.address.secondaryAddress(),
      city: faker.address.city(),
      countryCode: faker.address.countryCode(),
      postalCode: faker.address.zipCode(),
      provinceCode: faker.address.state(),
    },
    email: faker.internet.exampleEmail(),
    name: faker.name.firstName(),
    phone: faker.phone.number('###-###-####'),
    shippingAddress: {
      address1: faker.address.streetAddress(),
      address2: faker.address.secondaryAddress(),
      city: faker.address.city(),
      countryCode: faker.address.countryCode(),
      postalCode: faker.address.zipCode(),
      provinceCode: faker.address.state(),
    },
    type: 'consumer',
  },
  id: faker.datatype.uuid(),
  isTest: false,
  listPrice: {
    amount: generateNumber(),
    currencyCode: 'USD',
  },
  plan: {
    category: 'service_contract',
    id: faker.datatype.uuid(),
  },
  product: {
    fulfillmentDate: Number(faker.date.past().toUTCString()),
    listPrice: {
      amount: generateNumber(),
      currencyCode: 'USD',
    },
    manufacturerWarrantyLengthLabor: faker.datatype.number(),
    manufacturerWarrantyLengthParts: faker.datatype.number(),
    purchaseDate: Number(faker.date.past().toUTCString()),
    purchasePrice: {
      amount: generateNumber(),
      currencyCode: 'USD',
    },
    referenceId: faker.datatype.uuid(),
    reimbursementAmount: {
      amount: generateNumber(),
      currencyCode: 'USD',
    },
    name: 'USB',
    transactionId: faker.datatype.uuid(),
    serialNumber: '123456',
  },
  planDetails: {
    category: 'wheels_tire',
    coverage_includes: faker.datatype.string(),
    coverage_starts: faker.datatype.string(),
    deductible: faker.datatype.number(),
    pcmi_category: faker.datatype.string(),
    pcmi_subcategory: 'Battery',
    servicer_ids: ['10001'],
    replacement_type: faker.datatype.string(),
    service_type: 'repair_depot',
    term_length: 12,
  },
  purchaseCurrency: 'USD',
  purchaseDate: Number(faker.date.past().toUTCString()),
  purchasePrice: generateNumber(),
  sellerId: faker.datatype.uuid(),
  sellerName: faker.name.firstName(),
  status: ContractStatus.LIVE,
  transactionId: faker.datatype.uuid(),
  type: ContractType.PCRS,
  updatedAt: Number(faker.date.past().toUTCString()),
  currency: 'USD',
  merchantCustomerId: 'af31ee4a-67bd-404c-bc90',
  transactionDate: Number(faker.date.past().toUTCString()),
  ...overrides,
})

export const generateContractV2UpdateResponse = (
  overrides: Partial<ContractsV2GetResponse> = {},
): ContractsV2GetResponse => ({
  contractVersion: '2.0',
  adminContractId: faker.datatype.uuid(),
  adminSyncError: 'error',
  adminSyncStatus: ContractAdminSyncStatus.SYNCED,
  coverage: {
    starts: 1573212210205,
    ends: 1573212210205,
    ...overrides.coverage,
  },
  createdAt: faker.date.past().getTime(),
  customer: {
    billingAddress: {
      address1: faker.address.streetAddress(),
      address2: faker.address.secondaryAddress(),
      city: faker.address.city(),
      countryCode: faker.address.countryCode(),
      postalCode: faker.address.zipCode(),
      provinceCode: faker.address.state(),
    },
    email: faker.internet.exampleEmail(),
    name: faker.name.firstName(),
    phone: faker.phone.number('###-###-####'),
    shippingAddress: {
      address1: faker.address.streetAddress(),
      address2: faker.address.secondaryAddress(),
      city: faker.address.city(),
      countryCode: faker.address.countryCode(),
      postalCode: faker.address.zipCode(),
      provinceCode: faker.address.state(),
    },
    type: 'consumer',
  },
  id: faker.datatype.uuid(),
  isTest: false,
  listPrice: {
    amount: generateNumber(),
    currencyCode: 'USD',
  },
  plan: {
    category: 'service_contract',
    id: faker.datatype.uuid(),
  },
  product: {
    fulfillmentDate: Number(faker.date.past().toUTCString()),
    listPrice: {
      amount: generateNumber(),
      currencyCode: 'USD',
    },
    manufacturerWarrantyLengthLabor: faker.datatype.number(),
    manufacturerWarrantyLengthParts: faker.datatype.number(),
    purchaseDate: Number(faker.date.past().toUTCString()),
    purchasePrice: {
      amount: generateNumber(),
      currencyCode: 'USD',
    },
    referenceId: faker.datatype.uuid(),
    reimbursementAmount: {
      amount: generateNumber(),
      currencyCode: 'USD',
    },
    name: faker.random.word(),
    transactionId: faker.datatype.uuid(),
    serialNumber: '123456',
  },
  planDetails: {
    category: 'wheels_tire',
    coverage_includes: faker.datatype.string(),
    coverage_starts: faker.datatype.string(),
    deductible: faker.datatype.number(),
    pcmi_category: faker.datatype.string(),
    pcmi_subcategory: 'Battery',
    servicer_ids: ['10001'],
    replacement_type: faker.datatype.string(),
    service_type: 'repair_depot',
    term_length: 12,
  },
  purchaseCurrency: 'USD',
  purchaseDate: Number(faker.date.past().toUTCString()),
  purchasePrice: generateNumber(),
  sellerId: faker.datatype.uuid(),
  sellerName: faker.name.firstName(),
  status: ContractStatus.LIVE,
  transactionId: faker.datatype.uuid(),
  type: ContractType.PCRS,
  updatedAt: Number(faker.date.past().toUTCString()),
  currency: 'USD',
  merchantCustomerId: faker.datatype.uuid(),
  transactionDate: Number(faker.date.past().toUTCString()),
  ...overrides,
})

export const generateContractProduct = (
  overrides: Partial<ContractProduct> = {},
): ContractProduct => ({
  title: faker.commerce.productName(),
  purchaseDate: date.formatToMilliseconds('02/20/2021'),
  purchasePrice: generateNumber(),
  referenceId: faker.datatype.uuid(),
  reimbursementAmount: generateNumber(),
  listPrice: generateNumber(),
  manufacturerWarrantyLengthLabor: generateNumber(),
  manufacturerWarrantyLengthParts: generateNumber(),
  transactionDate: generateNumber(),
  transactionId: faker.datatype.uuid(),
  serialNumber: faker.datatype.uuid(),
  imageUrl: faker.internet.url(),
  name: faker.commerce.productName(),
  ...overrides,
})

export const generateContractTrackingInfo = (
  overrides: Partial<ShippingProtectionContract['trackingInfo'][0]> = {},
): ShippingProtectionContract['trackingInfo'][0] => ({
  trackingId: faker.datatype.uuid(),
  shippingProvider: faker.helpers.arrayElement(['usps', 'ups', 'fedex']),
  shippingState: faker.helpers.arrayElement(['created', 'shipped', 'delivered', 'expired']),
  shipmentId: faker.datatype.uuid(),
  productsShipped: [faker.datatype.uuid()],
  estimatedDeliveryDate: dayjs().valueOf(),
  ...overrides,
})

export const generatePlanContract = (overrides: Partial<PlanContract> = {}): PlanContract =>
  ({
    coverage_starts: 'immediate',
    coverage_includes: 'adh',
    service_type: 'replace',
    replacement_type: 'new',
    deductible: 0,
    term_length: 24,
    category: 'consumer_electronics',
    ...overrides,
  } as PlanContract)

export function generateGetContractResponse(
  overrides: Partial<ContractsGetResponse> = {},
): ContractsGetResponse {
  return {
    contractVersion: '1.0',
    adminContractId: faker.datatype.uuid(),
    adminSyncStatus: ContractAdminSyncStatus.SYNCED,
    coverage: {
      starts: 1573212210205,
      ends: 1573212210205,
      ...overrides.coverage,
    },
    createdAt: faker.date.past().getTime(),
    customer: {
      email: faker.internet.exampleEmail(),
      name: faker.name.firstName(),
      billingAddress: {
        address1: '123 45th St.',
        city: 'San Francisco',
        countryCode: 'US',
        postalCode: '94110',
        provinceCode: 'CA',
      },
      phone: faker.phone.number('###-###-####'),
      ...overrides.customer,
    },
    id: faker.datatype.uuid(),
    purchasePrice: 99999,
    product: {
      imageUrl: faker.image.cats(),
      listPrice: {
        amount: 99999,
        currencyCode: 'USD',
        formattedAmount: '$999.99',
      },
      purchasePrice: {
        amount: 99999,
        currencyCode: 'USD',
        formattedAmount: '$999.99',
      },
      referenceId: faker.datatype.uuid(),
      reimbursementAmount: {
        amount: 0,
        currencyCode: 'USD',
        formattedAmount: '$0.00',
      },
      serialNumber: faker.random.alphaNumeric(6),
      title: 'Sample Product',
      transactionDate: faker.date.recent().getTime(),
      transactionId: faker.datatype.uuid(),
    },
    plan: {
      planId: '10001-misc-elec-adh-replace-1y',
      purchasePrice: {
        amount: 4999,
        currencyCode: 'USD',
        formattedAmount: '$49.99',
      },
    },
    planDetails: {
      category: 'consumer_electronics',
      coverage_includes: 'adh',
      coverage_starts: 'immediate',
      deductible: 0,
      pcmi_category: 'Mobile Electronics',
      pcmi_subcategory: 'General Electronic Devices',
      servicer_ids: ['10001', '10002'],
      replacement_type: 'new',
      service_type: 'replace',
      term_length: 12,
    },
    sellerName: 'Store Name',
    sellerId: faker.datatype.uuid(),
    status: ContractStatus.LIVE,
    terms: {
      termsId: faker.datatype.uuid(),
      version: '3',
    },
    transactionDate: faker.date.recent().getTime(),
    transactionId: faker.datatype.uuid(),
    transactionTotal: {
      amount: 104998,
      currencyCode: 'USD',
      formattedAmount: '$1049.98',
    },
    type: ContractType.PCRS,
    updatedAt: faker.date.recent().getTime(),
    ...overrides,
  }
}

export const generateContractAuditItem = (contract: Contract): AuditItem<Contract> => ({
  resourceType: 'CONTRACT',
  item: contract,
  actor: 'actor',
  reason: 'some reason',
  method: 'some method',
  eventTime: new Date().getTime(),
  changes: [{ kind: 'E', lhs: 'Jon', rhs: 'Jonathan', path: ['customer', 'name'] }],
})

export const generateContractUpdate = (): ContractsUpdateRequest => {
  return {
    currency: 'USD',
    customer: {
      billingAddress: {
        address1: faker.address.streetAddress(),
        address2: '',
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        provinceCode: faker.address.state(),
      },
      email: faker.internet.exampleEmail(),
      phone: faker.phone.number('###-###-####'),
      name: faker.name.firstName(),
      shippingAddress: {
        address1: faker.address.streetAddress(),
        address2: faker.address.secondaryAddress(),
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        provinceCode: faker.address.state(),
      },
    },
    merchantCustomerId: faker.datatype.uuid(),
    plan: {
      planId: faker.datatype.uuid(),
      planSku: '852369741',
      purchasePrice: 1599,
      expectedPrice: 1,
      subcategory: 'Jewelry',
    },
    poNumber: '55555',
    product: {
      imageUrl: faker.internet.url(),
      listPrice: 5999,
      manufacturerWarrantyLengthLabor: 0,
      manufacturerWarrantyLengthParts: 0,
      purchasePrice: 5999,
      referenceId: '3333',
      reimbursementAmount: 3500,
      serialNumber: '888888',
      title: faker.commerce.productName(),
      transactionDate: date.formatToMilliseconds('05/10/2019'),
    },
    sellerId: faker.datatype.uuid(),
    sellerName: faker.random.words(),
    status: ContractStatus.LIVE,
    store: {
      adminDealerNumber: '642587319',
      adminLocationNumber: '46179238',
      adminManufacturer: faker.random.words(),
    },
    source: {
      agentId: '555555',
      channel: 'web',
      integratorId: '555555',
      locationId: faker.datatype.uuid(),
      platform: faker.lorem.word(),
    },
    terms: {
      termsId: faker.datatype.uuid(),
      version: faker.lorem.word(),
    },
    transactionDate: date.formatToMilliseconds('05/10/2019'),
    transactionId: faker.datatype.uuid(),
    transactionTotal: 7599,
  }
}

export const generateContractsV2Update = (): Partial<Contract> => {
  return {
    coverage: {
      starts: date.formatToMilliseconds('05/10/2019'),
      ends: date.formatToMilliseconds('05/10/2021'),
    },
    currency: 'USD',
    customer: {
      billingAddress: {
        address1: faker.address.streetAddress(),
        address2: '',
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        provinceCode: faker.address.state(),
      },
      email: faker.internet.exampleEmail(),
      phone: faker.phone.number('###-###-####'),
      name: faker.name.firstName(),
      shippingAddress: {
        address1: faker.address.streetAddress(),
        address2: faker.address.secondaryAddress(),
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        provinceCode: faker.address.state(),
      },
      type: 'consumer',
    },
    merchantCustomerId: faker.datatype.uuid(),
    listPrice: 2599,
    plan: {
      planId: faker.datatype.uuid(),
      category: 'service_contract',
      planSku: '987654321',
      termsVersion: '123',
      termsId: faker.datatype.uuid(),
      version: 3,
    },
    purchaseDate: date.formatToMilliseconds('02/20/2021'),
    purchasePrice: 1299,
    product: {
      referenceId: faker.datatype.uuid(),
      serialNumber: '26479831',
      purchasePrice: 3499,
      title: faker.random.words(),
      listPrice: 4599,
      manufacturerWarrantyLengthLabor: faker.datatype.number(),
      manufacturerWarrantyLengthParts: faker.datatype.number(),
      purchaseDate: date.formatToMilliseconds('02/20/2021'),
      fulfillmentDate: date.formatToMilliseconds('02/20/2021'),
      transactionDate: date.formatToMilliseconds('02/20/2021'),
      transactionId: faker.datatype.uuid(),
      reimbursementAmount: faker.datatype.number(),
    },
    sellerId: faker.datatype.uuid(),
    status: ContractStatus.LIVE,
    transactionId: faker.datatype.uuid(),
  }
}

export const generateContracts20220201Update = (): Contracts20220201UpdateRequest => {
  return {
    currency: 'USD',
    customer: {
      billingAddress: {
        address1: faker.address.streetAddress(),
        address2: '',
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        provinceCode: faker.address.state(),
      },
      email: faker.internet.exampleEmail(),
      phone: faker.phone.number('###-###-####'),
      name: faker.name.firstName(),
      shippingAddress: {
        address1: faker.address.streetAddress(),
        address2: faker.address.secondaryAddress(),
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        provinceCode: faker.address.state(),
      },
    },
    merchantCustomerId: faker.datatype.uuid(),
    listPrice: 2599,
    plan: {
      id: 'asdf',
      skuId: '987654321',
      termsVersion: '123',
      termsId: faker.datatype.uuid(),
      version: 3,
    },
    purchaseDate: date.formatToMilliseconds('02/20/2021'),
    purchasePrice: 1299,
    product: {
      referenceId: faker.datatype.uuid(),
      serialNumber: '26479831',
      purchasePrice: 3499,
      name: faker.random.words(),
      listPrice: 4599,
      manufacturerWarrantyLengthLabor: faker.datatype.number(),
      manufacturerWarrantyLengthParts: faker.datatype.number(),
      purchaseDate: date.formatToMilliseconds('02/20/2021'),
    },
    isTest: true,
    sellerId: faker.datatype.uuid(),
    status: ContractStatus.LIVE,
    transactionId: faker.datatype.uuid(),
    limitOfLiabilityAmount: faker.datatype.number(),
    limitOfLiabilityAmountType: 'product_purchase_price',
  }
}

export const generateContract20220201GetResponse = (
  overrides: Partial<Contract20220201GetResponse> = {},
): Contract20220201GetResponse => {
  const { coverage, customer, plan, product, ...rest } = overrides
  return {
    accountId: faker.datatype.uuid(),
    adminContractId: faker.datatype.uuid(),
    adminSyncError: 'error',
    adminSyncStatus: ContractAdminSyncStatus.SYNCED,
    cancelledAt: date.formatToMilliseconds('12/10/2019'),
    createdAt: faker.date.past().getTime(),
    coverage: {
      starts: 1573212210205,
      ends: 1573212210205,
      ...coverage,
    },
    customer: {
      billingAddress: {
        address1: faker.address.streetAddress(),
        address2: faker.address.secondaryAddress(),
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        provinceCode: faker.address.state(),
      },
      email: faker.internet.exampleEmail(),
      name: faker.name.firstName(),
      phone: faker.phone.number('###-###-####'),
      shippingAddress: {
        address1: faker.address.streetAddress(),
        address2: faker.address.secondaryAddress(),
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        postalCode: faker.address.zipCode(),
        provinceCode: faker.address.state(),
      },
      type: 'consumer',
      ...customer,
    },
    formId: faker.datatype.uuid(),
    id: faker.datatype.uuid(),
    isTest: false,
    listPrice: generateNumber(),
    orderId: faker.datatype.uuid(),
    plan: {
      category: 'service_contract',
      id: '10001-misc-elec-adh-replace-1y',
      ...plan,
    },
    product: {
      fulfillmentDate: date.formatToMilliseconds('02/20/2021'),
      listPrice: generateNumber(),
      manufacturerWarrantyLengthLabor: faker.datatype.number(),
      manufacturerWarrantyLengthParts: faker.datatype.number(),
      purchaseDate: faker.date.past().getTime(),
      purchasePrice: generateNumber(),
      referenceId: faker.datatype.uuid(),
      reimbursementAmount: generateNumber(),
      name: faker.commerce.productName(),
      transactionId: faker.datatype.uuid(),
      ...product,
    },
    purchaseCurrency: 'USD',
    purchaseDate: date.formatToMilliseconds('02/20/2021'),
    purchasePrice: generateNumber(),
    sellerId: faker.datatype.uuid(),
    sellerName: faker.name.firstName(),
    status: ContractStatus.LIVE,
    transactionId: faker.datatype.uuid(),
    type: ContractType.PCRS,
    updatedAt: faker.date.past().getTime(),
    ...rest,
  }
}

export function generateContractCategory(
  overrides: Partial<CategoryContract> = {},
): CategoryContract {
  const { customer, plan, productIds, ...rest } = overrides

  const defaults = {
    accountId: faker.datatype.uuid(),
    createdAt: faker.date.recent().getTime(),
    customer: generateCustomer(customer as Partial<ContractCustomer>),
    id: faker.datatype.uuid(),
    lineItemId: faker.datatype.uuid(),
    orderId: faker.datatype.uuid(),
    plan: {
      category: 'service_contract',
      id: '10001-auto-part-base-replace-1y',
      version: 1,
      termsId: 'ALLTESTPROD',
      termsVersion: '1',
      ...plan,
    },
    refundAmount: {
      amount: faker.datatype.number({ min: 500, max: 1000 }),
      currencyCode: 'USD',
    },
    productIds: [faker.datatype.uuid()],
    productsList: [generateContractCoveredProduct()],
    purchaseCurrency: 'USD',
    purchasePrice: faker.datatype.number({ min: 300, max: 800 }),
    sellerId: faker.datatype.uuid(),
    sellerName: faker.company.name(),
    status: 'live',
    purchaseDate: faker.date.recent().getTime(),
    transactionId: faker.random.alphaNumeric(10),
    type: 'category',
    updatedAt: faker.date.recent().getTime(),
    ...rest,
  }

  return defaultsDeep(defaults, overrides)
}

export const generateContractCoveredProduct = (
  overrides: Partial<ContractCoveredProduct> = {},
): ContractCoveredProduct => {
  return {
    listPrice: generateNumber(),
    purchasePrice: generateNumber(),
    quantity: generateNumber(),
    referenceId: faker.datatype.uuid(),
    title: faker.random.word(),
    ...overrides,
  }
}

export const generateShippingContract = (
  overrides: Partial<Contract> = {},
  withUnshipped?: number,
): Contract => {
  const trackingInfo = [generateContractTrackingInfo(), generateContractTrackingInfo()]
  const productsList = [
    ...trackingInfo.map((info) =>
      generateContractCoveredProduct({ referenceId: info.productsShipped[0] }),
    ),
  ]

  if (withUnshipped) {
    productsList.push(...new Array(withUnshipped).fill(null).map(generateContractCoveredProduct))
  }

  const contract = generateContract({
    type: ContractType.SHIPPING_PROTECTION,
    poNumber: faker.datatype.uuid(),
  })
  return {
    ...contract,
    plan: {
      ...contract.plan,
      id: 'shipping-protection-plan-id',
    },
    purchasePrice: generateNumber(),
    productsList,
    trackingInfo: trackingInfo as Contract['trackingInfo'],
    ...overrides,
  }
}

export const generateShippingContractV2GetResponse = (
  overrides: Partial<ContractsV2GetResponse> = {},
  withUnshipped?: number,
): ContractsV2GetResponse => {
  const trackingInfo = [generateContractTrackingInfo(), generateContractTrackingInfo()]
  const productsList = [
    ...trackingInfo.map((info) =>
      generateContractCoveredProduct({ referenceId: info.productsShipped[0] }),
    ),
  ]

  if (withUnshipped) {
    productsList.push(...new Array(withUnshipped).fill(null).map(generateContractCoveredProduct))
  }
  const contract = generateContract({
    type: ContractType.SHIPPING_PROTECTION,
    poNumber: faker.datatype.uuid(),
  })
  return {
    ...contract,
    plan: {
      ...contract.plan,
      id: 'shipping-protection-plan-id',
    },
    purchasePrice: generateNumber(),
    productsList,
    trackingInfo: trackingInfo as ContractsV2GetResponse['trackingInfo'],
    contractVersion: '2.0',
    currency: 'USD',
    isTest: false,
    type: 'shipping_protection' as ContractType,
    listPrice: {
      amount: faker.datatype.number(),
    },
    product: {
      fulfillmentDate: Number(faker.date.past().toUTCString()),
      listPrice: {
        amount: generateNumber(),
        currencyCode: 'USD',
      },
      manufacturerWarrantyLengthLabor: faker.datatype.number(),
      manufacturerWarrantyLengthParts: faker.datatype.number(),
      purchaseDate: Number(faker.date.past().toUTCString()),
      purchasePrice: {
        amount: generateNumber(),
        currencyCode: 'USD',
      },
      referenceId: faker.datatype.uuid(),
      reimbursementAmount: {
        amount: generateNumber(),
        currencyCode: 'USD',
      },
      name: 'USB',
      transactionId: faker.datatype.uuid(),
      serialNumber: '123456',
    },
    planDetails: {
      category: 'wheels_tire',
      coverage_includes: faker.datatype.string(),
      coverage_starts: faker.datatype.string(),
      deductible: faker.datatype.number(),
      pcmi_category: faker.datatype.string(),
      pcmi_subcategory: 'Battery',
      servicer_ids: ['10001'],
      replacement_type: faker.datatype.string(),
      service_type: 'repair_depot',
      term_length: 12,
    },
    purchaseCurrency: 'USD',
    transactionDate: Number(faker.date.past().toUTCString()),
    purchaseDate: Number(faker.date.past().toUTCString()),
    merchantCustomerId: 'af31ee4a-67bd-404c-bc90',
    ...overrides,
  }
}

export const generateShippingContractLatest = (
  overrides: Partial<ShippingProtectionContract> = {},
  withUnshipped?: number,
): ShippingProtectionContract => {
  const trackingInfo = overrides.trackingInfo || [
    generateContractTrackingInfo(),
    generateContractTrackingInfo(),
  ]
  const productsList = trackingInfo
    .map((info) =>
      info.productsShipped.map((productId) =>
        generateContractCoveredProduct({ referenceId: productId }),
      ),
    )
    .reduce((acc, curr) => {
      return [...acc, ...curr]
    }, [])

  if (withUnshipped) {
    productsList.push(...new Array(withUnshipped).fill(null).map(generateContractCoveredProduct))
  }

  const contract = generateContract({
    type: ContractType.SHIPPING_PROTECTION,
    poNumber: faker.datatype.uuid(),
  })
  return {
    ...contract,
    plan: {
      ...contract.plan,
      id: 'shipping-protection-plan-id',
    } as ShippingProtectionContract['plan'],
    purchasePrice: generateNumber(),
    productsList,
    trackingInfo,
    isTest: false,
    type: 'shipping_protection',
    purchaseCurrency: 'USD',
    purchaseDate: dayjs().subtract(1, 'week').valueOf(),
    merchantCustomerId: 'af31ee4a-67bd-404c-bc90',
    ...overrides,
  } as ShippingProtectionContract
}

export function generateContractCategoryProduct(
  overrides: Partial<CategoryProduct> = {},
): CategoryProduct {
  const { coverage, ...rest } = overrides

  return {
    contractId: faker.datatype.uuid(),
    email: faker.internet.email().toLowerCase(),
    fulfillmentDate: faker.date.recent().getTime(),
    limitOfLiability: generateNumber(),
    lineItemId: faker.datatype.uuid(),
    listPrice: generateNumber(),
    manufacturerWarrantyLengthLabor: faker.datatype.number({ min: 6, max: 12 }),
    manufacturerWarrantyLengthParts: faker.datatype.number({ min: 6, max: 12 }),
    purchasePrice: generateNumber(),
    referenceId: faker.datatype.uuid(),
    title: faker.commerce.productName(),
    transactionDate: faker.date.recent().getTime(),
    transactionId: faker.random.alphaNumeric(10),
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    status: 'live',
    coverage: {
      starts: coverage?.starts || faker.date.recent().getTime(),
      ends: coverage?.ends || faker.date.recent().getTime(),
    },
    ...rest,
  }
}

export const generateContractNote = (overrides: Partial<ContractNote> = {}): ContractNote => ({
  id: faker.datatype.uuid(),
  storeId: faker.datatype.uuid(),
  storeName: faker.company.name(),
  contractId: faker.datatype.uuid(),
  customerName: faker.name.fullName(),
  customerEmail: faker.internet.email(),
  type: 'basic',
  contractStatus: ContractStatus.LIVE,
  category: 'Customer Feedback',
  categoryDescription: faker.lorem.sentence(),
  text: faker.lorem.paragraphs(2),
  createdAt: faker.date.past().getTime(),
  createdBy: faker.internet.email(),
  updatedAt: faker.date.past().getTime(),
  updatedBy: faker.internet.email(),
  ...overrides,
})

export const generateContractNotes = (
  n: number,
  overrides: Partial<ContractNote> = {},
): ContractNote[] => {
  const notes: ContractNote[] = []
  for (let i = 0; i < n; i++) {
    notes.push(generateContractNote(overrides))
  }
  return notes
}
