import React from 'react'
import type { Plan } from '@helloextend/extend-api-client'
import type { ProductServicingSettingsModalType, ProductShippingProtectionModalType } from './types'

export const getProductSPModalDescription = (
  modalType: ProductShippingProtectionModalType,
  isEnabled: boolean,
): React.ReactNode => {
  const isShippable = modalType === 'shippable'
  let text: React.ReactNode

  if (isShippable && isEnabled) {
    text = (
      <>
        <p>
          By disabling this setting, you are saying this product is a non-shippable item, i.e. a
          digital good (like software), a service (like tech support), or an item that is
          hand-delivered (like a Peloton). This product would NOT be covered by Shipping Protection.
        </p>
        <p>Are you sure you want to disable?</p>
      </>
    )
  } else if (isShippable && !isEnabled) {
    text = (
      <>
        <p>
          By enabling this setting, you are saying this is a product a merchant physically ships to
          their customers, so the product would be covered by Shipping Protection.
        </p>
        <p>Are you sure you want to enable?</p>
      </>
    )
  } else if (!isShippable && isEnabled) {
    text = (
      <>
        <p>
          By disabling this setting you are marking a shippable product as ineligible for Shipping
          Protection. This could create a confusing user experience because the v1 Shipping
          Protection program does not support mixed carts of eligible and ineligible products.
          Please check with the Shipping Protection product owner with any questions.
        </p>
        <p>Are you sure you want to disable this product from Shipping Protection?</p>
      </>
    )
  } else if (!isShippable && !isEnabled) {
    text = (
      <>
        <p>
          By enabling this setting you are marking a shippable product as eligible for Shipping
          Protection. Most product categories are eligible for Shipping Protection.
        </p>
        <p>Are you sure you want to enable this product for Shipping Protection?</p>
      </>
    )
  }
  return text
}

export const getProductServicingSettingsModalDescription = (
  modalType: ProductServicingSettingsModalType,
  isEnabled: boolean,
): React.ReactNode => {
  let text: React.ReactNode
  const isProductReplacement = modalType === 'partialReplacementEligible'

  if (isProductReplacement && !isEnabled) {
    text = (
      <>
        <p>
          Enabling partial replacement will allow claims for this product to be fulfilled even when
          only the broken part or item is replaced.
        </p>
        <p> Are you sure you want to enable partial replacement?</p>
      </>
    )
  } else if (isProductReplacement && isEnabled) {
    text = (
      <>
        <p>
          By disabling partial replacement, any future approved claim will receive a full product
          replacement.
        </p>
        <p>Are you sure you want to disable partial replacement?</p>
      </>
    )
  } else if (!isProductReplacement && !isEnabled) {
    text = (
      <>
        <p>Enabling product return will mean that customers have to return defective products.</p>
        <p>Are you sure you want to enable product returns?</p>
      </>
    )
  } else if (!isProductReplacement && isEnabled) {
    text = (
      <>
        <p>
          Disabling product return will mean that customers do not have to return their defective
          products.
        </p>
        <p>Are you sure you want to disable product returns?</p>
      </>
    )
  }
  return text
}

export const arePlanWarrantyCategoriesValid = (
  plans: Plan[],
  toastError: (message: string) => void,
): boolean => {
  const planWarrantyCategorySet = plans.reduce((warrantyCategorySet, plan) => {
    return warrantyCategorySet.add(plan.contract.category)
  }, new Set<string>())

  if (planWarrantyCategorySet.size > 1) {
    toastError(
      `All plans must share the same adjudication category. Found categories: ${Array.from(
        planWarrantyCategorySet,
      ).join(', ')}`,
    )
    return false
  }
  return true
}
