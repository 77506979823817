import type { SortingState } from '@extend/zen'
import { Add, DataTable } from '@extend/zen'
import type { ClaimsSearchClaim } from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router-dom'
import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import { useNewClaimsSearch } from '../../../hooks/use-new-claims-search'
import { getFilterDefs, formatClaimItems, getTableColumns } from './new-table-config'
import { useExtendAuth } from '@extend/package-okta-login'
import { getUserEmailFromToken } from '../../../lib/jwt'
import type { ClaimsUser } from '@helloextend/extend-api-client'

interface AssignedToMeTableProps {
  users?: ClaimsUser[]
}

export const AssignedToMeTable: FC<AssignedToMeTableProps> = ({ users }) => {
  const { token: accessToken } = useExtendAuth()
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({ type: false })
  const email = getUserEmailFromToken(accessToken) ?? ''
  const filterDefs = useMemo(() => getFilterDefs(), [])

  const matchingUser = useMemo(() => {
    return (users ?? []).find((user) => user.email === email)
  }, [email, users])

  const { data: claims, isLoading } = useNewClaimsSearch(
    {
      assignedUserId: matchingUser?.id ?? '',
    },
    !matchingUser,
  )
  const [sorting, setSorting] = useState<SortingState>([{ id: 'dateReported', desc: true }])
  const { push } = useHistory()

  const handleFileClaim = (): void => {
    push('/admin/contracts')
  }

  const handleRowClick = (row: ClaimsSearchClaim): void => {
    window.open(`/admin/claims/${row.id}`, '_blank', 'noreferrer')
  }

  if (!matchingUser) {
    return (
      <DataTable
        data-cy="assigned-to-me-table"
        data={[]}
        isLoading={false}
        sorting={sorting}
        onSortingChange={setSorting}
        filterDefs={filterDefs}
        stateViews={{
          noData: {
            heading: 'No claims assigned to you',
            description: 'Check the Action Required tab to get new claims',
          },
        }}
        columns={getTableColumns()}
        pageSizeOptions={[50, 100]}
        getRowId={(row) => row.id}
        getRowActions={(row) => [
          {
            onClick: () => handleRowClick(row),
            text: 'View',
            emphasis: 'low',
          },
        ]}
        getTableActions={() => [
          {
            emphasis: 'medium',
            text: 'File a new claim',
            onClick: handleFileClaim,
            icon: Add,
          },
        ]}
      />
    )
  }

  return (
    <DataTable
      data-cy="assigned-to-me-table"
      data={claims.map(formatClaimItems)}
      isLoading={isLoading}
      sorting={sorting}
      onSortingChange={setSorting}
      filterDefs={filterDefs}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      columns={getTableColumns()}
      pageSizeOptions={[50, 100]}
      getRowId={(row) => row.id}
      stateViews={{
        noData: {
          heading: 'No claims assigned to you',
          description: 'Check the Action Required tab to get new claims',
        },
      }}
      getRowActions={(row) => [
        {
          onClick: () => handleRowClick(row),
          text: 'View',
          emphasis: 'low',
        },
      ]}
      getTableActions={() => [
        {
          emphasis: 'medium',
          text: 'File a new claim',
          onClick: handleFileClaim,
          icon: Add,
        },
      ]}
    />
  )
}
