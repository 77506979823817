import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  AuthFinishRequest,
  AuthFinishResponse,
  AuthStartRequest,
  AuthStartResponse,
  ConsumerProfileResponse,
  ConsumerProfileUpdateRequest,
  MyExtendApiVersion,
  MyExtendRequestWithApiVersion,
} from './types'
import { X_EXTEND_ACCESS_TOKEN, baseQuery } from '../base-query'

export const myExtendApi = createApi({
  reducerPath: 'MyExtend',
  baseQuery,
  tagTypes: ['myextend'],
  endpoints: (build) => ({
    finishPopaChallenge: build.mutation<
      AuthFinishResponse,
      MyExtendRequestWithApiVersion<AuthFinishRequest>
    >({
      query: ({ body, apiVersion = 'latest' }) => ({
        url: '/myextend/auth/finish',
        method: 'POST',
        body,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
      invalidatesTags: ['myextend'],
    }),
    startPopaChallenge: build.mutation<
      AuthStartResponse,
      MyExtendRequestWithApiVersion<AuthStartRequest>
    >({
      query: ({ body, apiVersion = 'latest' }) => ({
        url: '/myextend/auth/start',
        method: 'POST',
        body,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
      invalidatesTags: ['myextend'],
    }),
    searchConsumerProfile: build.query<ConsumerProfileResponse, MyExtendApiVersion>({
      query: ({ apiVersion = 'latest', accessToken }) => ({
        url: '/myextend/profiles',
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
          [X_EXTEND_ACCESS_TOKEN]: accessToken,
        },
      }),
    }),
    updateConsumerProfile: build.mutation<ConsumerProfileResponse, ConsumerProfileUpdateRequest>({
      query: ({ apiVersion = 'latest', accessToken, body, id }) => ({
        url: `/myextend/profiles/${id}`,
        method: 'PUT',
        body,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
          [X_EXTEND_ACCESS_TOKEN]: accessToken,
        },
      }),
    }),
  }),
})

export const {
  useFinishPopaChallengeMutation,
  useStartPopaChallengeMutation,
  useLazySearchConsumerProfileQuery,
  useUpdateConsumerProfileMutation,
} = myExtendApi
