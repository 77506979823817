import React from 'react'
import type { BadgeDescriptionItem } from '@helloextend/merchants-ui'
import { Badge, Loading } from '@extend/zen'
import type { ColumnDef, CellContext, FilterDef, BadgeProps } from '@extend/zen'
import { date as dateHelper } from '@extend/client-helpers'
import type {
  ConversationResponse,
  ConversationStatus,
  ThreadStatus,
} from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { formatDateWithTimezone } from '../../../../utils/date-formatting'
import { BadgeDescriptions } from '../components/badge-descriptions'

const { formatToMilliseconds } = dateHelper

export const getBadgeText = (status: ConversationStatus): string => {
  switch (status) {
    case 'draft':
      return 'Draft'
    case 'published':
      return 'Published'
    case 'archived':
      return 'Archived'
    default:
      return 'Unknown'
  }
}

export const getBadgeColor = (status: ConversationStatus | ThreadStatus): BadgeProps['color'] => {
  switch (status) {
    case 'published':
      return 'green'
    case 'archived':
      return 'neutral'
    default:
      return 'yellow'
  }
}

const conversationStatusDetails: Record<ConversationStatus, BadgeDescriptionItem> = {
  draft: {
    badgeText: 'Draft',
    description:
      'Draft conversations can be changed without impacting any conversation configurations.',
    badgeColor: 'yellow',
  },
  published: {
    badgeText: 'Published',
    description: 'Published conversations can be assigned to conversation configurations.',
    badgeColor: 'green',
  },
  archived: {
    badgeText: 'Archived',
    description:
      'Archived conversations cannot be utilized to create any conversation configuration.',
    badgeColor: 'neutral',
  },
}

export const conversationColumns: Array<ColumnDef<ConversationResponse>> = [
  {
    id: 'title',
    label: 'Name',
    maxWidth: 400,
    renderCell: (data: CellContext<ConversationResponse, string>) => data.getValue(),
    search: 'explicit',
  },
  {
    id: 'description',
    label: 'Description',
    maxWidth: 200,
    renderCell: (data: CellContext<ConversationResponse, string>) => data.getValue(),
    search: 'explicit',
  },
  {
    id: 'status',
    label: 'Status',
    information: <BadgeDescriptions descriptions={Object.values(conversationStatusDetails)} />,
    renderCell: (data: CellContext<ConversationResponse, string>) => {
      if (data.getValue() === 'restore') {
        return (
          <LoadingWrapper>
            <Loading heightPx={15} dotsSize="xs" />
          </LoadingWrapper>
        )
      }
      const status = data.getValue() as ConversationStatus
      const badgeColor = getBadgeColor(status)
      return <Badge color={badgeColor} emphasis="medium" text={getBadgeText(status)} />
    },
  },
  {
    id: 'updatedAt',
    label: 'Last Update',
    renderCell: (data: CellContext<ConversationResponse, string>) =>
      data.getValue() &&
      `${formatDateWithTimezone(formatToMilliseconds(data.getValue()))} by ${
        data.row.original.editedBy
      }`,
    isSortable: true,
  },
]

export const filterDefs: FilterDef[] = [
  {
    type: 'group',
    label: 'Filter',
    filterDefs: [
      {
        id: 'status',
        label: 'Status',
        type: 'select',
        options: [
          { display: 'Draft', value: 'draft' },
          { display: 'Published', value: 'published' },
          { display: 'Archived', value: 'archived' },
        ],
        isMultiSelect: true,
      },
    ],
  },
]

const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
})
