import styled from '@emotion/styled'
import { Modal, ModalController, Paragraph } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'

type ServicingModalProps = {
  isEnable: boolean
  isOpen: boolean
  optionName: string
  enableMessage: string
  disableMessage: string
  isProcessing?: boolean
  onClickCancel: () => void
  onClickSubmit: () => void
}

const ServicingModal: FC<ServicingModalProps> = ({
  isOpen,
  isEnable,
  optionName,
  enableMessage,
  disableMessage,
  isProcessing = false,
  onClickSubmit,
  onClickCancel,
}) => {
  return (
    <ModalController isOpen={isOpen} data-cy="servicing-modal">
      <Modal
        heading={`${isEnable ? 'Enable' : 'Disable'} ${optionName}`}
        primaryButtonProps={{
          'data-cy': 'servicing-modal-submit',
          onClick: onClickSubmit,
          text: `${isEnable ? 'Enable' : 'Disable'}`,
          isProcessing,
        }}
        secondaryButtonProps={{
          'data-cy': 'servicing-modal-cancel',
          onClick: onClickCancel,
          text: 'Cancel',
        }}
        onDismissRequest={onClickCancel}
      >
        <>
          <ParagraphWithBottomMargin>
            {isEnable ? enableMessage : disableMessage}
          </ParagraphWithBottomMargin>
          <Paragraph>
            Are you sure you want to {isEnable ? 'enable' : 'disable'} {optionName.toLowerCase()}?
          </Paragraph>
        </>
      </Modal>
    </ModalController>
  )
}

const ParagraphWithBottomMargin = styled(Paragraph)({
  marginBottom: '16px',
})

export type { ServicingModalProps }
export { ServicingModal }
