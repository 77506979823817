import type { BadgeColor } from '@extend/zen'
import type { Claim, ClaimStatus } from '@helloextend/extend-api-client'
import type { ClaimClosedResolution } from '@helloextend/extend-api-client/src/models/claim'
import type { ClaimsSearchClaim } from '@helloextend/extend-api-rtk-query'

export interface BadgeDescriptionItem {
  badgeText: string
  badgeColor: BadgeColor
  description: string
  badgeDetails?: string
}

export const claimStatusDetails: Record<ClaimStatus, BadgeDescriptionItem> = {
  approved: {
    badgeText: 'Approved',
    description:
      "The customer's reported issue is approved for replacement or repair and is currently in progress.",
    badgeColor: 'green',
  },
  fulfilled: {
    badgeText: 'Fulfilled',
    description: 'The customer has received their replacement or repair.',
    badgeColor: 'neutral',
  },
  closed: {
    badgeText: 'Closed',
    description: "The customer's reported issue has been closed with no further action required.",
    badgeColor: 'neutral',
  },
  denied: {
    badgeText: 'Denied',
    description: "The customer's issue is not eligible based on the limit of liability.",
    badgeColor: 'red',
  },
  review: {
    badgeText: 'In Review',
    description: "The customer's reported issue is being reviewed.",
    badgeColor: 'yellow',
  },
  pending_adjudication: {
    badgeText: 'Pending Adjudication',
    description: 'The claim is pending an adjudication decision. ',
    badgeColor: 'yellow',
  },
}

const closedResolutionMap: Record<ClaimClosedResolution, string> = {
  no_service: 'No service required',
  defective_product_not_shipped: 'Defective product not shipped',
}

export const validationErrorMap: Record<string, string> = {
  invalid_contract_status: 'Contract has an invalid status',
  occurred_before_purchase: 'Incident occurred before the purchase date',
  occurred_before_coverage: 'Incident occurred before the coverage period',
  occurred_future_date: 'Incident occurred on a future date',
  occurred_after_coverage: 'Incident occurred after the coverage period',
  reported_after_coverage: 'Claim reported after the coverage period',
  service_type_invalid: 'Service type is invalid',
  occurred_outside_coverage: 'Occurred outside coverage',
  failure_not_covered: 'Failure not covered',
  police_report_not_provided: 'Police report not provided',
  images_not_provided: 'Images not provided',
  under_manufacturer_warranty: 'Under manufacturer warranty',
  fraud_risk: 'Claim has been flagged as high fraud risk.',
  compliance_review: 'Further review is required to meet compliance.',
  misconfigured_ruleset: 'The ruleset is misconfigured',
  no_coverage_remaining: 'There is no coverage remaining for the contract.',
}

export const getStatusBadgeDetails = (claim: Claim): BadgeDescriptionItem | null => {
  const { status, closedMetaData, reopened, photoRequirements } = claim
  const badgeData = claimStatusDetails[status]

  if (!badgeData) return null

  if (status === 'pending_adjudication' && photoRequirements?.length) {
    return {
      ...badgeData,
      badgeDetails: 'Photos Required',
    }
  }

  if (status === 'closed' && closedMetaData) {
    return {
      ...badgeData,
      badgeDetails: closedResolutionMap[closedMetaData.resolution],
    }
  }

  if (status === 'approved' && reopened) {
    return {
      ...badgeData,
      badgeText: 'Reopened',
    }
  }

  if (
    status === 'denied' &&
    validationErrorMap[claim.validationError ?? claim.denialReason ?? '']
  ) {
    return {
      ...badgeData,
      badgeDetails: validationErrorMap[claim.validationError ?? claim.denialReason ?? ''],
    }
  }

  return badgeData
}

export const getStatusBadgeDetailsClaimsSearchClaim = (
  claim: ClaimsSearchClaim,
): BadgeDescriptionItem | null => {
  const { status, closedMetaData, reopened, photoRequirements } = claim
  const badgeData = claimStatusDetails[status]

  if (!badgeData) return null

  if (status === 'pending_adjudication' && photoRequirements?.length) {
    return {
      ...badgeData,
      badgeDetails: 'Photos Required',
    }
  }

  if (status === 'closed' && closedMetaData) {
    return {
      ...badgeData,
      badgeDetails: closedResolutionMap[closedMetaData.resolution],
    }
  }

  if (status === 'approved' && reopened) {
    return {
      ...badgeData,
      badgeText: 'Reopened',
    }
  }

  if (
    status === 'denied' &&
    validationErrorMap[claim.validationError ?? claim.denialReason ?? '']
  ) {
    return {
      ...badgeData,
      badgeDetails: validationErrorMap[claim.validationError ?? claim.denialReason ?? ''],
    }
  }

  return badgeData
}
