import type { FC } from 'react'
import React, { useState } from 'react'
import { Switch } from '@extend/zen'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import { useUpsertMerchantServicingSettingsMutation } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { SaveChangesButtonGroup } from '../save-changes-button-group'

type ShippingProtectionRepairsStoreProps = {
  merchantServicingSettings: MerchantServicingSettings
}

const ShippingProtectionRepairsStore: FC<ShippingProtectionRepairsStoreProps> = ({
  merchantServicingSettings,
}) => {
  const originalSetting = !!merchantServicingSettings.isServiceOrderShippingInsuranceProvider

  const [
    isServiceOrderShippingInsuranceProviderEnabled,
    setIsServiceOrderShippingInsuranceProviderEnabled,
  ] = useState(merchantServicingSettings.isServiceOrderShippingInsuranceProvider ?? false)

  const toggleIsServiceOrderShippingInsuranceProviderEnabled = () => {
    setIsServiceOrderShippingInsuranceProviderEnabled(
      !isServiceOrderShippingInsuranceProviderEnabled,
    )
  }

  const [upsertMerchantServicingSettings, { isLoading }] =
    useUpsertMerchantServicingSettingsMutation()

  const handleSave = async (): Promise<void> => {
    await upsertMerchantServicingSettings({
      sellerId: merchantServicingSettings.sellerId,
      isServiceOrderShippingInsuranceProvider: isServiceOrderShippingInsuranceProviderEnabled,
    }).unwrap()
  }

  const handleCancel = (): void => {
    setIsServiceOrderShippingInsuranceProviderEnabled(originalSetting)
  }

  const hasSettingChanged = originalSetting !== isServiceOrderShippingInsuranceProviderEnabled

  const handleSwitchToggle = () => {
    toggleIsServiceOrderShippingInsuranceProviderEnabled()
  }

  return (
    <Wrapper data-cy="shipping-protection-repair-store">
      <Switch
        data-cy="shipping-protection-repair-store-switch"
        label="Extend Shipping Protection Store for Repairs"
        isOn={isServiceOrderShippingInsuranceProviderEnabled}
        onChange={handleSwitchToggle}
        helperText="Internal Store for handling Shipping Protection covering repair products shipped from/to customer location to/from servicer location during a service-order processing"
      />
      {hasSettingChanged && (
        <SaveChangesButtonGroup
          handleSave={handleSave}
          handleCancel={handleCancel}
          isLoading={isLoading}
          isSaveDisabled={isLoading}
          id="shipping-protection-repair-store-save-group"
          data-cy="shipping-protection-repair-store-save-group"
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})

export { ShippingProtectionRepairsStore }
