import React from 'react'

import type { BadgeDescriptionItem } from '@helloextend/merchants-ui'
import { Badge, Loading } from '@extend/zen'
import type { ColumnDef, CellContext, FilterDef } from '@extend/zen'
import type {
  ThreadListItem,
  ThreadStatus,
} from '@helloextend/extend-api-rtk-query/src/conversations'
import { date as dateHelper } from '@extend/client-helpers'
import styled from '@emotion/styled'
import { formatDateWithTimezone } from '../../../../utils/date-formatting'
import { BadgeDescriptions } from '../components/badge-descriptions'
import { getBadgeColor } from '../conversations/adjudication-conversation-list.utils'

const { formatToMilliseconds } = dateHelper

export const getBadgeText = (status: ThreadStatus): string => {
  switch (status) {
    case 'draft':
      return 'Draft'
    case 'pending_changes':
      return 'Changes Pending'
    case 'published':
      return 'Published'
    case 'archived':
      return 'Archived'
    case 'override':
      return 'Override'
    default:
      return 'Unknown'
  }
}

const threadStatusDetails: Record<ThreadStatus, BadgeDescriptionItem> = {
  draft: {
    badgeText: 'Draft',
    description: 'Draft threads can be changed without impacting any conversations.',
    badgeColor: 'yellow',
  },
  pending_changes: {
    badgeText: 'Changes Pending',
    description:
      'Changes Pending thread cannot be assigned to conversations unless it is published. If this thread is currently assigned to conversations, the assigned thread is the latest published version. To view the details, please go to the thread preview.',
    badgeColor: 'yellow',
  },
  published: {
    badgeText: 'Published',
    description: 'Published thread can be assigned to conversations.',
    badgeColor: 'green',
  },
  archived: {
    badgeText: 'Archived',
    description: 'Archived threads cannot be utilized to create any conversations.',
    badgeColor: 'neutral',
  },
  override: {
    badgeText: 'Override',
    description: 'Override',
    badgeColor: 'neutral',
  },
}

export const threadColumns: Array<ColumnDef<ThreadListItem>> = [
  {
    id: 'title',
    label: 'Name',
    renderCell: (data: CellContext<ThreadListItem, string>) => data.getValue(),
    search: 'explicit',
  },
  {
    id: 'type',
    label: 'Thread Type',
    renderCell: (data: CellContext<ThreadListItem, string>) => data.getValue(),
  },
  {
    id: 'status',
    label: 'Status',
    information: <BadgeDescriptions descriptions={Object.values(threadStatusDetails)} />,
    renderCell: (data: CellContext<ThreadListItem, string>): JSX.Element => {
      // While restoring, the status will temporarily be set to 'restore' until request is successfully completed
      if (data.getValue() === 'restore') {
        return (
          <LoadingWrapper>
            <Loading heightPx={15} dotsSize="xs" />
          </LoadingWrapper>
        )
      }
      const status = data.getValue() as ThreadStatus
      const badgeColor = getBadgeColor(status)
      return (
        <Badge
          color={badgeColor}
          emphasis="medium"
          text={getBadgeText(status)}
          data-cy={`badge-${data.row.original.id}`}
        />
      )
    },
  },
  {
    id: 'updatedAt',
    label: 'Last Update',
    renderCell: (data: CellContext<ThreadListItem, string>) =>
      data.getValue() &&
      `${formatDateWithTimezone(formatToMilliseconds(data.getValue()))} by ${
        data.row.original.editedBy
      }`,
    isSortable: true,
  },
]

export const filterDefs: FilterDef[] = [
  {
    type: 'group',
    label: 'Filters',
    filterDefs: [
      {
        id: 'type',
        label: 'Type',
        type: 'select',
        options: [
          { display: 'Adjudication', value: 'adjudication' },
          { display: 'Troubleshooting', value: 'troubleshooting' },
        ],
        isMultiSelect: true,
      },
      {
        id: 'status',
        label: 'Status',
        type: 'select',
        options: [
          { display: 'Published', value: 'published' },
          { display: 'Draft', value: 'draft' },
          { display: 'Changes Pending', value: 'pending_changes' },
          { display: 'Archived', value: 'archived' },
        ],
        isMultiSelect: true,
      },
    ],
  },
]

const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
})
