import { createApi } from '@reduxjs/toolkit/query/react'
import { omit } from 'lodash/fp'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import type { PhotosetResponse } from '@extend-conversations/types'
import { baseQuery } from '../base-query'
import type {
  ThreadListItem,
  ThreadResponse,
  ConversationResponse,
  UsedByResponse,
  ThreadUpdateRequest,
  ThreadPublishRequest,
  ConversationConfigurationResponse,
  ConversationConfigUpdateRequest,
  ConversationConfigCreateRequest,
  ConversationCreateRequest,
  ThreadCreateRequest,
  ConversationUpdateOptimisticRequest,
  ConversationPublishRequest,
  PhotoCreateResponse,
  PhotoCreateRequest,
  RulesetResponse,
  CreateThreadRulesetRequest,
  UpdateThreadRulesetRequest,
  AdjudicationRuleRequest,
  AdjudicationRuleResponse,
  ConversationPatchRequest,
  ThreadPatchRequest,
  CreatePhotosetRequest,
  UpdatePhotosetRequest,
} from './types'
import { applyPatch } from './utils'

dayjs.extend(utc)

function getTimestamp(): string {
  return dayjs.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
}

export const conversationsApi = createApi({
  baseQuery,
  reducerPath: 'conversations',
  tagTypes: [
    'ConversationList',
    'Conversation',
    'ThreadList',
    'Thread',
    'ThreadUsedBy',
    'ConfigurationList',
    'Configuration',
    'ThreadRuleset',
    'Photoset',
  ],
  endpoints: (build) => ({
    createThread: build.mutation<ThreadResponse, ThreadCreateRequest>({
      query: (body) => ({
        url: 'conversations/thread',
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        body,
      }),
      invalidatesTags: ['ThreadList'],
    }),
    listThreads: build.query<ThreadListItem[], void>({
      query: () => ({
        url: `conversations/thread`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (result, err) => {
        if (err || !result) return []
        return [
          ...result.map(
            ({ id }) =>
              ({
                type: 'ThreadList',
                id,
              } as const),
          ),
        ]
      },
    }),
    getThread: build.query<ThreadResponse, string>({
      query: (threadId: string) => ({
        url: `conversations/thread/${threadId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, threadId) => [{ type: 'Thread', id: threadId }],
    }),
    listConversations: build.query<ConversationResponse[], void>({
      query: () => ({
        url: 'conversations',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest',
        },
      }),
      providesTags: (_, _err) => [{ type: 'ConversationList' }],
    }),
    getConversation: build.query<ConversationResponse, string>({
      query: (conversationId: string) => ({
        url: `conversations/${conversationId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest',
        },
      }),
      providesTags: (_, _err) => [{ type: 'Conversation' }],
    }),
    createConversation: build.mutation<ConversationResponse, ConversationCreateRequest>({
      query: ({ title, description, threads }) => {
        return {
          url: `conversations/`,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest',
          },
          method: 'POST',
          body: {
            title,
            description,
            threads,
          },
        }
      },
      invalidatesTags: (conversationResponse) =>
        conversationResponse ? [{ type: 'ConversationList' }] : [],
    }),
    updateConversation: build.mutation<ConversationResponse, ConversationUpdateOptimisticRequest>({
      query: ({ conversationId, conversation }) => {
        const { description, threads } = conversation
        return {
          url: `conversations/${conversationId}`,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest',
          },
          method: 'PUT',
          body: { description, threads },
        }
      },
      async onQueryStarted({ conversationId, conversation }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          conversationsApi.util.updateQueryData('getConversation', conversationId, (draft) => {
            if (draft) {
              Object.assign(draft, { ...conversation, version: conversation.version + 1 })
            }
          }),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    publishConversation: build.mutation<ConversationResponse, ConversationPublishRequest>({
      query: ({ conversationId, version }) => ({
        url: `conversations/${conversationId}/publish`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest',
        },
        method: 'PUT',
        body: { version },
      }),
      async onQueryStarted({ conversationId }, { dispatch, queryFulfilled }) {
        const timestamp = getTimestamp()
        const getConversationPatchResult = dispatch(
          conversationsApi.util.updateQueryData('getConversation', conversationId, (draft) => {
            if (draft) {
              Object.assign(draft, { status: 'published', updatedAt: timestamp })
            }
          }),
        )
        const listConversationsPatchResult = dispatch(
          conversationsApi.util.updateQueryData(
            'listConversations',
            undefined,
            (response: ConversationResponse[]) => {
              return response.map((conversation) =>
                conversation.id === conversationId
                  ? { ...conversation, status: 'published', updatedAt: timestamp }
                  : conversation,
              )
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          getConversationPatchResult.undo()
          listConversationsPatchResult.undo()
        }
      },
    }),
    patchConversation: build.mutation<ConversationResponse, ConversationPatchRequest>({
      query: ({ conversationId, patches }) => ({
        url: `conversations/${conversationId}`,
        headers: {
          'content-type': 'application/json-patch+json',
          accept: 'application/json-patch+json; version=latest',
        },
        method: 'PATCH',
        body: patches,
      }),
      async onQueryStarted({ conversationId, patches }, { dispatch, queryFulfilled }) {
        const timestamp = getTimestamp()
        const getConversationPatchResult = dispatch(
          conversationsApi.util.updateQueryData(
            'getConversation',
            conversationId,
            (conversation) => {
              const patchedConversation = applyPatch(conversation, patches)

              return {
                ...patchedConversation,
                updatedAt: timestamp,
              }
            },
          ),
        )
        const listConversationsPatchResult = dispatch(
          conversationsApi.util.updateQueryData(
            'listConversations',
            undefined,
            (response: ConversationResponse[]) => {
              return response.map((conversation) => {
                const patchedConversation = applyPatch(conversation, patches)

                return conversation.id === conversationId
                  ? { ...patchedConversation, updatedAt: timestamp }
                  : conversation
              })
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          getConversationPatchResult.undo()
          listConversationsPatchResult.undo()
        }
      },
    }),
    getThreadUsedBy: build.query<UsedByResponse[], string>({
      query: (threadId: string) => ({
        url: `conversations/thread/${threadId}/usedBy`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest',
        },
      }),
      providesTags: (_, _err) => [{ type: 'ThreadUsedBy' }],
    }),
    saveThread: build.mutation<ThreadResponse, ThreadUpdateRequest>({
      query: ({ threadId, thread }) => ({
        url: `conversations/thread/${threadId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest',
        },
        method: 'PUT',
        body: omit(['status', 'version'], thread),
      }),
      async onQueryStarted({ threadId, thread }, { dispatch, queryFulfilled }) {
        const updatedStatus =
          thread.status === 'published' || thread.status === 'pending_changes'
            ? 'pending_changes'
            : 'draft'
        const timestamp = getTimestamp()
        const getThreadPatchResult = dispatch(
          conversationsApi.util.updateQueryData('getThread', threadId, (draft) => {
            if (draft) {
              Object.assign(draft, {
                ...thread,
                status: updatedStatus,
                updatedAt: timestamp,
                version: thread.version + 1,
              })
            }
          }),
        )
        const listThreadsPatchResult = dispatch(
          conversationsApi.util.updateQueryData(
            'listThreads',
            undefined,
            (response: ThreadListItem[]) => {
              return response.map((threadRes) =>
                threadRes.id === threadId
                  ? { ...threadRes, status: updatedStatus, updatedAt: timestamp }
                  : threadRes,
              )
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          getThreadPatchResult.undo()
          listThreadsPatchResult.undo()
        }
      },
    }),
    publishThread: build.mutation<string, ThreadPublishRequest>({
      query: ({ threadId, version }) => {
        return {
          url: `conversations/thread/${threadId}/publish`,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest',
          },
          method: 'PUT',
          body: { version },
        }
      },
      async onQueryStarted({ threadId }, { dispatch, queryFulfilled }) {
        const timestamp = getTimestamp()
        const getThreadPatchResult = dispatch(
          conversationsApi.util.updateQueryData('getThread', threadId, (draft) => {
            if (draft) {
              Object.assign(draft, { status: 'published', updatedAt: timestamp })
            }
          }),
        )
        const listThreadsPatchResult = dispatch(
          conversationsApi.util.updateQueryData(
            'listThreads',
            undefined,
            (response: ThreadListItem[]) => {
              return response.map((thread) =>
                thread.id === threadId
                  ? { ...thread, status: 'published', updatedAt: timestamp }
                  : thread,
              )
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          getThreadPatchResult.undo()
          listThreadsPatchResult.undo()
        }
      },
    }),
    patchThread: build.mutation<ThreadResponse, ThreadPatchRequest>({
      query: ({ threadId, patches }) => {
        return {
          url: `conversations/thread/${threadId}`,
          headers: {
            'content-type': 'application/json-patch+json',
            accept: 'application/json-patch+json; version=latest',
          },
          method: 'PATCH',
          body: patches,
        }
      },
      async onQueryStarted({ threadId, patches }, { dispatch, queryFulfilled }) {
        if (patches.some((patch) => patch.op === 'remove')) {
          return
        }
        const timestamp = getTimestamp()
        const getThreadPatchResult = dispatch(
          conversationsApi.util.updateQueryData('getThread', threadId, (thread) => {
            const patchedThread = applyPatch(thread, patches)

            return {
              ...patchedThread,
              updatedAt: timestamp,
            }
          }),
        )
        const listThreadsPatchResult = dispatch(
          conversationsApi.util.updateQueryData(
            'listThreads',
            undefined,
            (response: ThreadListItem[]) => {
              return response.map((thread) => {
                const patchedThread = applyPatch(thread, patches)

                return thread.id === threadId ? { ...patchedThread, updatedAt: timestamp } : thread
              })
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          getThreadPatchResult.undo()
          listThreadsPatchResult.undo()
        }
      },
    }),
    listConfigurations: build.query<ConversationConfigurationResponse[], void>({
      query: () => ({
        url: 'conversations/config',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest',
        },
      }),
      providesTags: (_, _err) => [{ type: 'ConfigurationList' }],
    }),
    getConversationConfiguration: build.query<ConversationConfigurationResponse, string>({
      query: (configId: string) => ({
        url: `conversations/config/${configId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, configurationId) => [{ type: 'Configuration', id: configurationId }],
    }),
    deleteConfiguration: build.mutation<string, string>({
      query: (configId: string) => ({
        url: `conversations/config/${configId}`,
        method: 'DELETE',
        headers: {
          accept: 'application/json; version=latest;',
        },
        // rtk query will try to parse this response as JSON and throw an
        // error since this endpoint returns a string. Custom handler will override
        // default behavior and handle string response body
        responseHandler: (res) => res.text(),
      }),
      invalidatesTags: [{ type: 'ConfigurationList' }],
    }),
    saveConversationConfiguration: build.mutation<
      ConversationConfigurationResponse,
      ConversationConfigUpdateRequest
    >({
      query: ({ id, conversationId }) => {
        return {
          url: `conversations/config/${id}`,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest',
          },
          method: 'PUT',
          body: { conversationId },
        }
      },
      invalidatesTags: (_, _err, { id }) => [
        { type: 'ConfigurationList' },
        { type: 'Configuration', id },
      ],
    }),
    createConversationConfiguration: build.mutation<
      ConversationConfigurationResponse,
      ConversationConfigCreateRequest
    >({
      query: (conversationConfiguration) => {
        return {
          url: `conversations/config`,
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest',
          },
          body: conversationConfiguration,
        }
      },
      invalidatesTags: [{ type: 'ConfigurationList' }],
    }),
    createPhoto: build.mutation<PhotoCreateResponse, PhotoCreateRequest>({
      query: (photo) => {
        return {
          url: `conversations/photo`,
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest',
          },
          body: photo,
        }
      },
    }),
    getThreadRuleset: build.query<RulesetResponse, string>({
      query: (threadId: string) => ({
        url: `conversations/thread/${threadId}/ruleset`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err) => [{ type: 'ThreadRuleset' }],
    }),
    createThreadRuleset: build.mutation<RulesetResponse, CreateThreadRulesetRequest>({
      query: ({ threadId, data }) => ({
        url: `conversations/thread/${threadId}/ruleset`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        body: data,
      }),
      invalidatesTags: (rulesetResponse) => (rulesetResponse ? [{ type: 'ThreadRuleset' }] : []),
    }),
    createThreadRulesetOptimistic: build.mutation<RulesetResponse, CreateThreadRulesetRequest>({
      query: ({ threadId, data }) => {
        return {
          url: `conversations/thread/${threadId}/ruleset`,
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest',
          },
          body: data,
        }
      },
      async onQueryStarted({ threadId, data }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          conversationsApi.util.updateQueryData('getThreadRuleset', threadId, (draft) => {
            if (draft) {
              Object.assign(draft, { ...data })
            }
          }),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    updateThreadRuleset: build.mutation<RulesetResponse, UpdateThreadRulesetRequest>({
      query: ({ threadId, data }) => ({
        url: `conversations/thread/${threadId}/ruleset`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        body: data,
      }),
      invalidatesTags: (rulesetResponse) => (rulesetResponse ? [{ type: 'ThreadRuleset' }] : []),
    }),
    getAdjudicationRule: build.query<AdjudicationRuleResponse, AdjudicationRuleRequest>({
      query: ({ rulesetId, ruleId, version, expand }) => {
        const expandParam = expand ? '?expand=script' : ''
        const url = `conversations/ruleset/${rulesetId}/rule/${ruleId}/version/${version}${expandParam}`
        return {
          url,
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest;',
          },
        }
      },
    }),
    getPhotoset: build.query<PhotosetResponse, string>({
      query: (threadId: string) => ({
        url: `conversations/thread/${threadId}/photoset`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err) => [{ type: 'Photoset' }],
    }),
    createPhotoset: build.mutation<PhotosetResponse, CreatePhotosetRequest>({
      query: ({ threadId, data }) => ({
        url: `conversations/thread/${threadId}/photoset`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        body: data,
      }),
      invalidatesTags: (photosetResponse) => (photosetResponse ? [{ type: 'Photoset' }] : []),
    }),
    createPhotosetOptimistic: build.mutation<PhotosetResponse, CreatePhotosetRequest>({
      query: ({ threadId, data }) => {
        return {
          url: `conversations/thread/${threadId}/photoset`,
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest',
          },
          body: data,
        }
      },
      async onQueryStarted({ threadId, data }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          conversationsApi.util.updateQueryData('getPhotoset', threadId, (draft) => {
            if (draft) {
              Object.assign(draft, { ...data })
            }
          }),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    updatePhotoset: build.mutation<PhotosetResponse, UpdatePhotosetRequest>({
      query: ({ threadId, data }) => ({
        url: `conversations/thread/${threadId}/photoset`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        body: data,
      }),
      invalidatesTags: (photosetResponse) => (photosetResponse ? [{ type: 'Photoset' }] : []),
    }),
  }),
})

export const {
  useCreateThreadMutation,
  useListThreadsQuery,
  useGetThreadQuery,
  useLazyGetThreadQuery,
  useListConversationsQuery,
  useGetThreadUsedByQuery,
  useGetConversationQuery,
  useUpdateConversationMutation,
  usePatchConversationMutation,
  useSaveThreadMutation,
  usePublishThreadMutation,
  useListConfigurationsQuery,
  useGetConversationConfigurationQuery,
  useLazyGetConversationConfigurationQuery,
  useLazyGetConversationQuery,
  useDeleteConfigurationMutation,
  useSaveConversationConfigurationMutation,
  useCreateConversationConfigurationMutation,
  useCreateConversationMutation,
  usePublishConversationMutation,
  usePatchThreadMutation,
  useCreatePhotoMutation,
  useGetThreadRulesetQuery,
  useLazyGetThreadRulesetQuery,
  useCreateThreadRulesetMutation,
  useCreateThreadRulesetOptimisticMutation,
  useUpdateThreadRulesetMutation,
  useGetAdjudicationRuleQuery,
  useGetPhotosetQuery,
  useLazyGetPhotosetQuery,
  useCreatePhotosetMutation,
  useCreatePhotosetOptimisticMutation,
  useUpdatePhotosetMutation,
} = conversationsApi
