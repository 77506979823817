import { isEqual, remove } from 'lodash/fp'
import { productCsvHeaders, productCsvHeadersNewFields } from './product-csv-headers'

// We use this function primarily for the analytics team.
// They are using SQL headers, which are always lower case.
// The headers can still be camel case, but the validation should
// convert to lower case for ease of operating with SQL.
function toLowerCase(header: string): string {
  return header.toLowerCase()
}

// The function is being used below in the validateHeader funcs,
// as well as being used to return the camelCase properties within
// the parse-product-csv lib utility.  It will default to lowercasing
// all properties in the CSV headers passed for proper validation
// except for where it's used in where we need the camelCase values
// returned in order to generate the CSV property index map
export function getNonSPAndAltLngHeaders(
  header: readonly string[],
  returnLowerCase = true,
): string[] {
  return remove(
    (h) => {
      return (
        toLowerCase(h) === 'shippable' ||
        toLowerCase(h) === 'shipenabled' ||
        toLowerCase(h) === 'alternatelanguages'
      )
    },
    returnLowerCase ? header.map(toLowerCase) : header,
  )
}

export function getSPHeaders(header: readonly string[]): string[] {
  return header.filter((h: string) => {
    return toLowerCase(h) === 'shippable' || toLowerCase(h) === 'shipenabled'
  })
}

function validateSPHeaders(header: string[]): boolean {
  const spAttributes = getSPHeaders(header)

  // Logic: If the shippable attribute is present in the CSV, make sure it has the correct name
  //        If shippable is present, make sure that shipEnabled is undefined or has the correct name
  if (spAttributes[0] === undefined) return true
  return (
    toLowerCase(spAttributes[0]) === 'shippable' &&
    (spAttributes[1] === undefined || toLowerCase(spAttributes[1]) === 'shipenabled')
  )
}

export const validateHeader = (header: string[]): boolean => {
  const receivedHeaders = getNonSPAndAltLngHeaders(header)
  const expectedHeaders = getNonSPAndAltLngHeaders(productCsvHeaders)
  return isEqual(receivedHeaders, expectedHeaders) && validateSPHeaders(header)
}

export const validateHeaderNewFields = (header: string[]): boolean => {
  const headersToLowerCase = getNonSPAndAltLngHeaders(header)
  const expectedHeadersToLowerCase = getNonSPAndAltLngHeaders(productCsvHeadersNewFields)
  const rtnVal =
    isEqual(headersToLowerCase, expectedHeadersToLowerCase) && validateSPHeaders(header)
  return rtnVal
}
