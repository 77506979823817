import React, { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { useHistory, useLocation } from 'react-router-dom'
import { COLOR } from '@extend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { TabMenu } from '../../../components/tab-menu/tab-menu'
import { ProductProtectionPlans } from './product-protection/product-protection-plans'
import { ShippingProtectionPlans } from './shipping-protection/shipping-protection-plans'
import { LDFlag } from '../../../constants/ld-flags'

enum PlansTab {
  ProductProtection = 'product-protection',
  ShippingProtection = 'shipping-protection',
}

const Plans: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const tab = new URLSearchParams(location.search).get('tab')

  const flags = useFlags()
  const FF_SHIPPING_PROTECTION_PLANS = flags[LDFlag.ShippingProtectionPlans]

  const handleTabClick = (tabKey: PlansTab): void => {
    if (tabKey === PlansTab.ShippingProtection) {
      history.push(`/admin/plans?tab=${tabKey}`)
      return
    }
    history.push('/admin/plans')
  }

  const activeTab = useMemo(() => {
    if (FF_SHIPPING_PROTECTION_PLANS && tab === PlansTab.ShippingProtection) {
      return PlansTab.ShippingProtection
    }
    return PlansTab.ProductProtection
  }, [FF_SHIPPING_PROTECTION_PLANS, tab])

  useEffect(() => {
    if (!FF_SHIPPING_PROTECTION_PLANS && activeTab === PlansTab.ShippingProtection) {
      history.push('/admin/plans')
    }
  }, [FF_SHIPPING_PROTECTION_PLANS, activeTab, history])

  return (
    <>
      <Title data-cy="plans-header-text">Plans</Title>
      <TabContent>
        <TabMenu
          tabs={[
            { text: 'Product Protection', key: PlansTab.ProductProtection },
            FF_SHIPPING_PROTECTION_PLANS && {
              text: 'Shipping Protection',
              key: PlansTab.ShippingProtection,
            },
          ]}
          onClick={(tabKey) => handleTabClick(tabKey as PlansTab)}
          activeTab={activeTab}
        />
      </TabContent>
      {activeTab === PlansTab.ProductProtection && (
        <div data-cy="product-protection-plans-content">
          <ProductProtectionPlans />
        </div>
      )}
      {activeTab === PlansTab.ShippingProtection && (
        <div data-cy="shipping-protection-plans-content">
          <ShippingProtectionPlans />
        </div>
      )}
    </>
  )
}

const Title = styled.h1({
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  marginTop: 0,
})

const TabContent = styled.div({
  marginBottom: 40,
})

export { Plans }
