import type { UserRole } from '../../../utils/user-role-mapper'

interface SalesAssociate {}

interface MerchantAgent {
  customerEmail: boolean
  fullName: boolean
  merchantCustomerId: boolean
  phoneNumber: boolean
  billingAddress: boolean
  billingAddressTwo: boolean
  billingCity: boolean
  billingProvinceCode: boolean
  billingPostalCode: boolean
  billingCountryCode: boolean
  shippingAddress: boolean
  shippingAddressTwo: boolean
  shippingCity: boolean
  shippingProvinceCode: boolean
  shippingPostalCode: boolean
  shippingCountryCode: boolean
  customerType: boolean
}

interface User extends MerchantAgent {
  serialNumber: boolean
}

interface Agent extends User {
  productPurchaseDate: boolean
}

interface Admin extends Agent {
  currencyCode: boolean
  contractPurchaseDate: boolean
  contractListPrice: boolean
  contractPurchasePrice: boolean
  planId: boolean
  planWarrantySku: boolean
  planVersion: boolean
  termsId: boolean
  termVersion: boolean
  transactionId: boolean
  productListPrice: boolean
  productPurchasePrice: boolean
  productPurchaseDate: boolean
  referenceId: boolean
  serialNumber: boolean
  productName: boolean
  mfrsWarrantyLengthLabor: boolean
  mfrsWarrantyLengthParts: boolean
  isTest: boolean
  planPurchasePrice: boolean
  planListPrice: boolean
  planPurchaseDate: boolean
  limitOfLiabilityAmountType: boolean
  limitOfLiabilityAmount: boolean
  brand: boolean
  sku: boolean
}

interface SuperAdmin extends Admin {
  contractStatus: boolean
  storeId: boolean
}

export type Permissions =
  | SalesAssociate
  | MerchantAgent
  | User
  | Agent
  | Admin
  | SuperAdmin
  | ConversationEditor

const salesAssociate: SalesAssociate = {}

const merchantAgent: MerchantAgent = {
  customerEmail: true,
  fullName: true,
  merchantCustomerId: true,
  phoneNumber: true,
  billingAddress: true,
  billingAddressTwo: true,
  billingCity: true,
  billingProvinceCode: true,
  billingPostalCode: true,
  billingCountryCode: true,
  shippingAddress: true,
  shippingAddressTwo: true,
  shippingCity: true,
  shippingProvinceCode: true,
  shippingPostalCode: true,
  shippingCountryCode: true,
  customerType: true,
}

const user: User = {
  ...merchantAgent,
  serialNumber: true,
}

const agent: Agent = {
  ...user,
  productPurchaseDate: true,
}

const admin: Admin = {
  ...agent,
  currencyCode: true,
  contractPurchaseDate: true,
  contractListPrice: true,
  contractPurchasePrice: true,
  planId: true,
  planWarrantySku: true,
  planVersion: true,
  planListPrice: true,
  planPurchasePrice: true,
  planPurchaseDate: true,
  termsId: true,
  termVersion: true,
  transactionId: true,
  productListPrice: true,
  productPurchasePrice: true,
  productPurchaseDate: true,
  referenceId: true,
  serialNumber: true,
  productName: true,
  mfrsWarrantyLengthLabor: true,
  mfrsWarrantyLengthParts: true,
  limitOfLiabilityAmountType: true,
  limitOfLiabilityAmount: true,
  isTest: true,
  brand: true,
  sku: true,
}

const superAdmin: SuperAdmin = {
  ...admin,
  storeId: true,
  contractStatus: true,
}

interface ConversationEditor {}
const conversationEditor: ConversationEditor = {}

interface ConversationViewer {}
const conversationViewer: ConversationViewer = {}

interface ConversationsAdmin {}
const conversationsAdmin: ConversationsAdmin = {}

export const permissionsSelectorV2: Record<UserRole, Permissions> = {
  salesassociate: salesAssociate,
  merchantagent: merchantAgent,
  user,
  agent,
  admin,
  superadmin: superAdmin,
  conversationseditor: conversationEditor,
  conversationsviewer: conversationViewer,
  conversationsadmin: conversationsAdmin,
  /**
   * These Enterprise roles are stubbed to appease the _UserRole_ type,
   * but this object should not be used in an Enterprise role situation
   */
  planmanageradmin: {},
  storeadmin: {},
  MerchantClaimsAgent: {},
  MerchantPortalAdmin: {},
  MerchantApiManager: {},
  MerchantTransactionManager: {},
  StoreAssociate: {},
  ExtendPortalAdmin: {},
  ProvisioningManager: {},
  MerchantSuccess: {},
  SolutionEngineer: {},
  CustomerSupportAgent: {},
  WarrantyMappingSpecialist: {},
  WarrantyMappingManager: {},
  PlanManager: {},
  PlanAnalyst: {},
  FinanceAnalyst: {},
  Engineer: {},
  PlatformAppsEngineer: {},
  ComplianceManager: {},
  DemoCSR: {},
}
