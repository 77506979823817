import type { ChangeEvent, FC } from 'react'
import React, { useEffect } from 'react'
import type { OfferPriceType, Store } from '@helloextend/extend-api-client'
import { Information, InformationSize, Radio, Switch } from '@extend/zen'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'
import { SectionContainer } from '../../../../../../../../components/section-container'
import { Divider } from '../../../../../../../../components/divider'

type OfferFormProps = {
  store: Store
}

const OfferForm: FC<OfferFormProps> = ({ store }) => {
  const [updateStore, { isError, isSuccess, isLoading }] = useUpdateStoreMutation()
  const { toastCompleted, toastError } = useStandardToast()

  const handleUpdateStore = (storeData: Partial<Store>): void => {
    updateStore({
      storeId: store.id,
      data: storeData,
      version: 'latest',
    })
  }

  const handleOffersByCategoryToggle = (): void => {
    handleUpdateStore({ offersByCategoryEnabled: !store.offersByCategoryEnabled })
  }

  const handleOfferPriceTypeUpdate = (e: ChangeEvent<HTMLInputElement>): void => {
    handleUpdateStore({ offerPriceType: e.target.value as OfferPriceType })
  }

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Offer Settings have been successfully updated.')
    }
  }, [isSuccess, toastCompleted])

  useEffect(() => {
    if (isError) {
      toastError('Offer Settings could not be updated. Please try again.')
    }
  }, [isError, toastError])

  return (
    <SectionContainer title="Offer">
      <RadioGroup>
        <RadioButtonsLabel>Display Offer Based On</RadioButtonsLabel>
        <Radio
          checked={store?.offerPriceType === 'product_list_price'}
          onChange={handleOfferPriceTypeUpdate}
          label="Product List Price"
          name="offerPriceType"
          value="product_list_price"
        />
        <Radio
          checked={store?.offerPriceType === 'product_purchase_price'}
          onChange={handleOfferPriceTypeUpdate}
          label="Product Purchase Price"
          name="offerPriceType"
          value="product_purchase_price"
        />
      </RadioGroup>
      <Divider />
      <Switch
        label="Offers by category"
        id="offersByCategory"
        isOn={store?.offersByCategoryEnabled}
        onChange={() => handleOffersByCategoryToggle()}
        data-cy="offers-by-category-toggle"
        isProcessing={isLoading}
      />
      <Information size={InformationSize.small} padding="0px 12px">
        <p>
          When enabled, this feature will default to offer presentation based on category rather
          than requiring a plan be mapped directly to the product. Note that the category must be
          first mapped as warrantable
        </p>
      </Information>
    </SectionContainer>
  )
}

const RadioGroup = styled.div({
  fontSize: '14px',
})
const RadioButtonsLabel = styled.p({
  fontWeight: 800,
  lineHeight: '16px',
})

export { OfferForm }
