import type { FC, ReactElement } from 'react'
import React, { memo, useContext } from 'react'
import { ContractType } from '@helloextend/extend-api-client'
import { usePrecheckQuery } from '@helloextend/extend-api-rtk-query'
import { isEmpty } from 'lodash'
import { PCRS, ShippingProtection, Category, ProductProtectionBundle } from './forms'
import type { ContractContextValue } from './components/contract-container'
import { ContractContainer, ContractContext } from './components/contract-container'

const DisplayContractType: FC = memo(() => {
  const { contract, toggleNavBlocked, saveUpdates, planTermsUrl, orderUrl } = useContext(
    ContractContext,
  ) as ContractContextValue

  const contractType = contract?.type
  const { data: precheckResponse } = usePrecheckQuery(
    { contractId: contract?.id },
    {
      skip: isEmpty(contract),
    },
  )

  const contractComponents: Record<string, () => ReactElement> = {
    [ContractType.SHIPPING_PROTECTION]: () => (
      <ShippingProtection
        saveUpdates={saveUpdates}
        toggleNavBlocked={toggleNavBlocked}
        contract={contract}
        planTermsUrl={planTermsUrl}
        orderUrl={orderUrl}
      />
    ),
    [ContractType.PCRS]: () => (
      <PCRS
        saveUpdates={saveUpdates}
        toggleNavBlocked={toggleNavBlocked}
        contract={contract}
        planTermsUrl={planTermsUrl}
        orderUrl={orderUrl}
      />
    ),
    [ContractType.CATEGORY]: () => (
      <Category
        precheckData={precheckResponse}
        saveUpdates={saveUpdates}
        toggleNavBlocked={toggleNavBlocked}
        contract={contract}
        planTermsUrl={planTermsUrl}
        orderUrl={orderUrl}
      />
    ),
    [ContractType.PRODUCT_PROTECTION_BUNDLE]: () => (
      <ProductProtectionBundle
        saveUpdates={saveUpdates}
        toggleNavBlocked={toggleNavBlocked}
        contract={contract}
        planTermsUrl={planTermsUrl}
        orderUrl={orderUrl}
        precheckData={precheckResponse}
      />
    ),
  }

  if (!Object.keys(contractComponents).includes(contractType)) {
    return null
  }

  return contractComponents[contractType]()
})

const Contract: FC = () => {
  return (
    <ContractContainer>
      <DisplayContractType />
    </ContractContainer>
  )
}

export { Contract, DisplayContractType }
