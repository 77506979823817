import type { PlanTermsVersion } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'
import type { CellContext, ColumnDefs } from '@extend/zen'
import { format } from '@extend/zen'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

const tableColumns: ColumnDefs<PlanTermsVersion> = [
  {
    id: 'version',
    label: 'Version',
    isSortable: true,
    renderCell: (data: CellContext<PlanTermsVersion, string>) => {
      const { termsId } = useParams<{ termsId: string }>()
      return (
        <Link to={`/admin/terms/${termsId}/versions/${data.getValue()}?tab=details`}>
          {data.getValue()}{' '}
        </Link>
      )
    },
  },
  {
    id: 'createdAt',
    label: 'Date Created',
    isSortable: true,
    renderCell: (data: CellContext<PlanTermsVersion, string>) => {
      const { createdAt } = data.row.original
      return <span>{format(createdAt, 'MMM DD YYYY')}</span>
    },
  },
]

export { tableColumns }
