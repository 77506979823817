import { combineReducers } from 'redux'
import type {
  PlansReducerState,
  ServicersReducerState,
  ContractsReducerState,
  ProductsReducerState,
} from '@helloextend/core-api-redux'
import {
  plansReducer,
  servicersReducer,
  contractsReducer,
  productsReducer,
} from '@helloextend/core-api-redux'
import {
  taxonomyApi,
  claimsApi,
  contractsApi,
  productsApi,
  serviceOrdersApi,
  plansApi,
  servicersApi,
  productsMappingApi,
  skusApi,
  claimsAssignmentUsersApi,
  ordersApi,
  incredibotApi,
  conversationsApi,
  conversationsSamplePhotos,
  claimPhotosApi,
  virtualCardsApi,
  storesApi,
  refundsApi,
  accountsApi,
  usersV3Api,
  oktaApi,
  grantsApi,
  productsRdbApi,
  productCategoriesRdbApi,
  plansTermsApi,
  wdOrchestratorApi,
  warrantyDecisioningAPI,
  auditApi,
  messagingApi,
  insuranceProgramsApi,
  donationsApi,
  shippingOffersStoresConfigApi,
  adjudicationManagementApi,
  emsApi,
  offersApi,
  popaApi,
  themesApi,
  authApi,
  lineItemsApi,
  aisApi,
} from '@helloextend/extend-api-rtk-query'
import type { LocalServicersReducerState } from './servicers'
import servicersLocal from './servicers'
import type { MerchantsStoreReducerState } from './stores'
import store from './stores'
import type { SlicesState } from '../store/slices'
import { slicesReducers } from '../store/slices'

export interface RootState extends SlicesState {
  contracts: ContractsReducerState
  products: ProductsReducerState
  plans: PlansReducerState
  stores: MerchantsStoreReducerState
  servicers: ServicersReducerState
  servicersLocal: LocalServicersReducerState
  [auditApi.reducerPath]: ReturnType<typeof auditApi.reducer>
  [serviceOrdersApi.reducerPath]: ReturnType<typeof serviceOrdersApi.reducer>
  [plansApi.reducerPath]: ReturnType<typeof plansApi.reducer>
  [contractsApi.reducerPath]: ReturnType<typeof contractsApi.reducer>
  [productsApi.reducerPath]: ReturnType<typeof productsApi.reducer>
  [claimsApi.reducerPath]: ReturnType<typeof claimsApi.reducer>
  [servicersApi.reducerPath]: ReturnType<typeof servicersApi.reducer>
  [productsMappingApi.reducerPath]: ReturnType<typeof productsMappingApi.reducer>
  [skusApi.reducerPath]: ReturnType<typeof skusApi.reducer>
  [claimsAssignmentUsersApi.reducerPath]: ReturnType<typeof claimsAssignmentUsersApi.reducer>
  [claimPhotosApi.reducerPath]: ReturnType<typeof claimPhotosApi.reducer>
  [ordersApi.reducerPath]: ReturnType<typeof ordersApi.reducer>
  [incredibotApi.reducerPath]: ReturnType<typeof incredibotApi.reducer>
  [insuranceProgramsApi.reducerPath]: ReturnType<typeof insuranceProgramsApi.reducer>
  [conversationsApi.reducerPath]: ReturnType<typeof conversationsApi.reducer>
  [conversationsSamplePhotos.reducerPath]: ReturnType<typeof conversationsSamplePhotos.reducer>
  [storesApi.reducerPath]: ReturnType<typeof storesApi.reducer>
  [refundsApi.reducerPath]: ReturnType<typeof refundsApi.reducer>
  [accountsApi.reducerPath]: ReturnType<typeof accountsApi.reducer>
  [virtualCardsApi.reducerPath]: ReturnType<typeof virtualCardsApi.reducer>
  [oktaApi.reducerPath]: ReturnType<typeof oktaApi.reducer>
  [usersV3Api.reducerPath]: ReturnType<typeof usersV3Api.reducer>
  [grantsApi.reducerPath]: ReturnType<typeof grantsApi.reducer>
  [productsRdbApi.reducerPath]: ReturnType<typeof productsRdbApi.reducer>
  [productCategoriesRdbApi.reducerPath]: ReturnType<typeof productCategoriesRdbApi.reducer>
  [plansTermsApi.reducerPath]: ReturnType<typeof plansTermsApi.reducer>
  [wdOrchestratorApi.reducerPath]: ReturnType<typeof wdOrchestratorApi.reducer>
  [warrantyDecisioningAPI.reducerPath]: ReturnType<typeof warrantyDecisioningAPI.reducer>
  [taxonomyApi.reducerPath]: ReturnType<typeof taxonomyApi.reducer>
  [messagingApi.reducerPath]: ReturnType<typeof messagingApi.reducer>
  [donationsApi.reducerPath]: ReturnType<typeof donationsApi.reducer>
  [donationsApi.reducerPath]: ReturnType<typeof donationsApi.reducer>
  [shippingOffersStoresConfigApi.reducerPath]: ReturnType<
    typeof shippingOffersStoresConfigApi.reducer
  >
  [adjudicationManagementApi.reducerPath]: ReturnType<typeof adjudicationManagementApi.reducer>
  [emsApi.reducerPath]: ReturnType<typeof emsApi.reducer>
  [offersApi.reducerPath]: ReturnType<typeof offersApi.reducer>
  [popaApi.reducerPath]: ReturnType<typeof popaApi.reducer>
  [themesApi.reducerPath]: ReturnType<typeof themesApi.reducer>
  [authApi.reducerPath]: ReturnType<typeof authApi.reducer>
  [lineItemsApi.reducerPath]: ReturnType<typeof lineItemsApi.reducer>
  [aisApi.reducerPath]: ReturnType<typeof aisApi.reducer>
}

export default combineReducers<RootState>({
  contracts: contractsReducer,
  products: productsReducer,
  plans: plansReducer,
  stores: store,
  servicers: servicersReducer,
  servicersLocal,
  [auditApi.reducerPath]: auditApi.reducer,
  [serviceOrdersApi.reducerPath]: serviceOrdersApi.reducer,
  [plansApi.reducerPath]: plansApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [claimsApi.reducerPath]: claimsApi.reducer,
  [servicersApi.reducerPath]: servicersApi.reducer,
  [productsMappingApi.reducerPath]: productsMappingApi.reducer,
  [skusApi.reducerPath]: skusApi.reducer,
  [virtualCardsApi.reducerPath]: virtualCardsApi.reducer,
  [claimsAssignmentUsersApi.reducerPath]: claimsAssignmentUsersApi.reducer,
  [claimPhotosApi.reducerPath]: claimPhotosApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [incredibotApi.reducerPath]: incredibotApi.reducer,
  [insuranceProgramsApi.reducerPath]: insuranceProgramsApi.reducer,
  [conversationsApi.reducerPath]: conversationsApi.reducer,
  [conversationsSamplePhotos.reducerPath]: conversationsSamplePhotos.reducer,
  [storesApi.reducerPath]: storesApi.reducer,
  [refundsApi.reducerPath]: refundsApi.reducer,
  [accountsApi.reducerPath]: accountsApi.reducer,
  [oktaApi.reducerPath]: oktaApi.reducer,
  [usersV3Api.reducerPath]: usersV3Api.reducer,
  [grantsApi.reducerPath]: grantsApi.reducer,
  [productsRdbApi.reducerPath]: productsRdbApi.reducer,
  [productCategoriesRdbApi.reducerPath]: productCategoriesRdbApi.reducer,
  [plansTermsApi.reducerPath]: plansTermsApi.reducer,
  [wdOrchestratorApi.reducerPath]: wdOrchestratorApi.reducer,
  [warrantyDecisioningAPI.reducerPath]: warrantyDecisioningAPI.reducer,
  [taxonomyApi.reducerPath]: taxonomyApi.reducer,
  [messagingApi.reducerPath]: messagingApi.reducer,
  [donationsApi.reducerPath]: donationsApi.reducer,
  [shippingOffersStoresConfigApi.reducerPath]: shippingOffersStoresConfigApi.reducer,
  [adjudicationManagementApi.reducerPath]: adjudicationManagementApi.reducer,
  [emsApi.reducerPath]: emsApi.reducer,
  [offersApi.reducerPath]: offersApi.reducer,
  [popaApi.reducerPath]: popaApi.reducer,
  [themesApi.reducerPath]: themesApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [lineItemsApi.reducerPath]: lineItemsApi.reducer,
  [aisApi.reducerPath]: aisApi.reducer,
  ...slicesReducers,
})
