import type { AdvancedSelectProps } from '@extend/zen'
import type { FormikErrors } from 'formik'
import { getIn } from 'formik'
import type React from 'react'

const states = [
  'AL',
  'AK',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WI',
  'WV',
  'WY',
]
const provinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT']
const phoneRegEx = /^\d{3}-\d{3}-\d{4}$/
const postalCodeRegex = {
  US: /^[0-9]{5}(?:-[0-9]{4})?$/,
  CA: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
}
const cityRegex = /^[a-zA-Z.-]+(?:[\s-][a-zA-Z.]+)*$/

const toValidPhoneNumber = (value: string): string => {
  let val = value

  // Trim off the country code
  if (val[0] === '+') val = val.substring(2)

  // Remove everything not a number
  val = val.replace(/\D/g, '')

  // Add dashes where appropriate
  if (val.length > 3 && val.length <= 6) val = `${val.slice(0, 3)}-${val.slice(3)}`
  else if (val.length > 6) val = `${val.slice(0, 3)}-${val.slice(3, 6)}-${val.slice(6)}`

  // Don't let it get too long
  if (val.length > 12) val = val.substring(0, val.length - 1)

  return val
}

interface AlertItem {
  key: string
  text: string
}

const convertToAlertItem = (alerts: string[]): AlertItem[] => {
  return alerts.map((error, index) => ({
    key: `error-${index}`,
    text: error,
  }))
}

/**
 *
 * @param list planIds list
 * @param selectedOption the currently selected option (optional)
 * @param additionalDetails additional details for the selected planID to display in the tooltip (optional)
 * @returns AdvancedSelectOption
 */
const listToAdvancedSelectOptions = (
  list: string[],
  selectedOption?: string,
  additionalDetails?: React.ReactNode,
): AdvancedSelectProps['options'] => {
  return list.map((item) => {
    return {
      display: item,
      value: item,
      ...(selectedOption === item && additionalDetails ? { information: additionalDetails } : {}),
    }
  })
}

const getFormikError = <TValues>(errors: FormikErrors<TValues>, fieldName: string): string => {
  const error = getIn(errors, fieldName)
  return error ?? ''
}

export {
  cityRegex,
  phoneRegEx,
  states,
  provinces,
  toValidPhoneNumber,
  postalCodeRegex,
  convertToAlertItem,
  listToAdvancedSelectOptions,
  getFormikError,
}
