import type { ThreadListItem, ThreadStatus, ThreadTypes } from '@helloextend/extend-api-rtk-query'

const generateDefaultThreadListItem = (): ThreadListItem => {
  return {
    createdAt: '1655741111111',
    updatedAt: '1655741111111',
    editedBy: 'Carla Stanton',
    id: '1',
    status: 'draft',
    title: '',
    type: 'adjudication',
  }
}

const generateThreadListItem = (
  id: string,
  title: string,
  type: ThreadTypes,
  status: ThreadStatus,
): ThreadListItem => {
  return {
    ...generateDefaultThreadListItem(),
    id,
    status,
    title,
    type,
  }
}

export const defaultThreadItem: ThreadListItem = generateDefaultThreadListItem()
export const mockedThreads: ThreadListItem[] = [
  generateThreadListItem('1', 'Manual Review', 'adjudication', 'published'),
  generateThreadListItem(
    '2',
    'Consumer Electronics adjudication Questions',
    'adjudication',
    'published',
  ),
  generateThreadListItem('3', 'Jewelry adjudication Questions', 'adjudication', 'published'),
  generateThreadListItem(
    '4',
    'Tires and Wheel adjudication Questions',
    'adjudication',
    'published',
  ),
  generateThreadListItem('5', 'Furniture adjudication Questions', 'adjudication', 'draft'),
  generateThreadListItem('6', 'Manual Review', 'adjudication', 'published'),
]

export const mockPublishedThread: ThreadListItem = mockedThreads[0]
export const mockPublishedThreadWithFiveConversations: ThreadListItem = mockedThreads[5]
export const mockPublishedThreadWithReferencedConversationsNoOverflow = mockedThreads[2]
export const mockPublishedThreadNoReferencedConversations: ThreadListItem = mockedThreads[1]

export const mockDraftThread: ThreadListItem = mockedThreads[4]
