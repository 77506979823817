import type { Money, ServiceOrder } from '@helloextend/extend-api-client'
import { AccordionSection } from '@extend/zen'
import type { FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { ExpensesTab } from '../../../expenses'

interface ExpensesProps {
  serviceOrder: ServiceOrder
  expandSignal?: boolean
  entitlements?: { coverageAmountRemaining: Money }
}

const Expenses: FC<ExpensesProps> = ({ serviceOrder, expandSignal, entitlements }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggle = (): void => {
    setIsExpanded(!isExpanded)
  }
  const firstUpdate = useRef(true)
  useEffect(() => {
    // do nothing on first render
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    setIsExpanded(true)
  }, [expandSignal])

  return (
    <AccordionSection
      heading="Expenses"
      headingSize="sub"
      onToggleRequest={handleToggle}
      isExpanded={isExpanded}
      data-cy={`${serviceOrder.id}-expenses`}
    >
      <ExpensesTab {...{ serviceOrder }} entitlements={entitlements} />
    </AccordionSection>
  )
}

export { Expenses }
