import type { FC } from 'react'
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useToaster } from '@extend/zen'
import { useGetAccountOrganizationsListQuery } from '@helloextend/extend-api-rtk-query'
import {
  DataReactTable,
  PaginationType,
  ToastColor,
  ToastDuration,
  ZeroState,
} from '@helloextend/merchants-ui'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import { organizationsColumns, toTableOrganizations } from './table-config'

const OrganizationsDataTable: FC = () => {
  const { accountId } = useParams<{ accountId: string }>()
  const [pageSize, setPageSize] = useQueryStringState('pageSize', 10)
  const [pageCursor, setPageCursor] = useState('')
  const [autoResetPage, setAutoResetPage] = useState(true)
  const { toast } = useToaster()

  const initialState = useMemo(() => {
    return {
      pageSize,
    }
  }, [pageSize])

  const { data, isLoading, isError } = useGetAccountOrganizationsListQuery({
    accountId,
    cursor: pageCursor,
  })

  const handleServerPagination = useCallback((cursor) => {
    const nextCursor = cursor ?? ''
    setPageCursor(nextCursor)
    setAutoResetPage(false)
  }, [])

  const setPagination = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize)
    },
    [setPageSize],
  )

  const tableAccounts = useMemo(() => toTableOrganizations(data?.items ?? []), [data])

  useEffect(() => {
    if (isLoading) {
      toast({
        message: 'Retrieving results... This might take a few moments',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      return
    }
    if (isError) {
      toast({
        message: 'Unable to retrieve accounts data',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isError, isLoading, toast])

  return (
    <DataReactTable
      type="account-organizations"
      data={tableAccounts}
      initialState={initialState}
      isLoading={isLoading}
      columns={organizationsColumns}
      paginationType={PaginationType.ENHANCED_SERVER_SIDE}
      onServerPagination={handleServerPagination}
      setPagination={setPagination}
      autoResetPage={autoResetPage}
      nextPageCursor={data?.nextPageCursor}
      shouldDisplayZeroState={!data?.items?.length}
      zeroState={
        <ZeroState
          title="No organizations found under this account"
          text="organizations should be listed here"
          data-cy="account-organizations-zero-state:message-container"
        />
      }
    />
  )
}

export { OrganizationsDataTable }
