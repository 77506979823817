import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  EmbeddedPromotionsPage,
  Offer,
  OffersStoreConfig,
  EmbeddedPromotion,
  EmbeddedPromotionProductsPage,
  PromotionProductsMutateResponse,
  PromotionProductsDownloadResponse,
  PromotionProductsDownloadRequest,
} from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'
import type {
  EmbeddedPromotionCreateResponse,
  EmbeddedPromotionCreateRequest,
  EmbeddedPromotionUploadPresignedUrlResponse,
  EmbeddedPromotionUploadPresignedUrlRequest,
  ImportEmbeddedPromotionRequest,
} from './types'

export const offersApi = createApi({
  baseQuery,
  // TODO: MEXP-614 update these paths once we remove redux from the merchants portal
  reducerPath: 'Offers',
  tagTypes: [
    'Offers',
    'OffersStoreConfig',
    'Promotions',
    'Promotion',
    'PromotionProducts',
    'PromotionProductsDownload',
  ],
  endpoints: (build) => ({
    getOffers: build.query<Offer, { storeId?: string; productId?: string; version?: string }>({
      query: ({ storeId, productId, version = 'default' }) => ({
        url: '/offers',
        params: { storeId, productId },
        headers: {
          accept: `application/json; version=${version}`,
        },
      }),
      providesTags: (_, _err) => [{ type: 'Offers' }],
    }),
    getPromotions: build.query<EmbeddedPromotionsPage, { storeId?: string; version?: string }>({
      query: ({ storeId, version = '2022-02-01' }) => ({
        url: `/offers/stores/${storeId}/embedded-promotions`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version}`,
        },
      }),
      providesTags: (_, _err, { storeId }) => [{ type: 'Promotions', id: storeId }],
    }),
    getPromotion: build.query<
      EmbeddedPromotion,
      { storeId?: string; promotionId?: string; version?: string }
    >({
      query: ({ storeId, promotionId, version = '2022-02-01' }) => ({
        url: `/offers/stores/${storeId}/embedded-promotions/${promotionId}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version}`,
        },
      }),
      providesTags: (_, _err, { promotionId }) => [{ type: 'Promotion', id: promotionId }],
    }),
    updatePromotion: build.mutation<
      EmbeddedPromotion,
      { storeId: string; promotionId: string; data: Partial<EmbeddedPromotion>; version?: string }
    >({
      query: ({ storeId, promotionId, data, version = '2022-02-01' }) => ({
        url: `/offers/stores/${storeId}/embedded-promotions/${promotionId}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version}`,
        },
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_, _err, { storeId, promotionId }) => [
        { type: 'Promotions', id: storeId },
        { type: 'Promotion', id: promotionId },
      ],
    }),
    deletePromotion: build.mutation<
      EmbeddedPromotion,
      { storeId: string; promotionId: string; version?: string }
    >({
      query: ({ storeId, promotionId, version = '2022-02-01' }) => ({
        url: `/offers/stores/${storeId}/embedded-promotions/${promotionId}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version}`,
        },
        method: 'DELETE',
      }),
      invalidatesTags: (_, _err, { storeId }) => [{ type: 'Promotions', id: storeId }],
    }),
    getPromotionProducts: build.query<
      EmbeddedPromotionProductsPage,
      { storeId?: string; promotionId?: string; version?: string }
    >({
      query: ({ storeId, promotionId, version = '2022-02-01' }) => ({
        url: `/offers/stores/${storeId}/embedded-promotions/${promotionId}/products`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version}`,
        },
      }),
      providesTags: (_, _err, { promotionId }) => [{ type: 'PromotionProducts', id: promotionId }],
    }),
    downloadPromotionProducts: build.mutation<
      PromotionProductsMutateResponse,
      { storeId?: string; promotionId: string; email: string; version?: string }
    >({
      query: ({ storeId, promotionId, email, version = '2022-02-01' }) => ({
        url: `/offers/stores/${storeId}/embedded-promotions/${promotionId}/download`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version}`,
          'X-Idempotency-Key': `epp-download-request-${storeId}-${promotionId}-${email}`,
        },
      }),
      invalidatesTags: ['PromotionProductsDownload'],
    }),
    getEmbeddedPromotionDownloadPresignedUrl: build.mutation<
      PromotionProductsDownloadResponse,
      PromotionProductsDownloadRequest
    >({
      query: ({ storeId, promotionId, email, bulkDownloadKey }) => ({
        url: `/offers/stores/${storeId}/embedded-promotions/${promotionId}/downloadUrl?bulkDownloadKey=${bulkDownloadKey}`,
        method: 'POST',
        headers: {
          'X-Idempotency-Key': `epp-download-presigned-url-${storeId}-${promotionId}-${email}`,
        },
      }),
      invalidatesTags: (_, _err, { storeId }) => [
        { type: 'PromotionProductsDownload', id: storeId },
      ],
    }),
    createEmbeddedPromotion: build.mutation<
      EmbeddedPromotionCreateResponse,
      EmbeddedPromotionCreateRequest
    >({
      query: ({ storeId, body }) => ({
        url: `/offers/stores/${storeId}/embedded-promotions`,
        headers: { accept: 'application/json; version=latest;' },
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, _err, { storeId }) => [{ type: 'Promotions', id: storeId }],
    }),
    getEmbeddedPromotionUploadPresignedUrl: build.mutation<
      EmbeddedPromotionUploadPresignedUrlResponse,
      EmbeddedPromotionUploadPresignedUrlRequest
    >({
      query: ({ storeId, promotionId }) => ({
        url: `/offers/stores/${storeId}/embedded-promotions/${promotionId}/upload`,
        headers: { accept: 'application/json; version=latest;' },
        method: 'POST',
      }),
    }),
    importEmbeddedPromotion: build.mutation<string, ImportEmbeddedPromotionRequest>({
      query: ({ url, blob }) => {
        return {
          url,
          method: 'PUT',
          body: blob,
          headers: { 'content-type': 'application/gzip' },
        }
      },
    }),
    getOffersStoreConfiguration: build.query<
      OffersStoreConfig,
      { storeId: string; version?: string }
    >({
      query: ({ storeId, version = '2022-02-01' }) => ({
        url: `/offers/store-config/${storeId}`,
        headers: {
          accept: `application/json; version=${version}`,
        },
      }),
      providesTags: (_, _err) => [{ type: 'OffersStoreConfig' }],
    }),
    updateOffersStoreConfiguration: build.mutation<
      OffersStoreConfig,
      { storeId: string; offersStoreConfig: OffersStoreConfig; version?: string }
    >({
      query: ({ storeId, offersStoreConfig, version = '2022-02-01' }) => ({
        url: `/offers/store-config/${storeId}`,
        headers: {
          accept: `application/json; version=${version}`,
        },
        method: 'PUT',
        body: offersStoreConfig,
      }),
      invalidatesTags: (_, _err, { storeId }) => [{ type: 'OffersStoreConfig', storeId }],
    }),
  }),
})

export const {
  useGetOffersQuery,
  useGetOffersStoreConfigurationQuery,
  useUpdateOffersStoreConfigurationMutation,
  useGetPromotionsQuery,
  useGetPromotionQuery,
  useUpdatePromotionMutation,
  useDeletePromotionMutation,
  useGetPromotionProductsQuery,
  useDownloadPromotionProductsMutation,
  useGetEmbeddedPromotionDownloadPresignedUrlMutation,
  useCreateEmbeddedPromotionMutation,
  useGetEmbeddedPromotionUploadPresignedUrlMutation,
  useImportEmbeddedPromotionMutation,
} = offersApi
