// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0_webpack-cli@4.8.0_webpack-bundle-analyzer@4.4.2_webpack-dev-s_vrefs4evkprez4c3y7xwx6ru5q/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Ehol_mw8Roby-XQRXatke h4 {\n  font-weight: normal;\n  font-size: 16px;\n  text-transform: none;\n}\n", ""]);
// Exports
exports.locals = {
	"accordion-section-wrapper": "_3Ehol_mw8Roby-XQRXatke"
};
module.exports = exports;
