import * as Yup from 'yup'

const schema = Yup.object().shape({
  isLearnMoreModal: Yup.boolean(),
  isCartOffer: Yup.boolean(),
  learnMoreModalSelect: Yup.string()
    .oneOf(['V1', 'V2'])
    .when('isLearnMoreModal', {
      is: true,
      then: () => Yup.string().required('Version is required'),
    }),
  cartOfferSelect: Yup.string()
    .oneOf(['V1'])
    .when('isCartOffer', {
      is: true,
      then: () => Yup.string().required('Version is required'),
    }),
})

export { schema }
