import type { FC, FormEvent } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import type { DefaultMessage } from '@helloextend/extend-api-rtk-query'
import type { DatePickerPrompt, Reply } from '@helloextend/extend-api-client'
import { DatePicker } from '@helloextend/merchants-ui'
import { Button } from '@extend/zen'
import ReactMarkdown from 'react-markdown'
import { getMessagesContent } from '../../utils'
import type { UserInputComponentProps } from './types'

export interface AnswerDatePickerProps extends UserInputComponentProps<Reply & { prompt: DatePickerPrompt }> {}

const AnswerDatePicker: FC<AnswerDatePickerProps> = ({ isLoading, onSubmit, reply }) => {
  const [value, setValue] = useState<Date | null>(new Date())

  const handleChange = (date: Date | null): void => {
    if (date) {
      setValue(date)
    }
  }

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault()
    if (reply.prompt?.slot && value) {
      onSubmit(reply.prompt.slot, value.getTime())
    }
  }

  const content = getMessagesContent(reply.messages as DefaultMessage[])

  return (
    <ContentWrapper>
      {content && (
        <h2>
          <ReactMarkdown>{content}</ReactMarkdown>
        </h2>
      )}
      <DatePickerForm onSubmit={handleSubmit}>
        <DatePicker
          data-cy="date-stopped-working"
          selected={value}
          onChange={handleChange}
          calendarPosition="bottom"
        />
        <ButtonWrapper>
          <Button
            data-cy="continue-button"
            text="Continue"
            isDisabled={isLoading}
            isProcessing={isLoading}
            type="submit"
          />
        </ButtonWrapper>
      </DatePickerForm>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  width: '100%',
  maxWidth: 816,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
})

const DatePickerForm = styled.form({
  '.datepicker-form-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 56,
  },
  'div[class*="DateWrapper"]': {
    justifyContent: 'center',
  },
})

const ButtonWrapper = styled.div({
  marginTop: 32,
  display: 'flex',
  justifyContent: 'center',
})

export { AnswerDatePicker }
