import type { FC } from 'react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ModalController, Modal, useToaster, ToastColor, ToastDuration, COLOR } from '@extend/zen'
import type { ConversationResponse } from '@helloextend/extend-api-rtk-query'
import { usePatchConversationMutation } from '@helloextend/extend-api-rtk-query'
import type { ApiResponse, ErrorResponse } from '@helloextend/extend-api-fetch'
import styled from '@emotion/styled'
import { setIsArchiveConversationModalVisible } from '../../../../../store/slices/amp-slice'

type ArchiveConversationModalProps = {
  isVisible: boolean
  onCancel: () => void
  conversation: ConversationResponse | null
}

const ArchiveConversationModal: FC<ArchiveConversationModalProps> = ({
  isVisible,
  onCancel,
  conversation,
}) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()

  const [archiveConversation, { isLoading: isArchiveConversationProcessing }] =
    usePatchConversationMutation()

  const handleArchive = async (): Promise<void> => {
    if (!conversation) return
    try {
      const archivedConversation = await archiveConversation({
        conversationId: conversation.id,
        patches: [
          {
            op: 'replace',
            path: '/status',
            value: 'archived',
          },
        ],
      }).unwrap()

      toast({
        message: `${archivedConversation.title} has been successfully archived.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      dispatch(setIsArchiveConversationModalVisible(false))
    } catch (err: unknown) {
      const responsePayload = (err as ApiResponse)?.data
      const message = (responsePayload as ErrorResponse)?.message
      toast({
        message: message ?? 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="amp-archive-conversation-modal"
        heading="Archive Conversation"
        primaryButtonProps={{
          'data-cy': 'amp-archive-conversation-modal-submit',
          onClick: handleArchive,
          text: 'Archive',
          isDisabled: isArchiveConversationProcessing,
          isProcessing: isArchiveConversationProcessing,
        }}
        secondaryButtonProps={{
          'data-cy': 'amp-archive-conversation-modal-cancel',
          onClick: onCancel,
          text: 'Cancel',
        }}
        onDismissRequest={onCancel}
      >
        <Text data-cy="amp-archive-conversation-warning-message">
          Archiving a conversation prevents it from being assigned to conversation configurations.
        </Text>
        <Text data-cy="amp-archive-confirm-message">
          Are you sure you want to archive {conversation?.title || 'it'}?
        </Text>
      </Modal>
    </ModalController>
  )
}

const Text = styled.p({
  color: COLOR.NEUTRAL[800],
  fontSize: 16,
  lineHeight: '24px',
})

export type { ArchiveConversationModalProps }
export { ArchiveConversationModal }
