import type { ChangeEvent, FC } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import type { ValidImageExtension, AdvancedSelectChangeEvent } from '@extend/zen'
import {
  Button,
  Trash,
  COLOR,
  TextArea,
  ImageInput,
  Switch,
  AdvancedSelect,
  useToaster,
  isFileValidImage,
  isFileValidSize,
  ToastColor,
  ToastDuration,
} from '@extend/zen'
import { useDispatch, useSelector } from 'react-redux'
import { useCreatePhotoMutation } from '@helloextend/extend-api-rtk-query'
import type { PhotoConditionSlot } from '@extend-conversations/types'
import {
  updatePhotoRequirementConditions,
  deletePhotoRequirement,
  updatePhotoRequirement,
} from '../../../../../store/slices/amp-slice'
import type { PhotoRequirement } from '../../../../../types/conversations'
import { getBase64, generatePhotoConditionOptions } from '../../utils'
import type { RootState } from '../../../../../reducers'
import { getSelectedThread } from '../../../../../reducers/selectors'
import Tags from './tags'
import { DeletePhotoRequirementModal } from './delete-photo-requirement-modal'

type PhotoRequirementFormProps = {
  index: number
  photoRequirement: PhotoRequirement
}

const MAX_FILE_SIZE_MB = 1
const SUPPORTED_FILE_EXTENSIONS: ValidImageExtension[] = ['jpeg', 'jpg', 'png']

const PhotoRequirementForm: FC<PhotoRequirementFormProps> = (props: PhotoRequirementFormProps) => {
  const [createPhoto] = useCreatePhotoMutation()
  const { toast } = useToaster()
  const dispatch = useDispatch()

  const { photoRequirement, index } = props
  const { description, isAlwaysRequired, conditions, tags } = photoRequirement
  const selectedThread = useSelector((state: RootState) => getSelectedThread(state))

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false)

  // on photo requirement save, the image url will be added
  const imageUrl: string | undefined = useMemo(() => {
    return photoRequirement?.imageUrl
  }, [photoRequirement.imageUrl])

  const toggleIsAlwaysRequired = (): void => {
    dispatch(updatePhotoRequirement({ index, updates: { isAlwaysRequired: !isAlwaysRequired } }))
  }

  const onDeleteClick = (): void => {
    dispatch(deletePhotoRequirement(index))
    setIsDeleteModalVisible(false)
  }

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const imageFile = event.target.files && event.target.files[0]

    if (!imageFile) {
      dispatch(updatePhotoRequirement({ index, updates: { objectId: undefined } }))
      return
    }

    const isValidFile =
      isFileValidImage(imageFile, SUPPORTED_FILE_EXTENSIONS) &&
      isFileValidSize(imageFile, MAX_FILE_SIZE_MB)

    if (!isValidFile) {
      return
    }

    try {
      const base64 = await getBase64(imageFile)
      if (base64) {
        const { id } = await createPhoto({ photo: base64 }).unwrap()
        dispatch(updatePhotoRequirement({ index, updates: { objectId: id } }))
      }
    } catch (e: unknown) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }
  const onConditionSelect = (
    event: AdvancedSelectChangeEvent<string[]>,
    slot: PhotoConditionSlot,
  ) => {
    dispatch(
      updatePhotoRequirementConditions({
        index,
        slot,
        values: event.target.value,
      }),
    )
  }

  const onDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    dispatch(updatePhotoRequirement({ index, updates: { description: event.target.value } }))
  }

  const conditionOptions = generatePhotoConditionOptions(selectedThread ?? undefined)
  const failureTypeValues = (conditions || [])
    .filter((condition) => condition.slot === 'FailureType')
    .map((condition) => condition.value)
  const failureCauseValues = (conditions || [])
    .filter((condition) => condition.slot === 'FailureCause')
    .map((condition) => condition.value)

  return (
    <Container data-cy={`photo-requirements-form-${index}`}>
      <DeletePhotoRequirementModal
        isVisible={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        onDelete={onDeleteClick}
      />
      <HeaderContainer>
        <HeaderText data-cy="photo-header">Photo {index + 1}</HeaderText>
      </HeaderContainer>
      <MainContentContainer>
        <PhotoUploadContainer data-cy="photo-requirement-upload">
          <TextArea
            label="MyExtend Claim Step"
            placeholder="Text will instruct customers what type of photo to upload."
            data-cy={`photo-requirement-description-${index}`}
            value={description}
            rows={2}
            id={`photo-requirement-description-${index}`}
            onChange={onDescriptionChange}
          />
          <ImageInputContainer>
            <ImageInput
              label="Sample Image (optional)"
              data-cy={`photo-requirement-image-input-${index}`}
              id={`photo-requirement-image-input-${index}`}
              height={145}
              imageExtensions={SUPPORTED_FILE_EXTENSIONS}
              maxSizeMb={MAX_FILE_SIZE_MB}
              helperText="Upload a sample image to show customers what type of photo to upload."
              onChange={handleImageChange}
              currentImage={imageUrl}
            />
          </ImageInputContainer>
        </PhotoUploadContainer>
        <PhotoRequirementTagsContainer data-cy="photo-requirement-tags">
          <Switch
            id={`always-required-switch-${index}`}
            data-cy={`always-required-switch-${index}`}
            disabledTooltip=""
            label="Always Required"
            labelPosition="before"
            isOn={isAlwaysRequired}
            onChange={toggleIsAlwaysRequired}
          />
          {!isAlwaysRequired && (
            <>
              <SelectContainer>
                <AdvancedSelect
                  id="failureType"
                  label="Failure Type"
                  data-cy={`failure-type-multi-select-${index}`}
                  multiple
                  maxQuantityToDisplay={5}
                  onChange={(event) => {
                    onConditionSelect(event, 'FailureType')
                  }}
                  options={conditionOptions.FailureType}
                  placeholder="Select"
                  value={failureTypeValues}
                />
              </SelectContainer>
              <SelectContainer>
                <AdvancedSelect
                  id="failureCause"
                  label="Failure Cause"
                  data-cy={`failure-cause-multi-select-${index}`}
                  multiple
                  maxQuantityToDisplay={5}
                  onChange={(event) => {
                    onConditionSelect(event, 'FailureCause')
                  }}
                  options={conditionOptions.FailureCause}
                  placeholder="Select"
                  value={failureCauseValues}
                />
              </SelectContainer>
            </>
          )}
          <SelectContainer>
            <Tags index={index} tags={tags ?? []} />
          </SelectContainer>
        </PhotoRequirementTagsContainer>
      </MainContentContainer>
      <FooterContainer>
        <Button
          text="Delete"
          color="blue"
          data-cy={`photo-requirement-delete-button-${index}`}
          emphasis="medium"
          icon={Trash}
          onClick={() => setIsDeleteModalVisible(true)}
        />
      </FooterContainer>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  width: '800px',
  padding: '24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 16,
  borderRadius: '4px',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
})

const MainContentContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 24,
  alignSelf: 'stretch',
  height: '100%',
})
const FooterContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignSelf: 'stretch',
  gap: 10,
  paddingTop: '16px',
})
const HeaderContainer = styled.div({
  display: 'flex',
  width: '752px',
  height: ' 29px',
  justifyContent: 'start',
  alignItems: 'start',
})
const HeaderText = styled.span({
  color: COLOR.NEUTRAL[1000],
  fontFeatureSettings: `clig off, liga off`,
  fontFamily: 'Nunito Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '18px',
  textTransform: 'uppercase',
})

const PhotoUploadContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 24,
  '& > *': {
    width: '100%',
  },
})

const ImageInputContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flexStart',
  gap: 8,
  width: '360px',
  height: '180px',
})

const PhotoRequirementTagsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 16,
  flex: '1 0 0',
})

const SelectContainer = styled.div({
  width: '100%',
})

export { PhotoRequirementForm }
