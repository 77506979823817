import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Information, Radio } from '@extend/zen'
import type { StoreIntegrationSettings } from '@helloextend/extend-api-client'
import type { FormikHandlers } from 'formik'

type OfferSyncTypeProps = {
  offerSyncType: StoreIntegrationSettings['offerSyncType']
  isDisabled: boolean
  handleChange: FormikHandlers['handleChange']
}

const OfferSyncTypeRadio: FC<OfferSyncTypeProps> = ({
  offerSyncType,
  isDisabled,
  handleChange,
}) => {
  return (
    <div data-cy="offer-sync-type-radio">
      <HeaderWrapper>
        <OfferSyncTypeHeader>Offer Sync Type</OfferSyncTypeHeader>
        <InfoWrapper>
          <Information buttonSize="xsmall" data-cy="offer-sync-type-tooltip">
            <Text>The two ways shopint-2 syncs its offer</Text>
          </Information>
        </InfoWrapper>
      </HeaderWrapper>
      <RadioWrapper>
        <Radio
          name="offerSyncType"
          checked={offerSyncType === 'off'}
          onChange={handleChange}
          label="No offer sync"
          value="off"
          disabled={isDisabled}
        />
        <Radio
          name="offerSyncType"
          checked={offerSyncType === 'directMap'}
          onChange={handleChange}
          label="Direct map"
          value="directMap"
          disabled={isDisabled}
        />
        <Radio
          name="offerSyncType"
          checked={offerSyncType === 'pricePoints'}
          onChange={handleChange}
          label="Price points"
          value="pricePoints"
          disabled={isDisabled}
        />
      </RadioWrapper>
    </div>
  )
}

const HeaderWrapper = styled.div({
  display: 'flex',
})

const OfferSyncTypeHeader = styled.h3({
  fontSize: 14,
  margin: '8px 0',
  lineHeigh: '16px',
  color: COLOR.NEUTRAL[1000],
})

const RadioWrapper = styled.span({
  label: {
    fontSize: 15,
    marginBottom: 0,
    lineHeight: '18px',
  },
})

const Text = styled.p({
  margin: 0,
})

const InfoWrapper = styled.div({
  alignSelf: 'center',
})

export { OfferSyncTypeRadio }
