import * as Yup from 'yup'

const schema = Yup.object().shape({
  isLearnMoreModal: Yup.boolean(),
  isOfferCart: Yup.boolean(),
  isOfferModal: Yup.boolean(),
  isProductDetailsPage: Yup.boolean(),
  isOrderConfirmationOffer: Yup.boolean(),
  learnMoreModalSelect: Yup.string()
    .oneOf(['V1'])
    .when('isLearnMoreModal', {
      is: true,
      then: () => Yup.string().required('Version is required'),
    }),
  offerCartSelect: Yup.string()
    .oneOf(['V1', 'V2'])
    .when('isOfferCart', {
      is: true,
      then: () => Yup.string().required('Version is required'),
    }),
  offerModalSelect: Yup.string()
    .oneOf(['V1', 'V2'])
    .when('isOfferModal', {
      is: true,
      then: () => Yup.string().required('Version is required'),
    }),
  productDetailsPageSelect: Yup.string()
    .oneOf(['V1', 'V2'])
    .when('isProductDetailsPage', {
      is: true,
      then: () => Yup.string().required('Version is required'),
    }),
  orderConfirmationOfferSelect: Yup.string()
    .oneOf(['V1'])
    .when('isOrderConfirmationOffer', {
      is: true,
      then: () => Yup.string().required('Version is required'),
    }),
})

export { schema }
