import React from 'react'
import type { Sku } from '@helloextend/extend-api-rtk-query'
import { formatDateWithTimezone } from '../../../../../utils/date-formatting'
import type { CellContext, ColumnDefs } from '@extend/zen'
import { Link } from 'react-router-dom'

const skuVersionsColumns: ColumnDefs<Sku> = [
  {
    id: 'version',
    label: 'Version',
    isSortable: true,
    renderCell: (data: CellContext<Sku, string>) => {
      return (
        <Link to={`/admin/premiums/${data.row.original.id}?version=${data.getValue()}`}>
          {data.getValue()}
        </Link>
      )
    },
  },
  {
    id: 'createdAt',
    label: 'Date Activated',
    renderCell: (data: CellContext<Sku, string>): JSX.Element => {
      const { createdBy } = data.row.original as Sku
      return (
        <div>
          {data ? formatDateWithTimezone(parseInt(data.getValue())) : '-'} by {createdBy}
        </div>
      )
    },
  },
  {
    id: 'deactivatedAt',
    label: 'Date Deactivated',
    renderCell: (data: CellContext<Sku, string>) => {
      const { updatedBy } = data.row.original as Sku
      return (
        <div>
          {data ? formatDateWithTimezone(parseInt(data.getValue())) : '-'} by {updatedBy}
        </div>
      )
    },
  },
]

export { skuVersionsColumns }
