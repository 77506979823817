import type { FC } from 'react'
import React, { useState, useEffect } from 'react'
import type { Product, ProductsSearchItem } from '@helloextend/extend-api-client'
import { useUpdateProductMutation } from '@helloextend/extend-api-rtk-query'
import { Switch } from '@extend/zen'
import { useParams } from 'react-router-dom'
import { useStandardToast } from '@helloextend/merchants-ui'

type DisplayOfferToggleProps = {
  isEnabled: boolean
  product: ProductsSearchItem | Product
}

const DisplayOfferToggle: FC<DisplayOfferToggleProps> = ({ isEnabled, product }) => {
  const [isOfferEnabled, setIsOfferEnabled] = useState(isEnabled)
  const [updateProduct] = useUpdateProductMutation()
  const { toastError, toastCompleted } = useStandardToast()
  const { storeId } = useParams<{ storeId: string }>()
  const { referenceId, warrantyStatus } = product
  const isToggleDisabled = warrantyStatus !== 'warrantable' && !isOfferEnabled

  // used as a workaround for isOfferEnabled state not being set correctly
  useEffect(() => {
    setIsOfferEnabled(isEnabled)
  }, [isEnabled])

  const handleToggleOnChange = async (): Promise<void> => {
    try {
      await updateProduct({
        storeId,
        productId: referenceId,
        data: {
          enabled: !isEnabled,
        },
        version: 'latest',
      }).unwrap()

      toastCompleted(isOfferEnabled ? 'Offer display turned off' : 'Offer display turned on')
      setIsOfferEnabled(!isOfferEnabled)
    } catch {
      toastError('Offer display could not be toggled. Please try again later')
    }
  }
  return (
    <Switch
      data-cy="product-display-offer-toggle"
      isOn={isOfferEnabled}
      onChange={handleToggleOnChange}
      isDisabled={isToggleDisabled}
    />
  )
}

export { DisplayOfferToggle }
