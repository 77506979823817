import type { InvokeNeed, Need, Option, Pattern } from '@helloextend/extend-api-rtk-query'
import { KaleyConditionTypes, getIndex } from '../../utils'
import { mapSuccessToCode } from '../../kaley-conditions/utils'

export interface KaleyConditionValues {
  value: string
  index: number | string
}

export function getKaleyConditionTitle(needs: Need[], type?: string): string | undefined {
  const invokeNeed: InvokeNeed | undefined = needs.find((need: Need): need is InvokeNeed => {
    return 'invoke' in need
  })

  const hasConditionTypeMatch = Object.keys(KaleyConditionTypes).find(
    (conditionType) => invokeNeed && conditionType === invokeNeed.invoke,
  )

  if (invokeNeed && !hasConditionTypeMatch) {
    return invokeNeed.invoke
  }
  return type
}

export function getKaleyConditionValues(options: Option[], type?: string): KaleyConditionValues[] {
  return options
    .map((option: Option): KaleyConditionValues[] => {
      const index: number | string = getIndex(option)

      if (option.default && !option.patterns) {
        return [{ value: 'none of the above', index }]
      }

      if (option.patterns) {
        return getValuesFromPatterns(option.patterns, index, type)
      }

      throw new Error('Improperly formatted script option, must have default or patterns')
    })
    .reduce((acc, currentValue) => {
      return acc.concat(currentValue)
    }, [] as KaleyConditionValues[])
}

export function getValuesFromPatterns(
  patterns: Pattern[],
  index: number | string,
  type?: string,
): KaleyConditionValues[] {
  return patterns.map((pattern: Pattern): KaleyConditionValues => {
    if (pattern.input) {
      return { value: `customer supplied: ${pattern.input}`, index }
    }

    if (pattern.params) {
      const keys: string[] = Object.keys(pattern.params)
      const hasMultipleResultUsages =
        keys.filter((key: string) => key.startsWith('result')).length > 1

      const values: string[] = []
      for (const key of keys) {
        // We only want to show result.state if were not using another result property
        // Essentially do not show failure if we are already going to show the error code
        if (key === 'result.state' && !hasMultipleResultUsages) {
          if (pattern.params[key] === 'success' && type) {
            const mappedSuccess = mapSuccessToCode(type as KaleyConditionTypes)
            values.push(mappedSuccess.replace(/_/g, ' '))
          } else {
            values.push(String(pattern.params[key]))
          }
        } else if (key === 'result.errorCode') {
          values.push(String(pattern.params[key]).replace(/_/g, ' '))
        } else if (!key.startsWith('result')) {
          values.push(`${key} is equal to ${pattern.params[key]}`)
        }
      }
      return { value: values.join(' & '), index }
    }
    throw new Error('Improperly formatted pattern block, must have input or params')
  })
}

export function getPatternValue(patterns: Pattern[] | undefined, type?: string): string[] {
  if (!patterns) throw new Error('No patterns supplied')

  return patterns.map((pattern: Pattern): string => {
    if (pattern.input) {
      return String(pattern.input)
    }

    if (pattern.params) {
      const keys: string[] = Object.keys(pattern.params)
      const hasMultipleResultUsages =
        keys.filter((key: string) => key.startsWith('result')).length > 1

      for (const key of keys) {
        // We only want to show result.state if were not using another result property
        // Essentially do not show failure if we are already going to show the error code
        if (key === 'result.state' && !hasMultipleResultUsages) {
          if (pattern.params[key] === 'success' && type) {
            return mapSuccessToCode(type as KaleyConditionTypes)
          }
          return String(pattern.params[key])
        }

        if (key === 'result.errorCode') {
          return String(pattern.params[key])
        }
      }
    }
    throw new Error('Improperly formatted pattern block, must have input or params')
  })
}
