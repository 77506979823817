import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useFetchPlanQuery } from '@helloextend/extend-api-rtk-query'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { useStandardToast } from '@helloextend/merchants-ui'
import { COLOR, Spinner } from '@extend/zen'
import { mapSchemaToValues } from '../../../../../../../../utils/plans-mapping'
import { PricingDetailsRow } from '../pricing-details-row'

type PricingDetailsTableProps = {
  planId: string
  storeId: string
  currencyCode: string
}

const PricingDetailsTable: FC<PricingDetailsTableProps> = ({ planId, storeId, currencyCode }) => {
  const { data: planData, error, isError, isLoading } = useFetchPlanQuery(planId)
  const history = useHistory()
  const { toastError } = useStandardToast()

  if ((error as FetchBaseQueryError)?.status === 404) {
    toastError('The plan could not be found. You are being redirected to the Plan & Pricing page.')
    history.push(`/admin/stores/${storeId}/product-protection`)
  }

  const mappedPlanData = useMemo(() => mapSchemaToValues(planData), [planData])

  if (isError) {
    return null
  }

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  return (
    <Container data-cy="pricing-details-table">
      {mappedPlanData?.pricing?.map((pricingRow, index) => (
        <PricingDetailsRow
          pricingRow={pricingRow}
          currencyCode={currencyCode}
          isLabelDisplayed={index === 0}
          key={pricingRow.vendorSku}
        />
      ))}
    </Container>
  )
}

const Container = styled.div({
  paddingTop: 32,
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { PricingDetailsTable }
