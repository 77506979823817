import type { FC } from 'react'
import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import type { ContractFormValuesUpdate, ContractNote } from '@helloextend/extend-api-client'
import { ClaimSelectStatus, ContractType } from '@helloextend/extend-api-client'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { PageError, Toast } from '@helloextend/merchants-ui'
import { usePrevious } from '@helloextend/client-hooks'
import {
  Breadcrumbs,
  Button,
  Stack,
  COLOR,
  Warning,
  InlineAlert,
  InlineAlertColor,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import {
  useGetClaimNotesQuery,
  useGetContract20220201Query,
  useGetPlanTermsVersionLanguageUrlQuery,
  useGetRefundQuoteByContractIdMutation,
  useListInsuranceClaimsQuery,
  usePrecheckQuery,
} from '@helloextend/extend-api-rtk-query'
import type { PrecheckResponse } from '@helloextend/extend-api-rtk-query'
import { isEmpty } from 'lodash'
import { RefundModal } from '../../../../../components/refund-modal/refund-modal'
import * as contractsActions from '../../../../../actions/contracts'
import { TabBar } from '../../../../../components/tab-bar'
import { LeavePageGuard } from '../../../../../components/leave-page-guard'
import { SaveContractModal } from '../../../../../components/save-contract-modal'
import { SuccessHeaderPopup } from '../../../../../components/success-popup'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'
import { DashboardSpinner } from '../../../../../components/dashboard-spinner'
import { ResendDetailsModal } from '../resend-details-modal'
import { CellStatusBadge } from '../../../../../components/cell-status-badge'
import { getContractStatusCopy } from '../../../../../lib/contract-status'
import { HeaderDetails } from '../header-details'
import { getTooltipText } from '../../../../../lib/claim-button-disable'
import { ContractNotes } from '../contract-notes/contract-notes'
import { contractNotesEscalationDescription } from 'src/lib/escalation-levels'

interface ContractContextValue {
  contract: ReturnType<typeof useGetContract20220201Query>['data']
  saveUpdates?: (formValues: ContractFormValuesUpdate, openModal?: boolean) => void
  toggleNavBlocked?: (isNavBlocked: boolean) => void
  isClaimsSearchLoading?: boolean
  claimData?: ReturnType<typeof useListInsuranceClaimsQuery>['data']
  claimNotes?: ReturnType<typeof useGetClaimNotesQuery>['data']
  planTermsUrl?: string
  orderUrl?: string
  setIsSaveModalVisible?: (isVisible: boolean) => void
  handleEscalationNote?: (note: ContractNote) => void
}

const ContractContext = createContext<ContractContextValue | null>(null)

const ContractContainer: FC = ({ children }) => {
  const { contractId } = useParams<{ contractId: string }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const isContractUpdateSuccessful = useSelector((state: RootState) =>
    selectors.getIsContractUpdateSuccess(state),
  )
  const { toast } = useToaster()
  const {
    data: contract,
    isLoading: isContractLoading,
    isError: isContractError,
  } = useGetContract20220201Query({
    contractId,
  })
  const { data: precheckResponse } = usePrecheckQuery(
    { contractId },
    {
      skip: isEmpty(contract),
    },
  )

  const orderUrl = contract?.orderId ? `/admin/orders/${contract?.orderId}` : ''

  const { data: planTermsUrl } = useGetPlanTermsVersionLanguageUrlQuery(
    {
      termsId: contract?.plan.termsId || '',
      version: parseInt(contract?.plan.termsVersion || ''),
    },
    { skip: !contract },
  )

  const [getRefundQuoteByContractId, { isLoading: isQuoteLoading }] =
    useGetRefundQuoteByContractIdMutation()

  const contractError = useSelector((state: RootState) => selectors.getContractsError(state))
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false)
  const [refundAmount, setRefundAmount] = useState<number | undefined>(undefined)
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false)
  const [isNavBlocked, setIsNavBlocked] = useState(false)
  const [updateValues, setUpdateValues] = useState<ContractFormValuesUpdate>()
  const [escalationNote, setEscalationNote] = useState<ContractNote | undefined>(undefined)
  const [showEscalationBanner, setShowEscalationBanner] = useState<boolean>(false)

  const isContractUpdating = useSelector((state: RootState) =>
    selectors.getIsContractUpdating(state),
  )
  const prevUpdating = usePrevious(isContractUpdating)
  const [hasUpdateError, setHasUpdateError] = useState<boolean>(false)
  const [isResendDetailsModalVisible, setIsResendDetailsModalVisible] = useState<boolean>(false)

  const handleEscalationNote = useCallback((note: ContractNote) => {
    setEscalationNote(note)
    setShowEscalationBanner(!note.isResolved)
  }, [])

  const toggleNavBlocked = (blocked: boolean): void => {
    setIsNavBlocked(blocked)
  }

  const handleLeavePage = (path: string): void => {
    history.push(path)
  }

  const toggleSaveModalVisibility = (): void => {
    setIsSaveModalVisible(!isSaveModalVisible)
  }

  const toggleRefundModalVisibility = async (): Promise<void> => {
    const response = await getRefundQuoteByContractId({ contractId })
    if ('error' in response) {
      toast({
        message: 'Failed to get refund quote.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      return
    }

    setRefundAmount(response.data?.refundAmounts.customer)
    setIsRefundModalVisible(!isRefundModalVisible)
  }

  const toggleResendModalVisibility = (): void => {
    setIsResendDetailsModalVisible(!isResendDetailsModalVisible)
  }

  const saveUpdates = (values: ContractFormValuesUpdate, openModal = true): void => {
    if (values.productUpdate) {
      setUpdateValues(values)
    } else {
      setUpdateValues({ contractUpdate: values.contractUpdate })
    }

    if (openModal) setIsSaveModalVisible(true)
  }

  const onAnimationEnd = (): void => {
    setTimeout(() => {
      dispatch(contractsActions.updateSuccessReset())
    }, 1500)
  }

  const onToastAnimationEnd = (): void => {
    setTimeout(() => {
      dispatch(contractsActions.errorReset())
      setHasUpdateError(false)
    }, 1500)
  }

  const handleBackClick = (): void => {
    history.push('/admin/contracts')
  }

  useEffect(() => {
    if (prevUpdating && contractError) {
      setHasUpdateError(true)
    }
  }, [prevUpdating, contractError])

  const queryParams = useMemo(() => {
    const matchesClaimStatus = [
      ClaimSelectStatus.review,
      ClaimSelectStatus.approved,
      ClaimSelectStatus.denied,
      ClaimSelectStatus.fulfilled,
      ClaimSelectStatus.closed,
      ClaimSelectStatus.pending_adjudication,
    ]

    return {
      sellerId: contract?.sellerId,
      containsContractId: contractId,
      matchesClaimStatus,
    }
  }, [contract?.sellerId, contractId])

  const { data: claimsData, isLoading: isClaimsSearchLoading } = useListInsuranceClaimsQuery(
    queryParams,
    {
      refetchOnMountOrArgChange: true,
      skip: !contract,
    },
  )

  const filteredClaimsByStatus = useMemo(() => {
    const validClaimStatuses = new Set([
      ClaimSelectStatus.review,
      ClaimSelectStatus.approved,
      ClaimSelectStatus.pending_adjudication,
    ])
    return claimsData?.items?.filter((claim) =>
      validClaimStatuses.has(claim.status as ClaimSelectStatus),
    )
  }, [claimsData?.items])

  const isIndividualClaimButtonDisabled = (precheck: PrecheckResponse | undefined): boolean => {
    if (!precheck || precheck.status === 'failure') return true
    const hasNoActiveClaims = Object.values(precheck.lineItems ?? []).some(
      (lineItemStatus) => !lineItemStatus.hasActiveClaim,
    )
    return !hasNoActiveClaims
  }

  const items = claimsData?.items
  const claims = Array.isArray(items) && items.length > 0 ? claimsData : []

  // There had been calls to the claim notes get endpoint with a claimId of `undefined`
  // This should ensure that no `undefined` claimIds are used in the query
  const claimIds = items?.map((claim) => claim.id).filter((claimId) => !!claimId)
  const { claimNotes } = useGetClaimNotesQuery(
    { claimIds },
    {
      skip: !claimIds || claimIds.length === 0,
      selectFromResult: ({ data }) => ({
        claimNotes: [...(data?.items || [])].sort((a, b) => b.createdAt - a.createdAt),
      }),
    },
  )

  const hasExistingClaims = Boolean(filteredClaimsByStatus?.length)
  const isRefundButtonDisabled =
    hasExistingClaims ||
    isClaimsSearchLoading ||
    (contract?.status !== 'live' && contract?.status !== 'created') ||
    isContractLoading

  if (isContractError && !hasUpdateError && !prevUpdating) {
    return (
      <ErrorWrapper>
        <PageError data-cy="error-message" handleBackClick={handleBackClick} />
      </ErrorWrapper>
    )
  }

  if (!contract || isContractLoading) return <DashboardSpinner />

  const contextValue = {
    contract,
    claimData: claims,
    claimNotes,
    saveUpdates,
    toggleNavBlocked,
    isClaimsSearchLoading,
    planTermsUrl: planTermsUrl?.url,
    orderUrl,
    setIsSaveModalVisible,
    handleEscalationNote,
  }

  const escalationDescription =
    escalationNote?.escalationLevel &&
    contractNotesEscalationDescription[escalationNote.escalationLevel]

  return (
    <>
      <ContractContext.Provider value={contextValue}>
        <LeavePageGuard isNavBlocked={isNavBlocked} handleLeavePage={handleLeavePage} />
        {isContractUpdateSuccessful && (
          <SuccessHeaderPopup message="Contract changes saved!" onAnimationEnd={onAnimationEnd} />
        )}
        {hasUpdateError && (
          <Toast
            message="Changes couldn’t be saved due to a system error. Please try again later."
            onAnimationEnd={onToastAnimationEnd}
            isSuccessToaster={false}
          />
        )}
        <SaveContractModal
          isVisible={isSaveModalVisible}
          toggle={toggleSaveModalVisibility}
          contractId={contractId}
          updateValues={updateValues}
        />
        <ResendDetailsModal
          isVisible={isResendDetailsModalVisible}
          contract={contract}
          toggle={toggleResendModalVisibility}
          data-cy="resend-modal"
        />
        <RefundModal
          visible={isRefundModalVisible}
          contractId={contractId}
          amount={refundAmount}
          closeModal={toggleRefundModalVisibility}
          data-cy="refund-modal"
        />
        <HeaderContainer>
          <Stack isRow justify="space-between" doesWrap>
            <Header>
              <Breadcrumbs
                crumbs={[
                  {
                    text: 'Contracts',
                    to: '/admin/contracts',
                  },
                  {
                    text: `Contract ID: ${contractId}`,
                  },
                ]}
                data-cy="contract-details-breadcrumbs"
                max-width="50ch"
              />
              <TitleContainer>
                <Title data-cy="contract-id-header">{contract.customer.name}&apos;s Contract</Title>
                <CellStatusBadge badgeData={getContractStatusCopy(contract.status)} />
              </TitleContainer>
            </Header>
            <ButtonContainer>
              <Button
                emphasis="medium"
                text="Refund"
                data-cy="refund-button"
                onClick={toggleRefundModalVisibility}
                isDisabled={isRefundButtonDisabled}
                isProcessing={isQuoteLoading}
              />
              {![ContractType.CATEGORY, ContractType.PRODUCT_PROTECTION_BUNDLE].includes(
                contract?.type,
              ) && (
                <Button
                  emphasis="medium"
                  text="File a Claim"
                  data-cy="file-claim-button"
                  onClick={() => handleLeavePage(`/admin/contracts/${contractId}/file-a-claim`)}
                  isDisabled={precheckResponse?.status === 'failure'}
                  tooltip={getTooltipText(precheckResponse)}
                />
              )}
              {contract?.type === ContractType.CATEGORY && (
                <Button
                  emphasis="medium"
                  text="File Claim for Individual Product"
                  data-cy="goto-products-list-button"
                  onClick={() => {
                    history.push('#products')
                  }}
                  isDisabled={isIndividualClaimButtonDisabled(precheckResponse)}
                />
              )}
              <Button
                emphasis="medium"
                text="Resend Email"
                data-cy="resend-button"
                onClick={() => toggleResendModalVisibility()}
                isDisabled={contract?.status !== 'live'}
              />
            </ButtonContainer>
            {showEscalationBanner && (
              <SubheaderContainer>
                <InlineAlert color={InlineAlertColor.yellow} icon={Warning}>
                  {escalationDescription}
                </InlineAlert>
              </SubheaderContainer>
            )}
            <SubheaderContainer>
              <HeaderDetails contract={contract} />
            </SubheaderContainer>
          </Stack>
        </HeaderContainer>
        <TabBar
          tabBarLinks={[
            { to: `/admin/contracts/${contractId}`, text: 'Contract Details' },
            {
              to: `/admin/contracts/${contractId}/claims`,
              text: 'Claims',
              tabBadge:
                (filteredClaimsByStatus?.length && {
                  color: 'blue',
                  text: String(filteredClaimsByStatus?.length),
                }) ||
                undefined,
            },
            { to: `/admin/contracts/${contractId}/auditlog`, text: 'Audit Log' },
          ]}
        />
        {children}
        <ContractNotes contract={contract} handleEscalationNote={handleEscalationNote} />
      </ContractContext.Provider>
    </>
  )
}

const ButtonContainer = styled.div({
  display: 'flex',
  gap: 8,
})

const HeaderContainer = styled.div({
  marginBottom: 32,
})

const Header = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const TitleContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

const Title = styled.h1({
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
  marginTop: 0,
  marginBottom: 4,
  marginRight: 16,
})

const SubheaderContainer = styled.div({
  width: '100%',
  marginBottom: 8,
})

const ErrorWrapper = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 535,
  margin: '0 auto',
})

export type { ContractContextValue }
export { ContractContainer, ContractContext }
