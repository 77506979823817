import type { FC, SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'
import type { ClaimClosedResolution } from '@helloextend/extend-api-client/src/models/claim'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { ButtonGroup, Button } from '@extend/zen'
import type { Claim } from '@helloextend/extend-api-client'
import { Select, SelectItem } from '../../../../../../components/select'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { useUpdateClaim } from '../../../../../../hooks'

interface CloseClaimDropdownProps {
  claim: Claim
}

const CloseClaimDropdown: FC<CloseClaimDropdownProps> = ({ claim }) => {
  const dispatch = useDispatch()
  const [resolution, setResolution] = useState<ClaimClosedResolution | null>(null)
  const { updateClaim, isSuccess } = useUpdateClaim()

  const handleSubmit = async (): Promise<void> => {
    if (!resolution) return
    await updateClaim({
      claimId: claim.id,
      body: {
        status: 'closed',
        resolution,
      },
    })
    dispatch(setClaimDetailsActiveView(''))
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(setClaimDetailsToast('Claim closed!'))
    }
  }, [isSuccess, dispatch])

  return (
    <ContentWrapper>
      <DataRow>
        <ItemWrapper>
          <LabelWrapper>
            <LabelText>Reason</LabelText>
          </LabelWrapper>
          <Select
            onChange={(e: SyntheticEvent<Element>): void => {
              const { value } = e.currentTarget as HTMLInputElement
              setResolution((value as ClaimClosedResolution) || null)
            }}
            value={resolution ?? ''}
            placeholder="—SELECT—"
            data-cy="close-resolution-select"
          >
            <SelectItem
              key="no_service"
              value="no_service"
              label="No service required"
              data-cy="select-item-no-service"
            />
            {claim.status === 'pending_adjudication' && (
              <SelectItem
                key="no_images_submitted"
                value="no_images_submitted"
                label="No images submitted"
                data-cy="select-item-no-images-submitted"
              />
            )}
            <SelectItem value="" label="—SELECT—" />
          </Select>
        </ItemWrapper>
      </DataRow>
      <ButtonRow>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
          />
          <Button
            type="button"
            text="Submit"
            data-cy="submit-button"
            onClick={handleSubmit}
            isDisabled={!resolution}
          />
        </ButtonGroup>
      </ButtonRow>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

const DataRow = styled.div({
  display: 'flex',
  width: '100%',
})

const ButtonRow = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
})

const ItemWrapper = styled.div({
  width: '100%',
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
})

const LabelWrapper = styled.label({
  fontFamily: 'Nunito Sans, sans-serif',
})

const LabelText = styled.div({
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
  lineHeight: '24px',
})

export { CloseClaimDropdown }
