import type { FC, SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'
import { FailureType } from '@helloextend/extend-api-client/src/models/claim'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { ButtonGroup, Button } from '@extend/zen'
import type { Claim } from '@helloextend/extend-api-client'
import { Select, SelectItem } from '../../../../../../components/select'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { formatStartCase } from '../../../util'
import { useUpdateClaim } from '../../../../../../hooks'

interface ApproveClaimContentProps {
  claim: Claim
}

const ApproveClaimContent: FC<ApproveClaimContentProps> = ({ claim }) => {
  const dispatch = useDispatch()
  const [failureType, setFailureType] = useState<FailureType | null>(null)
  const { updateClaim, isSuccess } = useUpdateClaim()

  const failureTypes = Object.keys(FailureType)

  const handleSubmit = async (): Promise<void> => {
    if (!failureType) return
    await updateClaim({
      claimId: claim.id,
      body: {
        status: 'approved',
        customer: claim.customer,
        incident: { ...claim.incident, failureType },
      },
    })
    dispatch(setClaimDetailsActiveView(''))
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(setClaimDetailsToast('Claim approved!'))
    }
  }, [isSuccess, dispatch])

  return (
    <ContentWrapper>
      <DataRow>
        <ItemWrapper>
          <Select
            label="Failure Type"
            onChange={(e: SyntheticEvent<Element>): void => {
              const { value } = e.currentTarget as HTMLInputElement
              setFailureType(value as FailureType)
            }}
            value={failureType ?? ''}
            placeholder="—SELECT—"
            data-cy="approve-failure-type-select"
          >
            <SelectItem value="" label="—SELECT—" />
            {failureTypes.map((type) => (
              <SelectItem
                key={type}
                value={type}
                label={formatStartCase(type)}
                data-cy={`select-item-${type}`}
              />
            ))}
          </Select>
        </ItemWrapper>
      </DataRow>
      <ButtonRow>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
          />
          <Button
            type="button"
            text="Submit"
            data-cy="submit-button"
            onClick={handleSubmit}
            isDisabled={!failureType}
          />
        </ButtonGroup>
      </ButtonRow>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  height: 200,
  marginTop: 50,
  justifyContent: 'space-between',
})

const DataRow = styled.div({
  display: 'flex',
  width: '100%',
})

const ButtonRow = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
})

const ItemWrapper = styled.div({
  width: '100%',
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
})

export { ApproveClaimContent }
