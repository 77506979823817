import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import type {
  GetMSAAmendmentResponse,
  GetMSAAmendmentsRequest,
  MSAAmendmentCreateDraftRequest,
  MSAAmendmentCreateResponse,
  MSAAmendmentUpdatedRequest,
  PPRevShareDetailsUpsertRequest,
  PPRevShareDetailsUpsertResponse,
} from './types'

export const invoicingApi = createApi({
  baseQuery,
  reducerPath: 'invoicing',
  tagTypes: ['invoicing'],
  endpoints: (build) => ({
    createMSAAmendment: build.mutation<MSAAmendmentCreateResponse, MSAAmendmentCreateDraftRequest>({
      query: ({ body, accountId, version = 'latest' }) => ({
        url: `invoicing/msa/${accountId}/amendments`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
    }),
    updateMSAAmendment: build.mutation<MSAAmendmentCreateResponse, MSAAmendmentUpdatedRequest>({
      query: ({ body, accountId, amendment, version = 'latest' }) => ({
        url: `invoicing/msa/${accountId}/amendments/${amendment}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'PUT',
        body,
      }),
    }),
    createPPRevenueShareDetails: build.mutation<
      PPRevShareDetailsUpsertResponse,
      PPRevShareDetailsUpsertRequest
    >({
      query: ({ body, accountId, amendment, version = 'latest' }) => ({
        url: `invoicing/msa/${accountId}/amendments/${amendment}/PPRevenueShareDetails`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
    }),
    getMSAAmendments: build.query<GetMSAAmendmentResponse, GetMSAAmendmentsRequest>({
      query: ({ accountId, version = 'latest' }) => ({
        url: `invoicing/msa/${accountId}/amendments`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useCreateMSAAmendmentMutation,
  useUpdateMSAAmendmentMutation,
  useCreatePPRevenueShareDetailsMutation,
  useGetMSAAmendmentsQuery,
  useLazyGetMSAAmendmentsQuery,
} = invoicingApi
