import type { ClaimAddress } from '@helloextend/extend-api-client/src/models/claim'

const transformAddress = (options: {
  address: ClaimAddress
  shouldRemoveAddress2?: boolean
}): string => {
  const { address, shouldRemoveAddress2 } = options

  if (!address || !address.address1) {
    return ''
  }

  const { address1, address2, city, provinceCode, postalCode } = address
  return `${address1} ${
    address2 && !shouldRemoveAddress2 ? `${address2} ` : ''
  }${city}, ${provinceCode}, ${postalCode}`
}

export { transformAddress }
