import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@extend/zen'
import { AlertText, AlertIconWrapper, AlertContainer } from './styles'
import { ExclamationIcon } from '../icons/exclamation-icon'

interface WarningMessageProps {
  message: string
  hasIcon?: boolean
}

const WarningMessage: FC<WarningMessageProps> = ({ message, hasIcon }) => (
  <AlertContainer backgroundColor={COLOR.YELLOW[100]}>
    {hasIcon && (
      <AlertIconWrapper>
        <ExclamationIcon strokeWidth={1.25} />
      </AlertIconWrapper>
    )}
    <AlertText title={message} color={COLOR.YELLOW[600]}>
      {message}
    </AlertText>
  </AlertContainer>
)

export { WarningMessage }
