import type { FC, SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  ModalController,
  Modal,
  Button,
  Input,
  Grid,
  AdvancedSelect,
  COLOR,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import type { EmbeddedPromotion } from '@helloextend/extend-api-client'
import {
  useDeletePromotionMutation,
  useGetEmbeddedPromotionUploadPresignedUrlMutation,
  useUpdatePromotionMutation,
} from '@helloextend/extend-api-rtk-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Container, FooterContainer } from '../styles'

import { formatDateTimeOfDay } from '../promotions-data-table/promotions-data-table-helpers'
import { PromotionCSVImport } from './promotion-csv-import'
import { ImportState, TIME_ZONES, TIMES } from './constants'
import { FormDatePicker } from '../../../../../../../components/collapsible-info-group/form-date-picker'
import { parseTime } from './utils'

interface PromotionEditModalProps {
  isOpen: boolean
  onClose: () => void
  storeId: string
  promotion: EmbeddedPromotion
}

interface UpdatePromotionFormFields {
  name: string
  startDate?: number
  startTime?: string
  endDate?: number
  endTime?: string
  timezone?: string
}

const schema: Yup.ObjectSchema<UpdatePromotionFormFields> = Yup.object()
  .shape({
    name: Yup.string().required('Name is required'),
    startDate: Yup.number().optional(),
    startTime: Yup.string().optional(),
    endDate: Yup.number().optional(),
    endTime: Yup.string().optional(),
    timezone: Yup.string().optional(),
  })
  .test({
    name: 'dateValidation',
    test(values) {
      const start = parseTime(values.startDate, values.startTime, values.timezone)
      const end = parseTime(values.endDate, values.endTime, values.timezone)
      if (!start && end) {
        return this.createError({
          message: 'Start date must be before end date',
          path: `startDate`,
        })
      }

      if (end?.isBefore(start)) {
        return this.createError({
          message: 'Start date must be before end date',
          path: `startDate`,
        })
      }

      return true
    },
  })
  .defined()

export const PromotionEditModal: FC<PromotionEditModalProps> = ({
  isOpen,
  onClose,
  storeId,
  promotion,
}) => {
  const history = useHistory()
  const { toast } = useToaster()
  const now = new Date()

  const {
    handleChange,
    setFieldValue,
    values,
    errors,
    handleBlur,
    touched,
    isValid,
    dirty: isDirty,
  } = useFormik<Yup.InferType<typeof schema>>({
    enableReinitialize: true,
    initialValues: {
      name: promotion.name,
      startDate: promotion.startTime ? promotion.startTime * 1000 : undefined,
      startTime: formatDateTimeOfDay(promotion.startTime),
      endDate: promotion.endTime ? promotion.endTime * 1000 : undefined,
      endTime: formatDateTimeOfDay(promotion.endTime),
      timezone: undefined,
    },
    validationSchema: schema,
    onSubmit: () => {},
  })

  const [importState, setImportState] = useState<ImportState>(ImportState.READY)
  const [presignedUrl, setPresignedUrl] = useState<string | null>(null)

  const [getPromotionUploadUrl, { isLoading: isGettingPromotionUploadUrl }] =
    useGetEmbeddedPromotionUploadPresignedUrlMutation()
  const [updatePromotion, { isLoading: isUpdatingPromotion }] = useUpdatePromotionMutation()
  const [deletePromotion, { isLoading: isDeletingPromotion }] = useDeletePromotionMutation()

  const handleSetFieldFromEvent =
    (field: keyof UpdatePromotionFormFields) =>
    (e: SyntheticEvent<HTMLInputElement>): void => {
      if (setFieldValue) {
        setFieldValue(field, e.currentTarget.value)
      }
    }

  const handleSetFieldFromValue =
    (field: keyof UpdatePromotionFormFields) =>
    (value: number | null): void => {
      if (setFieldValue) {
        setFieldValue(field, value)
      }
    }

  const getPresignedUrl = async (): Promise<string | null> => {
    let response
    try {
      response = await getPromotionUploadUrl({
        storeId,
        promotionId: promotion.id,
      }).unwrap()
      return response?.url || null
    } catch {
      setImportState(ImportState.UPLOAD_FAILED)
      return null
    }
  }

  const handleOnDelete = async (): Promise<void> => {
    await deletePromotion({
      storeId,
      promotionId: promotion.id,
    }).unwrap()

    toast({
      message: 'Promotion has been deleted.',
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.short,
    })

    onClose()
    history.push(`/admin/stores/${storeId}/promotion`)
  }

  const handleOnUpdate = async (): Promise<void> => {
    if (isDirty) {
      let startTime = parseTime(values.startDate, values.startTime, values.timezone)
      let endTime = parseTime(values.endDate, values.endTime, values.timezone)

      if (startTime && formatDateTimeOfDay(promotion.startTime) !== values.startTime) {
        startTime = startTime.minute(0)
      }
      if (endTime && formatDateTimeOfDay(promotion.endTime) !== values.endTime) {
        endTime = endTime.minute(0)
      }

      await updatePromotion({
        storeId,
        promotionId: promotion.id,
        data: {
          name: values.name,
          ...(startTime && { startTime: startTime.unix() }),
          ...(endTime && { endTime: endTime.unix() }),
        },
      }).unwrap()

      toast({
        message: 'Promotion has been updated.',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    }

    if (importState === ImportState.FILE_SELECTED) {
      setImportState(ImportState.UPLOADING)
      const url = await getPresignedUrl()
      setPresignedUrl(url)
    }

    onClose()
  }

  useEffect(() => {
    if (importState === ImportState.UPLOADED) {
      toast({
        message:
          'Promotions have been successfully imported. Check back in a bit to see your uploaded promotion.',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
    }

    if (importState === ImportState.UPLOAD_FAILED) {
      toast({
        message: 'Upload of promotions has failed. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [toast, importState])

  const isProcessing = isGettingPromotionUploadUrl || isUpdatingPromotion || isDeletingPromotion
  const isDisabled = !isValid || (!isDirty && importState !== ImportState.FILE_SELECTED)

  return (
    <>
      <ModalController isOpen={isOpen}>
        <Modal
          heading="Edit Promotion"
          data-cy="promotion-edit-modal"
          onDismissRequest={onClose}
          footerContent={
            <FooterContainer>
              <Button
                color="red"
                data-cy="promotion-delete-button"
                emphasis="medium"
                onClick={handleOnDelete}
                text="Delete"
                isDisabled={isProcessing}
                isProcessing={isDeletingPromotion}
              />
              <Button
                data-cy="promotion-cancel-button"
                emphasis="medium"
                onClick={onClose}
                text="Cancel"
                isDisabled={isProcessing}
              />
              <Button
                data-cy="promotion-update-button"
                onClick={handleOnUpdate}
                text="Update"
                isDisabled={isDisabled || isProcessing}
                isProcessing={isUpdatingPromotion}
              />
            </FooterContainer>
          }
        >
          <Container>
            <MarginDiv>
              <Input
                data-cy="promotion-name"
                id="name"
                label="Name"
                onChange={handleSetFieldFromEvent('name')}
                onBlur={handleBlur}
                value={values.name}
                isError={Boolean(errors.name) && touched.name}
                errorFeedback={errors.name}
              />
            </MarginDiv>
            <Grid columns={{ sm: 1, md: 2 }} spacing={2}>
              <CustomInputWrapper>
                <FormDatePicker
                  data-cy="start-date"
                  label="Start date"
                  date={values.startDate ? new Date(values.startDate) : undefined}
                  onChange={handleSetFieldFromValue('startDate')}
                  invalid={false}
                  minDate={now}
                  maxDate={null}
                />
                {errors.startDate ? (
                  <div style={{ color: '#B50318' }}>{errors.startDate}</div>
                ) : null}
              </CustomInputWrapper>
              <AdvancedSelect
                onChange={handleChange}
                id="startTime"
                label="Start time"
                options={TIMES}
                value={values.startTime || ''}
                isError={Boolean(errors.startTime)}
                errorFeedback={errors.startTime}
                multiple={false}
              />
            </Grid>
            <Grid columns={{ sm: 1, md: 2 }} spacing={2}>
              <CustomInputWrapper>
                <FormDatePicker
                  data-cy="end-date"
                  label="End date"
                  date={values.endDate ? new Date(values.endDate) : undefined}
                  onChange={handleSetFieldFromValue('endDate')}
                  invalid={false}
                  minDate={new Date()}
                  maxDate={null}
                />
              </CustomInputWrapper>
              <AdvancedSelect
                onChange={handleChange}
                id="endTime"
                label="End time"
                options={TIMES}
                value={values.endTime || ''}
                isError={Boolean(errors.endTime)}
                errorFeedback={errors.endTime}
                multiple={false}
              />
            </Grid>
            <MarginDiv>
              <AdvancedSelect
                label="Time Zone"
                onChange={handleChange}
                id="timezone"
                options={TIME_ZONES}
                placeholder="Select Time Zone"
                value={values.timezone || ''}
                isError={Boolean(errors.timezone && errors.timezone)}
                errorFeedback={errors.timezone ?? ''}
                multiple={false}
              />
            </MarginDiv>
            <PaddedDiv>
              <PromotionCSVImport
                importState={importState}
                setImportState={setImportState}
                presignedUrl={presignedUrl}
              />
            </PaddedDiv>
          </Container>
        </Modal>
      </ModalController>
    </>
  )
}

const MarginDiv = styled.div({
  marginBottom: 16,
})

const PaddedDiv = styled.div({
  paddingTop: 24,
})

const CustomInputWrapper = styled.div({
  '& label > div': {
    color: COLOR.BLACK,
    marginBottom: '2px',
  },
  '.react-datepicker-wrapper input': {
    borderColor: '#A9B1BB !important',
  },
})
