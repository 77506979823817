import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { TextInput } from '@helloextend/merchants-ui'
import { Icon, COLOR, Search } from '@extend/zen'

interface DataFilterBarProps {
  value?: string
  placeholder?: string
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void
}

const DataFilterBar: FC<DataFilterBarProps> = ({ value = '', placeholder = '', onChange }) => {
  return (
    <InputWrapper>
      <IconWrapper>
        <Icon icon={Search} color={COLOR.NEUTRAL[800]} />
      </IconWrapper>
      <TextInput id="search" label="" value={value} onChange={onChange} placeholder={placeholder} />
    </InputWrapper>
  )
}

const IconWrapper = styled.div({
  backgroundColor: 'transparent',
  border: 'none',
  width: 48,
  height: '100%',
  display: 'flex',
  position: 'absolute',
  zIndex: 9,
  justifyContent: 'center',
  alignItems: 'center',
})

const InputWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  maxWidth: 688,
  input: {
    width: '100%',
    paddingLeft: 48,
  },
  marginBottom: 50,
})

export { DataFilterBar }
