import type { InsuranceProgram } from '@helloextend/extend-api-client'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import type {
  InsuranceProgramListResponse,
  InsuranceProgramCreateRequest,
  InsuranceProgramVersionsListResponse,
} from './types'

export const insuranceProgramsApi = createApi({
  baseQuery,
  reducerPath: 'InsurancePrograms',
  tagTypes: ['InsurancePrograms'],
  endpoints: (build) => ({
    createInsuranceProgram: build.mutation<InsuranceProgram, InsuranceProgramCreateRequest>({
      query: (request) => {
        return {
          url: '/sku-management/insurance-programs',
          method: 'POST',
          body: request,
        }
      },
      invalidatesTags: ['InsurancePrograms'],
    }),
    getInsuranceProgram: build.query<InsuranceProgram, string>({
      query: (insuranceProgramId) => ({
        url: `/sku-management/insurance-programs/${insuranceProgramId}?cc=${Date.now()}`, // Add the current time stamp to break server-side caching
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, insuranceProgramId) => [
        { type: 'InsurancePrograms', id: insuranceProgramId },
      ],
    }),
    listInsuranceProgramVersions: build.query<InsuranceProgramVersionsListResponse, string>({
      query: (insuranceProgramId) => ({
        url: `/sku-management/insurance-programs/list/${insuranceProgramId}`,
      }),
      transformResponse: (response: InsuranceProgramVersionsListResponse) => {
        const sortedVersions = response.versions.sort((a, b) => a.version - b.version)
        return { ...response, versions: sortedVersions }
      },
      providesTags: (result) => {
        if (result?.versions?.length) {
          return [
            ...result.versions.map(
              ({ id: insuranceProgramId }: InsuranceProgram) =>
                ({
                  type: 'InsurancePrograms',
                  id: insuranceProgramId,
                } as const),
            ),
            { type: 'InsurancePrograms', id: 'LIST' },
          ]
        }
        return [{ type: 'InsurancePrograms', id: 'LIST' }]
      },
    }),
    listInsurancePrograms: build.query<InsuranceProgramListResponse, void>({
      query: () => ({
        url: `/sku-management/insurance-programs/list`,
      }),
      providesTags: (result) => {
        if (result?.items?.length) {
          return [
            ...result.items.map(
              ({ id: insuranceProgramId }: InsuranceProgram) =>
                ({
                  type: 'InsurancePrograms',
                  id: insuranceProgramId,
                } as const),
            ),
            { type: 'InsurancePrograms', id: 'LIST' },
          ]
        }
        return [{ type: 'InsurancePrograms', id: 'LIST' }]
      },
    }),
  }),
})

export const {
  useCreateInsuranceProgramMutation,
  useGetInsuranceProgramQuery,
  useLazyGetInsuranceProgramQuery,
  useListInsuranceProgramVersionsQuery,
  useListInsuranceProgramsQuery,
} = insuranceProgramsApi
