import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  COLOR,
  Header,
  HeaderMenuItem,
  HeaderUserInfo,
  MenuLinkItem,
  Popover,
  PopoverAlignment,
  Shell,
  Stack,
  usePopover,
  Account,
  Export,
  UserManagement,
  Help,
} from '@extend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useExtendAuth } from '@extend/package-okta-login'
import { LDFlag } from '../../constants/ld-flags'
import { HeadTag } from '../../components/head-tag'
import { DashboardNavLinks } from './dashboard-navlinks'
import { adminNavList } from './nav-list'
import type { NavDropdown, NavLink } from './types'
import { RoleSwitcher } from '../../components/role-switcher'
import { isProductionEnv } from './isProductionEnv'
import { usePermissions } from '../../hooks/use-permissions'
import { Permission } from '../../lib/permissions/permissions'
import type { UserRole } from '../../utils/user-role-mapper'
import { mapUserType } from '../../utils/user-role-mapper'

export enum DashboardSection {
  Admin = 'ADMIN',
  None = 'NONE',
}

const navMenuLinks: Record<DashboardSection, Array<NavLink | NavDropdown>> = {
  [DashboardSection.Admin]: adminNavList,
  [DashboardSection.None]: [],
}

type DashboardLayoutProps = {
  section: DashboardSection
}

const DashboardLayout: FC<React.PropsWithChildren<DashboardLayoutProps>> = ({
  children,
  section,
}: React.PropsWithChildren<DashboardLayoutProps>) => {
  const isProdEnv = isProductionEnv()
  const { user } = useExtendAuth()
  const { firstName, lastName } = user ?? {}

  const userInitials = user ? (firstName ? firstName[0] : '') + (lastName ? lastName[0] : '') : ''

  const { [LDFlag.RolePermissionManagement]: isRolePermissionManagementEnabled } = useFlags()
  const { hasPermission } = usePermissions()

  const threadPreviewLocation = useRouteMatch(
    '/admin/adjudication-management/threads/:threadId/preview',
  )
  const ampEditingPage = useRouteMatch([
    '/admin/adjudication-management/threads/:threadId/preview',
    '/admin/adjudication-management/threads/:threadId/edit/adjudication-rules',
    '/admin/adjudication-management/threads/:threadId/edit/photos',
    '/admin/adjudication-management/threads/:threadId/edit/content',
    '/admin/adjudication-management/threads/:threadId/edit',
    '/admin/adjudication-management/conversations/:id/preview',
    '/admin/adjudication-management/conversations/:id/edit',
  ])
  const isAMPEditingPage = ampEditingPage?.isExact

  const { triggerRef, popoverRef, isPresent, toggle, hide, triggerBoundingBox } =
    usePopover<HTMLDivElement>()
  const {
    triggerRef: userManagementTriggerRef,
    popoverRef: userManagementPopoverRef,
    isPresent: isUserManagementPresent,
    toggle: toggleUserManagement,
    hide: hideUserManagement,
    triggerBoundingBox: triggerUserManagementBoundingBox,
  } = usePopover<HTMLDivElement>()

  const navList = navMenuLinks[section]

  const shouldShowUserManagement = useMemo(() => {
    const hasAtLeastOnePermission =
      hasPermission(Permission.ManageExtendUsers) ||
      hasPermission(Permission.ManageClaimsAssignments) ||
      hasPermission(Permission.RoleManagementView)

    return section === DashboardSection.Admin && hasAtLeastOnePermission
  }, [hasPermission, section])

  return (
    <>
      <HeadTag siteTitle="Extend | Portal Dashboard" />
      <Shell
        viewportPadding="40px 32px"
        banner={
          !isAMPEditingPage && (
            <EnvironmentBannerContainer data-cy="environment-banner" isProdEnv={isProdEnv}>
              <EnvironmentBanner>{`You are currently logged into ${
                isProdEnv ? 'the production' : 'a non-production'
              } environment.`}</EnvironmentBanner>
            </EnvironmentBannerContainer>
          )
        }
        header={
          <Header
            color={COLOR.NEUTRAL['1000']}
            data-cy="portal-header"
            end={
              <Stack isRow spacing={1}>
                {shouldShowUserManagement && (
                  <>
                    <HeaderMenuItem
                      ref={userManagementTriggerRef}
                      isToggled={isUserManagementPresent}
                      onClick={toggleUserManagement}
                      data-cy="user-management-menu"
                      icon={UserManagement}
                      tooltip="User Management"
                    />
                    <Popover
                      ref={userManagementPopoverRef}
                      isPresent={isUserManagementPresent}
                      triggerBoundingBox={triggerUserManagementBoundingBox}
                    >
                      <Stack padding={1}>
                        {hasPermission(Permission.ManageExtendUsers) && (
                          <MenuLinkItem
                            to="/admin/user-management"
                            data-cy="user-management-page-link"
                            onClick={hideUserManagement}
                          >
                            User Management
                          </MenuLinkItem>
                        )}
                        {hasPermission(Permission.ManageClaimsAssignments) && (
                          <MenuLinkItem
                            to="/admin/claim-assignment"
                            data-cy="claim-assignment-page-link"
                            onClick={hideUserManagement}
                          >
                            Claim Assignment List
                          </MenuLinkItem>
                        )}
                        {hasPermission(Permission.RoleManagementView) &&
                          isRolePermissionManagementEnabled && (
                            <MenuLinkItem to="/admin/role-management" onClick={hideUserManagement}>
                              Role Management
                            </MenuLinkItem>
                          )}
                      </Stack>
                    </Popover>
                  </>
                )}
                <HeaderMenuItem icon={Help} tooltip="Help" />
                <HeaderMenuItem ref={triggerRef} isToggled={isPresent} onClick={toggle}>
                  <HeaderUserInfo
                    data-cy="header-user-info"
                    initials={userInitials}
                    label={mapUserType((user?.role as UserRole) ?? 'user')}
                  />
                </HeaderMenuItem>
                <Popover
                  ref={popoverRef}
                  alignment={PopoverAlignment.end}
                  isPresent={isPresent}
                  triggerBoundingBox={triggerBoundingBox}
                  maxWidth={280}
                  header={
                    <Stack padding={1}>
                      <MenuLinkItem icon={Account} to="/profile" onClick={hide}>
                        Profile
                      </MenuLinkItem>
                    </Stack>
                  }
                  footer={
                    <Stack padding={1}>
                      <MenuLinkItem icon={Export} to="/logout" onClick={hide}>
                        Log out
                      </MenuLinkItem>
                    </Stack>
                  }
                >
                  <Wrapper>
                    <Stack padding={1}>
                      <RoleSwitcher />
                    </Stack>
                  </Wrapper>
                </Popover>
              </Stack>
            }
            start={<DashboardNavLinks navList={navList} />}
          />
        }
        {...(threadPreviewLocation?.isExact && { viewportBackground: COLOR.NEUTRAL[100] })}
      >
        {children}
      </Shell>
    </>
  )
}

const Wrapper = styled.div({
  width: 280,
})

const EnvironmentBanner = styled.p({
  fontSize: 14,
  lineHeight: '2px',
  textAlign: 'center',
  padding: '16px 0px',
  margin: 0,
})
const EnvironmentBannerContainer = styled.div<{ isProdEnv: boolean }>(({ isProdEnv }) => ({
  backgroundColor: isProdEnv ? COLOR.RED[500] : COLOR.BLUE[500],
  color: COLOR.WHITE,
}))

export { DashboardLayout }
