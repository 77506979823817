import { startCase } from 'lodash'
import type { AuditHistoryResponse } from '@helloextend/extend-api-client'
import type { CellContext, ColumnDef } from '@extend/zen'
import { format } from '@extend/zen'

export type AuditFieldChange = any[]

export interface AuditLogTableProps<T> {
  auditLog: AuditHistoryResponse<T>
  resourceType?: string
  isLoading?: boolean
}

export interface AuditLogTableFields {
  eventField: string
  eventReason: string
  functionName: string
  method: string
  eventTime: number
  actor: string
  changes: AuditFieldChange
}

interface AuditFieldDelta {
  field: string
  oldValue: string | number | null | object
  newValue: string | number | null | object
}

export const auditLogColumns: Array<ColumnDef<AuditLogTableFields>> = [
  {
    id: 'eventField',
    label: 'Event Field',
    renderCell: (data: CellContext<AuditLogTableFields, string>) =>
      data.getValue()?.replace('Id', 'ID'),
  },
  {
    id: 'eventReason',
    label: 'Event Reason',
    renderCell: (data: CellContext<AuditLogTableFields, string>) => data.getValue(),
  },
  {
    id: 'functionName',
    label: 'Function Name',
    renderCell: (data: CellContext<AuditLogTableFields, string>) => data.getValue(),
  },
  {
    id: 'method',
    label: 'Method',
    renderCell: (data: CellContext<AuditLogTableFields, string>) => data.getValue(),
  },
  {
    id: 'eventTime',
    label: 'Event Time',
    renderCell: (data: CellContext<AuditLogTableFields, number>) =>
      format(data.getValue(), 'MMMM DD YYYY, HH:mm A'),
  },
  {
    id: 'actor',
    label: 'Actor',
    renderCell: (data: CellContext<AuditLogTableFields, string>) => data.getValue(),
  },
]

export const buildFieldChanges = (changes: AuditFieldChange): Map<string, AuditFieldDelta[]> => {
  return changes.reduce((hmap, change) => {
    if (change?.path) {
      const field = change.path[0]
      const fieldDelta: AuditFieldDelta = {
        field: startCase(change.path.join(' ')),
        oldValue: change?.lhs || null,
        newValue: change.rhs,
      }

      if (!hmap.has(field)) {
        hmap.set(field, [fieldDelta])
      } else {
        hmap.get(field)?.push(fieldDelta)
      }
    }
    return hmap
  }, new Map<string, AuditFieldDelta[]>())
}

export const buildAuditLogTableFields = <T>(
  auditLog: AuditHistoryResponse<T>,
): AuditLogTableFields[] => {
  return (auditLog?.items || []).flatMap((item) => {
    const changes = buildFieldChanges(item.changes || [])
    return Array.from(changes, ([eventField, changeArray]) => ({
      eventField: startCase(eventField),
      eventReason: startCase(item.reason),
      functionName: item.lambdaMeta?.lambdaFunctionName || 'N/A',
      method: item.method,
      eventTime: item.eventTime,
      actor: item.actor,
      changes: changeArray,
    }))
  })
}

export const flattenObject = (
  obj: Record<string, any>,
  parentKey = '',
  result: Record<string, any> = {},
): Record<string, any> => {
  for (const key in obj) {
    const newKey = parentKey ? `${startCase(parentKey)} ${startCase(key)}` : key
    const value = obj[key]

    if (typeof value === 'object' && value !== null) {
      flattenObject(value, newKey, result)
    } else {
      result[newKey] = value
    }
  }

  return result
}
