import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { Switch, useToaster, ToastColor, ToastDuration, ModalController } from '@extend/zen'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import type { ToggleStatusType } from '../types'
import { ShippingProtectionModal } from '../shipping-protection-modal'

type StoreStatusToggleProps = {
  storeName?: string
  storeId: string
  isEnabled: boolean
  toggleType: ToggleStatusType
  isShippingProtection?: boolean
}

const StoreStatusToggle: FC<StoreStatusToggleProps> = ({
  isEnabled,
  storeName,
  storeId,
  toggleType,
  isShippingProtection = false,
}) => {
  const [updateStore, { isLoading }] = useUpdateStoreMutation()
  const [enabledStatus, setEnabledStatus] = useState(isEnabled)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { toast } = useToaster()

  const handleOnChange = useCallback(async () => {
    if (isShippingProtection && !enabledStatus) {
      setIsModalOpen(true)
      return
    }
    const status = {
      [toggleType]: !enabledStatus,
    }
    const data = isShippingProtection
      ? {
          shippingProtection: {
            ...status,
          },
        }
      : { ...status }
    setEnabledStatus(!enabledStatus)
    try {
      await updateStore({
        storeId,
        data,
        version: 'latest',
      }).unwrap()

      toast({
        message: `${
          isShippingProtection ? 'Shipping' : 'Product'
        } Protection status has been updated`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    } catch {
      toast({
        message: `${
          isShippingProtection ? 'Shipping' : 'Product'
        } Protection status could not be updated. Please try again later`,
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      setEnabledStatus(isEnabled)
    }
  }, [enabledStatus, isEnabled, isShippingProtection, storeId, toast, toggleType, updateStore])

  const id = `${storeId}-${isShippingProtection ? 'sp' : 'pp'}-${toggleType}-toggle`

  const handleCloseModal = (): void => {
    setIsModalOpen(false)
  }

  const handleEnableToggle = (): void => {
    setEnabledStatus(true)
    handleCloseModal()
  }

  return (
    <SwitchContainer>
      <Switch
        isOn={enabledStatus}
        id={id}
        onChange={handleOnChange}
        isProcessing={isLoading}
        isTentative={isModalOpen}
        data-cy={id}
      />
      {isShippingProtection && !enabledStatus && (
        <ModalController isOpen={isModalOpen}>
          <ShippingProtectionModal
            dismissModal={handleCloseModal}
            onSaved={handleEnableToggle}
            storeName={storeName}
            storeId={storeId}
            toggleType={toggleType}
          />
        </ModalController>
      )}
    </SwitchContainer>
  )
}

const SwitchContainer = styled.div({
  display: 'inline-flex',
})

export { StoreStatusToggle }
