 
import type { FC } from 'react'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useExtendAuth } from '@extend/package-okta-login'
import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { parseISO, addSeconds } from 'date-fns'
import { useGetEmbeddedPromotionDownloadPresignedUrlMutation } from '@helloextend/extend-api-rtk-query'
import { DownloadCSVData } from '../../../utils/download-csv-data'
import { getUserEmailFromToken } from '../../../lib/jwt'

const PromotionProductsDownload: FC = () => {
  const { token: accessToken } = useExtendAuth()
  const email = getUserEmailFromToken(accessToken) ?? ''
  const { search } = useLocation()
  const { push } = useHistory()
  const { toast } = useToaster()
  const urlSearchParams = new URLSearchParams(search)
  const expiry = parseInt(urlSearchParams.get('expires') || '', 10) || null
  const storeId = urlSearchParams.get('storeId') || ''
  const promotionId = urlSearchParams.get('promotionId') || ''
  const promotionUrl = `/admin/stores/${storeId}/promotions/${promotionId}`
  const bulkDownloadKey = urlSearchParams.get('bulkDownloadKey') || ''
  const [getBulkDownloadUrl, { isLoading }] = useGetEmbeddedPromotionDownloadPresignedUrlMutation()
  const [isExpired, setIsExpired] = useState<boolean>(expiry === null || Date.now() > expiry)

  const onDownloadClick = async (): Promise<void> => {
    try {
      const downloadResponse = await getBulkDownloadUrl({
        storeId,
        promotionId,
        bulkDownloadKey,
        email,
      }).unwrap()

      if (downloadResponse && 'url' in downloadResponse) {
        const s3Params = new URLSearchParams(downloadResponse.url)
        // parse expiration date from S3 URL
        const creationDate = parseISO(s3Params.get('X-Amz-Date')!)
        const expiresInSecs = Number(s3Params.get('X-Amz-Expires')!)
        const expiryDate = addSeconds(creationDate, expiresInSecs)
        // determine if S3 URL has expired
        const isS3Expired = Date.now() > expiryDate.getTime()
        if (isS3Expired) {
          setIsExpired(true)
        } else {
          window.open(downloadResponse.url, '_blank')
        }
      }
    } catch (err) {
      toast({
        message: 'There was an error downloading promotion products, please try again later.',
        toastDuration: ToastDuration.long,
        toastColor: ToastColor.red,
      })
      push(promotionUrl)
    }
  }

  const handleDismissClick = (): void => {
    push(promotionUrl)
  }
  return (
    <div>
      <h2>Download Promotion Products Data</h2>
      <DownloadCSVData
        downloadDataType="Promotion"
        retryLink={promotionUrl}
        isExpired={isExpired}
        handleDismissClick={handleDismissClick}
        isProcessing={isLoading}
        onDownloadClick={onDownloadClick}
      />
    </div>
  )
}

export { PromotionProductsDownload }
