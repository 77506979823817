import type { ColumnFiltersState, SortingState } from '@extend/zen'
import type { ContractsV2GetResponse } from '@helloextend/extend-api-client'
import { ContractType } from '@helloextend/extend-api-client'
import type { ContractSearchV1QueryStringOptions } from '@helloextend/extend-api-rtk-query'
import { toTableContractItems } from './to-table-contract-items'
import type { TableContractsSearch } from '../../../types/contracts'
import { getTableFilters } from './tableConfig'

export const ALLOWED_CONTRACT_TYPES = [
  ContractType.PCRS,
  ContractType.SHIPPING_PROTECTION,
  ContractType.CATEGORY,
  ContractType.PRODUCT_PROTECTION_BUNDLE,
]

export const prepareContractSearchArgs = (
  columnFilters: ColumnFiltersState,
): ContractSearchV1QueryStringOptions => {
  let args: ContractSearchV1QueryStringOptions = {
    apiVersion: 'latest',
    typeFilter: ALLOWED_CONTRACT_TYPES,
    showAll: true,
  }
  if (columnFilters.length > 0) {
    const filters = getTableFilters(columnFilters)
    args = { ...args, ...filters }
  }

  return args
}

export const formatAndSortContractItems = (
  contracts: ContractsV2GetResponse[],
  sorting: SortingState,
): TableContractsSearch[] => {
  const temp = toTableContractItems(contracts)
  if (sorting) {
    for (const sort of sorting) {
      if (sort.id === 'purchaseDate') {
        temp.sort((a: TableContractsSearch, b: TableContractsSearch) => {
          if (sort.desc) {
            return a.purchaseDate - b.purchaseDate
          }
          return b.purchaseDate - a.purchaseDate
        })
      } else if (sort.id === 'type') {
        temp.sort((a: TableContractsSearch, b: TableContractsSearch) => {
          if (sort.desc) {
            return a.type.localeCompare(b.type)
          }
          return b.type.localeCompare(a.type)
        })
      }
    }
  }
  return temp
}
