import type { FC, ChangeEvent } from 'react'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  ModalController,
  Modal,
  Input,
  Select,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@extend/zen'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import type { ThreadListItem } from '@helloextend/extend-api-rtk-query'
import { usePatchThreadMutation } from '@helloextend/extend-api-rtk-query'
import * as Yup from 'yup'
import { setIsRenameThreadModalVisible, setThread } from '../../../../../store/slices/amp-slice'

type RenameThreadModalProps = {
  isVisible: boolean
  onCancel: () => void
  thread: ThreadListItem | null
}

const RenameThreadModal: FC<RenameThreadModalProps> = ({ isVisible, onCancel, thread }) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()

  const [renameThread, { isLoading: isRenameThreadProcessing }] = usePatchThreadMutation()

  const schema = Yup.object()
    .shape({
      name: Yup.string().required('Thread name is required'),
    })
    .defined()

  const {
    setFieldValue,
    values,
    errors,
    handleBlur,
    touched,
    isValid,
    dirty: isDirty,
  } = useFormik<Yup.InferType<typeof schema>>({
    enableReinitialize: true,
    initialValues: {
      name: thread?.title ?? '',
    },
    validationSchema: schema,
    onSubmit: () => {},
  })

  const isDisabled = !(isDirty && isValid)

  const handleNameInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue('name', e.target.value)
  }

  const handleProceed = async (): Promise<void> => {
    if (!thread) return
    try {
      const renamedThread = await renameThread({
        threadId: thread.id,
        patches: [
          {
            op: 'replace',
            path: '/title',
            value: values.name,
          },
        ],
      }).unwrap()
      toast({
        message: `${values.name} has been successfully updated.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      dispatch(setIsRenameThreadModalVisible(false))
      dispatch(setThread(renamedThread))
    } catch (err: unknown) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="amp-rename-thread-modal"
        heading="Rename thread"
        primaryButtonProps={{
          'data-cy': 'amp-rename-thread-modal-submit',
          onClick: handleProceed,
          text: 'Save',
          isDisabled: isDisabled || isRenameThreadProcessing,
          isProcessing: isRenameThreadProcessing,
        }}
        secondaryButtonProps={{
          'data-cy': 'amp-rename-thread-modal-cancel',
          onClick: onCancel,
          text: 'Cancel',
        }}
        onDismissRequest={onCancel}
      >
        <>
          <NameWrapper>
            <Input
              data-cy="amp-rename-thread-name"
              id="name"
              label="Name"
              onChange={handleNameInputChange}
              onBlur={handleBlur}
              value={values.name}
              isError={Boolean(errors.name) && touched.name}
              errorFeedback={errors.name}
            />
          </NameWrapper>
          <Select
            data-cy="amp-rename-thread-type"
            id="type"
            label="Thread Type"
            onChange={() => {}}
            value={thread?.type ?? ''}
            isDisabled
          >
            <option value="adjudication">Adjudication</option>
            <option value="troubleshooting">Troubleshooting</option>
            <option value="other">Other</option>
          </Select>
        </>
      </Modal>
    </ModalController>
  )
}

const NameWrapper = styled.div({
  marginBottom: 16,
})

export type { RenameThreadModalProps }
export { RenameThreadModal }
