import type { ChangeEvent, FC } from 'react'
import React, { createRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import {
  Button,
  Check,
  COLOR,
  Error,
  InlineAlert,
  InlineAlertColor,
  HeadingLarge,
  Paragraph,
} from '@extend/zen'
import { useHistory, useParams } from 'react-router-dom'
import { useStandardToast } from '@helloextend/merchants-ui'
import {
  useGetPlanCategoriesQuery,
  useGetCategoryMappingsImportUrlMutation,
  useImportCategoryMappingsMutation,
} from '@helloextend/extend-api-rtk-query'
import { gzip } from 'fflate'
import { SaveChangesButtonGroup } from '../merchants/stores/store-details/settings/components/save-changes-button-group'
import { parseCategoryMappingsCsv } from './utils'
import categoryMappingsCsvSample from '../../../misc/category-mappings-csv-sample.csv'

interface AlertItem {
  key: string
  text: string
}

const convertToAlertItem = (alerts: string[]): AlertItem[] => {
  return alerts.map((error, index) => ({
    key: `error-${index}`,
    text: error,
  }))
}

const gzipPromise = async (file: File): Promise<Uint8Array> => {
  const arrayBuffer = await file.arrayBuffer()
  return new Promise((resolve, reject) => {
    const uint8Array = new Uint8Array(arrayBuffer)
    gzip(uint8Array, (err, data) => {
      if (err) reject(err)

      resolve(data)
    })
  })
}

const CategoryMappingImport: FC = () => {
  const history = useHistory()
  const { storeId } = useParams<{ storeId: string }>()
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true)
  const [isParsing, setIsParsing] = useState<boolean>(false)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [importItemsNumber, setImportItemsNumber] = useState(0)
  const [errorsBanner, setErrorsBanner] = useState<AlertItem[]>([])
  const [hasValidated, setHasValidated] = useState(false)

  const { toastCompleted, toastError } = useStandardToast()

  const inputFileRef = createRef<HTMLInputElement>()

  const { data: planCategories, isLoading: isPlanCategoriesLoading } = useGetPlanCategoriesQuery()

  const [getCategoryMappingsUploadUrl, { isLoading: isGettingCategoryMappingsUploadUrl }] =
    useGetCategoryMappingsImportUrlMutation()

  const [uploadCsv] = useImportCategoryMappingsMutation()

  const handleCancelClick = (): void => {
    history.push('/admin/products/category-mapping')
  }

  const getUploadUrl = async (): Promise<string | null> => {
    if (!uploadedFile) return null
    try {
      const response = await getCategoryMappingsUploadUrl({
        storeId,
      }).unwrap()
      return response.url
    } catch {
      toastError('Something went wrong. Please try again.')
      return null
    }
  }
  const handleImportClick = async (): Promise<void> => {
    const url = await getUploadUrl()
    if (!url || !uploadedFile) return

    try {
      const content = await gzipPromise(uploadedFile)
      const fileBlob = new Blob([content])
      await uploadCsv({ url, fileBlob }).unwrap()
      toastCompleted(
        `${importItemsNumber} Category mappings have been successfully imported. Check back in a bit to see your uploaded mappings.`,
      )
      history.push('/admin/products/category-mapping')
    } catch {
      toastError('Something went wrong. Please try again.')
    }
  }

  const handleSelectFile = (): void => {
    resetUploadedFile()
    inputFileRef.current?.click()
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0] || null
    setUploadedFile(file)
    setHasValidated(false)
  }

  const resetUploadedFile = (): void => {
    setUploadedFile(null)
    setErrorsBanner([])
    setHasValidated(false)
    setIsSaveDisabled(true)
    const { current } = inputFileRef
    if (current?.value) {
      current.value = ''
    }
  }

  useEffect(() => {
    if (!uploadedFile || hasValidated || isPlanCategoriesLoading) {
      return
    }
    setIsParsing(true)
    ;(async function parseAndValidateUploadedFile(): Promise<void> {
      try {
        const results = await parseCategoryMappingsCsv(uploadedFile, planCategories?.items)

        setImportItemsNumber(results?.data?.length as number)

        const errors = convertToAlertItem(results?.errors || [])

        setErrorsBanner(errors)
        setHasValidated(true)

        if (errors.length === 0) {
          setIsSaveDisabled(false)
        }
      } catch {
        toastError('Something went wrong. Please try again.')
      }
      setIsParsing(false)
    })()
  }, [hasValidated, planCategories, toastError, uploadedFile, isPlanCategoriesLoading])

  const alertPrimaryButtonProps = {
    text: 'Replace File',
    onClick: handleSelectFile,
    isProcessing: isParsing,
    isDisabled: isParsing,
  }

  return (
    <>
      <HeadingLarge>CSV Import</HeadingLarge>
      {uploadedFile && hasValidated && errorsBanner.length === 0 && (
        <InlineAlertWrapper>
          <InlineAlert
            color={InlineAlertColor.green}
            icon={Check}
            primaryButtonProps={alertPrimaryButtonProps}
            data-cy="import-success-alert"
          >
            {uploadedFile?.name} has been successfully validated! You are about to import{' '}
            {importItemsNumber} category mappings. Note that if the category mappings already
            exists, the attributes provided in this file will update the existing category mappings.
          </InlineAlert>
        </InlineAlertWrapper>
      )}
      {uploadedFile && hasValidated && errorsBanner.length > 0 && (
        <InlineAlertWrapper>
          <InlineAlert
            color={InlineAlertColor.red}
            icon={Error}
            primaryButtonProps={alertPrimaryButtonProps}
            data-cy="import-error-alert"
          >
            {uploadedFile.name} failed to validate.
            {errorsBanner.map(({ key, text }) => (
              <li key={key}>{text}</li>
            ))}
          </InlineAlert>
        </InlineAlertWrapper>
      )}

      <ParagraphWrapper data-cy="download-links">
        <StyledDownload href={categoryMappingsCsvSample}>
          Download category mapping CSV example
        </StyledDownload>
      </ParagraphWrapper>
      {(!uploadedFile || isParsing) && (
        <ButtonContainer>
          <Button
            text="Select CSV File"
            onClick={handleSelectFile}
            emphasis="medium"
            isProcessing={isParsing}
            isDisabled={isParsing}
          />
        </ButtonContainer>
      )}
      <ButtonsGroupContainer>
        <SaveChangesButtonGroup
          saveButtonText="Import"
          id="import-buttons-group"
          handleCancel={handleCancelClick}
          handleSave={handleImportClick}
          isSaveDisabled={isSaveDisabled}
          isLoading={isGettingCategoryMappingsUploadUrl}
        />
      </ButtonsGroupContainer>
      <HiddenInput
        id="inputFile"
        ref={inputFileRef}
        accept=".csv"
        type="file"
        onChange={handleFileChange}
      />
    </>
  )
}

const ButtonsGroupContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

const HiddenInput = styled.input({
  display: 'none',
})

const ButtonContainer = styled.div({
  margin: '32px 0 40px',
})

const InlineAlertWrapper = styled.div({
  marginTop: 24,
})

const ParagraphWrapper = styled(Paragraph)({
  padding: '24px 0',
})

const StyledDownload = styled.a({
  color: COLOR.BLUE[800],
  textDecoration: 'underline',
})

export { CategoryMappingImport }
