import type { FC, ChangeEvent, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

export interface InputProps {
  autoComplete?: string
  errorMessage?: string
  inputCSS?: any
  formGroupCSS?: any
  isDisabled?: boolean
  isRequired?: boolean
  invalid?: boolean
  label?: string
  labelCss?: any
  name: string
  onBlur?: (e: SyntheticEvent<any> & ChangeEvent<any>) => void
  type?: string
  value?: string
  textArea?: boolean
  placeholder?: string
  appendValue?: string
  onChange: (e: SyntheticEvent<any> & ChangeEvent<any>) => void
  maxCount?: number
  'data-cy'?: string
}

const Input: FC<InputProps> = ({
  autoComplete,
  errorMessage = 'Please enter a valid value',
  inputCSS = {},
  formGroupCSS = {},
  isDisabled = false,
  isRequired = false,
  invalid = false,
  label,
  labelCss,
  name,
  onBlur,
  type = 'text',
  value = '',
  textArea,
  placeholder,
  appendValue,
  onChange,
  maxCount,
  'data-cy': dataCy,
}) => {
  return (
    <FormGroup style={{ ...formGroupCSS }}>
      <Label htmlFor={name} style={labelCss}>
        {label}
      </Label>
      {textArea ? (
        <TextArea
          data-cy={dataCy}
          autoComplete={autoComplete}
          disabled={isDisabled}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          required={isRequired}
          style={{ ...inputCSS }}
          invalid={invalid}
        />
      ) : (
        <InputField
          data-cy={dataCy}
          autoComplete={autoComplete}
          disabled={isDisabled}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type={type}
          value={value}
          required={isRequired}
          style={{ ...inputCSS }}
          invalid={invalid}
          appendValue={!!appendValue}
          placeholder={placeholder}
        />
      )}
      {maxCount && !invalid && !errorMessage ? (
        <CharacterCount>{`${maxCount - value.length} character${
          maxCount - value.length === 1 ? '' : 's'
        } remaining`}</CharacterCount>
      ) : (
        <ErrorMessage data-cy="error-message" data-error-msg-for={name}>
          {(invalid && errorMessage) || ''}
        </ErrorMessage>
      )}
    </FormGroup>
  )
}

const FormGroup = styled.div({
  height: '100%',
})

const Label = styled.label({
  display: 'block',
  color: `${COLOR.BLUE[1000]}`,
  fontFamily: 'Nunito Sans',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '18px',
  paddingBottom: 5,
})

const InputField = styled.input<{ invalid?: boolean; appendValue?: boolean }>(
  ({ invalid, appendValue }) => ({
    border: `1px solid ${COLOR.NEUTRAL[300]}`,
    borderRadius: 4,
    boxSizing: 'border-box',
    fontFamily: "'Nunito Sans', Helvetica, sans-serif",
    fontSize: 14,
    lineHeight: '38px',
    padding: '0 12px',
    width: '100%',
    margin: 0,
    '&::placeholder': {
      color: COLOR.NEUTRAL[400],
    },
    '&:disabled': {
      borderColor: COLOR.NEUTRAL[300],
      background: COLOR.NEUTRAL[300],
    },
    '&:focus': {
      border: `1px solid ${invalid ? COLOR.RED[600] : COLOR.BLUESKY}`,
      boxShadow: `0 0 0 1px inset ${invalid ? COLOR.RED[600] : COLOR.BLUESKY}`,
      outline: 'none',
    },
    borderColor: invalid ? COLOR.RED[600] : COLOR.NEUTRAL[300],
    borderTopRightRadius: appendValue ? 0 : 4,
    borderBottomRightRadius: appendValue ? 0 : 4,
  }),
)

const TextArea = styled.textarea<{ invalid?: boolean }>(
  {
    borderRadius: 4,
    border: `1px solid ${COLOR.NEUTRAL[300]}`,
    boxSizing: 'border-box',
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    lineHeight: '40px',
    padding: '0 12px',
    width: '100%',
    margin: 0,
    resize: 'none',
    '&:hover': {
      borderColor: `${COLOR.NEUTRAL[300]}`,
    },
    '&:focus': {
      outline: 'none',
      borderColor: `${COLOR.BLUESKY}`,
      boxShadow: `0 0 0 1px inset ${COLOR.BLUESKY}`,
    },
  },
  ({ invalid }) => ({
    borderColor: invalid ? COLOR.RED[600] : COLOR.NEUTRAL[300],
    boxShadow: `0 0 0 1px inset ${invalid ? COLOR.RED[600] : COLOR.BLUESKY}`,
  }),
)

const ErrorMessage = styled.div({
  color: COLOR.RED[700],
  fontFamily: 'Nunito Sans',
  fontSize: 12,
  lineHeight: '16px',
  marginBottom: 16,
  marginTop: 8,
})

const CharacterCount = styled.div({
  color: COLOR.NEUTRAL[800],
  fontFamily: 'Nunito Sans',
  fontSize: 12,
  lineHeight: '16px',
  marginBottom: 16,
  marginTop: 8,
})

export default Input
