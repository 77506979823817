import type { FC } from 'react'
import React, { useState, useMemo } from 'react'
import type { WDProductFrontend } from '@helloextend/extend-api-rtk-query';
import { useLazyWdProductsSearchQuery } from '@helloextend/extend-api-rtk-query'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import styled from '@emotion/styled'
import { DataTable, Dots, Reset, ModalController } from '@extend/zen'
import { getTableColumns } from './table-config'
import { StoreInfo } from '../store-info'
import { useLoadOptionData, getProductMappingData } from './data-utils'
import { DropdownSelect } from '../dropdown-select'
import type { PaginationState, DataTableAction, ButtonProps } from '@extend/zen'
// import { generateDropdownOptions, generateSearchWDProductsResponse } from '@helloextend/client-test-utils'
import { ValidatePlanCategoryModal } from '../validate-plan-category-modal'


const ProductMapper: FC = () => {
  const [hasFetchedProducts, setHasFetchedProducts] = useState<boolean>(false)
  const [selectedStore, setSelectedStore] = useState<string>()
  const [selectedMappingStatus, setSelectedMappingStatus] = useState<string>('validation_required')
  const [selectedPlanCategoryId, setSelectedPlanCategoryId] = useState<string>()
  const [isPlanCategoryModalOpen, setIsPlanCategoryModalOpen] = useState(false)
  const [productsToValidate, setProductsToValidate] = useState<WDProductFrontend[]>([])

  // const [tableLength, setTableLength] = useState<string>()
  const [pagination, setPagination] = useQueryStringState<PaginationState>(
    'pagination',
    {
      pageIndex: 0,
      pageSize: 100,
    },
    { encode: true },
  )

  const [getProductMappings, { data: productSearchResponseData, isLoading, isFetching }] =
    useLazyWdProductsSearchQuery()
  // const productSearchResponseData = useState(() => generateSearchWDProductsResponse(10))[0]
  // const isLoading = useState(false)[0]
  // const isFetching = useState(false)[0]

  // Fetch data options for dropdowns
  const { storeOptions, storePDRDictionary, mappingStatusOptions, planCategoryIdOptions, isDropdownSelectDataLoading } =
    useLoadOptionData()
  // const storeOptions = useState(() => generateDropdownOptions(5))[0]
  // const mappingStatusOptions = useState(() => generateDropdownOptions(5))[0]
  // const planCategoryIdOptions = useState(() => generateDropdownOptions(5))[0]
  // const isDropdownSelectDataLoading = useState(false)[0]

  // send lazy call to backend API with filters to load the table data
  if (selectedStore && !hasFetchedProducts) {
    const productFilters = getProductMappingData(
      selectedStore,
      selectedMappingStatus,
      selectedPlanCategoryId,
    )
    getProductMappings(productFilters).unwrap()
    setHasFetchedProducts(true)
  }

  const resetProducts = () => {
    setHasFetchedProducts(false)
  }

  const handleValidateClick = (selectedRows: WDProductFrontend[]) => {
    setProductsToValidate(selectedRows)
    setIsPlanCategoryModalOpen(true)
  }

  const handleClosePlanCategoryModal = () => {
    setIsPlanCategoryModalOpen(false)
  }

  const planCategoryOptionsForStore = useMemo(() => {
    if (selectedStore) {
      return storePDRDictionary[selectedStore].map((planCategoryId) => {
        return { display: (planCategoryIdOptions?.find( (option) => option.value == planCategoryId))?.display ?? '' , value: planCategoryId }
      })
    }
    return []
  }, [selectedStore, storePDRDictionary, planCategoryIdOptions])

  const getTableActions = (selectedRows: WDProductFrontend[]): DataTableAction[] => {
    if (selectedRows.length) {
      return [
        {
          onClick: () => handleValidateClick(selectedRows),
          text: `Validate ${selectedRows.length} Selected Products`,
          emphasis: 'medium',
          'data-cy': 'validate-products-button',
          color: 'neutral',
        },
      ]
    }

    const resetButton = {
      onClick: resetProducts,
      emphasis: 'medium' as ButtonProps['emphasis'],
      icon: Reset,
      'data-cy': 'refetch-product-button',
    }

    return [resetButton]
  }

  if (isDropdownSelectDataLoading) {
    return <Dots size='lg' isCenteredInParent isWide />
  }

  return (
    <>
      {!isDropdownSelectDataLoading && (
        <DropdownSelect
          resetProducts={resetProducts}
          setSelectedStore={setSelectedStore}
          setSelectedMappingStatus={setSelectedMappingStatus}
          setSelectedPlanCategoryId={setSelectedPlanCategoryId}
          storeOptions={storeOptions}
          mappingStatusOptions={mappingStatusOptions}
          planCategoryIdOptions={planCategoryIdOptions}
          isDropdownSelectDataLoading={isDropdownSelectDataLoading}
          selectedStore={selectedStore}
          selectedMappingStatus={selectedMappingStatus}
          selectedPlanCategoryId={selectedPlanCategoryId}
        />
      )}
      {selectedStore && (
        <>
        <StoreDataWrapper>
          <StoreInfoWrapper>
            <StoreInfo
              label={storeOptions?.find((store) => store.value === selectedStore)?.display ?? ''}
              value={selectedStore}
              tableLength={productSearchResponseData?.items.length.toString() ?? ''}
            />
          </StoreInfoWrapper>
          <DataTable
            data={productSearchResponseData?.items ?? []}
            columns={getTableColumns(planCategoryIdOptions)}
            isLoading={isLoading || isFetching}
            pageSizeOptions={[5, 10, 25, 50, 100, 250]}
            onPaginationChange={setPagination}
            pagination={pagination}
            getTableActions={getTableActions}
            hasSelectableRows
          />
        </StoreDataWrapper>
        <ModalController isOpen={isPlanCategoryModalOpen}>
          <ValidatePlanCategoryModal
            closeModal={handleClosePlanCategoryModal}
            selectedStoreId={selectedStore}
            planCategoryOptions={planCategoryOptionsForStore}
            productMappings={productsToValidate}
          />
        </ModalController>
        </>
      )}
    </>
  )
}

const StoreDataWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  marginTop: 24,
})

const StoreInfoWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export { ProductMapper }
