import type { FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import type { AdvancedSelectOption } from '@extend/zen'
import {
  Checkbox,
  Button,
  ButtonGroup,
  Stack,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@extend/zen'
import type {
  WDOrchestratorConfig,
  WDActionListItem,
  WDOrchestratorConfigPostRequest,
  WDOrchestratorConfigPutRequest,
} from '@helloextend/extend-api-rtk-query'
import {
  useGetRulesEngineConfigsQuery,
  useGetMlConfigsQuery,
  useUpdateOrchestratorConfigMutation,
  useCreateOrchestratorConfigMutation,
} from '@helloextend/extend-api-rtk-query'
import { AdvancedSelectList } from '../advanced-select-list'

type EditPanelProps = {
  storeId: string
  storeName: string
  onSubmit?: () => void
  onCancel?: () => void
  initialConfig?: WDOrchestratorConfig
}

const EditPanel: FC<EditPanelProps> = ({
  storeId,
  storeName,
  onCancel = () => {},
  onSubmit = () => {},
  initialConfig,
}) => {
  const [isNewConfig, setIsNewConfig] = useState<boolean>(true)
  const [isSentToWD, setIsSentToWD] = useState<boolean>(initialConfig?.isSentToWD === 'true')
  const initialConfigIds: string[] = useMemo(() => {
    if (!initialConfig || JSON.stringify(initialConfig) === '{}') {
      setIsNewConfig(true)
      return []
    }
    setIsNewConfig(false)
    return (initialConfig.actionList ?? []).map((actionItem) => actionItem.id)
  }, [initialConfig])
  const [actionListIds, setActionListIds] = useState<string[]>(
    initialConfigIds.length === 0 ? ['', '', '', ''] : initialConfigIds, // default to 1 empty dropdown
  )

  const { toast } = useToaster()

  // RTK Qeury hooks
  const { rulesEngineConfigs } = useGetRulesEngineConfigsQuery(undefined, {
    selectFromResult: ({ data }): { rulesEngineConfigs: WDActionListItem[] } => ({
      rulesEngineConfigs:
        data === undefined
          ? []
          : data?.configurations.map((config) => ({
              id: config.rules_engine_config_id,
              type: 'rule',
            })),
    }),
  })
  const { mlConfigs } = useGetMlConfigsQuery(undefined, {
    selectFromResult: ({ data }): { mlConfigs: WDActionListItem[] } => ({
      mlConfigs:
        data === undefined ? [] : Object.keys(data).map((mlId) => ({ id: mlId, type: 'ml' })),
    }),
  })
  const [updateConfigPut, { isLoading: isUpdateLoating }] = useUpdateOrchestratorConfigMutation()
  const [createConfigPost, { isLoading: isCreateLoading }] = useCreateOrchestratorConfigMutation()

  // check if the selected values are different from the initial config
  const changedFromOriginalConfig: {
    hasDifferentIsSentToWD: boolean
    hasDifferentActionList: boolean
    hasEmptySelectedValues: boolean
  } = useMemo(() => {
    const hasDifferentActionList =
      JSON.stringify(actionListIds) !== JSON.stringify(initialConfigIds)
    const hasEmptySelectedValues =
      actionListIds.length === 0 ? false : actionListIds.some((actionId) => actionId === '')
    const initialIsSentToWD = initialConfig?.isSentToWD ?? 'false'
    const hasDifferentIsSentToWD = isSentToWD !== (initialIsSentToWD === 'true')
    return { hasDifferentIsSentToWD, hasDifferentActionList, hasEmptySelectedValues }
  }, [actionListIds, initialConfig, initialConfigIds, isSentToWD])
  const { hasDifferentIsSentToWD, hasDifferentActionList, hasEmptySelectedValues } =
    changedFromOriginalConfig

  // Combine the rules engine config and ml config into a single array of options, re-rendered when the data changes from the RTK hooks
  const combinedGroupOptions = useMemo(() => {
    // Sort the rules engine config and ml config by id
    const rulesEngineConfigsSorted = rulesEngineConfigs.sort((a, b) => a.id.localeCompare(b.id))
    const mlConfigsSorted = mlConfigs.sort((a, b) => a.id.localeCompare(b.id))

    // Create the options for the rules engine config and ml config
    const rulesEngineConfigOptions: AdvancedSelectOption[] = rulesEngineConfigsSorted.map(
      (actionItem: WDActionListItem) => ({
        badgeColor: 'red',
        badgeText: actionItem.type,
        display: actionItem.id,
        value: actionItem.id,
      }),
    )
    const mlConfigOptions: AdvancedSelectOption[] = mlConfigsSorted.map(
      (actionItem: WDActionListItem) => ({
        badgeColor: 'blue',
        badgeText: actionItem.type,
        display: actionItem.id,
        value: actionItem.id,
      }),
    )
    // Combine the options into a single array of groups
    return [
      {
        label: 'Rules Engine Configurations',
        options: rulesEngineConfigOptions,
      },
      {
        label: 'ML Configurations',
        options: mlConfigOptions,
      },
    ]
  }, [rulesEngineConfigs, mlConfigs])

  const handleSubmitOnClick = async (): Promise<void> => {
    const actionList: WDActionListItem[] = actionListIds.map((actionId) => ({
      id: actionId,
      type: rulesEngineConfigs.some((rule) => rule.id === actionId) ? 'rule' : 'ml',
    }))
    if (isNewConfig) {
      // POST (create) the new config
      const postRequest: WDOrchestratorConfigPostRequest = {
        storeId,
        storeName,
        isSentToWD: isSentToWD ? 'true' : 'false',
        actionList,
      }
      try {
        await createConfigPost(postRequest).unwrap()
        toast({
          message: 'WD Configuration has been successfully created.',
          toastColor: ToastColor.blue,
          toastDuration: ToastDuration.long,
        })
      } catch (error) {
        toast({
          message: 'Something went wrong. Please try again.',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.long,
        })
      }
    } else {
      // PUT (update) the existing config
      const putRequest: WDOrchestratorConfigPutRequest = {
        storeId,
      }
      if (hasDifferentIsSentToWD) {
        putRequest.isSentToWD = isSentToWD ? 'true' : 'false'
      }
      if (hasDifferentActionList) {
        putRequest.actionList = actionList
      }
      try {
        await updateConfigPut(putRequest).unwrap()
        toast({
          message: 'WD Configuration has been successfully updated.',
          toastColor: ToastColor.blue,
          toastDuration: ToastDuration.long,
        })
      } catch (error) {
        toast({
          message: 'Something went wrong. Please try again.',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.long,
        })
      }
    }
    onSubmit() // close panel
  }

  const handleCheckboxChange: () => void = useCallback(() => {
    setIsSentToWD(!isSentToWD)
  }, [isSentToWD])

  return (
    <>
      <Checkbox
        data-cy="edit-checkbox"
        checked={isSentToWD}
        label="Send the store to WD engine"
        onChange={handleCheckboxChange}
      />
      <h2>ML Rules Engine Configuration</h2>
      <>
        Please specify ML models and rulesets to be used for the store. The store will run through
        the configuration in the list order.
      </>
      <Stack spacing={1} data-cy="edit-stack">
        <AdvancedSelectList
          onSelectedValuesChange={(selectedActionIds) => setActionListIds(selectedActionIds)}
          initialSelectedValues={initialConfigIds}
          optionGroups={combinedGroupOptions}
        />
        <ButtonWrapper>
          <ButtonGroup>
            <Button
              text="Cancel"
              onClick={onCancel}
              emphasis="medium"
              isDisabled={false}
              data-cy="cancel-button"
            />
            <Button
              text="Save Changes"
              onClick={handleSubmitOnClick}
              emphasis="high"
              isDisabled={
                (!hasDifferentActionList && !hasDifferentIsSentToWD) || hasEmptySelectedValues
              }
              isProcessing={isUpdateLoating || isCreateLoading}
              data-cy="submit-button"
              tooltip="All Dropdowns must be selected and there must be changes from initial config"
            />
          </ButtonGroup>
        </ButtonWrapper>
      </Stack>
    </>
  )
}

const ButtonWrapper = styled.div({
  marginTop: '24px',
})

export { EditPanel }
