import type { FC } from 'react'
import React from 'react'
import type { BadgeColor } from '@extend/zen'
import {
  Badge,
  DataProperty,
  DataPropertyType,
  Label,
  OpenInNew,
  Icon,
  COLOR,
  IconSize,
} from '@extend/zen'
import styled from '@emotion/styled'
import type { CompactFormFieldData } from './types'
import { AdditionalDataPropertyTypes } from './types'

const CompactFormDataProperty: FC<CompactFormFieldData> = ({
  value,
  label,
  dataPropertyType,
  currencyCode = 'USD',
  urlText,
}) => {
  const commonProps = { label, isHorizontal: true, currencyCode }
  switch (dataPropertyType) {
    case DataPropertyType.number:
      return <DataProperty {...commonProps} value={Number(value)} type={dataPropertyType} />
    case DataPropertyType.date:
      return <DataProperty {...commonProps} value={Number(value)} type={dataPropertyType} />
    case DataPropertyType.string:
      return <DataProperty {...commonProps} value={String(value)} type={dataPropertyType} />
    case DataPropertyType.currency:
      return <DataProperty {...commonProps} value={Number(value)} type={dataPropertyType} />
    case AdditionalDataPropertyTypes.badge:
      return <BadgeDataProperty label={label} value={String(value)} />
    case AdditionalDataPropertyTypes.url:
      return value ? (
        <UrlDataProperty label={label} value={String(value)} urlText={urlText || ''} />
      ) : (
        <DataProperty {...commonProps} />
      )
    default:
      return <DataProperty label={label} value={String(value)} isHorizontal />
  }
}

const UrlDataProperty: FC<{ label: string; value: string; urlText: string }> = ({
  label,
  value,
  urlText,
}) => {
  return (
    <CustomDataPropertyContainer>
      <Label isMuted>{label}:</Label>
      <a href={value}>{urlText}</a>
      <Icon icon={OpenInNew} size={IconSize.xsmall} color={COLOR.BLUE[700]} />
    </CustomDataPropertyContainer>
  )
}

const BadgeDataProperty: FC<{ label: string; value: string; color?: BadgeColor }> = ({
  label,
  value,
  color = 'green',
}) => {
  return (
    <CustomDataPropertyContainer>
      <Label isMuted>{label}</Label>
      <Badge data-cy="status-badge" text={value} color={color} />
    </CustomDataPropertyContainer>
  )
}

const CustomDataPropertyContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 8,
})

export { CompactFormDataProperty }
