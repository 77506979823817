import styled from '@emotion/styled'
import { COLOR, Chip, Icon, Input, Check } from '@extend/zen'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updatePhotoRequirement } from '../../../../../store/slices/amp-slice'

type TagsProps = {
  index: number
  tags: string[]
}

const Tags: React.FC<TagsProps> = ({ index, tags }) => {
  const dispatch = useDispatch()
  /**
   * There is no guard logic for tags per requirements. User must be careful with data sanitization.
   */
  const [inputValue, setInputValue] = useState('')

  const handleDismissClick = (tag: string): void => {
    // we use filter so that a new array is returned and the component re-renders as opposed to mutating the array
    dispatch(updatePhotoRequirement({ index, updates: { tags: tags.filter((t) => t !== tag) } }))
  }

  return (
    <Wrapper>
      <Input
        label="Tags"
        endAdornment={inputValue ? <Icon icon={Check} color={COLOR.BLUE[700]} /> : undefined}
        value={inputValue}
        id={`tag-input-${index}`}
        onChange={(e): void => {
          setInputValue(e.target.value)
        }}
        onKeyUp={(e): void => {
          if (e.key === 'Enter' && inputValue) {
            dispatch(updatePhotoRequirement({ index, updates: { tags: [...tags, inputValue] } }))
            setInputValue('')
          }
        }}
        helperText="Tags will be used for image data analysis."
        placeholder="Ex. mattress"
        data-cy={`tag-input-${index}`}
      />
      {!!tags.length && (
        <TagsContainer>
          {tags.map((tag) => (
            <Chip
              key={`tag-chip-${index}-${tag}`}
              text={tag}
              onDismissClick={() => handleDismissClick(tag)}
              data-cy={`tag-chip-${index}-${tag}`}
            />
          ))}
        </TagsContainer>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  gap: 4,
  flexDirection: 'column',
})

const TagsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  padding: '7px 12px 7px 12px',
  borderRadius: 4,
  border: 'solid',
  borderColor: COLOR.NEUTRAL[300],
  placeItems: 'flex-start',
})

export default Tags
