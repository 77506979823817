import type { FC} from 'react';
import React, { useState } from 'react'
import { Add, Button, Grid, GridItem, Input, InputType, Section, Select, Stack } from '@extend/zen'
import type { LimitOfLiability as LimitOfLiabilityType } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'
import {
  useCreateLimitOfLiabilityMutation,
  useUpdateLimitOfLiabilityMutation,
} from '@helloextend/extend-api-rtk-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'

type LimitOfLiabilityProps = {
  termsId: string
  version: number
  limitOfLiability?: Pick<LimitOfLiabilityType, 'amount' | 'count' | 'percentage'>
}

const schema = Yup.object()
  .shape({
    valueType: Yup.string().required('Select type of limit of liability'),
    value: Yup.number()
      .min(1, 'Limit of Liability must be minimum of 1')
      .required('Value is required'),
  })
  .notRequired()

const LimitOfLiability: FC<LimitOfLiabilityProps> = ({ termsId, version, limitOfLiability }) => {
  const [editMode, setEditMode] = useState(false)

  const [create, { isLoading: isCreating }] = useCreateLimitOfLiabilityMutation()

  const [update, { isLoading: isUpdating }] = useUpdateLimitOfLiabilityMutation()

  const { values, handleChange, handleBlur, errors, touched, handleSubmit, resetForm, dirty } =
    useFormik({
      initialValues: {
        valueType: getDefinedPropertyName(limitOfLiability) ?? '',
        value: getDefinedPropertyValue(limitOfLiability) ?? 0,
      },
      validationSchema: schema,
      validateOnMount: false,
      validateOnBlur: true,
      validateOnChange: true,
      onSubmit: async (values) => {
        if (limitOfLiability) {
          await update({
            termsId,
            version,
            limitOfLiability: {
              [values.valueType]: values.value,
            },
            updateReason: `Update limit of liability ${values.valueType} to ${values.value}`,
          })
        }

        if (!limitOfLiability) {
          await create({
            termsId,
            version,
            limitOfLiability: {
              [values.valueType]: values.value,
            },
            updateReason: `Create limit of liability with ${values.valueType} of ${values.value}`,
          })
        }
      },
    })

  if (!limitOfLiability && !editMode) {
    return (
      <Button
        icon={Add}
        type="button"
        text="Add Limit Of Liability"
        emphasis="medium"
        onClick={() => setEditMode(true)}
        data-cy="add-limit-of-liability-button"
      />
    )
  }

  return (
    <Section
      heading="Limit Of Liability"
      buttons={
        editMode
          ? [
              {
                text: 'Save',
                emphasis: 'high',
                onClick: () => handleSubmit(),
                isProcessing: isCreating || isUpdating,
                isDisabled: !dirty || Object.keys(errors).length > 0,
                'data-cy': 'save-limit-of-liability-button',
              },
              {
                text: 'Cancel',
                emphasis: 'medium',
                onClick: () => {
                  resetForm()
                  setEditMode(false)
                },
                'data-cy': 'cancel-limit-of-liability-button',
              },
            ]
          : [
              {
                text: 'Edit',
                emphasis: 'medium',
                onClick: () => setEditMode(true),
                'data-cy': 'edit-limit-of-liability-button',
              },
            ]
      }
    >
      <Stack spacing={1}>
        <Grid columns={4} spacing={2}>
          <GridItem span={1}>
            <Select
              id="valueType"
              value={values.valueType}
              onChange={handleChange}
              onBlur={handleBlur}
              data-cy="limit-of-liability-type-select"
              isDisabled={!editMode}
              isError={touched.valueType && !!errors.valueType}
              errorFeedback={errors.valueType}
            >
              <option value="">Select</option>
              <option value="count">Count</option>
              <option value="percentage">Percentage</option>
              <option value="amount">Amount</option>
            </Select>
          </GridItem>
          {values.valueType ? (
            <GridItem span={1}>
              <Input
                id="value"
                value={values.value.toString()}
                type={InputType.number}
                onChange={handleChange}
                onBlur={handleBlur}
                data-cy="limit-of-liability-input"
                isDisabled={!editMode}
                isError={touched.value && !!errors.value}
                errorFeedback={errors.value}
              />
            </GridItem>
          ) : null}
        </Grid>
      </Stack>
    </Section>
  )
}

function getDefinedPropertyName<T extends object>(obj: T | undefined): keyof T | undefined {
  for (const key in obj) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      obj.hasOwnProperty(key) &&
      obj[key] !== undefined &&
      ['amount', 'percentage', 'count'].includes(key)
    ) {
      return key
    }
  }
  return undefined
}

function getDefinedPropertyValue<T extends object>(obj: T | undefined): T[keyof T] | undefined {
  for (const key in obj) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      obj.hasOwnProperty(key) &&
      obj[key] !== undefined &&
      ['amount', 'percentage', 'count'].includes(key)
    ) {
      return obj[key]
    }
  }
  return undefined
}

export { LimitOfLiability }
