import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Button, ButtonGroup } from '@extend/zen'
import { useServiceOrderSetRepairShippedMutation } from '@helloextend/extend-api-rtk-query'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { ClaimDetailsTabs } from '../tab-section/types'

interface MarkAsShippedContentProps {
  serviceOrder: ServiceOrder
}

const MarkAsShippedContent: FC<MarkAsShippedContentProps> = ({ serviceOrder }) => {
  const dispatch = useDispatch()
  const [setRepairShipped, { isSuccess, isLoading }] = useServiceOrderSetRepairShippedMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(setClaimDetailsToast('SO was manually marked as shipped!'))
      dispatch(setClaimDetailsActiveView(ClaimDetailsTabs.ServiceOrder))
    }
  }, [isSuccess, dispatch])

  const handleSubmit = (): void => {
    setRepairShipped({ serviceOrderId: serviceOrder.id })
  }

  return (
    <>
      <ContentWrapper>
        <Text>
          The product return shipping label that has been sent to the customer has not yet been
          scanned.
        </Text>
        <Text>Are you sure you want to manually mark this repair as shipped?</Text>
      </ContentWrapper>
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(ClaimDetailsTabs.ServiceOrder))}
            isDisabled={isLoading}
          />
          <Button
            type="submit"
            text="Mark as Shipped"
            data-cy="mark-shipped-button"
            isDisabled={isLoading}
            onClick={() => handleSubmit()}
            isProcessing={isLoading}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  maxHeight: 650,
  overflow: 'scroll',
  scrollbarWidth: 'none',
})

const Text = styled.p({
  marginBottom: 0,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  paddingTop: 20,
  marginLeft: -32,
  padding: '20px 32px 0 32px',
  width: '100%',
  justifyContent: 'flex-end',
})

export { MarkAsShippedContent }
