import { AdvancedSelect, Checkbox, Grid } from '@extend/zen'
import { useFormikContext } from 'formik'
import React from 'react'
import type { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../../../../../../constants/ld-flags'
import type { Values } from '../schema'
import { partialReimbursementOptions } from '../schema'

const PartialReimbursementSelection: FC = () => {
  const { values, handleChange, errors } = useFormikContext<Values>()
  const flags = useFlags()
  const FF_PARTIAL_REIMBURSEMENT_OPTIONALITY = flags[LDFlag.PartialReimbursementOptionality]

  const partialReimbursementDisplayOptions = FF_PARTIAL_REIMBURSEMENT_OPTIONALITY
    ? partialReimbursementOptions
    : [
        {
          display: 'Percentage',
          value: 'percentage',
        },
      ]
  return (
    <>
      <Checkbox
        label="Partial Reimbursement Merchant"
        checked={Boolean(values.partialReimbursementEnabled)}
        onChange={handleChange}
        name="partialReimbursementEnabled"
        data-cy="partial-reimbursement-merchant-checkbox"
      />
      {values.partialReimbursementEnabled && (
        <Grid columns={4} spacing={4}>
          <AdvancedSelect
            isNotClearable
            label="Partial Reimbursement Options"
            id="partialReimbursementSelection"
            value={values.partialReimbursementSelection as string}
            onChange={handleChange}
            placeholder="Select"
            options={partialReimbursementDisplayOptions}
            multiple={false}
            isError={Boolean(errors?.partialReimbursementSelection)}
            errorFeedback={errors?.partialReimbursementSelection}
            data-cy="partial-reimbursement-sp-select"
          />
        </Grid>
      )}
    </>
  )
}

export { PartialReimbursementSelection }
