import type { Servicer, ServicerLocation, ServicerSettings } from '@helloextend/extend-api-client'
import { faker } from '@faker-js/faker/locale/en'
import type { RateCard } from '@helloextend/extend-api-rtk-query/src/servicers/types'

export const generateServicerSettings = (
  overrides: Partial<ServicerSettings> = {},
): ServicerSettings => ({
  depotRepairEnabled: false,
  onsiteRepairEnabled: false,
  preventAutoAccept: false,
  shipments: {
    rmaNumberRequired: false,
  },
  ...overrides,
})

export const generateServicer = (overrides: Partial<Servicer> = {}): Servicer => {
  return {
    id: faker.datatype.uuid(),
    sellerId: faker.datatype.uuid(),
    type: 'third-party',
    authOrganizationId: faker.datatype.uuid(),
    businessName: faker.company.name(),
    contact: {
      email: faker.internet.exampleEmail(),
      phone: faker.phone.number('!##-!##-####'),
      name: faker.name.firstName(),
    },
    locations: [],
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    ...overrides,
    settings: generateServicerSettings(overrides?.settings),
  }
}

export const generateServicerLocation = (
  overrides: Partial<ServicerLocation> = {},
): ServicerLocation => {
  return {
    id: faker.datatype.uuid(),
    businessName: faker.company.name(),
    contact: {
      email: faker.internet.exampleEmail(),
      phone: faker.phone.number('!##-!##-####'),
      name: faker.name.firstName(),
    },
    address: {
      address1: faker.address.streetAddress(),
      address2: faker.address.secondaryAddress(),
      city: faker.address.city(),
      countryCode: 'US',
      postalCode: faker.address.zipCode(),
      provinceCode: faker.address.stateAbbr(),
    },
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    ...overrides,
  }
}

export const generateServicerRateCard = (overrides?: Partial<RateCard>): RateCard => {
  return {
    id: faker.datatype.uuid(),
    servicerId: faker.datatype.uuid(),
    lastUpdatedBy: faker.name.fullName(),
    isActive: true,
    createdAt: faker.date.recent().getTime(),
    startDate: faker.date.recent().getTime(),
    rates: [
      {
        type: 'diagnosis_fee',
        description: faker.lorem.sentence(),
        qtyLow: 1,
        qtyHigh: 1,
        costLow: {
          amount: 100,
          currencyCode: 'USD',
        },
        rate: {
          amount: 1000,
          currencyCode: 'USD',
          formattedAmount: '$10.00',
        },
      },
    ],
    ...overrides,
  }
}
