import type { UserRole } from '../../utils/user-role-mapper'
import { enterpriseInternalRoles } from '../../utils/user-role-mapper'
import { LDFlag } from '../../constants/ld-flags'
import { Permission } from './permissions'
import type { RuleDefinition } from './rules'

export const enterpriseRules: Record<Permission, RuleDefinition> = {
  // ACCOUNT
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageProfile]: {
    allowedRoles: [
      enterpriseInternalRoles.ExtendPortalAdmin.value as UserRole,
      enterpriseInternalRoles.ProvisioningManager.value as UserRole,
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
      enterpriseInternalRoles.conversationsadmin.value as UserRole,
    ],
  },

  [Permission.RoleManagementView]: {
    allowedRoles: [
      enterpriseInternalRoles.ExtendPortalAdmin.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
    requiredFeatureFlags: [LDFlag.RolePermissionManagement],
  },

  // PLANS
  // ----------------------------------------------------------------------------------------------
  [Permission.ViewPlans]: {
    allowedRoles: [
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  [Permission.ManagePlans]: {
    allowedRoles: [
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  // PLANS ATTRIBUTES
  [Permission.ViewPlansAttributes]: {
    allowedRoles: [
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  [Permission.ManagePlansAttributes]: {
    allowedRoles: [
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  // PLAN SETS
  [Permission.ManagePlanSets]: {
    allowedRoles: [
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  // SERVICERS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageServicers]: {
    allowedRoles: [
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  // ORDERS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageOrders]: {
    allowedRoles: [
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
      enterpriseInternalRoles.DemoCSR.value as UserRole,
    ],
  },

  // CONTRACTS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageContracts]: {
    allowedRoles: [
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
      enterpriseInternalRoles.DemoCSR.value as UserRole,
    ],
  },

  // PRODUCTS
  // ----------------------------------------------------------------------------------------------

  // Plan Mapping Validation
  [Permission.ManageProducts]: {
    allowedRoles: [
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  // Category Mapping
  [Permission.ManageCategories]: {
    allowedRoles: [
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  // Promotion Products Download
  [Permission.ManageEPPDownload]: {
    allowedRoles: [
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  // CLAIMS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageClaims]: {
    allowedRoles: [
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.DemoCSR.value as UserRole,
    ],
  },

  [Permission.ManageClaimsAssignments]: {
    allowedRoles: [enterpriseInternalRoles.PlanManager.value as UserRole],
  },

  // PREMIUMS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManagePremiums]: {
    allowedRoles: [
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
    ],
  },

  [Permission.ViewPremiums]: {
    allowedRoles: [
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  // Insurance Programs
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageInsurancePrograms]: {
    allowedRoles: [
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  // ADJUDICATION
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageAdjudication]: {
    allowedRoles: [enterpriseInternalRoles.PlanManager.value as UserRole],
  },

  [Permission.ViewAdjudication]: {
    allowedRoles: [
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
    ],
  },

  // STORES
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageStores]: {
    allowedRoles: [
      enterpriseInternalRoles.ProvisioningManager.value as UserRole,
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  [Permission.CreateStores]: {
    allowedRoles: [enterpriseInternalRoles.ProvisioningManager.value as UserRole],
  },

  // ORGANIZATIONS
  // ----------------------------------------------------------------------------------------------
  [Permission.ViewOrganizations]: {
    allowedRoles: [
      enterpriseInternalRoles.ProvisioningManager.value as UserRole,
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
    requiredFeatureFlags: [LDFlag.OrganizationsManagement],
  },

  [Permission.ManageOrganizations]: {
    allowedRoles: [
      enterpriseInternalRoles.ProvisioningManager.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
    requiredFeatureFlags: [LDFlag.OrganizationsManagement],
  },

  // ACCOUNTS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageAccounts]: {
    allowedRoles: [
      enterpriseInternalRoles.ProvisioningManager.value as UserRole,
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  [Permission.CreateAccounts]: {
    allowedRoles: [enterpriseInternalRoles.ProvisioningManager.value as UserRole],
  },

  // USERS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageMerchantUsers]: {
    allowedRoles: [enterpriseInternalRoles.ProvisioningManager.value as UserRole],
    requiredFeatureFlags: [LDFlag.LegacyAndEnterpriseRoles],
  },

  [Permission.ViewMerchantUsers]: {
    allowedRoles: [
      enterpriseInternalRoles.ProvisioningManager.value as UserRole,
      enterpriseInternalRoles.MerchantSuccess.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
    requiredFeatureFlags: [LDFlag.LegacyAndEnterpriseRoles],
  },

  [Permission.ManageExtendUsers]: {
    allowedRoles: [enterpriseInternalRoles.ExtendPortalAdmin.value as UserRole],
    requiredFeatureFlags: [LDFlag.LegacyAndEnterpriseRoles],
  },

  // WARRANTY DECISIONING
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageWarrantyDecisioning]: {
    allowedRoles: [
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
    ],
  },

  // TERMS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageTerms]: {
    allowedRoles: [
      enterpriseInternalRoles.SolutionEngineer.value as UserRole,
      enterpriseInternalRoles.CustomerSupportAgent.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingManager.value as UserRole,
      enterpriseInternalRoles.WarrantyMappingSpecialist.value as UserRole,
      enterpriseInternalRoles.PlanManager.value as UserRole,
      enterpriseInternalRoles.PlanAnalyst.value as UserRole,
      enterpriseInternalRoles.FinanceAnalyst.value as UserRole,
      enterpriseInternalRoles.Engineer.value as UserRole,
      enterpriseInternalRoles.ComplianceManager.value as UserRole,
    ],
  },
}
