import type { FC } from 'react'
import React from 'react'
import type { DataTableAction, ColumnDefs, CellContext } from '@extend/zen'
import { HeadingLarge, DataTable, Add, Trash } from '@extend/zen'
import type { PlanCategory, PlanSet, PlanSetPriceBand } from '@helloextend/extend-api-client'
import { currency } from '@extend/client-helpers'
import { getPlanSetFilterDefs } from '../lib'
import { sortPlanCategoriesAsSelectOptions } from '../../utils/sort-plan-categories-as-select-options'

interface PlanSetsTableProps {
  planSets: PlanSet[]
  planCategories: PlanCategory[]
  isFetching: boolean
  handleCreatePlanSet: () => void
  handleEditPlanSet: (planSetId: string) => void
  handleDeletePlanSet: (planSetId: string) => void
}

const PlanSetsTable: FC<PlanSetsTableProps> = ({
  planSets,
  planCategories,
  isFetching,
  handleCreatePlanSet,
  handleEditPlanSet,
  handleDeletePlanSet,
}) => {
  // TODO: [OFFERS-1743] remove this after running taskr script to delete old data
  const filteredPlanSets: PlanSet[] = planSets
    .filter(({ name }) => name)
    // DataTable does not support filtering on booleans - maps GTM string value to filter on
    .map((planSet) => ({
      ...planSet,
      isGTMString: `${planSet.isGTM ? 'Yes' : 'No'}`,
    }))

  const getTableActions = (): DataTableAction[] => [
    {
      text: 'Create Plan Set',
      'data-cy': 'create-plan-set-button',
      icon: Add,
      emphasis: 'medium',
      onClick: handleCreatePlanSet,
    },
  ]

  const getRowActions = (row: PlanSet): DataTableAction[] => [
    {
      onClick: () => handleEditPlanSet(row.id),
      'data-cy': 'edit-plan-set-button',
      text: 'Edit',
      emphasis: 'low',
    },
    {
      onClick: () => handleDeletePlanSet(row.id),
      'data-cy': 'delete-plan-set-button',
      icon: Trash,
      emphasis: 'low',
    },
  ]

  const sortedPlanSetCategories = sortPlanCategoriesAsSelectOptions(planCategories)

  return (
    <DataTable
      heading={<HeadingLarge data-cy="plan-sets-header-text">Plan Sets</HeadingLarge>}
      itemName="plan set"
      data={filteredPlanSets}
      data-cy="plan-sets-data-table"
      columns={TABLE_COLUMNS}
      isLoading={isFetching}
      getTableActions={getTableActions}
      getRowActions={getRowActions}
      filterDefs={getPlanSetFilterDefs(sortedPlanSetCategories)}
    />
  )
}

export const TABLE_COLUMNS: ColumnDefs<PlanSet> = [
  {
    id: 'name',
    label: 'Plan Set Name',
    search: 'explicit',
    isAlwaysPinned: true,
    isHugged: true,
  },
  {
    id: 'planCategory',
    label: 'Extend Plan Category',
    isHugged: true,
  },
  {
    id: 'priceBands',
    label: '# of Price Bands',
    renderCell: (data: CellContext<PlanSet, PlanSetPriceBand[]>) => (
      <span data-tooltip={getPriceBandToolTipValue(data.getValue())}>{data.getValue().length}</span>
    ),
  },
  {
    id: 'isGTMString',
    label: 'GTM',
    renderCell: (data: CellContext<PlanSet, string>) => data.getValue(),
    isSortable: true,
  },
]

const getPriceBandToolTipValue = (priceBands: PlanSetPriceBand[]): string =>
  priceBands
    .map(
      (priceBand) =>
        `${currency.format(priceBand.minPrice)} - ${currency.format(priceBand.maxPrice)}`,
    )
    .join(', ')

export { PlanSetsTable }
