import { Breadcrumbs } from '@extend/zen'
import type { FC } from 'react'
import React, { memo } from 'react'

const LineItemBreadcrumbs: FC<{ orderId: string; lineItemId: string }> = memo(
  ({ orderId, lineItemId }) => {
    return (
      <Breadcrumbs
        crumbs={[
          {
            text: 'Orders',
            to: '/admin/orders',
          },
          {
            text: `Order ID: ${orderId}`,
            to: `/admin/orders/${orderId}`,
          },
          {
            text: `Line Item ID: ${lineItemId}`,
          },
        ]}
        data-cy="line-item-details-breadcrumbs"
        max-width="50ch"
      />
    )
  },
)

export { LineItemBreadcrumbs }
