import styled from '@emotion/styled'
import type { ScriptItem, Message } from '@helloextend/extend-api-rtk-query'
import { COLOR } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import { MessageType } from '@helloextend/extend-api-rtk-query'
import { AdjudicationMessage } from '../message-block/adjudication-message'
import {
  DynamicPickerTypes,
  getDynamicPickerTypeFromScriptItem,
  getIndex,
  getIsReplacementReply,
} from '../../utils'
import { AdjudicationPromptWrapper } from '../message-block/prompt-types/adjudication-prompt-wrapper'

interface DynamicPickerProps {
  scriptItem: ScriptItem
  onBadgeClick: (val: number | string) => void
}

const DynamicPicker: FC<DynamicPickerProps> = ({ scriptItem, onBadgeClick }) => {
  const {
    reply,
    collect: { options },
  } = scriptItem
  const pickerType = getDynamicPickerTypeFromScriptItem(scriptItem)

  const messages = getIsReplacementReply(reply)
    ? [
        {
          content: `The content here is dynamic based on the customer input. This message block doesn't represent what consumers see in Kaley.`,
          type: MessageType.text,
        },
      ]
    : reply?.messages

  return (
    <TemplateWrapper>
      <HeadingRow>
        <MessageTitle data-cy="adjudication-management-block-title">Dynamic Picker</MessageTitle>
      </HeadingRow>
      <Wrapper>
        {messages?.map((message: Message, index: number) => {
          return (
            <AdjudicationMessage
              key={JSON.stringify(message)}
              message={message}
              isReplacementReply={false}
              messageIndex={index}
            />
          )
        })}
      </Wrapper>
      <Wrapper>
        <AdjudicationPromptWrapper
          hasBottomBorder
          key="adjudication-prompt-shipment-select-input"
          badgeValue={getIndex(options[0])}
          onBadgeClick={onBadgeClick}
        >
          <StyledInput
            data-cy="adjudication-prompt-shipment-select-input"
            disabled
            id="shipmentSelectInput"
            value=""
            onChange={() => {}}
            placeholder={`Select ${
              pickerType === DynamicPickerTypes.shipment ? 'Shipment' : 'Item(s)'
            }`}
          />
        </AdjudicationPromptWrapper>
      </Wrapper>
    </TemplateWrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  margin: '8px 0 0 0',
})

const TemplateWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const HeadingRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const MessageTitle = styled.div({
  color: COLOR.NEUTRAL[600],
  fontSize: 10,
  lineHeight: '18px',
  marginLeft: 4,
})

const StyledInput = styled.input({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 24px 4px 12px',
  border: `1px solid ${COLOR.NEUTRAL[600]}`,
  lineHeight: '24px',
  borderRadius: '4px',
  flexGrow: 1,
  fontStyle: 'italic',
})

export { DynamicPicker }
