import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { currency, date } from '@extend/client-helpers'
import { COLOR } from '@extend/zen'
import type { ServiceOrder } from '@helloextend/extend-api-client'

interface EstimateInfoBannerProps {
  serviceOrder: ServiceOrder
  expenseTotal: number
  remainingLoL?: number
}

const EstimateInfoBanner: FC<EstimateInfoBannerProps> = ({
  serviceOrder,
  expenseTotal,
  remainingLoL = 0,
}) => {
  const isFulfilled =
    serviceOrder.status === 'fulfilled' ||
    serviceOrder.status === 'payment_approved' ||
    serviceOrder.status === 'payment_requested' ||
    serviceOrder.status === 'paid'
  const data = [
    {
      header: 'Amount Requested',
      text: currency.format(expenseTotal),
      dataCy: 'amount-requested',
    },
    {
      header: 'Amount Approved',
      text: currency.format(serviceOrder.status === 'payment_approved' ? expenseTotal : 0),
      dataCy: 'amount-approved',
    },
    {
      header: 'Last Modified',
      text: date.format(serviceOrder.updatedAt, 'MM/DD/YYYY'),
      dataCy: 'last-modified',
    },
    {
      header: 'LoL',
      text: currency.format(remainingLoL),
      dataCy: 'lol',
    },
    {
      header: 'Remaining LoL',
      text: isFulfilled
        ? currency.format(remainingLoL)
        : currency.format(remainingLoL - expenseTotal),
      dataCy: 'remaining-lol',
    },
  ]

  return (
    <Banner>
      {data.map((info) => (
        <Info data-cy={info.dataCy} key={info.header}>
          <Header>{info.header}</Header>
          {info.text}
        </Info>
      ))}
    </Banner>
  )
}

const Banner = styled.div({
  display: 'flex',
  gap: 40,
  alignItems: 'center',
  margin: '16px 0',
  backgroundColor: COLOR.BLUE[100],
  borderRadius: 6,
  padding: 15,
  border: `1px solid ${COLOR.BLUE[300]}`,
})

const Info = styled.div({
  display: 'flex',
  gap: 5,
  flexDirection: 'column',
  fontSize: 16,
})

const Header = styled.div({
  fontSize: 14,
  fontWeight: 700,
})

export { EstimateInfoBanner }
