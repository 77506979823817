import type { SortingState } from '@extend/zen'

export function sortItems<TItem>(items: TItem[], [sortingProps]: SortingState) {
  if (!sortingProps) return items

  const { desc } = sortingProps
  const id = sortingProps.id as keyof TItem

  return [...items].sort((a, b) => {
    // This should push undefined values to the bottom of the table
    if (a[id] === undefined) return 1
    if (b[id] === undefined) return -1

    if (desc) {
      return (a[id] || 0) > (b[id] || 0) ? -1 : 1
    }

    return (a[id] || 0) < (b[id] || 0) ? -1 : 1
  })
}
