import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Badge } from '@extend/zen'
import type { ClaimCode } from '@helloextend/extend-api-client'
import { getValidationErrorMessage } from './utils'

type ErrorDenialProps = {
  validationError: keyof typeof ClaimCode
}

const ErrorDenial: FC<ErrorDenialProps> = ({ validationError }) => {
  const claimMessage = validationError && getValidationErrorMessage(validationError)
  return (
    <Container>
      <ClaimResult>
        <Description>AMP claim result:</Description>
        <Badge data-cy="claim-denial-badge" color="red" emphasis="medium" text="Claim Denied" />
      </ClaimResult>
      <Description data-cy="claim-denial-reason">{claimMessage}</Description>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
})

const ClaimResult = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
})

const Description = styled.p({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
})

export { ErrorDenial }
