import type { FC, SyntheticEvent } from 'react'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useFlags } from 'launchdarkly-react-client-sdk'
import * as Yup from 'yup'
import styled from '@emotion/styled'
import { useServiceOrderStartLabelTrackingMutation } from '@helloextend/extend-api-rtk-query'
import type { ShippingCarrier } from '@helloextend/extend-api-client'
import { Button, Input, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { CarrierSelect } from '../../../../../../../../../../components/carrier-select'
import { ShippingLabelErrorBanner } from './shipping-label-error-banner'
import type { TrackingErrorResponse } from '../../../../../../../../../../types/service-orders'
import { LDFlag } from '../../../../../../../../../../constants/ld-flags'

interface ShippingLabelFormProps {
  serviceOrderId: string
  destinationType?: string
}

const ShippingLabelForm: FC<ShippingLabelFormProps> = ({ serviceOrderId, destinationType }) => {
  const { [LDFlag.ExpandedServiceOrdersSection]: FF_EXPANDED_SO_SECTION } = useFlags()
  const { toast } = useToaster()
  let errorCode
  const [createShippingLabel, { isSuccess, isLoading, error }] =
    useServiceOrderStartLabelTrackingMutation()
  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: { carrierCode: '' as ShippingCarrier, trackingNumber: '', destinationType },
    onSubmit: async (body) => {
      createShippingLabel({ serviceOrderId, body })
    },
    validationSchema: Yup.object().shape({
      carrierCode: Yup.string<ShippingCarrier>().required('Required'),
      trackingNumber: Yup.string().required('Required'),
    }),
  })

  if (error) {
    errorCode = (error as TrackingErrorResponse).data.code
  }

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: 'Tracking information saved!',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isSuccess, toast])

  return (
    <form onSubmit={handleSubmit}>
      <FormWrapper restrictWidth={FF_EXPANDED_SO_SECTION}>
        {errorCode && <ShippingLabelErrorBanner errorCode={errorCode} />}
        <RowWrapper gridView={FF_EXPANDED_SO_SECTION}>
          <CarrierSelect
            data-cy="shipping-label-carrier-select"
            errorMessage={(touched.carrierCode && errors.carrierCode) || ''}
            onChange={(e: SyntheticEvent<HTMLSelectElement | HTMLButtonElement>) => {
              setFieldValue('carrierCode', e.currentTarget.value)
            }}
            value={values.carrierCode}
          />
          <TrackingWrapper reorderItem={FF_EXPANDED_SO_SECTION}>
            <Input
              label="Tracking Number"
              value={values.trackingNumber}
              onChange={handleChange}
              id="trackingNumber"
              data-cy="shipping-label-tracking-number"
              isError={touched.trackingNumber && Boolean(errors.trackingNumber)}
              errorFeedback={errors.trackingNumber}
            />
          </TrackingWrapper>
        </RowWrapper>
        <Button
          text="Save"
          data-cy="save-label-button"
          fillContainer={!FF_EXPANDED_SO_SECTION}
          type="submit"
          isProcessing={isLoading}
        />
      </FormWrapper>
    </form>
  )
}

const RowWrapper = styled.div<{ gridView?: boolean }>(({ gridView }) => {
  if (gridView) {
    return {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 16,
    }
  }
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  }
})

const TrackingWrapper = styled.div<{ reorderItem?: boolean }>(({ reorderItem }) => {
  if (reorderItem) {
    return {
      display: 'inherit',
      order: -1,
    }
  }
  return {}
})

const FormWrapper = styled.div<{ restrictWidth?: boolean }>(({ restrictWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  width: restrictWidth ? 572 : 'auto',
}))

export { ShippingLabelForm }
