import { useCallback, useEffect, useState } from 'react'
import type { Account } from '@helloextend/extend-api-rtk-query'
import { useLazyGetAccountsListQuery } from '@helloextend/extend-api-rtk-query'

type UseGetAccountsList = {
  accounts: Account[]
  isError: boolean
  isLoading: boolean
}

const useGetAccounts = (): UseGetAccountsList => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [accounts, setAccounts] = useState<Account[]>([])
  const [getAccounts] = useLazyGetAccountsListQuery()

  const fetchData = useCallback(async () => {
    let list: Account[] = []
    let nextPageCursor: string | undefined
    let response

    try {
      setIsLoading(true)
      do {
         
        response = await getAccounts({ cursor: nextPageCursor }).unwrap()

        list = list.concat(response.items)
        nextPageCursor = response.nextPageCursor
      } while (nextPageCursor)
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
    } finally {
      setIsLoading(false)
      setAccounts(list)
    }
  }, [getAccounts])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    accounts,
    isError,
    isLoading,
  }
}

export { useGetAccounts }
