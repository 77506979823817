import {
  useDeletePlanTermsMutation,
  useGetPlanTermsByVersionQuery,
} from '@helloextend/extend-api-rtk-query'
import {
  Breadcrumbs,
  DisplayLarge,
  Stack,
  Loading,
  useToaster,
  ToastColor,
  ToastDuration,
  TabSections,
  TabSection,
  Button,
  Trash,
} from '@extend/zen'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { useExtendAuth } from '@extend/package-okta-login'
import { TermsDetailsVersions } from './terms-versions/terms-versions-list-table'
import { BasicInformation } from './terms-details-sections/basic-information'
import { DocumentLinks } from './terms-details-sections/document-links'
import { ContractRefundInfo } from './terms-details-sections/contract-refund-info/contract-refund-info'
import { LDFlag } from '../../../../constants/ld-flags'
import { userRoles } from '../../../../utils/user-role-mapper'
import { TermsMetaData } from './terms-details-sections/terms-metadata/terms-metadata'
import { CoverageInfo } from './terms-details-sections/coverage-info/coverage-info'
import { LimitOfLiability } from './terms-details-sections/limit-of-liability/limit-of-liability'

const TermsDetails: FC = () => {
  const { user } = useExtendAuth()
  const { termsId, version } = useParams<{ termsId: string; version: string }>()
  const history = useHistory()
  const { toast } = useToaster()

  const {
    [LDFlag.TermsContractRefundInfo]: FF_TERMS_CONTRACT_REFUND_INFO,
    [LDFlag.TermsCoverageInfo]: FF_TERMS_COVERAGE_INFO,
  } = useFlags()

  const { data, isLoading, isError, isFetching } = useGetPlanTermsByVersionQuery({
    termsId,
    version,
  })

  const [
    deleteTerms,
    {
      isSuccess: isTermsDeleteSuccess,
      isError: isTermsDeleteError,
      isLoading: isDeletingTerms,
      error,
    },
  ] = useDeletePlanTermsMutation({})

  useEffect(() => {
    if (isTermsDeleteSuccess) {
      toast({
        message: `${termsId} deleted successfully`,
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
      history.push('/admin/terms')
    }

    if (isTermsDeleteError) {
      const { status, data: errorData } = error as FetchBaseQueryError

      const errorMessage =
        status === 400
          ? (errorData as { message: string }).message
          : 'Something went wrong. Please try again.'
      toast({
        message: errorMessage,
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isTermsDeleteSuccess, isTermsDeleteError, history, toast, termsId, error])

  if (isLoading || isFetching)
    return <Loading messages={[{ text: 'Loading Terms & Conditions' }]} data-cy="loading-screen" />

  if (!data || isError) {
    toast({
      message: 'Something went wrong. Please try again.',
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.short,
    })

    history.push('/admin/terms')
    return null
  }

  return (
    <>
      <Stack spacing={3}>
        <Breadcrumbs
          data-cy="terms-breadcrumbs"
          crumbs={[
            {
              text: 'Terms & Conditions',
              to: '/admin/terms',
            },
            {
              text: `${termsId} Details`,
            },
          ]}
        />
        <Stack>
          <DisplayLarge>Terms & Conditions Details</DisplayLarge>
          <Stack isRow justify="space-between">
            <div>
              <TermsMetaData termsId={data.termsId} termsDescription={data.description} />
            </div>
            <div>
              {user?.role === userRoles.ComplianceManager.value ? (
                <Button
                  icon={Trash}
                  type="button"
                  text="Delete"
                  emphasis="medium"
                  color="red"
                  isProcessing={isDeletingTerms}
                  isDisabled={isDeletingTerms}
                  onClick={() => deleteTerms(termsId)}
                  data-cy="delete-button"
                />
              ) : null}
            </div>
          </Stack>
          <TabSections id="tab" isQSControlled data-cy="tab-sections">
            <TabSection tabLabel="Details" qs="details">
              <Stack spacing={2}>
                <BasicInformation termsDetails={data} />
                <DocumentLinks languages={data.languages ?? []} />
                {FF_TERMS_CONTRACT_REFUND_INFO ? (
                  <ContractRefundInfo
                    termsId={data.termsId}
                    version={data.version}
                    contractRefundInfo={data.contractRefundInfo}
                  />
                ) : null}
                {FF_TERMS_COVERAGE_INFO ? (
                  <>
                    <CoverageInfo
                      termsId={data.termsId}
                      version={data.version}
                      coverageInfo={data.coverage}
                    />
                    <LimitOfLiability
                      termsId={data.termsId}
                      version={data.version}
                      limitOfLiability={data.limitOfLiability}
                    />
                  </>
                ) : null}
              </Stack>
            </TabSection>
            <TabSection tabLabel="Versions" qs="versions">
              <TermsDetailsVersions />
            </TabSection>
          </TabSections>
        </Stack>
      </Stack>
    </>
  )
}

export { TermsDetails }
