import { HeadingLarge, Stack, TabSection, TabSections } from '@extend/zen'
import React from 'react'
import { ActionRequiredTable } from './action-required-table'
import { useListClaimsAssignmentUsersQuery } from '../../../../packages/extend-api-rtk-query'
import { AssignedToMeTable } from './assigned-to-me-table'
import { EscalationsTable } from './escalations-table'
import { ClaimsSearchTable } from './claims-search-table'

export const NewClaims = () => {
  const { data: users } = useListClaimsAssignmentUsersQuery(undefined)

  return (
    <Stack>
      <HeadingLarge>Claims</HeadingLarge>
      <TabSections data-cy="claims-tabs" isLazy>
        <TabSection tabLabel="Action Required">
          <ActionRequiredTable />
        </TabSection>
        <TabSection tabLabel="Assigned to Me">
          <AssignedToMeTable users={users?.items} />
        </TabSection>
        <TabSection tabLabel="Escalations">
          <EscalationsTable users={users?.items} />
        </TabSection>
        <TabSection tabLabel="Claims Search">
          <ClaimsSearchTable users={users?.items} />
        </TabSection>
      </TabSections>
    </Stack>
  )
}
