import styled from '@emotion/styled'
import ReactHTMLParser from 'react-html-parser'
import { Badge, Info, COLOR, Icon, IconSize, Notes } from '@extend/zen'
import type { ContractNote } from '@helloextend/extend-api-client'
import type { FC} from 'react';
import React, { useState } from 'react'
import { escalationLevels } from '../../../../../../lib/escalation-levels'
import type { EscalationTier } from '@helloextend/extend-api-client/src/models/claim-note'
import { formatTimestampToPST } from 'src/lib/date'

export const ContractNoteTableRowDetail: FC<{ contractNote: ContractNote }> = ({
  contractNote: {
    text: noteText,
    category,
    categoryDescription,
    createdAt,
    createdBy,
    escalationLevel,
    isResolved,
    resolutionText,
    resolvedAt,
    resolvedBy,
  },
}) => {
  return (
    <NoteDetailsContainer data-cy="escalation-note-details">
      <NoteDetail
        data-cy="escalation-note-detail"
        type="escalation"
        text={noteText}
        date={createdAt}
        author={createdBy}
        category={category}
        categoryDescription={categoryDescription}
        escalationLevel={escalationLevel}
        isResolved={isResolved}
      />
      {resolvedBy && resolvedAt && (
        <NoteDetail
          data-cy="resolution-note-detail"
          type="resolution"
          text={resolutionText}
          date={resolvedAt}
          author={resolvedBy}
        />
      )}
    </NoteDetailsContainer>
  )
}

const NoteDetail: FC<{
  type: string
  text?: string
  date: number
  author: string
  category?: string
  categoryDescription?: string
  escalationLevel?: EscalationTier
  isResolved?: boolean
}> = ({
  type,
  text = 'N/A',
  date,
  author,
  category,
  categoryDescription,
  escalationLevel,
  isResolved,
}) => {
  const [showMore, setShowMore] = useState(false)
  const isLongNote = text ? text.length >= 200 : false
  const noteText = showMore || !isLongNote ? text + ' ' : `${text.substring(0, 200).trim()}... `
  const noteDate = formatTimestampToPST(date)
  const header = type === 'escalation' ? 'ESCALATION NOTE' : 'RESOLUTION NOTE'

  return (
    <NoteDetailContainer data-cy={`${type}-note-detail`}>
      <Header data-cy={`${type}-note-header`}>{header}</Header>
      <IconAndNoteDetail>
        <Icon size={IconSize.medium} color={COLOR.NEUTRAL[800]} icon={Notes} />
        <NoteDetailContent data-cy={`${type}-note-content`}>
          <AuthorContainer>
            <Date data-cy={`${type}-created-date}`}>{noteDate}</Date>
            <Author>
              <Badge
                color="neutral"
                data-cy={`${type}-author}`}
                emphasis="low"
                size="small"
                text={author}
              />
            </Author>
          </AuthorContainer>
          {category && (
            <CategoryContainer data-cy="category">
              <Category>Category:</Category>
              <CategoryText>{category}</CategoryText>
            </CategoryContainer>
          )}
          {categoryDescription && (
            <CategoryContainer data-cy="category-description">
              <Category>Category Description:</Category>
              <CategoryText>{categoryDescription}</CategoryText>
            </CategoryContainer>
          )}
          <NoteTextContainer>
            <NoteText data-cy={`${type}-note-text`} className="ql-editor">
              {ReactHTMLParser(noteText)}
              {isLongNote && (
                <ShowMoreButton className="see-more-btn" onClick={() => setShowMore(!showMore)}>
                  {showMore ? 'see less' : ' see more'}
                </ShowMoreButton>
              )}
            </NoteText>
          </NoteTextContainer>
          {escalationLevel && (
            <NoteFooter>
              <Badge
                text="Escalation"
                color={isResolved ? 'neutral' : 'red'}
                icon={Info}
                emphasis="medium"
                details={escalationLevels[escalationLevel]}
                data-cy={'escalation-level-badge'}
              />
            </NoteFooter>
          )}
        </NoteDetailContent>
      </IconAndNoteDetail>
    </NoteDetailContainer>
  )
}

const NoteDetailsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 64,
  fontSize: 14,
  margin: '8px 56px',
})

const NoteDetailContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  fontSize: 14,
  width: '450px',
})
const IconAndNoteDetail = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  fontSize: 14,
})

const NoteDetailContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 8,
  fontSize: 14,
})

const Header = styled.div({
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '18px',
})

const AuthorContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  fontSize: 14,
})

const CategoryContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  fontSize: 14,
  lineHeight: '24px',
})

const Date = styled.div({})

const Author = styled.div({})

const Category = styled.div({
  fontWeight: 700,
  fontSize: 14,
  color: COLOR.NEUTRAL[700],
})

const CategoryText = styled.div({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 14,
  fontWeight: 400,
})

const NoteTextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const NoteText = styled.div({
  fontSize: 14.5,
  overflow: 'hidden',
  wordWrap: 'break-word',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  color: 'inherit',
})

const NoteFooter = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const ShowMoreButton = styled.a({
  cursor: 'pointer',
  textAlign: 'right',
  fontWeight: 700,
  color: COLOR.NEUTRAL[700],
  whiteSpace: 'nowrap',
})
