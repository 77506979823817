import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import type { FC, SyntheticEvent } from 'react'
import React from 'react'

interface NumberInputProps {
  id: string
  label: string
  disabled: boolean
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void
  value?: number
  hasError?: boolean
  errorMessage?: string
  width?: number
  step?: number
  'data-cy'?: string
}

const NumberInput: FC<NumberInputProps> = ({
  id,
  label,
  disabled,
  onChange,
  onBlur,
  value,
  hasError = false,
  errorMessage,
  width,
  step = 1,
  'data-cy': dataCy,
}) => {
  const handleBlur = (e: SyntheticEvent<HTMLInputElement>): void => {
    if (onBlur) onBlur(e)
  }
  return (
    <LabelWrapper htmlFor={id} width={width}>
      {label && <LabelText>{label}</LabelText>}
      <Input
        data-cy={dataCy}
        id={id}
        step={step}
        type="number"
        disabled={disabled || false}
        onChange={onChange}
        onBlur={handleBlur}
        value={value}
        hasError={hasError}
      />
      {hasError && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </LabelWrapper>
  )
}

const LabelWrapper = styled.label<{ width?: number }>(({ width }) => ({
  fontFamily: 'Nunito Sans, sans-serif',
  width: width ?? 'inherit',
}))

const LabelText = styled.div({
  color: COLOR.BLUE[1000],
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
})

const Input = styled.input<{ hasError: boolean }>(({ hasError }) => ({
  border: `1px solid ${hasError ? COLOR.RED[700] : COLOR.NEUTRAL[300]}`,
  boxSizing: 'border-box',
  borderRadius: 4,
  fontFamily: 'inherit',
  fontSize: 16,
  height: 40,
  padding: '0 16px',
  width: 'inherit',
  '&:disabled': {
    borderColor: COLOR.NEUTRAL[300],
    background: COLOR.NEUTRAL[100],
  },
  WebkitAppearance: 'none',
  MozAppearance: 'textfield',
  '&:hover': {
    WebkitAppearance: 'textfield',
    MozAppearance: 'none',
  },
  '&:focus': {
    WebkitAppearance: 'textfield',
    MozAppearance: 'none',
  },
}))

const ErrorMessage = styled.span({
  color: COLOR.RED[700],
  fontWeight: 700,
  lineHeight: '16px',
  fontSize: 12,
})

export type { NumberInputProps }
export { NumberInput }
