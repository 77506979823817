import type { FC} from 'react';
import React, { useState } from 'react'
import ReactHTMLParser from 'react-html-parser'
import styled from '@emotion/styled'
import { Badge, COLOR, Divider } from '@extend/zen'

interface ContractResolutionNoteProps {
  resolvedAtDate: string | undefined | number
  resolvedBy: string
  resolutionText: string
}

const ContractResolutionNote: FC<ContractResolutionNoteProps> = ({
  resolvedAtDate,
  resolvedBy,
  resolutionText,
}: ContractResolutionNoteProps) => {
  const [showMore, setShowMore] = useState(false)
  const isLongNote = resolutionText ? resolutionText.length >= 200 : false
  const noteText =
    showMore || !isLongNote
      ? resolutionText + ' '
      : `${resolutionText.substring(0, 200).trim()}... `

  return (
    <ContainResolutionNotes data-cy="contract-resolution-note">
      <Divider />
      <ContainResolutionNote>
        <NoteHeader>
          <NoteResolutionDate data-cy="contract-resolution-note-date">
            {resolvedAtDate}
          </NoteResolutionDate>
          <Badge
            text="Resolved"
            color="green"
            emphasis="low"
            data-cy={'contract-note-resolution-badge'}
          />
          <NoteAuthor>
            <Badge
              color="neutral"
              data-cy="notes-resolution-author-badge"
              emphasis="low"
              size="small"
              text={resolvedBy}
            />
          </NoteAuthor>
        </NoteHeader>
      </ContainResolutionNote>
      <CategoryText>Resolution Note:</CategoryText>
      <NoteTextContainer>
        <NoteText data-cy="resolution-note-text">
          {ReactHTMLParser(noteText)}
          {isLongNote && (
            <ShowMoreButton className="see-more-btn" onClick={() => setShowMore(!showMore)}>
              {showMore ? 'see less' : ' see more'}
            </ShowMoreButton>
          )}
        </NoteText>
      </NoteTextContainer>
    </ContainResolutionNotes>
  )
}

export const CategoryText = styled.span({
  color: COLOR.NEUTRAL[600],
  fontWeight: 700,
  lineHeight: '24px',
})

export const NoteHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 0,
})

export const NoteAuthor = styled.div({
  textAlign: 'right',
})

export const NoteDate = styled.div({
  fontSize: 15,
  fontWeight: 400,
  lineHeight: '20px',
  color: COLOR.BLACK[800],
})

const NoteResolutionDate = styled(NoteDate)({
  fontSize: 15,
  fontWeight: 400,
  lineHeight: '20px',
  color: COLOR.BLACK[800],
})

const ContainResolutionNotes = styled.div({
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
})

const ContainResolutionNote = styled.div({
  marginTop: '1rem',
  marginBottom: '0.5rem',
})

const NoteTextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const NoteText = styled.div({
  fontSize: 15,
  overflow: 'hidden',
  wordWrap: 'break-word',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  color: 'inherit',
})

const ShowMoreButton = styled.a({
  cursor: 'pointer',
  textAlign: 'right',
  fontWeight: 700,
  color: COLOR.NEUTRAL[700],
  whiteSpace: 'nowrap',
})

export { ContractResolutionNote }
