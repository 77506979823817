import type { Order } from '@helloextend/extend-api-client'
import { InputType, DataPropertyType } from '@extend/zen'
import type {
  CustomerInformation,
  BillingInformation,
  ShippingInformation,
  TransactionInformation,
  OrderTotals,
  StoreInformation,
} from './schema'
import { mapOrderToOrderDetailsSchema } from './schema'
import type { CompactFormFieldData } from '../../../../../../components/compact-form/types'
import { AdditionalDataPropertyTypes } from '../../../../../../components/compact-form/types'

export interface OrderDetailsFormFields {
  'CUSTOMER INFORMATION': CompactFormFieldData[]
  'BILLING INFORMATION': CompactFormFieldData[]
  'SHIPPING INFORMATION': CompactFormFieldData[]
  'TRANSACTION INFORMATION': CompactFormFieldData[]
  'ORDER TOTALS': CompactFormFieldData[]
  'STORE INFORMATION': CompactFormFieldData[]
}

export const getOrderDetailsFormFields = (order: Order): OrderDetailsFormFields => {
  const details = mapOrderToOrderDetailsSchema(order)

  return {
    'CUSTOMER INFORMATION': getCustomerFields(details.customerInformation),
    'BILLING INFORMATION': getBillingInformationInputs(details.billingInformation),
    'SHIPPING INFORMATION': getShippingInformation(details.shippingInformation),
    'TRANSACTION INFORMATION': getTransactionInformation(details.transactionInformation),
    'ORDER TOTALS': getOrderTotals(details.orderTotals),
    'STORE INFORMATION': getStoreInformation(details.storeInformation),
  }
}

const getCustomerFields = ({
  fullName,
  phoneNumber = '',
  email,
  merchantCustomerId = '',
}: CustomerInformation): CompactFormFieldData[] => [
  {
    id: 'customerInformation.fullName',
    label: 'Full Name',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: fullName,
    isEditable: true,
  },
  {
    id: 'customerInformation.phoneNumber',
    label: 'Phone Number',
    inputType: InputType.tel,
    dataPropertyType: DataPropertyType.string,
    value: phoneNumber,
    isEditable: true,
  },
  {
    id: 'customerInformation.email',
    label: 'Email',
    inputType: InputType.email,
    dataPropertyType: DataPropertyType.string,
    value: email,
    isEditable: true,
  },
  {
    id: 'customerInformation.merchantCustomerId',
    label: 'Merchant Customer ID',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: merchantCustomerId,
    isEditable: false,
  },
]

const getBillingInformationInputs = ({
  address1,
  address2 = '',
  city,
  province = '',
  postalCode,
  countryCode,
}: BillingInformation): CompactFormFieldData[] => [
  {
    id: 'billingInformation.address1',
    label: 'Address',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: address1,
    isEditable: true,
  },
  {
    id: 'billingInformation.address2',
    label: 'Address 2',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: address2,
    isEditable: true,
  },
  {
    id: 'billingInformation.city',
    label: 'City',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: city,
    isEditable: true,
  },
  {
    id: 'billingInformation.province',
    label: 'Province Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: province,
    isEditable: true,
  },
  {
    id: 'billingInformation.postalCode',
    label: 'Postal Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: postalCode,
    isEditable: true,
  },
  {
    id: 'billingInformation.countryCode',
    label: 'Country Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: countryCode,
    isEditable: true,
  },
]

const getShippingInformation = ({
  address1,
  address2 = '',
  city,
  countryCode,
  postalCode,
  province = '',
}: ShippingInformation): CompactFormFieldData[] => [
  {
    id: 'billingInformation.address1',
    label: 'Address',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: address1,
    isEditable: true,
  },
  {
    id: 'billingInformation.address2',
    label: 'Address 2',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: address2,
    isEditable: true,
  },
  {
    id: 'billingInformation.city',
    label: 'City',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: city,
    isEditable: true,
  },
  {
    id: 'billingInformation.province',
    label: 'Province Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: province,
    isEditable: true,
  },
  {
    id: 'billingInformation.postalCode',
    label: 'Postal Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: postalCode,
    isEditable: true,
  },
  {
    id: 'billingInformation.countryCode',
    label: 'Country Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: countryCode,
    isEditable: true,
  },
]

const getTransactionInformation = ({
  transactionId,
  transactionDate,
  currencyCode,
  poNumber = '',
  merchantCustomerId = '',
  isTest,
  cartId,
}: TransactionInformation): CompactFormFieldData[] => [
  {
    id: 'transactionInformation.transactionId',
    label: 'Transaction ID',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: transactionId,
    isEditable: true,
  },
  {
    id: 'transactionInformation.transactionDate',
    label: 'Transaction Date',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: transactionDate,
    isEditable: true,
  },
  {
    id: 'transactionInformation.currencyCode',
    label: 'Currency Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: currencyCode,
    isEditable: true,
  },
  {
    id: 'transactionInformation.poNumber',
    label: 'PO Number',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: poNumber,
    isEditable: true,
  },
  {
    id: 'transactionInformation.merchantCustomerId',
    label: 'Merchant Customer ID',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: merchantCustomerId,
    isEditable: true,
  },
  {
    id: 'transactionInformation.isTest',
    label: 'Is Test',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: isTest?.toString() || '',
    isEditable: true,
  },
  {
    id: 'transactionInformation.cartId',
    label: 'Cart ID',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: cartId || '',
    isEditable: true,
  },
]

const getOrderTotals = ({
  shippingCostTotal,
  taxCostTotal,
  productCostTotal,
  orderTotal,
}: OrderTotals): CompactFormFieldData[] => [
  {
    id: 'orderTotals.shippingCostTotal',
    label: 'Shipping Cost Total',
    inputType: InputType.number,
    dataPropertyType: DataPropertyType.currency,
    value: shippingCostTotal?.toString() || '',
    isEditable: false,
  },
  {
    id: 'orderTotals.taxCostTotal',
    label: 'Tax Cost Total',
    inputType: InputType.number,
    dataPropertyType: DataPropertyType.currency,
    value: taxCostTotal?.toString() || '',
    isEditable: false,
  },
  {
    id: 'orderTotals.productCostTotal',
    label: 'Product Cost Total',
    inputType: InputType.number,
    dataPropertyType: DataPropertyType.currency,
    value: productCostTotal?.toString() || '',
    isEditable: false,
  },
  {
    id: 'orderTotals.orderTotal',
    label: 'Order Total',
    inputType: InputType.number,
    dataPropertyType: DataPropertyType.currency,
    value: orderTotal?.toString() || '',
    isEditable: false,
  },
]

const getStoreInformation = ({ storeName, storeId }: StoreInformation): CompactFormFieldData[] => [
  {
    id: 'storeInformation.storeName',
    label: 'Store Name',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: storeName || '',
    isEditable: true,
  },
  {
    id: 'storeInformation.storeId',
    label: 'Store ID',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: storeId,
    isEditable: true,
  },
  {
    id: 'storeInformation.status',
    label: 'Status',
    inputType: InputType.text,
    dataPropertyType: AdditionalDataPropertyTypes.badge,
    value: 'Good',
    isEditable: false,
  },
]
