import React, { useState } from 'react'
import type { FC } from 'react'
import {
  Modal,
  ModalController,
  Input,
  InputType,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@extend/zen'
import {
  useUpdateAccountLegacyMutation,
  useUpdateAccountEnterpriseMutation,
} from '@helloextend/extend-api-rtk-query'

type AccountEditNameModalProps = {
  isModalOpen: boolean
  accountId: string
  isLegacyAccount?: boolean
  toggleModalOff: () => void
}

const AccountEditNameModal: FC<AccountEditNameModalProps> = ({
  accountId,
  isModalOpen,
  isLegacyAccount,
  toggleModalOff,
}) => {
  const { toast } = useToaster()
  const [name, setInputName] = useState('')
  const [showBlurMessage, setShowBlurMessage] = useState(false)

  const [updateAccountLegacy, { isLoading: isUpdateAccountLegacyLoading }] =
    useUpdateAccountLegacyMutation()

  const [updateAccountEnterprise, { isLoading: isUpdateAccountEnterpriseLoading }] =
    useUpdateAccountEnterpriseMutation()

  const handleBlur = (): void => {
    if (!name) {
      setShowBlurMessage(true)
    }
  }

  const handleModalClosing = (): void => {
    setInputName('')
    setShowBlurMessage(false)
    toggleModalOff()
  }

  const handleEditConfirm = async (): Promise<void> => {
    try {
      if (isLegacyAccount) {
        await updateAccountLegacy({ accountId, body: { name } }).unwrap()
      } else {
        await updateAccountEnterprise({ accountId, body: { name } }).unwrap()
      }

      toast({
        message: 'Account name updated',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    } catch {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    } finally {
      handleModalClosing()
    }
  }

  return (
    <ModalController isOpen={isModalOpen}>
      <Modal
        data-cy="account-edit-name-modal"
        heading="Edit account name"
        onDismissRequest={handleModalClosing}
        size="xs"
        primaryButtonProps={{
          onClick: handleEditConfirm,
          text: 'Confirm',
          color: 'blue',
          isDisabled: isUpdateAccountLegacyLoading || isUpdateAccountEnterpriseLoading || !name,
          isProcessing: isUpdateAccountLegacyLoading || isUpdateAccountEnterpriseLoading,
          'data-cy': 'account-edit-name-modal-confirm-button',
        }}
        secondaryButtonProps={{
          onClick: handleModalClosing,
          text: 'Cancel',
          color: 'blue',
          isDisabled: isUpdateAccountLegacyLoading || isUpdateAccountEnterpriseLoading,
          'data-cy': 'account-edit-name-modal-cancel-button',
        }}
      >
        <Input
          id="name"
          data-cy="account-edit-name-modal-input"
          label="Account name"
          type={InputType.text}
          value={name}
          onChange={(e) => setInputName(e.target.value)}
          onBlur={handleBlur}
          isError={showBlurMessage}
          errorFeedback="Please enter a valid account name"
        />
      </Modal>
    </ModalController>
  )
}

export { AccountEditNameModal }
