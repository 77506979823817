import type { PlanTermsVersion } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'
import * as Yup from 'yup'

export const schema = Yup.object()
  .shape({
    description: Yup.string<PlanTermsVersion['description']>().required(
      'Plan Term Description is required',
    ),
  })
  .defined()
