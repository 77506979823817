import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from '../base-query'

export const tremendoussApi = createApi({
  baseQuery,
  reducerPath: 'Tremendous',
  tagTypes: ['ServiceOrder', 'Tremendous'],
  endpoints: (build) => ({
    resendRewardEmail: build.mutation<{ id: string }, { serviceOrderId: string }>({
      query: ({ serviceOrderId }) => ({
        url: `/service-orders/${serviceOrderId}/resend-reward`,
        method: 'POST',
      }),
      invalidatesTags: ['ServiceOrder'],
    }),
  }),
})

export const { useResendRewardEmailMutation } = tremendoussApi
