import type { FC } from 'react'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

type ReferenceIdLinkProps = {
  referenceId: string
}

const ReferenceIdLink: FC<ReferenceIdLinkProps> = ({ referenceId }) => {
  const { storeId } = useParams<{
    storeId: string
  }>()

  return <Link to={`/admin/stores/${storeId}/ais/${referenceId}`}>{referenceId}</Link>
}

export { ReferenceIdLink }
