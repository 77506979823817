import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { Button, ButtonGroup } from '@extend/zen'
import { useCheckInServiceOrderMutation } from '@helloextend/extend-api-rtk-query'
import type { ServiceOrderCheckInRequest } from '@helloextend/extend-api-client/src/models/service-order'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import type { Values } from './schema'
import { formSchema } from './schema'
import { FormDatePicker } from '../../../../../../components/collapsible-info-group/form-date-picker'

interface CheckInServiceOrderFormProps {
  serviceOrder: ServiceOrder
}

const CheckInServiceOrderForm: FC<CheckInServiceOrderFormProps> = ({ serviceOrder }) => {
  const dispatch = useDispatch()
  const [checkInServiceOrder, { isSuccess, isLoading }] = useCheckInServiceOrderMutation()
  const { values, errors, handleSubmit, setFieldValue } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: formSchema.getDefault(),
    validationSchema: formSchema,
    onSubmit: (formValues: Values) => {
      if (!formValues.checkedInAt) return
      checkInServiceOrder({
        serviceOrderId: serviceOrder?.id ?? '',
        body: formValues as ServiceOrderCheckInRequest,
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      dispatch(setClaimDetailsToast('Check in complete!'))
      dispatch(setClaimDetailsActiveView(''))
    }
  }, [isSuccess, dispatch])

  const handleDateChange = (selected: number | null): void => {
    setFieldValue('checkedInAt', selected)
  }

  return (
    <form data-cy="check-in-service-order-form" onSubmit={handleSubmit}>
      <DateWrapper>
        <FormDatePicker
          data-cy="check-in"
          label="Checked In Date"
          date={values.checkedInAt ? new Date(values.checkedInAt) : null}
          onChange={handleDateChange}
          invalid={Boolean(errors.checkedInAt)}
          errorMessage={errors.checkedInAt}
          maxDate={new Date()}
        />
      </DateWrapper>
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
            isDisabled={isLoading}
          />
          <Button
            type="submit"
            text="Check In"
            data-cy="check-in-button"
            isDisabled={isLoading}
            isProcessing={isLoading}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </form>
  )
}

const DateWrapper = styled.div({
  width: 352,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export { CheckInServiceOrderForm }
