// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0_webpack-cli@4.8.0_webpack-bundle-analyzer@4.4.2_webpack-dev-s_vrefs4evkprez4c3y7xwx6ru5q/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2KEylIDBMXyIiE-hq2bN-O {\n    background-color: var(--neutral-100);\n    min-height: 650px;\n    min-width: 450px;\n    padding: 40px 12%;\n    display: flex;\n    justify-content: center;\n}\n\n._30pfIaC41db8p-p-pDd6mA {\n    display: flex;\n    height: 100%;\n    flex-direction: column;\n    align-items: center;\n}\n\n._3n1D2-uj7MhprSF2HBX_mH {\n    margin-top: 24px;\n    margin-bottom: 16px;\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 28px;\n    text-align: center;\n}\n\n.DXzkS-zHNu4n3017S_BMF {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n    text-align: center;\n    margin-top: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"background": "_2KEylIDBMXyIiE-hq2bN-O",
	"offer": "_30pfIaC41db8p-p-pDd6mA",
	"title": "_3n1D2-uj7MhprSF2HBX_mH",
	"description": "DXzkS-zHNu4n3017S_BMF"
};
module.exports = exports;
