import type { FC, ChangeEvent } from 'react'
import React, { useRef, memo } from 'react'
import { currency } from '@extend/client-helpers'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

interface CurrencyInputProps {
  'data-cy'?: string
  disabled?: boolean
  name: string
  onChange?: (value?: number) => void
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  value: number | undefined
  showSymbol?: boolean
  currencyCode: string
  invalid?: boolean
  autoFocus?: boolean
}

export const format = (
  money: number | undefined,
  currencyCode: string,
  showSymbol: boolean,
): string => {
  const formatted = currency.format(money, currencyCode, '')
  return !showSymbol ? formatted.replace(/[^\d,.]+/g, '') : formatted
}

export const unformat = (formatted: string | undefined): number | undefined => {
  if (typeof formatted !== 'string') return undefined
  const unformatted = Number(formatted.replace(/[^\d]+/g, ''))
  return unformatted === 0 ? undefined : unformatted
}

const Component: FC<CurrencyInputProps> = ({
  'data-cy': dataCy,
  disabled = false,
  name,
  onChange,
  onBlur,
  placeholder,
  value,
  showSymbol = true,
  currencyCode,
  invalid,
  autoFocus,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <GroupInputField
      onChange={(e) => onChange && onChange(unformat(e.target.value))}
      onBlur={onBlur}
      data-cy={dataCy}
      invalid={invalid}
      disabled={disabled}
      name={name}
      ref={inputRef}
      placeholder={disabled ? '' : placeholder}
      value={format(value, currencyCode, showSymbol)}
      autoFocus={autoFocus}
    />
  )
}

const CurrencyInput = memo(Component)

export { CurrencyInput }

const GroupInputField = styled.input<{ invalid?: boolean }>(({ invalid }) => ({
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  fontFamily: 'inherit',
  fontSize: 16,
  height: 40,
  padding: '0 16px',
  width: '75%',
  marginRight: 0,
  textAlign: 'right',
  '&::placeholder': {
    color: COLOR.NEUTRAL[400],
  },
  '&:disabled': {
    borderColor: COLOR.NEUTRAL[300],
    background: COLOR.NEUTRAL[100],
  },
  '&:focus': {
    border: `1px solid ${invalid ? COLOR.RED[700] : COLOR.BLUESKY}`,
    boxShadow: `0 0 0 1px inset ${invalid ? COLOR.RED[700] : COLOR.BLUESKY}`,
    outline: 'none',
  },
  borderColor: invalid ? COLOR.RED[700] : COLOR.NEUTRAL[300],
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
}))
