import type { FC } from 'react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Input, Paragraph } from '@extend/zen'
import type { RuleStatus, NumericConditionWithValue } from '@helloextend/extend-api-rtk-query'
import * as selectors from '../../../../../../reducers/selectors'
import type { RootState } from '../../../../../../reducers'
import { updateConditionNumericValue } from '../../../../../../store/slices/amp-slice'

type NumericInputProps = {
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
  isMonetaryValue: boolean
  isDisabled?: boolean
}

const NumericInput: FC<NumericInputProps> = ({
  rulesetType,
  ruleIndex,
  conditionIndex,
  isMonetaryValue,
  isDisabled = false,
}) => {
  const dispatch = useDispatch()

  const condition = useSelector((state: RootState) =>
    selectors.getCondition({ state, rulesetType, ruleIndex, conditionIndex }),
  )

  const conditionValue = condition ? (condition as NumericConditionWithValue)?.value : 0

  const inputValue = conditionValue > 0 ? String(conditionValue) : ''
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    dispatch(updateConditionNumericValue({ rulesetType, ruleIndex, conditionIndex, value }))
  }

  return (
    <Wrapper>
      <Input
        data-cy="claim-validation-numeric-input"
        id="claim-validation-numeric-input"
        onChange={handleChange}
        value={inputValue}
        isDisabled={isDisabled}
      />
      {isMonetaryValue && (
        <Paragraph data-cy="claim-validation-numeric-input-paragraph">USD</Paragraph>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  gap: 12,
  alignItems: 'center',
  width: 108,
})
export { NumericInput }
