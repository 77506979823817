// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0_webpack-cli@4.8.0_webpack-bundle-analyzer@4.4.2_webpack-dev-s_vrefs4evkprez4c3y7xwx6ru5q/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2VZx0h3NzUHZb5Km3MsvBW {\n    border-collapse: separate;\n    border-spacing: 16px 19px;\n    font-weight: 400;\n}\n\n._3Ea2enxFjaJL85dOwNlEnS {\n    padding-top: 5px;\n    text-align: right;\n    vertical-align: top;\n    white-space: nowrap;\n}", ""]);
// Exports
exports.locals = {
	"table": "_2VZx0h3NzUHZb5Km3MsvBW",
	"badge-cell": "_3Ea2enxFjaJL85dOwNlEnS"
};
module.exports = exports;
