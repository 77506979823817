import type { FC } from 'react'
import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyListServicersQuery } from '@helloextend/extend-api-rtk-query'
import { DataTable, HeadingLarge, Add } from '@extend/zen'
import type { ColumnFiltersState } from '@extend/zen'
import { servicerColumns } from './table-config'
import { toTableServicerItems } from './to-table-servicer-items'
import type { TableServicersSearch } from '../../../types/servicers'

const LIMIT = 7999
const MAX_LIMIT = 100000

const Servicers: FC = () => {
  const { push } = useHistory()
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [servicers, setServicers] = useState<TableServicersSearch[]>([])
  const [hasStartedFetching, setHasStartedFetching] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const [listServicers, { isSuccess, isError }] = useLazyListServicersQuery({
    selectFromResult: (result) => {
      return {
        ...result,
        ...result.data,
      }
    },
  })

  const fetchAllServicers = useCallback(
    async (currentServicers = [], nextPageCursor?: string) => {
      if ((currentServicers.length === 0 && !isSuccess && !isError) || nextPageCursor) {
        const response = await listServicers({
          limit: LIMIT,
          excludeLocations: true,
          cursor: nextPageCursor,
        }).unwrap()
        if (
          response.nextPageCursor &&
          currentServicers.length + response.items.length < MAX_LIMIT
        ) {
          fetchAllServicers(
            [...currentServicers, ...toTableServicerItems(response.items)],
            response.nextPageCursor,
          )
        } else {
          setServicers([...currentServicers, ...toTableServicerItems(response.items)])
          setIsFetching(false)
        }
      }
    },
    [listServicers, setServicers, isSuccess, isError],
  )

  useEffect(() => {
    if (!hasStartedFetching) {
      setHasStartedFetching(true)
      setIsFetching(true)
      fetchAllServicers()
    }
  }, [hasStartedFetching, fetchAllServicers, setIsFetching, setHasStartedFetching])

  const handleOnboardClick = (): void => {
    push('/admin/servicers/create')
  }

  const handleRowClick = (servicer: TableServicersSearch): void => {
    window.open(`/admin/servicers/${servicer.servicerId}`, '_blank', 'noreferrer')
  }

  return (
    <DataTable
      heading={<HeadingLarge>Servicers</HeadingLarge>}
      itemName="servicer"
      data-cy="servicers"
      data={servicers}
      columns={servicerColumns}
      isLoading={isFetching}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      getRowId={(row) => row.id}
      getRowActions={(row) => [
        {
          onClick: () => handleRowClick(row),
          text: 'View',
          emphasis: 'low',
        },
      ]}
      getTableActions={() => [
        {
          emphasis: 'medium',
          text: 'Create 3rd Party Servicer',
          onClick: handleOnboardClick,
          'data-cy': 'create-third-party-btn',
          icon: Add,
        },
      ]}
    />
  )
}

export { Servicers }
