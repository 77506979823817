import type { FC, MouseEvent } from 'react'
import React, { useCallback } from 'react'
import { useFetchPlanVersionsListQuery } from '@helloextend/extend-api-rtk-query'
import { DataReactTable } from '@helloextend/merchants-ui'
import { useHistory } from 'react-router'
import type { Plan } from '@helloextend/extend-api-client'
import { planVersionsColumns } from './table-config'

type ProductsImportModalProps = {
  planId: string
}

const PlanVersions: FC<ProductsImportModalProps> = ({ planId }) => {
  const { push } = useHistory()

  const { planVersions, isFetching: isFetchingVersions } = useFetchPlanVersionsListQuery(planId, {
    skip: !planId,
    selectFromResult: ({ data, isFetching }) => ({
      isFetching,
      // filter out the current/latest plan version
      planVersions: [...(data?.versions || [])].sort((a, b) => a.version - b.version).slice(0, -1),
    }),
  })
  const handleRowClick = useCallback(
    (_e: MouseEvent, rowData: Plan) => {
      push(`/admin/plans/${planId}/versions/${rowData.version}`)
    },
    [push, planId],
  )

  if (!planVersions) return null
  return (
    <DataReactTable
      isLoading={isFetchingVersions}
      type="versions"
      data={planVersions}
      columns={planVersionsColumns}
      initialState={{
        sortBy: [{ id: 'version', desc: true }],
        pageSize: 10,
      }}
      autoResetPage={false}
      onRowClick={handleRowClick}
    />
  )
}

export { PlanVersions }
