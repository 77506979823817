import type { FC, RefObject } from 'react'
import React, { useMemo, useRef, useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import { batch, useDispatch, useSelector } from 'react-redux'
import { COLOR, InlineAlert, InlineAlertColor, Error, Accordion } from '@extend/zen'
import type { ScriptItem, ThreadResponse } from '@helloextend/extend-api-rtk-query'
import type { RootState } from '../../../../reducers'
import * as selectors from '../../../../reducers/selectors'
import { hasFailureTypeSlot, hasRequiredSlots } from '../../../../store/slices/amp-validation'
import { AdjudicationTitleBlock } from '../components/message-block/adjudication-title-block'
import { AdjudicationMessageBlock } from '../components'
import {
  setIsEditorPanelVisible,
  setSelectedMessageBlock,
} from '../../../../store/slices/amp-slice'
import { ThreadAppendButtons } from '../components/thread-append-buttons'
import { getHasReplyScriptItem, getIsKaleyCondition, getIsReplacementReply } from '../utils'
import { handleNumericBadgeClick } from '../utils-ui'
import { ThreadEditTabsEnum } from './types'

type ThreadEditorPreviewProps = {
  activeTab: ThreadEditTabsEnum
  isEditPaneVisible: boolean
  selectedThread: ThreadResponse | null
  editorRef: RefObject<HTMLDivElement>
}

const ThreadEditorPreview: FC<ThreadEditorPreviewProps> = ({
  activeTab,
  isEditPaneVisible,
  selectedThread,
  editorRef,
}) => {
  const dispatch = useDispatch()

  const startThreadRef = useRef<HTMLDivElement>(null)
  const threadContainerRef = useRef<HTMLDivElement>(null)
  const refs = useRef<{ [key: number]: HTMLElement }>({})

  const isPublishValidationModeActive = useSelector((state: RootState) =>
    selectors.getIsPublishValidationModeActive(state),
  )
  const selectedMessageBlockIndex = useSelector((state: RootState) =>
    selectors.getSelectedMessageBlockIndex(state),
  )
  const whitespaceRef = useRef<HTMLDivElement>(null)
  const selectedMessageBlock = useSelector((state: RootState) =>
    selectors.getSelectedThreadMessageBlock(state),
  )
  const isSelectedThreadStructureLocked = selectedThread?.status !== 'draft'
  const scripts = useMemo(() => {
    return selectedThread ? selectedThread.script : []
  }, [selectedThread])

  const getMissingSlotValueText = (): string => {
    const missingSlots = []
    if (!hasFailureTypeSlot(selectedThread)) missingSlots.push('FailureType')
    return missingSlots.join(', ')
  }

  const isInlineAlertVisible = useMemo(() => {
    return (
      isPublishValidationModeActive &&
      selectedThread?.type === 'adjudication' &&
      !hasRequiredSlots(selectedThread)
    )
  }, [isPublishValidationModeActive, selectedThread])

  const handleSelectMessageBlock = (index: number, scriptItem: ScriptItem): void => {
    if (getHasReplyScriptItem(scriptItem) || getIsKaleyCondition(scriptItem)) {
      dispatch(setSelectedMessageBlock(index))
    }
  }

  const handleBadgeClick = (value: number | string): void => {
    if (typeof value !== 'number') return
    // adding 1 to badge values in getIndex util function so UI is not 0 indexed
    // Subtracting that 1 here here make sure badge clicks select proper index
    handleNumericBadgeClick(refs, value - 1)
  }

  const handleClickTitleBlock = (): void => {
    if (!scripts.length) return
    const firstSelectableIndex = scripts.findIndex((scriptItem) => Boolean(scriptItem?.reply))

    const firstSelectableScriptItem = scripts[firstSelectableIndex]

    handleSelectMessageBlock(firstSelectableIndex, firstSelectableScriptItem)
    handleBadgeClick(firstSelectableIndex + 1)
  }

  const closeEditPanel = useCallback((): void => {
    if (
      activeTab === ThreadEditTabsEnum.AdjudicationRules ||
      activeTab === ThreadEditTabsEnum.Photos
    )
      return
    batch(() => {
      dispatch(setSelectedMessageBlock(null))
      dispatch(setIsEditorPanelVisible(false))
    })
  }, [dispatch, activeTab])

  useEffect(() => {
    const maybeToggleEditPane = (ev: MouseEvent): void => {
      const clickedElement = ev.target as Node
      // Don't toggle if event is inside the editor
      if (editorRef.current && editorRef.current.contains(clickedElement)) return
      // Close editor if click was in whitespace
      if (whitespaceRef.current && whitespaceRef.current.contains(clickedElement)) {
        closeEditPanel()
        return
      }
      // Determine if the click was on the messageblock itself
      if (scripts.some((_, index) => refs.current[index]?.contains(clickedElement))) {
        // Get the ref index of the clicked element
        const triggeredRefIndex = scripts.findIndex((_, index) =>
          refs.current[index].contains(clickedElement),
        )
        const triggeredRef = refs.current[triggeredRefIndex]
        // If the clicked element is the ref itself, or the badge text close the pane
        if (ev.target === triggeredRef || ev.target === triggeredRef.firstChild) {
          closeEditPanel()
          return
        }
        // Otherwise, the messageblock has been clicked, so open the pane
        dispatch(setIsEditorPanelVisible(true))
        return
      }
      // Close editor if the thread container itself is clicked
      if (threadContainerRef.current && threadContainerRef.current === clickedElement) {
        closeEditPanel()
        return
      }
      // Determine if click was on the start thread ref
      if (startThreadRef.current && startThreadRef.current.contains(clickedElement)) {
        dispatch(setIsEditorPanelVisible(true))
      }
    }
    window.addEventListener('click', maybeToggleEditPane)

    return () => {
      window.removeEventListener('click', maybeToggleEditPane)
    }
  }, [scripts, closeEditPanel, dispatch, editorRef])

  return (
    <>
      <ThreadContainer
        data-cy="edit-thread-container"
        isFullWidth={isEditPaneVisible}
        activeTab={activeTab}
        ref={threadContainerRef}
      >
        <InlineAlertWrapper isInlineAlertVisible={isInlineAlertVisible}>
          <Accordion isExpanded={isInlineAlertVisible} transitionDurationMs={250}>
            <InlineAlert
              color={InlineAlertColor.red}
              icon={Error}
              data-cy="required-slots-inline-alert"
            >
              <InlineAlertText>
                The Adjudication thread contains missing <b>Connect Customer Response To</b> values:{' '}
                {getMissingSlotValueText()}
              </InlineAlertText>
            </InlineAlert>
          </Accordion>
        </InlineAlertWrapper>
        <MessageBlocksWrapper isInlineAlertVisible={isInlineAlertVisible}>
          <AdjudicationTitleBlock ref={startThreadRef} onClick={handleClickTitleBlock} />
          {scripts.map((scriptItem, index) => {
            const uiIndex = String(index + 1)
            const isKaleyCondition = getIsKaleyCondition(scriptItem)
            const isSelectable =
              isKaleyCondition || (scriptItem.reply && !getIsReplacementReply(scriptItem.reply))

            return (
              <>
                <AdjudicationMessageBlock
                  isClaimsMode={
                    activeTab === ThreadEditTabsEnum.AdjudicationRules ||
                    activeTab === ThreadEditTabsEnum.Photos
                  }
                  index={String(index + 1)}
                  scriptItem={scriptItem}
                  onBadgeClick={handleBadgeClick}
                  ref={(element: HTMLDivElement) => {
                    refs.current[index] = element
                  }}
                  onClick={
                    isSelectable ? () => handleSelectMessageBlock(index, scriptItem) : undefined
                  }
                  isActive={index === selectedMessageBlock?.index}
                  threadId={selectedThread?.id}
                />
                {selectedMessageBlockIndex !== null &&
                  selectedMessageBlockIndex === index &&
                  !isSelectedThreadStructureLocked && (
                    <ThreadAppendButtonsIndent index={uiIndex}>
                      <ThreadAppendButtons data-cy="end-of-message-block-cta" />
                    </ThreadAppendButtonsIndent>
                  )}
              </>
            )
          })}
        </MessageBlocksWrapper>
        {!isEditPaneVisible && !isSelectedThreadStructureLocked && (
          <ThreadAppendButtons data-cy="end-of-thread-cta" />
        )}
      </ThreadContainer>
      <Whitespace ref={whitespaceRef} data-cy="amp-thread-editor-whitespace" />
    </>
  )
}

const ThreadContainer = styled.div<{ isFullWidth: boolean; activeTab: string }>(
  ({ isFullWidth, activeTab }) => {
    let marginLeft = 0
    if (
      activeTab === ThreadEditTabsEnum.AdjudicationRules ||
      activeTab === ThreadEditTabsEnum.Photos
    ) {
      marginLeft = 932
    } else if (isFullWidth) {
      marginLeft = 520
    }
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      background: COLOR.NEUTRAL[100],
      marginLeft,
      transition: '0.25s',
      padding: `40px 0 96px 40px`,
      minHeight: 'calc(100vh - 208px)',
    }
  },
)

const MessageBlocksWrapper = styled.div<{ isInlineAlertVisible: boolean }>(
  ({ isInlineAlertVisible }) => ({
    paddingTop: isInlineAlertVisible ? 72 : 0,
  }),
)

const InlineAlertText = styled.p({
  fontWeight: 400,
  fontSize: 15,
  lineHeight: '20px',
  margin: 0,
})

const InlineAlertWrapper = styled.div<{ isInlineAlertVisible: boolean }>(
  ({ isInlineAlertVisible }) => ({
    width: 'max-content',
    paddingBottom: isInlineAlertVisible ? 16 : 0,
    position: 'fixed',
    zIndex: 2,
  }),
)

const Whitespace = styled.div({
  minHeight: 'calc(100vh - 112px)',
  width: '100%',
})

const ThreadAppendButtonsIndent = styled.div<{ index: string }>(({ index }) => {
  let indent = 0
  if (index.length === 2) {
    indent = 10
  }
  if (index.length === 3) {
    indent = 20
  }
  return {
    marginLeft: indent,
  }
})

export { ThreadEditorPreview }
