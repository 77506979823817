import type { FC } from 'react'
import React from 'react'
import { useGetConfigurationQuery, useGetStoreQuery } from '@helloextend/extend-api-rtk-query'
import { Grid, Spinner } from '@extend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { MessageTypes } from '@helloextend/extend-api-client'
import { ProductProtectionForm } from '../../../components/product-protection-form'
import { OfferForm } from './components/offer-form'
import { MerchantPortalForm } from './components/merchant-portal-form'
import { SectionContainer } from '../../../../../../components/section-container'
import { ContactForm } from './components/contact-form'
import { Servicing } from './components/servicing'
import { BasicInfoForm } from './components/basic-info-form'
import { LDFlag } from '../../../../../../constants/ld-flags'

type SettingsProps = {
  storeId: string
}

const Settings: FC<SettingsProps> = ({ storeId }) => {
  const { [LDFlag.MessaginFeaturesConfigurationSendTermsSms]: FF_MESSAGING_SMS_TERMS } = useFlags()

  const { data, isLoading } = useGetStoreQuery({ storeId, version: 'latest' })
  const { data: messagingConfig, isLoading: isGettingMessagingConfig } = useGetConfigurationQuery(
    {
      messageName: MessageTypes.ORDER_RECEIVED_SEND_TERMS_SMS,
      storeId,
    },
    { skip: !FF_MESSAGING_SMS_TERMS },
  )

  if (isLoading || isGettingMessagingConfig) {
    return <Spinner />
  }
  if (!data) return null

  return (
    <>
      <SectionContainer title="Basic">
        <BasicInfoForm store={data} />
      </SectionContainer>
      <Grid columns={2} spacing={4}>
        <MerchantPortalForm store={data} />
        <OfferForm store={data} />
      </Grid>
      <SectionContainer title="Product Protection">
        {/* Handle Cancel a noop for this form */}
        <ProductProtectionForm
          store={data}
          messagingConfig={messagingConfig}
          handleCancel={() => {}}
        />
      </SectionContainer>
      <SectionContainer title="Servicing">
        <Servicing store={data} />
      </SectionContainer>
      <SectionContainer title="Contact">
        <ContactForm store={data} />
      </SectionContainer>
    </>
  )
}

export { Settings }
