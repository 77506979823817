import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

type CollapsibleTableRowProps = {
  open: boolean
}

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@extend/zen'`
 */
const TableRowCollapsible: FC<CollapsibleTableRowProps> = ({ children, open }) => {
  return <TableBody>{open && children}</TableBody>
}

const TableBody = styled.tbody({
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
})

export { TableRowCollapsible }
