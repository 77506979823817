import type {
    WDProductFrontend,
    SearchWDProductsResponse,
    WDConfiguration,
    GetWDConfigurationListResponse,
} from '@helloextend/extend-api-rtk-query'
import type { AdvancedSelectOption } from '@extend/zen'

import { faker } from '@faker-js/faker/locale/en'

export const generateSearchWDProductsResponse = (
  numProducts: number,
): SearchWDProductsResponse => {
  const productArr = Array<WDProductFrontend>(numProducts)
  const mockStatus = 'non-warrantable'
  for (let i = 0; i < numProducts; i += 1) {
    productArr[i] = {
      productId: faker.datatype.uuid(),
      title: faker.commerce.productName(),
      category: `merch cat ${i}`,
      sku: `BHWLS11KF172${i}`,
      description: `description ${i}`,
      price: faker.commerce.price(),
      planCategoryId: faker.datatype.uuid(),
      plansArray: [faker.datatype.uuid()],
      mappingStatus: 'validation_required',
      wdUpdatedAt: faker.date.recent().getTime(),
      warrantyStatus: mockStatus,
      wdUpdatedBy: faker.name.firstName(),
    }
  }
  return { items: productArr }
}

export const generateWDConfigruationResponse = (numConfigs: number): GetWDConfigurationListResponse => {
  const configArr = Array<WDConfiguration>(numConfigs)
  for (let i = 0; i < numConfigs; i += 1) {
    configArr[i] = {
      storeId: faker.datatype.uuid(),
      storeName: faker.company.name(),
      actionArray: [],
      isSentToWD: true,
      updatedAt: faker.date.recent().getTime(),
      createdAt: faker.date.past().getTime(),
      lastUpdatedBy: faker.name.firstName(),
      digitalPDRArray: [faker.datatype.uuid()],
    }
  }
  return { items: configArr }
}

export const generateDropdownOptions = (numOptions: number): AdvancedSelectOption[] => {
  const options = Array<AdvancedSelectOption>(numOptions)
  for (let i = 0; i < numOptions; i += 1) {
    options[i] = {
      value: faker.datatype.uuid(),
      display: faker.commerce.productName(),
    }
  }
  return options
}
