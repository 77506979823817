import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { RouterError } from '@helloextend/component-commons'
import { useUserAnalytics } from '@helloextend/client-hooks'
import { useExtendAuth } from '@extend/package-okta-login'
import { Login } from './login/login'
import { Logout } from './logout'
import { ProtectedRoute } from '../hoc/protected-route'
import { Permission, enterpriseRules, rules } from '../lib/permissions'
import { Admin } from './admin'
import { DashboardProfilePage } from './dashboard/profile/dashboard-profile-page'
import { enterpriseInternalRoles, legacyInternalRoles } from '../utils/user-role-mapper'
import type { UserRole } from '../utils/user-role-mapper'

const Router: FC = () => {
  const ldClient = useLDClient()
  const { initialize } = useUserAnalytics()

  const { user, isAuthenticated } = useExtendAuth()

  useEffect(() => {
    if (!user) return
    const { id: userId, email, accountId, role } = user

    const ldUser = !userId
      ? {
          anonymous: true,
        }
      : {
          key: userId,
          email,
          custom: {
            accountId,
          },
          privateAttributeNames: ['email'],
        }

    ldClient?.identify(ldUser)
    initialize({ userId, email, accountId, role })
  }, [ldClient, user, initialize])

  const rootRedirectTo = useMemo(() => {
    if (!isAuthenticated) return '/login'

    if (!user) return '/profile'
    if (
      user.role &&
      [
        legacyInternalRoles.admin.value,
        legacyInternalRoles.superadmin.value,
        ...Object.keys(enterpriseInternalRoles)
          .filter(
            (key) =>
              ![
                enterpriseInternalRoles.ExtendPortalAdmin.value,
                enterpriseInternalRoles.ProvisioningManager.value,
                enterpriseInternalRoles.ComplianceManager.value,
                enterpriseInternalRoles.DemoCSR.value,
              ].includes(key),
          )
          .map((key) => enterpriseInternalRoles[key as keyof typeof enterpriseInternalRoles].value),
      ].includes(user.role)
    ) {
      return '/admin'
    }

    if (user.role && user.role === enterpriseInternalRoles.ExtendPortalAdmin.value) {
      return '/admin/user-management'
    }

    if (user.role && user.role === enterpriseInternalRoles.ProvisioningManager.value) {
      return '/admin/accounts'
    }

    if (user.role && user.role === enterpriseInternalRoles.ComplianceManager.value) {
      return '/admin/terms'
    }

    if (user.role && user.role === enterpriseInternalRoles.DemoCSR.value) {
      return '/admin/claims'
    }

    const allowedAdjudicationRoles: UserRole[] = [
      ...enterpriseRules[Permission.ViewAdjudication].allowedRoles,
      ...rules[Permission.ViewAdjudication].allowedRoles,
    ]

    if (user.role && allowedAdjudicationRoles.includes(user.role as UserRole)) {
      return '/admin/adjudication-management'
    }

    return '/profile'
  }, [user, isAuthenticated])

  return (
    <Switch>
      <Redirect from="/" to={rootRedirectTo} exact />

      <Route path="/login">
        {' '}
        <Login />{' '}
      </Route>

      <Route path="/logout">
        <Logout />
      </Route>

      <ProtectedRoute path="/admin" component={Admin} />

      <ProtectedRoute
        requiredPermissions={[Permission.ManageProfile]}
        path="/profile"
        component={DashboardProfilePage}
      />

      <Route>
        <RouterError />
      </Route>
    </Switch>
  )
}

export { Router }
