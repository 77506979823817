import type { ExtractActions } from '@helloextend/core-api-redux/src/types/utility'
import * as contracts from './contracts'
import * as stores from './stores'
import * as plans from './plans'
import * as servicers from './servicers'

export { contracts, stores, plans, servicers }

export type Action =
  | ExtractActions<typeof contracts>
  | ExtractActions<typeof stores>
  | ExtractActions<typeof plans>
  | ExtractActions<typeof servicers>

export type ActionType = Action['type']
