import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import {
  usePopover,
  Button,
  ToastColor,
  ToastDuration,
  useToaster,
  Menu,
  Notes,
  MessageBlock,
} from '@extend/zen'
import { useDispatch, batch, useSelector } from 'react-redux'
import type { MenuItem } from '@extend/zen'
import type { RootState } from '../../../../../reducers'
import {
  setIsEditorPanelVisible,
  setIsReusableThreadPickerVisible,
  setPlaceholderMessageBlock,
  setSelectedMessageBlock,
} from '../../../../../store/slices/amp-slice'
import { DynamicPickerTypes, KaleyConditionTypes, MessageBlockTypes } from '../../utils'
import { useAddMessageBlock } from './use-add-message-block'
import { getSelectedMessageBlockIndex } from '../../../../../reducers/selectors'

type ConversationAppendButtonsProps = {
  description: string
  isAtThreadTermination?: boolean
}

const ConversationAppendButtons: FC<ConversationAppendButtonsProps> = ({
  description,
  isAtThreadTermination = true,
}) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()

  const { toggle } = usePopover<HTMLButtonElement>()

  const selectedMessageBlockIndex = useSelector((state: RootState) =>
    getSelectedMessageBlockIndex(state),
  )

  const {
    handleMessageBlockClickItem,
    handleKaleyConditionClickItem,
    handleDynamicPickerClickItem,
    addingMessageBlockError,
  } = useAddMessageBlock(toggle)

  useEffect(() => {
    if (addingMessageBlockError !== null) {
      toast({
        message: 'An error occurred while adding a message block',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [addingMessageBlockError, toast])

  const handleAddThread = (): void => {
    batch(() => {
      dispatch(setPlaceholderMessageBlock(selectedMessageBlockIndex))
      dispatch(setSelectedMessageBlock(null))
      dispatch(setIsEditorPanelVisible(true))
      dispatch(setIsReusableThreadPickerVisible(true))
    })
  }

  const menuButtons: MenuItem[] = [
    {
      type: 'menu',
      label: 'Message Block',
      items: Object.values(MessageBlockTypes).map((blockType) => ({
        type: 'button',
        label: blockType,
        onClick: handleMessageBlockClickItem,
        'data-cy': blockType,
      })),
    },
    {
      type: 'menu',
      label: 'Kaley Condition',
      items: Object.values(KaleyConditionTypes).map((conditionType) => ({
        type: 'button',
        label: conditionType,
        onClick: handleKaleyConditionClickItem,
        'data-cy': conditionType,
      })),
    },
    {
      type: 'menu',
      label: 'Dynamic Picker',
      items: Object.values(DynamicPickerTypes).map((dynamicPickerType) => ({
        type: 'button',
        label: dynamicPickerType,
        onClick: handleDynamicPickerClickItem,
        'data-cy': dynamicPickerType,
      })),
    },
  ]

  return (
    <Container data-cy={`amp-${description}-ctas`}>
      {isAtThreadTermination && (
        <Button
          text="Add Thread"
          size="small"
          emphasis="medium"
          icon={Notes}
          onClick={handleAddThread}
          data-cy={`add-thread-button-${description}`}
        />
      )}
      <Menu
        data-cy="menu-buttons"
        items={menuButtons}
        triggerRenderer={() => (
          <Button
            text="Add Block"
            size="small"
            emphasis="medium"
            icon={MessageBlock}
            data-cy={`add-block-button-${description}`}
          />
        )}
      />
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  gap: 8,
  marginTop: 12,
  marginLeft: 44,
})

export { ConversationAppendButtons }
