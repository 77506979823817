import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useGetOrchestratorConfigQuery } from '@helloextend/extend-api-rtk-query'
import { Box, DataProperty, DataPropertyType, Button, Edit, Stack, Dots } from '@extend/zen'
import { InfoPanel } from './components/info-panel'
import { EditPanel } from './components/edit-panel'

type WDConfigurationProps = {
  storeId: string
  storeName: string
}

const WDConfiguration: FC<WDConfigurationProps> = ({ storeId, storeName }) => {
  const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false)

  const { data: orchestratorConfig, isFetching: isFetchingOrchestrator } =
    useGetOrchestratorConfigQuery({ storeId })

  const updatedInformationText = useMemo(() => {
    const updatedAtString =
      orchestratorConfig && orchestratorConfig.dbUpdatedAt
        ? new Date(orchestratorConfig.dbUpdatedAt).toLocaleString()
        : '---'
    const updatedByString =
      orchestratorConfig && orchestratorConfig.lastUpdatedBy
        ? orchestratorConfig.lastUpdatedBy
        : '---'
    return 'Last update: '.concat(updatedAtString, ' by ', updatedByString)
  }, [orchestratorConfig])

  // Early exits (loading, no feature flag)
  if (isFetchingOrchestrator) {
    return <Dots />
  }
  return (
    <>
      <DataProperty label="" type={DataPropertyType.string} value={updatedInformationText} />
      <Box>
        <Stack spacing={1}>
          <Header>
            <h2>Basic Settings</h2>
            {!isEditEnabled && (
              <Button
                text="Edit"
                emphasis="low"
                size="small"
                onClick={() => setIsEditEnabled(true)}
                icon={Edit}
                data-cy="edit-button"
              />
            )}
          </Header>
          {isEditEnabled ? (
            <EditPanel
              initialConfig={orchestratorConfig}
              storeId={storeId}
              storeName={storeName}
              onSubmit={() => setIsEditEnabled(false)}
              onCancel={() => setIsEditEnabled(false)}
            />
          ) : (
            <InfoPanel orchestratorConfig={orchestratorConfig} minNumberOfActions={4} />
          )}
        </Stack>
      </Box>
    </>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

export { WDConfiguration }
