import type { FC } from 'react'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { useDispatch, useSelector } from 'react-redux'
import { addPhotoRequirement } from '../../../../../store/slices/amp-slice'
import * as selectors from '../../../../../reducers/selectors'
import { PhotoRequirementForm } from '../photo-requirement-form/photo-requirement-form'
import { PhotoRequirementAddButton } from '../photo-requirement-add-button/photo-requirement-add-button'
import type { RootState } from '../../../../../reducers'
import type { PhotoRequirement } from '../../../../../types/conversations'

const PhotoRequirementPanel: FC = () => {
  const dispatch = useDispatch()

  const photoRequirements = useSelector((state: RootState) => selectors.getPhotoRequirements(state))

  const addPhotoRequirementCallback = useCallback(
    (index: number): void => {
      dispatch(
        addPhotoRequirement({
          requirement: {
            description: '',
            isAlwaysRequired: false,
            conditions: [],
            tags: [],
          },
          index,
        }),
      )
    },
    [dispatch],
  )

  return (
    <MainContainer>
      <HeaderContainer>
        <Header>Photos</Header>
        <SubHeader>
          Add conditions that require photos to determine the failure type and cause.
        </SubHeader>
      </HeaderContainer>
      <AddPhotoRequirementButtonContainer>
        <PhotoRequirementAddButton
          data-cy="photo-requirements-add-button-0"
          onClick={(index) => addPhotoRequirementCallback(index)}
          key="photo-requirements-add-button-0"
          index={0}
        />
      </AddPhotoRequirementButtonContainer>
      {photoRequirements &&
        photoRequirements.length > 0 &&
        photoRequirements.map((photoRequirement: PhotoRequirement, index: number) => (
          <div key={`photo-requirements-wrapper-div-${index}`}>
            <PhotoRequirementForm
              photoRequirement={photoRequirement}
              index={index}
              key={`photo-requirements-form-${index}`}
            />
            <AddPhotoRequirementButtonContainer key={`photo-requirements-container-${index}`}>
              <PhotoRequirementAddButton
                data-cy={`photo-requirements-add-button-${index + 1}`}
                onClick={(clickedIndex) => addPhotoRequirementCallback(clickedIndex)}
                key={`photo-requirements-add-button-${index + 1}`}
                index={index + 1}
              />
            </AddPhotoRequirementButtonContainer>
          </div>
        ))}
    </MainContainer>
  )
}

const MainContainer = styled.div({
  display: 'flex',
  padding: '0 8px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flexShrink: 0,
  gap: 16,
  background: COLOR.WHITE,
  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
})
const HeaderContainer = styled.div({
  display: 'flex',
  padding: '24px 0px 21px 24px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexDirection: 'column',
})
const Header = styled.span({
  width: '752px',
  height: '29px',
  flexShrink: 0,
  color: COLOR.NEUTRAL[1000],
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
})
const SubHeader = styled.span({
  width: '752px',
  height: '25px',
  flexShrink: 0,
  color: COLOR.NEUTRAL[1000],
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
})
const AddPhotoRequirementButtonContainer = styled.div({
  display: 'flex',
  width: '776px',
  alignItems: 'center',
  gap: '12px',
})

export { PhotoRequirementPanel }
