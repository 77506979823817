import type { FC } from 'react'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Button } from '@extend/zen'
import type { RuleStatus } from '@helloextend/extend-api-rtk-query'
import { addConditionToRule } from '../../../../../store/slices/amp-slice'

type AddConditionButtonProps = {
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
  addConditionKey: () => void
}

const AddConditionButton: FC<AddConditionButtonProps> = ({
  ruleIndex,
  rulesetType,
  conditionIndex,
  addConditionKey,
}) => {
  const dispatch = useDispatch()

  const handleButtonClick = (): void => {
    addConditionKey()
    dispatch(
      addConditionToRule({
        rulesetType,
        ruleIndex,
      }),
    )
  }

  return (
    <ADDWrapper>
      <Button
        color="blue"
        emphasis="low"
        data-cy={`add-${rulesetType}-rule${ruleIndex}-condition${conditionIndex}-condition-button`}
        text="And"
        onClick={handleButtonClick}
        size="small"
      />
    </ADDWrapper>
  )
}

const ADDWrapper = styled.div({
  alignSelf: 'center',
})
export { AddConditionButton }
