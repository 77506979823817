import type { FC } from 'react'
import React, { useState } from 'react'
import type { ServiceOrder, Servicer } from '@helloextend/extend-api-client'
import { useListServicersQuery } from '@helloextend/extend-api-rtk-query'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { Loading, AdvancedSelect } from '@extend/zen'
import type { V2 } from '@extend-services/service-orders'

interface ServicerSelectProps {
  name: string
  label?: string
  errorMessage?: string
  error?: boolean
  onChange: (name: string, fieldValue: Servicer | null) => void
  serviceType?: ServiceOrder['serviceType'] | V2.Models.RepairFulfillmentMethod
}

const ServicerSelect: FC<ServicerSelectProps> = ({
  name,
  onChange,
  label = 'Servicer',
  errorMessage = '',
  error = false,
  serviceType,
}) => {
  const [selectedServicerId, setSelectedServicerId] = useState<string>('')
  const { isLoading, isFetching, data } = useListServicersQuery(
    { serviceType },
    {
      selectFromResult: (result) => ({
        ...result,
        data: {
          ...result.data,
          items: result.data?.items
            ? [...result.data.items].sort((a: Servicer, b: Servicer) =>
                a.businessName.localeCompare(b.businessName),
              )
            : [],
        },
      }),
    },
  )

  const options = data?.items
    ? data.items.map((s) => ({ display: s.businessName, value: s.id }))
    : []

  const handleChange = (e: AdvancedSelectChangeEvent): void => {
    if (!data?.items) return
    const {
      target: { value },
    } = e

    setSelectedServicerId(value)

    const servicer = data.items.find((s) => s.id === value) || null

    onChange(name, servicer)
  }

  return isFetching || isLoading ? (
    <Loading dotsSize="xs" heightPx={65} />
  ) : (
    <AdvancedSelect
      multiple={false}
      value={selectedServicerId}
      id="servicers"
      isNotClearable
      label={label}
      onChange={handleChange}
      data-cy="servicer-select"
      showSearch
      isError={error}
      errorFeedback={errorMessage}
      options={options}
    />
  )
}

export { ServicerSelect }
