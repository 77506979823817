import type { FC } from 'react'
import React, { useState, useEffect, useCallback, Fragment } from 'react'
import type { ActionMeta, SingleValue } from 'react-select'
import Select from 'react-select'
import {
  COLOR,
  Button,
  InlineAlert,
  InlineAlertColor,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@extend/zen'
import type {
  LegacyProductsMutateRequest,
  ProductMappedRecord,
  InvalidProductMapped,
  ExtendCategory,
} from '@helloextend/extend-api-rtk-query'
import {
  useLegacyValidateProductMappingsMutation,
  useGetMappingPlansQuery,
} from '@helloextend/extend-api-rtk-query'
import { BasicModal } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'
import type { SelectOption } from '../../../../../../types/products'

const PLANS_TO_MAP = [1, 2, 3]
const BASE_PLAN_AUTO_TBD = 'auto_tbd'

type ValidateProductModalProps = {
  isOpen: boolean
  onClickClose: () => void
  productMappings?: ProductMappedRecord[]
  storeExtendCategories?: ExtendCategory[]
}

const generateSelectOptions = (lbls: string[]): SelectOption[] => {
  return [...new Set(lbls)].map((lbl) => {
    return { label: lbl, value: lbl }
  })
}

const ValidateProductModal: FC<ValidateProductModalProps> = ({
  isOpen,
  onClickClose,
  productMappings,
  storeExtendCategories,
}) => {
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>()
  const [planOptions, setPlanOptions] = useState<SelectOption[]>()
  const [hasDifferentCategories, setHasDifferentCategories] = useState<boolean>(false)
  const [hasDifferentPlans, setHasDifferentPlans] = useState<boolean>(false)
  const [hasNoBasePlan, setHasNoBasePlan] = useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] = useState<SelectOption | null>()
  const [selectedBasePlan, setSelectedBasePlan] = useState<SelectOption | null>()
  const [selectedWarrantyStatus, setSelectedWarrantyStatus] = useState<string | null>()
  const [categoryDictionary, setCategoryDictionary] = useState<Record<string, ExtendCategory[]>>()
  const [selectedPlans, setSelectedPlans] = useState<SelectOption[]>([])
  const [basePlanOptions, setBasePlanOptions] = useState<SelectOption[]>()

  const [
    validateMappingPOST,
    {
      data: updatedProductMapping,
      isLoading: isUpdatedProductLoading,
      isSuccess: isUpdatedProductSuccess,
      error: updatedProductError,
    },
  ] = useLegacyValidateProductMappingsMutation()
  const { data: planData, isLoading: isPlanDataLoading } = useGetMappingPlansQuery()
  const { toast } = useToaster()

  const handleClickCloseReset = useCallback((): void => {
    setSelectedBasePlan(null)
    setSelectedCategory(null)
    setSelectedWarrantyStatus(null)
    setHasNoBasePlan(false)
    setHasDifferentCategories(false)
    setHasDifferentPlans(false)
    setSelectedPlans([])
    onClickClose() // closes modal in parent component
  }, [onClickClose])

  const handleClickSubmit = (): void => {
    const mappedCategory =
      selectedCategory && selectedCategory.label ? selectedCategory.label : undefined
    const productIds = productMappings
      ? productMappings.map((product) => product.product_id)
      : ([] as string[])
    const storeId = productMappings ? productMappings[0].store_id : ''
    // hard code evaluation_code to 'valid' or 'invalid' based on whether ML mapping equals the submitted category from the agent
    const evalCode =
      productMappings &&
      (!mappedCategory || productMappings.every((prod) => prod.extend_category === mappedCategory))
        ? 'valid'
        : 'invalid'

    // build POST request
    const mutateQuery: LegacyProductsMutateRequest = {
      product_ids: productIds,
      evaluation_code: evalCode,
      base_plan: selectedBasePlan ? selectedBasePlan.value : undefined,
      plans: selectedPlans.filter((item) => item.label).map((item) => item.label),
      store_id: storeId,
    }
    if (mappedCategory !== '') mutateQuery.extend_category = mappedCategory

    validateMappingPOST(mutateQuery)
  }

  const handleCategoryChange = (
    option: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>,
  ): void => {
    if (actionMeta && actionMeta.action === 'clear') setSelectedCategory(null)
    if (option) setSelectedCategory(option)
  }

  const handleBasePlanChange = (
    option: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>,
  ): void => {
    if (actionMeta && actionMeta.action === 'clear') setSelectedBasePlan(null)
    if (option) {
      setSelectedBasePlan(option)
      const basePlanPlans =
        categoryDictionary && selectedCategory
          ? categoryDictionary[selectedCategory.label].find(
              (category) => category.base_plan === option.value,
            )?.plans
          : []
      const plans =
        option.value?.toLocaleLowerCase() === BASE_PLAN_AUTO_TBD ? planData?.plans : basePlanPlans
      setPlanOptions(plans ? generateSelectOptions([...plans].sort()) : [])
    }
    setSelectedPlans([])
  }

  const handlePlanChanges = (
    index: number,
    option: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>,
  ): void => {
    if (actionMeta.action === 'clear') {
      setSelectedPlans(selectedPlans.filter((_, i) => i !== index))
    }
    if (option) {
      setSelectedPlans([...selectedPlans.filter((_, i) => i !== index), option])
    }
  }
  const loadPostValidationMessage = useCallback(
    (validItems: ProductMappedRecord[], invalidItems: InvalidProductMapped[]): void => {
      if (validItems.length > 0) {
        const msg = 'Successfully validated '.concat(validItems.length.toString(), ' item(s).')
        toast({
          message: msg,
          toastColor: ToastColor.green,
          toastDuration: ToastDuration.long,
        })
      }
      if (invalidItems.length > 0) {
        const errList: string[] = invalidItems.map((val) =>
          '   .('.concat(val.item.title, ' [', val.item.product_id, ']): ', val.error),
        )
        toast({
          message: invalidItems.length.toString().concat(' item(s) not validated:', ...errList),
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.indefinite,
        })
      }
    },
    [toast],
  )

  const getBasePlans = useCallback(
    (categoryOption: SelectOption, categoryDict: Record<string, ExtendCategory[]>): string[] => {
      const categoryDictHasOption = Object.prototype.hasOwnProperty.call(
        categoryDictionary,
        categoryOption.label,
      )
      return categoryDictHasOption
        ? categoryDict[categoryOption.label].reduce(function filterValues(
            filtered: string[],
            option,
          ) {
            if (option.base_plan) {
              filtered.push(option.base_plan)
            }
            return filtered
          },
          [])
        : []
    },
    [categoryDictionary],
  )

  // Triggers after a validation submission
  useEffect(() => {
    let validItems: ProductMappedRecord[] = []
    let invalidItems: InvalidProductMapped[] = []

    if (updatedProductMapping) {
      validItems = updatedProductMapping.valid_items
      invalidItems = updatedProductMapping.invalid_items
      loadPostValidationMessage(validItems, invalidItems)
    }
    handleClickCloseReset()
  }, [
    isUpdatedProductSuccess,
    toast,
    updatedProductError,
    updatedProductMapping,
    handleClickCloseReset,
    loadPostValidationMessage,
  ])

  // Triggers when user selects new products
  useEffect(() => {
    if (!productMappings) return

    const hasDiffCats = !productMappings.every(
      (val, _, arr) => val.extend_category === arr[0].extend_category,
    )

    const hasDiffPlans = !productMappings.every(
      (product, _, arr) =>
        product.plans?.length === undefined ||
        (product.plans?.length === arr[0].plans?.length &&
          product.plans?.every((plan) => arr[0].plans?.includes(plan))),
    )
    setHasDifferentCategories(hasDiffCats)
    setHasDifferentPlans(hasDiffPlans)

    // display matching plans only when all products have the same exact set of plans
    if (hasDiffPlans) setSelectedPlans([])
    else setSelectedPlans(generateSelectOptions(productMappings[0].plans ?? []))
  }, [productMappings])

  // Triggers when user changes the category. Auto-sets base plan and warranty status
  useEffect(() => {
    setSelectedBasePlan(null)
    setSelectedPlans([])
    setPlanOptions([])
    if (selectedCategory && categoryDictionary) {
      const basePlans = getBasePlans(selectedCategory, categoryDictionary)
      setBasePlanOptions(basePlans.length > 0 ? generateSelectOptions([...basePlans].sort()) : [])
      setSelectedWarrantyStatus(basePlans.length > 0 ? 'warrantable' : 'non-warrantable')
      if (basePlans.length === 1) {
        setSelectedBasePlan(generateSelectOptions(basePlans)[0])
      }
    } else {
      setBasePlanOptions([])
    }
  }, [selectedCategory, categoryDictionary, getBasePlans])

  // Effect to handle the 'no base plan' rule
  useEffect(() => {
    setHasNoBasePlan(!productMappings?.every((val) => val.base_plan) && !selectedBasePlan)
  }, [productMappings, selectedBasePlan])

  const setUserEnabledExtendCategories = (
    storeExtendCategoryArray: ExtendCategory[],
  ): Record<string, ExtendCategory[]> => {
    const productExtendCategoryDictionaries = storeExtendCategoryArray.reduce(
      function groupedCategories(r, a) {
        const groupDict = r
        groupDict[a.extend_category] = groupDict[a.extend_category] || []
        groupDict[a.extend_category].push(a)
        return groupDict
      },
      Object.create(null),
    )

    return productExtendCategoryDictionaries
  }

  const shouldSetCategoriesAfterReset = !categoryOptions && !categoryDictionary
  const noPlanOptionsAndDataAvailable =
    (!planOptions || planOptions.length === 0) && selectedBasePlan && planData

  if (shouldSetCategoriesAfterReset) {
    const extendCategoryList = storeExtendCategories
      ? storeExtendCategories
          .map((extendCategory) => extendCategory.extend_category)
          .sort((a, b) => a.localeCompare(b))
      : []
    setCategoryOptions(generateSelectOptions(extendCategoryList))
    setCategoryDictionary(setUserEnabledExtendCategories(storeExtendCategories || []))
    setBasePlanOptions([])
  }

  useEffect(() => {
    const extendCategoryList = storeExtendCategories
      ? storeExtendCategories
          .map((extendCategory) => extendCategory.extend_category)
          .sort((a, b) => a.localeCompare(b))
      : []

    setCategoryOptions(generateSelectOptions(extendCategoryList))
    setCategoryDictionary(setUserEnabledExtendCategories(storeExtendCategories || []))
  }, [storeExtendCategories])

  if (noPlanOptionsAndDataAvailable) {
    // all plans available for auto_tbd, limit to base plan otherwise
    if (
      categoryDictionary &&
      selectedBasePlan &&
      selectedBasePlan?.value.trim() !== '' &&
      selectedCategory
    ) {
      const plans =
        selectedBasePlan.value?.toLocaleLowerCase() === BASE_PLAN_AUTO_TBD
          ? planData?.plans
          : categoryDictionary[selectedCategory.label].find(
              (category) => category.base_plan === selectedBasePlan.value,
            )?.plans
      setPlanOptions(plans ? generateSelectOptions([...plans].sort()) : [])
    } else {
      setPlanOptions([])
    }
  }

  const warrantableExtendCategories = ['auto_warrantable', 'general_warrantable']
  const disablePlans =
    selectedCategory === null ||
    selectedBasePlan === null ||
    selectedWarrantyStatus === 'non-warrantable'
  const disableBasePlans =
    !selectedCategory ||
    selectedWarrantyStatus === 'non-warrantable' ||
    (categoryDictionary && !categoryDictionary[selectedCategory.label][0].user_enabled)

  // Submit button should be disabled for warrantable products without product mapping selected
  const isSubmitDisabled =
    (selectedWarrantyStatus === 'warrantable' && selectedPlans.length === 0) ||
    productMappings?.some(
      (product) =>
        warrantableExtendCategories.includes(product.extend_category) && selectedCategory === null,
    )

  return (
    <BasicModal isVisible={isOpen} onClickClose={handleClickCloseReset}>
      <Title>Validate Product Mapping(s)</Title>
      {productMappings?.length === 1 ? (
        <>
          <Label>Product Title</Label>
          <Value>{productMappings[0].title}</Value>
          <Label>Price</Label>
          <Value>{productMappings[0].price || <span>&nbsp;&nbsp;</span>}</Value>
        </>
      ) : (
        <AlertsWrapper>
          <InlineAlert color={InlineAlertColor.blue}>
            Validating {productMappings?.length} products.
          </InlineAlert>
          {hasDifferentCategories && !selectedCategory && (
            <InlineAlert color={InlineAlertColor.yellow}>
              WARNING: You have selected products with different mapped Extend Categories. If an
              Extend Category is not selected in this modal, then the mapped Extend Categories for
              each product will be used for the update.
            </InlineAlert>
          )}
          {hasNoBasePlan && (
            <InlineAlert color={InlineAlertColor.yellow}>
              WARNING: One or more of the selected Extend Categories have no Base Plan. If a Plan is
              selected, then the product(s) with no base plan will not be validated.
            </InlineAlert>
          )}
          {hasDifferentPlans && (
            <InlineAlert color={InlineAlertColor.yellow}>
              WARNING: You have selected products with different plan IDs.
            </InlineAlert>
          )}
        </AlertsWrapper>
      )}
      <Label data-cy="extend-category">Extend Category</Label>
      <Select
        isLoading={!storeExtendCategories}
        placeholder="-- Select --"
        options={categoryOptions}
        onChange={(option, action) => handleCategoryChange(option, action)}
        value={categoryOptions?.filter((option) => {
          return option.label === selectedCategory?.label
        })}
        isOptionDisabled={(option) =>
          (categoryDictionary && !categoryDictionary[option.label][0].user_enabled) ?? true
        }
        isClearable
      />
      <Label data-cy="base-plan-id">Base Plan ID</Label>
      <Select
        isLoading={!basePlanOptions}
        placeholder="-- Select --"
        options={basePlanOptions}
        onChange={(option, action) => handleBasePlanChange(option, action)}
        value={selectedBasePlan}
        isDisabled={disableBasePlans}
        isClearable
      />
      <Label data-cy="warranty-status">Warranty Status</Label>
      <Value>
        {!selectedWarrantyStatus && <span>&nbsp;&nbsp;</span>}
        {selectedWarrantyStatus}
      </Value>
      {PLANS_TO_MAP.map((i) => {
        return (
          <Fragment key={i}>
            <Label data-cy={`plan-id-${i}`}>Plan ID {i}</Label>
            <Select
              isLoading={isPlanDataLoading}
              placeholder="-- Select --"
              options={planOptions}
              onChange={(option, action) => handlePlanChanges(i - 1, option, action)}
              value={planOptions?.filter((option) => {
                return option.label === selectedPlans[i - 1]?.label
              })}
              isOptionDisabled={(option) => selectedPlans.includes(option)}
              isDisabled={disablePlans}
              isClearable
            />
          </Fragment>
        )
      })}
      <ButtonWrapper>
        <Button text="Cancel" emphasis="medium" onClick={handleClickCloseReset} />
        <Button
          text="Submit"
          emphasis="high"
          onClick={handleClickSubmit}
          isDisabled={isSubmitDisabled}
          isProcessing={isUpdatedProductLoading}
        />
      </ButtonWrapper>
    </BasicModal>
  )
}

const AlertsWrapper = styled.div({
  display: 'flex',
  gap: 12,
  flexDirection: 'column',
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 12,
  marginTop: 32,
})

const Title = styled.h2({
  fontSize: 20,
  lineHeight: '28px',
  fontWeight: 700,
  marginTop: 0,
})

const Value = styled.div({
  lineHeight: '24px',
  display: 'block',
})

const Label = styled.div({
  boxSizing: 'border-box',
  lineHeight: '24px',
  fontWeight: 700,
  color: COLOR.BLACK,
  marginTop: 12,
})

export type { ValidateProductModalProps }
export { ValidateProductModal }
