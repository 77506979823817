import React from 'react'

import { CellText } from '@helloextend/merchants-ui'

import type { Column } from '@helloextend/merchants-ui'
import type { TablePlanAttributesSearch } from '../../../types/plans'

const planAttributesColumns: Array<Column<TablePlanAttributesSearch>> = [
  {
    Header: 'Plan Attribute',
    accessor: 'id',
    Cell: (data) => <CellText title={data.id}>{data.id}</CellText>,
    canSearch: true,
    isSelectable: true,
  },
]

export { planAttributesColumns }
