import type { ClaimCode } from '@helloextend/extend-api-client'

export const VALIDATION_ERROR_MAP: Partial<Record<ClaimCode, string>> = {
  compliance_review: 'Further review is required to meet compliance.',
  fraud_risk: 'Claim has been flagged as high fraud risk.',
  invalid_contract_status: 'Contract has an invalid status.',
  mfr_warranty_active:
    'This claim was denied because the customer still has an active manufacturer warranty that should cover this damage.',
  no_coverage_remaining: 'There is no coverage remaining for the contract.',
  occurred_after_coverage: 'Incident occurred after the coverage period.',
  occurred_before_coverage: 'Incident occurred before the coverage period.',
  occurred_before_purchase: 'Incident occurred before the purchase date.',
  occurred_future_date: 'Incident occurred on a future date.',
  reported_after_coverage: 'Claim reported after the coverage period.',
  service_type_invalid: 'Service type is invalid.',
}

export const getValidationErrorMessage = (
  validationError: keyof typeof ClaimCode,
): string => {
  return VALIDATION_ERROR_MAP[validationError] ?? validationError
}
