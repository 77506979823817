import { isEqual } from 'lodash/fp'
import type {
  EmbeddedPromotionCsvValidatorPipe,
  EmbeddedPromotionHeader,
} from './embedded-promotion-csv-headers'
import { embeddedPromotionHeader } from './embedded-promotion-csv-headers'
import { isEncoded, isScientificNotation } from '../validation-helpers'

export function validateHeader(header: string[]): boolean {
  return isEqual(header, embeddedPromotionHeader)
}

export const validateString = (
  meta: EmbeddedPromotionCsvValidatorPipe,
  headerIndex: number,
): EmbeddedPromotionCsvValidatorPipe => {
  const headerNames = Object.keys(meta.headerIndexes) as EmbeddedPromotionHeader[]
  const header = headerNames[headerIndex]
  const str = meta.rowData[headerIndex]
  if (!str) {
    meta.errors.push(`"${header}" should be defined`)
    return meta
  }

  if (str.trim() !== str) {
    meta.errors.push(`"${header}" should not have leading or trailing whitespace (${str})`)
  }

  if (isEncoded(str)) {
    meta.errors.push(`"${header}" should not be encoded (${str})`)
  }

  if (isScientificNotation(str)) {
    meta.errors.push(`"${header}" may be formatted in scientific notation (${str})`)
  }

  return meta
}

export const validateBoolean = (
  meta: EmbeddedPromotionCsvValidatorPipe,
  headerIndex: number,
): EmbeddedPromotionCsvValidatorPipe => {
  const headerNames = Object.keys(meta.headerIndexes) as EmbeddedPromotionHeader[]
  const header = headerNames[headerIndex]
  const bool = meta.rowData[headerIndex]
  if (!bool) {
    meta.errors.push('"toDelete" should be defined')
    return meta
  }

  if (bool.toLowerCase() !== 'true' && bool.toLowerCase() !== 'false') {
    meta.errors.push(`"${header}" should either be "false" or "true"`)
  }

  return meta
}
