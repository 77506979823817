import type { PlanSet, PlanSetPriceBand } from '@helloextend/extend-api-client'

// check plans in a planSet to see if another planSet within the same category uses all the same plans and has same number of price bands
// (Note: price band min/maxes don't have to match up just checking to see if they have same plans mapped)
export const findDuplicatePlanSets = (
  planSets: PlanSet[],
  categoryId: string,
  currentPlanSetName: string,
  currentPriceBands: PlanSetPriceBand[],
): PlanSet[] => {
  const filteredPlanSets = planSets.filter(
    (ps) => ps.planCategoryId === categoryId && ps.name !== currentPlanSetName,
  )
  const response: PlanSet[] = []
  if (currentPriceBands.length > 0) {
    for (const planSet of filteredPlanSets) {
      if (planSet.priceBands.length === currentPriceBands.length) {
        if (comparePlanSetPriceBands(currentPriceBands, planSet.priceBands)) {
          response.push(planSet)
        }
      }
    }
  }

  return response
}

export const comparePlanSetPriceBands = (
  priceBands1: PlanSetPriceBand[],
  priceBands2: PlanSetPriceBand[],
): boolean => {
  const arePlansInPriceBandSame: boolean[] = []
  for (let i = 0; i < priceBands1.length; i += 1) {
    const priceBand1 = priceBands1[i]
    const priceBand2 = priceBands2[i]
    if (
      priceBand2.plans.length === priceBand1.plans.length &&
      priceBand2.plans.every((plan) => priceBand1.plans.includes(plan))
    ) {
      arePlansInPriceBandSame.push(true)
    } else {
      arePlansInPriceBandSame.push(false)
    }
  }
  if (checkAllTrue(arePlansInPriceBandSame)) {
    return true
  }
  return false
}

function checkAllTrue(booleans: boolean[]): boolean {
  let allTrue = true

  for (let i = 0; i < booleans.length; i += 1) {
    if (!booleans[i]) {
      allTrue = false
      break
    }
  }

  if (allTrue) {
    return true
  }
  return false
}
