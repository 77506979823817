import { AdvancedSelect, DataProperty, Grid } from '@extend/zen'
import { useFormikContext } from 'formik'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import type { SpPlanFormValues } from '../sp-plan-form-schema'
import { GetFormPropsAndLabels } from '../sp-plan-form-schema'

type SpPlanGeoSectionProps = {
  mode: 'view' | 'edit'
}

type PlanGeoProps = 'allowedRegions'

const SpPlanGeoSection: FC<SpPlanGeoSectionProps> = ({ mode }) => {
  const { values, handleChange, errors, touched, handleBlur } = useFormikContext<SpPlanFormValues>()

  const formProps = useMemo(() => GetFormPropsAndLabels<PlanGeoProps>(), [])

  return (
    <Grid columns={2} data-cy="plan-geo-section">
      {mode === 'edit' ? (
        <AdvancedSelect
          id={formProps.allowedRegions.property}
          value={values.allowedRegions ?? []}
          label={formProps.allowedRegions.label}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={!!errors.allowedRegions?.toString() && !!touched.allowedRegions}
          errorFeedback={errors.allowedRegions as string}
          options={[
            { value: 'US', display: 'US' },
            { value: 'CA', display: 'Canada' },
          ]}
          multiple
          data-cy={formProps.allowedRegions.property}
        />
      ) : (
        <DataProperty
          label={formProps.allowedRegions.label}
          value={
            values.allowedRegions
              ?.map((callbackfn) => callbackfn.valueOf().toString())
              .join(', ') ?? ''
          }
          data-cy={formProps.allowedRegions.property}
        />
      )}
    </Grid>
  )
}

export { SpPlanGeoSection }
