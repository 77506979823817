import type { FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import { DataTable, Reset } from '@extend/zen'
import type {
  DataTableAction,
  ColumnFiltersState,
  PaginationState,
  SortingState,
  ButtonProps,
} from '@extend/zen'
import type { AISSearchArguments, AISSearchRecord } from '@helloextend/extend-api-rtk-query'
import { useSearchAISRecordsQuery } from '@helloextend/extend-api-rtk-query'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import {
  getTableFilters,
  getTableColumns,
  // getTableFilterOptions,
  getSearchFiltersFromQSParams,
} from './ais-data-table-config'

type AISDataTableProps = {
  storeId: string
}

const AisDataTable: FC<AISDataTableProps> = ({ storeId }) => {
  const [sortKey, setSortKey] = useQueryStringState('sortKey', '')
  const [sortDirection, setSortDirection] = useQueryStringState('sortDirection', 'asc')
  const [searchKey, setSearchKey] = useQueryStringState('searchKey', '')
  const [searchValue, setSearchValue] = useQueryStringState('searchValue', '')
  const [searchFilters, setSearchFilters] = useQueryStringState('filters', {}, { encode: true })
  const [sorting, setSorting] = useState<SortingState>([
    { id: sortKey, desc: sortDirection === 'desc' },
  ])
  const [hasAllRowsSelected, setHasAllRowsSelected] = useState<boolean>(false)

  const [pagination, setPagination] = useQueryStringState<PaginationState>(
    'pagination',
    {
      pageIndex: 0,
      pageSize: 100,
    },
    { encode: true },
  )

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    ...getSearchFiltersFromQSParams(searchFilters),
    ...(searchKey && searchValue ? [{ id: searchKey, value: searchValue }] : []),
  ])

  const [globalFilter, setGlobalFilter] = useState('')

  const setQueryStringParams = useCallback((): void => {
    setSortKey(sorting[0]?.id || '')
    setSortDirection(sorting[0]?.desc ? 'desc' : 'asc')
    const searchParam = columnFilters.find(({ id }) => id === 'name' || id === 'referenceId')

    setSearchKey(searchParam?.id || '')
    setSearchValue((searchParam?.value as string) || '')
    const filters = getTableFilters(columnFilters)
    setSearchFilters(filters)
  }, [
    columnFilters,
    setSearchFilters,
    setSearchKey,
    setSearchValue,
    setSortDirection,
    setSortKey,
    sorting,
  ])

  const columns = useMemo(() => {
    return getTableColumns()
  }, [])

  const argsForApi: AISSearchArguments = useMemo(() => {
    let args: AISSearchArguments = { storeId }
    if (columnFilters.length > 0) {
      const filters = getTableFilters(columnFilters)
      args = { ...args, ...filters }
    }
    setQueryStringParams()
    return args
  }, [columnFilters, pagination, setQueryStringParams, sorting, storeId])

  const {
    data: aisRecordData,
    isFetching: isAisRecordDataFetching,
    refetch: refetchSisRecordDataData,
  } = useSearchAISRecordsQuery(argsForApi)

  const handleRefetchData = (): void => {
    refetchSisRecordDataData()
  }

  //   const handleEditClick = (selectedRows: AisRecord[]): void => {
  //     setSelectedRecords(selectedRows)
  //     handleModalOn()
  //   }

  const getTableActions = (selectedRows: AISSearchRecord[]): DataTableAction[] => {
    if (selectedRows.length) {
      return [
        {
          //   onClick: () => handleEditClick(selectedRows),
          text: `Edit ${
            hasAllRowsSelected ? aisRecordData?.records.length : selectedRows.length
          } Selected Records`,
          emphasis: 'medium',
          'data-cy': 'edit-records-button',
          color: 'neutral',
        },
      ]
    }

    const resetButton = {
      onClick: handleRefetchData,
      emphasis: 'medium' as ButtonProps['emphasis'],
      icon: Reset,
      'data-cy': 'refetch-ais-records-button',
    }

    return [resetButton]
  }

  const isTableLoading = isAisRecordDataFetching

  return (
    <>
      <DataTable
        data-cy="ais-data-table"
        isLoading={isTableLoading}
        data={aisRecordData?.records || []}
        columns={columns}
        hasSelectableRows
        pageSizeOptions={[10, 25, 50, 100, 250]}
        onPaginationChange={setPagination}
        pagination={pagination}
        rowCount={aisRecordData?.records.length || 0}
        sorting={sorting}
        onSortingChange={setSorting}
        hasManualSorting
        hasManualFiltering
        hasManualPagination
        columnFilters={columnFilters}
        onColumnFiltersChange={setColumnFilters}
        globalFilter={globalFilter}
        onGlobalFilterChange={setGlobalFilter}
        // filterDefs={getTableFilterOptions([])}
        getTableActions={getTableActions}
        onSelectAllRowsChange={setHasAllRowsSelected}
      />
    </>
  )
}

export { AisDataTable }
