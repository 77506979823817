import type { FC } from 'react'
import React, { useState, useEffect } from 'react'

import {
  conversationsApi,
  useListConversationsQuery,
  usePatchConversationMutation,
} from '@helloextend/extend-api-rtk-query'
import type { ConversationResponse } from '@helloextend/extend-api-rtk-query'
import { useHistory, useLocation } from 'react-router'
import styled from '@emotion/styled'
import {
  Add,
  Button,
  COLOR,
  DataTable,
  ToastColor,
  ToastDuration,
  useToaster,
  Visibility,
} from '@extend/zen'
import type {
  SortingState,
  PaginationState,
  ColumnFiltersState,
  DataTableAction,
  DataTableMenuItem,
} from '@extend/zen'
import { bp } from '@extend/client-helpers'
import { Link } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'
import { AdjudicationTabs } from '../components'
import { conversationColumns, filterDefs } from './adjudication-conversation-list.utils'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'
import { CreateConversationModal } from '../components/create-conversation-modal/create-conversation-modal'
import { getSearchParams, getColumnFiltersFromSearchParams } from '../utils'
import {
  setIsArchiveConversationModalVisible,
  setIsRenameConversationModalVisible,
  setSelectedConversation,
} from '../../../../store/slices/amp-slice'
import type { RootState } from '../../../../reducers'
import * as selectors from '../../../../reducers/selectors'
import { ArchiveConversationModal } from '../components/archive-conversation-modal/archive-conversation-modal'
import { RenameConversationModal } from '../components/rename-conversation-modal'

const AdjudicationConversationList: FC = () => {
  const history = useHistory()
  const { toast } = useToaster()
  const { search: searchParams } = useLocation()
  const dispatch = useDispatch()
  const { data: conversationsData, isFetching, isLoading } = useListConversationsQuery()
  const [isCreateConversationModalVisible, setIsCreateConversationModalVisible] = useState(false)
  const selectedConversationListItem = useSelector((state: RootState) =>
    selectors.getSelectedConversation(state),
  )
  const isArchiveModalVisible = useSelector((state: RootState) =>
    selectors.getIsArchiveConversationModalVisible(state),
  )
  const isRenameModalVisible = useSelector((state: RootState) =>
    selectors.getIsRenameConversationModalVisible(state),
  )

  const { hasPermission } = usePermissions()
  const isUserAssumedEditorRole = hasPermission(Permission.ManageAdjudication)

  const [sorting, setSorting] = useState<SortingState>([{ id: 'updatedAt', desc: true }])
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })

  const defaultFilters: ColumnFiltersState = [{ id: 'status', value: ['draft', 'published'] }]
  const columnFiltersInit = searchParams
    ? getColumnFiltersFromSearchParams(searchParams)
    : defaultFilters
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(columnFiltersInit)
  const [restoreConversation] = usePatchConversationMutation()

  useEffect(() => {
    if (columnFilters.length > 0) {
      const search = getSearchParams(columnFilters)
      history.push({
        pathname: '/admin/adjudication-management/conversations',
        search,
      })
      return
    }
    history.push({
      pathname: '/admin/adjudication-management/conversations',
      search: '',
    })
  }, [history, columnFilters])

  const handleRoute = (route: string): void => {
    history.push({
      pathname: route,
      search: searchParams,
    })
  }

  const handleToggleCreateConversation = (): void => {
    setIsCreateConversationModalVisible(!isCreateConversationModalVisible)
  }

  const handleToggleRenameConversation = (): void => {
    dispatch(setIsRenameConversationModalVisible(!isRenameModalVisible))

    if (selectedConversationListItem) {
      dispatch(setSelectedConversation(null))
    }
  }

  const handleToggleArchiveConversation = (): void => {
    dispatch(setIsArchiveConversationModalVisible(!isArchiveModalVisible))

    if (selectedConversationListItem) {
      dispatch(setSelectedConversation(null))
    }
  }

  const handleArchiveClick = (row: ConversationResponse): void => {
    batch(() => {
      dispatch(setIsArchiveConversationModalVisible(true))
      dispatch(setSelectedConversation(row))
    })
  }

  const handleRenameClick = (row: ConversationResponse): void => {
    batch(() => {
      dispatch(setIsRenameConversationModalVisible(true))
      dispatch(setSelectedConversation(row))
    })
  }

  const handleRestoreClick = async (conversation: ConversationResponse): Promise<void> => {
    try {
      const updatedConversation = await restoreConversation({
        conversationId: conversation.id,
        patches: [{ op: 'replace', path: '/status', value: 'restore' }],
      }).unwrap()

      dispatch(
        conversationsApi.util.updateQueryData(
          'listConversations',
          undefined,
          (response: ConversationResponse[]) => {
            return response.map((m) => {
              return m.id === updatedConversation.id ? updatedConversation : m
            })
          },
        ),
      )
      toast({
        message: `${conversation.title} has been successfully restored.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    } catch (err: unknown) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  const getRowActions = (row: ConversationResponse): DataTableAction[] => [
    {
      onClick: () => handleRoute(`/admin/adjudication-management/conversations/${row.id}/preview`),
      tooltip: 'Preview',
      emphasis: 'low',
      icon: Visibility,
      'data-cy': `preview-${row.id}`,
    },
  ]

  const getRowMenuItems = (row: ConversationResponse): DataTableMenuItem[] => [
    ...(row.status === 'draft'
      ? [
          {
            onClick: () =>
              handleRoute(`/admin/adjudication-management/conversations/${row.id}/edit`),
            'data-cy': 'adjudication-management-conversation-list-edit-item',
            children: 'Edit',
          },
        ]
      : []),
    {
      onClick: () => handleRenameClick(row),
      'data-cy': 'adjudication-management-conversation-list-rename-item',
      children: 'Rename',
    },
    ...(row.status !== 'archived'
      ? [
          {
            onClick: () => handleArchiveClick(row),
            'data-cy': 'adjudication-management-conversation-list-archive-item',
            tooltip: 'Archive',
            children: 'Archive',
          },
        ]
      : []),
    ...(row.status === 'archived'
      ? [
          {
            onClick: () => handleRestoreClick(row),
            'data-cy': 'adjudication-management-conversation-list-restore-item',
            children: 'Restore',
          },
        ]
      : []),
  ]

  return (
    <>
      {isCreateConversationModalVisible && (
        <CreateConversationModal
          isVisible={isCreateConversationModalVisible}
          onCancel={handleToggleCreateConversation}
        />
      )}
      {isArchiveModalVisible && (
        <ArchiveConversationModal
          isVisible={isArchiveModalVisible}
          onCancel={handleToggleArchiveConversation}
          conversation={selectedConversationListItem}
        />
      )}
      {isRenameModalVisible && (
        <RenameConversationModal
          isVisible={isRenameModalVisible}
          onCancel={handleToggleRenameConversation}
          conversation={selectedConversationListItem}
        />
      )}
      <AdjudicationTabs />
      <TopRowContainer>
        <ConversationsMessage data-cy="adjudication-management-conversations-message">
          A conversation is the end-to-end experience customers will go through when they file
          claims via Kaley. The surfacing of the published conversations can be managed in the{' '}
          <ConfigurationsLink
            to="/admin/adjudication-management/conversation-configuration"
            data-cy="adjudication-management-configurations-link"
          >
            conversation configuration settings
          </ConfigurationsLink>
          .
        </ConversationsMessage>
      </TopRowContainer>
      {isUserAssumedEditorRole && (
        <TopRowButtonContainer>
          <ButtonContainer>
            <Button
              data-cy="adjudication-management-conversations-create-button"
              emphasis="medium"
              icon={Add}
              onClick={handleToggleCreateConversation}
              text="Create Conversation"
              tooltip="Create Conversation"
            />
          </ButtonContainer>
        </TopRowButtonContainer>
      )}
      <DataTable
        data-cy="adjudication-management-conversation-list"
        isLoading={isLoading || isFetching}
        data={conversationsData ?? []}
        columns={conversationColumns}
        hasConfigurableColumns={false}
        sorting={sorting}
        onSortingChange={setSorting}
        hasManualSorting={false}
        pagination={pagination}
        onPaginationChange={setPagination}
        hasManualPagination={false}
        filterDefs={filterDefs}
        columnFilters={columnFilters}
        onColumnFiltersChange={setColumnFilters}
        hasManualFiltering={false}
        getRowActions={getRowActions}
        getRowMenuItems={isUserAssumedEditorRole ? getRowMenuItems : undefined}
      />
    </>
  )
}

const TopRowContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 40,
})

const TopRowButtonContainer = styled(TopRowContainer)({
  marginBottom: 24,
  [bp.lg]: {
    justifyContent: 'flex-end',
    marginBottom: -40,
  },
})

const ConversationsMessage = styled.div({
  marginBottom: 28,
  fontSize: 16,
  lineHeight: '24px',
})

const ButtonContainer = styled.div({
  flexShrink: 0,
})

const ConfigurationsLink = styled(Link)({
  color: COLOR.BLUE[700],
})

export { AdjudicationConversationList }
