type ButtonRadiusMeasurements = 'px' | 'rem'
interface ThemeBase {
  name: string
  storeId: string
  contents: {
    global: {
      storeLogoUrl: string
      backgroundColor: string
      fontFamily: string
      color: string
      buttonBackgroundColor: string
      buttonFontColor: string
      buttonRadius: {
        size: number
        measurement: ButtonRadiusMeasurements
      }
    }
  }
  cssConfig?: {
    css?: string
    enabled?: boolean
  }
}

enum ThemePublishedStatus {
  published = 'PUBLISHED',
  unpublished = 'UNPUBLISHED',
}
interface Theme extends ThemeBase {
  themeId: string
  updatedAt: number
  createdAt: number
  status: ThemePublishedStatus
}

type ThemeMinimal = Pick<
  Theme,
  'storeId' | 'status' | 'themeId' | 'name' | 'createdAt' | 'updatedAt'
>
type ThemesGetAllResponse = {
  themes: ThemeMinimal[]
}
type ThemeByIdArgs = {
  storeId: string
  themeId: string
}

type RequiredThemeUpdateFields = Pick<Theme, 'storeId' | 'themeId' | 'name'>
type OptionalThemeUpdateFields = Partial<Pick<Theme, 'cssConfig' | 'contents'>>
type ThemeUpdateRequest = RequiredThemeUpdateFields & OptionalThemeUpdateFields

interface UseLazyGetThemeQueryState {
  themeQueryData: Theme | undefined
  isFetching?: boolean
  isLoading?: boolean
  isError?: boolean
}

interface UploadThemeLogoRequest {
  base64image: string
}

export type {
  Theme,
  ThemeMinimal,
  ThemeByIdArgs,
  ThemesGetAllResponse,
  UseLazyGetThemeQueryState,
  ThemeBase,
  ThemeUpdateRequest,
  ButtonRadiusMeasurements,
  UploadThemeLogoRequest,
}
export { ThemePublishedStatus }
