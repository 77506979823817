import { Button, Add as AddIcon } from '@extend/zen'
import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { PageHeader } from '../../../components/page-header'
import { UserManagementDataTable } from './user-management-data-table'
import { CreateExtendUserModal } from './create-user/create-extend-user-modal'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'

const UserManagement: FC = () => {
  const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false)
  const { hasPermission } = usePermissions()

  const closeModal = useCallback(() => {
    setShowCreateUserModal(false)
  }, [])

  return (
    <>
      <HeaderContainer>
        <PageHeader title="User Management" />
        {hasPermission(Permission.ManageExtendUsers) && (
          <Button
            text="Invite User"
            emphasis="medium"
            onClick={() => setShowCreateUserModal(true)}
            data-cy="create-account-button"
            icon={AddIcon}
          />
        )}
      </HeaderContainer>
      <UserManagementDataTable />
      <CreateExtendUserModal visible={showCreateUserModal} toggleOff={closeModal} />
    </>
  )
}

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export { UserManagement }
