import type { ComponentClass, FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import type { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { ProtectedRoute as ExtendAuthProtectedRoute } from '@extend/package-okta-login'
import { usePermissions } from '../hooks/use-permissions'
import type { Permission } from '../lib/permissions'

interface ProtectedRouteProps {
  path: string
  exact?: boolean
  requiredPermissions?: Permission[]
  component: React.FC<RouteComponentProps> | ComponentClass
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  path,
  exact,
  requiredPermissions = [],
  component: PropsComponent,
}) => {
  const { hasPermission } = usePermissions()
  const history = useHistory()

  const isAllowed = useMemo(() => {
    return requiredPermissions.every((permission) => hasPermission(permission))
  }, [hasPermission, requiredPermissions])

  useEffect(() => {
    if (!isAllowed) {
      history.push('/')
    }
  }, [history, isAllowed])

  return <ExtendAuthProtectedRoute path={path} exact={exact} component={PropsComponent} />
}

export { ProtectedRoute }
