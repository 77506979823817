import styled from '@emotion/styled'
import { COLOR, HeadingSmall } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'

const MSAAmendmentLog: FC = () => {
  return (
    <HeaderContainer>
      <HeadingSmall>Amendment Log</HeadingSmall>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div({
  border: `1px solid ${COLOR.NEUTRAL}`,
})

export { MSAAmendmentLog }
