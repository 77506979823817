import type { ChangeEvent, FC, RefObject } from 'react'
import React, { useReducer, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Button, COLOR } from '@extend/zen'
import { plansActions } from '@helloextend/core-api-redux'
import { PlansImportModal } from '../../../components/plans-import-modal'
import { usePerformImport } from './hooks/use-perform-import'
import { usePerformValidation } from './hooks/use-perform-validation'
import type { PlansBatchResult } from './actions/import'
import importReducer, { initialState } from './reducers/plans-import'
import { actions } from './actions'

const PlansImport: FC = () => {
  const sagaDispatch = useDispatch()
  const [state, dispatch] = useReducer(importReducer, initialState)
  const { performImport } = usePerformImport()
  const { performValidation } = usePerformValidation()

  const plansFileInputRef: RefObject<HTMLInputElement> = React.createRef()
  const fileBrowseClick = (): void => {
    dispatch(actions.clearMessages())
    plansFileInputRef.current?.click()
  }

  const handleFileSelected = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (e.target.files) {
      dispatch(actions.beginValidation())
      const files = [...e.target.files]
      e.target.value = ''
      const result = await performValidation(files)
      if (result.errorMessage) {
        // display error message and leave
        dispatch(actions.batchError(result.errorMessage))
        return
      }

      if (result.newPlans.length > 0 || result.existingPlans.length > 0) {
        // begin import flow
        dispatch(actions.beginImport(result))
        return
      }

      // no valid files after parse show result
      const results: PlansBatchResult = {
        success: [],
        failure: result.failedPlans.map((el) => {
          return { name: el, message: 'Could not parse file.' }
        }),
        skipped: [],
      }
      dispatch(actions.importComplete(results))
    }
  }

  useEffect(() => {
    const runImport = async (): Promise<void> => {
      if (
        state.isLoading &&
        state.uploadReadyPlans.length + state.skippedPlans.length > 0 &&
        state.modalPlans.length === 0 &&
        state.uploadResult.success.length === 0
      ) {
        // we are at the end of the batch, perform import
        const batchResult = await performImport(
          state.failedPlans,
          state.skippedPlans,
          state.uploadReadyPlans,
        )
        dispatch(actions.importComplete(batchResult))
        sagaDispatch(plansActions.reset())
      }
    }
    runImport()
  }, [state, dispatch, sagaDispatch, performImport])

  const handleSkip = (): void => {
    // add this to skipped, then move to next file in batch
    dispatch(actions.skip())
  }

  const handleCancel = (): void => {
    // clear everything and start over
    dispatch(actions.cancel())
  }

  const handleConfirm = (): void => {
    // add this to ready files, move to next file
    dispatch(actions.confirm())
  }
  const handleConfirmAll = (): void => {
    // set the rest of the items to ready, move to end of batch
    dispatch(actions.confirmAll())
  }

  return (
    <>
      <Header>
        <Title>Plan Import</Title>
      </Header>
      <Button isProcessing={state.isLoading} text="Upload Plan Files" onClick={fileBrowseClick} />
      <HiddenInput
        id="plansFileInput"
        ref={plansFileInputRef}
        type="file"
        accept=".json"
        onChange={handleFileSelected}
        multiple
      />
      {state.errorMessage && (
        <>
          <FailedResult>
            <UploadTitle>Could not upload batch</UploadTitle>
            {state.errorMessage}
          </FailedResult>
        </>
      )}
      {state.uploadResult.success.length > 0 && (
        <>
          <SuccessResult>
            <UploadTitle data-cy="success-msg">Plan created successfully</UploadTitle>
            {state.uploadResult.success.map((worked) => (
              <p key={worked.plan.id} data-cy="success-msg-content">
                <ResultSubtitle>Filename:</ResultSubtitle>
                {`${worked.name},`}
                <ResultSubtitle>Plan Id:</ResultSubtitle>
                {`${worked.plan.id},`}
                <ResultSubtitle>Version:</ResultSubtitle>
                {worked.plan.version}
              </p>
            ))}
          </SuccessResult>
        </>
      )}
      {state.uploadResult.failure.length > 0 && (
        <>
          <FailedResult>
            <UploadTitle data-cy="failed-msg">Plan creation failed</UploadTitle>
            {state.uploadResult.failure.map((failed) => (
              <p key={failed.name} data-cy="failed-msg-content">
                <ResultSubtitle>Filename:</ResultSubtitle>
                {`${failed.name},`}
                <ResultSubtitle>Message:</ResultSubtitle>
                {failed.message}
              </p>
            ))}
          </FailedResult>
        </>
      )}
      {state.uploadResult.skipped.length > 0 && (
        <>
          <InfoResult>
            <UploadTitle>Files Skipped</UploadTitle>
            {state.uploadResult.skipped.map((skipped) => (
              <p key={skipped}>
                <ResultSubtitle>Name:</ResultSubtitle>
                {skipped}
              </p>
            ))}
          </InfoResult>
        </>
      )}
      <PlansImportModal
        isVisible={state.modalPlans.length > 0}
        handleSkip={handleSkip}
        handleCancel={handleCancel}
        handleConfirmAll={handleConfirmAll}
        handleConfirm={handleConfirm}
        filename={state.modalPlans[0]?.file.name ?? ''}
        planId={state.modalPlans[0]?.plan.id ?? ''}
        planVersion={state.modalPlans[0]?.version ?? ''}
        currentIndex={state.totalModalPlans - state.modalPlans.length + 1}
        totalModals={state.totalModalPlans}
      />
    </>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const SuccessResult = styled.div({
  color: COLOR.GREEN[800],
})
const FailedResult = styled.div({
  color: COLOR.RED[600],
})
const InfoResult = styled.div({
  color: COLOR.BLUE[1000],
})

const Title = styled.h1({
  fontWeight: 700,
  fontSize: 32,
  lineHeight: '44px',
  marginTop: 0,
  color: COLOR.BLUE[1000],
})

const UploadTitle = styled.h1({
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '32px',
})

const ResultSubtitle = styled.strong({
  paddingLeft: 2,
  paddingRight: 2,
})

const HiddenInput = styled.input({
  display: 'none',
})

export { PlansImport }
