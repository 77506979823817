import styled from '@emotion/styled'
import { useGetAISRecordQuery } from '@helloextend/extend-api-rtk-query'
import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { COLOR, Dots, Grid, Input } from '@extend/zen'
import { SectionContainer } from '../../../../../../../components/section-container'

const AisRecordDetails: FC = () => {
  const { storeId, referenceId } = useParams<{ storeId: string; referenceId: string }>()
  const { data: record, isLoading: isLoadingRecord } = useGetAISRecordQuery({
    storeId,
    referenceId,
    version: 'latest',
  })

  const isLoading = isLoadingRecord
  if (isLoading) {
    return (
      <SpinnerContainer>
        <Dots color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  if (!record) {
    return null
  }

  return (
    <>
      <AisHeaderContainer></AisHeaderContainer>
      <SectionContainer title="AIS Record Info">
        <Grid>
          {Object.keys(record).map((key) => (
            <Input
              key={key}
              id={key}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              value={typeof record[key] === 'object' ? JSON.stringify(record[key]) : record[key]}
            />
          ))}
        </Grid>
      </SectionContainer>
    </>
  )
}

const AisHeaderContainer = styled.div({
  marginBottom: 24,
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { AisRecordDetails }
