import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useListInsuranceProgramVersionsQuery } from '@helloextend/extend-api-rtk-query'
import { DataReactTable } from '@helloextend/merchants-ui'
import { Spinner } from '@extend/zen'
import { insuranceProgramVersionColumns } from './table-config'

type InsuranceProgramVersionsProps = {
  insuranceProgramId: string
}

const InsuranceProgramVersions: FC<InsuranceProgramVersionsProps> = ({ insuranceProgramId }) => {
  const { versions, isLoading } = useListInsuranceProgramVersionsQuery(insuranceProgramId, {
    selectFromResult: ({ data, ...rest }) => ({
      versions: data?.versions?.slice(0, -1),
      ...rest,
    }),
  })

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  if (!versions?.length) {
    return null
  }
  return (
    <DataReactTable
      isLoading={isLoading}
      type="versions"
      data={versions}
      columns={insuranceProgramVersionColumns}
      initialState={{
        sortBy: [{ id: 'version', desc: true }],
        pageSize: 10,
      }}
    />
  )
}

const SpinnerContainer = styled.div({
  textAlign: 'center',
})

export { InsuranceProgramVersions }
