import { Button, Add, useToaster, ToastColor, ToastDuration } from '@extend/zen'
import type { FC } from 'react'
import React, { useRef, useCallback, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useGetUsersListWithGrantsQuery } from '@helloextend/extend-api-rtk-query'
import { useParams } from 'react-router-dom'
import { UsersDataTable } from './users-list/users-data-table'
import CreateUserModal from '../components/create-user-modal/create-user-modal'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'

const Users: FC = () => {
  const { hasPermission } = usePermissions()

  const { toast, dismiss } = useToaster()
  const { accountId } = useParams<{ accountId: string }>()
  const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false)
  const {
    data: userData,
    isLoading,
    isError,
  } = useGetUsersListWithGrantsQuery({ account: accountId, internal: false })

  const loadingToastRef = useRef<number>(0)

  const closeModal = useCallback(() => {
    setShowCreateUserModal(false)
  }, [])

  useEffect(() => {
    if (userData?.length) {
      dismiss(loadingToastRef.current)
      return
    }

    if (isError) {
      dismiss(loadingToastRef.current)

      toast({
        message: 'Unable to retrieve user data',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      return
    }
    if (isLoading) {
      loadingToastRef.current = toast({
        message: 'Retrieving results... This might take a few moments',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    }
  }, [dismiss, isError, isLoading, toast, userData])

  return (
    <>
      <HeaderContainer>
        {hasPermission(Permission.ManageMerchantUsers) ? (
          <>
            {' '}
            <Button
              text="Invite User"
              data-cy="invite-user-button"
              icon={Add}
              emphasis="medium"
              onClick={() => setShowCreateUserModal(true)}
            />
          </>
        ) : null}
      </HeaderContainer>{' '}
      <UsersDataTable accountId={accountId} userData={userData || []} isLoading={isLoading} />
      <CreateUserModal visible={showCreateUserModal} toggleOff={closeModal} />
    </>
  )
}

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export { Users }
