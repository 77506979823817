import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { LDFlag } from '../../../constants/ld-flags'
import { NewClaims } from './new-claims'
import { OldClaims } from './old-claims'

export const Claims = () => {
  const { [LDFlag.NewClaimsPage]: FF_NEW_CLAIMS_PAGE } = useFlags()
  return FF_NEW_CLAIMS_PAGE ? <NewClaims /> : <OldClaims />
}
