import type { AdvancedSelectOption, AdvancedSelectOptionGroup } from '@extend/zen'

const generateAdvancedSelectOption = (value: string): AdvancedSelectOption => ({
  display: value,
  value,
})

const generateAdvancedSelectOptions = (values: string[]): AdvancedSelectOption[] =>
  values.map(generateAdvancedSelectOption)

const generateAdvancedSelectOptionGroup = (
  label: string,
  values: string[],
): AdvancedSelectOptionGroup => ({
  label,
  options: generateAdvancedSelectOptions(values),
})

export const reportingValueMap: {
  [key: string]: Array<AdvancedSelectOption | AdvancedSelectOptionGroup>
} = {
  AdjudicationServiceType: generateAdvancedSelectOptions(['repair', 'service']),
  AdjudicationType: generateAdvancedSelectOptions(['accident', 'random_failure']),
  EndClaim: generateAdvancedSelectOptions([
    'END_VALID_CLAIM',
    'END_INVALID_CLAIM',
    'END_TRACK_CLAIM',
    'END_APPROVED_CLAIM',
    'END_INVALID_ADH_CLAIM',
  ]),
  FailureLocation: generateAdvancedSelectOptions(['in_transit', 'on_site', 'other']),
  FailureType: [
    generateAdvancedSelectOptionGroup('Electrical', [
      'connection_issue',
      'failure_battery_charging',
      'failure_electrical',
      'overheat',
    ]),
    generateAdvancedSelectOptionGroup('Jewelry', ['loose_lost_stone', 'stretch_necklace']),
    generateAdvancedSelectOptionGroup('Lost/Stolen', ['lost_part', 'lost_stolen']),
    generateAdvancedSelectOptionGroup('Mechanical', [
      'failure_burning_mechanism',
      'failure_mechanical',
    ]),
    generateAdvancedSelectOptionGroup('Other', [
      'base',
      'cleaning_benefit',
      'crash_collision_drone',
      'damage_cosmetic',
      'failure_accessory',
      'failure_electrical_mechanical',
      'mold_mildew',
      'noise_squeak',
      'nos',
      'odor',
      'other',
      'performance',
    ]),
    generateAdvancedSelectOptionGroup('Service', [
      'service_refinish_polish',
      'service_reset',
      'service_reshank',
      'service_rhodium_plate',
      'service_ring_resize',
    ]),
    generateAdvancedSelectOptionGroup('Shipping', [
      'shipment_damaged',
      'shipment_damaged_partial',
      'shipment_lost',
      'shipment_other',
      'shipment_stolen',
      'shipment_wrong',
    ]),
    generateAdvancedSelectOptionGroup('Stain', ['stain']),
    generateAdvancedSelectOptionGroup('Structural', [
      'bent_broken_part',
      'break_failure_weld',
      'loss_foam',
      'scrape_scratch_break',
      'stretch',
    ]),
    generateAdvancedSelectOptionGroup('Surface', [
      'abrasion_fray_pill',
      'buckle_warp_ripple_bubble',
      'burn_heat_mark',
      'change_fade_loss_color',
      'chip_crack',
      'cosmetic_embellishment',
      'crack_subsurface',
      'cut_puncture_rip_tear',
      'damage_fringe',
      'delamination_peeling',
      'joint_gap',
      'loss_silvering',
      'separation_seam',
      'transition_issue',
    ]),
    generateAdvancedSelectOptionGroup('Vehicle', [
      'bent_wheel',
      'blowout_tire',
      'bubble_tire',
      'cracked_wheel',
      'curb_rash',
      'puncture_side_wall',
      'puncture_tread',
    ]),
  ],
  FailureCause: [
    generateAdvancedSelectOptionGroup('Accident', [
      'accident',
      'collision_motor_vehicle_fixed_object',
      'contact_with_curb',
      'road_hazard',
      'user_error',
    ]),
    generateAdvancedSelectOptionGroup('Burn', [
      'burn_candle_open_flame',
      'burn_chemical',
      'burn_fire_flame',
      'burn_hot_surface',
      'burn_house_fire',
    ]),
    generateAdvancedSelectOptionGroup('Mishandling', [
      'incorrect_assembly',
      'incorrect_installation',
      'incorrect_unboxing',
      'arrived_damaged',
    ]),
    generateAdvancedSelectOptionGroup('Other', [
      'animal',
      'moisture_exposure',
      'nos',
      'other',
      'part_failure',
      'power_surge',
      'random_stopped_working_properly',
      'unknown_circumstance',
      'water_liquid',
      'wear_tear',
      'weather',
    ]),
    generateAdvancedSelectOptionGroup('Shipping', ['other', 'post_delivery', 'unboxing']),
    generateAdvancedSelectOptionGroup('Stain', [
      'stain_bleach_chlorine',
      'stain_ballpoint_ink',
      'stain_cosmetics_product',
      'stain_dampness_condensation_water',
      'stain_food_beverage',
      'stain_human_pet_bodily_fluid_waste',
      'stain_nail_polish',
      'stain_other',
      'stain_pen_marker',
      'stain_perspiration_oil',
      'stain_unknown',
    ]),
  ],
  JewelryType: generateAdvancedSelectOptions([
    'anklet',
    'body_piercing',
    'cufflinks',
    'earrings',
    'necklace',
    'other',
    'pendant',
    'ring',
    'tie_pin',
  ]),
  MissingStone: generateAdvancedSelectOptions(['no_missing_stone', 'missing_stone']),
  ProductSection: generateAdvancedSelectOptions([
    'adjustable_bed_base',
    'area_rug',
    'fabric_area_cushion_set',
    'fabric_vinyl_leather',
    'indoor_hard_surface',
    'mattress',
    'outdoor_hard_surface',
    'propane_fire_pit',
    'umbrella',
  ]),
  SelfTroubleshoot: generateAdvancedSelectOptions(['resolved', 'continue', 'ignore']),
  StoneInSetting: generateAdvancedSelectOptions(['stone_not_in_setting', 'stone_in_setting']),
  TiresAndWheelsValidation: generateAdvancedSelectOptions([
    'greater_than_three_thirty_seconds_inch',
    'less_than_three_thirty_seconds_inch',
    'damage_cosmetic',
    'damage_not_cosmetic',
  ]),
}
