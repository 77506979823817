import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  Product,
  Subproduct,
  ProductsWarrantyMappingUpdateResponse,
  ProducstWarrantyMappingUpdateRequest,
} from '@helloextend/extend-api-client'
import { encodeURIComponentIfNotEncoded } from '@extend/client-helpers'
import type {
  GetProductRequest,
  UpdateProductRequest,
  CreateSubproductRequest,
  DeleteSubproductRequest,
  CreateBatchResponse,
  CreateBatchProductRequest,
  DeleteProductsBulkRequest,
  ProductImportRequest,
  ProductImportUrlRequest,
  ProductImportUrlResponse,
  ProductExportResponse,
} from './types'
import { baseQuery } from '../base-query'

export const productsApi = createApi({
  baseQuery,
  // TODO: MEXP-600 update these paths once we remove redux from the merchants portal  reducerPath: 'Products',
  reducerPath: 'Products',
  tagTypes: ['Products', 'subproduct'],
  endpoints: (build) => ({
    getProduct: build.query<Product, GetProductRequest>({
      query: ({ storeId, referenceId, version = '2020-08-01' }) => ({
        url: `/stores/${storeId}/products/${encodeURIComponentIfNotEncoded(referenceId)}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, { referenceId }) => [
        { type: 'Products', id: encodeURIComponentIfNotEncoded(referenceId) },
      ],
    }),
    getProducts: build.query<Product[], string>({
      query: (storeId: string) => ({
        url: `/stores/${storeId}/products`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=2020-08-01;',
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ referenceId }) =>
                  ({ type: 'Products', id: encodeURIComponentIfNotEncoded(referenceId) } as const),
              ),
              { type: 'Products', id: 'LIST' },
            ]
          : [{ type: 'Products', id: 'LIST' }],
    }),
    updateProduct: build.mutation<Product, UpdateProductRequest>({
      query: ({ storeId, productId, data, version = 'default' }: UpdateProductRequest) => ({
        url: `/stores/${storeId}/products/${encodeURIComponentIfNotEncoded(productId)}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (product) =>
        product
          ? [{ type: 'Products', id: encodeURIComponentIfNotEncoded(product.referenceId) }]
          : [],
    }),
    createSubproduct: build.mutation<Subproduct, CreateSubproductRequest>({
      query: ({ storeId, productId, subproductId, body }) => ({
        url: `/stores/${storeId}/products/${encodeURIComponentIfNotEncoded(
          productId,
        )}/product-mappings/${encodeURIComponentIfNotEncoded(subproductId)}`,
        method: 'POST',
        body,
      }),
      /**
       * Currently manually refetching on bundle management route to
       * sidestep existing product caching issues.
       *
       * Proper cache policy around subproducts will need to be handled
       * after Product caching is fixed.
       *
       * We ideally want to invalidate the subproduct and consequentially
       * the bundle it is a part of without invalidating non-related
       * bundles.
       */
      invalidatesTags: ['subproduct'],
    }),
    deleteSubproduct: build.mutation<Subproduct, DeleteSubproductRequest>({
      query: ({ storeId, productId, subproductId }) => ({
        url: `/stores/${storeId}/products/${encodeURIComponentIfNotEncoded(
          productId,
        )}/product-mappings/${encodeURIComponentIfNotEncoded(subproductId)}`,
        method: 'DELETE',
        // `responseHandler` is required because this DELETE endpoint
        // returns a string, and therefore
        // RTK-Query will parse it incorrectly
        responseHandler: (res) => res.text(),
      }),
      invalidatesTags: ['subproduct'],
    }),
    createProducts: build.mutation<CreateBatchResponse, CreateBatchProductRequest>({
      query: ({ storeId, data, version = 'default', isBatch }) => ({
        url: `/stores/${storeId}/products${isBatch ? '?batch=true' : ''}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) =>
        result?.updated
          ? [
              ...result.updated.map(
                ({ referenceId }) =>
                  ({ type: 'Products', id: encodeURIComponentIfNotEncoded(referenceId) } as const),
              ),
              { type: 'Products', id: 'LIST' },
            ]
          : [{ type: 'Products', id: 'LIST' }],
    }),
    deleteProductsBulk: build.mutation<string[], DeleteProductsBulkRequest>({
      async queryFn({ storeId, productIds }, _queryApi, _extraOptions, fetchWithBQ) {
        const plans = await Promise.allSettled(
          productIds.map(async (referenceId) => {
            const response = await fetchWithBQ({
              url: `/stores/${storeId}/products/${encodeURIComponentIfNotEncoded(referenceId)}`,
              headers: { accept: 'application/json; version=default;' },
              method: 'DELETE',
              responseHandler: (res) => res.text(),
            })
            if (response.error) {
              console.error(response.error)
            }

            return referenceId
          }),
        )
        const fulfilledProducts = plans
          .filter((result) => result.status === 'fulfilled')
          .map((result) => (result as PromiseFulfilledResult<string>).value)
        return { data: fulfilledProducts }
      },
      invalidatesTags: (result) =>
        result
          ? [
              ...result.map(
                (referenceId) =>
                  ({ type: 'Products', id: encodeURIComponentIfNotEncoded(referenceId) } as const),
              ),
              { type: 'Products', id: 'LIST' },
            ]
          : [{ type: 'Products', id: 'LIST' }],
    }),
    getProductsImportUrl: build.mutation<ProductImportUrlResponse, ProductImportUrlRequest>({
      query: ({ fileName, storeId }) => ({
        url: `/stores/${storeId}/products/import?originalFilename=${fileName}`,
        headers: { accept: 'application/json; version=default;' },
        method: 'POST',
      }),
    }),
    getProductCategoriesImportUrl: build.mutation<
      ProductImportUrlResponse,
      ProductImportUrlRequest
    >({
      query: ({ fileName, storeId }) => ({
        url: `/stores/${storeId}/categories/import?originalFilename=${fileName}`,
        headers: { accept: 'application/json; version=default;' },
        method: 'POST',
      }),
    }),
    importProducts: build.mutation<string, ProductImportRequest>({
      query: ({ url, fileArrayBuffer }) => {
        return { url, method: 'PUT', body: fileArrayBuffer }
      },
      invalidatesTags: ['Products', 'subproduct'],
    }),
    exportProducts: build.mutation<ProductExportResponse, string>({
      query: (storeId) => ({
        url: `/stores/${storeId}/products/export`,
        headers: { accept: 'application/json; version=default;' },
        method: 'POST',
      }),
    }),
    productWarrantyMapping: build.mutation<
      ProductsWarrantyMappingUpdateResponse,
      ProducstWarrantyMappingUpdateRequest
    >({
      query: ({ storeId, data }) => {
        return {
          url: `/stores/${storeId}/products/warranty-mapping`,
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest;',
          },
          body: data,
        }
      },
      invalidatesTags: ['Products'],
    }),
  }),
})

export const {
  useGetProductQuery,
  useLazyGetProductQuery,
  useGetProductsQuery,
  useUpdateProductMutation,
  useCreateSubproductMutation,
  useDeleteSubproductMutation,
  useCreateProductsMutation,
  useDeleteProductsBulkMutation,
  useGetProductsImportUrlMutation,
  useGetProductCategoriesImportUrlMutation,
  useImportProductsMutation,
  useExportProductsMutation,
  useProductWarrantyMappingMutation,
} = productsApi
