import type { FC} from 'react';
import React, { useState } from 'react'
import { RateCardForm } from './rate-card-form'
import { Stack, Button, Add, Loading } from '@extend/zen'
import { useParams } from 'react-router-dom'
import {
  useGetRateCardsForServicerQuery,
  useGetServicerQuery,
} from '@helloextend/extend-api-rtk-query'
import { RateCardDisplay } from './rate-card-display'

export const RateCardTab: FC = () => {
  const { servicerId } = useParams<{ servicerId: string }>()
  const [isFormVisible, setIsFormVisible] = useState(false)
  const { data: servicer } = useGetServicerQuery(servicerId)
  const { data: rateCards } = useGetRateCardsForServicerQuery(servicerId)
  if (!servicer) return <Loading />

  const handleAddRateCardClick = () => {
    setIsFormVisible(true)
  }

  const handleFormClose = () => {
    setIsFormVisible(false)
  }

  return (
    <Stack data-cy="rate-card-tab" spacing={3}>
      {rateCards?.map((rateCard, index) => (
        <RateCardDisplay rateCard={rateCard} index={index} />
      ))}
      {isFormVisible && (
        <RateCardForm servicer={servicer} index={0} onCancelOrSave={handleFormClose} />
      )}
      {!isFormVisible && (
        <div>
          <Button
            text="Add Rate Card"
            emphasis="medium"
            icon={Add}
            onClick={handleAddRateCardClick}
            data-cy="add-rate-card-button"
          />
        </div>
      )}
    </Stack>
  )
}
