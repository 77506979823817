import type { ReducerState } from './reducers'
import { isValidToken } from '../util/jwt'
import type { ErrorReducerState } from '../types/error'

type Selector<T> = (state: ReducerState) => T

export const getAccessToken: Selector<string | null> = (state) => state.accessToken
export const getError: Selector<ErrorReducerState> = (state) => state.error
export const getIsLoading: Selector<boolean> = (state) => state.isLoading
export const getIsLoggedIn: Selector<boolean> = (state) => {
  return isValidToken(getAccessToken(state), false)
}

export const getChangePasswordMessage: Selector<string | null> = (state: ReducerState) =>
  state.changePasswordMessage || null
