import { useMemo } from 'react'
import type {
  ConversationConfigurationResponse,
  ConversationResponse,
} from '@helloextend/extend-api-rtk-query'
import {
  useListConversationsQuery,
  useListConfigurationsQuery,
} from '@helloextend/extend-api-rtk-query'
import type { ConversationConfigurationResponseTableRow } from '../pages/admin/adjudication-management/conversation-configuration/table-config'
import { mapConfigurationsTotableData } from '../pages/admin/adjudication-management/conversation-configuration/table-config'

export const useListConversationConfigs = (): {
  isFetching: boolean
  hasConfigurations: boolean
  tableData: ConversationConfigurationResponseTableRow[]
} => {
  const { data, isFetching: isFetchingConfigs } = useListConfigurationsQuery()
  const { data: conversations, isFetching: isFetchingConversations } = useListConversationsQuery()

  const isFetching = isFetchingConfigs || isFetchingConversations

  const hasConfigurations = Boolean((data && data.length !== 0) || isFetching)

  const tableData = useMemo(() => {
    if (!data || !conversations || isFetching) return []
    const conversationMap = conversations
      ? conversations.reduce((acc, conversation) => {
          acc[conversation.id] = conversation
          return acc
        }, {} as Record<string, ConversationResponse>)
      : {}
    const configs: Array<ConversationConfigurationResponse & { conversationName?: string }> =
      data.map((configuration) => ({
        ...configuration,
        conversationName: conversationMap[configuration.conversationId]
          ? conversationMap[configuration.conversationId].title
          : '',
      }))
    return mapConfigurationsTotableData(configs)
  }, [data, conversations, isFetching])

  return { isFetching, hasConfigurations, tableData }
}
