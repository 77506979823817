import type { UserGrant } from '@helloextend/extend-api-rtk-query'
import { isERNBoundToAllAccounts, parseTenancyErn } from '../lib/tenancy-ern'

export const legacyMerchantRoles = Object.freeze({
  planmanageradmin: {
    value: 'planmanageradmin',
    display: 'Plan Manager Admin',
  },
  user: {
    value: 'user',
    display: 'User',
  },
  merchantagent: {
    value: 'merchantagent',
    display: 'Merchant Agent',
  },
  agent: {
    value: 'agent',
    display: 'Agent',
  },
  storeadmin: {
    value: 'storeadmin',
    display: 'Store Admin',
  },
  salesassociate: {
    value: 'salesassociate',
    display: 'Sales Associate',
  },
})

export const legacyInternalRoles = Object.freeze({
  superadmin: {
    value: 'superadmin',
    display: 'Super Admin',
  },
  admin: {
    value: 'admin',
    display: 'Admin',
  },
  conversationseditor: {
    value: 'conversationseditor',
    display: 'Conversations Editor',
  },
  conversationsviewer: {
    value: 'conversationsviewer',
    display: 'Conversations Viewer',
  },
})

type LegacyMerchantRoles = keyof typeof legacyMerchantRoles
type LegacyInternalRoles = keyof typeof legacyInternalRoles
type LegacyRoles = LegacyMerchantRoles | LegacyInternalRoles

export const enterpriseMerchantRoles = Object.freeze({
  MerchantPortalAdmin: {
    value: 'MerchantPortalAdmin',
    display: 'Admin',
  },
  MerchantApiManager: {
    value: 'MerchantApiManager',
    display: 'Merchant API Manager',
  },
  MerchantClaimsAgent: {
    value: 'MerchantClaimsAgent',
    display: 'Claims Manager',
  },
  MerchantTransactionManager: {
    value: 'MerchantTransactionManager',
    display: 'Transaction Manager',
  },
  StoreAssociate: {
    value: 'StoreAssociate',
    display: 'Store Associate',
  },
})

export const enterpriseInternalRoles = Object.freeze({
  ExtendPortalAdmin: {
    value: 'ExtendPortalAdmin',
    display: 'Extend Portal Admin',
  },
  ProvisioningManager: {
    value: 'ProvisioningManager',
    display: 'Provisioning Manager',
  },
  MerchantSuccess: {
    value: 'MerchantSuccess',
    display: 'Merchant Success',
  },
  SolutionEngineer: {
    value: 'SolutionEngineer',
    display: 'Solution Engineer',
  },
  CustomerSupportAgent: {
    value: 'CustomerSupportAgent',
    display: 'Customer Support Agent',
  },
  WarrantyMappingSpecialist: {
    value: 'WarrantyMappingSpecialist',
    display: 'Warranty Mapping Specialist',
  },
  WarrantyMappingManager: {
    value: 'WarrantyMappingManager',
    display: 'Warranty Mapping Manager',
  },
  PlanManager: {
    value: 'PlanManager',
    display: 'Plan Manager',
  },
  PlanAnalyst: {
    value: 'PlanAnalyst',
    display: 'Plan Analyst',
  },
  FinanceAnalyst: {
    value: 'FinanceAnalyst',
    display: 'Finance Analyst',
  },
  conversationsadmin: {
    value: 'conversationsadmin',
    display: 'Conversations Admin',
  },
  Engineer: {
    value: 'Engineer',
    display: 'Engineer',
  },
  PlatformAppsEngineer: {
    value: 'PlatformAppsEngineer',
    display: 'Platform Apps Engineer',
  },
  ComplianceManager: {
    value: 'ComplianceManager',
    display: 'Compliance Manager',
  },
  DemoCSR: {
    value: 'DemoCSR',
    display: 'Demo CSR',
  },
})

type EnterpriseMerchantRoles = keyof typeof enterpriseMerchantRoles
type EnterpriseInternalRoles = keyof typeof enterpriseInternalRoles
type EnterpriseRoles = EnterpriseMerchantRoles | EnterpriseInternalRoles

export type UserRole = LegacyRoles | EnterpriseRoles

export const userRoles = {
  ...legacyMerchantRoles,
  ...legacyInternalRoles,
  ...enterpriseMerchantRoles,
  ...enterpriseInternalRoles,
}

export const mapUserType = (userType: UserRole): string => {
  return userRoles[userType].display
}

export const getUserRolesFromGrants = ({
  grants,
  roleFilter,
  includeEnterpriseRoles = false,
  accountId,
}: {
  grants: UserGrant[]
  roleFilter: 'internal' | 'merchant'
  includeEnterpriseRoles?: boolean
  accountId?: string
}): Array<{ role: UserRole; display: string }> => {
  const rolesFromGrants = filterGrantsByAccount(grants, accountId).filter((it) => {
    return roleFilter === 'merchant'
      ? isMerchantRole(it.role as UserRole)
      : isInternalRole(it.role as UserRole, includeEnterpriseRoles)
  })

  return mapGrantsToUserRoles(rolesFromGrants)
}

export const mapGrantsToUserRoles = (
  grants: UserGrant[],
): Array<{ role: UserRole; display: string }> => {
  return grants.map((grant) => {
    const role = grant.role as UserRole
    return { role, display: mapUserType(role) }
  })
}

export const filterGrantsByAccount = (grants: UserGrant[], accountId?: string): UserGrant[] => {
  return grants.filter((grant) => {
    if (accountId) {
      return parseTenancyErn(grant.ern)?.accountResourceId === accountId
    }

    return isERNBoundToAllAccounts(grant.ern)
  })
}

export const isMerchantRole = (role: string): boolean => {
  return Object.keys({ ...legacyMerchantRoles, ...enterpriseMerchantRoles }).includes(role)
}

export const isLegacyInternalRole = (role: string): boolean => {
  return Object.keys(legacyInternalRoles).includes(role)
}

export const isInternalRole = (role: string, includeEnterpriseRoles: boolean): boolean => {
  const internalRoleMapping = includeEnterpriseRoles
    ? {
        ...enterpriseInternalRoles,
        ...legacyInternalRoles,
      }
    : legacyInternalRoles
  return Object.keys(internalRoleMapping).includes(role)
}
