import { useEffect, useState, useCallback } from 'react'
import { flatten } from 'lodash/fp'
import { useLazyListServiceOrderShipmentsQuery } from '@helloextend/extend-api-rtk-query'

type ReturnType = {
  data: string[]
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  error: any
}

/**
 * Concurrently fetches service order shipment IDs for all service order IDs provided.
 *
 * @param serviceOrderIds serviceOrderIds
 * @returns serviceOrderShipmentIds under 'data' subfield
 */
export const useServiceOrdersShipments = (serviceOrderIds: string[]): ReturnType => {
  const [getServiceOrderShipments] = useLazyListServiceOrderShipmentsQuery()
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<any>(null)
  const [serviceOrderShipmentIds, setServiceOrderShipmentIds] = useState<string[]>([])

  const fetchData = useCallback(async (): Promise<void> => {
    setIsLoading(true)

    try {
      const results: string[] = flatten(
        await Promise.all(
          serviceOrderIds.map(async (serviceOrderId) => {
            const response = await getServiceOrderShipments(serviceOrderId)
            return response.data || []
          }),
        ),
      )
        .filter((shipment) => shipment.status !== 'failed')
        .map((shipment) => shipment.id)

      setServiceOrderShipmentIds(results)
      setIsSuccess(true)
    } catch (err: unknown) {
      setError(err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [serviceOrderIds, getServiceOrderShipments])

  useEffect(() => {
    if (isLoading || isError || isSuccess) return
    fetchData()
  }, [fetchData, isLoading, isError, isSuccess])

  return { data: serviceOrderShipmentIds, isLoading, isSuccess, isError, error }
}
