import {
  AccordionSection,
  Box,
  DataProperty,
  Button,
  ModalController,
  Modal,
  COLOR,
  Spinner,
} from '@extend/zen'
import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useGetScopesAndPermissionsQuery } from '@helloextend/extend-api-rtk-query'

export const PERMISSIONS_FETCH_ERROR_MESSAGE =
  'There was an error fetching Permissions. Please try again later.'
export const Permissions: FC = () => {
  const [scopeListModalDetails, setScopeListModalDetails] = useState({
    isOpen: false,
    content: [] as JSX.Element[],
    heading: '',
  })
  const [isExpanded, setIsExpanded] = useState(false)
  const { data, isLoading, isError } = useGetScopesAndPermissionsQuery()

  return (
    <Box>
      <AdditionalPadding>
        <AccordionSection
          heading="Permissions & Scope Collections Details"
          isExpanded={isExpanded || isError}
          onToggleRequest={() => setIsExpanded(!isExpanded)}
          toolbar={isLoading && <Spinner />}
          isToggleDisabled={isError}
        >
          {isError ? (
            <p>{PERMISSIONS_FETCH_ERROR_MESSAGE}</p>
          ) : (
            <Items>
              {Object.entries(data?.scopeCollections || {}).map(([key, value]) => {
                const hasScopes = value.scopes.length
                const actorType = value.actorType.join(', ')
                return (
                  <Item key={value.name}>
                    <DataProperty label="Permission Name" value={value.longDescription} />
                    <DataProperty label="Actor Type" value={actorType} />
                    <DataProperty label="Permission Code Name" value={key} />
                    <Button
                      emphasis="medium"
                      size="small"
                      text={hasScopes ? 'View Scopes' : 'No Scopes'}
                      onClick={() =>
                        setScopeListModalDetails({
                          isOpen: true,
                          content: value.scopes.map((scope) => <li key={scope}>{scope}</li>),
                          heading: value.longDescription,
                        })
                      }
                      isDisabled={!hasScopes}
                    />
                  </Item>
                )
              })}
              <ModalController isOpen={scopeListModalDetails.isOpen}>
                <Modal
                  onDismissRequest={() =>
                    setScopeListModalDetails({ isOpen: false, content: [], heading: '' })
                  }
                  heading={scopeListModalDetails.heading}
                >
                  <ul>{scopeListModalDetails.content}</ul>
                </Modal>
              </ModalController>
            </Items>
          )}
        </AccordionSection>
      </AdditionalPadding>
    </Box>
  )
}

const Item = styled.div({
  display: 'grid',
  gridTemplateColumns: '35% 15% 25% 25%',
  borderBottom: `1px solid ${COLOR.NEUTRAL['300']}`,
  alignItems: 'center',
  paddingBottom: '16px',
  div: {
    margin: '0',
    paddingRight: '15px',
  },
  button: {
    width: 'fit-content',
    alignSelf: 'unset',
  },
  '&:last-of-type': {
    borderBottom: 'none',
  },
})

const Items = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
})

const AdditionalPadding = styled.div({
  padding: '20px 0',
})
