import React, { memo, useMemo } from 'react'
import { DataTable } from '@extend/zen'

import type { AuditLogTableProps } from './helpers'
import { auditLogColumns, buildAuditLogTableFields } from './helpers'
import NestedAuditDelta from './nested-audit-delta'

export const AuditLogTable = memo(
  <T,>({ auditLog, resourceType, isLoading }: AuditLogTableProps<T>) => {
    const data = useMemo(() => buildAuditLogTableFields(auditLog), [auditLog])

    return (
      <DataTable
        data-cy={`audit-log-table-component-${resourceType || 'default'}`}
        isLoading={!!isLoading}
        data={data}
        columns={auditLogColumns}
        hasConfigurableColumns
        hasManualSorting
        hasManualFiltering
        rowDetailRenderer={(fields) => (
          <NestedAuditDelta fields={fields} resourceType={resourceType} />
        )}
      />
    )
  },
)
