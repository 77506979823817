import styled from '@emotion/styled'
import { number as numberHelper } from '@extend/client-helpers'
import type { Store, StoreShippingProtection } from '@helloextend/extend-api-client'
import { Button, ButtonGroup, Grid } from '@extend/zen'
import { useFormik } from 'formik'
import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import { LocationFilteringSelect } from '../common/location-filtering-select'
import { RevenueShareInput } from '../common/revenue-share-input'
import type { Values } from './schema'
import { getPlanIdDefault, getLocationFilteringDefault, schema } from './schema'
import { ShippingProtectionPlansDropdown } from '../shipping-protection-purchase-model/shipping-protection-plans-dropdown/shipping-protection-plans-dropdown'
import { convertMerchantSpRevenueSharePercentage } from '../shipping-protection-purchase-model/schema'

type ShippingProtectionDropdownsProps = {
  updateStore: ({
    storeId,
    data,
    version,
  }: {
    storeId: string
    data: Partial<Store>
    version: string
  }) => void
  storeId: string
  shippingProtection: StoreShippingProtection
  isLoading?: boolean
  isSuccess?: boolean
  merchantSpRevenueSharePercentage?: number | null
}

const ShippingProtectionDropdowns: FC<ShippingProtectionDropdownsProps> = ({
  updateStore,
  storeId,
  shippingProtection,
  isLoading,
  isSuccess,
  merchantSpRevenueSharePercentage,
}) => {
  const [displayButtons, setDisplayButtons] = useState(false)

  const handleSave = (values: Values): void => {
    const revenueShare = numberHelper.getDecimalFromPercentage(
      values?.merchantSpRevenueSharePercentage as number,
    )

    updateStore({
      storeId,
      data: {
        merchantSpRevenueSharePercentage: revenueShare,
        merchantSpRevenueAgreement: revenueShare ? 'revenue_share' : null,
        shippingProtection: {
          planId: values.planId,
          locationFilteringEnabled: values.locationFilteringEnabled === 'Enabled',
        },
      },
      version: 'latest',
    })
  }

  const {
    values,
    errors,
    dirty,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik<Values>({
    initialValues: {
      merchantSpRevenueSharePercentage: convertMerchantSpRevenueSharePercentage(
        merchantSpRevenueSharePercentage,
      ),
      planId: getPlanIdDefault(shippingProtection?.planId),
      locationFilteringEnabled: getLocationFilteringDefault(
        shippingProtection?.locationFilteringEnabled,
      ),
    },
    validationSchema: schema,
    onSubmit: handleSave,
    enableReinitialize: true,
  })

  const handleCancelClick = useCallback(() => {
    resetForm()
    setDisplayButtons(false)
  }, [resetForm])

  const handleSubmitClick = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  useEffect(() => {
    if (dirty) {
      setDisplayButtons(true)
    }
  }, [dirty, values])

  useEffect(() => {
    if (isSuccess) {
      setDisplayButtons(false)
    }
  }, [isSuccess])

  const hasRevenueShareInputError =
    Boolean(errors.merchantSpRevenueSharePercentage) && touched.merchantSpRevenueSharePercentage
  const hasPlanIdErrors = Boolean(errors.planId) && touched.planId
  const hasLocationFilteringEnabledErrors =
    Boolean(errors.locationFilteringEnabled) && touched.locationFilteringEnabled

  return (
    <div data-cy="shipping-protection-dropdowns">
      <Grid columns={3} spacing={4}>
        <RevenueShareInput
          value={values.merchantSpRevenueSharePercentage}
          isError={hasRevenueShareInputError}
          errorFeedback={errors.merchantSpRevenueSharePercentage}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ShippingProtectionPlansDropdown
          onChange={handleChange}
          value={values.planId}
          isError={hasPlanIdErrors}
          errorFeedback={errors.planId}
        />
        <LocationFilteringSelect
          value={values.locationFilteringEnabled}
          isError={hasLocationFilteringEnabledErrors}
          errorFeedback={errors.locationFilteringEnabled}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      {displayButtons && (
        <SettingContainerWithMargin marginTop={16}>
          <ButtonGroup>
            <Button
              text="Cancel"
              emphasis="medium"
              onClick={handleCancelClick}
              data-cy="sp-dropdowns-cancel-button"
              isDisabled={isLoading}
            />
            <Button
              text="Save"
              isProcessing={isLoading}
              onClick={handleSubmitClick}
              isDisabled={!isValid}
              emphasis="high"
              data-cy="sp-dropdowns-save-button"
            />
          </ButtonGroup>
        </SettingContainerWithMargin>
      )}
    </div>
  )
}

const SettingContainerWithMargin = styled.div<{ marginTop: number }>(({ marginTop }) => ({
  marginTop,
}))

export { ShippingProtectionDropdowns }
