import * as Yup from 'yup'
import { PPABTests } from '@extend-offers/types'

const schema = Yup.object()
  .shape({
    ppAbEnabled: Yup.boolean().required(),
    ppAbType: Yup.string().oneOf(Object.values(PPABTests)).required('AB Test Type is required'),
    targetedCategories: Yup.array()
      .of(Yup.string().min(1))
      .when(['ppAbEnabled', 'ppAbType'], {
        is: (ppAbEnabled: boolean, ppAbType: string) => ppAbEnabled === true && ppAbType === PPABTests.PLAN_SET,
        then: () => Yup.array()
          .of(Yup.string().min(1))
          .required('Plan Category is required for this Experiment Type'),
        otherwise: () => Yup.array().of(Yup.string().min(1)).notRequired(),
      }),
  })
  .defined()

export { schema }
