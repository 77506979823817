import {
  useCreateShippingProtectionPlanMutation,
  useLazyGetShippingProtectionPlanQuery,
} from '@helloextend/extend-api-rtk-query'
import { useToaster, ToastDuration, ToastColor, Stack } from '@extend/zen'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useFormik, FormikProvider } from 'formik'
import type { ForwardRefExoticComponent, RefAttributes } from 'react'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useHistory } from 'react-router'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../../../constants/ld-flags'
import type { SpPlanFormValues } from './sp-plan-form-schema'
import { schema } from './sp-plan-form-schema'
import { SpPlanDetailsSection } from './sp-plan-sections/sp-plan-details-section'
import { SpPlanGeoSection } from './sp-plan-sections/sp-plan-geo-section'
import { SpPlanTermsSection } from './sp-plan-sections/sp-plan-terms-section'
import { SpPlanSectionShell } from './sp-plan-section-shell/sp-plan-section-shell'
import { SpPlanFailureTypesSection } from './sp-plan-sections/sp-plan-failure-types'

type SpPlanFormProps = {
  initialValues: SpPlanFormValues
  handleFormProps: ({ isLoading, canSubmit }: { isLoading: boolean; canSubmit: boolean }) => void
  isEditing?: boolean
  isExistingPlan?: boolean
  modalRender?: boolean
  isRestoringPlan?: boolean
}

const SpPlanForm: ForwardRefExoticComponent<
  SpPlanFormProps & RefAttributes<{ submit: () => void; resetForm: () => void } | undefined>
> = forwardRef(
  (
    { initialValues, handleFormProps, isEditing, isExistingPlan, modalRender, isRestoringPlan },
    ref,
  ) => {
    const flags = useFlags()
    const FF_FAILURE_TYPE_MAPPING_ENABLED = flags[LDFlag.PlanFailureTypeMappingEnabled]
    const history = useHistory()
    const { toast } = useToaster()
    const [isLoading, setIsLoading] = useState(false)
    const [createPlan] = useCreateShippingProtectionPlanMutation()
    const [getSpPlan] = useLazyGetShippingProtectionPlanQuery()

    const formik = useFormik<SpPlanFormValues>({
      initialValues,
      validationSchema: schema,
      validateOnMount: false,
      validateOnBlur: true,
      onSubmit: async (values) => {
        setIsLoading(true)

        if (!isExistingPlan) {
          // validating on submit for now since we need to check if the plan id already exists
          // having issues with validating on form and persisting the error
          const spPlan = await getSpPlan({ planId: values.id })

          if ('data' in spPlan) {
            toast({
              message: 'Plan ID already exists. Please enter a different Plan ID.',
              toastDuration: ToastDuration.short,
              toastColor: ToastColor.red,
            })
            setIsLoading(false)
            return
          }

          if ((spPlan.error as FetchBaseQueryError).status !== 404) {
            toast({
              message: 'Unable to validate Plan ID. Please try again.',
              toastDuration: ToastDuration.short,
              toastColor: ToastColor.red,
            })
            setIsLoading(false)
            return
          }
        }

        const res = await createPlan({ ...values, active: true })

        if ('error' in res) {
          toast({
            message: 'Something went wrong. Please try again.',
            toastDuration: ToastDuration.short,
            toastColor: ToastColor.red,
          })
          setIsLoading(false)
          return
        }

        toast({
          message: isExistingPlan
            ? `${res.data.id} has been successfully ${
                isRestoringPlan ? 'restored' : 'updated'
              } to version ${res.data.version}`
            : `${res.data.id} has been successfully created`,
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
        setIsLoading(false)

        if (!isExistingPlan) {
          history.push('/admin/plans?tab=shipping-protection')
        }

        if (isRestoringPlan) {
          history.push(
            `/admin/plans/shipping-protection/${res.data.id}/version/${res.data.version}`,
          )
        }
      },
    })

    const { handleSubmit, resetForm, isValid, dirty, validateForm } = formik

    useImperativeHandle(
      ref,
      () => {
        return {
          submit: () => {
            handleSubmit()
            validateForm()
          },
          resetForm: () => {
            resetForm()
          },
        }
      },
      [handleSubmit, resetForm, validateForm],
    )

    useEffect(() => {
      handleFormProps({
        isLoading,
        canSubmit: isValid && dirty,
      })
    }, [dirty, handleFormProps, isLoading, isValid])

    return (
      <FormikProvider value={formik}>
        <Stack spacing={2} doesWrap data-cy="sp-plan-form">
          <SpPlanSectionShell
            heading="Plan Details"
            dataCy="plan-details"
            modalRender={modalRender}
            divider
          >
            <SpPlanDetailsSection
              mode={isEditing ? 'edit' : 'view'}
              modalRender={modalRender}
              existingPlan={isExistingPlan}
            />
          </SpPlanSectionShell>
          <SpPlanSectionShell
            heading="Plan Terms"
            dataCy="plan-terms"
            modalRender={modalRender}
            divider
          >
            <SpPlanTermsSection mode={isEditing ? 'edit' : 'view'} modalRender={modalRender} />
          </SpPlanSectionShell>
          {FF_FAILURE_TYPE_MAPPING_ENABLED && (
            <SpPlanSectionShell
              heading="Failure Matrix"
              dataCy="failure-type-details"
              modalRender={modalRender}
            >
              <SpPlanFailureTypesSection
                mode={isEditing ? 'edit' : 'view'}
                isExistingPlan={isExistingPlan}
              />
            </SpPlanSectionShell>
          )}
          <SpPlanSectionShell
            heading="Geographical Details"
            dataCy="geo-details"
            modalRender={modalRender}
          >
            <SpPlanGeoSection mode={isEditing ? 'edit' : 'view'} />
          </SpPlanSectionShell>
        </Stack>
      </FormikProvider>
    )
  },
)

export { SpPlanForm }
