import type { ScriptItem } from '@helloextend/extend-api-rtk-query'

export const generateShipmentCount = (): ScriptItem => ({
  collect: {
    needs: [
      {
        invoke: 'numberOfShipments',
        expects: {
          type: 'object',
          schema: 'SearchResult',
        },
      },
    ],
    options: [
      {
        action: 'execute',
        execute: {
          scriptIndex: -1,
        },
        patterns: [
          {
            params: {
              'result.state': 'success',
            },
          },
        ],
      },
      {
        action: 'execute',
        execute: {
          scriptIndex: -1,
        },
        patterns: [
          {
            params: {
              'result.state': 'failure',
              'result.errorCode': 'not_one_shipment',
            },
          },
        ],
      },
      {
        action: 'execute',
        execute: {
          scriptIndex: -1,
        },
        patterns: [
          {
            params: {
              'result.state': 'failure',
              'result.errorCode': 'more_than_one_shipment',
            },
          },
        ],
      },
    ],
  },
})
