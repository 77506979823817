import type { OktaUserStatus, UserGrant } from '@helloextend/extend-api-rtk-query'
import { Badge } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import { getUserStatus, mapStatusToBadge } from '../../utils/user-status'

type UserStatusBadgeProps = {
  grants: UserGrant[]
  oktaUserStatus: OktaUserStatus
  roleFilter: 'internal' | 'merchant'
  accountId?: string
}

const UserStatusBadge: FC<UserStatusBadgeProps> = ({
  grants,
  oktaUserStatus,
  roleFilter,
  accountId,
}) => {
  const extendStatus = getUserStatus({
    userStatus: oktaUserStatus,
    userGrants: grants,
    roleFilter,
    accountId,
  })
  const { text, badgeColor } = mapStatusToBadge(extendStatus)
  return <Badge text={text} color={badgeColor} data-cy="user-status-badge" />
}

export default UserStatusBadge
