import type { FC } from 'react'
import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import type { ErrorObject } from '@helloextend/core-api-redux/src/types/error'
import type { OfferType, Store, StoreShippingProtection } from '@helloextend/extend-api-client'
import { Information, Switch } from '@extend/zen'
import { ShippingProtectionDropdowns } from './shipping-protection-dropdowns'

export type SPUpdateValue = boolean | OfferType[number] | string
interface ShippingProtectionFormProps {
  storeId: string
  shippingProtection: StoreShippingProtection
  merchantSpRevenueSharePercentage?: Store['merchantSpRevenueSharePercentage']
}

interface StoreUpdateError {
  status: number
  data: ErrorObject
}

const ShippingProtectionForm: FC<ShippingProtectionFormProps> = ({
  storeId,
  merchantSpRevenueSharePercentage,
  shippingProtection,
}) => {
  const { toastError, toastCompleted } = useStandardToast()
  const [loadingToggle, setLoadingToggle] = useState('')
  const [updateStore, { isLoading, isSuccess, error }] = useUpdateStoreMutation()
  const handleStoreUpdate = useCallback(
    (shippingProtectionAttr: keyof StoreShippingProtection, value: SPUpdateValue): void => {
      setLoadingToggle(shippingProtectionAttr)
      if (isLoading) return

      updateStore({
        storeId,
        data: {
          shippingProtection: {
            [shippingProtectionAttr]: value,
          },
        },
        version: 'latest',
      })
    },
    [isLoading, storeId, updateStore],
  )

  const handleToggleChange = useCallback(
    (attribute: keyof StoreShippingProtection, newToggleState: boolean): void => {
      const newShippingProtectionState = { ...shippingProtection, [attribute]: newToggleState }
      const { approved, enabled, offerType, planId } = newShippingProtectionState

      // Display an error if either offerType or planId are unset and user tries to toggle both approved and enabled on
      if ((!offerType || !planId) && approved && enabled) {
        toastError(
          'Store cannot be Approved AND Enabled without first setting a valid Plan ID and Offer Type',
        )
      } else {
        handleStoreUpdate(attribute, newToggleState)
      }
    },
    [handleStoreUpdate, shippingProtection, toastError],
  )
  useEffect(() => {
    if (error) {
      const { message: errorMessage } = (error as StoreUpdateError).data
      let message: string

      if (errorMessage.startsWith('.body.shippingProtection.planId')) {
        message = 'No plan found for that Plan Id'
      } else if (errorMessage.startsWith('.body.shippingProtection.offerType')) {
        message = 'Invalid Offer Type'
      } else {
        message = `Something went wrong: ${errorMessage}`
      }

      toastError(message)
    }
  }, [error, toastError])

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Shipping Protection Settings have been successfully updated.')
    }
  }, [isSuccess, toastCompleted])

  return (
    <div data-cy="sp-form">
      <Switch
        label="Shipping Protection Approved"
        isOn={Boolean(shippingProtection?.approved)}
        id="shipping-protection-approved"
        onChange={() => handleToggleChange('approved', !shippingProtection?.approved)}
        data-cy="sp-approved-toggle"
        isTentative={isLoading && loadingToggle === 'approved'}
        isProcessing={isLoading && loadingToggle === 'approved'}
      />
      <SettingContainerWithMargin marginTop={24}>
        <Switch
          label="Shipping Protection Enabled"
          id="shipping-protection-enabled"
          isOn={Boolean(shippingProtection?.enabled)}
          onChange={() => handleToggleChange('enabled', !shippingProtection?.enabled)}
          data-cy="sp-enabled-toggle"
          isTentative={isLoading && loadingToggle === 'enabled'}
          isProcessing={isLoading && loadingToggle === 'enabled'}
        />
      </SettingContainerWithMargin>
      <SettingContainerWithMargin marginTop={8}>
        <ShippingProtectionDropdowns
          updateStore={updateStore}
          storeId={storeId}
          shippingProtection={shippingProtection}
          isLoading={isLoading}
          isSuccess={isSuccess}
          merchantSpRevenueSharePercentage={merchantSpRevenueSharePercentage}
        />
      </SettingContainerWithMargin>

      <SettingContainerWithMargin marginTop={24}>
        <SwitchContainer>
          <Switch
            label="Offers by Category Enabled"
            id="offersByCategoryEnabled"
            isOn={Boolean(shippingProtection?.offersByCategoryEnabled)}
            onChange={() =>
              handleToggleChange(
                'offersByCategoryEnabled',
                !shippingProtection?.offersByCategoryEnabled,
              )
            }
            data-cy="sp-offers-by-category-toggle"
          />
          <Information data-cy="sp-offers-by-category-info">
            <p>
              Extend can offer Shipping Protection on products that don&apos;t exist in the store
              product catalog if this setting is Enabled.
            </p>
          </Information>
        </SwitchContainer>
      </SettingContainerWithMargin>
    </div>
  )
}

const SettingContainerWithMargin = styled.div<{ marginTop: number }>(({ marginTop }) => ({
  marginTop,
}))

const SwitchContainer = styled.div({
  display: 'flex',
})

export { ShippingProtectionForm }
