import styled from '@emotion/styled'
import { Button, ButtonGroup, Paragraph } from '@extend/zen'
import type { ChangeEvent, FC } from 'react';
import React from 'react'
import { contractResolutionNoteSchema, NOTE_TEXT_MAX } from './contract-note-schema'
import type { ContractResolutionNoteSchemaValues } from './contract-note-schema'
import { useFormik } from 'formik'
import type { ContractNote } from '@helloextend/extend-api-client'
import { useUpdateContractNoteMutation } from '@helloextend/extend-api-rtk-query'
import { ContractNoteTextArea } from './contract-note-text-area'

interface ContractNoteResolutionFormProps {
  setDisplayResolutionNote: (displayResolutionNote: boolean) => void
  contractNote: ContractNote
  setHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void
  handleEscalationNote?: (note: ContractNote) => void
}

const ContractNoteResolutionForm: FC<ContractNoteResolutionFormProps> = ({
  setDisplayResolutionNote,
  setHasUnsavedChanges,
  contractNote,
  handleEscalationNote,
}: ContractNoteResolutionFormProps) => {
  const [updateContractNote, { isLoading: isUpdateLoading }] = useUpdateContractNoteMutation()

  const initialValues = {
    isResolved: contractNote?.isResolved ?? false,
    resolutionText: contractNote?.resolutionText ?? '',
  }

  const { errors, handleSubmit, handleChange, values, resetForm, dirty, validateField, isValid } =
    useFormik<ContractResolutionNoteSchemaValues>({
      enableReinitialize: true,
      initialValues,
      validationSchema: contractResolutionNoteSchema,
      validateOnChange: false,
      onSubmit: async (formValues: ContractResolutionNoteSchemaValues) => {
        const data = { ...formValues, isResolved: true }
        await updateContractNote({
          contractId: contractNote?.contractId,
          noteId: contractNote.id,
          data,
        })

        setDisplayResolutionNote(false)
        handleEscalationNote?.({ ...contractNote, ...data })
        setHasUnsavedChanges && setHasUnsavedChanges(false)
      },
    })

  const handleNoteChange = (evt: ChangeEvent<{ value: string }>) => {
    handleChange(evt)

    if (setHasUnsavedChanges) {
      const { value } = evt.target
      setHasUnsavedChanges(value.length > 0)
    }
  }

  return (
    <ResolutionNoteForm>
      <StyledParagraph>Resolution Note</StyledParagraph>
      <ContainerResolutionNote>
        <ContractNoteTextArea
          id="resolutionText"
          maxLength={NOTE_TEXT_MAX}
          onChange={handleNoteChange}
          rows={6}
          value={values.resolutionText}
          isError={!!errors.resolutionText}
          errorFeedback={errors.resolutionText}
          validateField={validateField}
          dataCy="contract-resolution-note-text"
          isDisabled={isUpdateLoading}
        />
      </ContainerResolutionNote>
      <ButtonGroup justify="end">
        <Button
          emphasis="medium"
          color="blue"
          size="regular"
          data-cy="cancel-resolution-button"
          text="Cancel"
          onClick={() => {
            resetForm()
            setDisplayResolutionNote(false)
          }}
        />
        <Button
          emphasis="high"
          color="blue"
          size="regular"
          isDisabled={!dirty || isUpdateLoading || !isValid}
          data-cy="submit-resolution-button"
          text="Resolve Escalation"
          onClick={() => handleSubmit()}
          isProcessing={isUpdateLoading}
        />
      </ButtonGroup>
    </ResolutionNoteForm>
  )
}

const ResolutionNoteForm = styled.form({
  flexDirection: 'column',
  marginTop: '0.5rem',
})

const ContainerResolutionNote = styled.div({
  width: '100%',
  marginTop: '0.3rem',
  marginBottom: '0.5rem',
})

const StyledParagraph = styled(Paragraph)({
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: '22px',
  textAlign: 'left',
})

export { ContractNoteResolutionForm }
