import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  ClaimsUser,
  ClaimsUsersCreateRequest,
  ClaimsUsersListResponse,
  ClaimsUsersUpdateRequest,
  ClaimsUsersUpdateResponse,
} from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'

export const claimsAssignmentUsersApi = createApi({
  baseQuery,
  reducerPath: 'claimsAssignmentUsers',
  tagTypes: ['claims-assignment-users'],
  endpoints: (build) => ({
    listClaimsAssignmentUsers: build.query<ClaimsUsersListResponse, undefined>({
      query: () => ({
        url: 'claims-management/claims/users',
        providesTags: ['claims-assignment-users'],
      }),
      transformResponse: (res: ClaimsUsersListResponse) => {
        return {
          ...res,
          items: (res.items ?? [])
            .filter((item: ClaimsUser) => item.isActive)
            .map((item: ClaimsUser) => {
              return {
                ...item,
                fullName: `${item.firstName} ${item.lastName}`,
              }
            }),
        }
      },
    }),
    createClaimsUser: build.mutation<ClaimsUser, ClaimsUsersCreateRequest>({
      query: (body) => ({
        url: `claims-management/claims/users`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        body,
      }),
      invalidatesTags: ['claims-assignment-users'],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          claimsAssignmentUsersApi.util.updateQueryData(
            'listClaimsAssignmentUsers',
            undefined,
            (response: ClaimsUsersListResponse) => {
              response?.items.push({
                ...(body as ClaimsUser),
                isActive: true,
                id: `${Date.now()}`,
                createdAt: Date.now(),
              })
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    updateClaimsUser: build.mutation<
      ClaimsUsersUpdateResponse,
      { userId: string; body: ClaimsUsersUpdateRequest }
    >({
      query: ({ userId, body }) => ({
        url: `claims-management/claims/users/${userId}`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        body,
      }),
      invalidatesTags: ['claims-assignment-users'],
      async onQueryStarted({ userId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          claimsAssignmentUsersApi.util.updateQueryData(
            'listClaimsAssignmentUsers',
            undefined,
            (response: ClaimsUsersListResponse) => {
              const match = response?.items?.find((user: ClaimsUser) => user.id === userId)
              if (match) {
                match.isActive = body.isActive
              }
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
  }),
})

export const {
  useListClaimsAssignmentUsersQuery,
  useCreateClaimsUserMutation,
  useUpdateClaimsUserMutation,
} = claimsAssignmentUsersApi
