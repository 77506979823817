import React from 'react'
import { date } from '@extend/client-helpers'
import type { ColumnDef } from '@extend/zen'
import type { ContractNote, ContractStatus } from '@helloextend/extend-api-client'
import type { EscalationTier } from '@helloextend/extend-api-client/src/models/claim-note'
import { Badge } from '@extend/zen'
import { ContractNoteOwnerCell } from '../components/contract-note-owner-cell'
import type { UniqueUsersMap, UserNameAndEmail } from './get-unique-users'
import { contractStatusMap } from './contract-status-map'

export const defaultColumnVisibility = {
  createdBy: false,
  resolvedAt: false,
  resolvedBy: false,
  contractId: false,
}

export const defaultColumnFilterState = [{ id: 'isResolved', value: ['false'] }]

export const getTableColumns = (
  uniqueUsersMap: UniqueUsersMap,
  uniqueUsersArray: UserNameAndEmail[],
): Array<ColumnDef<ContractNote>> => [
  {
    label: 'Owner',
    id: 'ownedBy',
    renderCell: (cellData) => {
      const contractNote: ContractNote = cellData.row.original

      return (
        <ContractNoteOwnerCell
          key={contractNote.id}
          usersMap={uniqueUsersMap}
          usersArray={uniqueUsersArray}
          contractNote={contractNote}
        />
      )
    },
  },
  {
    label: 'Ownership Date',
    id: 'ownedAt',
    isSortable: true,
    renderCell: (cellData) =>
      cellData.getValue() ? date.format(cellData.getValue(), 'DD MMM YYYY') : '',
  },
  {
    label: 'Escalation Status',
    id: 'isResolved',
    renderCell: (cellData) => {
      const badgeText = cellData.getValue() ? 'Resolved' : 'Active'
      const badgeColor = badgeText === 'Resolved' ? 'green' : 'red'
      return badgeText ? (
        <Badge text={badgeText} emphasis="medium" color={badgeColor} size="regular" />
      ) : (
        <div />
      )
    },
  },
  {
    label: 'Escalation Tier',
    id: 'escalationLevel',
    isSortable: true,
    renderCell: (cellData) => {
      const tier = cellData.getValue() as EscalationTier
      const tierMap: Record<EscalationTier, string> = {
        tier1: 'Tier 1',
        tier2: 'Tier 2',
        tier3: 'Tier 3',
      }

      return tierMap[tier]
    },
  },
  {
    label: 'Created Date',
    id: 'createdAt',
    isSortable: true,
    renderCell: (cellData) => {
      const createdAt = cellData.getValue()
      return date.format(createdAt, 'DD MMM YYYY')
    },
  },
  {
    label: 'Store Name',
    id: 'storeName',
    isSortable: true,
    renderCell: (cellData) => cellData.getValue(),
  },
  {
    label: 'Contract Status',
    id: 'contractStatus',
    renderCell: (cellData) => {
      const contractStatus = cellData.getValue() as ContractStatus

      return contractStatusMap[contractStatus]
    },
  },
  {
    label: 'Note Category',
    id: 'category',
    renderCell: (cellData) => cellData.getValue(),
  },
  {
    label: 'Customer Name',
    id: 'customerName',
    renderCell: (cellData) => cellData.getValue(),
  },
  {
    label: 'Customer Email',
    id: 'customerEmail',
    renderCell: (cellData) => cellData.getValue(),
  },
  {
    label: 'Created By',
    id: 'createdBy',
    renderCell: ({
      row: {
        original: { createdBy },
      },
    }) => (!createdBy ? '' : uniqueUsersMap.get(createdBy)?.name || createdBy),
  },
  {
    label: 'Resolved Date',
    id: 'resolvedAt',
    isSortable: true,
    renderCell: (cellData) =>
      cellData.getValue() ? date.format(cellData.getValue(), 'DD MMM YYYY') : '',
  },
  {
    label: 'Resolved By',
    id: 'resolvedBy',
    renderCell: ({
      row: {
        original: { resolvedBy },
      },
    }) => (!resolvedBy ? '' : uniqueUsersMap.get(resolvedBy)?.name || resolvedBy),
  },
  {
    label: 'Contract ID',
    id: 'contractId',
    search: 'explicit',
    renderCell: (cellData) => cellData.getValue(),
  },
]
