import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import {
  useCreatePlanMutation,
  useFetchPlanVersionsListQuery,
} from '@helloextend/extend-api-rtk-query'
import { COLOR, Badge, Breadcrumbs, useToaster, ToastColor, ToastDuration } from '@extend/zen'
import { useFormik } from 'formik'
import { useHistory, useParams } from 'react-router'
import { date } from '@extend/client-helpers'
import { SplashScreen } from '@helloextend/component-commons'
import type { Plan } from '@helloextend/extend-api-client'
import { LeavePageGuard } from '../../../components/leave-page-guard'
import type { Values } from '../plan/schema'
import { schema } from '../plan/schema'
import { mapSchemaToValues } from '../../../utils/plans-mapping'
import { PlanForm } from '../plan/plan-form'
import { planPropertyMapper } from '../../../utils/plan-property-mapper'
import { RestoreHeaderButtonGroup } from '../../../components/restore-header-button-group/restore-header-button-group'
import { RestoreVersionModal } from '../../../components/restore-version-modal/restore-version-modal'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'

const PlanVersion: FC = () => {
  const { planId, versionId } = useParams<{ planId: string; versionId: string }>()
  const { push } = useHistory()

  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState<boolean>(false)
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true)
  const [latestVersion, setLatestVersion] = useState<Plan | null>(null)
  const [planDetails, setPlanDetails] = useState<Plan | null>(null)

  const { hasPermission } = usePermissions()

  const {
    isLoading: isVersionsLoading,
    sortedPlanVersions,
    isUninitialized,
  } = useFetchPlanVersionsListQuery(planId, {
    skip: !versionId || !planId,
    selectFromResult: ({ data, ...rest }) => ({
      sortedPlanVersions: [...(data?.versions || [])].sort((a, b) => a.version - b.version),
      ...rest,
    }),
  })
  const [create, { data: updatedPlansData, isLoading: isCreateProcessing, isSuccess, isError }] =
    useCreatePlanMutation()

  const { toast } = useToaster()

  const {
    values,
    errors,
    dirty,
    touched,
    handleChange,
    handleBlur,
    resetForm,
    setFieldValue,
    setErrors,
  } = useFormik<Values>({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: mapSchemaToValues(planDetails),
    onSubmit: (): void => {},
  })
  const hasErrors = Object.entries(errors).length >= 1

  const matchingPlanVersion = useMemo((): Plan | undefined => {
    return sortedPlanVersions?.find((plan: Plan) => plan?.version === Number(versionId))
  }, [sortedPlanVersions, versionId])

  useEffect(() => {
    setPlanDetails(matchingPlanVersion ?? null)

    if (sortedPlanVersions.length) {
      setLatestVersion(sortedPlanVersions[sortedPlanVersions.length - 1])
    }
  }, [sortedPlanVersions, versionId, matchingPlanVersion])

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: 'Version Restored',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      setIsEditDisabled(true)
      setIsRestoreModalOpen(false)
      push(`/admin/plans/${planId}`)
    }
    if (isError) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      setIsRestoreModalOpen(false)
    }
  }, [isSuccess, isError, toast, push, planId])

  const toggleIsEditDisabled = useCallback((): void => {
    setIsEditDisabled(!isEditDisabled)
  }, [isEditDisabled])

  const toggleRestoreModal = useCallback((): void => {
    setIsRestoreModalOpen(!isRestoreModalOpen)
  }, [isRestoreModalOpen])

  const handleLeavePage = (path: string): void => {
    push(path)
  }

  const handleSave = (): void => {
    if (planDetails && latestVersion) {
      const updatedPlanInfo = planPropertyMapper(values)
      // need to make sure that we're taking the status of the latest version because the previous version is always inactive
      create({ ...updatedPlanInfo, active: latestVersion.active })
    }
  }

  const { version, termsVersion, ...exportDetails } = planDetails ?? {}

  if (isUninitialized || isVersionsLoading) return <SplashScreen data-cy="splash-screen" />
  // if a user hits the direct url for the latest version, redirect to the plan details page
  // or if ff is disabled
  if (latestVersion && latestVersion.version === Number(versionId)) {
    push(`/admin/plans/${planId}`)
    return null
  }

  if (!matchingPlanVersion) {
    push('/404')
    return null
  }

  return (
    <>
      <Breadcrumbs
        data-cy="plan-version-breadcrumbs"
        crumbs={[
          { to: '/admin/plans', text: 'Plans' },
          { to: `/admin/plans/${planId}`, text: `Plan ID: ${planId}` },
          { text: `Version: ${planDetails?.version}` },
        ]}
      />

      <Header>
        {!updatedPlansData && (
          <LeavePageGuard isNavBlocked={dirty} handleLeavePage={handleLeavePage} />
        )}
        <PlanVersionContainer>
          <TitleContainer>
            <Title data-cy="plan-id-header-text">Version {versionId} </Title>
            <BadgeContainer>
              <Badge text="Deactivated" color="yellow" />
            </BadgeContainer>
          </TitleContainer>
          <DetailText>
            Date Deactivated:{' '}
            {planDetails?.plan_end_date
              ? date.format(Number(planDetails.plan_end_date), 'MMM D YYYY')
              : 'N/A'}
          </DetailText>
          <DetailText data-cy="created">
            Date Activated:{' '}
            {planDetails?.plan_start_date
              ? date.format(Number(planDetails.plan_start_date), 'MMM D YYYY')
              : 'N/A'}
          </DetailText>
        </PlanVersionContainer>
        {hasPermission(Permission.ManagePlans) && (
          <RestoreHeaderButtonGroup
            isEditDisabled={isEditDisabled}
            toggleIsEditDisabled={toggleIsEditDisabled}
            resetForm={resetForm}
            restoreDetails={planDetails}
            toggleRestoreModal={toggleRestoreModal}
            isRestoreDisabled={hasErrors || !dirty}
            exportFilePrefix="PLAN"
            duplicateDetailsUrl="/admin/plans/create"
            exportDetails={exportDetails}
          />
        )}
      </Header>
      <PlanContainer>
        <PlanForm
          isDisabled={isEditDisabled}
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setErrors={setErrors}
        />
      </PlanContainer>
      {latestVersion && (
        <RestoreVersionModal
          isOpen={isRestoreModalOpen}
          handleModalToggle={toggleRestoreModal}
          handleRestoreClick={handleSave}
          id={planId}
          latestVersionId={latestVersion?.version}
          isProcessing={isCreateProcessing}
          type="Plan"
        />
      )}
    </>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const PlanVersionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const Title = styled.h1({
  fontWeight: 700,
  fontSize: 32,
  lineHeight: '44px',
  margin: 0,
  color: COLOR.BLUE[1000],
})

const TitleContainer = styled.div({
  display: 'flex',
  margin: '24px 0 24px',
})

const DetailText = styled.p({
  fontSize: 20,
  lineHeight: '28px',
  margin: 0,
})

const PlanContainer = styled.div({
  display: 'flex',
  marginTop: 24,
  flexDirection: 'column',
})

const BadgeContainer = styled.div({
  alignSelf: 'center',
  display: 'inline-flex',
  marginLeft: 16,
})

export { PlanVersion }
