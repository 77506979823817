import { skuCsvHeaders } from './skus-csv-headers'

interface UploadValidatorPipe {
  rowData: string[]
  errors: string[]
  warnings: string[]
}

const formatPipeErrorMessage = (rowId: number, errors: string[]): string =>
  `Row ${rowId}: ${errors.join('; ')}`

const getColumnValue = (validationPipeResults: UploadValidatorPipe, column: string): string => {
  return validationPipeResults.rowData[skuCsvHeaders.indexOf(column)]?.trim()
}

export type { UploadValidatorPipe }
export { formatPipeErrorMessage, getColumnValue }
