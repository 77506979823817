import type { OffersStoreConfig } from '@helloextend/extend-api-client'

export type SurfaceVersion = OffersStoreConfig['surfaceVersion']
export interface OfferSurfaceManagementForm {
  isLearnMoreModal: boolean
  isOfferCart: boolean
  isOfferModal: boolean
  isProductDetailsPage: boolean
  isOrderConfirmationOffer: boolean
  learnMoreModalSelect: string
  offerCartSelect: string
  offerModalSelect: string
  productDetailsPageSelect: string
  orderConfirmationOfferSelect: string
}

export const mapOfferSurfaceVersionToFormValues = (surfaceVersion: SurfaceVersion) => {
  if (!surfaceVersion) {
    return {
      isLearnMoreModal: false,
      isOfferCart: false,
      isOfferModal: false,
      isProductDetailsPage: false,
      isOrderConfirmationOffer: false,
      learnMoreModalSelect: 'V1',
      offerCartSelect: 'V1',
      offerModalSelect: 'V1',
      productDetailsPageSelect: 'V1',
      orderConfirmationOfferSelect: 'V1',
    }
  }

  return {
    isLearnMoreModal: surfaceVersion.learnMoreModal?.enabled ?? false,
    isOfferCart: surfaceVersion.offerCart?.enabled ?? false,
    isOfferModal: surfaceVersion.offerModal?.enabled ?? false,
    isProductDetailsPage: surfaceVersion.pdp?.enabled ?? false,
    isOrderConfirmationOffer: surfaceVersion.orderConfirmationOffer?.enabled ?? false,
    learnMoreModalSelect: surfaceVersion.learnMoreModal?.version ?? 'V1',
    offerCartSelect: surfaceVersion.offerCart?.version ?? 'V1',
    offerModalSelect: surfaceVersion.offerModal?.version ?? 'V1',
    productDetailsPageSelect: surfaceVersion.pdp?.version ?? 'V1',
    orderConfirmationOfferSelect: surfaceVersion.orderConfirmationOffer?.version ?? 'V1',
  }
}

export const mapOfferSurfaceVersionFromFormValues = (
  values: OfferSurfaceManagementForm,
): SurfaceVersion => {
  const result = {
    learnMoreModal: {
      version: values.learnMoreModalSelect,
      enabled: values.isLearnMoreModal,
    },
    offerCart: {
      version: values.offerCartSelect,
      enabled: values.isOfferCart,
    },
    offerModal: {
      version: values.offerModalSelect,
      enabled: values.isOfferModal,
    },
    pdp: {
      version: values.productDetailsPageSelect,
      enabled: values.isProductDetailsPage,
    },
    orderConfirmationOffer: {
      version: values.orderConfirmationOfferSelect,
      enabled: values.isOrderConfirmationOffer,
    },
  }

  return result as SurfaceVersion
}
