import React from 'react'
import type { FC } from 'react'
import { Badge } from '@extend/zen'
import type { BadgeColor } from '@extend/zen'
import styled from '@emotion/styled'

interface BadgeDescriptionsItem {
  badgeText: string
  badgeColor: BadgeColor
  description: string
}

type BadgeDescriptionsProps = {
  'data-cy'?: string
  descriptions: BadgeDescriptionsItem[]
}

const BadgeDescriptions: FC<BadgeDescriptionsProps> = ({ 'data-cy': dataCy, descriptions }) => {
  return (
    <table>
      <tbody>
        {descriptions.map((d) => (
          <tr key={d.badgeText}>
            <BadgeCell>
              <Badge text={d.badgeText} color={d.badgeColor} data-cy={`${dataCy}:badge`} />
            </BadgeCell>
            <td>
              <TextContainer data-cy={`${dataCy}:description`}>{d.description}</TextContainer>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const BadgeCell = styled.td({
  marginLeft: 16,
  marginRight: 7,
  paddingTop: 5,
  textAlign: 'right',
  verticalAlign: 'top',
  whiteSpace: 'nowrap',
  'tr:not(:last-child) &': {
    paddingBottom: 19,
  },
})

const TextContainer = styled.div({
  fontSize: 13,
  marginLeft: 16,
  lineHeight: '18px',
  'tr:not(:last-child) &': {
    marginBottom: 19,
  },
})

export { BadgeDescriptions }
