import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { ContextValue } from '@extend/zen'
import { useCallback } from 'react'

interface StandardToast extends ContextValue {
  /**
   * Use this to inform the user of an unexpected error. For example, an API call failed with a 400 or 500.
   */
  toastError: (message: string) => void

  /**
   * Use this to inform the user of a positive outcome in an uncertain situation. For example, a claim was filed
   * and the user didn't know if it would be approved or not.
   */
  toastApproved: (message: string) => void

  /**
   * Use this to inform the user that an async action completed as expected. For example, an API call succeeded
   * at saving contract details.
   */
  toastCompleted: (message: string) => void

  /**
   * Use this to inform the user that an instantaneous and/or non-mutating action completed. For example, a value
   * was copied to the clipboard.
   */
  toastInstantAction: (message: string) => void
}

const useStandardToast = (): StandardToast => {
  const { toast, dismiss } = useToaster()

  const toastError = useCallback(
    (message: string) => {
      toast({ message, toastColor: ToastColor.red, toastDuration: ToastDuration.short })
    },
    [toast],
  )

  const toastApproved = useCallback(
    (message: string) => {
      toast({ message, toastColor: ToastColor.green, toastDuration: ToastDuration.short })
    },
    [toast],
  )

  const toastCompleted = useCallback(
    (message: string) => {
      toast({ message, toastColor: ToastColor.blue, toastDuration: ToastDuration.short })
    },
    [toast],
  )

  const toastInstantAction = useCallback(
    (message: string) => {
      toast({ message, toastColor: ToastColor.neutral, toastDuration: ToastDuration.short })
    },
    [toast],
  )

  return {
    toast,
    dismiss,
    toastError,
    toastApproved,
    toastCompleted,
    toastInstantAction,
  }
}

export { useStandardToast, ToastColor, ToastDuration }
