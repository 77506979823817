import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import type {
  GetWDStoresRequest,
  GetWDStoresPlanCategoryResponse,
  WDOrchestratorConfigResponse,
  WDOrchestratorConfigPostRequest,
  WDOrchestratorConfigPutRequest,
  WDRulesEngineConfig,
  WDMlConfig,
} from './types'

export const wdOrchestratorApi = createApi({
  reducerPath: 'WDOrchestratorAPI',
  baseQuery,
  tagTypes: ['OrchestratorConfig'],
  endpoints: (build) => ({
    // downstream routes for /wd-orchestrator/stores/
    planCategoryIds: build.query<GetWDStoresPlanCategoryResponse, GetWDStoresRequest>({
      query: ({ storeId }) => ({
        url: `/wd-orchestrator/stores/${storeId}/plan-categories`,
      }),
    }),
    // downstream apis for configs
    getOrchestratorConfigsSentToWD: build.query<WDOrchestratorConfigResponse[], void>({
      query: () => ({
        url: `/wd-orchestrator/stores`,
      }),
    }),
    getOrchestratorConfig: build.query<WDOrchestratorConfigResponse, GetWDStoresRequest>({
      query: ({ storeId }) => ({
        url: `/wd-orchestrator/stores/${storeId}`,
      }),
      providesTags: ['OrchestratorConfig'],
    }),
    updateOrchestratorConfig: build.mutation<
      WDOrchestratorConfigResponse,
      WDOrchestratorConfigPutRequest
    >({
      query: ({ storeId, ...requestBody }) => ({
        url: `/wd-orchestrator/stores/${storeId}`,
        method: 'PUT',
        body: requestBody,
      }),
      invalidatesTags: ['OrchestratorConfig'],
    }),
    createOrchestratorConfig: build.mutation<
      WDOrchestratorConfigResponse,
      WDOrchestratorConfigPostRequest
    >({
      query: (requestBody) => ({
        url: `/wd-orchestrator/stores`,
        method: 'POST',
        body: requestBody,
      }),
      invalidatesTags: ['OrchestratorConfig'],
    }),
    // downstream routes for /wd-orchestrator/rulesets/
    getRulesEngineConfigs: build.query<WDRulesEngineConfig, void>({
      query: () => ({
        url: `/wd-orchestrator/rulesets`,
      }),
    }),
    // downstream routes for /wd-orchestrator/ml-models/
    getMlConfigs: build.query<Record<string, WDMlConfig>, void>({
      query: () => ({
        url: `/wd-orchestrator/ml-models`,
      }),
    }),
  }),
})

export const {
  useLazyPlanCategoryIdsQuery,
  useGetOrchestratorConfigsSentToWDQuery,
  useGetOrchestratorConfigQuery,
  useUpdateOrchestratorConfigMutation,
  useCreateOrchestratorConfigMutation,
  useGetRulesEngineConfigsQuery,
  useGetMlConfigsQuery,
} = wdOrchestratorApi
