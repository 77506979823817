import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@extend/zen'

type ContractIconProps = {
  fill?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@extend/zen'`
 */
const ContractIcon: FC<ContractIconProps> = ({ fill = COLOR.NEUTRAL[600] }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 12">
    <path
      d="M9.42 0H1.594C.707 0 .014.873.014 1.985L0 3.073a.348.348 0 00.11.255.378.378 0 00.266.106h.842v6.373c0 .096.04.187.11.255s.166.106.266.106h3.718c.1 0 .196-.038.266-.106s.11-.16.11-.255a.353.353 0 00-.11-.255.385.385 0 00-.266-.106H1.97V3.434h6.244c.1 0 .196-.038.266-.106.07-.067.11-.159.11-.255V1.991c0-.688.377-1.268.83-1.268.452 0 .827.58.827 1.268v7.816c0 .096.04.187.11.255a.385.385 0 00.533 0c.07-.068.11-.16.11-.255V1.99C11 .87 10.31 0 9.42 0zM7.838 1.986v.722H.756l.01-.722c0-.687.377-1.268.828-1.268h6.599a2.33 2.33 0 00-.355 1.268z"
      fill={fill}
    />
    <path
      d="M3.873 5.622h4.413c.1 0 .195-.038.266-.105.07-.068.11-.16.11-.255a.353.353 0 00-.11-.255.385.385 0 00-.266-.106H3.873c-.1 0-.195.038-.266.106a.353.353 0 00-.11.255c0 .095.04.187.11.255.07.067.166.105.266.105zM5.513 6.819h-1.64c-.1 0-.195.038-.266.105a.353.353 0 00-.11.255c0 .096.04.188.11.256.07.067.166.105.266.105h1.64c.1 0 .195-.038.266-.105a.353.353 0 000-.51.385.385 0 00-.266-.106z"
      fill={fill}
    />
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 9a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm0 1.3a1.5 1.5 0 10-1.5 0V12l.75-.45.75.45v-1.7z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75 9a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm0 1.3a1.5 1.5 0 10-1.5 0V12l.75-.45.75.45v-1.7z"
      fill="red"
    />
    <path
      d="M8.75 10.3l-.5-.866-.5.289v.576h1zm-1.5 0h1v-.577l-.5-.29-.5.866zm0 1.7h-1v1.766l1.514-.909L7.25 12zm.75-.45l.514-.857-.514-.31-.514.31.514.857zm.75.45l-.514.857 1.514.91V12h-1zM8 10.75A1.75 1.75 0 009.75 9h-2A.25.25 0 018 8.75v2zM6.25 9c0 .966.784 1.75 1.75 1.75v-2a.25.25 0 01.25.25h-2zM8 7.25A1.75 1.75 0 006.25 9h2a.25.25 0 01-.25.25v-2zM9.75 9A1.75 1.75 0 008 7.25v2A.25.25 0 017.75 9h2zm-.5 2.165A2.5 2.5 0 0010.5 9h-2a.5.5 0 01-.25.434l1 1.73zM10.5 9A2.5 2.5 0 008 6.5v2a.5.5 0 01.5.5h2zM8 6.5A2.5 2.5 0 005.5 9h2a.5.5 0 01.5-.5v-2zM5.5 9a2.5 2.5 0 001.25 2.165l1-1.731A.5.5 0 017.5 9h-2zm2.75 3v-1.7h-2V12h2zm-.764-1.307l-.75.45 1.029 1.714.75-.45-1.03-1.714zm1.779.45l-.75-.45-1.03 1.715.75.45 1.03-1.716zm-1.515-.844V12h2v-1.7h-2z"
      fill={fill}
      mask="url(#a)"
    />
  </svg>
)

export { ContractIcon }
