import type { SortingState } from '@extend/zen'
import { Add, DataTable } from '@extend/zen'
import type { ClaimsSearchClaim } from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router-dom'
import React, { useMemo, useState } from 'react'
import { useNewClaimsSearch } from '../../../hooks/use-new-claims-search'
import { getFilterDefs, formatClaimItems, getTableColumns } from './new-table-config'

export const ActionRequiredTable = () => {
  const { data: claims, isLoading } = useNewClaimsSearch({ assignedUserId: 'action_required' })
  const [sorting, setSorting] = useState<SortingState>([{ id: 'dateReported', desc: true }])
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({
    followUpDate: false,
    escalationUpdatedAt: false,
    escalationLevel: false,
    type: false,
  })
  const filterDefs = useMemo(() => getFilterDefs(), [])
  const { push } = useHistory()

  const handleFileClaim = (): void => {
    push('/admin/contracts')
  }

  const handleRowClick = (row: ClaimsSearchClaim): void => {
    window.open(`/admin/claims/${row.id}`, '_blank', 'noreferrer')
  }
  return (
    <DataTable
      data-cy="action-required-table"
      data={claims.map(formatClaimItems)}
      isLoading={isLoading}
      sorting={sorting}
      onSortingChange={setSorting}
      filterDefs={filterDefs}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      columns={getTableColumns()}
      pageSizeOptions={[50, 100]}
      getRowId={(row) => row.id}
      getRowActions={(row) => [
        {
          onClick: () => handleRowClick(row),
          text: 'View',
          emphasis: 'low',
        },
      ]}
      getTableActions={() => [
        {
          emphasis: 'medium',
          text: 'File a new claim',
          onClick: handleFileClaim,
          icon: Add,
        },
      ]}
    />
  )
}
