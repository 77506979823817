import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/extend-api-fetch'
import type { SagaIterator } from 'redux-saga'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.resetPassword>

export function* resetPassword(action: Action): SagaIterator {
  const { password, token } = action.payload

  yield put(actions.resetPasswordRequest())

  try {
    const response: ResolvedType<typeof client.auth.resetPassword> = yield call(
      client.auth.resetPassword,
      token,
      password,
    )

    if (isErrorResponse(response)) {
      yield put(actions.resetPasswordFailure(response.data.message, response.status))
      return
    }
    yield put(actions.resetPasswordSuccess())
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.resetPasswordFailure(e.message))
    }
  }
}
