import styled from '@emotion/styled'
import type { FC } from 'react'
import React, { useRef, useState } from 'react'
import { ButtonGroup, Button, DisplayLarge } from '@extend/zen'
import { useHistory } from 'react-router-dom'
import { getInitialValues } from '../sp-plan-form/sp-plan-form-schema'
import { SpPlanForm } from '../sp-plan-form/sp-plan-form'
import { FlexColumn, FlexRowSpaceBetween } from '../../../../styles'

const SpPlanCreate: FC = () => {
  const history = useHistory()
  const createFormRef = useRef<{ submit: () => void; resetForm: () => void }>(null)

  const [formProps, setFormProps] = useState({ isLoading: false, canSubmit: false })

  return (
    <FlexColumn>
      <HeaderContainer>
        <DisplayLarge>Create Shipping Protection Plan</DisplayLarge>
        <ButtonGroup isReversed>
          <Button
            text="Create"
            onClick={() => createFormRef.current?.submit()}
            isProcessing={formProps.isLoading}
            isDisabled={!formProps.canSubmit || formProps.isLoading}
            data-cy="create-sp-plan-button"
          />
          <Button
            text="Cancel"
            emphasis="medium"
            onClick={() => history.push('/admin/plans?tab=shipping-protection')}
            data-cy="cancel-sp-plan-button"
          />
        </ButtonGroup>
      </HeaderContainer>
      <SpPlanForm
        ref={createFormRef}
        initialValues={getInitialValues()}
        handleFormProps={setFormProps}
        isEditing
      />
    </FlexColumn>
  )
}

const HeaderContainer = styled(FlexRowSpaceBetween)({
  alignItems: 'center',
  marginBottom: '2rem',
})

export { SpPlanCreate }
