import React from 'react'
import type { ColumnDefs, CellContext } from '@extend/zen'
import type { EmbeddedPromotion, EmbeddedPromotionStatus } from '@helloextend/extend-api-client'
import { Badge } from '@extend/zen'
import { Descriptions } from 'src/components/badge-descriptions'
import { getPromotionBadgeProps, PROMOTION_STATUS_MAP } from '../../../../../../../utils/promotions'
import { formatDateTime } from './promotions-data-table-helpers'
import { PromotionToggle } from './components/toggle-switch'

export const getTableColumns = (activePromotionExists: boolean): ColumnDefs<EmbeddedPromotion> => [
  {
    id: 'name',
    label: 'Promotion Name',
    isSortable: true,
  },
  {
    id: 'status',
    label: 'Status',
    isSortable: true,
    information: <Descriptions descriptions={PROMOTION_STATUS_MAP} />,
    renderCell: (data: CellContext<EmbeddedPromotion, EmbeddedPromotionStatus>) => {
      const { text, color } = getPromotionBadgeProps(data.getValue())
      return <Badge text={text} color={color} />
    },
  },
  {
    id: 'startTime',
    label: 'Start',
    isSortable: true,
    renderCell: (data: CellContext<EmbeddedPromotion, number>) => {
      return formatDateTime(data.getValue())
    },
  },
  {
    id: 'endTime',
    label: 'End',
    isSortable: true,
    renderCell: (data: CellContext<EmbeddedPromotion, number>) => {
      return formatDateTime(data.getValue())
    },
  },
  {
    id: 'approved',
    label: 'Programs Approved',
    isSortable: true,
    information: (
      <>
        Plans and wholesale prices must be configured before toggling on &lsquo;Program
        Approved&lsquo;.
      </>
    ),
    renderCell: (data: CellContext<EmbeddedPromotion, boolean>) => {
      return (
        <PromotionToggle
          isEnabled={data.getValue()}
          promotion={data.row.original}
          toggleType="approved"
          activePromotionExists={activePromotionExists}
        />
      )
    },
  },
  {
    id: 'enabled',
    label: 'Available in Store',
    isSortable: true,
    renderCell: (data: CellContext<EmbeddedPromotion, boolean>) => {
      return (
        <PromotionToggle
          isEnabled={data.getValue()}
          promotion={data.row.original}
          toggleType="enabled"
          activePromotionExists={activePromotionExists}
        />
      )
    },
  },
]
