import type { FC, SyntheticEvent } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useListPlanAttributesQuery } from '@helloextend/extend-api-rtk-query'
import { COLOR, Button } from '@extend/zen'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { DataTable } from '../../../components/data-table'
import { planAttributeColumns } from './table-config'
import { toTablePlanAttributeItems } from './to-table-plan-attribute-items'
import { AttributeModal } from './attribute-modal'
import type { TablePlanAttribute } from '../../../types/plans'
import { DeleteAttributeModal } from './delete-attribute-modal'
import { blockedFields } from './blocked-fields'

const PlanAttribute: FC = () => {
  const { planAttributeId } = useParams<{ planAttributeId: string }>()
  const [isAddAttributeModalOpen, setIsAttributeModalOpen] = useState<boolean>(false)
  const [isUpdateAttributeModalOpen, setIsUpdateAttributeModalOpen] = useState<boolean>(false)
  const [isDeleteAttributeModalOpen, setIsDeleteAttributeModalOpen] = useState<boolean>(false)
  const [defaultAttributeValue, setDefaultAttributeValue] = useState<string>('')
  const {
    attributeValues,
    isFetching: isAttributesFetching,
    isLoading: isAttributesLoading,
  } = useListPlanAttributesQuery(undefined, {
    selectFromResult: ({ data, isFetching, isLoading }) => ({
      isFetching,
      isLoading,
      attributeValues: data?.items.find((attribute) => {
        return attribute.id === planAttributeId
      }),
    }),
  })

  const values = toTablePlanAttributeItems(attributeValues)

  const toggleAddAttributeModal = (): void => {
    setIsAttributeModalOpen(!isAddAttributeModalOpen)
  }

  const toggleUpdateAttributeModal = (): void => {
    setIsUpdateAttributeModalOpen(!isUpdateAttributeModalOpen)
  }

  const toggleDeleteAttributeModal = (): void => {
    setIsUpdateAttributeModalOpen(false)
    setIsDeleteAttributeModalOpen(!isDeleteAttributeModalOpen)
  }

  const handleRowClick = (_e: SyntheticEvent, attributeValue: TablePlanAttribute): void => {
    setDefaultAttributeValue(attributeValue.id)
    toggleUpdateAttributeModal()
  }

  const isDisabled = blockedFields.includes(planAttributeId)

  return (
    <>
      <NavigationWrapper>
        <StyledLink to="/admin/plans">Plans</StyledLink>
        <Divider>/</Divider>
        <StyledLink to="/admin/plans/attributes" data-cy="plan-attributes-link">
          Plan Attributes
        </StyledLink>
      </NavigationWrapper>
      <TitleWrapper>
        <Title data-cy="plan-attributes-id">{planAttributeId}</Title>
        <div data-tooltip={isDisabled ? 'Values cannot be added.' : ''}>
          <Button
            isDisabled={isDisabled}
            text="Add Value"
            data-cy="add-attribute-value-button"
            onClick={toggleAddAttributeModal}
          />
        </div>
      </TitleWrapper>
      <DataTable
        columns={planAttributeColumns}
        data={values}
        isLoading={isAttributesFetching || isAttributesLoading}
        rowClickEvent={handleRowClick}
      />
      <AttributeModal
        isOpen={isAddAttributeModalOpen}
        handleModalToggle={toggleAddAttributeModal}
        attributeName={planAttributeId}
      />
      <AttributeModal
        isOpen={isUpdateAttributeModalOpen}
        handleModalToggle={toggleUpdateAttributeModal}
        attributeName={planAttributeId}
        defaultValue={defaultAttributeValue}
        handleDelete={toggleDeleteAttributeModal}
        isUpdateModal
      />
      <DeleteAttributeModal
        isOpen={isDeleteAttributeModalOpen}
        handleModalToggle={toggleDeleteAttributeModal}
        attributeValue={defaultAttributeValue}
        attributeId={planAttributeId}
      />
    </>
  )
}

const Title = styled.h1({
  fontWeight: 700,
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
})

const NavigationWrapper = styled.div({
  display: 'flex',
})

const Divider = styled.span({
  margin: '0 8px',
  color: COLOR.NEUTRAL[600],
})

const StyledLink = styled(Link)({
  color: COLOR.BLUE[800],
  '&:visited': {
    color: COLOR.BLUE[800],
  },
})

const TitleWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export { PlanAttribute }
