import { DataProperty, Stack } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import { formatDate } from '../../utils'

const HeaderDetails: FC<{ id: string; createdAt: number; updatedAt: number }> = ({
  id,
  createdAt,
  updatedAt,
}) => {
  return (
    <Stack data-cy="order-header-details">
      <DataProperty data-cy="order-id" label="Order ID" value={id} isHorizontal />
      <DataProperty
        data-cy="created-date"
        label="Created Date"
        value={formatDate(createdAt)}
        isHorizontal
      />
      <DataProperty
        data-cy="updated-date"
        label="Updated Date"
        value={formatDate(updatedAt)}
        isHorizontal
      />
    </Stack>
  )
}

export { HeaderDetails }
