import type { Money } from '@helloextend/extend-api-client'
import * as Yup from 'yup'
import { transformAddress } from '../../../../../../utils/transform-claim-address'
import { formatCurrency, formatStartCase } from '../../../util'

const dataFields = [
  { key: 'serviceOrder.serviceType', label: 'Service Type', transformFn: formatStartCase },
  {
    key: 'entitlements.coverageAmountRemaining',
    label: 'Remaining LOL.',
    transformFn: (value: Money | '—') => {
      if (value === '—') return 'N/A'
      return formatCurrency(value)
    },
  },
  { key: 'contract.sellerName', label: 'Purchased From', columnCount: 1 },
  { key: 'claim.customer.phone', label: 'Customer Phone Number', columnCount: 1 },
  { key: 'claim.customer.name', label: 'Customer Name' },
  { key: 'claim.customer.email', label: 'Customer Email', columnCount: 1 },
  {
    key: 'claim.customer.shippingAddress',
    label: 'Customer Shipping Address',
    transformFn: transformAddress,
    columnCount: 2,
  },
]

const formSchema = Yup.object()
  .shape({
    method: Yup.string().default('product_replacement'),
    product: Yup.object()
      .shape({
        referenceId: Yup.string().default(''),
        name: Yup.string().default(''),
        value: Yup.object()
          .shape({
            amount: Yup.number(),
            currencyCode: Yup.string().default('USD').optional(),
            formattedAmount: Yup.string().default('').optional(),
          })
          .defined(),
        reimbursementAmount: Yup.object()
          .shape({
            amount: Yup.number(),
            currencyCode: Yup.string().default('USD').optional(),
            formattedAmount: Yup.string().default('').optional(),
          })
          .defined()
          .optional(),
      })
      .defined(),
    orderNumber: Yup.string().default('').required('Required'),
    expenseDescription: Yup.string().default('').required('Required'),
    wholesaleCost: Yup.object()
      .shape({
        amount: Yup.number(),
        currencyCode: Yup.string().default('USD').optional(),
      })
      .defined()
      .optional(),
    carrier: Yup.string().required('Required'),
    trackingNumber: Yup.string().required('Required'),
  })
  .defined()

const giftCardFormSchema = Yup.object()
  .shape({
    method: Yup.string().default('gift_card').required(),
    provider: Yup.string().default('').required('Required'),
    amount: Yup.object()
      .shape({
        amount: Yup.number().required('Required').default(1),
        currencyCode: Yup.string().default('USD').optional(),
      })
      .defined()
      .required('Required'),
    miscFees: Yup.object().shape({
      amount: Yup.number(),
      currencyCode: Yup.string().default('USD').optional(),
    }),
    wholesaleCost: Yup.object()
      .shape({
        amount: Yup.number(),
        currencyCode: Yup.string().default('USD').optional(),
      })
      .defined()
      .optional(),
    expenseDescription: Yup.string().default(''),
  })
  .defined()

type Values = Yup.InferType<typeof formSchema>
type GiftCardValues = Yup.InferType<typeof giftCardFormSchema>

export { dataFields, formSchema, giftCardFormSchema }
export type { Values, GiftCardValues }
