import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    rmaNumberRequired: Yup.boolean().required(),
    hasCustomReturnLabel: Yup.boolean().required(),
    hasCustomDefectiveLabel: Yup.boolean().required(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

const formFields = [
  {
    key: 'rmaNumberRequired',
    label: 'Require RMA Number for Shipment',
    header:
      'If your RMA Number is required for shipment, it will appear in the FedEx label INV field.',
  },
  {
    key: 'hasCustomReturnLabel',
    label: 'Return label automatically generated',
    header:
      'If the servicer owns return shipping, Extend will not generate a return label for the repair. Servicer carrier and tracking information can be manually added on the service order detail page.',
  },
  {
    key: 'hasCustomDefectiveLabel',
    label: 'Damaged shipping label automatically generated',
    header:
      'If the servicer owns damaged shipping, Extend will not generate a shipping label for the repair. Servicer carrier and tracking information can be manually added on the service order detail page or created by the servicer via the shipping label create API endpoint.',
  },
  {
    key: 'isSignatureTwoDayReturnShippingEnabled',
    label: 'Signature required 2 day shipping',
    header: 'The return shipment will be 2 day shipping with a signature required.',
  },
]

const packingSlipSchema = Yup.object()
  .shape({
    contractId: Yup.boolean().required().default(true),
    claimId: Yup.boolean().required().default(true),
    serviceOrderId: Yup.boolean().required().default(true),
    orderNumber: Yup.boolean().required().default(true),
    customerName: Yup.boolean().required().default(true),
    productName: Yup.boolean().required().default(true),
    productReferenceId: Yup.boolean().required().default(true),
    productListPrice: Yup.boolean().required().default(true),
    productPurchasePrice: Yup.boolean().required().default(true),
    failureType: Yup.boolean().required().default(true),
    failureDescription: Yup.boolean().required().default(true),
  })
  .defined()

type PackingSlipValues = Yup.InferType<typeof packingSlipSchema>

const packingSlipFormFields: Array<{
  key: keyof PackingSlipValues
  label: string
  sampleValue: string
}> = [
  { key: 'contractId', label: 'Contract ID', sampleValue: '2bdbd91-c627-421e-8d53-69b97ca3bb9f' },
  { key: 'claimId', label: 'Claim ID', sampleValue: '732dbd91-c627-421e-8d53-69b97ca3bb9f' },
  {
    key: 'serviceOrderId',
    label: 'Service Order ID',
    sampleValue: '02bdbd91-c627-421e-8d53-69b97ca3bb9f',
  },
  { key: 'orderNumber', label: 'Order Number', sampleValue: '83927172762' },
  { key: 'customerName', label: 'Customer Name', sampleValue: 'Jolene Wildhart' },
  {
    key: 'productName',
    label: 'Product Name',
    sampleValue: 'HomeStage Karaoke Microphone Speaker with Bluetooth',
  },
  { key: 'productReferenceId', label: 'Product Reference ID', sampleValue: '73ASD898JKI' },
  { key: 'productListPrice', label: 'Product List Price', sampleValue: '$199.95' },
  { key: 'productPurchasePrice', label: 'Product Purchase Price', sampleValue: '189.98' },
  { key: 'failureType', label: 'Failure Type', sampleValue: 'Failure type' },
  {
    key: 'failureDescription',
    label: 'Failure Description',
    sampleValue: 'Microphone handle broken, won’t turn on',
  },
]

export { schema, formFields, packingSlipSchema, packingSlipFormFields }
export type { Values, PackingSlipValues }
