import type { Order } from '@helloextend/extend-api-client'
import { InputType, DataPropertyType } from '@extend/zen'
import type { SaleOriginInformation, StoreAddressInformation } from './schema'
import { mapOrderToOrderAdditionalInfoSchema } from './schema'
import type { CompactFormFieldData } from '../../../../../../components/compact-form/types'

export interface OrderAdditionalSalesInfoFormFields {
  'SALE ORIGIN': CompactFormFieldData[]
  'STORE ADDRESS': CompactFormFieldData[]
}

export const getAdditionalInfoFormFields = (order: Order): OrderAdditionalSalesInfoFormFields => {
  const additionalSalesInfo = mapOrderToOrderAdditionalInfoSchema(order)

  return {
    'SALE ORIGIN': getSalesOriginFields(additionalSalesInfo.saleOrigin),
    'STORE ADDRESS': getStoreAddressFields(additionalSalesInfo.storeAddress),
  }
}

const getSalesOriginFields = ({
  agentId = '',
  channel = '',
  integratorId = '',
  locationId = '',
  platform = '',
}: SaleOriginInformation): CompactFormFieldData[] => [
  {
    id: 'saleOrigin.agentId',
    label: 'Agent ID',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: agentId,
    isEditable: true,
  },
  {
    id: 'saleOrigin.channel',
    label: 'Channel',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: channel,
    isEditable: true,
  },
  {
    id: 'saleOrigin.integratorId',
    label: 'Integrator ID',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: integratorId,
    isEditable: true,
  },
  {
    id: 'saleOrigin.locationId',
    label: 'Location ID',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: locationId,
    isEditable: true,
  },
  {
    id: 'saleOrigin.platform',
    label: 'Platform',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: platform,
    isEditable: true,
  },
]

const getStoreAddressFields = ({
  address1 = '',
  address2 = '',
  city = '',
  countryCode = '',
  postalCode = '',
  province = '',
}: StoreAddressInformation): CompactFormFieldData[] => [
  {
    id: 'storeAdddress.address1',
    label: 'Address',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: address1,
    isEditable: true,
  },
  {
    id: 'storeAdddress.address2',
    label: 'Address 2',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: address2,
    isEditable: true,
  },
  {
    id: 'storeAdddress.city',
    label: 'City',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: city,
    isEditable: true,
  },
  {
    id: 'storeAdddress.countryCode',
    label: 'Country Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: countryCode,
    isEditable: true,
  },
  {
    id: 'storeAdddress.postalCode',
    label: 'Postal Code',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: postalCode,
    isEditable: true,
  },
  {
    id: 'storeAdddress.province',
    label: 'Province',
    inputType: InputType.text,
    dataPropertyType: DataPropertyType.string,
    value: province,
    isEditable: true,
  },
]
