import type { ContractNote } from '@helloextend/extend-api-client'
import type { ColumnFilter } from '@tanstack/table-core'

export type DateFilterId = 'createdAt' | 'ownedAt'

const isDateFilter = (filterId: string) => {
  return filterId === 'createdAt' || filterId === 'ownedAt'
}
const filterDate = (filterId: DateFilterId, dateFilter: number[], contractNote: ContractNote) => {
  const noteValue = contractNote[filterId]
  if (!noteValue) return false

  const [startDate, endDate] = dateFilter

  return noteValue >= startDate && noteValue <= endDate
}

const shouldIncludeNote = (note: ContractNote, columnFilters: ColumnFilter[]): boolean => {
  return columnFilters.every(({ id, value: filterValue }) => {
    if (isDateFilter(id)) return filterDate(id as DateFilterId, filterValue as number[], note)

    const noteValue = note[id as keyof ContractNote]?.toString()

    if (typeof filterValue === 'string') return noteValue === filterValue

    if (Array.isArray(filterValue)) return filterValue.includes(noteValue)

    return false
  })
}

export const filteredContractNotes = (
  contractNotes: ContractNote[],
  columnFilters: ColumnFilter[],
) => {
  if (columnFilters.length === 0) return contractNotes

  return contractNotes.filter((note) => shouldIncludeNote(note, columnFilters))
}
