import React from 'react'
import type { ShippingProtectionPlan } from '@helloextend/extend-api-rtk-query/src/plans/types'
import type { CellContext, ColumnDefs } from '@extend/zen'
import { format } from '@extend/zen'
import { Link } from 'react-router-dom'

const columns: ColumnDefs<ShippingProtectionPlan> = [
  {
    label: 'Plan Id',
    id: 'id',
    search: 'explicit',
    renderCell: (cellData: CellContext<ShippingProtectionPlan, string>) => (
      <Link to={`/admin/plans/shipping-protection/${cellData.getValue()}`}>
        {cellData.getValue()}
      </Link>
    ),
  },
  {
    label: 'Plan Name',
    id: 'name',
    search: 'explicit',
    renderCell: (cellData: CellContext<ShippingProtectionPlan, string>) => cellData.getValue(),
  },
  {
    label: 'Version',
    id: 'version',
    renderCell: (cellData: CellContext<ShippingProtectionPlan, string>) => cellData.getValue(),
  },
  {
    label: 'Plan Category',
    id: 'productCategory',
    renderCell: (cellData: CellContext<ShippingProtectionPlan, string>) => cellData.getValue(),
  },
  {
    label: 'Last Updated',
    id: 'updatedAt',
    renderCell: (cellData: CellContext<ShippingProtectionPlan, number>) =>
      format(cellData.getValue(), 'MMMM DD YYYY, HH:mm A z'),
  },
]

export { columns }
