import { faker } from '@faker-js/faker/locale/en'
import type { ContractCustomer } from '@helloextend/extend-api-client'

export function generateCustomer(overrides: Partial<ContractCustomer> = {}): ContractCustomer {
  return {
    billingAddress: {
      address1: faker.address.streetAddress(),
      address2: '',
      city: faker.address.city(),
      countryCode: 'US',
      postalCode: faker.address.zipCode(),
      provinceCode: faker.address.stateAbbr(),
    },
    email: faker.internet.exampleEmail(),
    phone: faker.phone.number('###-###-####'),
    name: faker.name.fullName(),
    shippingAddress: {
      address1: faker.address.streetAddress(),
      address2: faker.address.secondaryAddress(),
      city: faker.address.city(),
      countryCode: 'US',
      postalCode: faker.address.zipCode(),
      provinceCode: faker.address.stateAbbr(),
    },
    ...overrides,
  }
}
