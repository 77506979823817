import { EXTEND_ENV } from '@helloextend/client-constants'

declare global {
  interface Window {
    dataLayer: Array<string | Date>
  }
}

const getEventDataLayerScript = (gtagId: string): HTMLScriptElement => {
  const script = document.createElement('script')
  const code = `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${gtagId}');`

  script.appendChild(document.createTextNode(code))
  return script
}

export function addGTagManager(googleAnalyticsTag: string): void {
  // Add the script in production only
  if (EXTEND_ENV !== 'production') return
  if (!googleAnalyticsTag) return

  const head = document.getElementsByTagName('head')[0]
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsTag}`
  script.async = true
  head.appendChild(script)

  // Add event to GTag data layer
  head.appendChild(getEventDataLayerScript(googleAnalyticsTag))
}
