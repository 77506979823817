import type { FC, ChangeEvent } from 'react'
import React, { useMemo, useState } from 'react'
import {
  ModalController,
  Modal,
  Input,
  AdvancedSelect,
  useToaster,
  ToastColor,
  ToastDuration,
  FileInput,
} from '@extend/zen'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import type { ThreadRulesetCreateRequest, ThreadTypes } from '@helloextend/extend-api-rtk-query'
import {
  useCreatePhotosetOptimisticMutation,
  useCreateThreadRulesetOptimisticMutation,
  usePublishThreadMutation,
  useCreateThreadMutation,
  useCreatePhotoMutation,
} from '@helloextend/extend-api-rtk-query'
import type { ApiResponse, ErrorResponse } from '@helloextend/extend-api-fetch'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import { getDefaultImageBase64, getFileText, isTextJson } from '../../utils'
import type { PhotoItemExport, ThreadExport } from '../../../../../types/conversations'

type CreateThreadModalProps = {
  isVisible: boolean
  onCancel: () => void
}

const CreateThreadModal: FC<CreateThreadModalProps> = ({ isVisible, onCancel }) => {
  const history = useHistory()
  const { toast } = useToaster()

  const [isDuplicateThreadName, setIsDuplicateThreadName] = useState<boolean>(false)
  const [isFileImportPresent, setIsFileImportPresent] = useState<boolean>(false)
  const [isThreadImportFileValid, setIsThreadImportFileValid] = useState<boolean>(false)
  const [currentThreadToImport, setCurrentThreadToImport] = useState<ThreadExport | null>(null)
  const [isThreadImporting, setIsThreadImporting] = useState<boolean>(false)
  const [createThread, { isLoading: isCreateThreadProcessing }] = useCreateThreadMutation()
  const [publishThread] = usePublishThreadMutation()
  const [createRuleset] = useCreateThreadRulesetOptimisticMutation()
  const [createPhotoset] = useCreatePhotosetOptimisticMutation()
  const [createPhoto] = useCreatePhotoMutation()

  const schema = Yup.object()
    .shape({
      name: Yup.string().required('Thread name is required'),
      type: Yup.string()
        .matches(/(adjudication|troubleshooting)/)
        .required('Thread type is required'),
    })
    .defined()

  const {
    handleChange,
    setFieldValue,
    values,
    errors,
    handleBlur,
    touched,
    isValid,
    dirty: isDirty,
  } = useFormik<Yup.InferType<typeof schema>>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      type: '',
    },
    validationSchema: schema,
    onSubmit: () => {},
  })

  const handleNameInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue('name', e.target.value)

    if (isDuplicateThreadName) {
      setIsDuplicateThreadName(false)
    }
  }

  function instanceThreadExport(object: ThreadExport): object is ThreadExport {
    return 'thread' in object
  }

  const handleImportThreadJSON = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const fileSelected = e.target.files && e.target.files[0]
    if (fileSelected) {
      const fileText = await getFileText(fileSelected)
      if (fileText) {
        setIsFileImportPresent(true)
        if (!isTextJson(fileText)) {
          setIsThreadImportFileValid(false)
        } else {
          const threadExport: ThreadExport = JSON.parse(fileText) as ThreadExport
          setIsThreadImportFileValid(instanceThreadExport(threadExport))
          setCurrentThreadToImport(threadExport)
        }
      }
    } else {
      setIsFileImportPresent(false)
      setCurrentThreadToImport(null)
    }
  }

  const isCurrentThreadAdjudicationType = useMemo(() => {
    return ((values.type as ThreadTypes) === 'adjudication') as boolean
  }, [values.type])

  const onProceed = async (): Promise<void> => {
    try {
      setIsThreadImporting(true)
      const script = currentThreadToImport ? currentThreadToImport.thread.script : []

      let hasImageError = false
      let autoGeneratedPhotoItems: PhotoItemExport[] = []

      if (currentThreadToImport?.images && script?.length) {
        const { images } = currentThreadToImport
        const promises = images.map(async (image) => {
          const { scriptIndex, messageIndex } = image
          if (image.imageData === 'error' || image.imageData === 'null') {
            image.imageData = getDefaultImageBase64()
            hasImageError = true
          }
          const { id } = await createPhoto({ photo: image.imageData }).unwrap()

          // @ts-expect-error this has already been verified & validated when pulling image data
          script[scriptIndex].reply.messages[messageIndex].content = id
          // @ts-expect-error this has already been verified & validated when pulling image data
          script[scriptIndex].reply.prompt.presignedPost.replace.arguments.photoId = id
        })
        await Promise.all(promises)

        // map this outside of the async mapper above to ensure the order is correct
        autoGeneratedPhotoItems = images.map((image) => {
          const { imageData, scriptIndex, messageIndex } = image
          return {
            // @ts-expect-error this has already been verified & validated when pulling image data
            // Take the content from the message before the image, which should be the image's description
            description: script[scriptIndex].reply.messages[messageIndex - 1].content,
            isAlwaysRequired: true,
            base64Image: imageData,
          }
        })
      }

      const createdThread = await createThread({
        title: values.name,
        type: values.type as ThreadTypes,
        script,
      }).unwrap()

      if (currentThreadToImport?.ruleset || currentThreadToImport?.photoset) {
        await publishThread({
          threadId: createdThread.id,
          version: createdThread.version,
        }).unwrap()
      }

      if (currentThreadToImport?.ruleset) {
        const ruleset = {
          approveRules: currentThreadToImport.ruleset.approveRules,
          denyRules: currentThreadToImport.ruleset.denyRules,
          reviewRules: currentThreadToImport.ruleset.reviewRules,
        } as ThreadRulesetCreateRequest
        await createRuleset({ data: ruleset, threadId: createdThread.id }).unwrap()
      }

      const photoItems = currentThreadToImport?.photoset?.photoItems?.length
        ? currentThreadToImport?.photoset?.photoItems
        : autoGeneratedPhotoItems
      if (photoItems?.length) {
        const promises = photoItems.map(async (photoItem) => {
          let { base64Image } = photoItem
          if (!base64Image) return

          if (base64Image === 'error' || base64Image === 'null') {
            base64Image = getDefaultImageBase64()
            hasImageError = true
          }

          const { id } = await createPhoto({ photo: base64Image }).unwrap()
          photoItem.objectId = id
          // remove base64 from the request as it isn't needed and can cause the request payload to be too large if there are many images
          delete photoItem.base64Image
        })

        await Promise.all(promises)

        await createPhotoset({ data: { photoItems }, threadId: createdThread.id }).unwrap()
      }

      history.push(`/admin/adjudication-management/threads/${createdThread.id}/edit/content`)
      if (hasImageError) {
        toast({
          message:
            'Thread imported successfully, but some images failed to import. Please re-upload all missing images.',
          toastDuration: ToastDuration.long,
          toastColor: ToastColor.yellow,
        })
      }
    } catch (err: unknown) {
      const responsePayload = (err as ApiResponse)?.data
      const code = (responsePayload as ErrorResponse)?.code
      const message = (responsePayload as ErrorResponse)?.message
      if (code === 'title_already_exists') {
        setIsDuplicateThreadName(true)
        return
      }
      // fallback to prevent strange UI states
      if (isDuplicateThreadName) {
        setIsDuplicateThreadName(false)
      }
      if (code === 'body_invalid') {
        setIsThreadImportFileValid(false)
        console.error((responsePayload as ErrorResponse)?.message)
      }
      toast({
        message: `Server error - ${message}. Please try again.`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    } finally {
      setIsThreadImporting(false)
    }
  }

  const isDisabled =
    !(isDirty && isValid) ||
    isCreateThreadProcessing ||
    isDuplicateThreadName ||
    (isCurrentThreadAdjudicationType && isFileImportPresent && !isThreadImportFileValid)

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="amp-create-thread-modal"
        heading="Create a new thread"
        primaryButtonProps={{
          'data-cy': 'amp-create-thread-modal-submit',
          onClick: onProceed,
          text: 'Proceed to Thread Builder',
          isDisabled,
          isProcessing: isThreadImporting,
        }}
        secondaryButtonProps={{
          'data-cy': 'amp-create-thread-modal-cancel',
          onClick: onCancel,
          text: 'Cancel',
        }}
        onDismissRequest={onCancel}
      >
        <>
          <NameWrapper>
            <Input
              data-cy="amp-create-thread-name"
              id="name"
              label="Name"
              onChange={handleNameInputChange}
              onBlur={handleBlur}
              placeholder="Name the new thread"
              value={values.name}
              isError={(Boolean(errors.name) && touched.name) || isDuplicateThreadName}
              errorFeedback={
                errors.name || (isDuplicateThreadName ? 'The thread name is taken' : '')
              }
            />
          </NameWrapper>
          <ThreadTypeWrapper>
            <AdvancedSelect
              data-cy="amp-create-thread-type"
              id="type"
              label="Thread Type"
              multiple={false}
              onChange={handleChange}
              placeholder="Select"
              value={values.type}
              isError={Boolean(errors.type) && touched.type}
              errorFeedback={errors.type}
              options={[
                {
                  display: 'Adjudication',
                  value: 'adjudication',
                },
                {
                  display: 'Troubleshooting',
                  value: 'troubleshooting',
                },
              ]}
            />
          </ThreadTypeWrapper>
          {isCurrentThreadAdjudicationType && (
            <FileInput
              data-cy="amp-create-thread-import-file"
              displayedFile=""
              fileExtensions={['json']}
              id="file"
              label="Import Thread (Optional)"
              errorFeedback="Invalid file format"
              isError={isFileImportPresent && !isThreadImportFileValid}
              onChange={handleImportThreadJSON}
            />
          )}
        </>
      </Modal>
    </ModalController>
  )
}

const NameWrapper = styled.div({
  marginBottom: 16,
})

const ThreadTypeWrapper = styled.div({
  marginBottom: 16,
})

export type { CreateThreadModalProps }
export { CreateThreadModal }
