import type { ChangeEvent, FC } from 'react'
import React from 'react'
import type { PhotoRequirement } from '@extend-services/claims-management'
import type { InsuranceClaim } from '@helloextend/extend-api-client'
import type { ValidImageExtension } from '@extend/zen'
import {
  Box,
  ImageInput,
  Label,
  ToastColor,
  ToastDuration,
  isFileValidImage,
  isFileValidSize,
  useToaster,
} from '@extend/zen'
import styled from '@emotion/styled'
import {
  claimsApi,
  useCreateClaimPhotoMutation,
  useUploadClaimPhotoMutation,
} from '@helloextend/extend-api-rtk-query'
import { useDispatch } from 'react-redux'

interface PhotoRequirementUploadCardProps {
  photoRequirement: PhotoRequirement & { index?: number }
  claim: InsuranceClaim
}

type ErrorType = { status?: string; error?: string }

const SUPPORTED_FILE_EXTENSIONS: ValidImageExtension[] = ['jpeg', 'png']
const MAX_FILE_SIZE_MB = 6

export const PhotoRequirementUploadCard: FC<PhotoRequirementUploadCardProps> = ({
  photoRequirement,
  claim,
}) => {
  const [createPhoto] = useCreateClaimPhotoMutation()
  const [uploadPhoto] = useUploadClaimPhotoMutation()
  const { toast } = useToaster()
  const dispatch = useDispatch()

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    try {
      const imageFile = event.target.files && event.target.files[0]
      if (!imageFile) {
        return
      }

      const isValidFile =
        isFileValidImage(imageFile, SUPPORTED_FILE_EXTENSIONS) &&
        isFileValidSize(imageFile, MAX_FILE_SIZE_MB)

      if (!isValidFile) {
        return
      }

      const presignedPost = await createPhoto({
        claimId: claim.id,
        body: {
          source: 'chatbot',
          description: photoRequirement.description as string,
          requirement: photoRequirement,
        },
      }).unwrap()

      const { url, fields } = presignedPost
      const formData = new FormData()
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value)
      })
      formData.append('file', imageFile)
      try {
        await uploadPhoto({ url, body: formData }).unwrap()
        toast({
          message: 'File successfully uploaded!',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
        setTimeout(() => dispatch(claimsApi.util.invalidateTags(['claim-photos'])), 7000)
      } catch (err: unknown) {
        // There can be CORS errors despite successfully uploading
        if (
          (err as ErrorType).status !== 'FETCH_ERROR' ||
          (err as ErrorType).error !== 'TypeError: NetworkError when attempting to fetch resource.'
        ) {
          toast({
            message: 'An error occurred',
            toastDuration: ToastDuration.short,
            toastColor: ToastColor.red,
          })
          return
        }
        toast({
          message: 'File successfully uploaded!',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
        setTimeout(() => dispatch(claimsApi.util.invalidateTags(['claim-photos'])), 7000)
      }
    } catch (err: unknown) {
      toast({
        message: 'An error occurred',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return (
    <Box>
      <HeadingText>
        Photo Requirement{' '}
        {photoRequirement.index || photoRequirement.index === 0 ? photoRequirement.index + 1 : ''}
      </HeadingText>
      <DescriptionText data-cy={`description-${photoRequirement.id}`}>
        {photoRequirement.description}
      </DescriptionText>
      <ImageRow>
        {photoRequirement.imageUrl && (
          <ImageContainer>
            <Label>Sample Photo</Label>
            <img
              data-cy={`sample-photo-${photoRequirement.id}`}
              src={photoRequirement.imageUrl}
              alt={photoRequirement.description}
            />
          </ImageContainer>
        )}
        <ImageUploadContainer>
          <ImageInput
            id="id"
            height={200}
            data-cy={`customer-photo-${photoRequirement.id}`}
            label="Customer's Photo"
            maxSizeMb={MAX_FILE_SIZE_MB}
            imageExtensions={SUPPORTED_FILE_EXTENSIONS}
            onChange={handleImageChange}
          />
        </ImageUploadContainer>
      </ImageRow>
    </Box>
  )
}

const HeadingText = styled.div({
  fontSize: 14,
  fontWeight: 700,
})

const DescriptionText = styled.div({
  margin: '10px 0',
  fontSize: 16,
  fontWeight: 400,
})

const ImageRow = styled.div({
  justifyContent: 'space-between',
  display: 'flex',
  width: 650,
  marginBottom: 25,
})

const ImageContainer = styled.div({
  img: {
    width: 300,
    height: 200,
  },
})

const ImageUploadContainer = styled.div({
  width: 300,
  height: 230,
})
