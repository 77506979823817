import type {
  ContractsV2GetResponse,
  ContractsV3GetResponse,
  CustomBundle,
} from '@helloextend/extend-api-client'
import { ContractType } from '@helloextend/extend-api-client'
import type { TableContractsSearch } from '../../../types/contracts'

export const toTableContractItem = (
  contractSearch: ContractsV3GetResponse,
): TableContractsSearch => ({
  id: contractSearch.id,
  contractId: contractSearch.id,
  currencyCode: contractSearch.currency || contractSearch.purchaseCurrency || 'USD',
  customerName: contractSearch.customer.name,
  storeName: contractSearch.sellerName,
  productName: getProductNameOrShippingProductCount(contractSearch as ContractsV2GetResponse),
  productSerialNumber: contractSearch.product?.serialNumber,
  customerEmail: contractSearch.customer.email,
  customerPhone: contractSearch.customer.phone,
  planPrice: typeof contractSearch.purchasePrice === 'number' ? contractSearch.purchasePrice : 0,
  status: contractSearch.status,
  type: contractSearch?.type || ('pcrs' as ContractType),
  transactionId: contractSearch.transactionId,
  purchaseDate: contractSearch.purchaseDate,
  planCategory: contractSearch.planDetails?.category ?? '',
  merchantCustomerId: contractSearch.merchantCustomerId ?? '',
})

const countTotalProductsWithBundles = (
  productsList: ContractsV3GetResponse['productsList'],
): number => {
  const productCounts = new Map<string, number>()

  productsList?.forEach((product) => {
    const id =
      'type' in product && product.type === 'bundle_component'
        ? product.bundleId
        : product.lineItemId ?? product.referenceId

    if ('type' in product && ['custom_bundle', 'standard_bundle'].includes(product.type)) return

    productCounts.set(id, (productCounts.get(id) ?? 0) + 1)
  })

  return productCounts.size
}

const getProductNameOrShippingProductCount = (contract: ContractsV3GetResponse): string => {
  if (contract.type === ContractType.SHIPPING_PROTECTION && contract.productsList) {
    const count = countTotalProductsWithBundles(contract.productsList)
    return `${count} product${count > 1 ? 's' : ''}`
  }
  if (contract.type === ContractType.PRODUCT_PROTECTION_BUNDLE && contract.productsList) {
    const { productsList } = contract
    return (productsList[productsList.length - 1] as CustomBundle)?.title ?? 'Bundle Product'
  }
  if (contract.type === ContractType.CATEGORY && contract.productIds) {
    const count = countTotalProductsWithBundles(contract.productsList)
    return `${count} product${count > 1 ? 's' : ''}`
  }
  return contract.product?.name || '--'
}

export const toTableContractItems = (
  contractSearchItems: ContractsV2GetResponse[] | undefined,
): TableContractsSearch[] => {
  if (contractSearchItems) {
    return contractSearchItems.map(toTableContractItem)
  }
  return []
}
