import type { FC } from 'react'
import React, { useState } from 'react'
import { DotPagination } from '@helloextend/merchants-ui'
import { Breakpoint } from './breakpoint'
import { ActiveOfferSurface } from './active-offer-surface'
import styles from './preview-container.module.css'

const OFFER_SURFACES = [
  {
    id: 'offerTypeModal',
    title: 'Offer Modal',
    description: 'This popup allows a customer to select an Extend protection plan',
  },
  {
    id: 'learnMoreModal',
    title: 'Learn More Modal',
    description:
      'This informational popup appears when a customer clicks "Learn More" in the Product Page Offer',
  },
  {
    id: 'offerButtons',
    title: 'Product Page Offer',
    description: 'This selector appears on product pages near the "Add to Cart" button',
  },
  {
    id: 'cartOffer',
    title: 'Cart Offer',
    description:
      "This button appears on the cart page with a product that's eligible for a protection plan",
  },
  {
    id: 'postPurchaseModal',
    title: 'Post-purchase Modal',
    description:
      'This popup appears when a customer clicks a link as part of a post-purchase campaign',
  },
]

type PreviewContainerProps = {
  isLoading?: boolean
}
export type SelectedBreakpoint = 'mobile' | 'desktop'

export const PreviewContainer: FC<PreviewContainerProps> = ({ isLoading }) => {
  const [offerSurfaceIndex, setOfferSurfaceIndex] = useState(0)
  const [selectedBreakpoint, setSelectedBreakpoint] = useState<SelectedBreakpoint>('desktop')

  const handleChangeOfferSurfaceIndex = (idx: number): void => {
    setOfferSurfaceIndex(idx)
  }

  return (
    <div className={styles.background}>
      <div className="flex flex-col align-items-center">
        <DotPagination onChange={handleChangeOfferSurfaceIndex} numOfDots={OFFER_SURFACES.length} />
        <h2 className={styles.title}>{OFFER_SURFACES[offerSurfaceIndex].title}</h2>
        <p className={styles.description}>{OFFER_SURFACES[offerSurfaceIndex].description}</p>
        <div className={styles.offer}>
          <ActiveOfferSurface
            isLoading={isLoading}
            surface={OFFER_SURFACES[offerSurfaceIndex].id}
            breakpoint={selectedBreakpoint}
          />
          <Breakpoint
            selectedBreakpoint={selectedBreakpoint}
            setSelectedBreakpoint={setSelectedBreakpoint}
          />
        </div>
      </div>
    </div>
  )
}
