import type { FC } from 'react'
import React from 'react'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { AdvancedSelect } from '@extend/zen'
import styled from '@emotion/styled'
import type { PlanIdsCoverageTypeMap } from '@helloextend/extend-api-client/src/models/plan-sets'
import { MAX_COVERAGE_TYPE_COUNT } from '../../lib/constants'

export const MAX_PLAN_SELECTION = MAX_COVERAGE_TYPE_COUNT * 2 // x2 for adh + base

export interface PlansSelectProps {
  isLoading: boolean
  onChange: (e: AdvancedSelectChangeEvent<string[]>) => void
  planIdsCoverageTypeMap: PlanIdsCoverageTypeMap
  value: string[]
  isError: boolean
  errorFeedback?: string
  isDisabled: boolean
}

const PlansSelect: FC<PlansSelectProps> = ({
  isLoading,
  planIdsCoverageTypeMap,
  onChange,
  value = [],
  isError,
  errorFeedback,
  isDisabled,
}) => {
  const planIdsSelectOptions = Object.keys(planIdsCoverageTypeMap)?.map((planId) => ({
    value: planId,
    display: `${planId} - ${planIdsCoverageTypeMap[planId]}`,
  }))

  return (
    <SelectWrapper>
      <AdvancedSelect
        id="plans-select"
        data-cy="plans-select"
        label="Plans"
        placeholder="Select"
        value={value}
        onChange={onChange}
        options={planIdsSelectOptions}
        multiple
        showSearch
        maxLength={MAX_PLAN_SELECTION}
        maxQuantityToDisplay={0}
        isLoading={isLoading}
        isCounterHiddenWhenValid
        pageSize={50}
        isError={isError}
        errorFeedback={errorFeedback}
        isDisabled={isDisabled}
      />
    </SelectWrapper>
  )
}

const SelectWrapper = styled.div({
  flex: 1,
  minWidth: '48%', // widest it can be without wrapping price input elements
})

export { PlansSelect }
