import type { SelectFilterOption } from '@extend/zen'
import type { ClaimsUser } from '@helloextend/extend-api-client'

export interface UserNameAndEmail {
  name: string
  email: string
}

export type UniqueUsersMap = Map<string, UserNameAndEmail>

interface UniqueUserDataSets {
  uniqueClaimsUsersMap: UniqueUsersMap
  uniqueClaimsUsersArray: UserNameAndEmail[]
  uniqueClaimsUserNameOptions: SelectFilterOption[]
}

export const getUniqueClaimsUsers = (users: ClaimsUser[]): UniqueUserDataSets => {
  const uniqueClaimsUsersMap = new Map<string, UserNameAndEmail>()
  const uniqueClaimsUsersArray: UserNameAndEmail[] = []
  const uniqueClaimsUserNameOptions: SelectFilterOption[] = []

  users.forEach((user) => {
    if (!uniqueClaimsUsersMap.has(user.email)) {
      const name = `${user.firstName} ${user.lastName}`
      const email = user.email
      const uniqueUser = { name, email }

      uniqueClaimsUsersMap.set(user.email, uniqueUser)
      uniqueClaimsUsersArray.push(uniqueUser)
      uniqueClaimsUserNameOptions.push({ display: name, value: email })
    }
  })

  return {
    uniqueClaimsUsersMap,
    uniqueClaimsUsersArray,
    uniqueClaimsUserNameOptions,
  }
}
