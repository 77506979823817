import { isEmpty } from 'lodash'
import {
  useGetPlanMappingsQuery,
  useGetStorePlanPricingListQuery,
  useGetStoreQuery,
  useGetConfigurationQuery,
  useGetOffersStoreConfigurationQuery,
} from '@helloextend/extend-api-rtk-query'
import type { AdvancedSelectOption } from '@extend/zen'
import {
  Add,
  Button,
  Info,
  Error,
  InlineAlert,
  InlineAlertColor,
  Spinner,
  Edit,
  ModalController,
  Paragraph,
} from '@extend/zen'
import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useToggle } from '@helloextend/client-hooks'
import type { PlanCategory } from '@helloextend/extend-api-client'
import { MessageTypes } from '@helloextend/extend-api-client'
import { useExtendAuth } from '@extend/package-okta-login'
import { ProductProtectionInfo } from './components/product-protection-info'
import { SectionContainer } from '../../../../../../components/section-container'
import { ProductProtectionForm } from '../../../components/product-protection-form'
import { TabMenu } from '../../../../../../components/tab-menu/tab-menu'
import { AssignedPlans } from './components/assigned-plans'
import { LDFlag } from '../../../../../../constants/ld-flags'
import { checkPlansForWholesalePricing } from '../../utils'
import { AddPlanSetModal } from './components/add-plan-set-modal'
import { PlanSetsDataTable } from './components/plan-sets-data-table/plan-sets-data-table'
import { AbStoreConfig } from '../ab-config/ab-config-product-protection'
import { CssOverride } from '../offer-customization/css-override'
import { OfferSurfaceManagement } from '../offer-surface-management/offer-surface-management'

type PlanAndPricingProps = {
  storeId: string
  selectCategories: AdvancedSelectOption[]
  planCategories: PlanCategory[]
}

enum PlanAssignmentTabs {
  PlanSets = 'plan-sets',
  IndividualPlans = 'individual-plans',
}

const PlanAndPricing: FC<PlanAndPricingProps> = ({ storeId, selectCategories, planCategories }) => {
  const [isEditEnabled, setIsEditEnabled] = useState(false)
  const [activeTab, setActiveTab] = useState<string>(PlanAssignmentTabs.PlanSets)
  const [isModalOpen, { on, off }] = useToggle(false)
  const { data, isLoading: isLoadingGetStore } = useGetStoreQuery({ storeId, version: 'latest' })

  const {
    [LDFlag.AssignedPlanSets]: FF_ASSIGNED_PLAN_SETS,
    [LDFlag.MessaginFeaturesConfigurationSendTermsSms]: FF_MESSAGING_SMS_TERMS,
    [LDFlag.OffersFeatures]: FF_OFFERS,
  } = useFlags()

  const { user } = useExtendAuth()
  const { data: messagingConfig, isLoading: isGettingMessagingConfig } = useGetConfigurationQuery(
    {
      messageName: MessageTypes.ORDER_RECEIVED_SEND_TERMS_SMS,
      storeId,
    },
    { skip: !FF_MESSAGING_SMS_TERMS },
  )
  const { data: planMappings, isLoading: isPlanMappingsLoading } = useGetPlanMappingsQuery({
    storeId,
  })
  const { data: storePlanPricingList } = useGetStorePlanPricingListQuery({
    storeId,
    version: 'latest',
  })
  const { data: offersStoreConfig } = useGetOffersStoreConfigurationQuery({
    storeId,
    version: '2022-02-01',
  })

  const menuTabs = [
    { text: 'Plan Sets', key: PlanAssignmentTabs.PlanSets },
    { text: 'Individual Plans', key: PlanAssignmentTabs.IndividualPlans },
  ]

  const handleTabClick = (tab: string): void => {
    setActiveTab(tab)
  }

  const handleCancel = (): void => {
    setIsEditEnabled(false)
  }

  const handleEditClick = (): void => {
    setIsEditEnabled(true)
  }

  const isWholesale = data?.merchantEwRevenueAgreement === 'wholesale'

  const plansWithoutWholesalePricing = useMemo(() => {
    if (!planMappings?.length) return []
    return checkPlansForWholesalePricing(planMappings, storePlanPricingList?.items)
  }, [planMappings, storePlanPricingList?.items])

  if (isLoadingGetStore || isPlanMappingsLoading || isGettingMessagingConfig) {
    return <Spinner data-cy="spinner" />
  }

  const hasAppropriateRole = ['SolutionEngineer', 'MerchantSuccess'].includes(user?.role || '')

  return (
    <>
      {isWholesale && planMappings?.length === 0 && (
        <InlineAlertContainer>
          <InlineAlert
            icon={Info}
            color={InlineAlertColor.yellow}
            data-cy="wholesale-revenue-model-alert"
          >
            <Paragraph>
              For the Wholesale Revenue Model, it is required to map wholesale values under
              individual plans.
            </Paragraph>
          </InlineAlert>
        </InlineAlertContainer>
      )}
      {isWholesale && Boolean(plansWithoutWholesalePricing.length) && (
        <InlineAlertContainer>
          <InlineAlert
            icon={Error}
            color={InlineAlertColor.red}
            data-cy="plans-without-wholesale-pricing-alert"
          >
            <Paragraph>
              For the Wholesale Revenue Model, it is required to map wholesale values under
              individual plans. Go to Wholesale Pricing page to map wholesale values. Assigned plans
              with missing wholesale values:
            </Paragraph>
            <List>
              {plansWithoutWholesalePricing.map((plan) => (
                <li key={plan}>{plan}</li>
              ))}
            </List>
          </InlineAlert>
        </InlineAlertContainer>
      )}
      <SectionContainer
        data-cy="product-protection-settings-section"
        title="Product Protection Settings"
        {...(!isEditEnabled && {
          actionItem: (
            <Button text="Edit" emphasis="low" size="small" onClick={handleEditClick} icon={Edit} />
          ),
        })}
      >
        {!isEditEnabled && data && (
          <ProductProtectionInfo store={data} messagingConfig={messagingConfig} />
        )}
        {isEditEnabled && data && (
          <ProductProtectionForm
            store={data}
            handleCancel={handleCancel}
            messagingConfig={messagingConfig}
          />
        )}
      </SectionContainer>
      {FF_ASSIGNED_PLAN_SETS ? (
        <SectionContainer data-cy="plan-assignment-section" title="Plan Assignment">
          <TabMenu tabs={menuTabs} onClick={handleTabClick} activeTab={activeTab} />
          {activeTab === PlanAssignmentTabs.PlanSets && (
            <>
              <HeaderContainer>
                <Button
                  text="Add Plan Set"
                  icon={Add}
                  emphasis="medium"
                  onClick={on}
                  data-cy="add-plan-set-button"
                />
              </HeaderContainer>
              <PlanSetsDataTable storeId={storeId} categoryList={planCategories || []} />
            </>
          )}
          {activeTab === PlanAssignmentTabs.IndividualPlans && (
            <AssignedPlansContainer>
              <AssignedPlans
                planMappings={planMappings}
                isLoading={isPlanMappingsLoading}
                storeId={storeId}
                isWholesale={isWholesale}
              />
            </AssignedPlansContainer>
          )}
        </SectionContainer>
      ) : (
        <AssignedPlans
          planMappings={planMappings}
          isLoading={isPlanMappingsLoading}
          storeId={storeId}
          isWholesale={isWholesale}
        />
      )}

      {hasAppropriateRole && !isEmpty(offersStoreConfig) && (
        <SectionContainer
          data-cy="offer-surface-management-section"
          title="Offer Surface Management"
        >
          <OfferSurfaceManagement offersStoreConfig={offersStoreConfig} storeId={storeId} />
        </SectionContainer>
      )}

      {FF_OFFERS.ppABTesting?.enabled && (
        <SectionContainer data-cy="ab-testing-section" title="A/B Testing">
          <AbStoreConfig
            storeId={storeId}
            categories={selectCategories}
            offersStoreConfig={offersStoreConfig}
          />
        </SectionContainer>
      )}

      {FF_OFFERS.offersCustomization?.enabled && (
        <SectionContainer data-cy="offer-customization-section" title="Offer Customization">
          <CssOverride storeId={storeId} />
        </SectionContainer>
      )}

      <ModalController isOpen={isModalOpen}>
        <AddPlanSetModal
          isModalOpen={isModalOpen}
          closeModal={off}
          storeId={storeId}
          categories={selectCategories}
        />
      </ModalController>
    </>
  )
}

const InlineAlertContainer = styled.div({
  marginBottom: 16,
})
const List = styled.ul({
  margin: 0,
  paddingInlineStart: 24,
})

const AssignedPlansContainer = styled.div({
  padding: '40px 0',
})

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '40px 0',
})

export { PlanAndPricing }
