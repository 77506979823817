import type { FC } from 'react'
import React, { useEffect } from 'react'
import type { Contract20220201GetResponse, ContractNote } from '@helloextend/extend-api-client'
import { useToggle } from '@helloextend/client-hooks'
import { useGetContractNotesQuery } from '@helloextend/extend-api-rtk-query'
import { ContractNotesModal } from './contract-notes-modal'
import { ContractNotesButton } from './contract-notes-button'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from 'src/constants/ld-flags'

interface ContractNotesProps {
  contract: Contract20220201GetResponse
  handleEscalationNote?: (note: ContractNote) => void
}

const ContractNotes: FC<ContractNotesProps> = ({ contract, handleEscalationNote }) => {
  const { [LDFlag.ContractNotes]: FF_CONTRACT_NOTES } = useFlags()
  if (!FF_CONTRACT_NOTES) return <></>
  const [isModalOpen, { toggle }] = useToggle(false)
  const { contractNotes } = useGetContractNotesQuery(
    { contractId: contract.id },
    {
      selectFromResult: ({ data }) => ({
        contractNotes: [...(data?.items || [])].sort((a, b) => b.createdAt - a.createdAt),
      }),
      skip: !contract.id,
      refetchOnMountOrArgChange: true,
    },
  )

  const escalationNote = contractNotes.find(
    (note) => note.type === 'escalation' && !note.isResolved,
  )

  useEffect(() => {
    if (handleEscalationNote && escalationNote) {
      handleEscalationNote(escalationNote)
    }
  }, [escalationNote])

  return !isModalOpen ? (
    <ContractNotesButton contractNotes={contractNotes} toggle={toggle} />
  ) : (
    <ContractNotesModal
      contract={contract}
      contractNotes={contractNotes}
      isModalOpen={isModalOpen}
      toggle={toggle}
      handleEscalationNote={handleEscalationNote}
      escalationExists={!!escalationNote}
    />
  )
}

export { ContractNotes }
