import type { FC } from 'react'
import React, { useLayoutEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'
import { useGetServicerQuery } from '@helloextend/extend-api-rtk-query'
import { ServicerLocationForm } from './location'
import { Button } from '@extend/zen'

const LocationsTab: FC = () => {
  const { servicerId } = useParams<{ servicerId: string }>()
  const { data: servicer, isSuccess } = useGetServicerQuery(servicerId)
  const locations = servicer?.locations || []
  // State to manage the visibility of the additional form
  const [showNewForm, setShowNewForm] = useState(true)

  useLayoutEffect(() => {
    if (locations.length) {
      setShowNewForm(false)
    }
  }, [locations])
  // Function to handle adding a new form instance
  const toggleNewForm = () => {
    setShowNewForm((prevShowNewForm) => !prevShowNewForm)
  }
  // Memoizing the locations forms
  const locationsFrom = useMemo(() => {
    return locations.map((location, index) => (
      <ServicerLocationForm
        isSuccess={isSuccess}
        location={location}
        servicer={servicer}
        locationIndex={index + 1}
        key={location.id}
      />
    ))
  }, [locations, isSuccess, servicer])
  return (
    <>
      {locationsFrom}
      {showNewForm && (
        <ServicerLocationForm isSuccess={isSuccess} servicer={servicer} key={`new-form`} />
      )}
      <Button
        color={'blue'}
        size="regular"
        data-cy="add-new-location-btn"
        text={showNewForm ? 'Cancel' : 'Add new location'}
        emphasis="medium"
        onClick={toggleNewForm}
      ></Button>
    </>
  )
}

export { LocationsTab }
