import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@extend/zen'`
 */
const SolidArrow = styled.span<{
  active?: boolean
  direction?: 'asc' | 'desc' | null
  width?: string
}>(({ active, direction, width = '8px' }) => ({
  borderLeft: `${width} solid transparent`,
  borderRight: `${width} solid transparent`,
  borderTop: active
    ? `${width} solid ${COLOR.NEUTRAL[1000]}`
    : `${width} solid ${COLOR.NEUTRAL[600]}`,
  display: 'inline-block',
  height: 0,
  cursor: 'pointer',
  marginLeft: 8,
  marginBottom: 2,
  width: 0,
  transform: active && direction === 'asc' ? 'rotate(180deg)' : 'rotate(0)',
  transition: 'transform .2s ease-in',
}))

export { SolidArrow }
