import type { AdvancedSelectChangeEvent, AdvancedSelectOption } from '@extend/zen'
import { AdvancedSelect, Grid, Input, InputType } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import type { FormikErrors, FormikHandlers, FormikValues } from 'formik'
import { useFormikContext } from 'formik'
import type { SPPlanCategoryMapping } from '@helloextend/extend-api-client/src/models/store-shipping-protection'
import { ShippingProtectionPlansDropdown } from '../../shipping-protection-plans-dropdown/shipping-protection-plans-dropdown'
import { getFormikError } from '../../../../../../../../../utils/form-utils'
import type { Values } from '../../schema'

type SpCategoryRowProps = {
  extendCategoryOptions: AdvancedSelectOption[]
  isExtendCategoryListLoading?: boolean
  index: number
  isLabelDisplayed?: boolean
  handleOnChange: FormikHandlers['handleChange']
  handleOnBlur: FormikHandlers['handleBlur']
  errors: FormikErrors<FormikValues>
  data: SPPlanCategoryMapping
}

const SpCategoryRow: FC<SpCategoryRowProps> = ({
  extendCategoryOptions,
  isExtendCategoryListLoading,
  index,
  isLabelDisplayed,
  data,
  errors,
  handleOnChange,
  handleOnBlur,
}) => {
  const { values, setFieldValue } = useFormikContext<Values>()

  const handleCategoryChange = (event: AdvancedSelectChangeEvent): void => {
    setFieldValue(`categoryMappings.${index}.spPlanId`, undefined)
    handleOnChange(event)
  }
  const categoriesFormikId = `categoryMappings[${index}]`

  return (
    <Grid
      columns={values.partialReimbursementEnabled && values.partialReimbursementSelection ? 4 : 3}
      spacing={4}
    >
      <AdvancedSelect
        data-cy={`extend-category-${index}`}
        label={isLabelDisplayed ? 'Extend Category' : ''}
        multiple={false}
        onChange={handleCategoryChange}
        onBlur={handleOnBlur}
        options={extendCategoryOptions}
        value={data.planCategoryId || ''}
        id={`${categoriesFormikId}planCategoryId`}
        showSearch
        isLoading={isExtendCategoryListLoading}
        placeholder="Select"
        pageSize={50}
        isNotClearable
        isError={Boolean(getFormikError(errors, `${categoriesFormikId}planCategoryId`))}
        errorFeedback={getFormikError(errors, `${categoriesFormikId}planCategoryId`)}
      />
      <ShippingProtectionPlansDropdown
        category={
          extendCategoryOptions.find((option) => option.value === data.planCategoryId)?.display
        }
        value={data.spPlanId}
        id={`${categoriesFormikId}spPlanId`}
        isDisabled={!data.planCategoryId}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        label={isLabelDisplayed ? 'Shipping Protection Plan' : ''}
        isPlanDetailsDisplayed
        isError={Boolean(getFormikError(errors, `${categoriesFormikId}spPlanId`))}
        errorFeedback={getFormikError(errors, `${categoriesFormikId}spPlanId`)}
        dataCy={`sp-plan-id-${index}`}
      />

      <Input
        value={data.revenueShare?.toString() || ''}
        id={`${categoriesFormikId}revenueShare`}
        label={isLabelDisplayed ? 'Revenue Share Per Shipment' : undefined}
        suffix="%"
        type={InputType.number}
        onChange={handleOnChange}
        placeholder="Enter Percentage"
        isError={Boolean(getFormikError(errors, `${categoriesFormikId}revenueShare`))}
        errorFeedback={getFormikError(errors, `${categoriesFormikId}revenueShare`)}
        data-cy={`revenue-share-${index}`}
      />
      {values.partialReimbursementSelection === 'percentage' &&
        values.partialReimbursementEnabled && (
          <Input
            type={InputType.number}
            id={`${categoriesFormikId}partialReimbursement`}
            suffix="%"
            data-cy={`partial-reimbursement-category-${index}`}
            value={data?.partialReimbursement?.toString() || ''}
            label={isLabelDisplayed ? 'Partial Reimbursement' : undefined}
            isError={Boolean(getFormikError(errors, `${categoriesFormikId}partialReimbursement`))}
            errorFeedback={getFormikError(errors, `${categoriesFormikId}partialReimbursement`)}
            onChange={handleOnChange}
            placeholder="Enter percentage"
          />
        )}
      {values.partialReimbursementSelection === 'fixed' && values.partialReimbursementEnabled && (
        <Input
          type={InputType.number}
          id={`${categoriesFormikId}partialReimbursementFixed`}
          suffix=""
          data-cy={`partial-reimbursement-fixed-category-${index}`}
          value={data?.partialReimbursementFixed?.toString() || ''}
          label={isLabelDisplayed ? 'Partial Reimbursement' : undefined}
          isError={Boolean(
            getFormikError(errors, `${categoriesFormikId}partialReimbursementFixed`),
          )}
          errorFeedback={getFormikError(errors, `${categoriesFormikId}partialReimbursementFixed`)}
          onChange={handleOnChange}
          placeholder="Enter Fixed Value"
        />
      )}
    </Grid>
  )
}
export { SpCategoryRow }
