import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/extend-api-fetch'
import { customLogger } from '@extend/client-helpers'
import { actions } from '../actions'
import { getTokenSub } from '../../util/jwt'

type Action = ReturnType<typeof actions.connect>

export function* connect(action: Action): SagaIterator {
  const { source, slot, slotValue, filedBy } = action.payload

  yield put(actions.connectRequest())

  try {
    const response = yield call(client.incredibot.connect, { source, filedBy, slot, slotValue })

    if (isErrorResponse(response)) {
      customLogger.error(`Error calling /connect, sessionId`, {
        source,
        statusCode: response.status,
        timestamp: Date.now(),
      })
      yield put(actions.connectFailure(response.data.message, response.status))
      return
    }

    const sessionId = getTokenSub(response.data.accessToken)
    customLogger.info(`Successfully called /connect`, {
      sessionId,
      source,
      timestamp: Date.now(),
    })

    yield put(actions.connectSuccess(response.data))
  } catch (error) {
    if (error instanceof Error) {
      customLogger.error(`An unexpected error occurred while calling /connect`, {
        error: error.message,
        timestamp: Date.now(),
      })
      yield put(actions.connectFailure(error.message))
    }
  }
}
