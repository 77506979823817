import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type {
  InsuranceClaim,
  Contract,
  Money,
  Order,
  ServiceOrder,
  Plan,
  ClaimNote,
  ClaimPhotosGetResponse,
} from '@helloextend/extend-api-client'
import { TabSection } from '../components/tab-section'
import { ClaimInfoSection } from '../components/claim-info-section'
import { ProductInfoSection } from '../components/product-info-section'
import { CustomerInfoSection } from '../components/customer-info-section'
import type { ClaimDetailsTabs } from '../components/tab-section/types'

interface ClaimDetailsTabProps {
  claim: InsuranceClaim
  contract: Contract
  claimNotes: ClaimNote[]
  serviceOrders?: ServiceOrder[]
  serviceOrder?: ServiceOrder
  entitlements?: { coverageAmountRemaining: Money }
  order?: Order
  plan?: Plan
  activeTab: string
  photoData?: ClaimPhotosGetResponse
  setActiveTab: React.Dispatch<React.SetStateAction<ClaimDetailsTabs>>
}

const ClaimDetailsTab: FC<ClaimDetailsTabProps> = ({
  claim,
  photoData,
  contract,
  serviceOrder,
  serviceOrders,
  entitlements,
  order,
  plan,
  activeTab,
  setActiveTab,
}) => {
  return (
    <ClaimPageWrapper data-cy="claim-details">
      <Title data-cy="claim-details-tab-title">Claim Details</Title>
      <CustomerInfoSection claim={claim} />
      <ClaimInfoSection claim={claim} contract={contract} serviceOrder={serviceOrder} plan={plan} />
      <ProductInfoSection {...{ serviceOrder, order, contract, claim }} />
      <TabSection
        serviceOrders={serviceOrders}
        entitlements={entitlements}
        claim={claim}
        photoData={photoData}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </ClaimPageWrapper>
  )
}

const ClaimPageWrapper = styled.div()
const Title = styled.h2({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '28px',
  marginBottom: 40,
})

export { ClaimDetailsTab }
export type { ClaimDetailsTabProps }
