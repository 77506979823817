import React, { memo, useMemo } from 'react'
import type { FC } from 'react'
import { startCase } from 'lodash'
import styled from '@emotion/styled'
import { Subheading, CaptionLegal, COLOR } from '@extend/zen'
import type { AuditFieldChange, AuditLogTableFields } from './helpers'
import { flattenObject } from './helpers'

interface NestedAuditDeltaProps {
  fields: AuditLogTableFields
  resourceType?: string
}

const handleNestedObjects = (changes: AuditFieldChange) => {
  const result = changes

  for (const [idx, change] of changes.entries()) {
    if (typeof change.newValue === 'object' && !Array.isArray(change.newValue)) {
      const flattenedNewValue = flattenObject(change.newValue)
      const flattenedOldValue = flattenObject(change.oldValue)

      Object.keys(flattenedNewValue).forEach((key) => {
        const newValue = flattenedNewValue[key]
        const oldValue = flattenedOldValue[key]

        result.push({
          field: `${change.field} ${key}`,
          newValue: newValue ?? null,
          oldValue: oldValue ?? null,
        })
      })

      result.splice(idx, 1)
    } else if (Array.isArray(change.newValue)) {
      change.newValue = change?.newValue?.join(', ') ?? null
      change.oldValue = change?.oldValue?.join(', ') ?? null
    }
  }

  return result
}

const NestedAuditDelta: FC<NestedAuditDeltaProps> = ({ fields: { changes }, resourceType }) => {
  const modifiedChanges = useMemo(() => handleNestedObjects(changes), [changes])

  return (
    <Wrapper data-cy={`audit-log-table-nested-audit-delta-${resourceType || 'default'}`}>
      <HeaderRow>
        <HeaderCell>
          <StyledSubheading>Property Changed</StyledSubheading>
        </HeaderCell>
        <HeaderCell>
          <StyledSubheading>Last Value</StyledSubheading>
        </HeaderCell>
        <HeaderCell>
          <StyledSubheading>New Value</StyledSubheading>
        </HeaderCell>
      </HeaderRow>
      {modifiedChanges.map((change, index) => (
        <Row key={index}>
          <Cell>
            <StyledCaptionLegal>{startCase(change.field)?.replace('Id', 'ID')}:</StyledCaptionLegal>
          </Cell>
          <Cell>
            <CaptionLegal>{String(change?.oldValue)}</CaptionLegal>
          </Cell>
          <Cell>
            <CaptionLegal>{String(change?.newValue)}</CaptionLegal>
          </Cell>
        </Row>
      ))}
    </Wrapper>
  )
}

const StyledSubheading = styled(Subheading)({
  fontSize: '0.8rem',
})

const StyledCaptionLegal = styled(CaptionLegal)({
  color: COLOR.NEUTRAL[600],
  fontWeight: 600,
})

const Wrapper = styled.div({
  margin: '1rem',
  display: 'flex',
  flexDirection: 'column',
})

const HeaderRow = styled.div({
  display: 'flex',
  padding: '0.25rem 0',
  gap: '5rem', // Add gap between columns
})

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  padding: '0.25rem 0',
  gap: '5rem',
})

const HeaderCell = styled.div({
  width: '13%',
  padding: '0.25rem',
  textAlign: 'left',
  whiteSpace: 'nowrap',
})

const Cell = styled.div({
  width: '13%',
  padding: '0.25rem',
  textAlign: 'left',
  whiteSpace: 'nowrap',
})

export default memo(NestedAuditDelta)
