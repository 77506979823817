import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/extend-api-fetch'
import { merge } from 'lodash/fp'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.refund>

export default function* refund(action: Action): SagaIterator {
  const { contract, accessToken, commit } = action.payload

  yield put(actions.refundRequest())

  try {
    const response: ResolvedType<typeof client.contracts.refund> = yield call(
      client.contracts.refund,
      accessToken,
      contract.sellerId,
      contract.id,
      commit,
    )

    if (isErrorResponse(response)) {
      yield put(actions.refundFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.refundFailure(`An unknown error occurred`, response.status))
      return
    }

    // FIXME: need to merge the contract in the backend API
    //  response with the cancellation quote to get rid of this merge
    const contractResponse = merge(contract, response.data)

    yield put(actions.refundSuccess(contractResponse))
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.updateFailure(e.message))
    }
  }
}
