import type { Middleware } from 'redux'
import { customLogger } from '@extend/client-helpers'

export const logErrors: Middleware = () => (next) => (action) => {
  const { error, payload, meta } = action

  if (error) {
    customLogger.error(payload, meta)
  }

  return next(action)
}
