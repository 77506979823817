import React from 'react'
import type { FC } from 'react'

import { Button, Add } from '@extend/zen'

type PhotoRequirementAddButtonProps = {
  onClick: (index: number) => void
  index: number
}
const PhotoRequirementAddButton: FC<PhotoRequirementAddButtonProps> = (
  props: PhotoRequirementAddButtonProps,
) => {
  const { onClick, index } = props
  return (
    <Button
      color="blue"
      data-cy={`photo-requirements-add-button-${index}`}
      emphasis="low"
      icon={Add}
      iconPosition="left"
      onClick={() => onClick(index)}
      size="regular"
      text="Add Photo Requirement"
      tooltip=""
      type="button"
    />
  )
}
export { PhotoRequirementAddButton }
