import React from 'react'
import type { CellContext, ColumnDefs } from '@extend/zen'
import { format } from '@extend/zen'
import { Link } from 'react-router-dom'
import type { PlanTerms, PlanTermsVersion } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'

const findLatestVersion = (versions: PlanTermsVersion[]): PlanTermsVersion => {
  const versionsCopy = [...versions]
  return versionsCopy.sort((a, b) => Number(b.version) - Number(a.version))?.[0]
}

const tableColumns: ColumnDefs<PlanTerms> = [
  {
    id: 'termsId',
    label: 'Terms ID',
    isAlwaysPinned: true,
    search: 'explicit',
    isSortable: true,
    renderCell: (data: CellContext<PlanTerms, string>) => {
      const { versions } = data.row.original
      return (
        <Link
          to={`/admin/terms/${data.getValue()}/versions/${findLatestVersion(versions).version}`}
        >
          {data.getValue()}{' '}
        </Link>
      )
    },
  },
  {
    id: 'description',
    label: 'Description',
    search: 'explicit',
    isSortable: true,
  },
  {
    id: 'versions',
    label: 'Version',
    renderCell: (data: CellContext<PlanTerms, string>) => {
      const { versions } = data.row.original
      return <span>{findLatestVersion(versions).version}</span>
    },
  },
  {
    id: 'updatedAt',
    label: 'Last Updated',
    isSortable: true,
    renderCell: (data: CellContext<PlanTerms, string>) => {
      const { versions } = data.row.original
      const latestVersionUpdatedAt = findLatestVersion(versions).updatedAt
      return <span>{format(latestVersionUpdatedAt, 'MMM DD YYYY')}</span>
    },
  },
]

export { tableColumns, findLatestVersion }
