import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@extend/zen'

type AnalyticsIconProps = {
  fill?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@extend/zen'`
 */
const AnalyticsIcon: FC<AnalyticsIconProps> = ({ fill = COLOR.NEUTRAL[600] }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
    <path
      d="M1.959 12H.39a.397.397 0 01-.276-.11.37.37 0 01-.114-.267V7.296c0-.1.041-.196.114-.267a.397.397 0 01.276-.11h1.569c.103 0 .203.04.276.11a.37.37 0 01.114.267v4.327a.37.37 0 01-.114.267.397.397 0 01-.276.11zM.78 11.246h.79V7.673H.78v3.573zM5.176 12H3.607a.397.397 0 01-.275-.11.37.37 0 01-.115-.267V4.677c0-.1.041-.196.115-.267a.397.397 0 01.275-.11h1.57c.103 0 .202.04.275.11a.37.37 0 01.114.267v6.949a.37.37 0 01-.115.264.397.397 0 01-.275.11zm-1.179-.754h.79V5.054h-.79v6.192zM8.393 12H6.825a.397.397 0 01-.276-.11.37.37 0 01-.114-.267V6.398c0-.1.04-.195.114-.266a.397.397 0 01.276-.11h1.568c.103 0 .202.04.275.11a.37.37 0 01.115.266v5.225a.37.37 0 01-.115.267.397.397 0 01-.275.11zm-1.178-.754h.788v-4.47h-.788v4.47zM11.61 12h-1.568a.397.397 0 01-.276-.11.37.37 0 01-.114-.267V3.797c0-.1.041-.195.114-.266a.397.397 0 01.276-.11h1.568c.103 0 .203.04.276.11a.37.37 0 01.114.266v7.821a.367.367 0 01-.112.27.39.39 0 01-.278.112zm-1.178-.754h.788V4.174h-.788v7.072zM1.323 4.242a.4.4 0 01-.224-.065.38.38 0 01-.145-.178.365.365 0 01.11-.419L4.183.959a.397.397 0 01.45-.043l2.88 1.592L10.422.086a.4.4 0 01.542.045.369.369 0 01-.033.525l-3.12 2.597a.397.397 0 01-.448.042L4.489 1.704l-2.91 2.445a.398.398 0 01-.256.093z"
      fill={fill}
    />
  </svg>
)

export { AnalyticsIcon }
