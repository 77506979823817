// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0_webpack-cli@4.8.0_webpack-bundle-analyzer@4.4.2_webpack-dev-s_vrefs4evkprez4c3y7xwx6ru5q/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ebdozXmYvEbL99JryaF6B {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 8px;\n}\n\n._3s0Ztpb2o2rW_Ubbd7QCJm {\n    padding-left: 4px;\n}\n\n._3C_hOs6mpwjgKMXxvXIchI {\n    display: flex;\n    gap: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "_1ebdozXmYvEbL99JryaF6B",
	"center": "_3s0Ztpb2o2rW_Ubbd7QCJm",
	"wrapper": "_3C_hOs6mpwjgKMXxvXIchI"
};
module.exports = exports;
