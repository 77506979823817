import React, { useMemo } from 'react'
import type { FC } from 'react'
import { Button, Grid, TextArea, Input, ButtonGroup, InputType } from '@extend/zen'
import { useFormik } from 'formik'
import { useGetAllCharitiesListQuery } from '@helloextend/extend-api-rtk-query'
import { toValidPhoneNumber } from '../../../../../../../../utils/form-utils'
import type { SelectedCharity } from '../charitable-donations'
import { schema } from './charitable-donations-form-schema'
import type { CharitySchema } from './charitable-donations-form-schema'

type CharitableDonationsFormProps = {
  onSubmit: (formValues: CharitySchema) => void
  onClose: () => void
  charity?: SelectedCharity
  isLoading: boolean
}

export const CharitableDonationsForm: FC<CharitableDonationsFormProps> = ({
  onSubmit,
  onClose,
  charity,
  isLoading,
}) => {
  const { data: charitiesData } = useGetAllCharitiesListQuery()

  const existingCharityNames = useMemo(
    () => (charitiesData || []).map((char) => char.name),
    [charitiesData],
  )

  const { values, handleChange, isValid, errors, handleSubmit } = useFormik<CharitySchema>({
    initialValues: {
      name: charity?.name || '',
      contactEmail: charity?.contactEmail || '',
      contactPhone: charity?.contactPhone || '',
      contactName: charity?.contactName || '',
      address: charity?.address || '',
    },
    validationSchema: schema(existingCharityNames, charity?.name),
    validateOnBlur: true,
    onSubmit: (formValues: CharitySchema) => {
      onSubmit(formValues)
    },
  })

  return (
    <Grid spacing={3}>
      <h4>CONTACT INFORMATION</h4>
      <Grid columns={2} spacing={3}>
        <Input
          placeholder="Enter a unique Charity Name"
          onChange={handleChange}
          label="Charity Name (Unique ID)"
          isError={!!errors.name}
          errorFeedback={errors.name}
          value={values.name}
          id="name"
          data-cy="charity-name"
        />
        <Input
          onChange={handleChange}
          value={values.contactName}
          isError={!!errors.contactName}
          errorFeedback={errors.contactName}
          label="Contact Name"
          id="contactName"
          data-cy="contact-name"
        />
        <Input
          type={InputType.email}
          value={values.contactEmail}
          onChange={handleChange}
          isError={!!errors.contactEmail}
          errorFeedback={errors.contactEmail}
          label="Email Address"
          id="contactEmail"
          data-cy="contact-email"
        />
        <Input
          type={InputType.tel}
          value={values.contactPhone}
          onChange={(e) => {
            e.target.value = toValidPhoneNumber(e.target.value)
            handleChange(e)
          }}
          isError={!!errors.contactPhone}
          errorFeedback={errors.contactPhone}
          label="Phone Number"
          id="contactPhone"
          data-cy="contact-phone"
        />
      </Grid>
      <TextArea
        onChange={handleChange}
        value={values.address}
        isError={!!errors.address}
        errorFeedback={errors.address}
        label="Address Line 1"
        id="address"
        data-cy="address"
      />
      <ButtonGroup justify="end">
        <Button emphasis="medium" text="Cancel" data-cy="cancel-button" onClick={onClose} />
        <Button
          text="Confirm"
          isProcessing={isLoading}
          onClick={() => handleSubmit()}
          data-cy="save-button"
          isDisabled={!isValid}
        />
      </ButtonGroup>
    </Grid>
  )
}
