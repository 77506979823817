import type { FC, ChangeEvent } from 'react'
import React from 'react'
import type { Store } from '@helloextend/extend-api-client'
import { Grid, Information, InformationSize, Input, Select, Checkbox, Switch } from '@extend/zen'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import type { Values } from './schema'
import { schema } from './schema'
import { SaveChangesButtonGroup } from '../save-changes-button-group'

type BasicInfoFormProps = {
  store: Store
}

const BasicInfoForm: FC<BasicInfoFormProps> = ({ store }) => {
  const { values, errors, dirty, isValid, handleChange, handleBlur, handleReset, setFieldValue } =
    useFormik<Values>({
      initialValues: {
        name: store.name || '',
        secondaryName: store.secondaryName || '',
        id: store.id || '',
        accountId: store.accountId || '',
        domain: store.domain || '',
        currencyCode: store.currencyCode || 'USD',
        adminDealerNumber: store.adminDealerNumber || '',
        adminLocationNumber: store.adminLocationNumber || '',
        adminManufacturer: store.adminManufacturer || '',
        syncOrderProducts: Boolean(store.syncOrderProducts),
        isTestStore: Boolean(store.isTestStore),
        unmappedProductContracts: Boolean(store.unmappedProductContracts),
      },
      onSubmit: () => {},
      validationSchema: schema,
      enableReinitialize: true,
    })
  const [updateStore, { isLoading }] = useUpdateStoreMutation()

  const toggleCheckbox = (e: ChangeEvent<HTMLInputElement>): void => {
    const fieldName = e.currentTarget.name as keyof Values
    setFieldValue(fieldName, !values[fieldName])
  }

  const handletoggleSwitch = (e: ChangeEvent<HTMLInputElement>): void => {
    const fieldName = e.currentTarget.id as keyof Values
    setFieldValue(fieldName, !values[fieldName])
  }

  const { toastCompleted, toastError } = useStandardToast()

  const handleUpdateStore = async (): Promise<void> => {
    await updateStore({
      storeId: store.id,
      data: {
        name: values.name,
        secondaryName: values.secondaryName,
        domain: values.domain,
        currencyCode: values.currencyCode,
        adminDealerNumber: values.adminDealerNumber,
        adminLocationNumber: values.adminLocationNumber,
        adminManufacturer: values.adminManufacturer,
        syncOrderProducts: values.syncOrderProducts,
        isTestStore: values.isTestStore,
        unmappedProductContracts: values.unmappedProductContracts,
      },
      version: 'latest',
    }).unwrap()
    try {
      toastCompleted('Basic Settings have been successfully updated.')
    } catch {
      toastError('Basic Settings could not be updated. Please try again.')
    }
  }

  return (
    <>
      <FormContainer>
        <Grid columns={3} spacing={4}>
          <StoreNameGridContainer>
            <NameInputContainer>
              <Input
                id="name"
                label="Store Name"
                value={values.name || ''}
                placeholder="Enter store name"
                isError={Boolean(errors.name)}
                errorFeedback={errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </NameInputContainer>
            <CheckboxContainer>
              <Checkbox
                name="isTestStore"
                label="Test Store"
                checked={Boolean(values.isTestStore)}
                onChange={toggleCheckbox}
                disabled={isLoading}
                data-cy="is-test-store"
              />
              <Information size={InformationSize.small} padding="0px 12px">
                <p>Test stores are not included in any invoicing or analysis.</p>
              </Information>
            </CheckboxContainer>
          </StoreNameGridContainer>
          <Input
            id="secondaryName"
            label="Alternate Name"
            value={values.secondaryName || ''}
            placeholder="Enter alternate name"
            isError={Boolean(errors.secondaryName)}
            errorFeedback={errors.secondaryName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="id"
            label="Store ID"
            value={values.id || ''}
            placeholder="Enter store ID"
            isError={Boolean(errors.id)}
            errorFeedback={errors.id}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled
          />
        </Grid>
        <Grid columns={4} spacing={4}>
          <Input
            id="accountId"
            label="Account ID"
            value={values.accountId || ''}
            placeholder="Enter account ID"
            isError={Boolean(errors.accountId)}
            errorFeedback={errors.accountId}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled
          />
          <Input
            id="domain"
            label="Store URL"
            value={values.domain || ''}
            placeholder="Enter store URL"
            isError={Boolean(errors.domain)}
            errorFeedback={errors.domain}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="adminDealerNumber"
            label="PCMI Dealer Number"
            value={values.adminDealerNumber || ''}
            placeholder="Enter PCMI Dealer Number"
            isError={Boolean(errors.adminDealerNumber)}
            errorFeedback={errors.adminDealerNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="adminLocationNumber"
            label="PCMI Seller Location"
            value={values.adminLocationNumber || ''}
            placeholder="Enter PCMI Seller Location"
            isError={Boolean(errors.adminLocationNumber)}
            errorFeedback={errors.adminLocationNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="adminManufacturer"
            label="PCMI Manufacturer"
            value={values.adminManufacturer || ''}
            placeholder="Enter PCMI Manufacturer"
            isError={Boolean(errors.adminManufacturer)}
            errorFeedback={errors.adminManufacturer}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Select
            label="Currency"
            id="currencyCode"
            value={values.currencyCode}
            onChange={handleChange}
          >
            <option value="USD" key="USD">
              USD
            </option>
            <option value="CAD" key="CAD">
              CAD
            </option>
          </Select>
          <CheckboxContainer>
            <Checkbox
              name="syncOrderProducts"
              label="Sync Order Products"
              checked={Boolean(values.syncOrderProducts)}
              onChange={toggleCheckbox}
              disabled={isLoading}
              data-cy="sync-order-products"
            />

            <Information size={InformationSize.small} padding="0px 12px">
              <p>
                When an order is created containing a product that does not currently exist within
                the merchant&apos;s Extend catalog, that product will automatically be created and
                available for warranty mapping
              </p>
            </Information>
          </CheckboxContainer>
          <CheckboxContainer>
            <Switch
              label="Unmapped Product Contracts"
              id="unmappedProductContracts"
              isOn={!!values.unmappedProductContracts}
              onChange={handletoggleSwitch}
              data-cy="unmapped-product-contracts-toggle"
              isDisabled={isLoading}
            />
            <Information size={InformationSize.small} padding="0px 12px">
              <p>
                Enables Extend to create a contract for a product that does not exist within a
                merchant&apos;s Extend catalog
              </p>
            </Information>
          </CheckboxContainer>
        </Grid>
      </FormContainer>
      {dirty && (
        <SaveChangesButtonGroup
          id="basic-info-form-buttons"
          handleSave={handleUpdateStore}
          handleCancel={handleReset}
          isSaveDisabled={!isValid}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

const FormContainer = styled.div({
  marginBottom: 24,
})

const StoreNameGridContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '2fr max-content',
  alignItems: 'end',
  gap: '20px',
  marginBottom: '16px',
})

const CheckboxContainer = styled.div({
  display: 'flex',
  alignItems: 'end',
  marginBottom: '3px',
})

const NameInputContainer = styled.div({})

export { BasicInfoForm }
