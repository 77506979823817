import type { FC, SyntheticEvent, ReactNode, ReactElement, ChangeEvent } from 'react'
import React, { useEffect, useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { COLOR, getBorderColor } from '@extend/zen'
import { DownCaret } from '../icons/down-caret'

type SelectProps = {
  value: string
  onChange: (e: SyntheticEvent) => void
  placeholder?: string
  inline?: boolean
  label?: string
  error?: boolean
  errorMessage?: string
  search?: boolean
  isDisabled?: boolean
  onSearchChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

/**
 * @deprecated Use Zen AdvancedSelect component instead: `import { AdvancedSelect } from '@extend/zen'`
 */
const Select: FC<SelectProps> = ({
  value,
  children,
  onChange,
  placeholder,
  label,
  inline,
  search,
  isDisabled,
  error,
  errorMessage = '',
  onSearchChange,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const handleClick = (): void => {
    document.addEventListener('click', hideMenu)
    setIsMenuVisible(!isDisabled)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!onSearchChange) return
    onSearchChange(event)
    document.addEventListener('click', hideMenu)
    setIsMenuVisible(true)
  }

  const hideMenu = useCallback((): void => {
    document.removeEventListener('click', hideMenu)
    setIsMenuVisible(false)
  }, [])

  useEffect(() => {
    return () => document.removeEventListener('click', hideMenu)
  }, [hideMenu])

  const childrenArray: ReactNode[] = React.Children.toArray(children)

  const handleItemClick =
    (child: ReactElement) =>
    (event: SyntheticEvent<Element>): void => {
      if (child.props.onClick) {
        child.props.onClick(event)
      }

      onChange(event)
    }

  const items = childrenArray.map((child) => {
    if (
      !React.isValidElement<{
        selected: boolean
        isSelected: boolean
        onClick: (event: SyntheticEvent<Element>) => void
        role: string
        value: string
        label: string
      }>(child)
    ) {
      return null
    }
    const selected = child.props.value === value
    return React.cloneElement(child, {
      selected,
      isSelected: selected,
      onClick: handleItemClick(child),
      role: 'option',
    })
  })

  const selectedValue = items.find((item) => item?.props.value === value)

  return (
    <>
      {label && <Label>{label}</Label>}
      <SelectWrapper active={isMenuVisible} inline={inline}>
        {search ? (
          <SearchInput onChange={handleSearchChange} onClick={handleClick} value={value} />
        ) : (
          <SelectButton
            error={Boolean(error)}
            disabled={Boolean(isDisabled)}
            type="button"
            onClick={handleClick}
            inline={Boolean(inline)}
            data-cy="select-button"
          >
            {selectedValue?.props.label || placeholder}
          </SelectButton>
        )}
        <Menu hidden={!isMenuVisible} data-cy="select-menu">
          {items}
        </Menu>
        <DownCaret />
      </SelectWrapper>
      {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  )
}

const Label = styled.div({
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 800,
  color: COLOR.NEUTRAL[1000],
  paddingBottom: 4,
})

const SelectButton = styled.button<{ error: boolean; inline: boolean }>(({ error, inline }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: getBorderColor(error),
  background: COLOR.WHITE,
  color: COLOR.NEUTRAL[1000],
  boxSizing: 'border-box',
  display: 'block',
  borderRadius: 4,
  borderTopRightRadius: inline ? 0 : 4,
  borderBottomRightRadius: inline ? 0 : 4,
  cursor: 'pointer',
  fontFamily: 'Nunito Sans, sans-serif',
  fontSize: 16,
  position: 'relative',
  width: '100%',
  height: 40,
  textAlign: 'start',
  paddingLeft: 16,
  paddingRight: 20,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  ':hover': {
    borderColor: COLOR.NEUTRAL[400],
  },
  ':focus': {
    borderColor: COLOR.BLUESKY,
    boxShadow: `0 0 0 1px inset ${COLOR.BLUE[800]}`,
    outline: 'none',
  },
  ':disabled': {
    background: COLOR.NEUTRAL[100],
    color: COLOR.NEUTRAL[600],
    '&:hover': {
      cursor: 'default',
    },
  },
}))

const Menu = styled.div({
  backgroundColor: COLOR.WHITE,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  position: 'absolute',
  width: '100%',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)',
  zIndex: 1,
  maxHeight: 300,
  overflowY: 'auto',
})

const SelectWrapper = styled.div<{
  active?: boolean
  inline?: boolean
}>(({ active, inline }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  borderRadius: 4,
  borderTopRightRadius: inline ? 0 : 4,
  borderBottomRightRadius: inline ? 0 : 4,
  '& > svg': {
    position: 'absolute',
    right: 12,
    top: '50%',
    transform: active ? 'translateY(-50%) rotate(-180deg)' : 'translateY(-50%)',
    transition: '250ms transform ease-in-out',
    pointerEvents: 'none',
  },
}))

const ErrorMessage = styled.div({
  fontFamily: 'Nunito Sans, sans-serif',
  color: COLOR.RED[700],
  fontSize: 12,
  fontWeight: 700,
  marginTop: 4,
})

const SearchInput = styled.input({
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  fontFamily: "'Nunito Sans', Helvetica, sans-serif",
  fontSize: 14,
  lineHeight: '38px',
  padding: '0 12px',
  width: '100%',
  margin: 0,
  '&::placeholder': {
    color: COLOR.NEUTRAL[400],
  },
  '&:hover': {
    borderColor: COLOR.NEUTRAL[300],
  },
  '&:focus': {
    border: `1px solid ${COLOR.BLUESKY}`,
    boxShadow: `0 0 0 1px inset ${COLOR.BLUESKY}`,
    outline: 'none',
  },
})

export type { SelectProps }
export { Select }
