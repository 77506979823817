import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import type {
  Account,
  AccountsListResponse,
  CreateLegacyAccountRequest,
  CreateAccountResponse,
  GetAccountRequest,
  UpdateLegacyAccountRequest,
  CreateEnterpriseAccountRequest,
  UpdateEnterpriseAccountRequest,
  AccountEnterprise,
  DeleteAccountRequest,
  AccountsListQueryStringOptions,
  GetAccountOrganizationsRequest,
  AccountOrganizationsListResponse,
  OrganizationsCreateRequest,
  OrganizationsCreateResponse,
  CreateAccountProvisionRequest,
  AccountLegacy,
} from './types'

export const accountsApi = createApi({
  baseQuery,
  reducerPath: 'accounts',
  tagTypes: ['accounts'],
  endpoints: (build) => ({
    getAccountsList: build.query<AccountsListResponse, AccountsListQueryStringOptions>({
      query: (qs) => ({
        url: `accounts`,
        params: qs,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=latest;`,
        },
      }),
      transformResponse: (accountsList: AccountsListResponse, _meta, arg) => {
        if (arg.name?.trimStart() === '') return { items: [], cursor: undefined }
        return accountsList
      },
    }),
    getAccount: build.query<Account, GetAccountRequest>({
      query: ({ accountId, version = 'latest' }) => ({
        url: `accounts/${accountId}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, { accountId }) => [{ type: 'accounts', id: accountId }],
    }),
    getAccountOrganizationsList: build.query<
      AccountOrganizationsListResponse,
      GetAccountOrganizationsRequest
    >({
      query: ({ accountId, version = 'latest', limit, cursor }) => ({
        url: `/accounts/${accountId}/organizations`,
        params: {
          cursor,
          limit,
        },
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
      }),
    }),
    deleteAccount: build.query<void, DeleteAccountRequest>({
      query: ({ accountId, version = 'latest' }) => ({
        url: `accounts/${accountId}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'DELETE',
      }),
    }),
    createAccountLegacy: build.mutation<CreateAccountResponse, CreateLegacyAccountRequest>({
      query: ({ body, version = 'latest' }) => ({
        url: `accounts/legacy`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
    }),
    updateAccountLegacy: build.mutation<CreateAccountResponse, UpdateLegacyAccountRequest>({
      query: ({ body, version = 'latest', accountId }) => ({
        url: `accounts/legacy/${accountId}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, _err, { accountId }) => [{ type: 'accounts', id: accountId }],
    }),
    createAccountEnterprise: build.mutation<CreateAccountResponse, CreateEnterpriseAccountRequest>({
      query: ({ body, version = 'latest' }) => ({
        url: `accounts`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
    }),
    updateAccountEnterprise: build.mutation<AccountEnterprise, UpdateEnterpriseAccountRequest>({
      query: ({ body, version = 'latest', accountId }) => ({
        url: `accounts/${accountId}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'PUT',
        body,
      }),
    }),
    createOrganization: build.mutation<OrganizationsCreateResponse, OrganizationsCreateRequest>({
      query: ({ body, version = 'latest', accountId }) => ({
        url: `accounts/${accountId}/organizations`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
    }),
    createAccountProvision: build.mutation<void, CreateAccountProvisionRequest>({
      query: ({ body, version = 'latest' }) => ({
        url: `accounts/provision`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
    }),
    createLegacyAccountProvision: build.mutation<AccountLegacy, CreateAccountProvisionRequest>({
      query: ({ body, version = 'latest' }) => ({
        url: `accounts/legacy/provision`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${version};`,
        },
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetAccountsListQuery,
  useLazyGetAccountsListQuery,
  useGetAccountQuery,
  useDeleteAccountQuery,
  useCreateAccountLegacyMutation,
  useUpdateAccountLegacyMutation,
  useCreateAccountEnterpriseMutation,
  useUpdateAccountEnterpriseMutation,
  useGetAccountOrganizationsListQuery,
  useCreateOrganizationMutation,
  useCreateAccountProvisionMutation,
  useCreateLegacyAccountProvisionMutation,
} = accountsApi
