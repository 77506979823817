import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button, COLOR, ModalController } from '@extend/zen'
import { useToggle } from '@helloextend/client-hooks'
import { PromotionsDataTable } from './promotions-data-table/promotions-data-table'
import { CreatePromotionModal } from './components/create-promotion-modal'

interface PromotionSettingsProps {
  storeId: string
}

const PromotionSettings: FC<PromotionSettingsProps> = ({ storeId }) => {
  const [isModalOpen, { on, off }] = useToggle(false)

  return (
    <>
      <Header>
        <Title>Promotions</Title>
        <Button
          data-cy="create-promotion-button"
          text="Create Promotion"
          onClick={on}
          emphasis="medium"
        />
      </Header>
      <PromotionsDataTable storeId={storeId} />
      <ModalController isOpen={isModalOpen}>
        <CreatePromotionModal isVisible={isModalOpen} onCancel={off} />
      </ModalController>
    </>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  margin: 0,
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

export { PromotionSettings }
