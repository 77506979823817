import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Breadcrumbs } from '@extend/zen'
import { Formik } from 'formik'
import { insuranceProgramFormInitialValues } from '../../../utils/insurance-program-property-mapper'
import { InsuranceProgramForm } from '../insurance-program/insurance-program-form'
import { schema } from '../insurance-program/schema'

const InsuranceProgramsCreate: FC = () => {
  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs
          crumbs={[
            { text: 'Insurance Programs', to: '/admin/insurance-programs' },
            { text: 'Create Insurance Program' },
          ]}
        />
      </BreadcrumbsWrapper>
      {/* ReactContext for useFormikContext hook */}
      <Formik
        initialValues={insuranceProgramFormInitialValues()}
        validationSchema={schema}
        onSubmit={() => {}}
      >
        <InsuranceProgramForm isCreateForm />
      </Formik>
    </>
  )
}

const BreadcrumbsWrapper = styled.div({
  margin: '-8px 0 16px',
})

export { InsuranceProgramsCreate }
