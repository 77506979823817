import * as Yup from 'yup'

const revenueModelOptions = [
  {
    display: 'Net Revenue Share',
    value: 'net_revenue_share',
  },
  {
    display: 'Gross Revenue Share',
    value: 'gross_revenue_share',
  },
  {
    display: 'Wholesale',
    value: 'wholesale',
  },
]

const partialReimbursementOptions = [
  {
    display: 'Fixed Price',
    value: 'fixed',
  },
  {
    display: 'Percentage',
    value: 'percentage',
  },
]

const schema = Yup.object()
  .shape({
    merchantEwRevenueAgreement: Yup.string()
      .required('Revenue model is required')
      .default(null)
      .nullable(),
    merchantEwRevenueSharePercentage: Yup.number()
      .default(undefined)
      .when('merchantEwRevenueAgreement', {
        is: (value: string) => value === 'wholesale',
        then: () => Yup.number().notRequired(),
        otherwise: () => Yup.number()
          .required('Merchant revenue share is required')
          .min(0, 'Merchant revenue share must be greater than 0')
          .max(100, 'Merchant revenue share must be equal or less than 100'),
      }),
    partialReimbursementValue: Yup.number()
      .default(undefined)
      .when(['partialReimbursementEnabled', 'partialReimbursementSelection'], {
        is: (enabled: boolean, selection: string) => enabled && selection === 'percentage',
        then: () => Yup.number()
          .required('Partial reimbursement percentage is required')
          .min(0, 'Partial reimbursement percentage must be greater than 0')
          .max(100, 'Partial reimbursement percentage must be equal or less than 100'),
      })
      .when(['partialReimbursementEnabled', 'partialReimbursementSelection'], {
        is: (enabled: boolean, selection: string) => enabled && selection === 'fixed',
        then: () => Yup.number()
          .required('Fixed Partial reimbursement is required')
          .min(0, 'Partial reimbursement fixed must be greater than 0'),
      })
      .nullable(),
    partialReimbursementSelection: Yup.string().default(null).nullable(),
    extendCut: Yup.number()
      .min(0, 'Custom Extend cut must be greater than 0')
      .max(100, 'Custom Extend cut must be equal or less than 100')
      .when('isExtendCutOn', {
        is: true,
        then: () => Yup.number().required('Extend cut is required'),
      })
      .nullable(),
    partialReimbursementEnabled: Yup.boolean(),
    isExtendCutOn: Yup.boolean(),
    locationFilteringEnabled: Yup.boolean(),
    SendSMSTermsEnabled: Yup.boolean(),
  })
  .defined()

const partialReimbursementSchema = Yup.object()
  .shape({
    partialReimbursementPercentage: Yup.number()
      .required('Partial reimbursement percentage is required')
      .min(0, 'Partial reimbursement percentage must be greater than 0')
      .max(100, 'Partial reimbursement percentage must be equal or less than 100'),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export type { Values }
export { schema, revenueModelOptions, partialReimbursementSchema, partialReimbursementOptions }
