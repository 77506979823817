import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, ModalController, Modal } from '@extend/zen'

type DeletePhotoRequirementModalProps = {
  isVisible: boolean
  onDelete: () => void
  onCancel: () => void
}

const DeletePhotoRequirementModal: FC<DeletePhotoRequirementModalProps> = ({
  isVisible,
  onDelete,
  onCancel,
}) => (
  <ModalController isOpen={isVisible}>
    <Modal
      data-cy="delete-photo-requirement-modal"
      heading="Delete Photo Requirement"
      onDismissRequest={onCancel}
      primaryButtonProps={{
        text: 'Delete',
        onClick: onDelete,
        color: 'red',
        'data-cy': 'delete-photo-requirement-modal:delete-button',
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        onClick: onCancel,
        'data-cy': 'delete-photo-requirement-modal:cancel-button',
      }}
    >
      <Description>
        Are you sure you want to delete this photo requirement? All claims that have been filed with
        the previous version will maintain their data. Once this change has been saved, it cannot be
        undone.
      </Description>
    </Modal>
  </ModalController>
)

const Description = styled.p({
  color: COLOR.NEUTRAL[800],
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  whiteSpace: 'pre-line',
})

export { DeletePhotoRequirementModal }
