import {
  useCreateContractRefundInfoMutation,
  useUpdateContractRefundInfoMutation,
} from '@helloextend/extend-api-rtk-query/src/plan-terms/plan-terms-api'
import type { PlanTermContractRefundInfo } from '@helloextend/extend-api-rtk-query/src/plan-terms/types'
import {
  Add,
  AdvancedSelect,
  Button,
  COLOR,
  CaptionLegal,
  DataProperty,
  DataPropertyType,
  Grid,
  Input,
  InputType,
  Modal,
  ModalController,
  Section,
  TextArea,
  ToastColor,
  ToastDuration,
  useToaster,
  format,
} from '@extend/zen'
import { useFormik } from 'formik'
import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import type { ContractRefundInfoFormValues } from './contract-refund-info-schema'
import { GetFormPropsAndLabels, getInitialValues, schema } from './contract-refund-info-schema'

type ContractRefundInfoProps = {
  termsId: string
  version: number
  contractRefundInfo?: Omit<PlanTermContractRefundInfo, 'termsId' | 'version'>
}

const ContractRefundInfo: FC<ContractRefundInfoProps> = ({
  termsId,
  version,
  contractRefundInfo,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)

  const [updateReason, setUpdateReason] = useState('')

  const { toast } = useToaster()

  const [createContractRefundInfo, { isLoading: creatingContractRefundInfo }] =
    useCreateContractRefundInfoMutation()

  const [updateContractRefundInfo, { isLoading: updatingContractRefundInfo }] =
    useUpdateContractRefundInfoMutation()

  const { values, handleChange, handleBlur, errors, touched, handleSubmit, resetForm, dirty } =
    useFormik<ContractRefundInfoFormValues>({
      initialValues: getInitialValues(contractRefundInfo),
      validationSchema: schema,
      validateOnMount: false,
      validateOnBlur: true,
      onSubmit: async (values) => {
        if (contractRefundInfo) {
          if (updateReason === '') {
            setConfirmationModal(true)
            return
          }

          const res = await updateContractRefundInfo({
            termsId,
            version,
            contractRefundInfo: { ...values, updateReason },
          })

          if ('error' in res) {
            toast({
              message: 'Failed to update Contract Refund Information',
              toastColor: ToastColor.red,
              toastDuration: ToastDuration.short,
            })
            return
          }

          toast({
            message: 'Contract Refund Information updated successfully',
            toastColor: ToastColor.green,
            toastDuration: ToastDuration.short,
          })
          setConfirmationModal(false)
        } else {
          const res = await createContractRefundInfo({
            termsId,
            version,
            contractRefundInfo: values,
          })

          if ('error' in res) {
            toast({
              message: 'Failed to create Contract Refund Information',
              toastColor: ToastColor.red,
              toastDuration: ToastDuration.short,
            })
            return
          }

          toast({
            message: 'Contract Refund Information created successfully',
            toastColor: ToastColor.green,
            toastDuration: ToastDuration.short,
          })
        }

        setEditMode(false)
      },
    })

  const formProps = useMemo(() => GetFormPropsAndLabels(), [])

  if (!contractRefundInfo && !editMode) {
    return (
      <Button
        icon={Add}
        type="button"
        text="Add Contract Refund Info"
        emphasis="medium"
        onClick={() => setEditMode(true)}
        data-cy="add-contract-refund-info-button"
      />
    )
  }

  return (
    <>
      <Section
        heading="Contract Refund Information"
        buttons={
          editMode
            ? [
                {
                  text: 'Save',
                  emphasis: 'high',
                  onClick: () => handleSubmit(),
                  isProcessing:
                    (creatingContractRefundInfo || updatingContractRefundInfo) &&
                    !confirmationModal,
                  isDisabled: !dirty || Object.keys(errors).length > 0,
                  'data-cy': 'save-contract-refund-info-button',
                },
                {
                  text: 'Cancel',
                  emphasis: 'medium',
                  onClick: () => {
                    resetForm()
                    setEditMode(false)
                  },
                },
              ]
            : [
                {
                  text: 'Edit',
                  emphasis: 'medium',
                  onClick: () => setEditMode(true),
                  'data-cy': 'edit-contract-refund-info-button',
                },
              ]
        }
      >
        <>
          <Grid columns={3} spacing={2}>
            {editMode ? (
              <>
                <Input
                  id={formProps.fullRefundCancellationDays.property}
                  value={values.fullRefundCancellationDays?.toString() ?? '0'}
                  type={InputType.number}
                  label={formProps.fullRefundCancellationDays.label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isDisabled={creatingContractRefundInfo || updatingContractRefundInfo}
                  isError={
                    !!errors.fullRefundCancellationDays && touched.fullRefundCancellationDays
                  }
                  errorFeedback={errors.fullRefundCancellationDays}
                  data-cy={formProps.fullRefundCancellationDays.property}
                />
                <AdvancedSelect
                  id={formProps.refundAmountProrated.property}
                  value={values.refundAmountProrated ? 'Yes' : 'No'}
                  label={formProps.refundAmountProrated.label}
                  options={[
                    { display: 'Yes', value: 'true' },
                    { display: 'No', value: 'false' },
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isDisabled={creatingContractRefundInfo || updatingContractRefundInfo}
                  isError={!!errors.refundAmountProrated && touched.refundAmountProrated}
                  errorFeedback={errors.refundAmountProrated}
                  data-cy={formProps.refundAmountProrated.property}
                  multiple={false}
                />
                <AdvancedSelect
                  id={formProps.deductClaims.property}
                  value={values.deductClaims ? 'Yes' : 'No'}
                  label={formProps.deductClaims.label}
                  options={[
                    { display: 'Yes', value: 'true' },
                    { display: 'No', value: 'false' },
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isDisabled={creatingContractRefundInfo || updatingContractRefundInfo}
                  isError={!!errors.deductClaims && touched.deductClaims}
                  errorFeedback={errors.deductClaims}
                  data-cy={formProps.deductClaims.property}
                  multiple={false}
                />
                <AdvancedSelect
                  id={formProps.deductAdminFee.property}
                  value={values.deductAdminFee ? 'Yes' : 'No'}
                  label={formProps.deductAdminFee.label}
                  options={[
                    { display: 'Yes', value: 'true' },
                    { display: 'No', value: 'false' },
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isDisabled={creatingContractRefundInfo || updatingContractRefundInfo}
                  isError={!!errors.deductAdminFee && touched.deductAdminFee}
                  errorFeedback={errors.deductAdminFee}
                  data-cy={formProps.deductAdminFee.property}
                  multiple={false}
                />
                <Input
                  id={formProps.adminFeeAmount.property}
                  value={values.adminFeeAmount?.toString() ?? '0'}
                  type={InputType.number}
                  label={formProps.adminFeeAmount.label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isDisabled={creatingContractRefundInfo || updatingContractRefundInfo}
                  isError={!!errors.adminFeeAmount && touched.adminFeeAmount}
                  errorFeedback={errors.adminFeeAmount}
                  data-cy={formProps.adminFeeAmount.property}
                />
              </>
            ) : (
              <>
                <DataProperty
                  label={formProps.fullRefundCancellationDays.label}
                  type={DataPropertyType.number}
                  value={values.fullRefundCancellationDays}
                  data-cy={formProps.fullRefundCancellationDays.property}
                />
                <DataProperty
                  label={formProps.refundAmountProrated.label}
                  type={DataPropertyType.string}
                  value={values.refundAmountProrated ? 'Yes' : 'No'}
                  data-cy={formProps.refundAmountProrated.property}
                />
                <DataProperty
                  label={formProps.deductClaims.label}
                  type={DataPropertyType.string}
                  value={values.deductClaims ? 'Yes' : 'No'}
                  data-cy={formProps.deductClaims.property}
                />
                <DataProperty
                  label={formProps.deductAdminFee.label}
                  type={DataPropertyType.string}
                  value={values.deductAdminFee ? 'Yes' : 'No'}
                  data-cy={formProps.deductAdminFee.property}
                />
                <DataProperty
                  label={formProps.adminFeeAmount.label}
                  type={DataPropertyType.number}
                  value={values.adminFeeAmount}
                  data-cy={formProps.adminFeeAmount.property}
                />
              </>
            )}
          </Grid>
          {contractRefundInfo ? (
            <CaptionLegal
              style={{ fontStyle: 'italic', color: COLOR.NEUTRAL[500] }}
            >{`Last updated by ${contractRefundInfo.lastEditedBy} on ${format(
              contractRefundInfo.updatedAt,
            )}`}</CaptionLegal>
          ) : null}
        </>
      </Section>
      <ModalController isOpen={confirmationModal}>
        <Modal
          heading="Update Reason"
          primaryButtonProps={{
            text: 'Save',
            onClick: () => handleSubmit(),
            isProcessing: creatingContractRefundInfo || updatingContractRefundInfo,
            isDisabled: updateReason === '',
            'data-cy': 'update-reason-modal-save-button',
          }}
          secondaryButtonProps={{
            text: 'Cancel',
            onClick: () => {
              setUpdateReason('')
              setConfirmationModal(false)
            },
          }}
          data-cy="update-reason-modal"
        >
          <TextArea
            value={updateReason}
            id="updateReason"
            onChange={(e) => setUpdateReason(e.target.value)}
            isDisabled={creatingContractRefundInfo || updatingContractRefundInfo}
            data-cy="update-reason-text-area"
          />
        </Modal>
      </ModalController>
    </>
  )
}

export { ContractRefundInfo }
