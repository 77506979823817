import type { FC, SyntheticEvent } from 'react'
import React, { useState, useCallback, useContext, useMemo, memo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { currency, date } from '@extend/client-helpers'
import type {
  BundleComponent,
  CategoryContract,
  CategoryProduct as Product,
  ShippingProtectionContract,
} from '@helloextend/extend-api-client'
import type { PrecheckResponse } from '@helloextend/extend-api-rtk-query'
import { DataTable, COLOR, Badge } from '@extend/zen'
import type { CellContext, ColumnDef, ColumnFiltersState, SortingState } from '@extend/zen'

import { omit, startCase } from 'lodash'
import images from '../../../../../images'
import type { ContractContextValue } from '../../components/contract-container'
import { ContractContext } from '../../components/contract-container'
import { ProductModal } from './modal'
import NestedProducts from './nestedProducts'
import { getTooltipText } from '../../../../../lib/claim-button-disable'

type SelectedProduct = Product & {
  coverageDuration?: string
  bundledItems?: number
}

type ContractProductsTableProps = {
  dataCy?: string
  isLoading: boolean
  contract: CategoryContract | ShippingProtectionContract
  productIdsToRender: string[]
  category?: string | undefined
  precheckData?: PrecheckResponse
}

type ProductsColumnProps = {
  openProductModal: (row: SelectedProduct) => void
  hasBundleProducts: boolean
}

type BundleProduct = Product & { quantity?: number }

const getProductsColumns = ({
  openProductModal,
  hasBundleProducts,
}: ProductsColumnProps): Array<ColumnDef<SelectedProduct>> => {
  const columns = [
    {
      label: 'Title',
      id: 'title',
      renderCell: (cellData: CellContext<SelectedProduct, string>) => {
        const {
          getValue,
          row: { original },
        } = cellData

        return (
          <ContainTitle>
            <Image src={original?.imageUrl || images.productPlaceholder} />
            <StyledLink onClick={() => openProductModal(original)}>
              {getValue() || 'No Title'}
            </StyledLink>
          </ContainTitle>
        )
      },
      isAlwaysPinned: true,
      isSortable: true,
    },
    {
      label: 'Product Reference ID',
      id: 'referenceId',
      renderCell: (cellData: CellContext<SelectedProduct, string>) => cellData.getValue() || '',
    },
    {
      label: 'Product Category',
      id: 'category',
      renderCell: (cellData: CellContext<SelectedProduct, string>) => {
        const value = cellData.getValue() || ''
        return startCase(value.replace(/_/g, ' '))
      },
    },
    {
      label: 'Purchase Price',
      id: 'purchasePrice',
      renderCell: (cellData: CellContext<SelectedProduct, string>) =>
        currency.format(cellData.getValue()) || '',
    },
    {
      label: 'List Price',
      id: 'listPrice',
      renderCell: (cellData: CellContext<SelectedProduct, string>) =>
        currency.format(cellData.getValue()) || '',
    },
    {
      label: 'Line Item ID',
      id: 'lineItemId',
      renderCell: (cellData: CellContext<SelectedProduct, string>) => cellData.getValue() || '',
    },
    {
      label: 'Transaction Date',
      id: 'transactionDate',
      renderCell: (cellData: CellContext<SelectedProduct, string>) =>
        cellData.getValue() ? date.format(Number(cellData.getValue()), 'MMM DD YYYY') : '',
    },
    {
      label: 'Fulfillment Date',
      id: 'fulfillmentDate',
      renderCell: (cellData: CellContext<SelectedProduct, string>) =>
        cellData.getValue() ? date.format(Number(cellData.getValue()), 'MMM DD YYYY') : '',
    },
    {
      label: 'Serial Number',
      id: 'serialNumber',
      renderCell: (cellData: CellContext<SelectedProduct, string>) => cellData.getValue() || '',
    },
    {
      label: 'Coverage Date',
      id: 'coverageDuration',
      renderCell: (cellData: CellContext<SelectedProduct, string>) => cellData.getValue() || '',
    },
    {
      label: 'Manufacturer Warranty Length Labor',
      id: 'manufacturerWarrantyLengthLabor',
      renderCell: (cellData: CellContext<SelectedProduct, string>) => cellData.getValue() || 0,
    },
    {
      label: 'Manufacturer Warranty Length Parts',
      id: 'manufacturerWarrantyLengthParts',
      renderCell: (cellData: CellContext<SelectedProduct, string>) => cellData.getValue() || 0,
    },
  ]

  if (hasBundleProducts) {
    const renderCell: (cellData: CellContext<SelectedProduct, string>) => JSX.Element = (
      cellData: CellContext<SelectedProduct, string>,
    ) => {
      return cellData.getValue() ? (
        <Badge text={`Bundle: ${cellData.getValue()}`} color="blue" />
      ) : (
        <></>
      )
    }

    columns.splice(2, 0, {
      label: 'Bundled Items',
      id: 'bundledItems',
      isAlwaysPinned: true,
      isSortable: true,
      renderCell,
    })
  }

  return columns
}

export const ContractsProductsTable: FC<ContractProductsTableProps> = memo(
  ({ dataCy, isLoading, contract, category, precheckData, productIdsToRender }) => {
    const { saveUpdates, setIsSaveModalVisible } = useContext(
      ContractContext,
    ) as ContractContextValue

    const [sorting, setSorting] = useState<SortingState>([])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [selectedProduct, setSelectedProduct] = useState<SelectedProduct | undefined>(undefined)
    const history = useHistory()

    const getProductCoverage = useCallback((coverage: Product['coverage']) => {
      if (coverage && coverage?.starts && coverage?.ends) {
        const { starts, ends } = coverage
        return `${date.format(Number(starts), 'MMM DD YYYY')} - ${date.format(
          Number(ends),
          'MMM DD YYYY',
        )}`
      }

      return ''
    }, [])

    const shouldDisable = (lineItemId: string): boolean => {
      if (precheckData?.status === 'failure') return true
      if (precheckData?.status === 'success') {
        const { lineItems } = precheckData
        if (!lineItems) return false
        const lineItem = lineItems[lineItemId]
        return lineItem?.hasActiveClaim || false
      }
      return false
    }
    const createBundleProductsMap = useCallback(
      (productsList: Product[] = []): Map<string, Product[]> => {
        const bundleMap = new Map<string, Product[]>()

        const bundles = ['standard_bundle', 'custom_bundle']
        productsList.forEach((product) => {
          if ('type' in product && bundles.includes((product as unknown as BundleComponent).type)) {
            bundleMap.set(product.referenceId, [])
          }
        })

        for (const product of productsList) {
          if ('type' in product && product.type === 'bundle_component') {
            const bundleComponentProduct = product as unknown as BundleComponent
            const bundleId = bundleComponentProduct?.bundleId as string
            const bundleProducts: Product[] = bundleMap.get(bundleId) || []

            bundleMap.set(bundleId, [...bundleProducts, product])
          }
        }

        return bundleMap
      },
      [],
    )

    const productsList = contract?.productsList || []

    const [customBundleMap, setCustomBundleMap] = useState<Map<string, BundleProduct[]>>(
      createBundleProductsMap(productsList),
    )
    const hasBundleProducts = customBundleMap.size > 0

    const retrieveAndDedupeProducts = useCallback(
      (productsList: Product[] = []): SelectedProduct[] => {
        const products = []
        const seen = new Set()

        for (const product of productsList) {
          const { lineItemId } = product

          if ('type' in product && product.type === 'bundle_component') continue

          if (!seen.has(lineItemId)) {
            seen.add(lineItemId)
            const values: SelectedProduct = {
              ...product,
              category: contract?.planDetails?.category,
              coverageDuration: getProductCoverage(product?.coverage),
            }

            if (hasBundleProducts) {
              const count = customBundleMap
                .get(product.referenceId)
                ?.reduce((acc, val) => (acc += val?.quantity || 0), 0) as number

              values.bundledItems = count || customBundleMap.get(product.referenceId)?.length || 0
            }

            if (productIdsToRender.includes(values.referenceId) && values.referenceId) {
              products.push(values)
            }
          }
        }

        return products
      },
      [contract?.planDetails?.category, getProductCoverage, productsList.length],
    )

    const [updatedProducts, setUpdatedProducts] = useState<SelectedProduct[]>(
      retrieveAndDedupeProducts(productsList),
    )

    const openProductModal = useCallback((row: SelectedProduct) => {
      setOpenModal(true)
      setSelectedProduct(row)
    }, [])

    const onChange = useCallback(
      (e: SyntheticEvent) => {
        const { id, value } = e.target as HTMLInputElement

        setSelectedProduct((prev) => {
          if (prev)
            return {
              ...prev,
              [id]: id === 'listPrice' || id === 'purchasePrice' ? Number(value) : value,
            }

          return prev
        })
      },
      [setSelectedProduct],
    )

    const onDateChange = useCallback((label: string, updatedDate: number | null) => {
      setSelectedProduct((prev) => {
        if (prev) {
          return { ...prev, [label]: updatedDate }
        }

        return prev
      })
    }, [])

    const handleUpdateProducts = useCallback(
      (newProducts: SelectedProduct[]): void => {
        if (saveUpdates) {
          const values = {
            ...omit(contract, ['status']),
            productsList: newProducts,
          }

          saveUpdates(
            {
              contractUpdate: {
                args: { contractId: contract.id },
                values,
              },
            },
            false,
          )

          setUpdatedProducts(retrieveAndDedupeProducts(newProducts))
          if (hasBundleProducts) setCustomBundleMap(createBundleProductsMap(newProducts))

          if (setIsSaveModalVisible) setTimeout(() => setIsSaveModalVisible(true), 250)
        }
      },
      [contract, saveUpdates, setIsSaveModalVisible],
    )

    const handleSave = useCallback(
      (product: SelectedProduct | undefined) => {
        if (product) {
          let newProducts: SelectedProduct[] = []

          if (!hasBundleProducts) {
            newProducts = updatedProducts?.map((newProduct) => {
              return newProduct.lineItemId === product.lineItemId ? product : newProduct
            })
          } else {
            const bundleComponents = Array.from(customBundleMap.values()).flat()
            newProducts = [...bundleComponents, ...updatedProducts]?.map((newProduct) => {
              return newProduct.lineItemId === product.lineItemId ? product : newProduct
            })
          }

          handleUpdateProducts(newProducts)
          setOpenModal(false)
        }
      },
      [handleUpdateProducts, updatedProducts],
    )

    const handleLeavePage = (path: string): void => {
      history.push(path)
    }

    const rowDetailRenderer = useCallback(
      (data: Product) => {
        const bundleProducts = customBundleMap.get(data.referenceId) || []
        const productCategory = startCase(contract?.planDetails?.category?.replace(/_/g, ' '))

        const maxLength = updatedProducts?.reduceRight((acc, product) => {
          return product?.title?.length > acc ? product?.title?.length : acc
        }, 0)

        return hasBundleProducts ? (
          <NestedProducts
            bundleProducts={bundleProducts}
            productCategory={productCategory}
            maxLength={maxLength}
            getProductCoverage={getProductCoverage}
            openProductModal={openProductModal}
          />
        ) : null
      },
      [getProductCoverage, openProductModal, customBundleMap],
    )

    const columns = getProductsColumns({ openProductModal, hasBundleProducts })
    const nonExpandableRows = useMemo(() => {
      return updatedProducts?.reduce((acc: number[], product, index) => {
        if (!('type' in product)) {
          acc.push(index)
        }

        return acc
      }, [])
    }, [updatedProducts])

    return (
      <>
        <DataTable
          data-cy={dataCy}
          key={category}
          isError={false}
          isLoading={isLoading}
          data={updatedProducts}
          columns={columns}
          rowCount={updatedProducts?.length}
          sorting={sorting}
          onSortingChange={setSorting}
          hasManualSorting
          hasManualFiltering
          columnFilters={columnFilters}
          staticRowIndices={nonExpandableRows}
          onColumnFiltersChange={setColumnFilters}
          pagination={{
            pageIndex: 0,
            pageSize: 10,
          }}
          getRowId={(row) => row.referenceId}
          getRowActions={(product) => {
            return contract.type === 'category' ? [
              {
                text: 'File Claim',
                emphasis: 'low',
                isDisabled: shouldDisable(product.lineItemId),
                tooltip: getTooltipText(precheckData, product.lineItemId),
                onClick: () =>
                  handleLeavePage(
                    `/admin/contracts/${product.contractId}/products/${product.lineItemId}/file-a-claim`,
                  ),
                'data-cy': 'file-claim-row-action',
              },
            ] : []
          }}
          {...{ rowDetailRenderer: hasBundleProducts ? rowDetailRenderer : undefined }}
        />
        <ProductModal
          isOpen={openModal}
          setOpenModal={setOpenModal}
          handleSave={handleSave}
          selectedProduct={selectedProduct}
          onDateChange={onDateChange}
          onChange={onChange}
        />
      </>
    )
  },
)

const Image = styled.img({
  width: '4.5rem',
  padding: '0.5rem',
})

const ContainTitle = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
})

const StyledLink = styled.a({
  color: COLOR.BLUE[700],
  cursor: 'pointer',
  fontSize: 14.5,
  textDecoration: 'underline !important',
})
