import type { FC } from 'react'
import React, { useMemo } from 'react'
import type { ProductsSearchItem } from '@helloextend/extend-api-client'
import type { ProductsSearchArguments } from '@helloextend/extend-api-rtk-query'
import {
  useBulkStoreUpdateJobMutation,
  useCreateProductsMutation,
  useDeleteProductsBulkMutation,
  useGetPlanCategoriesQuery,
  useGetPlanMappingsQuery,
  useLazyGetPlansByIdsQuery,
  useProductWarrantyMappingMutation,
} from '@helloextend/extend-api-rtk-query'
import { useFormik } from 'formik'
import { ModalController } from '@extend/zen'
import { useToggle } from '@helloextend/client-hooks'
import { useStandardToast } from '@helloextend/merchants-ui'
import {
  getProductsCheckboxValue,
  getWarrantyStatusValue,
  mapProductUpdatesToBatchUpdate,
  mapValuesToJobBulkUpdate,
} from '../edit-products-modal/utils'
import { arePlanWarrantyCategoriesValid } from '../../utils'
import { EditProductsModal } from '../edit-products-modal/edit-products-modal'
import { DeleteProductsModal } from '../delete-products-modal/delete-products-modal'

type EditProductsProps = {
  storeId: string
  selectedProducts: ProductsSearchItem[]
  isEditModalDisplayed: boolean
  handleEditModalOff: () => void
  handleEditModalOn: () => void
  refetchProducts: () => void
  totalProducts?: number
  filters?: ProductsSearchArguments
  useJob?: boolean
}

const REFETCH_TIMEOUT = 2500

const EditProducts: FC<EditProductsProps> = ({
  storeId,
  selectedProducts,
  isEditModalDisplayed,
  handleEditModalOff,
  handleEditModalOn,
  refetchProducts,
  totalProducts,
  filters,
  useJob,
}) => {
  const [isDeleteProductsModalDisplayed, { on: handleDeleteModalOn, off: handleDeleteModalOff }] =
    useToggle(false)

  const { planIds, isLoading: isPlansListLoading } = useGetPlanMappingsQuery(
    { storeId },
    {
      selectFromResult: ({ data, ...rest }) => ({
        planIds: data?.map((item) => item.planId) || [],
        ...rest,
      }),
    },
  )
  const [fetchAdditionalPlans] = useLazyGetPlansByIdsQuery()

  const [updateProductsBatch, { isLoading: isBatchUpdating }] = useCreateProductsMutation()

  const [deleteProductsBatch, { isLoading: isDeletingProducts }] = useDeleteProductsBulkMutation()

  const [createProductsJobQueue, { isLoading: isCreatingJobQueue }] =
    useBulkStoreUpdateJobMutation()

  const [updateProductPlanCategory, { isLoading: isUpdatingPlanCategoryMapping }] =
    useProductWarrantyMappingMutation()

  const { data: planCategories } = useGetPlanCategoriesQuery()

  const { toastCompleted, toastError } = useStandardToast()

  const displayOfferValue = useMemo(
    () => getProductsCheckboxValue(selectedProducts, 'enabled'),
    [selectedProducts],
  )

  const shippableValue = useMemo(
    () => getProductsCheckboxValue(selectedProducts, 'shippable'),
    [selectedProducts],
  )

  const warrantyStatusValue = useMemo(
    () => getWarrantyStatusValue(selectedProducts),
    [selectedProducts],
  )

  const shippingProtectionEnabledValue = useMemo(
    () => getProductsCheckboxValue(selectedProducts, 'shipEnabled'),
    [selectedProducts],
  )

  const initialValues = {
    warrantyStatus: useJob ? '' : warrantyStatusValue,
    displayOffer: useJob ? '' : displayOfferValue,
    planIds: [],
    mfrWarrantyLabor: '',
    mfrWarrantyParts: '',
    productShippable: useJob ? '' : shippableValue,
    shippingProtectionEnabled: useJob ? '' : shippingProtectionEnabledValue,
    planCategoryId: '',
  }

  const { values, dirty, handleChange, resetForm } = useFormik({
    initialValues,
    onSubmit: () => {},
    enableReinitialize: true,
  })

  const handleCloseEditModal = (): void => {
    handleEditModalOff()
    resetForm()
  }

  // handles initial delete click to close edit modal and open delete modal
  const handleDeleteProduct = (): void => {
    handleEditModalOff()
    handleDeleteModalOn()
  }

  const handleUpdateProduct = async (): Promise<void> => {
    const plansResponse = await fetchAdditionalPlans(values.planIds)
    const plansToAdd = plansResponse?.data ?? []
    if (plansToAdd?.length > 1 && !arePlanWarrantyCategoriesValid(plansToAdd, toastError)) {
      return
    }
    const { planCategoryId } = values
    if (planCategoryId) {
      try {
        const promises = selectedProducts.map(async (product) => {
          const response = await updateProductPlanCategory({
            storeId,
            data: [
              {
                referenceId: product.referenceId,
                planCategoryId,
              },
            ],
          }).unwrap()

          if (response.errors.length) {
            throw new Error(response.errors[0].reason)
          }
        })

        await Promise.all(promises)

        toastCompleted(`${selectedProducts.length} plan categories have been updated successfully.`)
      } catch {
        toastError('Something went wrong updating plan category mappings. Please try again.')
      }
    }
    if (useJob) {
      const bulkUpdateValues = mapValuesToJobBulkUpdate(values)
      try {
        await createProductsJobQueue({
          storeId,
          jobType: 'update-by-query',
          data: bulkUpdateValues,
          filters,
        }).unwrap()
        toastCompleted(`${totalProducts} products have been updated successfully.`)
      } catch (error) {
        toastError('Something went wrong. Please try again.')
      }
    } else {
      const mappedProducts = mapProductUpdatesToBatchUpdate(values, selectedProducts)
      try {
        await updateProductsBatch({
          storeId,
          data: mappedProducts,
          isBatch: true,
          version: 'latest',
        }).unwrap()
        // the easiest way to let data propagate to RDS
        setTimeout(() => {
          refetchProducts()
        }, REFETCH_TIMEOUT)
        toastCompleted(`${selectedProducts.length} products have been updated successfully.`)
      } catch (error) {
        toastError('Something went wrong. Please try again.')
      }
    }
    handleCloseEditModal()
  }

  // confirms the delete product action inside the confirmation modal
  const handleDeleteConfirm = async (): Promise<void> => {
    if (useJob) {
      try {
        await createProductsJobQueue({
          storeId,
          jobType: 'delete-by-query',
          filters,
        }).unwrap()
        toastCompleted(`${totalProducts} products have been deleted successfully.`)
        handleDeleteModalOff()
      } catch (error) {
        toastError('Something went wrong. Please try again.')
      }
    } else {
      try {
        await deleteProductsBatch({
          storeId,
          productIds: selectedProducts.map((product) => product.referenceId),
        }).unwrap()
        // the easiest way to let data propagate to RDS
        setTimeout(() => {
          refetchProducts()
        }, REFETCH_TIMEOUT)
        toastCompleted(`${selectedProducts.length} products have been deleted successfully.`)
        handleDeleteModalOff()
      } catch (error) {
        toastError('Something went wrong. Please try again.')
      }
    }
  }

  // cancels the confirmation delete modal and reopens the edit modal
  const handleDeleteCancel = (): void => {
    handleDeleteModalOff()
    handleEditModalOn()
  }

  return (
    <>
      <ModalController isOpen={isEditModalDisplayed}>
        <EditProductsModal
          values={values}
          isPlansListLoading={isPlansListLoading}
          planIds={planIds}
          handleChange={handleChange}
          handleModalOff={handleCloseEditModal}
          handleDeleteProduct={handleDeleteProduct}
          handleUpdateProduct={handleUpdateProduct}
          isUpdatingProducts={
            isBatchUpdating || isCreatingJobQueue || isUpdatingPlanCategoryMapping
          }
          planCategories={planCategories?.items || []}
          selectedProductsCount={useJob ? totalProducts : selectedProducts.length}
          isDirty={dirty}
        />
      </ModalController>
      <ModalController isOpen={isDeleteProductsModalDisplayed}>
        <DeleteProductsModal
          handleDeleteConfirm={handleDeleteConfirm}
          handleDeleteCancel={handleDeleteCancel}
          isProcessing={isDeletingProducts || isCreatingJobQueue}
        />
      </ModalController>
    </>
  )
}

export { EditProducts }
