import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/extend-api-fetch'
import { customLogger } from '@extend/client-helpers'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.update>

export function* update(action: Action): SagaIterator {
  const { sessionToken, slot, slotValue } = action.payload

  yield put(actions.updateRequest())

  try {
    const response = yield call(client.incredibot.update, slot, sessionToken, {
      slotValue,
    })

    if (isErrorResponse(response)) {
      customLogger.error(`Error calling /claims-assistant/update`, {
        statusCode: response.status,
        timestamp: Date.now(),
        slot,
        slotValue,
      })
      yield put(actions.updateFailure(response.data.message, response.status))
      return
    }

    yield put(actions.updateSuccess(response.data))
  } catch (error) {
    if (error instanceof Error) {
      customLogger.error(`An unexpected error occurred while calling /claims-assistant/update`, {
        error: error.message,
        timestamp: Date.now(),
      })
      yield put(actions.updateFailure(error.message))
    }
  }
}
