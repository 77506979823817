import * as Yup from 'yup'
import { ClaimServiceOrderType } from '@helloextend/extend-api-client/src/models/claim'
import { formatCurrency, formatStartCase } from '../../../util'

const baseRepairDataFields = [
  {
    key: 'serviceOrder.serviceType',
    label: 'Service Type',
    transformFn: formatStartCase,
  },
  {
    key: 'entitlements.coverageAmountRemaining',
    label: 'Remaining LOL.',
    transformFn: formatCurrency,
  },
  {
    key: 'contract.product.title',
    label: 'Item Requiring Fulfillment',
  },
  { key: 'contract.sellerName', label: 'Purchased From' },
  { key: 'contract.product.serialNumber', label: 'Serial Number' },
  { key: 'claim.incident.failureType', label: 'Failure Type', transformFn: formatStartCase },
  { key: 'claim.incident.description', label: 'Incident Description' },
]

const dataFields = {
  [ClaimServiceOrderType.repair_depot]: baseRepairDataFields,
  [ClaimServiceOrderType.repair_onsite]: baseRepairDataFields,
  [ClaimServiceOrderType.repair]: [
    ...baseRepairDataFields,
    {
      key: 'fulfillmentMethod',
      label: 'Service Outcome',
      transformFn: formatStartCase,
    },
  ],
  [ClaimServiceOrderType.repair_home]: [],
  [ClaimServiceOrderType.replace]: [],
  [ClaimServiceOrderType.repair_irobot]: [],
}

const formSchema = Yup.object()
  .shape({
    repairCompletedAt: Yup.mixed<number>()
      .test({
        name: 'isValidDate',
        message: 'Required',
        test: (value) => value != null && value > 0,
      })
      .default(undefined),
    repairExplanation: Yup.string().default(''),
  })
  .defined()

type Values = Yup.InferType<typeof formSchema>

export { dataFields, formSchema }
export type { Values }
