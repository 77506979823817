import React, { useState } from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'
import type { LineItem } from '@helloextend/extend-api-client'
import { COLOR, HeadingSmall, DataTable } from '@extend/zen'
import type { SortingState, VisibilityState } from '@extend/zen'
import { lineItemsTableColumns } from './table-config'
import { sortItems } from '../../utils/sort-table-items'

interface LineItemTableProps {
  lineItems: LineItem[]
  orderId: string
  isLoading?: boolean
  isError?: boolean
  title?: string
}

const handleRowClick = (orderId: string, lineItem: LineItem): void => {
  window.open(`/admin/orders/${orderId}/line-items/${lineItem.id}`, '_blank')
}
export const LineItemsTable: FC<LineItemTableProps> = ({
  lineItems,
  orderId,
  title = 'Line Items',
  isLoading = false,
  isError = false,
}) => {
  const [sorting, setSorting] = useState<SortingState>([{ id: 'type', desc: false }])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    contractId: false,
    leadToken: false,
    productId: false,
    productPurchasePrice: false,
    productListPrice: false,
    productPurchaseDate: false,
    productCategory: false,
    quantity: false,
    fulfilledQuantity: false,
    fulfilledAt: false,
  })

  const sortedLineItems = sortItems(lineItems, sorting)

  return (
    <LineItemsTableContainer>
      <HeadingSmall style={{ paddingBottom: '10px' }}>{title}</HeadingSmall>
      <DataTable
        itemName="lineItems"
        data-cy="line-items-table"
        isLoading={isLoading}
        isError={isError}
        data={sortedLineItems}
        columns={lineItemsTableColumns}
        rowCount={sortedLineItems?.length}
        sorting={sorting}
        onSortingChange={setSorting}
        hasManualSorting
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={setColumnVisibility}
        getRowId={(row) => row.id}
        getRowActions={(row) => [
          {
            onClick: () => handleRowClick(orderId, row),
            text: 'View',
            emphasis: 'low',
          },
        ]}
      />
    </LineItemsTableContainer>
  )
}

const LineItemsTableContainer = styled.div({
  padding: 32,
  marginBottom: 32,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
})
