import type { FC } from 'react'
import React, { useMemo } from 'react'
import type { LineItem, Order } from '@helloextend/extend-api-client'
import { useGetOrderQuery } from '@helloextend/extend-api-rtk-query'
import { LineItemsTable } from '../../../../../../components/line-items-table'

const filterForRelatedLines = (relatedLineItemIds: string[], order: Order): LineItem[] => {
  return order.lineItems.filter((liFromOrder) => {
    return relatedLineItemIds.includes(liFromOrder.id as string)
  }) as LineItem[]
}

export const RelatedLineItemsTable: FC<{ relatedLineItems: string[]; orderId: string }> = ({
  relatedLineItems: relatedLineItemIds,
  orderId,
}) => {
  const {
    data: order,
    isLoading: isOrderLoading,
    isError: isOrderLoadingError,
  } = useGetOrderQuery(orderId)

  const relatedLineItems = useMemo(() => {
    if (order) {
      return filterForRelatedLines(relatedLineItemIds, order)
    }

    return []
  }, [relatedLineItemIds, order])

  return (
    <LineItemsTable
      lineItems={(order && relatedLineItems) || []}
      orderId={orderId}
      title="Related Line Items"
      isLoading={isOrderLoading}
      isError={isOrderLoadingError}
    />
  )
}

export { RelatedLineItemsTable as LineItemDetailsForm }
