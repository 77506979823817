import { createApi } from '@reduxjs/toolkit/query/react'
import type { clientTypes } from '@extend-entitlements-mgmt/client'
import { baseQuery } from '../base-query'

export const emsApi = createApi({
  baseQuery,
  reducerPath: 'EMS',
  tagTypes: ['Entitlements'],
  endpoints: (build) => ({
    getEntitlementsById: build.query<
      clientTypes.GetEntitlementByIdResponseVersionMap[clientTypes.EntitlementVersions],
      { entitlementId: string; version: clientTypes.EntitlementVersions }
    >({
      query: ({ entitlementId, version }) => ({
        url: `/entitlements-management/entitlements/${entitlementId}`,
        headers: {
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, { entitlementId }) => [{ type: 'Entitlements', id: entitlementId }],
    }),
    searchEntitlements: build.query<
      clientTypes.SearchEntitlementsResponseVersionMap[clientTypes.EntitlementVersions],
      { queryString: string; version: clientTypes.EntitlementVersions }
    >({
      query: ({ queryString, version }) => ({
        url: `/entitlements-management/entitlements?${queryString}`,
        headers: {
          accept: `application/json; version=${version};`,
        },
      }),
      providesTags: (_, _err, { queryString }) => [{ type: 'Entitlements', id: queryString }],
    }),
  }),
})

export const { useGetEntitlementsByIdQuery, useSearchEntitlementsQuery } = emsApi
