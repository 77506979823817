import type { Ref } from 'react'
import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { useSelector } from 'react-redux'
import { ThreadAppendButtons } from '../thread-append-buttons'
import type { RootState } from '../../../../../reducers'
import * as selectors from '../../../../../reducers/selectors'

type AdjudicationTitleBlockProps = {
  onClick?: () => void
  ref?: Ref<HTMLDivElement>
}

const AdjudicationTitleBlock = forwardRef<HTMLDivElement, AdjudicationTitleBlockProps>(
  ({ onClick }, ref) => {
    // we have add block button logic elsewhere when the thread is empty, so we don't want to duplicate it here in that case
    const selectedThread = useSelector((state: RootState) => selectors.getSelectedThread(state))
    const threadSize = selectedThread?.script?.length
    const isEditableThreadStatus = !['published', 'archived', 'override'].includes(
      selectedThread?.status || '',
    )

    return (
      <>
        <TitleWrapper ref={ref} data-cy="adjudication-title-block-wrapper">
          <TitleText onClick={onClick}>
            <>Thread Start</>
          </TitleText>
        </TitleWrapper>
        {!!threadSize && isEditableThreadStatus && (
          <ThreadAppendButtons data-cy="thread-start-add-block" shouldAddBlockToBeginning />
        )}
      </>
    )
  },
)

const TitleWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '44px',
})

const TitleText = styled.div({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  width: 360,
  padding: 16,
  background: COLOR.WHITE,
  borderRadius: 8,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  transition: 'box-shadow 0.3s',
  '&:hover': {
    cursor: 'pointer',
  },
})

export { AdjudicationTitleBlock }
