import React, { useState } from 'react'
import type { FC } from 'react'
import { Grid } from '@extend/zen'
import { getShipmentInfoFormFields } from './fields'
import type { clientTypes } from '@extend-orders/client'
import type { ShipmentInfoFormFields } from './fields'
import { CompactForm, CompactFormSection } from '../../../../../../components/compact-form'

export type ShipmentInfo =
  clientTypes.ShippingProtectionLineItemCreateAndGetVersionMap['2022-02-01']['shipmentInfo'][0]

export const ShipmentDetailsForm: FC<{ shipments: ShipmentInfo[] }> = ({ shipments }) => {
  const [isExpanded, setIsExpanded] = useState(true)

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded)
  }
  return (
    <>
      {shipments.map((shipment, index) => (
        <CompactForm
          dataCy={`shipment-details-section-${index}`}
          isSubmitDisabled={false}
          isEditable={false}
          isEditing={false}
          isSubmitting={false}
          isExpanded={isExpanded}
          onToggleRequest={toggleIsExpanded}
          shouldStartExpanded
          heading={`Shipment ${index + 1}`}
        >
          <CompactFormSections
            data-cy="order-details-compact-forms"
            fields={getShipmentInfoFormFields(shipment)}
            isEditing={false}
          />
        </CompactForm>
      ))}
    </>
  )
}

const CompactFormSections: FC<{
  fields: ShipmentInfoFormFields
  isEditing: boolean
  dataCy?: string
}> = ({ fields, isEditing, dataCy }) => {
  return (
    <Grid columns={3} spacing={8} data-cy={dataCy}>
      {Object.keys(fields).map((subSectionTitle) => (
        <CompactFormSection
          key={subSectionTitle}
          subsectionTitle={subSectionTitle}
          isEditing={isEditing}
          fields={fields[subSectionTitle as keyof typeof fields]}
        />
      ))}
    </Grid>
  )
}
