import type { FC } from 'react'
import {
  useToaster,
  ToastColor,
  ToastDuration,
  Button,
  ButtonGroup,
  Checkbox,
  Select,
  Stack,
} from '@extend/zen'
import React from 'react'
import { useFormik } from 'formik'
import type { StoreConfig } from '@helloextend/extend-api-client'
import { useUpsertStoreConfigMutation } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
// This import needs to reference the specific file in the package to avoid importing some code
// that references node.js specific code which throws an error
import { SPABTests } from '@extend-services/shipping-offers/dist/src/client/ab-tests'
import type { SPABTestTypes } from '@extend-services/shipping-offers/dist/src/client/ab-tests'

import { schema } from './ab-config-shipping-protection-form-schema'

export type AbStoreConfigProps = {
  storeId: string
  storeConfig?: StoreConfig
}

const ABStoreConfigShippingProtection: FC<AbStoreConfigProps> = ({ storeId, storeConfig }) => {
  const { toast } = useToaster()
  const [upsertStoreConfig, { isLoading: isUpsertStoreConfigLoading }] =
    useUpsertStoreConfigMutation()

  const { values, handleChange, dirty, isValid, errors, resetForm, handleSubmit } = useFormik({
    validationSchema: schema,
    initialValues: {
      abEnabled: !!storeConfig?.abTest?.enabled,
      abType: storeConfig?.abTest?.type,
    },
    onSubmit: async (formValues) => {
      const payload = {
        storeId,
        body: {
          abTest: {
            enabled: formValues.abEnabled,
            type: formValues.abType as SPABTestTypes,
          },
        },
      }

      try {
        const result: StoreConfig = await upsertStoreConfig(payload).unwrap()

        resetForm({
          values: {
            abEnabled: !!result?.abTest?.enabled,
            abType: result?.abTest?.type,
          },
        })

        toast({
          message: 'A/B Test settings saved successfully',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
      } catch (error) {
        toast({
          message: 'Unable to save A/B Test settings',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    },
  })

  return (
    <Stack data-cy="ab-config-component">
      <Checkbox
        label="A/B Testing for Shipping Protection"
        name="abEnabled"
        data-cy="ab-config-checkbox"
        checked={values.abEnabled}
        onChange={handleChange}
      />
      {values.abEnabled && (
        <AbSelectorsContainer>
          <ABTypeSelectContainer>
            <Select
              id="abType"
              label="A/B Test Type"
              data-cy="ab-test-type-select"
              onChange={handleChange}
              placeholder="Select"
              isError={!!errors.abType}
              errorFeedback={errors.abType}
              value={values.abType || ''}
            >
              {Object.values(SPABTests).map((testType) => (
                <option value={testType} key={testType}>
                  {testType}
                </option>
              ))}
            </Select>
          </ABTypeSelectContainer>
        </AbSelectorsContainer>
      )}
      {dirty && (
        <ButtonGroupWrapper>
          <ButtonGroup>
            <Button
              text="Cancel"
              onClick={() => resetForm()}
              emphasis="medium"
              data-cy="ab-config-cancel-button"
            />
            <Button
              text="Save"
              onClick={() => handleSubmit()}
              emphasis="high"
              isDisabled={!isValid}
              isProcessing={isUpsertStoreConfigLoading}
              data-cy="ab-config-save-button"
            />
          </ButtonGroup>
        </ButtonGroupWrapper>
      )}
    </Stack>
  )
}

const ButtonGroupWrapper = styled.div({
  marginTop: 16,
})

const ABTypeSelectContainer = styled.div({
  maxWidth: 360,
  marginTop: 16,
})

const AbSelectorsContainer = styled.div({
  display: 'flex',
  maxWidth: 893,
})

export { ABStoreConfigShippingProtection }
