import React from 'react'
import { Modal, ModalController } from '@extend/zen'
import { useVoidShippingLabelMutation } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import type { V2 } from '@extend-services/service-orders'

export interface VoidLabelConfirmDialogProps {
  shipment: V2.Models.ServiceOrderShipment
  serviceOrderId: string
  isVoidModalVisible: boolean
  onClose: () => void
}
export const VoidLabelConfirmDialog: React.FC<VoidLabelConfirmDialogProps> = ({
  shipment,
  serviceOrderId,
  onClose,
  isVoidModalVisible,
}) => {
  const [voidShippingLabel] = useVoidShippingLabelMutation()
  return (
    <div>
      <ModalController data-cy="voidShippingLabelConfirmModal" isOpen={isVoidModalVisible}>
        <Modal
          onDismissRequest={onClose}
          primaryButtonProps={{
            'data-cy': 'voidShippingLabelConfirm',
            text: 'Void Label',
            color: 'red',
            onClick: () => {
              onClose()
              voidShippingLabel({
                serviceOrderId,
                body: {
                  labels: [
                    {
                      trackingNumber: shipment.trackingNumber ?? '',
                      carrierCode: shipment.carrier,
                    },
                  ],
                  voidAttemptReason: 'voided_by_agent',
                },
              })
            },
          }}
          secondaryButtonProps={{
            'data-cy': 'voidShippingLabelCancel',
            text: 'Cancel',
            onClick: onClose,
          }}
          size="md"
          heading="Void Shipping Label"
        >
          <TextContainer>
            <p>
              Voiding this label means it will not longer be available for use, are you sure you
              want to void this label?
            </p>
            <p>
              <strong>Note: </strong>Manually entered labels cannot be voided.
            </p>
          </TextContainer>
        </Modal>
      </ModalController>
    </div>
  )
}

const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  padding: '0px 20px',
  gap: '10px',
})
