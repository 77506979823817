import { Modal, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { ReactElement } from 'react'
import React from 'react'
import { useAtom, useSetAtom } from 'jotai/react'
import type { CustomTableauReport } from '@helloextend/extend-api-client'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import { useDispatch } from 'react-redux'
import { stores as storesActions } from '../../../../../../../../actions'
import { selectedTableauReportAtom, openTableauReportModalAtom } from './atoms/tableauReportAtom'

export function DeleteTableauReportsModal({
  customReports,
  storeId,
}: {
  customReports: CustomTableauReport[]
  storeId: string
}): ReactElement {
  const [selectedCustomReport, setSelectedCustomReport] = useAtom(selectedTableauReportAtom)

  const setOpenTableauReportModal = useSetAtom(openTableauReportModalAtom)
  const dispatch = useDispatch()
  const { toast } = useToaster()
  const [updateStore, { isError, isLoading: isDeleteProcessing }] = useUpdateStoreMutation()

  const deleteSelectedFromCustomReports = async (
    reportsToBeUpdated: CustomTableauReport[],
  ): Promise<void> => {
    const res = await updateStore({
      storeId,
      data: {
        customReports: reportsToBeUpdated,
      },
      version: 'latest',
    })
    if (res && 'data' in res && !isError) {
      toast({
        message: 'Report Deleted!',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
      dispatch(storesActions.updateSuccess(res.data))
      setOpenTableauReportModal(null)
      setSelectedCustomReport({ title: '', url: '' })
    } else {
      toast({
        message: `Something went wrong`,
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  const handleClickCancel = (): void => {
    setOpenTableauReportModal(null)
  }

  const handleClickDelete = (): void => {
    deleteSelectedFromCustomReports(
      customReports.filter((report) => report.title !== selectedCustomReport?.title),
    )
  }
  return (
    <Modal
      heading="Delete Custom Report?"
      size="sm"
      onDismissRequest={handleClickCancel}
      primaryButtonProps={{
        onClick: handleClickDelete,
        text: 'Delete',
        color: 'red',
        'data-cy': 'delete-tableau-report-modal-delete-button',
        isProcessing: isDeleteProcessing,
        isDisabled: isDeleteProcessing,
      }}
      secondaryButtonProps={{
        onClick: handleClickCancel,
        text: 'Cancel',
        'data-cy': 'delete-tableau-report-modal-cancel-button',
        isDisabled: isDeleteProcessing,
      }}
    >
      The merchant will lose access to this custom report in Merchant Portal.
    </Modal>
  )
}
