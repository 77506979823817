import type { FC } from 'react'
import React, { useState } from 'react'
import { useListSkuVersionsQuery } from '@helloextend/extend-api-rtk-query'
import { skuVersionsColumns } from './table-config'
import type { SortingState } from '@extend/zen';
import { DataTable } from '@extend/zen'

type SkuVersionsProps = {
  skuId: string
}

const PremiumVersions: FC<SkuVersionsProps> = ({ skuId }) => {
  const [sorting, setSorting] = useState<SortingState>([{ id: 'version', desc: true }])

  const { data, isLoading, isError } = useListSkuVersionsQuery(skuId)

  return (
    <DataTable
      columns={skuVersionsColumns}
      data={data?.items || []}
      sorting={sorting}
      onSortingChange={setSorting}
      hasManualSorting={false}
      isLoading={isLoading}
      isError={isError}
      data-cy="premiums-versions-table"
    />
  )
}

export { PremiumVersions }
