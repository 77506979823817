import * as Yup from 'yup'
import { NormalizedPhoneNumber } from '@extend/client-helpers'
import {
  cityRegex,
  states,
  toValidPhoneNumber,
  provinces,
  postalCodeRegex,
} from '../../../../../../utils/form-utils'

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .test('is-valid-e.164-number', 'Please enter a valid phone number', (value) => {
      // parsePhoneNumber throws an error on empty values and values with length 1
      if (!value || value.length <= 1) return false
      const phoneNumber = new NormalizedPhoneNumber(value, 'US')
      // confirm that the number is a valid E.164 number so it complies with Twilio's API downstream
      return phoneNumber.valid
    }),
  shippingAddress: Yup.object()
    .shape({
      address1: Yup.string().required('Shipping address is required'),
      address2: Yup.string(),
      city: Yup.string()
        .matches(cityRegex, 'Please Enter a valid city')
        .required('City is required'),
      provinceCode: Yup.string().when('countryCode', {
        is: 'CA',
        then: () =>
          Yup.string().oneOf(provinces, 'Select a province').required('Select a province'),
        otherwise: () => Yup.string().oneOf(states, 'Select a state').required('Select a state'),
      }),
      postalCode: Yup.string()
        .when('countryCode', ([countryCode]) => {
          if (countryCode === 'CA') {
            return Yup.string().matches(postalCodeRegex.CA, 'Enter a valid CA postal code')
          }
          return Yup.string().matches(postalCodeRegex.US, 'Enter a valid zip code')
        })
        .required('Zip Code is required'),
      countryCode: Yup.string().default('US'),
    })
    .required(),
})

export { schema, states, provinces, toValidPhoneNumber }
