import type { FC } from 'react'
import React from 'react'
import { AdvancedSelect, Modal } from '@extend/zen'

import { useFormik } from 'formik'
import { listToAdvancedSelectOptions } from '../../../../../../../../../utils/form-utils'

type AddPlanModalProps = {
  planIds: string[]
  arePlansLoading: boolean
  toggleModalOff: () => void
  handleAddPlans: (plansList: string[]) => Promise<void>
}

const AddPlanModal: FC<AddPlanModalProps> = ({
  planIds,
  arePlansLoading,
  handleAddPlans,
  toggleModalOff,
}) => {
  const { values, dirty, handleChange, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: {
      plans: [],
    },
    onSubmit: () => {},
  })

  const handleSelectClick = async (): Promise<void> => {
    await handleAddPlans(values?.plans || [])
    resetForm()
    toggleModalOff()
  }

  const handleCancelClick = (): void => {
    resetForm()
    toggleModalOff()
  }

  const planIdsOptions = listToAdvancedSelectOptions(planIds || [])

  return (
    <Modal
      heading="Select Plan"
      onDismissRequest={handleCancelClick}
      data-cy="select-plan-modal"
      primaryButtonProps={{
        text: 'Select',
        'data-cy': 'select-plan-confirm-button',
        onClick: handleSelectClick,
        isDisabled: !dirty,
        isProcessing: arePlansLoading,
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        'data-cy': 'select-plan-cancel-button',
        onClick: handleCancelClick,
      }}
    >
      <AdvancedSelect
        options={planIdsOptions || []}
        label="Select Plan"
        data-cy="select-plan"
        multiple
        showSearch
        onChange={handleChange}
        maxQuantityToDisplay={0}
        value={values?.plans || []}
        id="plans"
        isLoading={arePlansLoading}
      />
    </Modal>
  )
}

export { AddPlanModal }
