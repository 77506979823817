import type { FC } from 'react'
import React from 'react'
import type { AdvancedSelectOption } from '@extend/zen'
import {
  AdvancedSelect,
  Button,
  ButtonGroup,
  Checkbox,
  Select,
  Stack,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import styled from '@emotion/styled'
import { PPABTests } from '@extend-offers/types'
import type { OffersStoreConfig } from '@helloextend/extend-api-client'
import { useUpdateOffersStoreConfigurationMutation } from '@helloextend/extend-api-rtk-query'
import { useFormik } from 'formik'
import { schema } from './ab-config-product-protection-form-schema'

export type AbStoreConfigProps = {
  storeId: string
  categories: AdvancedSelectOption[]
  offersStoreConfig?: OffersStoreConfig
}

const AbStoreConfig: FC<AbStoreConfigProps> = ({ storeId, categories, offersStoreConfig }) => {
  const [updateOffersStoreConfig, { isLoading }] = useUpdateOffersStoreConfigurationMutation()

  const { toast } = useToaster()

  const { values, handleChange, dirty, isValid, errors, resetForm, handleSubmit } = useFormik({
    validationSchema: schema,
    initialValues: {
      ppAbEnabled: !!offersStoreConfig?.abConfig?.isEnabled,
      ppAbType: offersStoreConfig?.abConfig?.abTestType,
      ...(offersStoreConfig?.abConfig?.abTestType === PPABTests.PLAN_SET &&
        !!offersStoreConfig?.abConfig.isEnabled && {
          targetedCategories: offersStoreConfig?.abConfig?.targetedCategories,
        }),
    },
    onSubmit: async (formValues) => {
      const payload = {
        storeId,
        offersStoreConfig: {
          storeId,
          organizationId: offersStoreConfig?.organizationId,
          accountId: offersStoreConfig?.accountId,
          abConfig: {
            isEnabled: formValues.ppAbEnabled,
            abTestType: formValues.ppAbType,
            ...(formValues.ppAbType === PPABTests.PLAN_SET && {
              targetedCategories: formValues.targetedCategories,
            }),
          },
        },
      }

      try {
        const result = await updateOffersStoreConfig(payload).unwrap()

        resetForm({
          values: {
            ppAbEnabled: !!result?.abConfig?.isEnabled,
            ppAbType: result?.abConfig?.abTestType,
            ...(result?.abConfig?.abTestType === PPABTests.PLAN_SET && {
              targetedCategories: result?.abConfig?.targetedCategories,
            }),
          },
        })

        toast({
          message: 'Offers A/B Test settings saved successfully',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
      } catch (error) {
        toast({
          message: 'Failed to update Offers A/B Test settings',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    },
  })

  return (
    <Stack data-cy="ab-config-component">
      <Checkbox
        label="A/B Testing for Product Protection"
        name="ppAbEnabled"
        data-cy="ab-config-checkbox"
        checked={values.ppAbEnabled}
        onChange={handleChange}
      />
      {values.ppAbEnabled && (
        <AbConfigCheckedContent>
          <AbSelectorsContainer>
            <ExperimentSelectContainer>
              <Select
                id="ppAbType"
                label="Experiment Type"
                data-cy="ab-test-type-select"
                onChange={handleChange}
                placeholder="Select"
                isError={!!errors.ppAbType}
                errorFeedback={errors.ppAbType}
                value={values.ppAbType || ''}
              >
                {Object.values(PPABTests).map((testType) => (
                  <option value={testType} key={testType}>
                    {testType}
                  </option>
                ))}
              </Select>
            </ExperimentSelectContainer>
            {values.ppAbType === PPABTests.PLAN_SET && (
              <CategorySelectContainer>
                <AdvancedSelect
                  id="targetedCategories"
                  label="Plan Category"
                  data-cy="ab-test-category-select"
                  maxQuantityToDisplay={5}
                  multiple
                  onChange={handleChange}
                  options={categories}
                  placeholder="Select"
                  value={values.targetedCategories || []}
                  isError={!!errors.targetedCategories}
                  errorFeedback={errors.targetedCategories}
                  showSearch
                />
              </CategorySelectContainer>
            )}
          </AbSelectorsContainer>
        </AbConfigCheckedContent>
      )}
      {dirty && (
        <ButtonGroupWrapper>
          <ButtonGroup>
            <Button
              text="Cancel"
              onClick={() => resetForm()}
              emphasis="medium"
              data-cy="ab-config-cancel-button"
            />
            <Button
              text="Save"
              onClick={() => handleSubmit()}
              emphasis="high"
              isDisabled={!isValid}
              isProcessing={isLoading}
              data-cy="ab-config-save-button"
            />
          </ButtonGroup>
        </ButtonGroupWrapper>
      )}
    </Stack>
  )
}

const AbConfigCheckedContent = styled.div({})

const ButtonGroupWrapper = styled.div({
  marginTop: 16,
})

const ExperimentSelectContainer = styled.div({
  maxWidth: 360,
  marginTop: 16,
})

const CategorySelectContainer = styled.div({
  marginTop: 16,
  marginLeft: 16,
  flex: 1,
})

const AbSelectorsContainer = styled.div({
  display: 'flex',
  maxWidth: 893,
})

export { AbStoreConfig }
