import styled from '@emotion/styled'
import { CaptionLegal, COLOR, Paragraph } from '@extend/zen'
import type { FC} from 'react';
import React, { memo, useCallback } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface ContractNoteTextAreaProps {
  id: string
  label?: string
  placeholder?: string
  maxLength: number
  onChange: (evt: any) => void
  rows: number
  value: string
  isError?: boolean
  errorFeedback?: string
  dataCy: string
  isDisabled: boolean
  validateField?: (name: string) => Promise<void> | Promise<string | undefined>
}

const ContractNoteTextArea: FC<ContractNoteTextAreaProps> = memo(
  ({
    id,
    rows,
    value,
    onChange,
    placeholder,
    dataCy,
    isError,
    errorFeedback,
    maxLength,
    isDisabled,
    validateField,
  }) => {
    const runnningLetterCount = maxLength - value.length
    const handleChange = useCallback(
      (content: string) => {
        if (value !== content) {
          onChange({
            target: { name: id, value: content },
          })

          validateField && validateField(id)
        }
      },
      [onChange, id, value],
    )

    const height = `${rows * 24}px`
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
      clipboard: {
        matchVisual: false,
      },
    }

    return (
      <Container data-cy={dataCy}>
        <ContainTextArea isError={isError}>
          <ReactQuill
            id={id}
            theme="snow"
            value={value}
            modules={modules}
            style={{ height }}
            onChange={handleChange}
            placeholder={placeholder}
            readOnly={isDisabled}
            preserveWhitespace
          />
        </ContainTextArea>
        {isError && (
          <ErrorFeedback data-cy={dataCy && `${dataCy}:error-feedback`}>
            {errorFeedback}
          </ErrorFeedback>
        )}
        <StyledCaptionLegal>{runnningLetterCount} characters remaining</StyledCaptionLegal>
      </Container>
    )
  },
)

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
})

const ContainTextArea = styled.div<{ isError?: boolean }>(({ isError }) => ({
  '.ql-editor': {
    lineHeight: '24px !important',
    fontSize: '16px !important',
    border: isError ? `1px solid ${COLOR.RED[400]} !important` : 'none',
  },
  '.ql-container': {
    fontFamily: '"Nunito Sans", sans-serif !important',
  },
  marginBottom: isError ? '2rem' : '3rem',
}))

const ErrorFeedback = styled(Paragraph)({
  fontSize: '15px',
  lineHeight: '20px',
  color: COLOR.RED[700],
  marginTop: '0.5rem',
})

const StyledCaptionLegal = styled(CaptionLegal)({
  fontSize: '14px',
  lineHeight: '16px',
  color: COLOR.NEUTRAL[700],
})

export { ContractNoteTextArea }
