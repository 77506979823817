import React from 'react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, DataProperty, Grid, Label } from '@extend/zen'

interface PlanSetPriceBandItem {
  plans: string[]
  maxPrice: number
  minPrice: number
}

type PlanSetPriceBandDataProps = {
  planSetPriceBandData: PlanSetPriceBandItem[] | undefined
}

const PlanSetPriceBands: FC<PlanSetPriceBandDataProps> = ({ planSetPriceBandData }) => {
  const lastPriceBandPosition = planSetPriceBandData && planSetPriceBandData?.length - 1

  return (
    <ContentWrapper>
      {planSetPriceBandData?.map((priceBand, idx) => {
        return (
          <div key={`price-band-${priceBand}-${idx + 1}`}>
            <Grid
              columns={{
                lg: 3,
                md: 2,
                sm: 1,
              }}
              data-cy="plan-set-price-band-grid-item"
              spacing={{
                lg: 6,
                md: 4,
                sm: 2,
              }}
            >
              <div>
                <Label isMuted>Plans</Label>
                <List>
                  {priceBand.plans.map((plan, index) => (
                    <ListItem key={`plan-${plan[index]}`} data-cy={`plan-${plan[index]}`}>
                      <Link to={`/admin/plans/${plan}`} target="_blank" rel="noopener noreferrer">
                        <span>{plan}</span>
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </div>
              <DataProperty
                label={`Price Band ${idx + 1} Min`}
                value={`$${priceBand.minPrice.toString()}`}
              />
              <DataProperty
                label={`Price Band ${idx + 1} Max`}
                value={`$${priceBand.maxPrice.toString()}`}
              />
            </Grid>
            {idx === lastPriceBandPosition ? null : <Dash />}
          </div>
        )
      })}
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  marginTop: 100,
})

const List = styled.ul({
  color: COLOR.BLUE[700],
  listStyle: 'none',
  padding: 0,
  margin: 0,
})

const ListItem = styled.li({
  marginTop: 0,
})

const Dash = styled.hr({
  borderBottom: `1px solid ${COLOR.NEUTRAL[200]}`,
  margin: '28px 0',
})

export { PlanSetPriceBands }
