import type { UserRole } from '../../utils/user-role-mapper'
import { legacyInternalRoles } from '../../utils/user-role-mapper'
import { LDFlag } from '../../constants/ld-flags'
import { Permission } from './permissions'

export type RuleDefinition = {
  allowedRoles: UserRole[]
  requiredFeatureFlags?: LDFlag[]
}

// ------------------------------------------------------------------------------------------------
// New permission rules should be added as follows:
//  1. Define a permission in the Permission enum
//  2. Add a rule for the new permission in the rules below.
//    - allowedRoles: User roles that a user must be one of for the permission to be granted
//    - requiredFeatureFlags: Feature flags that must be enabled in LaunchDarkly for the permission
//      to be granted
// ------------------------------------------------------------------------------------------------
export const rules: Record<Permission, RuleDefinition> = {
  // ACCOUNT
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageProfile]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
      legacyInternalRoles.conversationseditor.value as UserRole,
      legacyInternalRoles.conversationsviewer.value as UserRole,
    ],
  },

  [Permission.RoleManagementView]: {
    allowedRoles: [legacyInternalRoles.superadmin.value as UserRole],
  },

  // PLANS
  // ----------------------------------------------------------------------------------------------
  [Permission.ViewPlans]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },
  [Permission.ManagePlans]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // PLANS ATTRIBUTES
  [Permission.ViewPlansAttributes]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  [Permission.ManagePlansAttributes]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // PLAN SETS
  [Permission.ManagePlanSets]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // SERVICERS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageServicers]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // ORDERS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageOrders]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // CONTRACTS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageContracts]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // PRODUCTS
  // ----------------------------------------------------------------------------------------------

  // Plan Mapping Validation
  [Permission.ManageProducts]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // Category Mapping
  [Permission.ManageCategories]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // CLAIMS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageClaims]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  [Permission.ManageClaimsAssignments]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // Premiums
  // ----------------------------------------------------------------------------------------------
  [Permission.ManagePremiums]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  [Permission.ViewPremiums]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // Insurance Programs
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageInsurancePrograms]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // ADJUDICATION
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageAdjudication]: {
    allowedRoles: [legacyInternalRoles.conversationseditor.value as UserRole],
  },

  [Permission.ViewAdjudication]: {
    allowedRoles: [
      legacyInternalRoles.conversationseditor.value as UserRole,
      legacyInternalRoles.conversationsviewer.value as UserRole,
    ],
  },

  // STORES
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageStores]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  [Permission.CreateStores]: {
    allowedRoles: [],
  },

  // ORGANIZATIONS
  // ----------------------------------------------------------------------------------------------
  [Permission.ViewOrganizations]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
    requiredFeatureFlags: [LDFlag.OrganizationsManagement],
  },
  [Permission.ManageOrganizations]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
    requiredFeatureFlags: [LDFlag.OrganizationsManagement],
  },

  // ACCOUNTS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageAccounts]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  [Permission.CreateAccounts]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // USERS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageMerchantUsers]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  [Permission.ViewMerchantUsers]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  [Permission.ManageExtendUsers]: {
    allowedRoles: [legacyInternalRoles.superadmin.value as UserRole],
  },

  // WARRANTY DECISIONING
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageWarrantyDecisioning]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // TERMS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageTerms]: {
    allowedRoles: [
      legacyInternalRoles.superadmin.value as UserRole,
      legacyInternalRoles.admin.value as UserRole,
    ],
  },

  // EMBEDDED PROMOTIONS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageEPPDownload]: {
    allowedRoles: [],
  },
}
