import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { ModalController } from '@extend/zen'
import {
  useGetPlanCategoriesQuery,
  useListAllPlanIdsWithCoverageTypeQuery,
  useListPlanSetsQuery,
} from '@helloextend/extend-api-rtk-query'
import type { PlanSet } from '@helloextend/extend-api-client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { PlanSetModal } from './modal'
import { PlanSetsTable } from './table'
import { LDFlag } from '../../../constants/ld-flags'
import { DeletePlanSetModal } from './modal/components/modal'

const PlanSets: FC = () => {
  const {
    pdr: { enabled: pdrEnabled },
  } = useFlags()[LDFlag.OffersFeatures]
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const { data: { items: planCategories = [] } = {} } = useGetPlanCategoriesQuery()
  const { data: planSets = [], isFetching: isListPlanSetsLoading } = useListPlanSetsQuery()
  const { data: planIdsCoverageTypeMap, isLoading: isPlanIdsLoading } =
    useListAllPlanIdsWithCoverageTypeQuery()
  const [planSetToEdit, setPlanSetToEdit] = useState<PlanSet>()
  const [planSetToDelete, setPlanSetToDelete] = useState<PlanSet>()

  const toggleEditModal = useCallback((): void => {
    setPlanSetToEdit(undefined)
    setIsEditModalOpen(!isEditModalOpen)
  }, [isEditModalOpen])
  const toggleDeleteModal = useCallback((): void => {
    setPlanSetToDelete(undefined)
  }, [])

  const handleEditPlanSet = useCallback(
    (planSetId: string): void => {
      const targetPlanSet = planSets.find(({ id }) => id === planSetId)
      setIsEditModalOpen(true)
      setPlanSetToEdit(targetPlanSet)
    },
    [planSets],
  )
  const handleDeletePlanSet = useCallback(
    (planSetId: string): void => {
      const targetPlanSet = planSets.find(({ id }) => id === planSetId)
      setPlanSetToDelete(targetPlanSet)
    },
    [planSets],
  )

  return (
    <>
      <ModalController isOpen={isEditModalOpen}>
        <PlanSetModal
          toggleModal={toggleEditModal}
          planCategories={planCategories}
          planSetToEdit={planSetToEdit}
          planIdsCoverageTypeMap={planIdsCoverageTypeMap}
          isPlanIdsLoading={isPlanIdsLoading}
          pdrEnabled={pdrEnabled}
          planSets={planSets}
        />
      </ModalController>
      <ModalController isOpen={!!planSetToDelete}>
        <DeletePlanSetModal
          toggleModal={toggleDeleteModal}
          planSetId={planSetToDelete?.id || ' '}
        />
      </ModalController>
      <PlanSetsTable
        planSets={planSets}
        planCategories={planCategories}
        isFetching={isListPlanSetsLoading}
        handleCreatePlanSet={toggleEditModal}
        handleEditPlanSet={handleEditPlanSet}
        handleDeletePlanSet={handleDeletePlanSet}
      />
    </>
  )
}

export { PlanSets }
