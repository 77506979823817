import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import dayjs from 'dayjs'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import { currency as CurrencyFormat, date as DateFormat } from '@extend/client-helpers'
import styled from '@emotion/styled'
import type { ChipProps } from '@extend/zen'
import { Chip } from '@extend/zen'
import { Menu } from '../menu'
import type { FilterOptions, FilterValues } from './types'
import { DateRange } from './types'
import { Filter } from './filter'
import { dateRangeToString } from '../../utils/date-range-filter'
import { MENU_MAX_HEIGHT } from './constants'

export type FilterChipProps = {
  property: string
  filter: FilterOptions
  values: FilterValues
  onFilterChange: (property: string, values: FilterValues | null) => void
  chipFilterValuesCount?: number
}

const formatDate = (date: Date): string => {
  return dayjs(date).format(DateFormat.standardDateFormat)
}

const formatNumber = (number: number, isCurrency = false): string => {
  if (isCurrency) {
    return CurrencyFormat.format(number * 100)
  }
  return number.toString()
}

const buildChipText = (filter: FilterOptions, values: FilterValues): string => {
  switch (filter.type) {
    case 'checkbox':
      if (values.type === 'checkbox') {
        if (values.values.length === 0) return 'All'
        return Object.entries(filter.options)
          .filter(([value]) => values.values.includes(value))
          .map(([, label]) => label)
          .join(', ')
      }
      break
    case 'nestedCheckbox':
      if (values.type === 'nestedCheckbox') {
        const selectedValues = values.values as Record<string, string[]>
        return Object.entries(filter.filters)
          .filter(([key]) => selectedValues[key])
          .map(([key, object]) => {
            if (!object.options) {
              return object.label
            }
            const mappedValues: string[] = selectedValues[key].map((value) => {
              return (object.options as Record<string, string>)[value]
            })

            return `${object.label}${mappedValues.length ? ` - ${mappedValues.join(',')}` : ''}`
          })
          .join(', ')
      }
      break
    case 'dateRange':
      if (values.type === 'dateRange') {
        if (values.range !== DateRange.custom) {
          return dateRangeToString(values.range)
        }
        if (values.end && values.start) {
          return `${formatDate(values.start)} to ${formatDate(values.end)}`
        }
        if (values.start) {
          return `${formatDate(values.start)} - Now`
        }
        if (values.end) {
          return `Start - ${formatDate(values.end)}`
        }
      } else {
        return 'All'
      }
      break
    case 'text':
      if (values.type === 'text') {
        return values.value
      }
      break
    case 'advancedText':
      if (values.type === 'advancedText') {
        const formatTextArr: string[] = []
        if (values.contains) formatTextArr.push(`Contains: ${values.contains}`)
        if (values.excludes) formatTextArr.push(`Does Not Contain: ${values.excludes}`)
        if (values.startsWith) formatTextArr.push(`Begins With: ${values.startsWith}`)
        if (values.endsWith) formatTextArr.push(`Ends With: ${values.endsWith}`)
        const filtersString: string =
          formatTextArr.length > 1
            ? formatTextArr.slice(0, -1).join(', ').concat(` and ${formatTextArr.pop()}`)
            : `${formatTextArr.pop()}`
        const caseSensiteString = values.caseSensitive
          ? filtersString.concat(' (case sensitive)')
          : filtersString
        return caseSensiteString
      }
      break
    case 'numberRange':
      if (values.type === 'numberRange') {
        if (values.low && values.high) {
          return `${formatNumber(values.low, filter.isCurrency)} to ${formatNumber(
            values.high,
            filter.isCurrency,
          )}`
        }
        if (values.low) {
          return `${formatNumber(values.low, filter.isCurrency)} and higher`
        }
        if (values.high) {
          return `${formatNumber(values.high, filter.isCurrency)} and lower`
        }
      }
  }

  return ''
}

const FilterChip: FC<FilterChipProps> = ({ property, filter, values, onFilterChange }) => {
  const [isMenuOpen, { toggle, off }] = useToggle()
  const [lockMenu, setLockMenu] = useState(false)

  const shouldChipContainOverflow = filter.type === 'checkbox' || filter.type === 'nestedCheckbox'
  const maxHeight = shouldChipContainOverflow ? MENU_MAX_HEIGHT : undefined

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    if (!lockMenu) {
      off()
    }
  })

  const handleMenuLock = useCallback((isLocked) => {
    setLockMenu(isLocked)
  }, [])

  const handleDismiss = useCallback(() => {
    onFilterChange(property, null)
  }, [property, onFilterChange])

  const hasBuiltChipText = buildChipText(filter, values) !== 'All'
  const hasMultipleFilters = Object.keys(filter).length > 1

  const chipProps: ChipProps = {
    'data-cy': 'filter',
    label: filter.label,
    text: buildChipText(filter, values),
    onClick: () => toggle(),
  }

  if (hasMultipleFilters && hasBuiltChipText) {
    chipProps.onDismissClick = handleDismiss
  }

  return (
    <div ref={ref}>
      <Chip {...chipProps} />
      <ChildWrapper>
        <Menu isOpen={isMenuOpen} position="left" width={216} maxHeight={maxHeight}>
          {filter.type !== 'group' && (
            <Filter
              property={property}
              filter={filter}
              values={values}
              onFilterChange={onFilterChange}
              onMenuLock={handleMenuLock}
            />
          )}
        </Menu>
      </ChildWrapper>
    </div>
  )
}

const ChildWrapper = styled.div({
  position: 'absolute',
  top: 32,
})

export { FilterChip }
