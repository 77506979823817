import type { FC } from 'react'
import React, { useMemo, useCallback, useState } from 'react'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { ModalController } from '@extend/zen'
import type { CategoryMapping, StoreIdAndName } from '@helloextend/extend-api-client'
import {
  useGetPlanCategoriesQuery,
  useListAllStoreIdsWithNameQuery,
  useListPlanCategoriesQuery,
} from '@helloextend/extend-api-rtk-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../constants/ld-flags'
import { StoreSelector } from './store-catalog-select'
import { CategoryMapperTable } from './category-mapper-table'
import { CategoryMapperModal } from './modal'
import type { PlanCategoryProps } from './category-mapper-table'

const CategoryMapper: FC = () => {
  const flags = useFlags()
  const offersFeatures = flags[LDFlag.OffersFeatures]
  const [selectedStore, setSelectedStore] = useState<StoreIdAndName>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedMappings, setSelectedMappings] = useState<CategoryMapping[]>([])
  const { data: { values: unSortedCategories = [] } = {} } = useListPlanCategoriesQuery()
  const { data: { items: unsortedTaxonomyCategories = [] } = {} } = useGetPlanCategoriesQuery()
  const { data: stores = [], isLoading } = useListAllStoreIdsWithNameQuery()

  const sortedPlanAttributesCategories = useMemo<PlanCategoryProps[]>(() => {
    return [...unSortedCategories].sort().map((category) => {
      const cat = unsortedTaxonomyCategories.find(
        (categoryWithId) => categoryWithId.name === category,
      )

      return {
        display: category,
        value: category,
        planCategoryId: cat?.id ?? category,
      }
    })
  }, [unsortedTaxonomyCategories, unSortedCategories])

  const sortedTaxonomyCategories = useMemo<PlanCategoryProps[]>(() => {
    return [...unsortedTaxonomyCategories]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ name, id }) => ({
        display: name,
        value: name,
        planCategoryId: id,
      }))
  }, [unsortedTaxonomyCategories])

  const toggleModal = useCallback((): void => {
    setIsModalOpen(!isModalOpen)
  }, [isModalOpen])

  const handleSelectedStore = useCallback(
    (e: AdvancedSelectChangeEvent): void => {
      const store = stores.find(({ id }) => id === e.target.value)
      setSelectedStore(store)
    },
    [stores],
  )

  const handleSelectedMappings = useCallback(
    (mappings?: CategoryMapping[]): void => {
      if (mappings) {
        setSelectedMappings(mappings)
        toggleModal()
      }
    },
    [toggleModal],
  )

  return (
    <>
      <StoreSelector
        selectedStore={selectedStore}
        stores={stores}
        handleSelectedStore={handleSelectedStore}
        isLoading={isLoading}
      />
      {selectedStore && (
        <>
          <CategoryMapperTable
            selectedStore={selectedStore}
            toggleModal={toggleModal}
            handleSelectedMappings={handleSelectedMappings}
            categories={
              offersFeatures.pdr.enabled ? sortedTaxonomyCategories : sortedPlanAttributesCategories
            }
          />

          <ModalController isOpen={isModalOpen}>
            <CategoryMapperModal
              toggleModal={toggleModal}
              mappings={selectedMappings}
              categories={
                offersFeatures.pdr.enabled
                  ? sortedTaxonomyCategories
                  : sortedPlanAttributesCategories
              }
              storeIdAndName={selectedStore}
            />
          </ModalController>
        </>
      )}
    </>
  )
}

export { CategoryMapper }
