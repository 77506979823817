import type { FC } from 'react'
import React from 'react'
import { Button, ButtonGroup, Edit, Trash, COLOR } from '@extend/zen'
import styled from '@emotion/styled'
import { useToggle } from '@helloextend/client-hooks'
import { RemovePlanSetModal } from '../../remove-plan-set-modal/remove-plan-set-modal'
import { EditPlanSetModal } from '../../edit-plan-set-modal'

type PlanSetRowButtonsProps = {
  planSetMappingId: string
  planSetMappingName: string
  storeId: string
  category: string
  categoryId: string
}

const PlanSetRowButtons: FC<PlanSetRowButtonsProps> = ({
  planSetMappingId,
  category,
  categoryId,
  planSetMappingName,
  storeId,
}) => {
  const [isRemovePlanSetModalOpen, { on: toggleModalOn, off: toggleModalOff }] = useToggle(false)
  const [isEditPlanSetModalOpen, { on, off }] = useToggle(false)
  // this is needed to prevent the whole row onClick event when inside this cell or modal content is clicked
  const handleRowClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
  }

  return (
    <Container onClick={handleRowClick}>
      <ButtonGroup isReversed>
        <Button
          icon={Trash}
          size="small"
          color={COLOR.BLUE[800] as 'blue'}
          emphasis="low"
          onClick={toggleModalOn}
          data-cy="delete-plan-set-button"
        />
        <Button
          icon={Edit}
          data-cy="edit-plan-set-button"
          size="small"
          color={COLOR.BLUE[800] as 'blue'}
          emphasis="medium"
          onClick={on}
        />
      </ButtonGroup>
      <RemovePlanSetModal
        isModalOpen={isRemovePlanSetModalOpen}
        toggleModalOff={toggleModalOff}
        storePlanSetMappingId={planSetMappingId}
        planSetMappingName={planSetMappingName}
        storeId={storeId}
      />
      <EditPlanSetModal
        isModalOpen={isEditPlanSetModalOpen}
        closeModal={off}
        storePlanSetMappingId={planSetMappingId}
        category={category}
        categoryId={categoryId}
      />
    </Container>
  )
}

const Container = styled.div({})

export { PlanSetRowButtons }
