import type { FC, SyntheticEvent } from 'react'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { AdvancedSelect, Input, Button, COLOR, Checkbox } from '@extend/zen'
import { currency } from '@extend/client-helpers'
import type {
  ExpenseType,
  ServiceOrderExpensesCreateRequest,
  ServiceOrderStatus,
  ServiceType,
} from '@helloextend/extend-api-client'
import { CurrencyInput } from '../../../../../../../../components/currency-input'
import type { MetaDataValues } from './schema'
import { baseSchema, metaDataLabels, getMetaDataForType, multiplyCostAndQuantity } from './schema'
import { NumberInput } from '../../../../../../../../components/number-input'
import { expenseOwners, expenseTypes } from './mappings'
import { LDFlag } from '../../../../../../../../constants/ld-flags'

type AddExpenseFormProps = {
  handleClose: (body?: ServiceOrderExpensesCreateRequest) => void
  isLoading: boolean
  isSuccess: boolean
  status?: ServiceOrderStatus
  serviceType?: ServiceType
}

const AddExpenseForm: FC<AddExpenseFormProps> = ({
  handleClose,
  isLoading,
  status,
  serviceType,
}) => {
  const { [LDFlag.GoodwillExpense]: FF_GOODWILL_EXPENSE } = useFlags()
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    touched,
    errors,
    resetForm,
    handleChange,
    setTouched,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: baseSchema,
    initialValues: baseSchema.getDefault(),
    onSubmit: () => {
      const { metaData, wholesaleCost, ...requestValues } = values
      const body = {
        ...requestValues,
        // @ts-ignore
        ...(metaData && { metaData }),
        // @ts-ignore
        ...(wholesaleCost && { wholesaleCost: { amount: wholesaleCost } }),
      }

      handleClose(body as ServiceOrderExpensesCreateRequest)
    },
  })

  const handleQuantityChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget
    const formVal = value == null || value === '' ? null : +value
    setFieldValue('quantity', formVal)
  }

  const setMetaDataForType = (type: ExpenseType): void => {
    const value = getMetaDataForType(type)
    setFieldValue('metaData', value)
  }

  const setDefaultValuesForType = (type: ExpenseType): void => {
    if (type === 'cleaning_kit') {
      setFieldValue('cost.amount', 500)
    }
  }

  const handleButtonClick = (): void => {
    handleClose()
  }

  const handleGoodwillToggle = (): void => {
    setFieldValue('isGoodwill', !values.isGoodwill)
  }

  const handleExpenseOwners = (): Array<{ value: string; display: string }> => {
    if (serviceType === 'replace' && (status === 'payment_approved' || status === 'paid')) {
      return expenseOwners.filter(
        (eo: { value: string; display: string }) =>
          eo.value === 'extend' || eo.value === 'manufacturer',
      )
    }
    return expenseOwners
  }

  useEffect(() => {
    return () => resetForm()
  }, [resetForm])

  return (
    <Form data-cy="add-expense-form" onSubmit={handleSubmit}>
      <Header>
        <Title>Add Expense</Title>
      </Header>
      <RowWrapper>
        <AdvancedSelect
          id="type"
          label="Expense Type"
          onChange={(e) => {
            setTouched({ type: true })
            setFieldValue('type', e.target.value)
            setMetaDataForType(e.target.value as ExpenseType)
            setDefaultValuesForType(e.target.value as ExpenseType)
            if (!e.target.value) {
              resetForm()
            }
          }}
          value={values.type}
          placeholder="Select"
          data-cy="select-type-button"
          isError={touched.type && Boolean(errors.type)}
          options={expenseTypes}
          multiple={false}
        />
        <DoubleLengthWrapper>
          <Input
            data-cy="expense-description"
            id="description"
            label="Description"
            value={values.description ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.description && Boolean(errors.description)}
          />
        </DoubleLengthWrapper>
        {FF_GOODWILL_EXPENSE && (
          <FieldWrapper>
            <CheckboxWrapper>
              <Checkbox
                checked={!!values.isGoodwill}
                label="Goodwill"
                data-cy="expense-goodwill-checkbox"
                onChange={handleGoodwillToggle}
              />
            </CheckboxWrapper>
          </FieldWrapper>
        )}
        <FieldWrapper>
          {values.metaData &&
            Object.entries(values.metaData).map(([key, value]) => (
              <Input
                data-cy={`expense-metadata-${key}`}
                key={key}
                id={`metaData.${key}`}
                label={metaDataLabels[key as keyof MetaDataValues]}
                value={value as string}
                onChange={handleChange}
                onBlur={handleBlur}
                isError={
                  Boolean(values.type) &&
                  touched.metaData &&
                  Boolean(errors.metaData && errors.metaData[key as keyof MetaDataValues])
                }
              />
            ))}
        </FieldWrapper>
      </RowWrapper>
      <RowWrapper>
        <FieldWrapper>
          <IncurredByWrapper>
            <AdvancedSelect
              label="Incurred By"
              id="incurredBy"
              onChange={(e) => {
                setTouched({})
                handleChange(e)
              }}
              value={values.incurredBy ?? ''}
              data-cy="select-incurred-by-button"
              placeholder="Select"
              isError={touched.incurredBy && Boolean(errors.incurredBy)}
              options={handleExpenseOwners()}
              multiple={false}
            />
          </IncurredByWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <CurrencyInput
            data-cy="expense-cost"
            name="cost.amount"
            label="Rate"
            onChange={(changeVal) => {
              setFieldValue('cost.amount', changeVal)
            }}
            onBlur={handleBlur}
            value={values.cost?.amount}
            showSymbol={false}
            placeholder="$0.00"
            isGroup={false}
            currencyCode="USD"
            invalid={touched.cost?.amount && Boolean(errors.cost?.amount)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <CurrencyInput
            data-cy="wholesale-cost-input"
            name="wholesaleCost"
            label="Wholesale Cost"
            onChange={(changeVal) => {
              setFieldValue('wholesaleCost', changeVal)
            }}
            onBlur={handleBlur}
            value={values.wholesaleCost}
            showSymbol={false}
            placeholder="$0.00"
            isGroup={false}
            currencyCode="USD"
            invalid={touched.wholesaleCost && Boolean(errors.wholesaleCost)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <QuantityWrapper>
            <NumberInput
              data-cy="expense-quantity"
              id="quantity"
              label="Quantity"
              onChange={handleQuantityChange}
              onBlur={handleBlur}
              disabled={false}
              step={0.25}
              value={values.quantity}
              hasError={touched.quantity && Boolean(errors.quantity)}
              errorMessage={errors.quantity}
            />
          </QuantityWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <Label>Total</Label>
          <Total>
            {currency.format(multiplyCostAndQuantity(values.cost?.amount || 0, values.quantity))}
          </Total>
        </FieldWrapper>
      </RowWrapper>
      <ButtonGroup>
        <Button
          emphasis="medium"
          text="Cancel"
          onClick={handleButtonClick}
          data-cy="cancel-button"
        />
        <Button
          data-cy="add-expense-submit"
          text="Add Expense"
          type="submit"
          isProcessing={isLoading}
        />
      </ButtonGroup>
    </Form>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginBottom: 12,
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

const Form = styled.form({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  marginTop: 26,
  borderTop: `1px solid ${COLOR.NEUTRAL[300]}`,
  paddingTop: 40,
})

const Label = styled.label({
  display: 'block',
  fontSize: 14,
  lineHeight: '18px',
  fontWeight: 600,
  paddingBottom: 5,
  color: COLOR.NEUTRAL[1000],
})

const Total = styled.p({
  paddingBottom: 4,
  margin: 0,
  marginTop: 8,
})

const RowWrapper = styled.div({
  display: 'flex',
  width: '100%',
  marginBottom: 16,
  flexDirection: 'row',
  '& > *': {
    flex: '0 0 calc(20% - 16px)',
  },
  gap: 16,
})

const FieldWrapper = styled.div({
  width: '100%',
})

const DoubleLengthWrapper = styled.div({
  flexBasis: 'calc(40% - 16px) !important',
})

const CheckboxWrapper = styled.div({
  height: '100%',
  display: 'flex',
  alignItems: 'flex-end',
})

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: 32,
})

const QuantityWrapper = styled.div({
  width: '100%',
  div: {
    position: 'relative',
    top: -1,
  },
  input: {
    position: 'relative',
    top: -1,
  },
})

const IncurredByWrapper = styled.div({
  width: '100%',
  div: {
    position: 'relative',
    top: -1,
  },
  input: {
    position: 'relative',
    top: -1,
  },
})

export type { AddExpenseFormProps }
export { AddExpenseForm }
