import { plansSelectors, contractsSelectors, productsSelectors } from '@helloextend/core-api-redux'
import type { Store } from '@helloextend/extend-api-client'
import type { RuleStatus } from '@helloextend/extend-api-rtk-query'
import {
  getThread,
  getIsThreadTextAssigned,
  getIsEditorPanelVisible,
  getConversation,
  getSingleUseThreads,
  getMessageBlockIndex,
  getIsReusableThreadPickerVisible,
  getSelectedMessageBlock,
  getThreadIdToReplace,
  getPlaceholderMessageBlockIndex,
  getIsPublishValidationModeActive as isPublishValidationModeActive,
  getMessageBlockReferenceMap,
  getIsDuplicateThreadModalVisible as isDuplicateModalVisible,
  getIsArchiveConversationModalVisible as isArchiveConversationModalVisible,
  getIsArchiveThreadModalVisible as isArchiveThreadModalVisible,
  getIsRenameThreadModalVisible as isRenameThreadModalVisible,
  getIsRenameConversationModalVisible as isRenameConversationModalVisible,
  getSelectedThreadListItem as getThreadListItem,
  getConversationAdjudicationThread,
  getRuleset as getThreadRuleset,
  getCondition as getRuleCondition,
  getRule as getThreadRule,
  getAvailableMessageBlocksForRule as getThreadAvailableMessageBlocksForRule,
  getHasValidConditions as getRulesetHasValidConditions,
  getConversationReusableThreads as getReusableThreads,
  getSlicePhotoRequirements,
  getSliceIsPhotosetValid,
} from '../store/slices/amp-slice'
import type { RootState } from '.'
import * as fromStores from './stores/selectors'
import * as fromServicers from './servicers/selectors'
import { getClaimsUrl } from '../store/slices/claim-breadcrumb'
import {
  getClaimDetailsActiveView,
  getClaimDetailsToast,
  getLastUpdatedClaimId,
} from '../store/slices/claim-details'

// store
// ==================================================================================
export function getStoreById(state: RootState, storeId?: string): Store | null {
  return fromStores.getById(state.stores, storeId)
}

export function getStoreErrors(state: RootState): ReturnType<typeof fromStores.getError> {
  return fromStores.getError(state.stores)
}

// contracts
// ==================================================================================
export function getContractById(
  state: RootState,
  contractId: string,
): ReturnType<typeof contractsSelectors.getById> {
  return contractsSelectors.getById(state.contracts, contractId)
}

export function getIsContractLoading(
  state: RootState,
): ReturnType<typeof contractsSelectors.getIsLoading> {
  return contractsSelectors.getIsLoading(state.contracts)
}

export function getIsContractSearching(
  state: RootState,
): ReturnType<typeof contractsSelectors.getIsSearching> {
  return contractsSelectors.getIsSearching(state.contracts)
}

export function getContractsError(
  state: RootState,
): ReturnType<typeof contractsSelectors.getError> {
  return contractsSelectors.getError(state.contracts)
}

export function getContractsSearchItems(
  state: RootState,
): ReturnType<typeof contractsSelectors.getContractsSearchItems> {
  return contractsSelectors.getContractsSearchItems(state.contracts)
}

export function getContractsSearchItemsCount(
  state: RootState,
): ReturnType<typeof contractsSelectors.getContractsSearchItemsCount> {
  return contractsSelectors.getContractsSearchItemsCount(state.contracts)
}

export function getContractsHasNextPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.hasNextPage> {
  return contractsSelectors.hasNextPage(state.contracts)
}

export function getContractsHasPrevPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.hasPrevPage> {
  return contractsSelectors.hasPrevPage(state.contracts)
}

export function getContractsPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.getContractsPage> {
  return contractsSelectors.getContractsPage(state.contracts)
}

export function getContractAuditHistory(
  state: RootState,
): ReturnType<typeof contractsSelectors.getAuditHistory> {
  return contractsSelectors.getAuditHistory(state.contracts)
}

export function getContractAuditHistoryCursor(
  state: RootState,
): ReturnType<typeof contractsSelectors.getAuditHistoryCursor> {
  return contractsSelectors.getAuditHistoryCursor(state.contracts)
}

export function getHasContractAuditHistoryPrevPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.hasPrevPageAuditHistory> {
  return contractsSelectors.hasPrevPageAuditHistory(state.contracts)
}

export function getContractAuditHistoryLastFetchedPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.getLastFetchedPageAuditHistory> {
  return contractsSelectors.getLastFetchedPageAuditHistory(state.contracts)
}

export function getContractAuditHistoryCurrentPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.getAuditHistoryCurrentPage> {
  return contractsSelectors.getAuditHistoryCurrentPage(state.contracts)
}

export function getIsContractUpdating(
  state: RootState,
): ReturnType<typeof contractsSelectors.getIsUpdating> {
  return contractsSelectors.getIsUpdating(state.contracts)
}

export function getIsContractUpdateSuccess(
  state: RootState,
): ReturnType<typeof contractsSelectors.getIsSuccess> {
  return contractsSelectors.getIsSuccess(state.contracts)
}

export function getContractSearchTerm(
  state: RootState,
): ReturnType<typeof contractsSelectors.getSearchTerm> {
  return contractsSelectors.getSearchTerm(state.contracts)
}

export function getContractSearchKey(
  state: RootState,
): ReturnType<typeof contractsSelectors.getSearchKey> {
  return contractsSelectors.getSearchKey(state.contracts)
}

// plans
// ==================================================================================
export function getIsPlansLoading(
  state: RootState,
): ReturnType<typeof plansSelectors.getIsLoading> {
  return plansSelectors.getIsLoading(state.plans)
}

export function getIsPlansSuccess(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansSuccess> {
  return plansSelectors.getPlansSuccess(state.plans)
}

export function getPlansError(state: RootState): ReturnType<typeof plansSelectors.getError> {
  return plansSelectors.getError(state.plans)
}

export function getPlansSearchFilter(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansSearchFilter> {
  return plansSelectors.getPlansSearchFilter(state.plans)
}

export function getPlansSearchItems(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansSearchItems> {
  return plansSelectors.getPlansSearchItems(state.plans)
}

export function getPlansSearchCursor(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansSearchCursor> {
  return plansSelectors.getPlansSearchCursor(state.plans)
}

export function getPlansSearchPage(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansPage> {
  return plansSelectors.getPlansPage(state.plans)
}

export function getPlansLastFetchedPage(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansLastFetchedPage> {
  return plansSelectors.getPlansLastFetchedPage(state.plans)
}

export function getHasPlansPrevPage(
  state: RootState,
): ReturnType<typeof plansSelectors.hasPrevPage> {
  return plansSelectors.hasPrevPage(state.plans)
}

export function getIsPlansCreating(
  state: RootState,
): ReturnType<typeof plansSelectors.getIsCreating> {
  return plansSelectors.getIsCreating(state.plans)
}

export function getPlanById(
  state: RootState,
  planId: string,
): ReturnType<typeof plansSelectors.getById> {
  return plansSelectors.getById(state.plans, planId)
}

// servicers
// ===============================================================================
export function getServicersSearchFilter(
  state: RootState,
): ReturnType<typeof fromServicers.getSearchFilter> {
  return fromServicers.getSearchFilter(state.servicersLocal)
}

export function getServicersSearchDropdownFilter(
  state: RootState,
): ReturnType<typeof fromServicers.getSearchDropdownFilter> {
  return fromServicers.getSearchDropdownFilter(state.servicersLocal)
}

// claims
// ===============================================================================
export function getClaimBreadcrumbUrl(state: RootState): ReturnType<typeof getClaimsUrl> {
  return getClaimsUrl(state.claimBreadcrumbs)
}

export function getActiveClaimDetailsView(
  state: RootState,
): ReturnType<typeof getClaimDetailsActiveView> {
  return getClaimDetailsActiveView(state.claimDetails)
}

export function getClaimDetailsToastMessage(
  state: RootState,
): ReturnType<typeof getClaimDetailsToast> {
  return getClaimDetailsToast(state.claimDetails)
}

export function getPreviouslyUpdatedClaimId(
  state: RootState,
): ReturnType<typeof getLastUpdatedClaimId> {
  return getLastUpdatedClaimId(state.claimDetails)
}

// AMP
// ===============================================================================
export function getSelectedThread(state: RootState): ReturnType<typeof getThread> {
  return getThread(state.amp)
}

export function getIsSelectedThreadTextAssigned(
  state: RootState,
): ReturnType<typeof getIsThreadTextAssigned> {
  return getIsThreadTextAssigned(state.amp)
}

export function getSelectedThreadMessageBlock(
  state: RootState,
): ReturnType<typeof getSelectedMessageBlock> {
  return getSelectedMessageBlock(state.amp)
}

export function getEditorVisibility(state: RootState): ReturnType<typeof getIsEditorPanelVisible> {
  return getIsEditorPanelVisible(state.amp)
}

export function getReusableThreadPickerVisibility(
  state: RootState,
): ReturnType<typeof getIsReusableThreadPickerVisible> {
  return getIsReusableThreadPickerVisible(state.amp)
}

export function getSelectedConversation(state: RootState): ReturnType<typeof getConversation> {
  return getConversation(state.amp)
}

export function getSingleUseThreadsMap(state: RootState): ReturnType<typeof getSingleUseThreads> {
  return getSingleUseThreads(state.amp)
}

export function getConversationReusableThreads(
  state: RootState,
): ReturnType<typeof getReusableThreads> {
  return getReusableThreads(state.amp)
}

export function getSelectedMessageBlockIndex(
  state: RootState,
): ReturnType<typeof getMessageBlockIndex> {
  return getMessageBlockIndex(state.amp)
}

export function getCurrentPlaceholderMessageBlockIndex(
  state: RootState,
): ReturnType<typeof getPlaceholderMessageBlockIndex> {
  return getPlaceholderMessageBlockIndex(state.amp)
}

export function getSelectedThreadIdToReplace(
  state: RootState,
): ReturnType<typeof getThreadIdToReplace> {
  return getThreadIdToReplace(state.amp)
}

export function getCurrentMessageBlockReferenceMap(
  state: RootState,
): ReturnType<typeof getMessageBlockReferenceMap> {
  return getMessageBlockReferenceMap(state.amp)
}

export function getIsPublishValidationModeActive(
  state: RootState,
): ReturnType<typeof isPublishValidationModeActive> {
  return isPublishValidationModeActive(state.amp)
}

export function getIsDuplicateThreadModalVisible(
  state: RootState,
): ReturnType<typeof isDuplicateModalVisible> {
  return isDuplicateModalVisible(state.amp)
}

export function getIsArchiveConversationModalVisible(
  state: RootState,
): ReturnType<typeof isArchiveConversationModalVisible> {
  return isArchiveConversationModalVisible(state.amp)
}

export function getIsArchiveThreadModalVisible(
  state: RootState,
): ReturnType<typeof isArchiveThreadModalVisible> {
  return isArchiveThreadModalVisible(state.amp)
}

export function getIsRenameThreadModalVisible(
  state: RootState,
): ReturnType<typeof isRenameThreadModalVisible> {
  return isRenameThreadModalVisible(state.amp)
}

export function getIsRenameConversationModalVisible(
  state: RootState,
): ReturnType<typeof isRenameConversationModalVisible> {
  return isRenameConversationModalVisible(state.amp)
}

export function getSelectedThreadListItem(state: RootState): ReturnType<typeof getThreadListItem> {
  return getThreadListItem(state.amp)
}

export function getCurrentAdjudicationThread(
  state: RootState,
): ReturnType<typeof getConversationAdjudicationThread> {
  return getConversationAdjudicationThread(state.amp)
}

export function getRuleset(state: RootState): ReturnType<typeof getThreadRuleset> {
  return getThreadRuleset(state.amp)
}

export function getRule({
  state,
  rulesetType,
  ruleIndex,
}: {
  state: RootState
  rulesetType: RuleStatus
  ruleIndex: number
}): ReturnType<typeof getThreadRule> {
  return getThreadRule({ state: state.amp, rulesetType, ruleIndex })
}

export function getCondition({
  state,
  rulesetType,
  ruleIndex,
  conditionIndex,
}: {
  state: RootState
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
}): ReturnType<typeof getRuleCondition> {
  return getRuleCondition({ state: state.amp, rulesetType, ruleIndex, conditionIndex })
}

export function getAvailableMessageBlocksForRule({
  state,
  source,
  rulesetType,
  ruleIndex,
  conditionIndex,
}: {
  state: RootState
  source: 'conversation' | 'thread'
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
}): ReturnType<typeof getThreadAvailableMessageBlocksForRule> {
  return getThreadAvailableMessageBlocksForRule({
    state: state.amp,
    source,
    rulesetType,
    ruleIndex,
    conditionIndex,
  })
}

export function getHasValidConditions(
  state: RootState,
): ReturnType<typeof getRulesetHasValidConditions> {
  return getRulesetHasValidConditions(state.amp)
}

// Products
export function getProductById(
  state: RootState,
  storeId: string,
  referenceId: string,
): ReturnType<typeof productsSelectors.getById> {
  return productsSelectors.getById(state.products, storeId, referenceId)
}

export function getProductsError(state: RootState): ReturnType<typeof productsSelectors.getError> {
  return productsSelectors.getError(state.products)
}

export function getPhotoRequirements(
  state: RootState,
): ReturnType<typeof getSlicePhotoRequirements> {
  return getSlicePhotoRequirements(state.amp)
}

export function getIsPhotosetValid(state: RootState): ReturnType<typeof getSliceIsPhotosetValid> {
  return getSliceIsPhotosetValid(state.amp)
}
