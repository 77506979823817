import type { FC } from 'react'
import React, { useCallback, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Button, Filter } from '@extend/zen'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import { Menu } from '@helloextend/merchants-ui'
import { FiltersMenu } from './filters-menu'
import type { FilterValues, FilterOptions } from './types'
import { FilterChip } from './filter-chip'

export type FiltersProps = {
  filters: Record<string, FilterOptions>
  values: Record<string, FilterValues>
  onFilter: (filters: Record<string, FilterValues>) => void
}

const flattenFilters = (filters: Record<string, FilterOptions>): FilterOptions[] => {
  return Object.entries(filters).reduce<FilterOptions[]>(
    (acc, [key, filter]) =>
      filter.type === 'group'
        ? { ...acc, ...flattenFilters(filter.filters) }
        : { ...acc, [key]: filter },
    [],
  )
}

const Filters: FC<FiltersProps> = ({ filters, values, onFilter }) => {
  const [isMenuOpen, { toggle, off }] = useToggle()
  const [lockMenu, setLockMenu] = useState(false)
  const [filterValues, setFilterValues] = useState<Record<string, FilterValues>>(values)

  useEffect(() => {
    setFilterValues(values)
  }, [values])

  const handleMenuLock = useCallback((isLocked) => {
    setLockMenu(isLocked)
  }, [])

  const handleFilterChange = useCallback(
    (property: string, newValues: FilterValues | null) => {
      let updatedFilters: Record<string, FilterValues>
      if (!newValues) {
        const { [property]: value, ...withoutFilter } = filterValues
        updatedFilters = withoutFilter
      } else {
        updatedFilters = {
          ...filterValues,
          [property]: newValues,
        }
      }
      setFilterValues(updatedFilters)
      onFilter(updatedFilters)
    },
    [filterValues, onFilter],
  )

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    if (!lockMenu) {
      off()
    }
  })

  const flattenedFilters = flattenFilters(filters)

  return (
    <FilterWrapper>
      <div ref={ref}>
        <Button
          emphasis="medium"
          isToggled={isMenuOpen}
          data-cy="filter-trigger"
          text="Filters"
          onClick={toggle}
          icon={Filter}
        />
        <MenuWrapper>
          <Menu isOpen={isMenuOpen} position="left" width={216}>
            <FiltersMenu
              filters={filters}
              values={filterValues}
              onMenuLock={handleMenuLock}
              onFilterChange={handleFilterChange}
            />
          </Menu>
        </MenuWrapper>
      </div>
      <ChipWrapper>
        {Object.entries(flattenedFilters).map(([key, filter]) => {
          const chipFilterValues = filterValues[key]
          return (
            chipFilterValues && (
              <FilterChip
                key={key}
                property={key}
                filter={filter}
                values={chipFilterValues}
                onFilterChange={handleFilterChange}
              />
            )
          )
        })}
      </ChipWrapper>
    </FilterWrapper>
  )
}

const FilterWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
  marginBottom: 12,
  marginTop: 32,
}))

const MenuWrapper = styled.div({
  width: '100%',
  padding: '8px 0px',
  position: 'absolute',
  top: 30,
})

const ChipWrapper = styled.div({
  marginLeft: 16,
  display: 'flex',
  gap: 16,
  minHeight: 40,
  height: '100%',
  flexGrow: 1,
  alignItems: 'center',
  flexWrap: 'wrap',
})

export { Filters }
