import type { Servicer } from '@helloextend/extend-api-client'
import type { TableServicersSearch } from '../../../types/servicers'

// TODO: [DEPOT-1564] Add functionality for filtering

export const toTableServicerItem = (servicer: Servicer): TableServicersSearch => ({
  id: servicer.id,
  servicerId: servicer.id,
  status:
    // NOTE: fulfillmentEnabled is migrated to MerchantServicingSettings
    servicer.settings?.depotRepairEnabled || servicer.settings?.onsiteRepairEnabled
      ? 'active'
      : 'inactive',
  servicerName: servicer.businessName.trimStart() || '',
  email: servicer.contact?.email || '',
  phone: servicer.contact?.phone || '',
})

export const toTableServicerItems = (servicers: Servicer[]): TableServicersSearch[] =>
  servicers.map(toTableServicerItem)
