import type { ServiceOrder, ShipmentStatus } from '@helloextend/extend-api-client'
import type { V2 } from '@extend-services/service-orders'
import { isEmpty } from 'lodash/fp'
import { useListServiceOrderShipmentsQuery } from '@helloextend/extend-api-rtk-query'
import {
  OpenInNew,
  Icon,
  IconSize,
  AccordionSection,
  Stack,
  DataProperty,
  Button,
  COLOR,
  LinkButton,
} from '@extend/zen'
import styled from '@emotion/styled'
import type { FC } from 'react'
import React, { useState, useMemo } from 'react'
import { ShippingLabelForm } from '..'
import { formatDate, formatStartCase } from '../../../../../../../util'
import { VoidLabelConfirmDialog } from './void-label-confirm-dialog'

const SHIP_ENGINE_DOWNLOAD_BASE_URL = 'https://api.shipengine.com/v1/downloads'
interface FailedProductShipmentSectionProps {
  serviceOrder: ServiceOrder
  isExpanded?: boolean
}

type MappedShipmentStatus = Extract<ShipmentStatus, 'received_by_carrier'>
const shipmentStatusMap: Record<MappedShipmentStatus, string> = {
  received_by_carrier: 'In Transit',
}

const FailedProductShipmentSection: FC<FailedProductShipmentSectionProps> = ({
  serviceOrder,
  isExpanded,
}) => {
  const [isExpandedInternal, setIsExpanded] = useState(isExpanded)
  const [isVoidModalVisible, setIsVoidModalVisible] = useState(false)

  const handleToggle = (): void => {
    setIsExpanded(!isExpandedInternal)
  }

  const { data: shipments } = useListServiceOrderShipmentsQuery(serviceOrder.id)

  const shipment = useMemo(() => {
    return (
      shipments?.find(({ destinationType }) => destinationType === 'servicer') ||
      ({} as V2.Models.ServiceOrderShipment)
    )
  }, [shipments]) as V2.Models.ServiceOrderShipment

  function getShipmentDeliveredDate(failedShipment?: V2.Models.ServiceOrderShipment): string {
    return failedShipment?.status === 'delivered'
      ? formatDate(failedShipment?.deliveredAt || 0)
      : formatDate(failedShipment?.estimatedDeliveryAt || 0)
  }

  function parseShippingProtectionContractId(insurancePolicyId: string): string {
    const match = insurancePolicyId.match(/ERN:CON:(.+)/)
    return match ? match[1] : ''
  }

  const downloadLabel = serviceOrder?.shippingLabels?.find((l) => l.destinationType === 'servicer')
  const downloadLabelUrl = downloadLabel?.pdfDownloadUrl || downloadLabel?.imageData
  const isShipmentVoid = shipment?.status === 'voided'
  const handleDownloadShippingLabel = (serviceOrderDownload: ServiceOrder | undefined): void => {
    if (
      serviceOrderDownload?.configurations?.packingSlipRequired &&
      serviceOrderDownload?.attachments?.packingSlip?.packingSlipUrl
    ) {
      window.open(
        serviceOrderDownload.attachments?.packingSlip?.packingSlipUrl,
        '_blank',
        'noopener,noreferrer',
      )
    }

    const labelForDestinationType = serviceOrderDownload?.shippingLabels?.find(
      (label) => label.destinationType === 'servicer',
    )

    // falls back to `imageData` if `pdfDownloadUrl` is not present, for backwards compatibility
    const downloadUrl =
      labelForDestinationType?.pdfDownloadUrl || labelForDestinationType?.imageData || ''
    if (!downloadUrl.startsWith(SHIP_ENGINE_DOWNLOAD_BASE_URL)) return

    window.open(downloadUrl, '_blank', 'noopener,noreferrer')
  }

  const hasShipment = !isEmpty(shipment)
  const isFailedShipment =
    hasShipment && (shipment.shipmentId.startsWith('failed') || shipment.status === 'initialized')
  const hasCustomLabelOptionOn = serviceOrder?.configurations?.hasCustomDefectiveLabel
  const isExtendShippingInsurance =
    hasShipment && shipment?.insurance === 'extend' && !!shipment?.insurancePolicyId
  const shippingProtectionContractId =
    isExtendShippingInsurance &&
    parseShippingProtectionContractId(shipment.insurancePolicyId as string)

  const renderChild = (): JSX.Element => {
    if (hasShipment && !isFailedShipment) {
      return (
        <div data-cy={`${serviceOrder.id}-failed-product-shipment-info-section`}>
          <Stack isRow justify="space-between" data-cy={`${serviceOrder.id}-props`}>
            <DataProperty
              data-cy={`${serviceOrder.id}-status`}
              label="Status"
              value={
                shipmentStatusMap[shipment.status as MappedShipmentStatus] ??
                formatStartCase(shipment.status)
              }
            />
            <DataProperty
              data-cy={`${serviceOrder.id}-tracking-number`}
              label="Tracking Number"
              value={shipment.trackingNumber}
            />
            <DataProperty
              data-cy={`${serviceOrder.id}-ship-date`}
              label="Shipment Ship Date"
              value={formatDate(shipment.shippedAt || 0)}
            />
            <DataProperty
              data-cy={`${serviceOrder.id}-delivered-date`}
              label={shipment.status === 'delivered' ? 'Delivered Date' : 'ETA Date'}
              value={getShipmentDeliveredDate(shipment)}
            />
            <StyledDataProperty data-cy={`${serviceOrder.id}-packing-slip`}>
              <Label>Packing Slip</Label>
              <ValueWrapper>
                {serviceOrder.attachments?.packingSlip?.soloPackingSlipUrl ||
                serviceOrder.attachments?.packingSlip?.packingSlipUrl ? (
                  <StyledLink>
                    <a
                      href={
                        serviceOrder.attachments?.packingSlip?.soloPackingSlipUrl ??
                        serviceOrder.attachments?.packingSlip?.packingSlipUrl ??
                        ''
                      }
                      target="_blank"
                      rel="noreferrer"
                      data-cy={`${serviceOrder.id}-packing-slip-link`}
                    >
                      <DetailsStyle>
                        <u>View Details</u>
                        <Icon icon={OpenInNew} size={IconSize.xsmall} color={COLOR.BLUE[700]} />
                      </DetailsStyle>
                    </a>
                  </StyledLink>
                ) : (
                  <Value>—</Value>
                )}
              </ValueWrapper>
            </StyledDataProperty>
          </Stack>
          {isExtendShippingInsurance && shippingProtectionContractId && (
            <StyledDataProperty>
              <Label data-cy={`${serviceOrder.id}-sp-contract-id-label`}>
                Shipping Protection Contract ID
              </Label>
              <ValueWrapper>
                <StyledLink>
                  <a
                    href={`/admin/contracts/${shippingProtectionContractId}`}
                    data-cy="sp-contract-id-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {shippingProtectionContractId}
                  </a>
                </StyledLink>
              </ValueWrapper>
            </StyledDataProperty>
          )}
          {downloadLabelUrl && !isShipmentVoid && (
            <Stack isRow>
              <ButtonsContainer>
                <Button
                  data-cy={`${serviceOrder.id}-download-button`}
                  text="Download Label"
                  type="button"
                  emphasis="medium"
                  onClick={() => {
                    handleDownloadShippingLabel(serviceOrder)
                  }}
                />
                <Button
                  data-cy={`${serviceOrder.id}-void-button`}
                  type="button"
                  text="Void Shipping Label"
                  emphasis="medium"
                  onClick={() => {
                    setIsVoidModalVisible(true)
                  }}
                />
              </ButtonsContainer>
            </Stack>
          )}

          {isVoidModalVisible && (
            <VoidLabelConfirmDialog
              data-cy="voidShippingLabelConfirmDialog"
              isVoidModalVisible={isVoidModalVisible}
              shipment={shipment}
              serviceOrderId={serviceOrder.id}
              onClose={() => setIsVoidModalVisible(false)}
            />
          )}
        </div>
      )
    }
    if (isFailedShipment || hasCustomLabelOptionOn) {
      return <ShippingLabelForm destinationType="servicer" serviceOrderId={serviceOrder?.id} />
    }
    return (
      <NotAvailableWrapper
        data-cy={`${serviceOrder.id}-no-failed-product-shipment-fallback-message`}
      >
        Shipment is not available
        {serviceOrder.attachments?.packingSlip?.soloPackingSlipUrl && (
          <LinkButton
            data-cy={`${serviceOrder.id}-packing-slip-button`}
            openInNew={false}
            text="Packing Slip"
            emphasis="medium"
            to={serviceOrder.attachments.packingSlip.soloPackingSlipUrl}
          />
        )}
      </NotAvailableWrapper>
    )
  }

  return (
    <AccordionSection
      heading="Failed Product Shipment"
      headingSize="sub"
      onToggleRequest={handleToggle}
      isExpanded={isExpandedInternal}
      data-cy={`${serviceOrder.id}-failed-product-shipment`}
    >
      {renderChild()}
    </AccordionSection>
  )
}

const NotAvailableWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})

const StyledDataProperty = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})

const ValueWrapper = styled.div({
  display: 'flex',
  gap: 2,
  marginBottom: 16,
  alignItems: 'center',
})

const Value = styled.span({
  lineHeight: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const Label = styled.div({
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 700,
  color: COLOR.NEUTRAL[600],
})

const StyledLink = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textDecoration: 'none',
  color: COLOR.BLUE[700],
  'a, a:visited, a:hover, a:active': {
    color: 'inherit',
  },
})

const DetailsStyle = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
})

const ButtonsContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
})
export { FailedProductShipmentSection }
