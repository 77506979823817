import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import type { Claim, Contract, Money, ServiceOrder } from '@helloextend/extend-api-client'
import { DataDisplayGroup, fieldMapper } from '../../../../../../components/data-display-group'
import { CompleteRepairForm } from './complete-repair-form'
import { dataFields } from './schema'

interface DataFields {
  claim: Claim
  serviceOrder: ServiceOrder
  contract: Contract
  entitlements: { coverageAmountRemaining: Money }
  fulfillmentMethod: string
}

interface CompleteRepairContentProps {
  claim: Claim
  serviceOrder: ServiceOrder
  contract: Contract
  entitlements: { coverageAmountRemaining: Money }
}

const CompleteRepairContent: FC<CompleteRepairContentProps> = ({
  claim,
  serviceOrder,
  contract,
  entitlements,
}) => {
  const readOnlyData = useMemo(
    () =>
      fieldMapper<DataFields>(
        {
          claim,
          serviceOrder,
          contract,
          entitlements,
          fulfillmentMethod: serviceOrder.configurations?.repairFulfillmentMethod
            ? serviceOrder.configurations.repairFulfillmentMethod
            : serviceOrder.serviceType,
        },
        dataFields[serviceOrder.serviceType],
      ),
    [claim, serviceOrder, contract, entitlements],
  )

  return (
    <ContentWrapper>
      <DataDisplayGroup data={[{ values: readOnlyData }]} numColumns={3} />
      <CompleteRepairForm serviceOrder={serviceOrder} />
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

export { CompleteRepairContent }
