import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { DataTable } from '@extend/zen'
import type { ColumnDef } from '@extend/zen'
import { useServiceOrdersShipments } from '../../../../../hooks/use-service-orders-shipments'
import { useCombinedAuditLogQuery } from '../../../../../hooks/use-combined-audit-log'

interface ClaimHistoryTabProps {
  claimId: string
  expenseIds: string[]
  serviceOrderIds: string[]
}

interface TableColumns {
  event: string
  timestamp: string
  user: string
}

const ClaimHistoryTab: FC<ClaimHistoryTabProps> = ({ claimId, expenseIds, serviceOrderIds }) => {
  const {
    data: serviceOrderShipmentIds,
    isSuccess: isShipmentsSuccess,
    isLoading: isShipmentsLoading,
    isError: isShipmentsError,
  } = useServiceOrdersShipments(serviceOrderIds)
  const {
    data,
    isLoading: isAuditLogLoading,
    isError: isAuditLogError,
  } = useCombinedAuditLogQuery({
    skip: !isShipmentsSuccess,
    claimId,
    serviceOrderIds,
    expenseIds,
    serviceOrderShipmentIds,
  })

  const tableData = data.map((auditLogEntry) => {
    const formatter = new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    const parts = formatter.formatToParts(new Date(auditLogEntry.eventTime))
    const formattedDate = `${parts[2].value} ${parts[0].value} ${parts[4].value} ${parts[6].value}:${parts[8].value} ${parts[10].value}`

    return {
      event: auditLogEntry.reason,
      timestamp: formattedDate,
      user:
        auditLogEntry.actor.startsWith('Extend_API') || auditLogEntry.actor === 'automated'
          ? 'Extend-system'
          : auditLogEntry.actor,
    }
  })

  const getTableColumns = (): Array<ColumnDef<TableColumns>> => {
    return [
      {
        id: 'event',
        label: 'Event',
      },
      {
        id: 'timestamp',
        label: 'Date Timestamp',
        isSortable: true,
      },
      {
        id: 'user',
        label: 'User',
      },
    ]
  }
  const columns = useMemo(() => getTableColumns(), [])

  return (
    <Container>
      <Title data-cy="claim-history-tab-title">Claim History</Title>
      <TableContainer>
        <DataTable
          data-cy="claim-history-table"
          isLoading={isShipmentsLoading || isAuditLogLoading}
          isError={isShipmentsError || isAuditLogError}
          columns={columns}
          data={tableData}
        />
      </TableContainer>
    </Container>
  )
}

const Container = styled.div()

const Title = styled.h2({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '28px',
})

const TableContainer = styled.div({
  marginBottom: 65,
})

export type { ClaimHistoryTabProps }
export { ClaimHistoryTab }
