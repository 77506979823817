import { useMemo, useState } from 'react'

export type PropertyAccessorFunction<T> = (item: T) => string | null
export type PropertyAccessor<T> = keyof T | PropertyAccessorFunction<T>

export type SortDirection = 'asc' | 'desc'

export type UseSortingProps<T> = {
  sortedData: T[]
  sortBy: PropertyAccessor<T> | null
  direction: SortDirection | null
  sortByQueryString: string | null
  setSortBy: (newSortBy: PropertyAccessor<T> | null, newDirection: SortDirection | null) => void
  resetSorting: () => void
}

const isFunction = (x: unknown): boolean => {
  return Object.prototype.toString.call(x) === '[object Function]'
}

/**
 * Gets the value from an item based on the accessor (which may be a string or a function)
 * @param item The item
 * @param accessor The accessor
 */
export const getValue = <T>(item: T | null, accessor: PropertyAccessor<T>): string | null => {
  if (!item) {
    return null
  }

  if (typeof accessor === 'string') {
    const value = item[accessor]
    if (typeof value !== 'string') {
      return null
    }
    return value !== null && typeof value !== 'undefined' ? value.toString() : null
  }

  const accessorFunction = accessor as PropertyAccessorFunction<T>
  const value = isFunction(accessorFunction) && accessorFunction(item)
  return value ? value.toString() : null
}

/**
 * Apply sort to a data set
 * @param data The data to sort
 * @param sortBy The property to sort by or an accessor function
 * @param direction Sort direction
 */
export const applySorting = <T>(
  data: T[],
  sortBy: PropertyAccessor<T> | null = null,
  direction: SortDirection | null = null,
): T[] => {
  if (!sortBy || !direction) {
    return data
  }

  const comparer = new Intl.Collator('co', { numeric: true }).compare
  return data.slice().sort((a, b) => {
    const aValue = getValue(a, sortBy)
    const bValue = getValue(b, sortBy)

    // Sort null/falsy values at the end/beginning for asc/desc respectively
    if (!aValue && !bValue) {
      return 0
    }
    if (!aValue) {
      return direction === 'asc' ? 1 : -1
    }
    if (!bValue) {
      return direction === 'asc' ? -1 : 1
    }

    return direction === 'asc' ? comparer(aValue, bValue) : comparer(bValue, aValue)
  })
}

/**
 * Cycle the direction initially setting null to asc and then toggling between asc and desc
 * @param direction Current direction
 */
const cycleSortDirection = (direction: SortDirection | null): SortDirection => {
  if (!direction) {
    return 'asc'
  }

  return direction === 'asc' ? 'desc' : 'asc'
}

/**
 * Sorting hook that takes care of sorting and storing the sort by and direction
 * If the default sort by of direction is null, no initial sorting will be applied
 * @param data The data to sort
 * @param defaultSortBy The default sort property
 * @param defaultDirection The default direction
 */
const useSortBy = <T>(
  data: T[],
  defaultSortBy: PropertyAccessor<T> | null = null,
  defaultDirection: SortDirection | null = null,
): UseSortingProps<T> => {
  const [sortBy, setSortBy] = useState<PropertyAccessor<T> | null>(defaultSortBy)
  const [direction, setDirection] = useState<SortDirection | null>(defaultDirection)
  const sortedData = useMemo(() => applySorting(data, sortBy, direction), [data, sortBy, direction])

  const resetSorting = (): void => {
    setSortBy(null)
    setDirection(null)
  }

  const updateSortBy = (
    newSortBy: PropertyAccessor<T> | null,
    newDirection: SortDirection | null,
  ): void => {
    // Figure out the new direction
    const newDirectionToSort =
      newDirection || sortBy === newSortBy || !newSortBy
        ? cycleSortDirection(direction)
        : cycleSortDirection(null)

    // The new sort by accessor might be a function,
    // passing it directly to setSortBy would invoke it as a setter
    setSortBy(() => newSortBy)
    setDirection(newDirectionToSort)
  }

  const sortByQueryString = sortBy && `sortBy=${String(sortBy)}&sortDirection=${direction}`

  return {
    sortedData,
    sortBy,
    direction,
    sortByQueryString,
    setSortBy: updateSortBy,
    resetSorting,
  }
}

export { useSortBy }
