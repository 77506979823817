import type { ServiceOrder } from '../../packages/extend-api-client'

export const isDepotRepair = (serviceOrder: ServiceOrder): boolean => {
  return (
    serviceOrder.serviceType === 'repair_depot' ||
    serviceOrder.configurations?.repairFulfillmentMethod === 'repair_depot'
  )
}

export const isOnsiteRepair = (serviceOrder: ServiceOrder): boolean => {
  return (
    serviceOrder.serviceType === 'repair_onsite' ||
    serviceOrder.configurations?.repairFulfillmentMethod === 'repair_onsite'
  )
}
