import type { FC, ChangeEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

interface CheckboxProps {
  checked: boolean
  disabled?: boolean
  id?: string
  label: string
  name?: string
  value?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  labelFontSizePx?: number
  disableFullWidth?: boolean
}

/**
 * @deprecated Use Zen Checkbox component instead: `import { Checkbox } from '@extend/zen'`
 */
const Checkbox: FC<CheckboxProps> = ({
  checked,
  disabled = false,
  id,
  label,
  name,
  value,
  onChange,
  labelFontSizePx = 14,
  disableFullWidth = false,
}) => {
  return (
    <CheckboxWrapper data-cy="checkboxWrapper">
      <Label htmlFor={id} disableFullWidth={disableFullWidth}>
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          name={name}
          value={value}
        />
        <CheckboxControl aria-checked={checked} className="checkbox-control" role="checkbox" />
        <LabelText fontSize={labelFontSizePx}>{label}</LabelText>
      </Label>
    </CheckboxWrapper>
  )
}

const CheckboxWrapper = styled.div({
  display: 'inline-flex',
})

const CheckboxControl = styled.div({
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  cursor: 'pointer',
  height: 20,
  lineHeight: '20px',
  marginRight: 8,
  position: 'relative',
  width: 20,
  flexShrink: 0,
  // "check" icon's base style
  '::after': {
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: COLOR.WHITE,
    boxSizing: 'border-box',
    content: "''",
    height: 10,
    left: 6,
    position: 'absolute',
    top: 4,
    transform: 'rotate(45deg)',
    width: 5,
  },
})

const Label = styled.label<{ disableFullWidth?: boolean }>(({ disableFullWidth }) => ({
  alignItems: 'flex-start',
  cursor: 'pointer',
  display: 'flex',
  fontSize: 14,
  width: disableFullWidth ? 'initial' : '100%',
  input: {
    // hides default browser checkbox
    height: 0,
    opacity: 0,
    position: 'absolute',
    width: 0,
    zIndex: -1,
    '&:focus': {
      '+ .checkbox-control': {
        borderColor: COLOR.BLUE[700],
        boxShadow: `0 0 0 1px inset ${COLOR.BLUE[700]}`,
      },
    },
    // styled-check indicator when input is "checked"
    '&:checked': {
      '+ .checkbox-control': {
        backgroundColor: COLOR.BLUE[700],
        // draws the "check" icon
        '::after': {
          borderWidth: '0 2px 2px 0',
        },
      },
    },
  },
}))

const LabelText = styled.div<{ fontSize?: number }>(({ fontSize = 14 }) => ({
  fontSize,
  wordWrap: 'break-word',
}))

export type { CheckboxProps }
export { Checkbox }
