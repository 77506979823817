import React from 'react'
import type { FormikErrors } from 'formik'
import * as Yup from 'yup'
import type { ClaimCustomerInformation } from '@helloextend/extend-api-client'
import {
  formatPhoneNumberLocal,
  formatPhoneNumberIso,
  validatePhoneNumber,
} from '@extend/client-helpers'
import type {
  CollapsibleInfoGroupProps,
  CustomComponentProps,
} from '../../../../../../components/collapsible-info-group'
import { fieldMapper } from '../../../../../../components/collapsible-info-group'
import { AddressSearch } from '../../../../../../components/address-search'
import { transformAddress } from '../../../../../../utils/transform-claim-address'

const schema = Yup.object()
  .shape({
    shippingAddress: Yup.object()
      .shape({
        address1: Yup.string().default(''),
        address2: Yup.string().default(''),

        city: Yup.string().default(''),
        postalCode: Yup.string().default(''),

        provinceCode: Yup.string().default(''),
        countryCode: Yup.string().default('USA'),
      })
      .defined(),
    phone: Yup.string()
      .default('')
      .required('Phone number is required')
      .test('is-valid-phone', 'Phone number is invalid', (value) => {
        const isoPhoneNumber = formatPhoneNumberIso(value || '')
        return validatePhoneNumber(isoPhoneNumber)
      }),
    email: Yup.string().default('').required('Email is required'),
    name: Yup.string().default('').required('Name is required'),
  })
  .defined()

type Values = ClaimCustomerInformation

const getCustomerInfo = <S extends Record<string, any>>(
  isEditable: boolean,
  customer: ClaimCustomerInformation,
  formValues?: { [key: string]: any },
  formErrors?: FormikErrors<S>,
): CollapsibleInfoGroupProps['data'] => {
  if (!customer) return []

  const fields = [
    { key: 'email', label: 'email' },
    {
      key: 'phone',
      label: 'Phone Number',
      transformFn: (phoneNumber: string) =>
        phoneNumber ? formatPhoneNumberLocal(phoneNumber) : '',
      editable: isEditable,
    },
    {
      key: 'shippingAddress',
      label: 'Shipping Address',
      editable: isEditable,
      ...(!isEditable && {
        transformFn: (values: any) =>
          transformAddress({ address: values, shouldRemoveAddress2: true }),
      }),
      ...(isEditable && {
        CustomComponent: ({ onChange, error, value }: CustomComponentProps) => (
          <AddressSearch
            label="Shipping Address"
            errorMessage={error}
            value={transformAddress({ address: value, shouldRemoveAddress2: true })}
            onChange={(changedFields) => {
              onChange('shippingAddress', changedFields)
            }}
          />
        ),
      }),
    },
    // following two fields are only for spacing on the UI
    { key: 'placeholder', label: '' },
    { key: 'placeholder2', label: '' },
    { key: 'shippingAddress.address2', label: 'Apt, suite, etc. (Optional)', editable: isEditable },
  ]

  return [
    {
      values: fieldMapper<ClaimCustomerInformation, S>(customer, fields, formValues, formErrors),
    },
  ]
}

export { getCustomerInfo, schema }
export type { Values }
