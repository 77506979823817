import styled from '@emotion/styled'
import type { FC } from 'react'
import React from 'react'
import { CompactFormInput } from './compact-form-input'
import { CompactFormDataProperty } from './compact-form-data-property'
import type { CompactFormFieldData } from './types'

interface CompactFormSectionProps {
  subsectionTitle?: string
  isEditing: boolean
  fields: CompactFormFieldData[]
  labelWidth?: number
}

const CompactFormSection: FC<CompactFormSectionProps> = ({
  subsectionTitle,
  isEditing,
  fields,
  labelWidth,
}) => {
  return (
    <FormSectionContainer
      data-cy={`form-section-container:${subsectionTitle}`}
      labelWidth={labelWidth}
    >
      {subsectionTitle && <SubSectionTitle>{subsectionTitle}</SubSectionTitle>}
      {fields.map((field) =>
        isEditing ? (
          <CompactFormInput key={field.label} {...field} />
        ) : (
          <CompactFormDataProperty key={field.label} {...field} />
        ),
      )}
    </FormSectionContainer>
  )
}

const FormSectionContainer = styled.div<{ labelWidth?: number }>(({ labelWidth = 164 }) => ({
  label: {
    width: labelWidth,
  },
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}))

const SubSectionTitle = styled.h2({
  fontSize: 14,
  fontWeight: 'bold',
  paddingTop: '0.5em',
})

export { CompactFormSection }
