import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import type {
  InsuranceClaim,
  Contract,
  ServiceOrder,
  ClaimNote,
} from '@helloextend/extend-api-client'
import { Badge, COLOR, Stack, InlineAlert, InlineAlertColor, Info as InfoIcon } from '@extend/zen'
import { Link } from 'react-router-dom'
import type {
  FollowUpClaimNote,
  EscalationClaimNote,
} from '@helloextend/extend-api-client/src/models/claim-note'
import { date } from '@extend/client-helpers'
import type { BadgeDescriptionItem } from '../../../../claims/claim-status'
import { ActionButtons } from '../action-buttons'
import { getStatusBadgeDetails } from '../../../../claims/claim-status'
import { getServiceOrderStatusDetails } from '../../../service-order-status'
import { AssignUserDropdown } from '../../../../../../components/assign-user-dropdown'
import { escalationLevelsDescription } from '../../../../../../lib/escalation-levels'
import { ClaimDetailsTabs } from '../tab-section/types'

interface ClaimHeaderProps {
  claim: InsuranceClaim
  product: Contract['product']
  claimNotes: ClaimNote[]
  serviceOrder?: ServiceOrder
  setActiveTab: React.Dispatch<React.SetStateAction<ClaimDetailsTabs>>
}

const ClaimHeader: FC<ClaimHeaderProps> = ({
  claim,
  product,
  serviceOrder,
  claimNotes,
  setActiveTab,
}) => {
  const statusBadgeDescription = useMemo((): BadgeDescriptionItem | null => {
    return getStatusBadgeDetails(claim)
  }, [claim])

  const serviceOrderStatusBadgeDescription = useMemo((): BadgeDescriptionItem | null => {
    return getServiceOrderStatusDetails(serviceOrder)
  }, [serviceOrder])

  const oldestIncompleteFollowUp = claimNotes
    .filter((cn) => cn.type === 'follow-up' && !(cn as FollowUpClaimNote).completed)
    .sort(
      (a, b) => (a as FollowUpClaimNote).followUpDate - (b as FollowUpClaimNote).followUpDate,
    )?.[0]

  const escalationNote = claimNotes.filter(
    (cn) => cn.type === 'escalation' && !(cn as EscalationClaimNote).isResolved,
  )?.[0] as EscalationClaimNote

  const escalationBannerPrefix = {
    tier1: 'Escalation Tier 1',
    tier2: 'Escalation Tier 2',
    tier3: 'Escalation Tier 3',
  }

  return (
    <Container data-cy="claim-header">
      <ClaimTitleContainer>
        <Stack>
          <Stack isRow align="center" spacing={1} doesWrap>
            <Title>{`${claim.customer?.name}'s Claim`}</Title>
            {statusBadgeDescription && (
              <Badge
                text={`Claim ${statusBadgeDescription.badgeText}`}
                details={statusBadgeDescription.badgeDetails}
                color={statusBadgeDescription.badgeColor}
              />
            )}
            {serviceOrderStatusBadgeDescription && (
              <Badge
                text={`SO ${serviceOrderStatusBadgeDescription.badgeText}`}
                color={serviceOrderStatusBadgeDescription.badgeColor}
                details={serviceOrderStatusBadgeDescription.badgeDetails}
              />
            )}
            {oldestIncompleteFollowUp && (
              <Badge
                text="Follow-up"
                details={date.format(
                  (oldestIncompleteFollowUp as FollowUpClaimNote).followUpDate,
                  'MMM DD YYYY',
                )}
                color="blue"
              />
            )}
          </Stack>
        </Stack>
      </ClaimTitleContainer>
      {escalationNote && (
        <EscalationBannerContainer>
          <InlineAlert color={InlineAlertColor.yellow} icon={InfoIcon}>
            <strong>{`${escalationBannerPrefix[escalationNote.escalationLevel]}: `}</strong>
            {escalationLevelsDescription[escalationNote.escalationLevel]}
          </InlineAlert>
        </EscalationBannerContainer>
      )}
      <ClaimInfoContainer>
        <Stack>
          <Stack isRow doesWrap>
            <DetailName>Claim ID: </DetailName>
            <DetailValue>{claim.id}</DetailValue>
          </Stack>
          {product && (
            <Stack isRow doesWrap>
              <DetailName>Covered Product: </DetailName>
              <DetailValue>{product.title}</DetailValue>
            </Stack>
          )}
          <Stack isRow doesWrap>
            <DetailName data-cy="detail-contract-id">Contract ID: </DetailName>
            <StyledLink
              data-cy="detail-contract-id-link"
              to={`/admin/contracts/${claim.contractId}`}
            >
              {claim.contractId}
            </StyledLink>
          </Stack>
          <Stack isRow data-cy="assignee-row" doesWrap>
            <DetailName data-cy="detail-assignee">Assignee: </DetailName>
            <AssignUserDropdown claim={claim} />
          </Stack>
          {claim.fraudRiskDetails?.riskScore && (
            <Stack isRow doesWrap>
              <DetailName data-cy="detail-fraud-score">Fraud Score: </DetailName>
              <StyledLink
                data-cy="detail-fraud-tab-link"
                to="#"
                onClick={(e) => {
                  e.preventDefault()
                  const element = document.querySelector('div#claim-tabs-section')
                  if (element) {
                    setActiveTab(ClaimDetailsTabs.FraudReport)
                    setTimeout(
                      () =>
                        element.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                          inline: 'nearest',
                        }),
                      100,
                    )
                  }
                }}
              >
                {claim.fraudRiskDetails?.riskScore}
              </StyledLink>
            </Stack>
          )}
        </Stack>
      </ClaimInfoContainer>
      <ActionButtonsContainer>
        <ActionButtons claim={claim} serviceOrder={serviceOrder} />
      </ActionButtonsContainer>
    </Container>
  )
}

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'auto auto auto',
  gridAutoFlow: 'column',
})

const ClaimInfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const ClaimTitleContainer = styled.div({
  display: 'flex',
})

const ActionButtonsContainer = styled.div({
  gridColumn: 2,
  gridRow: 1,
})

const Title = styled.h1({
  color: COLOR.NEUTRAL[1000],
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  marginTop: 0,
  marginBottom: 8,
})

const EscalationBannerContainer = styled.div({
  marginBottom: 10,
  gridColumn: 'span 2',
})

const DetailName = styled.span({
  fontSize: 16,
  fontWeight: 'bold',
  color: COLOR.NEUTRAL[600],
  marginRight: 4,
  width: 135,
})

const DetailValue = styled.span({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontSize: 16,
  color: COLOR.NEUTRAL[1000],
})

const StyledLink = styled(Link)({
  color: COLOR.BLUE[800],
  '&:hover': {
    textDecoration: 'underline',
  },
})

export { ClaimHeader }
