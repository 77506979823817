import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Checkbox, Stack } from '@extend/zen'
import type { WDActionListItem, WDOrchestratorConfig } from '@helloextend/extend-api-rtk-query'

type InfoPanelProps = {
  orchestratorConfig?: WDOrchestratorConfig
  minNumberOfActions?: number
}

const generateDisplayItems = (
  orchestratorConfig: WDOrchestratorConfig | undefined,
  minNumberOfActions = 4,
): JSX.Element => {
  const actionListItems: WDActionListItem[] = orchestratorConfig?.actionList ?? []
  const actionListLength = actionListItems.length ?? 0
  const numberOfDisplayItems = Math.max(actionListLength, minNumberOfActions)
  const displayItemText: Array<{ key: number; value: string }> = []
  for (let i = 0; i < numberOfDisplayItems; i += 1) {
    displayItemText.push({
      key: i,
      value: i < actionListLength ? actionListItems[i].id : '---',
    })
  }
  return (
    <>
      {displayItemText.map((obj) => (
        <ActionItem key={`action-item-${obj.key}`}>
          <Label>{`#${obj.key + 1}`}</Label>
          <Value>{obj.value}</Value>
        </ActionItem>
      ))}
    </>
  )
}

const InfoPanel: FC<InfoPanelProps> = ({ orchestratorConfig, minNumberOfActions = 4 }) => {
  return (
    <>
      <Checkbox
        data-cy="info-checkbox"
        checked={(orchestratorConfig?.isSentToWD ?? 'false') === 'true'}
        disabled
        label="Send the store to WD engine"
      />
      <h2>ML Rules Engine Configuration</h2>
      <>
        Please specify ML models and rulesets to be used for the store. The store will run through
        the configuration in the list order.
      </>
      <Stack spacing={1} data-cy="info-stack" align="start">
        {generateDisplayItems(orchestratorConfig, minNumberOfActions)}
      </Stack>
    </>
  )
}

const ActionItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
})

const Label = styled.h3({
  fontWeight: 700,
})

const Value = styled.h4({
  fontWeight: 400,
})

export { InfoPanel }
