import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { Button, ButtonGroup, COLOR } from '@extend/zen'
import { useHistory } from 'react-router-dom'
import { LeavePageGuard, LeavePageModal } from '../leave-page-guard'

type CreateFormHeaderProps = {
  title: string
  createButtonText: string
  isFormDirty: boolean
  isCreateSuccess: boolean
  isFormValid: boolean
  isProcessing: boolean
  titleCy?: string
  createButtonCy?: string
  resetForm: () => void
  handleSave: () => void
}

const CreateFormHeader: FC<CreateFormHeaderProps> = ({
  title,
  createButtonText,
  isFormDirty,
  isCreateSuccess,
  isFormValid,
  isProcessing,
  titleCy,
  createButtonCy,
  resetForm,
  handleSave,
}) => {
  const history = useHistory()
  const [isChangesModalVisible, setIsChangesModalVisible] = useState<boolean>(false)

  const handleLeavePage = useCallback(
    (path: string): void => {
      history.push(path)
    },
    [history],
  )

  const goBack = useCallback((): void => {
    if (isFormDirty) {
      resetForm()
    }
    history.goBack()
  }, [history, isFormDirty, resetForm])

  const handleUnsavedChangesModalToggle = useCallback((): void => {
    setIsChangesModalVisible(!isChangesModalVisible)
  }, [isChangesModalVisible])

  const handleCancelClick = useCallback((): void => {
    if (isFormDirty) {
      handleUnsavedChangesModalToggle()
      return
    }
    goBack()
  }, [goBack, handleUnsavedChangesModalToggle, isFormDirty])

  return (
    <Header>
      <LeavePageGuard
        isNavBlocked={isFormDirty && !isCreateSuccess}
        handleLeavePage={handleLeavePage}
      />
      <LeavePageModal
        isVisible={isChangesModalVisible}
        handleCloseModal={handleUnsavedChangesModalToggle}
        handleLeavePage={goBack}
      />
      <Title data-cy={titleCy}>{title}</Title>
      <ButtonGroup>
        <Button text="Cancel" onClick={handleCancelClick} emphasis="medium" />
        <Button
          text={createButtonText}
          onClick={() => handleSave()}
          isDisabled={!isFormDirty || !isFormValid || isProcessing}
          isProcessing={isProcessing}
          data-cy={createButtonCy}
        />
      </ButtonGroup>
    </Header>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 32,
})

const Title = styled.h1({
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  margin: 0,
})

export { CreateFormHeader }
