import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { Switch, useToaster, ToastColor, ToastDuration, Modal, ModalController } from '@extend/zen'
import { useUpdatePromotionMutation } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import type { EmbeddedPromotion } from '@helloextend/extend-api-client'

type PromotionToggleProps = {
  isEnabled: boolean
  promotion: EmbeddedPromotion
  toggleType: 'approved' | 'enabled'
  activePromotionExists?: boolean
}

const PromotionToggle: FC<PromotionToggleProps> = ({
  isEnabled,
  promotion,
  toggleType,
  activePromotionExists,
}) => {
  const [updatePromotion, { isLoading }] = useUpdatePromotionMutation()
  const [enabledStatus, setEnabledStatus] = useState(isEnabled)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { toast } = useToaster()

  const handleOnChange = useCallback(async () => {
    if (
      !isEnabled &&
      activePromotionExists &&
      ((toggleType === 'approved' && promotion.enabled) ||
        (toggleType === 'enabled' && promotion.approved))
    ) {
      setIsModalOpen(true)
      return
    }

    try {
      await updatePromotion({
        storeId: promotion.storeId,
        promotionId: promotion.id,
        data: { [toggleType]: !enabledStatus },
      }).unwrap()

      toast({
        message: `Promotion has been updated`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      setEnabledStatus(!enabledStatus)
    } catch {
      toast({
        message: `Promotion could not be updated. Please try again later`,
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      setEnabledStatus(isEnabled)
    }
  }, [
    enabledStatus,
    isEnabled,
    promotion,
    activePromotionExists,
    toast,
    toggleType,
    updatePromotion,
  ])

  const id = `promotion-${promotion.id}-${toggleType}-toggle`

  const handleCloseModal = (): void => {
    setIsModalOpen(false)
  }

  return (
    <SwitchContainer>
      <Switch
        data-cy={id}
        isOn={enabledStatus}
        id={id}
        onChange={handleOnChange}
        isProcessing={isLoading}
        isDisabled={toggleType === 'enabled' && !promotion.approved && !isEnabled}
        disabledTooltip="The Programs team needs to approve a promotion before activating it in the merchant store."
      />
      <ModalController isOpen={isModalOpen}>
        <Modal
          data-cy="promotion-limit-modal"
          heading="Single Promotion Limit"
          primaryButtonProps={{ onClick: handleCloseModal, text: 'Ok' }}
        >
          <p>Only one promotion can be activated at any given time.</p>
        </Modal>
      </ModalController>
    </SwitchContainer>
  )
}

const SwitchContainer = styled.div({
  display: 'inline-flex',
})

export { PromotionToggle }
