import type { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/extend-api-fetch'
import type { ServiceOrder } from '@helloextend/extend-api-client/src/models/service-order'
import { getServiceOrderError } from './get-service-order-error'
import { actions } from '../actions'
import { fetchServiceOrders } from './fetch-by-claim-id'

type Action = ReturnType<typeof actions.approveForPayment>

function* approveForPayment(payload: {
  serviceOrderId: ServiceOrder['id']
  accessToken: string
}): SagaIterator {
  const { serviceOrderId, accessToken } = payload
  yield put(actions.approveForPaymentRequest())
  try {
    const response = yield call(client.serviceOrders.approveForPayment, serviceOrderId, accessToken)

    if (isErrorResponse(response)) {
      yield put(actions.approveForPaymentFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.approveForPaymentFailure(`An unknown error occurred`, response.status))
      return
    }

    yield put(actions.approveForPaymentSuccess())
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.approveForPaymentFailure(e.message))
    }
  }
}

export default function* approveForPaymentAndRefetchServiceOrders(action: Action): SagaIterator {
  // These are not run in parallel because fetchServiceOrders should only be triggered
  // when approveForPayment is successful
  yield call(approveForPayment, action.payload)
  const error = yield select(getServiceOrderError)
  if (!error) {
    yield call(fetchServiceOrders, action.payload)
  }
}
