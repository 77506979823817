import { useHistory } from 'react-router-dom'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { safeBtoa } from '@helloextend/extend-api-rtk-query'
import { jsonDateFix } from './util/json-date-fix'

interface Options {
  /** Should the query string value be base64 encoded?
   * Sometimes this looks better than the escaped JSON string that would otherwise appear.  */
  encode?: boolean
  /** Should ISO date strings be transformed into Date typed values?
   * If you expect date values in the state, set this to true. JSON does not stringify and
   * parse date values intact. This value causes the parsed JSON to be transformed: searching
   * for ISO date strings and replacing them with date type values.
   */
  transformDates?: boolean
}

/** This is similar to a `useState`, but adds query string syncing. The value set in the
 * query string at page load is assumed initially. Subsequent updates to the state value
 * are synced to the query string.
 * ```
 * const [value, setValue] = useQueryStringState('queryStringKey', 'value if no query string value on page load')
 * ```
 */
const useQueryStringState = <TValue>(
  /**
   * This is the key that will be used in the query string to hold the synced value */
  key: string,
  /** If the given key does not exist in the query string on page load, what value
   * should the state assume? */
  defaultValue: TValue,
  /** Optional options object. */
  { encode = false, transformDates = false }: Options = {},
): [TValue, Dispatch<SetStateAction<TValue>>] => {
  const { replace } = useHistory()

  const valueToString = useCallback(
    (value: TValue) => {
      if (encode) {
        return safeBtoa(JSON.stringify(value))
      }

      return JSON.stringify(value)
    },
    [encode],
  )

  const postParseTransform = useCallback(
    (value: TValue) => {
      if (!transformDates) {
        return value
      }

      return jsonDateFix(value) as TValue
    },
    [transformDates],
  )

  const stringToValue = useCallback(
    (value: string): TValue => {
      try {
        if (encode) {
          return postParseTransform(JSON.parse(atob(value)))
        }

        return postParseTransform(JSON.parse(value))
      } catch {
        return defaultValue
      }
    },
    [encode, postParseTransform, defaultValue],
  )

  const getValueFromQueryString = useCallback(
    (queryString: string): TValue => {
      const urlSearchParams = new URLSearchParams(queryString)

      if (!urlSearchParams.has(key)) {
        return defaultValue
      }

      const jsonValue = urlSearchParams.get(key)

      if (jsonValue === null) {
        return defaultValue
      }

      try {
        return stringToValue(jsonValue)
      } catch (e: unknown) {
        return defaultValue
      }
    },
    [defaultValue, key, stringToValue],
  )

  const initialValue = getValueFromQueryString(window.location.search)
  const [stateValue, setStateValue] = useState(initialValue)

  const setQueryStringValue = useCallback(
    (value: TValue) => {
      const urlSearchParams = new URLSearchParams(window.location.search)

      if (isEqual(value, defaultValue)) {
        urlSearchParams.delete(key)
      } else {
        urlSearchParams.set(key, valueToString(value))
      }

      replace({ search: urlSearchParams.toString() })
    },
    [defaultValue, key, replace, valueToString],
  )

  useEffect(() => {
    if (!isEqual(stateValue, getValueFromQueryString(window.location.search))) {
      setQueryStringValue(stateValue)
    }
  }, [getValueFromQueryString, setQueryStringValue, stateValue])

  return [stateValue, setStateValue]
}

export { useQueryStringState }
