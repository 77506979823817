import { all, fork } from '@redux-saga/core/effects'
import {
  incredibotSagas,
  storesSagas,
  accountSagas,
  plansSagas,
  contractsSagas,
  productsSagas,
} from '@helloextend/core-api-redux'
import type { SagaIterator } from 'redux-saga'

export default function* allSagas(): SagaIterator {
  yield all([
    fork(incredibotSagas),
    fork(storesSagas),
    fork(accountSagas),
    fork(plansSagas),
    fork(contractsSagas),
    fork(productsSagas),
  ])
}
