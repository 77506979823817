import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import images from '../../../../../images'

const ConversationStartBlock: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <ConversationStartWrapper data-cy="conversation-start-block" onClick={onClick}>
      <img alt="kaley" src={images.kaleyFullColor} />
      <ConversationStartMessage data-cy="conversation-start-text">
        Conversation Start
      </ConversationStartMessage>
    </ConversationStartWrapper>
  )
}

const ConversationStartWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  boxSizing: 'border-box',
  width: 360,
  padding: 12,
  borderRadius: 8,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  background: COLOR.WHITE,
  gap: 12,
  cursor: 'pointer',
  marginLeft: 44,
})

const ConversationStartMessage = styled.div({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  color: COLOR.NEUTRAL[800],
})

export { ConversationStartBlock }
