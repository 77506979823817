import type { ShippingProtectionPlan } from '@helloextend/extend-api-rtk-query/src/plans/types'
import type { CellContext, ColumnDefs } from '@extend/zen'
import { format } from '@extend/zen'

const columns: ColumnDefs<ShippingProtectionPlan> = [
  {
    label: 'Version',
    id: 'version',
    renderCell: (cellData: CellContext<ShippingProtectionPlan, number>) => cellData.getValue(),
  },
  {
    label: 'Date Activated',
    id: 'createdAt',
    renderCell: (cellData: CellContext<ShippingProtectionPlan, number>) =>
      format(cellData.getValue(), 'MMMM DD YYYY, HH:mm A z'),
  },
  {
    label: 'Updated by',
    id: 'createdAt',
    renderCell: () => 'user name',
  },
]

export { columns }
