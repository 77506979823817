import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, ToastColor, ToastDuration, useToaster, Checkbox } from '@extend/zen'
import { getIn, useFormik } from 'formik'

import { useGetServicerQuery, useUpdateServicerMutation } from '@helloextend/extend-api-rtk-query'
import {
  TableHead,
  TableBody,
  TableFoot,
  ColumnHead,
  TableCell,
} from '../../../../../components/table'
import type { PackingSlipValues } from './schema'
import { packingSlipFormFields, packingSlipSchema } from './schema'
import image from '../../../../../images'

interface PackingSlipProps {
  servicerId: string
}
const PackingSlip: FC<PackingSlipProps> = ({ servicerId }) => {
  const { toast } = useToaster()
  const { data: servicer } = useGetServicerQuery(servicerId)
  const packingSlipRequired = servicer?.settings.shipments?.packingSlipRequired ?? false
  const [updateServicer, { isLoading }] = useUpdateServicerMutation()

  const { values, setFieldValue, submitForm } = useFormik<PackingSlipValues>({
    enableReinitialize: true,
    initialValues: servicer?.settings.shipments?.packingSlipConfiguration
      ? servicer.settings.shipments.packingSlipConfiguration
      : { ...packingSlipSchema.getDefault() },
    validationSchema: packingSlipSchema,
    onSubmit: () => {
      if (!servicer) return
      const { settings } = servicer
      updateServicer({
        servicerId,
        body: {
          settings: {
            ...settings,
            shipments: {
              ...settings.shipments,
              packingSlipConfiguration: values,
            },
          },
        },
      })
      toast({
        message: 'Changes saved!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    },
  })

  const togglePackingSlipRequired = (): void => {
    if (!servicer) return
    const { settings } = servicer
    updateServicer({
      servicerId,
      body: {
        settings: {
          ...settings,
          shipments: {
            ...settings.shipments,
            packingSlipRequired: !packingSlipRequired,
            packingSlipConfiguration: values,
          },
        },
      },
    })
    toast({
      message: 'Changes saved!',
      toastDuration: ToastDuration.short,
      toastColor: ToastColor.blue,
    })
  }

  const toggleField = (fieldName: keyof PackingSlipValues): void => {
    setFieldValue(fieldName, !values[fieldName])
    submitForm()
  }

  return (
    <ContentWrapper>
      <Header>
        <Title data-cy="packing-slip-title">Packing Slip (Mail-in Repairs)</Title>
      </Header>
      <SectionHeader>
        Requiring packing slips will require a service order to be manually accepted. Instructions
        can be entered on a service order packing slip in the Attachments section.
      </SectionHeader>

      <CheckboxWrapper>
        <Checkbox
          data-cy="packing-slip-required"
          checked={packingSlipRequired}
          key="packingSlipRequired"
          name="packingSlipRequired"
          disabled={isLoading}
          label="Require packing slip with shipment"
          onChange={togglePackingSlipRequired}
        />
      </CheckboxWrapper>
      {packingSlipRequired && (
        <SlipConfigContainer>
          <LeftColumn>
            <ColumnHeader>Packing Slip Information</ColumnHeader>

            <CheckboxWrapper>
              {packingSlipFormFields.map((field) => (
                <Checkbox
                  data-cy={field.key}
                  checked={getIn(values, field.key)}
                  key={field.key}
                  name={field.key}
                  disabled={isLoading}
                  label={field.label}
                  onChange={() => toggleField(field.key)}
                />
              ))}
            </CheckboxWrapper>
          </LeftColumn>

          <RightColumn>
            <ColumnHeader>Preview</ColumnHeader>
            <BorderedDiv>
              <HeaderWithImageWrapper>
                <Logo src={image.extendLogo} alt="Extend" />
                <InlineText>Packing Slip</InlineText>
              </HeaderWithImageWrapper>
              <Table>
                <TableHead>
                  <ColumnHead key="head-field" columnWidth={25} active={false} textAlign="left" />
                  <ColumnHead key="head-value" columnWidth={60} active={false} textAlign="left" />
                </TableHead>
                <TableBody>
                  {packingSlipFormFields
                    .filter((field) => values[field.key])
                    .map((field) => (
                      <TableRow data-cy={field.label} key={field.key}>
                        <Cell>{field.label}:</Cell>
                        <Cell>
                          <TransparentText>{field.sampleValue}</TransparentText>
                        </Cell>
                      </TableRow>
                    ))}
                  <TableRow data-cy="instructions-row">
                    <AlignmentCell>Instructions:</AlignmentCell>
                    <AlignmentCell>
                      <BoldWrapper>
                        Instructions can be added on each service order before it is accepted in the
                        Attachments section.
                      </BoldWrapper>
                    </AlignmentCell>
                  </TableRow>
                </TableBody>
                <TableFoot />
              </Table>
              <ForegroundText data-cy="sample-data-slip">SAMPLE DATA</ForegroundText>
            </BorderedDiv>
          </RightColumn>
        </SlipConfigContainer>
      )}
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  marginBottom: 32,
  padding: 40,
  borderRadius: 4,
})

const CheckboxWrapper = styled.div({
  display: 'flex',
  gap: 22,
  flexDirection: 'column',
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  margin: 0,
})

const SectionHeader = styled.p({
  margin: '24px 0px',
  fontSize: 16,
  lineHeight: '20px',
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

const SlipConfigContainer = styled.div({
  display: 'flex',
  width: '100%',
  marginTop: 20,
})

const ColumnHeader = styled.h2({
  fontSize: 20,
  fontWeight: 'bold',
})

const LeftColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '30%',
})

const RightColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
})

const Table = styled.table({
  width: '100%',
  fontFamily: 'Nunito Sans, sans-serif',
  fontSize: 16,
  lineHeight: '28px',
  borderCollapse: 'separate',
  tableLayout: 'fixed',
  borderSpacing: '0 5px',
})

const TableRow = styled.tr({
  backgroundColor: 'transparent',
  transition: 'background-color .2s ease-in',
})

const BoldWrapper = styled.div({
  fontWeight: 'bold',
})

const ForegroundText = styled.div({
  position: 'absolute',
  top: 200,
  left: 255,
  fontSize: 36,
  fontWeight: 600,
  color: COLOR.BLUE[500],
  transform: 'rotate(-30deg)',
})

const Logo = styled.img({
  height: 22,
  width: 'auto',
  float: 'left',
})

const HeaderWithImageWrapper = styled.div({
  marginBottom: 12,
})

const Cell = styled(TableCell)({
  fontSize: 16,
  lineHeight: '24px',
})

const AlignmentCell = styled.td({
  verticalAlign: 'top',
})

const InlineText = styled.span({
  marginLeft: 24,
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '28px',
  paddingTop: 8,
})

const TransparentText = styled.span({
  opacity: 0.5,
})

const BorderedDiv = styled.div({
  background: COLOR.WHITE,
  border: `1px solid ${COLOR.NEUTRAL[200]}`,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '52px 60px',
  position: 'static',
  width: 734,
  height: 575,
  margin: 0,
  filter: 'drop-shadow(0px 16px 12px rgba(0, 0, 0, 0.12))',
})

export { PackingSlip }
