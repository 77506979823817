import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import type { VirtualCardFulfillmentMetaData } from '@helloextend/extend-api-client/src/models/service-order'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetContractQuery,
  useGetInsuranceClaimQuery,
  useSearchServiceOrdersQuery,
  useGetOrderQuery,
  useFetchPlanQuery,
  useListServiceOrderExpensesQuery,
  useGetWalletQuery,
  useGetClaimNotesQuery,
  useGetClaimPhotosQuery,
  useSearchEntitlementsQuery,
} from '@helloextend/extend-api-rtk-query'
import { PageError } from '@helloextend/merchants-ui'
import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { RootState } from '../../../reducers'
import * as selectors from '../../../reducers/selectors'
import { DashboardSpinner } from '../../../components/dashboard-spinner'
import { ClaimDetails } from './claim-details'
import { setClaimDetailsToast } from '../../../store/slices/claim-details'
import { isShippingProtectionType } from './util'
import { getRemainingCoverageFromEntitlements } from 'src/utils/getRemainingCoverageFromEntitlements'

const Claim: FC = () => {
  const { push } = useHistory()
  const { toast } = useToaster()
  const dispatch = useDispatch()
  const claimsUrl = useSelector((state: RootState) => selectors.getClaimBreadcrumbUrl(state))
  const toastMessage = useSelector((state: RootState) =>
    selectors.getClaimDetailsToastMessage(state),
  )

  const { claimId } = useParams<{ claimId: string }>()
  const {
    data: claim,
    isFetching,
    isLoading: isClaimLoading,
    isError,
  } = useGetInsuranceClaimQuery({
    claimId,
  })
  const { claimNotes } = useGetClaimNotesQuery(
    { claimId },
    {
      selectFromResult: ({ data }) => ({
        claimNotes: [...(data?.items || [])].sort((a, b) => b.createdAt - a.createdAt),
      }),
      skip: !claimId,
    },
  )

  const { data: contract, isLoading: isContractLoading } = useGetContractQuery(
    {
      contractId: claim?.contractId ?? '',
    },
    { skip: !claim },
  )

  const {
    data: entitlementsResponse,
    isSuccess: isEMSSuccess,
    isLoading: isEMSLoading,
  } = useSearchEntitlementsQuery(
    { queryString: `contractId=${contract?.id}`, version: 'latest' },
    { skip: !contract?.id },
  )

  const entitlements = useMemo(() => {
    return isEMSSuccess && !isEMSLoading
      ? getRemainingCoverageFromEntitlements(entitlementsResponse)
      : undefined
  }, [entitlementsResponse, isEMSSuccess, isEMSLoading])

  const { data: plan, isLoading: isPlanLoading } = useFetchPlanQuery(contract?.plan?.id ?? '', {
    skip: !contract?.plan,
  })

  const { serviceOrders } = useSearchServiceOrdersQuery(
    { claimId, includeShippingLabels: true },
    {
      selectFromResult: ({ data }) => ({
        serviceOrders: data?.items ? [...data.items] : [],
      }),
    },
  )

  // sort by creation date descending
  serviceOrders.sort((a, b) => b.createdAt - a.createdAt)

  const serviceOrder = serviceOrders.length ? serviceOrders[0] : undefined

  const { data: wallet } = useGetWalletQuery(
    serviceOrder
      ? (serviceOrder.fulfillmentMetaData as VirtualCardFulfillmentMetaData)?.walletId ?? ''
      : '',
    {
      skip:
        !serviceOrder ||
        !serviceOrder.fulfillmentMetaData ||
        !(serviceOrder.fulfillmentMetaData as VirtualCardFulfillmentMetaData).walletId,
    },
  )

  const { expenses, isLoading: isExpensesLoading } = useListServiceOrderExpensesQuery(
    serviceOrder?.id || '',
    {
      skip: !serviceOrder,
      selectFromResult: ({ data, isLoading }) => ({ expenses: data ?? [], isLoading }),
    },
  )

  const orderId = contract && contract.orderId ? contract.orderId : null
  const { data: order, isLoading: isOrderLoading } = useGetOrderQuery(contract?.orderId ?? '', {
    skip: !contract || !orderId || !isShippingProtectionType(claim?.type ?? ''),
  })

  const { data: photoData, isLoading: isClaimPhotosLoading } = useGetClaimPhotosQuery({ claimId })

  const isLoading =
    isClaimLoading ||
    isContractLoading ||
    isEMSLoading ||
    isOrderLoading ||
    isPlanLoading ||
    isExpensesLoading ||
    isClaimPhotosLoading

  const handleBackClick = useCallback(() => {
    push(claimsUrl || '/admin/claims')
  }, [claimsUrl, push])

  useEffect(() => {
    if (toastMessage) {
      toast({
        message: toastMessage,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
      dispatch(setClaimDetailsToast(''))
    }
  }, [toastMessage, toast, dispatch])

  if (isLoading) return <DashboardSpinner data-cy="loading-spinner" />

  if (isError || (!isFetching && !isLoading && !claim)) {
    return (
      <ErrorWrapper>
        <PageError handleBackClick={handleBackClick} data-cy="page-error" />
      </ErrorWrapper>
    )
  }

  if (!claim || !contract) return null

  return (
    <ClaimDetails
      {...{
        expenses,
        claim,
        contract,
        serviceOrders,
        entitlements,
        order,
        plan,
        wallet,
        claimNotes,
        photoData,
      }}
    />
  )
}

const ErrorWrapper = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 535,
  margin: '0 auto',
})

export { Claim }
