import { faker } from '@faker-js/faker/locale/en'
import type {
  FailureCausesMapping,
  FailureTypesMapping,
  ShippingProtectionPlan,
} from '@helloextend/extend-api-rtk-query/src/plans/types'

export function generateShippingProtectionPlan(
  overrides: DeepPartial<ShippingProtectionPlan> = {},
): ShippingProtectionPlan {
  const failureEntries: FailureTypesMapping = {}
  if (overrides.failureTypesMapping) {
    Object.entries(overrides.failureTypesMapping).forEach(([key, value]) => {
      if (value?.failureCauses) {
        const failureCauses: FailureCausesMapping = {}
        Object.entries(value.failureCauses).forEach(([causeKey, causeValue]) => {
          if (causeValue?.liable && causeValue.covered !== undefined) {
            failureCauses[causeKey] = {
              liable: causeValue.liable,
              covered: causeValue.covered,
            }
          }
        })
        if (Object.keys(failureCauses).length > 0) {
          failureEntries[key] = { failureCauses }
        }
      }
    })
  }
  return {
    id: faker.datatype.uuid(),
    version: 1,
    premiumRate: faker.datatype.number({ max: 100, precision: 0.1 }),
    premiumMin: faker.datatype.number(100),
    shipCoverageMin: 500,
    shipCoverageMax: 5000,
    name: faker.commerce.productName(),
    termsId: faker.random.word(),
    termsVersion: 1,
    termLength: 12,
    obligor: 'Extend',
    productCategory: 'Electronics',
    active: true,
    programType: 'general_shipping_protection',
    serviceType: 'replace',
    insuranceProgram: 'Test Insurance Program',
    createdAt: faker.date.past().getTime(),
    updatedAt: faker.date.past().getTime(),
    planParentCreatedAt: faker.date.past().getTime(),
    planStartDate: faker.date.past().getTime(),
    planEndDate: faker.date.future().getTime(),
    schemaVersion: faker.datatype.number(10),
    currencyCode: 'USD',
    allowedRegions: ['US'],
    blockedSubdivisions: [],
    ...overrides,
    failureTypesMapping: failureEntries,
  }
}
