import { customLogger } from '@extend/client-helpers'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/extend-api-fetch'
import type { SagaIterator } from 'redux-saga'
import { actions as contractsActions } from '../actions'
import { actions as plansActions } from '../../plans/actions'
import fetch from '../../plans/sagas/fetch'

type Action = ReturnType<typeof contractsActions.fetchByStore>

export default function* fetchByStore(action: Action): SagaIterator {
  const { accessToken, contractId, storeId } = action.payload
  yield put(contractsActions.fetchRequest())
  try {
    const response = yield call(client.stores.searchContracts, {
      storeId,
      accessToken,
      searchKey: 'contractId',
      value: contractId,
    })
    if (isErrorResponse(response)) {
      yield put(contractsActions.fetchFailure(response.data.message))
      return
    }

    const contract = response.data?.[0]
    if (!contract) {
      yield put(contractsActions.fetchFailure('contract not found'))
      return
    }
    // makes a blocking call to fetch the contract plan
    //  before dispatching a "successful" action. This guarantees
    //  that all the required resources (plans, contracts) have been
    //  queried and loaded before displaying the results
    yield call(fetch, plansActions.fetch(contract.plan.planId))
    yield put(contractsActions.fetchSuccess(response.data[0]))
  } catch (error) {
    if (error instanceof Error) {
      customLogger.error('Caught an unexpected error with search-contract-byid', {
        errorMessage: error.message,
      })
      yield put(contractsActions.fetchFailure(error.message))
    }
  }
}
