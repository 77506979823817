import type { Order } from '@helloextend/extend-api-client'
import type { FC } from 'react'
import React, { useState } from 'react'
import { Grid } from '@extend/zen'
import { CompactForm, CompactFormSection } from '../../../../../../components/compact-form'
import type { OrderAnalyticsInfoFormFields } from './fields'
import { getAnalyticsInfoFormFields } from './fields'

const OrderAnalyticsInfoForm: FC<{ order: Order }> = ({ order }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const [isEditing, setIsEditing] = useState(false)

  const handleClickEdit = (): void => {
    setIsExpanded(true)
    setIsEditing(!isEditing)
  }

  const toggleIsExpanded = (): void => {
    setIsExpanded(!isExpanded)
  }

  const fields = getAnalyticsInfoFormFields(order)

  return (
    <CompactForm
      dataCy="order-analytics-info-section"
      handleSubmit={() => {}}
      handleCancel={() => {}}
      handleClickEdit={handleClickEdit}
      isSubmitDisabled={false}
      isEditable={false}
      isEditing={isEditing}
      isSubmitting={false}
      isExpanded={isExpanded}
      onToggleRequest={toggleIsExpanded}
      shouldStartExpanded
      heading="Analytics Information"
    >
      <CompactFormSections
        dataCy="order-analytics-info-compact-forms"
        fields={fields}
        isEditing={isEditing}
      />
    </CompactForm>
  )
}

const CompactFormSections: FC<{
  fields: OrderAnalyticsInfoFormFields
  isEditing: boolean
  dataCy?: string
}> = ({ fields, isEditing, dataCy }) => {
  return (
    <Grid columns={3} spacing={8} data-cy={dataCy}>
      {Object.keys(fields).map((subSectionTitle) => (
        <CompactFormSection
          key={subSectionTitle}
          subsectionTitle={subSectionTitle}
          isEditing={isEditing}
          fields={fields[subSectionTitle as keyof typeof fields]}
        />
      ))}
    </Grid>
  )
}

export { OrderAnalyticsInfoForm }
