import type { FC } from 'react'
import styled from '@emotion/styled'
import React from 'react'
import {
  InlineAlert,
  InlineAlertColor,
  Error as ErrorIcon,
  Button,
  ButtonGroup,
  Download as DownloadIcon,
} from '@extend/zen'

interface DownloadCSVDataProps {
  isExpired: boolean
  alertMessage?: string
  downloadDataType: string
  downloadLink?: string
  retryLink?: string
  handleDismissClick: () => void
  isProcessing: boolean
  onDownloadClick: () => void
}

export const DownloadCSVData: FC<DownloadCSVDataProps> = ({
  isExpired,
  alertMessage = 'The link to download this data has expired. Export your data again to receive a new link.',
  downloadDataType,
  retryLink,
  handleDismissClick,
  isProcessing,
  onDownloadClick,
}) => {
  return (
    <DownloadCSVWrapper>
      {isExpired ? (
        <InlineAlert data-cy="csv-banner-alert" color={InlineAlertColor.red} icon={ErrorIcon}>
          {alertMessage} Visit the <a href={retryLink}>{downloadDataType} Page</a> to export your
          data again.
        </InlineAlert>
      ) : (
        <DataIsReadyWrapper>Your {downloadDataType} data is ready for download.</DataIsReadyWrapper>
      )}
      <ButtonGroup>
        <Button onClick={handleDismissClick} text="Dismiss" emphasis="medium" />
        {!isExpired && (
          <Button
            onClick={onDownloadClick}
            icon={DownloadIcon}
            text="Download CSV"
            emphasis="high"
            isProcessing={isProcessing}
          />
        )}
      </ButtonGroup>
    </DownloadCSVWrapper>
  )
}

const DownloadCSVWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
})

const DataIsReadyWrapper = styled.div({
  maxWidth: '530px',
  margin: 0,
})
