import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ProductsTabs } from './products-tabs'
import { PlanMapper } from './plan-mapper'
import { CategoryMapper } from '../category-mapper'
import { ProductMapper } from './product-category-mapping'
import { LDFlag } from '../../../constants/ld-flags'

const Products: FC = () => {
  const history = useHistory()

  const pathName = history.location.pathname.split('/').pop()

  // Feature Flag to enable/disable WD Product Mapping
  const { [LDFlag.WDProductMappingFlag]: WD_PRODUCT_MAPPING_FLAG } = useFlags()

  return (
    <>
      <Header>Products</Header>
      <ProductsTabs />
      {pathName === 'plan-mapping' && <PlanMapper />}
      {pathName === 'category-mapping' && <CategoryMapper />}
      {pathName === 'product-mapping' &&
        (WD_PRODUCT_MAPPING_FLAG ? <ProductMapper /> : <div>Coming Soon!</div>)}
    </>
  )
}

const Header = styled.h3({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 12,
  fontSize: 32,
  margin: 0,
  padding: 0,
})

export { Products }
