import type { Plan } from '@helloextend/extend-api-client'
import { CellText } from '@helloextend/merchants-ui'
import React from 'react'
import type { Column, CellProps } from 'react-table'
import { formatDateWithTimezone } from '../../../utils/date-formatting'

const planVersionsColumns: Array<Column<Plan>> = [
  { Header: 'version', accessor: 'version' },
  {
    Header: 'date activated',
    accessor: 'plan_start_date',
    Cell: ({ value }: CellProps<Plan, unknown>): JSX.Element => (
      <CellText>{value ? formatDateWithTimezone(Number(value)) : '-'}</CellText>
    ),
  },
  {
    Header: 'date deactivated',
    accessor: 'plan_end_date',
    Cell: ({ value }: CellProps<Plan, unknown>): JSX.Element => (
      <CellText>{value ? formatDateWithTimezone(Number(value)) : '-'}</CellText>
    ),
  },
]

export { planVersionsColumns }
