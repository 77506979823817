import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { ListItemButton } from '../list'

export const MenuButton = styled(ListItemButton)<{ isActive: boolean }>(({ isActive }) => ({
  backgroundColor: isActive ? COLOR.BLUE[800] : 'transparent',
  color: isActive ? COLOR.WHITE : COLOR.BLACK,
  borderRadius: 0,
  display: 'flex',
  justifyContent: 'space-between',
  ...(isActive && {
    '&:hover': {
      backgroundColor: COLOR.BLUE[800],
      color: COLOR.WHITE,
    },
  }),
}))

export const ItemWrapper = styled.div({
  position: 'relative',
  '&:first-of-type > .menu-button': {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  '&:last-child > .menu-button': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
})

export const MenuWrapper = styled.div({
  position: 'absolute',
  left: 'calc(100% + 6px)',
  width: '100%',
  top: '-8px',
})

export const Chevron = styled.div({
  marginRight: -4,
  '& svg': {
    display: 'block',
  },
})
