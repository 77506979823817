import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import {
  Checkbox,
  Information,
  Stack,
  Subheading,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useUpsertMerchantServicingSettingsMutation } from '@helloextend/extend-api-rtk-query'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import { SaveChangesButtonGroup } from '../save-changes-button-group'

interface MerchantOwnedIntakeProps {
  merchantServicingSettings?: MerchantServicingSettings
}

export const MerchantOwnedIntake: FC<MerchantOwnedIntakeProps> = ({
  merchantServicingSettings,
}) => {
  const [updateMerchantServicingSettings] = useUpsertMerchantServicingSettingsMutation()
  const [isMerchantOwnedIntake, setIsMerchantOwnedIntake] = useState(
    merchantServicingSettings?.isMerchantOwnedIntake ?? false,
  )
  const { toast } = useToaster()

  const handleCheckboxChange = (): void => {
    setIsMerchantOwnedIntake(!isMerchantOwnedIntake)
  }

  const handleCancel = (): void => {
    setIsMerchantOwnedIntake(merchantServicingSettings?.isMerchantOwnedIntake ?? false)
  }

  const handleSave = async (): Promise<void> => {
    if (!merchantServicingSettings) return
    await updateMerchantServicingSettings({
      sellerId: merchantServicingSettings.sellerId,
      isMerchantOwnedIntake: !merchantServicingSettings.isMerchantOwnedIntake,
    }).unwrap()
    toast({
      message: 'Servicing settings have been successfully updated.',
      toastColor: ToastColor.blue,
      toastDuration: ToastDuration.short,
    })
  }

  const showButtons =
    isMerchantOwnedIntake !== (merchantServicingSettings?.isMerchantOwnedIntake ?? false)

  return (
    <div data-cy="merchant-owned-intake">
      <ContainerWithMargin>
        <Stack isRow align="center">
          <Subheading>Claim Intake</Subheading>
          <Information buttonSize="xsmall">
            When enabled, this setting will redirect all customers from Kaley to the Merchant using
            their support contact information so the Merchant can file claims in the Merchant
            Portal.
          </Information>
        </Stack>
      </ContainerWithMargin>
      <CheckboxContainer>
        <Checkbox
          name="merchant-owned-intake"
          data-cy="merchant-owned-intake-checkbox"
          label="Direct claims to Merchant for Merchant Portal claims intake"
          checked={isMerchantOwnedIntake}
          onChange={handleCheckboxChange}
        />
      </CheckboxContainer>
      {showButtons && (
        <SaveChangesButtonGroup
          handleSave={handleSave}
          handleCancel={handleCancel}
          isLoading={false}
          isSaveDisabled={false}
          id="merchant-owned-intake"
        />
      )}
    </div>
  )
}

const ContainerWithMargin = styled.div({
  marginBottom: 24,
})

const CheckboxContainer = styled.div({
  marginBottom: 16,
})
