import type { FC } from 'react'
import React from 'react'
import type { AdvancedSelectChangeEvent, AdvancedSelectOption } from '@extend/zen'
import { AdvancedSelect } from '@extend/zen'

export interface CategorySelectorProps {
  onChange: (e: AdvancedSelectChangeEvent<string>) => void
  value: string
  categories: AdvancedSelectOption[]
  isDisabled: boolean
  showSelector: boolean
}

const CategorySelector: FC<CategorySelectorProps> = ({
  onChange,
  value = '',
  categories,
  isDisabled,
  showSelector,
}) => {
  if (!showSelector) return null

  return (
    <AdvancedSelect
      id="category-select"
      data-cy="category-select"
      label="Extend Plan Category"
      placeholder="Select"
      onChange={onChange}
      value={value}
      options={categories}
      multiple={false}
      showSearch
      isDisabled={isDisabled}
    />
  )
}

export { CategorySelector }
