export type MSAAmendmentCreateDraftRequest = {
  body: MSAAmendmentCreate
  accountId: string
  version?: string
}
export type MSAAmendmentUpdatedRequest = {
  body: MSAAmendmentCreate & {
    isDraft?: boolean
  }
  accountId: string
  amendment: string
  version?: string
}

export type GetMSAAmendmentsRequest = {
  accountId: string
  version?: string
}
export type GetMSAAmendmentResponse = {
  items: MSAAccountAmendment[]
}

export type MSAAmendmentCreate = {
  startDate: number
  endDate?: number
  description: string
  linkToMSA: string
  linkToAmendment: string
  storeConfigDetails: storeConfigDetails[]
}

export type MSAAmendmentCreateResponse = MSAAmendmentCreate & {
  amendmentNumber: number
  createdAt: number
  createdBy: string
  version: number
  editedAt: number
  isDraft: boolean
}
export type PPRevShareDetailsUpsertRequest = {
  body: PPRevShareDetailsUpsert
  accountId: string
  amendment: string
  version?: string
}

export type storeConfigDetails = {
  storeId: string
  invoicePurchasePriceOverride: boolean
  productProtection: {
    revenueModelDetails: {
      revenueModels: PPRevenueModelType[]
    }
    defaultRevenueModel: PPRevenueModelType
    defaultMerchantRevenueShare: number
  }
  shippingProtection: {
    spRevenueModelDetails: {
      RevenueModel: SPRevenueModelType
      PurchaseModel: PurchaseModelType
    }
    defaultPlanId: string
    defaultMerchantRevenueShare: number
  }
}

export type MSAAccountAmendment = {
  accountId: string
  startDate: number
  endDate?: number
  amendmentNumber: number
  msaLink: string
  createdBy: string
  createdAt: number
  description: string
  isDraft: boolean
  amendmentLink?: string
  version: number
  editedAt: number
}

export const PPRevenueModelTypes = Object.freeze({
  NET: 'Net',
  GROSS: 'Gross',
  WHOLESALE: 'Wholesale',
} as const)
export type PPRevenueModelType = typeof PPRevenueModelTypes[keyof typeof PPRevenueModelTypes]

export const SPRevenueModelTypes = Object.freeze({
  PLATFORM_ACCESS_FUNDS: 'Platform Access Funds',
  REVENUE_SHARE: 'Revenue Share',
} as const)

export type SPRevenueModelType = typeof PPRevenueModelTypes[keyof typeof PPRevenueModelTypes]

export const PurchaseModelTypes = Object.freeze({
  SINGLE_PLAN: 'Single Plan',
  CATEGORY: 'Category',
  PRICE_BAND: 'Price Band',
} as const)

export type PurchaseModelType = typeof PurchaseModelTypes[keyof typeof PurchaseModelTypes]

export type PPRevShareDetailsUpsert = PPRevenueShareOverrides & {
  storeId: string
  description: string
}
export type PPRevShareDetailsUpsertResponse = {
  created: string[]
  errors: Array<{ planCategoryId: string; reason: string; planId?: string }>
}

export type PPRevenueShareOverrides = {
  revenueShareDetails?: PPRevenueShareDetails[]
  wholesaleRevenueShareDetails?: PPWholesaleRevenueShareDetails[]
  embeddedRevenueShareDetails?: PPEmbeddedRevShareDetails[]
}

export type PPRevenueShareDetails = {
  merchantRevenueShare: number
  planCategoryId: string
  revenueModel: Omit<PPRevenueModelType, 'Wholesale'>
}

export type PPWholesaleRevenueShareDetails = {
  planId: string
  planCategoryId: string
  planDetails: WholesalePlanDetails[]
}

export type WholesalePlanDetails = {
  planSkuId: string
  serviceContractFee: number
  priceBandMin: number
  priceBandMax: number
}

export type PPEmbeddedRevShareDetails = {
  planCategoryId: string
  planId: string
  planDetails: EmbeddedPlanDetails[]
}

export type EmbeddedPlanDetails = {
  planSkuId: string
  merchantRevenueShare: number
  priceBandMin: number
  priceBandMax: number
}
