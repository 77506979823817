import * as Yup from 'yup'
import { validate } from '@extend/client-helpers'

const schema = Yup.object()
  .shape({
    url: Yup.string().matches(validate.urlRegExp, 'Please enter a valid URL'),
    parts: Yup.number(),
    labor: Yup.number(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export type { Values }
export { schema }
