import { createApi } from '@reduxjs/toolkit/query/react'
import type { StoreConfigCreateBody, StoreConfig } from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'

const BASE_PATH = 'shipping-offers/store-config'
const DEFAULT_API_VERSION = 'default'

const getHeaders = ({ version }: { version?: string }): Record<string, string> => ({
  accept: `application/json; version=${version || DEFAULT_API_VERSION};`,
})

export const shippingOffersStoresConfigApi = createApi({
  baseQuery,
  reducerPath: 'store-config',
  tagTypes: ['store-config'],
  endpoints: (build) => ({
    getStoreConfig: build.query<StoreConfig, { storeId: string; version?: string }>({
      query: ({ storeId, version }) => ({
        url: `${BASE_PATH}/${storeId}`,
        headers: getHeaders({ version }),
      }),
      providesTags: (_, _err, { storeId }) => [{ type: 'store-config', id: storeId }],
    }),
    upsertStoreConfig: build.mutation<
      StoreConfig,
      { storeId: string; body: StoreConfigCreateBody; version?: string }
    >({
      query: ({ storeId, body, version }) => ({
        url: `${BASE_PATH}/${storeId}`,
        method: 'PUT',
        body,
        headers: getHeaders({ version }),
      }),
      invalidatesTags: (_, _err, { storeId }) => [{ type: 'store-config', id: storeId }],
    }),
  }),
})
export const { useUpsertStoreConfigMutation, useGetStoreConfigQuery } =
  shippingOffersStoresConfigApi
