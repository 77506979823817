import type { ColumnFiltersState, SortingState } from '@extend/zen'
import { Add, DataTable } from '@extend/zen'
import type { ClaimsSearchClaim } from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router-dom'
import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import { useNewClaimsSearch } from '../../../hooks/use-new-claims-search'
import { formatClaimItems, getTableColumns, getFilterDefs } from './new-table-config'
import type { ClaimsUser } from '@helloextend/extend-api-client'

interface EscalationsTableProps {
  users?: ClaimsUser[]
}

export const EscalationsTable: FC<EscalationsTableProps> = ({ users }) => {
  const { data: claims, isLoading } = useNewClaimsSearch({ escalationLevel: 'all' })
  const [sorting, setSorting] = useState<SortingState>([{ id: 'dateReported', desc: true }])
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({ type: false })
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'escalationLevel', value: ['tier1', 'tier2', 'tier3'] },
  ])
  const { push } = useHistory()
  const filterDefs = useMemo(() => getFilterDefs(users), [users])

  const handleFileClaim = (): void => {
    push('/admin/contracts')
  }

  const handleRowClick = (row: ClaimsSearchClaim): void => {
    window.open(`/admin/claims/${row.id}`, '_blank', 'noreferrer')
  }
  return (
    <DataTable
      data-cy="escalations-table"
      data={claims.map(formatClaimItems)}
      isLoading={isLoading || !users}
      sorting={sorting}
      onSortingChange={setSorting}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      onColumnFiltersChange={setColumnFilters}
      filterDefs={filterDefs}
      columnFilters={columnFilters}
      columns={getTableColumns()}
      pageSizeOptions={[50, 100]}
      getRowId={(row) => row.id}
      getRowActions={(row) => [
        {
          onClick: () => handleRowClick(row),
          text: 'View',
          emphasis: 'low',
        },
      ]}
      getTableActions={() => [
        {
          emphasis: 'medium',
          text: 'File a new claim',
          onClick: handleFileClaim,
          icon: Add,
        },
      ]}
    />
  )
}
