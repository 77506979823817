import type { Order } from '@helloextend/extend-api-client'
import * as Yup from 'yup'

const cutomerInformationSchema = Yup.object()
  .shape({
    customerStatus: Yup.string().notRequired(),
    customerType: Yup.string().notRequired(),
    loyaltyStatus: Yup.string().notRequired(),
    marketingOptIn: Yup.boolean().notRequired(),
  })
  .defined()

const paymentInformationSchema = Yup.object()
  .shape({
    paymentMethod: Yup.string().notRequired(),
    paymentInstrument: Yup.string().notRequired(),
    totalDiscounts: Yup.number().notRequired(),
    checkoutId: Yup.string().notRequired(),
    discountSource: Yup.string().notRequired(),
    fileName: Yup.string().notRequired(),
  })
  .defined()

type CustomerInformation = Yup.InferType<typeof cutomerInformationSchema>
type PaymentInformation = Yup.InferType<typeof paymentInformationSchema>

interface OrderAnalyticsInfoFormSchemaInterface {
  customer: CustomerInformation
  payment: PaymentInformation
}

export function mapOrderToOrderAnalyticsInfoSchema(
  order: Order,
): OrderAnalyticsInfoFormSchemaInterface {
  return {
    customer: {
      customerStatus: order?.analytics?.customerStatus || '',
      customerType: order?.analytics?.customerType || '',
      loyaltyStatus: order?.analytics?.loyaltyStatus || '',
      marketingOptIn: !!order?.analytics?.marketingOptIn,
    },
    payment: {
      paymentMethod: order?.analytics?.paymentMethod || '',
      paymentInstrument: order?.analytics?.paymentInstrument || '',
      totalDiscounts: order?.analytics?.totalDiscounts || 0,
      checkoutId: order?.analytics?.checkoutId || '',
      discountSource: order?.analytics?.discountSource || '',
      fileName: order?.analytics?.fileName || '',
    },
  }
}

export type { CustomerInformation, PaymentInformation }
