import type { FC } from 'react'
import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { Add, Button, Stack, Subheading, Trash } from '@extend/zen'
import styled from '@emotion/styled'
import type { SPPlanPriceBandMappingFormValues, Values } from '../schema'
import { getInitialPriceBandValues } from '../schema'
import { SpPriceBandRow } from './sp-price-band-row/sp-price-band-row'
import { PartialReimbursementSelection } from '../partial-reimbursement-selection/partial-reimbursement-selection'

const SpPriceBandModel: FC = () => {
  const { values, handleChange, errors } = useFormikContext<Values>()

  if (values.priceBands) {
    values.priceBands = values.priceBands.map((pb) => {
      return {
        ...(pb as SPPlanPriceBandMappingFormValues),
        pbPartialReimbursementSelection: values.partialReimbursementSelection,
        pbPartialReimbursementEnabled: values.partialReimbursementEnabled,
      }
    })
  }

  const isDeleteButtonDisplayed = values.priceBands && values?.priceBands.length > 1

  return (
    <Stack spacing={2} data-cy="price-bands-form">
      <Subheading>price band purchase model</Subheading>
      <PartialReimbursementSelection />
      <FieldArray
        name="priceBands"
        render={({ push, remove }) => (
          <>
            {(values?.priceBands as SPPlanPriceBandMappingFormValues[])?.map((value, index) => (
              <>
                <RowContainer
                  key={JSON.stringify(value)}
                  isDeleteDisplayed={isDeleteButtonDisplayed}
                >
                  <SpPriceBandRow
                    index={index}
                    data={value as SPPlanPriceBandMappingFormValues}
                    errors={errors}
                    handleOnChange={handleChange}
                    isLabelDisplayed={index === 0}
                  />
                  {isDeleteButtonDisplayed && (
                    <ButtonContainer
                      isLabelHidden={index > 0}
                      isError={!!errors?.priceBands?.[index]}
                    >
                      <Button
                        icon={Trash}
                        onClick={() => remove(index)}
                        emphasis="low"
                        data-cy={`delete-pb-row-${index}`}
                      />
                    </ButtonContainer>
                  )}
                </RowContainer>
              </>
            ))}
            <Button
              text="Add Price Band"
              data-cy="add-price-band-button"
              icon={Add}
              emphasis="low"
              onClick={() =>
                push(
                  getInitialPriceBandValues(
                    values.partialReimbursementEnabled ?? false,
                    values.partialReimbursementSelection,
                  ),
                )
              }
            />
          </>
        )}
      />
    </Stack>
  )
}

const ButtonContainer = styled.div<{ isLabelHidden?: boolean; isError?: boolean }>(
  ({ isLabelHidden, isError }) => ({
    ...(!isLabelHidden &&
      !isError && {
        marginTop: '22px',
      }),
    ...(isLabelHidden &&
      isError && {
        marginTop: '-27px',
      }),
    display: 'flex',
    alignSelf: 'center',
  }),
)

const RowContainer = styled.div<{ isDeleteDisplayed?: boolean }>(({ isDeleteDisplayed }) => ({
  marginBottom: 16,
  columnGap: 8,
  ...(isDeleteDisplayed && {
    display: 'grid',
    gridTemplateColumns: '3fr .1fr',
  }),
}))

export { SpPriceBandModel }
