import type { FC } from 'react'
import React from 'react'
import type { Order } from '@helloextend/extend-api-client'
import { TabSections, TabSection } from '@extend/zen'
import { AuditLog } from '../audit-log'
import { LineItemsTable } from '../../../../../components/line-items-table'
import {
  OrderDetailsForm,
  OrderAdditionalSalesInfoForm,
  OrderAnalyticsInfoForm,
} from '../order-details'

const OrderPageTabBar: FC<{ order: Order }> = ({ order }) => {
  return (
    <TabSections data-cy="order-page-tab-bar">
      <TabSection tabLabel="Order Details">
        <OrderDetailsForm order={order} />
        <OrderAdditionalSalesInfoForm order={order} />
        <LineItemsTable orderId={order.id} lineItems={order.lineItems} />
        <OrderAnalyticsInfoForm order={order} />
      </TabSection>
      <TabSection tabLabel="Audit Log">
        <AuditLog order={order} />
      </TabSection>
    </TabSections>
  )
}

export { OrderPageTabBar }
