import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  Badge,
  ToastColor,
  ToastDuration,
  useToaster,
  Stack,
  DisplayLarge,
  TabSections,
  TabSection,
  Loading,
  DataProperty,
  DataPropertyType,
} from '@extend/zen'
import { useHistory, useParams } from 'react-router'
import { useCreateSkuMutation, useGetSkuQuery } from '@helloextend/extend-api-rtk-query'
import { useFormik } from 'formik'
import type { Sku as SkuModel } from '@helloextend/extend-api-rtk-query'
import { PremiumForm } from './premium-form/premium-form'
import type { Values } from './premium-form/form-schema'
import { schema } from './premium-form/form-schema'
import { skuFormInitialValues, skuPropertiesMapper } from '../../../../utils/sku-property-mapper'
import { PremiumVersions } from './premium-versions/premium-versions'
import { formatDateWithTimezone } from '../../../../utils/date-formatting'
import { LeavePageGuard } from '../../../../components/leave-page-guard'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'

const Premium: FC = () => {
  const params = useParams<{ skuId: string }>()
  const queryParams = new URLSearchParams(location.search)
  const version = queryParams.get('version')

  const history = useHistory()
  const { toast } = useToaster()

  const { hasPermission } = usePermissions()

  const canEditPremium = hasPermission(Permission.ManagePremiums)

  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const {
    data: skuDetails,
    isLoading,
    isFetching,
  } = useGetSkuQuery({ skuId: params.skuId, version: version ?? undefined })

  const [create, { isLoading: isCreating, isSuccess, isError, data: createdSku }] =
    useCreateSkuMutation()

  const {
    values,
    errors,
    isValid,
    dirty,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setErrors,
    resetForm,
  } = useFormik<Values>({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: skuFormInitialValues((skuDetails as SkuModel) || {}),
    onSubmit: async (submitValues) => {
      const mappedValues = skuPropertiesMapper(submitValues)
      await create(mappedValues)
      setIsEditMode(false)
      history.push(`/admin/premiums/${params.skuId}`)
    },
  })

  useEffect(() => {
    if (isSuccess && createdSku) {
      toast({
        message: `SKU ${createdSku.id} has been updated.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    }
    if (isError) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isSuccess, isError, toast, createdSku, history])

  const handleCancelClick = useCallback(() => {
    resetForm()
    setIsEditMode(false)
  }, [resetForm])

  const handleLeavePage = useCallback(
    (path: string): void => {
      history.push(path)
    },
    [history],
  )

  if (!skuDetails || isLoading || isFetching)
    return <Loading messages={[{ text: 'Loading Premium Details' }]} />

  return (
    <>
      <Stack spacing={2}>
        <Breadcrumbs
          data-cy="sku-breadcrumbs"
          crumbs={[
            { to: '/admin/premiums', text: 'Premium' },
            { text: `Premium ID: ${skuDetails.id}` },
          ]}
        />
        <Stack isRow justify="space-between">
          <Stack isRow data-cy="sku-header-id" spacing={2}>
            <DisplayLarge>Premium ID: {skuDetails.id}</DisplayLarge>
            <Badge
              data-cy="sku-id-header-badge"
              text={skuDetails.isActive ? 'Active' : 'Inactive'}
              color={skuDetails.isActive ? 'green' : 'yellow'}
            />
          </Stack>
          {canEditPremium ? (
            <ButtonGroup>
              {isEditMode ? (
                <>
                  <Button
                    emphasis="medium"
                    text="Cancel"
                    onClick={handleCancelClick}
                    isDisabled={isCreating}
                    data-cy="cancel-button"
                  />
                  <Button
                    text="Save"
                    onClick={() => handleSubmit()}
                    isDisabled={!dirty || !isValid || isCreating}
                    isProcessing={isCreating}
                    data-cy="save-sku-button"
                  />
                </>
              ) : (
                <Button
                  text="Edit"
                  onClick={() => setIsEditMode(true)}
                  emphasis="medium"
                  data-cy="edit-button"
                />
              )}
            </ButtonGroup>
          ) : null}
        </Stack>
        <TabSections id="tab" isQSControlled data-cy="sku-details-tabs">
          <TabSection tabLabel="Details">
            <Stack spacing={2}>
              <Stack spacing={1}>
                <DataProperty
                  type={DataPropertyType.string}
                  label="Version"
                  value={skuDetails.version.toString()}
                  isHorizontal
                  data-cy="sku-version"
                />
                <DataProperty
                  type={DataPropertyType.string}
                  label="Last Updated"
                  value={`${formatDateWithTimezone(skuDetails?.createdAt)} by ${
                    skuDetails.createdBy
                  }`}
                  isHorizontal
                  data-cy="sku-last-updated"
                />
              </Stack>
              <PremiumForm
                values={values}
                errors={errors}
                isDisabled={!isEditMode}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setErrors={setErrors}
                activeVersion={(skuDetails as SkuModel) || {}}
              />
              <LeavePageGuard isNavBlocked={dirty} handleLeavePage={handleLeavePage} />
            </Stack>
          </TabSection>
          <TabSection tabLabel="Versions">
            <PremiumVersions skuId={skuDetails.id} />
          </TabSection>
        </TabSections>
      </Stack>
    </>
  )
}

export { Premium }
