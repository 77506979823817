import styled from '@emotion/styled'
import type { FC } from 'react'
import React from 'react'
import { Badge, COLOR } from '@extend/zen'

interface StoreInfoProps {
  label: string
  value: string
  tableLength?: string
}

const StoreInfo: FC<StoreInfoProps> = ({ label, value, tableLength }) => {
  return (
    <Container>
      <TitleBadgeWrapper>
        <StoreTitle>{label}</StoreTitle>
        {tableLength && (
          <Badge text="Unmapped" details={`${tableLength} product(s)`} color="yellow" />
        )}
      </TitleBadgeWrapper>
      <DetailRow>
        <DetailName>Store ID: </DetailName>
        {value}
      </DetailRow>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const TitleBadgeWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
})

const StoreTitle = styled.h3({
  fontSize: 28,
  fontWeight: 800,
  marginTop: 0,
  marginBottom: 6,
})

const DetailRow = styled.p({
  display: 'flex',
  margin: 0,
  marginBottom: 4,
  fontSize: 16,
  color: COLOR.NEUTRAL[1000],
})

const DetailName = styled.span({
  fontSize: 16,
  fontWeight: 700,
  color: COLOR.NEUTRAL[600],
  marginRight: 4,
})

export { StoreInfo }
