import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'

type AccountUpdateAction = ReturnType<typeof actions.update>

export function* update(action: AccountUpdateAction): SagaIterator {
  const { accountId, accessToken, data } = action.payload

  yield put(actions.updateRequest())

  try {
    const res = yield call(client.accounts.update, accountId, data, accessToken)

    if (isErrorResponse(res)) {
      yield put(actions.updateFailure(res.data.message, res.status))
      return
    }

    yield put(actions.updateSuccess())
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.updateFailure(e.message))
    }
  }
}
