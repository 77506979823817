import { COLOR } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'

type ClipboardSearchIconProps = {
  fill?: string
  sizePx?: number
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@extend/zen'`
 */
const ClipboardSearchIcon: FC<ClipboardSearchIconProps> = ({ fill = COLOR.BLACK, sizePx }) => (
  <svg
    width={sizePx}
    height={sizePx}
    viewBox="0 0 44 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.6894 49.8731L36.9394 43.1231C38.1308 41.6684 38.875 39.8341 38.875 37.8125C38.875 33.1601 35.0899 29.375 30.4375 29.375C25.7851 29.375 22 33.1601 22 37.8125C22 42.4649 25.7851 46.25 30.4375 46.25C32.4591 46.25 34.2917 45.5058 35.7464 44.3161L42.4964 51.0661C42.6617 51.2298 42.8777 51.3125 43.0937 51.3125C43.3097 51.3125 43.5257 51.2298 43.6894 51.0661C44.0185 50.7371 44.0185 50.2021 43.6894 49.8731ZM30.4375 44.5625C26.7149 44.5625 23.6875 41.5351 23.6875 37.8125C23.6875 34.0899 26.7149 31.0625 30.4375 31.0625C34.1601 31.0625 37.1875 34.0899 37.1875 37.8125C37.1875 41.5351 34.1601 44.5625 30.4375 44.5625ZM19.4687 49.6233H2.99031C2.30687 49.6233 1.75 49.0647 1.75 48.3779V8.68287C1.75 7.99606 2.30687 7.4375 2.99031 7.4375H8.5V8.28125C8.5 8.747 8.878 9.125 9.34375 9.125H27.9062C28.372 9.125 28.75 8.747 28.75 8.28125V7.4375H34.2597C34.9431 7.4375 35.5 7.99606 35.5 8.68287V26.8438C35.5 27.3095 35.878 27.6875 36.3437 27.6875C36.8095 27.6875 37.1875 27.3095 37.1875 26.8438V8.68287C37.1875 7.06625 35.8746 5.75 34.2597 5.75H28.6656C28.2741 3.82625 26.5681 2.375 24.5329 2.375H21.5393C20.9419 1.34562 19.8366 0.6875 18.625 0.6875C17.4134 0.6875 16.3081 1.34562 15.7124 2.375H12.7187C10.6819 2.375 8.97756 3.82625 8.58606 5.75H2.99031C1.37537 5.75 0.0625 7.06625 0.0625 8.68287V48.3796C0.0625 49.9962 1.37537 51.3125 2.99031 51.3125H19.4687C19.9345 51.3125 20.3125 50.9328 20.3125 50.4671C20.3125 50.0013 19.9345 49.6233 19.4687 49.6233ZM10.1875 6.59375C10.1875 5.19819 11.3232 4.0625 12.7187 4.0625H16.249C16.6051 4.0625 16.924 3.83806 17.0438 3.50056C17.2834 2.82894 17.9196 2.375 18.625 2.375C19.3304 2.375 19.9666 2.82894 20.2062 3.50056C20.326 3.83806 20.6449 4.0625 21.001 4.0625H24.5312C25.9268 4.0625 27.0625 5.19819 27.0625 6.59375V7.4375H10.1875V6.59375Z"
      fill={fill}
    />
  </svg>
)

export { ClipboardSearchIcon }
