import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import type {
  StoreResponse,
  EvalCodesResponse,
  ProductSearchRequest,
  ProductMappedRecord,
  ProductCategoriesResponse,
  PlansResponse,
  WarrantyStatusesResponse,
  MappingStatusesResponse,
  ProductsMutateRequest,
  ProductsMutateResponse,
  ProductSearchResponse,
  StoreConfigurationResponse,
  LegacyProductsMutateRequest,
} from './types'

export const productsMappingApi = createApi({
  reducerPath: 'ProductsMappingAPI',
  baseQuery,
  tagTypes: ['MappedProducts'],
  endpoints: (build) => ({
    // downstream rotes for /wd-mapping/
    validateProductMappings: build.mutation<ProductsMutateResponse, Partial<ProductsMutateRequest>>(
      {
        query: (reqBody) => ({
          url: '/wd-mapping/products',
          method: 'POST',
          body: reqBody,
        }),
        invalidatesTags: ['MappedProducts'],
      },
    ),
    // downstream routes for /mapping/
    getEvaluationCodes: build.query<EvalCodesResponse, void>({
      query: () => ({
        url: '/mapping/evaluation-codes',
      }),
    }),
    getWarrantyStatuses: build.query<WarrantyStatusesResponse, void>({
      query: () => ({
        url: '/mapping/warranty-statuses',
      }),
    }),
    getMappingStatuses: build.query<MappingStatusesResponse, void>({
      query: () => ({
        url: '/mapping/mapping-statuses',
      }),
    }),
    getMappingStores: build.query<StoreResponse, void>({
      query: () => ({
        url: '/mapping/stores',
      }),
    }),
    getProductCategories: build.query<ProductCategoriesResponse, void>({
      query: () => ({
        url: '/mapping/product-taxonomies/categories',
      }),
    }),
    getMappingPlans: build.query<PlansResponse, void>({
      query: () => ({
        url: '/mapping/plans',
      }),
    }),
    // downstream routes for /mapping/products/
    getProductMapping: build.query<ProductMappedRecord, { product_id: string }>({
      query: (params) => ({
        url: '/mapping/products',
        params,
      }),
    }),
    productMappingsSearch: build.query<ProductSearchResponse, ProductSearchRequest>({
      query: (reqBody) => ({
        url: '/mapping/products/search',
        method: 'POST',
        body: reqBody,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.Items.map((itm) => ({
                type: 'MappedProducts' as const,
                id: itm.product_id,
              })),
              { type: 'MappedProducts', id: 'LIST' },
            ]
          : [{ type: 'MappedProducts', id: 'LIST' }],
    }),
    getUploadUrl: build.query<string, void>({
      query: () => ({
        url: '/mapping/products/upload-url',
      }),
    }),
    legacyValidateProductMappings: build.mutation<
      ProductsMutateResponse,
      Partial<LegacyProductsMutateRequest>
    >({
      query: (reqBody) => ({
        url: '/mapping/products',
        method: 'POST',
        body: reqBody,
      }),
      invalidatesTags: ['MappedProducts'],
    }),
    getStoreConfiguration: build.query<StoreConfigurationResponse, void>({
      query: () => ({
        url: '/mapping/store-config',
      }),
    }),
  }),
})

export const {
  useValidateProductMappingsMutation,
  useGetEvaluationCodesQuery,
  useGetWarrantyStatusesQuery,
  useGetMappingStatusesQuery,
  useGetMappingStoresQuery,
  useGetProductCategoriesQuery,
  useGetMappingPlansQuery,
  useGetProductMappingQuery,
  useLazyProductMappingsSearchQuery,
  useGetUploadUrlQuery,
  useLegacyValidateProductMappingsMutation,
  useGetStoreConfigurationQuery,
} = productsMappingApi
