import type {
  PlanTerms,
  PlanTermsVersionLanguage,
} from '@helloextend/extend-api-rtk-query/src/plan-terms/types'

export function generatePlanTerms(overrides: Partial<PlanTerms> = {}): PlanTerms {
  const termsId = overrides.termsId || '3432-342-43-3fedf-3fdfd'
  const firstCreation = overrides.createdAt || 123456789000

  return {
    termsId,
    createdAt: firstCreation,
    description: 'Auto Parts Replace',
    versions: [
      {
        createdAt: firstCreation,
        termsId,
        version: 1,
        updatedAt: firstCreation,
        description: 'Auto Parts Replace',
      },
      {
        createdAt: 1232456789000,
        termsId,
        version: 2,
        updatedAt: 1232456789000,
        description: 'Auto Parts Replace',
      },
    ],
    ...overrides,
  }
}

export function generatePlanTermsLanguage(
  overrides?: Partial<PlanTermsVersionLanguage>,
): PlanTermsVersionLanguage {
  return {
    createdAt: Date.now(),
    language: 'en',
    ...overrides,
  }
}
