import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { COLOR, Button } from '@extend/zen'
import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { AnimatedSpinnerCheck } from '@helloextend/merchants-ui'
import type { ErrorObject } from '@helloextend/core-api-redux/src/types/error'

type SuccessfulSubmitProps = {
  submitted: boolean
  isLoading: boolean
  error?: ErrorObject | SerializedError | FetchBaseQueryError | null
  errorTitle: string
  successTitle: string
  successDescription?: string
  buttonText: string
  handleClick: () => void
}

const SuccessfulSubmit: FC<SuccessfulSubmitProps> = ({
  submitted,
  isLoading,
  error,
  errorTitle,
  successTitle,
  successDescription,
  buttonText,
  handleClick,
}) => (
  <CenterContainer>
    <SpinnerWrapper submitted={submitted}>
      {!error && (
        <AnimatedSpinnerCheck
          color={COLOR.BLUE[700]}
          size={100}
          thickness={3}
          loading={isLoading}
        />
      )}
    </SpinnerWrapper>
    <AnimatedText submitted={submitted} error={Boolean(error)}>
      {error ? (
        <>
          <Title>{errorTitle}</Title>
          {'message' in error && <DetailText>{error.message}</DetailText>}
        </>
      ) : (
        <>
          {submitted && (
            <>
              <Title data-cy="success-message">{successTitle}</Title>
              <DetailText>{successDescription}</DetailText>
              <Button
                isProcessing={false}
                onClick={handleClick}
                text={buttonText}
                data-cy="close-modal-button"
              />
            </>
          )}
        </>
      )}
    </AnimatedText>
  </CenterContainer>
)

const Title = styled.h3({
  fontSize: 20,
  marginBottom: 8,
})

const DetailText = styled.h3({
  fontSize: 16,
  fontWeight: 500,
  margin: '8px 0 32px',
})

const CenterContainer = styled.div({
  textAlign: 'center',
  transition: 'all .2s ease-in',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 310,
})

const heightAnimation = keyframes({
  '0%': { opacity: 0, height: 0 },
  '50%': { opacity: 1, height: '50%' },
  '75%': { opacity: 1, height: '75%' },
  '100%': { opacity: 1, height: '100%' },
})

const AnimatedText = styled.div<{ submitted: boolean; error: boolean }>(({ submitted, error }) => ({
  animationName: submitted ? `${heightAnimation}` : 'none',
  animationDuration: '3000ms',
  animationTimingFunction: 'ease',
  height: submitted ? '100%' : 0,
  marginTop: error ? 0 : 98,
  opacity: submitted ? 1 : 0,
  overflow: 'hidden',
}))

const transformAnimation = keyframes({
  '0%': { opacity: 0, transform: 'translateY(50%)' },
  '50%': { opacity: 0, transform: 'translateY(50%)' },
  '60%': { opacity: 1, transform: 'translateY(30%)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})
const SpinnerWrapper = styled.div<{ submitted: boolean }>(({ submitted }) => ({
  position: 'absolute',
  top: '15%',
  transform: submitted ? 'translateY(0)' : 'translateY(50%)',
  opacity: 1,
  animationName: submitted ? `${transformAnimation}` : 'none',
  animationDuration: '500ms',
  animationTimingFunction: 'ease',
}))

export { SuccessfulSubmit }
