import type { FC } from 'react'
import React, { useCallback } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import {
  Checkbox,
  Grid,
  GridItem,
  Select,
  Button,
  useToaster,
  ToastDuration,
  ToastColor,
} from '@extend/zen'
import { useUpsertStoreConfigMutation } from '@helloextend/extend-api-rtk-query'
import type { StoreConfig } from '@helloextend/extend-api-client'
import { schema } from './sp-offer-surface-management-form-schema'
import {
  mapSPOfferSurfaceVersionFromFormValues,
  mapSPOfferSurfaceVersionToFormValues,
} from './sp-offer-surface-management-helpers'

export interface SPOfferSurfaceManagementProps {
  storeConfig: StoreConfig | undefined
  storeId: string
}

export const SPOfferSurfaceManagement: FC<SPOfferSurfaceManagementProps> = ({
  storeConfig,
  storeId,
}) => {
  const { toast } = useToaster()
  const surfaceVersion = storeConfig?.surfaceVersion

  const [upsertStoreConfig, { isLoading: isUpsertStoreConfigLoading }] =
    useUpsertStoreConfigMutation()

  const formik = useFormik({
    initialValues: mapSPOfferSurfaceVersionToFormValues(surfaceVersion),
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const payload = mapSPOfferSurfaceVersionFromFormValues(values)
        const body = {
          ...storeConfig,
          storeId,
          surfaceVersion: payload,
          abTest: {
            enabled: storeConfig?.abTest?.enabled ?? false,
            type: storeConfig?.abTest?.type ?? 'OfferType',
          },
        }

        const response = await upsertStoreConfig({
          body,
          storeId,
        }).unwrap()

        formik.resetForm({
          values: mapSPOfferSurfaceVersionToFormValues(response?.surfaceVersion),
        })

        toast({
          message: 'Shipping Offer surface configuration updated.',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
      } catch (error) {
        toast({
          message: 'Failed to upsert shipping offer surface configuration',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    },
  })

  const handleCancel = useCallback(() => formik.resetForm(), [])
  const isDisabled = !formik.dirty || !formik.isValid

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid columns={3} spacing={6}>
        <GridItem>
          <Checkbox
            label="Learn More Modal"
            name="isLearnMoreModal"
            data-cy="learn-more-modal-checkbox"
            checked={formik.values.isLearnMoreModal}
            onChange={formik.handleChange}
          />
          <Select
            id="learnMoreModalSelect"
            label="Version"
            data-cy="learn-more-modal-select"
            onChange={formik.handleChange}
            isDisabled={!formik.values.isLearnMoreModal}
            placeholder="Select"
            value={formik.values.learnMoreModalSelect}
            isError={!!formik.errors.learnMoreModalSelect}
            errorFeedback={formik.errors.learnMoreModalSelect}
            subtext={`Content type: ${
              surfaceVersion?.learnMoreModal?.content ?? 'shippingProtectionLearnMoreModal'
            }`}
            disablePlaceholder
          >
            <option value="V1">V1</option>
            <option value="V2">V2</option>
          </Select>
        </GridItem>

        <GridItem>
          <Checkbox
            label="Cart Offer"
            name="isCartOffer"
            data-cy="cart-offer-checkbox"
            checked={formik.values.isCartOffer}
            onChange={formik.handleChange}
          />
          <Select
            id="cartOfferSelect"
            label="Version"
            onChange={formik.handleChange}
            data-cy="cart-offer-select"
            placeholder="Select"
            isDisabled={!formik.values.isCartOffer}
            value={formik.values.cartOfferSelect}
            isError={!!formik.errors.cartOfferSelect}
            errorFeedback={formik.errors.cartOfferSelect}
            subtext={`Content type: ${
              surfaceVersion?.cartOffer?.content ?? 'shippingProtectionCartOffer'
            }`}
            disablePlaceholder
          >
            <option value="V1">V1</option>
          </Select>
        </GridItem>
      </Grid>
      <Row>
        {formik.dirty && (
          <>
            <Button emphasis="medium" text="Cancel" onClick={handleCancel} />
            <Button
              type="submit"
              text="Save"
              isDisabled={isDisabled}
              isProcessing={isUpsertStoreConfigLoading}
            />
          </>
        )}
      </Row>
    </form>
  )
}

const Row = styled.div({
  marginTop: '2rem',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
  flexDirection: 'row',
})
