import type { LineItemStatus, LineItemType } from '@helloextend/extend-api-client/src/models/order'
import type { BadgeProps } from '@extend/zen'

export const lineItemStatusColors: { [key in LineItemStatus]: BadgeProps['color'] } = {
  fulfilled: 'green',
  contract_pending: 'yellow',
  pending: 'yellow',
  unfulfilled: 'yellow',
  cancel_failed: 'red',
  contract_failed: 'red',
  lead_failed: 'red',
  quote_failed: 'red',
  canceled: 'neutral',
  refunded: 'neutral',
}

export const formattedLineItemTypes: { [key in LineItemType]: string } = {
  non_warrantable: 'Non-Warrantable',
  contract: 'Contract',
  lead: 'Lead',
  shipments: 'Shipping',
  category_contract: 'Category',
  category_product: 'Category product',
  bundle_component: 'Bundle component',
  bundle_contract: 'Bundle',
  partial_contract: 'Partial',
}
