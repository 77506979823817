import { FieldArray, useFormikContext } from 'formik'
import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Add, Button, COLOR, Spinner, Stack, Subheading, Trash } from '@extend/zen'
import { useGetPlanCategoriesQuery } from '@helloextend/extend-api-rtk-query'
import type { SPPlanCategoryMapping } from '@helloextend/extend-api-client/src/models/store-shipping-protection'
import type { Values } from '../schema'
import { getInitialCategoryMappingValues } from '../schema'
import { SpCategoryRow } from './sp-category-row/sp-category-row'
import { PartialReimbursementSelection } from '../partial-reimbursement-selection/partial-reimbursement-selection'

const SpCategoryModel: FC = () => {
  const { values, handleChange, handleBlur, errors } = useFormikContext<Values>()

  const isDeleteButtonDisplayed = values.categoryMappings && values.categoryMappings.length > 1

  const { data: planCategories, isLoading: isExtendCategoryListLoading } =
    useGetPlanCategoriesQuery()

  if (isExtendCategoryListLoading) {
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  return (
    <Stack spacing={2} data-cy="category-model-form">
      <Subheading>Category purchase model</Subheading>
      <PartialReimbursementSelection />
      <FieldArray
        name="categoryMappings"
        render={({ push, remove }) => (
          <>
            {(values.categoryMappings as SPPlanCategoryMapping[]).map((value, index) => (
              <RowContainer key={JSON.stringify(value)} isDeleteDisplayed={isDeleteButtonDisplayed}>
                <SpCategoryRow
                  index={index}
                  errors={errors}
                  handleOnChange={handleChange}
                  handleOnBlur={handleBlur}
                  isLabelDisplayed={index === 0}
                  extendCategoryOptions={
                    planCategories?.items.map((planCategory) => {
                      return { display: planCategory.name, value: planCategory.id }
                    }) ?? []
                  }
                  isExtendCategoryListLoading={isExtendCategoryListLoading}
                  data={value as SPPlanCategoryMapping}
                />
                {isDeleteButtonDisplayed && (
                  <ButtonContainer
                    isLabelHidden={index > 0}
                    isError={!!errors?.priceBands?.[index]}
                  >
                    <Button
                      icon={Trash}
                      onClick={() => remove(index)}
                      emphasis="low"
                      data-cy={`delete-category-row-${index}`}
                    />
                  </ButtonContainer>
                )}
              </RowContainer>
            ))}
            <Button
              text="Add Extend Category"
              data-cy="add-category-button"
              icon={Add}
              emphasis="low"
              onClick={() => push(getInitialCategoryMappingValues())}
            />
          </>
        )}
      />
    </Stack>
  )
}

const ButtonContainer = styled.div<{ isLabelHidden?: boolean; isError?: boolean }>(
  ({ isLabelHidden, isError }) => ({
    ...(!isLabelHidden &&
      !isError && {
        marginTop: '22px',
      }),
    ...(isLabelHidden &&
      isError && {
        marginTop: '-27px',
      }),
    display: 'flex',
    alignSelf: 'start',
  }),
)

const RowContainer = styled.div<{ isDeleteDisplayed?: boolean }>(({ isDeleteDisplayed }) => ({
  marginBottom: 16,
  columnGap: 8,
  ...(isDeleteDisplayed && {
    display: 'grid',
    gridTemplateColumns: '3fr .1fr',
  }),
}))

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { SpCategoryModel }
