import * as accounts from './api/accounts'
import * as auth from './api/auth'
import * as incredibot from './api/claims-assistant'
import * as categories from './api/categories'
import * as contracts from './api/contracts'
import * as content from './api/content'
import * as consumers from './api/consumers'
import * as claimsPhotos from './api/claim-photos'
import * as fulfillments from './api/fulfillments'
import * as growth from './api/growth'
import * as offers from './api/offers'
import * as plans from './api/plans'
import * as products from './api/products'
import * as stores from './api/stores'
import * as support from './api/support'
import * as users from './api/users'
import * as claims from './api/claims'
import * as serviceOrders from './api/service-orders'
import * as servicers from './api/servicers'
import * as reports from './api/reports'
import * as serviceOrderShipments from './api/service-order-shipments'
import * as audit from './api/audit'
import * as consumerSignUp from './api/consumer-sign-up'

interface ExtendApiClient {
  accounts: typeof accounts
  auth: typeof auth
  audit: typeof audit
  categories: typeof categories
  claims: typeof claims
  consumerSignUp: typeof consumerSignUp
  contracts: typeof contracts
  content: typeof content
  consumers: typeof consumers
  claimsPhotos: typeof claimsPhotos
  fulfillments: typeof fulfillments
  growth: typeof growth
  incredibot: typeof incredibot
  offers: typeof offers
  plans: typeof plans
  products: typeof products
  reports: typeof reports
  servicers: typeof servicers
  serviceOrders: typeof serviceOrders
  serviceOrderShipments: typeof serviceOrderShipments
  stores: typeof stores
  support: typeof support
  users: typeof users
}

export const client: ExtendApiClient = {
  accounts,
  auth,
  audit,
  categories,
  claims,
  consumerSignUp,
  contracts,
  consumers,
  content,
  claimsPhotos,
  fulfillments,
  growth,
  incredibot,
  offers,
  plans,
  products,
  reports,
  servicers,
  serviceOrders,
  serviceOrderShipments,
  stores,
  support,
  users,
}
