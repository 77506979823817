import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { BadgeColor, ButtonSize } from '@extend/zen'
import { Badge } from '@extend/zen'
import { Information } from '../information'

interface BadgeDescriptionItem {
  badgeText: string
  badgeColor: BadgeColor
  description: string
}

interface BadgeDescriptionsProps {
  descriptions: BadgeDescriptionItem[]
  buttonSize?: Exclude<ButtonSize, 'regular' | 'large'>
  'data-cy'?: string
}

const BadgeDescriptions: FC<BadgeDescriptionsProps> = ({
  descriptions,
  buttonSize,
  'data-cy': dataCy,
}) => {
  return (
    <Information buttonSize={buttonSize} data-cy={dataCy}>
      <Descriptions data-cy={dataCy} descriptions={descriptions} />
    </Information>
  )
}

const Descriptions: FC<Omit<BadgeDescriptionsProps, 'buttonSize'>> = ({
  descriptions,
  'data-cy': dataCy,
}) => {
  return (
    <table>
      <tbody>
        {descriptions.map((d) => (
          <tr key={d.badgeText}>
            <BadgeCell>
              <Badge text={d.badgeText} color={d.badgeColor} data-cy={`${dataCy}:badge`} />
            </BadgeCell>
            <td>
              <TextContainer data-cy={`${dataCy}:description`}>{d.description}</TextContainer>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const BadgeCell = styled.td({
  marginLeft: 16,
  marginRight: 7,
  paddingTop: 5,
  textAlign: 'right',
  verticalAlign: 'top',
  whiteSpace: 'nowrap',
  'tr:not(:last-child) &': {
    paddingBottom: 19,
  },
})

const TextContainer = styled.div({
  fontSize: 13,
  marginLeft: 16,
  lineHeight: '18px',
  'tr:not(:last-child) &': {
    marginBottom: 19,
  },
})

export type { BadgeDescriptionsProps, BadgeDescriptionItem }
export { BadgeDescriptions, Descriptions }
