import * as Yup from 'yup'
import { NormalizedPhoneNumber } from '@extend/client-helpers'
import { phoneRegEx } from '../../../../../../../../utils/form-utils'

export type CharitySchema = {
  name: string
  contactName: string
  contactEmail: string
  contactPhone: string
  address: string
}

export const schema = (
  existingCharityNames: string[],
  currentCharityName?: string,
): Yup.ObjectSchema<CharitySchema> =>
  Yup.object()
    .shape({
      name: Yup.string()
        .trim()
        .required('Enter a unique Charity Name')
        .test('is-unique-name', 'Charity Name must be unique', (value) => {
          // Ensure the name is unique among existing charities
          return !existingCharityNames.includes(value || '') || value === currentCharityName
        }),
      contactName: Yup.string().trim().required('First and last name required'),
      contactEmail: Yup.string().email().required('Email address is required'),
      contactPhone: Yup.string()
        .matches(phoneRegEx, 'Please enter a valid phone number')
        .required('Phone number is required')
        .test('is-valid-e.164-number', 'Please enter a valid phone number', (value) => {
          // parsePhoneNumber throws an error on empty values and values with length 1
          if (!value || value.length <= 1) return false
          const phoneNumber = new NormalizedPhoneNumber(value, 'US')

          // confirm that the number is a valid E.164 number so it complies with Twilio's API downstream
          return phoneNumber.valid
        }),
      address: Yup.string().trim().required('Full address is required'),
    })
    .defined()
