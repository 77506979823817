import { useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useExtendAuth } from '@extend/package-okta-login'
import type { Permission } from '../lib/permissions'
import { getPermissions, rules, enterpriseRules } from '../lib/permissions'
import type { RuleDefinition } from '../lib/permissions/rules'
import { LDFlag } from '../constants/ld-flags'
import type { UserRole } from '../utils/user-role-mapper'

const usePermissions = (): {
  permissions: Permission[]
  hasPermission: (permission: Permission) => boolean
} => {
  const flags = useFlags()

  const FF_ENTERPRISE_ROLES = flags[LDFlag.EnterpriseRoles]
  const FF_ENTERPRISE_AND_LEGACY_ROLES = flags[LDFlag.LegacyAndEnterpriseRoles]

  const { user } = useExtendAuth()

  const combinedRules: Record<Permission, RuleDefinition> = Object.entries(rules).reduce(
    (acc, [key, value]) => {
      acc[key as Permission] = {
        allowedRoles: [...value.allowedRoles, ...enterpriseRules[key as Permission].allowedRoles],
        requiredFeatureFlags: [
          ...(value.requiredFeatureFlags || []),
          ...(enterpriseRules[key as Permission].requiredFeatureFlags || []),
        ],
      }
      return acc
    },
    {} as Record<Permission, RuleDefinition>,
  )

  const permissionRules = useMemo(() => {
    if (FF_ENTERPRISE_ROLES) {
      return enterpriseRules
    }

    if (FF_ENTERPRISE_AND_LEGACY_ROLES) {
      return combinedRules
    }

    return rules
  }, [FF_ENTERPRISE_AND_LEGACY_ROLES, FF_ENTERPRISE_ROLES, combinedRules])

  const permissions = useMemo(
    () => getPermissions(permissionRules, (user?.role as UserRole) ?? null, flags),
    [permissionRules, user, flags],
  )

  const hasPermission = useMemo(
    () =>
      (permission: Permission): boolean => {
        return permissions.includes(permission)
      },
    [permissions],
  )

  return { permissions, hasPermission }
}

export { usePermissions }
