import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Breadcrumbs, DisplayLarge, Paragraph } from '@extend/zen'
import { useParams } from 'react-router-dom'
import { WholesalePricingTable } from '../wholesale-pricing-table'

const WholesalePricing: FC = () => {
  const { storeId, planId } = useParams<{ storeId: string; planId: string }>()

  return (
    <>
      <Breadcrumbs
        crumbs={[
          {
            text: 'Stores',
            to: '/admin/stores',
          },
          {
            text: 'Plan & Pricing',
            to: `/admin/stores/${storeId}/product-protection`,
          },
          {
            text: 'Wholesale Pricing',
          },
        ]}
        data-cy="wholesale-pricing-breadcrumbs"
      />
      <DisplayLargeWithPadding data-cy="wholesale-pricing-heading">
        Wholesale Pricing
      </DisplayLargeWithPadding>
      <Paragraph data-cy="wholesale-plan-id">
        Plan ID:{' '}
        <a href={`/admin/plans/${planId}`} target="_blank" rel="noreferrer">
          {planId}
        </a>
      </Paragraph>
      <WholesalePricingTable storeId={storeId} planId={planId} />
    </>
  )
}

const DisplayLargeWithPadding = styled(DisplayLarge)({
  padding: '24px 0 8px',
})

export { WholesalePricing }
