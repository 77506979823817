import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@helloextend/merchants-ui'
import { BasicModal } from '../basic-modal'

type PlansImportModalProps = {
  handleCancel: () => void
  handleSkip: () => void
  handleConfirmAll: () => void
  handleConfirm: () => void
  planId: string
  planVersion: string
  filename: string
  isVisible: boolean
  totalModals: number
  currentIndex: number
}

const PlansImportModal: FC<PlansImportModalProps> = ({
  handleCancel,
  handleSkip,
  handleConfirmAll,
  handleConfirm,
  planId,
  planVersion,
  filename,
  isVisible,
  totalModals,
  currentIndex,
}) => (
  <BasicModal width="650px" onClickClose={handleCancel} isVisible={isVisible}>
    <Title>Plan updates</Title>
    <Position>{`${currentIndex} of ${totalModals}`}</Position>
    <ModalInfo>
      <p>
        File: <strong>{filename}</strong>
      </p>
      <p>
        Plan Id: <strong>{planId}</strong>
      </p>
      <p>
        Current Version: <strong>{planVersion}</strong>
      </p>
    </ModalInfo>
    <div>
      The above plan will be replaced with version <strong>{planVersion + 1}</strong>, and this may
      affect existing offers, do you want to proceed?
    </div>
    <ButtonContainer>
      <LeftButtonGroup>
        <Button text="Cancel" kind="dangerSecondary" size="sm" onClick={handleCancel} />
      </LeftButtonGroup>
      <RightButtonGroup>
        <Button text="Skip" kind="secondary" size="sm" onClick={handleSkip} />
        <Button text="Confirm" kind="secondary" size="sm" onClick={handleConfirm} />
        <Button text="Confirm All" kind="primary" size="sm" onClick={handleConfirmAll} />
      </RightButtonGroup>
    </ButtonContainer>
  </BasicModal>
)

const Title = styled.h3({
  fontSize: 20,
  marginBottom: 8,
})
const Position = styled.h4({
  fontSize: 16,
  marginBottom: 16,
})
const ButtonContainer = styled.div({
  display: 'flex',
})
const ModalInfo = styled.div({
  lineHeight: 1,
})
const RightButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: 24,
})
const LeftButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: 16,
  marginTop: 24,
  width: '35%',
})

export type { PlansImportModalProps }
export { PlansImportModal }
