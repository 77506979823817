import type { FC } from 'react'
import React from 'react'
import type { FormikHandlers } from 'formik'
import { Input, InputType } from '@extend/zen'

type RevenueShareInputProps = {
  value: number | null
  isError?: boolean
  errorFeedback?: string
  onChange: FormikHandlers['handleChange']
  onBlur?: FormikHandlers['handleBlur']
}

const RevenueShareInput: FC<RevenueShareInputProps> = ({
  value,
  isError,
  errorFeedback,
  onChange,
  onBlur,
}) => {
  return (
    <Input
      id="merchantSpRevenueSharePercentage"
      value={value?.toString() ?? ''}
      onChange={onChange}
      onBlur={onBlur}
      label="Revenue Share"
      placeholder="Enter percentage"
      suffix="%"
      type={InputType.number}
      isError={isError}
      errorFeedback={errorFeedback}
      data-cy="merchant-sp-revenue-share"
    />
  )
}

export { RevenueShareInput }
