import styled from '@emotion/styled'
import type { User, UserGrant } from '@helloextend/extend-api-rtk-query'
import {
  useCreateUserGrantMutation,
  useRevokeUserGrantMutation,
} from '@helloextend/extend-api-rtk-query'
import { COLOR, Stack, Switch, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../../../constants/ld-flags'
import { isAccountAdmin } from '../../../../../lib/tenancy-ern'

type AdminToggleProps = {
  accountId: string
  user: User
  userGrants: UserGrant[]
  isLoading?: boolean
  isdisabled?: boolean
}

const AdminToggle: FC<AdminToggleProps> = ({
  accountId,
  user,
  userGrants,
  isLoading,
  isdisabled,
}) => {
  const { toast } = useToaster()
  const {
    [LDFlag.EnterpriseRoles]: FF_ENTERPRISE_ROLES,
    [LDFlag.LegacyAndEnterpriseRoles]: FF_LEGACY_ENTERPRISE_ROLES,
  } = useFlags()

  const [addGrant, { isLoading: isAddingGrant, isError: errorAddingGrant }] =
    useCreateUserGrantMutation()
  const [removeGrant, { isLoading: isRemovingGrant, isError: errorRemovingGrant }] =
    useRevokeUserGrantMutation()

  const handleChange = (add: boolean, userEmail: string): void => {
    if (add) {
      addGrant({
        userId: userEmail,
        ern: `ERN:ACC:${accountId}`,
        role: FF_ENTERPRISE_ROLES || FF_LEGACY_ENTERPRISE_ROLES ? 'MerchantPortalAdmin' : 'user',
      })
    } else {
      removeGrant({
        userId: userEmail,
        ern: `ERN:ACC:${accountId}`,
        role: FF_ENTERPRISE_ROLES || FF_LEGACY_ENTERPRISE_ROLES ? 'MerchantPortalAdmin' : 'user',
      })
    }
  }

  const isAdmin = Boolean(
    userGrants &&
      isAccountAdmin(userGrants, accountId, FF_ENTERPRISE_ROLES || FF_LEGACY_ENTERPRISE_ROLES),
  )
  const isLoadingGrantsChange = isAddingGrant || isRemovingGrant

  useEffect(() => {
    if (errorAddingGrant || errorRemovingGrant) {
      toast({
        message: 'Unable to add or revoke grant',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [errorAddingGrant, errorRemovingGrant, toast])

  return (
    <Stack>
      <Stack isRow doesWrap>
        <DetailName>Merchant Admin:</DetailName>
        <DetailValue>
          <HeaderContainer>
            <Switch
              id="merchant-admin-switch"
              isOn={isAdmin}
              data-cy="merchant-admin-switch"
              onChange={() => handleChange(!isAdmin, user.email)}
              isDisabled={isLoadingGrantsChange || isdisabled}
              isProcessing={isLoadingGrantsChange || isLoading}
            />
          </HeaderContainer>
        </DetailValue>
      </Stack>
    </Stack>
  )
}

const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const DetailName = styled.span({
  fontSize: 16,
  fontWeight: 'bold',
  color: COLOR.NEUTRAL[600],
  marginRight: 4,
  width: 135,
})

const DetailValue = styled.span({
  marginBottom: 32,
})

export default AdminToggle
