import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { Store } from '@helloextend/extend-api-client'
import {
  useGetMerchantServicingSettingsQuery,
  useGetServicersBySellerIdQuery,
} from '@helloextend/extend-api-rtk-query'
import { Spinner } from '@extend/zen'
import { Divider } from '../../../../../../../../components/divider'
import { ReplacementFulfillment } from './replacement-fulfillment'
import { ReplacementLocation } from './replacement-location'
import { PrimaryServicerRepair } from './primary-servicer-repair'
import { PrimaryClaimAssignee } from './primary-claim-assignee'
import { claimAssigneeSchema, depotRepairSchema, onsiteRepairSchema } from './schema'
import { MidMccManagement } from './mid-management/mid-mcc-management'
import { MerchantOwnedIntake } from './merchant-owned-intake'
import { ShippingProtectionRepairsStore } from './shipping-protection-repairs-store'

type ServicingProps = {
  store: Store
}

const Servicing: FC<ServicingProps> = ({ store }) => {
  const { servicer, isFetching } = useGetServicersBySellerIdQuery(store.id, {
    selectFromResult: ({ data, ...rest }) => ({
      servicer: data?.[0],
      ...rest,
    }),
  })

  const { data: merchantServicingSettings, isLoading: isMerchantSettingsLoading } =
    useGetMerchantServicingSettingsQuery({
      sellerId: store.id,
    })

  if (isFetching || isMerchantSettingsLoading) {
    return <Spinner />
  }

  if (!servicer || !merchantServicingSettings) {
    return null
  }

  return (
    <>
      <SelectOrDividerContainer>
        <ReplacementFulfillment
          merchantServicingSettings={merchantServicingSettings}
          storePlatform={store.platform}
        />
      </SelectOrDividerContainer>
      <ReplacementLocation
        servicer={servicer}
        merchantServicingSettings={merchantServicingSettings}
      />
      <SelectOrDividerContainer>
        <Divider />
      </SelectOrDividerContainer>
      <PrimaryServicerRepair
        sellerId={store.id}
        serviceType="repair_onsite"
        schema={onsiteRepairSchema}
        initialValues={{
          primaryOnsiteServicerId: merchantServicingSettings?.primaryOnsiteServicerId ?? '',
          autoAssignOnsiteServicer: merchantServicingSettings?.autoAssignOnsiteServicer ?? false,
        }}
        checkboxProperty="autoAssignOnsiteServicer"
        dropdownServicerIdProperty="primaryOnsiteServicerId"
        repairTypeName="Onsite"
        dataCy="primary-onsite-repair"
      />
      <SelectOrDividerContainer>
        <Divider />
      </SelectOrDividerContainer>
      <PrimaryServicerRepair
        sellerId={store.id}
        serviceType="repair_depot"
        schema={depotRepairSchema}
        initialValues={{
          primaryDepotRepairServicerId:
            merchantServicingSettings?.primaryDepotRepairServicerId ?? '',
          autoAssignDepotRepairServicer:
            merchantServicingSettings?.autoAssignDepotRepairServicer ?? false,
        }}
        checkboxProperty="autoAssignDepotRepairServicer"
        dropdownServicerIdProperty="primaryDepotRepairServicerId"
        repairTypeName="Depot"
        dataCy="primary-depot-repair"
      />
      <SelectOrDividerContainer>
        <Divider />
      </SelectOrDividerContainer>
      <PrimaryClaimAssignee
        sellerId={store.id}
        schema={claimAssigneeSchema}
        initialValues={{
          primaryUserId: merchantServicingSettings?.primaryUserId ?? '',
          autoAssignUser: merchantServicingSettings?.autoAssignUser ?? false,
        }}
        checkboxProperty="autoAssignUser"
        dropdownUserIdProperty="primaryUserId"
        dataCy="primary-claim-assignee"
      />
      <SelectOrDividerContainer>
        <Divider />
      </SelectOrDividerContainer>
      <MidMccManagement
        settings={merchantServicingSettings}
        storeName={store.name ?? 'Store'}
        isMidManagement
      />
      <SelectOrDividerContainer>
        <Divider />
      </SelectOrDividerContainer>
      <MidMccManagement
        settings={merchantServicingSettings}
        storeName={store.name ?? 'Store'}
        isMidManagement={false}
      />
      <SelectOrDividerContainer>
        <Divider />
      </SelectOrDividerContainer>
      <MerchantOwnedIntake merchantServicingSettings={merchantServicingSettings} />
      <SelectOrDividerContainer>
        <Divider />
      </SelectOrDividerContainer>
      <ShippingProtectionRepairsStore merchantServicingSettings={merchantServicingSettings} />
    </>
  )
}

const SelectOrDividerContainer = styled.div({
  marginBottom: 24,
})

export { Servicing }
