import { faker } from '@faker-js/faker/locale/en'
import type { Consumer } from '@helloextend/extend-api-client'

export function generateConsumer(overrides: any = {}): Consumer {
  const createdAt = faker.date.past().getTime()

  return {
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    email: faker.internet.exampleEmail(),
    phoneNumber: '(786) 627-4949',
    address: {
      address1: faker.address.streetAddress(),
      address2: '',
      city: faker.address.city(),
      postalCode: faker.address.zipCode(),
      provinceCode: faker.address.stateAbbr(),
    },
    createdAt,
    uuid: faker.datatype.uuid(),
    verificationStatus: 'CONFIRMED',
    modifiedAt: createdAt,
    ...overrides,
  }
}
