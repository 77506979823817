import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'

import { useSelector, useDispatch } from 'react-redux'
import type { RuleStatus } from '@helloextend/extend-api-rtk-query'
import type { AdvancedSelectChangeEvent, AdvancedSelectOptionGroup } from '@extend/zen'
import { AdvancedSelect } from '@extend/zen'
import { updateConditionType } from '../../../../../../store/slices/amp-slice'
import type { RootState } from '../../../../../../reducers'
import * as selectors from '../../../../../../reducers/selectors'
import { getValueFromCondition } from '../../../utils'
import { getAdvancedSelectOptionGroupsForKeySelector } from '../../../utils-ui'

type KeySelectorProps = {
  source: 'conversation' | 'thread'
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
  isDisabled?: boolean
}

const KeySelector: FC<KeySelectorProps> = ({
  source,
  rulesetType,
  ruleIndex,
  conditionIndex,
  isDisabled = false,
}) => {
  const dispatch = useDispatch()
  const threadOptions = useSelector((state: RootState) => selectors.getSelectedThread(state))
  const condition = useSelector((state: RootState) =>
    selectors.getCondition({ state, rulesetType, ruleIndex, conditionIndex }),
  )

  const options = useSelector((state: RootState) =>
    selectors.getAvailableMessageBlocksForRule({
      state,
      source,
      rulesetType,
      ruleIndex,
      conditionIndex,
    }),
  )

  if (!options) return null

  const selectorValue = getValueFromCondition({ condition, thread: threadOptions })

  const choices: AdvancedSelectOptionGroup[] = getAdvancedSelectOptionGroupsForKeySelector({
    scriptObjects: options,
    currentSelection: selectorValue !== '' ? Number(selectorValue) : undefined,
  })

  const handleChange = (e: AdvancedSelectChangeEvent): void => {
    const { value } = e.target
    dispatch(
      updateConditionType({
        rulesetType,
        ruleIndex,
        conditionIndex,
        value,
      }),
    )
  }

  return (
    <Wrapper>
      <AdvancedSelect
        id="claim-validation-key-selector"
        onChange={handleChange}
        options={choices}
        value={selectorValue}
        multiple={false}
        badgePosition="start"
        data-cy="claim-validation-key-selector"
        isDisabled={isDisabled}
        isNotClearable
        hideSelectedMenuItems
      />
    </Wrapper>
  )
}

const Wrapper = styled.div({
  flexBasis: '100%',
  maxWidth: 232,
})

export { KeySelector }
