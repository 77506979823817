import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/extend-api-fetch'
import { customLogger } from '@extend/client-helpers'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.changePassword>

export function* changePassword(action: Action): SagaIterator {
  try {
    const { password, oldPassword, accessToken } = action.payload
    yield put(actions.changePasswordRequest())

    const res = yield call(client.auth.changePassword, { password, oldPassword }, accessToken)

    if (isErrorResponse(res)) {
      yield put(actions.changePasswordFailure(res.data.message, res.status))
    } else {
      yield put(actions.changePasswordSuccess('Password changed'))
    }
  } catch (e) {
    if (e instanceof Error) {
      customLogger.error(e.message)
    }
  }
}
