import { useGetPlansByIdsQuery, useGetProductQuery } from '@helloextend/extend-api-rtk-query'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import { COLOR, Grid, Spinner, Paragraph } from '@extend/zen'
import styled from '@emotion/styled'
import type { Money, ProductsSearchItem } from '@helloextend/extend-api-client'
import { Link } from 'react-router-dom'
import { calculatePlanPrice } from '../../product-details/components/product-plans-table/product-plans-table-config'

type ProductRowDetailsProps = {
  product: ProductsSearchItem
  storeId: string
}

const ProductRowDetails: FC<ProductRowDetailsProps> = ({ product, storeId }) => {
  const { data: productData, isLoading } = useGetProductQuery({
    storeId,
    referenceId: product.referenceId,
  })

  const { data: plansData, isLoading: isPlansDataLoading } = useGetPlansByIdsQuery(
    productData?.plans || [],
    {
      skip: !productData?.plans?.length,
    },
  )

  const plansInfo = useMemo(
    () =>
      plansData?.map((plan) => ({
        id: plan.id,
        price: calculatePlanPrice(plan?.matching, productData?.price as Money),
      })) || [],
    [plansData, productData?.price],
  )

  const isRowLoading = isLoading || isPlansDataLoading

  if (isRowLoading)
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )

  if (!plansInfo?.length && !isRowLoading) {
    return (
      <Container>
        <ParagraphWrapper data-cy="no-plans-empty-message">
          No plans mapped to this product.
        </ParagraphWrapper>
      </Container>
    )
  }

  return (
    <Container>
      <Grid columns={3}>
        <div data-cy="mapped-plans-column">
          <BoldParagraph>Mapped Plan</BoldParagraph>
          <List>
            {plansInfo.map((plan) => (
              <ListItem key={`plan-${plan.id}`} data-cy={`plan-${plan.id}`}>
                <Link to={`/admin/plans/${plan.id}`} target="_blank" rel="noopener noreferrer">
                  {plan.id}
                </Link>
              </ListItem>
            ))}
          </List>
        </div>
        {/* TODO PUPS-1533: uncomment when price calculation logic is added */}
        {/* <div data-cy="plan-prices-column">
          <BoldParagraph>Plan Price</BoldParagraph>
          <List>
            {plansInfo.map(({ id, price }) => (
              <ListItem key={`price-${id}`} data-cy={`price-${id}`}>
                {price}
                {price !== 'N/A' ? ' USD' : ''}
              </ListItem>
            ))}
          </List>
        </div> */}
      </Grid>
    </Container>
  )
}

const Container = styled.div({
  marginLeft: 170,
})

const BoldParagraph = styled(Paragraph)({
  fontWeight: 700,
  fontSize: 14,
})

const ParagraphWrapper = styled(Paragraph)({
  fontSize: 14,
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const List = styled.ul({
  listStyle: 'none',
  padding: 0,
  margin: 0,
})

const ListItem = styled.li({
  marginTop: 16,
})

export { ProductRowDetails }
