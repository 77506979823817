import type { UserGrant } from '@helloextend/extend-api-rtk-query'
import type { FC } from 'react'
import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { getUserRolesFromGrants } from '../../utils/user-role-mapper'
import { LDFlag } from '../../constants/ld-flags'

type UserRoleProps = {
  grants: UserGrant[]
  roleFilter: 'internal' | 'merchant'
  accountId?: string
}

const UserRole: FC<UserRoleProps> = ({ grants, roleFilter, accountId }) => {
  const {
    [LDFlag.EnterpriseRoles]: FF_ENTERPRISE_ROLES,
    [LDFlag.LegacyAndEnterpriseRoles]: FF_LEGACY_AND_ENTERPRISE_ROLES,
  } = useFlags()
  return (
    <>
      <div data-cy="user-roles">
        {concatRoles({
          grants,
          roleFilter,
          accountId,
          includeEnterpriseRoles: FF_ENTERPRISE_ROLES || FF_LEGACY_AND_ENTERPRISE_ROLES,
        })}
      </div>
    </>
  )
}

const concatRoles = ({
  grants,
  roleFilter,
  accountId,
  includeEnterpriseRoles = false,
}: {
  grants: UserGrant[]
  roleFilter: 'internal' | 'merchant'
  includeEnterpriseRoles: boolean
  accountId?: string
}): string => {
  const rolesFromGrants = getUserRolesFromGrants({
    grants,
    roleFilter,
    accountId,
    includeEnterpriseRoles,
  })

  if (rolesFromGrants.length === 0) return ''

  if (rolesFromGrants.length > 2) {
    return `${rolesFromGrants
      .map((role) => role.display)
      .slice(0, 2)
      .join(', ')} + ${rolesFromGrants.length - 2} more`
  }

  return rolesFromGrants.map((role) => role.display).join(', ')
}

export default UserRole
