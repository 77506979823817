import type { FC } from 'react'
import React from 'react'
import type { BasicPaginationProps } from './basic-pagination'
import { BasicPagination } from './basic-pagination'
import type { EnhancedPaginationProps } from './enhanced-pagination'
import { EnhancedPagination } from './enhanced-pagination'
import type { DeterminatePaginationProps } from './determinate-pagination'
import { DeterminatePagination } from './determinate-pagination'
import { PaginationType } from './types'

type PaginationProps = BasicPaginationProps | EnhancedPaginationProps | DeterminatePaginationProps

/**
 * @deprecated Instead of using this separate `<Pagination />` component, use the pagination features that exist within
 * the Zen DataTable.
 */
const Pagination: FC<PaginationProps> = (props) => {
  switch (props.paginationType) {
    case PaginationType.DETERMINATE_SERVER_SIDE:
      return <DeterminatePagination {...props} />
    case PaginationType.ENHANCED:
    case PaginationType.ENHANCED_SERVER_SIDE:
      return <EnhancedPagination {...props} />
    default:
      return <BasicPagination {...props} />
  }
}

export type { PaginationProps }
export { Pagination }
