import styled from '@emotion/styled'
import { Button, Notes } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import type { ContractNote } from '@helloextend/extend-api-client'

interface ContractNotesButtonProps {
  contractNotes: ContractNote[]
  toggle: () => void
}

const ContractNotesButton: FC<ContractNotesButtonProps> = ({ contractNotes, toggle }) => {
  const buttonText = 'Contract Notes' + (contractNotes?.length ? ` (${contractNotes.length})` : '')
  return (
    <ButtonContainer>
      <Button
        onClick={toggle}
        text={buttonText}
        color="neutral"
        emphasis="high"
        icon={Notes}
        borderRadius="24px"
        data-cy="contract-notes-button"
      />
    </ButtonContainer>
  )
}

const ButtonContainer = styled.a({
  position: 'fixed',
  bottom: '40px',
  right: '40px',
  zIndex: 1000,
})

export { ContractNotesButton }
