import type { FC } from 'react'
import React, { useState } from 'react'
import type { AdvancedSelectChangeEvent } from '@extend/zen'
import { Trash, Button, AdvancedSelect } from '@extend/zen'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import type { ScriptItem, DefaultReply, Reply } from '@helloextend/extend-api-rtk-query'
import { replaceMessageBlockInThread } from '../../../../../store/slices/amp-slice'
import { MessageBubble } from '../message-bubble'
import {
  MessageBlockTypes,
  isKaleyConditionBlockType,
  KaleyConditionTypes,
  DynamicPickerTypes,
  isDynamicPickerBlockType,
} from '../../utils'
import { getEmptyBlockByType } from '../../message-blocks/utils'
import type { RootState } from '../../../../../reducers'
import { getSelectedThreadMessageBlock } from '../../../../../reducers/selectors'
import { ConfirmationModal } from '../../../../../components/confirmation-modal'
import { getDynamicPickerByType } from '../../dynamic-picker/utils'
import { getConditionByType } from '../../kaley-conditions/utils'

type BlockTypeDropdownProps = {
  index: string
  value: string
  isDisabled?: boolean
  handleDeleteButtonClick: () => void
  isThreadDirty?: boolean
}

const BlockTypeDropdown: FC<BlockTypeDropdownProps> = ({
  index,
  value,
  isDisabled = false,
  handleDeleteButtonClick,
  isThreadDirty = false,
}) => {
  const dispatch = useDispatch()

  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false)
  const [newBlockType, setNewBlockType] = useState<
    MessageBlockTypes | KaleyConditionTypes | DynamicPickerTypes
  >()

  const selectedBlock = useSelector((state: RootState) => getSelectedThreadMessageBlock(state))

  const options = [
    {
      label: 'Message Block',
      key: 'message-block',
      options: Object.values(MessageBlockTypes).map((blockType) => ({
        display: blockType,
        value: blockType,
        key: blockType,
      })),
    },
    {
      label: 'Kaley Condition',
      key: 'kaley-condition',
      options: Object.values(KaleyConditionTypes).map((blockType) => ({
        display: blockType,
        value: blockType,
        key: blockType,
      })),
    },
    {
      label: 'Dynamic Picker',
      key: 'dynamic-picker',
      options: Object.values(DynamicPickerTypes).map((blockType) => ({
        display: blockType,
        value: blockType,
        key: blockType,
      })),
    },
  ]

  // onchange handler for advanced select
  const handleBlockTypeChange = (changeEvent: AdvancedSelectChangeEvent): void => {
    // check if the selected message block is the same as the new message block type
    if (changeEvent.target.value === value) return
    setNewBlockType(
      changeEvent.target.value as MessageBlockTypes | KaleyConditionTypes | DynamicPickerTypes,
    )
    setConfirmationModalVisible(true)
  }

  const handleConfirmBlockTypeUpdate = async (): Promise<void> => {
    setConfirmationModalVisible(false)

    let block: ScriptItem
    const isKaleyConditionBlock = isKaleyConditionBlockType(newBlockType)
    const isDynamicPickerBlock = isDynamicPickerBlockType(newBlockType)

    if (isKaleyConditionBlock) {
      block = getConditionByType(newBlockType as KaleyConditionTypes) as ScriptItem
    } else if (isDynamicPickerBlock) {
      block = getDynamicPickerByType(newBlockType as DynamicPickerTypes) as ScriptItem
    } else {
      block = getEmptyBlockByType(newBlockType as MessageBlockTypes) as ScriptItem
      // update new block with existing selected message block data
      const selectedBlockReply: Reply | undefined = selectedBlock?.script.reply
      if ((selectedBlockReply as DefaultReply)?.messages) {
        block.reply = {
          ...block.reply,
          messages: (selectedBlockReply as DefaultReply).messages,
        }
      }
    }

    // replace message block in thread
    dispatch(replaceMessageBlockInThread(block as ScriptItem))
  }

  const handleCancelMessageTypeUpdate = (): void => {
    setConfirmationModalVisible(false)
  }

  // isDisabled is true if the thread is not draft, so this disables delete when a published thread is dirty
  const isDeleteButtonDisabled = isThreadDirty && isDisabled

  return (
    <Container>
      <ConfirmationModal
        isVisible={isConfirmationModalVisible}
        onCancel={handleCancelMessageTypeUpdate}
        onConfirm={handleConfirmBlockTypeUpdate}
        confirmButtonColor="blue"
        confirmButtonText="Update"
        heading="Update block type?"
        description="This can not be undone and some content might be lost. Are you sure you want to update
        block type?"
        isProcessing={false}
        data-cy="amp-update-block-type-modal"
        size="sm"
      />
      <MessageBubble isActive data-cy="block-type-index">
        {index}
      </MessageBubble>
      <DropdownWrapper>
        <AdvancedSelect
          data-cy="block-type-dropdown"
          id="selectorValue"
          value={value}
          onChange={handleBlockTypeChange}
          multiple={false}
          options={options}
          maxQuantityToDisplay={8}
          badgePosition="start"
          isDisabled={isDisabled}
          isNotClearable
        />
      </DropdownWrapper>
      <Button
        data-cy="block-type-delete-button"
        emphasis="low"
        color="neutral"
        icon={Trash}
        isDisabled={isDeleteButtonDisabled}
        tooltip={
          isDeleteButtonDisabled
            ? 'Changes must be saved before message block can be removed'
            : undefined
        }
        onClick={handleDeleteButtonClick}
      />
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '32px 0',
  gap: 20,
})

const DropdownWrapper = styled.div({
  flex: 1,
})

export { BlockTypeDropdown }
