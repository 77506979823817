import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/extend-api-fetch'
import { actions } from '../actions'

type UserUpdateAction = ReturnType<typeof actions.update>

export default function* update(action: UserUpdateAction): SagaIterator {
  const { userId, accessToken, data } = action.payload

  yield put(actions.updateRequest())

  try {
    const res = yield call(client.users.updateUser, userId, accessToken, data)

    if (isErrorResponse(res)) {
      yield put(actions.updateFailure(res.data.message, res.status))
      return
    }

    yield put(actions.updateSuccess(data))
  } catch (e) {
    if (e instanceof Error) {
      yield put(actions.updateFailure(e.message))
    }
  }
}
