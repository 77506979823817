import type { FC, ChangeEvent } from 'react'
import React, { useCallback, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { COLOR, MenuCheckbox, Input, Search as SearchIcon } from '@extend/zen'
import { debounce } from 'lodash'
import type { CheckboxFilterValues, CheckboxFilterOptions } from './types'

export type CheckboxFilterProps = {
  onFilterChange: (property: string, values: CheckboxFilterValues | null) => void
  property: string
  values?: CheckboxFilterValues
  options: Record<string, string>
  includeSearchBar?: boolean
}

const CheckboxFilter: FC<CheckboxFilterProps> = ({
  property,
  options,
  values = {
    type: 'checkbox',
    values: [],
  },
  onFilterChange,
  includeSearchBar = false,
}) => {
  const [search, setSearch] = useState('')
  const [filteredOptions, setFilteredOptions] = useState<CheckboxFilterOptions['options']>(options)

  useEffect(
    () =>
      debounce(() => {
        if (search === '') {
          setFilteredOptions(options)
        }

        const filtered: CheckboxFilterOptions['options'] = Object.entries(options).reduce(
          (acc, [key, value]) => {
            if (key.toLowerCase().includes(search)) {
              acc[key as string] = value
            }
            return acc
          },
          {} as CheckboxFilterOptions['options'],
        )
        setFilteredOptions(filtered)
      }, 100)(),
    [setFilteredOptions, search, options],
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      let selectedValues = values.values

      const { value, checked } = e.target

      if (!checked) {
        selectedValues = selectedValues.filter((v) => v !== value)
      } else if (!selectedValues.includes(value)) {
        selectedValues = [...selectedValues, value]
      }

      onFilterChange(
        property,
        selectedValues.length > 0
          ? {
              type: 'checkbox',
              values: selectedValues,
            }
          : null,
      )
    },
    [property, values, onFilterChange],
  )

  if (!Object.keys(options).length) {
    return <NoOptionsWrapper>No options</NoOptionsWrapper>
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.currentTarget.value.toLowerCase())
  }

  return (
    <>
      {includeSearchBar && (
        <InputWrapper>
          <Input
            data-cy="checkbox-search"
            id="checkbox-search"
            value={search}
            icon={SearchIcon}
            placeholder="Search"
            onChange={handleSearchChange}
          />
        </InputWrapper>
      )}
      {Object.entries(filteredOptions).map(([value, label]) => (
        <CheckboxWrapper key={value}>
          <MenuCheckbox
            data-cy={value}
            checked={values.values.includes(value, 0) ?? false}
            onChange={handleChange}
            value={value}
          >
            {label}
          </MenuCheckbox>
        </CheckboxWrapper>
      ))}
    </>
  )
}
const CheckboxWrapper = styled.div({
  span: {
    paddingTop: 2,
    paddingBottom: 2,
  },
})

const NoOptionsWrapper = styled.div({
  padding: '8px 16px',
  color: COLOR.NEUTRAL[600],
})

const InputWrapper = styled.div({
  width: 'calc(100% - 20px)',
  borderBottom: `solid 1px ${COLOR.NEUTRAL[400]}`,
  padding: 10,
})

export { CheckboxFilter }
