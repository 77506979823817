import { useGetShippingProtectionPlanVersionsQuery } from '@helloextend/extend-api-rtk-query'
import type { DataTableAction } from '@extend/zen'
import { DataTable, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import type { ShippingProtectionPlan } from '@helloextend/extend-api-rtk-query/src/plans/types'
import { useHistory } from 'react-router-dom'
import { columns } from './sp-plan-versions-table-config'

type SpPlanVersionsProps = {
  planId: string
}

const SpPlanVersions: FC<SpPlanVersionsProps> = ({ planId }) => {
  const { toast } = useToaster()
  const history = useHistory()
  const {
    data: spPlanVersions,
    isLoading: isGettingPlanVersions,
    isError: isErrorGettingPlanVersions,
  } = useGetShippingProtectionPlanVersionsQuery(planId)

  const getRowActions = (row: ShippingProtectionPlan): DataTableAction[] => [
    {
      onClick: () =>
        history.push(`/admin/plans/shipping-protection/${row.id}/version/${row.version}`),
      text: 'View',
      emphasis: 'low',
    },
  ]

  if (isErrorGettingPlanVersions) {
    toast({
      message: 'Error getting plan versions',
      toastDuration: ToastDuration.short,
      toastColor: ToastColor.red,
    })
  }

  const sortedVersions = useMemo(() => {
    return spPlanVersions?.items.slice().sort((a, b) => b.version - a.version) ?? []
  }, [spPlanVersions?.items])

  return (
    <DataTable
      data={sortedVersions}
      columns={columns}
      isLoading={isGettingPlanVersions}
      isError={isErrorGettingPlanVersions}
      getRowActions={getRowActions}
      data-cy="sp-plan-versions-table"
    />
  )
}

export { SpPlanVersions }
