import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Badge, Breadcrumbs, COLOR, Spinner } from '@extend/zen'
import { Formik } from 'formik'
import { useGetInsuranceProgramQuery } from '@helloextend/extend-api-rtk-query'
import { useHistory, useParams } from 'react-router-dom'
import { insuranceProgramFormInitialValues } from '../../../utils/insurance-program-property-mapper'
import { InsuranceProgramForm } from './insurance-program-form'
import { schema } from './schema'
import { TabMenu } from '../../../components/tab-menu/tab-menu'
import { InsuranceProgramVersions } from '../insurance-program-versions'

enum InsuranceProgramsTab {
  ProgramDetails = 'programDetails',
  VersionHistory = 'versionHistory',
}

const InsuranceProgram: FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { data, isLoading, isError } = useGetInsuranceProgramQuery(id)

  const [activeTab, setActiveTab] = useState<InsuranceProgramsTab>(
    history.location.pathname.indexOf('/versions') > 0
      ? InsuranceProgramsTab.VersionHistory
      : InsuranceProgramsTab.ProgramDetails,
  )

  const handleTabClick = (tab: string): void => {
    setActiveTab(tab as InsuranceProgramsTab)
    const insuranceProgramPath = `/admin/insurance-programs/${id}`
    history.push(
      tab === InsuranceProgramsTab.ProgramDetails
        ? insuranceProgramPath
        : `${insuranceProgramPath}/versions`,
    )
  }
  const isInsuranceProgramActive = useMemo(() => {
    const { activateFrom = 0, activateTo } = data || {}

    const now = new Date().getTime()
    // activeTo can be unset, in that case status is based on activateFrom date only
    if (!activateTo && activateFrom <= now) {
      return true
    }

    return activateTo && activateFrom <= now && now <= activateTo
  }, [data])

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  if (isError) {
    history.push('/404')
    return null
  }

  if (!data) {
    return null
  }
  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs
          crumbs={[{ text: 'Insurance Programs', to: '/admin/insurance-programs' }, { text: id }]}
        />
      </BreadcrumbsWrapper>
      <TitleContainer>
        <Title data-cy="insurance-program-title">{data.id}</Title>
        <BadgeContainer>
          <Badge
            text={isInsuranceProgramActive ? 'Active' : 'Inactive'}
            color={isInsuranceProgramActive ? 'green' : 'yellow'}
          />
        </BadgeContainer>
      </TitleContainer>
      {data.version > 1 && (
        <TabMenuContainer>
          <TabMenu
            tabs={[
              { text: 'Program Details', key: InsuranceProgramsTab.ProgramDetails },
              { text: 'Version History', key: InsuranceProgramsTab.VersionHistory },
            ]}
            onClick={handleTabClick}
            activeTab={activeTab}
          />
        </TabMenuContainer>
      )}
      {activeTab === InsuranceProgramsTab.ProgramDetails && (
        // ReactContext for useFormikContext hook
        <Formik
          initialValues={insuranceProgramFormInitialValues(data)}
          validationSchema={schema}
          onSubmit={() => {}}
        >
          <InsuranceProgramForm insuranceData={data} />
        </Formik>
      )}
      {activeTab === InsuranceProgramsTab.VersionHistory && (
        <InsuranceProgramVersions insuranceProgramId={id} />
      )}
    </>
  )
}

const BreadcrumbsWrapper = styled.div({
  margin: '-8px 0 16px',
})

const TitleContainer = styled.div({
  display: 'flex',
  marginBottom: 8,
})

const Title = styled.h1({
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  margin: 0,
})

const BadgeContainer = styled.div({
  alignSelf: 'center',
  display: 'inline-flex',
  marginLeft: 16,
})

const SpinnerContainer = styled.div({
  textAlign: 'center',
})

const TabMenuContainer = styled.div({
  marginBottom: 24,
})

export { InsuranceProgram }
