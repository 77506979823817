export enum Permission {
  ManageProfile = 'MANAGE_PROFILE',

  ViewPlans = 'VIEW_PLANS',

  ManagePlans = 'MANAGE_PLANS',

  ViewPlansAttributes = 'VIEW_PLANS_ATTRIBUTES',

  ManagePlansAttributes = 'MANAGE_PLANS_ATTRIBUTES',

  ManagePlanSets = 'MANAGE_PLAN_SETS',

  ManageServicers = 'MANAGE_SERVICERS',

  ManageContracts = 'MANAGE_CONTRACTS',

  ManageOrders = 'MANAGE_ORDERS',

  ManageProducts = 'MANAGE_PRODUCTS',

  ManageCategories = 'MANAGE_CATEGORIES',

  ManageEPPDownload = 'MANAGE_EPP_DOWNLOAD',

  ManageClaims = 'MANAGE_CLAIMS',

  ManageClaimsAssignments = 'MANAGE_CLAIMS_ASSIGNMENTS',

  ManagePremiums = 'MANAGE_PREMIUMS',

  ViewPremiums = 'VIEW_PREMIUMS',

  ManageInsurancePrograms = 'MANAGE_INSURANCE_PROGRAMS',

  ManageAdjudication = 'MANAGE_ADJUDICATION',

  ViewAdjudication = 'VIEW_ADJUDICATION',

  ManageStores = 'MANAGE_STORES',

  CreateStores = 'CREATE_STORES',

  ViewOrganizations = 'VIEW_ORGANIZATIONS',

  ManageOrganizations = 'MANAGE_ORGANIZATIONS',

  ManageAccounts = 'MANAGE_ACCOUNTS',

  CreateAccounts = 'CREATE_ACCOUNTS',

  ManageMerchantUsers = 'MANAGE_MERCHANT_USERS',

  ViewMerchantUsers = 'VIEW_MERCHANT_USERS',

  ManageExtendUsers = 'MANAGE_EXTEND_USERS',

  ManageWarrantyDecisioning = 'MANAGE_WARRANTY_DECISIONING',

  ManageTerms = 'MANAGE_TERMS',

  RoleManagementView = 'ROLE_MANAGEMENT_VIEW',
}
