import type { FC } from 'react'
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import type { RuleStatus } from '@helloextend/extend-api-rtk-query/src/conversations'
import { COLOR, Paragraph } from '@extend/zen'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'
import { Condition } from './condition-component'
import { AddConditionButton } from './add-condition-button'
import { RemoveConditionButton } from './remove-condition-button'
import { useGetUniqueKeys } from '../../../../../hooks/use-get-unique-keys'

type RuleProps = {
  ruleIndex: number
  rulesetType: RuleStatus
  hasBottomBorder?: boolean
  removeRuleKey: (index: number) => void
}

const Rule: FC<RuleProps> = ({ ruleIndex, rulesetType, hasBottomBorder, removeRuleKey }) => {
  const rule = useSelector((state: RootState) =>
    selectors.getRule({ state, ruleIndex, rulesetType }),
  )

  const { keys, addKey, removeKey: removeConditionKey, generateInitialKeys } = useGetUniqueKeys()

  const isLastCondition = (conditionIndex: number): boolean => {
    if (rule?.conditions.length) {
      return conditionIndex === rule.conditions.length - 1
    }
    return false
  }

  const removeKeys = (index: number): void => {
    const isLastRuleCondition = rule?.conditions.length === 1
    if (isLastRuleCondition) {
      removeRuleKey(ruleIndex)
    }
    removeConditionKey(index)
  }

  const addConditionKey = (): void => {
    addKey(rule?.conditions.length || 0)
  }

  if (keys.length === 0 && rule?.conditions && rule.conditions.length > 0) {
    generateInitialKeys(rule.conditions.length)
    return null
  }

  return (
    <RuleWrapper
      data-cy={`claim-validation-rule-${rulesetType}-${ruleIndex}`}
      hasBottomBorder={hasBottomBorder}
    >
      {rule?.conditions.map((_, conditionIndex) => {
        return (
          <Fragment key={keys[conditionIndex]}>
            <Condition
              conditionIndex={conditionIndex}
              ruleIndex={ruleIndex}
              rulesetType={rulesetType}
            >
              <CTAWrapper>
                {isLastCondition(conditionIndex) && (
                  <AddConditionButton
                    ruleIndex={ruleIndex}
                    rulesetType={rulesetType}
                    conditionIndex={conditionIndex}
                    addConditionKey={addConditionKey}
                  />
                )}
                <RemoveConditionButton
                  rulesetType={rulesetType}
                  ruleIndex={ruleIndex}
                  conditionIndex={conditionIndex}
                  removeKeys={removeKeys}
                />
              </CTAWrapper>
            </Condition>
            {conditionIndex < rule.conditions.length - 1 && <Paragraph>and</Paragraph>}
          </Fragment>
        )
      })}
    </RuleWrapper>
  )
}

const RuleWrapper = styled.div<{ hasBottomBorder?: boolean }>(({ hasBottomBorder }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: hasBottomBorder ? `1px solid ${COLOR.NEUTRAL[300]}` : undefined,
  }
})

const CTAWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})

export { Rule }
