import styled from '@emotion/styled'
import {
  ArrowDropDown,
  Button,
  Input,
  MenuButtonItem,
  Popover,
  PopoverAlignment,
  PopoverDirection,
  Search,
  Stack,
  usePopover,
} from '@extend/zen'
import type { ContractNote } from '@helloextend/extend-api-client'
import { useUpdateContractNoteMutation } from '@helloextend/extend-api-rtk-query'
import Fuse from 'fuse.js'
import type { ChangeEvent, FC} from 'react';
import React, { useCallback, useMemo, useState } from 'react'
import type { UniqueUsersMap, UserNameAndEmail } from '../utils/get-unique-users'

interface ContractNoteOwnerCellProps {
  usersMap: UniqueUsersMap
  usersArray: UserNameAndEmail[]
  contractNote: ContractNote
}

export const ContractNoteOwnerCell: FC<ContractNoteOwnerCellProps> = ({
  usersMap,
  usersArray,
  contractNote,
}) => {
  const [filter, setFilter] = useState('')
  const [note, setNote] = useState<ContractNote>(contractNote)
  const { popoverRef, triggerRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLButtonElement>()

  const [updateContractNote] = useUpdateContractNoteMutation()

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setFilter(e.currentTarget.value)
  }

  const handleSelection = useCallback(
    async (email: string): Promise<void> => {
      toggle()
      const { data: updatedNote } = (await updateContractNote({
        contractId: note.contractId,
        noteId: note.id,
        data: {
          ...note,
          ownedBy: email,
        },
      })) as { data: ContractNote }

      setNote(updatedNote as ContractNote)
    },
    [toggle, updateContractNote, note],
  )

  const filteredUsers = useMemo(() => {
    if (!usersArray) return []
    if (filter === '') {
      return usersArray
    }

    const fuse = new Fuse(usersArray, {
      shouldSort: true,
      keys: ['name'],
      minMatchCharLength: 3,
      distance: 100,
      threshold: 0.3,
    })

    return fuse.search(filter)
  }, [usersArray, filter])

  const owner = !note.ownedBy ? 'Unassigned' : usersMap.get(note.ownedBy)?.name || note.ownedBy

  return (
    <Container data-cy="contract-note-owner-cell">
      <div data-cy="note-owner">{owner}</div>
      {!contractNote.isResolved && (
        <Button
          ref={triggerRef}
          icon={ArrowDropDown}
          color="neutral"
          emphasis="low"
          size="xsmall"
          onClick={toggle}
          data-cy="user-dropdown-button"
          isToggled={isPresent}
        />
      )}
      <Popover
        data-cy="assign-user-popover"
        ref={popoverRef}
        direction={PopoverDirection.down}
        alignment={PopoverAlignment[PopoverAlignment.end]}
        isPresent={isPresent}
        triggerBoundingBox={triggerBoundingBox}
        maxHeight={298}
        maxWidth={222}
      >
        <Stack padding={1}>
          <Input
            onChange={handleSearchInput}
            id="search"
            value={filter}
            icon={Search}
            placeholder="Search"
            data-cy="dropdown-search-input"
          />
          {filteredUsers.length ? (
            filteredUsers.map((user) => (
              <MenuButtonItem
                data-cy={`select-user-option-${user.name}`}
                key={user.name}
                onClick={() => handleSelection(user.email)}
              >
                {user.name}
              </MenuButtonItem>
            ))
          ) : (
            <MenuButtonItem data-cy="no-matches">No matches found</MenuButtonItem>
          )}
        </Stack>
      </Popover>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})
