import React, { useEffect } from 'react'
import { Button, DataTable, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { useGetShippingProtectionPlansQuery } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { columns } from './table-config/sp-plans-data-table-config'

const ShippingProtectionPlans: React.FC = () => {
  const history = useHistory()
  const { toast } = useToaster()
  const { data, isLoading, isError } = useGetShippingProtectionPlansQuery()

  useEffect(() => {
    if (isError) {
      toast({
        message: 'Unable to retrieve shipping protection plans data',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isError, toast])

  return (
    <>
      <Container>
        <CreateButton>
          <Button
            text="Create Plan"
            emphasis="medium"
            onClick={() => history.push('/admin/plans/shipping-protection/create')}
            data-cy="create-plan-button"
          />
        </CreateButton>
        <DataTable
          data={data ?? []}
          columns={columns}
          isLoading={isLoading}
          isError={isError}
          data-cy="sp-plans-table"
        />
      </Container>
    </>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const CreateButton = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '-40px',
})

export { ShippingProtectionPlans }
