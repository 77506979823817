import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import type {
  MappingStatusOptions,
  MLModelOptions,
  RulesEngineOptions,
  WDConfiguration,
  GetWDConfigurationRequest,
  CreateWDConfigurationRequest,
  UpdateWDConfigurationRequest,
  GetWDConfigurationListResponse,
  GetWDProductRequest,
  WDProduct,
  SearchWDProductsRequest,
  SearchWDProductsResponse,
  BulkValidateWDProductsRequest,
  BulkValidateProductsResponse,
} from './types'

export const warrantyDecisioningAPI = createApi({
  reducerPath: 'WarrantyDecisioningAPI',
  baseQuery,
  tagTypes: ['Configuration', 'Products'],
  endpoints: (build) => ({
    // misc routes for {{API_HOST}}/warranty-decisioning/
    getMappingStatusOptions: build.query<MappingStatusOptions, void>({
      query: () => ({
        url: `/warranty-decisioning/mapping-statuses`,
      }),
    }),
    getMLModelOptions: build.query<MLModelOptions, void>({
      query: () => ({
        url: `/warranty-decisioning/ml-models`,
      }),
    }),
    getRulesEngineOptions: build.query<RulesEngineOptions, void>({
      query: () => ({
        url: `/warranty-decisioning/rulesets`,
      }),
    }),
    // downstream routes for {{API_HOST}}/warranty-decisioning/stores/
    getWDStoresList: build.query<GetWDConfigurationListResponse, void>({
      query: () => ({
        url: `/warranty-decisioning/stores`,
      }),
    }),
    // downstream routes for {{API_HOST}}/warranty-decisioning/stores/{{storeId}}/configuration
    getWDConfiguration: build.query<WDConfiguration, GetWDConfigurationRequest>({
      query: ({ storeId }) => ({
        url: `/warranty-decisioning/stores/${storeId}/configuration`,
      }),
      providesTags: ['Configuration'],
    }),
    createWDConfiguration: build.mutation<WDConfiguration, CreateWDConfigurationRequest>({
      query: ({ storeId, ...requestBody }) => ({
        url: `/warranty-decisioning/stores/${storeId}/configuration`,
        method: 'POST',
        body: requestBody,
      }),
      invalidatesTags: ['Configuration'],
    }),
    updateWDConfiguration: build.mutation<WDConfiguration, UpdateWDConfigurationRequest>({
      query: ({ storeId, ...requestBody }) => ({
        url: `/warranty-decisioning/stores/${storeId}/configuration`,
        method: 'PUT',
        body: requestBody,
      }),
      invalidatesTags: ['Configuration'],
    }),
    // downstream routes for {{API_HOST}}/warranty-decisioning/stores/{{storeId}}/products
    getWDProduct: build.query<WDProduct, GetWDProductRequest>({
      query: ({ storeId, productId }) => ({
        url: `/warranty-decisioning/stores/${storeId}/products/${productId}`,
      }),
      providesTags: ['Products'],
    }),
    wdProductsSearch: build.query<SearchWDProductsResponse, SearchWDProductsRequest>({
      query: ({ storeId, ...reqBody }) => ({
        url: `/warranty-decisioning/stores/${storeId}/products/search`,
        method: 'POST',
        body: reqBody,
      }),
      transformResponse: (response: SearchWDProductsResponse): SearchWDProductsResponse => {
        // Reduce Response to encourage smoother frontend
        return {
          items: response.items.map((product) => ({
            productId: product.productId,
            title: product.title,
            category: product.category,
            sku: product.sku,
            description: product.description,
            price: product.price,
            planCategoryId: product.planCategoryId,
            plansArray: product.plansArray,
            mappingStatus: product.mappingStatus,
            wdUpdatedAt: product.wdUpdatedAt,
            warrantyStatus: product.warrantyStatus,
            wdUpdatedBy: product.wdUpdatedBy,
          })),
          ...(response.next ? { next: response.next } : {}),
        }
      },
      providesTags: ['Products'],
    }),
    // TODO: cahnge to version iwith items ersponse for the search
    bulkValidateWDProducts: build.mutation<
      BulkValidateProductsResponse,
      BulkValidateWDProductsRequest
    >({
      query: ({ storeId, ...reqBody }) => ({
        url: `/warranty-decisioning/stores/${storeId}/products/bulk`,
        method: 'POST',
        body: reqBody,
      }),
      invalidatesTags: ['Products'],
    }),
  }),
})

export const {
  useGetMappingStatusOptionsQuery,
  useGetMLModelOptionsQuery,
  useGetRulesEngineOptionsQuery,
  useGetWDStoresListQuery,
  useGetWDConfigurationQuery,
  useCreateWDConfigurationMutation,
  useUpdateWDConfigurationMutation,
  useGetWDProductQuery,
  useLazyWdProductsSearchQuery,
  useBulkValidateWDProductsMutation,
} = warrantyDecisioningAPI
