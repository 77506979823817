import type { Order } from '@helloextend/extend-api-client'
import type { FC } from 'react'
import React, { memo } from 'react'
import { OrderBreadcrumbs } from './order-breadcrumbs'
import { CustomerNameAndOrderStatus } from './customer-name-and-status'
import { HeaderDetails } from './header-details'

const OrderDetailsPageHeader: FC<{ order: Order }> = memo(({ order }) => {
  if (!order) return <></>

  const { customer, id, createdAt, updatedAt, status } = order

  return (
    <>
      <OrderBreadcrumbs id={id} />
      <CustomerNameAndOrderStatus name={customer.name} status={status} />
      <HeaderDetails id={id} createdAt={createdAt} updatedAt={updatedAt} />
    </>
  )
})

export { OrderDetailsPageHeader }
