import { DataProperty, Stack } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import { formatDate } from '../../utils'

const HeaderDetails: FC<{
  createdAt: number
  updatedAt: number
  contractId?: string
}> = ({ createdAt, updatedAt, contractId }) => {
  return (
    <Stack data-cy="line-item-header-details">
      {contractId && (
        <DataProperty
          data-cy="contract-id"
          label="Contract ID"
          value={contractId}
          to={`/admin/contracts/${contractId}`}
          isHorizontal
        />
      )}
      <DataProperty
        data-cy="created-date"
        label="Created Date"
        value={formatDate(createdAt)}
        isHorizontal
      />
      <DataProperty
        data-cy="updated-date"
        label="Updated Date"
        value={formatDate(updatedAt)}
        isHorizontal
      />
    </Stack>
  )
}

export { HeaderDetails }
