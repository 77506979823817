var api = require("!../../../../../../../node_modules/.pnpm/style-loader@1.3.0_webpack@4.46.0_webpack-cli@4.8.0_webpack-bundle-analyzer@4.4.2_webpack-dev_j5jbmix26kbw3hku6uezky3pqm/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0_webpack-cli@4.8.0_webpack-bundle-analyzer@4.4.2_webpack-dev-s_vrefs4evkprez4c3y7xwx6ru5q/node_modules/css-loader/dist/cjs.js??ref--7-1!./active-offer-surface.module.css");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};