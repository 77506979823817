import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import type { LineItemGetResponse } from './types'
import { lineItemsAPIVersion } from './types'

export const lineItemsApi = createApi({
  baseQuery,
  reducerPath: 'LineItems',
  tagTypes: ['LineItems'],
  endpoints: (build) => ({
    getLineItem: build.query<LineItemGetResponse, string>({
      query: (lineItemId: string) => ({
        url: `line-items/${lineItemId}`,
        headers: {
          accept: `application/json; version=${lineItemsAPIVersion};`,
        },
      }),
      providesTags: (_, _err, lineItemId) => [{ type: 'LineItems', id: lineItemId }],
    }),
  }),
})

export const { useGetLineItemQuery } = lineItemsApi
