import type { FC } from 'react'
import React from 'react'
import type { CurrencyCode, Product, WarrantyStatus } from '@helloextend/extend-api-client'
import { AdvancedSelect, CurrencyInput, Grid, Input, TextArea } from '@extend/zen'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { useUpdateProductMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { Values } from './schema'
import { schema } from './schema'
import { PRODUCT_WARRANTY_STATUS } from '../../../../../../../../../utils/products'
import { SaveChangesButtonGroup } from '../../../../settings/components/save-changes-button-group'

type ProductInfoFormProps = {
  currencyCode: string
  product: Product
}

const ProductInfoForm: FC<ProductInfoFormProps> = ({ currencyCode, product }) => {
  const { values, errors, dirty, isValid, handleChange, handleBlur, handleReset, handleSubmit } =
    useFormik<Values>({
      initialValues: {
        isBundle: product.settings?.isBundle?.toString() || 'false',
        warrantyStatus: product.warrantyStatus,
        title: product.title,
        category: product?.category || '',
        priceAmount: product?.price?.amount || null,
        imageUrl: product?.imageUrl || '',
        description: product?.description || '',
      },
      onSubmit: () => {},
      validationSchema: schema,
      enableReinitialize: true,
    })
  const { toastError, toastCompleted } = useStandardToast()

  const [updateProduct, { isLoading }] = useUpdateProductMutation()

  const handleUpdateProduct = async (): Promise<void> => {
    try {
      await updateProduct({
        version: 'latest',
        productId: product.referenceId,
        storeId: product.storeId,
        data: {
          warrantyStatus: values.warrantyStatus as WarrantyStatus,
          title: values.title,
          category: values.category,
          price: {
            amount: Number(values?.priceAmount),
            // currencyCode is not being persisted and is returned with USD for all GET requests
            // leaving this here for when that changes we will store proper value
            currencyCode: currencyCode as CurrencyCode,
          },
          imageUrl: values.imageUrl,
          description: values.description,
          settings: {
            isBundle: values.isBundle === 'true',
          },
        },
      }).unwrap()

      toastCompleted('Product Settings have been updated successfully.')
    } catch (error) {
       
      console.error(error as Error)
      toastError('Product Settings could not be updated. Please try again.')
    }
  }

  return (
    <form onSubmit={handleSubmit} data-cy="product-info-form">
      <ContainerWithMargin>
        <Grid columns={2} spacing={4}>
          <Grid columns={2} spacing={4}>
            <AdvancedSelect
              value={values.isBundle.toString()}
              id="isBundle"
              label="Product Type"
              isNotClearable
              multiple={false}
              onChange={handleChange}
              isError={Boolean(errors.isBundle)}
              errorFeedback={errors.isBundle}
              options={[
                { display: 'Single', value: 'false' },
                { display: 'Bundle', value: 'true' },
              ]}
            />
            <AdvancedSelect
              value={values.warrantyStatus}
              id="warrantyStatus"
              label="Matched Status"
              multiple={false}
              isError={Boolean(errors.warrantyStatus)}
              errorFeedback={errors.warrantyStatus}
              isNotClearable
              onChange={handleChange}
              options={[
                { display: PRODUCT_WARRANTY_STATUS.warrantable, value: 'warrantable' },
                { display: PRODUCT_WARRANTY_STATUS.pending, value: 'pending' },
                { display: PRODUCT_WARRANTY_STATUS['non-warrantable'], value: 'non-warrantable' },
              ]}
            />
          </Grid>

          <Input
            value={values.title}
            id="title"
            label="Product Name"
            placeholder="Enter product name"
            isError={Boolean(errors.title)}
            errorFeedback={errors.title}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      </ContainerWithMargin>
      <ContainerWithMargin>
        <Grid columns={4} spacing={4}>
          <Input
            value={values.category}
            id="category"
            label="Merchant Category"
            placeholder="Enter category"
            isError={Boolean(errors.category)}
            errorFeedback={errors.category}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <CurrencyInput
            value={values?.priceAmount ? values.priceAmount?.toString() : ''}
            currency={currencyCode}
            onChange={handleChange}
            onBlur={handleBlur}
            id="priceAmount"
            label="Product List Price"
            isError={Boolean(errors.priceAmount)}
            errorFeedback={errors.priceAmount}
          />
          <Input
            value={values.imageUrl}
            id="imageUrl"
            label="Image URL"
            placeholder="Enter image URL"
            isError={Boolean(errors.imageUrl)}
            errorFeedback={errors.imageUrl}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      </ContainerWithMargin>
      <ContainerWithMargin>
        <Grid columns={2} spacing={4}>
          <TextArea
            value={values.description || ''}
            id="description"
            label="Description"
            placeholder="Enter description"
            isError={Boolean(errors.description)}
            errorFeedback={errors.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      </ContainerWithMargin>

      {dirty && (
        <ButtonGroup>
          <SaveChangesButtonGroup
            id="product-info-save-buttons-group"
            isSaveDisabled={!dirty || !isValid}
            handleCancel={handleReset}
            handleSave={handleUpdateProduct}
            isLoading={isLoading}
            saveButtonText="Save Changes"
          />
        </ButtonGroup>
      )}
    </form>
  )
}

const ButtonGroup = styled.div({
  marginTop: 24,
})

const ContainerWithMargin = styled.div({
  marginBottom: 16,
})

export { ProductInfoForm }
