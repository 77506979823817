import type { FC, ChangeEvent } from 'react'
import React, { useCallback } from 'react'
import type {
  AccountLegacy,
  CreateAccountProvision as CreateAccountProvisionType,
  CreateAccountLegacyProvisionResponse,
} from '@helloextend/extend-api-rtk-query'
import type {
  PlatformType,
  CreateStoreResponse,
} from '@helloextend/extend-api-rtk-query/src/stores/types'
import {
  useLazyGetAccountsListQuery,
  useCreateLegacyAccountProvisionMutation,
  useCreateStoreMutation,
} from '@helloextend/extend-api-rtk-query'
import * as Yup from 'yup'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import debounce from 'lodash/debounce'
import {
  Input,
  InputType,
  Button,
  ButtonGroup,
  InlineAlert,
  InlineAlertColor,
  useToaster,
  ToastColor,
  ToastDuration,
  Select,
  Checkbox,
  COLOR,
} from '@extend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { LDFlag } from '../../../../../constants/ld-flags'

const platformOptions: { [key in PlatformType]: string } = {
  magento: 'Magento',
  shopify: 'Shopify',
  'big-commerce': 'Big Commerce',
  custom: 'Custom',
}

const storeUrlRegEx =
  /^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i

const validationSchema = (): Yup.ObjectSchema<
  object & {
    accountName: string
    accountOwnerFirstName: string
    accountOwnerLastName: string
    accountOwnerEmail: string
    provisionStore: boolean
    storeName: string | undefined
    storeUrl: string | undefined
    platform: PlatformType | undefined
  },
  object
> => {
  return Yup.object()
    .shape({
      accountName: Yup.string().trim().required('Please enter your account name'),
      accountOwnerFirstName: Yup.string().trim().required('Please enter your first name'),
      accountOwnerLastName: Yup.string().trim().required('Please enter you last name'),
      accountOwnerEmail: Yup.string()
        .trim()
        .email('Please enter a valid email')
        .required('Please enter an  email'),
      provisionStore: Yup.boolean().default(false),
      storeName: Yup.string()
        .trim()
        .when('provisionStore', {
          is: true,
          then: () => Yup.string().required('Please enter your store name'),
        }),
      storeUrl: Yup.string()
        .trim()
        .when('provisionStore', {
          is: true,
          then: () =>Yup.string()
            .trim()
            .matches(storeUrlRegEx, 'Store URL is invalid')
            .required('Please enter your store url'),
        }),
      platform: Yup.mixed<PlatformType>()
        .default('custom')
        .when('provisionStore', {
          is: true,
          then: () => Yup.mixed()
            .required('Please select a platform')
            .oneOf(Object.keys(platformOptions)),
        }),
    })
    .defined()
}

type AddAccountSchema = Yup.InferType<ReturnType<typeof validationSchema>>

type CreateAccountModalFormProps = {
  onClickClose: () => void
}

const CreateAccountModalForm: FC<CreateAccountModalFormProps> = ({ onClickClose }) => {
  const flags = useFlags()
  const FF_LEGACY_AND_ENTERPRISE_ROLES = flags[LDFlag.LegacyAndEnterpriseRoles]
  const FF_ENTERPRISE_ROLES = flags[LDFlag.EnterpriseRoles]
  const [createLegacyAccountProvision, { isLoading: provisioningLegacyAccount }] =
    useCreateLegacyAccountProvisionMutation()
  const [createStore, { isLoading: creatingStore }] = useCreateStoreMutation()
  const [getAccountsListContent, { data: accountsList }] = useLazyGetAccountsListQuery()
  const { toast } = useToaster()

  const {
    values,
    isValid,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik<AddAccountSchema>({
    enableReinitialize: true,
    initialValues: {
      accountName: '',
      accountOwnerFirstName: '',
      accountOwnerLastName: '',
      accountOwnerEmail: '',
      provisionStore: true,
      platform: 'custom',
    },
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async () => {
      await provisionAccount()

      onClickClose()
    },
  })

  const provisionAccount = async (): Promise<void> => {
    let storeCreateResponse: CreateStoreResponse | undefined
    const body = {
      user: {
        firstName: values.accountOwnerFirstName,
        lastName: values.accountOwnerLastName,
        email: values.accountOwnerEmail,
        role:
          FF_LEGACY_AND_ENTERPRISE_ROLES || FF_ENTERPRISE_ROLES ? 'MerchantPortalAdmin' : 'user', // if enterprise and legacy roles are disabled and we want to provision a legacy account set role = 'user' else role = 'MerchantPortalAdmin' for both legacy and enterprise account provisioning
      },
      account: {
        name: values.accountName,
        status: 'Approved',
      },
    }

    const response: CreateAccountLegacyProvisionResponse = await createLegacyAccountProvision({
      body: body as CreateAccountProvisionType,
    })

    if ('data' in response) {
      const { data } = response as { data: AccountLegacy }

      if (values.provisionStore) {
        storeCreateResponse = await createStore({
          body: {
            accountId: data.id,
            name: values.storeName as string,
            platform: values.platform || 'custom',
            domain: values.storeUrl as string,
          },
        })
      }
    }

    if ('error' in response) {
      if ((response as { error: FetchBaseQueryError }).error.status === 409) {
        toast({
          message: 'user already exists',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      } else {
        toast({
          message: 'There was an error provisioning your account. Please try again.',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      }
      return
    }

    if (storeCreateResponse && 'error' in storeCreateResponse) {
      toast({
        message: 'Account provision was successful but there was an error creating store.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })

      return
    }

    toast({
      message: 'Your Account has been provisioned successfully',
      toastColor: ToastColor.blue,
      toastDuration: ToastDuration.short,
    })
  }

  const delayedGetAccounts = useCallback(
    debounce(async (value: string) => {
      await getAccountsListContent({ name: value })
    }, 300),
    [getAccountsListContent],
  )

  const handleAccountNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    handleChange(event)
    delayedGetAccounts(event.target.value)
  }

  const hasItems = accountsList?.items && accountsList?.items.length > 0

  const resetProvisionStoreRequiredFields = useCallback(async () => {
    await setFieldValue('storeName', '')
    await setFieldValue('storeUrl', '')
  }, [setFieldValue])

  const handleCheckbox = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    await setFieldValue('provisionStore', e.target.checked)
    resetProvisionStoreRequiredFields()
  }

  const isProvisionStoreChecked = values.provisionStore

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        data-cy="account-name"
        id="accountName"
        label="Account Name"
        value={values.accountName}
        onChange={(event) => handleAccountNameChange(event)}
        onBlur={handleBlur}
        isError={!!(errors.accountName && touched.accountName)}
        errorFeedback={errors.accountName}
      />

      {hasItems && (
        <InlineAlert color={InlineAlertColor.red}>This account name already exists.</InlineAlert>
      )}

      <InputContainer>
        <Input
          data-cy="account-owner-first-name"
          id="accountOwnerFirstName"
          label="Account Owner First Name"
          value={values.accountOwnerFirstName}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={!!(errors.accountOwnerFirstName && touched.accountOwnerFirstName)}
          errorFeedback={errors.accountOwnerFirstName}
        />
        <Input
          data-cy="account-owner-last-name"
          id="accountOwnerLastName"
          label="Account Owner Last Name"
          value={values.accountOwnerLastName}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={!!(errors.accountOwnerLastName && touched.accountOwnerLastName)}
          errorFeedback={errors.accountOwnerLastName}
        />
      </InputContainer>

      <Input
        data-cy="account-owner-email"
        id="accountOwnerEmail"
        label="Account Owner Email"
        value={values.accountOwnerEmail}
        onChange={handleChange}
        onBlur={handleBlur}
        type={InputType.email}
        isError={!!(errors.accountOwnerEmail && touched.accountOwnerEmail)}
        errorFeedback={errors.accountOwnerEmail}
      />

      <CheckboxWrapper>
        <Checkbox
          data-cy="provisionStore:checkbox"
          label="Provision Store"
          name="Provision Store"
          checked={isProvisionStoreChecked}
          onChange={handleCheckbox}
        />
      </CheckboxWrapper>

      <Input
        data-cy="storename"
        id="storeName"
        label="Store Name"
        value={values.storeName || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        isError={!!(errors.storeName && touched.storeName)}
        errorFeedback={errors.storeName}
        isDisabled={!isProvisionStoreChecked}
      />

      <Input
        data-cy="storeurl"
        id="storeUrl"
        label="Store URL"
        value={values.storeUrl || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        isError={!!(errors.storeUrl && touched.storeUrl)}
        errorFeedback={errors.storeUrl}
        isDisabled={!isProvisionStoreChecked}
      />

      <Select
        data-cy="platform:select"
        id="platform"
        label="Platform"
        value={values.platform || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        isError={!!(errors.platform && touched.platform)}
        errorFeedback={errors.platform}
        isDisabled={!isProvisionStoreChecked}
      >
        {Object.keys(platformOptions).map((key: string) => {
          return (
            <option data-cy="platform-options" value={key}>
              {platformOptions[key as PlatformType]}
            </option>
          )
        })}
      </Select>

      <ButtonWrapper>
        <ButtonGroup>
          <Button emphasis="medium" data-cy="cancel-button" text="Cancel" onClick={onClickClose} />
          <Button
            data-cy="submit-button"
            emphasis="high"
            text="Create Account"
            type="submit"
            isDisabled={!isValid || hasItems}
            isProcessing={provisioningLegacyAccount || creatingStore}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </Form>
  )
}

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 24,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

const InputContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  div: {
    width: '100%',
  },
  'div:first-child': {
    paddingRight: 24,
  },
})

const CheckboxWrapper = styled.div({
  borderTop: `1px solid ${COLOR.NEUTRAL[400]}`,
  paddingTop: 15,
})

export { CreateAccountModalForm }
