import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { WalletGetResponse } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import type { Claim, ServiceOrder, Contract, Money, Expense } from '@helloextend/extend-api-client'
import { setClaimDetailsActiveView } from '../../../../../../store/slices/claim-details'
import type { RootState } from '../../../../../../reducers'
import * as selectors from '../../../../../../reducers/selectors'
import { AssignServiceOrderContent } from '../assign-service-order-content'
import { AcceptServiceOrderContent } from '../accept-service-order-content'
import { StartRepairContent } from '../start-repair-content'
import { CompleteRepairContent } from '../complete-repair-content'
import { CheckInServiceOrderContent } from '../check-in-service-order-content'
import { BasicModal } from '../../../../../../components/basic-modal'
import { ClaimDetailsTabs } from '../tab-section/types'
import { ApproveReplacementContent } from '../approve-replacement-content/approve-replacement-content'
import { ResolveServiceOrderContent } from '../resolve-service-order-content'
import { CloseClaimDropdown } from '../close-claim-dropdown'
import { ApproveClaimContent } from '../approve-claim-content'
import { CloseServiceOrderDropdown } from '../close-service-order-dropdown'
import { MarkAsShippedContent } from '../mark-as-shipped/mark-as-shipped'
import { MarkAsPaidContent } from '../mark-as-paid-content'
import { CreateServiceOrderDropdown } from '../create-service-order-dropdown'
import { CancelVirtualCardDropdown } from '../cancel-virtual-card-dropdown'
import { DenyClaimDropdown } from '../deny-claim-dropdown/deny-claim-dropdown'
import { SelectFulfillmentContent } from '../select-fulfillment-content'
import { SelectRepairFulfillmentContent } from '../select-repair-fulfillment-content'

interface ActionModalProps {
  claim: Claim
  serviceOrders?: ServiceOrder[]
  entitlements?: { coverageAmountRemaining: Money }
  contract: Contract
  expenses?: Expense[]
  wallet?: WalletGetResponse
}

const ActionModal: FC<ActionModalProps> = ({
  claim,
  serviceOrders,
  contract,
  entitlements,
  expenses,
  wallet,
}) => {
  const dispatch = useDispatch()
  const activeView = useSelector((state: RootState) => selectors.getActiveClaimDetailsView(state))
  const serviceOrder = serviceOrders && serviceOrders.length > 0 ? serviceOrders[0] : undefined

  const title = useMemo(() => {
    switch (activeView) {
      case 'approveClaimModal':
        return 'Approve Claim'
      case 'selectFulfillmentMethodModal':
        return 'Select Fulfillment Method'
      case 'selectRepairFulfillmentMethodModal':
        return 'Select Repair Service Outcome'
      case 'denyClaimModal':
        return 'Deny Claim'
      case 'closeClaimModal':
        return 'Close Claim'
      case 'assignServiceOrderModal':
        return 'Assign Service Order'
      case 'acceptServiceOrderModal':
        return 'Accept Service Order'
      case 'cancelVirtualCardModal':
        return 'Cancel Virtual Card'
      case 'checkInServiceOrderModal':
        return 'Check In'
      case 'fulfillServiceOrderModal':
        return 'Complete Repair'
      case 'startRepairServiceOrderModal':
        return 'Start Repair'
      case 'approveReplacementModal':
        return 'Approving SO for Replacement'
      case 'closeServiceOrderModal':
        return 'Close Service Order'
      case 'resolveServiceOrderModal':
        return 'Resolve Service Order'
      case 'markAsShippedModal':
        return 'Mark as Shipped'
      case 'markAsPaidModal':
        return 'Mark Service Order as Paid'
      case 'createServiceOrderModal':
        return 'Create Service Order'
      default:
        return ''
    }
  }, [activeView])

  useEffect(() => {
    return () => {
      dispatch(setClaimDetailsActiveView(ClaimDetailsTabs.ServiceOrder))
    }
  }, [dispatch])

  if (!title || !contract) {
    return null
  }

  return (
    <BasicModal
      isVisible={Boolean(activeView)}
      onClickClose={() => dispatch(setClaimDetailsActiveView(''))}
      width={activeView === 'resolveServiceOrderModal' ? '1280px' : '778px'}
      preventAutoFocus
      data-cy={activeView}
    >
      <Title>{title}</Title>
      {activeView === 'closeClaimModal' && claim && <CloseClaimDropdown claim={claim} />}
      {activeView === 'denyClaimModal' && claim && <DenyClaimDropdown claim={claim} />}
      {activeView === 'approveClaimModal' && claim && <ApproveClaimContent claim={claim} />}
      {activeView === 'assignServiceOrderModal' && serviceOrder && entitlements && (
        <AssignServiceOrderContent
          claim={claim}
          serviceOrder={serviceOrder}
          contract={contract}
          entitlements={entitlements}
        />
      )}
      {activeView === 'acceptServiceOrderModal' && serviceOrder && (
        <AcceptServiceOrderContent claim={claim} serviceOrder={serviceOrder} contract={contract} />
      )}
      {activeView === 'selectFulfillmentMethodModal' && serviceOrder && (
        <SelectFulfillmentContent claim={claim} serviceOrder={serviceOrder} contract={contract} />
      )}
      {activeView === 'selectRepairFulfillmentMethodModal' && serviceOrder && (
        <SelectRepairFulfillmentContent serviceOrder={serviceOrder} />
      )}
      {activeView === 'fulfillServiceOrderModal' && serviceOrder && entitlements && (
        <CompleteRepairContent
          claim={claim}
          serviceOrder={serviceOrder}
          contract={contract}
          entitlements={entitlements}
        />
      )}
      {activeView === 'checkInServiceOrderModal' && entitlements && serviceOrder && (
        <CheckInServiceOrderContent
          claim={claim}
          serviceOrder={serviceOrder}
          contract={contract}
          entitlements={entitlements}
        />
      )}
      {activeView === 'markAsShippedModal' && serviceOrder && (
        <MarkAsShippedContent serviceOrder={serviceOrder} />
      )}
      {activeView === 'startRepairServiceOrderModal' && serviceOrder && entitlements && (
        <StartRepairContent
          claim={claim}
          serviceOrder={serviceOrder}
          contract={contract}
          entitlements={entitlements}
        />
      )}
      {activeView === 'approveReplacementModal' && serviceOrder && (
        <ApproveReplacementContent serviceOrder={serviceOrder} />
      )}
      {activeView === 'closeServiceOrderModal' && serviceOrder && expenses && (
        <CloseServiceOrderDropdown serviceOrder={serviceOrder} hasExpenses={expenses.length > 0} />
      )}
      {activeView === 'resolveServiceOrderModal' && serviceOrder && (
        <ResolveServiceOrderContent
          claim={claim}
          serviceOrder={serviceOrder}
          contract={contract}
          entitlements={entitlements}
        />
      )}
      {activeView === 'markAsPaidModal' && serviceOrder && (
        <MarkAsPaidContent serviceOrder={serviceOrder} />
      )}
      {activeView === 'createServiceOrderModal' && claim && serviceOrders && (
        <CreateServiceOrderDropdown serviceOrders={serviceOrders} claim={claim} />
      )}

      {activeView === 'cancelVirtualCardModal' && serviceOrder && wallet && (
        <CancelVirtualCardDropdown serviceOrder={serviceOrder} wallet={wallet} />
      )}
    </BasicModal>
  )
}

const Title = styled.h3({
  color: COLOR.BLACK,
  fontSize: 20,
  marginBottom: 16,
  marginTop: 0,
})

export { ActionModal }
