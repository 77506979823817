import {
  AdvancedSelect,
  DataProperty,
  Grid,
  Input,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { useFormikContext } from 'formik'
import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import {
  useGetPlanCategoriesQuery,
  useLazyGetShippingProtectionPlanQuery,
} from '@helloextend/extend-api-rtk-query'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SpPlanFormValues } from '../sp-plan-form-schema'
import { GetFormPropsAndLabels } from '../sp-plan-form-schema'

type SpPlanDetailsSectionProps = {
  mode: 'view' | 'edit'
  existingPlan?: boolean
  modalRender?: boolean
}

type PlanDetailsProps = 'id' | 'name' | 'productCategory'

const SpPlanDetailsSection: FC<SpPlanDetailsSectionProps> = ({
  mode,
  existingPlan,
  modalRender,
}) => {
  const { toast } = useToaster()

  const { values, handleChange, errors, touched, handleBlur, setFieldError } =
    useFormikContext<SpPlanFormValues>()

  const {
    data: planCategories,
    isLoading: isGettingPlanCategories,
    isError: isErrorGettingPlanCategories,
  } = useGetPlanCategoriesQuery(undefined, { skip: mode === 'view' })

  const [getSpPlan, { isLoading: gettingSpPlan }] = useLazyGetShippingProtectionPlanQuery()

  const handlePlanIdBlur = async (e: React.FocusEvent<HTMLInputElement>): Promise<void> => {
    handleBlur(e)
    if (e.target.value !== '' && !errors.id) {
      const res = await getSpPlan({ planId: e.target.value })

      if ('data' in res) {
        setFieldError(
          formProps.id.property,
          'Plan ID already exists. Please enter a different Plan ID.',
        )
        return
      }

      if ((res.error as FetchBaseQueryError).status !== 404) {
        toast({
          message: 'Unable to validate Plan ID. Please try again.',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
        setFieldError(formProps.id.property, 'Unable to verify Plan ID')
      }
    }
  }

  useEffect(() => {
    if (isErrorGettingPlanCategories) {
      toast({
        message: 'Cannot get plan categories. Please try again.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [isErrorGettingPlanCategories, toast])

  const formProps = useMemo(() => GetFormPropsAndLabels<PlanDetailsProps>(), [])

  return (
    <Grid columns={modalRender ? 2 : 3} spacing={4} data-cy="plan-details-section">
      {mode === 'edit' ? (
        <>
          <Input
            id={formProps.id.property}
            value={values.id}
            label={formProps.id.label}
            onChange={handleChange}
            onBlur={handlePlanIdBlur}
            isDisabled={gettingSpPlan || existingPlan}
            isError={!!errors.id && touched.id}
            errorFeedback={errors.id}
            data-cy={formProps.id.property}
          />
          <Input
            id={formProps.name.property}
            value={values.name}
            label={formProps.name.label}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.name && touched.name}
            errorFeedback={errors.name}
            data-cy={formProps.name.property}
          />
          <AdvancedSelect
            id={formProps.productCategory.property}
            value={values.productCategory}
            label={formProps.productCategory.label}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={
              (!!errors.productCategory && touched.productCategory) || isErrorGettingPlanCategories
            }
            errorFeedback={errors.productCategory}
            isLoading={isGettingPlanCategories}
            isDisabled={isGettingPlanCategories}
            options={
              planCategories?.items?.map((category) => ({
                display: category.name,
                value: category.name,
              })) ?? []
            }
            multiple={false}
            showSearch
            data-cy={formProps.productCategory.property}
          />
        </>
      ) : (
        <>
          <DataProperty
            value={values.id}
            label={formProps.id.label}
            data-cy={formProps.id.property}
          />
          <DataProperty
            value={values.name}
            label={formProps.name.label}
            data-cy={formProps.name.property}
          />
          <DataProperty
            value={values.productCategory}
            label={formProps.productCategory.label}
            data-cy={formProps.productCategory.property}
          />
        </>
      )}
    </Grid>
  )
}

export { SpPlanDetailsSection }
