import React from 'react'
import type { Claim } from '@extend-services/claims-management'
import styled from '@emotion/styled'

interface ClaimResultMessaging {
  headerText: string
  titleText: string
  firstBullet: JSX.Element
  secondBullet: string | JSX.Element
  thirdBullet?: string | JSX.Element
}

export function useGetClaimResultMessaging(claim: Claim): ClaimResultMessaging {
  const { email } = claim.customer

  const secondBullet = (
    <div>
      <p>This email will include details on the next steps for this claim.</p>
      <p>
        <b>Claim ID:</b> {claim.id}{' '}
      </p>
    </div>
  )
  const headerText = 'Claim Submitted'

  if (claim.status === 'approved') {
    return {
      headerText,
      titleText: 'This claim is approved! 🎉',
      firstBullet: (
        <p>
          In a few minutes, the customer will receive an email with next steps from Extend at{' '}
          <b>{email}</b>.
        </p>
      ),
      secondBullet,
    }
  }

  if (claim.status === 'denied') {
    return {
      headerText,
      titleText: 'This claim is denied',
      firstBullet: (
        <p>
          In a few minutes, the customer will receive an email with next steps from Extend at{' '}
          <b>{email}</b>.
        </p>
      ),
      secondBullet,
    }
  }

  if (claim?.photoRequirements?.length) {
    return {
      headerText,
      titleText: 'Thank you! This claim requires photos in order to complete the submission.',
      firstBullet: (
        <p>
          You must upload photos in the next 30 days in order to submit your claim and receive a
          decision. You will receive email reminders at <b>{email}</b> with a link to MyExtend to
          upload.
        </p>
      ),
      secondBullet: (
        <StyledDiv>
          <p>
            If you do not upload in 30 days, the claim will automatically be closed. Your Claim ID
            is:
          </p>
          <p>
            <b>Claim ID:</b> {claim.id}{' '}
          </p>
        </StyledDiv>
      ),
      thirdBullet: (
        <p>
          You can also access MyExtend to upload your photos by going to{' '}
          <a href="https://customers.extend.com">customers.extend.com</a> and clicking on the Claims
          tab.
        </p>
      ),
    }
  }

  // catch all review claims
  return {
    headerText,
    titleText: 'Thank you! This claim is in review.',
    firstBullet: (
      <p>
        Extend will review this claim in the next 1-2 business days and send next steps to the
        customer at <b>{email}</b>.
      </p>
    ),
    secondBullet,
  }
}

const StyledDiv = styled.div({
  display: 'flex',
  flexDirection: 'column',
})
