import type { FC } from 'react'
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch, batch } from 'react-redux'
import styled from '@emotion/styled'
import {
  Add,
  Button,
  DataTable,
  ToastColor,
  ToastDuration,
  useToaster,
  Visibility,
} from '@extend/zen'
import type {
  SortingState,
  PaginationState,
  ColumnFiltersState,
  DataTableAction,
  DataTableMenuItem,
} from '@extend/zen'
import type {
  ThreadListItem,
  RulesetResponse,
  ThreadResponse,
} from '@helloextend/extend-api-rtk-query'
import {
  conversationsApi,
  useListThreadsQuery,
  useLazyGetThreadQuery,
  useLazyGetThreadRulesetQuery,
  useLazyGetSamplePhotoQuery,
  usePatchThreadMutation,
  useLazyGetPhotosetQuery,
} from '@helloextend/extend-api-rtk-query'
import { bp } from '@extend/client-helpers'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'
import { AdjudicationTabs } from '../components'
import { threadColumns, filterDefs } from './adjudication-thread-list.utils'
import { EditWarningModal } from '../components/edit-warning-modal/edit-warning-modal'
import { CreateThreadModal } from '../components/create-thread-modal'
import { defaultThreadItem } from '../mocks/mocked-thread-list-items'
import * as selectors from '../../../../reducers/selectors'
import type { RootState } from '../../../../reducers'
import { DuplicateThreadModal } from '../components/duplicate-thread-modal'
import {
  setIsDuplicateThreadModalVisible,
  setSelectedThreadListItem,
  setIsArchiveThreadModalVisible,
  setIsRenameThreadModalVisible,
} from '../../../../store/slices/amp-slice'
import {
  getSearchParams,
  getColumnFiltersFromSearchParams,
  getPhotosFromThread,
  getBase64,
} from '../utils'
import type { ImageDataExport, ImageExport, PhotosetExport } from '../../../../types/conversations'
import { ArchiveThreadModal } from '../components/archive-thread-modal/archive-thread-modal'
import { RenameThreadModal } from '../components/rename-thread-modal'

const AdjudicationThreadList: FC = () => {
  const history = useHistory()
  const { search: searchParams } = useLocation()
  const { toast } = useToaster()
  const dispatch = useDispatch()
  const {
    data: threadsData,
    isFetching,
    isLoading,
  } = useListThreadsQuery(undefined, { skip: false })

  const [fetchThread] = useLazyGetThreadQuery()
  const [fetchRuleset] = useLazyGetThreadRulesetQuery()
  const [fetchSamplePhoto] = useLazyGetSamplePhotoQuery()
  const [fetchPhotoset] = useLazyGetPhotosetQuery()

  const [isEditWarningModalVisible, setIsEditWarningModalVisible] = useState<boolean>(false)
  const [threadToEdit, setThreadToEdit] = useState<ThreadListItem>(defaultThreadItem)
  const [restoreThread] = usePatchThreadMutation()

  const [isCreateThreadModalVisible, setIsCreateThreadModalVisible] = useState(false)
  const isDuplicateModalVisible = useSelector((state: RootState) =>
    selectors.getIsDuplicateThreadModalVisible(state),
  )
  const isArchiveModalVisible = useSelector((state: RootState) =>
    selectors.getIsArchiveThreadModalVisible(state),
  )
  const isRenameModalVisible = useSelector((state: RootState) =>
    selectors.getIsRenameThreadModalVisible(state),
  )
  const selectedThreadListItem = useSelector((state: RootState) =>
    selectors.getSelectedThreadListItem(state),
  )

  const [sorting, setSorting] = useState<SortingState>([{ id: 'updatedAt', desc: true }])
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const { hasPermission } = usePermissions()
  const isUserAssumedEditorRole = hasPermission(Permission.ManageAdjudication)

  const defaultFilters: ColumnFiltersState = [
    { id: 'status', value: ['draft', 'published', 'pending_changes'] },
  ]
  const columnFiltersInit = searchParams
    ? getColumnFiltersFromSearchParams(searchParams)
    : defaultFilters
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(columnFiltersInit)

  useEffect(() => {
    if (columnFilters.length > 0) {
      const search = getSearchParams(columnFilters)
      history.push({
        pathname: '/admin/adjudication-management/threads',
        search,
      })
      return
    }
    history.push({
      pathname: '/admin/adjudication-management/threads',
      search: '',
    })
  }, [history, columnFilters])

  const handleRoute = (route: string): void => {
    history.push({
      pathname: route,
      search: searchParams,
    })
  }

  const resetState = (): void => {
    setIsEditWarningModalVisible(false)
    setThreadToEdit(defaultThreadItem)
  }

  const handleEditClick = (thread: ThreadListItem): void => {
    if (thread.status === 'published') {
      setThreadToEdit(thread)
      setIsEditWarningModalVisible(true)
    } else {
      handleRoute(`/admin/adjudication-management/threads/${thread.id}/edit`)
    }
  }

  const handleRestoreClick = async (thread: ThreadListItem): Promise<void> => {
    try {
      const updatedThread = await restoreThread({
        threadId: thread.id,
        patches: [{ op: 'replace', path: '/status', value: 'restore' }],
      }).unwrap()

      dispatch(
        conversationsApi.util.updateQueryData(
          'listThreads',
          undefined,
          (response: ThreadListItem[]) => {
            return response.map((t) => {
              return t.id === updatedThread.id ? updatedThread : t
            })
          },
        ),
      )
      toast({
        message: `${thread.title} has been successfully restored.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    } catch (err: unknown) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  const handleDuplicateClick = (thread: ThreadListItem): void => {
    batch(() => {
      dispatch(setIsDuplicateThreadModalVisible(true))
      dispatch(setSelectedThreadListItem(thread))
    })
  }

  const handleArchiveClick = (thread: ThreadListItem): void => {
    batch(() => {
      dispatch(setIsArchiveThreadModalVisible(true))
      dispatch(setSelectedThreadListItem(thread))
    })
  }

  const handleRenameClick = (thread: ThreadListItem): void => {
    batch(() => {
      dispatch(setIsRenameThreadModalVisible(true))
      dispatch(setSelectedThreadListItem(thread))
    })
  }

  const exportThread = (
    thread: ThreadResponse,
    ruleset: RulesetResponse | null,
    images: ImageDataExport[],
    photoset: PhotosetExport | null,
  ): void => {
    const exportObj = { thread, ruleset, images, photoset }
    const fileData = JSON.stringify(exportObj)
    const blob = new Blob([fileData], { type: 'json' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = `thread-export-${thread.title}.json`
    link.href = url
    link.click()
  }

  const getImages = async (images: ImageExport[]): Promise<ImageDataExport[]> => {
    const promises = images.map(async (image) => {
      const { id, imageUrl, scriptIndex, messageIndex } = image
      const imageData = await getBase64Image(imageUrl)
      return { id, imageData: String(imageData), scriptIndex, messageIndex }
    })
    return Promise.all(promises)
  }

  const getBase64Image = async (imageUrl: string): Promise<string | void | null> => {
    try {
      const image = await fetchSamplePhoto({ url: imageUrl }).unwrap()
      return await getBase64(image)
    } catch (err: unknown) {
      console.error('Failed to fetch image', err)
      return 'error'
    }
  }

  const handleThreadExportClick = async (thread: ThreadListItem): Promise<void> => {
    let threadToExport: ThreadResponse | null = null
    let threadRulesetToExport: RulesetResponse | null = null
    let imagesToExport: ImageDataExport[] = []
    let photosetToExport: PhotosetExport | null = null
    // It's a legit case that there is no ruleset, so that fetch may return 404
    try {
      threadToExport = await fetchThread(thread.id, true).unwrap()
      const images = getPhotosFromThread(threadToExport)
      const photoset = await fetchPhotoset(thread.id)
        .unwrap()
        .catch(() => {}) // Do nothing on photoset errors, these occur often when photoset is not present
      if (photoset) {
        const photoItemsToExport = await Promise.all(
          photoset.photoItems.map(async (photoItem) => {
            const { imageUrl } = photoItem
            return {
              ...photoItem,
              base64Image: imageUrl ? await getBase64Image(imageUrl) : undefined,
            }
          }),
        )
        photosetToExport = { ...photoset, photoItems: photoItemsToExport }
      }
      if (images) {
        imagesToExport = await getImages(images)
      }
      threadRulesetToExport = await fetchRuleset(thread.id, true).unwrap()
    } catch (err) {
       
      console.error(err)
    }

    if (threadToExport) {
      exportThread(threadToExport, threadRulesetToExport, imagesToExport, photosetToExport)
      toast({
        message: `${thread.title} has been successfully exported.`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    } else {
      toast({
        message: `Something went wrong. Please try again.`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  const handleConfigureRulesClick = (thread: ThreadListItem): void => {
    if (thread.status !== 'draft') {
      handleRoute(`/admin/adjudication-management/threads/${thread.id}/edit/adjudication-rules`)
      resetState()
    }
  }

  const handleEditWarningModalConfirm = (): void => {
    if (threadToEdit) {
      const contentPath = threadToEdit.type === 'adjudication' ? '/content' : ''
      handleRoute(`/admin/adjudication-management/threads/${threadToEdit.id}/edit${contentPath}`)
      resetState()
    }
  }

  const handleEditWarningModalCancel = (): void => {
    resetState()
  }

  const handleToggleCreateThread = (): void => {
    setIsCreateThreadModalVisible(!isCreateThreadModalVisible)
  }

  const handleToggleDuplicateThread = (): void => {
    dispatch(setIsDuplicateThreadModalVisible(!isDuplicateModalVisible))

    if (selectedThreadListItem) {
      dispatch(setSelectedThreadListItem(null))
    }
  }

  const handleToggleRenameThread = (): void => {
    dispatch(setIsRenameThreadModalVisible(!isRenameModalVisible))

    if (selectedThreadListItem) {
      dispatch(setSelectedThreadListItem(null))
    }
  }

  const handleToggleArchiveThread = (): void => {
    dispatch(setIsArchiveThreadModalVisible(!isArchiveModalVisible))

    if (selectedThreadListItem) {
      dispatch(setSelectedThreadListItem(null))
    }
  }

  const getRowActions = (row: ThreadListItem): DataTableAction[] => {
    return [
      {
        onClick: () => handleRoute(`/admin/adjudication-management/threads/${row.id}/preview`),
        tooltip: 'Preview',
        emphasis: 'low',
        icon: Visibility,
        'data-cy': `preview-${row.id}`,
      },
    ]
  }

  const getRowMenuItems = (row: ThreadListItem): DataTableMenuItem[] => [
    ...(row.status !== 'archived'
      ? [
          {
            onClick: () => handleEditClick(row),
            'data-cy': 'edit-thread-link',
            children: 'Edit',
          },
        ]
      : []),
    ...(row.status !== 'archived'
      ? [
          {
            onClick: () => handleDuplicateClick(row),
            'data-cy': 'duplicate-thread-link',
            children: 'Duplicate',
          },
        ]
      : []),
    ...(row.type === 'adjudication' && row.status !== 'archived'
      ? [
          {
            onClick: () => handleConfigureRulesClick(row),
            children: 'Configure claim adjudication rules',
            tooltip:
              'Only Published and Changes Pending claim adjudication rules can be configured',
            'data-cy': 'configure-adjudication-rules-thread-link',
            disabled: row.status === 'draft',
          } as DataTableMenuItem,
        ]
      : []),
    ...(row.type === 'adjudication' && row.status !== 'archived'
      ? [
          {
            onClick: () => handleThreadExportClick(row),
            children: 'Export',
            'data-cy': 'export-thread',
          },
        ]
      : []),
    {
      onClick: () => handleRenameClick(row),
      children: 'Rename',
      'data-cy': 'rename-thread',
    },
    ...(row.status !== 'archived'
      ? [
          {
            onClick: () => handleArchiveClick(row),
            children: 'Archive',
            'data-cy': 'archive-thread',
          },
        ]
      : []),
    ...(row.status === 'archived'
      ? [
          {
            onClick: () => handleRestoreClick(row),
            'data-cy': 'restore-thread-link',
            children: 'Restore',
          },
        ]
      : []),
  ]

  return (
    <>
      {isCreateThreadModalVisible && (
        <CreateThreadModal
          isVisible={isCreateThreadModalVisible}
          onCancel={handleToggleCreateThread}
        />
      )}
      {isDuplicateModalVisible && (
        <DuplicateThreadModal
          isVisible={isDuplicateModalVisible}
          onCancel={handleToggleDuplicateThread}
          thread={selectedThreadListItem}
        />
      )}
      {isArchiveModalVisible && (
        <ArchiveThreadModal
          isVisible={isArchiveModalVisible}
          onCancel={handleToggleArchiveThread}
          thread={selectedThreadListItem}
        />
      )}
      {isRenameModalVisible && (
        <RenameThreadModal
          isVisible={isRenameModalVisible}
          onCancel={handleToggleRenameThread}
          thread={selectedThreadListItem}
        />
      )}
      <AdjudicationTabs />
      <TopRowContainer>
        <ThreadsMessage data-cy="adjudication-management-threads-message">
          The published threads can be used to compose conversations. Claim Adjudication rules can
          only be configured under Adjudication threads.
        </ThreadsMessage>
      </TopRowContainer>
      <TopRowButtonContainer>
        {isUserAssumedEditorRole && (
          <ButtonContainer>
            <Button
              data-cy="adjudication-management-threads-create-button"
              emphasis="medium"
              icon={Add}
              onClick={handleToggleCreateThread}
              text="Create Thread"
              tooltip="Create Thread"
            />
          </ButtonContainer>
        )}
      </TopRowButtonContainer>
      <DataTable
        data-cy="adjudication-management-thread-list"
        isLoading={isLoading || isFetching}
        data={threadsData ?? []}
        columns={threadColumns}
        hasConfigurableColumns={false}
        sorting={sorting}
        onSortingChange={setSorting}
        hasManualSorting={false}
        pagination={pagination}
        onPaginationChange={setPagination}
        hasManualPagination={false}
        filterDefs={filterDefs}
        columnFilters={columnFilters}
        onColumnFiltersChange={setColumnFilters}
        hasManualFiltering={false}
        getRowActions={getRowActions}
        getRowMenuItems={isUserAssumedEditorRole ? getRowMenuItems : undefined}
      />
      {isEditWarningModalVisible && (
        <EditWarningModal
          isVisible={isEditWarningModalVisible}
          onConfirm={handleEditWarningModalConfirm}
          onCancel={handleEditWarningModalCancel}
          thread={threadToEdit}
        />
      )}
    </>
  )
}

const TopRowContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 40,
})

const TopRowButtonContainer = styled(TopRowContainer)({
  marginBottom: 24,
  [bp.lg]: {
    justifyContent: 'flex-end',
    marginBottom: -40,
  },
})

const ButtonContainer = styled.div({
  flexShrink: 0,
})

const ThreadsMessage = styled.div({
  marginBottom: 28,
  fontSize: 16,
  lineHeight: '24px',
})

export { AdjudicationThreadList }
